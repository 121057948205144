<ul class="hvac-cp-results-list hvac-cp-results-list-{{ mode }} hvac-cp-results-list-{{ theme }}">
    <li class="hvac-cp-results-list-item" *ngFor="let resource of results">
        <div class="hvac-cp-results-list-item-container">
            <div class="hvac-cp-system-product-list-item">
                <hvac-connected-portal-system-product-card
                    [image]="resource.image"
                    [name]="resource.name"
                    [showFaultIndicator]="resource.showFaultIndicator"
                    [descriptors]="resource.descriptors"
                    [productLink]="resource.productLink"
                    [systemType]="resource.systemType"
                    [type]="resource.type"
                    [noDataMessage]="resource.noDataMessage"
                    [isUpdateEnabled]="resource.isUpdateEnabled"
                    [systemId]="systemId"
                    [showDescriptor]="resource.showDescriptor"
                    (onUpdateClick)="handleUpdateClick($event)"
                ></hvac-connected-portal-system-product-card>
            </div>
        </div>
    </li>
</ul>