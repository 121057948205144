<div class="hvac-property-system-panel">
    <hvac-accordion-new [open]="openState" (toggle)="handleToggle()" *ngIf="systemView === SystemView.ProductsView">
        <hvac-accordion-header>
            <div class="hvac-accordion-title">
                <span class="hvac-cp-name hvac-h4" [class.hvac-fault-indicator]="system.hasCriticalFault">{{ system.name }}</span>
                <button class="hvac-relearn-button" hvacButton="text" *ngIf="isRelearnSupported && !isRelearnDisabled" (click)="handleRelearnClick()">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.RELEARN_SYSTEM' | translate }}</button>
            </div>

            <div class="hvac-cp-header-content">
                <div class="hvac-cp-data-sharing">
                    <hvac-system-data-sharing-header [dataSharing]="system.dataSharing" [systemType]="system.systemType"></hvac-system-data-sharing-header>
                </div>

                <div *ngIf="system.systemType === SystemType.INFINITY" class="hvac-cp-view-button hvac-cp-notification-preferences-button-container">
                    <button *ngIf="systemView === SystemView.ProductsView" hvacButton="default" size="small" (click)="systemView = SystemView.NotificationPreferencesView; getNotificationPreferences()">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION_PREFERENCES' | translate }}</button>
                </div>
            </div>
        </hvac-accordion-header>
        <hvac-accordion-body>
            <hvac-toast [name]="system.systemId"></hvac-toast>

            <div style="position: relative;">
                <hvac-connected-portal-results-list
                mode="grid"
                theme="borderless"
                [results]="system.products"
                [systemId]="system.systemId"
                (onUpdateBtnClick)="handleProductUpdateClick($event)"></hvac-connected-portal-results-list>

                <ng-container *ngIf="isRelearning">
                    <utc-loading-spinner variant="solid"><span class="hvac-p-sm">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.RELEARNING_EQUIPMENT' | translate }}</span></utc-loading-spinner>
                </ng-container>
            </div>
        </hvac-accordion-body>

    </hvac-accordion-new>
    <ng-container *ngIf="isDeviceStatusLoading">
        <utc-loading-spinner  ></utc-loading-spinner>
    </ng-container>

    <div *ngIf="systemView === SystemView.NotificationPreferencesView" class="hvac-system-notification-preferences">
        <div class="hvac-cp-notifications-header">
            <span class="hvac-h4">{{system.name}} {{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION_PREFERENCES' | translate }}</span>

            <div class="hvac-cp-header-content">
                <div class="hvac-cp-view-button">
                    <button *ngIf="systemView === SystemView.NotificationPreferencesView" hvacButton="default" size="small" (click)="systemView = SystemView.ProductsView">
                            <div class="hvac-button-arrow"><utc-svg src="assets/icons/arrow.svg"></utc-svg></div>{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.SYSTEM_VIEW' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div [ngClass]="{ 'hvac-notifications-preferences-loading': isLoading.notificationPreferences }">
            <ng-container *ngIf="isLoading.notificationPreferences; else notificationPreferences">
                <utc-loading-spinner></utc-loading-spinner>
            </ng-container>

            <ng-template #notificationPreferences>
                <ng-container *ngIf="this.notificationPreferences$ | async as notificationPreferences">
                    <hvac-system-notification-preferences [data]="notificationPreferences"></hvac-system-notification-preferences>
                </ng-container>
            </ng-template>
        </div>
    </div>
</div>
