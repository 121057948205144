<div class="hvac-notifications"
    *ngIf="(isActiveUser$ | async) && (((requestCount$ | async) || 0) > 0 || (notifications$ | async)?.length)">
    <span class="hvac-notifications-symbol">
        <utc-svg src="assets/icons/bell.svg"></utc-svg>
    </span>
    <ul class="hvac-notification-list">
        <li class="hvac-notifications-list-item" *ngIf="((requestCount$ | async) || 0) > 0">
            <a [routerLink]="'/Pages/Admin/Registration.aspx'"
                class="hvac-notifications-link hvac-user-registration-notification"
                [innerHtml]="((approvalRequestsNotification$ | async) || '') | safe:'html'"></a>
        </li>
        <li class="hvac-notifications-list-item" *ngFor="let notification of (notifications$ | async) ">
            <hvac-link *ngIf="hasLink(notification); else textNotification" [link]="notification?.Content?.links"
                class="hvac-notifications-link">
                <div class="hvac-notifications-link-box">
                    <span [innerHtml]="notification?.Content?.title | richText"></span>
                </div>
            </hvac-link>
            <ng-template #textNotification>
                <span class="hvac-notifications-text-notification"
                    [innerHtml]="notification?.Content?.title | richText"></span>
            </ng-template>
            <button class='hvac-notifications-remove-button' (click)="removeNotification(notification)" >
                <utc-svg class='hvac-notifications-remove-icon' src="assets/icons/close.svg"></utc-svg>
            </button>
        </li>
    </ul>
</div>
