<div class="hvac-eligibility-criteria-container">

    <form [formGroup]="fieldCriteriaForm" class="hvac-rhythm-3">
        <div class="hvac-row hvac-criteria-name-container">
            <div class="hvac-row hvac-criteria-name-text">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_NAME' | translate }}
                    <abbr title="required" class="hvac-program-criteria-header-required">*</abbr>
                </h2>
                <p class="hvac-p-sm">
                    <abbr title="required" class="hvac-program-header-required">*</abbr>
                    {{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" | translate }}
                </p>
            </div>
            <utc-select class="hvac-criteria-name-input hvac-col-xs-12 hvac-col-md-5 hvac-col-4" [options]="(criteriaNamesList$ | async)!" shadow="none" [group]="fieldCriteriaForm" control="criteriaName"
                defaultOption="{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.SELECT' | translate }}"
                (change)="getAccountAdminProgramCriteria()">
            </utc-select>
        </div>
    </form>
    <br>

    <div [ngClass]="{ 'disable-fields' : disableFields }">
        <h2 class="hvac-header">
            {{ 'ACCOUNT_ADMIN.PROGRAMS.FIELD_CRITERIA' | translate }}
        </h2>
        <hvac-dynamic-nested-filter [QueryBuilderConfigData]="queryBuilderConfigValues" [QueryData]="queryDataToDisplay" [ProgramNameData$]="programNameData$" [autoComplete$]="autoComplete$" (updatedQueryData)="modifyQueryData($event)" (programNameString)="programName($event)" (programNameWithPagination)="programNameWithPagination()"> </hvac-dynamic-nested-filter>
    </div>
    <div [ngClass]="{ 'disable-fields' : disableFields }" class="hvac-row hvac-eligibility-criteria-field-container">
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-6">
            <form [formGroup]="customerCompanyForm" class="hvac-criteria-form hvac-rhythm-4">
                <div class=" hvac-company-id hvac-criteria-input">
                    <h2 class="hvac-header">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.CUSTOMER_COMPANYID' | translate }}
                    </h2>
                    <hvac-input-file acceptableFileType="csv" [displayInputField]=displayInputField fileUploadControl="customer" (onUploadFile)="eligibilityCriteriaService.uploadedFile($event)" (onImportFile)="eligibilityCriteriaService.importedFile($event)"></hvac-input-file>
                    <div class="hvac-upload-file-table" *ngIf="eligibilityCriteriaService.isCustomerTableVisible">
                        <div class="hvac-upload-file-table-header">
                            <p class="hvac-p-sm">
                                {{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}
                            </p>
                            <p class="hvac-p-sm">
                                {{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION' | translate }}
                            </p>
                        </div>
                        <div class="hvac-upload-file-table-body">
                            <form [formGroup]="customerCompanyForm">
                                <utc-input shadow="none" class="hvac-col-lg-12  hvac-col-md-12 hvac-col-xs-12" type="text" [inputControl]="customerCompanyForm.controls.customerCompanyId"
                                    label="Filter" (keyup)="eligibilityCriteriaService.filterCustomerID($event)">
                                </utc-input>
                            </form>
                            <hvac-table class="hvac-criteria-table-wrap" [theme]="'zebra'">
                                <tbody>
                                    <tr *ngFor="let company of (eligibilityCriteriaService.customerCompanySearchFilterData$ | async)">
                                        <td>
                                            {{company}}
                                        </td>
                                        <td class="hvac-upload-file-actions-width">
                                            <button type="button" (click)="eligibilityCriteriaService.removeCompanyId(company)" hvacButton="text">{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.REMOVE' | translate }}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </hvac-table>
                            <p class="hvac-upload-file-no-results" *ngIf="!(eligibilityCriteriaService.customerCompanyList$ | async)?.length">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
                        </div>
                        <div class="hvac-upload-fle-table-footer">
                            <hvac-pagination *ngIf="!eligibilityCriteriaService.isCustomerCompanyIDFiltered; else removeCustomerCompanyIDPagination" [totalPages]="eligibilityCriteriaService.customerTotalPages" [currentPage]="eligibilityCriteriaService.customerCurrentPage" (pageChange)="eligibilityCriteriaService.handleCustomerPageChange($event)">
                            </hvac-pagination>
                            <ng-template #removeCustomerCompanyIDPagination>
                                <hvac-pagination [totalPages]="totalPagesAfterFilter" [currentPage]="currentPageAfterFilter">
                                </hvac-pagination>
                            </ng-template>
                            <div class="hvac-upload-file-table-count">
                                <p>{{eligibilityCriteriaService.customerTotalCompany}} Results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-6">
            <form [formGroup]="distributorCompanyForm" class="hvac-rhythm-4">
                <div class=" hvac-company-id hvac-criteria-input">
                    <h2 class="hvac-header">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.DISTRIBUTOR_COMPANYID' | translate }}
                    </h2>
                    <hvac-input-file acceptableFileType="csv" [displayInputField]=displayInputField control="distributor" (onUploadFile)="eligibilityCriteriaService.uploadedFile($event)" (onImportFile)="eligibilityCriteriaService.importedFile($event)"></hvac-input-file>
                    <div class="hvac-upload-file-table" *ngIf="eligibilityCriteriaService.isDistributorTableVisible">
                        <div class="hvac-upload-file-table-header">
                            <p class="hvac-p-sm">
                                {{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}
                            </p>
                            <p class="hvac-p-sm">
                                {{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION' | translate }}
                            </p>
                        </div>
                        <div class="hvac-upload-file-table-body">
                            <form [formGroup]="distributorCompanyForm">
                                <utc-input shadow="none" class="hvac-col-lg-12  hvac-col-md-12 hvac-col-xs-12" type="text" [inputControl]="distributorCompanyForm.controls.distributorCompanyId"
                                    label="Filter" (keyup)="eligibilityCriteriaService.filterDistributorID($event)">
                                </utc-input>
                            </form>
                            <hvac-table class="hvac-criteria-table-wrap" [theme]="'zebra'">
                                <tbody>
                                    <tr *ngFor="let company of (eligibilityCriteriaService.distributorCompanySearchFilterData$ | async)">
                                        <td>
                                            {{company}}
                                        </td>
                                        <td class="hvac-upload-file-actions-width">
                                            <button type="button" (click)="eligibilityCriteriaService.removeDistributorId(company)" hvacButton="text">{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.REMOVE' | translate }}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </hvac-table>
                            <p class="hvac-upload-file-no-results" *ngIf="!(eligibilityCriteriaService.distributorCompanyList$ | async)?.length">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
                        </div>
                        <div class="hvac-upload-fle-table-footer">
                            <hvac-pagination *ngIf="!eligibilityCriteriaService.isDistributorCompanyIDFiltered; else removeDistributorCompanyIDPagination" [totalPages]="eligibilityCriteriaService.distributorTotalPages" [currentPage]="eligibilityCriteriaService.distributorCurrentPage" (pageChange)="eligibilityCriteriaService.handleDistributorPageChange($event)">
                            </hvac-pagination>
                            <ng-template #removeDistributorCompanyIDPagination>
                                <hvac-pagination [totalPages]="totalPagesAfterFilter" [currentPage]="currentPageAfterFilter">
                                </hvac-pagination>
                            </ng-template>
                            <div class="hvac-upload-file-table-count">
                                <p>{{eligibilityCriteriaService.distributorTotalCompany}} Results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="hvac-csv-template-note-container hvac-col-xs-12 hvac-col-md-12">
            <utc-svg class="hvac-note-info-icon" src="assets/icons/info.svg"></utc-svg>
            <p class="hvac-h6 notification-settings-note">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CSV_TEMPLATE_TEXT' | translate }}</p>
        </div>
    </div>
    <div *ngIf="criteriaData!=null && criteriaData.eligibleCompanies!=null" class="hvac-results-count">
        <p class="hvac-p-sm">{{ 'ACCOUNT_ADMIN.RESULTS_COUNT' | translate: { totalCount: criteriaData.eligibleCompanies.length} }}</p>
    </div>
    <hvac-table class="hvac-criteria-table-wrap" [theme]="'zebra'" [responsive]="true">
        <thead>
            <tr>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COMPANY_ID' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COMPANY_NAME' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.TIER_LEVEL' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.CITY' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.STATE' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COUNTRY' | translate }}
                </td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let company of (eligibleCompanyData$ | async)">
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COMPANY_ID' | translate">{{ company.companyId }}</td>
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COMPANY_NAME' | translate">{{ company.name }}</td>
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.TIER_LEVEL' | translate">{{ company.tierLevel }}</td>
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.CITY' | translate">{{ company.city }}</td>
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.STATE' | translate">{{ company.state }}</td>
                <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TABLE_LABELS.COUNTRY' | translate">{{ company.country }}</td>
            </tr>
        </tbody>
    </hvac-table>
    <p class="hvac-no-results" *ngIf="!(eligibleCompanyData$ | async)?.length && !isLoading">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.NO_ELIGIBLE_COMPANIES' | translate }}</p>
    <utc-loading-spinner spinnerSize="small" *ngIf="isLoading"></utc-loading-spinner>

    <div class="hvac-row">
        <div class="hvac-col-lg-8 hvac-col-md-8">
            <div class="hvac-pagination">
                <hvac-pagination *ngIf="totalPages > 1 && !isLoading" [totalPages]="totalPages" [currentPage]="currentPage" (pageChange)="handlePageChange($event)">
                </hvac-pagination>
            </div>
        </div>
        <div class="hvac-col-lg-4 hvac-col-md-4">
            <div class="hvac-eligibility-criteria-action-buttons">
                <button type="button" hvacButton="default" (click)="onCancel()">
                    <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
                </button>
                <button type="button" hvacButton="primary" (click)="onSubmit()" [disabled]="isLoading || (!modifiedQueryDataToDisplay || modifiedQueryDataToDisplay.rules.length === 0) || !isQueryUpdated">
                    <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.UPDATE' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
