import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'common/app-constants';
import { createAccountEmailInputValidator, createAccountInputValidator, createAccountPostalCodeValidator } from 'common/utils/createAccountValidators';
import { ExportUserDetailsEntity, UserDetails, UserDetailsEntity, UserRegistrationDetails } from 'private/app/models/user.model';
import { inputValidator } from '../account-admin-edit-form.service';
import { ContentHeadingData } from 'common/models/content-heading';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ToastTheme } from 'common/services/toast.service';

@Injectable({ providedIn: 'root' })
export class UserAdminService {
    public userSecurityRoleGrouping: string[];
    public userSecurityAdditionalRoleGrouping: string[];
    public menuActive: boolean = false;
    public bannerType = 'UserEdit';
    public breadCrumbData: ContentHeadingData = {
        Content: {
            title: this.translate.instant('USER_ADMIN.HEADING'),
            pageTitle: this.translate.instant('USER_ADMIN.HEADING')
        },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
                url: '/admin'
            },
            {
                title: `${this.translate.instant('ACCOUNT_ADMIN.PARENT_2')}`,
                url: '/Admin/Admin-Tools/account-admin.html'
            }
        ]
    };

    public userEditForm = new UntypedFormGroup({
        firstName: new UntypedFormControl('', [createAccountInputValidator(41, true)]),
        lastName: new UntypedFormControl('', [createAccountInputValidator(41, true)]),
        userID: new UntypedFormControl(''),
        userTitle: new UntypedFormControl('', [createAccountInputValidator(41, false)]),
        employerName: new UntypedFormControl(''),

        companyName: new UntypedFormControl(''),
        companyId: new UntypedFormControl(''),
        hvacPin: new UntypedFormControl(''),

        userAddress1: new UntypedFormControl('', [createAccountInputValidator(61, true)]),
        userAddress2: new UntypedFormControl('', [createAccountInputValidator(61, false)]),
        userCity: new UntypedFormControl('', [createAccountInputValidator(41, true)]),

        userState: new UntypedFormControl('', [Validators.required]),
        userCountry: new UntypedFormControl('', [Validators.required]),
        userPostalCode: new UntypedFormControl('', [createAccountPostalCodeValidator()]),

        userPhone: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        userCellPhone: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        userFax: new UntypedFormControl('', [inputValidator(21, false), Validators.pattern(AppConstants.validPhoneRegex)]),

        email: new UntypedFormControl('',
            [createAccountEmailInputValidator(101, false, AppConstants.validEmailRegex)]),
        contactOnly: new UntypedFormControl(''),

        lastLoginDate: new UntypedFormControl(''),
        registrationAction: new UntypedFormControl('', [Validators.required]),
        comments: new UntypedFormControl('', [createAccountInputValidator(500, false)])
    });

    constructor(
        private translate: TranslateService,
        private readonly toastService: ToastService

    ) {}

    public smoothScrollToTop() {
        window.scroll({
            behavior: 'smooth',
            top: 0,
            left: 0
        });
    }

    getActiveScreenName(activeUserScreen: string): string {
        let activeScreenName = '';

        if (activeUserScreen === 'EditInfo') {
            activeScreenName = this.translate.instant('USER_ADMIN.EDIT_USER_DETAILS');
        }
        else if (activeUserScreen === 'EditSecurity') {
            activeScreenName = this.translate.instant('USER_ADMIN.EDIT_USER_SECURITY');
        }
        else if (activeUserScreen === 'ReadOnlyUserInfo') {
            activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_INFORMATION');
        }
        else if (activeUserScreen === 'EditRelations') {
            activeScreenName = this.translate.instant('ACCOUNT_ADMIN.EDIT_USER_RELATIONSHIP');
        }
        else if (activeUserScreen === 'CloneUser') {
            activeScreenName = this.translate.instant('ACCOUNT_ADMIN.CLONE_USER');
        }
        else if (activeUserScreen === 'Step 4: User Roles') {
            activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_CREATE_PAGE.STEP_4');
        }

        return activeScreenName;
    }

    createUserPostBody(user: UserDetailsEntity): UserDetailsEntity {
        this.userEditForm.controls.firstName && (user.firstName = `${this.userEditForm.controls.firstName.value}`);
        this.userEditForm.controls.lastName && (user.lastName = `${this.userEditForm.controls.lastName.value}`);
        this.userEditForm.controls.userID && (user.userID = `${this.userEditForm.controls.userID.value}`);
        this.userEditForm.controls.userTitle && (user.userTitle = `${this.userEditForm.controls.userTitle.value}`);

        this.userEditForm.controls.companyName && (user.companyName = `${this.userEditForm.controls.companyName.value}`);
        this.userEditForm.controls.companyId && (user.companyId = `${this.userEditForm.controls.companyId.value}`);
        this.userEditForm.controls.hvacPin && (user.hvacPin = `${this.userEditForm.controls.hvacPin.value}`);

        this.userEditForm.controls.userAddress1 && (user.userAddress1 = `${this.userEditForm.controls.userAddress1.value}`);
        this.userEditForm.controls.userAddress2 && (user.userAddress2 = `${this.userEditForm.controls.userAddress2.value}`);
        this.userEditForm.controls.userCity && (user.userCity = `${this.userEditForm.controls.userCity.value}`);

        this.userEditForm.controls.userPostalCode && (user.userPostalCode = `${this.userEditForm.controls.userPostalCode.value}`);

        this.userEditForm.controls.userPhone && (user.userPhone = `${this.userEditForm.controls.userPhone.value}`);
        this.userEditForm.controls.userCellPhone && (user.userCellPhone = `${this.userEditForm.controls.userCellPhone.value}`);
        this.userEditForm.controls.userFax && (user.userFax = `${this.userEditForm.controls.userFax.value}`);

        this.userEditForm.controls.email && (user.email = `${this.userEditForm.controls.email.value}`);

        return user;
    }

    createUserRegistrationPostBody(user: UserRegistrationDetails): UserRegistrationDetails {
        this.userEditForm.controls.firstName && (user.firstName = `${this.userEditForm.controls.firstName.value}`);
        this.userEditForm.controls.lastName && (user.lastName = `${this.userEditForm.controls.lastName.value}`);
        this.userEditForm.controls.userID && (user.userID = `${this.userEditForm.controls.userID.value}`);
        this.userEditForm.controls.userTitle && (user.userTitle = `${this.userEditForm.controls.userTitle.value}`);
        this.userEditForm.controls.employerName && (user.employerName = `${this.userEditForm.controls.employerName.value}`);
        this.userEditForm.controls.companyId && (user.companyId = `${this.userEditForm.controls.companyId.value}`);

        this.userEditForm.controls.userAddress1 && (user.userAddress1 = `${this.userEditForm.controls.userAddress1.value}`);
        this.userEditForm.controls.userAddress2 && (user.userAddress2 = `${this.userEditForm.controls.userAddress2.value}`);
        this.userEditForm.controls.userCity && (user.userCity = `${this.userEditForm.controls.userCity.value}`);
        this.userEditForm.controls.userCountry && (user.userCountry = `${this.userEditForm.controls.userCountry.value}`);
        this.userEditForm.controls.userState && (user.userState = `${this.userEditForm.controls.userState.value}`);
        this.userEditForm.controls.userPostalCode && (user.userPostalCode = `${this.userEditForm.controls.userPostalCode.value}`);

        this.userEditForm.controls.userPhone && (user.userPhone = `${this.userEditForm.controls.userPhone.value}`);
        this.userEditForm.controls.userCellPhone && (user.userCellPhone = `${this.userEditForm.controls.userCellPhone.value}`);
        this.userEditForm.controls.userFax && (user.userFax = `${this.userEditForm.controls.userFax.value}`);

        this.userEditForm.controls.email && (user.email = `${this.userEditForm.controls.email.value}`);
        this.userEditForm.controls.registrationAction && (user.registrationAction = `${this.userEditForm.controls.registrationAction.value[0].value}`);
        this.userEditForm.controls.comments && (user.registrationComment = `${this.userEditForm.controls.comments.value}`);

        return user;
    }

    toggleMenu() {
        this.menuActive = !this.menuActive;
    }

    mapCreateUserDetails() {
        const createUserDetails: UserDetails = {
            userID: this.userEditForm.value.userID,
            companyId: this.userEditForm.value.companyId,
            employerName: this.userEditForm.value.employerName,
            firstName: this.userEditForm.value.firstName,
            lastName: this.userEditForm.value.lastName,
            userTitle: this.userEditForm.value.userTitle,
            userAddress1: this.userEditForm.value.userAddress1,
            userAddress2: this.userEditForm.value.userAddress2,
            userCity: this.userEditForm.value.userCity,
            userCountry: (typeof this.userEditForm.value.userCountry === 'object') ? this.userEditForm.value.userCountry[0].value : this.userEditForm.value.userCountry.substring(this.userEditForm.value.userCountry.indexOf('-') + 1).trim(),
            userState: (typeof this.userEditForm.value.userState === 'object') ? this.userEditForm.value.userState[0].value : this.userEditForm.value.userState.substring(0, this.userEditForm.value.userState.indexOf('-')).trim(),
            userPostalCode: this.userEditForm.value.userPostalCode,
            userPhone: this.userEditForm.value.userPhone,
            userCellPhone: this.userEditForm.value.userCellPhone,
            userFax: this.userEditForm.value.userFax,
            email: this.userEditForm.value.email,
            contactOnly: this.userEditForm.value.contactOnly !== ''
        };

        return createUserDetails;
    }

    public transformToExportUserDetailFormart(userDetails: UserDetailsEntity, userExportDetails: ExportUserDetailsEntity): ExportUserDetailsEntity {
        userExportDetails.FirstName = userDetails.firstName;
        userExportDetails.LastName = userDetails.lastName;
        userExportDetails.UserName = userDetails.userID;
        userExportDetails.HvacPin = userDetails.hvacPin;
        userExportDetails.LastLoginDate = userDetails.lastLoginDate;
        userExportDetails.EmployerName = userDetails.employerName;
        userExportDetails.CompanyId = userDetails.companyId;
        userExportDetails.AddressOne = userDetails.userAddress1;
        userExportDetails.AddressTwo = userDetails.userAddress2;
        userExportDetails.UserCity = userDetails.userCity;
        userExportDetails.UserCountry = userDetails.userCountry;
        userExportDetails.UserPostalCode = userDetails.userPostalCode;
        userExportDetails.UserState = userDetails.userState;
        userExportDetails.UserEmail = userDetails.email;
        userExportDetails.Status = userDetails.userStatus;
        userExportDetails.UserPhone = userDetails.userPhone;
        userExportDetails.UserCellPhone = userDetails.userCellPhone;
        userExportDetails.UserFax = userDetails.userFax;
        userExportDetails.Roles = (userDetails.userRolesDetails).map((data) => data['roleDisplayName']).join(', ');

        return userExportDetails;
    }

    public showToastBar(theme: ToastTheme, response: string) {
        this.toastService.add({
            content: response,
            theme: theme,
            id: this.bannerType,
            closeable: true,
            autoClose: true
        });
    }

    public removePrefixLetterZ(str = ''): string {
        if (str && str.length > 0 && str.toLowerCase()[0] === 'z') {
            return str.substring(1);
        }

        return str;
    }
}
