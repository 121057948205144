<section class="hvac-product-details-content-section">
    <div *ngIf="showFeatures$ | async">
        <div class="hvac-product-content-section-overview">
            <div class="hvac-product-content-section-overview-description">
                <h2 class="hvac-section-heading" [attr.id]="'PRODUCTS.FEATURES' | translate" tabindex="-1">{{ 'PRODUCTS.FEATURES' | translate }}</h2>
                <div class="hvac-product-content-section-text" [innerHTML]="featuresTexts$ | async"></div>
            </div>
        </div>
    </div>
    <div class="hvac-proposition-container">
        <p class="hvac-proposition-cta">{{ 'PRODUCTS.CALIFORNIA_RESIDENTS_PLEASE_SEE' | translate }} <a href="{{ 'PRODUCTS.PROPOSITION_LINK' | translate }}" class="hvac-text-link">{{ 'PRODUCTS.PROPOSITION_65' | translate }}</a></p>
    </div>
    <hr class="hvac-section-rule">
</section>
