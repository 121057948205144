<div class="hvac-models-table-container">
    <div class="hvac-zipcode-filter-container">
        <utc-input class="hvac-col-xs hvac-zipcode-input" [inputControl]="filterControlGroup.controls.brandControl"
            label="{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.BRAND' | translate }}">
        </utc-input>

        <utc-input class="hvac-col-xs hvac-zipcode-input" [inputControl]="filterControlGroup.controls.countyControl"
            label="{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.COUNTY' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-zipcode-input" [inputControl]="filterControlGroup.controls.cityControl"
            label="{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.CITY' | translate }}">
        </utc-input>
        <utc-input class="hvac-col-xs hvac-zipcode-input" [inputControl]="filterControlGroup.controls.stateControl"
            label="{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.STATE' | translate }}">
        </utc-input>

        <utc-input class="hvac-col-xs hvac-zipcode-input" [inputControl]="filterControlGroup.controls.zipCodeControl"
            label="{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ZIP' | translate }}">
        </utc-input>
    </div>
    <div class="hvac-model-table-wrap hvac-rhythm-3">
        <hvac-table>
            <thead>
                <tr>
                    <td>
                        <button class="hvac-select-all-button" hvacButton="text"
                        (click)="selectAllZipCodes()">
                        {{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.SELECT_ALL' | translate}}
                    </button>
                    </td>
                    <td *ngFor="let item of dataHeaders">
                        <div class="hvac-model-table-header">
                            <button class="hvac-table-sort-btn" (click)="sort(item)">
                                {{item.title}}
                                <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                                    <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                                </span>
                            </button>

                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let data of (zipCodeDisplayArray$ | async)'>
                    <td>
                        <utc-checkbox  *ngIf="tableTypeIdentifier === 'availableZipcodeTable'; else assignedZipcode" [checked]="data.isAssigned || false" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectZipCode(data, $event)">
                        </utc-checkbox>
                        <ng-template #assignedZipcode>
                            <utc-checkbox [checked]="!data.isAssigned" inputId="create-account-input-terms" inputName="create-account-input-terms"
                            (change)="selectZipCode(data, $event)">
                        </utc-checkbox>
                        </ng-template>
                    </td>
                    <td class="hvac-zip-code-parent-brand-name">
                        {{ data.parentBrandName | lowercase}}
                    </td>
                    <td>
                        {{ data.countyName }}
                    </td>
                    <td>
                        {{ data.cityName }}
                    </td>
                    <td>
                        {{ data.stateName }}
                    </td>
                    <td>
                        {{ data.zipCodeName }}
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </div>
    <div class="hvac-row hvac-between-xs">
        <div class="hvac-zipcode-pagination-control">
            <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
        <div *ngIf="totalPages > 0" class="hvac-zipcode-assignment-action-container">
            <button class="hvac-zipcode-assignment-export-desktop" [hvacButton]="'primary'" (click)="assignSelectedCodes()">
                <span>
                    {{ ( tableTypeIdentifier === 'availableZipcodeTable' ? 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ASSIGN_SELECTED_CODES' : 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.REMOVE_SELECTED_CODES' ) | translate  }}
                </span>
            </button>
            <button class="hvac-zipcode-assignment-export-mobile" [hvacButton]="'primary'" (click)="assignSelectedCodes()">
                <span>{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ASSIGN_SELECTED_CODES' | translate }}</span>
            </button>
        </div>
    </div>
</div>
