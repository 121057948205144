<ng-container *ngIf="(dataSharingPermissions$ | async) as dataSharingPermissions">
    <div class="hvac-row">
        <div class="hvac-col-xs-12">
            <div class="hvac-cp-card">
                <hvac-product-details-card *ngIf="(productData$ | async) as productData; else productDetailLoader"
                    [dealerId]="dealerId"
                    [propertyId]="propertyId"
                    [product]="productData.productInfo"
                    [isRuntimeReportEnabled]="productData.isRuntimeReportEnabled"
                    [isTestEquipmentEnabled]="productData.isTestEquipmentEnabled"
                    [isDeviceConnected]="productData.isWallControlConnected"
                    (onTestEquipmentClick)="handleTestEquipmentClick()">
                </hvac-product-details-card>

                <ng-template #productDetailLoader>
                    <div class="hvac-product-detail-loader">
                        <utc-loading-spinner></utc-loading-spinner>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-container *ngIf="(accordions$ | async) as accordions">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.alerts"
                    [open]="accordions.alerts && dataSharingPermissions?.alerts === true"
                    (toggle)="toggleAccordion$.next('alerts')">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.ALERTS.CONTROL_ALERTS' | translate
                        }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-product-detail-alerts *ngIf="(alertsData$ | async) as alertsData"
                            [data]="alertsData.items"
                            [currentPage]="alertsData.currentPage"
                            [enableFilter]="true"
                            [totalPages]="alertsData.totalPages" (onPageChange)="alertsCurrentPage$.next($event)"
                            (selectedFilter)="handleAlertFilterChange($event)"
                            >
                        </hvac-product-detail-alerts>
                        <utc-loading-spinner *ngIf="isLoadingAlerts$ | async" variant="overlay"></utc-loading-spinner>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>

        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [disabled]="!dataSharingPermissions?.detailedStatus"
                    [open]="accordions.status && dataSharingPermissions?.detailedStatus === true"
                    (toggle)="toggleAccordion$.next('status')">
                    <hvac-accordion-header>{{'CONNECTED_PORTAL.ODU.STATUS.HEADING' | translate}}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <hvac-product-property-list *ngIf="(oduStatusProps$ | async) as oduStatusProps">
                            <hvac-product-property-list-item *ngFor="let prop of oduStatusProps">
                                <hvac-product-property-list-item-label> {{'CONNECTED_PORTAL.ODU.STATUS.' + prop[0] |
                                    translate}} </hvac-product-property-list-item-label>
                                <hvac-product-property-list-item-value>
                                    <utc-svg class="hvac-cp-warning-icon" src="assets/icons/info.svg"
                                        *ngIf="prop[1] === (CharSymbol.DoubleDash)"></utc-svg> {{ prop[1] }}
                                </hvac-product-property-list-item-value>
                            </hvac-product-property-list-item>
                        </hvac-product-property-list>
                        <div class="hvac-status-warnings" *ngIf="isOutOfRangeWarningDisplayed$ | async">
                            <utc-svg class="hvac-cp-warning-icon" src="assets/icons/info.svg"></utc-svg> <span>{{
                                'CONNECTED_PORTAL.PARAMETER_OUT_OF_RANGE' | translate }}</span>
                        </div>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </ng-container>
</ng-container>
