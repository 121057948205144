import { Component, OnDestroy, OnInit } from '@angular/core';
import { ASN_TABLE_COLUMN_HEADERS, PoToastTypes, PurchaseOrderItem } from 'private/app/models/purchase-order-details.model';
import { AppConstants } from 'common/app-constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { PurchaseOrderService } from 'private/app/services/purchase-orders.service';
import { Router } from '@angular/router';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

type shippingForm = {
    freightWeight: FormControl<string | null>,
    freightCarrier: FormControl<string | null>,
    freightAmount: FormControl<string | null>,
    dateShipped: FormControl<string | null>,
    trackingNumber: FormControl<string | null>
}

@Component({
    selector: 'hvac-advanced-shipment',
    templateUrl: './advanced-shipment.component.html',
    styleUrls: ['./advanced-shipment.component.scss']
})
export class AdvancedShipmentComponent implements OnInit, OnDestroy {
    readonly AppConstants = AppConstants;
    readonly TableColumnHeaders = ASN_TABLE_COLUMN_HEADERS;
    public todaysDate: string = '';
    public itemsFormHasValue: boolean = false;
    public formHasMaxValueError: boolean = false;
    public isLoading: boolean;
    ngOnDestroy$ = new Subject();
    public purchaseOrderItemsForm: FormGroup<{
        [key: string]: FormControl<string | number | null>
    }> = new FormGroup({});

    public shippingForm: FormGroup = new FormGroup<shippingForm>({
        freightWeight: new FormControl(''),
        freightCarrier: new FormControl('', Validators.required),
        freightAmount: new FormControl(''),
        dateShipped: new FormControl('', Validators.required),
        trackingNumber: new FormControl('', Validators.required)
    })

    private purchaseOrderLineItems: PurchaseOrderItem[];
    private advanceShipPostData?: {
        poNumber: string;
        poDate: string;
        supplierSoNumber: string;
    };

    constructor(
        public purchaseOrderService: PurchaseOrderService,
        private readonly router: Router,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.todaysDate = new Date().toISOString().substr(0, 10);
        this.purchaseOrderService.activePurchaseOrder$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((poItem) => Object.keys(poItem).length > 0)
        ).subscribe((poItem) => {
            const { pONumber: poNumber, pOCreationDate: poDate, supplierSoNumber } = poItem;

            this.advanceShipPostData = {
                poNumber,
                poDate,
                supplierSoNumber
            };

            this.purchaseOrderLineItems = poItem.lines;

            poItem.lines.forEach((poLineItem) => {
                const maxValue = Math.floor(parseInt(poLineItem.openQuantity, 10));
                this.purchaseOrderItemsForm.addControl(poLineItem.pOLineNumber, new FormControl<number | null>(0, Validators.max(maxValue)));
            });
        });

        this.purchaseOrderItemsForm.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((form) => {
            this.itemsFormHasValue = Object.keys(form).some((field) => Boolean(form[field]));
        });
        this.purchaseOrderItemsForm.statusChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(() => {
            this.formHasMaxValueError = Boolean(!this.purchaseOrderItemsForm.valid);
        });
    }

    onSubmit() {
        this.toastService.removeAll();
        this.isLoading = true;
        const { dateShipped, ...rest } = this.shippingForm.value;
        const formattedDate = dateShipped.replace(/-/g, '');

        const asnLines = this.purchaseOrderLineItems.map((lineItem: PurchaseOrderItem) => {
            const unitsShipped = this.purchaseOrderItemsForm.get(lineItem.pOLineNumber)?.value;

            const controlValue = {
                poLineItemNumber: lineItem.pOLineNumber,
                sapMaterialNumber: lineItem.sapMaterialNumber,
                supplierPartNumber: lineItem.supplierPartNumber,
                originalQty: lineItem.pOOrderQuantity,
                qtyOpen: lineItem.openQuantity,
                unitOfMeasure: 'EA',
                asnDate: formattedDate,
                unitsShipped: unitsShipped
            };

            return controlValue;
        }).filter((asn) => Number(asn.unitsShipped) !== 0);


        const data = {
            advanceShipNoticeRequest: {
                ...this.advanceShipPostData,
                dateShipped: formattedDate,
                ...rest,
                asnLines
            }
        };

        this.purchaseOrderService.sendAdvanceShipNotice(data).pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(
            () => {
                this.router.navigate(['./trade-partners'], { queryParams: { [PoToastTypes.ASN]: this.advanceShipPostData?.poNumber } });
            }, () => {
                this.isLoading = false;
                this.toastService.add({
                    bgColor: '#F8F8F8',
                    closeable: true,
                    content: this.translate.instant('TRADE_PARTNERS.API_ERROR'),
                    id: 'trade-partners-api-error',
                    theme: 'error'
                });
            }
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
