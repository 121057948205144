import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface DetailSection {
    title: string;
    container: HTMLElement;
    order: number;
}

@Injectable({ providedIn: 'root' })
export class DetailSectionService {
    public sections$;

    private sectionsSubject = new BehaviorSubject<DetailSection[]>([] as DetailSection[]);

    constructor() {
        this.sections$ = this.sectionsSubject.asObservable();
    }

    registerSection(section: DetailSection) {
        this.sectionsSubject.next(
            [...this.sectionsSubject.value, section]
                .sort((sectionA, sectionB) => sectionA.order - sectionB.order)
                .filter((detailSection, index, detailSections) => index === 0 || detailSections[index - 1].title !== detailSection.title)
        );
    }

    deregisterSection(removeSection: DetailSection) {
        const updatedSections = this.sectionsSubject.value
            .filter((section) => section.container !== removeSection.container);
        this.sectionsSubject.next(updatedSections);
    }
}
