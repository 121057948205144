<div class="hvac-recent-activity-banner hvac-row" [ngClass]="'hvac-recent-activity-' + alert.error.severity | lowercase">
    <div class="hvac-recent-activity-section hvac-recent-activity-severity hvac-col-xs-12 hvac-col-lg-2">
        <div class="hvac-recent-activity-title hvac-h5">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RECENT_ACTIVITY_BANNER.RECENT_ACTIVITY' | translate }}</div>
        <div class="hvac-p-sm hvac-fw-rg">{{alert.dateTime | date:AppConstants.dateFormat }}</div>
        <div class="hvac-p-sm hvac-fw-rg">{{alert.dateTime | date:AppConstants.timeFormat }}</div>
    </div>
    <div class="hvac-recent-activity-section hvac-col-xs-6 hvac-col-lg-2 hvac-col-lg-offset-1">
        <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RECENT_ACTIVITY_BANNER.MODEL_NO' | translate }}</div>
        <div class="hvac-p-sm">{{alert.product.modelNo}}</div>
    </div>
    <div class="hvac-recent-activity-section hvac-col-xs-6 hvac-col-lg-2">
        <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RECENT_ACTIVITY_BANNER.SERIAL_NO' | translate }}</div>
        <div class="hvac-p-sm">{{alert.product.serialNo}}</div>
    </div>
    <div class="hvac-recent-activity-section hvac-col-xs-12 hvac-col-lg-4 hvac-col-lg-offset-1">
        <div class="hvac-p-sm hvac-fw-sb">{{alert.error.code}}</div>
        <div class="hvac-p-sm hvac-fw-rg">{{alert.error.description}}</div>
    </div>
    <button class="hvac-cp-close-btn" (click)="handleClose()"><utc-svg src="assets/icons/delete-normal.svg"></utc-svg></button>
</div>
