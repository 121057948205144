import { Component, OnInit } from '@angular/core';
import { ErrorService } from 'common/content/services/error.service';

@Component({
    selector: 'hvac-error-redirect',
    templateUrl: './error-redirect.component.html',
    styleUrls: ['./error-redirect.component.scss']
})
export class ErrorRedirectComponent implements OnInit {
    constructor(
        private errorService: ErrorService
    ) { }

    ngOnInit(): void {
        this.errorService.errorStatus$.next(404);
    }
}
