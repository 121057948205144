<ng-template #controlType let-item="item">
    <div class="hvac-p-sm">{{ item.controlType }}</div>
</ng-template>

<ng-template #serialNo let-item="item">
    <div class="hvac-p-sm">{{ item.serialNo }}</div>
</ng-template>

<ng-template #esn let-item="item">
    <div class="hvac-p-sm">{{ item.esn }}</div>
</ng-template>

<ng-template #remove let-item="item">
    <div hvacButton="default" size="small" (click)="onRemoveQueueItem(item)">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.ACTIONS.REMOVE' | translate }}</div>
</ng-template>

<div class="hvac-cp-list-container">
    <hvac-toast [name]="toastOutlet"></hvac-toast>
    <utc-loading-spinner *ngIf="showSpinner"></utc-loading-spinner>

    <ul class="hvac-cp-list">
        <ng-container *ngIf="items?.length === 0">
            <li class="hvac-cp-list-item hvac-cp-no-data">
                <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
            </li>
        </ng-container>

        <ng-container *ngIf="items">
            <li class="hvac-cp-list-item" *ngFor="let item of items; trackBy:trackByItem">
                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{
                        'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.CONTROL_TYPE' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="controlType" [ngTemplateOutletContext]="{item: item}">
                        </ng-container>
                    </div>
                </div>
                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{
                        'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.SERIAL_NUMBER' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="serialNo" [ngTemplateOutletContext]="{item: item}">
                        </ng-container>
                    </div>
                </div>
                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.ESN' |
                        translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="esn" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>
                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-col-xs-12 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="remove" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>
            </li>
        </ng-container>

        <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
    </ul>
    <table class="hvac-cp-list">
        <thead>
            <tr class="hvac-cp-list-table-header">
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.CONTROL_TYPE' | translate }}
                </th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.SERIAL_NUMBER' | translate }}
                </th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.HEADINGS.ESN' | translate }}</th>
                <th class="hvac-label"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="items?.length === 0">
                <tr class="hvac-cp-list-row hvac-cp-list-item">
                    <td class="hvac-cp-field-group hvac-cp-no-data" colspan="6">
                        <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="items">
                <tr class="hvac-cp-list-row hvac-cp-list-item" *ngFor="let item of items; trackBy:trackByItem">
                    <td class="hvac-cp-field-group hvac-cp-control-type-group">
                        <ng-container [ngTemplateOutlet]="controlType" [ngTemplateOutletContext]="{item: item}">
                        </ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-serial-group">
                        <ng-container [ngTemplateOutlet]="serialNo" [ngTemplateOutletContext]="{item: item}">
                        </ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-esn-group">
                        <ng-container [ngTemplateOutlet]="esn" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-rmove-group">
                        <ng-container [ngTemplateOutlet]="remove" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                </tr>
            </ng-container>

            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
        </tbody>
    </table>
    <div class="hvac-cp-list-footer">
        <div class="hvac-cp-list-footer-pagination">
            <hvac-pagination [totalPages]="totalPages" [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>

        <div class="hvac-cp-list-footer-export">
            <button class="hvac-cp-action-button" hvacButton="primary" (click)="handleExportProperties()" [disabled]="items?.length === 0">
                {{ 'CONNECTED_PORTAL.CUSTOMERS.ACTIONS.EXPORT_CUSTOMER_LIST' | translate }}
            </button>
        </div>
    </div>
</div>

