import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { IdmService } from 'private/app/services/idm.service';
import { AuthState } from 'common/store/auth/auth.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Company, AuthBrands } from 'private/app/models/company.model';
import { User } from 'common/models';
import { DateService } from 'common/services/date/date.service';

@Component({
    selector: 'hvac-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanyInfoComponent implements OnInit {
    @Select(AuthState.user) user$: Observable<User>;
    public authorisedBrands$ = new BehaviorSubject<Array<string>>([]);
    public companyServices$ = new BehaviorSubject<Array<string>>([]);
    public company: Company;
    public isDistributor = false;
    public isDealer = false;

    public companyInfoForm: UntypedFormGroup = new UntypedFormGroup({
        address1: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        address2: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        city: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        companyId: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        contactName: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        country: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        companyName: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        email: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        fax: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        phone: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        state: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        zip: new UntypedFormControl({
            value: '',
            disabled: true
        }),

        companyCategory: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        companyType: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        hours: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        language: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        mobile: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        tollFree: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        parentCompany: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        payer: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        payerCustomerId: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        soldTo: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        shipToNumber: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        showOnDistributorLocator: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        isLegalEntity: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        // should be “Location” options:  ‘main’ or ‘branch’
        priceList: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        priceGroup: new UntypedFormControl({
            value: '',
            disabled: true
        })
    });

    constructor(
        public dateService: DateService,
        private readonly idmService: IdmService
    ) { }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.idmService.userInfo$.subscribe((res: any) => {
            const companyId = res?.companies && res.companies.length ? res.companies[0].id : '';
            const companyType = res?.companies && res.companies.length ? res.companies[0].companyType : '';

            switch (companyType.code) {
                case 'DDT':
                    this.isDistributor = true;
                    break;
                case 'DCR':
                    this.isDealer = true;
                    break;
                default:
                    break;
            }
            this.idmService.getCompany(companyId).subscribe((company: Company) => {
                this.company = company;
                this.getBrandsListArray(company.authorizedBrands);
                this.companyServices$.next(company.companyServices);
                this.setFormValues(company);
            });
        });
    }

    getBrandsListArray(arr: AuthBrands[]) {
        const brands = arr.sort((a, b) => a.name.localeCompare(b.name)).map((brand) => brand.name);
        this.authorisedBrands$.next(brands);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setFormValues(company: any) {
        this.companyInfoForm.controls.address1.setValue(company.address.addressLine1 || '');
        this.companyInfoForm.controls.address2.setValue(company.address.addressLine2 || '');
        this.companyInfoForm.controls.city.setValue(company.address.city || '');
        this.companyInfoForm.controls.contactName.setValue(company.contactName || '');
        this.companyInfoForm.controls.country.setValue(company.address.countryCode || '');
        this.companyInfoForm.controls.companyName.setValue(company.name || '');
        this.companyInfoForm.controls.companyId.setValue(company.id || '');

        this.companyInfoForm.controls.email.setValue(company.emailAddress || '');
        this.companyInfoForm.controls.payerCustomerId.setValue(company.payerCustomerId || '');
        this.companyInfoForm.controls.phone.setValue(company.phoneNumber || '');
        this.companyInfoForm.controls.state.setValue(company.address.regionCode || '');
        this.companyInfoForm.controls.zip.setValue(company.address.postalCode || '');
        this.companyInfoForm.controls.companyType.setValue(company.companyType ? company.companyType.name : '');
        this.companyInfoForm.controls.language.setValue(company.language || '');
        this.companyInfoForm.controls.payer.setValue(company.payer || '');
        this.companyInfoForm.controls.soldTo.setValue(company.soldToCustomerId || '');
        this.companyInfoForm.controls.shipToNumber.setValue(company.shipToNumber || '');
        this.companyInfoForm.controls.priceList.setValue(company.priceList || '');
        this.companyInfoForm.controls.priceGroup.setValue(company.priceGroup || '');
    }
}
