import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ProductDetails } from 'common/models';
import { FetchCatalogProductAttributes } from 'common/store/catalog/catalog.actions';
import { ProductsState } from 'common/store/products/products.state';
import { ResourceRepositoryModel } from 'common/store/repository/repository.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';
import { register } from 'swiper/element/bundle';
register();

@Component({
    selector: 'utc-carousel-section',
    templateUrl: './carousel-section.component.html',
    styleUrls: ['./carousel-section.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CarouselSectionComponent implements OnInit, OnDestroy {
    @Input() sectionTitle = '';
    @Input() sectionOrder: string = '0';
    @Input() productRelationship = '';
    @Input() carouselFamily = '';

    @Select(ProductsState.detailsRepository) detailRepository$: Observable<ResourceRepositoryModel<ProductDetails>>;
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;

    ngOnDestroy$ = new Subject();
    public centeredSlidesConfig = false;
    public enablePagination = false;
    public swiperNavigation = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relationshipProductsDetails$: Observable<any[]>;

    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    relationshipProducts$ = combineLatest([this.attributes$]).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map(([attributes]: any) => (attributes && attributes.relationships && attributes.relationships[this.productRelationship] ?
            attributes.relationships[this.productRelationship]
            : []))
    );

    swiperParams: Record<string, unknown> = {
        direction: 'horizontal',
        keyboard: true,
        mousewheel: false,
        scrollbar: false,
        centeredSlides: false,
        pagination: false,
        navigation: null,
        observeSlideChildren: true,
        freeMode: {
            enabled: true,
            sticky: true
        },
        breakpoints: {
            100: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20
            }
        }
    };

    constructor(
        private readonly store: Store,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef
    ) {}

    ngOnInit() {
        this.relationshipProductsDetails$ = combineLatest([this.detailRepository$, this.relationshipProducts$]).pipe(
            map(([detailRepository, relationshipProducts]) => relationshipProducts.map((product: string) => detailRepository[product] || { type: 'placeholder' }))
        );

        this.relationshipProducts$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((products) => {
                if (products.length) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                    products.map((product: string) => this.store.dispatch(new FetchCatalogProductAttributes(product)));

                    this.swiperParams.centeredSlides = products.length === 1;
                    this.swiperParams.pagination = products.length > 3;
                    this.swiperParams.navigation = {
                        nextEl: `.${this.carouselFamily} .hvac-carousel-next`,
                        prevEl: `.${this.carouselFamily} .hvac-carousel-prev`
                    };

                    this.swiperParams = { ...this.swiperParams };
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.sectionTitle,
            order: parseInt(this.sectionOrder, 10),
            container: this.element.nativeElement
        };
    }
}
