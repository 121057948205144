<section class="hvac-dealer-locator-header">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>

<div class="hvac-dealer-locator">
    <section class="hvac-content-container">
        <div class="hvac-tab-container">
            <hvac-tab-bar>
                <a class="hvac-tab-item" routerLink="details" routerLinkActive="hvac-active"
                    queryParamsHandling="merge">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DETAILS' | translate }}</a>
                <a class="hvac-tab-item" routerLink="zipcode" routerLinkActive="hvac-active"
                    queryParamsHandling="merge">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.ZIP_CODE_ASSIGNMENTS' |
                    translate }}</a>
                <a class="hvac-tab-item" *ngIf="thirdPartyRatingsTab$ | async" routerLink="ratings"
                    routerLinkActive="hvac-active" queryParamsHandling="merge">{{
                    'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.THIRD_PARTY_RATINGS' | translate }}</a>
            </hvac-tab-bar>
            <button [hvacButton]="'text'" (click)="backToCompanyDetails()">
                <span>{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.BACK_TO_COMPANY_DETAILS' | translate }}</span>
            </button>
        </div>
        <section class="hvac-dealer-locator-content">
            <router-outlet></router-outlet>
        </section>
    </section>
</div>
