import { CompanyType } from './../../models/account-admin-search';
import { Company } from './../../models/company.model';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { AccountAdminFormResultMap } from 'private/app/models/account-admin-search';
import { Option } from 'common/components/select/select.component';
import { FormControl, FormGroup } from '@angular/forms';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { BehaviorSubject } from 'rxjs';
import { AccountAdminCompanyCreateService, CompanyDesignation } from 'private/app/services/account-admin/account-admin-company-create.service';


@Component({
    selector: 'hvac-company-designation',
    templateUrl: './company-designation.component.html',
    styleUrls: ['./company-designation.component.scss']
})
export class CompanyDesignationComponent implements OnInit {
    @Input() group: FormGroup;
    public formData: AccountAdminFormResultMap;
    public company: Company;
    public idmUserRole: IdmUserRoles;
    public companyTypeData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    companyCategory : Option[] ;
    companyType : Option[];

    public companyDesignationForm: FormGroup = new FormGroup({
        companyCategoryControl: new FormControl(''),
        companyTypeControl: new FormControl('')
    })

    private designation: CompanyDesignation = {
        companyCategory: '',
        companyType: ''
    }

    constructor(
        private translate: TranslateService,
        private readonly companyCreateService: AccountAdminCompanyCreateService
    ) {}

    ngOnInit(): void {
        this.companyCreateService.formData.subscribe((formData) => {
            this.formData = formData;
            this.companyCategory = this.formData.companyTypes.filter((category) => category.canCreate).map((companyCategory) => ({
                name: companyCategory.name,
                value: companyCategory.code
            }));

            this.companyCategory.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.SELECT_CATEGORY'),
                value: ''
            });
        });


        this.companyDesignationForm.controls.companyCategoryControl.valueChanges.subscribe((value: Option[]) => {
            if (value) {
                this.resetDesignation();
                this.companyDesignationForm.controls.companyTypeControl.setValue('');
                this.designation.companyCategory = value[0].name;
                this.filterCompanyByCategory(value[0].value);
                this.companyCreateService.fetchParentCompany(value[0].name);
            }
        });

        this.companyDesignationForm.controls.companyTypeControl.valueChanges.subscribe((value: Option[]) => {
            if (value.length === 0) {
                return;
            }

            let optionCompanyType: CompanyType[] = [];
            if (!Array.isArray(value)) {
                optionCompanyType = this.formData.companyTypes.find((category) => category.name.toLowerCase() === this.designation.companyCategory.toLowerCase())?.companyTypes?.filter((companyType) => companyType.name.toLowerCase() === String(value).toLowerCase()) || [];
            }


            this.designation.companyType = Array.isArray(value) ? value[0].value : optionCompanyType[0].code;
            if (value) {
                this.companyCreateService.fetchCompanyServiceData(Array.isArray(value) ? value[0].name : value);
                this.companyCreateService.designation$.next(this.designation);
            }
        });
    }

    filterCompanyByCategory(code: string) {
        this.companyCreateService.designation$.next(this.designation);
        const foundCompanyTypes: CompanyType[] = this.formData.companyTypes.find((category) => category.code.toLowerCase() === code.toLowerCase())?.companyTypes || [];
        if (foundCompanyTypes.length === 1) {
            this.designation.companyType = foundCompanyTypes[0].code;
            this.companyDesignationForm.controls.companyTypeControl.setValue(foundCompanyTypes[0].name, { eventEmit: false });
            this.companyDesignationForm.controls.companyTypeControl.disable();
            this.companyTypeData$.next([]);
        }
        else {
            this.companyDesignationForm.controls.companyTypeControl.enable();
        }
        const companyType: Option[] = foundCompanyTypes.map((companytype) => ({
            name: companytype.name,
            value: companytype.code
        })) || [];

        companyType.unshift({
            name: this.translate.instant('ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.SELECT_COMPANY'),
            value: ''
        });
        this.companyTypeData$.next(companyType);
    }

    private resetDesignation() {
        this.designation = {
            companyCategory: '',
            companyType: ''
        };
    }
}
