import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { SearchControlStatusResponse } from 'private/app/models/add-control.model';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

export const QUERY_DEALER_CONTROL_STATUS = gql`
    query queryDealerControlStatus($params:DealerControlInput!) {
        queryDealerControlStatus(params: $params) {
            data {
                status,
                customerAddress {
                    address1
                    address2
                    city
                    state
                    country
                    zipCode
                }
            }
        }
      }
`;

export const ADD_DEALER_CONTROL = gql`
    mutation addDealerControl($params:AddDealerControlInput!) {
        addDealerControl(params: $params) {
            code
            message
        }
    }
`;

export const REMOVE_QUEUED_CONTROL_BY_SERIAL_NO = gql`
    mutation removeQueuedControlBySerialNo($id: String!, $dealerId: String) {
        removeQueuedControlBySerialNo(id: $id, dealerId: $dealerId) {
            code
            message
        }
    }
`;

export const REMOVE_CONNECTED_CONTROL_BY_SERIAL_NO = gql`
    mutation removeConnectedControlBySerialNo($id: String!, $dealerId: String) {
        removeConnectedControlBySerialNo(id: $id, dealerId: $dealerId) {
            code
            message
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class DealerControlService extends ConnectedPortalBaseService {
    constructor(public injector: Injector) {
        super(injector);
    }

    public queryDealerControlStatus(dealerId: string, serialNoOrEsn: string): Observable<SearchControlStatusResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealerControlStatus').query<{ queryDealerControlStatus: SearchControlStatusResponse }>({
                query: QUERY_DEALER_CONTROL_STATUS,
                variables: {
                    params: {
                        dealerId,
                        serialNoOrEsn
                    }
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDealerControlStatus } = value.data;

                    return queryDealerControlStatus;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public addDealerControl(dealerId: string, serialNoOrEsn: string | undefined, controlState: string | undefined, disableCustomerNotification: boolean) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('addDealerControl').mutate<{ addDealerControl: DefaultUpdateResponse }>({
                mutation: ADD_DEALER_CONTROL,
                variables: {
                    params: {
                        dealerId,
                        serialNoOrEsn,
                        controlState,
                        disableCustomerNotification
                    }
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.addDealerControl)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public removeQueuedControl(serialNo: string, dealerId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('removeQueuedControlBySerialNo').mutate<{ removeQueuedControlBySerialNo: DefaultUpdateResponse }>({
                mutation: REMOVE_QUEUED_CONTROL_BY_SERIAL_NO,
                variables: {
                    id: serialNo,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.removeQueuedControlBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public removeConnectedControl(id: string, dealerId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('removeConnectedControlBySerialNo').mutate<{ removeConnectedControlBySerialNo: DefaultUpdateResponse }>({
                mutation: REMOVE_CONNECTED_CONTROL_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.removeConnectedControlBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
