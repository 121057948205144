import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountAdminCountriesEntity } from 'private/app/models/account-admin-search';
import { UserDetails, UserDetailsEntity } from 'private/app/models/user.model';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Option } from '../../../../../common/components/select/select.component';
import { ActivatedRoute } from '@angular/router';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'common/services/toast.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Select } from '@ngxs/store';

type SearchCompany = {
    userCreateStep: string,
    company: CompanyResultDataResponse
}

@Component({
    selector: 'hvac-create-user-details',
    templateUrl: './create-user-details.component.html',
    styleUrls: ['./create-user-details.component.scss']
})
export class CreateUserDetailsComponent implements OnInit {
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;
    @Input() company: CompanyResultDataResponse;
    @Input() cloneUserData: UserDetailsEntity;
    @Output() userDetails = new EventEmitter<UserDetails>();
    @Output() createUserChangePage = new EventEmitter<{
        userCreateStep: string,
        company : CompanyResultDataResponse
    }>();

    public user: UserDetailsEntity;
    public userAddress1: string ='';
    public stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public userState: string;
    public userCountry: string;
    public createUserDetails: UserDetails;
    public brandFamily: string | null;
    public brandFamilyValue: string = 'ICP';

    constructor(
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        public commonUtils: CommonUtilsService,
        private userApiService: UserApiService,
        public userAdminService: UserAdminService,
        private route: ActivatedRoute,
        private toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.accountAdminCompanyUtilsService.filterListUserUnderSelectedCompany$.subscribe((data: UserDetailsEntity[]) => {
            this.user = this.cloneUserData || (data.length > 0 ? data[0] : { ...this.user });

            this.commonUtils.getCountriesEntity().subscribe((countryList) => {
                const optionList: Option[] = [];
                countryList.forEach((country: AccountAdminCountriesEntity) => {
                    optionList.push({
                        name: country.name,
                        value: country.code
                    });
                });
                this.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(optionList));
            });

            if (this.route.snapshot.params.id) {
                this.userApiService.getUserDetailsById(this.route.snapshot.params.id).subscribe(() => {
                    if (this.company) {
                        this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.company.country)?.name || '';
                        this.userAdminService.userEditForm.controls.userCountry.setValue(this.userCountry);
                        this.getStateListByCountry(this.company.country || '');
                    }
                }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('CreateUserDetailsComponent-ngOnInit', error.message));
            }
            this.initializeUiData();
        });

        this.userAdminService.userEditForm.controls.userCountry.valueChanges.subscribe((selectedCountry: string) => {
            if (Array.isArray(selectedCountry)) {
                this.userAdminService.userEditForm.controls.userState.setValue('', { emitEvent: true });
                this.userAdminService.userEditForm.controls.userState.markAsDirty();

                this.getStateListByCountry(selectedCountry[0].value);
            }
            else {
                const countrySelection = this.countryOptionData$.value.find((country) => country.value === selectedCountry);
                if (countrySelection) {
                    this.getStateListByCountry(countrySelection.value);
                }
            }
        });
        this.userBrandFamily$.subscribe((brand) => this.brandFamily = brand);
    }

    initializeUiData() {
        if (this.cloneUserData) {
            this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.user.userCountry)?.name || '';
            this.userAdminService.userEditForm.controls.userCountry.setValue(this.userCountry);
            this.user = this.cloneUserData;

            this.userAdminService.userEditForm.controls.employerName.setValue(this.user.employerName);
            this.userAdminService.userEditForm.controls.companyId.setValue(this.user.companyId);
            this.userAdminService.userEditForm.controls.userAddress1.setValue(this.user.userAddress1);
            if (this.user && this.user.userAddress2) {
                this.userAdminService.userEditForm.controls.userAddress2.setValue(this.user.userAddress2);
            }
            this.userAdminService.userEditForm.controls.userCity.setValue(this.user.userCity);
            this.userAdminService.userEditForm.controls.userPostalCode.setValue(this.user.userPostalCode);
            this.userAdminService.userEditForm.controls.registrationAction.setValue('Select');

            this.commonUtils.getStateByCountry(this.user.userCountry).subscribe((stateList) => {
                this.stateOptionData$.next(stateList);
                this.userState = this.stateOptionData$.value.find((state) => state.value === this.user.userState)?.name || '';
                this.userAdminService.userEditForm.controls.userState.setValue(this.userState);
                this.updateUIWithUser();
            });
        }
        else {
            this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.company.country)?.name || '';
            this.commonUtils.getStateByCountry(this.company.country).subscribe((stateList) => {
                this.stateOptionData$.next(stateList);
                this.userState = this.stateOptionData$.value.find((state) => state.value === this.company.state)?.name || '';
                this.userAdminService.userEditForm.controls.userState.setValue(this.userState);
                this.updateUIWithUser();
            });
        }
    }

    updateUIWithUser() {
        this.userAdminService.userEditForm.setValue({
            firstName: '',
            lastName: '',
            userID: this.user.userID || '',
            userTitle: this.user.userTitle || '',
            employerName: this.company.companyName || '',

            companyName: this.user.employerName || '',
            companyId: this.company.hvacCompanyId || '',
            hvacPin: this.user.hvacPin || '',

            userAddress1: this.company.address1 || '',
            userAddress2: this.company.address2 || '',
            userCity: this.company.city || '',

            userState: this.userState || '',
            userPostalCode: this.company.postalCode || '',
            userCountry: this.userCountry || '',

            userPhone: '',
            userCellPhone: '',
            userFax: this.user.userFax || '',

            email: '',
            contactOnly: this.user.contactOnly || '',

            registrationAction: 'Select',
            comments: '',
            lastLoginDate: this.user.lastLoginDate || ''
        }, { emitEvent: true });
    }

    onCountryChange(option: Option[]) {
        this.user.userCountry = option[0]?.value;
    }

    onStateChange(option: Option[]) {
        this.user.userState = option[0]?.value;
    }

    setContactOnly(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.user.contactOnly = input.checked;
        }
    }

    onClickCreateUserChangePage(userCreateStep3: boolean) {
        const emptyCompany: CompanyResultDataResponse = {
            hvacCompanyId: 0,
            soldTo: '',
            shipTo: '',
            payer: '',
            companyName: '',
            state: '',
            city: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            brandsName: '',
            companyType: '',
            country: '',
            fax: '',
            parentBrandsName: '',
            postalCode: '',
            warranty: ''
        };

        if (this.cloneUserData) {
            this.company = emptyCompany;
        }

        const createUserObj : SearchCompany = {
            company: this.company,
            userCreateStep: 'step4_userRolesFeatures'
        };

        this.userDetails.emit(this.userAdminService.mapCreateUserDetails());

        if (!userCreateStep3) {
            if (this.cloneUserData) {
                createUserObj.userCreateStep = 'ReadOnlyUserInfo';
            }
            else {
                createUserObj.userCreateStep = 'step2_registeredUsersInCompany';
            }
        }
        this.createUserChangePage.emit(createUserObj);
    }

    private getStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateByCountry(selectedCountry).subscribe((stateList) => {
            this.stateOptionData$.next(stateList);
            this.userState = this.stateOptionData$.value.find((state) => state.value === this.company.state)?.name || '';
            this.userAdminService.userEditForm.controls.userState.setValue(this.userState);
        });
    }
}
