import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select/select.component';
import { IdToken } from 'common/models';
import { BrandService } from 'common/services/brand.service';
import { OktaService } from 'common/services/okta/okta.service';
import { ToastService } from 'common/services/toast.service';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { BrandedProductOffering, DealerLocatorEnrollmentList, DealerLocatorEnrollments, DealerProductOfferings, ProductOffering, ProductOfferings } from 'private/app/models/manage-dealer-locator';
import { AccountAdminEditService } from 'private/app/services/account-admin/account-admin-edit.service';
import { ManageDealerLocatorService } from 'private/app/services/manage-dealer-locator.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-dealer-locator-details',
    templateUrl: './dealer-locator-details.component.html',
    styleUrls: ['./dealer-locator-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DealerLocatorDetailsComponent implements OnInit {
    public dealerLocatorListData: DealerLocatorEnrollmentList = {
        dealerLocatorEnrollments: [],
        availableZipCodes: [],
        assignedZipCodes: [],
        locatorRatings: [],
        companyId: '',
        isFADEnabled: false
    }

    public form: UntypedFormArray;
    public loading: boolean;
    public rankings: Option[] = [];
    public enableSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private isLocatorAdmin: boolean;
    private productOfferings: DealerProductOfferings[] = [];
    private formGroup: UntypedFormGroup[];
    private ngOnDestroy$ = new Subject();

    constructor(
        private dealerLocatorService: ManageDealerLocatorService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private toastService: ToastService,
        public brandService: BrandService,
        private translateService: TranslateService,
        public readonly accountAdminEditService: AccountAdminEditService,
        private oktaService: OktaService
    ) {}

    ngOnInit(): void {
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.isLocatorAdmin = Boolean(res?.claims?.idm_user_roles?.includes(IdmUserRoles.LOCATORADMINUPDATES));
        });
        this.rankings = this.getRankingOptions();
        this.dealerLocatorService.data$.pipe(skipWhile((response) => !response.companyId), takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            this.dealerLocatorListData = data;
            this.getProductOfferings();
        });
    }

    getValueAt(index: number) {
        return this.dealerLocatorListData.dealerLocatorEnrollments[index];
    }

    toggleDealerLocator(company: DealerLocatorEnrollments) {
        company.isEnrolled = !company.isEnrolled;
    }

    toggleResultRow(company: DealerLocatorEnrollments) {
        company.isCollapsed = !company.isCollapsed;
    }

    getControlsArray(group: AbstractControl) {
        const offeringArray: UntypedFormArray = group.get('productOfferings') as UntypedFormArray;

        return offeringArray.controls as UntypedFormControl[];
    }

    rankingFormGroup(group: AbstractControl): UntypedFormGroup {
        const ranking = group?.get('ranking') as UntypedFormGroup;

        return ranking;
    }

    trackByFn(index: number, item: AbstractControl) {
        return item.value[index];
    }

    dropdownChange(enrollment: DealerLocatorEnrollments, group: UntypedFormGroup) {
        const rankingVal = group.get('rankingDropdown')?.value[0]['name'];
        enrollment.ranking = Number(rankingVal);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    onOfferingChange(offering: DealerProductOfferings) {
        offering.isSelected = !offering.isSelected;
        if (!offering.isSelected) {
            this.removeOffering(offering);
        }
    }

    onSubmit(form: UntypedFormArray, dealerLocatorDetails: DealerLocatorEnrollmentList) {
        const body = this.dealerLocatorService.getEnrollmentsPostRequestBody(form, dealerLocatorDetails);
        this.loading = true;
        this.dealerLocatorService.postDealerEnrollmentData(body).pipe(takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            this.enableSubmit$.next(false);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.loading = false;
            if (data.status === 'Success') {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_LOCATOR_POST_SUCCESS_MESSAGE'),
                    theme: 'success',
                    id: 'DEALER_LOCATOR_POST_SUCCESS_MESSAGE',
                    closeable: true,
                    autoClose: true
                });
                this.dealerLocatorService.refreshEnrollmentData$.next(true);
            }
        });
    }

    cancelAction() {
        this.dealerLocatorService.backAction.next(true);
    }

    private getRankingOptions(): Option[] {
        const optionList: Option[] = [];
        [...Array(5).keys()].map((index) => {
            optionList.push(
                {
                    name: `${index + 1}`,
                    value: `Option${index + 1}`
                }
            );

            return index;
        });

        optionList.unshift(
            {
                name: this.translateService.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.SELECT_RANKING'),
                value: '0'
            }
        );


        return optionList;
    }

    private removeOffering(offering:DealerProductOfferings) {
        const index: number = this.productOfferings.indexOf(offering);
        if (index !== -1) {
            this.productOfferings.splice(index, 1);
        }
    }

    private createForm(dealerLocatorList: DealerLocatorEnrollmentList): UntypedFormArray {
        this.formGroup = dealerLocatorList.dealerLocatorEnrollments.map((enrollment: DealerLocatorEnrollments) => this.formBuilder.group({
            isEnrolled: new UntypedFormControl(enrollment.isEnrolled),
            callTrackingNumber: new UntypedFormControl(
                {
                    value: enrollment.callTrackingNumber,
                    disabled: !this.isLocatorAdmin
                }
            ),
            addCallTrackingNumber: new UntypedFormControl(
                {
                    value: enrollment.addCallTrackingNumber,
                    disabled: !this.isLocatorAdmin
                }
            ),
            productOfferings: new UntypedFormArray(enrollment.productOfferings.map((obj) => new UntypedFormControl(obj))),
            ranking: this.formBuilder.group({ rankingDropdown: new UntypedFormControl(enrollment.ranking === 0 ? this.translateService.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.SELECT_RANKING') : enrollment.ranking.toString()) }),
            url: new UntypedFormControl(
                {
                    value: enrollment.url,
                    disabled: !this.isLocatorAdmin
                }
            ),
            firstYearInHVAC: new UntypedFormControl(enrollment.firstYearInHVAC === 0 ? '' : enrollment.firstYearInHVAC.toString())
        }));

        this.formGroup.forEach((dynamicData) => {
            dynamicData.valueChanges.subscribe((value) => {
                if (value) {
                    this.enableSubmit$.next(true);
                }
            });
        });

        return this.formBuilder.array(this.formGroup);
    }


    private isOfferingMarked(offeringData: ProductOffering, productOfferings: ProductOfferings[]): boolean {
        return Boolean(productOfferings.find((offering) => String(offeringData.productOfferingId) === String(offering.productOfferingId)));
    }

    private getProductOfferings() {
        this.dealerLocatorService.getBrandedProductOfferings().pipe(skipWhile((response) => !response.length), takeUntil(this.ngOnDestroy$)).subscribe((data: BrandedProductOffering[]) => {
            this.dealerLocatorListData.dealerLocatorEnrollments.map((enrollment) => {
                const brandedEnrolment = data.find((offering) => offering.brandName.toLowerCase() === enrollment.dealerBrandLocatorNm.toLowerCase());
                if (brandedEnrolment) {
                    enrollment.productOfferings = brandedEnrolment.productOfferings.map((offeringData) => ({
                        productOfferingId: offeringData.productOfferingId,
                        dealerLocatorEnrollmentId: enrollment.Id ? enrollment.Id : '',
                        productNm: offeringData.productNm,
                        companyId: this.dealerLocatorListData.companyId ? this.dealerLocatorListData.companyId : '',
                        isSelected: this.isOfferingMarked(offeringData, enrollment.productOfferings)
                    }));
                }
                else {
                    enrollment.productOfferings = [];
                }


                return enrollment;
            });
            this.form = this.createForm(this.dealerLocatorListData);
        });
    }
}
