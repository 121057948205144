<section class="hvac-cool-calc-terms-popup-container">
    <div class="hvac-cool-calc-close-button-container">
        <button class="hvac-cool-calc-terms-close" hvacButton="secondary" (click)="closeAction()">
            <utc-svg src="assets/icons/close.svg"></utc-svg>
            <span class="hvac-accessible-text">{{ 'DASHBOARD.NEXT.CLOSE' | translate }}</span>
        </button>
    </div>
    <div class="hvac-cool-calc-terms-page-head">
        <h1 class="hvac-cool-calc-terms-page-title">{{ 'COOL_CALC_TERMS_OF_USE.PAGE_TITLE' | translate }}</h1>
    </div>
    <hr/>
    <div class="hvac-cool-calc-scroll-container">
        <p class="hvac-cool-calc-terms-page-description" [innerHTML]="'COOL_CALC_TERMS_OF_USE.PAGE_DESCRIPTION' | translate"></p>
    </div>
    <hr/>
    <div class="hvac-cool-calc-button-container">
        <button hvacButton="default" (click)="declineAction()" class='hvac-cool-calc-decline-link'>{{'COOL_CALC_TERMS_OF_USE.FOOTER_DECLINE_BUTTON' | translate }}</button>
        <button hvacButton="default" (click)="acceptAction()" class='hvac-cool-calc-accept-link'>{{'COOL_CALC_TERMS_OF_USE.FOOTER_ACCEPT_BUTTON' | translate }}</button>
    </div>
</section>
