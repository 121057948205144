import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';


export const InternationalCustomerConfig = {
    configType: CompanyCategoryType.INTERNATIONAL_CUSTOMER,
    formConfig: {
        companyUrl: { view: true },
        companyType: { edit: false },
        contactLic: {
            view: true,
            edit: false
        },
        companyName: {
            view: true,
            edit: true
        },
        address1: {
            view: true,
            edit: true
        },
        address2: {
            view: true,
            edit: true
        },
        country: {
            view: true,
            edit: true
        },
        state: {
            view: true,
            edit: true
        },
        city: {
            view: true,
            edit: true
        },
        postalCode: {
            view: true,
            edit: true
        },
        phone: {
            view: true,
            edit: true
        },
        mobile: {
            view: true,
            edit: true
        },
        tollFree: {
            view: true,
            edit: true
        },
        fax: {
            view: true,
            edit: true
        },
        email: {
            view: true,
            edit: true
        },
        notes: {
            view: true,
            edit: true
        }
    },
    association: {
        associatedDistributor: true,
        associatedContact: true,
        associatedDealer: false
    }

} as AccountAdminDetailsEditConfiguration;
