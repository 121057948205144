import { Injectable } from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    ValidationErrors
} from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class AccountAdminProgramFormService {
    public static programSearchForm = new FormGroup({
        status: new FormControl(''),
        brand: new FormControl(''),
        programName: new FormControl(''),
        programCode: new FormControl('', [Validators.maxLength(4)]),
        criteriaName: new FormControl('')
    });

    public static programEditForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        code: new FormControl('', [
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.required,
            AccountAdminProgramFormService.specialCharProhibitValidator
        ]),
        status: new FormControl(''),
        programPeriodStart: new FormControl(
            new Date().toISOString().slice(0, -1)
        ),
        programPeriodEnd: new FormControl(
            new Date().toISOString().slice(0, -1)
        ),
        enrollmentStartDate: new FormControl(
            new Date().toISOString().slice(0, -1)
        ),
        enrollmentEndDate: new FormControl(
            new Date().toISOString().slice(0, -1)
        ),
        description: new FormControl(''),
        vertical: new FormControl('', [Validators.required]),
        brands: new FormControl(''),
        eligibilityCriteria: new FormControl('', [Validators.required]),
        enrollmentLevel: new FormControl(false),
        locatorStart: new FormControl(new Date().toISOString().slice(0, -1)),
        locatorEnd: new FormControl(new Date().toISOString().slice(0, -1)),
        locatorIcon: new FormControl(''),
        enrollmentWorkflow: new FormControl(''),
        enrollmentForm: new FormControl('', [Validators.required]),
        massEnrollFl: new FormControl(false),
        finalApprovedBy: new FormControl('')
    });

    public static manageEnrollmentsForm = new FormGroup({
        companyName: new FormControl(''),
        companyId: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        enrollmentLevel: new FormControl(''),
        workflowStatus: new FormControl(''),
        sponsoringDistributor: new FormControl('')
    });

    public static specialCharProhibitValidator(
        control: FormControl
    ): ValidationErrors | null {
        const pattern = /^[a-zA-Z0-9]*$/;
        if (pattern.test(control.value)) {
            return null;
        }

        return { specialCharProhibitError: true };
    }
}
