import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { NavOption } from 'common/components/dropdown/dropdown-navigation/dropdown-navigation.component';
import { Option } from 'common/components/dropdown/options-menu/options-menu.component';
import { MobileMenuVisible, ToggleBookmarkSlide, ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { GetInboxCount, GetInboxDetails, InboxStateModel } from 'private/app/store/inbox.actions';
import { InboxState } from 'private/app/store/inbox.state';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hvac-private-header-icon-links',
    templateUrl: './private-header-icon-links.component.html',
    styleUrls: ['./private-header-icon-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrivateHeaderIconLinksComponent {
    @Select(InboxState.unreadCount) unreadCount$: Observable<InboxStateModel>;

    @Select(UiState.headerDropdown) headerDropdown$: Observable<string>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<string>;

    helpSelectedOption: NavOption | null = null;
    helpDefaultoptions$ = of([
        {
            title: 'DIRECTORY',
            value: 'https://www.apps1.hvacpartners.com/SeamlessSignOn/SeamlessSignOn/?programCode=hvpreport&Brand=Any',
            action: 'new_tab'
        },
        {
            title: 'FAQ',
            value: '/Pages/partners/FAQ.aspx',
            action: 'link'
        },
        {
            title: 'SUPPORT',
            value: '/Pages/partners/SiteSupport.aspx',
            action: 'link'
        },
        {
            title: 'CONTACT_US',
            value: '/Pages/partners/ContactUs.aspx',
            action: 'link'
        }
    ]);

    helpNavigationOptions$: Observable<Option[]> = combineLatest([this.helpDefaultoptions$, this.translate.get('HEADER.HELP_MENU_LINKS')])
        .pipe(map(([defaultOptions, optionTranslations]) => defaultOptions.map((option) => ({
            title: optionTranslations[option.title],
            value: option.value,
            action: option.action
        }))));

    constructor(private router: Router, private readonly translate: TranslateService, private readonly store: Store) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    routeTo(url: string, queryParams: any) {
        this.router.navigate([url], { queryParams: queryParams });
    }

    openHelpDropdown(isOpen: boolean) {
        return isOpen ? this.store.dispatch(new ToggleHeaderDropdown('help')) : this.store.dispatch(new ToggleHeaderDropdown('none'));
    }

    handleInboxButton() {
        this.store.dispatch(new ToggleHeaderDropdown('none'));
        this.store.dispatch(new MobileMenuVisible(false));
        this.store.dispatch(new Navigate(['/inbox']));
        this.store.dispatch(new GetInboxCount());
        this.store.dispatch(new GetInboxDetails());
    }

    openBookMarkWindow() {
        this.store.dispatch(new ToggleBookmarkSlide(true));
    }
}
