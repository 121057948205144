

export const accessoryConfigKeys = [
    'ventilation_percent_on_time',
    'ventilation_max_idu_odu_temp_delta',
    'rh_humidification_sp',
    'rh_humidifier_heat_dependent',
    'rh_dehumidification_sp',
    'rh_dehumidify_fan_dependent',
    'dry_mode',
    'overcool_setpoint',
    'drymode_set_point'
];
