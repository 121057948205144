import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { DataTableSort, DataTableSortDirection } from 'common/models/data-table';
import { ToastService } from 'common/services/toast.service';
import { InboxService } from 'private/app/services/inbox.service';
import { GetInboxCount, GetInboxDetails } from 'private/app/store/inbox.actions';
import { InboxState } from 'private/app/store/inbox.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hvac-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InboxComponent {
    @Select(InboxState.inboxDetails) inboxInfo$: BehaviorSubject<string[]>;

    @ViewChild('bannerContainer', { static: false }) bannerContainer: ElementRef;

    columnHeaders: string[] = ['name', 'type', 'requested', 'completed', 'status'];
    contextMenuData: string[] = ['View', 'Resubmit', 'Delete'];
    fileName: string;
    dataSort: DataTableSort;
    disableRefresh = false;
    inboxStatus$: Observable<boolean>;
    bannerTimer: NodeJS.Timeout;
    bannerDuration = 8000;
    public toastOutlet = 'inboxToast';

    constructor(
        private inboxService: InboxService,
        private store: Store,
        private toastService: ToastService,
        private translate: TranslateService
    ) {
        this.inboxStatus$ = this.inboxInfo$.pipe(
            map((status) => status.length > 0)
        );

        this.dataSort = {
            sortFieldName: 'completed',
            sortDirection: DataTableSortDirection.DESCENDING
        } as DataTableSort;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick(event: any) {
        this.gotoTop();
        if (event.menu) {
            this.updateInboxStatus(event.value.headerId);
        }
        switch (event.menu) {
            case 'row':
                this.fileName = event.value.fileName;
                if (event.value.status === 'Error') {
                    this.inboxService.fileError(event);
                    break;
                }
                if (event.value.status === 'In Progress') {
                    this.toastService.add({
                        content: this.translate.instant('INBOX.FILE_WARNING'),
                        theme: 'warning',
                        closeable: true,
                        id: 'inbox-file-warning',
                        outletName: this.toastOutlet
                    });
                    break;
                }

                this.toastService.add({
                    content: this.translate.instant('INBOX.DOWNLOAD_STARTED'),
                    theme: 'success',
                    closeable: true,
                    id: 'inbox-download-success',
                    outletName: this.toastOutlet
                });

                this.inboxService.downloadReport(event.value.fileName).subscribe((data) => {
                    if (data) {
                        this.downLoadFile({
                            content: data,
                            fileName: this.fileName
                        });
                    }
                    else {
                        this.toastService.add({
                            content: this.translate.instant('INBOX.INVALID_DOWNLOAD'),
                            theme: 'error',
                            closeable: true,
                            id: 'inbox-invalid-download',
                            outletName: this.toastOutlet
                        });
                    }
                });
                break;
            case 'Delete':
                this.inboxService.deleteReport(event.value.headerId).subscribe((_data) => {
                    this.store.dispatch(new GetInboxCount());
                    this.store.dispatch(new GetInboxDetails());
                });
                break;
            case 'View':
                this.openInboxviewer(event);
                break;
            case 'Resubmit':
                window.open(`${event.value.documentLink}?HeaderId=${event.value.headerId}`, '_blank');
                break;
            default:
                break;
        }
    }

    updateInboxStatus(headerId: string) {
        this.inboxService.updateInboxStatus(headerId).subscribe((_status) => {
            this.store.dispatch(new GetInboxCount());
            this.store.dispatch(new GetInboxDetails());
        });
    }

    refreshInboxInfo() {
        this.store.dispatch(new GetInboxCount());
        this.store.dispatch(new GetInboxDetails());
        this.disableRefresh = true;
        setTimeout(() => {
            this.disableRefresh = false;
            this.toastService.add({
                content: this.translate.instant('INBOX.INBOX_REFRESHING'),
                theme: 'success',
                closeable: true,
                id: 'inbox-refreshing',
                autoClose: true
            });
        }, 2000);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openInboxviewer(event: any) {
        const reportName = event.value.type.split(' ').join('+');
        this.store.dispatch(new Navigate(['/Pages/partners/InboxViewer.aspx'],
            {
                FileId: event.value.fileId,
                ReportName: reportName
            }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    downLoadFile(data: any) {
        const fileType = data.content.type === 'application/zip' ? 'application/zip' : 'text/plain;charset=utf-8;';
        const blob = new Blob([data.content], { type: fileType });
        let fileName = '';
        if (fileType === 'application/zip') {
            fileName = data.fileName.split('.')[0];
            fileName = `${fileName}.zip`;
        }
        else {
            fileName = data.fileName;
        }
        // IE
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const navigator: any = window.navigator;

        if (navigator.msSaveOrOpenBlob) {
            return navigator.msSaveOrOpenBlob(blob, fileName);
        }
        const url = window.URL.createObjectURL(data.content);
        const fileLink = document.createElement('a');
        fileLink.href = url;
        fileLink.download = fileName;

        return fileLink.click();
    }

    readMultiInboxReport(event: {headerId:string, isRead:string}) {
        if (!event.isRead) {
            this.inboxService.updateInboxStatus(event.headerId).subscribe((_status) => {
                this.store.dispatch(new GetInboxCount());
            });
        }
    }

    gotoTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
}
