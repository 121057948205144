import { Injectable } from '@angular/core';
import { CanActivateChild, UrlTree } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TradePartnersGuard implements CanActivateChild {
    constructor(
        private readonly store: Store,
        private oktaService: OktaService
    ) {}

    canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.oktaService.idToken$.pipe(
            skipWhile((res) => Object.keys(res).length < 1),
            map((res: IdToken) => {
                const roles = res?.claims?.idm_user_roles;
                if (roles) {
                    return roles.includes('TradePartners');
                }

                return false;
            }),
            catchError(() => {
                this.store.dispatch(new Navigate(['/']));

                return of(false);
            })
        );
    }
}
