/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'enrollment-form-builder',
    templateUrl: './enrollment-form-builder.component.html',
    styleUrls: ['../enrollment-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnrollmentFormBuilderComponent {
    @Input() field: any;
    @Input() form: any;
    @Input() group: string;
    @Input() disable: boolean;
    @Input() disableRSM : boolean;

    get isValid() {
        return this.form.controls[this.field.name].valid;
    }

    get isDirty() {
        return this.form.controls[this.field.name].dirty;
    }
}
