import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateService } from 'common/services/date/date.service';
import { createAccountEmailInputValidator, createAccountInputValidator, createAccountPostalCodeValidator } from 'common/utils/createAccountValidators';
import { EmailVerification } from 'private/app/components/email-validation/email-validation.component';
import { UserValidationService } from 'private/app/services/user-validation/user-validation.service';
import { ProfileDetails } from 'private/app/models/user-validation';
import { Option } from 'common/components/select/select.component';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { GetUserStatus } from 'common/store/account-status/account-status.actions';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountStatusModel } from 'common/models/account-status';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { HttpErrorResponse } from '@angular/common/http';

const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {
    @Select(AccountStatusState.getProfileInfo) userInfo$: Observable<AccountStatusModel>;
    ngOnDestroy$ = new Subject();

    public profileForm: UntypedFormGroup = new UntypedFormGroup({
        address1: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        address2: new UntypedFormControl(''),
        cellPhone: new UntypedFormControl(''),
        city: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        country: new UntypedFormControl(''),
        email: new UntypedFormControl('', [createAccountEmailInputValidator(101, true, validEmailRegex)]),
        fax: new UntypedFormControl(''),
        firstName: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        lastName: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        phone: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        userName: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        zip: new UntypedFormControl('', [createAccountPostalCodeValidator()]),
        myHVACpin: new UntypedFormControl({
            value: '',
            disabled: true
        })
    });

    enableEmailVerification$ = new BehaviorSubject<boolean>(false);
    countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    bannerType = 'ProfileBanner';
    public apiRequestActive: boolean;

    private userProfile$: BehaviorSubject<ProfileDetails[]> = new BehaviorSubject<ProfileDetails[]>([]);
    private userEmailId$ = new BehaviorSubject<string>('');
    private emailVerificationStatus = new BehaviorSubject<EmailVerification>({
        email: '',
        status: false
    });

    constructor(
        private store: Store,
        public dateService: DateService,
        private userValidationService: UserValidationService,
        private toastService: ToastService,
        private translate: TranslateService,
        private commonUtils: CommonUtilsService
    ) { }

    ngOnInit(): void {
        this.userInfo$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((statusData) => statusData),
            mergeMap((statusData) => forkJoin([this.userValidationService.getProfileDetails(statusData?.userId)]))
        ).subscribe(([profileInfo]) => {
            this.userEmailId$.next(profileInfo[0].email);
            this.userProfile$.next(profileInfo);
            this.setFormValues(profileInfo[0]);
        }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('ProfileComponent-ngOnInit', error.message));

        this.commonUtils.getCountryOptions().subscribe((countryList) => {
            this.countryOptionData$.next(countryList);
        });

        this.profileForm.controls.country.valueChanges.subscribe((selectedCountry) => {
            if (selectedCountry) {
                this.stateOptionData$.next([]);
                if (Array.isArray(selectedCountry)) {
                    this.fetchStateListByCountry(selectedCountry[0].value);
                }
                else {
                    const countrySelection = this.countryOptionData$.value.find((country) => country.name === selectedCountry);
                    if (countrySelection) {
                        this.fetchStateListByCountry(countrySelection.value);
                    }
                }
                this.profileForm.controls.state.setValue('');
            }
        });


        combineLatest([
            this.userProfile$,
            this.countryOptionData$
        ]).pipe(
            map(([profileInfo, countryOptionData$]) => {
                if (profileInfo.length > 0 && countryOptionData$.length > 0) {
                    return this.setCountrySelection(profileInfo);
                }
            })
        ).subscribe();

        combineLatest([
            this.userProfile$,
            this.countryOptionData$,
            this.stateOptionData$
        ]).pipe(
            map(([profileInfo, countryOptionData, stateOptionData]) => {
                if (profileInfo.length > 0 && countryOptionData.length > 0 && stateOptionData.length > 0) {
                    return this.setStateSelection(profileInfo, stateOptionData);
                }
            })
        ).subscribe();
    }

    fetchStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateByCountry(selectedCountry).subscribe((stateList) => {
            this.stateOptionData$.next(stateList);
        });
    }

    emailVerification(emailVerification: EmailVerification) {
        if (emailVerification.status) {
            this.emailVerificationStatus.next(emailVerification);
            this.enableEmailVerification$.next(false);
            this.updateProfileInfo();
        }
    }

    setCountrySelection(profileInfo: ProfileDetails[]) {
        const countrySelection = this.countryOptionData$.value.find((country) => country.value === profileInfo[0].userCountry);
        if (countrySelection) {
            this.profileForm.controls.country.setValue(countrySelection.name);
        }
    }

    setStateSelection(profileInfo: ProfileDetails[], stateOptionData: Option[]) {
        const stateSelection = stateOptionData.find((country) => country.value === profileInfo[0].userState);
        if (stateSelection) {
            this.profileForm.controls.state.setValue(stateSelection.name);
        }
    }

    updateProfileInfo() {
        if (!(this.userEmailId$.value.toLowerCase() === this.profileForm.controls.email.value.toLowerCase()) && this.emailVerificationStatus.value.email !== this.profileForm.controls.email.value.toLowerCase()) {
            this.enableEmailVerification$.next(true);

            return;
        }

        this.apiRequestActive = true;
        this.setUpdateUserProfileData();
        this.userValidationService.updateUserProfileDetails(this.userProfile$.value[0]).subscribe((data) => {
            this.apiRequestActive = false;
            if (data.status === 'Success') {
                this.toastService.add({
                    content: this.translate.instant('USER_VALIDATION.PROFILE_UPDATE_SUCCESS'),
                    theme: 'success',
                    id: this.bannerType,
                    outletName: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
                this.enableEmailVerification$.next(false);
                this.store.dispatch(new GetUserStatus());
            }
        });
    }

    setUpdateUserProfileData() {
        this.userProfile$.value[0].firstName = this.profileForm.controls.firstName.value;
        this.userProfile$.value[0].lastName = this.profileForm.controls.lastName.value;
        this.userProfile$.value[0].userAddress1 = this.profileForm.controls.address1.value;
        this.userProfile$.value[0].userAddress2 = this.profileForm.controls.address2.value;
        this.userProfile$.value[0].userCity = this.profileForm.controls.city.value;
        this.userProfile$.value[0].email = this.profileForm.controls.email.value;
        this.userProfile$.value[0].userPostalCode = this.profileForm.controls.zip.value;
        this.userProfile$.value[0].userPhone = this.profileForm.controls.phone.value;
        this.userProfile$.value[0].userCellPhone = this.profileForm.controls.cellPhone.value;
        this.userProfile$.value[0].userFax = this.profileForm.controls.fax.value;
        if (Array.isArray(this.profileForm.controls.country.value)) {
            this.userProfile$.value[0].userCountry = this.countryOptionData$.value.find((country) => country.name === this.profileForm.controls.country.value[0].name)?.value || '';
        }
        else {
            this.userProfile$.value[0].userCountry = this.countryOptionData$.value.find((country) => country.name.toLowerCase() === this.profileForm.controls.country.value.toLowerCase())?.value || '';
        }

        if (Array.isArray(this.profileForm.controls.state.value)) {
            this.userProfile$.value[0].userState = this.stateOptionData$.value.find((state) => state.name === this.profileForm.controls.state.value[0].name)?.value || '';
        }
        else {
            this.userProfile$.value[0].userState = this.stateOptionData$.value.find((state) => state.name === this.profileForm.controls.state.value)?.value || '';
        }
    }

    cancelValidation(value: boolean) {
        this.enableEmailVerification$.next(value);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private setFormValues(user: ProfileDetails) {
        if (user) {
            this.profileForm.controls.address1.setValue(user.userAddress1 || '');
            this.profileForm.controls.address2.setValue(user.userAddress2 || '');
            this.profileForm.controls.city.setValue(user.userCity || '');
            this.profileForm.controls.email.setValue(user.email || '');
            this.profileForm.controls.firstName.setValue(user.firstName || '');
            this.profileForm.controls.lastName.setValue(user.lastName || '');
            this.profileForm.controls.userName.setValue(user.userID || '');
            this.profileForm.controls.zip.setValue(user.userPostalCode || '');
            this.profileForm.controls.phone.setValue(user.userPhone || '');
            this.profileForm.controls.cellPhone.setValue(user.userCellPhone || '');
            this.profileForm.controls.fax.setValue(user.userFax || '');
            this.profileForm.controls.myHVACpin.setValue(user.hvacPin || '');
        }
    }
}
