<ng-template [ngIf]="!isLoading">
    <div class="hvac-account-admin-heading hvac-account-admin-heading-default" [ngClass]="activeUserScreen === 'EditSecurity' ? 'hvac-account-admin-heading-default-user-role' : 'hvac-account-admin-heading-default-user-info'">
        <h1 class="hvac-h4">
            {{ userAdminService.getActiveScreenName(activeUserScreen) }}
        </h1>
        <h2 *ngIf="activeUserScreen === 'EditSecurity'" class="hvac-h6">
            ({{ user.userID }})
        </h2>

        <button class="hvac-more-options" (click)="userAdminService.toggleMenu()">
            <utc-svg src="assets/icons/elipses.svg"></utc-svg>
        </button>

        <div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{'hvac-dropdown-menu-expanded': userAdminService.menuActive}">
            <ol class="hvac-dropdown-menu-options">
                <li *ngIf="this.user.userStatus === 'Active'" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option" (click)="onClickEditSecurity(true)">{{ 'USER_ADMIN.MANAGE_USER_SECURITY' | translate}}</a>
                </li>
                <li *ngIf="this.user.userStatus === 'Active'" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option" (click)="enableUserRelationshipsEditMode()">{{ 'USER_ADMIN.MANAGE_USER_RELATIONSHIP' | translate}}</a>
                </li>
                <li class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option" (click)="onClickCloneUser()">{{ 'USER_ADMIN.CLONE_USER' | translate}}</a>
                </li>
                <li *ngIf="this.user.userStatus === 'Active'" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option" (click)="deactivateUserModal = true">{{ 'USER_ADMIN.DEACTIVATE_USER' | translate}}</a>
                </li>
                <li *ngIf="this.user.userStatus === 'Inactive'" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option">{{ 'USER_ADMIN.ACTIVATE_USER' | translate}}</a>
                </li>
                <li class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option" (click)="onExportUserDetails(user)">{{ 'USER_ADMIN.EXPORT_TO_EXCEL' | translate}}</a>
                </li>
                <li *ngIf="isITSuperAdmin" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option">{{ 'USER_ADMIN.SYNC_TO_OKTA' | translate}}</a>
                </li>
                <li *ngIf="isITSuperAdmin" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option">{{ 'USER_ADMIN.SYNC_TO_LDAP' | translate}}</a>
                </li>
                <li *ngIf="isITSuperAdmin" class="hvac-dropdown-menu-option-item">
                    <a class="hvac-dropdown-menu-option">{{ 'USER_ADMIN.SYNC_TO_HYBRIS' | translate}}</a>
                </li>
            </ol>
        </div>

        <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/results/users', {
            queryParams: this.accountAdminService.lastQueryParams.value
            }]" queryParamsHandling="merge" class="hvac-text-link hvac-user-details-back-to-result">
            <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
            <div class="hvac-back-text">{{ 'ACCOUNT_ADMIN.BACK_TO_SEARCH_RESULTS' | translate}}</div>
        </a>
    </div>
    <ng-container *ngIf="activeUserScreen === 'EditSecurity'">
        <hvac-edit-user-security
            [userDetails]="user"
            (cancelUserSecurityChanges)="onClickEditSecurity($event)"
        ></hvac-edit-user-security>
    </ng-container>
    <ng-container *ngIf="activeUserScreen === 'CloneUser' && createUserOnStepNumber !=='step4_userRolesFeatures'">
        <hvac-create-user-details [cloneUserData]="user" [company]="company" (createUserChangePage)="onClickCreateUserChangePage($event)" (userDetails)="CreateUserDetails($event)"></hvac-create-user-details>
    </ng-container>
    <ng-container *ngIf="createUserOnStepNumber==='step4_userRolesFeatures' && activeUserScreen !== 'ReadOnlyUserInfo'">
        <hvac-user-roles-details (activeScreenName)="userAdminService.getActiveScreenName($event)" (registrationUserChangePage)="onContinueToStep2Click($event)" (userDetailsSubmit)="userCreateDetailsSubmit($event)" [page]="'createUser'" [userDetails]="userDetails" [usersData]="user"></hvac-user-roles-details>
    </ng-container>
    <div *ngIf="activeUserScreen === 'EditInfo' || activeUserScreen === 'ReadOnlyUserInfo'" [ngClass]="{'hvac-editable-user-info': activeUserScreen !== 'ReadOnlyUserInfo'}">
    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{user.firstName | textFallback}}
                    </div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                            [error]="userAdminService.userEditForm.controls.firstName.dirty && !userAdminService.userEditForm.controls.firstName.valid"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FIRST_NAME' | translate" [max]="40"
                            [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.firstName" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.lastName | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                            [error]="userAdminService.userEditForm.controls.lastName.dirty && !userAdminService.userEditForm.controls.lastName.valid"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.LAST_NAME' | translate" [max]="40"
                            [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.lastName"
                            type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.USER_NAME' | translate}}
                        <button type="button" *ngIf="activeUserScreen !== 'ReadOnlyUserInfo'" class="hvac-user-text-button" hvacButton="text">
                            {{ 'USER_ADMIN.DEACTIVATE_USER' | translate}}
                        </button>
                    </div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.userID | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userID.dirty && !userAdminService.userEditForm.controls.userID.valid"
                        [disabledInput]="true" [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.userID"
                        type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.USER_ID' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.userID | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userID.dirty && !userAdminService.userEditForm.controls.userID.valid"
                        [disabledInput]="true" [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.userID"
                        type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.USER_TITLE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userTitle | textFallback }}</div>

                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userTitle.dirty && !userAdminService.userEditForm.controls.userTitle.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_TITLE' | translate" [hideLabel]="true" [max]="40"
                        [inputControl]="userAdminService.userEditForm.controls.userTitle" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_NAME' | translate}}
                        <button type="button" *ngIf="activeUserScreen !== 'ReadOnlyUserInfo'" class="hvac-user-text-button" hvacButton="text"
                            (click)="onUserCompanyChangeClick()">
                            {{ 'USER_ADMIN.CHANGE_COMPANY' | translate}}
                        </button>
                    </div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.employerName | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.employerName.dirty && !userAdminService.userEditForm.controls.employerName.valid"
                        [hideLabel]="true"
                        [disabledInput]="true"
                        [inputControl]="userAdminService.userEditForm.controls.employerName" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_ID' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.companyId | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.companyId.dirty && !userAdminService.userEditForm.controls.companyId.valid"
                        [disabledInput]="true" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.companyId" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MY_HVAC_PIN' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.hvacPin | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.hvacPin.dirty && !userAdminService.userEditForm.controls.hvacPin.valid"
                        [disabledInput]="true" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.hvacPin" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{
                        'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userAddress1 | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userAddress1.dirty && !userAdminService.userEditForm.controls.userAddress1.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS1' | translate" [hideLabel]="true" [max]="60"
                        [inputControl]="userAdminService.userEditForm.controls.userAddress1" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userAddress2 | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userAddress2.dirty && !userAdminService.userEditForm.controls.userAddress2.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS2' | translate" [hideLabel]="true" [max]="60"
                        [inputControl]="userAdminService.userEditForm.controls.userAddress2" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{
                        'ACCOUNT_ADMIN.CITY' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userCity | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userCity.dirty && !userAdminService.userEditForm.controls.userCity.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.CITY' | translate" [hideLabel]="true" [max]="40"
                        [inputControl]="userAdminService.userEditForm.controls.userCity" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                    <div *ngIf="activeUserScreen === 'ReadOnlyUserInfo';else editUserCountry" class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                        userCountry | textFallback }}</div>
                    <ng-template #editUserCountry>
                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            [error]="userAdminService.userEditForm.controls.userCountry.dirty && !userAdminService.userEditForm.controls.userCountry.valid"
                            [shadow]="'none'"
                            (onChange)="onCountryChange($event)"
                            [group]="userAdminService.userEditForm" control="userCountry" [options]="(countryOptionData$ | async) || []">
                        </utc-select>
                    </ng-template>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{ 'ACCOUNT_ADMIN.STATE' | translate}}</div>
                    <div *ngIf="activeUserScreen === 'ReadOnlyUserInfo';else editState" class="hvac-col-xs hvac-account-admin-detail-list-value">{{ userState
                        | textFallback }}</div>
                    <ng-template #editState>
                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            [error]="userAdminService.userEditForm.controls.userState.dirty && !userAdminService.userEditForm.controls.userState.valid"
                            [shadow]="'none'"
                            (onChange)="onStateChange($event)"
                            [group]="userAdminService.userEditForm" control="userState" [options]="(stateOptionData$ | async) || []">
                        </utc-select>
                    </ng-template>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{
                        'ACCOUNT_ADMIN.ZIP_CODE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userPostalCode | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userPostalCode.dirty && !userAdminService.userEditForm.controls.userPostalCode.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.ZIP_CODE' | translate" [hideLabel]="true" [max]="10"
                        [inputControl]="userAdminService.userEditForm.controls.userPostalCode" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.OFFICE_PHONE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.userPhone | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userPhone.dirty && !userAdminService.userEditForm.controls.userPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.OFFICE_PHONE' | translate" [hideLabel]="true" [max]="20"
                        [inputControl]="userAdminService.userEditForm.controls.userPhone" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE_NUMBER' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userCellPhone | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userCellPhone.dirty && !userAdminService.userEditForm.controls.userCellPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER' | translate" [hideLabel]="true" [max]="20"
                        [inputControl]="userAdminService.userEditForm.controls.userCellPhone" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FAX_NUMBER' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userFax | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userFax.dirty && !userAdminService.userEditForm.controls.userFax.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER' | translate" [hideLabel]="true" [max]="20"
                        [inputControl]="userAdminService.userEditForm.controls.userFax" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : activeUserScreen !== 'ReadOnlyUserInfo' }">{{
                        'ACCOUNT_ADMIN.EMAIL' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.email | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.email.dirty && !userAdminService.userEditForm.controls.email.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMAIL' | translate" [hideLabel]="true" [max]="100"
                        [inputControl]="userAdminService.userEditForm.controls.email" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs" *ngIf="activeUserScreen !== 'ReadOnlyUserInfo'">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'USER_ADMIN.RESET_PASSWORD' | translate}}</div>
                    <utc-checkbox class="hvac-col-xs hvac-account-admin-edit-value" [checked]="false" inputId="'USER_ADMIN.RESET_PASSWORD' | translate"
                            (change)="resetPassword($event)"
                            inputName="'USER_ADMIN.RESET_PASSWORD' | translate">
                    </utc-checkbox>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.CONTACT_ONLY' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.contactOnly.toString() | textFallback }}</div>
                    <utc-checkbox class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode" [checked]="user.contactOnly || false"
                        inputId="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate" (change)="setContactOnly($event)"
                        inputName="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate">
                    </utc-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>
    <div class="hvac-row hvac-account-admin-detail-footer" *ngIf="activeUserScreen === 'ReadOnlyUserInfo'else editUserInfoFooter">
        <div class="hvac-col-xs-12 hvac-col-md-6">
            <p class="hvac-account-admin-changes">
                <span class="hvac-account-admin-changes-date">{{ 'ACCOUNT_ADMIN.LAST_LOGIN_DATE' |
                    translate}}: {{ (user.lastLoginDate | date: 'dd/MM/yyyy') || 'USER_ADMIN.NOT_LOGGED_IN_TO_THE_SYSTEM' |
                    translate }} </span>
            </p>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-end-xs hvac-flex">
            <button *ngIf="this.user.userStatus === 'Active'" type="button" class="hvac-edit-button" hvacButton="primary" (click)="editUserInfo$.next(true)">
                {{ 'USER_ADMIN.EDIT_USER_DETAILS' | translate}}
            </button>
        </div>
    </div>
    <ng-template #editUserInfoFooter>
        <div class="hvac-row hvac-account-admin-detail-footer hvac-flex-end">
            <div class="hvac-row hvac-user-admin-edit-footer">
                <div class="hvac-label">{{ 'USER_ADMIN.VALIDATE_ON_NEXT_LOGIN' | translate}}</div>
                <utc-checkbox class="hvac-account-admin-edit-value" [checked]="false" inputId="'USER_ADMIN.RESET_PASSWORD' | translate"
                        (change)="validatePassword($event)"
                        inputName="'USER_ADMIN.RESET_PASSWORD' | translate">
                </utc-checkbox>
            </div>
            <button type="button" [hvacButton]="'default'" (click)="editUserInfo$.next(false)" [disabled]="apiRequestActive">
                <span>{{ "USER_ADMIN.CANCEL" | translate }}</span>
            </button>
            <button type="button" class="hvac-edit-button" hvacButton="primary" [disabled]="apiRequestActive || !userAdminService.userEditForm.valid || userAdminService.userEditForm.pristine" (click)="submitChanges()">
                {{ 'USER_ADMIN.SUBMIT_CHANGES' | translate}}
                <utc-svg src="assets/icons/arrow.svg"></utc-svg>
            </button>
        </div>
        </ng-template>
    </div>
    <div *ngIf="activeUserScreen === 'EditRelations'">
        <hvac-edit-user-relationships></hvac-edit-user-relationships>
    </div>
</ng-template>
<ng-template [ngIf]="userCompanyEditMode">
    <div class="x-overflow-hidden">
        <utc-modal [theme]="'rounded'" [visible]="true" [size]="'dynamic-height'" (closeAction)="searchCompanyModalClose()">
            <hvac-modal-header><h1>{{ "USER_ADMIN.USER_DETAILS.SEARCH_USER_IN_COMPANY" | translate }}</h1></hvac-modal-header>
            <hvac-create-user-company-search-form
                [selectedCompanyTypeName]="userCompanyTypeName"
                [selectedCompanyTypeCode]="userCompanyTypeCode"
                [isCompanyTypeReadOnlyField]="true"
                [clickClearForm]="onClickClearSearch"
                (onClickSearch)="onCompanySearchClick()"
            >
            </hvac-create-user-company-search-form>
            <hvac-create-user-company-table *ngIf="isCompanyListDataAvailable" [onCompanySelect]="onCompanySelect"></hvac-create-user-company-table>
        </utc-modal>
    </div>
</ng-template>

<utc-modal class='hvac-deactivate-user-modal' size="dynamic-height" (closeAction)="deactivateUserModal = false" [visible]="deactivateUserModal" theme='rounded' [showHeader]="false">
    <div class="hvac-deactivate-user-wrapper">
        <h3 class="hvac-h3 hvac-warning-text">{{
            'USER_ADMIN.DEACTIVATE_USER_MODAL.WARNING_TEXT' | translate }}</h3>
        <p class="deactivate-modal-message">
            {{'USER_ADMIN.DEACTIVATE_USER_MODAL.WARNING_INFO' | translate }}
        </p>
        <p class="deactivate-modal-message">
            {{'USER_ADMIN.DEACTIVATE_USER_MODAL.WARNING_INFO_2' | translate }}
        </p>
        <div class="hvac-divider hvac-button-divider"></div>
        <div class="hvac-deactivate-user-modal-actions">
            <button buttonType="button" hvacButton="default" (click)="deactivateUserModal= false;">
                {{'ACCOUNT_ADMIN.CANCEL' | translate }}
            </button>
            <button buttonType="button" hvacButton="primary" (click)="deactivateUser()">
                {{'USER_ADMIN.DEACTIVATE_USER_MODAL.CONTINUE' | translate }}
            </button>
        </div>
    </div>
</utc-modal>

<ng-template [ngIf]="isLoading">
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
