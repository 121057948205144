import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from 'common/content/services/error.service';
import { CompanyCode } from 'private/app/models/company.model';
import { NavigationLinksService } from 'private/app/services/connected-portal/navigation-links.service';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiResponseCode, MISSING_ROUTE_PARAMS_ERROR } from '../../constants';

export interface ConnectedPortalNavLink {
    label: string,
    route: string,
    comingSoon?: boolean;
}

@Component({
    selector: 'hvac-connected-portal-navigation',
    templateUrl: './connected-portal-navigation.component.html',
    styleUrls: ['./connected-portal-navigation.component.scss']
})
export class ConnectedPortalNavigationComponent implements OnInit {
    public isNavOpen = false;
    public navLinks$: Observable<ConnectedPortalNavLink[] | null>;
    public isLoadingNavLink$ = new BehaviorSubject(false);
    private userId$: Observable<string | null>;

    constructor(
        private route: ActivatedRoute,
        private navLinksService: NavigationLinksService,
        private errorService: ErrorService
    ) {}

    ngOnInit(): void {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

            return;
        }

        this.userId$ = this.route.parent.paramMap.pipe(
            map((params) => params.get('id'))
        );

        this.navLinks$ = combineLatest([this.userId$, this.route.data]).pipe(
            tap(() => {
                this.isLoadingNavLink$.next(true);
            }),
            switchMap(([id, { viewType }]) => {
                const navLinks$ = this.getNavLinks$(viewType, id);

                if (navLinks$) {
                    return navLinks$;
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            catchError(() => {
                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            tap(() => {
                this.isLoadingNavLink$.next(false);
            })
        );
    }

    closeNav() {
        this.isNavOpen = false;
    }

    toggleNav() {
        this.isNavOpen = !this.isNavOpen;
    }

    trackByNavLink(_index: number, navLink: ConnectedPortalNavLink) {
        return navLink.label;
    }

    private getNavLinks$(viewType: CompanyCode, userId: string | null): Observable<ConnectedPortalNavLink[]> | null {
        if (viewType) {
            switch (viewType) {
                case CompanyCode.Dealer:
                    if (userId) {
                        return this.navLinksService.getDealerNavLinks$(userId);
                    }

                    throw new Error(MISSING_ROUTE_PARAMS_ERROR);
                case CompanyCode.Distributor:
                    if (userId) {
                        return of(this.navLinksService.getDistributorNavLinks(userId));
                    }

                    throw new Error(MISSING_ROUTE_PARAMS_ERROR);
                case CompanyCode.Internal:
                    return of(this.navLinksService.getFactoryNavLinks());

                default:
                    return null;
            }
        }

        return null;
    }
}
