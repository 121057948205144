import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './product-details.component';
import { DetailsComponent } from './components/details/details.component';

const routes: Routes = [
    {
        path: ':product',
        component: ProductDetailsComponent,
        data: {
            state: 'product-details',
            title: 'HVACpartners: Product Details',
            track: { pageName: 'product-details' }
        },
        children: [
            {
                path: '',
                component: DetailsComponent,
                data: { state: 'product-details.details' }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductDetailsRoutingModule {}
