import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { CloseRecentActivity, ToggleBookmarkSlide } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { Observable } from 'rxjs';

export type TabItem = 'bookmark' | 'recentActivity';
export interface TabActive {
    tab: TabItem;
}

@Component({
    selector: 'hvac-bookmarks-slide',
    templateUrl: './bookmarks-slide.component.html',
    styleUrls: ['./bookmarks-slide.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class BookmarksSlideComponent implements OnInit {
    @Select(UiState.bookmarkSlide) bookmarkSlide$: Observable<boolean>;

    sliderTitle = {
        bookmark: 'upwork',
        recentActivity: 'refer from friend'
    };

    results: string[] = [];
    today: string | null;
    recentActivity = false;

    activeTab: TabActive = { tab: 'bookmark' };
    constructor(private readonly store: Store, readonly translate: TranslateService) { }

    ngOnInit(): void {
        this.sliderTitle = {
            bookmark: 'BOOKMARKSLIDER.TITLE.BOOKMARKS',
            recentActivity: 'BOOKMARKSLIDER.TITLE.RECENTACTIVITY'
        };
    }

    toggleSlider(_action: boolean) {
        this.store.dispatch(new ToggleBookmarkSlide(false));
    }

    openRecentLink(link: string) {
        this.store.dispatch(new Navigate([link]));
        this.store.dispatch(new CloseRecentActivity());
        this.store.dispatch(new ToggleBookmarkSlide(false));
    }

    recentActivitySelect() {
        this.activeTab = { tab: 'recentActivity' };
    }

    bookmarkSelect() {
        this.activeTab = { tab: 'bookmark' };
    }
}
