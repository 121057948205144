import { Injectable } from '@angular/core';

export interface BasicCSV {
    headings: string[],
    body: (string | number | null)[][]
}

@Injectable({ providedIn: 'root' })
export class CSVExportService {
    toCSV(data: BasicCSV) {
        const lineBreak = '\r\n';
        const headings = data.headings.map((item) => `"${item}"`).join(',') + lineBreak;
        const rows = data.body.reduce((acc, curr) => {
            const row = curr.map((item) => `"${item}"`).join(',');

            return acc.concat(`${row}${lineBreak}`);
        }, '');

        const csvData = headings + rows;

        return csvData;
    }

    saveAs(csvData: string, filenamePrefix: string = 'data') {
        const filename = `${filenamePrefix}-${new Date().toISOString()}.csv`;

        const blob = new Blob([`\ufeff${csvData}`], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        this.downloadFile(url, filename);
    }

    downloadFile(url: string, filename?: string) {
        const link = document.createElement('a');

        const isSafariBrowser =
            navigator.userAgent.indexOf('Safari') !== -1 &&
            navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            // if Safari open in new window to save file with random filename.
            link.setAttribute('target', '_blank');
        }

        link.setAttribute('href', url);
        link.setAttribute('download', filename || url);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
