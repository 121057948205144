/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import {
    DiagnosticEquipmentsResponse,
    DiagnosticEquipments,
    DiagnosticTest,
    SystemDiagnosticsParamsData,
    DiagnosticEquipmentData,
    SystemDiagnosticsExtendedParamConfig,
    DiagnosticsTestInputProps,
    DiagnosticTestDeviceKeys,
    DiagnosticTestUnitType,
    WallControlCommand
} from 'private/app/models/connected-portal-system-diagnostics.model';
import { ApiResponseCode, REALTIME_API_CUSTOM_DOMAIN_ENVS, SYSTEM_DIAGNOSTIC_TEST_TIME } from 'private/app/views/connected-portal/constants';
import { Subject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { ProductConfigurationService } from './product-configuration.service';
import { AppSyncHeaders, AppSyncMessage, AppSyncQuery, AppSyncSocketData, AppSyncSocketDiagnosticsDataValues, AppSyncSocketDiagnosticsErrors, AppSyncSocketError, AppSyncSocketEvent, AppSyncSocketRegistration } from 'private/app/models/system-diagnostics.model';
import * as uuid from 'uuid';
import { environment } from 'common/environments/environment';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { EnvName } from 'common/app-constants';
import { ProductService } from './product.service';
import { TranslateService } from '@ngx-translate/core';

export const UnrecognizedAppSyncEvent = 'APPSYNC_EVENT_NOT_RECOGNIZED';

const QUERY_DIAGNOSTIC_TEST_EQUIPMENT_BY_SERIAL_NO = gql`
    query queryDiagnosticTestEquipmentBySerialNo($id:String!, $dealerId: String) {
        queryDiagnosticTestEquipmentBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                device
                stageParam
                numStages
            }
        }
    }
`;

const RUN_DIAGNOSTIC_TEST_BY_SERIAL_NO = gql`
    mutation runDiagnosticsTestBySerialNo($id:String!, $data:DiagnosticsTestInputProps!) {
        runDiagnosticsTestBySerialNo(id:$id, data: $data) {
            message
            code
            data {
                id
                name
                value
                description
            }
        }
    }
`;


const envUrl = environment.api.connectedPortal.webSocketUrl;
const envAppKey = environment.api.connectedPortal.webSocketKey;
const envName = environment.envName;

@Injectable()
export class SystemDiagnosticsService extends ConnectedPortalBaseService implements OnDestroy {
    public webSocketError$: Subject<Event> = new Subject();
    public webSocketDataError$: Subject<string> = new Subject();
    public errorMessages$: Subject<AppSyncSocketDiagnosticsErrors> = new Subject();
    public webSocketStartAck$ = new Subject();

    public publishCommand = this.productService.publishCommand;

    private envName: EnvName = envName;
    private webSocket: WebSocket | null = null;
    private token: string | null;
    private controlSerialNo?: string;
    private wsPollingSubject$ = new Subject<string>();
    private wsDataSubject$ = new Subject<SystemDiagnosticsParamsData>();
    private pollingTimer: ReturnType<typeof setTimeout>;
    private pollingInactive = new Subject<boolean>();
    private subscriptionId?: string;
    private isRegistered = false;
    private socketProtocol = 'graphql-ws';
    private apiKey: string;
    private hostUrl: string;
    private socketUrl: string;
    private isDeregisterInitiated: boolean;
    private deregisterSubject$ = new Subject<boolean>();
    private waitForTimeout?: ReturnType<typeof setTimeout>;
    private isTestStarted = false;

    constructor(
        public injector: Injector,
        private configService: ProductConfigurationService,
        private productService: ProductService,
        private translate: TranslateService
    ) {
        super(injector);

        const { hostUrl, socketUrl } = this.getSocketUrls(envUrl);
        this.hostUrl = hostUrl;
        this.socketUrl = socketUrl;
        this.apiKey = envAppKey;
    }

    ngOnDestroy() {
        if (this.waitForTimeout) {
            clearTimeout(this.waitForTimeout);
        }
    }

    queryDiagnosticTestEquipmentBySerialNo(id: string, dealerId: string): Observable<DiagnosticEquipments[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDiagnosticTestEquipmentBySerialNo').query<{ queryDiagnosticTestEquipmentBySerialNo: DiagnosticEquipmentsResponse }>({
                query: QUERY_DIAGNOSTIC_TEST_EQUIPMENT_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDiagnosticTestEquipmentBySerialNo } = value.data;

                    return this.formatEquipmentData(queryDiagnosticTestEquipmentBySerialNo);
                })
            )),
            catchError((err: HttpErrorResponse) => {
                const errorResponse = {
                    ...err,
                    testEquipmentListingError: true
                };

                this.showErrorToast(errorResponse);

                throw err;
            })
        );
    }

    runDiagnosticsTestBySerialNo(diagnosticsTestInputProps: DiagnosticsTestInputProps, serialNo: string): Observable<DefaultUpdateResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const { id, data } = diagnosticsTestInputProps;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('runDiagnosticsTestBySerialNo').mutate<{ runDiagnosticsTestBySerialNo: DefaultUpdateResponse }>({
                mutation: RUN_DIAGNOSTIC_TEST_BY_SERIAL_NO,
                variables: {
                    id,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    if (value.data?.runDiagnosticsTestBySerialNo.code === ApiResponseCode.SUCCESS) {
                        this.isTestStarted = true;
                        this.initiateSocketConnection(serialNo);
                    }

                    return value.data?.runDiagnosticsTestBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.errorMessages$.next(err.error);
                this.webSocket?.close();
                this.webSocket = null;
                this.showErrorToast(err);

                throw err;
            })
        );
    }


    getDiagnosticRealTimeData$(): Observable<SystemDiagnosticsParamsData> {
        return this.wsDataSubject$.asObservable();
    }

    wsPollingListener$() {
        return this.wsPollingSubject$.asObservable();
    }

    resetTestState(serialNo: string, dealerId: string) {
        this.deregisterSubscription();

        if (this.isTestStarted) {
            this.isTestStarted = false;

            this.publishCommand(serialNo, dealerId, { command: WallControlCommand.ExitCurrentTest }).subscribe();
        }
    }

    exitDiagnosticsMode(serialNo: string, dealerId: string) {
        this.deregisterSubscription();

        if (this.isTestStarted) {
            this.publishCommand(serialNo, dealerId, { command: WallControlCommand.ExitCurrentTest }).pipe(
                switchMap(() => this.publishCommand(serialNo, dealerId, { command: WallControlCommand.DisconnectCommand }))
            ).subscribe();
        }
        else {
            this.publishCommand(serialNo, dealerId, { command: WallControlCommand.DisconnectCommand }).subscribe();
        }

        this.isTestStarted = false;
    }

    getSystemDiagnosticParamConfig(): Observable<SystemDiagnosticsExtendedParamConfig> {
        return this.configService.systemDiagnosticConfigParams$;
    }

    setPollingTimer() {
        this.pollingTimer = setTimeout(() => {
            this.pollingInactive.next();
        }, SYSTEM_DIAGNOSTIC_TEST_TIME);
    }

    resetPollingTimer() {
        this.clearPollingTimer();
        this.setPollingTimer();
    }

    getIsPollingInactive$() {
        return this.pollingInactive.asObservable();
    }

    clearPollingTimer() {
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer);
        }
    }

    registerSubscription() {
        if (this.isRegistered) {
            return;
        }

        if (this.controlSerialNo && this.token) {
            this.subscriptionId = uuid.v4();

            const data: AppSyncSocketRegistration = {
                id: this.subscriptionId,
                payload: {
                    data: this.getDataQuery(this.controlSerialNo),
                    extensions: {
                        authorization: {
                            'host': this.hostUrl,
                            'x-api-key': this.apiKey,
                            'Authorization': this.token
                        }
                    }
                },
                type: AppSyncSocketEvent.Start
            };

            this.send(JSON.stringify(data));
        }
    }

    getDeregisterSubject(): Observable<boolean> {
        return this.deregisterSubject$.asObservable();
    }

    private deregisterSubscription() {
        if (this.isRegistered) {
            this.isDeregisterInitiated = true;

            const data = {
                type: AppSyncSocketEvent.Stop,
                id: this.subscriptionId
            };

            this.send(JSON.stringify(data));
        }
    }

    private formatEquipmentData(queryDiagnosticTestEquipmentBySerialNo: DiagnosticEquipmentsResponse): DiagnosticEquipments[] {
        if (queryDiagnosticTestEquipmentBySerialNo.data && queryDiagnosticTestEquipmentBySerialNo.data?.length > 0) {
            const equipmentList = queryDiagnosticTestEquipmentBySerialNo.data.map((equipment) => ({
                equipmentName: equipment.device,
                serialNumber: equipment.stageParam,
                isTestable: Boolean(equipment.numStages),
                isSelected: false,
                ...this.getAdditionalFormattedEquipmentData(equipment)
            }));

            equipmentList.sort((a: DiagnosticEquipments, b: DiagnosticEquipments) => a.displayOrder - b.displayOrder);
            equipmentList[0].isSelected = true;

            return equipmentList;
        }

        return [];
    }

    private getTestStages(stages: number, test: string, skipIndex: boolean) {
        const tests: DiagnosticTest[] = [];
        for (let i = 0; i < stages; i++) {
            tests.push({
                testName: test,
                hasStarted: false,
                testId: i + 1,
                ...this.getTranslatedTestNames(test, i, skipIndex)
            });
        }

        return tests;
    }

    private getAdditionalFormattedEquipmentData(equipment: DiagnosticEquipmentData) {
        const additionalParams = {
            displayOrder: -1,
            equipmentType: DiagnosticTestUnitType.IDU,
            tests: this.getTestStages(equipment.numStages, 'stage', false)
        };

        switch (equipment.device) {
            case DiagnosticTestDeviceKeys.FURNACE:
                additionalParams.equipmentType = DiagnosticTestUnitType.IDU;
                additionalParams.displayOrder = 1;
                break;
            case DiagnosticTestDeviceKeys.HEAT_PUMP_COOL:
                additionalParams.equipmentType = DiagnosticTestUnitType.ODU_AND_IDU;
                additionalParams.displayOrder = 2;
                break;
            case DiagnosticTestDeviceKeys.HEAT_PUMP_HEAT:
                additionalParams.equipmentType = DiagnosticTestUnitType.ODU_AND_IDU;
                additionalParams.displayOrder = 3;
                break;
            case DiagnosticTestDeviceKeys.AC:
                additionalParams.displayOrder = 4;
                additionalParams.equipmentType = DiagnosticTestUnitType.ODU_AND_IDU;
                break;
            case DiagnosticTestDeviceKeys.FAN:
                additionalParams.displayOrder = 5;
                additionalParams.equipmentType = DiagnosticTestUnitType.IDU;
                additionalParams.tests = this.getTestStages(equipment.numStages, 'testFan', true);
                break;
            case DiagnosticTestDeviceKeys.FAN_COIL_COOL_2_PIPE:
                additionalParams.displayOrder = 6;
                additionalParams.equipmentType = DiagnosticTestUnitType.IDU;
                break;
            case DiagnosticTestDeviceKeys.FAN_COIL_HEAT_2_PIPE:
                additionalParams.displayOrder = 7;
                additionalParams.equipmentType = DiagnosticTestUnitType.IDU;
                break;
            case DiagnosticTestDeviceKeys.FAN_COIL_HEAT_DUAL_TERM:
                additionalParams.displayOrder = 8;
                additionalParams.equipmentType = DiagnosticTestUnitType.IDU;
                break;
            case DiagnosticTestDeviceKeys.VENTILATOR:
            case DiagnosticTestDeviceKeys.HUMIDIFIER:
            case DiagnosticTestDeviceKeys.DEHUMIDIFIER:
                additionalParams.displayOrder = 9;
                break;
            default:
                break;
        }

        return additionalParams;
    }

    private getDataQuery(serialNo: string): string {
        const query: AppSyncQuery = {
            query: `
                subscription subscribe {
                    subscribe(serialNumber:"${serialNo}")
                        {
                            serialNumber
                            liquidLineTemperature
                            liquidLinePressure
                            dateTime
                            blowerRpm
                            deltaT
                            outdoorTemperature
                            returnAirTemperature
                            subCooling
                            suctionPressure
                            suctionTemperature
                            superHeat
                            supplyAirTemperature
                            ErrorMessage
                         }
                }`,
            variables: {}
        };

        return JSON.stringify(query);
    }

    private initiateSocketConnection(serialNo: string) {
        if (this.isDeregisterInitiated) {
            this.getDeregisterSubject()
                .pipe(take(1))
                .subscribe(() => {
                    this.createSocketConnection(serialNo);
                });
        }
        else {
            this.createSocketConnection(serialNo);
        }
    }

    private createSocketConnection(controlSerialNo: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        options$.pipe(
            switchMap((options) => {
                const token = options.headers.get('Authorization');

                return of(token);
            })
        ).subscribe((token) => {
            if (token) {
                this.token = token;
                this.controlSerialNo = controlSerialNo;

                const headers = this.getEncodedHeaders();
                const payload = this.utf8ToB64('{}');
                const url = `${this.socketUrl}?header=${headers}&payload=${payload}`;

                this.webSocket = new WebSocket(url, this.socketProtocol);
                this.webSocket.onopen = this.onOpen.bind(this);
                this.webSocket.onerror = this.onError.bind(this);
                this.webSocket.onmessage = this.onMessage.bind(this);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private onMessage(msg: MessageEvent<any>) {
        const msgData: AppSyncMessage | AppSyncSocketData | AppSyncSocketError = JSON.parse(msg.data);
        const { type } = msgData;
        switch (type) {
            case AppSyncSocketEvent.Data:
                if (msgData.payload?.data) {
                    this.handleDataMessage(msgData as AppSyncSocketData);
                }
                break;

            case AppSyncSocketEvent.KeepAlive:
                break;

            case AppSyncSocketEvent.ConnectionAcknowledged:
                this.registerSubscription();
                break;

            case AppSyncSocketEvent.Start:
                break;

            case AppSyncSocketEvent.StartAcknowledged:
                this.isRegistered = true;
                this.webSocketStartAck$.next();
                break;

            case AppSyncSocketEvent.Complete:
                this.isRegistered = false;
                this.webSocket?.close();
                this.webSocket = null;
                this.deregisterSubject$.next();
                this.isDeregisterInitiated = false;
                break;

            case AppSyncSocketEvent.ConnectionError:
            case AppSyncSocketEvent.Error:
                if (msgData.payload?.errors) {
                    this.handleErrors(msgData as AppSyncSocketError);
                    this.webSocket?.close();
                }

                break;

            default:
                throw new Error(UnrecognizedAppSyncEvent);
        }
    }

    private handleDataMessage(msgData: AppSyncSocketData) {
        const dataValues = msgData.payload?.data?.subscribe;

        if (dataValues) {
            const { ErrorMessage } = dataValues as AppSyncSocketDiagnosticsDataValues;

            if (ErrorMessage && ErrorMessage.trim() !== '') {
                this.webSocketDataError$.next(ErrorMessage);
            }
            else {
                this.wsDataSubject$.next(dataValues);
            }
        }
    }

    private handleErrors(msgData: AppSyncSocketError) {
        const error = msgData.payload?.errors;

        if (error) {
            this.errorMessages$.next(error);
        }
    }

    private onOpen() {
        this.send(JSON.stringify({ type: AppSyncSocketEvent.ConnectionInitiated }));
    }

    private onError(event: Event) {
        this.webSocketError$.next(event);
    }

    private getSocketUrls(baseUrl: string): { hostUrl: string, socketUrl: string } {
        if (REALTIME_API_CUSTOM_DOMAIN_ENVS.includes(this.envName)) {
            return {
                hostUrl: baseUrl.replace('https://', '').replace('/graphql/realtime', ''),
                socketUrl: baseUrl.replace('https', 'wss')
            };
        }

        return {
            hostUrl: baseUrl.replace('https://', '').replace('/graphql', ''),
            socketUrl: baseUrl.replace('https', 'wss').replace('appsync-api', 'appsync-realtime-api')
        };
    }

    private getEncodedHeaders(): string {
        const headers: AppSyncHeaders = {
            'x-api-key': this.apiKey,
            'host': this.hostUrl
        };

        return this.utf8ToB64(JSON.stringify(headers));
    }

    private utf8ToB64(str: string): string {
        return window.btoa(unescape(encodeURIComponent(str)));
    }

    private send = (message: string | ArrayBufferLike | Blob | ArrayBufferView) => {
        this.waitForConnection(() => {
            this.webSocket?.send(message);
        }, 1000);
    };

    private waitForConnection = (callback: Function, interval: number) => {
        if (this.webSocket?.readyState === 1) {
            callback();
        }
        else {
            this.waitForTimeout = setTimeout(() => {
                this.waitForConnection(callback, interval);
            }, interval);
        }
    };

    private getTranslatedTestNames(test: string, indx: number, skipIndex: boolean) {
        const index = indx + 1;
        const stageTestText = this.translate.instant(`CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.STAGES.${test}`);
        const testText = this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TEST');
        const stageTestEndText = skipIndex ? testText : `${stageTestText} ${index} ${testText}`;

        const testDisplayText = `${testText} ${stageTestText} ${skipIndex ? '' : index}`;
        const cancelDisplayText = `${this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.CANCEL')} ${stageTestEndText}`;
        const cancellingDisplayText = `${this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.CANCELLING')} ${stageTestEndText}`;

        return {
            testText: testDisplayText,
            cancelTestText: cancelDisplayText,
            cancellingTestText: cancellingDisplayText
        };
    }
}
