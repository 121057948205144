import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Option } from 'common/components/select/select.component';
import { AccountStatusModel } from 'common/models/account-status';
import { ToastService } from 'common/services/toast.service';
import { GetUserStatus } from 'common/store/account-status/account-status.actions';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { createAccountEmailInputValidator, createAccountInputValidator, createAccountPostalCodeValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { EmailVerification } from 'private/app/components/email-validation/email-validation.component';
import { ProfileDetails } from 'private/app/models/user-validation';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { UserValidationService } from 'private/app/services/user-validation/user-validation.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

@Component({
    selector: 'hvac-user-validation-form',
    templateUrl: './user-validation-form.component.html',
    styleUrls: ['./user-validation-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserValidationFormComponent implements OnInit {
    @Select(AccountStatusState.getProfileInfo) userInfo$: Observable<AccountStatusModel>;
    @Output() onValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emailVaidationCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() requestUserProfileUpdate: Observable<boolean> = new Observable<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    firstNameErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastNameErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    zipCodeErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addressErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cityErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    countryErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stateErrorMessage$: Observable<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emailErrorMessage$: Observable<any>;

    countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    stateOptionData: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    enableEmailVerification$ = new BehaviorSubject<boolean>(false);
    userEmailId$ = new BehaviorSubject<string>('');

    emailVerificationStatus = new BehaviorSubject<EmailVerification>({
        email: '',
        status: false
    });

    public userInfoForm = new UntypedFormGroup({
        userIdControl: new UntypedFormControl(''),
        myHvacPinControl: new UntypedFormControl(''),
        employeeIdControl: new UntypedFormControl(''),
        employeeNameControl: new UntypedFormControl(''),
        usernameControl: new UntypedFormControl(''),
        firstNameControl: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        lastNameControl: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        address1Control: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        address2Control: new UntypedFormControl(''),
        cityControl: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        countryControl: new UntypedFormControl(''),
        stateControl: new UntypedFormControl(''),
        zipControl: new UntypedFormControl('', [createAccountPostalCodeValidator()]),
        phoneControl: new UntypedFormControl(''),
        cellControl: new UntypedFormControl(''),
        emailControl: new UntypedFormControl('',
            [createAccountEmailInputValidator(101, true, validEmailRegex)]),
        faxControl: new UntypedFormControl(''),
        termsControl: new UntypedFormControl(false, Validators.requiredTrue)
    });

    private userProfile$: BehaviorSubject<ProfileDetails[]> = new BehaviorSubject<ProfileDetails[]>([]);
    private userData: AccountStatusModel;
    private ngOnDestroy$ = new Subject();
    private eventsSubscription: Subscription;
    private errorMessages$ = this.translate.get('USER_VALIDATION.ERROR_MESSAGES');

    constructor(
        private store: Store,
        private translate: TranslateService,
        private userValidationService: UserValidationService,
        private toastService: ToastService,
        private commonUtils: CommonUtilsService
    ) { }

    ngOnInit() {
        this.eventsSubscription = this.requestUserProfileUpdate.subscribe((formStatus) => {
            if (formStatus) {
                this.updateUserProfile();
            }
        });
        this.userInfoForm.statusChanges.subscribe((status) => {
            this.onValidChange.emit(status === 'VALID');
        });

        this.commonUtils.getCountryOptions().subscribe((countryList) => {
            this.countryOptionData$.next(countryList);
        });

        this.userInfo$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((statusData) => {
                this.userData = statusData;

                return this.userData;
            }),
            mergeMap((userData) => forkJoin([this.userValidationService.getProfileDetails(userData?.userId)]))
        ).subscribe(([profileInfo]) => {
            profileInfo[0].userTitle = this.userData.company.name;
            profileInfo[0].id = this.userData.company.id;
            this.userEmailId$.next(profileInfo[0].email);
            this.userProfile$.next(profileInfo);
            this.userValidationService.setUserDetails(this.userInfoForm, profileInfo);
        });


        combineLatest([
            this.userProfile$,
            this.countryOptionData$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([profileInfo, countryOptionData$]) => {
                if (profileInfo.length > 0 && countryOptionData$.length > 0) {
                    return this.setCountrySelection(profileInfo);
                }
            })
        ).subscribe();

        combineLatest([this.userProfile$, this.countryOptionData$, this.stateOptionData]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([profileInfo, countryOptionData$, stateOptionData]) => {
                if (profileInfo.length > 0 && countryOptionData$.length > 0 && stateOptionData.length > 0) {
                    return this.setStateSelection(profileInfo, stateOptionData);
                }
            })
        ).subscribe();

        this.firstNameErrorMessage$ = combineLatest([
            this.userInfoForm.controls.firstNameControl.statusChanges, this.errorMessages$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].firstName = this.userInfoForm.controls.firstNameControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.firstNameControl.errors, message);
            })
        );

        this.lastNameErrorMessage$ = combineLatest([this.userInfoForm.controls.lastNameControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].lastName = this.userInfoForm.controls.lastNameControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.lastNameControl.errors, message);
            })
        );

        this.addressErrorMessage$ = combineLatest([this.userInfoForm.controls.address1Control.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].userAddress1 = this.userInfoForm.controls.address1Control.value;
                }
                getErrorMessage(this.userInfoForm.controls.address1Control.errors, message);
            })
        );

        this.cityErrorMessage$ = combineLatest([this.userInfoForm.controls.cityControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].userCity = this.userInfoForm.controls.cityControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.cityControl.errors, message);
            })
        );

        this.stateErrorMessage$ = combineLatest([this.userInfoForm.controls.stateControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].userState = this.userInfoForm.controls.stateControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.stateControl.errors, message);
            })
        );

        this.countryErrorMessage$ = combineLatest([this.userInfoForm.controls.countryControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].userCountry = this.userInfoForm.controls.countryControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.countryControl.errors, message);
            })
        );
        this.zipCodeErrorMessage$ = combineLatest([this.userInfoForm.controls.zipControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].userPostalCode = this.userInfoForm.controls.zipControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.zipControl.errors, message);
            })
        );
        this.emailErrorMessage$ = combineLatest([this.userInfoForm.controls.emailControl.statusChanges, this.errorMessages$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([valid, message]) => {
                if (valid) {
                    this.userProfile$.value[0].email = this.userInfoForm.controls.emailControl.value;
                }
                getErrorMessage(this.userInfoForm.controls.emailControl.errors, message);
            })
        );

        this.userInfoForm.controls.address2Control.statusChanges.subscribe((valid) => {
            if (valid) {
                this.userProfile$.value[0].userAddress2 = this.userInfoForm.controls.address2Control.value;
            }
        });

        this.userInfoForm.controls.phoneControl.statusChanges.subscribe((valid) => {
            if (valid) {
                this.userProfile$.value[0].userPhone = this.userInfoForm.controls.phoneControl.value;
            }
        });

        this.userInfoForm.controls.cellControl.statusChanges.subscribe((valid) => {
            if (valid) {
                this.userProfile$.value[0].userCellPhone = this.userInfoForm.controls.cellControl.value;
            }
        });

        this.userInfoForm.controls.faxControl.statusChanges.subscribe((valid) => {
            if (valid) {
                this.userProfile$.value[0].userFax = this.userInfoForm.controls.faxControl.value;
            }
        });

        this.userInfoForm.controls.countryControl.valueChanges.subscribe((selectedCountry) => {
            if (selectedCountry) {
                if (Array.isArray(selectedCountry)) {
                    this.fetchStateListByCountry(selectedCountry[0].value);
                }
                else {
                    const countrySelection = this.countryOptionData$.value.find((country) => country.name === selectedCountry);
                    if (countrySelection) {
                        this.fetchStateListByCountry(countrySelection.value);
                    }
                }
                this.stateOptionData.next([]);
                this.userInfoForm.controls.stateControl.setValue('');
            }
        });
    }


    updateUserProfile() {
        if (!(this.userEmailId$.value.toLowerCase() === this.userInfoForm.controls.emailControl.value.toLowerCase()) && this.emailVerificationStatus.value.email !== this.userInfoForm.controls.emailControl.value.toLowerCase()) {
            this.enableEmailVerification$.next(true);

            return;
        }

        this.userValidationService.updateUserProfileDetails(this.userProfile$.value[0]).subscribe((data) => {
            if (data.status === 'Success') {
                this.toastService.add({
                    content: this.translate.instant('USER_VALIDATION.PROFILE_UPDATE_SUCCESS'),
                    id: 'USER_VALIDATION.PROFILE_UPDATE_SUCCESS',
                    theme: 'success',
                    closeable: true,
                    autoClose: true
                });
                this.enableEmailVerification$.next(false);
                this.store.dispatch(new GetUserStatus());
            }
        });
    }

    setCountrySelection(profileInfo: ProfileDetails[]) {
        const countrySelection = this.countryOptionData$.value.find((country) => country.value === profileInfo[0].userCountry);
        if (countrySelection) {
            this.userInfoForm.controls.countryControl.setValue(countrySelection.name);
        }
    }

    setStateSelection(profileInfo: ProfileDetails[], stateOptionData: Option[]) {
        const stateSelection = stateOptionData.find((country) => country.value === profileInfo[0].userState);
        if (stateSelection) {
            this.userInfoForm.controls.stateControl.setValue(stateSelection.name);
        }
    }

    fetchStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateByCountry(selectedCountry).subscribe((stateList) => {
            this.stateOptionData.next(stateList);
        });
    }

    emailVerification(emailVerification: EmailVerification) {
        if (emailVerification.status) {
            this.emailVerificationStatus.next(emailVerification);
            this.enableEmailVerification$.next(false);
            this.emailVaidationCompleted.next(true);
            this.updateUserProfile();
        }
    }

    cancelValidation(value: boolean) {
        this.enableEmailVerification$.next(value);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.eventsSubscription.unsubscribe();
    }

    changeTermsControl($event: Event) {
        return this.userInfoForm.controls.termsControl.setValue(($event.target as HTMLInputElement).checked);
    }
}

