import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PublicLinkIconService } from 'common/content/services/public-link-icon.service';
import { ContentModelContent, MenuLinkDetails } from 'common/models';

@Component({
    selector: 'hvac-media-highlight',
    templateUrl: './media-highlight.component.html',
    styleUrls: ['./media-highlight.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MediaHighlightComponent {
    @Input() data: ContentModelContent;

    additionalLinks: MenuLinkDetails[];

    constructor(private readonly publicLinkIcon: PublicLinkIconService) {}

    ngOnInit() {
        const linkList = this.data.Content.links.$values || [];
        this.additionalLinks = linkList.map((link: object) => ({
            ...link,
            icon: this.publicLinkIcon.getLinkIcon(link)
        }));
    }
}
