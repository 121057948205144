<utc-create-account-header
    [hideCancelButton]="isIcpDealerRegistrationRequest"
    [cancelButtonText]="'CREATE_ACCOUNT_LOOKUP.HEADER_CANCEL_BUTTON' | translate"
    [cancelButtonDisabled]="(apiRequestActive$ | async)!"
    (cancelFunction)="handleHeaderCancel()"
    (logoFunction)="handleHeaderLogo()"
>
</utc-create-account-header>

<div class='hvac-create-account-email'>
    <div class='hvac-create-account-email-heading'>
        <h2 class='hvac-create-account-email-title hvac-hero-large-text'> {{'CREATE_ACCOUNT_EMAIL.TITLE' | translate }}
        </h2>
        <div class='hvac-create-account-email-banner-container'>
            <hvac-toast></hvac-toast>
        </div>
        <div class='hvac-create-account-email-body'>
            <div class='hvac-create-account-email-input-container'>
                <utc-input inputId="create-account-email-input"
                    type="text"
                    [inputControl]="emailCodeControl"
                    [label]="'CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_CODE' | translate"
                    [error]="emailCodeControl.dirty && !emailCodeControl.valid"
                    [errorMessage]="emailCodeErrorMessage$ | async"
                    [disabledInput]="(ICPCreateAccount$ | async)! || (apiRequestActive$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"></utc-input>
            </div>
            <div>
                <p class='hvac-create-account-email-body-copy hvac-hero-normal-text'>{{'CREATE_ACCOUNT_EMAIL.BODY' |
                    translate
                    }}</p>
                <button [disabled]="resetButtonDisabled" hvacButton="secondary" (click)="requestEmailCode()"
                    class='hvac-resend-email-code'
                    [innerHTML]="(resetButtonDisabled ? 'CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_CODE_SEND' : 'CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_CODE_RESEND') | translate"></button>
            </div>
        </div>
    </div>
</div>

<utc-tab-navigation-footer
    [continueButtonDisabled]="!(emailCodeControl.valid) || (apiRequestActive$ | async)!"
    [backButtonDisabled]="(apiRequestActive$ | async)!" [numberOfSteps]="footerNumberOfSteps"
    [continueText]="footerActionButtonText$ | async"
    [backText]="'CREATE_ACCOUNT_EMAIL.FOOTER_BACK_BUTTON' | translate"
    (continueButtonFunction)="submitAccountInfo()"
    (backButtonFunction)="backToInformationPage()">
</utc-tab-navigation-footer>
