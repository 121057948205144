import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../base/base.component';

const DEBOUNCE_TIME = 1000;

@Component({
    selector: 'hvac-number-stepper',
    templateUrl: './number-stepper.component.html',
    styleUrls: ['./number-stepper.component.scss']
})
export class NumberStepperComponent extends BaseComponent implements OnInit {
    @Input() minValue: number | null = 0;
    @Input() maxValue: number | null = 0;
    @Output() minValueChange = new EventEmitter<number>();
    @Output() maxValueChange = new EventEmitter<number>();
    @Input() disabled: boolean;

    public hasMinError = false;
    public hasMaxError = false;

    private increment$ = new Subject<number>();
    private decrement$ = new Subject<number>();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.increment$.pipe(
            tap(() => {
                if (this.maxValue) {
                    if (this.minValue !== null && this.maxValue < this.minValue) {
                        this.hasMaxError = true;
                    }
                }
            }),
            debounceTime(DEBOUNCE_TIME),
            tap(() => {
                if (this.maxValue) {
                    if (this.minValue !== null && this.maxValue < this.minValue) {
                        this.maxValue = this.minValue;
                    }

                    this.hasMaxError = false;
                    this.maxValueChange.emit(this.maxValue);
                }
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();

        this.decrement$.pipe(
            debounceTime(DEBOUNCE_TIME),
            tap(() => {
                if (this.minValue) {
                    if (this.maxValue !== null && this.minValue > this.maxValue) {
                        this.minValue = this.maxValue;
                    }

                    this.minValueChange.emit(this.minValue);
                }
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    incrementMin() {
        if (this.minValue !== null && (this.maxValue === null || this.minValue < this.maxValue)) {
            this.minValue += 1;

            // Accounts for inputs that are not integers
            if (this.maxValue !== null && this.minValue > this.maxValue) {
                this.minValue = this.maxValue;
            }

            this.minValueChange.emit(this.minValue);
        }
    }

    decrementMin() {
        if (this.minValue !== null) {
            this.minValue -= 1;
            this.minValueChange.emit(this.minValue);
        }
    }

    incrementMax() {
        if (this.maxValue !== null) {
            this.maxValue += 1;
            this.maxValueChange.emit(this.maxValue);
        }
    }

    decrementMax() {
        if (this.maxValue !== null && (this.minValue === null || this.maxValue > this.minValue)) {
            this.maxValue -= 1;

            // Accounts for inputs that are not integers
            if (this.minValue !== null && this.maxValue < this.minValue) {
                this.maxValue = this.minValue;
            }

            this.maxValueChange.emit(this.maxValue);
        }
    }

    onMinValueChanged() {
        if (this.minValue !== null) {
            this.decrement$.next(this.minValue);
        }
    }

    onMaxValueChanged() {
        if (this.maxValue !== null) {
            this.increment$.next(this.maxValue);
        }
    }
}
