import { Injectable, Injector } from '@angular/core';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { RunTimeDateParams, RunTimeReportResponse } from './ecobee-report.service';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';

export interface EltDataSummary {
    dateTime: string,
    indoorTemp: number,
    coolToSetPoint: number,
    heatToSetPoint: number,
    outdoorTemp: number,
    indoorHumidity: number,
}
export const QUERY_ELT_RUNTIME_REPORT_BY_SERIAL_NO = gql`
    query queryELTRuntimeReportBySerialNo($id:String!, $params: StartEndDateInput!, $systemType: String!) {
        queryELTRuntimeReportBySerialNo(id: $id, params: $params, systemType: $systemType) {
            data {
                dataSummary {
                    dateTime
                    indoorTemp
                    outdoorTemp
                    indoorHumidity
                    coolToSetPoint
                    heatToSetPoint
                }
                filepath
            }
        }
    }
`;
@Injectable({ providedIn: 'root' })
export class CarrierEltReportService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryELTRuntimeReportBySerialNo(id: string, params: RunTimeDateParams, systemType: string): Observable<RunTimeReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryELTRuntimeReportBySerialNo').query<{ queryELTRuntimeReportBySerialNo: RunTimeReportResponse }>({
                query: QUERY_ELT_RUNTIME_REPORT_BY_SERIAL_NO,
                variables: {
                    id,
                    params,
                    systemType
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryELTRuntimeReportBySerialNo } = value.data;

                    return queryELTRuntimeReportBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
