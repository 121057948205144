import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ErrorService } from 'common/content/services/error.service';
import { AccountStatusModel, UserStatus } from 'common/models/account-status';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoolCalTermsGuard implements CanActivate {
    @Select(AccountStatusState.getProfileInfo) userInfo$: Observable<AccountStatusModel>;

    constructor(
        private readonly store: Store,
        private errorService: ErrorService
    ) {}

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userInfo$.pipe(
            skipWhile((status) => !status.userId),
            map((status) => {
                if (status.userStatus === UserStatus.Pending) {
                    this.errorService.errorStatus$.next(403);
                }
                else if (!status.coolCalcTerms) {
                    this.store.dispatch(new Navigate(['/support/pre-sale-support/cool-calc/terms']));

                    return false;
                }

                return true;
            }),
            catchError(() => {
                this.store.dispatch(new Navigate(['/']));

                return of(false);
            })
        );
    }
}
