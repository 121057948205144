import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { SwimlaneChartProps } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/components/connected-portal-swimlane-chart/connected-portal-swimlane-chart.component';

export interface DataSummary extends SwimlaneChartProps {
    dateTime: string,
    indoorTemp: number,
    coolToSetPoint: number,
    heatToSetPoint: number,
    outdoorTemp: number,
    indoorHumidity: number,
    outdoorHumidity:number
}

export interface RunTimeReportData {
    dataSummary: DataSummary[] | null;
    filepath: string | null;
}

export interface RunTimeReportResponse {
    data: RunTimeReportData
}

export interface RunTimeDateParams {
    startDate: string,
    endDate: string
}

export const QUERY_RUNTIME_REPORT_BY_SERIAL_NO = gql`
    query queryRuntimeReportBySerialNo($id:String!, $dealerId: String, $params: StartEndDateInput!, $systemType: String!) {
        queryRuntimeReportBySerialNo(id: $id, dealerId: $dealerId, params: $params, systemType: $systemType) {
            data {
                dataSummary {
                    dateTime
                    indoorTemp
                    coolToSetPoint
                    heatToSetPoint
                    outdoorTemp
                    indoorHumidity
                    outdoorHumidity
                    fan
                    coolingStage1
                    coolingStage2
                    heatingStage1
                    heatingStage2
                    hpHeatingStage1
                    hpHeatingStage2
                    auxHeatingStage1
                    auxHeatingStage2
                }
                filepath
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class EcobeeReportService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryRuntimeReportBySerialNo(id: string, dealerId: string, params: RunTimeDateParams, systemType: string): Observable<RunTimeReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryRuntimeReportBySerialNo').query<{ queryRuntimeReportBySerialNo: RunTimeReportResponse }>({
                query: QUERY_RUNTIME_REPORT_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    params,
                    systemType
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryRuntimeReportBySerialNo } = value.data;

                    return queryRuntimeReportBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
