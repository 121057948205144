<section class="hvac-user-validation-popup-container" *ngIf="( showUserValidation$ | async )">
    <button hvacButton="default" (click)="cancelForm()"  class='hvac-user-validation-cancel-link'>{{'USER_VALIDATION.HEADER_CANCEL_BUTTON' | translate }}</button>
    <div class="hvac-user-validation-page-head">
        <h1 class="hvac-user-validation-page-title">{{ 'USER_VALIDATION.PAGE_TITLE' | translate}}</h1>
        <p class="hvac-user-validation-page-description ">{{ 'USER_VALIDATION.PAGE_DESCRIPTION' | translate }}</p>
    </div>
    <hr/>
    <hvac-user-validation-form (onValidChange)="updateInfoValid($event)" (emailVaidationCompleted)= "onSuccessEmailValidation($event)" [requestUserProfileUpdate]="eventsSubject.asObservable()"></hvac-user-validation-form>
    <hr/>
    <div class="hvac-user-validation-page-subscription">
        <h1 class="hvac-user-validation-subscription-title">{{ 'USER_VALIDATION.MANAGE_SUBSCRIPTION' | translate}}</h1>
        <p class="hvac-user-validation-subscription-description">{{ 'USER_VALIDATION.SUBSCRIPTION_SUB_TEXT' | translate }}</p>
        <p class="hvac-user-validation-subscription-note">{{ 'USER_VALIDATION.SUBSCRIPTION_NOTE' | translate }}</p>

        <div class='hvac-user-validation-input-row'>
            <utc-checkbox
                [checked]="(subscriptionStatus$ | async)!"
                inputId="user-validation-input-terms"
                inputName="user-validation-input-terms"
                (change)="changeSubscriptionControl($event)">
            </utc-checkbox>
            <p class='user-validation-input-terms-checkbox-terms-link'>{{ 'USER_VALIDATION.SUBSCRIPTION_TERMS_AND_CONDITION' | translate  }}</p>
        </div>
    </div>
    <hr/>
    <div class="user-validation-control-container">
        <button class="user-validation-submit" [hvacButton]="'primary'"  [disabled]="!(formValid$ | async) || apiRequestActive" (click)="updateUserProfileDetails()">
            <span>{{ 'USER_VALIDATION.SUBMIT' | translate }}</span>
        </button>
    </div>
</section>
