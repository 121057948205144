import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { User } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { CompanyCode } from 'private/app/models/company.model';
import { UserEntity } from 'private/app/models/user.model';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { IdmService } from '../idm.service';
import { DealersService } from './dealers.service';

const { linkedDealersEnabled } = environment.features.connectedPortal;

@Injectable({ providedIn: 'root' })
export class ConnectedPortalUserService {
    @Select(AuthState.user) user$: Observable<User>;

    public isPrimaryDealer$: Observable<boolean>;

    constructor(
        private readonly idmService: IdmService,
        private readonly dealerService: DealersService
    ) {
        this.isPrimaryDealer$ = combineLatest([this.getUserData(), this.getCompanyCode()])
            .pipe(
                switchMap(([userData, companyCode]) => {
                    if (!linkedDealersEnabled || companyCode !== CompanyCode.Dealer) {
                        return of(false);
                    }

                    return this.dealerService.queryDealerById(userData.defaultCompanyId).pipe(
                        map((dealer) => dealer.data.isPrimaryDealer || false)
                    );
                }),
                shareReplay(),
                catchError(() => of(false))
            );
    }

    getHVPID$(): Observable<string> {
        return this.getUserData().pipe(
            map((user) => user.defaultCompanyId)
        );
    }

    getCompanyCode(): Observable<string> {
        return this.getUserData().pipe(
            map((user) => {
                const [companyData] = user?.companies || [];

                return companyData.companyType?.code || '';
            })
        );
    }

    getUserData(): Observable<UserEntity> {
        return this.user$.pipe(
            switchMap((res: User) => this.idmService.getUser(res.profile.login))
        );
    }
}
