import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ContentHeadingData } from 'common/models/content-heading';
import { DealerLocatorEnrollmentList, DealerLocatorEnrollments } from 'private/app/models/manage-dealer-locator';
import { ManageDealerLocatorService } from 'private/app/services/manage-dealer-locator.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-dealer-locator',
    templateUrl: './dealer-locator.component.html',
    styleUrls: ['./dealer-locator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DealerLocatorComponent {
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.MANAGE_LOCATOR_TITLE') },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PARENT_2'),
                url: '/admin/admin tools'
            }
        ]
    };

    public thirdPartyRatingsTab$ = new BehaviorSubject<boolean>(false);

    private ngOnDestroy$ = new Subject();
    private hvacpCompanyId: string;

    constructor(
        private translate: TranslateService,
        private dealerLocatorService: ManageDealerLocatorService,
        private router: Router,
        private store: Store
    ) {
        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlSplit = event.urlAfterRedirects.split('/') || [];
                this.hvacpCompanyId = urlSplit.length ? urlSplit[urlSplit.length - 2] : '';
                this.getDealerEnrollments(this.hvacpCompanyId);
            }
        });
    }

    ngOnInit() {
        this.dealerLocatorService.backAction.pipe(takeUntil(this.ngOnDestroy$), skip(1)).subscribe((value) => {
            if (value) {
                this.store.dispatch(new Navigate(['Admin/Admin-Tools/account-admin.html/company', this.hvacpCompanyId]));
            }
        });

        this.dealerLocatorService.refreshEnrollmentData$.subscribe((refresh) => {
            if (refresh) {
                this.getDealerEnrollments(this.hvacpCompanyId);
            }
        });
    }

    getDealerEnrollments(hvacpCompId: string) {
        this.dealerLocatorService.getEnrollments(hvacpCompId).pipe(takeUntil(this.ngOnDestroy$)).subscribe((res) => {
            res.hvacpCompanyId = this.hvacpCompanyId;
            this.dealerLocatorService.data$.next(res);
            const isEnrolled = this.isCarrierBryantEnrolled(res);
            this.thirdPartyRatingsTab$.next(Boolean(isEnrolled?.length && res.isFADEnabled));
        });
    }


    backToCompanyDetails() {
        this.dealerLocatorService.backAction.next(true);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private isCarrierBryantEnrolled(data: DealerLocatorEnrollmentList) {
        const filteredArr = data?.dealerLocatorEnrollments?.filter((enrollment: DealerLocatorEnrollments) => (enrollment.dealerBrandLocatorNm.toLowerCase() === 'carrier' || enrollment.dealerBrandLocatorNm.toLowerCase() === 'bryant') && enrollment.isEnrolled);

        return filteredArr;
    }
}
