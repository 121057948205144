<div class="hvac-row">
    <div class="hvac-col-md-6 hvac-row-padding available-sold-to-content">
        <div class="hvac-row">
            <div class="hvac-col-lg-12">
                <div class="hvac-row header-row"><label 
                    class="hvac-label"
                    [attr.for]="'available-companies-sold-to-input'" 
                >
                    {{ 'USER_ADMIN.USER_RELATIONSHIPS.AVAILABLE_SOLD_TOS' | translate }}
                </label></div>
                <div class="hvac-row search-input-row">
                    <utc-input
                        [className]="'full-width'"
                        inputId="assigned-companies-sold-to-input"
                        type="text"
                        [placeholder]="'USER_ADMIN.USER_RELATIONSHIPS.SEARCH_AVAILABLE_SOLD_TOS_LABEL' | translate"
                        [inputControl]="searchAvailableSoldToControl"
                        [size]="'small'"
                        [shadow]="'none'">
                </utc-input>
                </div>
                <div class="hvac-row companies-list-row">
                    <table class="hvac-list-zebra">
                        <tr *ngFor="let companyName of availableSoldTos.crntPageCompanyNames">
                            <td class="company-row">
                                <utc-checkbox 
                                    [className]="'company-row-item'"
                                    [checked]="availableSoldTos.crntPageCompanies.get(companyName)?.selected || false" 
                                    [inputName]="companyName" 
                                    (change)="onAvailableSoldTosCompanySelect($event)">
                                </utc-checkbox>
                                <span class="company-row-item company-name">{{companyName}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="relationships-controls-row">
                    <utc-button
                        buttonType="button"
                        classNames="hvac-light-button-link"
                        label="Select All"
                        (click)="onSelectAllAvailableSoldTosCompanies()"
                    ></utc-button>
                    <utc-button
                        buttonType="button"
                        label="Assign Selected"
                        (click)="assgnSlctdAvlblCmpnsToAssignd()"
                    ></utc-button>
                </div>
                <hvac-pagination
                    [currentPage]="availableSoldTos.crntPgNum"
                    [totalPages]="availableSoldTos.pagesCount"
                    (pageChange)="onAvailableSoldTosPgChange($event)"
                ></hvac-pagination>
            </div>
        </div>
    </div>
    <div class="hvac-col-md-6 hvac-row-padding assigned-sold-to-content">
        <div class="hvac-row  ">
            <div class="company-list hvac-col-lg-12">
                <div class="hvac-row header-row">
                    <label 
                    class="hvac-label"
                    [attr.for]="'assigned-companies-sold-to-input'" 
                >
                    {{ 'USER_ADMIN.USER_RELATIONSHIPS.ASSIGNED_SOLD_TOS' | translate }}
                    </label>
                </div>
               <div class="hvac-row search-input-row">
                <utc-input 
                    [className]="'full-width'"
                    inputId="assigned-companies-sold-to-input"
                    type="text"
                    [placeholder]="'USER_ADMIN.USER_RELATIONSHIPS.SEARCH_ASSIGNED_SOLD_TOS_LABEL' | translate"
                    [inputControl]="searchAssignedSoldToControl"
                    [size]="'small'"
                    [shadow]="'none'">
                </utc-input></div>
                <div class="hvac-row companies-list-row">
                    <table class="hvac-list-zebra">
                        <tr *ngFor="let companyName of assignedSoldTos.crntPageCompanyNames">
                            <td class="company-row ">
                                <utc-checkbox
                                    [className]="'company-row-item'"
                                    [checked]="assignedSoldTos.crntPageCompanies.get(companyName)?.selected || false" 
                                    [inputName]="companyName" 
                                    (change)="onAssignedSoldTosCompanySelect($event)">
                                </utc-checkbox>
                                <span class="company-row-item company-name">{{companyName}}</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="relationships-controls-row">
                    <utc-button
                        buttonType="button"
                        classNames="hvac-light-button-link"
                        label="Select all"
                        (click)="onSelectAllAssgndSoldTosCompanies()"
                    ></utc-button>
                    <utc-button
                        buttonType="button"
                        label="Unassign Selected"
                        (click)="unassignSlctdCpmnstoAvailableSoldTos()"
                    ></utc-button>
                </div>
                <hvac-pagination
                    [currentPage]="assignedSoldTos.crntPgNum"
                    [totalPages]="assignedSoldTos.pagesCount"
                    (pageChange)="onAssignedSoldTosPgChange($event)"
                ></hvac-pagination>
            </div>
        </div>
    </div>
</div>
<div class="hvac-divider"></div>
<div class="controls">
    <div class="reset-control">
        <utc-button
            buttonType="button"
            [label]="'USER_ADMIN.RESET' | translate"
            (click)="unassignSlctdCpmnstoAvailableSoldTos()"
        ></utc-button>
    </div>
    <div>
        <utc-button
            buttonType="button"
            classNames="hvac-primary-button"
            [label]="'USER_ADMIN.SUBMIT_CHANGES' | translate"
            (click)="unassignSlctdCpmnstoAvailableSoldTos()"
        >{{ 'USER_ADMIN.SUBMIT_CHANGES' | translate }}</utc-button>
    </div>
</div>
