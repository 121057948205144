import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { HvacNotification } from 'common/models/Notifications';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiOptionsService } from './api-options/api-options.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) { }

    removeNotifications(notifications: HvacNotification[]) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<string>(environment.api.accountNotificationRemoveApi, { notifications: notifications }, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
