import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, forkJoin, interval, Observable, Subject } from 'rxjs';
import { ProductService } from './product.service';
import { MISSING_ROUTE_PARAMS_ERROR, MQTT_ACTIVATION_REFRESH_INTERVAL, MQTT_ACTIVATION_TIMEOUT } from 'private/app/views/connected-portal/constants';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class MqttActivationManagerService implements OnInit, OnDestroy {
    ngOnDestroy$ = new Subject();

    private activationSet = new Set<string>();
    private activationLoopTimeout: ReturnType<typeof setTimeout> | null = null;
    private routeData$: Observable<{dealerId: string}>;

    constructor(
        private productService: ProductService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.createActivationLoop()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe();

        this.routeData$ = this.route.paramMap.pipe(
            map((params) => {
                const dealerId = params.get('id');

                if (dealerId) {
                    return { dealerId };
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            })
        );
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    addControl(serialNo: string, dealerId: string) {
        if (this.activationSet.has(serialNo)) {
            this.resetActivationLoopTimeout();

            return;
        }

        this.productService.activateMQTTControlBySerialNo(serialNo, dealerId)
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe(() => {
                this.activationSet.add(serialNo);
                this.resetActivationLoopTimeout();
            });
    }

    removeControl(serialNo: string) {
        this.activationSet.delete(serialNo);
    }

    getActivationSetCount() {
        return this.activationSet.size;
    }

    private resetActivationLoopTimeout() {
        this.clearActivationLoopTimeout();

        this.activationLoopTimeout = setTimeout(() => {
            this.activationSet = new Set();
        }, MQTT_ACTIVATION_TIMEOUT);
    }

    private clearActivationLoopTimeout() {
        if (this.activationLoopTimeout) {
            clearTimeout(this.activationLoopTimeout);
            this.activationLoopTimeout = null;
        }
    }

    private createActivationLoop() {
        return combineLatest([this.routeData$, interval(MQTT_ACTIVATION_REFRESH_INTERVAL)])
            .pipe(
                filter(() => this.activationSet.size > 0),
                switchMap(([{ dealerId }]) => {
                    const activationCalls = Array.from(this.activationSet).map((serialNo) => this.productService.activateMQTTControlBySerialNo(serialNo, dealerId));

                    return forkJoin(activationCalls);
                })
            );
    }
}
