import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { Observable, Subject, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

abstract class BaseCacheObservableResponse<T> {
    events: EventEmitter<string> = new EventEmitter();

    protected getNetworkObservableResponse(httpClient: HttpClient, apiOptions: ApiOptionsService, url: string): Observable<T> {
        const options$ = apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => httpClient
                .get<T>(url, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    abstract value(key?: string): Observable<T>;

    abstract reset(): void;

    abstract resetTimer(observable: Observable<unknown>): void;
}

export class CacheObservableResponse<T> extends BaseCacheObservableResponse<T> {
    private getObservable: () => Observable<T>;
    private isObservableExecuted = false;
    private cachedObservableResponse: T | null;
    private intermittentObservable$: Subject<T>;
    private invokerDesc: string | null = null;

    private constructor(
        getObsevable: () => Observable<T>,
        invokerDesc = ''
    ) {
        super();
        this.getObservable = getObsevable;
        this.invokerDesc = invokerDesc;
        this.events.next(`Cache observable response triggered for ${this.invokerDesc}`);
    }

    public static createCachedClass<T>(
        inputVariable: CacheObservableResponse<T>,
        getObservable: () => Observable<T>,
        invokerDesc = ''
    ): CacheObservableResponse<T> {
        if (!inputVariable) {
            return new CacheObservableResponse(getObservable, invokerDesc);
        }

        return inputVariable;
    }

    public value(): Observable<T> {
        if (this.cachedObservableResponse) {
            return of(this.cachedObservableResponse);
        }

        if (!this.isObservableExecuted) {
            this.isObservableExecuted = true;
            this.intermittentObservable$ = new Subject();
            this.getObservable().subscribe(
                (val) => {
                    this.cachedObservableResponse = val;
                    this.intermittentObservable$.next(val);
                    this.intermittentObservable$.complete();
                },
                (error) => {
                    this.intermittentObservable$.error(error);
                }
            );
        }

        return this.intermittentObservable$;
    }

    reset(): void {
        this.cachedObservableResponse = null;
        this.isObservableExecuted = false;
    }

    resetTimer(observable: Observable<unknown>): void {
        observable.subscribe(() => this.reset());
    }
}

export class CacheNetworkObservableResponse<T> extends BaseCacheObservableResponse<T> {
    private httpClient: HttpClient;
    private apiOptions: ApiOptionsService;
    private cachedObservableResponse: Map<string, T> = new Map();
    private isCallInitiatedForParam: Map<string, boolean> = new Map();
    private intermittentObservableForParam: Map<string, Subject<T>> = new Map();
    private invokerDesc: string;

    private constructor(
        httpClient: HttpClient,
        apiOptions: ApiOptionsService,
        invokerDesc: string
    ) {
        super();
        this.httpClient = httpClient;
        this.apiOptions = apiOptions;
        this.invokerDesc = invokerDesc;
        this.events.next(`Cache observable response triggered for ${this.invokerDesc}`);
    }

    public static createCachedClass<T>(
        inputVariable: CacheNetworkObservableResponse<T>,
        httpClient: HttpClient,
        apiOptions: ApiOptionsService,
        invokerDesc: string
    ): CacheNetworkObservableResponse<T> {
        if (!inputVariable) {
            return new CacheNetworkObservableResponse(httpClient, apiOptions, invokerDesc);
        }

        return inputVariable;
    }

    public value(key: string): Observable<T> {
        const cachedObservableResponse = this.cachedObservableResponse.get(key);

        if (cachedObservableResponse) {
            return of(cachedObservableResponse);
        }


        if (!this.isCallInitiatedForParam.get(key)) {
            this.isCallInitiatedForParam.set(key, true);
            this.intermittentObservableForParam.set(key, new Subject());

            const intermittentObservable$ = this.intermittentObservableForParam.get(key)!;

            this.getNetworkObservableResponse(this.httpClient, this.apiOptions, key).subscribe(
                (val) => {
                    this.cachedObservableResponse.set(key, val);
                    intermittentObservable$.next(val);
                    intermittentObservable$.complete();
                },
                (error) => {
                    intermittentObservable$.error(error);
                }
            );
        }

        return this.intermittentObservableForParam.get(key)!;
    }

    reset(): void {
        this.cachedObservableResponse.clear();
        this.isCallInitiatedForParam.clear();
        this.intermittentObservableForParam.clear();
    }

    resetTimer(observable: Observable<unknown>): void {
        observable.subscribe(() => this.reset());
    }
}
