/* eslint-disable default-param-last, no-sparse-arrays,array-bracket-spacing */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { Filter, SearchQueryParams } from 'private/app/models/default-query-params';
import { AdvancedSearchService } from 'private/app/services/connected-portal/advanced-search.service';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { QUERY_PARAM_DEBOUNCE_INTERVAL, SEARCH_RESULTS_PAGE_SIZE } from '../../constants';
import { AdvancedSearchCustomerPropertiesResponse, ConnectedPortalAdvancedSearchCustomerProperties } from 'private/app/models/connected-portal-advanced-search.model';
import { CSVExportService } from 'common/services/csv-export.service';
import { Option } from 'common/components/select-new/select-new.component';
import { AddToast, ToastService } from 'common/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HeapService } from 'common/services/heap.service';
import { eventNames } from '../../heap/constants';
import { CountryAndStates } from './connected-portal-advanced-search-fields/connected-portal-advanced-search-fields.component';


@Component({
    selector: 'hvac-connected-portal-advanced-search',
    templateUrl: './connected-portal-advanced-search.component.html',
    styleUrls: ['./connected-portal-advanced-search.component.scss'],
    providers: [QueryParamsService]
})
export class ConnectedPortalAdvancedSearchComponent extends BaseComponent implements OnInit {
    @Input() distributorId: string;
    @Input() parentComponentName: string;
    public advancedSearchForm: UntypedFormGroup;
    public dataSharingOptionsForm: UntypedFormGroup;
    public controlModelNumbers: Option[];
    public exportErrorToastOutlet = 'exportErrorToastOutlet';
    public currentPage?: number;
    public pageSize = SEARCH_RESULTS_PAGE_SIZE
    public itemCount = 0;
    public totalPages?: number;
    public isLoading$ = new BehaviorSubject(false);
    public customers?: ConnectedPortalAdvancedSearchCustomerProperties[];
    public hasSearched$ = new BehaviorSubject<boolean>(false);
    public customerResult$ = new BehaviorSubject<boolean>(false);
    public countriesAndStates$: Observable<CountryAndStates[]>;
    public controlSerialNo: string;

    constructor(
        private advancedSearchSvc: AdvancedSearchService,
        private translate: TranslateService,
        private queryParamsService: QueryParamsService,
        private csvExportService: CSVExportService,
        private toastService: ToastService,
        private heapService: HeapService
    ) {
        super();
    }

    ngOnInit(): void {
        this.controlModelNumbers = this.advancedSearchSvc.getControlModelNumbers();
        this.countriesAndStates$ = <Observable<CountryAndStates[]>> this.advancedSearchSvc.getCountries()
            .pipe(
                switchMap((countries) => {
                    const stateQueries = countries.map((country) => this.advancedSearchSvc.getStateByCountry(country.value));

                    return forkJoin(stateQueries).pipe(
                        map((data) => data.map((states) => ({
                            ...countries.shift(),
                            states
                        })))
                    );
                })
            );


        const queryParams$ = this.queryParamsService.init({ page: this.currentPage || 1 });

        combineLatest([queryParams$, this.hasSearched$]).pipe(
            filter(([, hasSearched]) => hasSearched),
            debounceTime(QUERY_PARAM_DEBOUNCE_INTERVAL),
            switchMap(([params]) => {
                const { page } = params;
                const nextPage = this.getNextPage(page);
                const filters = this.advancedSearchSvc.getFilterParameters(this.advancedSearchForm, this.dataSharingOptionsForm);

                this.isLoading$.next(true);
                this.currentPage = nextPage;

                if (this.distributorId) {
                    return this.queryCustomerByDistributorId(this.distributorId, nextPage, ...[, ], filters)
                        .pipe(
                            takeUntil(this.ngOnDestroy$),
                            catchError(() => {
                                this.resetOnError();

                                return EMPTY;
                            })
                        );
                }

                return this.queryCustomerByFactory(nextPage, ...[, ], filters)
                    .pipe(
                        takeUntil(this.ngOnDestroy$),
                        catchError(() => {
                            this.resetOnError();

                            return EMPTY;
                        })
                    );
            }),
            takeUntil(this.ngOnDestroy$)
        )
            .subscribe((res) => {
                if (res.filepath) {
                    return;
                }

                this.handleResponseData(res as AdvancedSearchCustomerPropertiesResponse);
            });
    }

    queryCustomerByDistributorId(distributorId: string, page: number, pageSize = this.pageSize, filters?: Filter[], isFileExport = false) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (filters && filters.length > 0) {
            queryParams.filter = filters;
        }

        return this.advancedSearchSvc
            .queryCustomerByDistributorId(distributorId, queryParams, isFileExport);
    }

    queryCustomerByFactory(page: number, pageSize = this.pageSize, filters?: Filter[], isFileExport = false) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (filters && filters.length > 0) {
            queryParams.filter = filters;
        }

        return this.advancedSearchSvc
            .queryCustomerByFactory(queryParams, isFileExport);
    }

    searchCustomers(fieldForms: { advancedSearchForm: UntypedFormGroup, dataSharingOptionsForm: UntypedFormGroup }) {
        this.advancedSearchForm = fieldForms?.advancedSearchForm;
        this.dataSharingOptionsForm = fieldForms?.dataSharingOptionsForm;
        this.controlSerialNo = fieldForms?.advancedSearchForm.controls.controlSerialNo.value;
        this.customerResult$.next(true);
        this.isLoading$.next(true);
        this.hasSearched$.next(true);
        this.queryParamsService.updateParams({ page: 1 });
        if (this.parentComponentName === this.translate.instant('CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DISTRIBUTOR_SEARCH')) {
            this.heapService.track(eventNames.handleDistributorAdvancedSearchClick);
        }
        else {
            this.heapService.track(eventNames.handleInternalAdvancedSearchClick);
        }
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    handleExportProperties() {
        const exportErrorToast: AddToast = {
            outletName: this.exportErrorToastOutlet,
            closeable: true,
            autoClose: true
        };
        const filters = this.advancedSearchSvc.getFilterParameters(this.advancedSearchForm, this.dataSharingOptionsForm);
        const queryToCall = this.distributorId
            ? () => this.queryCustomerByDistributorId(this.distributorId, 1, this.itemCount, filters, true)
            : () => this.queryCustomerByFactory(1, this.itemCount, filters, true);

        this.isLoading$.next(true);
        queryToCall().pipe(
            takeUntil(this.ngOnDestroy$),
            catchError((error:HttpErrorResponse) => {
                this.toastService.add({
                    ...exportErrorToast,
                    content: error.message,
                    theme: 'error'
                });
                this.isLoading$.next(false);

                return EMPTY;
            })
        )
            .subscribe(({ filepath }) => {
                this.isLoading$.next(false);

                if (filepath) {
                    this.csvExportService.downloadFile(filepath);
                }
            });
    }

    private getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    private handleResponseData(res: AdvancedSearchCustomerPropertiesResponse) {
        this.isLoading$.next(false);
        this.customers = res.data || [];
        this.totalPages = res.totalPages || 1;
        this.itemCount = res.totalCount || 0;

        if (this.currentPage && this.currentPage > this.totalPages) {
            this.queryParamsService.updateParams({ page: 1 });
        }

        this.customerResult$.next(true);
    }

    private resetOnError() {
        this.isLoading$.next(false);
        this.customers = [];
        this.totalPages = 1;
        this.itemCount = 0;
        this.customerResult$.next(true);
    }
}
