import { Component, OnInit } from '@angular/core';
import { CompaniesTable } from 'private/app/models/user-relationships-company-table.model';
import { UserRelationshipsEditService } from 'private/app/services/account-admin/user/edit-user-relationships.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'hvac-edit-user-relationships',
    templateUrl: './edit-user-relationships.component.html',
    styleUrls: ['./edit-user-relationships.component.scss']
})
export class EditUserRelationshipsComponent implements OnInit {
  public availableSoldTos: CompaniesTable = new CompaniesTable('Available-todos-table', this.userRelationshipsEditService.sampleCompaniesList, 10);
  public assignedSoldTos: CompaniesTable = new CompaniesTable('Assigned-todos-table', [], 10);
  public searchAvailableSoldToControl = this.userRelationshipsEditService.userRelationshipEditFormControls.controls.searchAvailableSoldTos;
  public searchAssignedSoldToControl = this.userRelationshipsEditService.userRelationshipEditFormControls.controls.searchAssignedSoldTos;

  constructor(
      public userRelationshipsEditService: UserRelationshipsEditService,
      public userAdminService: UserAdminService
  ) {}

  ngOnInit(): void {
      this.userAdminService.menuActive = false;
      this.searchAvailableSoldToControl.valueChanges.pipe(debounceTime(1000)).subscribe((searchTerm) => {
          this.availableSoldTos.searchCompanies(searchTerm);
      });
      this.searchAssignedSoldToControl.valueChanges.pipe(debounceTime(1000)).subscribe((searchTerm) => {
          this.assignedSoldTos.searchCompanies(searchTerm);
      });
  }

  onAvailableSoldTosPgChange(pgNum: number) {
      this.availableSoldTos.showCompaniesAtPageNum(pgNum);
  }

  onAssignedSoldTosPgChange(pgNum: number) {
      this.assignedSoldTos.showCompaniesAtPageNum(pgNum);
  }

  onAvailableSoldTosCompanySelect(event: Event) {
      const input = <HTMLInputElement>event.target;

      if (input) {
          this.availableSoldTos.selectCompany(input.name, input.checked);
      }
  }

  onAssignedSoldTosCompanySelect(event: Event) {
      const input = <HTMLInputElement>event.target;

      if (input) {
          this.assignedSoldTos.selectCompany(input.name, input.checked);
      }
  }

  onSelectAllAvailableSoldTosCompanies() {
      this.availableSoldTos.selectAllCompanies();
  }

  assgnSlctdAvlblCmpnsToAssignd() {
      const selectedCompanies = this.availableSoldTos.getSelectedCompanies();

      this.availableSoldTos.removeCompanies(selectedCompanies, true);
      selectedCompanies.forEach((companyName) => this.assignedSoldTos.addCompany(companyName, true));
      this.assignedSoldTos.setInitalList(selectedCompanies);
      this.assignedSoldTos.refreshTableDisplay();
      this.availableSoldTos.refreshTableDisplay();
  }

  onSelectAllAssgndSoldTosCompanies() {
      this.assignedSoldTos.selectAllCompanies();
  }

  unassignSlctdCpmnstoAvailableSoldTos() {
      const selectedCompanies = this.assignedSoldTos.getSelectedCompanies();

      this.assignedSoldTos.removeCompanies(selectedCompanies, false);
      selectedCompanies.forEach((companyName) => this.availableSoldTos.addCompany(companyName, true));
      this.assignedSoldTos.setInitalList(this.assignedSoldTos.getCurrentCompanyNames());
      this.assignedSoldTos.refreshTableDisplay();
      this.availableSoldTos.refreshTableDisplay();
  }
}
