<section class="hvac-login-personas" [ngClass]="{ 'hvac-personas-show-detail': showDetails$ | async }">
    <div class="hvac-personas-header">
        <h2 class="hvac-personas-title">{{ 'LOGIN_PAGE.PERSONAS_TITLE' | translate }}</h2>
        <p class="hvac-personas-section-description-large">{{  'LOGIN_PAGE.PERSONAS_DESCRIPTION' | translate }}</p>
    </div>
    <ul class="hvac-personas-list">
        <li
            class="hvac-personas-list-item"
            *ngFor="let persona of personas$ | async; let idx = index"
            [ngClass]="{ 'hvac-personas-list-item-selected': (selectedPersona$ | async) === idx }">
            <hvac-personas-item [persona]="persona" [id]="idx"></hvac-personas-item>
        </li>
    </ul>
</section>
