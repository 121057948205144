import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from 'private/app/models/account-admin-program.model';
import { AlertSeverity, AlertSeverityFilter } from 'private/app/models/alert.model';
import { ToastOutlet } from 'private/app/views/connected-portal/constants';

export interface ConnectedPortalAlert {
    id: string;
    datetime: string;
    errorCode: string;
    errorMessage: string;
    customerName:string;
    customerPhone: string;
    troubleshootUri: string;
    propertyAddress: string;
    alertLevel: AlertSeverity;
    modelNumber: string;
    serialNumber: string;
    systemType: string;
    selectedForRemoval: boolean;
    propertyId: string;
    type: string;
    dealerId: string;
    wallControlSerialNo: string;
}

@Component({
    selector: 'hvac-connected-portal-alerts',
    templateUrl: './connected-portal-alerts.component.html',
    styleUrls: ['./connected-portal-alerts.component.scss']
})
export class ConnectedPortalAlertsComponent {
    @Input() alertLevelFilterSelection: AlertSeverityFilter;
    @Input() alertsListTotalPages: number;
    @Input() alertsListCurrentPage: number;
    @Input() alerts: ConnectedPortalAlert[];
    @Input() alertsStartDate: number;
    @Input() alertsEndDate: number;
    @Input() isLoading: boolean;
    @Output() public onAlertsFilterChange = new EventEmitter<AlertSeverityFilter>();
    @Output() public onAlertsPageChange = new EventEmitter<number>();
    @Output() public onAlertsDateRangeChange = new EventEmitter();
    @Output() public onRemoveSelectedAlerts: EventEmitter<boolean> = new EventEmitter();
    @Output() public onExportAlerts: EventEmitter<boolean> = new EventEmitter();

    public readonly ConnectedDeviceAlertSeverity = AlertSeverity;
    public toastOutlet = ToastOutlet.DealerAlertsToast;

    updateAlertLevelFilter(alertFilter: AlertSeverityFilter) {
        this.onAlertsFilterChange.emit(alertFilter);
    }

    handleAlertsListPageChange(nextPage: number) {
        this.onAlertsPageChange.emit(nextPage);
    }

    handleDateRangeChange($event: DateRange) {
        this.onAlertsDateRangeChange.emit($event);
    }

    handleAlertsListRemoveSelected() {
        this.onRemoveSelectedAlerts.emit(true);
    }

    handleExportAlerts() {
        this.onExportAlerts.emit(true);
    }

    trackByAlert(_index: number, alert: ConnectedPortalAlert) {
        return alert.id;
    }
}
