import { Component, Input, ViewEncapsulation } from '@angular/core';
import { EntityModelData } from 'common/models';

@Component({
    selector: 'hvac-video-widget',
    templateUrl: './video-widget.component.html',
    styleUrls: ['./video-widget.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VideoWidgetComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() videos: EntityModelData<any>[];
}
