import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { environment } from 'common/environments/environment';
import { ContentHeadingData } from 'common/models/content-heading';
import { ConnectedControlReport } from 'private/app/models/distributor.model';
import { DistributorsService } from 'private/app/services/connected-portal/distributors.service';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ConnectedPortalNavLink } from '../../components/connected-portal-navigation/connected-portal-navigation.component';
import { ConnectedPortalStat } from '../../components/connected-portal-stats/connected-portal-stats.component';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'hvac-distributor-dashboard',
    templateUrl: './distributor-dashboard.component.html',
    styleUrls: ['./distributor-dashboard.component.scss']
})
export class DistributorDashboardComponent extends BaseComponent implements OnInit {
    public stats$?: Observable<ConnectedPortalStat[]>;
    public connectedControlsData$: Observable<ConnectedControlReport>;
    public navLinks: ConnectedPortalNavLink[];
    public distributorId: string | null = null;
    public distributorName: string | null = null;
    public isLoadingList = { stats: false };
    public queryControl = new UntypedFormControl('', Validators.required);

    public isDistributorStatsFeatureEnabled = environment.features.connectedPortal.distributorStats;
    public isOpportunityReportUsageGraphEnabled = environment.features.connectedPortal.opportunityReportUsageGraph

    public breadCrumbData: ContentHeadingData;

    constructor(
        private distributorService: DistributorsService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private decimalPipe: DecimalPipe
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.route.parent) {
            return;
        }

        this.route.parent.paramMap.subscribe((params) => {
            this.distributorId = params?.get('id');
            this.distributorName = this.route.snapshot.queryParams['name'];

            if (this.distributorId) {
                if (this.distributorName) {
                    this.breadCrumbData = this.getBreadcrumbData(this.distributorName);
                }
                else {
                    this.breadCrumbData = this.getBreadcrumbData();
                }

                if (this.isDistributorStatsFeatureEnabled) {
                    this.isLoadingList.stats = true;
                    this.stats$ = this.distributorService
                        .queryDistributorStats(this.distributorId)
                        .pipe(
                            takeUntil(this.ngOnDestroy$),
                            map((res) => {
                                const { data } = res;

                                this.isLoadingList.stats = false;

                                return Object.entries(data).map(([key, value]) => ({
                                    label: `CONNECTED_PORTAL.STATS.PROPS.${key}`,
                                    value: this.decimalPipe.transform(value, '1.0-0') || ''
                                }), []);
                            })
                        );
                }
            }
        });
    }

    handleSearchSubmit(query: string | null) {
        const params = { search: query };

        this.router.navigate(['../dealers'], {
            relativeTo: this.route,
            queryParams: params
        });
    }

    getBreadcrumbData(distributerName?: string): ContentHeadingData {
        if (distributerName) {
            return {
                Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
                breadCrumbs: [
                    {
                        title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                        url: '/connected-portal'
                    },
                    {
                        title: distributerName ? distributerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                        url: '/connected-portal/distributors'
                    }
                ]
            };
        }

        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                }

            ]
        };
    }
}
