/* eslint-disable max-lines */
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { AddToast, ToastService } from 'common/services/toast.service';
import { AlertStatusFilter, ProductAlert } from 'private/app/models/alert.model';
import { Pagination, EcobeeProductDetailsData, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { WallControlStatus, WallControlDetails } from 'private/app/models/wall-control.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { DataSharingService, DataSharingOptions } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { ApiResponseCode, DATA_REFRESH_DISCONNECT_DELAY, MISSING_PRODUCT_DATA } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, delay, filter, finalize, map, shareReplay, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { WallControlEventLogConfig, WallControlEventLogEvent } from '../components/wall-control-event-log/wall-control-event-log.component';
import { EcobeeEditConfigState, ProductDetailContextService } from '../../product-detail-context.service';
import { ConnectedPortalContextService } from 'private/app/services/connected-portal/connected-portal-context.service';
import { ErrorService } from 'common/content/services/error.service';
import { DefaultQueryParams } from 'private/app/models/default-query-params';

const PAGINATION_PAGE_SIZE = 10;
const REFRESH_THROTTLE_TIME = 5 * 1000;

interface EventLogData extends Pagination {
    config: WallControlEventLogConfig;
    items: WallControlEventLogEvent[];
}

@Component({
    selector: 'hvac-ecobee-wall-control-detail',
    templateUrl: './ecobee-wall-control-detail.component.html',
    styleUrls: ['./ecobee-wall-control-detail.component.scss']
})
export class EcobeeWallControlDetailComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;

    public readonly EcobeeEditConfigState = EcobeeEditConfigState;
    public toastOutlet = 'dataRefreshToast';
    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public wallControlStatusTime$: Observable<string>;
    public productData$: Observable<EcobeeProductDetailsData>;
    public wallControlDetails$: Observable<WallControlDetails>;
    public wallControlStatus$: Observable<WallControlStatus>
    public wallControlEventLogData$: Observable<EventLogData>
    public wallControlEventLogCurrentPage$ = new BehaviorSubject<number>(1);
    public alertsCurrentFilter$ = new BehaviorSubject<AlertStatusFilter>(AlertStatusFilter.ACTIVE);
    public isEcobeeDataRefreshing$ = new BehaviorSubject<boolean>(false);
    public isZoneRouteSet$: Observable<boolean>;
    public refreshButtonMsg$: Observable<string>;
    public refreshEcobeeData$ = new Subject<boolean>();
    public isTestEquipmentButtonLoading: boolean = false;
    public toggleAccordion$: Subject<keyof typeof this.accordions$.value> = new Subject();
    public isLoadingAlerts$ = new BehaviorSubject(false);
    public accordions$ = new BehaviorSubject({
        eventLog: false,
        config: false
    });

    private loadData$ = new BehaviorSubject<boolean>(true);

    constructor(
        public productContextService: ProductDetailContextService,
        private cpContextService: ConnectedPortalContextService,
        private translateService: TranslateService,
        private toastService: ToastService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private alertService: AlertsService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlStatus$ = this.loadData$.pipe(
            switchMap(() => this.productService
                .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
                .pipe(
                    map((res) => res.data)
                )),
            shareReplay()
        );

        this.wallControlStatusTime$ = this.wallControlStatus$.pipe(
            map((data) => data.dateTime)
        );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(
                map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false)
            );

        this.productData$ = this.loadData$.pipe(
            switchMap(() => combineLatest([
                this.dataSharingPermissions$,
                this.wallControlStatus$,
                this.isWallControlConnected$,
                this.system$
            ]).pipe(
                map(([dataSharingPermissions, wallControlStatus, isWallControlConnected, system]) => {
                    const productData = system?.products.find((product) => product.type === ProductType.WallControl);

                    const isTestEquipmentEnabled = this.productContextService.getIsTestEquipmentEnabled(
                        isWallControlConnected,
                        dataSharingPermissions.editConfig,
                        productData?.isPlcEquipped === 'true',
                        productData?.systemType
                    );

                    if (productData) {
                        return {
                            isWallControlConnected,
                            isRuntimeReportEnabled: wallControlStatus.isRuntimeReportEnabled,
                            isTestEquipmentEnabled,
                            productInfo: productData,
                            modelName: productData.name
                        };
                    }

                    throw new Error(MISSING_PRODUCT_DATA);
                }),
                catchError((err) => {
                    this.errorService.errorStatus$.next(404);

                    throw err;
                })
            ))
        );

        this.productContextService.productData$ = this.productData$;

        this.wallControlDetails$ = this.loadData$.pipe(
            switchMap(() => this.productService
                .queryWallControlBySerialNo(this.serialNo, this.dealerId, this.systemType)
                .pipe(
                    map((res) => res.data)
                )),
            shareReplay()
        );

        this.isZoneRouteSet$ = this.wallControlDetails$
            .pipe(
                switchMap((data) => {
                    if (data.zones.length) {
                        const zoneName = data.zones[0]?.name;
                        if (zoneName) {
                            const zonePath = encodeURIComponent(zoneName);

                            return from(this.router.navigate([zonePath], {
                                relativeTo: this.route,
                                queryParams: { scroll: false },
                                queryParamsHandling: 'merge'
                            })).pipe(map((navigationSuccess) => navigationSuccess));
                        }
                    }

                    return of(false);
                })
            );

        this.wallControlEventLogData$ = this.loadData$.pipe(
            switchMap(() => combineLatest([
                this.alertsCurrentFilter$,
                this.wallControlEventLogCurrentPage$,
                this.system$.pipe(map((system) => system?.systemId))
            ]).pipe(
                // Avoids making multiple api calls when props change close together
                debounceTime(100),
                tap(() => {
                    this.isLoadingAlerts$.next(true);
                }),
                filter(([, systemId]) => Boolean(systemId)),
                switchMap(
                    ([currentFilterType, currentPage, systemId]) => {
                        const queryParams: DefaultQueryParams = {
                            pagination: {
                                currentPage,
                                pageSize: PAGINATION_PAGE_SIZE
                            },
                            sort: {
                                field: 'dateTime',
                                order: 'desc'
                            }
                        };
                        if (currentFilterType !== AlertStatusFilter.ALL) {
                            queryParams.filter = [
                                {
                                    field: 'error.isActive',
                                    value: currentFilterType === AlertStatusFilter.ACTIVE ? 'true' : 'false'
                                }
                            ];
                        }

                        return this.alertService
                            .queryAlertsBySystemId(
                                systemId as string,
                                this.systemType,
                                this.dealerId,
                                queryParams
                            ).pipe(
                                map(({ data, totalPages }) => {
                                    const items = this.formatProductAlertsToEventLogItems(data);
                                    const config = new WallControlEventLogConfig();
                                    config.diagnostics = false;

                                    return {
                                        config,
                                        items,
                                        totalPages,
                                        currentPage
                                    };
                                }),
                                catchError(() => {
                                    this.isLoadingAlerts$.next(false);

                                    return EMPTY;
                                })
                            );
                    }
                ),
                tap(() => {
                    this.isLoadingAlerts$.next(false);
                }),
                takeUntil(this.ngOnDestroy$)
            ))
        );

        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);
                }
            });

        this.productContextService.closeDiagnosticsTest$
            .pipe(
                tap((toastMessage) => {
                    if (toastMessage) {
                        this.toastService.add(toastMessage);
                        window.scrollTo(0, 0);
                    }

                    this.productContextService.isDiagnosticsModalVisible$.next(false);
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.refreshEcobeeData$
            .pipe(
                throttleTime(REFRESH_THROTTLE_TIME),
                switchMap(() => this.onEcobeeDataRefresh$()),
                tap(() => this.loadData$.next(true)),
                delay(DATA_REFRESH_DISCONNECT_DELAY),
                tap(() => this.cpContextService.disconnectWallControlById$.next({
                    serialNo: this.serialNo,
                    dealerId: this.dealerId
                })),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.refreshButtonMsg$ = this.getRefreshButtonMsg();
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();
        this.cpContextService.disconnectWallControlById$.next({
            serialNo: this.serialNo,
            dealerId: this.dealerId
        });
        super.ngOnDestroy();
    }

    public handleFilterChange(alertStatusFilter: AlertStatusFilter) {
        this.wallControlEventLogCurrentPage$.next(1);
        this.alertsCurrentFilter$.next(alertStatusFilter);
    }

    public handleTestEquipmentClick() {
        this.productContextService.isDiagnosticsModalVisible$.next(true);
    }

    private onEcobeeDataRefresh$() {
        const dataRefreshSuccessToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.SUCCESS'),
            theme: 'success'
        });

        const dataRefreshConnectionErrorToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.CONNECTION_ERROR'),
            theme: 'error'
        });

        this.isEcobeeDataRefreshing$.next(true);

        return this.productService.activateEcobeeControlBySerialNo(this.serialNo, this.dealerId)
            .pipe(
                tap((res) => {
                    if (res) {
                        const { code } = res;

                        if (code === ApiResponseCode.SUCCESS) {
                            this.toastService.add(dataRefreshSuccessToast);
                            this.productContextService.onEcobeeDataRefresh$.next(true);
                        }
                        else {
                            this.toastService.add(dataRefreshConnectionErrorToast);
                        }
                    }
                }),
                catchError(() => {
                    this.toastService.add(dataRefreshConnectionErrorToast);

                    return EMPTY;
                }),
                finalize(() => {
                    this.isEcobeeDataRefreshing$.next(false);
                })
            );
    }

    private createToast(toast: AddToast): AddToast {
        const defaults = {
            outletName: this.toastOutlet,
            closeable: true,
            autoClose: true
        };

        return {
            ...defaults,
            content: toast.content,
            theme: toast.theme
        };
    }

    private formatProductAlertsToEventLogItems(productAlerts: ProductAlert[]): EventLogData['items'] {
        return [...productAlerts]
            .map((alert): WallControlEventLogEvent => ({
                code: alert.error.code,
                dateTime: alert.dateTime,
                severity: alert.error.severity,
                type: alert.error.type,
                description: alert.error.description,
                consecutiveEvents: alert.occurrenceCount,
                equipmentSource: alert.source,
                diagnosticsUrl: alert.error.troubleshootUri
            }))
            .sort((itemA, itemB) => (itemA && itemB ? itemB.dateTime.localeCompare(itemA.dateTime) : 0));
    }

    private getRefreshButtonMsg():Observable<string> {
        return combineLatest([this.isWallControlConnected$,
            this.dataSharingPermissions$]).pipe(map(([isWallControlConnected, dataSharingPermissions]) => {
            if (isWallControlConnected && dataSharingPermissions?.editConfig === false) {
                return this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.UNAVAILABLE');
            }
            if (!isWallControlConnected && dataSharingPermissions?.editConfig === false) {
                return this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.DISCONNECTED_EDIT_TRUE');
            }
            if (!isWallControlConnected && dataSharingPermissions?.editConfig === true) {
                return this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_DATA_REFRESH.DISCONNECTED_EDIT_TRUE');
            }
        }));
    }
}
