<form [formGroup]="userInfoForm">
    <div class="hvac-user-validation-form-container">
        <h2 class="hvac-user-validation-form-title">{{ 'USER_VALIDATION.PROFILE' | translate}}</h2>
        <div class="hvac-user-validation-input-container">
            <div class="hvac-user-validation-input-form-column">
                <utc-input [label]="'GENERAL.LABELS.USER_ID' | translate" type="text"
                    [inputControl]="userInfoForm.controls.userIdControl" [disabledInput]="true"></utc-input>
                <utc-input [label]="'GENERAL.LABELS.MY_HVAC_PIN' | translate"
                    [inputControl]="userInfoForm.controls.myHvacPinControl" type="text" [disabledInput]="true">
                </utc-input>
                <utc-input [label]="'GENERAL.LABELS.EMPLOYER_ID' | translate"
                    [inputControl]="userInfoForm.controls.employeeIdControl" type="text" [disabledInput]="true">
                </utc-input>
                <utc-input [label]="'GENERAL.LABELS.EMPLOYER_NAME' | translate"
                    [inputControl]="userInfoForm.controls.employeeNameControl" type="text" [disabledInput]="true">
                </utc-input>
                <utc-input [label]="'GENERAL.LABELS.USER_NAME' | translate"
                    [inputControl]="userInfoForm.controls.usernameControl" type="text" [disabledInput]="true">
                </utc-input>
                <utc-input
                    [error]="userInfoForm.controls.firstNameControl.dirty && !userInfoForm.controls.firstNameControl.valid"
                    [errorMessage]="firstNameErrorMessage$ | async"
                    [inputControl]="userInfoForm.controls.firstNameControl" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.FIRST_NAME' | translate" type="text"></utc-input>
                <utc-input
                    [error]="userInfoForm.controls.lastNameControl.dirty && !userInfoForm.controls.lastNameControl.valid"
                    [errorMessage]="lastNameErrorMessage$ | async"
                    [inputControl]="userInfoForm.controls.lastNameControl" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.LAST_NAME' | translate" type="text"></utc-input>
            </div>
            <span class="vertical-spacer"></span>
            <div class="hvac-user-validation-input-form-column">
                <utc-input
                    [error]="userInfoForm.controls.address1Control.dirty && !userInfoForm.controls.address1Control.valid"
                    [errorMessage]="addressErrorMessage$ | async" [inputControl]="userInfoForm.controls.address1Control"
                    inputId="user-validation-input" [label]="'GENERAL.LABELS.ADDRESS_1' | translate" type="text">
                </utc-input>
                <utc-input [inputControl]="userInfoForm.controls.address2Control" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.ADDRESS_2' | translate" type="text"></utc-input>
                <utc-input [error]="userInfoForm.controls.cityControl.dirty && !userInfoForm.controls.cityControl.valid"
                    [errorMessage]="cityErrorMessage$ | async" [inputControl]="userInfoForm.controls.cityControl"
                    inputId="user-validation-input" [label]="'GENERAL.LABELS.CITY' | translate" type="text">
                </utc-input>

                <utc-select [group]="userInfoForm" control="countryControl"
                    [selectId]="'selectcountry'"
                    [defaultOption]="'GENERAL.LABELS.COUNTRY' | translate"
                    [options]="(countryOptionData$ | async)!">
                </utc-select>

                <utc-select [group]="userInfoForm" control="stateControl"
                    [selectId]="'selectState'"
                    [defaultOption]="'GENERAL.LABELS.STATE' | translate"
                    [options]="(stateOptionData | async)!">
                </utc-select>

                <utc-input [error]="userInfoForm.controls.zipControl.dirty && !userInfoForm.controls.zipControl.valid"
                    [errorMessage]="zipCodeErrorMessage$ | async" [inputControl]="userInfoForm.controls.zipControl"
                    inputId="user-validation-input" [label]="'GENERAL.LABELS.ZIP' | translate" type="text">
                </utc-input>
            </div>
            <span class="vertical-spacer"></span>
            <div class="hvac-user-validation-input-form-column">
                <utc-input [inputControl]="userInfoForm.controls.phoneControl" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.PHONE' | translate" type="text"></utc-input>
                <utc-input [inputControl]="userInfoForm.controls.cellControl" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.CELL_PHONE' | translate" type="text"></utc-input>
                <utc-input [inputControl]="userInfoForm.controls.faxControl" inputId="user-validation-input"
                    [label]="'GENERAL.LABELS.FAX' | translate" type="text"></utc-input>
                <utc-input
                    [error]="userInfoForm.controls.emailControl.dirty && !userInfoForm.controls.emailControl.valid"
                    [errorMessage]="emailErrorMessage$ | async" [inputControl]="userInfoForm.controls.emailControl"
                    inputId="user-validation-input" [label]="'GENERAL.LABELS.EMAIL' | translate" type="text">
                </utc-input>
            </div>
        </div>
    </div>
    <div class='hvac-user-validation-input-row'>
        <utc-checkbox inputId="user-validation-input-terms" inputName="user-validation-input-terms"
            (change)="changeTermsControl($event)">
        </utc-checkbox>
        <p class='user-validation-input-terms-checkbox-terms-link'>{{ 'GENERAL.TEXT.TERMS_AND_CONDITIONS' | translate
            }}
            <a target='_blank' hvacLink='primary' [href]="'FOOTER.TERMS_OF_USE_LINK' | translate"
                class='user-validation-policy-link'>{{'GENERAL.TEXT.TERMS_OF_USE' | translate}}</a>
            {{ 'GENERAL.TEXT.AND' | translate
            }}
            <a target='_blank' hvacLink='primary' [href]="'FOOTER.PRIVACY_POLICY_LINK' | translate"
                class='user-validation-policy-link'>{{'GENERAL.TEXT.PRIVACY_POLICY' | translate}}</a>
        </p>
    </div>
</form>
<hvac-email-validation *ngIf="enableEmailVerification$ | async" [requestEmail]="userInfoForm.controls.emailControl.value" (emailVerification)="emailVerification($event)" (cancel)="cancelValidation($event)"></hvac-email-validation>

