import { Component, Input, ViewEncapsulation } from '@angular/core';

export interface Incentive {
    title: string;
    body: string;
    icon: string;
}

@Component({
    selector: 'hvac-login-incentives-item',
    templateUrl: './incentives-item.component.html',
    encapsulation: ViewEncapsulation.None
})
export class IncentivesItemComponent {
    @Input() incentive: Incentive;
}
