import { Component } from '@angular/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hvac-distributor-resources',
    templateUrl: './distributor-resources.component.html',
    styleUrls: ['./distributor-resources.component.scss']
})
export class DistributorResourcesComponent {
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.RESOURCES') },
        breadCrumbs: [
            {
                title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                url: '/connected-portal/distributors'
            }
        ]
    };

    constructor(
        private translateService: TranslateService
    ) {}
}
