import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BaseChartDirective } from 'ng2-charts';
import { FaultsReportForFactoryResponse } from 'private/app/models/factory.model';
import { BarChartGradient, ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';

export const barChartColors: BarChartGradient[] = [
    {
        gradientTop: 'rgba(255, 84, 51, 1)',
        gradientBottom: 'rgba(255, 84, 51, 0.5)'
    },
    {
        gradientTop: 'rgba(24, 145, 246, 1)',
        gradientBottom: 'rgba(24, 145, 246, 0.5)'
    }
];

const translationBase = 'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SOURCE';

@Component({
    selector: 'hvac-fault-source-report',
    templateUrl: './fault-source-report.component.html',
    styleUrls: ['./fault-source-report.component.scss']
})
export class FaultSourceReportComponent extends BaseComponent implements OnInit, OnChanges {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    @Input() systemId: string;

    public hasData$: Observable<boolean>;
    public data$: Observable<FaultsReportForFactoryResponse>;
    public chartData$: Observable<null | ChartData<'bar'>>;
    public isLoading = true;
    public chartOptions$: Observable<ChartConfiguration['options']>;
    public chartType: ChartType = 'bar';
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);
    public legendData = [
        {
            label: this.translationService.instant(`${translationBase}.LEGEND.MALFUNCTION`),
            color: barChartColors[0].gradientTop
        },
        {
            label: this.translationService.instant(`${translationBase}.LEGEND.NOTIFICATION`),
            color: barChartColors[1].gradientTop
        }
    ];

    public hasFaults$: Observable<boolean>;

    constructor(
        private chartConfigService: ChartConfigService,
        private factoryService: FactoryService,
        private translationService: TranslateService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['systemId'].isFirstChange()) {
            this.yearSelectFormControl.setValue(this.startYear);
        }
    }

    ngOnInit(): void {
        this.data$ = this.yearSelectFormControl.valueChanges.pipe(
            startWith(this.startYear),
            tap(() => {
                this.isLoading = true;
            }),
            switchMap((year) => this.factoryService.queryFaultReportForFactory(parseInt(year, 10), this.systemId).pipe(
                catchError(() => {
                    this.isLoading = false;

                    return EMPTY;
                })
            ))
        );

        this.chartOptions$ = of(this.chartConfigService.getGroupedBarChartConfig({
            xScaleTickFont: {
                size: 14,
                weight: '500'
            }
        }));

        this.chartData$ = this.data$.pipe(
            map(({ data }) => this.formatChartData(data)),
            tap(() => {
                this.isLoading = false;
            })
        );

        this.hasFaults$ = this.data$.pipe(map(({ data }) => Boolean(data.faultSource) && !(Object.values(data.faultSource)?.every((value) => value.malFunctionCount === 0 && value.notificationCount === 0))));

        this.hasData$ = combineLatest([this.hasFaults$, this.chartData$]).pipe(
            map(([hasFaults, chartData]) => Boolean(hasFaults && chartData)),
            startWith(false)
        );
    }

    private formatChartData(data: FaultsReportForFactoryResponse['data']) {
        const translationPath = `${translationBase}.DATA_LABELS`;
        const labels = data.faultSource && Object.keys(data.faultSource)?.map((key) => this.translationService
            .instant(`${translationPath}.${key}`));

        const counts = data.faultSource && Object.values(data.faultSource).reduce((acc, curFault) => {
            acc.malfunctions.push(curFault.malFunctionCount);
            acc.notifications.push(curFault.notificationCount);

            return acc;
        }, {
            malfunctions: [],
            notifications: []
        } as { malfunctions: number[], notifications: number[] });

        const dataSets = [
            {
                label: this.translationService.instant(`${translationPath}.malfunctionCount`),
                data: counts?.malfunctions
            },
            {
                label: this.translationService.instant(`${translationPath}.notificationCount`),
                data: counts?.notifications
            }
        ];

        return this.chartConfigService.getGroupedBarChartData(labels, dataSets, {
            barColors: barChartColors,
            datasetConfig: { categoryPercentage: 0.2 }
        });
    }
}
