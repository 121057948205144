<section class="hvac-product-details">
    <utc-product-breadcrumbs></utc-product-breadcrumbs>
    <section class="hvac-product-details-content">
        <utc-product-subnav [showPlaceholder]="!(revealDetails$ | async)"></utc-product-subnav>
        <utc-product-content-section-overview [showPlaceholder]="!(revealDetails$ | async)"></utc-product-content-section-overview>
        <ng-container *ngIf="revealDetails$ | async">
            <utc-product-content-section-features></utc-product-content-section-features>
            <utc-product-content-section-options *ngIf="showOptions"></utc-product-content-section-options>
            <utc-product-content-section-options-data></utc-product-content-section-options-data>
            <utc-product-content-section-physical-data></utc-product-content-section-physical-data>
            <utc-product-content-section-documents></utc-product-content-section-documents>
            <utc-product-content-section-training></utc-product-content-section-training>
            <utc-carousel-section
                [sectionTitle]="'PRODUCTS.CONTROLS' | translate"
                [sectionOrder]="'6'"
                [productRelationship]="'Controls'"
                [carouselFamily]="'controlsCarousel'"></utc-carousel-section>
            <utc-carousel-section
                *ngIf="showWorksWellWith"
                [sectionTitle]="'PRODUCTS.WORKS_WELL_WITH' | translate"
                [sectionOrder]="'7'"
                [productRelationship]="'Works Well With'"
                [carouselFamily]="'worksWellWithCarousel'"></utc-carousel-section>
            <utc-carousel-section
                [sectionTitle]="'PRODUCTS.SIMILAR_PRODUCTS' | translate"
                [sectionOrder]="'8'"
                [productRelationship]="'Similar Products'"
                [carouselFamily]="'similarProductsCarousel'"></utc-carousel-section>
        </ng-container>
    </section>
</section>
