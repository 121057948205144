import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';

@Component({
    selector: 'hvac-factory-resources',
    templateUrl: './factory-resources.component.html',
    styleUrls: ['./factory-resources.component.scss']
})
export class FactoryResourcesComponent {
  public breadCrumbData: ContentHeadingData = {
      Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.RESOURCES') },
      breadCrumbs: [
          {
              title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
              url: '/connected-portal/dashboard'
          },
          {
              title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.RESOURCES'),
              url: '/connected-portal/resources'
          }
      ]
  };

  constructor(
      private translateService: TranslateService
  ) {}
}
