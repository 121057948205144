import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { ConnectedDealer } from 'private/app/models/connected-dealer.model';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { catchError, takeUntil, map } from 'rxjs/operators';
import { ConnectedPortalNavLink } from '../../components/connected-portal-navigation/connected-portal-navigation.component';
import { SEARCH_RESULTS_PAGE_SIZE } from '../../constants';
import { BasicCSV, CSVExportService } from 'common/services/csv-export.service';

const EXPORT_FILENAME_PREFIX = 'connected-dealers';
export interface ConnectedPortalDealerProperty {
    id: string;
    name: string;
    customerCount: string;
    connectedControlCount: string;
    queuedControlCount: string;
}
@Component({
    selector: 'hvac-distributor-dealers',
    templateUrl: './distributor-dealers.component.html',
    styleUrls: ['./distributor-dealers.component.scss'],
    providers: [QueryParamsService]
})
export class DistributorDealersComponent extends BaseComponent implements OnInit {
    navLinks: ConnectedPortalNavLink[] = [];
    dealers: ConnectedDealer[] = [];
    totalPages = 0;
    currentPage = 1;
    pageSize = SEARCH_RESULTS_PAGE_SIZE;
    isLoading: boolean;
    queryControl = new UntypedFormControl('', Validators.required);
    searchTerm?: string | null;
    itemCount: number = 0;

    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
        breadCrumbs: [
            {
                title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                url: '/connected-portal/distributors'
            }
        ]
    };

    private distributorId: string | null = null;

    constructor(
        private queryParamsService: QueryParamsService,
        private dealersService: DealersService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private csvExportService: CSVExportService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.route.parent) {
            return;
        }

        this.route.parent.paramMap.subscribe((params) => {
            this.distributorId = params?.get('id');
        });

        this.queryParamsService.init({ page: this.currentPage })
            .pipe(takeUntil(this.ngOnDestroy$)).subscribe((value) => {
                this.isLoading = true;

                const { page, search } = value;
                const nextPage = this.getNextPage(page);

                const queryParams: SearchQueryParams = {
                    pagination: {
                        pageSize: this.pageSize,
                        currentPage: nextPage
                    }
                };

                if (search) {
                    queryParams.searchTerm = search;
                    this.queryControl.setValue(search);
                    this.searchTerm = search;
                }

                if (this.distributorId) {
                    this.dealersService.queryDealersByDistributorId(this.distributorId, queryParams)
                        .pipe(
                            takeUntil(this.ngOnDestroy$),
                            catchError(() => {
                                this.isLoading = false;

                                return this.dealers = [];
                            })
                        ).subscribe((res) => {
                            this.isLoading = false;
                            this.totalPages = res.totalPages;
                            this.itemCount = res.totalCount;
                            this.dealers = res.data;
                        });

                    this.currentPage = nextPage;
                }
            });
    }

    handleSearchSubmit(query: string | null) {
        const params = {
            page: 1,
            search: query
        };

        this.queryParamsService.updateParams(params);
    }

    handleSearchReset() {
        const params = {
            page: 1,
            search: null
        };

        this.queryParamsService.updateParams(params);
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    handleExportProperties() {
        if (this.distributorId) {
            this.isLoading = true;

            this.getProperties(this.distributorId, 1, this.searchTerm, this.itemCount).pipe(
                takeUntil(this.ngOnDestroy$),
                catchError(() => {
                    this.isLoading = false;

                    return [];
                })
            )
                .subscribe((res) => {
                    const { data } = res;
                    const formattedData = data.reduce((acc, curItem, index) => {
                        const { id, name, customerCount, connectedControlCount, queuedControlCount } = curItem;

                        const rowData = {
                            id,
                            name,
                            customerCount,
                            connectedControlCount,
                            queuedControlCount
                        };

                        if (index === 0) {
                            acc.headings = Object.keys(rowData)
                                .map((key) => this.translateService.instant(`CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.EXPORT_HEADINGS.${key}`));
                        }

                        acc.body.push(Object.values(rowData));

                        return acc;
                    }, {
                        headings: [],
                        body: []
                    } as BasicCSV);

                    const csvData = this.csvExportService.toCSV(formattedData);
                    this.csvExportService.saveAs(csvData, EXPORT_FILENAME_PREFIX);
                    this.isLoading = false;
                });
        }
    }

    private getProperties(distributorId: string, page: number, searchTerm?: string | null, pageSize = this.pageSize) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        return this.dealersService
            .queryDealersByDistributorId(distributorId, queryParams)

            .pipe(
                map((value): { totalPages: number, totalCount: number, data: ConnectedPortalDealerProperty[]} => {
                    const { totalPages, totalCount } = value;
                    const items = value.data.map((entry): ConnectedPortalDealerProperty => {
                        const { id, name, customerCount, connectedControlCount, queuedControlCount } = entry;

                        return {
                            id,
                            name,
                            customerCount: customerCount!.toString(),
                            connectedControlCount: connectedControlCount!.toString(),
                            queuedControlCount: queuedControlCount!.toString()
                        };
                    });

                    return {
                        totalPages,
                        totalCount,
                        data: items
                    };
                })
            );
    }
}
