import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface QueryParams {
    page: number;
    search?: string | null;
    scroll?: boolean
}

export interface QueryParamsConfig extends QueryParams {
    parseParams?: Function;
}


@Injectable()
export class QueryParamsService implements OnDestroy {
    ngOnDestroy$ = new Subject();
    onParamsChange$ = new BehaviorSubject<QueryParams>({ page: 1 });

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {}

    init(paramConfig: QueryParamsConfig) {
        this.route.queryParamMap.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((params) => {
            const { page, search, scroll = false } = this.parseParams(params);

            if (this.isDefined(page)) {
                this.onParamsChange$.next({
                    page: page as number,
                    search,
                    scroll
                });
            }
            else {
                this.updateParams({
                    page: paramConfig.page,
                    search,
                    scroll
                });
            }
        });

        return this.onParamsChange$;
    }

    emitParamsChange(params: QueryParams) {
        this.onParamsChange$.next(params);
    }

    updateParams(params: Partial<QueryParams>) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge'
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private parseParams(params: ParamMap): Partial<QueryParams> {
        const page = this.paramStringToInt(
            params.get('page')
        );

        const search = params.get('search');

        return {
            page,
            search
        };
    }

    private paramStringToInt(param: string | null): number | undefined {
        if (param) {
            const parsedInt = parseInt(param, 10);

            if (!isNaN(parsedInt)) {
                return parsedInt;
            }
        }

        return;
    }

    private isDefined(prop: unknown) {
        return typeof prop !== 'undefined';
    }
}
