import { Injectable, ElementRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BrowseCategoriesIconsService } from '../../../services/browse-categories-icons.service';
import { FetchCatalogCategoryProducts } from '../../../store/catalog/catalog.actions';
import { CatalogCategory } from '../../../store/catalog/catalog.state';

export interface FilterCategory {
    catalog: CatalogCategory;
    selected: boolean;
    id: string;
}

export interface FilterSubCategory {
    subCategory: string;
    selected: boolean;
    urn: string;
}

@Injectable({ providedIn: 'root' })
export class ProductLiteratureService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    finalProductArray: any[];
    productListLoading = new BehaviorSubject<Boolean>(false);

    constructor(
        private readonly iconService: BrowseCategoriesIconsService,
        private readonly store: Store
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    focusSubcategory(subCategoryCrumb: ElementRef<any>) {
        subCategoryCrumb.nativeElement.focus();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    focusProduct(productCrumb: ElementRef<any>) {
        productCrumb.nativeElement.focus();
    }

    expandCategory(categoryExpanded$: BehaviorSubject<Boolean>) {
        categoryExpanded$.next(true);
    }

    collapseCategory(categoryExpanded$: BehaviorSubject<Boolean>) {
        categoryExpanded$.next(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseDocumentsByBrands(documents: any[]): any[] {
        const multiBrandArray = documents.filter((document) => (document.Brands.length > 1));
        let singleBrandArray = documents.filter((document) => (document.Brands.length === 1));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tempArray: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        multiBrandArray.forEach((document: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
            document.Brands.forEach((brand: any) => {
                const options = { ...document };
                tempArray.push(this.pushBrand(options, brand));
            });
        });
        singleBrandArray = [...singleBrandArray, ...tempArray];

        return singleBrandArray;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pushBrand(document: any, brand: {Brands: string}): any {
        const doc = document;
        doc.Brands = brand;

        return doc;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findImageSrc(media: any) {
        let src = null;
        if (media['100x100'] && media['100x100'][0].url) {
            src = media['100x100'][0].url;
        }
        else if (media['150x150'] && media['150x150'][0].url) {
            src = media['150x150'][0].url;
        }
        else if (media['200x200'] && media['200x200'][0].url) {
            src = media['200x200'][0].url;
        }
        else if (media['300x300'] && media['300x300'][0].url) {
            src = media['300x300'][0].url;
        }
        else if (media['400x400'] && media['400x400'][0].url) {
            src = media['400x400'][0].url;
        }
        else if (media['500x500'] && media['500x500'][0].url) {
            src = media['500x500'][0].url;
        }
        else if (media['highest-res'] && media['highest-res'][0].url) {
            src = media['highest-res'][0].url;
        }

        return src;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadingSpinner(): BehaviorSubject<any> {
        return this.productListLoading;
    }

    getCatagoryLabel(filterCategories$: Observable<FilterCategory[]>) {
        return filterCategories$.pipe(
            map((filterCategories) => {
                const categories = filterCategories.filter((filterCategory) => filterCategory && filterCategory.selected);
                if (categories.length > 1) {
                    return `${categories.length} Category Selected`;
                }

                return categories.length === 1 ? categories[0].id : '';
            })
        );
    }

    getSubcategoryLabel(filterSubCategories$: Observable<FilterSubCategory[]>) {
        return filterSubCategories$.pipe(
            map((filterSubCategories) => {
                const subCategories = filterSubCategories.filter((filterSubCategory) => filterSubCategory && filterSubCategory.selected);
                if (subCategories.length > 1) {
                    return `${subCategories.length} Sub-Category selected`;
                }

                return subCategories.length === 1 ? subCategories[0].urn.split('/')[1] : '';
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getProductLabel(filterProducts$: Observable<any[]>) {
        return filterProducts$.pipe(
            map((filterProducts) => {
                const products = filterProducts.filter((selectedProduct) => selectedProduct && selectedProduct.selected);
                if (products.length > 1) {
                    return `${products.length} Product Selected`;
                }

                return products.length === 1 ? products[0].urn : '';
            })
        );
    }

    getProductEnable(filterProducts$: Observable<unknown[]>, filterSubCategories$: Observable<FilterSubCategory[]>): Observable<Boolean> {
        return combineLatest([filterProducts$, filterSubCategories$]).pipe(
            map(([product, subcategory]) => {
                const subCategories = subcategory.filter((filterSubCategory) => filterSubCategory && filterSubCategory.selected);
                if (subCategories.length > 0) {
                    return true;
                }

                return Boolean(product.length);
            })
        );
    }

    getSubcategoryEnable(filterSubCategories$: Observable<FilterSubCategory[]>): Observable<boolean> {
        return filterSubCategories$.pipe(
            map((filterSubCategories) => Boolean(filterSubCategories && filterSubCategories.length))
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchProductIcon(filterCategories: any): Observable<string> {
        return filterCategories.pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((category: any) => {
                const selectedCategory = category.filter((filterCategory: {selected:string}) => filterCategory && filterCategory.selected);
                if (!selectedCategory.length) {
                    return '';
                }
                if (selectedCategory.length > 1) {
                    return 'assets/icons/multi-icon.svg';
                }

                return this.iconService.getCategoryIcon(selectedCategory[0].catalog.urn);
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchProducts(filterCategories$: Observable<FilterCategory[]>, filterSubCategories$: Observable<FilterSubCategory[]>, catalogProducts$: Observable<any>) {
        return combineLatest([filterCategories$, filterSubCategories$, catalogProducts$]).pipe(
            tap(([filterCategories, selectedSubCategories, catalogProducts]) => {
                this.productListLoading.next(false);
                this.finalProductArray = [];
                const selectedCategory = filterCategories.filter((filterCategory) => filterCategory && filterCategory.selected && (filterCategory.catalog.children.length === 0));
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedCategory.forEach((category: any) => {
                    const categoryProducts = catalogProducts[category.catalog.urn] || null;
                    if (category.catalog.urn && categoryProducts === null) {
                        this.productListLoading.next(true);
                        this.store.dispatch(new FetchCatalogCategoryProducts(category.catalog.urn));
                    }
                });
                const subCategories = selectedSubCategories.filter((filterSubCategory) => filterSubCategory && filterSubCategory.selected);
                subCategories.forEach((subcategory) => {
                    const categoryProducts = catalogProducts[subcategory.urn] || null;
                    if (subcategory.urn && categoryProducts === null) {
                        this.productListLoading.next(true);
                        this.store.dispatch(new FetchCatalogCategoryProducts(subcategory.urn));
                    }
                });
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchFilterProduct(familyProducts$: Observable<any[]>, selectedProducts$: BehaviorSubject<{ [product: string]: boolean; }>): Observable<any[]> {
        return combineLatest([
            familyProducts$,
            selectedProducts$
        ]).pipe(
            map(([familyProducts, selectedProducts]) => {
                this.finalProductArray = [];
                familyProducts.forEach((familyProduct) => {
                    this.finalProductArray.push({
                        urn: familyProduct.urn,
                        name: familyProduct.name,
                        selected: selectedProducts[familyProduct.urn] || false,
                        image: familyProduct.image || null
                    });
                });

                return this.finalProductArray;
            })
        );
    }

    exportToExcel(documents: string[]) {
        const mergedDocumentArray = this.mergeDocumentArrat(documents);
        const filename = `data-${Date.now()}`;
        const csvData = this.ConvertToCSV(mergedDocumentArray,
            [
                'Document Type',
                'Document Sub-Type',
                'Document Title',
                'SubCategory',
                'Catalog Number',
                'Release Date',
                'Brands'
            ]);
        const blob = new Blob([`\ufeff${csvData}`], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser =
            navigator.userAgent.indexOf('Safari') !== -1 &&
            navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            // if Safari open in new window to save file with random filename.
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `${filename}.csv`);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    mergeDocumentArrat(documents: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const mergedArray: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        documents.forEach((document: any) => {
            mergedArray.push({
                'Document Type': document['Type'][0],
                'Document Sub-Type': document['Subtype'][0],
                'Document Title': document['DocumentTitle'].split(',').join(' '),
                'SubCategory': document['SubCategory'],
                'Catalog Number': document['FormNumber'],
                'Release Date': document['ReleaseDate'],
                'Brands': document['Brands'].toString().split(',').join(' ')
            });
        });

        return mergedArray;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ConvertToCSV(objArray: Array<string>, headerList: any) {
        const array = objArray instanceof Array ? objArray : JSON.parse(objArray);
        let str = '';
        let row = 'S.No,';
        headerList.forEach((index: number) => {
            row += `${index},`;
        });
        row = row.slice(0, -1);
        str += `${row}\r\n`;
        for (let i = 0; i < array.length; i++) {
            let line = (i + 1).toString();
            headerList.forEach((index: number) => {
                line += `,${array[i][index]}`;
            });
            str += `${line}\r\n`;
        }

        return str;
    }
}
