import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthorizedBrand, Company, CompanyContact, CompanyServices, ParentCompany } from 'private/app/models/account-admin-search';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminApiService } from './account-admin-api.service';
import { AccountAdminCompanyUtilsService } from './account-admin-company-utils.service';
import { AccountAdminCommonComponentService } from './account-admin-common-constants.service';
import { coordinateValidator } from './account-admin-edit-form.service';
import { AccountAdminUtilsService, LATITUDE, LONGITUDE } from './account-admin-utils.service';
import { CompanyQueryService } from './company-query.service';
@Injectable({ providedIn: 'root' })
export class AccountAdminEditService {
    public companyStatus: boolean;
    public parentCompany: Option[] = [];
    public selectedHoursOfOperarion: string;
    public languageOptions = this.accountAdminUtilsService?.getLanguageOptions() || [];
    public companyEditCopy: Company;

    constructor(
        private readonly companyQueryService: CompanyQueryService,
        private readonly accountAdminUtilsService: AccountAdminUtilsService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly accountAdminCompanyUtilService: AccountAdminCompanyUtilsService,
        private readonly accountAdminCommonComponentService: AccountAdminCommonComponentService
    ) {
    }

    copyCompanyEditDetails(editCopy: Company) {
        this.companyEditCopy = JSON.parse(JSON.stringify(editCopy)) as Company;
    }

    updateCompanyDetailsform(companyDetailsForm: UntypedFormGroup, rsms: string, prsms: string, accountManagers: string, ptams: string, tsms: string) {
        companyDetailsForm.controls.companyName.setValue(this.companyEditCopy.name || '', { emitEvent: true });
        companyDetailsForm.controls.companyType.setValue(this.companyEditCopy.companyType?.name || '', { emitEvent: true });
        companyDetailsForm.controls.companyCategory.setValue(this.companyEditCopy.companyCategory?.name || '', { emitEvent: true });
        companyDetailsForm.controls.companyId.setValue(this.companyEditCopy.hvacCompanyId || '', { emitEvent: true });
        companyDetailsForm.controls.services.setValue(this.companyQueryService.generateServiceSelectiondata(this.companyEditCopy.companyServices || ''));
        companyDetailsForm.controls.claimType.setValue(this.companyEditCopy.warrantyType, { emitEvent: true });
        companyDetailsForm.controls.soldTo.setValue(this.companyEditCopy.soldToCustomerId || '', { emitEvent: true });
        companyDetailsForm.controls.latitude.setValue(this.companyEditCopy.latitude || '', { emitEvent: true });
        companyDetailsForm.controls.longitude.setValue(this.companyEditCopy.longitude || '', { emitEvent: true });
        companyDetailsForm.controls.companyUrl.setValue(this.companyEditCopy.url || '', { emitEvent: true });
        companyDetailsForm.controls.shipTo.setValue(this.companyEditCopy.shipToCustomerId || '', { emitEvent: true });
        companyDetailsForm.controls.payer.setValue(this.companyEditCopy.payerCustomerId || '', { emitEvent: true });
        companyDetailsForm.controls.priceList.setValue(this.companyEditCopy.priceListType || '', { emitEvent: true });
        companyDetailsForm.controls.priceGroup.setValue(this.companyEditCopy.priceGroup || '', { emitEvent: true });
        companyDetailsForm.controls.address1.setValue(this.companyEditCopy.addressLine1 || '', { emitEvent: true });
        companyDetailsForm.controls.address2.setValue(this.companyEditCopy.addressLine2 || '', { emitEvent: true });
        companyDetailsForm.controls.city.setValue(this.companyEditCopy.city || '', { emitEvent: true });
        companyDetailsForm.controls.state.setValue(this.companyEditCopy.state || '', { emitEvent: true });
        companyDetailsForm.controls.country.setValue(this.companyEditCopy.country || '', { emitEvent: true });
        companyDetailsForm.controls.postalCode.setValue(this.companyEditCopy.postalCode || '', { emitEvent: true });
        companyDetailsForm.controls.phone.setValue(this.companyEditCopy.phoneNumber || '', { emitEvent: true });
        companyDetailsForm.controls.tollFree.setValue(this.companyEditCopy.tollFreeNumber || '', { emitEvent: true });
        companyDetailsForm.controls.mobile.setValue(this.companyEditCopy.mobileNum || '', { emitEvent: true });
        companyDetailsForm.controls.fax.setValue(this.companyEditCopy.faxNumber || '', { emitEvent: true });
        companyDetailsForm.controls.email.setValue(this.companyEditCopy.emailAddress || '', { emitEvent: true });
        companyDetailsForm.controls.contLic.setValue(this.companyEditCopy.contractorLicense || '', { emitEvent: true });
        companyDetailsForm.controls.rsm.setValue(rsms, { emitEvent: true });
        companyDetailsForm.controls.parentCompany.setValue(this.companyEditCopy.parentCompany?.name, { emitEvent: false });
        companyDetailsForm.controls.partRSM.setValue(prsms, { emitEvent: true });
        companyDetailsForm.controls.accountManagers.setValue(accountManagers, { emitEvent: true });
        companyDetailsForm.controls.partAM.setValue(ptams, { emitEvent: true });
        companyDetailsForm.controls.tsm.setValue(tsms, { emitEvent: true });
        companyDetailsForm.controls.notes.setValue(this.companyEditCopy.notes || '', { emitEvent: true });
        if (this.companyEditCopy.language.length === 0) {
            companyDetailsForm.controls.language.setValue(this.languageOptions[0].value);
        }
        else if (this.companyEditCopy.language.length === 1) {
            companyDetailsForm.controls.language.setValue(this.languageOptions.filter((item: Option) => this.companyEditCopy.language[0] === item.value));
        }
        else {
            companyDetailsForm.controls.language.setValue(this.languageOptions?.map((language) => ({
                value: language.value,
                name: language.name
            })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase())) || []);
        }
        companyDetailsForm.controls.ownership.setValue(this.companyEditCopy.ownershipType);

        companyDetailsForm.controls.searchRadius.setValue(this.companyEditCopy.searchRadius);
        companyDetailsForm.controls.locatorName.setValue(this.companyEditCopy.locatorName);

        // IT Super Admin
        companyDetailsForm.controls.inventoryAPI.setValue(this.companyEditCopy.inventoryAPI);
        companyDetailsForm.controls.regionCode.setValue(this.companyEditCopy.regionCode);
        companyDetailsForm.controls.customerUserName.setValue(this.companyEditCopy.customerUserName);
        companyDetailsForm.controls.customerGUID.setValue(this.companyEditCopy.customerGUID);
    }

    changeStatusControl(value: Event) {
        const input = <HTMLInputElement>value.target;
        this.companyStatus = input.checked;
    }

    statusChangeCompanyDetailsform(companyDetailsForm: UntypedFormGroup) {
        companyDetailsForm.controls.services.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                if (value.length) {
                    this.updateCompanyServices(value);
                }
                if (this.companyEditCopy.companyServices.length) {
                    this.companyQueryService.isClaimTypeVisible(this.companyQueryService.mapCompanyServices(value).map((service) => service.name));
                }
                if (this.companyQueryService.configuration.formConfig.claimType?.edit) {
                    if (this.companyEditCopy.warrantyType === null) {
                        companyDetailsForm.controls.claimType.setValue(this.accountAdminCommonComponentService.claimTypeOptions[0].name);
                        this.companyEditCopy.warrantyType = this.accountAdminCommonComponentService.claimTypeOptions[0].name;
                    }
                }
            }
        });
        companyDetailsForm.controls.soldTo.valueChanges.subscribe((value) => {
            this.companyEditCopy.soldToCustomerId = value;
        });
        companyDetailsForm.controls.language.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.companyEditCopy.language = value?.filter((lang) => Boolean(lang.value)).map((language: Option) => language.name);
            }
        });
        companyDetailsForm.controls.claimType.valueChanges.subscribe((claimType: Option[]) => {
            if (Array.isArray(claimType)) {
                this.companyEditCopy.warrantyType = claimType[0].value ? claimType[0].name : '';
            }
        });
        companyDetailsForm.controls.rsm.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Regional Sales Manager');
            }
        });

        companyDetailsForm.controls.partRSM.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Parts Regional Sales Manager');
            }
        });

        companyDetailsForm.controls.accountManagers.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Account Manager');
            }
        });

        companyDetailsForm.controls.partAM.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Parts Account Manager');
            }
        });

        companyDetailsForm.controls.tsm.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Technical Service Manager (TSM)');
            }
        });

        companyDetailsForm.controls.pricingAnalyst.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.updateContact(value, 'Pricing Analyst');
            }
        });

        companyDetailsForm.controls.ownership.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.companyEditCopy.ownershipType = value[0].name;
            }
        });

        companyDetailsForm.controls.parentCompany.valueChanges.subscribe((value: string) => {
            this.parentCompany = [];
            if (!value) {
                return;
            }
            this.companyQueryService.getParentCompanyByCategoryType(this.companyEditCopy.companyCategory.name).subscribe((parentCompany: ParentCompany[]) => {
                this.parentCompany = parentCompany?.filter((company) => company.id !== this.companyEditCopy.hvacCompanyId).map((company: ParentCompany) => ({
                    value: company.id,
                    name: company.name
                })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            });
        });
    }

    updateCompanyServices(selectedServices: Option[]) {
        this.companyEditCopy.companyServices = [];
        const companyServices: CompanyServices[] = this.accountAdminApiService.getCompanyServices();
        selectedServices.map((option) => companyServices.map((service) => {
            if (option.value === service.id) {
                this.companyEditCopy.companyServices.push({
                    id: service.id,
                    code: service.code,
                    name: service.name
                });
            }

            return this.companyEditCopy;
        }));
    }

    updateContact(option: Option[], companyType: string) {
        if (option.length > 1) {
            option.filter((contactInfo) => this.companyEditCopy.companyContacts.push({
                contactType: companyType,
                userName: contactInfo.name,
                userId: contactInfo.value
            }));

            return;
        }
        const contact: CompanyContact = {
            contactType: companyType,
            userName: option?.map((value: Option) => value.name)[0],
            userId: option?.map((value: Option) => value.value)[0]
        };
        if (this.companyEditCopy) {
            if (this.companyEditCopy.companyContacts.findIndex((elem) => elem.contactType === contact.contactType) === -1 || contact.contactType === '') {
                this.companyEditCopy.companyContacts.push(contact);

                return;
            }

            this.companyEditCopy.companyContacts.map((companyContact) => {
                if (companyContact.contactType === contact.contactType) {
                    companyContact.userName = contact.userName;
                    companyContact.userId = contact.userId;
                }

                return companyContact;
            });
        }
    }

    fetchCompanyInfoEditData(companyDetailsForm: UntypedFormGroup, stateOptionData: Option[], countryOptionData: Option[]): Company {
        this.companyEditCopy.name = companyDetailsForm.value.companyName;
        this.companyEditCopy.url = companyDetailsForm.value.companyUrl;
        this.companyEditCopy.shipToCustomerId = companyDetailsForm.value.shipTo;
        this.companyEditCopy.payerCustomerId = companyDetailsForm.value.payer;
        this.companyEditCopy.warrantyType = this.accountAdminCommonComponentService.claimTypeOptions.find((option) => option.name === this.companyEditCopy.warrantyType)?.value || '';
        this.companyEditCopy.addressLine1 = companyDetailsForm.value.address1;
        this.companyEditCopy.addressLine2 = companyDetailsForm.value.address2;
        this.companyEditCopy.city = companyDetailsForm.value.city;
        this.companyEditCopy.state = Array.isArray(companyDetailsForm.value.state) ? this.accountAdminCompanyUtilService.getRegionCodeByState(companyDetailsForm.value.state[0].name, stateOptionData) : this.accountAdminCompanyUtilService.getRegionCodeByState(companyDetailsForm.value.state, stateOptionData) || companyDetailsForm.value.state;
        this.companyEditCopy.country = Array.isArray(companyDetailsForm.value.country) ? this.accountAdminCompanyUtilService.getCountryCodeByCountry(companyDetailsForm.value.country[0].name, countryOptionData) : this.accountAdminCompanyUtilService.getCountryCodeByCountry(companyDetailsForm.value.country, countryOptionData);
        this.companyEditCopy.postalCode = companyDetailsForm.value.postalCode;
        this.companyEditCopy.emailAddress = companyDetailsForm.value.email;
        this.companyEditCopy.priceGroup = companyDetailsForm.value.priceGroup;
        this.companyEditCopy.priceListType = companyDetailsForm.value.priceList;
        this.companyEditCopy.contractorLicense = companyDetailsForm.value.contLic;
        if (!this.companyQueryService.configuration.formConfig.showOnDistributorLocator?.edit) {
            this.companyEditCopy.includeInDistributorLocator = false;
        }
        if (!this.companyQueryService.configuration.formConfig.claimType?.edit) {
            this.companyEditCopy.warrantyType = '';
        }
        this.companyEditCopy.isActive = Boolean(companyDetailsForm.value.statusActive);
        this.companyEditCopy.mobileNum = companyDetailsForm.value.mobile === '' ? null : Number((companyDetailsForm.value.mobile).toString().replace(/\D/g, ''));
        this.companyEditCopy.tollFreeNumber = companyDetailsForm.value.tollFree === '' ? null : Number((companyDetailsForm.value.tollFree).toString().replace(/\D/g, ''));
        this.companyEditCopy.phoneNumber = (companyDetailsForm.value.phone) ? companyDetailsForm.value.phone.replace(/\D/g, '') : null;
        this.companyEditCopy.latitude = companyDetailsForm.value.latitude === '' ? null : companyDetailsForm.value.latitude;
        this.companyEditCopy.longitude = companyDetailsForm.value.longitude === '' ? null : companyDetailsForm.value.longitude;
        this.companyEditCopy.faxNumber = companyDetailsForm.value.fax === '' ? null : Number((companyDetailsForm.value.fax).toString().replace(/\D/g, ''));
        this.companyEditCopy.notes = companyDetailsForm.value.notes;
        if (this.selectedHoursOfOperarion) {
            this.companyEditCopy.operatingTime = this.selectedHoursOfOperarion;
        }
        this.companyEditCopy.searchRadius = companyDetailsForm.value.searchRadius;
        this.companyEditCopy.locatorName = companyDetailsForm.value.locatorName;

        // IT Super Admin
        this.companyEditCopy.inventoryAPI = companyDetailsForm.value.inventoryAPI;
        this.companyEditCopy.regionCode = companyDetailsForm.value.regionCode;
        this.companyEditCopy.customerUserName = companyDetailsForm.value.customerUserName;
        this.companyEditCopy.customerGUID = companyDetailsForm.value.customerGUID;

        return this.companyEditCopy;
    }

    setAsPrimary(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.companyEditCopy.isPrimaryAccount = input.checked;
        }
    }

    setflagForDC(event: Event) {
        this.companyEditCopy.flagForDC = (<HTMLInputElement>event.target).checked;
    }

    setflagForPOS(event: Event) {
        this.companyEditCopy.flagForPOS = (<HTMLInputElement>event.target).checked;
    }

    onShowOnDistributorLocator(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.companyEditCopy.includeInDistributorLocator = input.checked;
        }
        input.checked ? this.accountAdminUtilsService.companyDetailsForm.controls.latitude.addValidators([coordinateValidator(LATITUDE, -LATITUDE, true)]) : this.accountAdminUtilsService.companyDetailsForm.controls.latitude.setValidators([coordinateValidator(LATITUDE, -LATITUDE, false)]);
        input.checked ? this.accountAdminUtilsService.companyDetailsForm.controls.longitude.addValidators([coordinateValidator(LONGITUDE, -LONGITUDE, true)]) : this.accountAdminUtilsService.companyDetailsForm.controls.longitude.setValidators([coordinateValidator(LONGITUDE, -LONGITUDE, false)]);
        this.accountAdminUtilsService.companyDetailsForm.controls.latitude.updateValueAndValidity();
        this.accountAdminUtilsService.companyDetailsForm.controls.longitude.updateValueAndValidity();
    }

    setStateSelection(stateOptionData: Option[], companyDetailsForm: UntypedFormGroup) {
        const stateSelection = stateOptionData.find((state) => state.value === this.companyEditCopy?.state);
        if (stateSelection) {
            companyDetailsForm.controls.state.setValue(stateSelection.name, { emitEvent: true });
        }
    }

    setCountrySelection(countryOptionData: Option[], companyDetailsForm: UntypedFormGroup) {
        const countrySelection = countryOptionData.find((country) => country.value === this.companyEditCopy?.country);
        if (countrySelection) {
            companyDetailsForm.controls.country.setValue(countrySelection.name, { emitEvent: true });
        }
    }

    getStateNameByStateCode(stateOptionData: Option[]): string {
        return stateOptionData?.find((state) => state.value === this.companyEditCopy?.state)?.name || '';
    }

    getCountryNameByCountryCode(countryOptionData: Option[]): string {
        return countryOptionData?.find((country) => country.value === this.companyEditCopy?.country)?.name || '';
    }

    updateAuthorizedBrands(authorizedBrands: AuthorizedBrand[]) {
        this.companyEditCopy.brands = authorizedBrands.map((authorizedBrand) => ({
            name: authorizedBrand.name,
            code: authorizedBrand.code
        }));
    }

    setHoursOfOperation(event: Event, hoursOfOperation: string) {
        if ((<HTMLInputElement>event.target).checked) {
            this.selectedHoursOfOperarion = hoursOfOperation;
        }
    }
}
