import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BaseChartDirective } from 'ng2-charts';
import { ConnectedControlDataSummaryEntry, ConnectedControlReport } from 'private/app/models/factory.model';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
@Component({
    selector: 'hvac-factory-connected-control-report',
    templateUrl: './factory-connected-control-report.component.html',
    styleUrls: ['./factory-connected-control-report.component.scss']
})
export class FactoryConnectedControlReportComponent extends BaseComponent {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public data?: ConnectedControlReport;
    public isLoading = false;
    public chartOptions: ChartConfiguration['options'];
    public chartType: ChartType = 'line';
    public chartData: null | ChartData<'line'> = null;
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);

    private maxTickCount = {
        mobile: 4,
        tablet: 12
    }

    private pointDivisor = {
        mobile: 3,
        tablet: 1
    }

    constructor(
        private chartConfigService: ChartConfigService,
        private cdr: ChangeDetectorRef,
        private breakpointService: BreakPointService,
        private csvExportService: CSVExportService,
        private readonly datePipe: DatePipe,
        private factoryService: FactoryService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.yearSelectFormControl.value) {
            const selectedYear = parseInt(this.yearSelectFormControl.value, 10);
            this.loadChartData(selectedYear).subscribe((res) => {
                this.isLoading = false;
                this.data = res.data;
                this.configureChart(this.data?.dataSummary || []);
            });
        }

        this.yearSelectFormControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((year) => {
                const parsedYear = parseInt(year, 10);

                return this.loadChartData(parsedYear);
            })
        ).subscribe((res) => {
            this.isLoading = false;
            this.data = res.data;
            this.configureChart(this.data?.dataSummary || []);
        });

        this.breakpointService.breakpoint$.pipe(
            filter(() => Boolean(this.data)),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((_value) => {
            if (this.data?.dataSummary) {
                this.configureChart(this.data.dataSummary);
                this.cdr.detectChanges();
            }
        });
    }

    public handleRunReport() {
        if (this.data?.filepath) {
            this.csvExportService.downloadFile(this.data.filepath);
        }
    }

    private loadChartData(year: number) {
        this.isLoading = true;

        return this.factoryService
            .queryConnectedControlsForFactory(year)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                catchError((err) => {
                    this.isLoading = false;

                    throw err;
                })
            );
    }

    private getChartLabelsAndDataset(dataSummary: ConnectedControlDataSummaryEntry[]) {
        return dataSummary.reduce((acc, curItem) => {
            const label = this.datePipe.transform(curItem.dateTime, 'MMM')?.toUpperCase() || '';
            const value = curItem.controlCount;

            return {
                labels: [...acc.labels, label],
                dataset: [...acc.dataset, value]
            };
        }, {
            labels: [],
            dataset: []
        } as { labels: string[], dataset: number[]});
    }

    private configureChart(data: ConnectedControlReport['dataSummary']) {
        if (data === null) {
            return;
        }

        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
        const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
        const { labels, dataset } = this.getChartLabelsAndDataset(data);

        this.chartOptions = this.chartConfigService.getLineChartConfig(dataset, xScaleMaxTicks);
        this.chartData = this.chartConfigService.getLineChartData(labels, dataset, pointDivisor);
    }
}
