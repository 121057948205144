import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { SafePipeModule } from 'safe-pipe';
import { CommonComponentsModule } from '../../components/common-components.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CarouselSectionComponent } from './components/content-section/carousel-section.component';
import { DocumentsComponent } from './components/content-section/documents.component';
import { FeaturesComponent } from './components/content-section/features.component';
import { OptionsDataComponent } from './components/content-section/options-data.component';
import { OptionsComponent } from './components/content-section/options.component';
import { OverviewComponent } from './components/content-section/overview.component';
import { PhysicalDataComponent } from './components/content-section/physical-data.component';
import { ProductAttributeGroupComponent } from './components/content-section/product-attribute-group.component';
import { ProductDataComponent } from './components/content-section/product-data.component';
import { TrainingCoursesComponent } from './components/content-section/training-courses.component';
import { DetailsComponent } from './components/details/details.component';
import { SubnavComponent } from './components/subnav/subnav.component';
import { ProductDetailsRoutingModule } from './product-details-routing.module';
import { ProductDetailsComponent } from './product-details.component';
import { SwiperDirective } from 'common/components/swiper/swiper.directive';

@NgModule({
    declarations: [
        ProductDetailsComponent,
        BreadcrumbsComponent,
        DetailsComponent,
        DocumentsComponent,
        OptionsComponent,
        PhysicalDataComponent,
        OptionsDataComponent,
        FeaturesComponent,
        OverviewComponent,
        ProductAttributeGroupComponent,
        ProductDataComponent,
        SubnavComponent,
        TrainingCoursesComponent,
        CarouselSectionComponent
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        ProductDetailsRoutingModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SafePipeModule,
        EllipsisModule,
        SwiperDirective
    ],
    exports: [
        SubnavComponent
    ]
})
export class ProductDetailsModule {}
