import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService, BreakpointSize } from 'common/services/breakpoint.service';
import { ProductType } from 'private/app/models/connected-product.model';
import { takeUntil } from 'rxjs/operators';
import { ConnectedPortalDealerDashboardAlertsService } from '../../../services/connected-portal-dealer-dashboard-alerts.service';
import { ConnectedPortalAlert } from '../connected-portal-alerts.component';

@Component({
    selector: 'hvac-connected-portal-alerts-list',
    templateUrl: './connected-portal-alerts-list.component.html',
    styleUrls: ['./connected-portal-alerts-list.component.scss']
})
export class ConnectedPortalAlertsListComponent extends BaseComponent implements OnInit {
    @Input() alerts: ConnectedPortalAlert[];
    @Input() totalPages: number;
    @Input() currentPage: number;
    @Input() isLoading: boolean;
    @Output() public onPageChange: EventEmitter<number> = new EventEmitter();
    @Output() public onRemoveSelected: EventEmitter<boolean> = new EventEmitter();
    @Output() public onExportAlerts: EventEmitter<boolean> = new EventEmitter();

    public readonly ProductType = ProductType;
    public readonly AppConstants = AppConstants;
    public isModalVisible = false;
    public hasAlertsSelected = false;
    public isDesktopBp = false;
    public readonly ComputedAlertType = 'Computed';
    public allSelected = false;
    public selectedCount = 0;
    constructor(
        private alertsService: ConnectedPortalDealerDashboardAlertsService,
        private breakpointService: BreakPointService
    ) {
        super();
    }

    ngOnInit(): void {
        this.alertsService.allSelected$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((allSelected) => {
            this.allSelected = allSelected;
        });

        this.alertsService.selectedCount$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((count) => {
            this.selectedCount = count;
        });

        this.alertsService.selectedAlertIds$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((alertIds) => {
                this.hasAlertsSelected = alertIds.length > 0;
            });

        this.breakpointService.breakpoint$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((val) => {
                this.isDesktopBp = val.breakpoints[BreakpointSize.Desktop];
            });
    }

    handlePageChange(nextPage: number) {
        this.onPageChange.emit(nextPage);
    }

    handleAlertSelectChange(event: Event) {
        const target = event.target as HTMLInputElement;
        const alertId = target.value;
        const isChecked = target.checked;

        if (isChecked) {
            this.alertsService.addAlertIds([alertId]);
        }
        else {
            this.alertsService.removeAlertIds([alertId]);
        }
    }

    handleSelectAll() {
        const ids = this.alerts.map((alert) => alert.id);

        this.alertsService.addAlertIds(ids);
    }

    toggleSelectAll() {
        if (this.allSelected) {
            this.alertsService.unselectAll();
        }
        else {
            this.alertsService.selectAll();
        }
    }

    handleRemoveSelected() {
        this.onRemoveSelected.emit(true);
    }

    handleExportAlerts() {
        this.onExportAlerts.emit(true);
    }

    trackByAlert(_index: number, alert: ConnectedPortalAlert) {
        return alert.id;
    }
}
