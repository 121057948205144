<div class="hvac-quick-links"
    [ngClass]="{'hide-quick-links': ( !(quickLinks$ | async)?.length || (quickLinks$ | async)?.length  === 0 ) && !inputLinks }">
    <utc-svg class="hvac-quick-links-pattern" src="assets/images/home-pattern.svg"></utc-svg>
    <div class="hvac-quick-links-content">
        <div class='hvac-quick-links-title-container'>
            <h3 class="hvac-quick-links-title">{{ 'QUICK_LINKS.TITLE' | translate }}</h3>
            <button *ngIf="!inputLinks" (click)="toggleEditView()" [ngClass]="{'edit-toggle-icon': !showEditView} "
                class="hvac-quick-links-edit-toggle">
                <span *ngIf="showEditView">{{ 'QUICK_LINKS.TOGGLE_EDIT_TEXT' | translate}}</span>
                <span *ngIf="!showEditView">
                    <utc-svg src="assets/icons/elipses.svg"></utc-svg>
                </span>
            </button>
        </div>


        <ng-template [ngIf]="!(contentLoading$ | async)" [ngIfElse]="spinner">
            <ng-template [ngIf]="!showEditView" [ngIfElse]="editContainer">
                <div class="hvac-quick-links-list-container">
                    <swiper-container class="hvac-quick-links-list" hvacSwiper init="false" [config]="swiperParams">
                        <swiper-slide class="quick-link-grid" *ngFor="let page of (visibleLinks$ | async); trackBy:position">
                            <ng-container *ngFor="let link of page; trackBy:position">
                                <div *ngIf="link.status" class="hvac-quick-links-item">
                                    <a class="hvac-quick-link-anchor" [routerLink]="link.link" [target]="link.target"
                                       [title]="link.title | richText:false"
                                       *ngIf="link.target === '_self'; else externalLink">
                                        <utc-svg *ngIf="link.image" [src]="link.image" [svgId]="(link.title | richText:false).replaceAll(' ', '_') + '-' + (link.contentId | richText:false)" class="hvac-quick-link-icon">
                                        </utc-svg>
                                        <span class="hvac-accessible-text" [innerHTML]="link.title | richText"></span>
                                    </a>
                                    <ng-template #externalLink>
                                        <button class="hvac-quick-link-anchor"
                                                (click)="checkSiteMap(link)"
                                                [attr.title]="link.title | richText:false">
                                            <utc-svg *ngIf="link.image" [src]="link.image" [svgId]="(link.title | richText:false).replaceAll(' ', '_') + '-' + (link.contentId | richText:false)" class="hvac-quick-link-icon">
                                            </utc-svg>
                                            <span class="hvac-accessible-text" [innerHTML]="link.title | richText"></span>
                                        </button>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </swiper-slide>
                    </swiper-container>
                </div>
                <div [ngClass]="((visibleLinks$ | async)?.length || 0) > 1 ? 'hvac-quick-links-pagination' : 'pagination-no-show' "
                    class="hvac-quick-links-pagination">
                    <button class="hvac-quickLink-nav-button hvac-quickLink-next">
                        <utc-svg class="hvac-quickLink-navigation-icon" src="assets/icons/nav-left.svg"></utc-svg>
                        <span class="hvac-accessible-text">{{ 'QUICKLINK.PREV' | translate }}</span>
                    </button>
                    <button class="hvac-quickLink-nav-button hvac-quickLink-prev">
                        <utc-svg class="hvac-quickLink-navigation-icon" src="assets/icons/nav-right.svg"></utc-svg>
                        <span class="hvac-accessible-text">{{ 'QUICKLINK.NEXT' | translate }}</span>
                    </button>
                </div>
            </ng-template>
            <ng-template #editContainer>
                <div cdkDropListGroup class="hvac-quick-links-edit-container">
                    <div cdkDropList (cdkDropListDropped)="dropListDropped()"></div>
                    <div class='hvac-edit-link' cdkDropList *ngFor="let link of (editableQuickLinkObservable$ | async); trackBy:position"
                        [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped()">
                        <div cdkDrag (cdkDragMoved)="dragMoved($event);" class="hvac-quick-links-item">
                            <button (click)="toggleLinkVis(link)" class='hvac-quick-link-toggle-link'
                                [ngClass]="{'hvac-quick-link-toggle-link-plus-icon': !link.status}">
                                {{link.status ? "-" : "+"}}
                            </button>
                            <div class="hvac-quick-link-anchor" [ngClass]="{'quick-link-hidden': !link.status}">
                                <utc-svg *ngIf="link.image" [src]="link.image" class="hvac-quick-link-icon"  [svgId]="link.title.replace(' ', '_') + '-' + link.contentId">
                                </utc-svg>
                            </div>
                            <div *cdkDragPreview class='quick-link-drag-preview'>
                                <utc-svg *ngIf="link.image" [src]="link.title.replace(' ', '_') + '-' + link.contentId">
                                </utc-svg>
                            </div>
                            <div class="quick-link-drag-placeholder" *cdkDragPlaceholder></div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-template>
        <ng-template #spinner>
            <utc-loading-spinner></utc-loading-spinner>
        </ng-template>
    </div>
</div>
