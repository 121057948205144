import { Component, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AccountState } from 'common/store/create-account.state';
import { ResetUniqueUserName, ResetUniqueEmail, ResetAccountUserInformation, ResetEmailVerification, FetchCreateAccount, SetPrimaryDistributorId } from 'private/app/store/create-account.actions';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { getErrorMessage } from 'common/utils/createAccountValidators';
import { Address, AssociatedDistributor, AssociatedDistributorResult, CompanyDetailInfo } from 'private/app/models/accountInfo';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CreateAccountService } from 'private/app/services/create-account.service';
import { FilterPipe } from 'common/pipes/filter.pipe';

interface SelctedDistributor {
    name: string;
    address: string;
    id?: string;
}

@Component({
    selector: 'hvac-associated-distributor-lookup',
    templateUrl: './associated-distributor-lookup.html',
    styleUrls: ['./associated-distributor-lookup.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssociatedDistributorLookup {
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AccountState.companyCodeInfo) companyCodeInfo$!: Observable<CompanyDetailInfo>;
    footerNumberOfSteps = 4;
    footerCurrentStep = 4;
    public currentPage = 0;
    public associatedDistributorList$ = new BehaviorSubject<AssociatedDistributor[]>([]);
    public selectedDistributor$ = new BehaviorSubject<SelctedDistributor>({
        name: '',
        address: ''
    });

    errorMessages$ = this.translate.get('CREATE_ACCOUNT_LOOKUP.ERROR_MESSAGES');
    distributorLocatorInput = new UntypedFormControl('');
    zipCodeErrorMessage$ = combineLatest([this.distributorLocatorInput.statusChanges, this.errorMessages$]).pipe(
        map(([_valid, message]) => getErrorMessage(this.distributorLocatorInput.errors, message))
    );

    companyInfo$: Observable<CompanyDetailInfo> ;
    companyInfo: CompanyDetailInfo;
    hasRequested = false;
    totalPages: number;

    private buttonTimer: NodeJS.Timeout;
    private shadowCopy: AssociatedDistributor[] = [];
    private pageSize = 10;

    constructor(
        private readonly store: Store,
        private location: Location,
        private readonly translate: TranslateService,
        private readonly accountService: CreateAccountService
    ) { }

    ngOnInit() {
        this.companyCodeInfo$.pipe(
            map((companyInfo) => {
                this.companyInfo = companyInfo;
                this.pullPrimaryDistributors();
            })
        ).subscribe();

        this.distributorLocatorInput.valueChanges.subscribe((searchVal: string) => {
            if (this.distributorLocatorInput.valid) {
                this.associatedDistributorList$.next(new FilterPipe().transform(this.shadowCopy, ['zipCode'], searchVal));
            }
        });
    }

    pullPrimaryDistributors() {
        this.hasRequested = true;
        this.accountService.checkAssociatedDistributorForDealer({
            criteria: {
                companyId: this.companyInfo.companyId,
                companyTypeCode: 'DDT',
                getAssociatedBrands: true,
                postalCode: ''
            },
            determineTotalCount: true,
            pageParams: {
                page: this.currentPage,
                pageSize: this.pageSize
            }

        }).subscribe((results: AssociatedDistributorResult) => {
            this.hasRequested = false;
            const data = results.data.map((distributor: AssociatedDistributor) => {
                distributor.zipCode = distributor.address.postalCode;

                return distributor;
            });
            this.currentPage += 1;
            this.totalPages = Math.ceil(results?.totalCount / this.pageSize);
            this.shadowCopy = [...this.shadowCopy, ...data];
            this.associatedDistributorList$.next(this.shadowCopy);
            this.zipCodeSearch();
        });
    }

    setCompanyId(result: AssociatedDistributor) {
        this.selectedDistributor$.next({
            id: result.id,
            name: result.name,
            address: this.formatAddress(result.address)
        });
        this.store.dispatch(new SetPrimaryDistributorId(result.id.toString()));
    }

    public zipCodeSearch() {
        if (this.distributorLocatorInput.valid) {
            this.associatedDistributorList$.next(new FilterPipe().transform(this.shadowCopy, ['zipCode'], this.distributorLocatorInput.value));
        }
    }

    public formatAddress(result: Address) {
        const address = result.addressLine1 ? `${result.addressLine1}, ` : '';
        const city = result.city ? `${result.city}, ` : '';
        const state = result.regionCode ? `${result.regionCode} ` : '';
        const postalCode = result.postalCode ? result.postalCode : '';

        return address + city + state + postalCode;
    }

    backToInformationPage() {
        clearTimeout(this.buttonTimer);

        return this.location.back();
    }

    handleHeaderCancel() {
        clearTimeout(this.buttonTimer);
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetAccountUserInformation());
        this.store.dispatch(new ResetEmailVerification());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    handleHeaderLogo() {
        clearTimeout(this.buttonTimer);
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    submitAccountInfo() {
        this.store.dispatch(new FetchCreateAccount());
    }
}
