<div class="hvac-association-title-section hvac-dealer-title-section" #dealerContainer>
    <h1 class="havc-association-header-title">{{ 'ACCOUNT_ADMIN.ASSOCIATED_DEALERS' | translate }}</h1>
    <div *ngIf="!loading && totalCount">
        <h2 *ngIf="pagination === 1; else nonZeroPage" class="havc-association-sub-header-title">{{ 'ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE_FIRST_PAGE' | translate: { begin: displayCountStart , total:
            totalCount
            } }}</h2>
            <ng-template #nonZeroPage>
                <h2 class="havc-association-sub-header-title">{{ 'ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE' | translate: { begin: displayCountStart, end: displayCountEnd, total:
                    totalCount
                    } }}</h2>
            </ng-template>
    </div>
</div>
<div class="hvac-account-admin-table-wrap hvac-rhythm-3">
    <hvac-table>
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button class="hvac-table-sort-btn">
                        <span class="hvac-association-detail-column-header">{{item.title}}</span>
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
            <a [routerLink]="['../../company/', dealer.id]" queryParamsHandling="merge" class="hvac-account-admin-table-link hvac-association-details-table-link" *ngFor="let dealer of dealerDetails$ | async">
                <tr>
                    <td>
                        <div class="hvac-association-name-column">{{dealer.name}}</div>
                        <div class="hvac-p-xs hvac-association-detail-fields">{{createDetails(dealer)}}
                            <br />
                            {{'ACCOUNT_ADMIN.COMPANY_ID' | translate }}: {{dealer.id}}
                        </div>
                    </td>
                    <td>
                        {{dealer.id}}
                    </td>
                    <td>
                        {{dealer?.active ? ('ACCOUNT_ADMIN.ACTIVE' | translate) : ('ACCOUNT_ADMIN.INACTIVE' | translate)}}
                    </td>
                </tr>
            </a>
        </tbody>
    </hvac-table>
    <p class="hvac-account-admin-no-results" *ngIf="!(dealerDetails$ | async)?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
    <div class="hvac-association-detail-loader"><utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small"></utc-loading-spinner>
    </div>
</div>
<div class="hvac-associations-pagination-control">
    <hvac-pagination *ngIf="totalPages > 1"
        [totalPages]="totalPages"
        [currentPage]="pagination"
        (pageChange)="handlePageChange($event)">
    </hvac-pagination>
</div>
