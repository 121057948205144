<div class="hvac-wall-control-humidity-and-ventilation-details">

    <hvac-tab-bar variant="secondary">
        <button *ngIf="data.wake && !isEmpty(data.wake)" (click)="handleTabClick('wake')" [ngClass]="{ 'hvac-active' : selectedTab === 'wake'}">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROFILES.WAKE' | translate }}</button>
        <button *ngIf="data.away && !isEmpty(data.away)" (click)="handleTabClick('away')" [ngClass]="{ 'hvac-active' : selectedTab === 'away'}">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROFILES.AWAY' | translate }}</button>
        <button *ngIf="data.vacation && !isEmpty(data.vacation)" (click)="handleTabClick('vacation')" [ngClass]="{ 'hvac-active' : selectedTab === 'vacation'}">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROFILES.VACATION' | translate }}</button>
        <button *ngIf="data.home && !isEmpty(data.home)" (click)="handleTabClick('home')" [ngClass]="{ 'hvac-active' : selectedTab === 'home'}">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROFILES.HOME' | translate }}</button>
        <button *ngIf="data.sleep && !isEmpty(data.sleep)" (click)="handleTabClick('sleep')" [ngClass]="{ 'hvac-active' : selectedTab === 'sleep'}">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HUMIDITY_AND_VENTILATION.PROFILES.SLEEP' | translate }}</button>

    </hvac-tab-bar>
    <ul class="hvac-cp-list" *ngIf="templateProps">
        <li *ngFor="let profile of templateProps | keyvalue" class="hvac-cp-list-item" [ngClass]="{ 'hvac-cp-list-item-visible': selectedTab === profile.key }">
            <hvac-product-property-list>
                <hvac-product-property-list-item *ngFor="let property of profile.value">
                    <hvac-product-property-list-item-label>{{ property.label }}</hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>{{ property.value }}</hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>
        </li>
    </ul>
    <div *ngIf="arePropsEmpty" class="hvac-wall-control-profiles-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.WALL_CONTROL.SETTINGS_MESSAGES.COMFORT_PROFILES' | translate }}</div>
</div>

