import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { environment } from 'common/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { PurchaseOrderDetail, AcknowledgementPost, AdvancedShipNotification, PO_STATUS } from '../models/purchase-order-details.model';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Select } from '@ngxs/store';

const SORT_ORDER = new Map([
    [PO_STATUS.CHANGED, 1],
    [PO_STATUS.NEW, 2],
    [PO_STATUS.WAITING, 3],
    [PO_STATUS.ACKNOWLEDGED, 4],
    [PO_STATUS.PARTIAL_SHIP, 5],
    [PO_STATUS.FULLY_SHIPPED, 6],
    [PO_STATUS.CANCELLED, 7]
]);

@Injectable({ providedIn: 'root' })
export class PurchaseOrderService {
    @Select(AccountStatusState.getUserId) userId$: Observable<string>;
    public purchaseOrderList$: BehaviorSubject<PurchaseOrderDetail[]> = new BehaviorSubject([] as PurchaseOrderDetail[]);
    public activePurchaseOrder$ = new BehaviorSubject<PurchaseOrderDetail>({} as PurchaseOrderDetail);
    public isLoading = new BehaviorSubject<Boolean>(false);
    public isLoadingObs = this.isLoading.asObservable();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {}

    fetchPurchaseOrders(): Observable<PurchaseOrderDetail[]> {
        return this.getPurchaseOrders().pipe(
            map((purchaseOrders) => {
                const allPurchaseOrders = purchaseOrders.map((poItem: PurchaseOrderDetail) => {
                    const sortValue = SORT_ORDER.get(poItem.pOstatus);
                    const purchaseOrder = { ...poItem };
                    purchaseOrder['sort'] = sortValue;

                    return purchaseOrder;
                });

                return allPurchaseOrders.sort((a, b) => {
                    const sortA = a.sort || Number.MAX_SAFE_INTEGER;
                    const sortB = b.sort || Number.MAX_SAFE_INTEGER;

                    return sortA - sortB;
                });
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    sendAdvanceShipNotice(formData: AdvancedShipNotification) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<AdvancedShipNotification>(environment.api.tradePartners.vendorASN, formData, { ...options })),
            catchError((err) => {
                throw err;
            })
        );
    }

    public createAcknowledgement(supplierSoNo?: string, acknowledgmentData?: { pOLineNumber: string, ackDate: string }[]) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return combineLatest([options$, this.activePurchaseOrder$]).pipe(
            filter(([_options, activePo]) => Object.keys(activePo).length > 0),
            switchMap(([options, activePo]) => {
                const postatus = activePo.pOstatus;
                if (postatus === PO_STATUS.NEW || postatus === PO_STATUS.CHANGED || postatus === PO_STATUS.WAITING) {
                    const headerData = {
                        poNumber: activePo.pONumber,
                        supplierSoNumber: supplierSoNo || activePo.supplierSoNumber,
                        waitingAcknowledgement: (postatus === PO_STATUS.NEW || postatus === PO_STATUS.CHANGED) && !acknowledgmentData ? 'X' : ''
                    };

                    const acknowledgedLines = activePo.lines.map((lineItem) => {
                        const postData = {
                            poLineItemNumber: parseInt(lineItem.pOLineNumber, 10),
                            poQuantity: parseInt(lineItem.pOOrderQuantity, 10),
                            ackDate: acknowledgmentData ? acknowledgmentData?.find((item) => item.pOLineNumber === lineItem.pOLineNumber)?.ackDate : lineItem.requestedShipDate,
                            unitOfMeasure: 'EA',
                            sapMaterialNumber: lineItem.sapMaterialNumber
                        };

                        return postData;
                    });
                    const formData = {
                        purchaseOrderRequest: {
                            ...headerData,
                            poLines: acknowledgedLines
                        }
                    };

                    return this.httpClient.post<AcknowledgementPost>(environment.api.tradePartners.pOacknowledgement, formData, { ...options });
                }

                return of({});
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    getActivePo(purchaseOrderId: string) {
        return this.purchaseOrderList$.pipe(
            switchMap((list) => {
                const match = list.find((item) => item.pONumber === purchaseOrderId);
                if (match) {
                    return of(match);
                }
                this.isLoading.next(true);

                return this.getPurchaseOrders(purchaseOrderId).pipe(
                    map((purchaseOrder) => {
                        this.isLoading.next(false);
                        const isError = Object.prototype.hasOwnProperty.call(purchaseOrder, 'error');
                        if (isError) {
                            throw new Error('error');
                        }

                        return purchaseOrder[0];
                    }),
                    catchError((error) => throwError(error))
                );
            }),
            catchError((error) => throwError(error))
        );
    }

    private getPurchaseOrders(purchaseOrderId?: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = purchaseOrderId ? `${environment.api.tradePartners.openPos}?ponumber=${purchaseOrderId}` : `${environment.api.tradePartners.openPos}`;

        return options$.pipe(
            switchMap((options) => this.httpClient.get<PurchaseOrderDetail[]>(url, { ...options }))
        );
    }
}
