import { Injectable } from '@angular/core';
import { BaseContentEntity, ContentPage, ContentRegion } from 'common/models';
import { QuickLinksService } from 'common/services/quickLinks.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardContentService {
    public dataSubject$: BehaviorSubject<ContentPage|null> = new BehaviorSubject<ContentPage|null>(null);

    public data$: Observable<ContentPage> = this.dataSubject$.asObservable().pipe(
        filter((content: ContentPage|null): content is ContentPage => Boolean(content))
    );

    public mainRegion$: Observable<ContentRegion> = this.data$.pipe(
        map((content) => content.Regions.find(({ Name: name }) => name === 'Main')),
        filter((region: ContentRegion|undefined): region is ContentRegion => Boolean(region))
    );

    public whatsNew$: Observable<BaseContentEntity|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks1-w100-01')))
    );

    public whatsNewDocList$: Observable<BaseContentEntity|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks1-w100-07') && entity?.Content.tags.$values.find((tag: string) => tag === 'type:whatsnew')))
    );

    public videos$: Observable<BaseContentEntity|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_CardCollection-w4-01')))
    );

    public highlights$: Observable<BaseContentEntity[]|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.filter((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_CardCollection-w2-01')))
    );

    public serviceBulletins$: Observable<BaseContentEntity|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks1-w100-02')))
    );

    public bulletinsDocList$: Observable<BaseContentEntity|undefined> = this.mainRegion$.pipe(
        map((mainRegion) => mainRegion.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks1-w100-07') && entity?.Content.tags.$values.find((tag:string) => tag === 'type:bulletin')))
    );

    public notifications$: Observable<BaseContentEntity[]> = combineLatest([this.data$, this.mainRegion$]).pipe(
        map(([data, region]) => {
            const blksRegion = data.Regions.find(({ Name: name }) => name === 'Blks4');
            if (blksRegion) {
                const notificationEntity = blksRegion.Entities.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks4-w100-News'));
                if (!notificationEntity) {
                    return [];
                }

                return notificationEntity.Content.list.$values || [notificationEntity.Content.list];
            }

            const notificationEntity = region.Entities?.find((entity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_Blks2-w100-01'));
            if (!notificationEntity) {
                return [];
            }

            return notificationEntity.Content.list.$values || [notificationEntity.Content.list];
        })
    );

    constructor(private readonly quickLinks: QuickLinksService) { }

    public addData(data: ContentPage) {
        this.dataSubject$.next(data);
        this.getQuickLinkData(data);
    }

    public getQuickLinkData(data: ContentPage) {
        if (!data || !data.Regions) {
            return;
        }
        const mainRegion = data.Regions.find((region: {Name:string}) => region.Name === 'Main');
        if (!mainRegion) {
            return;
        }
        const quickLinks = mainRegion.Entities?.find(
            (entity: BaseContentEntity) => Boolean(entity?.ComponentTemplate?.Title === 'CT_CardCollection-w3-01')
        );

        return quickLinks ? this.quickLinks.buildQuickLinks(quickLinks) : null;
    }
}
