export enum AppSyncSocketEvent {
    ConnectionInitiated = 'connection_init',
    ConnectionAcknowledged = 'connection_ack',
    KeepAlive = 'ka',
    Start = 'start',
    StartAcknowledged = 'start_ack',
    Data = 'data',
    Stop = 'stop',
    Complete = 'complete',
    Error = 'error',
    ConnectionError = 'connection_error'
}

export enum DiagnosticStageParam {
    CoolStage = 'cool_stage',
    AuxHeatStage = 'aux_heat_stage',
    FanStage = 'fan_stage',
    HpHeatStage = 'hp_heat_stage',
    DehumidifierOn = 'dehumidifierOn',
    HumidifierOn = 'humidifierOn',
    VentilatorOn = 'ventilatorOn'
}

export interface AppSyncQuery {
    query: string;
    operationName?: string;
    variables?: { [key: string]: unknown }
}

export interface AppSyncMessage {
    id?: string;
    type: AppSyncSocketEvent;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}

export interface AppSyncSocketRegistration extends AppSyncMessage {
    type: AppSyncSocketEvent.Start;
    payload: {
        data: string;
        extensions: {
            authorization: {
                'host': string;
                'x-api-key': string;
                'Authorization': string;
            }
        }
    }
}

export interface AppSyncSocketData extends AppSyncMessage {
    type: AppSyncSocketEvent.Data;
    payload: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any;
    }
}

export interface AppSyncSocketDiagnosticsData extends AppSyncSocketData {
    payload: {
        data: {
            subscribe: {
                serialNumber: string,
                liquidLineTemperature: string,
                liquidLinePressure: string,
                dateTime: string,
                blowerRpm: string,
                deltaT: string,
                outdoorTemperature: string,
                returnAirTemperature: string,
                subCooling: string,
                suctionPressure: string,
                suctionTemperature: string,
                superHeat: string,
                supplyAirTemperature: string,
                ErrorMessage: string | null
            }
        };
    }
}

export type AppSyncSocketDiagnosticsDataValues = AppSyncSocketDiagnosticsData['payload']['data']['subscribe'];

export interface AppSyncSocketError extends AppSyncMessage {
    type: AppSyncSocketEvent.Error,
    payload: {
        errors: {
            errorType: string;
            message: string;
        }[]
    }
}

export type AppSyncSocketDiagnosticsErrors = AppSyncSocketError['payload']['errors'];

export interface AppSyncHeaders {
    'x-api-key': string;
    'host': string;
}
