export interface PlaceHolderText {
    placeHolderPhone: string;
    placeHolderOffice: string;
    placeHolderMobile: string;
    placeHolderFax: string;
    placeHolderTollFree: string;
    placeHolderZipcode: {
        label: string;
        info?: string;
    };
}

export class RelationshipConfig {
    brand: boolean | true;
    companyNameOrId: boolean | true;
    tierLevel: boolean | true;
    cinNumber: boolean | true;
    claimType: boolean | true;
}

export interface DealerDistributor {
    comment: string;
    companyRelationShipId: number;
    createdDate: string;
    status: number;
    distributorCompanyId: number;
    distributorCompanyName: string;
    distributorAddress: string;
    validToDate: string;
    isSameHierarchy: string;
}

export interface RelationshipCriteriaDate {
    startDate: number | undefined;
    endDate: number | undefined;
}


export interface BrandedRelationships {
    dealerDistributor: DealerDistributor;
    brandId: string;
    brandName: string;
    tierLevel?: string | null;
    tierLevelId?: number | null;
}

export interface PricingWarrantyRelationships {
    dealerDistributor: DealerDistributor;
    relationshipType: string;
    relationshipId: number;
    relationTypeCode: string | null;
    cin: string;
    hvp: string;
    soldTo: number;
    claims: string;
}

export interface PricingBrandWarrantyRelationship {
    dealerDistributor: DealerDistributor
    brandId?: number | string;
    brandName?: string;
    tierLevel?: string | null;
    tierLevelId?: number | null;
    relationshipType?: string;
    relationshipId?: number;
    relationTypeCode?: string | null;
    cin?: string;
    hvp?: string;
    soldTo?: number;
    claims?: string;
    sapMessage?: string;
}


export interface CompanyRelationshipsResult {
    brandedRelationships: PricingBrandWarrantyRelationship[];
    pricingWarrantyRelationships: PricingBrandWarrantyRelationship[];
    dealerCompanyId?: number;
    deactivatedRelationships?: string[]
    edit?: boolean
}

export interface CompanyLookUpQuery {
    companyType?: string,
    companyCategory?: string;
    brandFamily?: string;
    brand: string[];
    company: string;
}


export interface CSRValidatePostParam {
    CIN: string;
    distributorCompanyId: number;
    relationshipTypeId: number
}

export class BrandedPricingAndWarrantyConfiguration {
    branded: RelationshipConfig = {
        brand: true,
        companyNameOrId: true,
        tierLevel: true,
        cinNumber: false,
        claimType: false
    };

    pricing: RelationshipConfig = {
        brand: false,
        companyNameOrId: true,
        tierLevel: false,
        cinNumber: true,
        claimType: false
    };

    warranty: RelationshipConfig = {
        brand: false,
        companyNameOrId: true,
        tierLevel: false,
        cinNumber: true,
        claimType: true
    };
}

export enum RelationshipTabOption {
    WARRANTY = 'warranty',
    PRICING = 'pricing',
    BRAND = 'branded'
}


