/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { AppConstants } from 'common/app-constants';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { ToastService } from 'common/services/toast.service';
import { differenceInMinutes, format, sub } from 'date-fns';
import { DateRange } from 'private/app/models/account-admin-program.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { ActivityCode, ActivityTrackerService } from 'private/app/services/connected-portal/activity-tracker.service';
import { ChartConfigService, MultiLineDataSet, MultiLineChartColors, RuntimeChartType } from 'private/app/services/connected-portal/chart-config.service';
import { EcobeeReportService, RunTimeReportResponse, DataSummary, RunTimeReportData } from 'private/app/services/connected-portal/ecobee-report.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { ECOBEE_MAX_RUNTIME_REPORT_DAY_RANGE, RUNTIME_REPORT_END_DATE_DIFF_MINUTE_MAX } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

type EnabledDataSets = { heatSetPointEnabled: boolean, coolSetPointEnabled: boolean, indoorTemperatureEnabled: boolean, outdoorTemperatureEnabled: boolean, indoorHumidityEnabled:boolean, outdoorHumidityEnabled:boolean }

interface SwimlanePropsData {
    [key: string]: {start: Date | null, end: Date | null}[];
}

interface SwimlaneChart {
    dates: Date[];
    relayData: SwimlanePropsData;
}

@Component({
    selector: 'hvac-ecobee-run-time-report',
    templateUrl: './ecobee-run-time-report.component.html',
    styleUrls: ['./ecobee-run-time-report.component.scss']
})
export class EcobeeRunTimeReportComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() systemType: string;
    @Input() serialNo: string;
    @Input() modelName: string;
    @Input() propertyId: string;
    @Output() onReportDownload = new EventEmitter();

    public readonly AppConstants = AppConstants;
    public toastOutlet = 'runtimeReportToast';
    public isLoading = false;
    public chartOptions: ChartConfiguration['options'];
    public ecobeeChartOptions: ChartConfiguration['options'];
    public chartType: ChartType = 'line';
    public chartData: null | ChartData<'line'> = null;
    public ecobeeChartData: null | ChartData<'line'> = null;
    public data?: RunTimeReportData;
    public equipmentActivity: { label: string, value: string }[] = [];
    public startDate: number;
    public endDate: number = new Date().getTime();
    public chartLegendFormControlGroup = new UntypedFormGroup(
        {
            heatSetPointEnabled: new UntypedFormControl(true),
            coolSetPointEnabled: new UntypedFormControl(true),
            indoorTemperatureEnabled: new UntypedFormControl(true),
            outdoorTemperatureEnabled: new UntypedFormControl(true)
        }
    )

    public humidChartLegendFormControlGroup = new UntypedFormGroup(
        {
            indoorHumidityEnabled: new UntypedFormControl(true),
            outdoorHumidityEnabled: new UntypedFormControl(true)
        }
    )

    public endDateMaxDiffExceededMessage$ = new BehaviorSubject<string | null>(null);

    public readonly SystemType = SystemType;

    public swimlaneChartData: SwimlaneChart;

    public swimlaneChartDataSummary$ = new BehaviorSubject<DataSummary[]>([]);

    private selectedStartDate: string;
    private selectedEndDate: string;
    private loadChartData$ = new Subject<{startDate: string, endDate: string}>();
    private chartSerialOrEsn: Observable<string>;

    private maxTickCount = {
        mobile: 3,
        tablet: 6
    }

    private pointDivisor = {
        mobile: 3,
        tablet: 1
    }

    constructor(
        private ecobeeReportSvc: EcobeeReportService,
        private csvExportService: CSVExportService,
        private breakpointService: BreakPointService,
        private chartConfigService: ChartConfigService,
        private productService: ProductService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private activityTrackerService: ActivityTrackerService
    ) {
        super();
    }

    ngOnInit():void {
        this.startDate = this.getStartDate(ECOBEE_MAX_RUNTIME_REPORT_DAY_RANGE);

        this.productService.queryWallControlBySerialNo(this.serialNo, this.dealerId, SystemType.ECOBEE)
            .pipe(
                map((res) => {
                    const { fan, cooling, heating } = res.data;

                    return [
                        { ...fan },
                        { ...cooling },
                        ...heating
                    ];
                })
            )
            .subscribe((data) => {
                this.equipmentActivity = data;
            });

        // OBSERVABLES
        this.chartSerialOrEsn = of('').pipe(
            switchMap(() => of(this.serialNo)),
            shareReplay()
        );

        // SUBSCRIPTIONS
        this.chartLegendFormControlGroup
            .valueChanges
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((enabledDataSets) => {
                this.configureChart(this.data?.dataSummary || [], enabledDataSets as EnabledDataSets);
            });

        this.humidChartLegendFormControlGroup
            .valueChanges
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((enabledDataSets) => {
                this.configureHumidityChart(this.data?.dataSummary || [], enabledDataSets as EnabledDataSets);
            });

        this.getDataLoader$().pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    handleDateRangeChange($event: DateRange) {
        if (!($event.startDate && $event.endDate)) {
            return;
        }

        this.selectedStartDate = new Date($event.startDate).toISOString();
        this.selectedEndDate = new Date($event.endDate).toISOString();

        this.loadChartData$.next({
            startDate: this.selectedStartDate,
            endDate: this.selectedEndDate
        });
    }

    onExportClick() {
        if (this.serialNo) {
            const params = {
                startDate: this.selectedStartDate ? this.selectedStartDate : new Date(this.startDate).toISOString(),
                endDate: this.selectedEndDate ? this.selectedEndDate : new Date(this.endDate).toISOString()
            };

            this.chartSerialOrEsn.pipe(
                tap(() => this.isLoading = true),
                switchMap((serialOrEsn) => this.ecobeeReportSvc.queryRuntimeReportBySerialNo(serialOrEsn, this.dealerId, params, this.systemType)),
                take(1),
                catchError(() => {
                    this.addErrorToast();
                    this.isLoading = false;

                    return EMPTY;
                })
            )
                .subscribe((res: RunTimeReportResponse) => {
                    this.isLoading = false;
                    const { filepath } = res.data;
                    this.trackActivity();
                    if (filepath) {
                        this.csvExportService.downloadFile(filepath);
                    }
                });
        }
    }

    private getDataLoader$() {
        return this.loadChartData$.pipe(
            startWith({
                startDate: new Date(this.startDate).toISOString(),
                endDate: new Date(this.endDate).toISOString()
            }),
            tap(() => this.isLoading = true),
            switchMap((params) => this.chartSerialOrEsn.pipe(
                map((serialOrEsn) => ({
                    params,
                    serialOrEsn
                }))
            )),
            switchMap(({ serialOrEsn, params }) => this.ecobeeReportSvc.queryRuntimeReportBySerialNo(serialOrEsn, this.dealerId, params, this.systemType).pipe(
                map((res) => ({
                    startEndDate: params,
                    resData: res
                }))
            )),
            tap(({ startEndDate, resData }) => {
                this.data = resData.data;
                this.configureChart(this.data?.dataSummary || [], this.chartLegendFormControlGroup.value as EnabledDataSets);
                this.configureHumidityChart(this.data?.dataSummary || [], this.humidChartLegendFormControlGroup.value as EnabledDataSets);
                this.swimlaneChartDataSummary$.next(this.data?.dataSummary || []);
                const { endDate } = startEndDate;
                const endDateMaxDiffExceededMessage = this.data.dataSummary
                    ? this.getEndDateMaxDiffExceededMessage(endDate, this.data.dataSummary)
                    : null;

                this.endDateMaxDiffExceededMessage$.next(endDateMaxDiffExceededMessage);
            }),
            map(({ resData }) => resData.data),
            tap(() => this.isLoading = false),
            catchError(() => {
                this.addErrorToast();
                this.isLoading = false;

                return EMPTY;
            })
        );
    }

    private addErrorToast() {
        this.toastService.removeAll();
        this.toastService.add({
            outletName: this.toastOutlet,
            content: this.translateService.instant('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.TOAST.DATA_ERROR'),
            autoClose: true,
            theme: 'error'
        });
    }

    private trackActivity() {
        this.activityTrackerService.trackActivity({ activityCode: ActivityCode.ExportRuntimeReport })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe();
    }

    private getStartDate(daysFromNow: number) {
        return sub(new Date(), { days: daysFromNow }).getTime();
    }

    private getChartLabelsAndDataset(data: DataSummary[]) {
        return [...data]
            .sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime))
            .reduce((acc, curItem) => {
                const label = curItem.dateTime;
                const heatToSetPointValue = curItem.heatToSetPoint;
                const coolToSetPointValue = curItem.coolToSetPoint;
                const indoorTempValue = curItem.indoorTemp;
                const outdoorTempValue = curItem.outdoorTemp;


                return {
                    labels: [...acc.labels, label],
                    heatDataset: [...acc.heatDataset, heatToSetPointValue],
                    coolDataset: [...acc.coolDataset, coolToSetPointValue],
                    indoorTempDataset: [...acc.indoorTempDataset, indoorTempValue],
                    outdoorTempDataset: [...acc.outdoorTempDataset, outdoorTempValue]

                };
            }, {
                labels: [],
                heatDataset: [],
                coolDataset: [],
                indoorTempDataset: [],
                outdoorTempDataset: []

            } as { labels: string[], heatDataset: number[], coolDataset: number[], indoorTempDataset: number[], outdoorTempDataset: number[]});
    }

    private configureChart(data: RunTimeReportData['dataSummary'], enabledDataSets: EnabledDataSets) {
        if (data === null) {
            return;
        }

        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
        const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
        const { labels, heatDataset, coolDataset, indoorTempDataset, outdoorTempDataset } = this.getChartLabelsAndDataset(data);


        const dataSets: MultiLineDataSet[] = [{
            id: 'heatSetPointEnabled',
            configOptions: {
                pointBackgroundColor: MultiLineChartColors.White,
                pointBorderColor: MultiLineChartColors.Red,
                borderColor: MultiLineChartColors.Red
            },
            dataSet: heatDataset
        },
        {
            id: 'coolSetPointEnabled',
            configOptions: {
                pointBackgroundColor: MultiLineChartColors.White,
                pointBorderColor: MultiLineChartColors.Blue,
                borderColor: MultiLineChartColors.Blue
            },
            dataSet: coolDataset
        },
        {
            id: 'indoorTemperatureEnabled',
            configOptions: {
                pointBackgroundColor: MultiLineChartColors.White,
                pointBorderColor: MultiLineChartColors.DarkBlue,
                borderColor: MultiLineChartColors.DarkBlue
            },
            dataSet: indoorTempDataset
        },
        {
            id: 'outdoorTemperatureEnabled',
            configOptions: {
                pointBackgroundColor: MultiLineChartColors.White,
                pointBorderColor: MultiLineChartColors.Turquoise,
                borderColor: MultiLineChartColors.Turquoise
            },
            dataSet: outdoorTempDataset

        }].filter((dataSet) => (enabledDataSets)[dataSet.id as keyof typeof enabledDataSets]);

        const yAxis = [...heatDataset, ...coolDataset, ...indoorTempDataset, ...outdoorTempDataset]
            .flat()
            .sort((valueA, valueB) => valueB - valueA);

        const yPadding = 5;

        let tempMax = yPadding;
        let tempMin = -yPadding;

        if (yAxis.length > 0) {
            tempMax = Math.floor(yAxis.shift() as number);
            tempMin = Math.floor(yAxis.pop() as number);
        }

        const yMax = this.getNextMultipleOfTen(tempMax);
        const yMin = this.getLeastMultipleOfTen(tempMin);

        const additionalParams = {
            xScaleMaxTicks,
            chartType: RuntimeChartType.TEMPERATURE
        };

        this.chartOptions = this.chartConfigService.getMultiLineChartConfig(yMax, yMin, additionalParams, data.length);
        this.chartData = this.chartConfigService.getMultiLineChartData(labels, dataSets, pointDivisor);
    }

    private getLeastMultipleOfTen(num:number) {
        return Math.floor(num / 10) * 10;
    }

    private getNextMultipleOfTen(num:number) {
        return Math.ceil(num / 10) * 10;
    }

    private getHumidityChartLabelsAndDataset(data: DataSummary[]) {
        return [...data]
            .sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime))
            .reduce((acc, curItem) => {
                const label = curItem.dateTime;

                const indoorHumidityValue = curItem.indoorHumidity;
                const outdoorHumidityValue = curItem.outdoorHumidity;

                return {
                    labels: [...acc.labels, label],

                    indoorHumidityDataset: [...acc.indoorHumidityDataset, indoorHumidityValue],
                    outdoorHumidityDataset: [...acc.outdoorHumidityDataset, outdoorHumidityValue]
                };
            }, {
                labels: [],

                indoorHumidityDataset: [],
                outdoorHumidityDataset: []
            } as { labels: string[], indoorHumidityDataset: number[], outdoorHumidityDataset: number[]});
    }

    private configureHumidityChart(data: RunTimeReportData['dataSummary'], enabledDataSets: EnabledDataSets) {
        if (data === null) {
            return;
        }

        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
        const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
        const { labels, indoorHumidityDataset, outdoorHumidityDataset } = this.getHumidityChartLabelsAndDataset(data);

        const dataSets: MultiLineDataSet[] = [

            {
                id: 'indoorHumidityEnabled',
                configOptions: {
                    pointBackgroundColor: MultiLineChartColors.White,
                    pointBorderColor: MultiLineChartColors.Orange,
                    borderColor: MultiLineChartColors.Orange
                },
                dataSet: indoorHumidityDataset
            },
            {
                id: 'outdoorHumidityEnabled',
                configOptions: {
                    pointBackgroundColor: MultiLineChartColors.White,
                    pointBorderColor: MultiLineChartColors.Yellow,
                    borderColor: MultiLineChartColors.Yellow
                },
                dataSet: outdoorHumidityDataset
            }
        ].filter((dataSet) => (enabledDataSets)[dataSet.id as keyof typeof enabledDataSets]);

        const yAxis = [...indoorHumidityDataset, ...outdoorHumidityDataset]
            .flat()
            .sort((valueA, valueB) => valueB - valueA);

        const yPadding = 5;

        let tempMax = yPadding;
        let tempMin = -yPadding;

        if (yAxis.length > 0) {
            tempMax = Math.floor(yAxis.shift() as number);
            tempMin = Math.floor(yAxis.pop() as number);
        }

        const yMax = this.getNextMultipleOfTen(tempMax);
        const yMin = this.getLeastMultipleOfTen(tempMin);

        const additionalParams = {
            xScaleMaxTicks,
            chartType: RuntimeChartType.HUMIDITY
        };

        this.ecobeeChartOptions = this.chartConfigService.getMultiLineChartConfig(yMax, yMin, additionalParams);
        this.ecobeeChartData = this.chartConfigService.getMultiLineChartData(labels, dataSets, pointDivisor);
    }

    private getEndDateMaxDiffExceededMessage(endDate: string, reportData: DataSummary[]): string | null {
        const sortedData = [...reportData].sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime));
        const reportLastEntryTime = sortedData.pop()?.dateTime;

        const isExceeded = reportLastEntryTime ? differenceInMinutes(new Date(endDate), new Date(reportLastEntryTime).setSeconds(0)) > RUNTIME_REPORT_END_DATE_DIFF_MINUTE_MAX : false;

        if (isExceeded && reportLastEntryTime) {
            const formattedDate = format(new Date(reportLastEntryTime), `${AppConstants.dateTimeFormatShort2}`);

            return this.translateService.instant('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.MISSING_RUNTIME_INFORMATION', { reportLastEntryTime: formattedDate });
        }

        return null;
    }
}
