<utc-create-account-header
    (cancelFunction)="handleHeaderCancel()"
    (logoFunction)="handleHeaderLogo()"
    [cancelButtonDisabled]="(apiRequestActive$ | async)!"
    [hideCancelButton]="isIcpDealerRegistrationRequest"
    [cancelButtonText]="'CREATE_ACCOUNT_INFORMATION.HEADER_CANCEL_BUTTON' | translate">
</utc-create-account-header>

<div class='hvac-create-account-information'>
    <h2 class='hvac-create-account-information-title hvac-hero-large-text'> {{'CREATE_ACCOUNT_INFORMATION.TITLE' | translate }}</h2>
    <p class='hvac-create-account-information-body hvac-hero-normal-text'>{{'CREATE_ACCOUNT_INFORMATION.BODY' | translate }}</p>
    <utc-create-account-info-form (onValidChange)="updateInfoValid($event)"></utc-create-account-info-form>
</div>

<utc-tab-navigation-footer
    [continueButtonDisabled]="!(infoValid$ | async)!|| (apiRequestActive$ | async)!"
    [backButtonDisabled]="(apiRequestActive$ | async)!"
    (continueButtonFunction)="submitAccountInfo()" [numberOfSteps]="footerNumberOfSteps" [currentStep]="footerCurrentStep"
    continueText="Continue">
</utc-tab-navigation-footer>
