<section class="hvac-product-details-content-section">
    <div class="hvac-product-content-section-overview" *ngIf="!showPlaceholder; else placeholder">
        <div *ngIf="(showOverview$ | async) || showPlaceholder" class="hvac-product-content-section-overview-component-description">
            <ul *ngIf=" ((brandList$ | async)?.length || 0) > 1" class="hvac-product-catalog-brand-list">
                <li *ngFor="let brand of (brandList$ | async)" class="hvac-product-catalog-hero-brand" (click)="brandClicked(brand)">
                    <img [src]="brand.imageUrl" [alt]="brand.brandName">
                </li>
            </ul>
            <h2 class="hvac-section-heading" [attr.id]="'PRODUCTS.OVERVIEW' | translate" tabindex="-1">
                {{ 'PRODUCTS.OVERVIEW' | translate }}</h2>
            <div class="hvac-product-content-section-text" [innerHTML]="overviewText$ | async"></div>
            <ul class='hvac-product-content-badge-row'
                *ngIf="(attributes$ | async)?.badges && (attributes$ | async)?.badges?.length">
                <li class='hvac-product-content-badge-item' *ngFor="let badge of (attributes$ | async)?.badges">
                    <utc-tooltip>
                        <utc-tooltip-content>
                                <h4 class='hvac-tooltip-heading'>{{ badge.heading }}</h4>
                                <p *ngIf="badge.description" class='hvac-tooltip-description' [innerHTML]="badge.description"></p>
                        </utc-tooltip-content>
                        <utc-tooltip-anchor-content><img class='hvac-product-content-badge-image' [src]="badge.imageUrl"
                                [alt]="badge.heading" /></utc-tooltip-anchor-content>
                    </utc-tooltip>
                </li>
            </ul>
        </div>
        <div *ngIf="imageSrc$ | async" class="hvac-product-content-section-overview-component-media">
            <img class="hvac-product-content-section-overview-component-image" [src]="imageSrc$.value.url"
                [alt]="(attributes$ | async)?.urn" onError="this.src='assets/images/no-image-icon.svg';this.className='hvac-product-content-section-overview-component-image error-image-opacity';"/>
            <div class="hvac-product-content-section-more-images"
                *ngIf="(moreImages$ | async) && (moreImages$ | async)?.length">
                <button type="button" *ngFor="let image of moreImages$ | async" class="col"
                    [ngClass]="{'hvac-product-content-section-more-image-link-active': image.urn === imageSrc$.value.urn}"
                    (click)="changeImage(image)">
                    <img class="hvac-product-content-section-more-image" [src]="image.url"
                        [alt]="'PRODUCTS.ADDITIONAL_PRODUCT_IMAGE' | translate" onError="this.src='assets/images/no-image-icon.svg';this.className='hvac-product-content-section-more-image error-image-opacity';"/>
                </button>
            </div>
        </div>
    </div>
    <hr class="hvac-section-rule">
</section>
<ng-template #placeholder>
    <div class="hvac-product-content-section-overview">
        <div class="hvac-product-content-placeholder">
            <div class="hvac-placeholder-content hvac-placeholder-content-dark hvac-product-content-placeholder-title">
            </div>
            <div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
            </div>
            <div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
                <div class="hvac-placeholder-content hvac-placeholder-content-dark"></div>
            </div>
        </div>
        <div class="hvac-placeholder-content hvac-placeholder-content-dark hvac-product-content-image-placeholder"></div>
    </div>
</ng-template>
