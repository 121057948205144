import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { WallControlNotifications } from 'private/app/models/wall-control.model';

@Component({
    selector: 'hvac-wall-control-notifications',
    templateUrl: './hvac-wall-control-notifications.component.html',
    styleUrls: ['./hvac-wall-control-notifications.component.scss']
})
export class WallControlNotificationsComponent {
    @Input() data: Array<WallControlNotifications>;
    @Input() totalPages: number;
    @Input() currentPage: number;

    @Output() onPageChange = new EventEmitter<number>()

    public readonly AppConstants = AppConstants;

    handlePageChange(page: number) {
        this.onPageChange.emit(page);
    }
}
