import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';

export const DefaultConfig = {
    configType: CompanyCategoryType.DEFAULT,
    association: {
        associatedContact: false,
        associatedDistributor: false,
        associatedDealer: false
    },
    formConfig: {
        claimType: {
            view: false,
            edit: false
        },
        language: {
            view: false,
            edit: false
        },
        contactLic: { view: false },
        ownership: { view: false },
        searchRadius: { view: false },
        legalEntity: { view: false },
        divRegNumber: { view: false },
        c10Child: { view: false },
        c10Parent: { view: false },
        storeNumber: { view: false }
    },
    managerInformation: { show: false },
    addBrands: false,
    program: false

} as AccountAdminDetailsEditConfiguration;
