import { ConnectedProduct } from './connected-product.model';
import { Activity, EventType } from './event.model';

export enum ApiProtocol {
    MQTT = 'MQTT',
    REST = 'REST'
}

export type DataSharingValue = 'true' | 'false' | '';

export class DataSharing {
    viewStatus: DataSharingValue;
    viewConfig: DataSharingValue;
    editConfig: DataSharingValue;
}

export interface ConnectedSystem {
    apiProtocol: ApiProtocol
    dataSharing: DataSharing;
    modelEcosystem: string;
    products: ConnectedProduct[];
    systemId: string;
    systemName: string;
    systemType: string;
    wallControlName: string;
    vppActivity: Activity;
    vppEligibility: EventType;
}

export interface ConnectedSystemsResponse {
    data: ConnectedSystem[];
}

export interface ApiNotificationPreferencesResponse {
    data: {
        dealerCritical: string;
        dealerMaintenance: string;
        dealerOffline: string;
        maxNotifications: number;
        primaryConfirmations: string;
        primaryCritical: string;
        primaryEmail: string;
        primaryMaintenance: string;
        primaryOffline: string;
        primaryUtilityEvents: string;
        secondaryConfirmations: string;
        secondaryCritical: string;
        secondaryEmail: string;
        secondaryMaintenance: string;
        secondaryOffline: string;
        secondaryUtilityEvents: string;
        unresponsiveTimeLimitInHours: number;
    }
}

export interface NotificationPreferences {
    maxStoredNotification: number;
    unresponsiveTimeLimitHours: number;
    customer: {
        primary: null | {
            email: string;
            notifications: {
                maintenanceEnabled: boolean | null;
                offlineEnabled: boolean | null;
                criticalEnabled: boolean | null;
                utilityEventsEnabled: boolean | null;
                confirmationsEnabled: boolean | null;
            }
        },
        secondary: null | {
            email: string;
            notifications: {
                maintenanceEnabled: boolean | null;
                offlineEnabled: boolean | null;
                criticalEnabled: boolean | null;
                utilityEventsEnabled: boolean | null;
                confirmationsEnabled: boolean | null;
            }
        }
    }
    dealerNotifications: {
        criticalEnabled: boolean | null;
        maintenanceEnabled: boolean | null;
        offlineEnabled: boolean | null;
    }
}

export interface NotificationPreferencesResponse {
    data: NotificationPreferences
}
