import { Directive, Input, ElementRef, HostListener, Renderer2, ViewContainerRef, TemplateRef } from '@angular/core';

export enum Placement {
    TOP,
    RIGHT,
    BOTTOM,
    LEFT
}

export interface Tooltip {
    template: TemplateRef<ElementRef>;
    placement: Placement;
    height: number;
    width: number;
    distance: number;
}

@Directive({ selector: '[tooltip]' })
export class TooltipDirective {
    @Input('tooltip') tooltip: Tooltip = {
        template: this.renderer.createElement('hidden'),
        placement: Placement.TOP,
        height: 0,
        width: 0,
        distance: 0
    };

    constructor(
        private hostElementRef: ElementRef<HTMLElement>,
        private renderer: Renderer2,
        private vcr: ViewContainerRef
    ) {

    }

    @HostListener('mouseenter') onMouseEnter() {
        this.createTooltip();
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.vcr.remove();
    }

    createTooltip() {
        let tooltipTop = 0;
        let tooltipLeft = 0;
        let anchorTop = 0;
        let anchorLeft = 0;
        let anchorRotation = 0;
        const distance = this.tooltip?.distance || 0;
        const placement = this.tooltip?.placement || 0;
        const mainHeight = this.tooltip?.height || 0;
        const mainWidth = this.tooltip?.width || 0;

        const hostElement = this.hostElementRef.nativeElement;
        const hostDimensions = {
            top: hostElement.offsetTop,
            left: hostElement.offsetLeft,
            width: hostElement.offsetWidth,
            height: hostElement.offsetHeight
        };
        const tooltipTemplate: TemplateRef<ElementRef> = this.tooltip.template;
        const viewRef = this.vcr.createEmbeddedView(tooltipTemplate);
        const containerElement = viewRef.rootNodes.find((el) => el.className === 'tooltip-container');
        const contentElement = <HTMLDivElement>Array.from(containerElement?.children as HTMLCollection).find((el) => el.className === 'tooltip-content');
        const anchorElement = <HTMLDivElement>Array.from(containerElement?.children as HTMLCollection).find((el) => el.className === 'tooltip-anchor');
        const anchorOffset = anchorElement?.offsetWidth / 2;

        this.renderer.setStyle(containerElement, 'display', 'block');
        this.renderer.setStyle(containerElement, 'position', 'absolute');
        this.renderer.setStyle(containerElement, 'pointer-events', 'none');
        this.renderer.setStyle(containerElement, 'z-index', 'calc(9e5)');

        this.renderer.setStyle(contentElement, 'height', `${mainHeight}px`);
        this.renderer.setStyle(contentElement, 'width', `${mainWidth}px`);

        this.renderer.setStyle(hostElement, 'cursor', 'default');

        switch (placement) {
            case Placement.TOP:
                tooltipTop = hostDimensions.top - mainHeight - anchorOffset - distance;
                tooltipLeft = hostDimensions.left + (hostDimensions.width - mainWidth) / 2;
                anchorTop = mainHeight;
                anchorLeft = (mainWidth - anchorOffset) / 2;
                anchorRotation = 0;
                break;

            case Placement.RIGHT:
                tooltipTop = hostDimensions.top + (hostDimensions.height - mainHeight) / 2;
                tooltipLeft = hostDimensions.left + hostDimensions.width + anchorOffset + distance;
                anchorTop = (mainHeight / 2) - anchorOffset;
                anchorLeft = -anchorOffset * 2;
                anchorRotation = 90;
                break;

            case Placement.BOTTOM:
                tooltipTop = hostDimensions.top + hostDimensions.height + anchorOffset + distance;
                tooltipLeft = hostDimensions.left + (hostDimensions.width - mainWidth) / 2;
                anchorTop = -anchorOffset * 2;
                anchorLeft = (mainWidth - anchorOffset) / 2;
                anchorRotation = 180;
                break;

            case Placement.LEFT:
                tooltipTop = hostDimensions.top + (hostDimensions.height - mainHeight) / 2;
                tooltipLeft = hostDimensions.left - mainWidth - anchorOffset - distance;
                anchorTop = (mainHeight / 2) - anchorOffset;
                anchorLeft = mainWidth;
                anchorRotation = 270;
                break;

            default: return;
        }

        this.renderer.setStyle(containerElement, 'top', `${tooltipTop}px`);
        this.renderer.setStyle(containerElement, 'left', `${tooltipLeft}px`);
        this.renderer.setStyle(anchorElement, 'top', `${anchorTop}px`);
        this.renderer.setStyle(anchorElement, 'left', `${anchorLeft}px`);
        this.renderer.setStyle(anchorElement, 'transform-origin', '50% 50%');
        this.renderer.setStyle(anchorElement, 'rotate', `${anchorRotation}deg`);
    }
}
