import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { format } from 'date-fns-tz';

@Pipe({ name: 'dateTimeTz' })
export class DateTimeTzPipe implements PipeTransform {
    transform(value: string | number | Date | null | undefined, dateFormat: 'date' | 'time' | 'datetime' = 'datetime') {
        switch (dateFormat) {
            case 'datetime': {
                if (value) {
                    const datetime = format(new Date(value), AppConstants.dateTimeTzFormat);

                    return datetime ? `${datetime}` : value;
                }

                return value;
            }
            case 'time': {
                if (value) {
                    const time = format(new Date(value), AppConstants.timeTzFormat);

                    return time ? `${time}` : value;
                }

                return value;
            }
            case 'date': {
                if (value) {
                    const date = format(new Date(value), AppConstants.dateFormat);

                    return date ? `${date}` : value;
                }

                return value;
            }
            default:
                return value;
        }
    }
}
