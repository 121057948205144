import { Injectable } from '@angular/core';
const PUBLIC_ROUTES = ['/login', '/logout', '/create-account-information', '/create-account-company-lookup', '/create-account-email-validate'];

@Injectable({ providedIn: 'root' })

export class PublicRoutesService {
    isPublicRoute(route: string) {
        return PUBLIC_ROUTES.some(
            (publicRoute) => route.startsWith(publicRoute)
        );
    }
}
