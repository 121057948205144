import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { RecentActivityModel } from 'common/models/recent-activity';
import { PerformSearch } from 'common/store/search/search.actions';
import { CloseRecentActivity, ToggleBookmarkSlide } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { Observable } from 'rxjs';


@Component({
    selector: 'recent-activities',
    templateUrl: './recent-activity.component.html',
    styleUrls: ['./recent-activity.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class RecentActivityComponent implements OnInit {
    @Select(UiState.bookmarkSlide) recentActivityVisible$: Observable<Boolean>;

    results: {
        timeStamp: string | null;
        values: RecentActivityModel[]
    }[] = [];

    today: string;

    constructor(private readonly store: Store, public datepipe: DatePipe) { }

    ngOnInit(): void {
        this.recentActivityVisible$.subscribe((visibility) => {
            if (visibility) {
                this.today = this.datepipe.transform(new Date(), 'MMM d, y') || '';
                this.getRecentActivityByDate(localStorage.recent_activity ?
                    JSON.parse(localStorage.getItem('recent_activity') || '[]')
                    : []);
            }
        });
    }

    getRecentActivityByDate(recentactivities: RecentActivityModel[]) {
        const groups = new Set(recentactivities.map((item) => item.timeStamp));
        this.results = [];
        groups.forEach((g) => this.results.push({
            timeStamp: g,
            values: recentactivities.filter((i) => i.timeStamp === g).map((value) => ({
                timeStamp: value.timeStamp,
                pageTitle: value.pageTitle,
                pageUrl: value.pageUrl,
                routerLink: {
                    url: value.pageUrl.split('?')[0],
                    params: value.pageUrl.includes('?') ? JSON.parse(`{"${decodeURI(value.pageUrl.split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/[=]/g, '":"')}"}`) : ''
                },
                breadcrumb: value.breadcrumb

            }))
        }));
    }

    closeSlide() {
        this.store.dispatch(new ToggleBookmarkSlide(false));
    }

    closeRecentActivity() {
        this.store.dispatch(new CloseRecentActivity());
    }

    openRecentLink(link: string) {
        const decodedURL = decodeURIComponent(link);
        if (decodedURL.includes('/search') && decodedURL.includes('?')) {
            const httpParams = new HttpParams({ fromString: decodedURL.split('?')[1] });
            const query = { q: httpParams.get('q') as string };
            this.store.dispatch(new PerformSearch(query));
        }
        this.store.dispatch(new Navigate([link]));
        this.store.dispatch(new ToggleBookmarkSlide(false));
    }
}
