import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';

@Component({
    selector: 'hvac-factory-advanced-search',
    templateUrl: './factory-advanced-search.component.html',
    styleUrls: ['./factory-advanced-search.component.scss']
})
export class FactoryAdvancedSearchComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = {};
    public componentName:string = this.translateService.instant('CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.FACTORY_SEARCH');
    constructor(
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.breadCrumbData = this.getBreadcrumbData();
    }

    private getBreadcrumbData() {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.ADVANCED_SEARCH.HEADING') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal/dashboard'
                },
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.ADVANCED_SEARCH'),
                    url: '/connected-portal/advanced-search'
                }
            ]
        };
    }
}
