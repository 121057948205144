import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeapService } from 'common/services/heap.service';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';

@Component({
    selector: 'hvac-remove-control-modal',
    templateUrl: './remove-control-modal.component.html',
    styleUrls: ['./remove-control-modal.component.scss']
})
export class RemoveControlModalComponent {
    @Input() isModalVisible: boolean;
    @Input() heading: string | undefined;
    @Input() message: string | undefined;
    @Input() controlType:string;
    @Output() onCloseModal = new EventEmitter();
    @Output() onConfirmButtonClick = new EventEmitter();

    constructor(
        private heapService: HeapService,
        private translateService: TranslateService
    ) {

    }

    handleCloseModal() {
        this.onCloseModal.emit();
    }

    handleConfirmAction() {
        if (this.controlType === this.translateService.instant('CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CONNECTED_CONTROLS')) {
            this.heapService.track(eventNames.handleRemoveConnectedControlClick);
        }
        else {
            this.heapService.track(eventNames.handleRemoveQueuedControlClick);
        }
        this.onConfirmButtonClick.emit();
    }
}
