
<div>
    <div class="hvac-trade-partner-subhead" *ngIf="(user$ | async) as user">
        <h2 class="hvac-trade-partner-subhead-text">
            {{user.company.name}}
        </h2>
    </div>
    <utc-loading-spinner class="hvac-po-list-loader" *ngIf="isLoading"></utc-loading-spinner>
    <form [ngClass]="{'hvac-pos-search-input-has-error': hasError}" (submit)="onSubmit()">
        <div class="hvac-po-list-search">
            <utc-search-input
                [formControl]="purchaseOrderSearch"
                class="hvac-po-list-search-input"
                inputId="hvac-search-hero-input-id"
                name="search"
                type="text"
                [hideLabel]="true"
                [placeholder]="'TRADE_PARTNERS.SEARCH.PLACEHOLDER' | translate"
                [theme]="searchTheme"
                autocomplete="off"
                [enableClearButton]="false"
                [headerSearchInput]="true"
                [enableAutoSuggestions]="false">
            </utc-search-input>
            <button buttonType="submit" class="hvac-search-po-submit hvac-button">
                <span class="hvac-accessible-text">{{ 'TRADE_PARTNERS.SEARCH_CALL_TO_ACTION' | translate }}</span>
                <utc-svg class="hvac-icon-button-icon" src="assets/icons/search.svg"></utc-svg>
            </button>
        </div>
        <div class="hvac-po-list-search-error">
            <utc-svg src="assets/icons/info.svg"></utc-svg>
            <span class='hvac-po-list-search-error-message'>{{ 'TRADE_PARTNERS.SEARCH.ERROR' | translate }}</span>
        </div>
    </form>

    <ng-template [ngIf]="purchaseOrders$ | async" let-purchaseOrders [ngIfElse]="emptyState">
        <hvac-table class="hvac-po-list-search-results" [responsive]="true" theme="zebra">
            <thead>
                <tr>
                    <td>{{ 'TRADE_PARTNERS.PO_LIST.HEADERS.STATUS' | translate }}</td>
                    <td>{{ 'TRADE_PARTNERS.PO_LIST.HEADERS.PO_NO' | translate }}</td>
                    <td>{{ 'TRADE_PARTNERS.PO_LIST.HEADERS.DATE' | translate }}</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let order of purchaseOrders | slice:pagination['start']:pagination['end']">
                    <td data-label="Status" [ngSwitch]="order.pOstatus">
                        <ng-template [ngSwitchCase]="poStatusOptions.CHANGED">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.CHANGED' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.NEW">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.NEW' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.WAITING">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.WAITING' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.ACKNOWLEDGED">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.ACKNOWLEDGED' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.PARTIAL_SHIP">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.PARTIAL_SHIP' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.CANCELLED">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.CANCELLED' | translate }}
                        </ng-template>
                        <ng-template [ngSwitchCase]="poStatusOptions.FULLY_SHIPPED">
                            {{ 'TRADE_PARTNERS.PO_LIST.STATUS.FULLY_SHIPPED' | translate }}
                        </ng-template>
                        <ng-template *ngSwitchDefault>*</ng-template>
                    </td>
                    <td data-label="Purchase Order No.">{{ order.pONumber }}</td>
                    <td data-label="Creation Date">{{ order.pOCreationDate | yearMoDate }}</td>
                    <td data-label="" class="hvac-tp-actions">
                        <a [routerLink]="order.pOstatus === poStatusOptions.ACKNOWLEDGED || order.pOstatus === poStatusOptions.PARTIAL_SHIP ? order.pONumber + '/advanced-shipment' : order.pONumber" [hvacButton]="'primary'" size="small">{{ 'TRADE_PARTNERS.PO_ITEM.VIEW' | translate }}</a>
                    </td>
                </tr>
                
            </tbody>
        </hvac-table>
        <hvac-pagination
            [currentPage]="pagination.currentPage"
            [totalPages]="pagination.totalPages"
            (pageChange)="onPagination($event)"
        ></hvac-pagination>
    </ng-template>
    <ng-template #emptyState><p class="hvac-trade-partners-purchase-order-list-empty hvac-p">{{ 'TRADE_PARTNERS.PO_LIST.EMPTY_STATE' | translate }}</p></ng-template>
</div>