<div class="hvac-cp-dealer-pending-activation-container">
    <div class="hvac-cp-dealer-pending-activation-notification">
        <utc-svg class="hvac-cp-dealer-pending-activation-icon" src="assets/images/clipboard.svg"></utc-svg>
        <div class="hvac-cp-dealer-pending-activation-message">
            <p class="hvac-cp-dealer-pending-activation-title">{{ 'CONNECTED_PORTAL.PENDING_ACTIVATION.DEALER.ACCOUNT_REVIEWED' | translate }}
            </p>
            <p class="hvac-cp-dealer-pending-activation-body">
                {{ 'CONNECTED_PORTAL.PENDING_ACTIVATION.DEALER.ACCOUNT_EXPECTED_TIME' | translate }}
                <br />
                <span
                    [innerHTML]="'CONNECTED_PORTAL.PENDING_ACTIVATION.DEALER.ACCOUNT_ASSISTANCE' | translate | safe: 'html'"></span>
            </p>
        </div>
    </div>
</div>

