import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FrameContentComponent } from './frame-content.component';
import { FrameContentRoutingModule } from './frame-content-routing.module';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from 'common/components/common-components.module';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FrameContentRoutingModule,
        CommonComponentsModule,
        SafePipeModule
    ],
    declarations: [
        FrameContentComponent
    ],
    exports: [FrameContentComponent]
})
export class FrameContentModule {}
