import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'hvac-distributor-advanced-search',
    templateUrl: './distributor-advanced-search.component.html',
    styleUrls: ['./distributor-advanced-search.component.scss']
})
export class DistributorAdvancedSearchComponent extends BaseComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = {};
    public distributorId: string | null;
    public componentName:string = this.translateService.instant('CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DISTRIBUTOR_SEARCH');
    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.route.parent) {
            return;
        }

        this.route.parent.paramMap
            .pipe(
                tap((params) => {
                    this.distributorId = params?.get('id') || null;
                    this.breadCrumbData = this.getDistributorBreadcrumbData();
                }),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe();
    }

    private getDistributorBreadcrumbData() {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.ADVANCED_SEARCH.HEADING') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal/distributors'
                }
            ]
        };
    }
}
