import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';


export const InternalConfig = {
    configType: CompanyCategoryType.INTERNAL,
    association: {
        associatedContact: true,
        addContact: false
    },
    formConfig: {
        companyUrl: { view: true },
        companyName: {
            view: true,
            edit: true
        },
        parentCompany: {
            view: true,
            edit: true
        },
        address1: {
            view: true,
            edit: true
        },
        address2: {
            view: true,
            edit: true
        },
        country: {
            view: true,
            edit: true
        },
        state: {
            view: true,
            edit: true
        },
        city: {
            view: true,
            edit: true
        },
        postalCode: {
            view: true,
            edit: true
        },
        phone: {
            view: true,
            edit: true
        },
        mobile: {
            view: true,
            edit: true
        },
        tollFree: {
            view: true,
            edit: true
        },
        fax: {
            view: true,
            edit: true
        },
        email: {
            view: true,
            edit: true
        },
        notes: {
            view: true,
            edit: true
        },
        brands: { view: true }
    },
    addBrands: true

}as AccountAdminDetailsEditConfiguration;
