import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'DateStatus' })
export class DateStatusPipe implements PipeTransform {
    transform(_date: string, today: string): string {
        if (_date === today) {
            return 'Today';
        }

        return _date;
    }
}
