import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { UpdateCriteriaData } from 'private/app/models/account-admin-program.model';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { ToastService } from 'common/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RuleSet, QueryBuilderConfig } from 'common/libs/angular2-query-builder/query-builder/query-builder.interfaces';
@Injectable({ providedIn: 'root' })
export class EligibilityCriteriaQueryConfig {
    public bannerType = 'EditEligibiltyCriteria';
    public criteriaDetails: UpdateCriteriaData = {
        criteriaId: 0,
        criteriaName: '',
        criteriaFilter: '',
        dealerCompanyIds: [],
        distributorCompanyIds: [],
        programIds: [],
        selectedFields: []
    };

    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.EDIT_ELIGIBILITY_CRITERIA') },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                url: '/Admin/Program'
            }
        ]
    }

    public emptyQueryData: RuleSet = {
        condition: '',
        rules: []
    };

    public QueryBuilderConfigValues: QueryBuilderConfig = {
        fields: {
            TierLevel: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.TIER_LEVEL'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                defaultValue: '1',
                options: [{
                    value: '1',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TIER_LEVEL_OPTIONS.LEADERSHIP')
                }, {
                    value: '2',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TIER_LEVEL_OPTIONS.ADVERTISING')
                }, {
                    value: '3',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TIER_LEVEL_OPTIONS.RESIDENTIAL_CONTRACTOR')
                }, {
                    value: '4',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TIER_LEVEL_OPTIONS.OTHER')
                }, {
                    value: '5',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.TIER_LEVEL_OPTIONS.COMMERCIAL_CONTRACTOR')
                }]
            },
            CompanyType: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.COMPANY_TYPE'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                defaultValue: '7',
                options: [{
                    value: '7',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_TYPE_OPTIONS.DEALER_CONTRACTOR')
                }, {
                    value: '15',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_TYPE_OPTIONS.VENDOR')
                }, {
                    value: '16',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_TYPE_OPTIONS.INTERNAL')
                }, {
                    value: '17',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_TYPE_OPTIONS.DOMESTIC_DISTRIBUTOR')
                }, {
                    value: '18',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_TYPE_OPTIONS.INTERNATIONAL_DISTRIBUTOR')
                }]
            },
            CompanyService: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.COMPANY_SERVICE'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                defaultValue: '7',
                options: [{
                    value: '7',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_SERVICE_OPTIONS.DISTRIBUTOR_ICPD')
                }, {
                    value: '20',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_SERVICE_OPTIONS.WARRANTY_DISTRIBUTOR_WTYD')
                }, {
                    value: '23',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_SERVICE_OPTIONS.COMMERCIAL_ICPC')
                }, {
                    value: '24',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.COMPANY_SERVICE_OPTIONS.RESIDENTIAL_ICPR')
                }]
            },
            ProductOffering: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.PRODUCT_OFFERING'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                defaultValue: '4',
                options: [{
                    value: '4',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PRODUCT_OFFERING_OPTIONS.DUCTLESS_SPLIT_SYSTEMS_BRYANT')
                }, {
                    value: '5',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PRODUCT_OFFERING_OPTIONS.GEOTHERMAL_BRYANT')
                }, {
                    value: '6',
                    name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PRODUCT_OFFERING_OPTIONS.INDOOR_AIR_QUALITY_SPECIALIST_BRYANT')
                }]
            },
            CompanyId: {
                type: 'number',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.COMPANY_ID'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')]
            },
            Name: {
                type: 'string',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.COMPANY_NAME'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.CONTAINS'),
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.BEGINS_WITH')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.ENDS_WITH')]]
            },
            City: {
                type: 'string',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.CITY'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.CONTAINS'),
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.BEGINS_WITH')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.ENDS_WITH')]]
            },
            Country: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.COUNTRY'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                options: [],
                defaultValue: 224
            },
            StateProvince: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.STATE'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                options: []
            },
            PostalCode: {
                type: 'string',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.POSTAL_CODE'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.CONTAINS'),
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.BEGINS_WITH')],
                    [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.ENDS_WITH')]]
            },
            ProgramId: {
                type: 'category',
                name: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.PROGRAM_NAME'),
                operators: [this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_OPTIONS.EQUALS')],
                options: []
            }
        }
    };

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        private toastService: ToastService

    ) {}

    showToastBar(error: HttpErrorResponse) {
        this.accountAdminProgramService.smoothScrollToTop();
        this.toastService.add({
            content: error.error.message,
            theme: 'error',
            id: this.bannerType,
            closeable: true,
            autoClose: true
        });
    }
}
