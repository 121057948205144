<section class="hvac-product-details-content-section hvac-product-content-section-physical-data" *ngIf="showPhysicalData$ | async; else productDataOverview ">
    <div class="hvac-product-content-section-overview">
        <div class="hvac-product-content-section-overview-description">
            <h2 class="hvac-section-heading" [attr.id]="'PRODUCTS.PHYSICAL_DATA' | translate" tabindex="-1">{{ 'PRODUCTS.PHYSICAL_DATA' | translate }}</h2>
            <div class="hvac-product-content-section-text" [innerHTML]="physicalDataTexts$ | async"></div>
        </div>
    </div>
    <hr class="hvac-section-rule">
</section>
<ng-template #productDataOverview><utc-product-content-section-product-data></utc-product-content-section-product-data></ng-template>
