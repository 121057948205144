import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ContentService } from 'common/services/content.service';
import { environment } from 'common/environments/environment';
import { NavMenu } from 'common/services/navigation-content.service';
import { ClearUIPageContent, SetContentLoading } from 'common/store/content/content.actions';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, take } from 'rxjs/operators';
import { AccountStatusService } from '../../services/account-status.service';
import { ReceiveUIContent, SetContentBrandPreference } from '../../store/content/content.actions';
import { ContentState } from 'common/store/content/content.state';
import { BrandService } from 'common/services/brand.service';
import { Navigate } from '@ngxs/router-plugin';
import { SearchQuery, UIContentResponse } from 'common/models';
import { PerformSearch } from 'common/store/search/search.actions';
import { SearchState } from 'common/store/search/search.state';
import { ErrorService, SiteMap } from './error.service';

@Injectable({ providedIn: 'root' })
export class DataResolverService implements Resolve<object> {
    @Select(NavigationState.currentPage) navItem$!: Observable<NavMenu>;
    @Select(SearchState.query) searchQuery$!: Observable<SearchQuery>;

    searchTerms$ = this.searchQuery$.pipe(map((query) => query.q));

    public resolving$: BehaviorSubject<number> = new BehaviorSubject(1);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public showNav$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public pageData$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

    private noNavItem$: Observable<boolean>;

    constructor(
        private readonly contentService: ContentService,
        private readonly store: Store,
        private readonly accountStatusService: AccountStatusService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private readonly brandService: BrandService,
        private readonly errorService: ErrorService
    ) {
        this.noNavItem$ = this.navItem$.pipe(
            debounceTime(1000),
            map((navItem) => !navItem)
        );
    }


    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<object> | Observable<never> {
        if (this.router.url.includes('/search') || this.router.url.includes('/Admin-Tools')) {
            this.resolving$.next(0);

            return of({});
        }
        let page = state.url || route.url.join('/');

        if (page.startsWith('/products') && (environment.brand === 'Carrier' || environment.brand === 'Bryant')) {
            page = '/products';
        }

        this.resolving$.next(1);
        this.store.dispatch(new SetContentLoading(true));

        this.pageData$.next({});

        return this.contentService.fetchContentPage<object>(page).pipe(
            take(1),
            mergeMap((res: object) => {
                this.store.dispatch(new ClearUIPageContent());

                this.errorService.errorStatus$.next(0);
                this.resolving$.next(0);

                if (res) {
                    this.store.dispatch(new SetContentLoading(false));
                    this.showNav$.next(false);
                    this.pageData$.next(res);

                    return of(res);
                }

                this.store.dispatch(new SetContentLoading(false));
                this.showNav$.next(false);

                return of({});
            }),
            catchError((err: {status:number}) => {
                this.noNavItem$
                    .pipe(take(1))
                    .subscribe((noNavItem) => {
                        if (noNavItem) {
                            this.showNav$.next(false);
                            this.errorService.errorStatus$.next(err.status);
                            this.resolving$.next(0);
                        }
                        else {
                            this.showNav$.next(true);
                            this.errorService.errorStatus$.next(0);
                            this.resolving$.next(0);
                        }
                    });

                this.store.dispatch(new SetContentLoading(false));

                return of({});
            })
        );
    }

    public resolveData() {
        let isInitialLoad: boolean = true;

        if (isInitialLoad) {
            combineLatest([this.contentService.siteMap$, this.contentService.fetchUiContent()]).pipe(
                map(([siteMap, res]) => {
                    if (siteMap && res) {
                        isInitialLoad = false;

                        this.handleData(this.router.routerState.snapshot.url.split('?')[0], res);
                    }
                })
            ).subscribe();
        }

        this.router.events.subscribe((val) => {
            if (isInitialLoad) {
                return;
            }

            if (val instanceof NavigationEnd) {
                // No data resolution
                if (
                    this.route.snapshot.queryParams.error === 'login_required' ||
                    val.url === '/logout' ||
                    val.url === '/login' ||
                    this.router.url.includes('/search') ||
                    val.url.includes('/callback?') ||
                    val.url.startsWith('/connected-portal')
                ) {
                    this.resolving$.next(0);

                    return;
                }

                // UI and Tridion Page Content
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.contentService.fetchUiContent().subscribe((res: any) => {
                    this.handleData(val.url, res);
                });
            }
        });
    }

    isICPPublicPage(pageURL:string): boolean {
        if (pageURL === '/') {
            return false;
        }

        // entry to check if user has icp brands in preference
        const userBrands = this.store.selectSnapshot(ContentState.contentBrandList);
        if (this.brandService.filterICPBrands(userBrands.map((brand) => brand.brandName)).length === 0) {
            return false;
        }

        const siteData = this.errorService.siteMap$.value.find((option: SiteMap) => option.Url.split('/').pop() === pageURL.split('/').pop()?.split('?')[0]);

        return siteData ? Boolean(this.brandService.filterICPBrands(siteData?.brands).length) : false;
    }

    public updateUserBrandPreferences(brandName: string, contentFilteringPreference: string): void {
        this.resolving$.next(1);

        this.accountStatusService.updateUserPref({
            brandName: brandName === 'default' ? 'All Brands' : brandName,
            companyTypes: contentFilteringPreference
        }).subscribe(() => {
            this.store.dispatch(new SetContentBrandPreference(brandName));

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.contentService.fetchUiContent().subscribe((res: any) => {
                this.handleData(this.router.routerState.snapshot.url.split('?')[0], res);
            });

            if (this.router.url.includes('/search/')) {
                this.searchTerms$.pipe(take(1)).subscribe((term) => {
                    const query = { q: term };

                    this.store.dispatch(new PerformSearch(query));
                });
            }
        });
    }

    public simplifyUrl(url: string) {
        return url.split(' ').join('').split('-').join('');
    }

    private handleData(url: string, res: UIContentResponse) {
        this.store.dispatch(new ReceiveUIContent(res));

        if (this.errorService.isTridion(url)) {
            if (this.isICPPublicPage(url) && environment.brand === 'private') {
                if (this.store.selectSnapshot(ContentState.contentBrand).toLowerCase() === 'default' && !url.split('/').pop()?.split('?')[1]) {
                    this.store.dispatch(new Navigate(['/brand-select'], { url: this.router.routerState.snapshot.url }));

                    return;
                }
                const prefBrand = this.store.selectSnapshot(ContentState.contentBrand);
                if (!url.split('/').pop()?.split('?')[1]) {
                    this.router.routerState.snapshot.url = this.router.routerState.snapshot.url.concat(`?Brand=${encodeURIComponent(prefBrand)}`);
                    this.location.replaceState(this.router.routerState.snapshot.url);
                }
                this.resolve(this.route.snapshot, this.router.routerState.snapshot).subscribe();

                return;
            }
            this.resolve(this.route.snapshot, this.router.routerState.snapshot).subscribe();
        }
        else {
            this.resolving$.next(0);
            this.showNav$.next(true);
            this.store.dispatch(new SetContentLoading(false));
        }
    }
}
