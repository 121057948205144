<header class="hvac-private-header" [class.hvac-header-search-active]="safeSearchVisible$ | async"
    [class.hvac-header-menu-visible]="(mobileMenuVisible$ | async) || (tabletMenuVisible$ | async)">
    <a [routerLink]="[]" fragment="main-content" queryParamsHandling="preserve" [preserveFragment]="true"
        class="hvac-skip-to-main-content" (click)="skipToMainContent()" (focus)="scrollToTop()">{{
        'SKIP_TO_MAIN_CONTENT' | translate }}</a>
    <section class="hvac-private-header-content hvac-private-header-content-left">
        <button class="hvac-private-mobile-menu-button hvac-private-mobile-menu-open" buttonType="button"
            (click)="openMobileMenu()" title="{{ 'HEADER.OPEN_NAVIGATION' | translate }}">
            <utc-svg class="hvac-private-header-mobile-menu-icon-open" src="assets/icons/menu.svg"></utc-svg>
        </button>
        <div class="hvac-private-header-logo-container">
            <a routerLink="/" class="hvac-private-header-logo-link">
                <span class="hvac-accessible-text">{{ 'BRAND' | translate }}</span>
                <utc-svg class="hvac-private-header-logo" src="assets/images/hvac-partners.svg"></utc-svg>
            </a>
        </div>
        <div *ngIf="brandFilter" class="hvac-brand-section">
            <hvac-brand-filter></hvac-brand-filter>
        </div>
    </section>
    <nav class="hvac-private-header-nav"
        [ngClass]="{'hvac-mobile-menu-active': (mobileMenuVisible$ | async) || (tabletMenuVisible$ | async)}">
        <div class="hvac-mobile-menu-header">
            <button class="hvac-private-header-mobile-menu-button hvac-private-header-mobile-menu-close"
                buttonType="button" (click)="closeMobileMenu()" title="{{ 'OPEN_MENU' | translate }}">
                <utc-svg class="hvac-private-header-mobile-menu-icon-close" src="assets/icons/close.svg"></utc-svg>
            </button>
            <div class='hvac-private-header-mobile-back-button'
                *ngIf="(backButtonCurrentMenu$ | async) else firstLevelMobileMenu;">
                <span class='hvac-private-header-mobile-back-text'>{{(backButtonCurrentMenu$ | async)?.title}}</span>
                <button class="hvac-private-header-mobile-menu-button hvac-private-header-mobile-menu-back"
                    buttonType="button" (click)="handleMobileNavigateBack()" title="{{ 'NAVIGATE_BACK' | translate }}">
                    <utc-svg class="hvac-private-header-mobile-menu-icon-back" src="assets/icons/chevron-down.svg">
                    </utc-svg>
                </button>
            </div>
            <ng-template #firstLevelMobileMenu>
                <div class='hvac-private-header-first-level-mobile'>
                    <ng-container *ngIf="(bookmarksMenu$ | async)">
                        <div class='hvac-private-header-mobile-back-button hvac-private-header-bookmark-back-button '>
                            <span class='hvac-private-header-mobile-back-text'>{{ 'HEADER.ICON_LINKS.BOOKMARK' |
                                translate}}</span>
                            <button class="hvac-private-header-mobile-menu-button hvac-private-header-mobile-menu-back"
                                buttonType="button" (click)="handleCloseBookmarks()"
                                title="{{ 'HEADER.CLOSE_BOOKMARK' | translate }}">
                                <utc-svg class="hvac-private-header-mobile-menu-icon-back"
                                    src="assets/icons/chevron-down.svg">
                                </utc-svg>
                            </button>
                        </div>
                    </ng-container>
                    <hvac-private-header-icon-links *ngIf="isActiveUser$ | async"></hvac-private-header-icon-links>
                    <div class="hvac-private-header-account-details-select">
                        <hvac-user-detail-dropdown [icon]="'assets/icons/user-solid.svg'" ></hvac-user-detail-dropdown>
                    </div>
                    <div *ngIf="(loggedIn$ | async)" class="hvac-private-header-sign-out">
                        <button hvacButton="tertiary" size="small" (click)="signOut()">
                            {{ 'HEADER.SIGNOUT' | translate }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </nav>
    <section class='hvac-private-header-content hvac-private-header-content-right'>
        <hvac-private-header-icon-links *ngIf="isActiveUser$ | async"></hvac-private-header-icon-links>
        <div class="hvac-private-header-account-details-select">
            <hvac-user-detail-dropdown></hvac-user-detail-dropdown>
        </div>
        <div *ngIf="(loggedIn$ | async)" class="hvac-private-header-sign-out">
            <button hvacButton="tertiary" size="small" (click)="signOut()">{{ 'HEADER.SIGNOUT' | translate }}</button>
        </div>
    </section>
    <div class="hvac-header-search">
        <utc-button buttonType="submit" classNames="hvac-search-hero-submit" iconSrc="assets/icons/search.svg"
            title="{{ 'SEARCH' | translate }}" (click)="displaySearch()" buttonId="open-search"></utc-button>
        <form class="hvac-header-search-form" (submit)="onSubmit()">
            <utc-search-input
                [formControl]="queryControl"
                name="q"
                inputId="hvac-header-search"
                autocomplete="off"
                label="{{ 'SEARCH' | translate }}"
                [title]="'SEARCH' | translate"
                [placeholder]="'SEARCH' | translate"
                type="text"
                [hideLabel]="true"
                theme="light"
                [enableAutoSuggestions]="queryControl.valid"
                [enableClearButton]="true"
                [headerSearchInput]="true"
                (closeSearch)="hideSearch()">
            </utc-search-input>
            <button [disabled]="!queryControl.valid" type="submit" class="hvac-invisible-submit" tabindex="-1">
                <span class="hvac-accessible-text">{{'SEARCH' | translate }}</span>
            </button>
        </form>
    </div>
</header>
