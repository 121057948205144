<section class="hvac-manage-subscription-page">
    <h1 class="hvac-h2 hvac-rhythm-3">{{'ACCOUNT_ADMIN.MANAGE_SUBSCRIPTIONS.TITLE' | translate}}</h1>
    <p class="hvac-p">{{'ACCOUNT_ADMIN.MANAGE_SUBSCRIPTIONS.SUBTITLE' | translate}}</p>

    <form [formGroup]="manageSubscriptionsForm">
        <p class="hvac-h6 hvac-manage-subscription-terms">{{'ACCOUNT_ADMIN.MANAGE_SUBSCRIPTIONS.NOTE' | translate}}</p>
        <div class="hvac-manage-subscription-terms-control-container">
            <div *ngIf="!isLoading;else loadingSpinner">
                <utc-checkbox
                    [checked]="(isSubscribed$ | async)!"
                    inputId="user-validation-input-terms"
                    inputName="user-validation-input-terms"
                    (change)="changeSubscriptionControl($event)"
                    class="hvac-label-sm">
                </utc-checkbox>
            </div>
            <ng-template #loadingSpinner>
                <utc-loading-spinner [spinnerSize]="'small'"></utc-loading-spinner>
            </ng-template>
            <p class='hvac-h6 hvac-manage-subscription-terms hvac-terms-label'>{{ 'USER_VALIDATION.SUBSCRIPTION_TERMS_AND_CONDITION' | translate  }}</p>
        </div>

        <hr />

        <div class="hvac-manage-subscription-form-foot-container">
            <button class="hvac-manage-subscription-submit" [hvacButton]="'primary'" [disabled]="apiRequestActive" (click)="updateUserProfileDetails()">
                <span>{{ 'USER_VALIDATION.SUBMIT' | translate }}</span>
            </button>
        </div>
    </form>
</section>
