<utc-create-account-header (cancelFunction)="handleHeaderCancel()" (logoFunction)="handleHeaderLogo()"
    [cancelButtonDisabled]="(apiRequestActive$ | async)!"
    [cancelButtonText]="'CREATE_ACCOUNT_LOOKUP.HEADER_CANCEL_BUTTON' | translate"></utc-create-account-header>
<div class='hvac-create-account-lookup'>

    <div class='hvac-create-account-lookup-heading'>
        <h2 class='hvac-create-account-lookup-title hvac-hero-large-text'> {{'CREATE_ACCOUNT_LOOKUP.TITLE' | translate
            }}
        </h2>
        <div class='hvac-create-account-lookup-banner-container'>
            <hvac-toast></hvac-toast>
        </div>
        <div class='hvac-create-account-lookup-body'>
            <div class='hvac-create-account-lookup-input-container'>
                <utc-input inputId="create-account-lookup-input"
                    type="password"
                    [inputControl]="companyCodeControl"
                    [label]="(companyCodeInfoMarkup$ | async ) ? '' : 'CREATE_ACCOUNT_LOOKUP.LABEL.COMPANY_CODE' | translate"
                    [value]="(codeStatus$ | async) ? (companyCode$ | async)! : ''"
                    [error]="companyCodeControl.dirty && !companyCodeControl.valid"
                    [errorMessage]="companyCodeErrorMessage$ | async"
                    [infoMessage]="(companyCodeInfoMarkup$ | async)!"
                    [hasIconFunction]="!(!!(companyCodeName$ | async))"
                    [iconPosition]="'right'"
                    [labelIcon]="(companyCodeIcon$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    (iconClickFunction)="checkCompanyCode($event)"
                    (enterKeyFunction)="checkCompanyCode($event)"
                >
                </utc-input>
            </div>
            <p class='hvac-create-account-lookup-body-copy hvac-hero-normal-text'>{{'CREATE_ACCOUNT_LOOKUP.BODY' |
                translate
                }}</p>
        </div>
    </div>
    <div class='hvac-create-account-lookup-info-container'>
        <div class='hvac-create-account-lookup-info-column'>
            <p class='hvac-create-account-lookup-info-title'>{{'CREATE_ACCOUNT_LOOKUP.LOOKUP_TITLE' | translate}}</p>
            <p class='hvac-create-account-lookup-info-body'>{{'CREATE_ACCOUNT_LOOKUP.LOOKUP_BODY' | translate}}</p>
            <div class='hvac-create-account-lookup-info-input'>
                <utc-input [error]="zipCode.dirty && !zipCode.valid" [errorMessage]="zipCodeErrorMessage$ | async"
                    [inputControl]="zipCode" inputId="create-account-postcode-lookup-input"
                    [label]="'CREATE_ACCOUNT_LOOKUP.LABEL.ZIP_CODE' | translate" type="text"
                    [labelIcon]="'assets/icons/search.svg'" iconPosition="right" [hasIconFunction]="true"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [disableInputIcon]="!zipCode.valid || (apiRequestActive$ | async)!"
                    (enterKeyFunction)="zipCodeSearch($event)" (iconClickFunction)="zipCodeSearch($event)">
                </utc-input>
            </div>
        </div>
        <div class='hvac-create-account-lookup-info-column'>
            <p class='hvac-create-account-lookup-info-title'>
                {{'CREATE_ACCOUNT_LOOKUP.NEW_COMPANY_TITLE.' + (((brand$ | async) || '') | uppercase) | translate}}
            </p>
            <p class='hvac-create-account-lookup-info-body'>{{'CREATE_ACCOUNT_LOOKUP.NEW_COMPANY_BODY' | translate}}</p>
            <div class='hvac-create-account-lookup-enroll-button-container'>

                <a class='hvac-create-account-lookup-enroll-button'
                    [href]="('CREATE_ACCOUNT_LOOKUP.ENROLL_SURVEYS.' + (((brand$ | async) || '') | uppercase)) | translate"
                    rel="noopener noreferrer" target="_blank">
                    <utc-svg class="hvac-create-account-enroll-icon" src="assets/icons/pencil.svg"></utc-svg>
                    <span>{{'CREATE_ACCOUNT_LOOKUP.ENROLL_BUSINESS' | translate}}</span>
                </a>
            </div>
        </div>
        <div class='hvac-create-account-lookup-info-column'>
            <p class='hvac-create-account-lookup-info-title'>{{'CREATE_ACCOUNT_LOOKUP.ASSISTANCE_TITLE' | translate}}
            </p>
            <p class='hvac-create-account-lookup-info-body'
                [innerHTML]="'CREATE_ACCOUNT_LOOKUP.ASSISTANCE_BODY' | translate | safe: 'html'"></p>
        </div>
    </div>
</div>

<utc-business-locator></utc-business-locator>

<utc-tab-navigation-footer
    [continueButtonDisabled]="!( validForm$ | async)! || (apiRequestActive$ | async)!"
    [backButtonDisabled]="(apiRequestActive$ | async)!"
    [numberOfSteps]="(footerNumberOfSteps$ | async)!"
    [currentStep]="footerCurrentStep"
    [continueText]="footerActionButtonText$ | async"
    [backText]="'CREATE_ACCOUNT_LOOKUP.FOOTER_BACK_BUTTON' | translate"
    (continueButtonFunction)="submitAccountInfo()"
    (backButtonFunction)="backToInformationPage()">
</utc-tab-navigation-footer>
