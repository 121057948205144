import { Injectable } from '@angular/core';
import { Rule, RuleSet } from 'common/libs/angular2-query-builder/query-builder/query-builder.interfaces';

enum Operators {
    EQUALS = 'Equals',
    CONTAINS = 'Contains',
    BEGINS_WITH = 'Begins With',
    ENDS_WITH = 'Ends With'
}

@Injectable({ providedIn: 'root' })
export class DynamicNestedFilterService {
    public selectedFieldsList: string[] = [];

    public valueToString(value: string, specialCase?: string): string | number | null {
        switch (typeof value) {
            case 'string':
                if (specialCase) {
                    return `${value}`;
                }

                return `'${value}'`;
            case 'boolean':
                return value ? '1' : '0';
            case 'number':
                if (isFinite(value)) {
                    return value;
                }

                return null;

            default:
                return null;
        }
    }

    public isDefined(value: string | number | null | undefined) {
        return value !== 'undefined';
    }

    public isRule = (data: Rule | RuleSet): data is Rule => 'field' in data;

    public basicRulesetToQueryString(ruleset: RuleSet): string {
        return ruleset.rules?.map((rule: RuleSet | Rule) => {
            if (!this.isRule(rule)) {
                return `( ${this.basicRulesetToQueryString(rule)} )`;
            }

            const column = rule.field;
            let operator;
            let value;

            switch (rule.operator) {
                case Operators.EQUALS:
                    operator = '=';
                    value = this.valueToString(rule.value);
                    break;
                case Operators.CONTAINS:
                    value = this.valueToString(rule.value, 'specialCase');
                    operator = `LIKE('%${value}%')`;
                    break;
                case Operators.BEGINS_WITH:
                    value = this.valueToString(rule.value, 'specialCase');
                    operator = `LIKE('${value}%')`;
                    break;
                case Operators.ENDS_WITH:
                    value = this.valueToString(rule.value, 'specialCase');
                    operator = `LIKE('%${value}')`;
                    break;
                default:
                    operator = rule.operator;
                    value = this.valueToString(rule.value);
                    break;
            }

            if (this.isDefined(column) && this.isDefined(value) && this.isDefined(operator)) {
                if (rule.operator === Operators.CONTAINS || rule.operator === Operators.BEGINS_WITH || rule.operator === Operators.ENDS_WITH) {
                    return `${(`${column} ${operator}`).trim()}`;
                }

                return `${(`${column} ${operator} ${value}`).trim()}`;
            }

            return null;
        }).filter(this.isDefined).join(` ${ruleset.condition?.toUpperCase()} `);
    }

    public parseExpression(expr: string) {
        const tokens = expr.matchAll(/(\S\w*)(?:\s*(=|LIKE)\(?\s*('(?:[^']|'')*'|[-\d.]+)\)?)?/g);

        return (function recur() {
            const obj: RuleSet = {
                condition: 'and',
                rules: []
            };
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const { value: [all, field, operator, literal] } = tokens.next();
                const operatorValue = function operatorValue() {
                    if (operator === '=') {
                        return Operators.EQUALS;
                    }
                    else if (literal[1] !== '%') {
                        return Operators.BEGINS_WITH;
                    }
                    else if (literal.at(-2) !== '%') {
                        return Operators.ENDS_WITH;
                    }

                    return Operators.CONTAINS;
                };
                const valueVal = function valueVal() {
                    if (literal[0] !== '\'') {
                        return Number(literal);
                    }
                    else if (operator === 'LIKE') {
                        return literal.replaceAll('%', '').slice(1, -1).replaceAll('\'\'', '\'');
                    }

                    return literal.slice(1, -1).replaceAll('\'\'', '\'');
                };
                obj.rules.push(all === '('
                    ? recur()
                    : {
                        field,
                        operator: operatorValue(),
                        value: valueVal()
                    });
                const { done, value } = tokens.next();
                if (done || value[0] === ')') {
                    return obj;
                }
                obj.condition = value[0].toLowerCase();
            }
        }());
    }

    public getSelectedFields(ruleset: RuleSet): string[] {
        const fields = ruleset.rules.flatMap((ruleOrSet: (Rule | RuleSet)) => {
            if (this.isRule(ruleOrSet)) {
                return ruleOrSet.field;
            }

            return this.getSelectedFields(ruleOrSet);
        });

        return [...new Set(fields)];
    }
}
