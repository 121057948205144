import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { BrandingState } from 'common/store/branding/branding.state';
import { AccountState } from 'common/store/create-account.state';
import { BusinessLocator, HideBusinessLocator } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { createAccountPostalCodeValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { CompanyDetailInfo, ICPDealerRegistrationDetails } from 'private/app/models/accountInfo';
import { FetchSearchCompanyZipCode, SetCompanyCodeInfo } from 'private/app/store/create-account.actions';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'utc-business-locator',
    templateUrl: './businessLocator.component.html',
    styleUrls: ['./businessLocator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BusinessLocatorComponent {
    @Select(UiState.bussinessLocator) businessLocator$!: Observable<BusinessLocator>;
    @Select(AccountState.zipCodeQuery) zipCodeQuery$: Observable<string>;
    @Select(BrandingState.themeBrand) brand$!: Observable<string>;
    @Select(AccountState.zipCodeSearchStatusPending) zipCodeSearchStatusPending$: Observable<boolean>;
    @Select(AccountState.businessLocatorResults) businessLocatorResults$!: Observable<Array<CompanyDetailInfo>>;
    @Select(AccountState.icpDealerRegistrationDetails) icpDealerRegistrationDetails$!: Observable<ICPDealerRegistrationDetails>;

    @ViewChild('businessLocator') businessLocator: ElementRef;

    isZipCode$ = this.businessLocator$.pipe(map((businessLocator: BusinessLocator) => businessLocator.businessLocatorType === 'ZipCode'));


    formattedResults$ = combineLatest([this.businessLocatorResults$, this.icpDealerRegistrationDetails$]).pipe(
        map(([results, icpDealerRegistrationDetails]) => {
            if (!results || !results.length) {
                return [];
            }

            if (icpDealerRegistrationDetails.brand.length > 0) {
                return results
                    .filter((locatorData) => locatorData.brands.findIndex((brand) => brand.brandName.toUpperCase() === icpDealerRegistrationDetails.brand.toUpperCase()) !== -1)
                    .map((result) => ({
                        ...result,
                        name: result.companyName,
                        address: this.formatAddress(result),
                        brands: result.brands,
                        companyId: result.companyId
                    }));
            }

            return (results && results.length > 0)
                ? results.map((result) => ({
                    ...result,
                    name: result.companyName,
                    address: this.formatAddress(result),
                    brands: result.brands,
                    companyId: result.companyId
                }))
                : [];
        })
    );

    errorMessages$ = this.translate.get('CREATE_ACCOUNT_LOOKUP.ERROR_MESSAGES');

    businessLocatorInput = new UntypedFormControl('', createAccountPostalCodeValidator());

    isZipCode: boolean;

    zipCodeErrorMessage$ = combineLatest([this.businessLocatorInput.statusChanges, this.errorMessages$]).pipe(
        map(([_valid, message]) => getErrorMessage(this.businessLocatorInput.errors, message))
    );

    constructor(
        private readonly store: Store,
        private readonly translate: TranslateService
    ) { }

    ngOnInit() {
        this.zipCodeQuery$.subscribe((query) => {
            this.businessLocatorInput.setValue(query, { emitEvent: true });
        });
        this.isZipCode$.subscribe((res) => {
            this.isZipCode = res;
        });
    }

    public close() {
        this.store.dispatch(new HideBusinessLocator());
    }

    setCompanyId(result: CompanyDetailInfo) {
        this.store.dispatch(new SetCompanyCodeInfo(result.companyId.toString(), result));
        this.close();
    }

    public zipCodeSearch() {
        if (this.businessLocatorInput.valid) {
            this.store.dispatch(new FetchSearchCompanyZipCode(this.businessLocatorInput.value));
        }
    }

    public formatAddress(result: {address1: string, city: string, state: string, postalCode: string}) {
        const address = result.address1 ? `${result.address1}, ` : '';
        const city = result.city ? `${result.city}, ` : '';
        const state = result.state ? `${result.state} ` : '';
        const postalCode = result.postalCode ? result.postalCode : '';

        return address + city + state + postalCode;
    }
}
