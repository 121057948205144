import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductLiteratureComponent } from './product-literature.component';

const routes: Routes = [
    {
        path: '',
        component: ProductLiteratureComponent,
        data: {
            state: 'product-literature',
            title: 'HVACpartners: Product Literature',
            track: { pageName: 'product-literature' }
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductsLiteratureRoutingModule { }
