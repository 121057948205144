import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { AlertError } from 'private/app/models/alert.model';
import { ConnectedProduct } from 'private/app/models/connected-product.model';

export type RecentActivityBannerAlert = {
    dateTime: string;
    product: Partial<ConnectedProduct>;
    error: Partial<AlertError>;
}

@Component({
    selector: 'hvac-recent-activity-banner',
    templateUrl: './recent-activity-banner.component.html',
    styleUrls: ['./recent-activity-banner.component.scss']
})
export class RecentActivityBannerComponent {
    @Input() alert: RecentActivityBannerAlert;
    @Output() close = new EventEmitter();

    public readonly AppConstants = AppConstants;

    handleClose() {
        this.close.emit();
    }
}
