/* eslint-disable max-lines */
import * as d3 from 'd3';
import html2canvas from 'html2canvas';
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { differenceInHours, format } from 'date-fns';
import { BaseComponent } from 'common/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { SensorPropMinMax } from 'private/app/models/factory.model';
import { ChartData, SensorDataPoint } from 'private/app/models/engineering-insights-filters.model';
import { CharSymbol } from 'common/enums/char-symbol';
import { ENGINEERING_GRAPH_PERCENT_PROPS, ENGINEERING_GRAPH_TEMP_PROPS } from 'private/app/views/connected-portal/constants';

export interface MinMaxProps {
    [key: string]: {
        min?: number | null;
        max?: number | null;
    }
}

interface Margin {
    top: number;
    bottom: number;
    left: number;
    right: number;
}

enum ChartColors {
    Gray200 = '#E8E8E8',
    Blue500 = '#003366',
    Turquoise600 = '#00B5A2',
    White = '#FFF'
}

const PROP_TRANSLATE_BASE = 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW';

@Component({
    selector: 'hvac-factory-engineering-insights-chart',
    templateUrl: './factory-engineering-insights-chart.component.html',
    styleUrls: ['./factory-engineering-insights-chart.component.scss']
})
export class FactoryEngineeringInsightsChartComponent extends BaseComponent implements AfterViewInit, OnChanges {
    @ViewChild('chart', { static: false }) chart: ElementRef;
    @ViewChild('downloadLink', { static: false }) downloadLinkRef: ElementRef;

    @Input() minMaxData: SensorPropMinMax;
    @Input() chartData: ChartData;

    private chartContainer: ElementRef;

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.drawChart();
    }

    ngAfterViewInit(): void {
        this.chartContainer = this.chart;

        this.drawChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.chartData?.currentValue || changes?.minMaxData?.currentValue) {
            this.drawChart();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        d3.select('#hvac-engineering-chart-overlay')
            .on('mousemove', null)
            .on('mouseout', null);

        d3.selectAll('*').interrupt();
    }

    downloadChart() {
        if (!this.chartContainer) {
            return;
        }
        const svgContainer = this.chartContainer.nativeElement;

        html2canvas(svgContainer).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');

            const downloadLink = this.downloadLinkRef.nativeElement;

            downloadLink.href = dataURL;
            downloadLink.download = 'chart.png';
            downloadLink.click();
        });
    }

    private drawChart(): void {
        if (!this.chartContainer) {
            return;
        }

        const { sensorData: datasets, relayData } = this.chartData;

        const element = this.chartContainer.nativeElement;
        const containerWidth = element.getBoundingClientRect().width;

        // Clear existing chart
        d3.select(element).selectAll('*').remove();

        const { dates } = this.chartData;

        const datesHourDiff = differenceInHours(dates[dates.length - 1], dates[0]);

        const margin: Margin = {
            top: 15,
            right: 20,
            bottom: 15,
            left: 245
        };

        const unifiedXAxisHeight = 30;
        const width = containerWidth - margin.left - margin.right;
        const heightPerSwimlane = 80;

        const relayBarMargin = 8;
        const relayBarHeight = 16 + (relayBarMargin * 2);
        const relayGroupHeight = Object.keys(relayData).length * relayBarHeight;
        const swimlaneGroupHeight = (heightPerSwimlane + margin.top + margin.bottom) * datasets.length;
        const chartHeight = relayGroupHeight + swimlaneGroupHeight + unifiedXAxisHeight + margin.bottom;

        const relayYScale = d3.scaleBand()
            .domain(Object.keys(relayData))
            .range([0, relayGroupHeight]);

        const xScale = d3.scaleTime()
            .domain([d3.min([...dates]), d3.max([...dates])] as [Date, Date])
            .range([0, width]);

        const xScaleUnified = d3.scaleTime()
            .domain(d3.extent(this.chartData.dates) as [Date, Date])
            .range([0, width]);

        // Create the SVG container
        const svg = d3.select(element)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', chartHeight);

        // RELAY GROUP START
        const relayGroup = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        relayGroup.append('rect')
            .attr('width', width)
            .attr('height', relayGroupHeight)
            .attr('fill', 'none');

        relayGroup.selectAll('.hvac-engineering-chart-swimlane-label')
            .data(Object.keys(relayData))
            .enter()
            .append('text')
            .attr('class', 'hvac-engineering-chart-relay-label hvac-h6')
            .attr('x', -margin.left)
            .attr('y', (datum) => relayYScale(datum) as number + relayYScale.bandwidth() / 2)
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'middle')
            .text((datum) => this.translateService.instant(`${PROP_TRANSLATE_BASE}.RELAY_PROPS.${datum}.LABEL`));

        relayGroup.selectAll('.hvac-engineering-chart-relay-swimlane')
            .data(Object.entries(relayData))
            .enter()
            .append('g')
            .attr('class', 'hvac-engineering-chart-relay-swimlane')
            .attr('transform', (datum) => `translate(0,${relayYScale(datum[0])})`)
            // eslint-disable-next-line func-names
            .each(function([, intervals]) {
                d3.select(this).selectAll('.hvac-engineering-chart-relay-bar')
                    .data(intervals)
                    .enter()
                    .append('rect')
                    .attr('class', 'hvac-engineering-chart-relay-bar')
                    .attr('x', (datum) => xScale(new Date(datum.start)))
                    .attr('width', (datum) => xScale(new Date(datum.end)) - xScale(new Date(datum.start)))
                    .attr('y', relayBarMargin)
                    .attr('height', relayYScale.bandwidth() - (2 * relayBarMargin));
            });

        const relayGridLines = d3.axisBottom(xScaleUnified)
            .tickSizeInner(relayGroupHeight - margin.top)
            .tickSizeOuter(0)
            // No labels for these lines
            .tickFormat(() => '');

        relayGroup.append('g')
            .attr('class', 'hvac-engineering-chart-grid-lines')
            .attr('transform', `translate(0,${margin.top / 2})`)
            .call(relayGridLines)
            .call((grp) => grp.select('.domain').remove())
            .selectAll('line')
            .attr('stroke', ChartColors.Gray200)
            .attr('stroke-width', '2px');
        // RELAY GROUP END

        // Loop over each swimlane data
        datasets.forEach((dataset, index) => {
            const { data: dataPoint } = dataset;
            const swimlaneColor = this.getSwimlaneColor(index);

            const min = this.minMaxData[dataset.name]?.min || d3.min(dataPoint, (datum) => datum.value) as number;
            const max = this.minMaxData[dataset.name]?.max || d3.max(dataPoint, (datum) => datum.value) as number;
            const yExtent = [min, max] as [number, number];
            const yScale = d3.scaleLinear().domain(yExtent).range([heightPerSwimlane, 0]);

            // Create a group for each swimlane
            const swimlaneY = index * (heightPerSwimlane + margin.top + margin.bottom) + margin.top + (relayGroupHeight + margin.top);
            const group = svg.append('g')
                .attr('transform', `translate(${margin.left}, ${swimlaneY})`);

            const displayName = this.getParameterPropName(dataset.name);

            // Add the yScale property names
            group.append('text')
                // Adjust this position as needed
                .attr('x', -margin.left)
                // Vertically centered
                .attr('y', heightPerSwimlane / 2)
                .attr('dy', '.35em')
                .attr('text-anchor', 'start')
                .attr('class', 'hvac-engineering-chart-params-axis-y-prop-label')
                .text(displayName);

            // Draw the line for the swimlane
            const line = d3.line<SensorDataPoint>()
                // Define where the line is broken
                .defined((datum) => datum.value !== null)
                .x((datum) => xScale((datum.date)))
                .y((datum) => yScale(datum.value as number));

            const rectPadding = 0;

            // Add the swimlane background color
            group.append('rect')
                .attr('class', 'hvac-engineering-chart-swimlane')
                .attr('x', 0)
                .attr('y', -rectPadding)
                .attr('width', width)
                .attr('height', heightPerSwimlane + (rectPadding * 2))
                .attr('fill', `${swimlaneColor}20`);

            // Add the swimlane vertical grid lines
            const gridLines = d3.axisBottom(xScaleUnified)
                .tickSizeInner(heightPerSwimlane)
                .tickSizeOuter(0)
                // No labels for these lines
                .tickFormat(() => '');

            group.append('g')
                .attr('class', 'hvac-engineering-chart-grid-lines')
                .attr('transform', `translate(0,${0})`)
                .call(gridLines)
                .call((grp) => grp.select('.domain').remove())
                .selectAll('line')
                .attr('stroke', ChartColors.White)
                .attr('stroke-width', '2px');

            // Create the clip path to hide values outside of the filter range
            group.append('clipPath')
                .attr('id', `clip-${dataset.name}`)
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', width)
                .attr('height', heightPerSwimlane);

            // Attach the clip path to each swimlane at the correct position
            group.append('path')
                .datum(dataset.data)
                .attr('fill', 'none')
                .attr('stroke', swimlaneColor)
                .attr('stroke-width', 1.5)
                .attr('d', line)
                // Clips any values that are outside of the explicity min / max values
                .attr('clip-path', `url(#clip-${dataset.name})`);

            // Add the y-axis
            group.append('g')
                .attr('class', 'hvac-engineering-chart-params-axis-y')
                .style('transform', 'translate(-8px, 0)')
                .call(d3.axisLeft(yScale)
                    .tickFormat((datum) => {
                        const unit = this.getPropertyUnit(dataset.name);

                        return `${datum} ${unit}`;
                    })
                    // Explicit min and max
                    .tickValues([min, max])
                    // No tick marks
                    .tickSize(0))
                // Remove the vertical line
                .call((grp) => grp.select('.domain').remove());
        });

        // Add the unified x-axis at the bottom
        const xScaleGroup = svg.append('g')
            .attr('transform', `translate(${margin.left}, ${datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight + margin.bottom})`)
            .attr('class', 'hvac-engineering-chart-axis-x');

        xScaleGroup.call(d3.axisBottom(xScaleUnified)
            .tickFormat((date, index) => {
                if (index % 4 === 0) {
                    if (datesHourDiff < 24) {
                        return format(date as Date, 'h:mm a');
                    }

                    return format(date as Date, 'MM/dd');
                }

                return '';
            }))
            // Remove the horizontal line
            .call((grp) => grp.select('.domain').remove());

        // Add an overlay for capturing hover events
        const overlay = svg.append('rect')
            .attr('id', 'hvac-engineering-chart-overlay')
            .attr('width', width)
            .attr('height', datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight)
            .attr('transform', `translate(${margin.left}, 0)`)
            .style('fill', 'none')
            .style('pointer-events', 'all');

        // Prepare the vertical line
        const verticalLine = svg.append('g')
            .append('line')
            .attr('y1', margin.top)
            .attr('y2', datasets.length * (heightPerSwimlane + margin.top + margin.bottom) + relayGroupHeight)
            .attr('class', 'hvac-engineering-chart-cursor')
            .style('pointer-events', 'none')
            .style('display', 'none');

        // Prepare the tooltip (but don't populate it yet)
        const tooltip = this.createTooltip(this.chartContainer);

        // Event listener for hover
        overlay.on('mousemove', (event) => {
            const [x] = d3.pointer(event);
            const dateTime = xScaleUnified.invert(x);
            const bisectDate = d3.bisector((datum: SensorDataPoint) => datum.date).left;

            // Show and position the vertical line
            verticalLine
                .attr('x1', x + margin.left)
                .attr('x2', x + margin.left)
                .style('display', null);

            // Prepare tooltip data
            const tooltipData = datasets.map((dataset) => {
                const closestDataPoint = bisectDate(dataset.data, dateTime);
                const closestValue = dataset.data[closestDataPoint]?.value;
                const value = this.getTooltipValue(closestValue, this.minMaxData[dataset.name]?.min, this.minMaxData[dataset.name]?.max);

                return {
                    property: dataset.name,
                    value
                };
            });

            // Populate the tooltip
            const tooltipTimeHtml = `
                <div class="hvac-engineering-chart-tooltip-time">${format(dateTime, 'MM/dd hh:mm:ss a')}</div>
            `;

            const tooltipPropHtml = tooltipData.map((datum) => `
                <div class="hvac-engineering-chart-tooltip-prop">
                    <span class="hvac-engineering-chart-tooltip-prop-label">${this.getParameterPropName(datum.property)}</span>:
                    <span class="hvac-engineering-chart-tooltip-prop-value">${datum.value}</span>
                </div>`).join('');

            tooltip.html(`${tooltipTimeHtml} ${tooltipPropHtml}`)
                .style('left', `${event.layerX + 10}px`)
                .style('top', `${event.layerY + 10}px`)
                .style('display', 'block');
        });

        // Hide the vertical line when not hovering
        overlay.on('mouseout', () => {
            verticalLine.style('display', 'none');
            tooltip.style('display', 'none');
        });
    }

    private getPropertyUnit(propName: string) {
        const tempProps = ENGINEERING_GRAPH_TEMP_PROPS;
        const percentProps = ENGINEERING_GRAPH_PERCENT_PROPS;

        if (tempProps.includes(propName)) {
            return CharSymbol.Fahrenheit;
        }
        else if (percentProps.includes(propName)) {
            return CharSymbol.Percent;
        }

        return '';
    }

    private getTooltipValue(value: number | null, min?: number | null, max?: number | null) {
        if (
            typeof value === 'number'
            && typeof min === 'number'
            && typeof max === 'number'
            && (value < min || value > max)
        ) {
            return CharSymbol.EmDash;
        }

        return Number.isFinite(value) ? value : CharSymbol.EmDash;
    }

    private getParameterPropName(propKey: string) {
        return this.translateService.instant(`${PROP_TRANSLATE_BASE}.PARAMETER_PROPS.${propKey}.LABEL`);
    }

    private createTooltip(elRef: ElementRef) {
        return d3.select(elRef.nativeElement)
            .append('div')
            .attr('class', 'hvac-engineering-chart-tooltip')
            .style('position', 'absolute')
            .style('pointer-events', 'none')
            .style('display', 'none');
    }

    private getSwimlaneColor(index: number): string {
        if (index % 2 === 0) {
            return ChartColors.Turquoise600;
        }

        return ChartColors.Blue500;
    }
}
