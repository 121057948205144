import { AlertSeverity } from './alert.model';
import { ConnectedCustomer } from './connected-customer.model';
import { ConnectedProduct } from './connected-product.model';
import { ConnectedProperty } from './connected-property.model';
import { DefaultCollectionResponse } from './default-collection-response.model';

export enum FaultSeverity {
    CRITICAL = 'Critical',
    MODERATE = 'Moderate',
    INFORMATIONAL = 'Informational'
}

export interface Fault {
    description: string;
    type: string;
    severity: FaultSeverity;
    troubleshootUri: string;
}

export interface SystemFault {
    id: string;
    dateTime: string;
    error: Fault;
    property?: ConnectedProperty;
    customer?: ConnectedCustomer;
    product: ConnectedProduct;
    source: string;
    occurrenceCount: number;
}

export interface ConnectedPortalSystemFault {
    id: string;
    datetime: string;
    faultCode: string;
    faultMessage: string;
    faultSeverity: AlertSeverity;
    source: string;
}

export interface SystemFaultsResponse extends DefaultCollectionResponse {
    data: SystemFault[];
}
