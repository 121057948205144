import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeapService } from 'common/services/heap.service';
import { ProductType } from 'private/app/models/connected-product.model';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';

@Component({
    selector: 'hvac-confirm-config-edit-modal',
    templateUrl: './confirm-config-edit-modal.component.html',
    styleUrls: ['./confirm-config-edit-modal.component.scss']
})
export class ConfirmConfigEditModalComponent {
    @Input() isModalVisible: boolean;
    @Input() productType: ProductType;
    @Output() onCloseModal = new EventEmitter();
    @Output() onConfirmButtonClick = new EventEmitter();

    constructor(private heapService: HeapService) {}


    handleCloseModal() {
        this.onCloseModal.emit();
    }

    handleConfirmAction() {
        switch (this.productType) {
            case ProductType.WallControl:
                this.heapService.track(eventNames.handleWallControlConfigChangeConfirmClick);
                break;
            case ProductType.IndoorUnit:
                this.heapService.track(eventNames.handleIndoorUnitConfigChangeConfirmClick);
                break;
            case ProductType.OutdoorUnit:
                this.heapService.track(eventNames.handleOutdoorUnitConfigChangeConfirmClick);
                break;
            default:
                break;
        }

        this.onConfirmButtonClick.emit();
    }
}
