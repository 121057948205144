<div class="hvac-opportunity-usage-report">
    <hvac-report-chart-container
        [chartTitle]="'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.OPPORTUNITY_REPORT.OPPORTUNITY_REPORT_USAGE' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.YEAR_TO_DATE_ANALYSIS' | translate"
        [hasData]="!!(chartData)"
        [isLoading]="isLoading"
        yearSelectId="hvac-opportunity-usage-report-year-select"
        [yearSelectFormControl]="yearSelectFormControl"
        [yearSelectOptions]="yearSelectOptions"
        [isReportButtonDisabled]="!data"
        (onRunReport)="handleRunReport()"
        >
        <canvas *ngIf="chartData" baseChart class="hvac-chart" [data]="chartData" [options]="chartOptions" type="doughnut">
        </canvas>
    </hvac-report-chart-container>
</div>
