<utc-full-page-loader *ngIf='isLoading' [branded]="true"></utc-full-page-loader>
<main class="hvac-main-content-container">
    <div class="content-wrap" [ngClass]="{ 'hvac-frame-content-loading': isLoading }">
        <iframe
            *ngIf="frameReset"
            #contentFrame
            [name]="contentFrameTitle"
            [src]="contentSource | safe:'resourceUrl'"
            class='iframe'
            frameBorder='0' scrolling="auto" allowtransparency="true"
            (load)="frameLoaded()"></iframe>
    </div>
</main>
