<div class="hvac-cp-wall-control-system-diag-container">
    <div>
        <div>
            <ng-container *ngIf="(equipmentList$ | async) as equipmentList" >
                <hvac-system-diagnostic-equipment
                    *ngIf="activationState === ApiResponseState.Success"
                    [equipmentsList]="equipmentList"
                    [dealerId]="dealerId"
                    [serialNo]="serialNo"
                    (onReset)="resetTestData($event)"
                    (onEquipmentSelect)="setSelectedEquipment($event)"
                    (onWebSocketDataError)="handleSocketDataError($event)"></hvac-system-diagnostic-equipment>
            </ng-container>

            <div class="hvac-equipment-list-error" *ngIf="activationState === ApiResponseState.Error">
                <hvac-toast [name]="equipmentListToastOutlet"></hvac-toast>
                <button class="hvac-equipment-list-retry-btn" hvacButton="primary" size="small" (click)="activateWallControl$.next(true)">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ACTIONS.RETRY_CONNECTION' | translate }}</button>
            </div>

            <div class="hvac-equipment-list-loader" *ngIf="activationState === ApiResponseState.Loading">
                <utc-loading-spinner variant="overlay">
                    <ul class="hvac-loading-message-container" *ngIf="activationState === ApiResponseState.Loading">
                        <li class="hvac-loading-message hvac-label-sm" *ngFor="let message of loadingMessages">{{message}}</li>
                    </ul>
                </utc-loading-spinner>
            </div>
        </div>

        <div class="hvac-h4 hvac-cp-diag-param-title" *ngIf="selectedEquipment?.isTestable">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.PARAMS_SECTION.HEADING' | translate}}</div>

        <ng-container *ngIf="(testUnitType$ | async) as testUnitType">
            <ng-container *ngIf="[DiagnosticTestUnitType.IDU, DiagnosticTestUnitType.ODU_AND_IDU].includes(testUnitType) && systemDiagnosticsParams">
                <div class="hvac-h5 hvac-cp-unit-title">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.PARAMS_SECTION.INDOOR_UNIT_HEADING' | translate}}</div>
                <div class="hvac-cp-diagnostic-params-container">
                    <div class="hvac-cp-diagnostic-param" *ngFor="let item of systemDiagnosticsParams | keyvalue | systemDiagnosticsParamUnitType : 'IDU' | orderBy: sortIDU">
                        <div class="hvac-cp-diagnostic-param-value">
                            <div class="hvac-cp-diagnostic-param-current-value">
                                <div class="hvac-cp-diagnostic-param-notification-icon" *ngIf="item?.value?.showAlertNotification">
                                    <utc-svg src="assets/icons/diagnostic-notification.svg"></utc-svg>
                                </div>
                                {{item?.value?.displayValue}}
                            </div>
                            <div class="hvac-cp-diagnostic-param-value-range-indicator" *ngIf="item?.value?.currentValue !== undefined">
                                <span class="hvac-cp-diagnostic-param-value-range-indicator-circle"
                                    [ngClass]="{'hvac-cp-diagnostic-param-value-range-indicator-circle-out-of-range': item?.value?.showAlertNotification}"
                                ></span> {{(item?.value?.showAlertNotification ? 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.NOTIFICATIONS.OUTSIDE_NORMAL_RANGE' : 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.NOTIFICATIONS.NORMAL_RANGE') | translate}}
                            </div>
                        </div>
                        
                        <div class="hvac-cp-diagnostic-param-outer-wrap">
                            <div class="hvac-cp-diagnostic-param-inner-content hvac-cp-diagnostic-param-out-of-range">
                                <system-diagnostic-parameter
                                    [minValue]="item?.value?.outOfRangeLowerMin"
                                    [maxValue]="item?.value?.outOfRangeLowerMax"
                                    [currentValue]="item?.value?.currentValue"
                                    [leftOutOfRange]="true"
                                ></system-diagnostic-parameter>
                            </div>
                            <div class="hvac-cp-diagnostic-param-inner-content">
                                <system-diagnostic-parameter
                                    [minValue]="item?.value?.min"
                                    [maxValue]="item?.value?.max"
                                    [currentValue]="item?.value?.currentValue"
                                ></system-diagnostic-parameter>
                                <div class="hvac-cp-diagnostic-param-range">
                                    <div>{{item?.value?.min}}</div>
                                    <div>{{item?.value?.max}}</div>
                                </div>
                            </div>
                            <div class="hvac-cp-diagnostic-param-inner-content hvac-cp-diagnostic-param-out-of-range">
                                <system-diagnostic-parameter
                                    [minValue]="item?.value?.outOfRangeUpperMin"
                                    [maxValue]="item?.value?.outOfRangeUpperMax"
                                    [currentValue]="item?.value?.currentValue"
                                    [rightOutOfRange]="true"
                                ></system-diagnostic-parameter>
                            </div>
                        </div>
                        <div class="hvac-cp-diagnostic-param-name">{{('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT_PARAMS.' + item?.key | translate)}}</div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="[DiagnosticTestUnitType.ODU, DiagnosticTestUnitType.ODU_AND_IDU].includes(testUnitType) && systemDiagnosticsParams">
                <div class="hvac-h5 hvac-cp-unit-title hvac-cp-odu-unit-title">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.PARAMS_SECTION.OUTDOOR_UNIT_HEADING' | translate}}</div>
                <div class="hvac-cp-odu-outside-display-param" *ngFor="let oduOutDisplayItem of systemDiagnosticsParams | keyvalue | systemDiagnosticsParamUnitType : 'ODU': {filterparam: 'oduCategory', filterValue: 'airTemperature'}">
                    <div class="hvac-cp-odu-outside-display-param-name">{{('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT_PARAMS.' + oduOutDisplayItem?.key | translate)}}</div>
                    <div class="hvac-cp-odu-outside-display-param-current-value">{{oduOutDisplayItem?.value?.displayValue}}</div>
                </div>
                <div class="hvac-cp-odu-params-outer-wrap">
                    <div class="hvac-cp-diagnostic-params-container hvac-cp-odu-params-container">
                        <div class="hvac-cp-diag-param-category" >{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ODU_CATEGORY.SUCTION' | translate}}</div>
                        <div class="hvac-cp-diagnostic-param hvac-cp-odu-param" *ngFor="let item of systemDiagnosticsParams | keyvalue | systemDiagnosticsParamUnitType : 'ODU': {filterparam: 'oduCategory', filterValue: 'suction'} | orderBy: sortODU">
                            <div class="hvac-cp-diagnostic-param-name">{{('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT_PARAMS.' + item?.key | translate)}}</div>
                            <div class="hvac-cp-odu-param-current-value">{{item?.value?.displayValue}}</div>
                        </div>
                    </div>
                    <div class="hvac-cp-diagnostic-params-container hvac-cp-odu-params-container">
                        <div class="hvac-cp-diag-param-category" >{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ODU_CATEGORY.LIQUID' | translate}}</div>
                        <div class="hvac-cp-odu-content-wrap" *ngFor="let item of systemDiagnosticsParams | keyvalue | systemDiagnosticsParamUnitType : 'ODU': {filterparam: 'oduCategory', filterValue: 'liquid'} | orderBy: sortODU">
                            <div class="hvac-cp-diagnostic-param hvac-cp-odu-param" >
                                <div class="hvac-cp-diagnostic-param-name">{{('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT_PARAMS.' + item?.key | translate)}}</div>
                                <div class="hvac-cp-odu-param-current-value">{{item?.value?.displayValue}}</div>
                            </div>
                            <div class="hvac-cp-diagnostic-param-info" *ngIf="item?.key === 'subCooling' && item?.value?.currentValue !== item?.value?.min ">
                                <utc-svg src="assets/icons/banner-info.svg"></utc-svg>
                                <span class="hvac-cp-diagnostic-param-info-message">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.NOTIFICATIONS.SUB_COOLING_OPTIMAL_TIME' | translate}}</span>
                            </div> 
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="hvac-cp-diagnostic-test-actions">
            <div class="hvac-cp-diagnostic-test-actions-legend">
                <div class="hvac-cp-diagnostic-test-actions-legend-notification-icon" *ngIf="showAlertNotification">
                    <utc-svg src="assets/icons/diagnostic-notification.svg"></utc-svg>
                </div>
                <div class="hvac-p-sm hvac-fw-rg" *ngIf="showAlertNotification">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.NOTIFICATIONS.EXCEED_RANGE' | translate}}</div>
            </div>
            <div class="hvac-cp-diagnostic-test-actions-wrap">
                <button hvacButton="default" [disabled]="true" class="hidden">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ACTIONS.EXPORT_REPORT' | translate }}</button>
                <button hvacButton="primary" [disabled]="false" (click)="exitTest()">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ACTIONS.EXIT_TESTING' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="hvac-cp-toast-wrapper">
        <hvac-toast [name]="diagnosticsToastOutlet"></hvac-toast>
    </div>
</div>

