/* tslint:disable:max-line-length */
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { BrandingState } from 'common/store/branding/branding.state';
import { AccountState } from 'common/store/create-account.state';
import { BusinessLocator, ShowBusinessLocator } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { createAccountCompanyCodeValidator, createAccountPostalCodeValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { AssociatedDistributorResult, CompanyDetailInfo, CreateAccountStatus, InvalidCompanyDetailInfo } from 'private/app/models/accountInfo';
import { FetchCompanyCode, FetchCreateAccount, FetchSearchCompanyZipCode, LockUserAccountForm, ResetAccountUserInformation, ResetBusinessLocator, ResetCompanyCodeInfo, ResetCompanyCodeQuery, ResetEmailVerification, ResetUniqueEmail, ResetUniqueUserName, UnlockUserAccountForm } from 'private/app/store/create-account.actions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { CreateAccountService } from '../../services/create-account.service';
/* tslint:enable:max-line-length */

const SUCCESS_ICON = 'assets/icons/requirement-check.svg';
const SEARCH_ICON = 'assets/icons/search.svg';

export interface AddressInfo{
    address?:string;
    state?:string;
    city?:string;
    zip?:string;
}
@Component({
    selector: 'utc-create-account-lookup',
    templateUrl: './createAccountLookup.component.html',
    styleUrls: ['./createAccountLookup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateAccountLookupComponent implements OnDestroy {
    @Select(AccountState.createAccountStatus) createAccountStatus$: Observable<CreateAccountStatus>;
    @Select(AccountState.companyCodeInfo) companyCodeInfo$!: Observable<CompanyDetailInfo>;
    @Select(AccountState.companyCodeErrors) companyCodeErrors$!: Observable<InvalidCompanyDetailInfo>;
    @Select(AccountState.validAccountInfo) validAccountInfo$!: Observable<boolean>;
    @Select(AccountState.companyCodeQuery) companyCodeQuery$: Observable<string>;
    @Select(AccountState.zipCodeQuery) zipCodeQuery$: Observable<string>;
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(BrandingState.themeBrand) brand$!: Observable<string>;
    @Select(UiState.bussinessLocator) businessLocator$: Observable<BusinessLocator>;

    public footerNumberOfSteps = 3;
    public footerCurrentStep = 3;
    ngOnDestroy$ = new Subject();
    companyCode$: BehaviorSubject<string> = new BehaviorSubject('');
    associatedDistributorLookup$ = new BehaviorSubject<boolean>(false);
    public footerNumberOfSteps$ = new BehaviorSubject<number>(3);
    public showSelectPrimaryDistributor = environment.features.selectPrimaryDistributor;
    companyCodeControl = new UntypedFormControl('', createAccountCompanyCodeValidator());
    zipCode = new UntypedFormControl('', createAccountPostalCodeValidator());
    errorMessages$ = this.translate.get('CREATE_ACCOUNT_LOOKUP.ERROR_MESSAGES');

    companyCodeName$ = this.companyCodeInfo$.pipe(map((companyCode: CompanyDetailInfo) => (companyCode && companyCode.companyName ? companyCode.companyName : null)));

    companyCodeAddress$ = this.companyCodeInfo$.pipe(map((companyCode: CompanyDetailInfo) => (companyCode
        ? {
            address: companyCode.address1,
            city: companyCode.city ? companyCode.city : '',
            state: companyCode.state ? companyCode.state : '',
            zipCode: companyCode.postalCode ? companyCode.postalCode : ''
        }
        : null)));

    companyCodeIcon$ = this.companyCodeInfo$.pipe(map((companyCode: CompanyDetailInfo) => (companyCode && companyCode.companyName ? SUCCESS_ICON : SEARCH_ICON)));

    codeStatus$ = this.companyCodeInfo$.pipe(map((companyCode: CompanyDetailInfo) => companyCode && companyCode.companyName));

    companyCodeInfoMarkup$ = combineLatest([this.companyCodeName$, this.companyCodeAddress$]).pipe(map(([name, address]) => (name ? this.formatInfoMessage(name, address) : null)));

    companyCodeErrorMessage$ = combineLatest([this.companyCodeControl.statusChanges, this.errorMessages$]).pipe(
        map(([_valid, message]) => getErrorMessage(this.companyCodeControl.errors, message))
    );

    zipCodeErrorMessage$ = combineLatest([this.zipCode.statusChanges, this.errorMessages$]).pipe(
        map(([_valid, message]) => getErrorMessage(this.zipCode.errors, message))
    );

    validForm$ = combineLatest([this.validAccountInfo$, this.companyCodeInfo$]).pipe(map(([validAccountInfo, validCompanyCodeInfo]) => validAccountInfo && (validCompanyCodeInfo && validCompanyCodeInfo.companyName)));

    footerActionButtonText$ = combineLatest([this.translate.get('CREATE_ACCOUNT_EMAIL.FOOTER_CONTINUE_BUTTON'), this.associatedDistributorLookup$, this.translate.get('CREATE_ACCOUNT_EMAIL.BUTTON_CREATE')]).pipe(
        map(([btnContinue, associatedDistributorLookup, btnCreate]) => {
            if (associatedDistributorLookup && this.showSelectPrimaryDistributor) {
                return btnContinue;
            }

            return btnCreate;
        })
    );

    constructor(private translate: TranslateService, private readonly store: Store, private readonly accountService: CreateAccountService) {
        this.companyCodeErrors$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(((companyCodeErrors) => {
            if (companyCodeErrors.isInvalid) {
                return this.companyCodeControl.setErrors({ INVALID_COMPANY_CODE: true });
            }
            else if (companyCodeErrors.serverDown) {
                return this.companyCodeControl.setErrors({ SERVER_DOWN: true });
            }
        }));

        this.companyCodeQuery$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((query: string) => {
            if (query !== '' && query !== this.companyCodeControl.value) {
                this.companyCodeControl.setValue('');
            }
        });

        this.zipCodeQuery$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((query: string) => {
            if (query !== '') {
                this.zipCode.setValue(query, { emitEvent: true });
            }
        });

        this.companyCodeControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged()
        ).subscribe(() => this.companyCodeKeystroke());

        this.businessLocator$.subscribe((businessLocator: BusinessLocator) => {
            if (businessLocator.businessLocatorType === 'ZipCode' && !businessLocator.businessLocatorVisible) {
                this.companyCodeControl.setErrors(null);
            }
        });
    }

    ngOnInit() {
        this.companyCodeInfo$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((companyInfo) => {
            this.companyCode$.next(companyInfo.companyId);
            if (companyInfo && companyInfo.companyName) {
                if (companyInfo.companyTypeCode === 'ZDCR') {
                    this.store.dispatch(new LockUserAccountForm());
                    this.accountService.checkAssociatedDistributorForDealer({
                        criteria: {
                            companyId: companyInfo.companyId,
                            companyTypeCode: 'DDT',
                            getAssociatedBrands: true,
                            postalCode: ''
                        },
                        determineTotalCount: true,
                        pageParams: {
                            page: 0,
                            pageSize: 20
                        }
                    }).subscribe((results: AssociatedDistributorResult) => {
                        this.store.dispatch(new UnlockUserAccountForm());
                        this.associatedDistributorLookup$.next(results.data.length > 0);
                        this.footerNumberOfSteps$.next(4);
                    });
                }
                this.associatedDistributorLookup$.next(false);
                this.footerNumberOfSteps$.next(3);
                this.companyCodeControl.setErrors(null, { emitEvent: false });
            }
        });
    }

    formatInfoMessage(name: string, address: AddressInfo | null) {
        const nameMarkup = `<p class='info-message'>${name}</p>`;
        let streetAddressMarkup = '';
        let secondLineAddressMarkup = '';
        if (address) {
            const city = address.city ? `${address.city}, ` : '';
            const state = address.state ? `${address.state} ` : '';
            const zip = address.zip ? address.zip : '';
            streetAddressMarkup = address.address ? `<p class='info-message'>${address.address}</p>` : '';
            secondLineAddressMarkup = city || state || zip ? `<p class='info-message'>${city} ${state} ${zip} </p>` : '';
        }

        return `<span> ${nameMarkup + streetAddressMarkup + secondLineAddressMarkup} </span>`;
    }

    backToInformationPage() {
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());
        this.store.dispatch(new ResetCompanyCodeQuery());
        this.store.dispatch(new ResetCompanyCodeInfo());
        this.store.dispatch(new ResetBusinessLocator());

        return this.store.dispatch(new Navigate(['/create-account-information']));
    }

    companyCodeKeystroke() {
        return this.store.dispatch(new ResetCompanyCodeInfo());
    }

    checkCompanyCode(code: string) {
        return this.store.dispatch(new FetchCompanyCode(code));
    }

    zipCodeSearch(zipCode: string) {
        if (this.zipCode.valid) {
            this.store.dispatch(new ShowBusinessLocator('ZipCode'));
            this.store.dispatch(new FetchSearchCompanyZipCode(zipCode));
        }
    }

    submitAccountInfo() {
        if (this.showSelectPrimaryDistributor) {
            if (this.associatedDistributorLookup$.value === true) {
                this.store.dispatch(new Navigate(['/associated-distributor-lookup'], {}, { queryParamsHandling: 'preserve' }));

                return;
            }
        }

        this.store.dispatch(new FetchCreateAccount());
    }

    handleHeaderCancel() {
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetAccountUserInformation());
        this.store.dispatch(new ResetEmailVerification());
        this.store.dispatch(new ResetCompanyCodeQuery());
        this.store.dispatch(new ResetCompanyCodeInfo());
        this.store.dispatch(new ResetBusinessLocator());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    handleHeaderLogo() {
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());
        this.store.dispatch(new ResetBusinessLocator());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
