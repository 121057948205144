import { Injectable, OnDestroy } from '@angular/core';
import { Toast } from 'common/services/toast.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class CustomerControlContextService implements OnDestroy {
    public isRemoveControlModalVisible$ = new BehaviorSubject<boolean>(false);
    public confirmRemoveControl$ = new Subject<boolean>();
    public onRemoveControlConfirmed$ = new Subject<true>();
    public isRemoveConnectedControlModalVisible$ = new BehaviorSubject<boolean>(false);
    public confirmRemoveConnectedControl$ = new Subject<boolean>();
    public onRemoveConnectedControlConfirmed$ = new Subject<true>();
    private _isControlAdded: boolean = false;
    private _toastContent: Toast | null;
    private ngOnDestroy$ = new Subject();


    constructor() {
        this.confirmRemoveControl$.pipe(
            tap(() => {
                this.onRemoveControlConfirmed$.next(true);
                this.isRemoveControlModalVisible$.next(false);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();

        this.confirmRemoveConnectedControl$.pipe(
            tap(() => {
                this.onRemoveConnectedControlConfirmed$.next(true);
                this.isRemoveConnectedControlModalVisible$.next(false);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    get isControlAdded(): boolean {
        return this._isControlAdded;
    }

    get toastContent() {
        return this._toastContent;
    }

    set isControlAdded(value: boolean) {
        this._isControlAdded = value;
    }

    set toastContent(value: Toast | null) {
        this._toastContent = value;
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
