import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ErrorService } from 'common/content/services/error.service';
import { CharSymbol } from 'common/enums/char-symbol';
import { ToastService } from 'common/services/toast.service';
import { ConnectedPortalUIConfig } from 'private/app/models/connected-portal-ui-config.model';
import { AlertsData, EcobeeProductDetailsData, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { IduStatus } from 'private/app/models/idu.model';
import { WallControlStatus } from 'private/app/models/wall-control.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { IduService } from 'private/app/services/connected-portal/idu.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { UIConfigApiService } from 'private/app/services/connected-portal/ui-config-api.service';
import { formatOutOfRangeEcobeeProps } from 'private/app/services/connected-portal/utils';
import { MISSING_PRODUCT_DATA, PRODUCT_ALERTS_PAGE_SIZE } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../product-detail-context.service';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { AlertStatusFilter } from 'private/app/models/alert.model';

interface StatusDataProps {
    statusProps: [string, string][],
    airflowVerificationProps: [string, string][] | null
}

@Component({
    selector: 'hvac-ecobee-idu-detail',
    templateUrl: './ecobee-idu-detail.component.html',
    styleUrls: ['./ecobee-idu-detail.component.scss']
})
export class EcobeeIduDetailComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;

    public readonly CharSymbol = CharSymbol;
    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public productData$: Observable<EcobeeProductDetailsData>;
    public iduStatusData$: Observable<IduStatus>;
    public iduStatusProps$: Observable<StatusDataProps>;
    public wallControlStatus$: Observable<WallControlStatus>;
    public alertsData$: Observable<AlertsData>;
    public alertsCurrentPage$ = new BehaviorSubject<number>(1);
    public alertsCurrentFilter$ = new BehaviorSubject<AlertStatusFilter>(AlertStatusFilter.ACTIVE);
    public isLoadingAlerts$ = new BehaviorSubject(false);
    public isOutOfRangeWarningDisplayed$: Observable<boolean>;
    public toggleAccordion$ = new Subject<keyof typeof this.accordions$.value>();
    public accordions$ = new BehaviorSubject({
        alerts: true,
        status: false
    });

    constructor(
        public contextService: ProductDetailContextService,
        private iduService: IduService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private alertService: AlertsService,
        private uiConfigService: UIConfigApiService,
        private toastService: ToastService,
        private errorService: ErrorService
    ) {
        super();
    }

    ngOnInit() {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlStatus$ = this.productService
            .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false));

        this.iduStatusData$ = this.iduService.queryIduStatus(this.serialNo, this.dealerId, this.systemType)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.iduStatusProps$ = combineLatest([
            this.iduStatusData$,
            this.uiConfigService.getConnectedPortalUIConfig()
        ])
            .pipe(
                map(([data, uiConfig]): StatusDataProps => {
                    const { latestAirflowVerification, ...otherStatusProps } = data;
                    const outOfRangeFormatted = this.getEcobeeOutOfRangeFormatted(otherStatusProps, uiConfig);
                    const statusProps = this.productService.formatData(Object.entries(outOfRangeFormatted));
                    const airflowVerificationProps = latestAirflowVerification
                        ? this.productService.formatData(Object.entries(latestAirflowVerification))
                        : null;

                    return {
                        statusProps,
                        airflowVerificationProps
                    };
                }),
                shareReplay()
            );

        this.isOutOfRangeWarningDisplayed$ = combineLatest([
            this.uiConfigService.getConnectedPortalUIConfig(),
            this.iduStatusData$
        ])
            .pipe(
                map(([uiConfig, iduStatusData]) => {
                    const outOfRangeFormatted = this.getEcobeeOutOfRangeFormatted(iduStatusData, uiConfig);

                    return Object.values(outOfRangeFormatted).some((value) => value === CharSymbol.DoubleDash);
                }),
                takeUntil(this.ngOnDestroy$)
            );

        this.productData$ = combineLatest([
            this.dataSharingPermissions$,
            this.iduStatusData$,
            this.isWallControlConnected$,
            this.system$
        ]).pipe(
            map(([dataSharingPermissions, iduStatusData, isWallControlConnected, system]) => {
                const productData = system?.products.find((product) => product.type === ProductType.IndoorUnit);

                const isTestEquipmentEnabled = this.contextService.getIsTestEquipmentEnabled(
                    isWallControlConnected,
                    dataSharingPermissions.editConfig,
                    productData?.isPlcEquipped === 'true',
                    productData?.systemType
                );

                if (productData) {
                    return {
                        isWallControlConnected,
                        isRuntimeReportEnabled: false,
                        isTestEquipmentEnabled,
                        modelName: productData.name,
                        productInfo: {
                            ...productData,
                            stage: iduStatusData.stage,
                            dateTime: iduStatusData.dateTime
                        }
                    };
                }

                throw new Error(MISSING_PRODUCT_DATA);
            }),
            catchError((err) => {
                this.errorService.errorStatus$.next(404);

                throw err;
            })
        );

        this.alertsData$ = combineLatest([this.alertsCurrentFilter$, this.alertsCurrentPage$])
            .pipe(
                // Avoids making multiple api calls when props change close together
                debounceTime(100),
                tap(() => {
                    this.isLoadingAlerts$.next(true);
                }),
                switchMap(([currentFilterType, currentPage]) => {
                    const queryParams: DefaultQueryParams = {
                        pagination: {
                            currentPage,
                            pageSize: PRODUCT_ALERTS_PAGE_SIZE
                        },
                        sort: {
                            field: 'dateTime',
                            order: 'desc'
                        }
                    };
                    if (currentFilterType !== AlertStatusFilter.ALL) {
                        queryParams.filter = [
                            {
                                field: 'error.isActive',
                                value: currentFilterType === AlertStatusFilter.ACTIVE ? 'true' : 'false'
                            }
                        ];
                    }

                    return this.alertService.queryAlertsBySerialNo(this.serialNo, 'indoor unit', this.systemType, queryParams)
                        .pipe(
                            map(({ data, totalPages }): AlertsData => ({
                                items: data,
                                totalPages,
                                currentPage
                            })),
                            catchError(() => {
                                this.isLoadingAlerts$.next(false);

                                return EMPTY;
                            })
                        );
                }),
                tap(() => {
                    this.isLoadingAlerts$.next(false);
                })
            );


        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);
                }
            });

        this.contextService.closeDiagnosticsTest$.pipe(
            tap((toastMessage) => {
                if (toastMessage) {
                    this.toastService.add(toastMessage);
                    window.scrollTo(0, 0);
                }

                this.contextService.isDiagnosticsModalVisible$.next(false);
            }),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();

        super.ngOnDestroy();
    }

    public handleAlertFilterChange(alertStatusFilter: AlertStatusFilter) {
        this.alertsCurrentPage$.next(1);
        this.alertsCurrentFilter$.next(alertStatusFilter);
    }

    public handleTestEquipmentClick() {
        this.contextService.isDiagnosticsModalVisible$.next(true);
    }

    private getEcobeeOutOfRangeFormatted(props: Partial<IduStatus>, uiConfig: ConnectedPortalUIConfig) {
        const outOfRangeFormatted = formatOutOfRangeEcobeeProps(props, uiConfig.propertyValueRanges.ecobee.idu.status, CharSymbol.DoubleDash);
        const { returnAirTemp, supplyAirTemp } = outOfRangeFormatted;

        // DeltaT will be considered out of range if either returnAirTemp or supplyAirTemp are out of range
        if ([returnAirTemp, supplyAirTemp].includes(CharSymbol.DoubleDash)) {
            return {
                ...outOfRangeFormatted,
                deltaT: CharSymbol.DoubleDash
            };
        }

        return outOfRangeFormatted;
    }
}
