import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity } from 'common/models';

@Component({
    selector: 'hvac-highlights',
    templateUrl: './highlights.component.html',
    styleUrls: ['./highlights.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HighlightsComponent {
    @Input() highlights: BaseContentEntity[];
    @Input() serviceBulletins: BaseContentEntity;
}
