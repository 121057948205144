<div class="hvac-models-table-container">
    <div class="hvac-manage-dealer-third-party-header">
        <p class="hvac-content-header-sub-title">
            <span>{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.VALIDATE_SOURCES' | translate }}</span>
        </p>
    </div>

    <div class="hvac-model-table-wrap" *ngIf="dealerLocatorArray.length > 0">
        <form [formGroup]="dynamicForm">
            <div class="hvac-row hvac-third-part-link"
                *ngFor="let ratingGroup of thirdPartyDynamicFormData; let i = index">
                <p class="hvac-col-md-12 hvac-third-party-source">
                    <span>{{ ratingGroup.data.sourceName }}</span>
                </p>
                <div class="hvac-col-md-12" [formGroup]="$any(ratingGroup.controller)">
                    <div class="col-xs-12 col-lg-8">
                        <div class="hvac-row">
                            <utc-input class="hvac-col-lg-3 hvac-third-party-input" type="text"
                                [inputControl]="ratingGroup.controller.get('id')"
                                label="{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.SOURCE_ID' | translate }}">
                            </utc-input>
                            <utc-input class="hvac-col-lg-6 hvac-third-party-input"
                                [inputControl]="ratingGroup.controller.get('url')"
                                label="{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.URL' | translate }}"
                                [disabledInput]="true">
                            </utc-input>

                            <div class="hvac-col-lg-3 hvac-row hvac-third-party-share">
                                <a [class.disabled]="!ratingGroup.controller.get('id')?.value"
                                    class="hvac-third-party-button" href="{{ratingGroup.data.sourceURL}}"
                                    hvacButton="default" target="_blank" rel="noopener noreferrer">
                                    <span>{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.VALIDATE' | translate }}</span>
                                </a>
                                <button class="hvac-third-party-button" [class.disabled]="!ratingGroup.controller.get('id')?.value" (click)="removeLink(ratingGroup.data)"
                                    hvacButton="text">
                                    <span>{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.REMOVE' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>
<div class="hvac-third-party-footer-container">
    <button (click)="saveAction()" [hvacButton]="'primary'" [disabled]="!(isRatingAvailable$ | async)">
        <span>{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.SUBMIT_RATING' | translate }}</span>
    </button>
</div>
<utc-loading-spinner *ngIf="loading"></utc-loading-spinner>
