
import { UserEntity } from 'private/app/models/user.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { CompanyAssociationsResult, ContactsSearchCriteria, DistributorSearchCriteria } from '../models/company.model';
import { Company, ContactAssociationsResult } from 'private/app/models/account-admin-search';
import { CompanyRelationshipsResult } from '../models/account-admin-company.model';

@Injectable({ providedIn: 'root' })
export class IdmService {
    public userInfo$ = new BehaviorSubject<UserEntity | null>(null);

    constructor(
        private readonly apiOptions: ApiOptionsService,
        private readonly httpClient: HttpClient
    ) { }

    public createAssociatedContactsSearchBody(company:Company, pagination: number | null) {
        const body: ContactsSearchCriteria = {
            criteria: { companyId: company?.hvacCompanyId },
            pageParams: {
                page: (pagination || 1) - 1,
                pageSize: 5
            },
            determineTotalCount: true
        };

        return body;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCompany(cid: string): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchMap((options: ApiOptions) => this.httpClient.get<any>(`${environment.api.companies}/${cid}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUser(id: string): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchMap((options: ApiOptions) => this.httpClient.get<any>(`${environment.api.user}/${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getAssociations(body: DistributorSearchCriteria): Observable<CompanyAssociationsResult> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchMap((options: ApiOptions) => this.httpClient.post<any>(`${environment.api.accountAdminDistributorAssociations}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public postAccountAdminContactSearchByCompany(body: any): Observable<ContactAssociationsResult> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            switchMap((options: ApiOptions) => this.httpClient.post<any>(`${environment.api.accountAdminContactsByCompany}`, body, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }


    public getCompanyRelationships(hvacCompanyID: string): Observable<CompanyRelationshipsResult> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<CompanyRelationshipsResult>(`${environment.api.accountAdminGetRelationships}=${hvacCompanyID}`, { ...options })),
            catchError((err:HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
