
<ng-template #faultCode let-fault="fault">
    <div class="hvac-connected-portal-faults-list-code-container">
        <div class="hvac-connected-portal-faults-severity-indicator"
            [ngClass]="{'hvac-faults-critical': fault.faultSeverity === ConnectedSystemFaultSeverity.CRITICAL,
                        'hvac-faults-moderate': fault.faultSeverity === ConnectedSystemFaultSeverity.MODERATE,
                        'hvac-faults-info': fault.faultSeverity === ConnectedSystemFaultSeverity.INFORMATIONAL}">
        </div>
        <div class="hvac-p-sm hvac-connected-portal-faults-list-error-code">{{fault.faultCode}}</div>
    </div>
</ng-template>

<ng-template #description let-fault="fault">
    <div class="hvac-p-sm hvac-connected-portal-faults-list-desc">{{fault.faultMessage}}</div>
</ng-template>

<ng-template #source let-fault="fault">
    <div class="hvac-p-sm hvac-connected-portal-faults-list-src">{{fault.source}}</div>
</ng-template>

<ng-template #datetime let-fault="fault">
    <div class="hvac-p-sm hvac-connected-portal-faults-list-date">{{fault.datetime | dateTimeTz:'date'}}</div>
</ng-template>

<div class="hvac-connected-portal-faults-list-container">
    <ul class="hvac-connected-portal-faults-list" >
        <ng-container *ngIf="faults">
            <li class="hvac-connected-portal-faults-list-item" *ngFor="let fault of faults; trackBy:trackByAlert">
                <div class="hvac-row hvac-top-xs hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-code">
                    <div class="hvac-subhead hvac-col-xs-4">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.CODE' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-connected-portal-faults-list-field-value">
                        <ng-container [ngTemplateOutlet]="faultCode" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-description">
                    <div class="hvac-subhead hvac-col-xs-4">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.DESCRIPTION' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-connected-portal-faults-list-field-value">
                        <ng-container [ngTemplateOutlet]="description" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-source">
                    <div class="hvac-subhead hvac-col-xs-4">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.SOURCE' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-connected-portal-faults-list-field-value">
                        <ng-container [ngTemplateOutlet]="source" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-datetime">
                    <div class="hvac-subhead hvac-col-xs-4">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.DATE' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-connected-portal-faults-list-field-value">
                        <ng-container [ngTemplateOutlet]="datetime" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </div>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="!isLoading &&faults?.length === 0">
            <div class="hvac-connected-portal-faults-list-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.EMPTY_MESSAGE' | translate }}</div>
        </ng-container>

        <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
    </ul>

    <table class="hvac-connected-portal-faults-list">
        <thead>
            <tr class="hvac-connected-portal-faults-list-table-header">
                <th class="hvac-subhead">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.CODE' | translate }}</th>
                <th class="hvac-subhead">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.DESCRIPTION' | translate }}</th>
                <th class="hvac-subhead">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.SOURCE' | translate }}</th>
                <th class="hvac-subhead">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.FAULT_HEADINGS.DATE' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="faults">
                <tr class="hvac-connected-portal-faults-list-row" *ngFor="let fault of faults; trackBy:trackByAlert">
                    <td class="hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-code">
                        <ng-container [ngTemplateOutlet]="faultCode" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </td>
                    <td class="hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-description">
                        <ng-container [ngTemplateOutlet]="description" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </td>
                    <td class="hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-source">
                        <ng-container [ngTemplateOutlet]="source" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </td>
                    <td class="hvac-connected-portal-faults-list-field-group hvac-connected-portal-faults-list-datetime">
                        <ng-container [ngTemplateOutlet]="datetime" [ngTemplateOutletContext]="{fault: fault}"></ng-container>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="!isLoading && faults?.length === 0">
                <tr>
                    <td colspan="6" [align]="'center'">
                        <div class="hvac-connected-portal-faults-list-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.EMPTY_MESSAGE' | translate }}</div>
                    </td>
                </tr>
            </ng-container>

            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
        </tbody>
    </table>

    <div class="hvac-connected-portal-faults-list-footer">
        <div class="hvac-connected-portal-faults-list-footer-pagination">
            <hvac-pagination
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
    </div>
</div>

