/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
    accountInfoErrorCodes, companyLookupErrorCodes, CreateAccountAccountInfoError,
    CreateAccountCompanyInfoError,
    CreateAccountDuplicateEmailError, CreateAccountDuplicateUsernameError, CreateAccountError,
    CreateAccountOktaPasswordError, CreateAccountServerError,
    duplicateEmailErrorCodes, oktaErrorCode, serverErrorCodes, TranslatableError
} from 'common/models/create-account-errors';
import { OktaService } from 'common/services/okta/okta.service';
import { BrandingState } from 'common/store/branding/branding.state';
import { CreateAccountService } from '../../private/app/services/create-account.service';
import {
    AccountInfoPostData, AccountInfoUserInfo, AccountStateModel, BrandObject,
    CompanyDetailInfo, CreateAccountStatus, EmailVerificationCodePostData, EmailVerificationStatus,
    ICPDealerRegistrationDetails,
    InvalidCompanyDetailInfo, RequestPasswordResetPutData, ResetPasswordStatus, ResponsePasswordReset,
    SubmitVerificationCodePostData, UniqueAccountInfoStatus, ZipCodeResult, ZipCodeSearchResponse
} from '../../private/app/models/accountInfo';
import {
    FetchCompanyCode, FetchCreateAccount,
    FetchSearchCompanyZipCode, FetchUniqueEmail, FetchUniqueUserName,
    ICPDealerRegistrationCode,
    LockUserAccountForm,
    ReceiveCompanyCode,
    ReceiveCompanyCodeErrors, ReceiveCreateAccount,
    ReceiveEmailVerificationCode,
    ReceiveSearchCompanyCode,
    ReceiveSearchCompanyZipCode, ReceiveUniqueEmail, ReceiveUniqueUserName,
    RequestEmailVerificationCode, RequestPasswordReset, ResetAccountUserInformation,
    ResetBusinessLocator, ResetCompanyCodeInfo, ResetCompanyCodeQuery,
    ResetEmailVerification,
    ResetPasswordState,
    ResetUniqueEmail, ResetUniqueUserName, ResetValidAccountInfo, SetAccountUserInformation,
    SetCompanyCodeInfo, SetPrimaryDistributorId, SubmitEmailVerificationCode, UnlockUserAccountForm
} from '../../private/app/store/create-account.actions';
import { ShowBusinessLocator } from './ui/ui.actions';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
/* tslint:enable:max-line-length */

@State<AccountStateModel>({
    name: 'createAccount',
    defaults: {
        accountInfo: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            emailAddress: '',
            username: '',
            password: ''
        },
        validAccountInfo: false,
        createAccountStatus: CreateAccountStatus.NotCreated,
        companyCode: {
            companyCodeQuery: '',
            companyCodeInfo: {} as CompanyDetailInfo,
            companyCodeErrors: {} as InvalidCompanyDetailInfo
        },
        businessLocator: {
            zipCodeQuery: '',
            zipCodeSearchStatusPending: false,
            results: null
        },
        userName: {
            uniqueUserNameStatus: UniqueAccountInfoStatus.NotChecked,
            userNameQuery: ''
        },
        email: {
            uniqueEmailStatus: UniqueAccountInfoStatus.NotChecked,
            emailQuery: ''
        },
        emailVerification: { emailVerificationStatus: EmailVerificationStatus.NotVerified },
        apiRequestActive: false,
        passwordResetStatus: ResetPasswordStatus.Pending,
        icpDealerRegistration: {
            brand: '',
            dealerCode: ''
        }
    }
})

@Injectable({ providedIn: 'root' })
export class AccountState {
    constructor(
        public toastService: ToastService,
        private readonly accountService: CreateAccountService,
        private store: Store,
        private readonly okta: OktaService,
        private translate: TranslateService
    ) { }

    @Selector()
    static uniqueUserNameStatus({ userName }: AccountStateModel): UniqueAccountInfoStatus {
        return userName.uniqueUserNameStatus;
    }

    @Selector()
    static uniqueEmailStatus({ email }: AccountStateModel): UniqueAccountInfoStatus {
        return email.uniqueEmailStatus;
    }

    @Selector()
    static emailVerificationCodeRequestStatus({ emailVerification }: AccountStateModel): EmailVerificationStatus {
        return emailVerification.emailVerificationStatus;
    }

    @Selector()
    static createAccountStatus({ createAccountStatus }: AccountStateModel): CreateAccountStatus {
        return createAccountStatus;
    }

    @Selector()
    static accountInfo({ accountInfo }: AccountStateModel): AccountInfoUserInfo {
        return accountInfo;
    }

    @Selector()
    static companyCodeInfo({ companyCode }: AccountStateModel): CompanyDetailInfo {
        return companyCode.companyCodeInfo;
    }

    @Selector()
    static companyCodeErrors({ companyCode }: AccountStateModel): InvalidCompanyDetailInfo {
        return companyCode.companyCodeErrors;
    }

    @Selector()
    static companyCodeQuery({ companyCode }: AccountStateModel): string {
        return companyCode.companyCodeQuery;
    }

    @Selector()
    static zipCodeQuery({ businessLocator }: AccountStateModel): string {
        return businessLocator.zipCodeQuery;
    }

    @Selector()
    static businessLocatorResults({ businessLocator }: AccountStateModel): Array<CompanyDetailInfo> | null {
        return businessLocator.results ? businessLocator.results : null;
    }

    @Selector()
    static validAccountInfo({ validAccountInfo }: AccountStateModel): boolean {
        return validAccountInfo;
    }

    @Selector()
    static zipCodeSearchStatusPending({ businessLocator }: AccountStateModel): boolean {
        return businessLocator.zipCodeSearchStatusPending;
    }

    @Selector()
    static apiRequestActive({ apiRequestActive }: AccountStateModel): boolean {
        return apiRequestActive;
    }

    @Selector()
    static passwordResetStatus({ passwordResetStatus }: AccountStateModel): ResetPasswordStatus {
        return passwordResetStatus;
    }

    @Selector()
    static icpDealerRegistrationDetails({ icpDealerRegistration }: AccountStateModel): ICPDealerRegistrationDetails {
        return icpDealerRegistration;
    }

    @Action(ICPDealerRegistrationCode)
    icpDealerRegistrationCode(ctx: StateContext<AccountStateModel>, action: ICPDealerRegistrationCode) {
        const ICPCompanyCode = action.code;
        ctx.patchState({ icpDealerRegistration: ICPCompanyCode });
    }

    @Action(FetchUniqueUserName)
    fetchUniqueUserName(ctx: StateContext<AccountStateModel>, action: FetchUniqueUserName) {
        return this.getUniqueUserName(ctx, action.userNameQuery);
    }

    @Action(FetchUniqueEmail)
    fetchUniqueEmail(ctx: StateContext<AccountStateModel>, action: FetchUniqueEmail) {
        return this.getUniqueEmail(ctx, action.emailQuery);
    }

    @Action(SubmitEmailVerificationCode)
    submitEmailVerificationCode(ctx: StateContext<AccountStateModel>, action: SubmitVerificationCodePostData) {
        return this.submitVerificationCode(ctx, action.email, action.userId, action.code);
    }

    @Action(RequestEmailVerificationCode)
    requestVerificationCode(ctx: StateContext<AccountStateModel>, action: EmailVerificationCodePostData) {
        return this.requestEmailVerificationCode(ctx, action.email, action.userId);
    }

    @Action(RequestPasswordReset)
    requestPasswordReset(ctx: StateContext<AccountStateModel>, action: RequestPasswordResetPutData) {
        ctx.patchState({ passwordResetStatus: ResetPasswordStatus.Pending });

        return this.accountService.requestPasswordReset(action).subscribe((_result: ResponsePasswordReset) => ctx.patchState({ passwordResetStatus: ResetPasswordStatus.Success }), (err) => {
            ctx.patchState({ passwordResetStatus: ResetPasswordStatus.Error });

            this.toastService.add({
                content: new CreateAccountOktaPasswordError(err.error.details[0]).content,
                id: new CreateAccountOktaPasswordError(err.error.details[0]).name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    @Action(ResetPasswordState)
    resetPasswordState(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({ passwordResetStatus: ResetPasswordStatus.Pending });
    }

    @Action(FetchCompanyCode)
    fetchCompanyCode(ctx: StateContext<AccountStateModel>, action: FetchCompanyCode) {
        ctx.patchState({
            companyCode: {
                ...ctx.getState().companyCode,
                companyCodeQuery: action.companyCodeQuery,
                companyCodeInfo: {} as CompanyDetailInfo
            }
        });

        return this.checkCompanyCode(ctx, action.companyCodeQuery);
    }

    @Action(FetchSearchCompanyZipCode)
    fetchSearchCompanyZipCode(ctx: StateContext<AccountStateModel>, action: FetchSearchCompanyZipCode) {
        ctx.patchState({
            businessLocator: {
                zipCodeQuery: action.zipCode,
                zipCodeSearchStatusPending: true,
                results: null
            }
        });

        return this.searchCompanyZipCode(ctx, action.zipCode);
    }

    @Action(FetchCreateAccount)
    fetchCreateAccount(ctx: StateContext<AccountStateModel>) {
        const state = ctx.getState();
        const postBody = {
            hvacCompanyId: state.icpDealerRegistration.dealerCode ? state.icpDealerRegistration.dealerCode : state.companyCode.companyCodeInfo.companyId,
            firstName: state.accountInfo.firstName,
            username: state.accountInfo.username,
            password: state.accountInfo.password,
            lastName: state.accountInfo.lastName,
            phoneNumber: state.accountInfo.phoneNumber,
            emailAddress: state.accountInfo.emailAddress
        } as AccountInfoPostData;
        if (state.primaryDistributorId) {
            postBody.primaryDistributorId = state.primaryDistributorId;
        }
        ctx.patchState({ apiRequestActive: true });

        return this.createAccount(ctx, postBody);
    }

    @Action(ReceiveUniqueUserName)
    receiveUniqueUserName(ctx: StateContext<AccountStateModel>, action: ReceiveUniqueUserName) {
        const { uniqueUserName } = action;
        ctx.patchState({
            userName: {
                uniqueUserNameStatus: uniqueUserName,
                userNameQuery: ''
            }
        });
    }

    @Action(ReceiveUniqueEmail)
    receiveUniqueEmail(ctx: StateContext<AccountStateModel>, action: ReceiveUniqueEmail) {
        const { uniqueEmail } = action;
        ctx.patchState({
            email: {
                uniqueEmailStatus: uniqueEmail,
                emailQuery: ''
            }
        });
    }

    @Action(ReceiveEmailVerificationCode)
    receiveVerificationCode(ctx: StateContext<AccountStateModel>, action: ReceiveEmailVerificationCode) {
        const verificationCodeStatus = action.codeStatus;
        ctx.patchState({ emailVerification: { emailVerificationStatus: verificationCodeStatus } });
    }

    @Action(ReceiveCompanyCode)
    receiveCompanyCode(ctx: StateContext<AccountStateModel>, action: ReceiveCompanyCode) {
        const companies = this.applyBrandFilter(action.companyCode);

        if (companies.length === 0) {
            return ctx.dispatch(new ReceiveCompanyCodeErrors({
                isInvalid: true,
                serverDown: false
            }));
        }

        if (companies.length === 1) {
            return this.store.dispatch(new SetCompanyCodeInfo(ctx.getState().companyCode.companyCodeQuery, companies[0]));
        }

        this.store.dispatch(new ReceiveSearchCompanyCode(companies));

        return this.store.dispatch(new ShowBusinessLocator('CompanyCode'));
    }

    @Action(ReceiveCompanyCodeErrors)
    receiveCompanyCodeError(ctx: StateContext<AccountStateModel>, action: ReceiveCompanyCodeErrors) {
        ctx.patchState({
            companyCode: {
                ...ctx.getState().companyCode,
                companyCodeErrors: { ...action.companyCodeErrors }
            }
        });
    }

    @Action(SetCompanyCodeInfo)
    setCompanyCode(ctx: StateContext<AccountStateModel>, action: SetCompanyCodeInfo) {
        ctx.patchState({
            companyCode: {
                ...ctx.getState().companyCode,
                companyCodeInfo: action.companyCodeInfo
            }
        });
    }

    @Action(SetPrimaryDistributorId)
    setPrimaryDistributorId(ctx: StateContext<AccountStateModel>, action: SetPrimaryDistributorId) {
        ctx.patchState({ primaryDistributorId: action.primaryDistributorId });
    }

    @Action(ReceiveSearchCompanyCode)
    receiveSearchCompanyCode(ctx: StateContext<AccountStateModel>, action: ReceiveSearchCompanyCode) {
        ctx.patchState({
            businessLocator: {
                ...ctx.getState().businessLocator,
                zipCodeSearchStatusPending: false,
                results: action.searchResults
            }
        });
    }

    @Action(ReceiveSearchCompanyZipCode)
    receiveSearchCompanyZipCode(ctx: StateContext<AccountStateModel>, action: ReceiveSearchCompanyZipCode) {
        const results = action.searchResults.CompanyDetails ? this.applyBrandFilter(action.searchResults.CompanyDetails) : [];

        ctx.patchState({
            businessLocator: {
                ...ctx.getState().businessLocator,
                zipCodeSearchStatusPending: false,
                results: this.formatZipCodeResults(results)
            }
        });
    }

    @Action(ReceiveCreateAccount)
    receiveCreateAccount(ctx: StateContext<AccountStateModel>, action: ReceiveCreateAccount) {
        const { createAccountStatus } = action;
        if (createAccountStatus === CreateAccountStatus.Success) {
            const state = ctx.getState();
            this.okta.login(state.accountInfo.username, state.accountInfo.password)
                .finally(() => {
                    this.store.dispatch(new ResetAccountUserInformation());
                    this.store.dispatch(new ResetBusinessLocator());
                    this.store.dispatch(new ResetCompanyCodeInfo());
                    this.store.dispatch(new ResetEmailVerification());
                    this.store.dispatch(new ResetCompanyCodeQuery());
                    this.store.dispatch(new ResetUniqueUserName());
                });
        }
    }

    @Action(ResetUniqueUserName)
    resetUniqueUserName(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            validAccountInfo: false,
            userName: {
                uniqueUserNameStatus: UniqueAccountInfoStatus.NotChecked,
                userNameQuery: ''
            }
        });
    }

    @Action(ResetUniqueEmail)
    resetUniqueEmail(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            validAccountInfo: false,
            email: {
                uniqueEmailStatus: UniqueAccountInfoStatus.NotChecked,
                emailQuery: ''
            }
        });
    }

    @Action(ResetValidAccountInfo)
    resetValidAccountInfo(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({ validAccountInfo: false });
    }

    @Action(ResetAccountUserInformation)
    resetAccountUserInformation(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            ...ctx.getState(),
            validAccountInfo: false,
            userName: {
                uniqueUserNameStatus: UniqueAccountInfoStatus.NotChecked,
                userNameQuery: ''
            },
            accountInfo: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                emailAddress: '',
                username: '',
                password: ''
            }
        });
    }

    @Action(ResetCompanyCodeInfo)
    resetCompanyCodeInfo(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            companyCode: {
                ...ctx.getState().companyCode,
                companyCodeInfo: {} as CompanyDetailInfo
            }
        });
    }

    @Action(ResetBusinessLocator)
    resetBusinessLocator(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            ...ctx.getState(),
            businessLocator: {
                zipCodeQuery: '',
                zipCodeSearchStatusPending: false,
                results: null
            }
        });
    }

    @Action(ResetCompanyCodeQuery)
    resetCompanyCodeQuery(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({
            companyCode: {
                ...ctx.getState().companyCode,
                companyCodeQuery: ''
            }
        });
    }

    @Action(SetAccountUserInformation)
    setAccountUserInformation(ctx: StateContext<AccountStateModel>, action: SetAccountUserInformation) {
        const { accountInfo } = action;
        ctx.patchState({
            ...ctx.getState(),
            validAccountInfo: true,
            accountInfo
        });
    }

    @Action(LockUserAccountForm)
    lockUserAccountForm(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({ apiRequestActive: true });
    }

    @Action(UnlockUserAccountForm)
    unlockUserAccountForm(ctx: StateContext<AccountStateModel>) {
        ctx.patchState({ apiRequestActive: false });
    }

    private getUniqueUserName(ctx: StateContext<AccountStateModel>, userName: string) {
        ctx.patchState({
            userName:
                {
                    uniqueUserNameStatus: UniqueAccountInfoStatus.Pending,
                    userNameQuery: userName
                }
        });

        return this.accountService.checkUserName(userName).subscribe((results: UniqueAccountInfoStatus) => ctx.dispatch(new ReceiveUniqueUserName(results as UniqueAccountInfoStatus)), (err: TranslatableError) => {
            if (err.name === 'userNameConflict') {
                this.store.dispatch(new ReceiveUniqueUserName(UniqueAccountInfoStatus.NotUnique));


                this.toastService.add({
                    content: this.translate.instant('ERROR_CONTENT.CHECK_USERNAME.CONFLICT'),
                    theme: 'error',
                    autoClose: true,
                    id: 'create-account-error'
                });
            }
        });
    }

    private getUniqueEmail(ctx: StateContext<AccountStateModel>, email: string) {
        ctx.patchState({
            email:
                {
                    uniqueEmailStatus: UniqueAccountInfoStatus.Pending,
                    emailQuery: email
                }
        });

        return this.accountService.checkEmail(email).subscribe((results: UniqueAccountInfoStatus) => ctx.dispatch(new ReceiveUniqueEmail(results as UniqueAccountInfoStatus)), (err: TranslatableError) => {
            if (err.name === 'checkEmailConflict') {
                this.store.dispatch(new ReceiveUniqueEmail(UniqueAccountInfoStatus.NotUnique));
            }

            this.toastService.add({
                content: this.translate.instant(err.content),
                theme: 'error',
                autoClose: true,
                id: 'create-account-error'
            });
        });
    }

    private requestEmailVerificationCode(ctx: StateContext<AccountStateModel>, email: string, userId: string) {
        ctx.patchState({
            emailVerification:
                { emailVerificationStatus: EmailVerificationStatus.Requested }
        });

        return this.accountService.requestEmailVerificationCode({
            email,
            userId
        }).subscribe((results: EmailVerificationStatus) => {
            ctx.patchState({
                emailVerification:
                    { emailVerificationStatus: EmailVerificationStatus.Pending }
            });

            return ctx.dispatch(new ReceiveEmailVerificationCode(results as EmailVerificationStatus));
        }, (err: TranslatableError) => {
            ctx.patchState({
                emailVerification:
                    { emailVerificationStatus: EmailVerificationStatus.Error }
            });

            this.toastService.add({
                content: err.content,
                id: err.name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });

            return ctx.dispatch(new ReceiveEmailVerificationCode('Error' as EmailVerificationStatus));
        });
    }

    private submitVerificationCode(ctx: StateContext<AccountStateModel>, email: string, userId: string, code: string) {
        ctx.patchState({
            emailVerification:
                { emailVerificationStatus: EmailVerificationStatus.Pending }
        });

        return this.accountService.submitVerificationCode({
            email,
            userId,
            code
        }).subscribe((results: EmailVerificationStatus) => ctx.dispatch(new ReceiveEmailVerificationCode(results as EmailVerificationStatus)), (err: TranslatableError) => {
            if (err.name === 'EmailVerificationForbiddenError') {
                this.store.dispatch(new ReceiveEmailVerificationCode(EmailVerificationStatus.Forbidden));
            }
            else {
                this.store.dispatch(new ReceiveEmailVerificationCode(EmailVerificationStatus.Error));
            }

            this.toastService.add({
                content: err.content,
                id: err.name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    private createAccount(ctx: StateContext<AccountStateModel>, accountInfoPostData: AccountInfoPostData) {
        return this.accountService.createAccount(accountInfoPostData).subscribe((results: CreateAccountStatus) => ctx.dispatch(new ReceiveCreateAccount(results)), (err: CreateAccountError) => {
            ctx.patchState({ apiRequestActive: false });

            return this.handleCreateAccountError(err);
        });
    }

    private checkCompanyCode(ctx: StateContext<AccountStateModel>, companyCode: string) {
        ctx.patchState({
            companyCode:
            {
                companyCodeInfo: {} as CompanyDetailInfo,
                companyCodeQuery: companyCode,
                companyCodeErrors: {} as InvalidCompanyDetailInfo
            }
        });

        return this.accountService.checkCompanyCode(companyCode).subscribe(
            (companyCodeInfo: Array<CompanyDetailInfo>) => ctx.dispatch(new ReceiveCompanyCode(companyCodeInfo)),
            (companyCodeError: HttpErrorResponse) => {
                ctx.dispatch(new ReceiveCompanyCodeErrors({
                    serverDown: companyCodeError.status === 500 || companyCodeError.status === 0,
                    isInvalid: companyCodeError.status >= 400 && companyCodeError.status < 500
                }));
            }
        );
    }

    private formatZipCodeResults(results: ZipCodeResult[]): Array<CompanyDetailInfo> {
        return results && results.length
            ? results.map((zipCodeResult: ZipCodeResult) => {
                const formattedZipCodeResult = {} as CompanyDetailInfo;
                formattedZipCodeResult.companyId = zipCodeResult.HVACCompanyID.toString();
                formattedZipCodeResult.companyName = zipCodeResult.companyName;
                formattedZipCodeResult.address1 = zipCodeResult.companyAddress;
                formattedZipCodeResult.city = zipCodeResult.city;
                formattedZipCodeResult.state = zipCodeResult.state;
                formattedZipCodeResult.country = zipCodeResult.country;
                formattedZipCodeResult.brands = zipCodeResult.brands;
                formattedZipCodeResult.companyTypeCode = zipCodeResult.companyTypeCode;
                formattedZipCodeResult.companyTypeName = zipCodeResult.companyTypeName;
                formattedZipCodeResult.postalCode = zipCodeResult.postalCode;
                formattedZipCodeResult.brandfamily = zipCodeResult.brandfamily;
                formattedZipCodeResult.isActive = zipCodeResult.isActive ? 1 : 0;

                return formattedZipCodeResult;
            })
            : [];
    }

    private searchCompanyZipCode(ctx: StateContext<AccountStateModel>, zipCode: string) {
        return this.accountService.searchCompanyZipCode(zipCode).subscribe((results: ZipCodeSearchResponse) => {
            ctx.dispatch(new ReceiveSearchCompanyZipCode(results));
        });
    }

    private applyBrandFilter<T extends { brands: BrandObject[] }>(companies: T[]): T[] {
        const brand = this.store.selectSnapshot(BrandingState.themeBrand).toLowerCase();
        if (brand === 'default') {
            return companies;
        }

        return companies.filter((company) => company.brands.some((coBrand) => coBrand.brandName.toLowerCase() === brand));
    }

    private handleCreateAccountError(err: CreateAccountError) {
        const containsServerErrors = err.error.errorCodes.some((errorCode) => serverErrorCodes.indexOf(errorCode) !== -1);
        const containsDuplicateEmailErrors = err.error.errorCodes.some((errorCode) => duplicateEmailErrorCodes.indexOf(errorCode) !== -1);
        const containsOktaErrors = err.error.errorCodes.some((errorCode) => oktaErrorCode.indexOf(errorCode) !== -1);
        const containsAccountInfoErrors = err.error.errorCodes.some((errorCode) => accountInfoErrorCodes.indexOf(errorCode) !== -1);
        const containsCompanyInfoErrors = err.error.errorCodes.some((errorCode) => companyLookupErrorCodes.indexOf(errorCode) !== -1);

        if (containsServerErrors) {
            this.toastService.add({
                content: this.translate.instant(new CreateAccountServerError().content),
                id: new CreateAccountServerError().name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
        else if (containsAccountInfoErrors || containsOktaErrors || containsDuplicateEmailErrors) {
            this.store.dispatch(new ResetUniqueUserName());
            this.store.dispatch(new ResetUniqueEmail());
            this.store.dispatch(new Navigate(['create-account-information']));
            if (containsOktaErrors) {
                err.error.details.some((detailError) => detailError.startsWith('password:')) ?
                    this.toastService.add({
                        content: new CreateAccountOktaPasswordError(err.error.details[0]).content,
                        id: new CreateAccountOktaPasswordError(err.error.details[0]).name,
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    }) :
                    this.toastService.add({
                        content: new CreateAccountDuplicateUsernameError().content,
                        id: new CreateAccountDuplicateUsernameError().name,
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    });
            }
            else if (containsDuplicateEmailErrors) {
                this.toastService.add({
                    content: this.translate.instant(new CreateAccountDuplicateEmailError().content),
                    id: new CreateAccountDuplicateEmailError().name,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
            }
            else {
                this.toastService.add({
                    content: this.translate.instant(new CreateAccountAccountInfoError().content),
                    id: new CreateAccountAccountInfoError().name,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
            }
        }
        else if (containsCompanyInfoErrors) {
            this.store.dispatch(new ResetCompanyCodeInfo());
            this.toastService.add({
                content: this.translate.instant(new CreateAccountCompanyInfoError().content),
                id: new CreateAccountCompanyInfoError().name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
        else {
            this.toastService.add({
                content: this.translate.instant(new CreateAccountServerError().content),
                id: new CreateAccountServerError().name,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
    }
}

