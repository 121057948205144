import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { AccountStatusModel } from 'common/models/account-status';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AccountState } from 'common/store/create-account.state';
import { createAccountEmailCodeValidator } from 'common/utils/createAccountValidators';
import { EmailVerificationCodePostData, EmailVerificationStatus } from 'private/app/models/accountInfo';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CreateAccountService } from '../../services/create-account.service';
import { AppConstants } from 'common/app-constants';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

export interface EmailVerification {
    email: string;
    status: boolean;
}

@Component({
    selector: 'hvac-email-validation',
    templateUrl: './email-validation.component.html',
    styleUrls: ['./email-validation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EmailValidationComponent implements OnInit, OnDestroy {
    @Input() requestEmail: string;
    @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() emailVerification: EventEmitter<EmailVerification> = new EventEmitter<EmailVerification>();

    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AccountStatusState.getProfileInfo) userInfo$: Observable<AccountStatusModel>;

    emailCodeControl = new UntypedFormControl('', createAccountEmailCodeValidator());
    ngOnDestroy$ = new Subject();
    resetButtonDisabled = true;
    buttonTimer: NodeJS.Timeout;
    errorBanner = false;
    public toastOutlet = 'submitEmailVerificationToast'
    private resendButtonTimer = AppConstants.resendButtonTimer;

    private accountInfo: AccountStatusModel;

    constructor(
        public toastService: ToastService,
        private readonly accountService: CreateAccountService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.disableResendButton();
        this.userInfo$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((userInfo) => {
                this.accountInfo = userInfo;
                if (userInfo?.userId.length > 0) {
                    this.requestVerificationCode();
                }
            })
        ).subscribe();

        this.emailCodeControl.valueChanges.subscribe((value) => {
            if (value) {
                this.toastService.remove('EmailValidation');
            }
        });
    }

    disableResendButton() {
        this.resetButtonDisabled = true;
        this.buttonTimer = setTimeout(() => {
            this.resetButtonDisabled = false;
            this.errorBanner = true;
            this.toastService.add({
                content: this.translate.instant('CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_RESEND_MESSAGE'),
                theme: 'warning',
                id: 'EmailValidation',
                closeable: true,
                autoClose: true
            });
        }, this.resendButtonTimer);
    }

    requestVerificationCode() {
        if (this.accountInfo?.userId.length > 0) {
            this.disableResendButton();
            this.accountService.requestEmailVerificationCode({
                email: this.requestEmail,
                userId: this.accountInfo.userId
            } as EmailVerificationCodePostData).subscribe((response) => response);
        }
    }

    submitEmailVerification() {
        if (this.accountInfo?.userId.length > 0) {
            this.accountService.verifyEmailCode({
                email: this.requestEmail,
                userId: this.accountInfo.userId,
                code: this.emailCodeControl.value
            }).subscribe((results: EmailVerificationStatus) => {
                if (results === 'Success') {
                    this.cancel.emit(false);

                    this.toastService.add({
                        content: this.translate.instant('USER_VALIDATION.EMAIL_VALIDATION_SUCCESS'),
                        theme: 'success',
                        autoClose: true,
                        id: 'submitEmailVerification',
                        outletName: this.toastOutlet
                    });

                    this.emailVerification.emit({
                        email: this.requestEmail,
                        status: true
                    });
                }
            });
        }
    }

    ngOnDestroy() {
        clearTimeout(this.buttonTimer);
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    cancelEmailValidation() {
        clearTimeout(this.buttonTimer);
        this.cancel.emit(false);
    }
}
