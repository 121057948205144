import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BrandService } from 'common/services/brand.service';
import { ErrorService } from 'common/content/services/error.service';

export interface ExternalLink {
    link: string,
    brand: string,
    target?: string
    params?: {Brand: string}
}

export interface BrandList {
    brandName: string,
    brandCode: string[]
}

export interface SiteMap {
    Url: string,
    brands: string[]
}

@Component({
    selector: 'app-brand-select',
    templateUrl: './brand-select.component.html',
    styleUrls: ['./brand-select.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BrandSelectComponent implements OnDestroy, OnInit {
    @Select(ContentState.contentBrandList) availableBrandsList$!: Observable<BrandList[]>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;

    externalLinks$: BehaviorSubject<ExternalLink[]> = new BehaviorSubject([] as ExternalLink[]);

    private externalLink: string | '';
    private internalLink: string | '';
    private ngOnDestroy$ = new Subject();
    private userPreferredBandList:Array<BrandList>;

    constructor(private route: ActivatedRoute, private readonly store: Store, private readonly router: Router, private errorService: ErrorService, private readonly brandService: BrandService) {
    }

    ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('select-brand-page');

        this.externalLink = this.route.snapshot.queryParams['q'] || '';
        this.internalLink = this.route.snapshot.queryParams['url'] || '';

        combineLatest([
            this.errorService.siteMap$,
            this.route.queryParamMap,
            this.availableBrandsList$,
            this.brand$
        ]).pipe(takeUntil(this.ngOnDestroy$)).subscribe(([response, params, availableBrands, brand]) => {
            if (this.externalLink.length === 0 && this.internalLink.length === 0) {
                this.router.navigate(['/']);
            }
            if (response && params && availableBrands && this.internalLink.length !== 0) {
                this.userPreferredBandList = availableBrands;
                const pageURL = params.get('url') as string;
                if (response.length > 0) {
                    if (brand.toLowerCase() === 'default') {
                        this.filterSiteMap(pageURL);
                    }
                    else if (this.brandService.isPublicICPBrand(brand)) {
                        this.externalLinks$.next([{
                            brand: this.store.selectSnapshot(ContentState.contentBrand).toLowerCase(),
                            link: `${pageURL}`,
                            target: '_self'
                        }]);
                    }
                    else {
                        this.externalLinks$.next([]);
                    }
                }
            }
        });

        combineLatest([
            this.availableBrandsList$,
            this.brand$
        ]).pipe(takeUntil(this.ngOnDestroy$)).subscribe(([brandList, prefBrand]) => {
            if (this.externalLink.length === 0 && this.internalLink.length === 0) {
                this.router.navigate(['/']);
            }
            if (brandList && this.externalLink.length !== 0) {
                if (prefBrand.toLowerCase() === 'default') {
                    const brandLink: ExternalLink[] = [];
                    const brands = this.brandService.filterICPBrands(brandList.map((brand) => brand.brandName));
                    brands.forEach((brand) => {
                        if (brand) {
                            brandLink.push({
                                brand: brand,
                                link: `${this.externalLink}?Brand=${this.formatBrandQueryParam(brand)}`,
                                target: '_blank'
                            });
                        }
                    });

                    this.externalLinks$.next(brandLink);
                }
                else if (this.brandService.isPublicICPBrand(prefBrand)) {
                    this.externalLinks$.next([{
                        brand: this.store.selectSnapshot(ContentState.contentBrand).toLowerCase(),
                        link: `${this.externalLink}?Brand=${this.formatBrandQueryParam(prefBrand)}`,
                        target: '_blank'
                    }]);
                }
                else {
                    this.externalLinks$.next([]);
                }
            }
        });
    }

    filterSiteMap(pageURL:string) {
        const filteredBrandsArray = this.brandService.filterICPBrands(this.userPreferredBandList.map((brand) => brand.brandName));
        if (filteredBrandsArray.length > 0) {
            const brandLink: ExternalLink[] = [];
            filteredBrandsArray.forEach((brand) => {
                if (brand) {
                    brandLink.push({
                        brand: brand,
                        link: `${pageURL}`,
                        target: '_self'
                    });
                }
            });
            this.externalLinks$.next(brandLink);
        }
    }

    ngOnDestroy(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('select-brand-page');

        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    formatBrandQueryParam(brand: string) {
        let formattedBrandName = '';
        if (this.externalLink.includes('opcost')) {
            formattedBrandName = brand === 'Day & Night' ? 'DayAndNight' : brand;
        }
        else {
            formattedBrandName = brand === 'Day & Night' ? 'DayandNight' : brand;
        }

        return formattedBrandName;
    }
}


