import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CSVExportService } from 'common/services/csv-export.service';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { SearchService } from 'private/app/services/connected-portal/search.service';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { ApiResponseCode, MAX_LIST_FIELD_LENGTH, MISSING_API_RESPONSE_DATA, MISSING_ROUTE_PARAMS_ERROR, QUERY_PARAM_DEBOUNCE_INTERVAL, SEARCH_RESULTS_PAGE_SIZE } from '../../../constants';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { AddToast, ToastService } from 'common/services/toast.service';
import { EMPTY } from 'rxjs';
import { DealerControlService } from 'private/app/services/connected-portal/dealer-control.service';


export interface ConnectedPortalQueuedCustomer {
    id: string;
    controlType: string;
    serialNo: string;
    esn: string | null;
    status: string;
}

@Component({
    selector: 'hvac-connected-portal-queued-customers',
    templateUrl: './connected-portal-queued-customers.component.html',
    styleUrls: ['./connected-portal-queued-customers.component.scss']
})
export class ConnectedPortalQueuedCustomersComponent extends BaseComponent implements OnInit {
    maxValueLength = MAX_LIST_FIELD_LENGTH;
    currentPage = 1;
    pageSize = SEARCH_RESULTS_PAGE_SIZE;
    itemCount = 0;
    totalPages: number = 1;
    items?: ConnectedPortalQueuedCustomer[];
    isLoading: boolean;
    dealerId?: string;
    searchTerm?: string | null;
    selectedItem: ConnectedPortalQueuedCustomer;
    toastOutlet = 'removeControlToast';
    showSpinner: boolean = false;

    constructor(
        private queryParamsService: QueryParamsService,
        private dealersService: DealersService,
        private searchService: SearchService,
        private router: Router,
        private csvExportService: CSVExportService,
        private translateService: TranslateService,
        private customerControlContextSvc: CustomerControlContextService,
        private toastService: ToastService,
        private dealerControlSvc: DealerControlService
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchService.searchPlaceholder.next('CONNECTED_PORTAL.CUSTOMERS.SEARCH.QUEUED_PLACEHOLDER');

        this.isLoading = true;

        const urlPageNumber = this.router.url?.split('page=')[1]?.split('&')[0] ? Number(this.router.url?.split('page=')[1]?.split('&')[0]) : this.currentPage;

        if (urlPageNumber !== this.currentPage) {
            this.handlePageChange(1);
        }

        this.queryParamsService.init({ page: this.currentPage })
            .pipe(
                debounceTime(QUERY_PARAM_DEBOUNCE_INTERVAL),
                switchMap((value) => {
                    const { page, search } = value;
                    const nextPage = this.getNextPage(page);

                    this.isLoading = true;
                    this.dealerId = this.router.url?.split('dealers/')[1]?.split('/')[0];
                    this.searchTerm = search;
                    this.currentPage = nextPage;

                    return this.getItems(this.dealerId, nextPage, search)
                        .pipe(
                            takeUntil(this.ngOnDestroy$),
                            catchError(() => {
                                this.isLoading = false;

                                return this.items = [];
                            })
                        );
                }),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((res) => {
                const { totalCount, totalPages, data } = res;

                this.totalPages = totalPages;
                this.itemCount = totalCount;
                this.isLoading = false;


                this.items = data;
            });

        if (this.customerControlContextSvc.isControlAdded) {
            if (this.customerControlContextSvc.toastContent) {
                this.toastService.add(this.customerControlContextSvc.toastContent);
            }

            this.customerControlContextSvc.isControlAdded = false;
            this.customerControlContextSvc.toastContent = null;
        }

        this.customerControlContextSvc.onRemoveControlConfirmed$.pipe(
            switchMap(() => this.removeQueueControl$()),
            takeUntil(this.ngOnDestroy$)
        ).subscribe();
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    handleExportProperties() {
        if (this.dealerId) {
            this.isLoading = true;

            this.getFilePathForExport(this.dealerId, 1, this.searchTerm, this.itemCount).pipe(
                takeUntil(this.ngOnDestroy$),
                catchError(() => {
                    this.isLoading = false;

                    return [];
                })
            )
                .subscribe((res) => {
                    const { filepath } = res;

                    if (filepath) {
                        this.csvExportService.downloadFile(filepath);
                    }
                    this.isLoading = false;
                });
        }
    }

    trackByItem(_index: number, item: ConnectedPortalQueuedCustomer) {
        return item.id;
    }

    onRemoveQueueItem(item: ConnectedPortalQueuedCustomer) {
        this.selectedItem = item;
        this.customerControlContextSvc.isRemoveControlModalVisible$.next(true);
    }

    private getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    private getItems(dealerId: string, page: number, searchTerm?: string | null, pageSize = this.pageSize) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        return this.dealersService
            .queryQueuedCustomersByDealerId(dealerId, queryParams)
            .pipe(
                map((value) => {
                    const { totalPages, totalCount } = value;
                    const items = value.data.map((entry): ConnectedPortalQueuedCustomer => {
                        const { id, controlType, sn: serialNo, esn, status } = entry;

                        return {
                            id,
                            controlType,
                            serialNo,
                            esn,
                            status
                        };
                    });

                    return {
                        totalPages,
                        totalCount,
                        data: items
                    };
                })
            );
    }

    private getFilePathForExport(dealerId: string, page: number, searchTerm?: string | null, pageSize = this.pageSize) {
        const exportFilePathControlErrorToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.TOAST.EXPORTFILE'),
            theme: 'error'
        });
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        return this.dealersService
            .queryQueuedCustomersByDealerIdExportOnly(dealerId, queryParams)
            .pipe(
                map((value) => {
                    const { totalPages, totalCount, filepath } = value;
                    if (filepath) {
                        return {
                            totalPages,
                            totalCount,
                            filepath
                        };
                    }

                    throw Error(MISSING_API_RESPONSE_DATA);
                }),
                catchError(() => {
                    this.toastService.removeAll();
                    this.toastService.add(exportFilePathControlErrorToast);
                    this.showSpinner = false;

                    return EMPTY;
                })
            );
    }

    private removeQueueControl$() {
        const removeControlSuccessToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.TOAST.SUCCESS'),
            theme: 'success'
        });

        const removeControlErrorToast = this.createToast({
            content: this.translateService.instant('CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.TOAST.ERROR'),
            theme: 'error'
        });

        this.showSpinner = true;
        const idParam = this.selectedItem.esn ? this.selectedItem.esn : this.selectedItem.serialNo;

        if (this.dealerId) {
            return this.dealerControlSvc.removeQueuedControl(idParam, this.dealerId)
                .pipe(
                    tap((res) => {
                        if (res) {
                            const { code } = res;

                            if (code === ApiResponseCode.SUCCESS) {
                                this.toastService.add(removeControlSuccessToast);
                            }
                            else {
                                this.toastService.add(removeControlErrorToast);
                            }
                        }

                        this.showSpinner = false;
                    }),
                    catchError(() => {
                        this.toastService.removeAll();
                        this.toastService.add(removeControlErrorToast);
                        this.showSpinner = false;

                        return EMPTY;
                    })
                );
        }

        throw new Error(MISSING_ROUTE_PARAMS_ERROR);
    }

    private createToast(toast: AddToast): AddToast {
        const defaults = {
            outletName: this.toastOutlet,
            closeable: true,
            autoClose: true
        };

        return {
            ...defaults,
            content: toast.content,
            theme: toast.theme
        };
    }
}
