import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { RouterStateSnapshot } from '@angular/router';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ProductDetails } from 'common/models';
import { UrlService } from 'common/services/url.service';
import { AuthState } from 'common/store/auth/auth.state';
import {
    FetchCatalogProductAttributes,
    FetchCatalogProductDataOverview, FetchCatalogProductDocuments
} from 'common/store/catalog/catalog.actions';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { ProductsState } from 'common/store/products/products.state';
import { lastChildRoute } from 'common/store/utils/routing.utils';
import * as he from 'he';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, skip, takeUntil, tap } from 'rxjs/operators';
@Component({
    selector: 'utc-product-details-page',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductDetailsComponent implements OnDestroy, OnInit {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(RouterState.state) routerState$!: Observable<RouterStateSnapshot>;
    @Select(ProductsState.revealProductDetails) revealDetails$!: Observable<boolean>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<Boolean>;
    @Select(ContentState.contentBrand) brand$!: Observable<string>;
    @Select(CatalogState.currentBrand) currentBrand$!: Observable<string>;
    @Select(ContentState.contentFilteringPreference) userPrefCompanyType$: Observable<string>;

    ngOnDestroy$ = new Subject();

    productUrn$: Observable<string> = this.routerState$.pipe(
        takeUntil(this.ngOnDestroy$),
        map(lastChildRoute),
        map(({ paramMap }) => paramMap.get('product') || '')
    );

    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    nameHtml$ = combineLatest([this.attributes$, this.brand$, this.currentBrand$]).pipe(
        map(([attributes, inputBrand, currentBrand]) => {
            const brand = (inputBrand === 'default') ? currentBrand : inputBrand;
            const nameText = attributes && attributes.names ? attributes.names : {};
            const selectedName = nameText[brand] ? nameText[brand] : nameText[Object.keys(nameText)[0]];

            return selectedName ? this.sanitizer.bypassSecurityTrustHtml(selectedName) : '';
        })
    );

    loading$: Subject<boolean> = new Subject();

    constructor(
        private readonly store: Store,
        private readonly sanitizer: DomSanitizer,
        private readonly urlService: UrlService,
        private readonly title: Title
    ) { }

    ngOnInit() {
        this.productUrn$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((urn) => Boolean(urn)),
            tap((urn) => {
                this.store.dispatch(new FetchCatalogProductAttributes(urn));
                this.store.dispatch(new FetchCatalogProductDataOverview(urn));
                this.store.dispatch(new FetchCatalogProductDocuments(urn));
                this.loading$.next(true);
            })
        ).subscribe();

        this.attributes$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((attributes) => Boolean(attributes)),
            delay(100),
            tap(() => this.loading$.next(false))
        ).subscribe();

        combineLatest([this.attributes$, this.productUrn$]).subscribe(([attributes, urn]) => {
            let pageTitle = urn;
            if (attributes && attributes.name) {
                pageTitle = `${urn}: ${attributes.name}`;
            }

            return pageTitle ? this.title.setTitle(he.decode(pageTitle)) : null;
        });

        combineLatest([this.brand$, this.userPrefCompanyType$]).pipe(
            takeUntil(this.ngOnDestroy$), skip(1), distinctUntilChanged()
        ).subscribe(([brand, companyType]) => {
            if (brand || companyType) {
                this.store.dispatch(new Navigate(['/products/dashboards/productcatalog']));
            }
        });
    }

    setLoginUrl() {
        return this.urlService.setLoginUrl();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
