<div class="hvac-wall-control-program-settings hvac-row">
    <div class="hvac-col-xs-12">
        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.PROGRAM_SETTINGS' | translate}}</h2>
        <div class="hvac-row">
            <div class="hvac-col-xs-12">
                <ul class="header-content hvac-cp-display-header">
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.DAY' | translate}} </li>
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.STATUS' | translate}} </li>
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.TIME' | translate}} </li>
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.ACTIVITY' | translate}} </li>
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.HEAT' | translate}} </li>
                    <li> {{'CONNECTED_PORTAL.WALL_CONTROL.COOL' | translate}} </li>

                </ul>
                <div *ngIf="groupedDataByDay.length < 1" class="hvac-wall-control-program-settings-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.WALL_CONTROL.SETTINGS_MESSAGES.PROGRAM_SETTINGS' | translate }}</div>
                <ng-container  *ngFor="let day of groupedDataByDay; let i= index;">
                    <hvac-accordion-new [id]= "'day '+i" [open]="day.isOpen" (toggle)="toggleAccordion(day)" [ngClass]="{'single-data-row': day.activities.length < 2}">
                        <hvac-accordion-header>
                            <ul class="accordion-header-content hvac-fw-rg" [ngClass]="{'hvac-cp-accordion-open': day.isOpen}">
                                <li class="hvac-fw-sb ">
                                    <div class="hvac-cp-activity-gutter">{{day.day}}</div>
                                </li>
                                <li><hvac-data-block style="text-align: center;" [status]="scheduleEnabled === 'true' && currentScheduleId === day.activities[0].id | boolToStatus" width="30px"> {{ scheduleEnabled === 'true' && currentScheduleId === day.activities[0].id ?
                                    ('CONNECTED_PORTAL.WALL_CONTROL.ON' | translate) :
                                    ('CONNECTED_PORTAL.WALL_CONTROL.OFF' | translate ) }} </hvac-data-block></li>
                                <li>
                                    <div class="hvac-cp-activity-gutter">{{day.activities[0].startTime || '' | militaryToStandard}}</div>
                                    <div class="hvac-cp-display-sm hvac-cp-temperature-activities">
                                        <div> {{'CONNECTED_PORTAL.WALL_CONTROL.HEAT' | translate}} </div>
                                        <div>{{day.activities[0].htsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                    </div>
                                </li>
                                <li class="hvac-fw-sb">
                                    <div class="hvac-cp-activity-gutter">{{day.activities[0].activity | titlecase }}</div>
                                    <div class="hvac-cp-display-sm hvac-cp-temperature-activities">
                                        <div>{{'CONNECTED_PORTAL.WALL_CONTROL.COOL' | translate}}</div>
                                        <div>{{day.activities[0].clsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                    </div>
                                </li>
                                <li class="hvac-cp-display-md">
                                    <div class="hvac-cp-activity-gutter">{{day.activities[0].htsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                </li>
                                <li class="hvac-cp-display-md">
                                    <div class="hvac-cp-activity-gutter">{{day.activities[0].clsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                </li>
                            </ul>
                        </hvac-accordion-header>
                        <hvac-accordion-body>
                            <ul class="accordion-activity-content">
                                <li></li>
                                <li>
                                    <div *ngFor="let activity of day.activities | slice:1: day.activities.length;" class="activity-status">
                                        <hvac-data-block style="text-align: center;" [status]="scheduleEnabled === 'true' && currentScheduleId === activity.id | boolToStatus"
                                            width="30px"> {{ scheduleEnabled === 'true' && currentScheduleId === activity.id ?
                                            ('CONNECTED_PORTAL.WALL_CONTROL.ON' | translate) :
                                            ('CONNECTED_PORTAL.WALL_CONTROL.OFF' | translate ) }}
                                        </hvac-data-block>
                                    </div>
                                </li>
                                <li>
                                    <div class="hvac-cp-activity-gutter" *ngFor="let activity of day.activities | slice:1: day.activities.length;">{{activity.startTime || '' | militaryToStandard}}
                                        <div class="hvac-cp-display-sm hvac-cp-temperature-activities">
                                            <div> {{'CONNECTED_PORTAL.WALL_CONTROL.HEAT' | translate}} </div>
                                            <div>{{activity.htsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="hvac-fw-sb hvac-cp-activity-gutter" *ngFor="let activity of day.activities | slice:1: day.activities.length;">{{activity.activity | titlecase }}
                                        <div class="hvac-cp-display-sm hvac-cp-temperature-activities">
                                            <div>{{'CONNECTED_PORTAL.WALL_CONTROL.COOL' | translate}}</div>
                                            <div>{{activity.clsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li class="hvac-cp-display-md"><div class="hvac-cp-activity-gutter" *ngFor="let activity of day.activities | slice:1: day.activities.length;">{{activity.htsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div></li>
                                <li class="hvac-cp-display-md"><div class="hvac-cp-activity-gutter" *ngFor="let activity of day.activities | slice:1: day.activities.length;">{{activity.clsp | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)}}</div></li>
                            </ul>
                        </hvac-accordion-body>
                    </hvac-accordion-new>

                </ng-container>

            </div>
        </div>
    </div>
</div>
