<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-opportunties-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation
                    *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>
        <hvac-opportunity-report-cards *ngIf="(opportunityCardData$ | async) as oppCardData"
            [userType]="oppCardData.companyCode"
            [dealerId]="oppCardData.dealerId"
            [brand]="oppCardData.brand"
            [inVppTerritory]="oppCardData.inVppTerritory"></hvac-opportunity-report-cards>
    </div>
</hvac-connected-portal-layout-container>
