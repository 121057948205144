import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })

export class OpticleanService {
  public opticleanForm: UntypedFormGroup;

  public formFields = {
      unitSizeValue: new UntypedFormControl(''),
      motorSpeedValue: new UntypedFormControl(''),
      cfm: new UntypedFormControl(''),
      roomHeight: new UntypedFormControl(''),
      roomArea: new UntypedFormControl(''),
      achValue: new UntypedFormControl(''),
      unitQuantity: new UntypedFormControl('')
  };

  constructor() {
      this.opticleanForm = new UntypedFormGroup(this.formFields);
  }
}
