<hvac-report-chart-container [chartTitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.CUSTOMER_LOGINS' | translate"
    [chartSubtitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.YEAR_TO_DATE_ANALYSIS' | translate"
    [hasData]="!!(chartData && data?.dataSummary)" [isLoading]="isLoading"
    yearSelectId="hvac-connected-control-report-year-select" [yearSelectFormControl]="yearSelectFormControl"
    [yearSelectOptions]="yearSelectOptions" [isReportButtonDisabled]="!data?.filepath"
    (onRunReport)="handleRunReport()">
    <canvas *ngIf="chartData" baseChart class="hvac-chart" [data]="chartData" [options]="chartOptions"
        [type]="chartType"></canvas>

    <div legend class="hvac-report-legend">
        <div class="hvac-report-legend-item" *ngFor="let item of legendData">
            <span class="hvac-report-legend-indicator" [style]="'background-color: ' + item.color"></span> <span class="hvac-report-legend-label hvac-subtext">{{item.label}}</span>
        </div>
    </div>
</hvac-report-chart-container>
