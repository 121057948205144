<div class="hvac-wall-control-config-details">
    <hvac-toast [name]="toastOutlet"></hvac-toast>
    <ng-container *ngIf="(categorizedData$ | async) as configData">
        <ng-container *ngIf="(viewState$ | async) === ViewState.Data">
            <ng-container *ngFor="let category of configData | keyvalue : categoryOrder">
                <div class="hvac-cp-property-sub-head" *ngIf="category.value?.length">
                    <div class="hvac-cp-property-sub-head-container">
                        <dt class="hvac-h4 hvac-fw-sb">{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.CONFIG_CATEGORIES.' + category.key | translate }}</dt>
                        <div class="hvac-cp-property-sub-head-info" *ngIf="category.key === 'minMax'">
                            <utc-svg src="assets/icons/info.svg"></utc-svg>
                            <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.CONFIG_CATEGORIES_HEADER_INFO.' + category.key | translate }}</span>
                        </div>
                    </div>
                </div>
                <hvac-product-property-list >
                    <hvac-product-property-list-item *ngFor="let prop of category.value">
                        <hvac-product-property-list-item-label>{{ prop.id === COMPRESSOR_LOCK_OUT_TEMP_PROPERTY ?
                            ('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.PROPS.' + prop.id | translate) : ('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.PROPS.' + prop.id | translate)}}</hvac-product-property-list-item-label>
                        <hvac-product-property-list-item-value>
                            <ng-container *ngIf="prop.readOnly || isEditEnabled === false; else editProp"> {{ prop.displayValue? prop.displayValue : prop.value }}
                            </ng-container>
                            <ng-template #editProp>
                                <span class="hvac-property-info-message"
                                    *ngIf="prop.id === COMPRESSOR_LOCK_OUT_TEMP_PROPERTY && (isCompressorLockOutWarningShown$ | async)">
                                    <utc-svg class="hvac-value-info-icon" src="assets/icons/info.svg"></utc-svg>{{
                                    'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.VALUE_WARNINGS.LOCK_OUT_OFFSET' |
                                    translate}}
                                </span>
                                <span class="hvac-property-info-message"
                                        *ngIf="prop.id === HEAT_DELTA_STAGE_2_PROPERTY && (isHeatDeltaWarningShown$ | async)">
                                        <utc-svg class="hvac-value-info-icon" src="assets/icons/info.svg"></utc-svg>{{
                                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.VALUE_WARNINGS.HEAT_DELTA_OFFSET' | translate}}
                                </span>
                                <span class="hvac-property-info-message"
                                        *ngIf="prop.id === COOL_DELTA_STAGE_2_PROPERTY && (isCoolDeltaWarningShown$ | async)">
                                        <utc-svg class="hvac-value-info-icon" src="assets/icons/info.svg"></utc-svg>{{
                                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.VALUE_WARNINGS.COOL_DELTA_OFFSET' | translate}}
                                </span>
                                <span class="hvac-property-info-message"
                                        *ngIf="prop.id === HEAT_DELTA_AUX_STAGE_2_PROPERTY && (isHeatDeltaAuxWarningShown$ | async)">
                                        <utc-svg class="hvac-value-info-icon" src="assets/icons/info.svg"></utc-svg>{{
                                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.VALUE_WARNINGS.HEAT_DELTA_AUX_OFFSET' | translate}}
                                </span>
                                <hvac-config-edit-prop
                                    *ngIf="prop.control"
                                    [editProp]="prop"
                                    [propFormControl]="prop.control"
                                    [groupId]="prop.groupId"
                                    (onEditCancel)="onControlEditCancel($event)"></hvac-config-edit-prop>
                            </ng-template>
                        </hvac-product-property-list-item-value>
                    </hvac-product-property-list-item>
                </hvac-product-property-list>
            </ng-container>

            <div *ngIf="isEditEnabled && configFormGroup" class="hvac-form-actions hvac-save-user-info">
                <div class="hvac-save-user-info-message" *ngIf="isDeviceConnected; else deviceDisconnectInfoMsg">
                    <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                    <span class="hvac-p-sm hvac-fw-rg">{{ isSaveEnabled ? ('CONNECTED_PORTAL.CONFIG_SAVE_ENABLED' |
                        translate) : ('CONNECTED_PORTAL.CONFIG_SAVE_DISABLED' | translate)}}</span>
                </div>
                <ng-template #deviceDisconnectInfoMsg>
                    <div class="hvac-save-user-info-message">
                        <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                        <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_DEVICE_DISCONNECTED' | translate
                            }}</span>
                    </div>
                </ng-template>
                <div class="hvac-form-actions-group">
                    <button class="hvac-form-actions-button"
                        [disabled]="!isSaveEnabled || (configFormGroup.pristine) || !isDeviceConnected || disableEditOnValidation"
                        hvacButton="default" size="small" (click)="resetFormGroups()">{{
                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.CANCEL' | translate }}</button>
                    <button class="hvac-form-actions-button"
                        [disabled]="!isSaveEnabled || (configFormGroup.pristine) || !isDeviceConnected || disableEditOnValidation"
                        hvacButton="primary" size="small" (click)="openConfirmModal()">{{
                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.SAVE' | translate }}</button>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="hvac-config-loading-spinner" *ngIf="(viewState$ | async) === ViewState.Loading">
        <utc-loading-spinner variant="overlay"></utc-loading-spinner>
    </div>
</div>
