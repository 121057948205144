import { PrivateConfig } from '../../models/environment.private';
import { commonEnvVars } from 'src/common/environments/private/environment.private.common';
import { EnvName } from 'common/app-constants';

const config: PrivateConfig = {
    envName: EnvName.Future,
    appId: 'l7xx5e5d882c07aa4188ba03f91ffd0dbe2e',
    googleMapAPI: '',
    brand: 'private',
    auth: {
        baseUrl: 'https://ssodev.hvacpartners.com',
        clientId: `0oaf8rm5uuaH5BBlE1d7`
    },
    addressDoctor: {
        login: '162277',
        password: 'i0gYu*^hgg'
    },
    baseUrl: 'https://dev4.hvacpartners.com',
    chatbotUrl: 'https://dev-chatbot.hvacpartners.com/bot2.html',
    connectedPortal: {
        url: 'https://17li4ggbul.execute-api.us-east-1.amazonaws.com/v1',
        key: 'da2-ymumcbrqdvatfl5jbzg6awtfxa',
        webSocketUrl: 'https://fkrsspdmnjacfk3os3z3nlpmna.appsync-api.us-east-1.amazonaws.com/graphql',
        webSocketKey: 'da2-dvjoxcun7nhnrmgaehkfs5evve'
    },
    coolCalcAPIDomain: 'stagingapi.coolcalc.com',
    coolCalcEnv: 'staging',
    documents: {
        whatsNewAge: 365,
        bulletinAge: 2555
    },
    envPath: '/dev',
    features: {
        accountAdmin: true,
        accountAdminDealers: true,
        associatedContacts: true,
        accountAdminUser: true,
        accountAdminCreateUser: true,
        auth: { login: true },
        brandFilter: {
            brand: true,
            content: true
        },
        brandSelection: true,
        chatbot: true,
        connectedPortal: {
            internal: false,
            distributors: true,
            distributorStats: false,
            dealers: true,
            warrantyLookup: false,
            ecobeeDiagnosticsTestingFeature: false,
            ecobeeSystemsFeature: true,
            ecobeeEditConfigFeature: false,
            dealerSettingFeature: true,
            addControlFeature: true,
            opportunityReportUsageGraph: false,
            energyEventsEnabled: false
        },
        dealerSearchCriteriaCIN: false,
        product: {
            worksWellWith: false,
            options: false
        },
        profile: true,
        relationshipType: false,
        searchContent: true,
        searchProgram: true,
        selectPrimaryDistributor: true,
        socialLogin: false,
        suggestions: true,
        tradePartner: true,
        userValidation: false,
        partsCatalog: true,
        googleAddressValidation: true,
        pendo: true,
        registrationRequest: true
    },
    gatewayPath: 'https://staging.servicesgateway.carrier.com',
    gatewayPathNoProtocol: 'staging.servicesgateway.carrier.com',
    idmPath: 'idm-dev',
    iframePath: 'https://dev-legacy2.hvacpartners.com',
    production: false,
    secureBaseUrl: 'https://dev4.hvacpartners.com',
    siteUrls: {
        default: 'https://dev4.hvacpartners.com',
        carrier: 'https://dev-carrier.hvacpartners.com',
        bryant: 'https://dev-bryant.hvacpartners.com',
        arcoaire: 'http://dev-arcoaire.hvacpartners.com',
        airquest: 'http://dev-airquest.hvacpartners.com',
        comfortmaker: 'http://dev-comfortmaker.hvacpartners.com',
        dayandnight: 'http://dev-dayandnight.hvacpartners.com',
        heil: 'http://dev-heil.hvacpartners.com',
        keeprite: 'http://dev-keeprite.hvacpartners.com',
        tempstar: 'http://dev-tempstar.hvacpartners.com',
        totaline: 'http://dev-totaline.hvacpartners.com'

    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const environment: any = { ...commonEnvVars(config) };
