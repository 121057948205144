export class OpenChatBot {
    static readonly type = '[Chatbot] OpenChatbot';
}

export class CloseChatbot {
    static readonly type = '[Chatbot] CloseChatbot';
}

export class UnMinimizeChatbot {
    static readonly type = '[Chatbot] UnMinimizeChatbot';
}

export class MinimizeChatbot {
    static readonly type = '[Chatbot] MinimizeChatbot';
}
