import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountAdminUtilsService } from 'private/app/services/account-admin/account-admin-utils.service';
import { ContentHeadingData } from '../../../../common/models/content-heading';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';

@Component({
    selector: 'hvac-account-admin',
    templateUrl: './account-admin.component.html',
    styleUrls: ['./account-admin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminComponent implements OnDestroy, OnInit {
    public breadCrumbData: ContentHeadingData;


    constructor(
        public accountAdminUtilsService: AccountAdminUtilsService,
        public accountAdminService: AccountAdminService,
        private languageTranslationService: LanguageTranslationService

    ) {}

    ngOnInit(): void {
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = this.accountAdminUtilsService.breadCrumbData;
            this.accountAdminUtilsService.setAdminBreadCrumbData(this.breadCrumbData);
        });
    }

    ngOnDestroy(): void {
        this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
    }
}
