<div class="hvac-odu-configuration">
    <hvac-product-property-list>
        <hvac-product-property-list-item *ngFor="let prop of formattedPropData | keyvalue: sortOrder">
            <hvac-product-property-list-item-label>
                {{ 'CONNECTED_PORTAL.ODU.CONFIGURATION.' + prop.value.id | translate}}
            </hvac-product-property-list-item-label>
            <hvac-product-property-list-item-value>
                <ng-container *ngIf="prop.value.readOnly || editEnabled === false; else editProp">
                    {{ prop.value.value }}
                </ng-container>

                <ng-template #editProp>
                    <hvac-config-edit-prop *ngIf="prop.value.control && configFormGroup"
                        [editProp]="prop.value"
                        [propFormControl]="prop.value.control"
                        [groupId]="prop.value.groupId"
                    ></hvac-config-edit-prop>
                </ng-template>

            </hvac-product-property-list-item-value>
        </hvac-product-property-list-item>
    </hvac-product-property-list>

    <hvac-toast [name]="toastOutlet"></hvac-toast>

    <div *ngIf="editEnabled && configFormGroup" class="hvac-form-actions hvac-save-user-info">
        <div class="hvac-save-user-info-message" *ngIf="isDeviceConnected; else deviceDisconnectInfoMsg">
            <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg> <div class="hvac-p-sm hvac-fw-rg">{{ saveEnabled ? ('CONNECTED_PORTAL.CONFIG_SAVE_ENABLED' | translate) : ('CONNECTED_PORTAL.CONFIG_SAVE_DISABLED' | translate) }}</div>
        </div>
        <ng-template #deviceDisconnectInfoMsg>
            <div class="hvac-save-user-info-message">
                <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg> <div class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_DEVICE_DISCONNECTED' | translate }}</div>
            </div>
        </ng-template>

        <div class="hvac-form-actions-group">
            <button class="hvac-form-actions-button" [disabled]="!saveEnabled || configFormGroup.pristine || !isDeviceConnected" hvacButton="default" size="small" (click)="handleCancel()">Cancel</button>
            <button class="hvac-form-actions-button" [disabled]="!saveEnabled || configFormGroup.pristine || !isDeviceConnected" hvacButton="primary" size="small" (click)="openConfirmModal()">Save</button>
        </div>
    </div>

    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
</div>


