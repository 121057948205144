/* eslint-disable max-lines */
/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddToast, ToastService } from 'common/services/toast.service';
import { ConnectedPortalAPIError } from 'private/app/models/connected-portal-error.model';
import { WallControlConfig } from 'private/app/models/wall-control.model';
import { ConfigEditProp, ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ApiErrorName, ApiErrorValue, CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP, TempUnitFormat, UI_TRANSLATION_CONFIG } from 'private/app/views/connected-portal/constants';
import { of } from 'rxjs';
import { ProductDetailContextService } from '../../../../product-detail-context.service';

// Types are not being property returned from this package atm, so ignoring them for now.
// @ts-ignore
import { getETRuleConfig } from './../../../../../../configurationDetails/entryTier/configData/index';

export enum ETConfigType {
    Range = 'range',
    Temprange = 'temprange',
    Option = 'option',
}

export enum Unit {
    Percent = '%',
}
interface ETRangeConfig {
    type?: ETConfigType.Range;
    min?: number | 'min_clsp';
    max?: number | 'max_htsp';
    default?: string | number;
    resolution?: number;
    note?: string;
    unit?: Unit;
}
interface ETTempConfig {
    type: ETConfigType.Temprange;
    F?: ETRangeConfig;
    C?: ETRangeConfig;
}

interface ETOptionConfig {
    type: ETConfigType.Option;
    values?: string[];
    default?: string;
}

interface UnitOptions {
    unitOptions: {
        [key: string]: {
            min: number,
            max: number
        };
    }
}

interface MappedConfig {
    maxHeatSetpoint?: UnitOptions;
    minCoolSetpoint?: UnitOptions;
    deadband?: UnitOptions;
}

type ETPropConfig = ETRangeConfig | ETOptionConfig | ETTempConfig;

export type AccessoryOutput = 'none' | 'ventilation' | 'humidifier' | 'dehumidifier' | null;

export interface ETEditConfig {
    [key: string]: ETPropConfig
}

export interface CarrierEltConfigData {
    configProps: Record<string, ConfigEditProp>;
}

export interface CarrierEltCategorizedData {
    [key: string]: ConfigEditProp[] | null
}

export interface ETConfigInput {
    systemType: string;
    subsystemType: string;
    accessoryOutput: string;
    maxHeatSetpoint?: number;
    minCoolSetpoint?: number;
}

const propertyNameMap = {
    accessory_output: 'accessoryOutput',
    auxiliary_heat_lockout: 'auxHeatLockoutEnableDisable',
    auxiliary_heat_lockout_temp: 'auxHeatLockoutTemp',
    change_over_type: 'changeOverType',
    clsp: 'coolToSetPoint',
    compressor_lockout: 'compressorLockoutEnableDisable',
    compressor_lockout_temp: 'compressorOutdoorMinTemp',
    config_cool_delta_stg1_on: 'coolDeltaStage1',
    config_cool_delta_stg2_on: 'coolDeltaStage2',
    config_heat_delta_aux1_on: 'heatDeltaAuxStage1',
    config_heat_delta_aux2_on: 'heatDeltaAuxStage2',
    config_heat_delta_stg1_on: 'heatDeltaStage1',
    config_heat_delta_stg2_on: 'heatDeltaStage2',
    cooling_cycles: 'coolingCycles',
    dry_mode: 'dryMode',
    drymode_set_point: 'drymodeSetpoint',
    fan_mode: 'fanMode',
    fan_type: 'fanType',
    fan_purge_cool: 'fanPurgeCool',
    fan_purge_heat: 'fanPurgeHeat',
    heating_cycles: 'heatingCycles',
    htsp: 'heatToSetPoint',
    max_htsp: 'maxHeatSetpoint',
    min_clsp: 'minCoolSetpoint',
    mode: 'userModeSelection',
    overcool_setpoint: 'overcoolSetpoint',
    rh_dehumidification_sp: 'rhDehumidificationSetpoint',
    rh_dehumidify_fan_dependent: 'rhDehumidifierControlOption',
    rh_humidifier_heat_dependent: 'rhHumidifierControlOption',
    rh_humidification_sp: 'rhHumidificationSetpoint',
    set_point_delta: 'deadband',
    ventilation_max_idu_odu_temp_delta: 'ventilationMaxIOTempDelta',
    ventilation_percent_on_time: 'ventilationPercentOnTime'
};

export const COMPRESSOR_LOCK_OUT_TEMP_PROPERTY = propertyNameMap.compressor_lockout_temp;
export const HEAT_DELTA_STAGE_2_PROPERTY = propertyNameMap.config_heat_delta_stg2_on;
export const COOL_DELTA_STAGE_2_PROPERTY = propertyNameMap.config_cool_delta_stg2_on;
export const HEAT_DELTA_AUX_STAGE_2_PROPERTY = propertyNameMap.config_heat_delta_aux2_on;

enum SystemConfigurationProperties {
    FANTYPE = 'fanType',
    SUBSYSTEMTYPE = 'subsystemType',
    SYSTEMTYPE = 'systemType',
    ACCESSORY_OUTPUT = 'accessoryOutput'
}

@Injectable()
export class CarrierEltWallControlConfigService {
    constructor(
        private productConfigService: ProductConfigurationService,
        private contextService: ProductDetailContextService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {}

    public getCategorizedData(data: CarrierEltConfigData['configProps'], propertyCategoryMap: Partial<typeof CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP> =
    CARRIER_ELT_CONFIG_PROP_CATEGORY_MAP): CarrierEltCategorizedData {
        const hiddenItem: AccessoryOutput = 'dehumidifier';
        const systemConfigProperties = Object.values(SystemConfigurationProperties) as string[];

        if (data.accessoryOutput?.value === hiddenItem) {
            delete propertyCategoryMap?.overcoolToDehumidify;
        }

        return Object.entries(propertyCategoryMap).reduce((acc, [category, properties]) => {
            const categoryData = properties.reduce((categoryAcc, property) => {
                if (data[property]) {
                    const propertyData = { ...data[property] };
                    if (UI_TRANSLATION_CONFIG.includes(propertyData.id)) {
                        propertyData.displayValue = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.UI_TRANSLATION_VALUES.${propertyData.id}.${propertyData.value}`);
                    }
                    if (systemConfigProperties.includes(property)) {
                        switch (propertyData.id) {
                            case SystemConfigurationProperties.SYSTEMTYPE:
                                propertyData.value = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_SYSTEM_TYPE.${propertyData.value}`);
                                break;
                            case SystemConfigurationProperties.FANTYPE:
                                propertyData.value = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_FANTYPE.${propertyData.value}`);
                                break;
                            case SystemConfigurationProperties.ACCESSORY_OUTPUT:
                                propertyData.value = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.ACCESSORY_OUTPUT.${propertyData.value}`);
                                break;
                            default:
                                break;
                        }
                    }
                    categoryAcc.push(propertyData);
                }

                return categoryAcc;
            }, [] as ConfigEditProp[]);

            const hasCategoryData = Object.values(categoryData).some((item) => item.value !== null);

            acc[category] = hasCategoryData ? categoryData : null;

            return acc;
        }, {} as CarrierEltCategorizedData);
    }

    public getEditableConfigPropData$(wallControlConfigData: WallControlConfig, tempUnitFormat: TempUnitFormat) {
        const { systemType, subsystemType, minCoolSetpoint, maxHeatSetpoint, accessoryOutput, heatToSetPoint, coolToSetPoint } = wallControlConfigData;

        const wallControlStaticConfigParams = this.getEditConfig(systemType, subsystemType, accessoryOutput, minCoolSetpoint, maxHeatSetpoint, tempUnitFormat, heatToSetPoint, coolToSetPoint) || {};
        const mergedData = this.productConfigService.mergeEltConfigData(wallControlConfigData, wallControlStaticConfigParams as {}, tempUnitFormat);

        const configDataControls = Object.entries(mergedData)
            .reduce((acc, [key, value]) => {
                if (value && value.readOnly === false && value.control) {
                    if (UI_TRANSLATION_CONFIG.includes(key)) {
                        if (!value.readOnly && value.control?.value) {
                            value.control.setValue(this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.UI_TRANSLATION_VALUES.${value.id}.${value.value}`));
                            value.value = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.UI_TRANSLATION_VALUES.${value.id}.${value.value}`);
                        }
                    }
                    acc[key] = value.control;
                }

                return acc;
            }, {} as Record<string, UntypedFormControl>);

        return of({
            configData: mergedData,
            configDataControls
        });
    }

    public showErrorToast(err: ConnectedPortalAPIError, outletName: string) {
        const configEditToast: AddToast = {
            outletName,
            closeable: true,
            autoClose: true
        };

        if (err.data?.length) {
            const hasConfigError = err.data
                .some((item) => item.name === ApiErrorName.ConfigParams && item.value === ApiErrorValue.UpdateError);

            const hasDeltaStageConfigError = err.data
                .some((item) => item.name === ApiErrorName.DeltaConfigParams && item.value === ApiErrorValue.UpdateError);

            const basePath = 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES';

            let toast: AddToast | null = null;

            if (hasConfigError && hasDeltaStageConfigError) {
                toast = {
                    ...configEditToast,
                    ...{
                        content: this.translateService.instant(`${basePath}.UNACKNOWLEDGED.TITLE`) + this.translateService.instant(`${basePath}.UNACKNOWLEDGED.SUB_TEXT`),
                        theme: 'error'
                    }
                };
            }
            else if (hasConfigError || hasDeltaStageConfigError) {
                toast = {
                    ...configEditToast,
                    ...{
                        content: this.translateService.instant(`${basePath}.CARRIER_ELT_PARTIAL_UPDATE_ERROR.TITLE`) + this.translateService.instant(`${basePath}.CARRIER_ELT_PARTIAL_UPDATE_ERROR.SUB_TEXT`),
                        theme: 'error'
                    }
                };
            }
            else {
                this.contextService.showToast(true, err, outletName);
            }

            if (toast) {
                this.toastService.add(toast);
            }
        }
        else {
            this.contextService.showToast(true, err, outletName);
        }
    }

    public getEditConfig(systemType: string | null, subsystemType: string | null, accessoryOutput: AccessoryOutput | null, minCoolSetpoint: null | string, maxHeatSetpoint: null | string, tempUnitFormat: TempUnitFormat, heatToSetPoint: null | string, coolToSetPoint: null | string) {
        if (systemType && subsystemType && accessoryOutput) {
            const etConfig = getETRuleConfig(systemType, subsystemType, accessoryOutput) as ETEditConfig;

            const maxHeatSetpointNum = parseFloat(maxHeatSetpoint!);
            const minCoolSetpointNum = parseFloat(minCoolSetpoint!);
            const heatToSetPointNum: number | null = isNaN(Number(String(heatToSetPoint))) ? null : Number(String(heatToSetPoint));
            const coolToSetPointNum: number | null = isNaN(Number(String(coolToSetPoint))) ? null : Number(String(coolToSetPoint));

            const mappedConfig = this.mapConfigProps(etConfig, maxHeatSetpointNum, minCoolSetpointNum, tempUnitFormat);

            return this.mappedConfigWithDropdownRestrictions(<MappedConfig>(mappedConfig as unknown), heatToSetPointNum, coolToSetPointNum, tempUnitFormat);
        }

        return null;
    }

    public mapConfigProps(config: ETEditConfig, maxHeatSetpoint: number, minCoolSetpoint: number, tempUnitFormat: TempUnitFormat) {
        return Object.entries(config).reduce((acc, [key, value]) => {
            const propName = propertyNameMap[key as keyof typeof propertyNameMap];

            if (propName) {
                const dropdownTranslation:boolean = false;
                let optionConfig:unknown;
                if (UI_TRANSLATION_CONFIG.includes(propName)) {
                    optionConfig = this.mapOptionConfig(value, maxHeatSetpoint, minCoolSetpoint, tempUnitFormat, true, propName);
                    // propertyData.displayValue = this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.UI_TRANSLATION_VALUES.${propertyData.value}`);
                }
                else {
                    optionConfig = this.mapOptionConfig(value, maxHeatSetpoint, minCoolSetpoint, tempUnitFormat, dropdownTranslation, propName);
                }


                if (optionConfig) {
                    acc[propName] = optionConfig;
                }
            }

            return acc;
        }, {} as { [key: string]: unknown});
    }

    public showDataErrorToast(outletName: string) {
        const configEditErrorToast: AddToast = {
            outletName: outletName,
            closeable: true,
            autoClose: true,
            content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.SUB_TEXT'),
            theme: 'error'
        };

        this.toastService.add(configEditErrorToast);
    }

    // Maps the ETConfig to match out static config format
    private mapOptionConfig(optionConfig: ETPropConfig, maxHeatSetpoint: number, minCoolSetpoint: number, tempUnitFormat: TempUnitFormat, uiTranslation:boolean, propName:string) {
        const type = optionConfig?.type || null;

        switch (type) {
            case ETConfigType.Option: {
                const { values } = optionConfig as ETOptionConfig;

                if (values) {
                    if (uiTranslation) {
                        const map = values.map((item) => this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.UI_TRANSLATION_VALUES.${propName}.${item}`));

                        return {
                            type: 'select',
                            options: [...map]
                        };
                    }

                    return {
                        type: 'select',
                        options: values
                    };
                }

                break;
            }
            case ETConfigType.Range: {
                return {
                    resolution: 1,
                    ...optionConfig,
                    type: 'select'
                };
            }
            case ETConfigType.Temprange: {
                const tempOptionConfig = { ...optionConfig } as ETTempConfig;

                if (!tempUnitFormat) {
                    return null;
                }

                const unitConfig = tempOptionConfig[tempUnitFormat];

                // Handle cases where min and max values are based on dynamic data
                // in which case one of the below strings will be returned from config instead of a number
                // currently this only affects the maxHeatSetpoint, minCoolSetpoint properties
                if (unitConfig?.min === 'min_clsp') {
                    unitConfig.min = minCoolSetpoint;
                }

                if (unitConfig?.max === 'max_htsp') {
                    unitConfig.max = maxHeatSetpoint;
                }

                return {
                    type: 'select',
                    unitOptions: { ...tempOptionConfig }
                };
            }
            default:
                return null;
        }

        return null;
    }

    private mappedConfigWithDropdownRestrictions(mappedConfig: MappedConfig, restrictedHeat: number | null, restrictedCool: number | null, tempUnitFormat: string) {
        if (mappedConfig?.deadband?.unitOptions?.[tempUnitFormat]?.max && restrictedCool && restrictedHeat) {
            mappedConfig.deadband.unitOptions[tempUnitFormat].max = Math.abs(restrictedCool - restrictedHeat);
        }

        if (mappedConfig?.maxHeatSetpoint?.unitOptions?.[tempUnitFormat]?.min && restrictedHeat) {
            mappedConfig.maxHeatSetpoint.unitOptions[tempUnitFormat].min = restrictedHeat;
        }

        if (mappedConfig?.minCoolSetpoint?.unitOptions?.[tempUnitFormat]?.max && restrictedCool) {
            mappedConfig.minCoolSetpoint.unitOptions[tempUnitFormat].max = restrictedCool;
        }

        return mappedConfig;
    }
}
