
import { DefaultCollectionResponse } from './default-collection-response.model';

export interface EnergyEvent {
    date?: string;
    startTime: string;
    endTime: string;
    description?: string | null;
}

export type WallControlEnergyEvent = Pick<EnergyEvent,
'date'
| 'startTime'
| 'endTime'
| 'description'
>

export interface WallControlEnergyEventResponse extends DefaultCollectionResponse {
    data: WallControlEnergyEvent[];
}

export class WallControlEnergyEventConfig {
    date: boolean = true;
    startTime: boolean = true;
    endTime: boolean = true;
    description: boolean = true;
}
