import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BaseContentEntity, UIContentResponse } from 'common/models';
import { ReceiveUIContent } from 'common/store/content/content.actions';
import { CloseChatbot, MinimizeChatbot, OpenChatBot, UnMinimizeChatbot } from './chatbot.actions';

const CHATBOT_TEMPLATE_NAME = 'Blks1-w100-07';

export interface ChatbotStateModel {
    contentData: {
        canShowChatbot: boolean;
        hoverText: string;
    };
    chatBotVisible: boolean;
    chatBotMinimized: boolean;
}

@State<ChatbotStateModel>({
    name: 'chatbot',
    defaults: {
        contentData: {
            canShowChatbot: false,
            hoverText: ''
        },
        chatBotVisible: false,
        chatBotMinimized: false
    }
})

@Injectable({ providedIn: 'root' })
export class ChatbotState {
    @Selector()
    static canShowChatbot({ contentData }: ChatbotStateModel): Boolean {
        return contentData.canShowChatbot;
    }

    @Selector()
    static hoverText({ contentData }: ChatbotStateModel): String {
        return contentData.hoverText;
    }

    @Selector()
    static chatBotVisible({ chatBotVisible }: ChatbotStateModel): Boolean {
        return chatBotVisible;
    }

    @Selector()
    static chatBotMinimized({ chatBotMinimized }: ChatbotStateModel): Boolean {
        return chatBotMinimized;
    }


    @Action(ReceiveUIContent)
    receiveChatbotUIContent(ctx: StateContext<ChatbotStateModel>, action: ReceiveUIContent) {
        return ctx.patchState({
            ...ctx.getState(),
            contentData: { ...this.formatChatBotData(action.uiContent) }
        });
    }

    @Action(OpenChatBot)
    showChatBot(ctx: StateContext<ChatbotStateModel>) {
        return ctx.patchState({ chatBotVisible: true });
    }

    @Action(CloseChatbot)
    hideChatBot(ctx: StateContext<ChatbotStateModel>) {
        ctx.dispatch(new UnMinimizeChatbot());

        return ctx.patchState({ chatBotVisible: false });
    }

    @Action(MinimizeChatbot)
    MinimizeChatBot(ctx: StateContext<ChatbotStateModel>) {
        return ctx.patchState({ chatBotMinimized: true });
    }

    @Action(UnMinimizeChatbot)
    UnMinimizeChatBot(ctx: StateContext<ChatbotStateModel>) {
        return ctx.patchState({ chatBotMinimized: false });
    }

    private formatChatBotData(uiContent: UIContentResponse) {
        const chatBotTemplate = this.findChatbotContent(uiContent);

        if (chatBotTemplate) {
            return {
                canShowChatbot: true,
                hoverText: chatBotTemplate.Content.title
            };
        }

        return {
            canShowChatbot: false,
            hoverText: ''
        };
    }

    private findChatbotContent(uiContent: UIContentResponse): BaseContentEntity | null {
        const mainReigion = uiContent.Regions.find((reigion) => reigion.Name === 'Main');

        if (!mainReigion || !mainReigion.Entities) {
            return null;
        }

        const chatbotContent = mainReigion.Entities.find((entity: BaseContentEntity) => entity?.MvcData.ViewName === CHATBOT_TEMPLATE_NAME);

        return chatbotContent ? chatbotContent : null;
    }
}
