<div class="hvac-config-edit-prop">
    <hvac-select-new *ngIf="editProp?.type === 'select' && editProp.options"
        size="small"
        shadow="none"
        [id]="editProp.id"
        [formControl]="propFormControl"
        [options]="editProp.options"
        [selectId]="editProp.id"
        >
    </hvac-select-new>

    <utc-input  *ngIf="editProp?.type === 'numberInput' && editProp.max"
        type="number"
        shadow="none"
        size="small"
        [id]="editProp.id"
        [inputControl]="propFormControl"
        [min]="editProp.min || 0"
        [max]="editProp.max"
        [step]="editProp.step"
        (blurFunction)="handleNumberInputBlur($event)"></utc-input>

    <button class="hvac-edit-cancel-button" *ngIf="propFormControl?.dirty" hvacButton="text" size="small" (click)="handlePropEditCancel()">Cancel</button>
</div>
