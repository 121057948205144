import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, UrlSerializer } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { LogOut } from 'common/store/auth/auth.actions';
import { AuthState, LoggedIn } from 'common/store/auth/auth.state';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'utc-authenticating',
    templateUrl: './authenticating.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None
})
export class AuthenticatingComponent implements OnInit, OnDestroy {
    @Select(AuthState.loggedIn) loggedIn$: Observable<LoggedIn>;

    ngOnDestroy$ = new Subject();
    redirect: string;

    constructor(private readonly store: Store, private route: ActivatedRoute, private urlSerializer: UrlSerializer) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe((params) => {
            const redirectParam = params.get('redirect');
            this.redirect = redirectParam || '/';
        });

        this.loggedIn$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((loggedIn) => {
                if (loggedIn === LoggedIn.LoggedIn) {
                    const tree = this.urlSerializer.parse(this.redirect);
                    const query = tree.queryParams;
                    tree.queryParams = {};
                    this.store.dispatch(new Navigate([decodeURIComponent(tree.toString())], query));
                }
                else if (loggedIn === LoggedIn.LoggedOut) {
                    this.store.dispatch(new LogOut());
                    this.store.dispatch(new Navigate(['/login']));
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
