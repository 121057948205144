import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InboxService } from '../services/inbox.service';
import { GetInboxCount, GetInboxDetails, Inboxmessage, InboxStateModel, ReceiveInboxCount } from './inbox.actions';

@State<InboxStateModel>({
    name: 'inbox',
    defaults: {
        unreadCount: 0,
        inboxMessages: []
    }
})
@Injectable({ providedIn: 'root' })
export class InboxState {
    constructor(private readonly inboxService: InboxService) { }

    @Selector()
    static unreadCount({ unreadCount }: InboxStateModel): number {
        return unreadCount;
    }

    @Selector()
    static inboxDetails({ inboxMessages }: InboxStateModel): Inboxmessage[] {
        return inboxMessages;
    }

    @Action(GetInboxCount)
    getInboxCount(ctx: StateContext<InboxStateModel>) {
        return this.inboxService.getInboxCount().subscribe((result) => {
            ctx.patchState({ unreadCount: result.inboxCount });
        });
    }

    @Action(GetInboxDetails)
    getInboxDetails(ctx: StateContext<InboxStateModel>) {
        return this.inboxService.getInboxDetails().subscribe((result) => {
            let inboxDetails: Inboxmessage[] = [];
            if (result?.length) {
                inboxDetails = this.inboxService.parseInboxData(result);
            }
            ctx.patchState({ inboxMessages: inboxDetails });
        });
    }

    @Action(ReceiveInboxCount)
    receiveUserStatus(ctx: StateContext<InboxStateModel>, action: ReceiveInboxCount) {
        return ctx.patchState({ unreadCount: action.unreadCountResponse.GetInboxUnreadCountResult });
    }
}
