import { Component, Input } from '@angular/core';
import { CharSymbol } from 'common/enums/char-symbol';
import { ActivityMap } from 'private/app/models/wall-control.model';

export interface WallControlProgramSettings {
    activeActivityTime: string;
    activities: (ActivityMap | null)[] | null;
    isHoldOn: boolean;
}

@Component({
    selector: 'hvac-wall-control-program-settings',
    templateUrl: './wall-control-program-settings.component.html',
    styleUrls: ['./wall-control-program-settings.component.scss']
})
export class WallControlProgramSettingsComponent {
    @Input() data: WallControlProgramSettings;

    public readonly CharSymbol = CharSymbol;
}
