import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PersonasService {
    public section$;

    private sectionSubject$ = new BehaviorSubject<number|null>(null);

    constructor() {
        this.section$ = this.sectionSubject$.asObservable();
    }

    openSection(id: number) {
        this.sectionSubject$.next(id);
    }

    closeSection() {
        this.sectionSubject$.next(null);
    }
}
