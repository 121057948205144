import { Address } from './address.model';

export enum CompanyCode {
    Distributor = 'DDT',
    Internal = 'INT',
    Dealer = 'DCR',
    International = 'DID'
}

export interface BrandOf {
    active: boolean,
    code: string,
    name: string,
    usageTypes: string[],
}

export interface CompanyType {
    code: string;
    name: string;
}

export interface AssociationsParamsObject {
    key: string;
    value: string;
}
export interface CompanyAssociations {
    id: string;
    companyType: {
        code: string;
        name: string;
    }
    legalEntity: boolean;
    active: boolean;
    name: string,
    cin: string,
    relationship: string;
    hvpId: string,
    emailAddress: string;
    address: Address,
    includeInDistributorLocator: boolean;
    companyCategory: {
        code: CompanyCode | string;
        name: string;
    }
    associatedBrands: string[];
    soldToCustomerId: string;
    shipToCustomerId: string;
    payerCustomerId: string;
    phoneNumber: string;
    faxNumber: string;
}

export interface CompanyAssociationsResult {
    data: CompanyAssociations[];
    totalCount: number;
}

export interface ContactsSearchCriteria {
    criteria: {
        companyId: string;
    },
    pageParams: {
        page: number;
        pageSize: number;
    }
    determineTotalCount: boolean;
}

export interface DistributorSearchCriteria {
    criteria: {
        companyId: string;
        companyTypeCode: string;
        postalCode: string;
        getAssociatedBrands: boolean
    },
    pageParams: {
        page: number;
        pageSize: number;
    }
    determineTotalCount: boolean;
    sortFields: string[];
}

export interface CompaniesEntity {
    id: string;
    dbId: number;
    companyType: CompanyType;
    soldToCustomerId: string;
    legalEntity: boolean;
    active: boolean;
    name: string;
    creationDate: string;
    lastModifiedDate: string;
    lastModifiedBy: string;
    emailAddress: string;
    address: Address;
    includeInDistributorLocator: boolean;
}

export interface AuthBrands {
    code: string;
    active: boolean;
    name: string;
    usageTypes: string[];
    catalogBrandOf: BrandOf[];
}

export interface Company {
    id: string,
    creationDate: string,
    active: true
    catalogBrandOf: BrandOf,
    code: string,
    name: string,
    usageTypes: string[];
    authorizedBrands: AuthBrands[];
    companyServices: string[];
}

export enum Usages {
    'MEDIA',
    'DOCUMENTS',
    'PRODUCTS'
}
