<div class="hvac-ecobee-report-container">
    <div class="hvac-report-sub-heading">
        <span class="hvac-h4">{{modelName && modelName.length > 0 ? modelName : ''}}</span>
    </div>

    <div class="hvac-row">
        <div class="hvac-report-date-picker hvac-col-xs-12 hvac-col-md-12">
            <div class="hvac-subhead">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.SELECT_DATE' | translate}}</div>
            <hvac-date-range-picker dateType="datetime" [startDate]="startDate" [minStartDate]="startDate"
                [endDate]="endDate" [maxEndDate]="endDate" (onSelection)="handleDateRangeChange($event)"
                [submitText]="('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.SUBMIT_TEXT' | translate)">
            </hvac-date-range-picker>

            <div *ngIf="(endDateMaxDiffExceededMessage$ | async) as endDateMaxDiffExceededMessage"
                class="hvac-report-date-picker-warning">
                <utc-svg src="assets/icons/info.svg"></utc-svg> {{endDateMaxDiffExceededMessage}}
            </div>
        </div>
    </div>

    <div class="hvac-runtime-report-toast">
        <hvac-toast [name]="toastOutlet"></hvac-toast>
    </div>

    <div class="hvac-report-chart-container hvac-row hvac-report-swimlane-container">
        <div class="hvac-col-xs-12" [ngClass]="{'hvac-report-swimlane-adjustments': isLoading}">
            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
            <hvac-connected-portal-swimlane-chart [dataSummary$]="swimlaneChartDataSummary$" [labelWidth]="318" [graphWidth]="750"></hvac-connected-portal-swimlane-chart>
        </div>
    </div>

    <div class="hvac-report-chart-container hvac-row">
        <div class="hvac-col-xs-12 hvac-col-md-3">
            <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.VIEW_BY' |
                translate }}</div>
            <div class="hvac-report-legend">
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-indoor-humid"
                        [inputControl]="humidChartLegendFormControlGroup.controls['indoorHumidityEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.INDOOR_HUMIDITY'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-outdoor-humid"
                        [inputControl]="humidChartLegendFormControlGroup.controls['outdoorHumidityEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.OUTDOOR_HUMIDITY'
                        | translate}}</utc-checkbox>
                </div>
            </div>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-9">
            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
            <div class="hvac-chart-ecobee">
                <canvas id="canvas-ecobee" *ngIf="ecobeeChartData" baseChart [data]="ecobeeChartData"
                [options]="ecobeeChartOptions" [type]="chartType"></canvas>
            </div>
            <div class="hvac-chart-legend-container hvac-chart-humidity-legend-container">
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-indoor-humidity-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.INDOOR_HUMIDITY'| translate}}
                </div>
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-outdoor-humidity-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.OUTDOOR_HUMIDITY'| translate}}
                </div>
            </div>
        </div>
    </div>

    <div class="hvac-report-chart-container hvac-row">
        <div class="hvac-col-xs-12 hvac-col-md-3">
            <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.VIEW_BY' |
                translate }}</div>
            <div class="hvac-report-legend">
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-heat-set-point"
                        [inputControl]="chartLegendFormControlGroup.controls['heatSetPointEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.HEAT_SETPOINT_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-cool-set-point"
                        [inputControl]="chartLegendFormControlGroup.controls['coolSetPointEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.COOL_SETPOINT_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-indoor-temp"
                        [inputControl]="chartLegendFormControlGroup.controls['indoorTemperatureEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.INDOOR_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-outdoor-temp"
                        [inputControl]="chartLegendFormControlGroup.controls['outdoorTemperatureEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.OUTDOOR_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
            </div>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-9">
            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
            <div class="hvac-chart-ecobee">
                <canvas id="canvas" *ngIf="chartData" baseChart [data]="chartData"
                [options]="chartOptions" [type]="chartType"></canvas>
            </div>
            <div class="hvac-chart-legend-container">
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-heat-set-point-temp-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.HEAT_SETPOINT_TEMPERATURE'| translate}}
                </div>
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-cool-set-point-temp-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.COOL_SETPOINT_TEMPERATURE'| translate}}
                </div>
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-indoor-temp-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.INDOOR_TEMPERATURE'| translate}}
                </div>
                <div class="hvac-chart-legend-item">
                    <span class="hvac-chart-legend-indicator hvac-outdoor-temp-indicator"></span>{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.OUTDOOR_TEMPERATURE'| translate}}
                </div>
            </div>

        </div>
    </div>

    <div class="hvac-report-footer">
        <div class="hvac-equipment-activity">
            <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.CURRENT_EQUIPMENT_ACTIVITY' |
                translate }}</div>
            <ul class="hvac-equipment-activity-list">
                <li *ngFor="let item of equipmentActivity" class="hvac-equipment-activity-item">
                    <span class="hvac-equipment-label">{{ item.label | titlecase }}</span> <hvac-data-block
                        [status]="item.value.toLowerCase() === 'off' ? 'inactive' : 'success'">{{item.value | uppercase
                        }}</hvac-data-block>
                </li>
            </ul>
        </div>
        <div class="hvac-report-export-button">
            <button hvacButton="primary" size="small" [disabled]="isLoading || !data?.filepath" (click)="onExportClick()">
                {{ 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.EXPORT_RUNTIME_REPORT' | translate }}
            </button>
        </div>
    </div>
</div>
