import { Component, Input } from '@angular/core';
import { ConnectedProduct, ProductType } from 'private/app/models/connected-product.model';

export interface FactorySystemDetails {
    productInfo: ConnectedProduct[];
}

@Component({
    selector: 'hvac-factory-system-details',
    templateUrl: './factory-system-details.component.html',
    styleUrls: ['./factory-system-details.component.scss']
})
export class FactorySystemDetailsComponent {
    @Input() isLoading: boolean;
    @Input() isWallControlConnected: boolean | null;
    @Input() data: FactorySystemDetails | null;

    readonly ProductType = ProductType;
}
