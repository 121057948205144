<hvac-connected-portal-layout-container>
    <div class="hvac-cp-top-container" top>
        <div class="hvac-cp-breadcrumb-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation
                    *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <section class="hvac-cp-bottom-container" bottom>
        <ng-container *ngIf="(routeData$ | async) as routeData">
            <ng-container [ngSwitch]="routeData.systemType">

                <hvac-infinity-odu-detail *ngSwitchCase="SystemType.INFINITY"
                    [dealerId]="routeData.dealerId"
                    [propertyId]="routeData.propertyId"
                    [systemType]="routeData.systemType"
                    [serialNo]="routeData.serialNo">
                </hvac-infinity-odu-detail>

                <hvac-ecobee-odu-detail *ngSwitchCase="SystemType.ECOBEE"
                    [dealerId]="routeData.dealerId"
                    [propertyId]="routeData.propertyId"
                    [systemType]="routeData.systemType"
                    [serialNo]="routeData.serialNo"> ></hvac-ecobee-odu-detail>

                <div *ngSwitchDefault>
                    <hvac-error-redirect></hvac-error-redirect>
                </div>
            </ng-container>
        </ng-container>
    </section>
</hvac-connected-portal-layout-container>

<hvac-system-diagnostics-modal *ngIf="(routeData$ | async) as routeData"
    [dealerId]="routeData.dealerId"
    [serialNo]="routeData.serialNo"
    [propertyId]="routeData.propertyId"
    [systemType]="routeData.systemType"
    [isDiagnosticModalVisible]="(contextService.isDiagnosticsModalVisible$ | async) || false"
    (closeDiagnosticsTestModal)="contextService.closeDiagnosticsTest$.next($event)"></hvac-system-diagnostics-modal>

<hvac-confirm-config-edit-modal *ngIf="(contextService.isConfigEditConfirmModalVisible$ | async) as isConfigEditConfirmModalVisible"
    [isModalVisible]="isConfigEditConfirmModalVisible"
    [productType]="ProductType.OutdoorUnit"
    (onCloseModal)="contextService.isConfigEditConfirmModalVisible$.next(false)"
    (onConfirmButtonClick)="contextService.confirmConfigEditSave$.next(true)"></hvac-confirm-config-edit-modal>
