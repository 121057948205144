import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { BrandingState } from 'common/store/branding/branding.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const brandMap: {[key: string]: string} = {
    carrier: 'assets/images/carrier-login-logo.png',
    bryant: 'assets/images/bryant-login-logo.png'
};

@Component({
    selector: 'hvac-login-hero',
    templateUrl: './login-hero.component.html',
    styleUrls: ['./login-hero.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginHeroComponent {
    @Select(BrandingState.themeBrand) brand$!: Observable<string>;
    @Output() onSignUp = new EventEmitter();
    brandLogo$ = this.brand$.pipe(map((brand: string) => brandMap[brand.toLowerCase()]));

    onSignUpClick() {
        this.onSignUp.emit();
    }
}
