<div tabindex="-1" #businessLocator>
    <utc-modal
        [visible]="(businessLocator$ | async)?.businessLocatorVisible || false"
        [size]="'full-business-locator'"
        (closeAction)="close()">

        <hvac-modal-header>{{'BUSINESS_LOCATOR.TITLE' | translate}}</hvac-modal-header>

        <div class="hvac-business-locator">
            <section class="hvac-business-locator-input-section" [ngClass]="{'hvac-business-locator-no-input': !(isZipCode$ | async) }">
                <div class='hvac-business-locator-input-container' *ngIf="isZipCode$ | async">
                    <h3 class="hvac-business-locator-label">{{'BUSINESS_LOCATOR.LABELS.ZIP_CODE' | translate}}</h3>
                    <utc-input
                        [inputControl]="businessLocatorInput"
                        inputId="business-locator-input"
                        type="text"
                        [labelIcon]="'assets/icons/search.svg'"
                        iconLabel="{{ 'BUSINESS_LOCATOR.SEARCH' | translate }}"
                        iconPosition="right"
                        [errorMessage]="zipCodeErrorMessage$ | async"
                        [disableInputIcon]="!businessLocatorInput.valid"
                        [hasIconFunction]="true"
                        (iconClickFunction)="zipCodeSearch()"
                        (enterKeyFunction)="zipCodeSearch()"></utc-input>
                </div>
                <div class='hvac-business-locator-results-count-container'>
                    <div *ngIf="formattedResults$" class='hvac-business-locator-results-count'>
                        {{( formattedResults$ | async)?.length}} {{'BUSINESS_LOCATOR.RESULTS' | translate}}
                    </div>
                </div>
            </section>
            <div class='hvac-business-locator-label-row'>
                <h3>{{'BUSINESS_LOCATOR.LABELS.COMPANY' | translate}}</h3>
                <h3>{{'BUSINESS_LOCATOR.LABELS.ADDRESS' | translate}}</h3>
            </div>
            <section *ngIf=" (formattedResults$ | async) && ( formattedResults$ | async)?.length && !(zipCodeSearchStatusPending$ | async)"  class='hvac-business-locator-results-section'>
                <div class='hvac-business-locator-results-row'  *ngFor="let result of ( formattedResults$ | async)">
                    <div class='hvac-business-locator-results-title'>
                        <h3>{{result.name}}</h3>
                        <ng-container *ngFor="let brand of result.brands">
                            <div *ngIf="brand.brandName.toLowerCase() !== 'icl'" class="hvac-business-locator-brand-badge hvac-business-locator-brand-badge-{{brand.brandName.substring(0,3).toLowerCase()}}">{{brand.brandName.toUpperCase()}}</div>
                        </ng-container>
                    </div>
                    <p>{{result.address}}</p>
                    <div class='hvac-business-locator-results-button'><button (click)="setCompanyId(result)">{{'BUSINESS_LOCATOR.CTA_BUTTON' | translate}} </button></div>
                </div>
            </section>
            <section *ngIf="(( formattedResults$ | async)?.length === 0 && !(zipCodeSearchStatusPending$ | async))" class='hvac-business-locator-results-section hvac-business-locator-no-results'>
                <p>{{'BUSINESS_LOCATOR.NO_RESULTS.'+ (((brand$ | async) || '') | uppercase) | translate}}</p>
            </section>
            <section class='hvac-business-locator-enroll-section' *ngIf="isZipCode$ | async">
                <a href="https://www.surveymonkey.com/r/FFDDM7P" target="_blank">{{'BUSINESS_LOCATOR.ENROLL_COMPANY_BUTTON' | translate}}</a>
            </section>
        </div>
    </utc-modal>
</div>
