import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select/select.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { Program, ProgramSearchPayload, ProgramSearchResults, Brand, EligibilityCriteria } from 'private/app/models/account-admin-program.model';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { BehaviorSubject } from 'rxjs';
import { AccountAdminProgramService } from '../../../services/account-admin/account-admin-program.service';
import { BaseResponse } from 'common/models/account-status';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'hvac-search-program',
    templateUrl: './search-program.component.html',
    styleUrls: ['./search-program.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchProgramComponent {
    public breadCrumbData: ContentHeadingData;
    loading: boolean;

    public brandsList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public criteriaNamesList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public programSearchForm = this.accountAdminProgramService.programSearchForm;
    public currentPage: number = 1;
    public program: Program;
    public programId: number;
    public programName?: string;
    public programCode?: string;
    public programIdToDelete: string;
    public pageSize: number = 10;
    public totalPages: number;
    public isModalVisible: boolean = false;
    public programResults$: BehaviorSubject<Program[]> = new BehaviorSubject<Program[]>([]);
    programAdminFullAccess: boolean;

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly toastService: ToastService,
        private route: ActivatedRoute,
        private languageTranslationService: LanguageTranslationService
    ) {
    }

    ngOnInit(): void {
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = {
                Content: {
                    title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS'),
                    pageTitle: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_3')
                },
                breadCrumbs: [
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                        url: '/admin'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                        url: '/Admin/Program'
                    }
                ]
            };
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
            this.programSearchForm.controls.status.setValue(this.translate.instant('ACCOUNT_ADMIN.ACTIVE'), { emitEvent: true });
        });
        this.programAdminFullAccess = (this.route.snapshot.data['programAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.MARKETING_PROGRAM_ADMIN) ||
            (this.route.snapshot.data['programAccess'] as IdmUserRoles[])?.includes(IdmUserRoles.CMNA_MARKETING_PROGRAM_ADMIN);
        this.getAccountAdminProgramBrands();
        this.getAccountAdminProgramEligibilityCriteria();
        this.defaultProgramSearchForm();
        this.getAccountAdminProgramsBySearch();
    }

    searchPrograms() {
        this.resetProgramSearch();
        this.getAccountAdminProgramsBySearch();
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), this.pageSize);
        this.getAccountAdminProgramsBySearch();
    }

    handleCloseModal() {
        this.isModalVisible = false;
    }

    modalVisible(programId: number) {
        this.programIdToDelete = programId.toString();
        this.isModalVisible = true;
        this.findProgramInformation(this.programIdToDelete);
    }

    findProgramInformation(programIdToDelete: string) {
        const programResults = this.programResults$.getValue();
        const programNameAndCode = programResults.find((progname) => programIdToDelete === progname.programId.toString());
        this.programName = programNameAndCode?.programName;
        this.programCode = programNameAndCode?.code;
    }

    public deleteEnrolledProgram() {
        this.accountAdminApiService.deleteAccountAdminProgram(this.programIdToDelete).subscribe((data:BaseResponse) => {
            if (data.status === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.REMOVE_PROGRAM_SUCCESS_MESSAGE')) {
                this.handleCloseModal();
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.REMOVE_PROGRAM_SUCCESS_TOAST', {
                        programName: this.programName,
                        programCode: this.programCode
                    }),
                    id: this.programIdToDelete,
                    theme: 'success',
                    closeable: true,
                    autoClose: true
                });
            }
            this.getAccountAdminProgramsBySearch();
        });
    }

    private getAccountAdminProgramBrands() {
        this.accountAdminApiService.getAccountAdminProgramBrands().subscribe((brands: Brand[]) => {
            const optionList: Option[] = [];
            brands.map((brand: Brand) => optionList.push({
                name: brand.name,
                value: brand.id
            }));
            optionList.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_BRAND'),
                value: ''
            });
            this.brandsList$.next(optionList);
        });
    }

    private getAccountAdminProgramEligibilityCriteria() {
        this.accountAdminApiService.getAccountAdminProgramEligibilityCriteria().subscribe((criteriaNames: EligibilityCriteria[]) => {
            const optionList: Option[] = [];
            criteriaNames.map((criteria: EligibilityCriteria) => optionList.push({
                name: criteria.name,
                value: criteria.id
            }));
            optionList.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_CRITERIA'),
                value: ''
            });
            this.criteriaNamesList$.next(optionList);
        });
    }

    private getAccountAdminProgramsBySearch() {
        this.loading = true;
        const criteria: ProgramSearchPayload = this.setCriteria();
        this.accountAdminApiService.getAccountAdminProgramsBySearch((criteria)).subscribe((programResults: ProgramSearchResults) => {
            this.loading = false;
            if (programResults?.data?.length) {
                this.totalPages = Math.ceil(programResults.totalCount / this.pageSize);
                programResults.data.map((program) => {
                    program.programManagerEmailsCount = this.getProgramManagerEmailsWithCount(program.programManagerEmails);

                    return program;
                });
                this.programResults$.next(programResults.data);
            }
            else {
                this.resetProgramSearch();
            }
        },
        () => {
            this.resetProgramSearch();
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS_ERROR'),
                id: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS_ERROR_ID'),
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    private setCriteria() {
        const formValue = this.programSearchForm.value;
        const criteriaData: ProgramSearchPayload = <ProgramSearchPayload>({
            criteria: {
                programName: '',
                programCode: '',
                programStatus: '',
                brandName: '',
                eligibleCriteriaName: ''
            },
            pageParams: {
                page: (this.currentPage - 1),
                pageSize: this.pageSize
            },
            sortFields: [],
            sortParams: [],
            determineTotalCount: true
        });
        if ((Array.isArray(formValue.status) && formValue.status[0].value)) {
            criteriaData.criteria.programStatus = formValue.status[0].value;
        }
        else if (formValue.status === this.translate.instant('ACCOUNT_ADMIN.ACTIVE')) {
            criteriaData.criteria.programStatus = this.translate.instant('ACCOUNT_ADMIN.ACTIVE');
        }
        else {
            criteriaData.criteria.programStatus = '';
        }
        criteriaData.criteria.programName = formValue.programName ? formValue.programName : '';
        criteriaData.criteria.programCode = formValue.programCode ? formValue.programCode : '';
        criteriaData.criteria.eligibleCriteriaName = (Array.isArray(formValue.criteriaName) && formValue.criteriaName[0].value) ? formValue.criteriaName[0].name : '';
        criteriaData.criteria.brandName = (Array.isArray(formValue.brand) && formValue.brand[0].value) ? formValue.brand[0].name : '';

        return criteriaData;
    }

    private getProgramManagerEmailsWithCount(emails: string[]) {
        let pmEmails: string = '';
        pmEmails = (emails.length > 2) ? `${emails[0]}, ${emails[1]} +${emails.length - 2}` : emails.join(',');

        return pmEmails;
    }

    private resetProgramSearch() {
        this.loading = false;
        this.currentPage = 1;
        this.totalPages = 0;
        this.programResults$.next([]);
        this.toastService.remove(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS_ERROR_ID'));
    }

    private defaultProgramSearchForm() {
        this.programSearchForm.controls.programCode.setValue('');
        this.programSearchForm.controls.programName.setValue('');
        this.programSearchForm.controls.brand.setValue('');
        this.programSearchForm.controls.criteriaName.setValue('');
    }
}
