import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { BrandList } from 'common/models/brand.model';
import { ContentState } from 'common/store/content/content.state';
import { AuthorizedBrand, Company, RelationshipType } from 'private/app/models/account-admin-search';
import { CompanyAssociations, CompanyAssociationsResult, DistributorSearchCriteria } from 'private/app/models/company.model';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { IdmService } from 'private/app/services/idm.service';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-authorized-brands',
    templateUrl: './authorized-brands.component.html',
    styleUrls: ['./authorized-brands.component.scss']
})
export class AuthorizedBrandsComponent implements OnInit, OnChanges {
    @Select(ContentState.contentBrandList) availableBrandsList$!: Observable<BrandList[]>;

    @Input() authorizedBrands: AuthorizedBrand[];
    @Input() editCompanyInformation: boolean;
    @Input() company: Company;
    @Output() public selectedBrands = new EventEmitter<AuthorizedBrand[]>();

    ngOnDestroy$ = new Subject();
    public shadowCopy$: BehaviorSubject<BrandList[]> = new BehaviorSubject<BrandList[]>([]);
    public addBrands: boolean;
    public userBrandList: BrandList[] = [];
    public associatedDealers: CompanyAssociations[][] = [];
    public relationshipsListWithDuplicates: string[] = [];
    public relationshipTypes: RelationshipType[];
    public brandsList: string[] = [];
    public associatedBrandsList: string[] = [];
    public relationshipList: string[] = [];
    public totalAPICalls: number;
    public pageSize: number = 50;

    constructor(
        protected idmService: IdmService,
        protected adminService: AccountAdminService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.authorizedBrands?.currentValue) {
            this.initBrandMapping();
        }
        this.authorizedBrands?.forEach((brand) => {
            if (this.brandsList.includes(brand.name)) {
                brand.canEdit = false;
            }
        });
    }


    ngOnInit(): void {
        this.initBrandMapping();
        this.adminService.getRelationshipTypes().pipe(takeUntil(this.ngOnDestroy$)).subscribe((response) => {
            this.relationshipTypes = response;
        });
        this.getRelationshipList();
    }

    getRelationshipList() {
        this.idmService.getAssociations(this.adminService.createAssociatedDistributerSearchBody(this.company, 0, null, 'DCR')).pipe(takeUntil(this.ngOnDestroy$)).subscribe((response: CompanyAssociationsResult) => {
            const arrayCalls = [];

            this.totalAPICalls = Math.ceil(response?.totalCount / this.pageSize);

            for (let i = 0; i < this.totalAPICalls; i++) {
                const body: DistributorSearchCriteria = {
                    criteria: {
                        companyId: this.company?.hvacCompanyId,
                        companyTypeCode: 'DCR',
                        postalCode: '',
                        getAssociatedBrands: true
                    },
                    pageParams: {
                        page: i,
                        pageSize: this.pageSize
                    },
                    determineTotalCount: true,
                    sortFields: []
                };

                arrayCalls.push(this.idmService.getAssociations(body));
            }

            forkJoin(arrayCalls).pipe(takeUntil(this.ngOnDestroy$)).subscribe((resultData) => {
                resultData.map((data) => this.associatedDealers.push(data.data));
                this.associatedDealers.flat(1).map((obj) => this.relationshipsListWithDuplicates.push(obj.relationship));

                this.relationshipList = [...new Set(this.relationshipsListWithDuplicates.flat(1))];

                this.relationshipTypes.map((element) => {
                    if (this.relationshipList.includes(element.description)) {
                        if (element.brand?.name) {
                            this.brandsList.push(element.brand?.name);
                        }
                    }

                    return this.brandsList;
                });

                this.authorizedBrands?.forEach((brand) => {
                    if (this.brandsList.includes(brand.name)) {
                        brand.canEdit = false;
                    }
                });
            });
        });
    }

    associatedBrandsCheck(brandName: string) {
        return (this.brandsList.includes(brandName));
    }

    showBrandlist() {
        this.addBrands = !this.addBrands;
        this.authorizedBrands?.forEach((brand) => {
            if (this.userBrandList?.find((userBrand) => userBrand.brandName.toLowerCase() === brand.name.toLowerCase())?.brandName === brand.name) {
                brand.canEdit = true;
            }
            if (this.brandsList.includes(brand.name)) {
                brand.canEdit = false;
            }
        });
    }

    addBrand(event: Event, brand: BrandList) {
        this.userBrandList?.forEach((userdBrand) => {
            if (brand.brandName === userdBrand.brandName) {
                userdBrand.selected = (event.target as unknown as HTMLInputElement).checked;
                if (brand.selected) {
                    this.authorizedBrands.push({
                        name: brand.brandName,
                        code: brand.brandCode,
                        active: true,
                        canEdit: true
                    });
                }
                else {
                    this.authorizedBrands = this.authorizedBrands.filter((authorizedBrand) => authorizedBrand.name !== brand.brandName);
                }
            }
        });
        this.selectedBrands.next(this.authorizedBrands);
    }

    removeBrand(brand: AuthorizedBrand) {
        this.userBrandList?.forEach((userdBrand) => {
            if (brand.name === userdBrand.brandName) {
                userdBrand.selected = false;
            }
        });
        this.authorizedBrands = this.authorizedBrands.filter((authorizedBrand) => brand.name !== authorizedBrand.name);
        this.selectedBrands.next(this.authorizedBrands);
    }

    trackBybrandCode(_index: number, brand: BrandList) {
        return brand.brandCode;
    }

    trackByCode(_index: number, brand: AuthorizedBrand) {
        return brand.code;
    }

    private initBrandMapping() {
        this.availableBrandsList$.subscribe((brandList) => {
            let availableBrandList: BrandList[] = [];
            brandList?.forEach((brand) => {
                brand?.catalogBrands?.forEach((catalogBrand) => {
                    availableBrandList.push({
                        brandName: catalogBrand.name,
                        brandCode: catalogBrand.code
                    });
                });
            });

            // remove brand duplicates
            availableBrandList = Array.from(availableBrandList.reduce((objA, objB) => objA.set(objB.brandName, objB), new Map()).values());
            this.shadowCopy$.next(availableBrandList);
        });

        this.shadowCopy$.subscribe((avalablebrandList: BrandList[]) => {
            avalablebrandList?.forEach((brand) => {
                if (this.authorizedBrands?.find((userBrand) => userBrand.name.toLowerCase() === brand.brandName.toLowerCase())?.name === brand.brandName) {
                    brand.selected = true;
                }
            });
            this.userBrandList = avalablebrandList;
        });

        this.authorizedBrands?.forEach((brand) => {
            if (this.userBrandList?.find((userBrand) => userBrand.brandName.toLowerCase() === brand.name.toLowerCase())?.brandName === brand.name) {
                brand.canEdit = true;
            }
        });
    }
}
