/* eslint-disable max-lines */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { Option } from 'common/components/select/select.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminProgramEditData, Brand, DefaultProgramDetails, ProgramDetails, DateRange } from 'private/app/models/account-admin-program.model';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { BrandList } from '../../brand-select/brand-select.component';
import endOfYear from 'date-fns/endOfYear';
import { DateUtilsService } from 'common/services/date-utils-service';
import { GMTTimeConversionUtilsService } from 'common/services/gmt-time-transformation.service';
import { endOfDay, startOfDay } from 'date-fns';
import { PeriodErrorValidationResult } from '../edit-program/edit-program.component';
import { ProgramEnrollmentDateUtilsService } from 'private/app/services/account-admin/program-enrollment-date-utils-service';

@Component({
    selector: 'hvac-create-program',
    templateUrl: './create-program.component.html',
    styleUrls: ['./create-program.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateProgramComponent implements OnInit, OnDestroy {
    @Select(ContentState.contentBrandList) availableBrandsList$!: Observable<BrandList[]>;
    @Select(ContentState.contentBrand) brand$: Observable<string>;

    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.CREATE_PROGRAM') },
        breadCrumbs: [
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                url: '/Admin/Program'
            },
            {
                title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_3'),
                url: '/Admin/Program/search-program/search'
            }
        ]
    }

    public isLoading = true;
    public isRequesting = false;
    public programCreateForm: FormGroup;
    public isFormDataChanged: boolean;
    public readonly todayDate: number = this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' })
    public readonly endOfYearDate: number = endOfYear(this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' })).setHours(23, 59, 59, 999);
    public maxInputLimitProgramNameField: number = 200;
    public maxProgramDescriptionLimit: number = 500;
    public isLocatorDateSet: boolean = true;

    programManagers: Option[];

    programDate$: BehaviorSubject<DateRange> = new BehaviorSubject<DateRange>({
        startDate: this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' }),
        endDate: endOfYear(this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' })).setHours(23, 59, 59, 999)
    });

    enrollmentDate$: BehaviorSubject<DateRange> = new BehaviorSubject<DateRange>({
        startDate: this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' }),
        endDate: endOfYear(this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' })).setHours(23, 59, 59, 999)
    });

    locatorDate$: BehaviorSubject<DateRange> = new BehaviorSubject<DateRange>({
        startDate: this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'StartOfDay' }),
        endDate: endOfYear(this.gmtTimeConversionUtilsService.getCurrentGMTTime({ formatTimeAs: 'EndOfDay' })).setHours(23, 59, 59, 999)
    });

    public programPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult();
    public enrollmentPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult();
    public locatorPeriodErrorValidationResult: PeriodErrorValidationResult = this.programEnrollmentDateUtilsService.getEmptyPeriodErrorValidationResult();
    programEndDate$: BehaviorSubject<number> = new BehaviorSubject<number>((new Date()).setMonth(new Date().getMonth()));
    brandOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    programDateErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    enrollmentDateErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    verticalError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    brandsError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    eligibilityCriteraError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    duplicateProgramName$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isProgramNameFieldValid: boolean = true;
    isDuplicateProgramName: boolean = false;
    programNameFieldErrorMessage: string = '';
    private bannerType = 'ProgramCreateBanner';
    private buttonTimer: NodeJS.Timeout;
    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        private languageTranslationService: LanguageTranslationService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private readonly toastService: ToastService,
        private router: Router,
        private dateUtils: DateUtilsService,
        private gmtTimeConversionUtilsService: GMTTimeConversionUtilsService,
        private programEnrollmentDateUtilsService: ProgramEnrollmentDateUtilsService
    ) {
        if (this.accountAdminProgramService.programEditForm) {
            this.accountAdminProgramService.resetForm();
        }
    }

    get areDateFieldsInError(): boolean {
        return this.programEnrollmentDateUtilsService.areDateFieldsInError([this.programPeriodErrorValidationResult, this.enrollmentPeriodErrorValidationResult, this.locatorPeriodErrorValidationResult]);
    }

    ngOnInit() {
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
        });
        this.accountAdminApiService.getAccountAdminProgramBrands().subscribe((brands: Brand[]) => {
            this.brandOptionData$.next(brands.map((brand: Brand) => ({
                name: brand.name,
                value: brand.id
            })));
        });
        this.programCreateForm = this.accountAdminProgramService.programEditForm;
        this.programDate$.subscribe((programdate) => {
            if (!(programdate.startDate && programdate.endDate)) {
                return;
            }
            this.programDateErrorMessage$.next('');
            const programStartDate: number = isNaN(programdate.startDate) ? new Date(programdate.startDate).setUTCHours(0, 0, 0, 0) : programdate.startDate;
            const programEndDate: number = isNaN(programdate.endDate) ? new Date(programdate.endDate).setUTCHours(0, 0, 0, 0) : programdate.endDate;
            if (this.accountAdminProgramService.programAdminData) {
                this.accountAdminProgramService.programAdminData.programDetails.programStartDate = this.dateUtils.formatDate(programStartDate);
                this.accountAdminProgramService.programAdminData.programDetails.programEndDate = this.dateUtils.formatDate(programEndDate);
            }
        });

        this.programCreateForm.controls.name.valueChanges.subscribe(() => {
            this.duplicateProgramName$.next(false);
        });

        this.programCreateForm.controls.vertical.valueChanges.subscribe((value: Option[]) => {
            if (value) {
                if (Array.isArray(value)) {
                    this.accountAdminProgramService.programAdminData.programDetails.vertical = {
                        description: value[0].name,
                        id: value[0].value
                    };
                }
                this.verticalError$.next(value.length < 1 || value[0].value.length < 1);
            }
        });

        this.programCreateForm.controls.eligibilityCriteria.valueChanges.subscribe((value: Option[]) => {
            if (value) {
                if (Array.isArray(value)) {
                    this.accountAdminProgramService.programAdminData.programDetails.eligibilityCriteria = {
                        name: value[0].name,
                        id: value[0].value
                    };
                }
                this.eligibilityCriteraError$.next(value.length < 1 || value[0].value.length < 1);
            }
        });

        this.programCreateForm.controls.brands.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails.brand = {
                    name: value[0].name,
                    id: value[0].value
                };
                this.accountAdminProgramService.programAdminData.locatorIcons = this.accountAdminProgramService.programAdminData?.locatorIconData[value[0].name?.toUpperCase()]?.map((icon) => ({
                    name: icon.description,
                    value: icon.id
                })) || [];
                this.brandsError$.next(value.length < 1 || value[0].value.length < 1);
            }
        });

        this.programCreateForm.controls.enrollmentWorkflow.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentWorkflow'] = {
                    name: value[0].name,
                    id: value[0].value
                };
                this.programCreateForm.controls.enrollmentForm.setValue(this.accountAdminProgramService.programEnrollmentFormOptions[0].name);
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentForm'] = {
                    name: this.accountAdminProgramService.programEnrollmentFormOptions[0].name,
                    id: this.accountAdminProgramService.programEnrollmentFormOptions[0].value
                };
                this.programCreateForm.controls.finalApprovedBy.setValue(this.accountAdminProgramService.programFinalApprover[0].name);
                this.accountAdminProgramService.programAdminData.programDetails['finalApprovedBy'] = {
                    desc: this.accountAdminProgramService.programFinalApprover[0].name,
                    id: this.accountAdminProgramService.programFinalApprover[0].value
                };
            }
        });

        this.programCreateForm.controls.enrollmentForm.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['enrollmentForm'] = {
                    name: value[0].name,
                    id: value[0].value
                };
            }
        });

        this.programCreateForm.controls.finalApprovedBy.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                this.accountAdminProgramService.programAdminData.programDetails['finalApprovedBy'] = {
                    desc: value[0].name,
                    id: value[0].value
                };
            }
        });

        this.programCreateForm.controls.locatorIcon.valueChanges.subscribe((value: Option[]) => {
            if (Array.isArray(value)) {
                if (value[0].value.length === 0) {
                    delete this.accountAdminProgramService.programAdminData.programDetails['locatorIcon'];

                    return;
                }
                this.accountAdminProgramService.programAdminData.programDetails['locatorIcon'] = {
                    description: value[0].name,
                    id: value[0].value
                };
            }
        });

        this.getAccountAdminProgramCreateData().subscribe((programAdminData) => {
            this.isLoading = false;
            this.accountAdminProgramService.programAdminData = programAdminData;
            // check to initi programDetails if it is empty
            if (Object.keys(this.accountAdminProgramService.programAdminData.programDetails).length === 0) {
                this.accountAdminProgramService.programAdminData.programDetails = new DefaultProgramDetails();
            }
            this.accountAdminProgramService.programAdminData.programDetails.programStartDate = this.dateUtils.formatDate(this.todayDate);
            this.accountAdminProgramService.programAdminData.programDetails.programEndDate = this.dateUtils.formatDate(this.endOfYearDate);
            this.accountAdminProgramService.programAdminData.programDetails.enrollmentStartDate = this.dateUtils.formatDate(this.todayDate);
            this.accountAdminProgramService.programAdminData.programDetails.enrollmentEndDate = this.dateUtils.formatDate(this.endOfYearDate);
            this.accountAdminProgramService.updateUI(programAdminData);
            this.programManagers = programAdminData.programManagers.map((manager) => ({
                name: `${`${manager.firstName.toUpperCase()} ${manager.lastName.toUpperCase()}`} `,
                value: manager.userId
            }));
        });

        this.duplicateProgramName$.subscribe((val: boolean) => {
            this.isDuplicateProgramName = val;
            this.programNameFieldErrorMessage = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_NAME_EXISTS');
        });

        this.programCreateForm.controls.name.valueChanges.subscribe(() => {
            this.isProgramNameFieldValid = this.programCreateForm.controls.name.pristine ||
                                            (this.programCreateForm.controls.name.dirty &&
                                            this.programCreateForm.controls.name.valid &&
                                            this.programCreateForm.controls.name.value.length > 0);
            this.programNameFieldErrorMessage = '';
        });
    }

    setProgramDate(programDates: DateRange) {
        const programStartDate = (programDates.startDate) ? startOfDay(programDates.startDate).getTime() : (new Date()).setMonth(new Date().getMonth());
        const programEndDate = (programDates.endDate) ? endOfDay(programDates.endDate).getTime() : (new Date()).setMonth(new Date().getMonth());
        this.programDate$.next({
            startDate: programStartDate,
            endDate: programEndDate
        });
        this.programEnrollmentDateUtilsService.setProgramPeriod(
            programDates,
            this.programDate$,
            this.accountAdminProgramService.programAdminData,
            this.enrollmentPeriodErrorValidationResult,
            this.locatorPeriodErrorValidationResult
        );
    }

    setEnrollmentDate(enrollmentDates: DateRange) {
        this.enrollmentDate$.next({
            startDate: enrollmentDates.startDate,
            endDate: enrollmentDates.endDate
        });
        this.programEnrollmentDateUtilsService.setEnrollmentPeriod(
            enrollmentDates,
            this.programDate$,
            this.accountAdminProgramService,
            this.enrollmentPeriodErrorValidationResult
        );
    }

    setLocatorDate(locatorDates: DateRange) {
        this.isLocatorDateSet = ((typeof locatorDates.startDate === 'number' && typeof locatorDates.endDate === 'number') || (typeof locatorDates.startDate === 'undefined' && typeof locatorDates.endDate === 'undefined'));
        this.locatorDate$.next({
            startDate: locatorDates.startDate,
            endDate: locatorDates.endDate
        });
        this.programEnrollmentDateUtilsService.setLocatorPeriod(
            locatorDates,
            this.programDate$,
            this.accountAdminProgramService,
            this.locatorPeriodErrorValidationResult
        );
    }

    onSubmit(event: UntypedFormGroup) {
        if (!this.isFormvalid(this.accountAdminProgramService.programAdminData.programDetails)) {
            return;
        }
        this.toastService.remove(this.bannerType);
        this.isRequesting = true;
        this.accountAdminApiService.createAccountAdminProgramDetails(this.accountAdminProgramService.getProgramAdminPostData(event.value)).subscribe((response) => {
            this.isRequesting = false;
            this.accountAdminProgramService.smoothScrollToTop();
            if (response.id) {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_CREATE_SUCCESS', { programName: response.name }),
                    theme: 'success',
                    id: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
            }
            this.buttonTimer = setTimeout(() => this.router.navigate(['/Admin/Program/search-program/search']), AppConstants.timeOutNavigation);
        },
        (err) => {
            this.duplicateProgramName$.next(true);
            this.programCreateForm.setErrors({ incorrect: true });
            this.isRequesting = false;
            this.accountAdminProgramService.smoothScrollToTop();
            this.toastService.add({
                content: `Error Name: ${err.error.message}. \n Detail(s): ${err.error?.details?.toString()}`,
                theme: 'error',
                id: this.bannerType,
                closeable: true,
                autoClose: true
            });
        });
    }

    onCancel() {
        this.router.navigate(['/Admin/Program/search-program/search']);
    }

    areAllMandatoryFieldsFilled(): boolean {
        const program = this.accountAdminProgramService.programAdminData.programDetails;

        return Boolean(program.programStartDate) && Boolean(program.programEndDate) && Boolean(program.enrollmentStartDate) && Boolean(program.enrollmentEndDate) && Boolean(program.vertical?.id) && Boolean(program.brand?.id) && Boolean(program.eligibilityCriteria?.id);
    }

    ngOnDestroy() {
        if (this.buttonTimer) {
            clearTimeout(this.buttonTimer);
        }
    }

    private getAccountAdminProgramCreateData(): Observable<AccountAdminProgramEditData> {
        const programDetails: Partial<ProgramDetails> = {};

        return forkJoin([
            of(programDetails),
            this.accountAdminApiService.getAccountAdminProgramEligibilityCriteria(),
            this.accountAdminApiService.getAccountAdminProgramBrands(),
            this.accountAdminApiService.getAccountAdminProgramsLocatorIcon(),
            this.accountAdminApiService.getAccountAdminProgramsManagers()
        ]).pipe(
            map((res) => this.accountAdminProgramService.mergeProgramAdminData(res))
        );
    }

    private isFormvalid(program: ProgramDetails): boolean {
        if (!program.programStartDate || !program.programEndDate) {
            this.programDateErrorMessage$.next(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.MANDATORY_DATE_PROGRAM'));
        }
        if (!program.enrollmentStartDate || !program.enrollmentEndDate) {
            this.enrollmentDateErrorMessage$.next(this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.MANDATORY_DATE_ENROLLMENT'));
        }
        this.verticalError$.next(Boolean(!program.vertical?.id.length));
        this.brandsError$.next(Boolean(!program.brand?.id));
        this.eligibilityCriteraError$.next(Boolean(!program.eligibilityCriteria?.id));

        return this.areAllMandatoryFieldsFilled();
    }
}
