import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-create-account-header',
    templateUrl: './createAccountHeader.component.html',
    styleUrls: ['./createAccountHeader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateAccountHeaderComponent {
    @Input() cancelButtonText: string;
    @Input() cancelButtonDisabled?: boolean;
    @Input() title?: string;
    @Input() hideCancelButton?: boolean;

    @Output() cancelFunction = new EventEmitter<boolean>();
    @Output() logoFunction = new EventEmitter<boolean>();

    logoClickFunction() {
        this.logoFunction.emit();
    }

    cancelClickFunction() {
        this.cancelFunction.emit();
    }
}
