<div class="hvac-cp-layout-container">
    <div class="hvac-cp-top">
        <div class="hvac-content-container">
            <ng-content select="[top]"></ng-content>
            <hvac-connected-portal-navigation></hvac-connected-portal-navigation>
        </div>
    </div>
    <div class="hvac-cp-bottom">
        <div class="hvac-content-container">
            <ng-content select="[bottom]"></ng-content>
        </div>
    </div>

    <ng-content select="[modal]"></ng-content>
</div>
