<section>
    <h1 class="hvac-h2 hvac-rhythm-5">{{'PROFILE.PROFILE' | translate}}</h1>
    <form [formGroup]="profileForm">
        <div class="hvac-row">
            <div class="hvac-col-xs-12 hvac-col-md-6 hvac-profile-col">
                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.userName"
                        label="{{'GENERAL.LABELS.USER_NAME' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.myHVACpin"
                        label="{{'GENERAL.LABELS.HVAC_PIN' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.firstName"
                        label="{{'GENERAL.LABELS.FIRST_NAME' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.lastName"
                        label="{{'GENERAL.LABELS.LAST_NAME' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.phone"
                        label="{{'GENERAL.LABELS.PHONE' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.cellPhone"
                        label="{{'GENERAL.LABELS.CELL_PHONE' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.email"
                        label="{{'GENERAL.LABELS.EMAIL' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.fax" label="{{'GENERAL.LABELS.FAX' | translate}}">
                    </utc-input>
                </div>
            </div>

            <div class="hvac-col-xs-12 hvac-col-md-6">
                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.address1"
                        label="{{'GENERAL.LABELS.ADDRESS_1' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.address2"
                        label="{{'GENERAL.LABELS.ADDRESS_2' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.city" label="{{'GENERAL.LABELS.CITY' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-select [group]="profileForm" control="country" [selectId]="'selectcountry'"
                        [defaultOption]="'GENERAL.LABELS.COUNTRY' | translate" [options]="(countryOptionData$ | async)!">
                    </utc-select>
                </div>

                <div class="hvac-form-field">
                    <utc-select [group]="profileForm" control="state" [selectId]="'selectState'"
                        [defaultOption]="'GENERAL.LABELS.STATE' | translate" [options]="(stateOptionData$ | async)!">
                    </utc-select>
                </div>

                <div class="hvac-form-field">
                    <utc-input [inputControl]="profileForm.controls.zip" label="{{'GENERAL.LABELS.ZIP' | translate}}">
                    </utc-input>
                </div>
            </div>
        </div>
    </form>
</section>

<div class="hvac-divider"></div>

<div class="hvac-profile-footer hvac-p">
    <a routerLink="/change-password" class="hvac-text-link hvac-p-sm">{{'GENERAL.LABELS.CHANGE_PASSWORD' |
        translate}}</a>
    <button hvacButton="primary" [disabled]="!profileForm.valid || !profileForm.dirty || apiRequestActive" (click)="updateProfileInfo()"
        class='hvac-email-validation-submit-button'>
        <span>{{ 'USER_VALIDATION.SUBMIT'
            | translate }}</span>
        <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
    </button>
</div>

<div class="hvac-divider"></div>

<!-- TODO: get last login date -->
<!-- <p class="hvac-subtext">{{ 'PROFILE.LAST_LOGIN_DATE' | translate}}: {{dateService.formatDate(user?.lastLoginDate) ||
    '--'}}</p> -->
<hvac-email-validation *ngIf="enableEmailVerification$ | async"
    [requestEmail]="profileForm.controls.email.value"
    (emailVerification)="emailVerification($event)"
    (cancel)="cancelValidation($event)">
</hvac-email-validation>
