import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { BehaviorSubject } from 'rxjs';
import { ConnectedPortalAdvancedSearchCustomerProperties } from 'private/app/models/connected-portal-advanced-search.model';

@Component({
    selector: 'hvac-connected-portal-advanced-search-result',
    templateUrl: './connected-portal-advanced-search-result.component.html',
    styleUrls: ['./connected-portal-advanced-search-result.component.scss']
})
export class ConnectedPortalAdvancedSearchResultComponent extends BaseComponent {
    @Input() customers?: ConnectedPortalAdvancedSearchCustomerProperties[];
    @Input() controlSerialNo?: string;
    @Input() totalPages?: number;
    @Input() currentPage?: number;
    @Input() itemCount?: number;
    @Input() customerResult$: BehaviorSubject<boolean>;
    @Input() isLoading$: BehaviorSubject<boolean>;
    @Output() exportPropertiesEvent = new EventEmitter<null>();
    @Output() pageChangeEvent = new EventEmitter<number>();

    constructor() {
        super();
    }

    handleExportProperties() {
        this.exportPropertiesEvent.emit(null);
    }

    handlePageChange(page: number) {
        this.pageChangeEvent.emit(page);
    }

    trackByItem(_index: number, item: ConnectedPortalAdvancedSearchCustomerProperties) {
        return item.id;
    }
}
