<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
    </div>

    <div bottom>
        <div class="hvac-cp-card">
            <div class="hvac-cp-search">
                <hvac-search-bar
                    [label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.DEALER_SEARCH' | translate"
                    [queryControl]="queryControl"
                    [placeholder]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.SEARCH_PLACEHOLDER' | translate"
                    (onSearchSubmit)="handleSearchSubmit($event)"
                    (onSearchReset)="handleSearchReset()"></hvac-search-bar>
            </div>

            <hvac-table [responsive]="true" theme="zebra">
                <thead>
                    <tr>
                        <th>{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.HVPID' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.NAME' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CUSTOMERS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CONNECTED_CONTROLS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.QUEUED_CONTROLS' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody [ngClass]="{ 'hvac-cp-empty-list' : dealers.length === 0 }">
                    <tr *ngFor="let dealer of dealers">
                        <td [attr.data-label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.HVPID' | translate">{{ dealer.id }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.NAME' | translate">{{ dealer.name }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CUSTOMERS' | translate">{{ dealer.customerCount }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.CONNECTED_CONTROLS' | translate">{{ dealer.connectedControlCount }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.QUEUED_CONTROLS' | translate">{{ dealer.queuedControlCount }}</td>
                        <td>
                            <div class="hvac-cp-details-link-wrapper">
                                <a *ngIf="dealer.id"
                                    class="hvac-cp-details-link"
                                    hvacButton="default"
                                    size="small"
                                    [routerLink]="['../../../dealers', dealer.id]">{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.DETAILS' | translate }}</a>
                            </div>
                        </td>
                    </tr>

                    <ng-container *ngIf="!isLoading && dealers?.length === 0">
                        <tr>
                            <td colspan="6" [align]="'center'">
                                <div class="hvac-fw-bd">{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.EMPTY_MESSAGE' | translate }}</div>
                            </td>
                        </tr>
                    </ng-container>

                    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
                </tbody>
            </hvac-table>

            <div class="hvac-cp-list-footer">
                <div class="hvac-cp-list-footer-pagination">
                    <hvac-pagination
                        *ngIf="totalPages > 1"
                        [totalPages]="totalPages"
                        [currentPage]="currentPage"
                        (pageChange)="handlePageChange($event)">
                    </hvac-pagination>
                </div>

                <div class="hvac-cp-list-footer-export">
                    <button class="hvac-cp-action-button" hvacButton="primary" (click)="handleExportProperties()" [disabled]="dealers.length === 0">
                        {{ 'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.EXPORT_DEALER_LIST' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</hvac-connected-portal-layout-container>
