<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-opportunties-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation
                    *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>

<section class="hvac-section-container" *ngIf="(fadData$ | async) as fadData">
    <div class="hvac-row hvac-rhythm-4">
        <div class="hvac-col-xs-12">
            <div class="fad-title">
                {{year}} {{"CONNECTED_PORTAL.FAD.FAD_TITLE"| translate}}
            </div>
        </div>
        <div class="hvac-zones-header hvac-col-xs-12">
                <div class="hvac-zone-status-date hvac-p-sm">
                    {{"CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF"| translate}}

                    {{ fadData.lastUpdatedTime | dateTimeTz }} (Updates Daily)
                </div>
        </div>
    </div>
    <div class="hvac-row hvac-rhythm-2">
        <div class="hvac-col-xs-12 hvac-col-md-3">
            <div class="label-card">
                <div class="label-card-head">
                    {{"CONNECTED_PORTAL.FAD.QUAT_METRICS"| translate}}
                </div>
                <div class="label-card-body">
                    <div class="label-card-title">
                        {{"CONNECTED_PORTAL.FAD.REPORT_DOWNLOADS"| translate}}
                    </div>
                    <div class="label-card-title">
                        {{"CONNECTED_PORTAL.FAD.CP_LOGIN"| translate}}
                    </div>

                </div>
            </div>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-9">
            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-md-3 progress-card-list">
                    <div class="card">
                        <div class="card-head">
                            {{"CONNECTED_PORTAL.FAD.Q1"| translate}}
                        </div>
                        <div class="card-body">

                            <hvac-progress-bar [quarterValue]="fadData.Q1.countOpportunityReportDownloads"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Q1.countConnectedPortalLogins"></hvac-progress-bar>

                        </div>
                    </div>
                </div>
                <div class="hvac-col-xs-12 hvac-col-md-3 progress-card-list">
                    <div class="card">
                        <div class="card-head">
                            {{"CONNECTED_PORTAL.FAD.Q2"| translate}}
                        </div>
                        <div class="card-body">
                            <hvac-progress-bar [quarterValue]="fadData.Q2.countOpportunityReportDownloads"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Q2.countConnectedPortalLogins"></hvac-progress-bar>

                        </div>
                    </div>
                </div>
                <div class="hvac-col-xs-12 hvac-col-md-3 progress-card-list">
                    <div class="card">
                        <div class="card-head">
                            {{"CONNECTED_PORTAL.FAD.Q3"| translate}}
                        </div>
                        <div class="card-body">
                            <hvac-progress-bar [quarterValue]="fadData.Q3.countOpportunityReportDownloads"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Q3.countConnectedPortalLogins"></hvac-progress-bar>

                        </div>
                    </div>
                </div>
                <div class="hvac-col-xs-12 hvac-col-md-3 progress-card-list">
                    <div class="card">
                        <div class="card-head">
                            {{"CONNECTED_PORTAL.FAD.Q4"| translate}}
                        </div>
                        <div class="card-body">
                            <hvac-progress-bar [quarterValue]="fadData.Q4.countOpportunityReportDownloads"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Q4.countConnectedPortalLogins"></hvac-progress-bar>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
     <hr>
    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-md-3 hvac-rhythm-2">
            <div class="label-card">
                <div class="label-card-head">
                    {{"CONNECTED_PORTAL.FAD.ANNUAL_METRICS"| translate}}
                </div>
                <div class="label-card-body">
                    <div class="label-card-title controls-connected">
                        {{"CONNECTED_PORTAL.FAD.CONTROLS_CONNECTED"| translate}}
                    </div>
                    <div class="label-card-title intelli-control">
                        {{"CONNECTED_PORTAL.FAD.INTELLI_CONTROL"| translate}}<span class="progress-asterick">*</span>
                    </div>
                    <div class="label-card-title runtime-report">
                        {{"CONNECTED_PORTAL.FAD.RUNTIME_REPORTS"| translate}}<span class="progress-asterick">*</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="hvac-col-xs-12 hvac-col-md-9 hvac-rhythm-2">
            <div class="hvac-row">
                <div class="hvac-col-xs-12">
                    <div class="annual-card">
                        <div class="annual-card-body">
                            <hvac-progress-bar [quarterValue]="fadData.Annual.connectedControlPercent" [formatter]="'percent'"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Annual.inteliSenseControlPinLinkedPercent" [formatter]="'percent'"></hvac-progress-bar>
                            <br>
                            <hvac-progress-bar [quarterValue]="fadData.Annual.countRuntimeReportsDownloaded" [quarterLimit]="10"></hvac-progress-bar>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="hvac-col-xs-12 hvac-col-md-12 bonus-text">
            <div>
                <span class="progress-asterick">*</span>{{"CONNECTED_PORTAL.FAD.FAD_BONUS"| translate}}
            </div>

        </div>
    </div>
</section>

    </div>
</hvac-connected-portal-layout-container>
