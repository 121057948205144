import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-tab-navigation-footer',
    templateUrl: './tabNavigationFooter.component.html',
    styleUrls: ['./tabNavigationFooter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabNavigationFooterComponent {
    @Input() numberOfSteps: number;
    @Input() currentStep: number;
    @Input() continueText: string;
    @Input() backText: string;
    @Input() skipText: string;
    @Input() continueButtonDisabled: boolean;
    @Input() backButtonDisabled: boolean;
    @Input() skipButtonDisabled: boolean;

    @Output() continueButtonFunction: EventEmitter<Event> = new EventEmitter();
    @Output() backButtonFunction: EventEmitter<Event> = new EventEmitter();
    @Output() skipButtonFunction: EventEmitter<Event> = new EventEmitter();

    continueButtonClick($event: Event) {
        this.continueButtonFunction.emit($event);
    }

    backButtonClick($event: Event) {
        this.backButtonFunction.emit($event);
    }

    skipButtonClick($event: Event) {
        this.skipButtonFunction.emit($event);
    }

    stepCounter(i: number) {
        return new Array(i);
    }
}

