<div class="hvac-wall-control-program-settings hvac-row">
    <div class="hvac-col-xs-12">
        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.PROGRAM_SETTINGS' | translate}}</h2>
        <div class="hvac-row">
            <div class="hvac-col-xs-12">
                <hvac-table [responsive]="true">
                    <thead>
                        <tr>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.ACTIVITY' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.STATUS' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.TIME' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.WEEKLY_ACTIVITY' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.HEAT' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.COOL' | translate}} </td>
                            <td> {{'CONNECTED_PORTAL.WALL_CONTROL.FAN' | translate}} </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let activity of data.activities" class="hvac-cp-program">
                            <ng-container *ngIf="activity?.name !== 'manual'; else holdSettings">
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ACTIVITY' | translate"
                                    class="hvac-h4 hvac-fw-sb">{{activity?.name | titlecase}}</td>
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.STATUS' | translate"
                                    class="hvac-h4 hvac-fw-sb">
                                    <hvac-data-block style="text-align: center;"
                                        [status]="(data.activeActivityTime | number) === (activity?.time?.split(':')?.join('') | number) && !data.isHoldOn | boolToStatus"
                                        width="30px"> {{ (data.activeActivityTime | number) ===
                                        (activity?.time?.split(':')?.join('') | number) && !data.isHoldOn ?
                                        ('CONNECTED_PORTAL.WALL_CONTROL.ON' | translate) :
                                        ('CONNECTED_PORTAL.WALL_CONTROL.OFF' | translate ) }} </hvac-data-block>
                                </td>
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.TIME' | translate">
                                    {{activity?.time || '' | militaryToStandard}}</td>
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.WEEKLY_ACTIVITY' | translate"
                                    class="hvac-h4 hvac-fw-sb">
                                    <div class="hvac-cp-week">
                                        <hvac-data-block class="hvac-cp-day"
                                            [status]="day.enabled === 'on' | boolToStatus"
                                            *ngFor="let day of activity?.days"> {{day.dayOfWeek[0]}}
                                        </hvac-data-block>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-template #holdSettings>
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ACTIVITY' | translate"
                                    class="hvac-h4 hvac-fw-sb">{{ 'CONNECTED_PORTAL.WALL_CONTROL.HOLD' | translate
                                    }}</td>
                                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.STATUS' | translate"
                                    class="hvac-h4 hvac-fw-sb">
                                    <hvac-data-block style="text-align: center;" [status]="data.isHoldOn | boolToStatus"
                                        width="30px" > {{ data.isHoldOn ? ('CONNECTED_PORTAL.WALL_CONTROL.ON' |
                                        translate) : ('CONNECTED_PORTAL.WALL_CONTROL.OFF' | translate ) }}
                                    </hvac-data-block>
                                </td>
                                <td colspan="2"
                                    [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.TIME' | translate"> {{
                                    activity?.time && data.isHoldOn ? (('CONNECTED_PORTAL.WALL_CONTROL.HOLD_UNTIL' |
                                    translate) + ' ' + (activity?.time || '' | militaryToStandard)) : '' }} </td>
                            </ng-template>
                            <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.HEAT' | translate">
                                {{activity?.heat | numberSuffix: CharSymbol.Fahrenheit}}</td>
                            <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.COOL' | translate">
                                {{activity?.cool | numberSuffix: CharSymbol.Fahrenheit}}</td>
                            <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.FAN' | translate">
                                {{activity?.fan | titlecase}}</td>
                        </tr>
                    </tbody>
                </hvac-table>
            </div>
        </div>
    </div>
</div>
