import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { Bookmark, BookmarkPostData } from 'private/app/store/bookmarks.actions';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BookmarkService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) { }

    getBookmarks() {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<Array<Bookmark>>(environment.api.bookmarks, { ...options })),
            catchError((err: HttpErrorResponse) => {
                if (err.error.status === 404) {
                    return of([]);
                }
                throw err;
            })
        );
    }

    addBookmarks(bookmark: BookmarkPostData) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<Bookmark>(environment.api.bookmarks, bookmark, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    deleteBookmarks(id: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.delete<string>(`${environment.api.bookmarks}/${id}`,
                {
                    ...options,
                    responseType: 'json'
                })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
