import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'private/app/components/components.module';
import { IncentivesItemComponent } from './components/incentives/incentives-item.component';
import { IncentivesComponent } from './components/incentives/incentives.component';
import { LoginCreateAccountComponent } from './components/login-create-account/login-create-account.component';
import { LoginHeroComponent } from './components/login-hero/login-hero.component';
import { PersonasItemComponent } from './components/personas/personas-item.component';
import { PersonasComponent } from './components/personas/personas.component';
import { LoginComponent } from './login.component';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ComponentsModule,
        TranslateModule,
        SafePipeModule
    ],
    declarations: [
        IncentivesComponent,
        IncentivesItemComponent,
        LoginComponent,
        LoginCreateAccountComponent,
        LoginHeroComponent,
        PersonasComponent,
        PersonasItemComponent
    ]
})
export class LoginModule {}
