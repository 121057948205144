export interface CompanyMetadata {
    selected: boolean;
}

export class CompaniesTable {
    public companyName: string;
    private displayedCompaniesMap: Map<string, CompanyMetadata | undefined>;
    private companiesMap: Map<string, CompanyMetadata | undefined>;
    private companiesCount: number;
    private crntPageNum: number;
    private pageSize: number;
    private currentCompanyNames: string[];
    private initialCompanyNames: string[];

    constructor(companyName: string, companyNames: string[], pageSize: number) {
        this.currentCompanyNames = [...companyNames];
        this.initialCompanyNames = [...companyNames];
        this.pageSize = pageSize;
        this.companyName = companyName;
        this.assignCompaniesList(companyNames);
    }

    get crntPageCompanyNames() {
        return Array.from(this.displayedCompaniesMap.keys());
    }

    get crntPageCompanies() {
        return this.displayedCompaniesMap;
    }

    get pagesCount(): number {
        return Math.ceil(this.totalCompaniesCount / this.pageSize);
    }

    get crntPgNum(): number {
        return this.crntPageNum;
    }

    get pgSize(): number {
        return this.pageSize;
    }

    get totalCompaniesCount(): number {
        return this.companiesCount;
    }

    assignCompaniesList(companies: string[]): void {
        this.companiesMap = new Map();
        this.displayedCompaniesMap = new Map();
        companies.forEach((company: string, index: number) => {
            this.companiesMap.set(company, this.getUnSelectedItemFormat());

            if (index < this.pageSize) {
                this.displayedCompaniesMap.set(company, this.getUnSelectedItemFormat());
            }
        });
        this.crntPageNum = 1;
        this.companiesCount = companies.length;
        this.showCompaniesAtPageNum(1);
    }

    getSelectedCompanies(): string[] {
        const selectedCompanies: string[] = [];

        for (const [key, value] of this.companiesMap.entries()) {
            if (value && value.selected) {
                selectedCompanies.push(key);
            }
        }

        return selectedCompanies;
    }

    addCompany(companyName: string, isMultipleAddition: boolean): void {
        if (this.companiesMap.get(companyName)) {
            return;
        }

        this.companiesMap.set(companyName, this.getUnSelectedItemFormat());
        this.companiesCount += 1;
        this.showCompaniesAtPageNum(this.crntPageNum);

        if (!isMultipleAddition) {
            this.showCompaniesAtPageNum(this.crntPageNum);
        }

        this.currentCompanyNames.push(companyName);
    }

    deleteCompany(companyName: string, isMultipleDeletion: boolean, preserveOrder: boolean): void {
        if (!this.companiesMap.get(companyName)) {
            return;
        }

        if (preserveOrder) {
            // eslint-disable-next-line no-undefined
            this.companiesMap.set(companyName, undefined);
        }
        else {
            this.companiesMap.delete(companyName);
        }

        this.companiesCount -= 1;

        if (!isMultipleDeletion) {
            this.showCompaniesAtPageNum(this.crntPageNum);
            this.currentCompanyNames = this.currentCompanyNames.filter((name) => name !== companyName);
        }
    }

    selectCompany(companyName: string, isChecked: boolean) {
        const value = isChecked ? this.getSelectedItemFormat() : this.getUnSelectedItemFormat();

        this.companiesMap.set(companyName, value);

        if (this.displayedCompaniesMap.get(companyName)) {
            this.displayedCompaniesMap.set(companyName, value);
        }
    }

    selectAllCompanies(): void {
        for (const key of this.companiesMap.keys()) {
            this.companiesMap.set(key, this.getSelectedItemFormat());
        }

        for (const key of this.displayedCompaniesMap.keys()) {
            this.displayedCompaniesMap.set(key, this.getSelectedItemFormat());
        }
    }

    removeCompanies(companies: string[], preserveOrder: boolean): void {
        companies.forEach((company) => {
            this.deleteCompany(company, true, preserveOrder);
        });

        this.showCompaniesAtPageNum(this.crntPgNum);
        this.currentCompanyNames = this.getRemainingCompaniesList(this.currentCompanyNames, companies);
    }

    refreshTableDisplay() {
        this.showCompaniesAtPageNum(this.crntPageNum);
    }

    showCompaniesAtPageNum(pageNum: number): void {
        const pageSize = this.pageSize;
        const rltnshpsAtCrntPage: Map<string, CompanyMetadata| undefined> = new Map();

        const requiredStartingItemIndex = (pageNum - 1) * pageSize;
        let crntItemIndex: number = 0;

        for (const [key, value] of this.companiesMap.entries()) {
            if (!(rltnshpsAtCrntPage.size < pageSize)) {
                break;
            }

            if (value) {
                if (crntItemIndex < requiredStartingItemIndex) {
                    crntItemIndex += 1;
                }
                else {
                    rltnshpsAtCrntPage.set(key, { ...value });
                }
            }
        }

        this.crntPageNum = pageNum;
        this.displayedCompaniesMap = rltnshpsAtCrntPage;
    }

    searchCompanies(searchTerm = '') {
        if (!searchTerm) {
            this.assignCompaniesList(this.initialCompanyNames);

            return;
        }

        this.assignCompaniesList(this.currentCompanyNames.filter((companyName) => companyName.toLowerCase().includes(searchTerm.toLowerCase())));
    }

    setInitalList(list: string[]) {
        this.initialCompanyNames = [...list];
    }

    getCurrentCompanyNames(): string[] {
        return Array.from(this.companiesMap.keys());
    }

    private getSelectedItemFormat(): { selected: boolean } {
        return { selected: true };
    }

    private getUnSelectedItemFormat(): { selected: boolean } {
        return { selected: false };
    }

    private getRemainingCompaniesList(originalList: string[], listToRemove: string[]): string[] {
        return this.performSetDifference(originalList, listToRemove);
    }

    private performSetDifference(itemsA: string[], itemsB: string[]): string[] {
        const mapB = new Map();

        itemsB.forEach((item) => mapB.set(item, true));

        return itemsA.filter((item) => !mapB.get(item));
    }
}
