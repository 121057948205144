<div class="hvac-wall-control-config-details">
    <div *ngIf="isDeviceConnected && (editConfigState$ | async) !== EditConfigState.Connected && isEditEnabled && isSaveEnabled" class="havc-edit-config-help-txt hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.TOOL_TIP' | translate }}</div>

    <hvac-toast [name]="editConfigConnectionToastOutlet"></hvac-toast>

    <ng-container *ngIf="(isLoading$ | async) as isLoading">
        <div class="hvac-config-loading-spinner" *ngIf="isLoading.loading; else content">
            <utc-loading-spinner variant="overlay">
                <ul *ngIf="isLoading.saveUpdate" class="hvac-loading-message-container">
                    <li class="hvac-loading-message hvac-label-sm"
                        *ngFor="let message of ('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.LOADING_MESSAGES' | translate)">
                        {{message}} </li>
                </ul>
            </utc-loading-spinner>
        </div>
    </ng-container>

    <ng-template #content>
        <ng-container *ngIf="(configData$ | async) as configData">
            <div class="hvac-cp-property-sub-head">
                <dt class="hvac-h4 hvac-fw-sb"> {{'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.SUB_HEADING.BASIC' |
                    translate}} </dt>
            </div>

            <hvac-product-property-list>
                <hvac-product-property-list-item
                    *ngFor="let prop of configData.configProps | keyvalue: ecobeeSortOrder | filterObjectArray : filterByConfigType : 'basic'">
                    <hvac-product-property-list-item-label> {{
                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.PROP_LABELS.' + prop.value.id | translate}}
                    </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>
                        <ng-container *ngIf="prop.value.readOnly || isEditEnabled === false; else editProp">
                            <utc-svg class="hvac-cp-warning-icon" src="assets/icons/info.svg"
                                *ngIf="prop.value.value === (CharSymbol.DoubleDash)"></utc-svg> {{ prop.value.value }}
                        </ng-container>
                        <ng-template #editProp>
                            <hvac-config-edit-prop *ngIf="prop.value.control && configFormGroup" [editProp]="prop.value"
                                [propFormControl]="prop.value.control"
                                [groupId]="prop.value.groupId"></hvac-config-edit-prop>
                        </ng-template>
                    </hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>

            <div class="hvac-cp-property-sub-head">
                <dt class="hvac-h4 hvac-fw-sb"> {{'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.SUB_HEADING.ADVANCED' |
                    translate}} </dt>
            </div>

            <hvac-product-property-list>
                <hvac-product-property-list-item
                    *ngFor="let prop of configData.configProps | keyvalue: ecobeeSortOrder | filterObjectArray : filterByConfigType : 'advanced'">
                    <hvac-product-property-list-item-label> {{
                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.PROP_LABELS.' + prop.value.id | translate}}
                    </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>
                        <ng-container *ngIf="prop.value.readOnly || isEditEnabled === false; else editProp">
                            <utc-svg class="hvac-cp-warning-icon" src="assets/icons/info.svg"
                                *ngIf="prop.value.value === (CharSymbol.DoubleDash)"></utc-svg> {{ prop.value.value }}
                        </ng-container>
                        <ng-template #editProp>
                            <span class="hvac-property-info-message"
                                *ngIf="prop.value.id === 'OUTDOOR_TEMP_LOCK' && (isCompressorOutdoorMinTempWarningShown$ | async)">
                                <utc-svg class="hvac-value-info-icon" src="assets/icons/info.svg"></utc-svg>{{
                                'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.VALUE_WARNINGS.OUTDOOR_TEMP_LOCK' |
                                translate}}</span>
                            <hvac-config-edit-prop *ngIf="prop.value.control && configFormGroup" [editProp]="prop.value"
                                [propFormControl]="prop.value.control"
                                [groupId]="prop.value.groupId"></hvac-config-edit-prop>
                        </ng-template>
                    </hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>
        </ng-container>

        <hvac-toast [name]="editConfigUpdateToastOutlet"></hvac-toast>

        <div *ngIf="isEditEnabled && configFormGroup" class="hvac-form-actions hvac-save-user-info">
            <div>
                <div *ngIf="isEcobeeConfigOutOfRange$ | async" class="hvac-save-user-info-message">
                    <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                    <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_OUT_OF_RANGE' | translate }}</span>
                </div>
                <div class="hvac-save-user-info-message" *ngIf="!isDeviceConnected; else deviceConnectInfoMsg">
                    <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                    <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_DEVICE_DISCONNECTED' | translate }}</span>
                </div>
                <ng-template #deviceConnectInfoMsg>
                    <div class="hvac-save-user-info-message">
                        <ng-container *ngIf="(editConfigState$ | async) === EditConfigState.Connected && isSaveEnabled">
                            <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                            <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_ENABLED' | translate }}</span>
                        </ng-container>

                        <ng-container  *ngIf="!isSaveEnabled">
                            <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                            <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_DISABLED' | translate }}</span>
                        </ng-container>
                    </div>
                </ng-template>
            </div>
            <div class="hvac-form-actions-group">
                <button class="hvac-form-actions-button"
                    [disabled]="((editConfigState$ | async) !== EditConfigState.Connected) || !isSaveEnabled || (configFormGroup.pristine) || configFormGroup.invalid || !isDeviceConnected"
                    hvacButton="default" size="small" (click)="resetFormGroups()">{{
                    'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.CANCEL' | translate }}</button>
                <button class="hvac-form-actions-button"
                    [disabled]="((editConfigState$ | async) !== EditConfigState.Connected) || !isSaveEnabled || (configFormGroup.pristine) || configFormGroup.invalid || !isDeviceConnected"
                    hvacButton="primary" size="small" (click)="openConfirmModal()">{{
                    'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.SAVE' | translate }}</button>
            </div>
        </div>
    </ng-template>
</div>
