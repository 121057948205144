import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PlaceHolderText } from 'private/app/models/account-admin-company.model';
import { CompanyCategoryType } from 'private/app/views/account-admin-company-details/company-query/company-query-configuration';
import { ServicesType } from 'private/app/views/create-company/business-information/business-information.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface InputErrorObject {
    'LENGTH'?: boolean;
    'REQUIRED'?: boolean;
}

export interface coordinateErrorObject {
    'VALUE'?: boolean;
    'REQUIRED'?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AccountAdminEditFormService {
    public placeHolderData$: BehaviorSubject<PlaceHolderText> = new BehaviorSubject<PlaceHolderText>({
        placeHolderPhone: '',
        placeHolderOffice: '',
        placeHolderMobile: '',
        placeHolderFax: '',
        placeHolderTollFree: '',
        placeHolderZipcode: {
            label: '',
            info: ''
        }
    });

    constructor(
        private translatePipe: TranslateService
    ) {
    }

    resetForm(companyDetailsForm: UntypedFormGroup) {
        companyDetailsForm.controls.companyName.setValue('');
        companyDetailsForm.controls.companyType.setValue('');
        companyDetailsForm.controls.companyCategory.setValue('');
        companyDetailsForm.controls.companyId.setValue('');
        companyDetailsForm.controls.services.setValue('');
        companyDetailsForm.controls.soldTo.setValue('');
        companyDetailsForm.controls.companyUrl.setValue('');
        companyDetailsForm.controls.parentCompany.setValue('');
        companyDetailsForm.controls.shipTo.setValue('');
        companyDetailsForm.controls.payer.setValue('');
        companyDetailsForm.controls.priceList.setValue('');
        companyDetailsForm.controls.priceGroup.setValue('');
        companyDetailsForm.controls.address1.setValue('');
        companyDetailsForm.controls.address2.setValue('');
        companyDetailsForm.controls.city.setValue('');
        companyDetailsForm.controls.state.setValue('');
        companyDetailsForm.controls.country.setValue('');
        companyDetailsForm.controls.postalCode.setValue('');
        companyDetailsForm.controls.phone.setValue('');
        companyDetailsForm.controls.tollFree.setValue('');
        companyDetailsForm.controls.mobile.setValue('');
        companyDetailsForm.controls.fax.setValue('');
        companyDetailsForm.controls.email.setValue('');
        companyDetailsForm.controls.rsm.setValue('');
        companyDetailsForm.controls.partRSM.setValue('');
        companyDetailsForm.controls.accountManagers.setValue('');
        companyDetailsForm.controls.partAM.setValue('');
        companyDetailsForm.controls.tsm.setValue('');
        companyDetailsForm.controls.notes.setValue('');
    }

    public isSoldToRequired(form: UntypedFormGroup): boolean {
        const soldToRequired = ((form.controls.companyCategory.value === CompanyCategoryType.DOMESTIC_DISTRIBUTOR || form.controls.companyCategory.value === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR) && form.controls.services.value.find((service: { name: ServicesType; }) => service.name === ServicesType.WarrantyDistributor));

        soldToRequired ? form.controls.soldTo.addValidators(Validators.required) : form.controls.soldTo.setValidators([]);
        form.controls.soldTo.updateValueAndValidity();

        return soldToRequired;
    }

    formInputPlaceHolder(countryCode: string) {
        if (countryCode === 'US') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_US.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_US.INFO')
                }
            });

            return;
        }
        if (countryCode === 'CA') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_CA.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_CA.INFO')
                }
            });

            return;
        }

        if (countryCode === 'MX') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_NUMBER'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_MX.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_MX.INFO')
                }
            });

            return;
        }

        this.placeHolderData$.next({
            placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_NUMBER'),
            placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER'),
            placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER'),
            placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER'),
            placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER'),
            placeHolderZipcode: { label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE') }
        });
    }
}

export function inputValidator(maxLength: number, isRequired: boolean): ValidatorFn {
    return (control: AbstractControl): InputErrorObject | null => {
        const errorObject: InputErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined' && controlValue !== null) {
            if (isRequired && controlValue.trim() === '') {
                errorObject.REQUIRED = true;
            }
            if (maxLength && controlValue.length > maxLength) {
                errorObject.LENGTH = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}

export function coordinateValidator(maxValue: number, minValue: number, isRequired: boolean): ValidatorFn {
    return (control: AbstractControl): coordinateErrorObject | null => {
        const errorObject: coordinateErrorObject = {};
        const controlValue = control.value;
        if (typeof controlValue !== 'undefined' && controlValue !== null) {
            if (isRequired && controlValue === '') {
                errorObject.REQUIRED = true;
            }
            if (!(controlValue >= minValue && controlValue <= maxValue)) {
                errorObject.VALUE = true;
            }
            if (Object.keys(errorObject).length !== 0) {
                return errorObject;
            }
        }

        return null;
    };
}
