import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CharSymbol } from 'common/enums/char-symbol';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { WallControlSystemInfo } from 'private/app/models/wall-control.model';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { DEVICE_TEMPERATURE_PROPERTIES } from 'private/app/views/connected-portal/constants';

type LabelValueProp = { label: string, value: unknown };
@Component({
    selector: 'hvac-wall-control-system-info',
    templateUrl: './wall-control-system-info.component.html',
    styleUrls: ['./wall-control-system-info.component.scss']
})
export class WallControlSystemInfoComponent implements OnChanges {
    @Input() data: WallControlSystemInfo;
    public systemData: LabelValueProp[];
    public zonePrefix: string;


    constructor(
        private translateService: TranslateService,
        private dateTimeTzPipe: DateTimeTzPipe,
        private numberSuffixPipe: NumberSuffixPipe
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.data?.firstChange) {
            this.zonePrefix = this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.ZONE_NAME_DEFAULT');
        }

        if (changes?.data?.currentValue) {
            this.systemData = this.formatData(this.data);
        }
    }

    formatData(data: WallControlSystemInfo) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            if (key === 'zoneBoards') {
                const zoneBoards = this.formatZones(value);
                acc.push(...zoneBoards);

                return acc;
            }

            const item = {
                label: this.getPropLabel(key),
                value: this.formatValue(key, value)
            };

            acc.push(item);

            return acc;
        }, [] as LabelValueProp[]);
    }

    formatZones(zones: []): LabelValueProp[] {
        return zones.map((zone, index) => Object.entries(zone).map(([propKey, propValue]) => ({
            label: `${this.zonePrefix} ${index + 1}: ${this.getPropLabel(propKey)}`,
            value: this.formatValue(propKey, propValue)
        }))).flat();
    }

    formatValue(propName: string, value: unknown) {
        if (DEVICE_TEMPERATURE_PROPERTIES.includes(propName)) {
            return this.numberSuffixPipe.transform(String(value), CharSymbol.Fahrenheit);
        }

        switch (propName) {
            case 'dateTime':
                return this.dateTimeTzPipe.transform(String(value));
            default:
                return value;
        }
    }

    getPropLabel(propName: string): string {
        const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_INFORMATION.PROPS.${propName}`;

        return this.translateService.instant(translationKey) || '';
    }
}
