<div class="hvac-cp-warranty-details-container" *ngIf="warrantyInfo">
    <div class="hvac-cp-warranty-general-section">
        <ng-container [ngTemplateOutlet]="warrantyDetailsSection" [ngTemplateOutletContext]="{warrantyDetails: warrantyInfo.generalSection}"></ng-container>
    </div>

    <div class="hvac-cp-warranty-coverd-parts-section">
        <div class="hvac-cp-warranty-coverd-parts-title">{{'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.TITLE' | translate}}</div>
        <div class="hvac-cp-warranty-coverd-parts">
            <ng-container [ngTemplateOutlet]="warrantyDetailsSection" [ngTemplateOutletContext]="{warrantyDetails: warrantyInfo.coveredParts}"></ng-container>
        </div>
    </div>

    <utc-loading-spinner *ngIf="isLoading"></utc-loading-spinner>
</div>

<ng-template #warrantyDetailsSection let-warrantyDetails="warrantyDetails">
    <div class="hvac-cp-warranty-detail" *ngFor="let detail of warrantyDetails">
        <h2 class="hvac-cp-warranty-detail-title">{{detail.title}}</h2>
        <div class="hvac-cp-warranty-detail-data" *ngFor="let data of detail.data">
            <div class="hvac-cp-warranty-detail-data-key hvac-p-sm hvac-fw-sb">{{ data.key | translate }}</div>
            <div class="hvac-cp-warranty-detail-data-value hvac-p-sm hvac-fw-rg">{{data.value}}</div>
        </div>
    </div>
</ng-template>


