<div class="hvac-opportunity-report-card">
    <div class="hvac-loader" *ngIf="isLoading; else content">
        <utc-loading-spinner></utc-loading-spinner>
    </div>

    <ng-template #content>
        <div class="hvac-opportunity-report-card-info">
            <h4 class="hvac-h4">{{ title }}</h4>
            <div class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARD.DESCRIPTION' | translate }}</div>
            <p class="hvac-opportunity-report-card-description hvac-p-sm hvac-fw-rg" [innerHTML]="description"></p>
        </div>
        <div class="hvac-opportunity-report-card-cta">
            <button [ngClass]="{ 'hvac-button-loading': isDownloading }" hvacButton="primary" size="small" (click)="handleButtonClick()">
                <ng-container *ngIf="isDownloading; else download">
                    {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARD.DOWNLOADING_REPORT' | translate }} <div class="hvac-button-spinner"></div>
                </ng-container>

                <ng-template #download>
                    {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARD.DOWNLOAD_REPORT' | translate }}
                </ng-template>
            </button>
        </div>
    </ng-template>
</div>
