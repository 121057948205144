import { Injectable } from '@angular/core';
import { OktaService } from 'common/services/okta/okta.service';
import { SystemType } from 'private/app/models/connected-product.model';
import { ConnectedSystem, DataSharing } from 'private/app/models/connected-system.model';
import { BehaviorSubject } from 'rxjs';
import { findSystemBySerialNo } from './utils';

export interface DataSharingOptions {
    systemConditions: boolean;
    equipmentActivity: boolean;
    systemMode: boolean;
    programSettings: boolean;
    alerts: boolean;
    notificationSettings: boolean;
    detailedStatus: boolean;
    systemInfo: boolean;
    controlConfiguration: boolean;
    humidityAndVentilation: boolean;
    historicalData: boolean;
    eventLog: boolean;
    editConfig: boolean;
    saveConfig: boolean;
    energyEvents?: boolean;
}

export const DEFAULT_DATA_SHARING_PERMISSIONS: DataSharingOptions = {
    systemConditions: false,
    equipmentActivity: false,
    systemMode: false,
    programSettings: false,
    alerts: false,
    notificationSettings: false,
    detailedStatus: false,
    systemInfo: false,
    controlConfiguration: false,
    humidityAndVentilation: false,
    historicalData: false,
    eventLog: false,
    editConfig: false,
    saveConfig: false,
    energyEvents: false
};

export const VIEW_STATUS_DATA_SHARING_PERMISSIONS: Partial<DataSharingOptions> = {
    systemConditions: true,
    equipmentActivity: true,
    systemMode: true,
    alerts: true,
    notificationSettings: true,
    detailedStatus: true,
    systemInfo: true,
    humidityAndVentilation: true,
    historicalData: true,
    eventLog: true,
    energyEvents: true
};

export const VIEW_CONFIG_DATA_SHARING_PERMISSIONS: Partial<DataSharingOptions> = {
    programSettings: true,
    controlConfiguration: true
};

export enum UserCompanyTypeCode {
    Distributor = 'DDT',
    Dealer = 'DCR',
    Factory = 'INT'
}

@Injectable({ providedIn: 'root' })
export class DataSharingService {
    public dataPoints$ = new BehaviorSubject<DataSharingOptions>(DEFAULT_DATA_SHARING_PERMISSIONS);

    constructor(private oktaService: OktaService) {}

    public setPermissions(dataSharing: DataSharing, systemType?: string) {
        let nextPermissions = DEFAULT_DATA_SHARING_PERMISSIONS;

        if (dataSharing.viewStatus === 'true') {
            nextPermissions = {
                ...nextPermissions,
                ...VIEW_STATUS_DATA_SHARING_PERMISSIONS
            };

            if (systemType === SystemType.ECOBEE) {
                nextPermissions = {
                    ...nextPermissions,
                    ...VIEW_CONFIG_DATA_SHARING_PERMISSIONS
                };
            }
        }

        if (dataSharing.viewConfig === 'true' && !(systemType === SystemType.ECOBEE)) {
            nextPermissions = {
                ...nextPermissions,
                ...VIEW_CONFIG_DATA_SHARING_PERMISSIONS
            };
        }

        if (dataSharing.editConfig === 'true') {
            nextPermissions = {
                ...nextPermissions,
                editConfig: true
            };

            if (this.getCompanyTypeCode() === UserCompanyTypeCode.Dealer) {
                nextPermissions = {
                    ...nextPermissions,
                    saveConfig: true
                };
            }
        }

        this.dataPoints$.next(nextPermissions);
    }

    public findCurrentSystem(systems: ConnectedSystem[], serialNo: string) {
        return findSystemBySerialNo(systems, serialNo);
    }

    public resetPermissions() {
        this.dataPoints$.next(DEFAULT_DATA_SHARING_PERMISSIONS);
    }

    private getCompanyTypeCode() {
        return this.oktaService.idToken$.value.claims?.idm_user_companies[0]?.companyType?.code;
    }
}
