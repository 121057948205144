import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Redirect } from 'common/models/auth';
import { HVAC_REDIRECT_KEY } from 'common/services/okta/okta-base.service';
import { OktaService } from 'common/services/okta/okta.service';
import { SendLastLogin } from 'common/store/account-status/account-status.actions';
import { CaptureSignInError, LogOut } from 'common/store/auth/auth.actions';
import { AuthState, LoggedIn } from 'common/store/auth/auth.state';
import { WINDOW } from 'common/window.provider';
import { PublicRoutesService } from 'private/app/services/public-routes.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, first, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CallbackComponent implements OnInit {
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;
    @Select(AuthState.loggedIn) loggedIn$: Observable<LoggedIn>;

    ngOnDestroy$ = new Subject();

    constructor(
        private readonly okta: OktaService,
        private readonly router: Router,
        @Inject(WINDOW) private readonly window: Window,
        private readonly store: Store,
        private readonly publicRoutes: PublicRoutesService
    ) {}

    ngOnInit() {
        combineLatest([this.loggedIn$, this.routerState$]).pipe(
            delay(0),
            first(),
            takeUntil(this.ngOnDestroy$)
        ).subscribe(([loggedIn, routerState]) => {
            if (loggedIn === LoggedIn.LoggedIn) {
                return this.store.dispatch(new Navigate(['/']));
            }

            if (routerState.url.indexOf('/secure-callback') >= 0) {
                this.okta.initiateAuthRedirect();

                return;
            }

            let redirectObject: Redirect = { path: '' } as Redirect;
            const redirect = this.window.sessionStorage.getItem(HVAC_REDIRECT_KEY);
            if (redirect) {
                redirectObject = JSON.parse(redirect);
            }

            return this.okta.parseToken(routerState).then(() => {
                this.store.dispatch(new SendLastLogin());
                this.router.navigate([`/${redirectObject.path}`],
                    redirectObject.query ? { queryParams: redirectObject.query } : {});
                this.window.sessionStorage.removeItem(HVAC_REDIRECT_KEY);
            })
                .catch((err) => {
                    if (err.message === 'login_required') {
                        // login_required errors are typically expected if the user isn't
                        // logged in. Don't add it to error banner but log to aid debugging.
                        // eslint-disable-next-line no-console
                        console.warn(err);
                    }
                    else {
                        this.store.dispatch(new CaptureSignInError(err));
                    }
                    if (err.message === 'access_denied') {
                        return;
                    }
                    this.store.dispatch(new LogOut());
                    if (this.publicRoutes.isPublicRoute(redirectObject.path)) {
                        this.router.navigate([`/${redirectObject.path}`],
                            redirectObject.query ? { queryParams: redirectObject.query } : {});
                    }
                    else {
                        this.store.dispatch(new Navigate(['/login']));
                    }
                });
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
