<ng-template [ngIf]="company">
    <form [formGroup]="companyDetailsForm">
        <ng-template #headerActions>
            <div class="hvac-account-admin-actions-wrapper">
                <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/results', {
                    queryParams: this.accountAdminService.lastQueryParams.value
                    }]" queryParamsHandling="merge" class="hvac-text-link hvac-user-details-back-to-result">
                    <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
                    <div class="hvac-back-text">{{ 'ACCOUNT_ADMIN.BACK_TO_SEARCH_RESULTS' | translate}}</div>
                </a>

                <button class="hvac-more-options" (click)="accountAdminCompanyUtilsService.toggleMenu()">
                    <utc-svg src="assets/icons/elipses.svg"></utc-svg>
                </button>

                <div class="hvac-dropdown-menu hvac-dropdown-menu-category" [ngClass]="{'hvac-dropdown-menu-expanded': accountAdminCompanyUtilsService.menuActive}">
                    <ol class="hvac-dropdown-menu-options">
                        <li *ngIf="company.isActive && isSameFamily && canEdit && adminAccess" class="hvac-dropdown-menu-option-item">
                            <a class="hvac-dropdown-menu-option" (click)="deactivateCompanyModal = true;">{{  'ACCOUNT_ADMIN.DEACTIVATE_COMPANY' | translate}}</a>
                        </li>
                        <li *ngIf="!company.isActive && isSameFamily && canEdit && adminAccess" class="hvac-dropdown-menu-option-item">
                            <a class="hvac-dropdown-menu-option" (click)="reactivateCompanyModal = true;">{{  'ACCOUNT_ADMIN.REACTIVATE_COMPANY' | translate}}</a>
                        </li>
                        <li *ngIf="company.companyType?.code === 'ZDCR' && canEdit && adminAccess" class="hvac-dropdown-menu-option-item">
                            <a [routerLink]="['/Admin/Admin-Tools/manage-dealer-locator', company.hvacCompanyId]"
                            [queryParams]="{ 'compName': company.name }"
                            [skipLocationChange]="false" class="hvac-dropdown-menu-option">{{
                            'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.MANAGE_LOCATOR_TITLE' | translate}}</a>
                        </li>
                        <li *ngIf="company.companyType?.code === 'ZDDT' && manageCountiesBrandsConditions(company.brands)" class="hvac-dropdown-menu-option-item">
                            <a href="#" class="hvac-dropdown-menu-option">{{  'ACCOUNT_ADMIN.MANAGE_COUNTIES' | translate}}</a>
                        </li>
                    </ol>
                </div>
            </div>
        </ng-template>
        <div class="hvac-account-admin-heading hvac-account-admin-heading-default">
            <hvac-content-jump-bar class="hvac-account-admin-jumpbar" [stickyHeader]="false"
                [possibleJumpLinks]="(accountAdminJumpBar$ | async) || []">
            </hvac-content-jump-bar>
            <div class="hvac-back-link-container-desktop">
                <ng-template [ngTemplateOutlet]="headerActions"></ng-template>
            </div>
        </div>
        <div class="hvac-back-link-container-mobile">
            <ng-template [ngTemplateOutlet]="headerActions"></ng-template>
        </div>
        <div class="hvac-company-edit-toast">
            <hvac-toast [name]="toastOutlet"></hvac-toast>
        </div>
        <h1 class="hvac-h3 havc-details-heading">{{ company.companyType?.name }} {{ 'ACCOUNT_ADMIN.INFORMATION' |
            translate}}</h1>
        <div class="hvac-row">
            <div class="hvac-row hvac-col-xs-12">
                <div
                    class="hvac-col-xs-12 hvac-col-lg-12 hvac-account-admin-details-col hvac-account-admin-details-section">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'ACCOUNT_ADMIN.COMPANY_NAME' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editName"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.name ||
                                        '--'}}
                                    </div>
                                    <ng-template #editName>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.companyName.dirty && !companyDetailsForm.controls.companyName.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_NAME' | translate"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyName?.edit"
                                            [max]="200">
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_CATEGORY' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editCategory"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.companyCategory.name || '--'}}</div>
                                    <ng-template #editCategory>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false"
                                            [inputControl]="companyDetailsForm.controls.companyCategory"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyCategory?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_TYPE' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editType"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.companyType?.name
                                        || '--'}}</div>
                                    <ng-template #editType>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyType"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyType?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_ID' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editId"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.hvacCompanyId || '--'}}
                                    </div>
                                    <ng-template #editId>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyId"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyId?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyUrl?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_URL' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editUrl"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.url ||
                                        '--'}}
                                    </div>
                                    <ng-template #editUrl>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.companyUrl.dirty && !companyDetailsForm.controls.companyUrl.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyUrl"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COMPANY_URL' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.companyUrl?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.parentCompany?.view">
                                    <div class=" hvac-col-xs-5 hvac-label">{{ 'COMPANY_INFO_FORM.PARENT_COMPANY' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editParentCompany"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.parentCompany? company.parentCompany.name : '--'}}
                                    </div>
                                    <ng-template #editParentCompany>
                                        <div
                                            class="hvac-col-xs hvac-account-admin-edit-value hvac-account-admin-parent">
                                            <hvac-autocomplete
                                                [inputControl]="$any(companyDetailsForm.controls.parentCompany)"
                                                show="nameValue" (onSelect)="onParentCompanyLookUp($event)"
                                                [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PARENT_COMPANY' | translate"
                                                [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.parentCompany?.edit"
                                                [data]="accountAdminEditService.parentCompany">
                                            </hvac-autocomplete>
                                        </div>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.services?.view && (servicesOptionData | async)?.length">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'COMPANY_INFO_FORM.SERVICES' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editServices"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ services || '--' }}
                                    </div>
                                    <ng-template #editServices>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            [group]="companyDetailsForm" control="services" [multiple]="true"
                                            [options]="(servicesOptionData | async ) || []"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.services?.edit"
                                            (onChange)="onServicesChange()">
                                        </utc-select>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.claimType?.view">
                                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required': editCompanyInformation }">{{ 'ACCOUNT_ADMIN.CLAIM_TYPE' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editClaimType"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ company.warrantyType
                                        ||
                                        '--' }}</div>
                                    <ng-template #editClaimType>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CLAIM_TYPE' | translate }}" shadow="none"
                                            [group]="companyDetailsForm" control="claimType"
                                            [options]="claimTypeData" [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.claimType?.edit || isCBP">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.soldTo?.view">
                                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required': (isSoldToRequiredInEdit$ | async) }">{{ 'ACCOUNT_ADMIN.SOLD_TO' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editsoldTo"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.soldToCustomerId
                                        ||
                                        '--'}}</div>
                                    <ng-template #editsoldTo>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.soldTo.dirty && !companyDetailsForm.controls.soldTo.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.soldTo"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_SOLD_TO_NUMBER' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.soldTo?.edit && !enableSoldTo"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.shipToNumber?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.SHIP_TO' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editshipTo"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.shipToCustomerId
                                        ||
                                        '--'}}</div>
                                    <ng-template #editshipTo>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value" [hideLabel]="false"
                                            inputId="havc-company-edit-shipTo"
                                            [error]="companyDetailsForm.controls.shipTo.dirty && !companyDetailsForm.controls.shipTo.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_SHIP_TO_NUMBER' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.shipToNumber?.edit"
                                            [inputControl]="companyDetailsForm.controls.shipTo" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.payer?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PAYER' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editPayer"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.payerCustomerId
                                        ||
                                        '--'}}</div>
                                    <ng-template #editPayer>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.payer.dirty && !companyDetailsForm.controls.payer.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ENTER_SAP_PAYER' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.payer?.edit"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.payer"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.priceList?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PRICE_LIST' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPriceList"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.priceListType ||
                                        '--'}}</div>
                                    <ng-template #editPriceList>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.priceList.dirty && !companyDetailsForm.controls.priceList.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.priceList"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICE_LIST' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.priceList?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.priceGroup?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PRICE_GROUP' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPriceGroup"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.priceGroup
                                        ||
                                        '--'}}</div>
                                    <ng-template #editPriceGroup>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.priceGroup.dirty && !companyDetailsForm.controls.priceGroup.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.priceGroup"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICE_GROUP' | translate"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.contactLic?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.CONT_LIC' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.contractorLicense
                                        || '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.contLic"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CONT_LIC' | translate"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                            </ul>

                            <div class="hvac-divider"></div>

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'ACCOUNT_ADMIN.ADDRESS1' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editAddress1"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.addressLine1 || '--'}}</div>
                                    <ng-template #editAddress1>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.address1.dirty && !companyDetailsForm.controls.address1.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS1' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.address1?.edit"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.address1"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editAddress2"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.addressLine2 || '--'}}</div>
                                    <ng-template #editAddress2>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.address2.dirty && !companyDetailsForm.controls.address2.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS2' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.address2?.edit"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.address2"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editCountry"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.country|| '--'}}</div>
                                    <ng-template #editCountry>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            [group]="companyDetailsForm" control="country"
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COUNTRY' | translate }}"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.country?.edit"
                                            [options]="(countryOptionData$ | async) || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{ 'ACCOUNT_ADMIN.STATE'
                                        | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editState"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ company.state
                                        || '--'}}</div>
                                    <ng-template #editState>

                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value "
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STATE' | translate }}"
                                            [group]="companyDetailsForm" control="state" [selectId]="'selectState'"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.state?.edit"
                                            [options]="(stateOptionData | async) || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{ 'ACCOUNT_ADMIN.CITY'
                                        | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editCity"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.city}}
                                    </div>
                                    <ng-template #editCity>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.city.dirty && !companyDetailsForm.controls.city.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.city"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CITY' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.city?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'ACCOUNT_ADMIN.ZIP_POSTAL_CODE' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPostal"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.postalCode
                                        || '--'}}</div>
                                    <ng-template #editPostal>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.postalCode.dirty && !companyDetailsForm.controls.postalCode.valid"
                                            [placeholder]="(accountAdminEditForm.placeHolderData$ | async)?.placeHolderZipcode?.label"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.postalCode?.edit"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.postalCode"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                            </ul>

                            <div class="hvac-divider"></div>

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PHONE' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editPhone"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{getMaskedNumber(company.phoneNumber) || '--'}}</div>
                                    <ng-template #editPhone>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.phone.dirty && !companyDetailsForm.controls.phone.valid"
                                            [hideLabel]="false"
                                            [placeholder]="(accountAdminEditForm.placeHolderData$ | async)?.placeHolderPhone"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.phone?.edit"
                                            [inputControl]="companyDetailsForm.controls.phone" type="text" [mask]=maskPhoneNumber inputId="office_number">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editMobile"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{getMaskedNumber(company.mobileNum)
                                        ||
                                        '--'}}
                                    </div>
                                    <ng-template #editMobile>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.mobile.dirty && !companyDetailsForm.controls.mobile.valid"
                                            [hideLabel]="false"
                                            [placeholder]="(accountAdminEditForm.placeHolderData$ | async)?.placeHolderPhone"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.mobile?.edit"
                                            [inputControl]="companyDetailsForm.controls.mobile" type="text" [mask]=maskPhoneNumber inputId="mobile_number">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.TOLL_FREE#' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editTollFree"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{getMaskedNumber(company.tollFreeNumber) || '--'}}
                                    </div>
                                    <ng-template #editTollFree>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.tollFree.dirty && !companyDetailsForm.controls.tollFree.valid"
                                            [hideLabel]="false"
                                            [placeholder]="(accountAdminEditForm.placeHolderData$ | async)?.placeHolderPhone"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.tollFree?.edit"
                                            [inputControl]="companyDetailsForm.controls.tollFree" type="text" [mask]=maskPhoneNumber inputId="toll_free_number">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FAX' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editFax"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{getMaskedNumber(company.faxNumber) ||
                                        '--'}}
                                    </div>
                                    <ng-template #editFax>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.fax.dirty && !companyDetailsForm.controls.fax.valid"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.fax"
                                            [placeholder]="(accountAdminEditForm.placeHolderData$ | async)?.placeHolderPhone"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.fax?.edit"
                                            type="text" [mask]=maskPhoneNumber inputId="fax_number">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMAIL_ADDRESS' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editEmail"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.emailAddress
                                        ||
                                        '--'}}</div>
                                    <ng-template #editEmail>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [error]="companyDetailsForm.controls.email.dirty && !companyDetailsForm.controls.email.valid"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.EMAIL' | translate"
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.email"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.email?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>

                        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                            <ul class="hvac-account-admin-detail-list"
                                *ngIf="(companyQuery.companyDetailsConfiguration | async)?.program && !editCompanyInformation">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ELITE_PROGRAM' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false"
                                            [inputControl]="companyDetailsForm.controls.elightFeatures" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.DEALER_TYPE' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.NATE_RED_SEA' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.GEOTHERMAL' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{'--' }}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.INVERTER' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FINANCE' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editForm"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ '--'}}</div>
                                    <ng-template #editForm>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.companyName"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <div class="hvac-divider"></div>
                            </ul>


                            <ul class="hvac-account-admin-detail-list"
                                *ngIf="(companyQuery.companyDetailsConfiguration | async)?.managerInformation?.show">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.RSM' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editRSM"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ rsms || '--' }}
                                    </div>
                                    <ng-template #editRSM>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value "
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.RSM' | translate }}"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.rsm?.edit"
                                            [group]="companyDetailsForm" control="rsm" [options]="formData?.rsms || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.managerInformation?.partRSM?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PARTS_RSM' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPRSM"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ prsms || '--' }}
                                    </div>
                                    <ng-template #editPRSM>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRSM' | translate }}"
                                            [group]="companyDetailsForm" control="partRSM"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.prsm?.edit"
                                            [options]="formData?.prsm || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ACCOUNT_MANAGER' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editAM"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ accountManagers ||
                                        '--'
                                        }}
                                    </div>
                                    <ng-template #editAM>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value "
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.AM' | translate }}"
                                            [group]="companyDetailsForm" control="accountManagers"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.accountManager?.edit"
                                            [options]="formData?.accountManagers || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.managerInformation?.partAM?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PARTS_ACCT_MANAGER' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPartAM"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ ptams || '--' }}
                                    </div>
                                    <ng-template #editPartAM>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value "
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PTAM' | translate }}"
                                            [group]="companyDetailsForm" control="partAM"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.partAccountManager?.edit"
                                            [options]="formData?.ptam || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.TSM' | translate}}</div>
                                    <div *ngIf="!editCompanyInformation;else editTSM"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ tsms || '--' }}
                                    </div>
                                    <ng-template #editTSM>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value "
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.TSM' | translate }}"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.tsm?.edit"
                                            [group]="companyDetailsForm" control="tsm" [options]="formData?.tsms || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.pricingAnalyst?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PRICING_ANAL' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPricing"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ pricingAnal || '--'
                                        }}
                                    </div>
                                    <ng-template #editPricing>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value " [multiple]="false"
                                            [group]="companyDetailsForm" control="pricingAnalyst"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.pricingAnalyst?.edit"
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.PRICING_ANALYST' | translate }}"
                                            [options]="formData?.pricingAnal || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <div class="hvac-divider"></div>
                            </ul>

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-authorized-brands hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label"
                                        [ngClass]="{ 'hvac-required': editCompanyInformation }">{{
                                        'ACCOUNT_ADMIN.BRANDS' | translate}}</div>
                                        <div *ngIf="company.brands.length < 1 && editCompanyInformation && (companyQuery.companyDetailsConfiguration | async)?.addBrands || false" >
                                            <span class="hvac-dot-warning"></span>
                                            <p class="hvac-select-brand-warning hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_EDIT.PLEASE_SELECT_A_BRAND' | translate }}</p>
                                        </div>
                                    <div class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        <hvac-authorized-brands [authorizedBrands]="company.brands || []"
                                            [editCompanyInformation]="editCompanyInformation && (companyQuery.companyDetailsConfiguration | async)?.addBrands || false"
                                            [company]="company"
                                            (selectedBrands)="accountAdminEditService.updateAuthorizedBrands($event)">
                                        </hvac-authorized-brands>
                                    </div>
                                </li>
                                <div class="hvac-divider"></div>

                            </ul>

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.STATUS' | translate}}</div>
                                    <div class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.isActive
                                        ? ('ACCOUNT_ADMIN.ACTIVE' | translate) : ('ACCOUNT_ADMIN.INACTIVE' |
                                        translate)}}
                                    </div>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.isPrimary">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.PRIMARY' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editPrimary"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.isPrimaryAccount
                                        || '--'}}
                                    </div>
                                    <ng-template #editPrimary>
                                        <div class="hvac-col-xs hvac-account-admin-edit-value">
                                            <utc-checkbox [checked]="company.isPrimaryAccount || false"
                                                [inputId]="'isPrimary'" [value]="'Is Primary'"
                                                [disabled]="company.isPrimaryAccount"
                                                (change)="accountAdminEditService.setAsPrimary($event)"
                                                [inputName]="'isPrimary'">
                                            </utc-checkbox>
                                        </div>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.legalEntity">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.LOCATION' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editLocation"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{company.legalEntity
                                        ?
                                        ('ACCOUNT_ADMIN.MAIN' | translate) : ('ACCOUNT_ADMIN.BRANCH' | translate)}}
                                    </div>
                                    <ng-template #editLocation>
                                        <div class="hvac-col-xs hvac-account-admin-edit-value">
                                            <hvac-radio-button [checked]="company.legalEntity || false"
                                                [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.locatorName?.edit"
                                                [inputName]="'location'">
                                                {{ 'ACCOUNT_ADMIN.MAIN' | translate }}
                                            </hvac-radio-button>
                                            <hvac-radio-button [checked]="!company.legalEntity || false"
                                                [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.locatorName?.edit"
                                                [inputName]="'location'">
                                                {{ 'ACCOUNT_ADMIN.BRANCH' | translate }}
                                            </hvac-radio-button>
                                        </div>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.flagForPOS?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FLAG_FOR_POS' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else flagForPOS"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ company.flagForPOS }}</div>
                                    <ng-template #flagForPOS>
                                        <div class="hvac-col-xs hvac-account-admin-edit-value">
                                            <utc-checkbox [checked]="company.flagForPOS || false"
                                                [inputId]="'flagForPOS'"
                                                [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.flagForPOS?.edit"
                                                (change)="accountAdminEditService.setflagForPOS($event)"
                                                [inputName]="'flagForPOS'">
                                            </utc-checkbox>
                                        </div>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.flagForDC?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FLAG_FOR_DC' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else flagForDC"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ company.flagForDC }}</div>
                                    <ng-template #flagForDC>
                                        <div class="hvac-col-xs hvac-account-admin-edit-value">
                                            <utc-checkbox [checked]="company.flagForDC || false"
                                                [inputId]="'flagForDC'"
                                                [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.flagForDC?.edit"
                                                (change)="accountAdminEditService.setflagForDC($event)"
                                                [inputName]="'flagForDC'">
                                            </utc-checkbox>
                                        </div>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.distributorLocator && dealerLocatorVisibility">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.DEALER_LOCATOR' |
                                        translate}}
                                    </div>
                                    <div
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ (company.dealerLocatorBrands | titlecase) || '--' }}</div>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.ownership?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.OWNERSHIP' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editownership"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{ company.ownershipType
                                        ||
                                        '--' }}</div>
                                    <ng-template #editownership>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            [group]="companyDetailsForm" control="ownership"
                                            [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.ownership?.edit"
                                            [options]="accountAdminService.ownershipOptions || []">
                                        </utc-select>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Parent?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.C10_PARENT' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editc10Parent"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.c10Parent
                                        ||
                                        '--'}}</div>
                                    <ng-template #editc10Parent>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.c10Parent"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.C10_PARENT' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Parent?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Child?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.C10_CHILD' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editc10Child"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.c10Child
                                        ||
                                        '--'}}</div>
                                    <ng-template #editc10Child>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.c10Child"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.C10_CHILD' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.c10Child?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.storeNumber?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.STORE_NUMBER' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editstoreNumber"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.storeNumber
                                        ||
                                        '--'}}</div>
                                    <ng-template #editstoreNumber>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false" [inputControl]="companyDetailsForm.controls.storeNumber"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STORE_NUMBER' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.storeNumber?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.showOnDistributorLocator?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.SHOW_ON_DISTRIBUTOR_LOCATOR' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editShowOnDistributorLocator" class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.includeInDistributorLocator}}</div>
                                    <ng-template #editShowOnDistributorLocator>
                                        <div class="hvac-col-xs hvac-account-admin-edit-value">
                                            <utc-checkbox [checked]="company.includeInDistributorLocator || false" [inputId]="'showOnDistributorLocator'"
                                                (change)="accountAdminEditService.onShowOnDistributorLocator($event)"
                                                [disabled]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.showOnDistributorLocator?.edit"
                                                [inputName]="'showOnDistributorLocator'">
                                            </utc-checkbox>
                                        </div>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.locatorName?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.LOCATOR_NAME' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editLocatorName" class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.locatorName
                                        ||
                                        '--'}}</div>
                                    <ng-template #editLocatorName>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                            [error]="companyDetailsForm.controls.locatorName.dirty && !companyDetailsForm.controls.locatorName.valid"
                                            [inputControl]="companyDetailsForm.controls.locatorName"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LOCATOR_NAME' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.locatorName?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.latitude?.view">
                                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : company.includeInDistributorLocator && editCompanyInformation }">{{ 'ACCOUNT_ADMIN.LATITUDE' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editlatitude" class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.latitude
                                        ||
                                        '--'}}</div>
                                    <ng-template #editlatitude>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                            [error]="companyDetailsForm.controls.latitude.dirty && !companyDetailsForm.controls.latitude.valid"
                                            [inputControl]="companyDetailsForm.controls.latitude"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LATITUDE' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.latitude?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.longitude?.view">
                                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="{ 'hvac-required' : company.includeInDistributorLocator && editCompanyInformation }">{{ 'ACCOUNT_ADMIN.LONGITUDE' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editlongitude" class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.longitude
                                        ||
                                        '--'}}</div>
                                    <ng-template #editlongitude>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                            [error]="companyDetailsForm.controls.longitude.dirty && !companyDetailsForm.controls.longitude.valid"
                                            [inputControl]="companyDetailsForm.controls.longitude"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LONGITUDE' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.longitude?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.salesOfficeNumber?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.SALES_OFFICE_NUMBER' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editsalesOfficeNumber" class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{company.salesOfficeNumber
                                        ||
                                        '--'}}</div>
                                    <ng-template #editsalesOfficeNumber>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                            [inputControl]="companyDetailsForm.controls.salesOfficeNumber"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.salesOfficeNumber?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.searchRadius?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.SEARCH_RADIUS' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editSearchRadius"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ company.searchRadius
                                        || '--' }}
                                    </div>
                                    <ng-template #editSearchRadius>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.SEARCH_RADIUS' | translate"
                                            [error]="companyDetailsForm.controls.searchRadius.dirty && !companyDetailsForm.controls.searchRadius.valid"
                                            [inputControl]="companyDetailsForm.controls.searchRadius"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.searchRadius?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.divRegNumber?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.DIV_REG_NUMBER' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editDivRegNumber"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{ company.divRegNum
                                        || '--' }}
                                    </div>
                                    <ng-template #editDivRegNumber>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value "
                                            [hideLabel]="false"
                                            [error]="companyDetailsForm.controls.divRegNumber.dirty && !companyDetailsForm.controls.divRegNumber.valid"
                                            [inputControl]="companyDetailsForm.controls.divRegNumber"
                                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DIV_REG_NUMBER' | translate"
                                            [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.divRegNumber?.edit"
                                            type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.language?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.LANGUAGE' | translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editLanguage"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">
                                        {{language
                                        ||
                                        '--'}}</div>
                                    <ng-template #editLanguage>
                                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                            [group]="companyDetailsForm" control="language" [multiple]="true"
                                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.LANGUAGE' | translate }}"
                                            [options]="accountAdminEditService.languageOptions || []">
                                        </utc-select>
                                    </ng-template>
                                </li>
                                <div class="hvac-divider"></div>
                            </ul>

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.hoursOfOperation">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.HOURS_OF_OPERATION' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editHoursOfOps"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                                        company.operatingTime || '--' }}
                                    </div>
                                    <ng-template #editHoursOfOps>

                                        <div class="hvac-col-xs hvac-account-admin-edit-valu">
                                            <utc-checkbox *ngFor="let operation of companyQuery.hoursOfOperation"
                                                [checked]="operation.toLowerCase() == accountAdminEditService.selectedHoursOfOperarion.toLowerCase()"
                                                [inputId]="operation" [value]="operation"
                                                (change)="accountAdminEditService.setHoursOfOperation($event,operation)"
                                                [inputName]="operation">
                                                <span class="hvac-search-filter-name">{{ operation }}</span>
                                            </utc-checkbox>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>

                            <!-- IT Super Admin -->

                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.inventoryAPI?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_INVENTORY_API' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editInventoryAPI"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                                        company.inventoryAPI || '--' }}
                                    </div>
                                    <ng-template #editInventoryAPI>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                                [error]="companyDetailsForm.controls.inventoryAPI.dirty && !companyDetailsForm.controls.inventoryAPI.valid"
                                                [inputControl]="companyDetailsForm.controls.inventoryAPI"
                                                [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_INVENTORY_API' | translate"
                                                [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.inventoryAPI?.edit" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>

                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.regionCode?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_REGION_CODE' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editRegionCode"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                                        company.regionCode || '--' }}
                                    </div>
                                    <ng-template #editRegionCode>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                                [error]="companyDetailsForm.controls.regionCode.dirty && !companyDetailsForm.controls.regionCode.valid"
                                                [inputControl]="companyDetailsForm.controls.regionCode"
                                                [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_REGION_CODE' | translate"
                                                [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.regionCode?.edit" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.customerUserName?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.DISTRIBUTOR_USER_NAME' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editCustomerUserName"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                                        company.customerUserName || '--' }}
                                    </div>
                                    <ng-template #editCustomerUserName>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                                [error]="companyDetailsForm.controls.customerUserName.dirty && !companyDetailsForm.controls.customerUserName.valid"
                                                [inputControl]="companyDetailsForm.controls.customerUserName"
                                                [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.DISTRIBUTOR_USER_NAME' | translate"
                                                [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.customerUserName?.edit" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                                <li class="hvac-row hvac-top-xs"
                                    *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.customerGUID?.view">
                                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.CUSTOMER_GUID' |
                                        translate}}
                                    </div>
                                    <div *ngIf="!editCompanyInformation;else editCustomerGUID"
                                        class="hvac-col-xs hvac-account-admin-detail-list-value">{{
                                        company.customerGUID || '--' }}
                                    </div>
                                    <ng-template #editCustomerGUID>
                                        <utc-input class="hvac-col-xs hvac-account-admin-edit-value " [hideLabel]="false"
                                                [error]="companyDetailsForm.controls.customerGUID.dirty && !companyDetailsForm.controls.customerGUID.valid"
                                                [inputControl]="companyDetailsForm.controls.customerGUID"
                                                [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CUSTOMER_GUID' | translate"
                                                [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.customerGUID?.edit" type="text">
                                        </utc-input>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hvac-divider hvac-associated-distributer-top-border"></div>
        <div class="hvac-col-xs-12 hvac-col-lg-12 hvac-account-admin-details-col">
            <div class="hvac-row hvac-layout-full-height hvac-account-admin-footer-container">
                <ul class="hvac-account-admin-detail-list hvac-list-height hvac-col-lg-8">
                    <li class="hvac-row hvac-layout-full-height" *ngIf="(companyQuery.companyDetailsConfiguration | async)?.formConfig?.notes?.view">
                        <div class="hvac-col-xs-12 hvac-label">{{ 'ACCOUNT_ADMIN.NOTES' | translate}}</div>
                        <div class="hvac-col-xs-12 hvac-layout-full-height">
                            <div class="hvac-account-admin-details-notes" *ngIf="!editCompanyInformation;else editNote">
                                <div *ngIf="company.notes" class="hvac-account-admin-detail-list-value">
                                    {{company.notes}}</div>
                                <p *ngIf="!company.notes" class="hvac-p-xs">({{ 'ACCOUNT_ADMIN.NO_NOTES' | translate}})
                                </p>
                            </div>
                            <ng-template #editNote>
                                <utc-textarea class="hvac-account-admin-details-notes hvac-account-admin-details-notes-edit"
                                    [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.NOTES' | translate"
                                    [disabledInput]="!(companyQuery.companyDetailsConfiguration | async)?.formConfig?.notes?.edit"
                                    [inputControl]="companyDetailsForm.controls.notes"
                                    [maxLength]="1000"></utc-textarea>
                            </ng-template>
                        </div>
                    </li>
                    <li class="hvac-footer-labels">
                        <p class="hvac-account-admin-changes">
                            <span class="hvac-account-admin-changes-date">{{ 'ACCOUNT_ADMIN.DATE' | translate }}:
                                {{lastModifiedDate || '--'}}</span>
                            <span>{{ 'ACCOUNT_ADMIN.WHO' | translate}}: {{company.lastModifiedBy || '--'}}</span>
                        </p>
                    </li>
                </ul>
                <div class="hvac-col-xs hvac-footer-button-container">
                    <div class="hvac-footer-button">

                    </div>
                    <button class="hvac-edit-button" *ngIf="canEdit && !editCompanyInformation && isSameFamily && adminAccess" hvacButton="primary"
                        (click)="onEdit(company)" [disabled]="!company.isActive">
                        {{ 'ACCOUNT_ADMIN.EDIT' | translate}}
                        <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                    </button>
                    <button class="hvac-edit-button" *ngIf="editCompanyInformation" hvacButton="primary"
                        (click)="onSaveEditClick()">
                        {{ 'ACCOUNT_ADMIN.SAVE_EDIT' | translate}}
                        <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                    </button>
                    <button class="hvac-edit-button" *ngIf="editCompanyInformation" hvacButton="primary"
                        (click)="cancelEdit()">
                        {{ 'ACCOUNT_ADMIN.CANCEL' | translate}}
                        <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                    </button>
                </div>
            </div>
        </div>
        <app-associated-contacts (onContactFormSubmit)="onContactFormSubmitAction()" [onCompanyChange]="onCompanyChange"
            [addContact]="((companyQuery.companyDetailsConfiguration | async)?.association?.addContact  && adminAccess) || false"
            [company]="company" [contactDetails$]="contactDetails$" [isSameFamily]="isSameFamily"
            *ngIf="((companyQuery.companyDetailsConfiguration | async)?.association?.associatedContact)">
        </app-associated-contacts>
        <app-associated-distributors [onCompanyChange]="onCompanyChange" [company]="company" [contactDetails$]="contactDetails$"
            [adminAccess]="adminAccess"
            *ngIf="(companyQuery.companyDetailsConfiguration | async)?.association?.associatedDistributor" [isSameFamily]="isSameFamily">
        </app-associated-distributors>
        <app-associated-dealers [onCompanyChange]="onCompanyChange" [company]="company"
            *ngIf="(companyQuery.companyDetailsConfiguration | async)?.association?.associatedDealer">
        </app-associated-dealers>
    </form>
    <utc-modal class='hvac-deactivate-company-modal' size="dynamic-height" (closeAction)="deactivateCompanyModal = false" [visible]="deactivateCompanyModal" theme='rounded' [showHeader]="false">
        <div class="hvac-deactivate-company-wrapper">
            <h3 class="hvac-h3 hvac-warning-text">{{
                'ACCOUNT_ADMIN.DEACTIVATE_COMPANY_MODAL.WARNING_TEXT' | translate }}</h3>
            <p class="deactivate-modal-message">
                {{'ACCOUNT_ADMIN.DEACTIVATE_COMPANY_MODAL.WARNING_INFO' | translate }}
            </p>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-deactivate-company-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="deactivateCompanyModal= false;">
                    {{'ACCOUNT_ADMIN.CANCEL' | translate }}
                </button>
                <button buttonType="button" hvacButton="primary" (click)="deactivateCompany()">
                    {{'ACCOUNT_ADMIN.DEACTIVATE_COMPANY_MODAL.CONTINUE' | translate }}
                </button>
            </div>

        </div>
    </utc-modal>
    <utc-modal class='hvac-deactivate-company-modal' size="dynamic-height" (closeAction)="reactivateCompanyModal = false" [visible]="reactivateCompanyModal" theme='rounded' [showHeader]="false">
        <div class="hvac-deactivate-company-wrapper">
            <h3 class="hvac-h3 hvac-warning-text">{{
                'ACCOUNT_ADMIN.REACTIVATE_COMPANY_MODAL.WARNING_TEXT' | translate }}</h3>
            <p class="deactivate-modal-message">
                {{'ACCOUNT_ADMIN.REACTIVATE_COMPANY_MODAL.WARNING_INFO' | translate }}
            </p>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-deactivate-company-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="reactivateCompanyModal= false;">
                    {{'ACCOUNT_ADMIN.CANCEL' | translate }}
                </button>
                <button buttonType="button" hvacButton="primary" (click)="reactivateCompany()">
                    {{'ACCOUNT_ADMIN.REACTIVATE_COMPANY_MODAL.CONTINUE' | translate }}
                </button>
            </div>

        </div>
    </utc-modal>
</ng-template>
<hvac-validate-soldto-popup [showModal]="showModalFrMkngCrntCmpnyToAsPrmry"
    [existingPrimaryDistributorDetails]="existingPrimaryDistributorDetails"
    (onCancel)="cnclChngngExstingPrmryDstrbtr()"
    (onContinue)="issueEditCompanyRequest()">
</hvac-validate-soldto-popup>
<ng-template [ngIf]="isLoading">
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>

<hvac-address-lookup [visible]="(showAddressVerification$ | async) || false" [addressLookup]="addressLookUpInput" (onAddressConfirmation)="onAddressConfirmation($event)"></hvac-address-lookup>
<!--  -->