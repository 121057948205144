import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { format } from 'date-fns-tz';

@Pipe({ name: 'yearMoDate' })
export class YearMoDatePipe implements PipeTransform {
    transform(value?: string | null): string {
        if (value?.trim()) {
            const year = value.substring(0, 4);
            const month = value.substring(4, 6);
            const date = value.substring(6);
            const fullDate = `${year}-${month}-${date}`;

            // space is required in new date() function to process UTC date
            return format(new Date(`${fullDate} `), AppConstants.dateFormat);
        }

        return '';
    }
}

