import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { LoginPanelContent } from 'common/components/login-panel/login-panel.component';
import { environment } from 'common/environments/environment';
import { ToastService, ToastTheme } from 'common/services/toast.service';
import { SignInError } from 'common/store/auth/auth.actions';
import { AuthState } from 'common/store/auth/auth.state';
import { ShowLoginPanel } from 'common/store/login-panel/login-panel.actions';
import Rellax, { RellaxInstance } from 'rellax';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, startWith, takeUntil } from 'rxjs/operators';
import { LoginCreateAccountComponent } from './components/login-create-account/login-create-account.component';

export interface Banner {
    visible?: boolean;
    theme?: ToastTheme;
    message?: string;
    params?: { [k: string]: string };
}

const LOGOUT_WAIT_AFTER_LOAD = 3000;

@Component({
    selector: 'hvac-secured-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild(LoginCreateAccountComponent) createAccountForm: LoginCreateAccountComponent;
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
    @Select(AuthState.signInError) signInError$: Observable<SignInError | null>;

    public toastOutlet = 'loginToast';
    loginErrorBanner: Banner = {};
    ngOnDestroy$ = new Subject();
    rellax: RellaxInstance;
    loginPanelConent$: Observable<LoginPanelContent> = this.translations.get('LOGIN_PANEL').pipe(
        startWith([{}])
    );

    logoutFrameUrl: string = 'about:blank';
    isLogoutRedirect = false;
    isLogoutLoading = false;
    loginErrorBanner$: BehaviorSubject<Banner> = new BehaviorSubject<Banner>({
        message: '',
        visible: false,
        theme: 'error'
    });

    constructor(
        private readonly store: Store,
        private readonly translations: TranslateService,
        private readonly location: Location,
        private readonly router: Router,
        private readonly toastService: ToastService
    ) { }

    ngOnInit() {
        this.signInError$.subscribe((signInError) => {
            if (signInError) {
                this.translations.get(this.getSignInErrorMessage(signInError)).subscribe((translated: string) => this.toastService.add({
                    content: translated,
                    id: 'signInError',
                    outletName: this.toastOutlet,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                }));
            }
        });

        this.routerState$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((routerState) => {
            const socialLoginEnabled = environment.features.socialLogin;

            this.isLogoutRedirect = routerState.url.includes('logout');

            if (this.isLogoutRedirect) {
                this.isLogoutLoading = true;
                this.logoutFrameUrl = environment.logoutFrameUrl;

                if (routerState.url === '/login-error') {
                    this.translations.get(this.getAccessDeniedErrorMessage(socialLoginEnabled)).subscribe((translated: string) => this.toastService.add({
                        content: translated,
                        id: 'LOGIN_ERROR_ACCESS_DENIED',
                        outletName: this.toastOutlet,
                        theme: 'error',
                        closeable: true,
                        autoClose: false
                    }));
                }
            }

            if (routerState.url === '/login-error') {
                this.translations.get(this.getAccessDeniedErrorMessage(socialLoginEnabled)).subscribe((translated: string) => this.toastService.add({
                    content: translated,
                    id: 'LOGIN_ERROR_ACCESS_DENIED',
                    outletName: this.toastOutlet,
                    theme: 'error',
                    closeable: true,
                    autoClose: false
                }));
            }
            this.rellax = new Rellax('.rellax');
            this.location.replaceState('/login');
        });

        this.isLoggedIn$.pipe(
            takeUntil(this.ngOnDestroy$),
            first((loggedIn: boolean) => loggedIn)
        ).subscribe(() => {
            // send to dashboard since we are already logged in
            this.router.navigate(['/']);
        });
    }

    showLoginPanel() {
        this.store.dispatch(new ShowLoginPanel());
    }

    scrollToCreateAccountForm() {
        this.createAccountForm.focusForm();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    logoutFrameLoaded() {
        setTimeout(() => {
            this.isLogoutLoading = false;

            return;
        }, LOGOUT_WAIT_AFTER_LOAD);
    }

    private getSignInErrorMessage(error: SignInError): string {
        if (this.isClockSkewError(error)) {
            return 'ERROR_CONTENT.SIGN_IN.CLOCK_SKEW_ERROR';
        }

        if (this.isMissingClaimsError(error)) {
            return 'ERROR_CONTENT.SIGN_IN.MISSING_CLAIMS_ERROR';
        }

        return error.content;
    }

    private getAccessDeniedErrorMessage(socialLoginEnabled: boolean): string {
        return socialLoginEnabled ? 'LOGIN_ERROR.ACCESS_DENIED_WITH_SOCIAL_LOGIN' : 'LOGIN_ERROR.ACCESS_DENIED';
    }

    private isClockSkewError(error: SignInError): boolean {
        const { message } = error.error;

        return message.includes('JWT was issued in the future') || message.includes('The JWT expired');
    }

    private isMissingClaimsError(error: SignInError): boolean {
        const { message } = error.error;

        return message.includes('Token missing claims');
    }
}
