import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { ContactofCompanies, ContactTypesMap, User } from '../../models/account-admin-search';
import { AccountAdminService } from '../../services/account-admin/account-admin.service';
import { IdmUserRoles } from '../../enums/idm-user-roles';


@Component({
    selector: 'hvac-account-admin-contact-details',
    templateUrl: './account-admin-contact-details.component.html',
    styleUrls: ['./account-admin-contact-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminContactDetailsComponent implements OnInit {
    public contact?: User;
    public contactTypes: ContactTypesMap[];
    public lastLoginDate: string;
    public lastModifiedDate: string;
    public canEdit: Boolean = false;

    constructor(
        public accountAdminService: AccountAdminService,
        private route: ActivatedRoute,
        private oktaService: OktaService
    ) { }

    ngOnInit(): void {
        // SUBSCRIPTIONS
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.canEdit = Boolean(res
                ?.claims
                ?.idm_user_roles
                ?.includes(IdmUserRoles.USERADMIN));
        });

        if (!this.route.snapshot.params.id) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.accountAdminService.getAccountAdminContactById(this.route.snapshot.params.id).subscribe((res: any) => {
            this.contact = res;

            this.lastLoginDate = this.accountAdminService.formatDate(res.lastLoginDate);
            this.lastModifiedDate = this.accountAdminService.formatDate(res.lastModifiedDate);

            this.createContactTypes(res.contactOfCompanies);
        });
    }

    createContactTypes(contactOfCompanies: ContactofCompanies[]): void {
        const map: ContactTypesMap[] = [];

        contactOfCompanies.forEach((company: ContactofCompanies) => {
            const found = map.find((existing: ContactTypesMap) => company.company.name === existing.name);

            if (found) {
                const foundContactType = found.types.find((existing) => company.contactType.name === existing);

                if (!foundContactType) {
                    found.types.push(company.contactType.name);
                }
            }
            else {
                map.push({
                    name: company.company.name,
                    types: [company.contactType.name]
                });
            }
        });

        this.contactTypes = map;
    }
}
