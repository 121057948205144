<p class="hvac-phvac-account-admin-results-count">
    <span *ngIf="totalCount">{{ 'ACCOUNT_ADMIN.RESULTS_COUNT' | translate: { totalCount: totalCount} }}</span>
</p>

<div class="hvac-account-admin-table-wrap hvac-rhythm-3">
    <hvac-table>
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button *ngIf="item.value === 'firstName'" class="hvac-table-sort-btn" (click)="sort(item)">
                        {{item.title}}
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>

                    <div *ngIf="item.value !== 'firstName'" class="hvac-table-sort-btn">
                        {{item.title}}
                    </div>
                </td>
            </tr>
        </thead>
        <tbody>
            <button type="button" role="link" (click)="onRowClick(contact.userId)" class="hvac-account-admin-table-link" *ngFor="let contact of contacts">
                <tr>
                    <td>
                        <div>{{contact.userFirstName}} {{contact.userLastName}}</div>
                        <div class="hvac-p-xs">{{createDetails(contact)}}</div>
                    </td>
                    <td>
                        {{ contact.companyName }}
                    </td>
                    <td>
                        {{ contact.soldTo }}
                    </td>
                </tr>
            </button>
        </tbody>
    </hvac-table>

    <p class="hvac-account-admin-no-results" *ngIf="!contacts?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>

    <utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small"></utc-loading-spinner>
</div>

<div class="hvac-row hvac-between-xs hvac-middle-xs">
    <div class="hvac-col-xs hvac-rhythm-4">
        <hvac-pagination *ngIf="totalPages > 1"
            [totalPages]="totalPages"
            [currentPage]="pagination"
            (pageChange)="handlePageChange($event)"
        >
        </hvac-pagination>
    </div>

    <div class="hvac-col-xs hvac-rhythm-4">
        <div class="hvac-account-admin-table-export-btn">
            <button hvacButton="primary" [disabled]="!contacts?.length"
                buttonType="submit"
                (click)="postCompanySearchExport(contacts ? contacts.length : 0 )"
                classNames="hvac-button-primary hvac-button-dark hvac-account-admin-btn">
                <span>{{ 'ACCOUNT_ADMIN.EXPORT' | translate }}</span>
            </button>
        </div>
    </div>
</div>
