/* eslint-disable max-lines */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'common/environments/environment';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { DealerOwner, DistributorOwner, DistributorOwnerPostParam, EnrollmentForm, EnrollmentFormData, EnrollmentFormDetails, OwnerInfo } from 'private/app/models/account-admin-enrollment.model';
import { EnrollmentFormFieldType, FormType } from 'private/app/views/program/enrollment-form/enrollment-form.component';
import { Observable } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import enrolmentStatusValues from 'private/app/views/program/enrollment-form/enrollment-forms/enrollment-status.json';

export interface BrandSold {
    name: string;
    value: string;
}

@Injectable({ providedIn: 'root' })
export class EnrollmentApiService {
    dealerOwnerInfo: OwnerInfo[];
    dealerOwnerAddressOne: string;
    dealerOwnerAddressTwo: string;
    dealerOwnerAddressCity: string;
    dealerOwnerAddressState: string;
    dealerOwnerPostalCode: string;
    dealerOwnerCountry: string;
    distributorManagerFirstName: string;
    distributorManagerLastName: string;
    distributorManagerUserId: number | string;
    distributorManagerCompanyAddressOne: string;
    distributorManagerCompanyAddressTwo: string;
    distributorManagerCity: string;
    distributorManagerState: string;
    distributorManagerPostalCode: string;
    distributorManagerCountry: string;
    distributorSubmittedBy: string;
    private enrollmentFormData: EnrollmentFormDetails;

    constructor(
        private readonly httpClient: HttpClient,
        public translate: TranslateService,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    setEnrollmentStatus(formValue: UntypedFormGroup, loggedInUserDetails: string, enrollmentStatus: number | null | undefined, ApproverWorkflowStatusId: number | null | undefined) {
        if (loggedInUserDetails === IdmUserRoles.DEFAULT_DISTRIBUTOR) {
            if ((enrollmentStatus === null && ApproverWorkflowStatusId === null) || (enrollmentStatus === enrolmentStatusValues.enrollmentStatus.Returned && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['SM Returned'])) {
                this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Initial'];
                this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['Dist Submitted'];
            }
        }
        if (loggedInUserDetails === IdmUserRoles.REGIONAL_SALES_MANAGER) {
            if ((enrollmentStatus === null && ApproverWorkflowStatusId === null)) {
                this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Final'];
                this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['SM Approved'];
            }
            if ((enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['Dist Submitted']) || (enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['PM Returned'])) {
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.APPROVE')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Final'];
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['SM Approved'];
                }
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.REJECT')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Initial'];
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['SM Declined'];
                }
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.RETURN')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus.Returned;
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['SM Returned'];
                }
            }
        }
        if (loggedInUserDetails === IdmUserRoles.PROGRAM_MANAGER) {
            if ((enrollmentStatus === null && ApproverWorkflowStatusId === null)) {
                this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus.Active;
                this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['PM Approved'];
            }
            if ((enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['Dist Submitted']) || (enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Final'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['SM Approved'])) {
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.APPROVE')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus.Active;
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['PM Approved'];
                }
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.RETURN')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Initial'];
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['PM Returned'];
                }
            }
            if (enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Initial'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['Dist Submitted']) {
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.REJECT')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Initial'];
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['PM Declined'];
                }
            }
            if (enrollmentStatus === enrolmentStatusValues.enrollmentStatus['Pending Final'] && ApproverWorkflowStatusId === enrolmentStatusValues.workflowStatus['SM Approved']) {
                if (formValue.value.approverInformation.ApproverAction[0].name === this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.APPROVER_ACTIONS.REJECT')) {
                    this.enrollmentFormData.enrollmentStatus = enrolmentStatusValues.enrollmentStatus['Pending Final'];
                    this.enrollmentFormData.ApproverWorkflowStatusId = enrolmentStatusValues.workflowStatus['PM Declined'];
                }
            }
        }
    }

    createPostBody(formValue: UntypedFormGroup, formdata: EnrollmentForm, formType: string): EnrollmentFormDetails {
        // Dealer info
        this.enrollmentFormData.DealerCompanyId = (formValue.value.dealerInformation.DealerCompanyId) ? Number(formValue.value.dealerInformation.DealerCompanyId) : null;
        if (formType === FormType.DUCTLESS_WITH_NO_OWNER) {
            this.enrollmentFormData.DealerOwnerId = 0;
        }
        else {
            this.enrollmentFormData.DealerOwnerId = (formValue.value.dealerInformation.DealerOwnerId[0].value) ? Number(formValue.value.dealerInformation.DealerOwnerId[0].value) : Number(formdata.dealerInformation[6].selectedId);
        }
        this.enrollmentFormData.dealerAddress = (formValue.value.dealerInformation.dealerAddress) ? formValue.value.dealerInformation.dealerAddress : '';
        this.enrollmentFormData.dealerCompanyName = (formValue.value.dealerInformation.dealerCompanyName) ? formValue.value.dealerInformation.dealerCompanyName : '';
        this.enrollmentFormData.dealerEmailAddress = (formValue.value.dealerInformation.dealerEmailAddress) ? formValue.value.dealerInformation.dealerEmailAddress : '';
        this.enrollmentFormData.dealerFax = (formValue.value.dealerInformation.dealerFax) ? formValue.value.dealerInformation.dealerFax : '';
        this.enrollmentFormData.dealerOwnerEmailAddress = (formValue.value.dealerInformation.dealerOwnerEmailAddress) ? formValue.value.dealerInformation.dealerOwnerEmailAddress : '';
        this.enrollmentFormData.dealerPhone = (formValue.value.dealerInformation.dealerPhone) ? formValue.value.dealerInformation.dealerPhone : '';

        // Distributor info
        this.enrollmentFormData.DistributorCompanyId = (formValue.value.distributorInformation.DistributorCompanyId) ? Number(formValue.value.distributorInformation.DistributorCompanyId) : null;
        this.enrollmentFormData.DistributorContactEmail = (formValue.value.distributorInformation.DistributorContactEmail) ? formValue.value.distributorInformation.DistributorContactEmail : '';
        this.enrollmentFormData.DistributorSalesManagerId = (formValue.value.distributorInformation.DistributorSalesManagerId[0].value) ? Number(formValue.value.distributorInformation.DistributorSalesManagerId[0].value) : Number(formdata.distributorInformation[3].selectedId);
        this.enrollmentFormData.distributorSubmittedBy = (formValue.value.distributorInformation.distributorSubmittedBy) ? formValue.value.distributorInformation.distributorSubmittedBy : '';
        this.enrollmentFormData.DistributorTerritoryManagerId = (formValue.value.distributorInformation.DistributorTerritoryManagerId[0].value) ? Number(formValue.value.distributorInformation.DistributorTerritoryManagerId[0].value) : Number(formdata.distributorInformation[4].selectedId);
        this.enrollmentFormData.distributorAddress = (formValue.value.distributorInformation.distributorAddress) ? formValue.value.distributorInformation.distributorAddress : '';
        this.enrollmentFormData.distributorName = (formValue.value.distributorInformation.distributorName) ? formValue.value.distributorInformation.distributorName : '';

        // Percent of Prior Sales
        this.enrollmentFormData.FirstBrandSoldPercentage = (formValue.value.percentOfPriorYearSales.FirstBrandSoldPercentage) ? formValue.value.percentOfPriorYearSales.FirstBrandSoldPercentage : '';
        this.enrollmentFormData.SecondBrandSoldPercentage = (formValue.value.percentOfPriorYearSales.SecondBrandSoldPercentage) ? formValue.value.percentOfPriorYearSales.SecondBrandSoldPercentage : '';
        this.enrollmentFormData.ThirdBrandSoldPercentage = (formValue.value.percentOfPriorYearSales.ThirdBrandSoldPercentage) ? formValue.value.percentOfPriorYearSales.ThirdBrandSoldPercentage : '';
        this.enrollmentFormData.FourthBrandSoldPercentage = (formValue.value.percentOfPriorYearSales.FourthBrandSoldPercentage) ? formValue.value.percentOfPriorYearSales.FourthBrandSoldPercentage : '';

        // Sales brand sold
        this.enrollmentFormData.FirstBrandSold = this.getBrandSoldValue(formValue.value.salesBrandSold.FirstBrandSold);
        this.enrollmentFormData.SecondBrandSold = this.getBrandSoldValue(formValue.value.salesBrandSold.SecondBrandSold);
        this.enrollmentFormData.ThirdBrandSold = this.getBrandSoldValue(formValue.value.salesBrandSold.ThirdBrandSold);
        this.enrollmentFormData.FourthBrandSold = this.getBrandSoldValue(formValue.value.salesBrandSold.FourthBrandSold);

        // sales info common
        this.enrollmentFormData.YearsDealerInProgram = (formValue.value.salesInfoCommon.YearsDealerInProgram[0].name) ? formValue.value.salesInfoCommon.YearsDealerInProgram[0].name : formValue.value.salesInfoCommon.YearsDealerInProgram;
        this.enrollmentFormData.FirstYearFundingForDealer = this.getFundingForDealerValue(formValue.value.salesInfoCommon.FirstYearFundingForDealer);
        this.enrollmentFormData.SecondYearFundingForDealer = this.getFundingForDealerValue(formValue.value.salesInfoCommon.secondYearFundingForDealer);
        this.enrollmentFormData.ThirdYearFundingForDealer = this.getFundingForDealerValue(formValue.value.salesInfoCommon.thirdYearFundingForDealer);

        // sales information
        this.enrollmentFormData.AOREquipmentPurchases = (formValue.value.salesInformation.AOREquipmentPurchases) ? formValue.value.salesInformation.AOREquipmentPurchases : '';
        this.enrollmentFormData.FirstYearEstimatedPurchases = (formValue.value.salesInformation.FirstYearEstimatedPurchases) ? formValue.value.salesInformation.FirstYearEstimatedPurchases : '';
        this.enrollmentFormData.SecondYearEstimatedPurchases = (formValue.value.salesInformation.SecondYearEstimatedPurchases) ? formValue.value.salesInformation.SecondYearEstimatedPurchases : '';
        this.enrollmentFormData.ThirdYearEstimatedPurchases = (formValue.value.salesInformation.ThirdYearEstimatedPurchases) ? formValue.value.salesInformation.ThirdYearEstimatedPurchases : '';
        this.enrollmentFormData.TotalDealerRevenue = (formValue.value.salesInformation.TotalDealerRevenue) ? formValue.value.salesInformation.TotalDealerRevenue : '';
        this.enrollmentFormData.AllBrandsTotalDuctlessSalesVolumeCurrentYear = (formValue.value.salesInformation.AllBrandsTotalDuctlessSalesVolumeCurrentYear) ? formValue.value.salesInformation.AllBrandsTotalDuctlessSalesVolumeCurrentYear : '';
        this.enrollmentFormData.AllBrandsAnnualDustlessEquipmentPuchasesCurrentYear = (formValue.value.salesInformation.AllBrandsAnnualDustlessEquipmentPuchasesCurrentYear) ? formValue.value.salesInformation.AllBrandsAnnualDustlessEquipmentPuchasesCurrentYear : '';
        this.enrollmentFormData.CBPAnnualDuctlessEquipmentPurchasesCurrentYear = (formValue.value.salesInformation.CBPAnnualDuctlessEquipmentPurchasesCurrentYear) ? formValue.value.salesInformation.CBPAnnualDuctlessEquipmentPurchasesCurrentYear : '';
        this.enrollmentFormData.AllBrandsDuctlessAORPurchaseVolumnCurrentYear = (formValue.value.salesInformation.AllBrandsDuctlessAORPurchaseVolumnCurrentYear) ? formValue.value.salesInformation.AllBrandsDuctlessAORPurchaseVolumnCurrentYear : '';
        this.enrollmentFormData.AllBrandsNewConstructionDuctlessPurchasesVolumnCurrentYear = (formValue.value.salesInformation.AllBrandsNewConstructionDuctlessPurchasesVolumnCurrentYear) ? formValue.value.salesInformation.AllBrandsNewConstructionDuctlessPurchasesVolumnCurrentYear : '';
        this.enrollmentFormData.CompetitiveBrand1NameCurrentYear = (formValue.value.salesInformation.CompetitiveBrand1NameCurrentYear) ? formValue.value.salesInformation.CompetitiveBrand1NameCurrentYear : '';
        this.enrollmentFormData.CompetitiveBrand1PercentageCurrentYear = (formValue.value.salesInformation.CompetitiveBrand1PercentageCurrentYear) ? formValue.value.salesInformation.CompetitiveBrand1PercentageCurrentYear : '';
        this.enrollmentFormData.CompetitiveBrand2NameCurrentYear = (formValue.value.salesInformation.CompetitiveBrand2NameCurrentYear) ? formValue.value.salesInformation.CompetitiveBrand2NameCurrentYear : '';
        this.enrollmentFormData.CompetitiveBrand2PercentageCurrentYear = (formValue.value.salesInformation.CompetitiveBrand2PercentageCurrentYear) ? formValue.value.salesInformation.CompetitiveBrand2PercentageCurrentYear : '';
        this.enrollmentFormData.CBPBrandPercentageCurrentYear = (formValue.value.salesInformation.CBPBrandPercentageCurrentYear) ? formValue.value.salesInformation.CBPBrandPercentageCurrentYear : '';

        // split of business
        this.enrollmentFormData.CommercialPercentages = (formValue.value.splitOfBusiness.CommercialPercentages) ? formValue.value.splitOfBusiness.CommercialPercentages : '';
        this.enrollmentFormData.ResidentialAORPercentage = (formValue.value.splitOfBusiness.ResidentialAORPercentage) ? formValue.value.splitOfBusiness.ResidentialAORPercentage : '';
        this.enrollmentFormData.ResidentialNewConstructionPercentage = (formValue.value.splitOfBusiness.ResidentialNewConstructionPercentage) ? formValue.value.splitOfBusiness.ResidentialNewConstructionPercentage : '';

        this.enrollmentFormData.Comment = (formValue.value.comments) ? formValue.value.comments : '';
        this.enrollmentFormData.ApproverComment = (formValue.value.approverInformation.ApproverComments) ? formValue.value.approverInformation.ApproverComments : '';

        return this.enrollmentFormData;
    }

    getBrandSoldValue(brandSold: BrandSold[] | string) {
        if (typeof brandSold === 'string') {
            return (brandSold === 'Please Select Brand') ? '' : brandSold;
        }

        return brandSold[0].name;
    }

    getFundingForDealerValue(funding: BrandSold[] | string) {
        return (typeof funding === 'object') ? funding[0].name : funding;
    }

    getEnrollmentFormInfo(companyId: string, pId: string):Observable<EnrollmentFormDetails> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<EnrollmentFormDetails>(`${environment.api.accountAdminProgram}/enrollment/form?hvacCompanyId=${companyId}&programId=${pId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getDealerOwners(id: string): Observable<DealerOwner> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<DealerOwner>(`${environment.api.accountAdminProgram}/enrollment/dealer/details?hvacCompanyId=${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getDistributorOwners(distributorOwnerPostParam: DistributorOwnerPostParam): Observable<DistributorOwner[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<DistributorOwner[]>(`${environment.api.accountAdminProgram}/enrollment/distributor/details?hvacCompanyId=${distributorOwnerPostParam.companyId}&programId=${distributorOwnerPostParam.programId}&brandFamily=${distributorOwnerPostParam.brandFamily}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    postEnrollmentFormData(enrollmentFormDetails: UntypedFormGroup, formdata: EnrollmentForm, formType: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<EnrollmentFormDetails>(`${environment.api.accountAdminProgram}/enrollment/form`, this.createPostBody(enrollmentFormDetails, formdata, formType), { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    prepareEnrollmentFormManagers(res: [DealerOwner, DistributorOwner[]], enrollmentForm: EnrollmentForm, data: EnrollmentFormData): EnrollmentForm {
        const [
            dealerOwners,
            distributorManagers
        ] = res;

        const dealerOwner = enrollmentForm.dealerInformation.filter((dealerField) => dealerField.name === EnrollmentFormFieldType.DEALER_OWNER)[0];
        this.dealerOwnerInfo = dealerOwners.ownerInfo;
        this.dealerOwnerAddressOne = dealerOwners.addressOne;
        this.dealerOwnerAddressTwo = dealerOwners.addressTwo;
        this.dealerOwnerAddressCity = dealerOwners.city;
        this.dealerOwnerAddressState = dealerOwners.state;
        this.dealerOwnerPostalCode = dealerOwners.postalCode;
        this.dealerOwnerCountry = dealerOwners.country;
        this.distributorManagerFirstName = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].firstName;
        this.distributorManagerLastName = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].lastName;
        this.distributorManagerUserId = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].userId;
        this.distributorManagerCompanyAddressOne = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].companyAddressOne;
        this.distributorManagerCompanyAddressTwo = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].companyAddressTwo;
        this.distributorManagerCity = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].city;
        this.distributorManagerState = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].state;
        this.distributorManagerPostalCode = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].postalCode;
        this.distributorManagerCountry = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].country;

        if (dealerOwner) {
            dealerOwner.options = dealerOwners?.ownerInfo?.map((owner) => ({
                name: owner.ownerName,
                value: `${owner.ownerUserId}`
            }));
            dealerOwner.selectedId = `${data.dealerInformation.DealerOwnerId}`;
        }

        const distributorManagerName = enrollmentForm.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.DISTRIBUTOR_NAME)[0];
        if (distributorManagerName) {
            distributorManagerName.options = distributorManagers.map((manager) => ({
                name: `${manager.companyName}`,
                value: `${manager.id}`
            }));
            distributorManagerName.selectedId = `${data.distributorInformation.DistributorCompanyId}`;
        }

        const residentialSM = enrollmentForm.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.RESIDENTIAL_SM)[0];
        if (residentialSM) {
            residentialSM.options = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].salesManagers?.map((manager) => ({
                name: `${manager.firstName} ${manager.lastName}`,
                value: `${manager.userId}`
            }));
            residentialSM.selectedId = `${data.distributorInformation.DistributorSalesManagerId}`;
        }

        const territoryManagers = enrollmentForm.distributorInformation.filter((dist) => dist.name === EnrollmentFormFieldType.TERRITORY_MANAGERS)[0];
        if (territoryManagers) {
            territoryManagers.options = distributorManagers.filter((manager) => Number(manager.id) === this.enrollmentFormData.DistributorCompanyId)[0].territoryManagers?.map((manager) => ({
                name: `${manager.firstName} ${manager.lastName}`,
                value: `${manager.userId}`
            }));
            territoryManagers.selectedId = `${data.distributorInformation.DistributorTerritoryManagerId}`;
        }

        return enrollmentForm;
    }

    mapEnrollmentResponse(res: EnrollmentFormDetails): EnrollmentFormData {
        this.enrollmentFormData = res;

        return {
            programName: res.programName,
            formType: res.enrollmentFormType.name,
            dealerInformation: {
                dealerCompanyName: res.dealerCompanyName,
                DealerCompanyId: res.DealerCompanyId,
                DealerCompanyIdForUI: res.dealerId,
                dealerAddress: res.dealerAddress,
                dealerPhone: res.dealerPhone,
                dealerFax: res.dealerFax,
                dealerEmailAddress: res.dealerEmailAddress,
                DealerOwnerId: res.DealerOwnerId,
                dealerOwnerEmailAddress: res.dealerOwnerEmailAddress
            },
            distributorInformation: {
                distributorName: res.distributorName,
                DistributorCompanyId: res.DistributorCompanyId,
                DistributorCompanyIdForUI: res.distributorId,
                distributorAddress: res.distributorAddress,
                DistributorSalesManagerId: res.DistributorSalesManagerId,
                DistributorTerritoryManagerId: res.DistributorTerritoryManagerId,
                DistributorContactEmail: res.DistributorContactEmail,
                distributorSubmittedBy: res.distributorSubmittedBy
            },
            salesInfoCommon: {
                YearsDealerInProgram: `${res.YearsDealerInProgram || 1}`,
                FirstYearFundingForDealer: `${res.FirstYearFundingForDealer || ''}`,
                SecondYearFundingForDealer: `${res.SecondYearFundingForDealer || ''}`,
                ThirdYearFundingForDealer: `${res.ThirdYearFundingForDealer || ''}`
            },
            salesInformation: {
                TotalDealerRevenue: `${res.TotalDealerRevenue || ''}`,
                AOREquipmentPurchases: `${res.AOREquipmentPurchases || ''}`,
                FirstYearEstimatedPurchases: `${res.FirstYearEstimatedPurchases || ''}`,
                SecondYearEstimatedPurchases: `${res.SecondYearEstimatedPurchases || ''}`,
                ThirdYearEstimatedPurchases: `${res.ThirdYearEstimatedPurchases || ''}`,
                AllBrandsTotalSalesVolumeCurrentYear: `${res.AllBrandsTotalSalesVolumeCurrentYear || ''}`,
                AllBrandsTotalDuctlessSalesVolumeCurrentYear: `${res.AllBrandsTotalDuctlessSalesVolumeCurrentYear || ''}`,
                AllBrandsAnnualEquipmentPurchasesCurrentYear: `${res.AllBrandsAnnualEquipmentPurchasesCurrentYear || ''}`,
                AllBrandsAnnualDustlessEquipmentPuchasesCurrentYear: `${res.AllBrandsAnnualDustlessEquipmentPuchasesCurrentYear || ''}`,
                AllBrandsDuctlessAORPurchaseVolumnCurrentYear: `${res.AllBrandsDuctlessAORPurchaseVolumnCurrentYear || ''}`,
                AllBrandsNewConstructionDuctlessPurchasesVolumnCurrentYear: `${res.AllBrandsNewConstructionDuctlessPurchasesVolumnCurrentYear || ''}`,
                CompetitiveBrand1NameFirstYear: `${res.CompetitiveBrand1NameFirstYear || ''}`,
                CompetitiveBrand1NameCurrentYear: `${res.CompetitiveBrand1NameCurrentYear || ''}`,
                CompetitiveBrand1PercentageFirstYear: `${res.CompetitiveBrand1PercentageFirstYear || ''}`,
                CompetitiveBrand1PercentageCurrentYear: `${res.CompetitiveBrand1PercentageCurrentYear || ''}`,
                CompetitiveBrand2NameFirstYear: `${res.CompetitiveBrand2NameFirstYear || ''}`,
                CompetitiveBrand2NameCurrentYear: `${res.CompetitiveBrand2NameCurrentYear || ''}`,
                CompetitiveBrand2PercentageFirstYear: `${res.CompetitiveBrand2PercentageFirstYear || ''}`,
                CompetitiveBrand2PercentageCurrentYear: `${res.CompetitiveBrand2PercentageCurrentYear || ''}`,
                CBPBrandPercentageCurrentYear: `${res.CBPBrandPercentageCurrentYear || ''}`,
                CBPAnnualDuctlessEquipmentPurchasesCurrentYear: `${res.CBPAnnualDuctlessEquipmentPurchasesCurrentYear || ''}`,
                CBPBrandPercentageFirstYear: `${res.CBPBrandPercentageFirstYear || ''}`,
                CBPBrandPercentageSecondYear: `${res.CBPBrandPercentageSecondYear || ''}`
            },
            splitOfBusiness: {
                ResidentialAORPercentage: `${res.ResidentialAORPercentage === '0' ? '' : res.ResidentialAORPercentage || ''}`,
                ResidentialNewConstructionPercentage: `${res.ResidentialNewConstructionPercentage === '0' ? '' : res.ResidentialNewConstructionPercentage || ''}`,
                CommercialPercentages: `${res.CommercialPercentages === '0' ? '' : res.CommercialPercentages || ''}`
            },
            salesBrandSold: {
                FirstBrandSold: `${(res.FirstBrandSold === 'UnSelected' || res.FirstBrandSold === null) ? this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_SELECT_BRAND') : res.FirstBrandSold}`,
                SecondBrandSold: `${(res.SecondBrandSold === 'UnSelected' || res.SecondBrandSold === null) ? this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_SELECT_BRAND') : res.SecondBrandSold}`,
                ThirdBrandSold: `${(res.ThirdBrandSold === 'UnSelected' || res.ThirdBrandSold === null) ? this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_SELECT_BRAND') : res.ThirdBrandSold}`,
                FourthBrandSold: `${(res.FourthBrandSold === 'UnSelected' || res.FourthBrandSold === null) ? this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_SELECT_BRAND') : res.FourthBrandSold}`
            },
            percentOfPriorYearSales: {
                FirstBrandSoldPercentage: `${res.FirstBrandSoldPercentage || ''}`,
                SecondBrandSoldPercentage: `${res.SecondBrandSoldPercentage || ''}`,
                ThirdBrandSoldPercentage: `${res.ThirdBrandSoldPercentage || ''}`,
                FourthBrandSoldPercentage: `${res.FourthBrandSoldPercentage || ''}`
            },
            approverInformation: {
                ApproverComments: res.ApproverComment,
                ApproverAction: res.ApproverAction,
                ApproverWorkflowStatusId: res.ApproverWorkflowStatusId,
                approveWorkflowStatusText: res.approveWorkflowStatusText
            },
            distributorComment: res.distributorComment,
            enrollmentStatus: res.enrollmentStatus
        };
    }
}
