<div class="hvac-product-details-panel">
    <hvac-accordion-new [open]="openState" (toggle)="handleToggle()">
        <hvac-accordion-header>{{ headerText }}</hvac-accordion-header>
        <hvac-accordion-body>
            <div class="hvac-product-details-panel-wrapper" [ngClass]="{ 'hvac-product-details-panel-divider': divider }">
                <div class="hvac-product-details-panel-content">
                    <ng-content></ng-content>
                </div>
            </div>
        </hvac-accordion-body>
    </hvac-accordion-new>
</div>

