<utc-base-layout class="hvac-product-page hvac-product-details-page" [ngClass]="{ 'hvac-product-details-loading': loading$ | async }">
    <ng-container *ngIf="(attributes$ | async) && (revealDetails$ | async); else placeholder">
        <utc-hero id="main-content">
            <h1 class="hvac-hero-title">{{ (attributes$ | async)?.urn }}</h1>
            <p *ngIf="nameHtml$ | async" class="hvac-hero-subtitle" [innerHTML]="nameHtml$ | async"></p>
        </utc-hero>
    </ng-container>
    <ng-template #placeholder>
        <utc-hero>
            <div class="hvac-hero-product-detail-placeholders">
                <div class="hvac-hero-title hvac-placeholder-content hvac-placeholder-title"></div>
                <div *ngIf="(attributes$ | async)?.name" class="hvac-hero-subtitle hvac-placeholder-content hvac-placeholder-subtitle"></div>
            </div>
        </utc-hero>
    </ng-template>
    <router-outlet #o="outlet"></router-outlet>
    <utc-sign-in-teaser [signInLink]="(setLoginUrl() | async)!"></utc-sign-in-teaser>
    <hvac-scroll-to-top-button></hvac-scroll-to-top-button>
</utc-base-layout>
