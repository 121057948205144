<div class="hvac-ecobee-report-container">
    <div class="hvac-report-sub-heading">
        <span class="hvac-h4">{{modelName && modelName.length > 0 ? modelName : ''}}</span>
    </div>

    <div class="hvac-row">
        <div class="hvac-report-date-picker hvac-col-xs-12 hvac-col-md-8">
            <div class="hvac-subhead">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.SELECT_DATE' | translate}}</div>
            <hvac-date-range-picker
                    dateType="datetime"
                    [startDate]="startDate"
                    [minStartDate]="startDate"
                    [endDate]="endDate"
                    [maxEndDate]="endDate"
                    (onSelection)="handleDateRangeChange($event)"
                    [submitText]="('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.SUBMIT_TEXT' | translate)"
                    >
            </hvac-date-range-picker>

            <div *ngIf="(endDateMaxDiffExceededMessage$ | async) as endDateMaxDiffExceededMessage" class="hvac-report-date-picker-warning">
                <utc-svg src="assets/icons/info.svg"></utc-svg> {{endDateMaxDiffExceededMessage}}
            </div>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-4">
            <div class="hvac-report-legend">
                <div class="hvac-legend-item hvac-checkbox-heat-set-point">
                    <utc-checkbox
                        [inputControl]="chartLegendFormControlGroup.controls['heatSetPointEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.HEAT_SETPOINT_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-cool-set-point"
                        [inputControl]="chartLegendFormControlGroup.controls['coolSetPointEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.COOL_SETPOINT_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
                <div class="hvac-legend-item">
                    <utc-checkbox class="hvac-checkbox-indoor-temp"
                        [inputControl]="chartLegendFormControlGroup.controls['indoorTemperatureEnabled']">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.INDOOR_TEMPERATURE'
                        | translate}}</utc-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="hvac-runtime-report-toast">
        <hvac-toast [name]="toastOutlet"></hvac-toast>
    </div>

    <div class="hvac-report-chart-container hvac-row">
        <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
        <div class="hvac-chart">
            <canvas id="canvas" *ngIf="chartData" baseChart  [data]="chartData" [options]="chartOptions" [type]="chartType"></canvas>
        </div>
    </div>

    <div class="hvac-report-footer">
        <div class="hvac-report-export-button">
            <button hvacButton="primary" size="small" [disabled]="isLoading" (click)="onExportClick()">
                {{ 'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.EXPORT_RUNTIME_REPORT' | translate }}
            </button>
        </div>
    </div>
</div>
