import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ProductDetails } from 'common/models';
import { ProductsState } from 'common/store/products/products.state';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';

@Component({
    selector: 'utc-product-content-section-product-data',
    templateUrl: './product-data.component.html',
    styleUrls: ['./product-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductDataComponent implements OnInit, OnDestroy {
    @ViewChild('productSearchInput') productSearchInput: ElementRef;
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;

    ngOnDestroy$ = new Subject();
    chooseFamilyProductForm = new UntypedFormGroup({ selectFamilyProductControl: new UntypedFormControl() });

    activeSisterProduct: {
        urn: string;
        attribute: string;
        values: {
            type: {
                name: string;
                unitOfMeasure: string;
            },
            value: string
        }[]
    }[];

    attributes$ = this.activeProduct$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filter((product: any) => Boolean(product)),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map(({ dataOverview }: any) => dataOverview)
    );

    showProductData$ = this.attributes$.pipe(
        map((familyProducts) => familyProducts && familyProducts.length > 0)
    );

    productOptions$ = this.attributes$.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((productData: any) => productData && productData.map((data: {urn:string}) => (
            data.urn
        )))
    );

    searchKey: string;

    private currentproductOptions$: BehaviorSubject<string> = new BehaviorSubject('');
    private searchKeyUpSubject$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private readonly translate: TranslateService,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef
    ) {}

    ngOnInit() {
        this.currentproductOptions$.pipe(
            takeUntil(this.ngOnDestroy$),
            delay(0),
            withLatestFrom(this.attributes$.pipe(startWith(null)))
        ).subscribe(([currentproductOptions, attributes]) => {
            const selectedProduct = attributes?.find((option: {urn:string}) => option.urn === currentproductOptions);
            if (selectedProduct) {
                this.selectSisterProduct(selectedProduct);
            }
        });

        this.chooseFamilyProductForm.controls.selectFamilyProductControl.valueChanges.subscribe((selectedProductOption) => {
            this.currentproductOptions$.next((Array.isArray(selectedProductOption) ? selectedProductOption[0] : selectedProductOption));
        });

        this.productOptions$.subscribe((productOptions) => {
            if (productOptions) {
                this.chooseFamilyProductForm.controls['selectFamilyProductControl'].setValue(productOptions[0]);
            }
        });

        this.showProductData$.subscribe();

        this.attributes$.pipe(
            tap((productData: string) => {
                if (productData && productData.length) {
                    return this.selectSisterProduct(productData[0]);
                }
            })
        ).subscribe();

        this.showProductData$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();

        this.searchKeyUpSubject$.pipe(
            debounceTime(500)
        ).subscribe((searchTextValue) => {
            this.searchKey = searchTextValue;
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.PRODUCT_DATA'),
            order: 3,
            container: this.element.nativeElement
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectSisterProduct(product: any) {
        if (product && product.attributes) {
            this.activeSisterProduct = Object.keys(product.attributes).map((attributeName) => ({
                urn: product.urn,
                attribute: attributeName,
                values: product.attributes[attributeName]
            }));
        }
    }

    updateActiveProduct(product: {urn:string}) {
        this.currentproductOptions$.next(product.urn);
    }


    inputKeyup(event: Event) {
        this.searchKeyUpSubject$.next((event.target as HTMLInputElement).value);
    }

    onOverviewSearch() {
        this.searchKeyUpSubject$.next(this.productSearchInput.nativeElement.value.toLowerCase());
    }
}
