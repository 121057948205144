<div>
    <section class="hvac-section-container login-and-opportunity">
        <h2 class="hvac-h3 hvac-cp-opportunities-title">
            {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARDS.LOGINS_AND_OPPORTUNITY_DOWNLOADS_REPORT.TITLE' | translate }}
        </h2>
        <div class="hvac-row">
            <div class="hvac-col-xs-12 hvac-col-lg-7 hvac-rhythm-2 subtitle">
                {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARDS.LOGINS_AND_OPPORTUNITY_DOWNLOADS_REPORT.DESCRIPTION' | translate }}
            </div>
            <div class="hvac-col-xs-12 hvac-col-lg-5">
                <div class="hvac-row">
                    <div class="hvac-col-xs-12 hvac-start-xs hvac-end-lg login-opportunity-actions">
                        <div class="hvac-year-select">
                            <hvac-select-new size="small" shadow="none" [formControl]="loginOpportunityYearFormControl" [options]="yearSelectOptions"></hvac-select-new>
                        </div>
                        <div>
                            <button [ngClass]="{ 'hvac-button-loading': isloginOpportunityDownloading }" hvacButton="primary" size="small" (click)="loginOpportunityReportDownload()">
                                <ng-container *ngIf="isloginOpportunityDownloading; else download">
                                    {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARD.DOWNLOADING_REPORT' | translate }} <div class="hvac-button-spinner"></div>
                                </ng-container>
                
                                <ng-template #download>
                                    {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARD.DOWNLOAD_REPORT' | translate }}
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="hvac-section-container">
        <h2 class="hvac-h3 hvac-cp-opportunities-title">
            {{ 'CONNECTED_PORTAL.OPPORTUNITIES.CARDS.OPPORTUNITY_REPORTS.TITLE' | translate }}
        </h2>
        <div class="hvac-dealer-opportunities-grid">
            <hvac-opportunity-report-card *ngFor="let card of opportunityCards$ | async | keyvalue"
                class="hvac-dealer-opportunities-grid-item" [isLoading]="card.value.isLoading"
                [isDownloading]="card.value.isDownloading" [id]="card.value.id" [title]="card.value.title"
                [description]="card.value.description"
                (onDownloadClick)="handleDownloadClick($event)"></hvac-opportunity-report-card>
        </div>
    </section>
</div>
