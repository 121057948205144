import { GraphQLFormattedError, SourceLocation } from 'graphql';
import { DefaultUpdateResponse } from './default-update-response.model';

export class ConnectedPortalDataError extends Error {
    public value: string;

    constructor(err: {
        message: string;
        value: string;
    }) {
        super(err.message);

        this.message = err.message;
        this.value = err.value;
    }
}

export class ConnectedPortalAPIError extends Error {
    public code: number;
    public data: DefaultUpdateResponse['data'];

    constructor(err: DefaultUpdateResponse) {
        super(err.message);

        this.message = err.message;
        this.code = err.code;
        this.data = err.data;
    }
}

export class ConnectedPortalGraphQLError {
    public message: string;
    public locations?: ReadonlyArray<SourceLocation>;
    public path?: ReadonlyArray<string | number>;
    public extensions?: {
        [key: string]: unknown;
    };

    constructor(err: GraphQLFormattedError) {
        this.message = err.message;
        this.locations = err?.locations;
        this.path = err?.path;
        this.extensions = err?.extensions;
    }
}
