<ng-template #enabled><span class="hvac-notification-enabled"><utc-svg src="assets/icons/enabled.svg"></utc-svg></span></ng-template>
<ng-template #disabled><span class="hvac-notification-disabled"><utc-svg src="assets/icons/disabled.svg"></utc-svg></span></ng-template>

<div class="hvac-system-notification-preferences">
    <section class="hvac-cp-section">
        <h4 class="hvac-h4 hvac-cp-section-title">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CONTROL_NOTIFICATION_SETTINGS' | translate }}</h4>
        <div class="hvac-cp-property-list">
            <hvac-product-property-list>
                <hvac-product-property-list-item>
                    <hvac-product-property-list-item-label>{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAX_STORED_NOTIFICATIONS' | translate }}
                    </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>{{ data.maxStoredNotification}}</hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
                <hvac-product-property-list-item>
                    <hvac-product-property-list-item-label>{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.UNRESPONSIVE_TIME_LIMIT' | translate }}
                    </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>{{ data.unresponsiveTimeLimitHours }}</hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>
        </div>
    </section>

    <section class="hvac-cp-section hvac-cp-customer">
        <h4 class="hvac-h4 hvac-cp-section-title">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CUSTOMER_SETTINGS' | translate }}</h4>
        <hvac-table [responsive]="true" theme="zebra">
            <thead class="hvac-cp-table-header">
                <tr class="hvac-cp-table-header-row">
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.EMAIL_ADDRESS' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAINTENANCE' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CRITICAL' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CONFIRMATIONS' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.OFFLINE' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.UTILITY_EVENTS' | translate }}</th>
                </tr>
            </thead>
            <tbody class="hvac-cp-body">
                <tr class="hvac-cp-items hvac-cp-customer-primary" *ngIf="data.customer.primary; let primary">
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION' | translate" class="hvac-p-sm hvac-fw-sb hvac-cp-item hvac-cp-customer-notification">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.PRIMARY' | translate }}</td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.EMAIL_ADDRESS' | translate" class="hvac-p-sm hvac-fw-rg hvac-cp-item hvac-cp-customer-email">{{ data.customer.primary.email }}</td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAINTENANCE' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-maintenance">
                        <ng-container *ngIf="primary.notifications.maintenanceEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CRITICAL' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-critical">
                        <ng-container *ngIf="primary.notifications.criticalEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CONFIRMATIONS' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-confirmations">
                        <ng-container *ngIf="primary.notifications.confirmationsEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.OFFLINE' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-offline">
                        <ng-container *ngIf="primary.notifications.offlineEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.UTILITY_EVENTS' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-utility">
                        <ng-container *ngIf="primary.notifications.utilityEventsEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                </tr>
                <tr class="hvac-cp-items hvac-cp-customer-secondary" *ngIf="data.customer.secondary; let secondary">
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION' | translate" class="hvac-p-sm hvac-fw-sb hvac-cp-item hvac-cp-customer-notification">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.SECONDARY' | translate }}</td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.EMAIL_ADDRESS' | translate" class="hvac-p-sm hvac-fw-rg hvac-cp-item hvac-cp-customer-email">{{ data.customer.secondary.email }}
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAINTENANCE' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-maintenance">
                        <ng-container *ngIf="secondary.notifications.maintenanceEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CRITICAL' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-critical">
                        <ng-container *ngIf="secondary.notifications.criticalEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CONFIRMATIONS' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-confirmations">
                        <ng-container *ngIf="secondary.notifications.confirmationsEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.OFFLINE' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-offline">
                        <ng-container *ngIf="secondary.notifications.offlineEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.UTILITY_EVENTS' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-customer-utility">
                        <ng-container *ngIf="secondary.notifications.utilityEventsEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </section>

    <section class="hvac-preferences-section hvac-cp-dealer">
        <h4 class="hvac-h4 hvac-preferences-section-title">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.DEALER_SETTINGS' | translate }}</h4>
        <hvac-table [responsive]="true" theme="zebra">
            <thead class="hvac-cp-table-header">
                <tr class="hvac-cp-table-header-row">
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAINTENANCE' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CRITICAL' | translate }}</th>
                    <th class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.OFFLINE' | translate }}</th>
                </tr>
            </thead>
            <tbody class="hvac-cp-body">
                <tr class="hvac-cp-items">
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.NOTIFICATION' | translate" class="hvac-p-sm hvac-fw-sb hvac-cp-item hvac-cp-dealer-notification">{{ 'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.PRIMARY' | translate }}</td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.MAINTENANCE' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-dealer-maintenance">
                        <ng-container *ngIf="data.dealerNotifications.maintenanceEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.CRITICAL' | translate" class="hvac-p-sm hvac-cp-item hvac-cp-dealer-critical">
                        <ng-container *ngIf="data.dealerNotifications.criticalEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                    <td [attr.data-label]="'CONNECTED_PORTAL.SYSTEM_NOTIFICATION_PREFERENCES.OFFLINE' | translate" class="hvac-p-sm hvac-cp-item">
                        <ng-container *ngIf="data.dealerNotifications.offlineEnabled; else disabled" [ngTemplateOutlet]="enabled"></ng-container>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </section>
</div>
