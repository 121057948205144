export enum DiagnosticTestUnitType {
    IDU = 'IDU',
    ODU = 'ODU',
    ODU_AND_IDU = 'ODU_AND_IDU'
}

export enum DiagnosticTestDeviceKeys {
    AC = 'AC',
    FAN = 'FAN',
    FAN_COIL_COOL_2_PIPE = 'FAN_COIL_COOL_2_PIPE',
    FAN_COIL_HEAT_2_PIPE = 'FAN_COIL_HEAT_2_PIPE',
    FAN_COIL_HEAT_DUAL_TERM = 'FAN_COIL_HEAT_DUAL_TERM',
    FURNACE = 'FURNACE',
    HEAT_PUMP_COOL = 'HEAT_PUMP_COOL',
    HEAT_PUMP_HEAT = 'HEAT_PUMP_HEAT',
    DEHUMIDIFIER = 'DEHUMIDIFIER',
    HUMIDIFIER = 'HUMIDIFIER',
    VENTILATOR = 'VENTILATOR'
}

export enum EquipmentHeatType {
    FAN_COIL = 'FAN_COIL',
    FURNACE = 'FURNACE'
}

export enum DiagnosticParameterUnit {
    THERMAL = 'F',
    PRESSURE = 'PSIG',
    ROTATION = 'RPM'
}

export enum DiagnosticEquipmentStageParameters {
    HP_HEAT_STAGE = 'hp_heat_stage',
    HP_COOL_STAGE = 'cool_stage',
    AUX_HEAT_STAGE = 'aux_heat_stage',
    FAN_STAGE = 'fan_stage',
    HUMIDIFIER_ON = 'humidifierOn',
    DEHUMIDIFIER_ON = 'dehumidifierOn',
    VENTILATION_ON = 'ventilatorOn'
}

export enum WallControlCommand {
    DisconnectCommand = 'DisconnectCommand',
    ExitCurrentTest = 'exitCurrentTest',
    StartLearningCommand = 'startLearningCommand'
}

export interface SystemDiagnosticsParamsData {
    dateTime?: String;
    blowerRpm: number;
    supplyAirTemperature: number;
    returnAirTemperature: number;
    deltaT: number;
    liquidLinePressure: number;
    liquidLineTemperature: number;
    outdoorTemperature: number;
    subCooling: number;
    suctionPressure: number;
    suctionTemperature: number;
    superHeat: number;
    ErrorMessage: string;
}

export enum OduCategory {
    SUCTION = 'suction',
    LIQUID = 'liquid',
    AIR = 'airTemperature'
}

export interface DiagnosticsConfigurations {
    min?: number;
    max?: number;
    currentValue?: number;
    equipmentType?: string;
    showAlertNotification?: boolean;
    unit?: string;
    displayValue?: string | number;
    outOfRangeLowerMin?: number;
    outOfRangeLowerMax?: number;
    outOfRangeUpperMin?: number;
    outOfRangeUpperMax?: number;
    oduCategory?: string;
    oduDisplayOrder?: number;
    iduDisplayOrder?: number;
}

export interface SystemDiagnosticsParamConfig {
    blowerRpm: DiagnosticsConfigurations;
    supplyAirTemperature: DiagnosticsConfigurations;
    returnAirTemperature: DiagnosticsConfigurations;
    deltaT: DiagnosticsConfigurations;
    liquidLinePressure: DiagnosticsConfigurations;
    liquidLineTemperature: DiagnosticsConfigurations;
    outdoorTemperature: DiagnosticsConfigurations;
    subCooling: DiagnosticsConfigurations;
    suctionPressure: DiagnosticsConfigurations;
    suctionTemperature: DiagnosticsConfigurations;
    superHeat: DiagnosticsConfigurations;
}

export interface DiagnosticTest {
    testName: string;
    testId: number;
    hasStarted?: boolean;
    testText: string;
    cancelTestText: string;
    cancellingTestText: string;
}

export interface DiagnosticEquipments {
    equipmentName: string;
    serialNumber: string;
    tests: DiagnosticTest[];
    isSelected?: boolean;
    hasAlertNotification?: boolean;
    isTestable?: boolean;
    equipmentType: DiagnosticTestUnitType;
    displayOrder: number;
}

export interface DiagnosticEquipmentData {
    device: string;
    stageParam: string;
    numStages: number;
}

export interface DiagnosticEquipmentsResponse {
    data: DiagnosticEquipmentData[];
}

export interface SystemDiagnosticsExtendedParamConfig extends SystemDiagnosticsParamConfig {
    fanCoilBlowerRpm: DiagnosticsConfigurations;
    fanCoilDeltaT: DiagnosticsConfigurations;
    furnaceBlowerRpm: DiagnosticsConfigurations;
    furnaceDeltaT: DiagnosticsConfigurations;
}

export interface DiagnosticsTestInputProps {
    id: string;
    data: Record<string, number | boolean>;
}

export interface CommandInput {
    command: WallControlCommand
}

