/* eslint-disable no-param-reassign */
import * as data from '../configData/json/SchemaConfig.json';
import * as accessoryData from '../configData/json/AccessoryConfig.json';
import { Config, DualFuel, HeatPump, Standard, SubConfig } from './types';
import { accessoryConfigKeys } from './constants/keys';

type ConfigMap = Config;
type keys = keyof ConfigMap;

const ConfigData = data as ConfigMap;

// interface Options {
//     min_clsp?: number;
//     max_clsp?: number;
//     unit?: 'C' | 'F';
// }
//
// const applyOptions = (object: SubConfig, options: Options = {}) => {
//   if (!options) {
//     return object;
//   }
//   const returnObject: SubConfig = { ...object };
//
//   const { min_clsp, max_clsp, unit } = options;
//
//   if (min_clsp != null && returnObject["clsp"] && unit) {
//     returnObject["clsp"] = {
//       ...returnObject["clsp"],
//       [unit]: {
//         ...returnObject["clsp"][unit],
//         min: min_clsp,
//       },
//     };
//   }
//
//   if (max_clsp != null && returnObject["htsp"] && unit) {
//     returnObject["htsp"] = {
//       ...returnObject["htsp"],
//       [unit]: {
//         ...returnObject["htsp"][unit],
//         max: max_clsp,
//       },
//     };
//   }
//
//   if (unit) {
//     Object.keys(returnObject).reduce((acc, key: keyof SubConfig) => {
//       if (acc[key]?.type === "temprange") {
//         if (unit === "C" && "F" in acc[key]!) {
//           delete (acc[key] as TempConfig)['F']!;
//         }
//       }
//
//       return acc;
//     }, returnObject);
//   }
// };

const isEmptyObject = (obj: SubConfig) => {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return true;
};

const deleteConfigParam = (editConfigRules: SubConfig, accesoryKey: keyof SubConfig) => {
    const configRuleSet = { ...editConfigRules };

    if (!isEmptyObject(configRuleSet)) {
        delete configRuleSet[accesoryKey];
    }

    return configRuleSet;
};

const keysToExcludeFromConfigRules = ['clsp', 'htsp', 'fan_type', 'accessory_output', 'set_point_delta'];

export const getETRuleConfig = (
    systemType: keys,
    subSystemType: keyof Standard | keyof HeatPump | keyof DualFuel,
    accessory?: keyof typeof accessoryData
) => {
    const accessoryConfigRules =
    accessory &&
    (accessoryData as Record<string, Record<string, unknown>>)?.[accessory];

    const SystemConfigOptions = ConfigData[systemType];

    if (!accessoryConfigRules) {
        return SystemConfigOptions[subSystemType];
    }

    const configRules = accessoryConfigKeys.reduce((editConfigRules, accesoryKey) => {
        if (!(accesoryKey in accessoryConfigRules)) {
            editConfigRules = deleteConfigParam(editConfigRules, accesoryKey as keyof SubConfig);
        }

        return editConfigRules;
    }, { ...SystemConfigOptions[subSystemType] });

    // To make explicit changes to remove keys so that it should not be editable
    keysToExcludeFromConfigRules.forEach((key:string) => {
        delete configRules[key as keyof typeof configRules];
    });

    return configRules;
};

export const ConfigMapping = data;


