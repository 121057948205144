import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Product } from 'common/models';
import { FetchCatalogCategories } from 'common/store/catalog/catalog.actions';
import { CatalogCategory, CatalogState, CategoryProducts } from 'common/store/catalog/catalog.state';
import { ProductsState } from 'common/store/products/products.state';
import { ResourceRepositoryModel } from 'common/store/repository/repository.model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, delay, first, map, skip, takeUntil, tap } from 'rxjs/operators';
import { BreakPointService } from '../../../../services/breakpoint.service';
import { ContentState } from '../../../../store/content/content.state';
import { FilterCategory, FilterSubCategory, ProductLiteratureService } from '../../service/product-literature.service';
/* eslint-disable max-lines */
export interface ProductItem {
    name: string;
    image: string;
    selected: boolean;
    urn: string;
}

@Component({
    selector: 'hvac-product-literarture-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    providers: [
        ProductLiteratureService
    ],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
    @ViewChild('productCrumb', { static: false }) productCrumb: ElementRef;
    @ViewChild('subCategoryCrumb', { static: false }) subCategoryCrumb: ElementRef;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() productURNData = new EventEmitter<any>();

    @Select(CatalogState.catalogCategories) catalogCategories$!: Observable<CatalogCategory[]>;
    @Select(CatalogState.categoryProducts) catalogProducts$!: Observable<CategoryProducts>;
    @Select(ProductsState.repository) productRepository$!: Observable<ResourceRepositoryModel<Product>>;
    @Select(ContentState.contentBrand) userPrefBrands$: Observable<string>;
    @Select(ContentState.contentFilteringPreference) userPrefCompanyType$: Observable<string>;

    selectedCategories$: BehaviorSubject<{ [category: string]: boolean }> = new BehaviorSubject({});
    selectedSubCategories$: BehaviorSubject<{ [subCategory: string]: boolean }> = new BehaviorSubject({});
    productURNFilteredArray: Array<ProductItem>;
    ngOnDestroy$ = new Subject();

    filterCategories$: Observable<FilterCategory[]> = combineLatest([this.catalogCategories$, this.selectedCategories$]).pipe(
        map(([catalogCategorie, selectedCategories]) => catalogCategorie.map((catalog) => ({
            catalog: catalog,
            selected: selectedCategories[catalog.urn] || false,
            id: catalog.name
        })))
    );

    filterSubCategories$: Observable<FilterSubCategory[]> = combineLatest([this.filterCategories$, this.selectedSubCategories$]).pipe(
        map(([filterCategories, selectedSubCategories]) => {
            const subCatArray: FilterSubCategory[] = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterCategories.filter((category) => category.selected).map((subCategory: any) => subCategory.catalog.children.map((item: {name:string, urn:string}) => subCatArray.push({
                subCategory: item.name,
                selected: selectedSubCategories[item.urn.split('/')[1]] || false,
                urn: item.urn
            })));

            return subCatArray;
        })
    );

    familyProducts$: Observable<object[]> = combineLatest([
        this.filterCategories$,
        this.filterSubCategories$,
        this.catalogProducts$,
        this.productRepository$
    ]).pipe(
        map(([filterCategories, filterSubCategories, categoryProducts, productRepository]) => {
            const productArray: {urn:string, name: string, selected: boolean, image:string | null}[] = [];
            const selectedCategory = filterCategories.filter((filterCategory) => filterCategory && filterCategory.selected && (filterCategory.catalog.children.length === 0));
            selectedCategory.forEach((category: FilterCategory) => {
                const products = categoryProducts[category.catalog.urn] || [];
                const filteredProducts = products.map((urn) => productRepository[urn]).filter(Boolean);
                filteredProducts.forEach(((product) => {
                    productArray.push({
                        urn: product.urn,
                        name: product.name,
                        selected: false,
                        image: product.media ? this.productLiteratireService.findImageSrc(product.media) : null
                    });
                }));
            });
            const subCategories = filterSubCategories.filter((filterSubCategory) => filterSubCategory && filterSubCategory.selected);
            this.resetProductSelection(categoryProducts, filterSubCategories, filterCategories);

            subCategories.forEach((subcategory) => {
                const products = categoryProducts[subcategory.urn] || [];
                const filteredProducts = products.map((urn) => productRepository[urn]).filter(Boolean);
                filteredProducts.forEach(((product) => {
                    productArray.push({
                        urn: product.urn,
                        name: product.name,
                        selected: false,
                        image: product.media ? this.productLiteratireService.findImageSrc(product.media) : null
                    });
                }));
            });

            return productArray;
        })
    );


    selectedProducts$: BehaviorSubject<{ [product: string]: boolean }> = new BehaviorSubject({});
    filterProducts$ = this.productLiteratireService.fetchFilterProduct(this.familyProducts$, this.selectedProducts$)
    categoryIcon$ = this.productLiteratireService.fetchProductIcon(this.filterCategories$);
    filterCategoriesLabel$ = this.productLiteratireService.getCatagoryLabel(this.filterCategories$);
    filterSubCategoriesLabel$ = this.productLiteratireService.getSubcategoryLabel(this.filterSubCategories$);
    filterproductLabel$ = this.productLiteratireService.getProductLabel(this.filterProducts$);
    subcategoryEnabled$: Observable<boolean> = this.productLiteratireService.getSubcategoryEnable(this.filterSubCategories$);
    productsEnabled$: Observable<Boolean> = this.productLiteratireService.getProductEnable(this.filterProducts$, this.filterSubCategories$)
    productListLoading$ = this.productLiteratireService.loadingSpinner();
    public quickSearchModelNumberControl = new UntypedFormControl('');
    public categoryExpanded$ = new BehaviorSubject<Boolean>(false);
    public subcategoryExpanded$ = new BehaviorSubject<Boolean>(false);
    public productsExpanded$ = new BehaviorSubject<Boolean>(false);

    private searchKeyUpSubject$: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private readonly productLiteratireService: ProductLiteratureService,
        private readonly store: Store,
        private readonly breakPointService: BreakPointService
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetProductSelection(categoryProducts: {[key:string]:string[]}, filterSubCategories: any, filterCategories: FilterCategory[]) {
        const subCategories = filterSubCategories.filter((filterSubCategory: {selected:string}) => filterSubCategory && !filterSubCategory.selected);
        if (subCategories.length > 0) {
            subCategories.forEach((subCategory: {[key:string]:string}) => {
                if (categoryProducts[subCategory.urn]) {
                    const selectedProducts = this.selectedProducts$.value;
                    categoryProducts[subCategory.urn].forEach((urn: string) => {
                        if (selectedProducts[urn]) {
                            this.selectedProducts$.next({
                                ...selectedProducts,
                                [urn]: false
                            });
                        }
                    });
                }
            });
        }
        const selectedCategory = filterCategories.filter((filterCategory: FilterCategory) => filterCategory && !filterCategory.selected && (filterCategory.catalog.children.length === 0));
        if (selectedCategory.length > 0) {
            selectedCategory.forEach((category: FilterCategory) => {
                if (categoryProducts[category.catalog.urn]) {
                    const selectedProducts = this.selectedProducts$.value;
                    categoryProducts[category.catalog.urn].forEach((urn: number | string) => {
                        if (selectedProducts[urn]) {
                            this.selectedProducts$.next({
                                ...selectedProducts,
                                [urn]: false
                            });
                        }
                    });
                }
            });
        }
    }

    ngOnInit() {
        this.productLiteratireService.fetchProducts(this.filterCategories$, this.filterSubCategories$, this.catalogProducts$).pipe(takeUntil(this.ngOnDestroy$)).subscribe();
        this.catalogCategories$.pipe(
            takeUntil(this.ngOnDestroy$),
            first(),
            tap((catalogCategories) => (catalogCategories.length ? null : this.store.dispatch(new FetchCatalogCategories())))
        ).subscribe();

        this.subcategoryExpanded$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(100),
            delay(100)
        ).subscribe((enabled) => {
            if (enabled) {
                this.productLiteratireService.focusSubcategory(this.subCategoryCrumb);
            }
        });

        this.productsExpanded$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(100),
            delay(100)
        ).subscribe((enabled) => {
            if (enabled) {
                this.productLiteratireService.focusProduct(this.productCrumb);
            }
        });

        this.filterProducts$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((filterProducts) => {
            this.productURNFilteredArray = filterProducts.filter((product: ProductItem) => product && product?.selected);
        });

        combineLatest([
            this.userPrefBrands$, this.userPrefCompanyType$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            skip(1),
            map(([userPrefBrands, userPrefCompanyType]) => {
                if (userPrefBrands || userPrefCompanyType) {
                    this.selectedCategories$.next({});
                    this.selectedSubCategories$.next({});
                    this.selectedProducts$.next({});
                    this.productURNData.emit([]);
                }
            })
        ).subscribe();

        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(1000)
        ).subscribe((searchTextValue) => {
            this.productURNData.emit([]);
            if (searchTextValue.length > 0) {
                this.quickSearch();
            }
        });
    }

    toggleCategory() {
        if (this.categoryExpanded$.value) {
            return this.productLiteratireService.collapseCategory(this.categoryExpanded$);
        }

        return this.productLiteratireService.expandCategory(this.categoryExpanded$);
    }

    toggleSubcategory() {
        if (this.subcategoryExpanded$.value) {
            return this.collapseSubcategory();
        }

        return this.expandSubcategory();
    }

    expandSubcategory() {
        this.subcategoryExpanded$.next(true);
    }

    collapseSubcategory() {
        this.subcategoryExpanded$.next(false);
    }

    toggleProducts() {
        if (this.productsExpanded$.value) {
            return this.collapseProducts();
        }

        return this.expandProducts();
    }

    expandProducts() {
        this.productsExpanded$.next(true);
    }

    collapseProducts() {
        this.productsExpanded$.next(false);
    }

    setCategory(event: Event, filterCategory: FilterCategory) {
        this.quickSearchModelNumberControl.setValue('');
        if ((!event.target as unknown as HTMLInputElement).checked && filterCategory.catalog.children.length > 0) {
            this.removeSubCategorySelection(filterCategory.catalog.children);
        }
        const selectedCategories = this.selectedCategories$.value;
        this.selectedCategories$.next({
            ...selectedCategories,
            [filterCategory.catalog.urn]: !selectedCategories[filterCategory.catalog.urn]
        });
        if (filterCategory.catalog.children.length > 0) {
            if (!(this.breakPointService.isTablet() || this.breakPointService.isMobile())) {
                this.subcategoryExpanded$.next(!filterCategory.selected);
                this.collapseProducts();
            }
        }
        else if (!(this.breakPointService.isTablet() || this.breakPointService.isMobile())) {
            this.productsExpanded$.next(!filterCategory.selected);
        }
    }

    removeSubCategorySelection(catalogCategory: CatalogCategory[]) {
        catalogCategory.forEach((category) => {
            const selectedSubCategories: { [category: string]: boolean } = this.selectedSubCategories$.value;
            if (selectedSubCategories[category.urn.split('/')[1]]) {
                this.selectedSubCategories$.next({
                    ...selectedSubCategories,
                    [category.urn.split('/')[1]]: false
                });
            }
        });
    }

    setSubcategory(category: FilterSubCategory) {
        const selectedSubCategories = this.selectedSubCategories$.value;
        this.selectedSubCategories$.next({
            ...selectedSubCategories,
            [category.urn.split('/')[1]]: !selectedSubCategories[category.urn.split('/')[1]]
        });
        this.productLiteratireService.collapseCategory(this.categoryExpanded$);
        if (!(this.breakPointService.isTablet() || this.breakPointService.isMobile())) {
            this.expandProducts();
        }
    }

    setProduct(product: Product) {
        const selectedProducts = this.selectedProducts$.value;
        this.selectedProducts$.next({
            ...selectedProducts,
            [product.urn]: !selectedProducts[product.urn]
        });
        this.collapseSubcategory();
        this.productLiteratireService.collapseCategory(this.categoryExpanded$);
        this.productURNData.emit(this.productURNFilteredArray.filter((productObj: ProductItem) => productObj && productObj?.selected));
    }

    handleClickOutside() {
        this.productLiteratireService.collapseCategory(this.categoryExpanded$);
        this.collapseSubcategory();
        this.collapseProducts();
    }

    inputKeyup(event: KeyboardEvent) {
        this.searchKeyUpSubject$.next((event.target as HTMLInputElement).value);
    }

    quickSearch() {
        this.selectedProducts$.next({});
        this.selectedSubCategories$.next({});
        this.selectedCategories$.next({});
        this.productURNData.emit([]);
        this.handleClickOutside();
        if (this.quickSearchModelNumberControl.value && this.quickSearchModelNumberControl.value.length > 0) {
            this.productURNData.emit([{ urn: this.quickSearchModelNumberControl.value }]);
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
