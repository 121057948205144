<div class="hvac-fault-severity-report">
    <hvac-report-chart-container
        [chartTitle]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SEVERITY.HEADER' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SEVERITY.SUBHEAD' | translate"
        [hasData]="!!(hasData$ | async)"
        [noDataMessage]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.EMPTY_MESSAGE'| translate"
        [isLoading]="isLoading"
        yearSelectId="hvac-fault-severity-year-select"
        [yearSelectFormControl]="yearSelectFormControl"
        [yearSelectOptions]="yearSelectOptions"
        [hasReportButton]="false">
        <ng-container *ngIf="(chartOptions$ | async) as chartOptions;">
            <canvas *ngIf="(chartData$ | async) as chartData" baseChart class="hvac-chart" [data]="chartData"
                [options]="chartOptions" [type]="chartType"></canvas>
        </ng-container>
    </hvac-report-chart-container>
</div>
