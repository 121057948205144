<section class="hvac-product-details-content-section hvac-product-details-content-carousel-section" *ngIf="(relationshipProducts$ | async)?.length">
    <div class="hvac-product-content-section-overview">
        <div class="hvac-product-content-section-overview-description">
            <h2 class="hvac-section-heading" [attr.id]="sectionTitle" tabindex="-1">{{ sectionTitle }}</h2>
            <div class="hvac-carousel" [ngClass]="carouselFamily">
                <swiper-container class="swiper-container" hvacSwiper init="false" [config]="swiperParams">
                    <swiper-slide *ngFor="let product of (relationshipProductsDetails$ | async)" [ngSwitch]="product.type" class="hvac-carousel-item">
                        <utc-placeholder-list-item *ngSwitchCase="'placeholder'" mode="grid"></utc-placeholder-list-item>
                        <utc-product-list-item *ngSwitchDefault [resource]="product.attributes" mode="grid"></utc-product-list-item>
                    </swiper-slide>
                </swiper-container>
                <button class="hvac-carousel-nav-button hvac-carousel-prev">
                    <utc-svg class="hvac-carousel-navigation-icon" src="assets/icons/chevron-right.svg"></utc-svg>
                    <span class="hvac-accessible-text">{{ 'CAROUSEL.PREV' | translate }}</span>
                </button>
                <button class="hvac-carousel-nav-button hvac-carousel-next">
                    <utc-svg class="hvac-carousel-navigation-icon" src="assets/icons/chevron-right.svg"></utc-svg>
                    <span class="hvac-accessible-text">{{ 'CAROUSEL.NEXT' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <hr class="hvac-section-rule">
</section>
