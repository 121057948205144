import { AfterViewInit, Component, NgZone, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { libMJ8 } from 'private/assets/default/plugins/coolcalc/libmj8.js';
import { myFunFactory } from 'private/assets/default/plugins/coolcalc/fun-factory.js';

import { ContentHeadingData } from 'common/models/content-heading';
import { IdToken } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { environment } from 'common/environments/environment';
import { CoolCalcService } from 'private/app/services/coolcalc/cool-calc.service';
import { ErrorService } from 'common/content/services/error.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OktaService } from 'common/services/okta/okta.service';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { createAccountEmailInputValidator } from 'common/utils/createAccountValidators';
import { AccountStatusService } from 'common/services/account-status.service';
import { AccountStatusResponse, CoolCalcSupportRequestBody } from 'common/models/account-status';
import { inputValidator } from 'private/app/services/account-admin/account-admin-edit-form.service';
import { AppConstants } from 'common/app-constants';
import { Router } from '@angular/router';
const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: 'app-cool-calc',
    templateUrl: './cool-calc.component.html',
    styleUrls: ['./cool-calc.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoolCalcComponent implements AfterViewInit {
    @Select(AuthState.idToken) tokens$: Observable<IdToken | undefined>;
    @Select(AccountStatusState.getUserId) userId$: Observable<string>;
    userIdControl = new UntypedFormControl('');
    breadCrumbData: ContentHeadingData = {
        Content: {
            title: this.translate.instant('COOL_CALC.TITLE'),
            subTitle: this.translate.instant('COOL_CALC.SUBTITLE')
        },
        breadCrumbs: [
            {
                title: this.translate.instant('COOL_CALC.BREADCRUMBS.CRUMB1'),
                url: '/support'
            },
            {
                title: this.translate.instant('COOL_CALC.BREADCRUMBS.CRUMB2'),
                url: '/support/pre-sale support'
            }
        ]
    };

    sessionUserErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    isModalVisible = false;
    isHelpModalVisible = false;
    canImpersonate: boolean = false;
    impersonatedUser: { id: string, userName: string } = {
        id: '',
        userName: ''
    };

    public maskPhoneNumber: boolean = true;

    public requestSupportForm = new UntypedFormGroup({
        emailValidationControl: new UntypedFormControl('', [createAccountEmailInputValidator(101, true, validEmailRegex)]),
        userPhoneControl: new UntypedFormControl('', [inputValidator(20, false), Validators.pattern(AppConstants.validPhoneRegex)]),
        issueDescriptionControl: new UntypedFormControl('')
    });

    public requestSupportBody : CoolCalcSupportRequestBody;
    public loading: boolean;
    public apiStatus : string = '';
    public maxCommentLimit: number = 500;
    private validUserToImpersonate: boolean;
    private nativeUser: string;
    private userPhone: string = '';
    private email: string = '';
    private formattedPhoneNumber: string = '';
    private formattedNumberFromForm: string = '';

    constructor(
        public coolCalcService: CoolCalcService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private router: Router,
        private ngZone: NgZone,
        private oktaService: OktaService,
        private readonly statusService: AccountStatusService
    ) {
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.canImpersonate = Boolean(res?.claims?.idm_user_roles?.includes(IdmUserRoles.COOLCALCIMPERSONATION));
        });
        this.statusService.getUserStatus().subscribe((result: AccountStatusResponse) => {
            this.userPhone = (result.phone);
            this.formattedPhoneNumber = this.userPhone ? this.usFormattedPhoneNumber(this.userPhone) : '';
            this.email = (result.email);
            this.requestSupportForm.controls.userPhoneControl.setValue(this.formattedPhoneNumber);
            this.requestSupportForm.controls.emailValidationControl.setValue(this.email);
        });
    }

    ngAfterViewInit(): void {
        this.userId$.subscribe((userId) => {
            this.nativeUser = userId;
            this.initCoolCalc(userId);
        });
    }

    validateInputUser(userId: string) {
        this.coolCalcService.validateSessionUser(userId).subscribe((impersonatedUser) => {
            this.validUserToImpersonate = (impersonatedUser.status === 'Success');
            this.impersonatedUser = this.validUserToImpersonate ?
                {
                    id: userId,
                    userName: impersonatedUser.details
                } :
                {
                    id: '',
                    userName: ''
                };
            this.sessionUserErrorMessage$.next(impersonatedUser.status === 'Success' ? '' : impersonatedUser.details);
            if (this.validUserToImpersonate) {
                this.isModalVisible = false;
                this.initCoolCalc(this.impersonatedUser.id);
            }
            this.userIdControl.setValue('');
        });
    }

    impersonateCoolCalc() {
        this.coolCalcService.isCoolCalcLoading = true;
        this.validUserToImpersonate = false;
        this.sessionUserErrorMessage$.next('');
        if (this.userIdControl.value.length > 0) {
            this.validateInputUser(this.userIdControl.value);
        }
    }

    exitImpersonation() {
        this.impersonatedUser =
        {
            id: '',
            userName: ''
        };
        this.initCoolCalc(this.nativeUser);
    }

    handleCloseModal() {
        this.isModalVisible = false;
    }

    openHelpModal() {
        this.isHelpModalVisible = true;
        this.formattedPhoneNumber = this.userPhone ? this.usFormattedPhoneNumber(this.userPhone) : '';
        this.requestSupportForm.controls.userPhoneControl.setValue(this.formattedPhoneNumber);
    }

    handleCloseHelpModal() {
        this.requestSupportForm.controls.userPhoneControl.setValue('');
        this.requestSupportForm.controls.issueDescriptionControl.setValue('');
        this.isHelpModalVisible = false;
        this.apiStatus = '';
    }

    usFormattedPhoneNumber(number : string) {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    requestSubmitClick() {
        this.loading = true;

        this.formattedNumberFromForm = this.requestSupportForm.controls.userPhoneControl.value;
        if (this.formattedNumberFromForm !== null) {
            this.formattedNumberFromForm = this.formattedNumberFromForm.replace(/\D/g, '');
        }

        this.requestSupportBody = {
            userPhone: this.formattedNumberFromForm,
            issueDescription: this.requestSupportForm.controls.issueDescriptionControl.value,
            userAltEmail: this.requestSupportForm.controls.emailValidationControl.value
        };

        this.statusService.postCoolCalcSupportRequest(
            this.requestSupportBody
        ).subscribe(
            (res) => {
                if (res.status === this.translate.instant('COOL_CALC.MODAL.SUCCESS')) {
                    this.loading = false;
                    this.apiStatus = this.translate.instant('COOL_CALC.MODAL.SUBMITTED_SUCCESS');
                }
            },
            (error) => {
                if (error) {
                    this.loading = false;
                    this.apiStatus = this.translate.instant('COOL_CALC.MODAL.SOMETHING_WENT_WRONG');
                }
            }
        );
    }

    private removeElementsFromPdf() {
        const globalNav = document.getElementById('cc-global-nav-one');
        if (globalNav && !globalNav.hasAttribute('data-html2canvas-ignore')) {
            globalNav.setAttribute('data-html2canvas-ignore', 'true');
        }
        const leftNav = document.getElementsByClassName('col-md-3');
        if (leftNav[0] && !leftNav[0].hasAttribute('data-html2canvas-ignore')) {
            leftNav[0].setAttribute('data-html2canvas-ignore', 'true');
        }
        const titleCrumb = document.getElementById('cc-crumb-one');
        if (titleCrumb && !titleCrumb.hasAttribute('data-html2canvas-ignore')) {
            titleCrumb.setAttribute('data-html2canvas-ignore', 'true');
        }

        const breadCrumb = document.getElementsByClassName('nav-pills');
        if (breadCrumb[0] && !breadCrumb[0].hasAttribute('data-html2canvas-ignore')) {
            breadCrumb[0].setAttribute('data-html2canvas-ignore', 'true');
        }

        const editButton = document.querySelector('[data-coolcalc-role="edit"]');
        if (editButton && !editButton.hasAttribute('data-html2canvas-ignore')) {
            editButton.setAttribute('data-html2canvas-ignore', 'true');
        }

        const shareButton = document.querySelector('[data-coolcalc-role="share-pdf"]');
        if (shareButton && !shareButton.hasAttribute('data-html2canvas-ignore')) {
            shareButton.setAttribute('data-html2canvas-ignore', 'true');
        }

        const downloadButton = document.querySelector('[data-coolcalc-role="download-pdf"]');
        if (downloadButton && !downloadButton.hasAttribute('data-html2canvas-ignore')) {
            downloadButton.setAttribute('data-html2canvas-ignore', 'true');
        }
    }

    private initCoolCalc(userId: string) {
        this.tokens$.subscribe((res) => {
            if (res && this.router.url.includes('/cool-calc')) {
                this.errorService.errorStatus$.next(0);
                const config = {
                    sessionUserURL: `${environment.api.coolCalc.sessionUserURL}?userId=${userId}`,
                    khipuConfigURL: environment.api.coolCalc.khipuConfigURL,
                    APIDomain: environment.api.coolCalc.APIDomain,
                    localDomain: `${environment.gatewayPathNoProtocol}/`,
                    APIClientEndpoint: `${environment.api.coolCalc.APIClientEndpoint}/${res.claims.preferred_username}`,
                    MJ8AjaxURL: environment.api.coolCalc.MJ8AjaxURL,
                    MJ8ReportURL: environment.api.coolCalc.MJ8ReportURL,
                    SVGIconURL: environment.api.coolCalc.SVGIconURL,
                    mapTraceConfigURL: environment.api.coolCalc.mapTraceConfigURL,
                    addlRequestHeaders: [
                        {
                            headerName: 'authorization',
                            headerValue: `Bearer ${res.idToken}`
                        },
                        {
                            headerName: 'appid',
                            headerValue: environment.appId
                        }
                    ]
                };

                myFunFactory.LoaderStart = () => {
                    this.coolCalcService.isCoolCalcLoading = true;
                    this.removeElementsFromPdf();
                };

                myFunFactory.LoaderEnd = () => {
                    this.ngZone.run(() => {
                        this.coolCalcService.isCoolCalcLoading = false;
                        this.isModalVisible = false;
                    });
                };

                this.ngZone.runOutsideAngular(() => {
                    libMJ8.setConfig(config, () => libMJ8.landingPage(myFunFactory, res));
                });
            }
        });
    }
}
