<div class="hvac-bookmarks-slide-container" [hidden]="!(bookmarkSlide$ | async)" [ngClass]="{ 'active' : (bookmarkSlide$ | async) === true }">
    <div class="hvac-bookmarks-slide-header">
        <h2 class='hvac-bookmarks-slide-title'>
            {{sliderTitle[activeTab.tab] | translate}}
        </h2>
        <button hvacButton="secondary" (click)="toggleSlider(false)">
            <utc-svg src="assets/icons/close.svg"></utc-svg>
            <span class="hvac-accessible-text">{{ 'DASHBOARD.NEXT.CLOSE' | translate }}</span>
        </button>
    </div>
    <div class="hvac-bookmark-tab">
        <div class="hvac-tab-bookmark" [ngClass]="{ 'hvac-tab-selected' : activeTab.tab === 'bookmark' }">
            <button class="bookmark" (click)="bookmarkSelect()">
                <span class="hvac-accessible-text">{{ 'HEADER.ICON_LINKS.FILE_FOLDER' | translate }}</span>
                <utc-svg src="assets/icons/bookmark-solid.svg"></utc-svg>
            </button>

        </div>
        <div class="hvac-tab-recent-activity" [ngClass]="{ 'hvac-tab-selected' : activeTab.tab === 'recentActivity' }">
            <button class="recent-activity" (click)="recentActivitySelect()">
                <span class="hvac-accessible-text">{{ 'HEADER.ICON_LINKS.FILE_FOLDER' | translate }}</span>
                <utc-svg src="assets/icons/clock.svg"></utc-svg>
            </button>
        </div>
    </div>
    <div class="hvac-bookmarks-slide-list">
        <hvac-bookmark-list *ngIf="activeTab.tab === 'bookmark' "></hvac-bookmark-list>
        <recent-activities *ngIf="activeTab.tab === 'recentActivity' "></recent-activities>
    </div>

</div>
<!-- overlay curtain -->
<div (click)="toggleSlider(false)" class='hvac-bookmarks-slide-overlay'
    [ngClass]="{'hvac-bookmarks-slide-overlay-active': (bookmarkSlide$ | async) === true}">
</div>
