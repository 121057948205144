import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'common/components/base/base.component';
import { ProductAlert } from 'private/app/models/alert.model';
import { InfinityProductDetailsData, Pagination, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ApiProtocol, ConnectedSystem } from 'private/app/models/connected-system.model';
import { WallControlHumidAndVent, WallControlHumidAndVentResponse, WallControlNotifications, WallControlSystemInfo, WallControlSystemInfoResponse, WallControlZoning, WallControlStatus, WallControlDetails } from 'private/app/models/wall-control.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { DataSharingService, DataSharingOptions } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { MqttActivationManagerService } from 'private/app/services/connected-portal/mqtt-activation-manager.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WallControlEventLogConfig, WallControlEventLogEvent } from '../components/wall-control-event-log/wall-control-event-log.component';
import { ProductDetailContextService } from '../../product-detail-context.service';
import { ApiResponseState, MISSING_PRODUCT_DATA, MISSING_ROUTE_PARAMS_ERROR } from 'private/app/views/connected-portal/constants';
import { ErrorService } from 'common/content/services/error.service';

export const PAGINATION_PAGE_SIZE = 10;

interface EventLogData extends Pagination {
    config: WallControlEventLogConfig;
    items: WallControlEventLogEvent[];
}

interface NotificationsData extends Pagination {
    items: WallControlNotifications[];
}


@Component({
    selector: 'hvac-infinity-wall-control-detail',
    templateUrl: './infinity-wall-control-detail.component.html',
    styleUrls: ['./infinity-wall-control-detail.component.scss']
})
export class InfinityWallControlDetailComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;

    public readonly ApiResponseState = ApiResponseState;
    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public productData$: Observable<InfinityProductDetailsData>;
    public wallControlDetails$: Observable<WallControlDetails>;
    public wallControlStatus$: Observable<WallControlStatus>
    public wallControlZoning$: Observable<WallControlZoning[]>;
    public wallControlHumidAndVent$: Observable<WallControlHumidAndVent>;
    public wallControlSystemInfo$: Observable<WallControlSystemInfo>;
    public wallControlNotificationsData$: Observable<NotificationsData>;
    public wallControlNotificationsCurrentPage$ = new BehaviorSubject<number>(1);
    public wallControlEventLogData$: Observable<EventLogData>
    public wallControlEventLogCurrentPage$ = new BehaviorSubject<number>(1);
    public systemConditionsZonesDataState$: Observable<ApiResponseState>;
    public toggleAccordion$ = new Subject<keyof typeof this.accordions$.value>();
    public accordions$ = new BehaviorSubject({
        detailedStatus: false,
        notification: false,
        systemInformation: false,
        humidityAndVentilation: false,
        zoning: false,
        eventLog: false,
        config: false
    });

    private apiProtocol?: ApiProtocol;

    constructor(
        public contextService: ProductDetailContextService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private alertService: AlertsService,
        private mqttActivationManager: MqttActivationManagerService,
        private route: ActivatedRoute,
        private router: Router,
        private errorService: ErrorService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlStatus$ = this.productService
            .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false));

        this.productData$ = combineLatest([
            this.isWallControlConnected$,
            this.system$
        ]).pipe(
            map(([isWallControlConnected, system]) => {
                const productData = system?.products.find((product) => product.type === ProductType.WallControl);

                if (productData) {
                    return {
                        isWallControlConnected,
                        productInfo: productData
                    };
                }

                throw new Error(MISSING_PRODUCT_DATA);
            }),
            catchError((err) => {
                this.errorService.errorStatus$.next(404);

                throw err;
            })
        );

        this.wallControlDetails$ = this.productService
            .queryWallControlBySerialNo(this.serialNo, this.dealerId, this.systemType)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.systemConditionsZonesDataState$ = this.wallControlDetails$
            .pipe(
                switchMap((data) => {
                    if (data.zones.length) {
                        const zoneName = data.zones[0]?.name;
                        if (zoneName) {
                            const zonePath = encodeURIComponent(zoneName);

                            return from(this.router.navigate([zonePath], {
                                relativeTo: this.route,
                                queryParams: { scroll: false },
                                queryParamsHandling: 'merge'
                            })).pipe(map((navigationSuccess) => (navigationSuccess ? ApiResponseState.Success : ApiResponseState.Error)));
                        }
                    }

                    throw new Error(MISSING_ROUTE_PARAMS_ERROR);
                }),
                startWith(ApiResponseState.Loading),
                catchError(() => of(ApiResponseState.Error))
            );

        this.wallControlZoning$ = this.productService
            .queryWallControlZoningBySerialNo(this.serialNo, this.dealerId)
            .pipe(map((res) => res.data));

        this.wallControlHumidAndVent$ = this.productService
            .queryWallControlHumidAndVentBySerialNo(this.serialNo, this.dealerId, this.systemType)
            .pipe(map((res: WallControlHumidAndVentResponse) => res.data));

        this.wallControlSystemInfo$ = this.productService
            .queryWallControlSystemInfoBySerialNo(this.serialNo, this.dealerId)
            .pipe(map((res: WallControlSystemInfoResponse) => res.data));

        this.wallControlNotificationsData$ = this.wallControlNotificationsCurrentPage$
            .pipe(
                switchMap((currentPage) => this.productService
                    .queryWallControlNotificationsBySerialNo(
                        this.serialNo,
                        this.dealerId,
                        {
                            pagination: {
                                currentPage,
                                pageSize: PAGINATION_PAGE_SIZE
                            }
                        }
                    ).pipe(
                        map((res): NotificationsData => ({
                            items: res.data,
                            currentPage,
                            totalPages: res.totalPages
                        }))
                    ))
            );

        this.wallControlEventLogData$ = combineLatest([
            this.wallControlEventLogCurrentPage$,
            this.system$.pipe(map((system) => system?.systemId))
        ]).pipe(
            filter(([, systemId]) => Boolean(systemId)),
            switchMap(([currentPage, systemId]) => this.alertService
                .queryAlertsBySystemId(
                    systemId as string,
                    this.systemType,
                    this.dealerId,
                    {
                        pagination: {
                            currentPage,
                            pageSize: PAGINATION_PAGE_SIZE
                        },
                        sort: {
                            field: 'dateTime',
                            order: 'desc'
                        }
                    }
                ).pipe(
                    map(({ data, totalPages }) => {
                        const items = this.formatProductAlertsToEventLogItems(data);
                        const config = new WallControlEventLogConfig();

                        return {
                            config,
                            items,
                            totalPages,
                            currentPage
                        };
                    })
                )),
            takeUntil(this.ngOnDestroy$)
        );

        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);

                    if (system.apiProtocol === ApiProtocol.MQTT) {
                        this.mqttActivationManager.addControl(this.serialNo, this.dealerId);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();

        if (this.apiProtocol === ApiProtocol.MQTT && this.serialNo) {
            this.mqttActivationManager.removeControl(this.serialNo);
        }

        super.ngOnDestroy();
    }

    private formatProductAlertsToEventLogItems(productAlerts: ProductAlert[]): EventLogData['items'] {
        return [...productAlerts]
            .map((alert): WallControlEventLogEvent => ({
                code: alert.error.code,
                dateTime: alert.dateTime,
                severity: alert.error.severity,
                type: alert.error.type,
                description: alert.error.description,
                consecutiveEvents: alert.occurrenceCount,
                equipmentSource: alert.source,
                diagnosticsUrl: alert.error.troubleshootUri
            }))
            .sort((itemA, itemB) => (itemA && itemB ? itemB.dateTime.localeCompare(itemA.dateTime) : 0));
    }
}
