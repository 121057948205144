import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountAdminFormResultMap } from 'private/app/models/account-admin-search';
import { PlaceHolderText } from 'private/app/models/account-admin-company.model';
import { AccountAdminCompanyCreateService, CountryForMasking } from 'private/app/services/account-admin/account-admin-company-create.service';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Option } from '../../../../../common/components/select/select.component';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
@Component({
    selector: 'hvac-location-information',
    templateUrl: './location-information.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LocationInformationComponent implements OnInit {
    @Input() title = '';
    public companyCreateForm = this.companyCreateService.companyCreateForm;
    public maskPhoneNumber: boolean;

    formData: AccountAdminFormResultMap;
    placeHolderByCountry: BehaviorSubject<PlaceHolderText> = new BehaviorSubject<PlaceHolderText>({
        placeHolderPhone: '',
        placeHolderOffice: '',
        placeHolderMobile: '',
        placeHolderFax: '',
        placeHolderTollFree: '',
        placeHolderZipcode: { label: '' }
    });

    constructor(
        public readonly companyCreateService: AccountAdminCompanyCreateService,
        public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        private readonly commonUtils: CommonUtilsService
    ) { }

    ngOnInit() {
        this.companyCreateService.formData.subscribe((formData) => {
            this.formData = formData;
            this.companyCreateService.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(formData.countries) || []);
        });


        this.accountAdminCompanyUtilsService.placeHolderData$.subscribe((placeHolderData) => {
            this.placeHolderByCountry.next(placeHolderData);
        });

        this.companyCreateForm.controls.country.valueChanges.subscribe((selectedCountry: string) => {
            this.companyCreateService.stateOptionData$.next([]);
            if (Array.isArray(selectedCountry)) {
                this.maskPhoneNumber = Boolean(selectedCountry[0].value === CountryForMasking.US || selectedCountry[0].value === CountryForMasking.CA);
                this.accountAdminCompanyUtilsService.formInputPlaceHolder(selectedCountry[0].value);
                this.getStateListByCountry(selectedCountry[0].value);
            }
            else {
                const countrySelection = this.companyCreateService.countryOptionData$.value.find((country) => country.name === selectedCountry);
                if (countrySelection) {
                    this.accountAdminCompanyUtilsService.formInputPlaceHolder(countrySelection.value);
                    this.getStateListByCountry(countrySelection.value);
                }
            }
            this.companyCreateForm.controls.state.setValue('');
        });
    }

    onCountryChange(option: Option[]) {
        this.companyCreateService.company.country = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getCountryCodeByCountry(option[0].name, this.companyCreateService.countryOptionData$.value) : '';
    }

    onStateChange(option: Option[]) {
        this.companyCreateService.company.state = Array.isArray(option) ? this.accountAdminCompanyUtilsService.getRegionCodeByState(option[0].name, this.companyCreateService.stateOptionData$.value) : '';
    }

    private getStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateByCountry(selectedCountry).subscribe(
            (stateList) => {
                this.companyCreateService.stateOptionData$.next(stateList);
            },
            (error) => {
                this.companyCreateService.stateOptionData$.error(error);
            }
        );
    }
}
