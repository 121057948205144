import { Component, Input } from '@angular/core';

export interface ConnectedPortalStat {
    label: string;
    value: string | number | undefined;
    comingSoon?: boolean;
}

@Component({
    selector: 'hvac-connected-portal-stats',
    templateUrl: './connected-portal-stats.component.html',
    styleUrls: ['./connected-portal-stats.component.scss']
})
export class ConnectedPortalStatsComponent {
    @Input() statsData: ConnectedPortalStat[] = [];
    @Input() isLoading?: boolean;
}
