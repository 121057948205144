<utc-base-layout class="hvac-product-literature-page">
    <hvac-content-header [isLandingPage]="true"></hvac-content-header>
    <section class="havc-product-literatture-container">
        <div class="hvac-content-container" *ngIf="showToggle">
            <div class="hvac-col-xs-12">
                <div class="toggle-group">
                    <utc-checkbox
                    [inputName]="option"
                    [inputId]="option"
                    *ngFor="let option of viewOptions"
                    class="toggle-group-button"
                    [class.checked]="searchView.value === option"
                    [checked]="searchView.value === option"
                    [value]="option"
                    (change)="toggleSearchType(option)">{{ option }}</utc-checkbox>
                </div>
            </div>
        </div>
        <hvac-product-literarture-breadcrumbs *ngIf="searchView.value === 'products'; else partsSelectors" (productURNData)="setProductURNData($event)"></hvac-product-literarture-breadcrumbs>
        <ng-template #partsSelectors>
        <div class="hvac-content-container">
            <hvac-parts-selectors (setCategory)="setCategory($event)"></hvac-parts-selectors>
        </div>
        </ng-template>
        <hvac-document-result *ngIf="(productURNData$ | async).length > 0 || (category$ | async).length; else noSearchQuery" [urnData]="productURNData$" [categoryData]="category$"></hvac-document-result>
        <ng-template #noSearchQuery>
            <div class="no-search-query-hint">
                <label *ngIf="searchView.value === 'products'; else parts">{{'PRODUCTS_LITERATURE.DOCUMENT_SEARCH_HINT' | translate}}</label>
                <ng-template #parts>
                    <label>{{'PRODUCTS_LITERATURE.DOCUMENT_SEARCH_HINT_PARTS' | translate}}</label>
                </ng-template>
            </div>
        </ng-template>
    </section>
</utc-base-layout>
