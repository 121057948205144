import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { BaseChartDirective } from 'ng2-charts';
import { DealerLoginsReportEntry, DealerLoginsReporttUsage } from 'private/app/models/distributor.model';
import { ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { DistributorsService } from 'private/app/services/connected-portal/distributors.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'hvac-dealer-logins-report',
    templateUrl: './dealer-logins-report.component.html',
    styleUrls: ['./dealer-logins-report.component.scss']
})
export class DealerLoginsReportComponent extends BaseComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    @Input() distributorId: string;

    public data: DealerLoginsReportEntry[] | null = null;
    public filepath:string;
    public isLoading = false;
    public chartOptions: ChartConfiguration['options'];
    public chartType: ChartType = 'line';
    public chartData: null | ChartData<'line'> = null;
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);

    private maxTickCount = {
        mobile: 4,
        tablet: 12
    }

    private pointDivisor = {
        mobile: 3,
        tablet: 1
    }

    constructor(
        private distributorsService: DistributorsService,
        private chartConfigService: ChartConfigService,
        private cdr: ChangeDetectorRef,
        private breakpointService: BreakPointService,
        private csvExportService: CSVExportService,
        private readonly datePipe: DatePipe
    ) {
        super();
    }

    ngOnInit(): void {
        const selectedYear = parseInt(this.yearSelectFormControl.value, 10);

        this.loadChartData(selectedYear).subscribe((res) => {
            this.handleReportData(res);
        });

        this.yearSelectFormControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((year) => {
                const parsedYear = parseInt(year, 10);

                return this.loadChartData(parsedYear);
            })
        )
            .subscribe((res) => {
                this.handleReportData(res);
            });

        this.breakpointService.breakpoint$.pipe(
            filter(() => Boolean(this.data)),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((_value) => {
            if (this.data) {
                this.configureChart(this.data);
                this.cdr.detectChanges();
            }
        });
    }

    public handleRunReport() {
        if (this.filepath) {
            this.csvExportService.downloadFile(this.filepath);
        }
    }

    private loadChartData(year: number) {
        this.isLoading = true;
        const { startDate, endDate } = this.chartConfigService.getStartEndDateFromYear(year);

        return this.distributorsService
            .queryDealerLoginsByDistributorId(this.distributorId, startDate, endDate)
            .pipe(

                takeUntil(this.ngOnDestroy$),
                catchError((err) => {
                    this.isLoading = false;

                    throw err;
                })
            );
    }

    private getChartLabelsAndDataset(year: number, data: DealerLoginsReportEntry[]) {
        const monthDates = this.chartConfigService.getMonthsOfYear(year);
        const monthLoginCount = monthDates.map(((monthDate) => ({
            dateTime: monthDate,
            label: this.datePipe.transform(monthDate, 'MMM')?.toUpperCase() || '',
            count: data.filter((entry) => {
                if (entry.dateTime) {
                    return new Date(entry.dateTime).getMonth() === monthDate.getMonth();
                }

                return false;
            }).length
        })));

        const labels = monthLoginCount.map((item) => item.label);
        const dataset = monthLoginCount.map((item) => item.count);

        return {
            labels,
            dataset
        };
    }

    private configureChart(data: DealerLoginsReportEntry[]) {
        if (data === null) {
            return;
        }

        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
        const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
        const { labels, dataset } = this.getChartLabelsAndDataset(this.yearSelectFormControl.value, data);

        this.chartOptions = this.chartConfigService.getLineChartConfig(dataset, xScaleMaxTicks);
        this.chartData = this.chartConfigService.getLineChartData(labels, dataset, pointDivisor);
    }

    private handleReportData(res: DealerLoginsReporttUsage) {
        this.isLoading = false;
        this.data = res.data?.length ? res.data : null;
        this.filepath = res.filepath ?? '';
        this.configureChart(this.data || []);
    }
}
