
/* eslint-disable no-undefined */
import { Injectable } from '@angular/core';
import { GMTTimeFormattingOptions } from '../../private/app/models/account-admin-program.model';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

@Injectable({ providedIn: 'root' })
export class GMTTimeConversionUtilsService {
    public getGMTTimeFromDateValue(dateTime: number | undefined, options?: GMTTimeFormattingOptions): number | undefined {
        if (!dateTime) {
            return dateTime;
        }

        const date = new Date(dateTime);
        if (options) {
            if (options.formatTimeAs === 'StartOfDay') {
                return startOfDay(this.getGMTCorrectedTime(date)!).getTime();
            }
            else if (options.formatTimeAs === 'EndOfDay') {
                return endOfDay(this.getGMTCorrectedTime(date)!).getTime();
            }
            throw new Error('Options case not handled');
        }

        return new Date(this.getGMTCorrectedTime(date)!).getTime();
    }

    public getCurrentGMTTime(options: GMTTimeFormattingOptions): number {
        return this.getGMTTimeFromDateValue(new Date().getTime(), options)!;
    }

    public getGMTTimeFromDate(dateValue: string | undefined, options: GMTTimeFormattingOptions): number | undefined {
        if (!dateValue) {
            return undefined;
        }

        return this.getGMTTimeFromDateValue(new Date(dateValue).getTime(), options);
    }

    private getGMTCorrectedTime(date: Date | undefined): number | undefined {
        if (!date) {
            return date;
        }

        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000).getTime();
    }
}
