
<div class="hvac-row hvac-create-company-dropdowns">
    <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-6">
        <div class="hvac-row">
            <h2 class="hvac-header hvac-col-xs-12 hvac-col-md-4 hvac-col-lg-4">
                {{
                "ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.COMPANY_CATEGORY"
                | translate
                }}
            </h2>
            <utc-select shadow="light" class="hvac-col-lg-6 hvac-col-xs-12 hvac-col-md-8 hvac-col-lg-8"
                [group]="companyDesignationForm" defaultOption="{{
                                        'ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.SELECT_CATEGORY'
                                            | translate
                                    }}" [options]="companyCategory" control="companyCategoryControl">
            </utc-select>
        </div>
    </div>
    <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-6">
        <div class="hvac-row">
            <h2 class="hvac-header hvac-col-xs-12 hvac-col-md-4 hvac-col-lg-4">
                {{
                "ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.COMPANY_TYPE"
                | translate
                }}
            </h2>
            <utc-select shadow="light" class="hvac-col-xs-12 hvac-col-md-8 hvac-col-lg-8" [group]="companyDesignationForm" control="companyTypeControl"
                defaultOption="{{
                'ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.SELECT_COMPANY' | translate
            }}" [options]="(companyTypeData$ | async) || []"></utc-select>
        </div>
    </div>
</div>

