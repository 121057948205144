<div class="hvac-cp-search-bar">
    <h2 class="hvac-h3 hvac-cp-search-bar-title">{{ label }}</h2>

    <div class="hvac-cp-search-container">
        <form (submit)="handleSearchSubmit()">
            <utc-search-input
            [formControl]="queryControl"
            name="q"
            inputId="hvac-cp-nav-search"
            autocomplete="off"
            theme="light"
            [label]="label"
            [title]="label"
            [placeholder]="placeholder"
            type="text"
            [enableClearButton]="queryControl.value"
            [hideLabel]="true"
            [headerSearchInput]="true"
            (closeSearch)="handleSearchReset()"></utc-search-input>

            <div class="hvac-cp-search-submit-button hvac-input-action-wrap">
                <button hvacButton="icon" type="submit" [title]="label" [attr.aria-label]="label">
                    <utc-svg src="assets/icons/search.svg"></utc-svg>
                </button>
            </div>
        </form>

        <button
            *ngIf="showAdvancedSearchButton"
            hvacButton="default"
            (onclick)="handleAdvancedSearchClick()">{{ 'CONNECTED_PORTAL.CUSTOMERS.SEARCH.ADVANCED_SEARCH' | translate }}</button>
    </div>
</div>
