<section *ngIf="showTraining$ | async" class="hvac-product-details-content-section">
    <div class="hvac-product-content-section-overview">
        <div class="hvac-product-content-section-overview-description">
            <h2 class="hvac-section-heading" [attr.id]="'PRODUCTS.TRAINING' | translate" tabindex="-1">{{ 'PRODUCTS.TRAINING' | translate }}</h2>
        </div>
    </div>
    <ul class="hvac-product-details-content-training">
        <li class="hvac-product-details-content-training-course" *ngFor='let course of courses$ | async'>
            <ng-container *ngIf="!(loggedIn$ | async); else loggedInIcon">
                <utc-tooltip>
                    <utc-tooltip-anchor-content>
                        <ng-container *ngTemplateOutlet="iconContent"></ng-container>
                    </utc-tooltip-anchor-content>
                    <utc-tooltip-content>
                        <h4 class='hvac-tooltip-heading'>Secure Content</h4>
                        <p class='hvac-tooltip-description'>Please sign in to view this content.</p>
                    </utc-tooltip-content>
                </utc-tooltip>
            </ng-container>

            <ng-template #loggedInIcon>
                <ng-container *ngTemplateOutlet="iconContent"></ng-container>
            </ng-template>

            <ng-template #iconContent>
                <a class="hvac-product-details-content-training-course-link" href="{{ course.url }}" target="_blank">
                    <div class="hvac-product-details-training-icon">
                        <utc-svg class="hvac-product-details-training-video-icon" [src]="'assets/icons/video-monitor.svg'"></utc-svg>
                        <utc-svg *ngIf="!course.public && !(loggedIn$ | async)" class="hvac-product-details-training-video-secure-icon" [src]="'assets/icons/lock.svg'"></utc-svg>
                    </div>
                    <div class="hvac-product-details-training-details">
                        <h3 class="hvac-product-details-training-title" [innerHTML]="course.title | safe:'html'"></h3>
                        <p class="hvac-product-details-training-description" ellipsis [innerHTML]="course.description | safe:'html'"></p>
                    </div>
                </a>
            </ng-template>
        </li>
    </ul>
</section>
