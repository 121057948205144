import { BaseResponse } from 'common/models/account-status';
import { TranslateService } from '@ngx-translate/core';
import { ApiOptions, ApiOptionsService } from './../../../common/services/api-options/api-options.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/internal/operators/catchError';
import { switchMap } from 'rxjs/operators';
import { environment } from 'common/environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import { CompanyDetails, FilteredOption, RelationshipTypesUpdated } from '../models/distributor-relationships.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Option } from 'common/components/select/select.component';
import { Brand } from './user-validation/user-validation.service';
import { Company } from '../models/account-admin-search';
import { CompanyRelationshipsResult, RelationshipTabOption } from '../models/account-admin-company.model';

@Injectable({ providedIn: 'root' })
export class DistributorService implements OnDestroy {
    isLoadMoreData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public pagination: number;
    public allPriceWarrantyOptions$ = new BehaviorSubject<Option[]>([]);
    public allCompanyInformation$ = new BehaviorSubject<Option[]>([])
    public allWarrantyOptions$ = new BehaviorSubject<Option[]>([]);
    public allPricingOptions$ = new BehaviorSubject<Option[]>([]);
    public relationshipTypes$ = new BehaviorSubject<RelationshipTypesUpdated[]>([] as RelationshipTypesUpdated[]);
    public allPriceInfo: Option[];
    public selectedBrand: string = '';
    public allPriceAddressInfo: Option[];
    public company: Company;
    public modalInformation$ = new BehaviorSubject<CompanyDetails[]>([] as CompanyDetails[]);
    public companyDetails$: BehaviorSubject<CompanyDetails | null> = new BehaviorSubject<CompanyDetails | null>(null);
    public readonly relationshipTabOption: typeof RelationshipTabOption = RelationshipTabOption;
    public showManageRelationshipModal: boolean = false;
    public selectedRelationShipTab: RelationshipTabOption = RelationshipTabOption.BRAND;
    public isSuccessfullySubmitted: boolean;
    private ngOnDestroy$ = new Subject();

    constructor(
        private readonly apiOptions: ApiOptionsService,
        private readonly httpClient: HttpClient,
        private translateService: TranslateService
    ) {}

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    filterBrands(options: FilteredOption[]) {
        const filteredOptions = options.filter((item: Brand) => (item.usageType && item.usageType.includes('PRODUCTS')) || item.name === this.translateService.instant('ACCOUNT_ADMIN.SELECT_BRAND'));

        return filteredOptions;
    }


    public postAssociationsData(associations:CompanyRelationshipsResult) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.accountAdminRelationships}`, associations, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getCompanyRelationships(hvacCompanyId: string): Observable<CompanyRelationshipsResult> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<CompanyRelationshipsResult>(`${environment.api.accountAdminRelationships}?hvacCompanyID=${hvacCompanyId}`, { ...options })),
            catchError((err:HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
