<div class="hvac-fault-source-report">
    <hvac-report-chart-container
        [chartTitle]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SOURCE.HEADER' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SOURCE.SUBHEAD' | translate"
        [hasData]="!!(hasData$ | async)"
        [noDataMessage]="'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.EMPTY_MESSAGE'| translate"
        [isLoading]="isLoading"
        yearSelectId="hvac-fault-source-year-select"
        [yearSelectFormControl]="yearSelectFormControl"
        [yearSelectOptions]="yearSelectOptions"
        [hasReportButton]="false">
            <ng-container *ngIf="(chartOptions$ | async) as chartOptions;">
                <canvas *ngIf="(chartData$ | async) as chartData" baseChart class="hvac-chart" [data]="chartData" [options]="chartOptions" [type]="chartType"></canvas>
            </ng-container>

            <div legend class="hvac-report-legend" *ngIf="!!(hasData$ | async)">
                <div class="hvac-report-legend-item" *ngFor="let item of legendData">
                    <span class="hvac-report-legend-indicator" [style]="'background-color: ' + item.color"></span> <span
                        class="hvac-report-legend-label hvac-subtext">{{item.label}}</span>
                </div>
            </div>
    </hvac-report-chart-container>
</div>
