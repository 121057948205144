import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AccountAdminBrandsItemsEntity, AccountAdminContactType, AccountAdminManagersEntity, RelationshipType } from 'private/app/models/account-admin-search';
import { environment } from 'common/environments/environment';
import { CompanyCategoryResult } from './account-admin-utils.service';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';

@Injectable({ providedIn: 'root' })
export class AccountAdminExtendedService {
    private companyTypeOptionsCache: CompanyCategoryResult[] | undefined;
    private brandOptionsCache: AccountAdminBrandsItemsEntity[] | undefined;
    private contactTypeOptionsCache: AccountAdminContactType[] | undefined;
    private managersListCache: AccountAdminManagersEntity | undefined;
    private relationshipTypeOptionsCache: RelationshipType[] | undefined;
    private legalEntityOptionsCache: CompanyResultDataResponse[] | undefined;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    public getCompanyTypes(): Observable<CompanyCategoryResult[]> {
        if (this.companyTypeOptionsCache) {
            return of(this.companyTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<CompanyCategoryResult[]>(environment.api.accountAdminCompanyTypes, { ...options })),
            tap((companyTypes) => this.companyTypeOptionsCache = companyTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getBrands(): Observable<AccountAdminBrandsItemsEntity[]> {
        if (this.brandOptionsCache) {
            return of(this.brandOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminBrandsItemsEntity[]>(environment.api.accountAdminBrands, { ...options })),
            tap((brands) => this.brandOptionsCache = brands),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getContactTypes(): Observable<AccountAdminContactType[]> {
        if (this.contactTypeOptionsCache) {
            return of(this.contactTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminContactType[]>(environment.api.accountAdminContactTypes, { ...options })),
            tap((contactTypes) => this.contactTypeOptionsCache = contactTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getManagers(): Observable<AccountAdminManagersEntity> {
        if (this.managersListCache) {
            return of(this.managersListCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<AccountAdminManagersEntity>(environment.api.accountAdminManagers, { ...options })),
            tap((managers) => this.managersListCache = managers),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public getRelationshipTypes(): Observable<RelationshipType[]> {
        if (this.relationshipTypeOptionsCache) {
            return of(this.relationshipTypeOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<RelationshipType[]>(environment.api.accountAdminRelationshipTypes, { ...options })),
            tap((relationTypes) => this.relationshipTypeOptionsCache = relationTypes),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public legalEntities(): Observable<CompanyResultDataResponse[]> {
        if (this.legalEntityOptionsCache) {
            return of(this.legalEntityOptionsCache);
        }

        const options$ = this.apiOptions.getAuthedHttpOptions();
        const body = {
            criteria: {
                companyCriteria: {
                    locationTypes: [
                        'MAIN'
                    ]
                },
                contactTypeIds: [],
                includeContactsInResponse: false
            },
            pageParams: {
                page: 0,
                pageSize: 50
            },
            determineTotalCount: true
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<CompanyResultDataResponse[]>(environment.api.accountAdminCompanySearch, body, { ...options })),
            tap((legalEntities) => this.legalEntityOptionsCache = legalEntities),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
