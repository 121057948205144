<div class="hvac-default-theme hvac-report-chart-container">
    <h4 class="hvac-chart-title hvac-h4">{{ chartTitle }}</h4>
    <div *ngIf="isLoading; else content" class="hvac-loading-spinner">
        <utc-loading-spinner></utc-loading-spinner>
    </div>
    <ng-template #content>
        <div class="hvac-subtitle-select-group">
            <div class="hvac-chart-subtitle hvac-label-sm">{{ chartSubtitle }}</div>
            <div class="hvac-year-select">
                <hvac-select-new [selectId]="yearSelectId" size="small" shadow="none"
                    [formControl]="yearSelectFormControl" [options]="yearSelectOptions"></hvac-select-new>
            </div>
        </div>
        <div *ngIf="hasData; else noData">
            <div class="hvac-chart-container">
                <ng-content></ng-content>
            </div>
            <div class="hvac-report-bottom-container">
                <ng-content select="[legend]"></ng-content>

                <div class="hvac-report-button-container" *ngIf="hasReportButton">
                    <button class="hvac-report-button" [disabled]="isReportButtonDisabled" hvacButton="default"
                        size="small" (click)="handleRunReport()">{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.RUN_REPORT' | translate }}</button>
                </div>
            </div>
        </div>
        <ng-template #noData>
            <div class="hvac-no-data" *ngIf="!noDataMessage; else noAlertsData">
                <h5 class="hvac-h5">{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.NO_CHART_DATA' | translate }}</h5>
            </div>
        </ng-template>
        <ng-template #noAlertsData>
            <div class="hvac-connected-portal-alerts-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.EMPTY_MESSAGE' | translate }}</div>
        </ng-template>
    </ng-template>
</div>
