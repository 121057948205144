import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'private/app/components/components.module';

import { AuthenticatingComponent } from './authenticating.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule
    ],
    declarations: [
        AuthenticatingComponent
    ]
})
export class AuthenticatingModule { }
