import { PrivateConfig, PrivateEnvironment } from '../../models/environment.private';

export const commonEnvVars = (environment: PrivateConfig): PrivateEnvironment => ({
    envName: environment.envName,
    addressDoctor: {
        baseUrl: 'https://validator5.addressdoctor.com',
        api: '/Webservice5/v2/AddressValidation.asmx',
        cred: {
            login: `${environment.addressDoctor.login}`,
            password: `${environment.addressDoctor.password}`
        },
        ProcessMode: 'INTERACTIVE',
        MaxResultCount: 11,
        GlobalPreferredDescriptor: 'SHORT',
        RangesToExpand: 'All'
    },
    api: {
        acceleratedSignUp: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/accelerated/signup`,
        accountApprovalRequestsApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/userApprovalRequests`,
        accountCompanyApi: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}`,
        accountCreateApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}`,
        accountAdminDistributorBranchApi: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/distributor/branches?hvacCompanyID=`,
        accountEmailApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/mail`,
        accountEmailCodeApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/mail/verification`,
        accountEmailVerificationApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/mail/verification/code`,
        accountNotificationRemoveApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/notification`,
        accountQuicklinksPersonalizationApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/dashboard/quicklinks`,
        accountStatusApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/v1/status`,
        accountUserApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/`,
        deactivateUserApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/deactivate`,
        accountUserPrefApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/settings`,
        bookmarks: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/bookmark`,
        brandCodeApi: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/auth/all/brands`,
        catalog: `${environment.gatewayPath}/apps/hvac/partners/secure/catalog${environment.envPath}`,
        accountAdminRelationshipTypes: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/companies/relationshipTypes`,
        accountAdminCompanyTypes: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/companies/companyTypes`,
        accountAdminBrands: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/brands`,
        accountAdminCompanyById: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/details`,
        accountAdminUpdateCompanyDetails: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/companies/update`,
        accountAdminParentCompanyById: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/parents`,
        accountAdminDistributorAssociations: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/associations`,
        accountAdminCompanyServices: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/services`,
        accountAdminCompanyDealerLocatorBrands: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/locator/brands`,
        accountAdminCompanyRelationshipsById: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/company/{id}/relationships`,
        accountAdminCompanyDeactivateById: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/deactivate`,
        accountAdminCompanyReactivateById: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/reactivate`,
        accountAdminCompanySearch: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/auth/search/company`,
        accountAdminContactById: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/user`,
        accountAdminContacts: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts`,
        accountAdminContactsById: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts`,
        accountAdminContactsByCompany: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/company`,
        accountAdminUsersByCompany: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/company/registrations`,
        accountAdminContactSearch: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/auth/search/user`,
        accountAdminUserSearch: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/users/auth/search`,
        accountAdminUserExport: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/users/auth/search/export`,
        accountAdminContactTypes: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/types`,
        accountAdminRelationships: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/relationships`,
        accountAdminCountries: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/locations/countries`,
        accountAdminExport: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/contacts/auth/search/company/export`,
        accountAdminManagers: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/managers`,
        accountAdminUserById: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/user`,
        accountAdminUserRegistrationDetails: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/registration/details`,
        accountAdminUserCreateRegisterDetails: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/create`,
        accountAdminUserRegistrationRequestDetails: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/registrations`,
        accountAdminUserAuthorizedRoles: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/authorized/roles`,
        accountAdminUserAuthorizedFeatures: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/authorized/features`,
        accountAssociatedDistributor: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/associations`,
        accountAdminProgramSearch: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/search`,
        accountAdminProgramBrands: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/brands`,
        accountAdminProgram: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}`,
        accountAdminProgramEligibilityCriteria: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/eligibility/criteria`,
        accountAdminProgramCriteria: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/criteria`,
        accountAdminProgramEligibleCompanies: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/eligible/companies`,
        accountAdminProgramLocatorIcons: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/locator/icons`,
        accountAdminProgramEnrollments: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/enrollments`,
        accountAdminProgramEnrollmentStatus: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/enrollment/status`,
        accountAdminProgramEnrollmentLevel: `${environment.gatewayPath}/public/hvac/account/program${environment.envPath}/enrollment/level`,
        companies: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/companies`,
        companyId: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/company-id`,
        accountAdminCompany: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}`,
        connectedPortal: {
            'graphql': `${environment.connectedPortal.url}/graphql`,
            'ui/config': `${environment.connectedPortal.url}/ui/config`,
            'webSocketUrl': environment.connectedPortal.webSocketUrl,
            'webSocketKey': environment.connectedPortal.webSocketKey
        },
        content: `${environment.gatewayPath}/apps/hvac/partners/secure/content/pages${environment.envPath}`,
        publicContent: `${environment.gatewayPath}/public/icp/content/pages${environment.envPath}`,
        document: `${environment.gatewayPath}/apps/hvac/partners/secure/document/search${environment.envPath}`,
        documentPublic: `${environment.gatewayPath}/public/icp/documents${environment.envPath}`,
        documentPublicParts: `${environment.gatewayPath}/public/totaline/documents${environment.envPath}`,
        inbox: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/inbox`,
        inboxDetails: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/inbox/details`,
        inboxDownload: `${environment.gatewayPath}/apps/hvac/partners/secure/report/download${environment.envPath}`,
        lastLoginApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/lastlogin`,
        resetPasswordApi: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/changepassword`,
        search: `${environment.gatewayPath}/apps/hvac/partners/secure/search${environment.envPath}`,
        siteMap: `${environment.gatewayPath}/public/hvac/content/sitemap${environment.envPath}/682`,
        suggestions: `${environment.gatewayPath}/apps/hvac/partners/secure/suggest${environment.envPath}`,
        user: `${environment.gatewayPath}/apps/hvac/${environment.idmPath}/users`,
        coolCalcTerms: `${environment.gatewayPath}/public/hvac/account/coolcalc${environment.envPath}/terms`,
        dealerLocatorEnrollments: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/locator/enrollments?hvacCompanyID=`,
        dealerProductOfferings: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/locator/product/offerings`,
        dealerLocatorEnrollmentsPost: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/locator/enrollments`,
        dealerLocatorThirdPartyRating: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/dealer/locator/ratings`,
        validateSessionUser: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/userid/validate`,
        totalineContent: `${environment.gatewayPath}/public/totaline/content/pages${environment.envPath}`,
        coolCalc: {
            sessionUserURL: `${environment.gatewayPath}/public/hvac/account/coolcalc${environment.envPath}/SessionUser`,
            APIClientEndpoint: `public/hvac/account/coolcalc${environment.envPath}/client`,
            APIDomain: `${environment.coolCalcAPIDomain}`,
            MJ8AjaxURL: `public/hvac/account/coolcalc/client/${environment.coolCalcEnv}/mj8-reports.ashx`,
            MJ8ReportURL: `public/hvac/account/coolcalc/${environment.envPath}/client/MJ8Reports`,
            SVGIconURL: 'private/assets/default/icons/danger.svg',
            emailURL: `public/hvac/account/coolcalc/client/${environment.envPath}/send-report.ashx`,
            khipuConfigURL: `https://cdn.coolcalc.com/config/mj8/v-bootstrap/${environment.coolCalcEnv}/khipu-js.json`,
            mapTraceConfigURL: `https://cdn.coolcalc.com/config/map-trace/${environment.coolCalcEnv}/map-trace.json`
        },
        coolCalcSupportRequest: `${environment.gatewayPath}/public/hvac/account/coolcalc${environment.envPath}/support`,
        getCountries: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/countries`,
        getStateListByCountry: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/{{state}}/states`,
        pim: {
            catalog: `${environment.gatewayPath}/public/apps/hvac/pim/rc`,
            suggestions: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products/suggestion`,
            partDetail: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products`,
            search: `${environment.gatewayPath}/public/apps/hvac/pim/rc/products/search`
        },
        profileDetails: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/details`,
        subscription: `${environment.gatewayPath}/public/hvac/account/user${environment.envPath}/auth/subscriptions`,
        tradePartners: {
            openPos: `${environment.gatewayPath}/apps/hvac/openpo/BySupplier`,
            vendorASN: `${environment.gatewayPath}/apps/hvac/inbound/vendors/ASN`,
            pOacknowledgement: `${environment.gatewayPath}/apps/hvac/inbound/vendors/PO_ACK`
        },
        warrantyLookup: `${environment.gatewayPath}/apps/warranty/lookup`,
        validateSoldTo: `${environment.gatewayPath}/public/hvac/account/company${environment.envPath}/validate/soldto`
    },
    appId: environment.appId,
    appziSrc: 'https://w.appzi.io/w.js?token=DET3g',
    auth: {
        createAccountUrl: `${environment.baseUrl}/login`,
        createAccountRegisterUrl: `${environment.baseUrl}/create-account-information`,
        baseUrl: environment.auth.baseUrl,
        clientId: environment.auth.clientId,
        redirectUri: `${environment.baseUrl}/callback`,
        postLogoutRedirectUri: `${environment.baseUrl}/logout`,
        identityProviders: environment.auth.identityProviders
    },
    brand: environment.brand,
    chatbotUrl: environment.chatbotUrl,
    connectedPortalKey: environment.connectedPortal.key,
    documents: environment.documents,
    features: environment.features,
    frameContent: {
        baseUrl: environment.iframePath,
        searchUrl: `${environment.iframePath}/Pages/SearchResultsFrame.aspx?k={{query}}&sc=Site Content&d={{query}}`,
        inboxUrl: `${environment.iframePath}/Pages/partners/InboxViewer.aspx`,
        maxLoaderTime: 6000
    },
    gatewayPath: environment.gatewayPath,
    gatewayPathNoProtocol: environment.gatewayPathNoProtocol,
    googleMapAPI: environment.googleMapAPI,
    loginErrorUrl: `${environment.secureBaseUrl}/login-error`,
    logoutFrameUrl: `${environment.iframePath}/Pages/devpages/Logout.aspx`,
    logoutUrl: `${environment.secureBaseUrl}/sign-out`,
    mocks: environment.mocks,
    primerFrameUrl: `${environment.iframePath}/Pages/GoFast.aspx`,
    production: environment.production,
    secureBaseUrl: environment.secureBaseUrl,
    siteUrls: environment.siteUrls,
    socialLogin: { idpDisplay: 'SECONDARY' },
    googleAddressValidationApi: `https://addressvalidation.googleapis.com/v1:validateAddress?key=${environment.googleMapAPI}`,
    trackingId: environment.trackingId
}
);
