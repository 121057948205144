import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { environment } from 'common/environments/environment';
import { CarrierELTProductDetailsData, Pagination, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { WallControlHumidAndVent, WallControlHumidAndVentResponse, WallControlStatus, EltWallControlConfigEmitProps } from 'private/app/models/wall-control.model';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import { catchError, debounceTime, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { WallControlEventLogConfig, WallControlEventLogEvent } from '../components/wall-control-event-log/wall-control-event-log.component';
import { ProductDetailContextService } from '../../product-detail-context.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { EnergyEventsService } from 'private/app/services/connected-portal/energy-events.service';
import { WallControlEnergyEvent } from 'private/app/models/energy-events.model';
import { EventType } from 'private/app/models/event.model';

const isRuntimeLinkFeatureEnabled = environment.features.connectedPortal.ecobeeNiRuntimeLink;
interface EventLogData extends Pagination {
    config: WallControlEventLogConfig;
    items: WallControlEventLogEvent[];
}

interface WallControlConfigData {
    dealerId: string;
    serialNo: string;
    systemType: SystemType;
    isEditEnabled ?: boolean;
    isSaveEnabled ?: boolean;
    isDeviceConnected: boolean;
    tempUnitFormat: TempUnitFormat;
}

interface WallControlHumidAndVentData {
    comfortProfiles: WallControlHumidAndVent;
    tempUnitFormat: TempUnitFormat;
}
const PAGINATION_PAGE_SIZE = 10;

interface EnergyEventData extends Pagination {
    items: WallControlEnergyEvent[];
}

@Component({
    selector: 'hvac-carrier-elt-wall-control-detail',
    templateUrl: './carrier-elt-wall-control-detail.component.html',
    styleUrls: ['./carrier-elt-wall-control-detail.component.scss']
})
export class CarrierEltWallControlDetailComponent extends BaseComponent implements OnInit {
    @Input() propertyId:string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;
    @Input() dealerId: string;

    public tempUnitFormat$: Observable<TempUnitFormat>;
    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public productData$: Observable<CarrierELTProductDetailsData>;
    public wallControlStatus$: Observable<WallControlStatus>
    public wallControlHumidAndVent$: Observable<WallControlHumidAndVent>;
    public wallControlHumidAndVentData$: Observable<WallControlHumidAndVentData>;
    public wallControlEventLogData$: Observable<EventLogData>;
    public wallControlEventLogCurrentPage$ = new BehaviorSubject<number>(1)
    public wallControlConfigData$: Observable<WallControlConfigData>
    public toggleAccordion$: Subject<keyof typeof this.accordions$.value> = new Subject();
    public eltWallControlConfigProps$: Observable<EltWallControlConfigEmitProps>;
    public systemProperties: Pick<ConnectedSystem, 'vppEligibility'| 'vppActivity'>;
    public accordions$ = new BehaviorSubject({
        detailedStatus: false,
        eventLog: false,
        config: false,
        humidityAndVentilation: false,
        energyEvents: false
    });

    public wallControlEnergyEventData$: Observable<EnergyEventData>;
    public isLoadingEnergyEvents$ = new BehaviorSubject(false);
    public readonly isEltWallControlEnergyEventEnabled = environment.features.connectedPortal.energyEventsEnabled;
    public wallControlEnergyEventCurrentPage$ = new BehaviorSubject<number>(1);
    readonly EventType = EventType;

    constructor(
        public contextService: ProductDetailContextService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private energyEventsService: EnergyEventsService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlStatus$ = this.productService
            .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
            .pipe(
                map((data) => data.data),
                shareReplay()
            );

        this.tempUnitFormat$ = this.wallControlStatus$.pipe(

            map((data) => data.tempUnitFormat as TempUnitFormat)
        );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false));

        this.productData$ = combineLatest([
            this.wallControlStatus$,
            this.isWallControlConnected$,
            this.productService.queryProductBySerialNo(this.serialNo, this.dealerId),
            this.system$
        ]).pipe(
            map(([wallControlStatus, isWallControlConnected, productRes, querySystemsByPropertyId]) => {
                const isRuntimeReportEnabled = (this.systemType === SystemType.CARRIER_ELT) ? (querySystemsByPropertyId?.dataSharing?.viewStatus === 'true') : wallControlStatus.isRuntimeReportEnabled;

                return {
                    isWallControlConnected,
                    isRuntimeReportEnabled: isRuntimeReportEnabled && isRuntimeLinkFeatureEnabled,
                    productInfo: productRes.data,
                    modelName: productRes.data.name
                };
            }),
            shareReplay()
        );

        this.wallControlHumidAndVent$ = this.productService
            .queryWallControlHumidAndVentBySerialNo(this.serialNo, this.dealerId, this.systemType)
            .pipe(map((res: WallControlHumidAndVentResponse) => res.data));

        this.wallControlConfigData$ = combineLatest([this.isWallControlConnected$, this.tempUnitFormat$, this.dataSharingPermissions$]).pipe(
            map(([isWallControlConnected, tempUnitFormat, dataSharingPermissions]) => ({
                dealerId: this.dealerId,
                serialNo: this.serialNo,
                systemType: this.systemType,
                isEditEnabled: dataSharingPermissions.editConfig,
                isSaveEnabled: dataSharingPermissions.saveConfig,
                isDeviceConnected: isWallControlConnected,
                tempUnitFormat
            }))
        );

        this.wallControlHumidAndVentData$ = combineLatest([this.wallControlHumidAndVent$, this.tempUnitFormat$]).pipe(
            map(([wallControlHumidAndVentPropsData, tempUnitFormat]) => ({
                comfortProfiles: wallControlHumidAndVentPropsData,
                tempUnitFormat

            }))
        );

        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);
                    this.systemProperties = {
                        vppEligibility: system?.vppEligibility,
                        vppActivity: system?.vppActivity
                    };
                }
            });

        this.wallControlEnergyEventData$ = this.wallControlEnergyEventCurrentPage$.pipe(
            debounceTime(100),
            tap(() => {
                if (this.isEltWallControlEnergyEventEnabled) {
                    return;
                }
                this.isLoadingEnergyEvents$.next(true);
            }),
            switchMap(
                (currentPage) => {
                    const queryParams: DefaultQueryParams = {
                        pagination: {
                            currentPage,
                            pageSize: PAGINATION_PAGE_SIZE
                        }
                    };

                    return this.energyEventsService
                        .queryScheduledVppEventsBySerialNo(
                            this.serialNo,
                            this.dealerId,
                            queryParams
                        ).pipe(
                            map(({ data, totalPages }) => {
                                const eventData = {
                                    items: data,
                                    totalPages,
                                    currentPage
                                };

                                return eventData;
                            }),
                            catchError(() => {
                                this.isLoadingEnergyEvents$.next(false);

                                return EMPTY;
                            })
                        );
                }
            ),
            tap(() => {
                this.isLoadingEnergyEvents$.next(false);
            }),
            takeUntil(this.ngOnDestroy$)
        );
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();

        super.ngOnDestroy();
    }
}
