import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Persona } from './personas.component';
import { PersonasService } from '../../services/personas.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hvac-personas-item',
    templateUrl: './personas-item.component.html',
    styleUrls: ['./personas-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PersonasItemComponent {
    @Input() persona: Persona;
    @Input() id: number;

    showDetails$: Observable<boolean> = this.personasService.section$.pipe(
        map((section: number|null) => section === this.id)
    );

    constructor(private readonly personasService: PersonasService) {}

    openDetails() {
        this.personasService.openSection(this.id);
    }

    closeDetails() {
        this.personasService.closeSection();
    }
}
