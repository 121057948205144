/* eslint-disable max-lines */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService } from 'common/services/toast.service';
import { AccountAdminCountriesEntity, Company, UserResponse } from 'private/app/models/account-admin-search';
import { UserDetailsEntity, ExportUserDetailsEntity, UserDetails, CreateUserDetails } from 'private/app/models/user.model';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { AccountAdminUtilsService } from 'private/app/services/account-admin/account-admin-utils.service';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { BehaviorSubject } from 'rxjs';
import { Option } from '../../../../../../common/components/select/select.component';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { AccountAdminExportService } from '../../../../services/account-admin/account-admin-export.service';
import { OktaService } from 'common/services/okta/okta.service';
import { IdToken } from 'common/models';


type activeScreensType = 'ReadOnlyUserInfo' | 'EditInfo' | 'EditSecurity' | 'EditRelations' | 'CloneUser';
type SearchCompany = {
    userCreateStep: string,
    company: CompanyResultDataResponse
}
@Component({
    selector: 'hvac-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
    public company: CompanyResultDataResponse;
    public userDetails: UserDetails;
    public createUserOnStepNumber: string;
    public activeScreenName: string;
    public breadCrumbData: ContentHeadingData = this.userAdminService.breadCrumbData;
    public user: UserDetailsEntity;
    public editUserInfo: boolean;
    public apiRequestActive = false;
    public userCountry: string;
    public userState: string;
    public resetUserPassword: boolean;
    public activeUserScreen: activeScreensType = 'ReadOnlyUserInfo';
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public stateOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public editUserInfo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public userCompanyTypeName = '';
    public userCompanyTypeCode = '';
    public isLoading = false;
    public isITSuperAdmin?: boolean;
    public deactivateUserModal: boolean = false;
    private _userCompanyEditMode = false;
    private _isCompanyListDataAvailable = false;
    private userExportDetails: ExportUserDetailsEntity = {} as ExportUserDetailsEntity;

    constructor(
        private translate: TranslateService,
        public accountAdminService: AccountAdminService,
        private userApiService: UserApiService,
        private route: ActivatedRoute,
        private readonly toastService: ToastService,
        public userAdminService: UserAdminService,
        private accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        private accountAdminUtilsService: AccountAdminUtilsService,
        private accountAdminApiService: AccountAdminApiService,
        private commonUtils: CommonUtilsService,
        private ExportService: AccountAdminExportService,
        private oktaService: OktaService,
        private router: Router
    ) {
        this.onCompanySelect = this.onCompanySelect.bind(this);
    }

    get userCompanyEditMode(): boolean {
        return this._userCompanyEditMode;
    }

    get isCompanyListDataAvailable(): boolean {
        return this._isCompanyListDataAvailable;
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent) {
        const clickedInsideMenu = (event.target as HTMLElement).closest('.hvac-dropdown-menu') !== null;
        const clickedOnButton = (event.target as HTMLElement).closest('.hvac-more-options') !== null;
        if (!clickedInsideMenu && !clickedOnButton) {
            this.userAdminService.menuActive = false;
        }
    }

    ngOnInit(): void {
        // SUBSCRIPTIONS
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.isITSuperAdmin = res?.claims?.idm_user_roles?.includes('InternalSuperAdmin');
        });
        this.accountAdminUtilsService.setAdminBreadCrumbData(this.breadCrumbData);
        this.commonUtils.getCountriesEntity().subscribe((countryList) => {
            const optionList: Option[] = [];
            countryList.forEach((country: AccountAdminCountriesEntity) => {
                optionList.push({
                    name: country.name,
                    value: country.code
                });
            });
            this.countryOptionData$.next(this.accountAdminCompanyUtilsService.filterCountryArraywithMostUsed(optionList));
        });
        this.userAdminService.menuActive = false;
        this.editUserInfo$.subscribe((isUserInEditInfoPage) => {
            if (isUserInEditInfoPage) {
                this.activeUserScreen = 'EditInfo';
                this.updateUIWithUser();

                return;
            }
            this.activeUserScreen = 'ReadOnlyUserInfo';

            this.isLoading = true;
            this.userApiService.getUserDetailsById(this.route.snapshot.params.id).subscribe((data) => {
                this.user = data[0];
                this.isLoading = false;
                this.userCountry = this.countryOptionData$.value.find((country) => country.value === this.user.userCountry)?.name || '';
                this.getStateListByCountry(this.user.userCountry);
                this.toastService.remove('createUser');
            });
            this.userAdminService.smoothScrollToTop();
        });

        this.userAdminService.userEditForm.controls.userCountry.valueChanges.subscribe((selectedCountry: string) => {
            if (Array.isArray(selectedCountry)) {
                this.userAdminService.userEditForm.controls.userState.setValue('', { emitEvent: true });
                this.userAdminService.userEditForm.controls.userState.markAsDirty();

                this.getStateListByCountry(selectedCountry[0].value);
            }
            else {
                const countrySelection = this.countryOptionData$.value.find((country) => country.value === selectedCountry);
                if (countrySelection) {
                    this.getStateListByCountry(countrySelection.value);
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.accountAdminUtilsService.setAdminBreadCrumbData(this.accountAdminUtilsService.breadCrumbData);
    }


    updateUIWithUser() {
        this.userAdminService.userEditForm.setValue({
            firstName: this.user.firstName || '',
            lastName: this.user.lastName || '',
            userID: this.user.userID || '',
            userTitle: this.user.userTitle || '',
            employerName: this.user.employerName || '',

            companyName: this.user.employerName || '',
            companyId: this.user.companyId || '',
            hvacPin: this.user.hvacPin || '',

            userAddress1: this.user.userAddress1 || '',
            userAddress2: this.user.userAddress2 || '',
            userCity: this.user.userCity || '',

            userState: this.user.userState || '',
            userPostalCode: this.user.userPostalCode || '',
            userCountry: this.user.userCountry || '',

            userPhone: this.user.userPhone || '',
            userCellPhone: this.user.userCellPhone || '',
            userFax: this.user.userFax || '',

            email: this.user.email || '',
            contactOnly: this.user.contactOnly || '',

            lastLoginDate: this.user.lastLoginDate || '',

            registrationAction: 'Select',
            comments: ''
        }, { emitEvent: true });
    }

    setContactOnly(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.user.contactOnly = input.checked;
        }
    }

    resetPassword(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.resetUserPassword = input.checked;
        }
    }

    validatePassword(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input) {
            this.user.isValidationRequired = input.checked;
        }
    }

    submitChanges() {
        this.apiRequestActive = true;
        this.userApiService.updateUserProfileDetails(this.userAdminService.createUserPostBody(this.user)).subscribe((response) => {
            this.apiRequestActive = false;
            this.editUserInfo$.next(false);
            this.userAdminService.smoothScrollToTop();
            this.userAdminService.showToastBar('success', response.details);
            this.userAdminService.userEditForm.markAsPristine();
        }, (error) => {
            this.apiRequestActive = false;
            this.userAdminService.showToastBar('error', error.message);
        });
    }

    onCountryChange(option: Option[]) {
        this.user.userCountry = option[0]?.value;
    }

    onStateChange(option: Option[]) {
        this.user.userState = option[0]?.value;
    }

    enableUserRelationshipsEditMode() {
        this.activeUserScreen = 'EditRelations';
    }

    onClickEditSecurity(isUserInEditSecurity: boolean) {
        if (isUserInEditSecurity) {
            this.userAdminService.menuActive = false;
            this.activeUserScreen = 'EditSecurity';
        }
        else {
            this.activeUserScreen = 'ReadOnlyUserInfo';
            this.userAdminService.smoothScrollToTop();
        }
    }

    onClickCloneUser() {
        this.activeUserScreen = 'CloneUser';
        this.userAdminService.menuActive = false;
    }

    onContinueToStep2Click(event: string) {
        this.createUserOnStepNumber = event;
    }

    userCreateDetailsSubmit(event : CreateUserDetails) {
        this.isLoading = true;
        this.apiRequestActive = true;
        let userID: string | undefined;
        this.userApiService.createOrRegisterUser(event).subscribe((response) => {
            this.isLoading = false;
            this.apiRequestActive = false;
            this.userAdminService.smoothScrollToTop();
            this.userAdminService.showToastBar('success', response.details);
            this.userAdminService.userEditForm.markAsPristine();
            userID = response.details.split(' : ').pop();
            this.router.navigate([`/Admin/Admin-Tools/account-admin.html/user-details/${userID}`]);
        }, (error) => {
            this.isLoading = false;
            this.apiRequestActive = false;
            this.userAdminService.smoothScrollToTop();
            this.userAdminService.showToastBar('error', error.error.message);
        });
    }

    onClickCreateUserChangePage(event : SearchCompany) {
        const { company, userCreateStep } = event;
        this.accountAdminCompanyUtilsService.getAccountAdminUsersByCompanyId(this.user.companyId.toString());
        this.company = company;
        this.userAdminService.smoothScrollToTop();
        if (userCreateStep === 'ReadOnlyUserInfo') {
            this.activeUserScreen = 'ReadOnlyUserInfo';
            this.userAdminService.smoothScrollToTop();
        }
        else {
            this.createUserOnStepNumber = 'step4_userRolesFeatures';
        }
    }

    deactivateUser() {
        this.deactivateUserModal = false;
        this.isLoading = true;
        const userId = this.user.userID;
        this.userApiService.deactivateUser(userId, this.userAdminService.createUserPostBody(this.user)).subscribe((response) => {
            this.isLoading = false;
            if (response.status === 'Success') {
                this.userAdminService.showToastBar('success', `User ${userId} has been deactivated successfully.`);
                this.userApiService.getUserDetailsById(this.route.snapshot.params.id).subscribe((data) => {
                    this.user = data[0];
                });
            }
        }, (error) => {
            if (error) {
                this.isLoading = false;
                this.userAdminService.showToastBar('error', this.translate.instant('ACCOUNT_ADMIN.DEACTIVATE_USER_ERROR'));
            }
        });
    }

    CreateUserDetails(event: UserDetails) {
        this.userDetails = event;
    }

    onUserCompanyChangeClick() {
        const selectedUserModel = localStorage.getItem(this.translate.instant('ACCOUNT_ADMIN.LOCAL_HOST_KEYS.USER_MODEL_DATA_KEY'));
        const user: UserResponse | null = selectedUserModel ? JSON.parse(selectedUserModel) as unknown as UserResponse : null;

        if (user) {
            this.isLoading = true;
            this.accountAdminApiService.getAccountAdminCompanyDetails(user.hvacCompanyId).subscribe((company: Company) => {
                this.userCompanyTypeCode = this.userAdminService.removePrefixLetterZ(company.companyType?.code || '');
                this.userCompanyTypeName = company.companyType?.name || '';
                this.isLoading = false;
            });
        }

        this._userCompanyEditMode = true;
    }

    onCompanySearchClick() {
        this._isCompanyListDataAvailable = true;
    }

    public onCompanySelect(company: CompanyResultDataResponse | null): void {
        if (company) {
            this.userAdminService.userEditForm.controls.employerName.setValue(company.companyName);
            this.userAdminService.userEditForm.controls.companyId.setValue(company.hvacCompanyId);
            this.userAdminService.userEditForm.controls.employerName.markAsDirty();
            this.userAdminService.userEditForm.controls.companyId.markAsDirty();
        }
        else {
            this.userAdminService.showToastBar('error', this.translate.instant('USER_ADMIN.USER_EDIT.ERROR.SOMETHING_WENT_WRONG'));
        }
        this._userCompanyEditMode = false;
    }

    public onClickClearSearch() {
        const companyValue = this.accountAdminService.advancedSearchForm.controls.companyType.value;

        this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
        this.accountAdminService.advancedSearchForm.controls.companyType.setValue(companyValue);
    }

    public searchCompanyModalClose() {
        this._userCompanyEditMode = false;
    }

    public onExportUserDetails(exportUser: UserDetailsEntity) {
        const selectedUser = this.userAdminService.transformToExportUserDetailFormart(exportUser, this.userExportDetails);
        this.ExportService.doExportUserXSLX(selectedUser);
    }

    private getStateListByCountry(selectedCountry: string) {
        this.commonUtils.getStateByCountry(selectedCountry).subscribe((stateList) => {
            this.stateOptionData$.next(stateList);
            this.userState = this.stateOptionData$.value.find((state) => state.value === this.user.userState)?.name || '';
        });
    }
}
