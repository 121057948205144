import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Company, DataHeader } from '../../../models/account-admin-search';
import { AccountAdminExportService } from '../../../services/account-admin/account-admin-export.service';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { CompanySearchResultResponse, CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { ToastService } from 'common/services/toast.service';

interface CompanyResults extends CompanyResultDataResponse {
    brands: string
}
@Component({
    selector: 'hvac-create-user-company-table',
    templateUrl: './create-user-company-table.component.html',
    styleUrls: ['./create-user-company-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateUserCompanyTableComponent implements OnInit {
  @Input() onCompanySelect?: (data: CompanyResultDataResponse | null) => void | null;
  @Output() createUserChangePage = new EventEmitter<{
      userCreateStep: string,
      company : CompanyResultDataResponse
  }>();

  public ngOnDestroy$ = new Subject();
  public companies?: CompanyResults[]
  public dataHeaders: DataHeader[] = [
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.COMPANY_ID'),
          value: 'companyId',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.SOLD_TO'),
          value: 'soldToCustomerId',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.NAME'),
          value: 'name',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.ADDRESS'),
          value: 'address',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.CITY'),
          value: 'city',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.POSTAL_CODE'),
          value: 'postalCode',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.STATE'),
          value: 'regionCode',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.COUNTRY'),
          value: 'countryCode',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.TYPE'),
          value: 'companyType',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.COMPANY_TABLE.BRANDS'),
          value: 'companyId',
          order: null
      },
      {
          title: '',
          value: 'SelectCompany',
          order: null
      }
  ];

  public loading: boolean;
  public pagination: number = 1;
  public totalCount: number;
  public totalPages: number;
  public toastOutlet = 'AccountAdminNoExportError';

  private pageSize = 10;
  constructor(
      public accountAdminService: AccountAdminService,
      public accountPostAdminService: AccountPostAdminService,
      public accountAdminExportService: AccountAdminExportService,
      private route: ActivatedRoute,
      private router: Router,
      private translate: TranslateService,
      private toastService: ToastService
  ) { }

  ngOnInit() {
      this.accountAdminService.lastQueryParams.subscribe(() => {
          this.postCompanySearch();
      });
  }

  public createDetails(company: Company): string {
      return [
          company.city,
          company.state,
          company.phoneNumber,
          company.faxNumber,
          company.emailAddress
      ].filter(Boolean).join(', ');
  }

  public handlePageChange(event: number) {
      this.pagination = event;
      this.accountAdminService.lastQueryParams.next(this.accountAdminService.lastQueryParams.value);
  }

  public sort(item: DataHeader) {
      const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

      if (!currentSort.order) {
          currentSort.order = 'asc';
      }
      else if (currentSort.order === 'asc') {
          currentSort.order = 'desc';
      }
      else {
          currentSort.order = null;
      }

      const queryParams: Params = { companySortFields: currentSort.order ? `${currentSort.value}-${currentSort.order}` : null };

      this.router.navigate(
          [],
          {
              relativeTo: this.route,
              queryParams: queryParams,
              queryParamsHandling: 'merge'
          }
      );
  }

  public onClickSelectCompany(company: CompanyResultDataResponse) {
      if (this.onCompanySelect) {
          const filteredCompanies = this.companies?.filter((currentCompany) => currentCompany.hvacCompanyId === company.hvacCompanyId) || [];
          const filteredCompany = filteredCompanies?.length > 0 ? filteredCompanies[0] : null;
          this.onCompanySelect(filteredCompany);

          return;
      }

      this.createUserChangePage.emit({
          userCreateStep: 'step2_registeredUsersInCompany',
          company: company
      });
  }


  private postCompanySearch() {
      this.loading = true;
      this.accountPostAdminService.postCompanySearch(
          this.accountAdminService.createSearchBody(
              this.accountAdminService.advancedSearchForm.value,
              this.pagination,
              null,
              'companyCriteria'
          )
      ).subscribe(
          (res: CompanySearchResultResponse) => {
              this.loading = false;
              this.companies = res?.data.map((companySearchResultResponse) => ({
                  ...companySearchResultResponse,
                  brands: (companySearchResultResponse.authorizedBrands || []).map((authorizedBrand) => authorizedBrand.code).join(', ')
              }));
              this.totalCount = (res?.totalCount === 0) ? this.totalCount : res?.totalCount;
              this.totalPages = Math.ceil(this.totalCount / this.pageSize);
          },
          (error) => {
              this.loading = false;
              this.toastService.add({
                  id: error?.toString(),
                  content: error?.toString(),
                  autoClose: true,
                  closeable: true,
                  theme: 'error'
              });
          }
      );
  }
}

