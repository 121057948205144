import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { SafePipeModule } from 'safe-pipe';
import { CommonComponentsModule } from '../../components/common-components.module';
import { ProductsLiteratureRoutingModule } from './product-literature-routing.module';
import { ProductLiteratureComponent } from './product-literature.component';
import { ContentModule } from 'common/content/content.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DocumentResultComponent } from './components/document-result/document-result.component';
import { PartsSelectors } from './components/breadcrumbs/parts-selectors.component';

@NgModule({
    declarations: [
        ProductLiteratureComponent,
        BreadcrumbsComponent,
        DocumentResultComponent,
        PartsSelectors
    ],
    imports: [
        CommonModule,
        CommonComponentsModule,
        ProductsLiteratureRoutingModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SafePipeModule,
        EllipsisModule,
        ContentModule
    ],
    providers: [
    ]
})
export class ProductLiteratureModule {}
