<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-settings-header">
            <hvac-content-header [data]="breadCrumbData">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>
        <section class="hvac-section-container hvac-dealer-settings-info">
            <ng-container *ngIf="dealerData$ | async; let dealerData">
                <hvac-dealer-info [dealerInfo]="dealerData"></hvac-dealer-info>
            </ng-container>
        </section>
        <section class="hvac-section-container hvac-dealer-settings-notifications">
            <ng-container *ngIf="dealerData$ | async; let dealerData">
                <hvac-dealer-notification-settings [dealerInfo]="dealerData" (getData)="getDealerData($event)"></hvac-dealer-notification-settings>
            </ng-container>
        </section>
    </div>
</hvac-connected-portal-layout-container>
