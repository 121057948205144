import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Select } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { IdToken } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ApiOptions, ApiOptionsService } from '../../../../common/services/api-options/api-options.service';
import { OktaService } from 'common/services/okta/okta.service';

@Injectable({ providedIn: 'root' })
export class ApiOptionsServicePrivate extends ApiOptionsService {
    @Select(AuthState.idToken) tokens$: Observable<IdToken | undefined>;

    constructor(private readonly oktaService: OktaService) {
        super();
    }

    getAuthedHttpOptions(): Observable<ApiOptions> {
        return this.tokens$.pipe(
            first(),
            map((token) => {
                if (!token || !this.oktaService.validateToken(token.idToken)) {
                    this.handleInvalidToken();
                }

                const auth = token ? { authorization: `Bearer ${token.idToken}` } : {};
                const headers = {
                    'appid': environment.appId,
                    'x-api-key': environment.connectedPortalKey,
                    'audience': environment.auth.clientId,
                    ...auth
                } as { [key: string]: string };

                return { headers: new HttpHeaders(headers) };
            })
        );
    }

    private handleInvalidToken() {
        this.oktaService.logout();
        throw new Error();
    }
}
