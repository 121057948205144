/* import { HttpErrorResponse } from '@angular/common/http'; */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ErrorService } from 'common/content/services/error.service';
import { SetDealerAccessCheckLoading } from 'common/store/content/content.actions';
import { ConnectedPortalGraphQLError } from 'private/app/models/connected-portal-error.model';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { ApiResponseCode, ApiResponseMessage } from 'private/app/views/connected-portal/constants';
import { Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, take, tap } from 'rxjs/operators';

const NOT_FOUND_REDIRECT_DELAY = 250;

@Injectable({ providedIn: 'root' })
export class DealerGuard implements CanActivate {
    constructor(
        private errorService: ErrorService,
        private dealerService: DealersService,
        private store: Store,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot):boolean | Observable<boolean> {
        const dealerId = route.params.id;

        return this.getHasAccess(dealerId);
    }

    getHasAccess(dealerId: string): boolean | Observable<boolean> {
        this.store.dispatch(new SetDealerAccessCheckLoading(true));

        return this.dealerService.queryDealerById(dealerId)
            .pipe(
                take(1),
                map(() => true),
                catchError((err: ConnectedPortalGraphQLError) => of(err).pipe(
                    // Delay added to avoid a race condition that causes 404 not to display.
                    delay(NOT_FOUND_REDIRECT_DELAY),
                    tap((error) => {
                        if (error?.extensions?.errorType && error?.extensions?.errorType === ApiResponseMessage.PENDING_DEALER_ACTIVATION) {
                            this.router.navigate(['pending-dealer-activation']);
                        }
                        else if (error?.message === ApiResponseMessage.PENDING_DEALER_ACTIVATION) {
                            this.router.navigate(['pending-dealer-activation']);
                        }
                        else {
                            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);
                        }
                    }),
                    map(() => false)
                )),
                finalize(() => {
                    this.store.dispatch(new SetDealerAccessCheckLoading(false));
                })

            );
    }
}
