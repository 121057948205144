import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrowseModule } from 'common/views/browse/browse.module';
import { ContentModule } from 'common/content/content.module';
import { ProductDetailsModule } from 'common/views/product-details/product-details.module';
import { ProductLiteratureModule } from 'common/views/product-literature/product-literature.module';
import { RichTextModule } from 'common/pipes/rich-text/rich-text.module';
import { QuickLinksService } from 'common/services/quickLinks.service';
import { ComponentsModule } from 'private/app/components/components.module';
import { SafePipeModule } from 'safe-pipe';
import { DashboardComponent } from './dashboard.component';
import { DashboardContentService } from './services/dashboard-content.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { SwiperDirective } from 'common/components/swiper/swiper.directive';
import { QuickLinksModule } from 'common/components/quick-links/quick-links.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ComponentsModule,
        TranslateModule,
        RouterModule,
        SafePipeModule,
        BrowseModule,
        ProductDetailsModule,
        ProductLiteratureModule,
        RichTextModule,
        ContentModule,
        DragDropModule,
        JwtModule.forRoot({ config: { tokenGetter: () => localStorage.getItem('token') } }),
        SwiperDirective,
        QuickLinksModule
    ],
    declarations: [
        DashboardComponent
    ],
    providers: [
        DatePipe,
        DashboardContentService,
        JwtHelperService,
        QuickLinksService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }

