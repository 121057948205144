/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { ConnectedProductRes, ConnectedProductUpdate, SystemType } from 'private/app/models/connected-product.model';
import {
    WallControlDetailsResponse,
    WallControlStatusResponse,
    WallControlHumidAndVentResponse,
    WallControlZoningResponse,
    WallControlNotificationsResponse,
    WallControlSystemInfoResponse,
    WallControlConfigResponse,
    InfinityWallControlConfigInputProps,
    EcobeeWallControlConfigInputProps,
    CarrierEltWallControlConfigInputProps,
    EltActivityResponse
} from 'private/app/models/wall-control.model';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService, MediaImageSize } from './connected-portal-base.service';
import { CatalogService } from 'common/services/catalog.service';
import { ProductImagesService } from './product-images.service';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { CharSymbol } from 'common/enums/char-symbol';
import { ECOBEE_TRANSLATION_VALUE_KEYS, SKIP_BRAND_PARAMS, DEVICE_TEMPERATURE_PROPERTIES, ApiResponseCode, ApiResponseMessage, MISSING_API_RESPONSE_DATA, TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { nonEmptyStringOrNull } from './utils';
import { CommandInput, DiagnosticParameterUnit } from 'private/app/models/connected-portal-system-diagnostics.model';
import { FactorySystemOverviewResponse } from 'private/app/models/factory.model';

export const QUERY_PRODUCT_BY_SERIAL_NO = gql`
    query QueryProductBySerialNo($id: String!, $dealerId: String) {
        queryProductBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                name
                modelNo
                serialNo
                firmware
                type,
                esn,
                isPlcEquipped
                systemType,
                refrigerantType
            }
        }
    }
`;

export const QUERY_WALL_CONTROL_BY_SERIAL_NO = gql`
       query QueryWallControlBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryWallControlBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                indoorHumidity
                outdoorHumidity
                userSetting
                outdoorTemp
                statusLocalTime
                heating {
                    label
                    value
                    }
                cooling {
                    label
                    value
                }
                fan {
                    label
                    value
                }
                zones {
                    coolTo
                    heatTo
                    indoorTemp
                    name
                    relativeHumidity
                    activities {
                        name
                        cool
                        heat
                        fan
                    }
                    programs {
                        dayOfWeek
                        periods {
                            activity
                            enabled
                            time
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_INFINITY_WALL_CONTROL_STATUS_BY_SERIAL_NO = gql`
        query QueryWallControlStatusBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryWallControlStatusBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                currentSystemOperatingMode
                dateTime
                filterUsed
                humidifierPadsUsed
                humidifierStatus
                isDisconnected
                name
                outdoorAirTemperature
                systemOperatingStatus
                type
                uvLightsUsed
                ventilatorCleanUsed
                ventStatus,
                isRuntimeReportEnabled
            }
        }
    }
`;

export const QUERY_CARRIER_ELT_WALL_CONTROL_STATUS_BY_SERIAL_NO = gql`
        query QueryWallControlStatusBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryWallControlStatusBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                stageStatus
                dateTime
                tempUnitFormat
                zipCode
                displayLock
                dayBrightness
                nightModeBrightness,
                softwareUpdateAvailable
                isDisconnected,
                isRuntimeReportEnabled,
                systemType,
                fanMode
            }
        }
    }
`;

export const QUERY_WALL_CONTROL_SYSTEM_INFO_BY_SERIAL_NO = gql`
    query QueryWallControlSystemInfoBySerialNo($id: String!, $dealerId: String) {
        queryWallControlSystemInfoBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                NIMPresent,
                NIMSoftwareVersion,
                EWTSensorPresent,
                zoneBoards {
                    modelNo,
                    serialNo
                }
            }
        }
    }
`;

export const QUERY_CARRIER_ELT_WALL_CONTROL_HUMID_AND_VENT_BY_SERIAL_NO = gql`
    query QueryWallControlHumidAndVentBySerialNo($id: String!, $dealerId: String, $systemType: String) {
        queryWallControlHumidAndVentBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                away {
                    heatToSetpoint
                    coolToSetpoint
                }
                home {
                    heatToSetpoint
                    coolToSetpoint
                }
                wake {
                    heatToSetpoint
                    coolToSetpoint
                }
                sleep {
                    heatToSetpoint
                    coolToSetpoint
                }
            }
        }
    }
`;

export const QUERY_INFINITY_WALL_CONTROL_HUMID_AND_VENT_BY_SERIAL_NO = gql`
    query QueryWallControlHumidAndVentBySerialNo($id: String!, $dealerId: String) {
        queryWallControlHumidAndVentBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                away {
                    coolingVentilatorMaxSpeed
                    coolingVentilatorMode
                    dehumidificationTarget
                    heatingVentilatorMaxSpeed
                    heatingVentilatorMode
                    humidificationTarget
                    humidifierMode
                    humidifyWithHumidifier
                    overcoolToDehumidify
                }
                home {
                    coolingVentilatorMaxSpeed
                    coolingVentilatorMode
                    dehumidificationTarget
                    heatingVentilatorMaxSpeed
                    heatingVentilatorMode
                    humidificationTarget
                    humidifierMode
                    humidifyWithHumidifier
                    overcoolToDehumidify
                }
                vacation {
                    coolingVentilatorMaxSpeed
                    coolingVentilatorMode
                    dehumidificationTarget
                    heatingVentilatorMaxSpeed
                    heatingVentilatorMode
                    humidificationTarget
                    humidifyWithHumidifier
                    overcoolToDehumidify
                }
            }
        }
    }
`;

export const QUERY_WALL_CONTROL_NOTIFICATIONS_BY_SERIAL_NO = gql`
    query QueryNotificationsBySerialNo($id: String!, $dealerId: String, $params: DefaultQueryParams) {
        queryNotificationsBySerialNo(id: $id, dealerId: $dealerId, params: $params) {
        totalCount
        totalPages
          data {
            type
            code
            message
            dateTime
          }
        }
      }
`;

export const QUERY_WALL_CONTROL_ZONING_BY_SERIAL_NO = gql`
    query QueryWallControlZoningBySerialNo($id: String!, $dealerId: String) {
        queryWallControlZoningBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                activity
                airflowLimit
                airflowLimitCFM
                blowerActualCFM
                blowerCoolingCFM
                blowerHeatingCFM
                blowerSpeed
                calcMinCFM
                coolSetpoint
                damperPosition
                fanSpeed
                heatSetpoint
                holdSetting
                leavingAirTemp
                overrideUntilTime
                smartSensorModel
                smartSensorSerial
                smartSetbackEnabled
                staticPressure
                systemCFM
                temperatureOffset
                timestamp
                zoneConditioning
                zoneName
                zoneSensorType
            }
        }
    }
`;

export const PUBLISH_COMMAND = gql`
    mutation publishCommand($id:String!, $dealerId: String, $data:CommandInput!) {
        publishCommand(id:$id, dealerId: $dealerId, data: $data) {
            message
            code
        }
    }
`;

export const QUERY_INFINITY_WALL_CONTROL_CONFIG_BY_SERIAL_NO = gql`
        query QueryWallControlConfigBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryWallControlConfigBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                configEditParams {
                    filterChangeInterval {
                        readOnly
                    }
                    staticPressureMonitor {
                        readOnly
                    }
                    ventilatorCleanInterval {
                        readOnly
                    }
                    uvLightsChangeInterval {
                        readOnly
                    }
                    humidifierMaintenanceInterval {
                        readOnly
                    }
                }
                autoModeChangeOverTime
                autoModeEnabled
                blowerSpeed
                buzzerSoundEnabled
                calcMinCFM
                changeoverMinutes
                deadband
                ductAssessmentHour
                electricUtilityRate
                gasUtilityRate
                fanProgrammingEnabled
                filterChangeInterval
                filterReminderEnabled
                fuelType
                gasUnit
                heatSource
                humidifierMaintenanceInterval
                humidifierPresent
                humidifierReminderEnabled
                humidityOffset
                outdoorTempOffset
                postalCodeOnWeatherScreen
                protectWithVentilator
                schedulingEnabled
                screenSaverEnabled
                simultaneousHeatCoolSetting
                smartRecoveryEnabled
                staticPressure
                staticPressureMonitor
                systemCFM
                systemConfigType
                torqueControl
                userModeSelection
                uvLightsChangeInterval
                uvLightsPresent
                uvLightsReminderEnabled
                ventilatorCleanInterval
                ventilatorPresent
                ventilatorReminderEnabled
                windowProtectEnabled
                windowProtectHumidificationTarget
                zones {
                    id
                    zoneName
                    zoneTemperatureOffset
                    zoningEnabled
                }
            }
        }
    }
`;

export const QUERY_ECOBEE_WALL_CONTROL_CONFIG_BY_SERIAL_NO_AND_SYSTEM_TYPE = gql`
    query QueryWallControlConfigBySerialNo($id: String!, $dealerId: String, $systemType: String!) {
        queryWallControlConfigBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                configEditParams {
                    auxOutdoorMaxTemp {
                        readOnly
                    }
                    compressorOutdoorMinTemp {
                        readOnly
                    }
                }
                userModeSelection
                coolToSetPoint
                heatToSetPoint
                fanRuntimeMin
                programMode
                deadband
                compressorOutdoorMinTemp
                acOvercoolMax
                auxOutdoorMaxTemp
            }
        }
    }
`;

export const QUERY_CARRIER_ELT_WALL_CONTROL_CONFIG_BY_SERIAL_NO_AND_SYSTEM_TYPE = gql`
    query QueryWallControlConfigBySerialNo($id: String!, $dealerId: String, $systemType: String!) {
        queryWallControlConfigBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                accessoryOutput
                auxHeatLockoutEnableDisable
                auxHeatLockoutTemp
                compressorLockoutEnableDisable
                compressorOutdoorMinTemp
                coolDeltaStage1
                coolDeltaStage2
                coolingCycles
                coolToSetPoint
                deadband
                drymodeSetpoint
                dryMode
                fanPurgeCool
                fanPurgeHeat
                heatDeltaAuxStage1
                heatDeltaAuxStage2
                heatDeltaStage1
                heatDeltaStage2
                heatingCycles
                heatToSetPoint
                maxHeatSetpoint
                minCoolSetpoint
                overcoolSetpoint
                rhDehumidificationSetpoint
                rhDehumidifierControlOption
                rhHumidificationSetpoint
                rhHumidifierControlOption
                subsystemType
                systemType
                userModeSelection
                ventilationMaxIOTempDelta
                ventilationPercentOnTime
                fanType
            }
        }
    }
`;

export const UPDATE_INFINITY_WALL_CONTROL_CONFIG_BY_SERIAL_NO = gql`
    mutation UpdateWallControlConfigBySerialNo($id: String!, $dealerId: String, $data: WallControlConfigInputProps!) {
        updateWallControlConfigBySerialNo(id: $id, dealerId: $dealerId, data: $data) {
            message,
            code,
            data {
                id
                name
                value
                description
            }
        }
    }
`;

export const UPDATE_ECOBEE_WALL_CONTROL_CONFIG_BY_SERIAL_NO = gql`
    mutation UpdateEcobeeWallControlConfigBySerialNo($id: String!, $dealerId: String, $data: EcobeeWallControlConfigInputProps!) {
        updateEcobeeWallControlConfigBySerialNo(id: $id, dealerId: $dealerId, data: $data) {
            message,
            code,
            data {
                id
                name
                value
                description
            }
        }
    }
`;

export const UPDATE_CARRIER_ELT_WALL_CONTROL_CONFIG_BY_SERIAL_NO = gql`
    mutation UpdateCarrierEltWallControlConfigBySerialNo($id: String!, $data: CarrierETLWallControlConfigInputProps!, $editParamsPrevValues: CarrierETLWallControlConfigInputProps!) {
        updateCarrierELTWallControlConfigBySerialNo(id: $id, data: $data, editParamsPrevValues: $editParamsPrevValues) {
            message,
            code,
            data {
                id
                name
                value
                description
            }
        }
    }
`;

export const ACTIVATE_MQTT_CONTROL_BY_SERIAL_NO = gql`
    mutation ActivateMQTTControlBySerialNo($id: String!, $dealerId: String) {
        activateMQTTControlBySerialNo(id: $id, dealerId: $dealerId) {
            message,
            code
        }
    }
`;

export const ACTIVATE_ECOBEE_CONTROL_BY_SERIAL_NO = gql`
    mutation ActivateEcobeeControlBySerialNo($id: String!, $dealerId: String) {
        activateEcobeeControlBySerialNo(id: $id, dealerId: $dealerId) {
            message,
            code
        }
    }
`;
export const QUERY_FACTORY_SYSTEM_BY_SERIAL_NO = gql`
query QuerySystemBySerialNo($id: String!, $unitType: String!) {
    querySystemBySerialNo(id: $id, unitType: $unitType) {
        code
        message
        data {
            systemId
            systemName
            wallControlName
            apiProtocol
            dealerHVPID
            dataSharing {
              viewStatus
              viewConfig
              editConfig
            }
            modelEcosystem
            products {
              name
              modelNo
              serialNo
              firmware
              type,
              systemType,
              esn
            }
          }}}
    `;
export const UPDATE_PRODUCT_INFO_BY_SYSTEM_ID = gql`
    mutation UpdateProductInfoBySystemId($systemId: String!, $data: ProductInputProps!) {
        updateProductInfoBySystemId(systemId: $systemId, data: $data) {
            message,
            code
        }
    }
`;

export const QUERY_WALL_CONTROL_CARRIER_ELT_COMFORT_PROFILE = gql`
query QueryCarrierELTComfortProfile($id: String!) {
    queryCarrierELTComfortProfile(id: $id) {
        data {
            scheduleInfo {
                currentScheduleId
                scheduleEnabled
                currentSceneName
            }
            away {
                id
                startTime
                day
                clsp
                htsp
            },
            home {
                id
                startTime
                day
                clsp
                htsp
            }
            wake {
                id
                startTime
                day
                clsp
                htsp
            }
            sleep {
                id
                startTime
                day
                clsp
                htsp
            }
        }
    }
}
`;

@Injectable({ providedIn: 'root' })
export class ProductService extends ConnectedPortalBaseService {
    constructor(
        private dateTimeTzPipe: DateTimeTzPipe,
        private numberSuffixPipe: NumberSuffixPipe,
        private catalogService: CatalogService,
        private productImagesService: ProductImagesService,
        public injector: Injector
    ) {
        super(injector);
    }

    public queryProductBySerialNo(id: string, dealerId: string): Observable<ConnectedProductRes> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryProductBySerialNo').query<{ queryProductBySerialNo: ConnectedProductRes }>({
                query: QUERY_PRODUCT_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                switchMap((productRes) => {
                    const { queryProductBySerialNo } = productRes.data;
                    const hasModelNo = Boolean(nonEmptyStringOrNull(queryProductBySerialNo.data.modelNo));

                    const productAttributesRes = hasModelNo
                        ? this.catalogService
                            .fetchProductAttributes(
                                this.productImagesService.findModel(queryProductBySerialNo.data.modelNo),
                                SKIP_BRAND_PARAMS
                            )
                            .pipe(catchError(() => of(null)))
                        : of(null);

                    return combineLatest([
                        of(productRes),
                        productAttributesRes
                    ]);
                })
            ).pipe(
                switchMap(([productRes, productAttributes]): Observable<ConnectedProductRes> => {
                    const { queryProductBySerialNo } = productRes.data;
                    const { data: productData } = queryProductBySerialNo;

                    return this.getProductCatalogData(productData, productAttributes, MediaImageSize.Small).pipe(
                        map((productCatalogData) => ({ data: { ...productCatalogData } }))
                    );
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public formatData(data: [string, string | null][], tempUnitFormat = TempUnitFormat.Fahrenheit): [string, string][] {
        const formattedArray = data.map((entry): [string, string | null] => {
            const label = entry[0];
            const value = entry[1];

            if (typeof value === 'string' && ECOBEE_TRANSLATION_VALUE_KEYS.includes(String(value))) {
                const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_PROP_VALUES.${value}`;

                const displayValue = this.translateService.instant(translationKey);

                return [
                    label,
                    displayValue
                ];
            }

            if (label === 'dateTime') {
                const transformed = this.dateTimeTzPipe.transform(value);
                const displayValue = typeof transformed === 'string' ? transformed : null;

                return [
                    label,
                    displayValue
                ];
            }

            if (label === 'stage') {
                const tempStageValue = value === '0' ? null : value;

                return [
                    label,
                    tempStageValue
                ];
            }

            if (value && (label.toLowerCase().includes('temp') || DEVICE_TEMPERATURE_PROPERTIES.includes(label))) {
                const unit = tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit;
                const transformed = this.numberSuffixPipe.transform(value, unit);
                const displayValue = typeof transformed === 'string' ? transformed : null;

                return [
                    label,
                    displayValue
                ];
            }

            if (label === 'isRuntimeReportEnabled') {
                return [
                    label,
                    null
                ];
            }

            if (value && label === 'liquidServiceValvePressure') {
                const transformed = this.numberSuffixPipe.transform(value, ` ${DiagnosticParameterUnit.PRESSURE}`);
                const displayValue = typeof transformed === 'string' ? transformed : null;

                return [
                    label,
                    displayValue
                ];
            }

            return entry;
        });

        return formattedArray.filter(([_key, value]) => value !== null) as [string, string][];
    }

    public queryWallControlBySerialNo(id: string, dealerId: string, systemType: SystemType): Observable<WallControlDetailsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const variables: any = {
            id,
            dealerId,
            systemType
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlBySerialNo').query<{ queryWallControlBySerialNo: WallControlDetailsResponse }>({
                query: QUERY_WALL_CONTROL_BY_SERIAL_NO,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryWallControlBySerialNo } = value.data;

                    return queryWallControlBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryWallControlStatusBySerialNo(id: string, systemType: SystemType, dealerId?: string): Observable<WallControlStatusResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const variables: { id: string, systemType: SystemType, dealerId?: string } = {
            id,
            systemType
        };

        if (dealerId) {
            variables.dealerId = dealerId;
        }

        let query = QUERY_INFINITY_WALL_CONTROL_STATUS_BY_SERIAL_NO;

        if (systemType === SystemType.CARRIER_ELT) {
            query = QUERY_CARRIER_ELT_WALL_CONTROL_STATUS_BY_SERIAL_NO;
        }

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlStatusBySerialNo').query<{ queryWallControlStatusBySerialNo: WallControlStatusResponse }>({
                query,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = this.removeEmptyProps(value.data.queryWallControlStatusBySerialNo) as WallControlStatusResponse;

                    const orderRef = { dateTime: data.dateTime };
                    const orderedData = Object.assign(orderRef, data);

                    // Converting these values to explicit booleans instead of string values
                    const isRuntimeReportEnabled = (data.isRuntimeReportEnabled as unknown as string) === 'true';
                    const isDisconnected = (data.isDisconnected as unknown) !== 'false';

                    return {
                        data: {
                            ...orderedData,
                            isRuntimeReportEnabled,
                            isDisconnected
                        }
                    };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryWallControlSystemInfoBySerialNo(id: string, dealerId: string): Observable<WallControlSystemInfoResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlSystemInfoBySerialNo').query<{ queryWallControlSystemInfoBySerialNo: WallControlSystemInfoResponse }>({
                query: QUERY_WALL_CONTROL_SYSTEM_INFO_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = this.removeEmptyProps(value.data.queryWallControlSystemInfoBySerialNo) as WallControlSystemInfoResponse;

                    return { data };
                }),
                catchError((err: HttpErrorResponse) => {
                    this.showErrorToast(err);

                    throw err;
                })
            ))
        );
    }

    public queryWallControlHumidAndVentBySerialNo(id: string, dealerId: string, systemType: SystemType): Observable<WallControlHumidAndVentResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        let query = QUERY_INFINITY_WALL_CONTROL_HUMID_AND_VENT_BY_SERIAL_NO;

        if (systemType === SystemType.CARRIER_ELT) {
            query = QUERY_CARRIER_ELT_WALL_CONTROL_HUMID_AND_VENT_BY_SERIAL_NO;
        }

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlHumidAndVentBySerialNo').query<{ queryWallControlHumidAndVentBySerialNo: WallControlHumidAndVentResponse }>({
                query,
                variables: {
                    id,
                    dealerId,
                    systemType
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryWallControlHumidAndVentBySerialNo } = value.data;

                    return this.removeEmptyProps(queryWallControlHumidAndVentBySerialNo) as WallControlHumidAndVentResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryWallControlZoningBySerialNo(id: string, dealerId: string): Observable<WallControlZoningResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlZoningBySerialNo').query<{ queryWallControlZoningBySerialNo: WallControlZoningResponse }>({
                query: QUERY_WALL_CONTROL_ZONING_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryWallControlZoningBySerialNo;

                    const orderedData = data.map((item) => {
                        const orderRef = { timestamp: item.timestamp };

                        return Object.assign(orderRef, item);
                    });

                    return this.removeEmptyProps({ data: orderedData }) as WallControlZoningResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryWallControlConfigBySerialNo(id: string, dealerId: string, systemType: SystemType): Observable<WallControlConfigResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const variables: any = {
            id,
            dealerId,
            systemType
        };

        let query = QUERY_INFINITY_WALL_CONTROL_CONFIG_BY_SERIAL_NO;

        if (systemType === SystemType.ECOBEE) {
            query = QUERY_ECOBEE_WALL_CONTROL_CONFIG_BY_SERIAL_NO_AND_SYSTEM_TYPE;
        }
        else if (systemType === SystemType.CARRIER_ELT) {
            query = QUERY_CARRIER_ELT_WALL_CONTROL_CONFIG_BY_SERIAL_NO_AND_SYSTEM_TYPE;
        }

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryWallControlConfigBySerialNo').query<{ queryWallControlConfigBySerialNo: WallControlConfigResponse }>({
                query,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryWallControlConfigBySerialNo } = value.data;

                    // Convert zones to an empty array if it comes back as null to avoid array checking in components
                    if (systemType === SystemType.INFINITY) {
                        const { zones } = queryWallControlConfigBySerialNo.data;

                        return this.removeEmptyProps({
                            data: {
                                ...queryWallControlConfigBySerialNo.data,
                                zones: zones || []
                            }
                        }) as WallControlConfigResponse;
                    }

                    return this.removeEmptyProps(queryWallControlConfigBySerialNo) as WallControlConfigResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateInfinityWallControlConfigBySerialNo(id: string, dealerId: string, data: InfinityWallControlConfigInputProps): Observable<DefaultUpdateResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateInfinityWallControlConfigBySerialNo').mutate<{ updateWallControlConfigBySerialNo: DefaultUpdateResponse }>({
                mutation: UPDATE_INFINITY_WALL_CONTROL_CONFIG_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateWallControlConfigBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateEcobeeWallControlConfigBySerialNo(id: string, dealerId: string, data: EcobeeWallControlConfigInputProps): Observable<DefaultUpdateResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateEcobeeWallControlConfigBySerialNo').mutate<{ updateEcobeeWallControlConfigBySerialNo: DefaultUpdateResponse }>({
                mutation: UPDATE_ECOBEE_WALL_CONTROL_CONFIG_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateEcobeeWallControlConfigBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateCarrierEltWallControlConfigBySerialNo(id: string, data: CarrierEltWallControlConfigInputProps, editParamsPrevValues: CarrierEltWallControlConfigInputProps): Observable<DefaultUpdateResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateCarrierELTWallControlConfigBySerialNo').mutate<{ updateCarrierELTWallControlConfigBySerialNo: DefaultUpdateResponse }>({
                mutation: UPDATE_CARRIER_ELT_WALL_CONTROL_CONFIG_BY_SERIAL_NO,
                variables: {
                    id,
                    data,
                    editParamsPrevValues
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateCarrierELTWallControlConfigBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryWallControlNotificationsBySerialNo(id: string, dealerId: string, queryParams?: DefaultQueryParams): Observable<WallControlNotificationsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryNotificationsBySerialNo').query<{ queryNotificationsBySerialNo: WallControlNotificationsResponse }>({
                query: QUERY_WALL_CONTROL_NOTIFICATIONS_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryNotificationsBySerialNo } = value.data;

                    return queryNotificationsBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public activateMQTTControlBySerialNo(id: string, dealerId: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const variables: { id: string, dealerId?: string } = { id };

        if (dealerId) {
            variables.dealerId = dealerId;
        }

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('activateMQTTControlBySerialNo').mutate<{ activateMQTTControlBySerialNo: DefaultUpdateResponse }>({
                mutation: ACTIVATE_MQTT_CONTROL_BY_SERIAL_NO,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.activateMQTTControlBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public activateEcobeeControlBySerialNo(id: string, dealerId: string): Observable<DefaultUpdateResponse | undefined> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('activateEcobeeControlBySerialNo').mutate<{ activateEcobeeControlBySerialNo: DefaultUpdateResponse }>({
                mutation: ACTIVATE_ECOBEE_CONTROL_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.activateEcobeeControlBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public publishCommand(id: string, dealerId: string, data: CommandInput) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('publishCommand').mutate<{ publishCommand: DefaultUpdateResponse }>({
                mutation: PUBLISH_COMMAND,
                variables: {
                    id,
                    dealerId,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    if (value.data?.publishCommand.code === ApiResponseCode.SUCCESS) {
                        return value.data?.publishCommand;
                    }

                    throw new HttpErrorResponse({
                        status: value.data?.publishCommand.code || ApiResponseCode.SERVER_ERROR,
                        statusText: value.data?.publishCommand.message || ApiResponseMessage.SERVER_ERROR
                    });
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public querySystemBySerialNo(id: String, unitType: String): Observable<FactorySystemOverviewResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('querySystemBySerialNo').query<{ querySystemBySerialNo: FactorySystemOverviewResponse }>({
                query: QUERY_FACTORY_SYSTEM_BY_SERIAL_NO,
                variables: {
                    id,
                    unitType
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { querySystemBySerialNo } = value.data;

                    const { code, message } = querySystemBySerialNo;
                    const codeNum = parseInt(String(code), 10);

                    if (codeNum !== ApiResponseCode.SUCCESS) {
                        throw new HttpErrorResponse({
                            status: codeNum,
                            statusText: message
                        });
                    }

                    try {
                        const systemType = querySystemBySerialNo.data?.products[0]?.systemType;

                        return {
                            code: codeNum,
                            message,
                            data: {
                                ...querySystemBySerialNo.data,
                                systemType
                            }
                        };
                    }
                    catch {
                        throw new HttpErrorResponse({
                            status: ApiResponseCode.NOT_FOUND,
                            statusText: MISSING_API_RESPONSE_DATA
                        });
                    }
                })
            )),
            catchError((err: HttpErrorResponse) => {
                if (err.status !== ApiResponseCode.NOT_FOUND) {
                    this.showErrorToast(err);
                }

                throw err;
            })
        );
    }

    public queryCarrierELTComfortProfile(id: string): Observable<EltActivityResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryCarrierELTComfortProfile').query<{ queryCarrierELTComfortProfile: EltActivityResponse }>({
                query: QUERY_WALL_CONTROL_CARRIER_ELT_COMFORT_PROFILE,
                variables: { id },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryCarrierELTComfortProfile } = value.data;

                    return queryCarrierELTComfortProfile;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateProductInfoBySystemId(dataToUpdate: ConnectedProductUpdate) {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const { systemId, productType, data } = dataToUpdate;
        const lowerCaseProductType = productType.toLowerCase();
        const dataToUpload = {
            ...data,
            unitType: lowerCaseProductType
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateProductInfoBySystemId').mutate<{ updateProductInfoBySystemId: DefaultUpdateResponse }>({
                mutation: UPDATE_PRODUCT_INFO_BY_SYSTEM_ID,
                variables: {
                    systemId,
                    data: dataToUpload
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateProductInfoBySystemId)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
