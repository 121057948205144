/* eslint-disable max-lines */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { SystemType } from 'private/app/models/connected-product.model';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { OduConfigurationResponse, OduHistoricalDataResponse, OduStatusResponse } from 'private/app/models/odu.model';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

export interface OduConfigInputProps {
    dehumidifyAirflow?: string;
    coolingLockoutTemp?: string;
    coolingAirflow?: string;
    heatingAirflow?: string;
    heatingLockoutTemp?: string;
    lowAmbientCooling?: string;
    defrostWithBackupHeat?: string;
    maxRPM?: string;
    defrostInterval?: string;
    utilityCurtailmentCooling?: string;
    utilityCurtailmentHeating?: string;
    variableCapacityFloorCFM?: string;
    variableCapacityCeilingCFM?: string;
}

export const QUERY_ODU_CONFIGURATION_BY_SERIAL_NO = gql`
    query QueryOduConfigurationBySerialNo($id: String!, $dealerId: String) {
        queryOduConfigurationBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                configEditParams {
                    coolingAirflow {
                        readOnly
                        options
                    }
                    heatingAirflow {
                        readOnly
                        options
                    }
                    heatingLockoutTemp {
                        readOnly
                        options
                    }
                    lowAmbientCooling {
                        readOnly
                    }
                    defrostWithBackupHeat {
                        readOnly
                    }
                    maxRPM {
                        readOnly
                    }
                    defrostInterval {
                        readOnly
                    }
                    utilityCurtailmentCooling {
                        readOnly
                    }
                    utilityCurtailmentHeating {
                        readOnly
                    }
                    variableCapacityFloorCFM {
                        readOnly
                        options
                    }
                    variableCapacityCeilingCFM {
                        readOnly
                        options
                    }
                }
                dateTime
                brownoutDisable
                coolingAirflow
                coolingLatchMode
                coolingLatchTemp
                coolingLockoutTemp
                defrostInterval
                defrostWithBackupHeat
                dehumidifyAirflow
                flowrateSetting
                freezeLimit
                heatingAirflow
                heatingLatchMode
                heatingLockoutTemp
                heatLatchTemp
                lockoutCount
                looppumpPowerSetting
                lowAirflowMultiplier
                lowAmbientCooling
                maxFloorMinCeilingCFM
                maxCoolingStage
                maxHeatingStage
                maxRPM
                minHeatingStage
                minCoolingStage
                quietShift
                type
                userCoolingStageLatch
                userHeatingStageLatch
                userMaxCoolingStage
                userMaxHeatingStage
                userMinCoolingStage
                userMinHeatingStage
                utilityCurtailmentCooling
                utilityCurtailmentHeating
                variableCapacityCeilingCFM
                variableCapacityCFMLimitsReceived
                variableCapacityFloorCFM
                variableCapacityMaxCeilingCFM
                variableCapacityMinFloorCFM
            }
        }
    }
`;

export const QUERY_ODU_STATUS_RESPONSE_BY_SERIAL_NO = gql`
        query QueryOduStatusResponseBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryOduStatusResponseBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                dateTime
                liquidLinePressure
                liquidLineTemperature
                subCooling
                stage
                compressorRPM
                dischargeTemp
                enteringRefrigerantTemp
                exvPosition
                indoorAirflowCFM
                lineVoltage
                lockoutActive
                lockoutTimer
                maxAvailableCoolingStage
                maxAvailableHeatingStage
                maxOperatingCoolingStage
                maxOperatingHeatingStage
                minAvailableCoolingStage
                minAvailableHeatingStage
                minOperatingCoolingStage
                minOperatingHeatingStage
                mode
                outdoorCoilTemp
                spareSensorStatus
                status
                suctionPressure
                suctionSuperheat
                suctionTemp
                blowerRPM
                curtailmentActive
                DCBusVoltage
                dischargePressure
                dischargeSuperheat
                IPMTemp
                leavingAirTemp
                lowAmbientCooling
                type
                outdoorFanRPM
                outdoorTemp
                PFCMTemp
                spareSensorValue
                staticPressure
                vaporInjectionEXVPosition
                liquidServiceValveSubcooling
                estimatedServiceValveSubcooling
                liquidServiceValvePressure
            }
        }
    }
`;

export const QUERY_ODU_HISTORICAL_DATA_BY_SERIAL_NO = gql`
    query QueryOduHistoricalDataBySerialNo($id: String!, $dealerId: String) {
        queryOduHistoricalDataBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                dateTime
                defrostCycles
                defrostHours
                highCoolCycle
                highCoolHours
                highHeatCycles
                highHeatHour
                highVentCycle
                highVentHours
                lowCoolCycles
                lowCoolHours
                lowHeatCycles
                lowHeatHours
                lowVentCycles
                lowVentHours
                mediumCoolCycles
                mediumCoolHours
                mediumHeatCycles
                mediumHeatHours
                mediumHighCoolCycles
                mediumHighCoolHours
                mediumHighHeatCycles
                mediumHighHeatHours
                mediumLowCoolCycles
                mediumLowCoolHours
                mediumLowHeatCycles
                mediumLowHeatHours
                powerCycles
                sumpHeatCycles
                sumpHeatHours
                totalOnHours
            }
        }
    }
`;

export const UPDATE_ODU_CONFIG_BY_SERIAL_NO = gql`
    mutation UpdateOduConfigBySerialNo($id: String!, $dealerId: String, $data: OduConfigInputProps!) {
        updateOduConfigBySerialNo(id: $id, dealerId: $dealerId, data: $data) {
            message,
            code,
            data {
                id
                name
                value
                description
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class OduService extends ConnectedPortalBaseService {
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    public queryOduConfigurationBySerialNo(id: string, dealerId: string): Observable<OduConfigurationResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryOduConfiguration').query<{ queryOduConfigurationBySerialNo: OduConfigurationResponse }>({
                query: QUERY_ODU_CONFIGURATION_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryOduConfigurationBySerialNo } = value.data;

                    return this.removeEmptyProps(queryOduConfigurationBySerialNo) as OduConfigurationResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateOduConfigBySerialNo(id: string, dealerId: string, data: OduConfigInputProps) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateOduConfigBySerialNo').mutate<{ updateOduConfigBySerialNo: DefaultUpdateResponse }>({
                mutation: UPDATE_ODU_CONFIG_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateOduConfigBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryOduStatusResponseById(id: string, dealerId: string, systemType: SystemType): Observable<OduStatusResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const variables: any = {
            id,
            dealerId,
            systemType
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryOduStatusResponseBySerialNo').query<{ queryOduStatusResponseBySerialNo: OduStatusResponse }>({
                query: QUERY_ODU_STATUS_RESPONSE_BY_SERIAL_NO,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryOduStatusResponseBySerialNo } = value.data;

                    return this.removeEmptyProps(queryOduStatusResponseBySerialNo) as OduStatusResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryOduHistoricalData(id: string, dealerId: string): Observable<OduHistoricalDataResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryOduHistoricalDataBySerialNo').query<{ queryOduHistoricalDataBySerialNo: OduHistoricalDataResponse }>({
                query: QUERY_ODU_HISTORICAL_DATA_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryOduHistoricalDataBySerialNo } = value.data;

                    return this.removeEmptyProps(queryOduHistoricalDataBySerialNo) as OduHistoricalDataResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
