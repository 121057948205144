import { Component, Input, ViewEncapsulation } from '@angular/core';
export interface Bulletin {
    link: string;
    target: string;
    title: string;
}

@Component({
    selector: 'hvac-bulletins',
    templateUrl: './bulletins.component.html',
    styleUrls: ['./bulletins.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BulletinsComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() bulletins: any;

    parsedBulletins: Bulletin[];
    title: string;
    ngOnInit() {
        this.title = this.bulletins.Content.title;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.parsedBulletins = this.bulletins.Content.list.$values.map((bulletin: any) => {
            const linkData = bulletin.Content.links;

            return {
                title: linkData.linkText,
                link: linkData.linkExternal,
                target: linkData.linkOperation.Key
            };
        });
    }
}
