<ng-template [ngIf]="!isLoading" [ngIfElse]="spinner">
<div class="hvac-manage-enrollment-container">
    <div class="hvac-program-header">
        <h1 class="hvac-h4">
            {{programData.name}}
        </h1>
    </div>
    <div class="hvac-row">
        <div class="hvac-col-lg-9">
            <p class="hvac-p-sm">
                {{programData.description}}
            </p>
        </div>
        <div class="hvac-col-lg-3">
            <div class="hvac-program-status">
                <hvac-data-block status="inactive">{{programData.brand.name}}</hvac-data-block>
                <hvac-data-block status="success">{{programData.status}}</hvac-data-block>
            </div>
        </div>
    </div>
    
    
    <form [formGroup]="manageEnrollmentsForm">
        <div class="hvac-row hvac-manage-enrollment-filter-container">
            <utc-input class="hvac-col-lg-4  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.dealerName"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_NAME' | translate">
            </utc-input>
        
            <utc-input class="hvac-col-lg-2  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.dealerDisplayId"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_ID' | translate">
            </utc-input>
        
            <utc-input class="hvac-col-lg-3  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.city"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.CITY' | translate">
            </utc-input>
        
            <utc-input class="hvac-col-lg-3  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.state" 
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.STATE' | translate">
            </utc-input>
        
            <utc-input class="hvac-col-lg-4  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.workflowStatus"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.WORKFLOW_STATUS' | translate">
            </utc-input>
        
            <utc-input class="hvac-col-lg-4  hvac-col-md-6 hvac-col-xs-12 hvac-enrollment-input" type="text" [inputControl]="manageEnrollmentsForm.controls.sponsoringDistributor"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SPONSORING_DISTRIBUTOR' | translate">
            </utc-input>
        </div>
    </form>

    <h1 class="hvac-p-sm">
        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLLMENT_STATUS' | translate | uppercase}}
    </h1>
    <hvac-tab-bar variant="secondary">
        <button *ngFor="let tab of tabs; let i = index;" (click)="handleTabClick(i, tab)" [ngClass]="{ 'hvac-active' : selectedTab === i }">{{ tab }}</button>
    </hvac-tab-bar>

    <section class="hvac-enrollments-table">
        <hvac-table class="hvac-enrollment-table-wrap" [theme]="'zebra'" [responsive]="true">
            <thead>
                <tr>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT_ALL' | translate">
                        <utc-checkbox class="hvac-select-all-checkbox" [checked]="isAllEnrllmntsSlctdFrCrntFltr" (change)="handleSelectAll($event)"></utc-checkbox>
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_NAME' | translate }}
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_ID' | translate }}
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.CITY' | translate }}
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.STATE' | translate }}
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLLMENT_STATUS' | translate }}
                    </td>
                    <td *ngIf="isDealerConversionProgram">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.WORKFLOW_STATUS' | translate }}
                    </td>
                    <td *ngIf="isEnrollmentLevel">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLLMENT_LEVEL' | translate }}
                    </td>
                    <td>
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SPONSORING_DISTRIBUTOR' | translate }}
                    </td>
                    <td *ngIf="showManageEnrollmentDropdowns$.value">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.MANAGE_ENROLLMENT' | translate }}
                    </td>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let enrollment of (currentPageEnrollmentData$ | async)">
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT' | translate">
                        <utc-checkbox [checked]="enrollment.isChecked" (change)="selectEnrollment(enrollment)"></utc-checkbox>
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_NAME' | translate">{{ enrollment.dealerName }}</td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.COMPANY_ID' | translate">{{ enrollment.dealerDisplayId }}</td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.CITY' | translate">{{ enrollment.city }}</td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.STATE' | translate">{{ enrollment.state }}</td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLLMENT_STATUS' | translate">
                        <span *ngIf="enrollment.enrollmentStatus === 0">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentStatus === 1">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentStatus === 2">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentStatus === 3">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_FINAL' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentStatus === 4">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_INITIAL' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentStatus === 5">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.RETURNED' | translate }}</span>
                    </td>
                    <td *ngIf="isDealerConversionProgram" [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.WORKFLOW_STATUS' | translate">
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 0">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.ELIGIBLE' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 1">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_APPROVED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 2">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_APPROVED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 3">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_DECLINED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 4">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_DECLINED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 5">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_RETURNED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 6">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.DIST_SUBMITTED' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentWorkFlowStatusId === 7">{{ 'ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_RETURNED' | translate }}</span>
                    </td>
                    <td *ngIf="isEnrollmentLevel" [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLLMENT_LEVEL' | translate">
                        <span *ngIf="enrollment.enrollmentLevel === 1">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LOCATOR' | translate }}</span>
                        <span *ngIf="enrollment.enrollmentLevel === 2">{{ 'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUSPEND' | translate }}</span>
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SPONSORING_DISTRIBUTOR' | translate">{{ enrollment.sponsoringDistributor }}</td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.MANAGE_ENROLLMENT' | translate">
                        <utc-select [shadow]="'none'" [size]="'small'" *ngIf="showManageEnrollmentDropdowns$.value"
                            [options]="enrollment.enrollmentOptions" 
                            [defaultOption]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT' | translate"
                            [group]="enrollmentActionForm" [control]="'enrollmentAction' + enrollment.dealerId"
                            [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.SELECT' | translate"
                            (change)="onSelectDealerConversionEnrollment(enrollment, enrollmentActionForm.controls['enrollmentAction' + enrollment.dealerId].value[0])">
                        </utc-select>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
        <p class="hvac-admin-enrollments-no-results" *ngIf="!(currentPageEnrollmentData$ | async)?.length && !isLoading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
        <utc-loading-spinner spinnerSize="small" *ngIf="isTableLoading"></utc-loading-spinner>
        <div class="hvac-row">
            <div class="hvac-col-lg-6 hvac-col-md-6">
                <div class="hvac-pagination">
                    <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
                        (pageChange)="handlePageChange($event)">
                    </hvac-pagination>
                </div>
            </div>
            <div class="hvac-col-lg-6 hvac-col-md-6" *ngIf="(currentPageEnrollmentData$ | async)?.length && !isLoading">
                <div class="hvac-enrollment-buttons">
                    <button [hvacButton]="'default'" size="small" routerLink="/Admin/Program/search-program/search">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
                    </button>
                    <button [hvacButton]="'primary'" size="small" (click)="showMultipleEnrollConfirmationModal()" [disabled]="isMultipleEnroll" *ngIf="programData?.massEnrollFl && isEnrollActionsEnabled && showManageEnrollmentDropdowns$.value">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.ENROLL_SELECTED' | translate }}</span>
                    </button>
                    <button [hvacButton]="'primary'" size="small" (click)="exportSelectedEnrollments()" [disabled]="disableExportButton">
                        <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.EXPORT_SELECTED' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <utc-modal modal
        [visible]="isEnrollModalVisible"
        theme="rounded"
        (closeAction)="handleCloseModal()"
        class="hvac-enrollment-status-control-modal">
        <hvac-modal-header><span class="hvac-h4">{{ 'ACCOUNT_ADMIN.PROGRAMS.CONFIRM' | translate }}</span></hvac-modal-header>
        <div class="hvac-enrollment-status-content hvac-rhythm-4">
            <p class="hvac-p-sm">{{enrollmentConfirmMessage}}</p>
        </div>
        <div class="hvac-enrollment-status-buttons">
            <button hvacButton="default" size="small" (click)="handleCloseModal()">{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</button>
            <button hvacButton="primary" size="small" (click)="handleOkModal()">{{ 'ACCOUNT_ADMIN.PROGRAMS.OK' | translate }}</button>
        </div>
    </utc-modal>
</div>
</ng-template>
<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>