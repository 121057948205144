import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FetchApprovalRequests, ReceiveApprovalRequests } from './approval-requests.actions';
import { ApprovalRequestsService } from 'private/app/services/approval-requests.service';
import { ApprovalRequestsResponse, ApprovalRequestUser } from 'common/models/approval-requests';

export interface ApprovalRequestsStateModel {
    count: number;
    users: ApprovalRequestUser[];
}

@State<ApprovalRequestsStateModel>({
    name: 'approvalRequests',
    defaults: {
        count: 0,
        users: []
    }
})
@Injectable({ providedIn: 'root' })
export class ApprovalRequestsState {
    constructor(private readonly approvalRequestsService: ApprovalRequestsService) {}

    @Selector()
    static requestCount({ count }: ApprovalRequestsStateModel): number {
        return count;
    }

    @Selector()
    static approvalRequestUsers({ users }: ApprovalRequestsStateModel): ApprovalRequestUser[] {
        return users;
    }

    @Action(FetchApprovalRequests)
    fetchApprovalRequests(ctx: StateContext<ApprovalRequestsStateModel>) {
        return this.approvalRequestsService.getApprovalRequestsCount().subscribe((response: ApprovalRequestsResponse) => {
            ctx.dispatch(new ReceiveApprovalRequests(response));
        }, () => {
            ctx.dispatch(new ReceiveApprovalRequests({
                count: 0,
                approvalUsers: []
            }));
        });
    }

    @Action(ReceiveApprovalRequests)
    receiveApprovalRequests(ctx: StateContext<ApprovalRequestsStateModel>, action: ReceiveApprovalRequests) {
        return ctx.patchState({
            count: action.approvalRequests.count,
            users: action.approvalRequests.approvalUsers
        });
    }
}
