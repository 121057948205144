import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResponseSubscription } from 'private/app/models/user-validation';
import { UserValidationService } from 'private/app/services/user-validation/user-validation.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'app-user-validation',
    templateUrl: './user-validation.component.html',
    styleUrls: ['./user-validation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserValidationComponent implements OnInit {
    subscriptionStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    eventsSubject: Subject<boolean> = new Subject<boolean>();
    showUserValidation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public apiRequestActive: boolean;

    public formValid$ = new BehaviorSubject(false);
    private subscriptionValid$ = new BehaviorSubject(false);

    constructor(
        private readonly userValidationService: UserValidationService
    ) { }

    ngOnInit(): void {
        this.userValidationService.getSubscriptionStatus().subscribe((subscriptionData) => {
            this.subscriptionStatus$.next(subscriptionData[0].isSubscribed);
        });
    }

    updateInfoValid(isValid: boolean) {
        this.formValid$.next(isValid);
    }

    changeSubscriptionControl(value: Event) {
        const input = <HTMLInputElement>value.target;
        this.subscriptionValid$.next(input.checked);
        this.subscriptionStatus$.next(input.checked);
    }

    updateUserProfileDetails() {
        this.eventsSubject.next(true);
    }

    cancelForm() {
        this.showUserValidation$.next(false);
    }

    onSuccessEmailValidation(validation: boolean) {
        if (validation) {
            if (this.subscriptionStatus$.value) {
                this.apiRequestActive = true;
                this.userValidationService.createSubscription().subscribe((status: ResponseSubscription) => {
                    if (status) {
                        this.apiRequestActive = false;
                    }
                });
            }
            else {
                this.userValidationService.deleteSubscription().subscribe((status: ResponseSubscription) => {
                    if (status) {
                        this.apiRequestActive = false;
                    }
                });
            }
        }
    }
}
