import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BrowseModule } from 'common/views/browse/browse.module';
import { ProductDetailsModule } from 'common/views/product-details/product-details.module';
import { ComponentsModule } from 'private/app/components/components.module';
import { TridionContentRoutingModule } from './trid-content-routing.module';
@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        TridionContentRoutingModule,
        TranslateModule,
        ProductDetailsModule,
        BrowseModule
    ]
})
export class TridionContentPageModule { }
