import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'private/app/components/components.module';
import { CreateAccountInformationComponent } from './createAccountInformation.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        TranslateModule
    ],
    declarations: [
        CreateAccountInformationComponent
    ]
})
export class CreateAccountInformationModule { }
