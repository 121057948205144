/* tslint:disable:max-line-length */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { buildHttpParams } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { EmailConflictError, EmailError, EmailVerificationError, EmailVerificationForbiddenError, UserNameConflictError, UserNameError } from 'common/models/create-account-errors';
import { AccountInfoPostData, AssociatedDistributorPostData, AssociatedDistributorResult, CreateAccountStatus, EmailVerificationCodePostData, EmailVerificationStatus, RequestPasswordResetPutData, ResponsePasswordReset, SubmitVerificationCodePostData, UniqueAccountInfoStatus } from 'private/app/models/accountInfo';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from 'common/services/toast.service';
/* tslint:enable:max-line-length */

@Injectable({ providedIn: 'root' })
export class CreateAccountService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly toastService: ToastService
    ) { }

    checkUserName(userName: string) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.get<string>(`${environment.api.accountUserApi}${userName}`, { ...options }).pipe(
            map((_res) => UniqueAccountInfoStatus.Unique),
            catchError((err: HttpErrorResponse) => {
                throw err.status === 409 || err.status === 403 ? new UserNameConflictError() : new UserNameError();
            })
        );
    }

    checkEmail(email: string) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.get<string>(`${environment.api.accountEmailApi}/${email}`, { ...options }).pipe(
            map((_res) => UniqueAccountInfoStatus.Unique),
            catchError((err: HttpErrorResponse) => {
                throw err.status === 409 ? new EmailConflictError() : new EmailError();
            })
        );
    }

    public postAccelerateSignUp(body: AccountInfoPostData): Observable<null | HttpErrorResponse> {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<null>(`${environment.api.acceleratedSignUp}`, body, { ...options }).pipe(
            catchError((err: HttpErrorResponse) => of(err))
        );
    }

    requestEmailVerificationCode(emailVerificationCodePostData: EmailVerificationCodePostData) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<EmailVerificationCodePostData>(environment.api.accountEmailCodeApi, emailVerificationCodePostData, { ...options }).pipe(
            map((_res) => EmailVerificationStatus.Requested),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    throw new EmailVerificationError(err.message);
                }
                if (err.status === 403) {
                    throw new EmailVerificationForbiddenError();
                }

                throw new EmailError();
            })
        );
    }

    requestPasswordReset(requestParam: RequestPasswordResetPutData): Observable<ResponsePasswordReset> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<ResponsePasswordReset>(environment.api.resetPasswordApi, requestParam, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    submitVerificationCode(submitVerificationCodePostData: SubmitVerificationCodePostData) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<SubmitVerificationCodePostData>(environment.api.accountEmailVerificationApi, submitVerificationCodePostData, { ...options }).pipe(
            map((_res) => EmailVerificationStatus.Success),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    throw new EmailVerificationError();
                }
                if (err.status === 403) {
                    throw new EmailVerificationForbiddenError();
                }

                throw new EmailError();
            })
        );
    }

    createAccount(accountInformation: AccountInfoPostData) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<AccountInfoPostData>(environment.api.accountCreateApi, accountInformation, { ...options }).pipe(
            map((_res) => CreateAccountStatus.Success)
        );
    }

    checkCompanyCode(companyCode: string) {
        const params = buildHttpParams({ companyId: companyCode });
        const options = this.apiOptions.getHttpOptions();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.httpClient.get<any>(`${environment.api.accountCompanyApi}/all`, {
            params,
            ...options
        });
    }

    searchCompanyZipCode(zipcode: string) {
        const params = buildHttpParams({ zipcode });
        const options = this.apiOptions.getHttpOptions();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.httpClient.get<any>(environment.api.accountCompanyApi, {
            params,
            ...options
        }).pipe(
            catchError((err) => of(err))
        );
    }

    checkAssociatedDistributorForDealer(associatedDistributorPostData: AssociatedDistributorPostData): Observable<AssociatedDistributorResult> {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<AssociatedDistributorResult>(environment.api.accountAssociatedDistributor, associatedDistributorPostData, { ...options }).pipe(
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }


    verifyEmailCode(submitVerificationCodePostData: SubmitVerificationCodePostData) {
        const options = this.apiOptions.getHttpOptions();

        return this.httpClient.post<SubmitVerificationCodePostData>(environment.api.accountEmailVerificationApi, submitVerificationCodePostData, { ...options }).pipe(
            map((_res) => EmailVerificationStatus.Success),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 400) {
                    this.toastService.add({
                        content: new EmailVerificationError().content,
                        id: new EmailVerificationError().name,
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    });
                }
                if (err.status === 403) {
                    this.toastService.add({
                        content: new EmailVerificationForbiddenError().content,
                        id: new EmailVerificationForbiddenError().name,
                        theme: 'error',
                        closeable: true,
                        autoClose: true
                    });
                }

                throw new EmailError();
            })
        );
    }
}
