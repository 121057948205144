import { Component, ElementRef, Input, SimpleChange, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';
import { Company } from '../../models/account-admin-search';

@Component({
    selector: 'app-account-admin-distributor-details',
    templateUrl: './account-admin-distributor-details.component.html',
    styleUrls: ['./account-admin-distributor-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountAdminDistributorDetailsComponent {
    @Input() company?: Company;
    @Input() brands?: string;
    @Input() rsms: string;
    @Input() tsms: string;
    @Input() accountManagers: string;
    @Input() services?: string;
    @Input() pricingAnal?: string;
    @Input() prsms?: string;
    @Input() ptams?:string;
    jumpBarData: JumpBarLink;

    constructor(
        private readonly jumpBarService: JumpbarService,
        private readonly element: ElementRef,
        private translatePipe: TranslateService
    ) {}

    ngOnInit() {
        this.initilizeJumpBar();
    }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
        for (const propName in changes) {
            if (propName === 'company') {
                const change = changes[propName];
                if (!change.isFirstChange) {
                    this.initilizeJumpBar();
                }
            }
        }
    }

    initilizeJumpBar() {
        this.jumpBarData = {
            link: this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_INFORMATION'),
            id: [this.translatePipe.instant('ACCOUNT_ADMIN.DISTRIBUTOR_INFORMATION')],
            container: this.element.nativeElement
        };
        this.jumpBarService.registerJumpBarLink(this.jumpBarData);
    }
}
