<p class="hvac-phvac-account-admin-results-count">
    <span *ngIf="totalCount">{{ 'ACCOUNT_ADMIN.RESULTS_COUNT' | translate: { totalCount: totalCount} }}</span>
</p>

<div class="hvac-account-admin-table-wrap hvac-rhythm-3">
    <hvac-table>
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button class="hvac-table-sort-btn" (click)="sort(item)">
                        {{item.title}}
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
            <button type="button" role="link" (click)="onRowClick(company.hvacCompanyId.toString() || '')" class="hvac-account-admin-table-link" *ngFor="let company of companies">
                <tr>
                    <td>
                        <div>{{company.companyName}}</div>
                        <div class="hvac-p-xs" *ngFor="let line of createDetails(company)">
                            <div>{{line}}</div>
                        </div>
                    </td>
                    <td>
                        {{company.hvacCompanyId}}
                    </td>
                    <td>
                        {{company.soldTo}}
                    </td>
                    <td>
                        {{company.shipTo}}
                    </td>
                    <td>
                        {{company.payer}}
                    </td>
                    <td>
                        {{company.companyType}}
                    </td>
                </tr>
            </button>
        </tbody>
    </hvac-table>

    <p class="hvac-account-admin-no-results" *ngIf="!companies?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' |
        translate }}</p>

    <utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small"></utc-loading-spinner>
</div>

<div class="hvac-row hvac-between-xs hvac-middle-xs">
    <div class="hvac-col-xs hvac-rhythm-4">
        <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="pagination"
            (pageChange)="handlePageChange($event)">
        </hvac-pagination>
    </div>

    <div class="hvac-col-xs hvac-rhythm-4">
        <div class="hvac-account-admin-table-export-btn">
            <button hvacButton="primary" buttonType="submit"
                [disabled]="!companies?.length || totalCount > exportCompanyLimit"
                (click)="postCompanySearchExport(companies ? companies.length : 0)">
                <span>{{ 'ACCOUNT_ADMIN.EXPORT' | translate }}</span>
            </button>
        </div>
    </div>
    <div class="hvac-account-export-warning" *ngIf="totalCount > exportCompanyLimit">
        <hvac-toast [name]="toastOutlet"></hvac-toast>
    </div>
</div>
