<section class="hvac-account-admin-header hvac-company-designation-header hvac-create-company-designation-section">
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>
<div class="hvac-content-container hvac-create-user-content-container">
    <div class="hvac-account-admin-form-header hvac-account-admin-heading hvac-account-admin-heading-default">
        <h1 class="hvac-h4">
            {{ getCreateUserActiveScreenName() }}
        </h1>
        <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/', {}]"
            queryParamsHandling="merge" class="hvac-text-link">
            <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
            <div class="hvac-back-text">{{ "ACCOUNT_ADMIN.BACK_TO_COMPANY_ADMIN" | translate }}</div>
        </a>
    </div>
    <ng-container *ngIf="createUserOnStepNumber==='step1_searchCompany'">
        <hvac-create-user-company-search-form (onClickSearch)="onClickSearch()" [clickClearForm]="onClickClearSearch"></hvac-create-user-company-search-form>
        <hvac-create-user-company-table *ngIf="isCompanyListDataAvailable" (createUserChangePage)="onClickCreateUserChangePage($event)"></hvac-create-user-company-table>
    </ng-container>

    <ng-container *ngIf="createUserOnStepNumber==='step2_registeredUsersInCompany'">
        <hvac-review-registered-users-in-company [company]="company" (createUserChangePage)="onClickCreateUserChangePage($event)"></hvac-review-registered-users-in-company>
    </ng-container>

    <ng-container *ngIf="createUserOnStepNumber==='step3_userDetails'">
        <hvac-create-user-details [company]="company" (createUserChangePage)="onClickCreateUserChangePage($event)" (userDetails)="CreateUserDetails($event)"></hvac-create-user-details>
    </ng-container>

    <ng-container *ngIf="createUserOnStepNumber==='step4_userRolesFeatures'">
        <hvac-user-roles-details (activeScreenName)="getActiveScreenName($event)" (registrationUserChangePage)="onContinueToStep2Click($event)" (userDetailsSubmit)="userCreateDetailsSubmit($event)" [page]="'createUser'" [userDetails]="userDetails"></hvac-user-roles-details>
    </ng-container>
    <utc-loading-spinner spinnerSize="small" *ngIf="isLoading"></utc-loading-spinner>
</div>

