<section class="hvac-login-create-account">
    <div class="hvac-login-create-account-heading">
        <h2 class="hvac-login-create-account-title">{{ 'LOGIN_PAGE.CREATE_ACCOUNT.TITLE' | translate }}</h2>
        <p class="hvac-login-create-account-body">{{ 'LOGIN_PAGE.CREATE_ACCOUNT.BODY' | translate }}</p>
    </div>
    <utc-create-account-info-form (onValidChange)="updateInfoValid($event)"></utc-create-account-info-form>
    <div class="hvac-login-create-account-controls">
        <button class="hvac-login-create-account-continue" [hvacButton]="'primary'" (click)="submitAccountInfoForm()" [disabled]="!(infoValid$ | async)">
            <span>{{ 'LOGIN_PAGE.CREATE_ACCOUNT.CONTINUE' | translate }}</span>
            <utc-svg src="assets/icons/arrow.svg"></utc-svg>
        </button>
    </div>
</section>
