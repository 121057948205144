<div class="hvac-row">
    <div *ngIf="(isLoading$ | async); else content" class="hvac-content-loader">
        <utc-loading-spinner></utc-loading-spinner>
    </div>

    <ng-template #content>
        <ng-container *ngIf="(pageData$ | async) as data">
        <div class="hvac-row" style="position: relative">
            <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-cp-system-conditions hvac-rhythm-3">
                <div class="hvac-row">
                    <div class="hvac-col-xs-6 hvac-col-md-12">
                        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS' | translate}}</h2>


                            <div class="hvac-row hvac-cp-carrier-elt-system-conditions-params">
                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-carrier-elt-system-reading">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_TEMP' | translate"
                                        [value]="data.systemDetails.indoorTemp| emptyToChar: CharSymbol.DoubleDash | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-carrier-elt-system-reading">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_HUMIDITY' | translate"
                                        [value]="data.systemDetails.indoorHumidity | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Percent"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.OUTDOOR_TEMP' | translate"
                                        [value]="data.systemDetails.outdoorTemp | emptyToChar: CharSymbol.DoubleDash | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)"></hvac-stats-widget>
                                </div>
                            </div>


                    </div>
                    <div class="hvac-col-xs-6 hvac-col-md-12">
                        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_SETTINGS' | translate}}</h2>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1 hvac-cp-carrier-elt-system-reading">
                                <hvac-stats-widget class="hvac-cp-cool" [label]="'CONNECTED_PORTAL.WALL_CONTROL.COOL_TO' | translate" [value]="data.systemDetails.coolTo | emptyToChar: CharSymbol.DoubleDash | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)"></hvac-stats-widget>
                            </div>

                            <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1 hvac-cp-carrier-elt-system-reading">
                                <hvac-stats-widget  class="hvac-cp-heat" [label]="'CONNECTED_PORTAL.WALL_CONTROL.HEAT_TO' | translate" [value]="data.systemDetails.heatTo | emptyToChar: CharSymbol.DoubleDash | numberSuffix: (tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit)"></hvac-stats-widget>
                            </div>

                            <div class="hvac-col-xs-12 hvac-col-md"></div>
                        </div>
                    </div>

                    <div class="hvac-col-lg-12 hvac-cp-system-condition-warning" *ngIf="isAnySystemConditionOutOfRange">
                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg"></utc-svg> <div class="hvac-p-sm hvac-fw-rg hvac-cp-system-condition-warning-text">{{ 'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS_MESSAGES.PARAMETER_OUT_OF_RANGE' | translate }}</div>
                    </div>

                </div>

        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-cp-system-mode hvac-rhythm-3">
            <div class="hvac-row">
                <div class="hvac-col-xs-12">
                    <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_MODE' | translate}}</h2>

                    <div class="hvac-row">
                        <div class="hvac-col-xs-12">
                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-lg-4">
                                    <div *ngIf="data.systemMode.isSystemModeEnabled" class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-3">
                                        <div class="hvac-col-xs hvac-col-lg-12 hvac-rhythm-2">
                                            <div class="hvac-subhead hvac-cp-heading">{{'CONNECTED_PORTAL.WALL_CONTROL.USER_SETTINGS' | translate}}</div>
                                        </div>

                                        <div class="hvac-col-xs hvac-col-lg-12 hvac-start-lg">
                                            <hvac-data-block [status]="data.systemMode.userSetting === 'auto' ? 'success' : 'inactive'">
                                                {{ data.systemMode.userSetting }}
                                            </hvac-data-block>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="data.systemMode.isEquipmentActivityEnabled" class="hvac-col-xs-12 hvac-col-lg-8">
                                    <div class="hvac-row hvac-rhythm-2">
                                        <div class="hvac-col-xs">
                                            <div class="hvac-subhead hvac-cp-heading">{{'CONNECTED_PORTAL.WALL_CONTROL.EQUIPMENT_ACTIVITY' | translate}}</div>
                                        </div>
                                    </div>

                                    <div class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-1" *ngIf="eltWallControlStatus?.stageStatus && eltWallControlStatus?.systemType">
                                        <div class="hvac-col-xs">
                                            <div class="hvac-subhead">{{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_SYSTEM_TYPE.' + eltWallControlStatus?.systemType | translate }}</div>
                                        </div>

                                        <div class="hvac-col-xs">
                                            <hvac-data-block style="text-align: center;" [status]="eltWallControlStatus?.stageStatus?.toLowerCase() === 'idle' ? 'inactive' : 'success'"  width="30px">
                                                {{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_STAGE_STATUS.' + eltWallControlStatus?.stageStatus | translate }}
                                            </hvac-data-block>
                                        </div>
                                    </div>


                                    <div class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-1" *ngIf="eltWallControlStatus?.fanMode">
                                        <div class="hvac-col-xs">
                                            <div class="hvac-subhead">{{'CONNECTED_PORTAL.WALL_CONTROL.FAN_MODE' | translate}}</div>
                                        </div>

                                        <div class="hvac-col-xs">
                                            <hvac-data-block style="text-align: center;" [status]="'success'" width="30px">
                                                {{eltWallControlStatus?.fanMode}}
                                            </hvac-data-block>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-12">
            <hvac-wall-control-elt-program-settings
            *ngIf="data.programSettings && dataSharingPermissions.programSettings"
            [data]="data.programSettings.data"
            [tempUnitFormat]="tempUnitFormat">
            ></hvac-wall-control-elt-program-settings>
        </div>
</div>
   </ng-container>
   </ng-template>
</div>
