import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { UserDetailsEntity } from 'private/app/models/user.model';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';

type SearchCompany = {
    userCreateStep: string,
    company: CompanyResultDataResponse
}
@Component({
    selector: 'hvac-review-registered-users-in-company',
    templateUrl: './review-registered-users-in-company.component.html',
    styleUrls: ['./review-registered-users-in-company.component.scss']
})
export class ReviewRegisteredUsersInCompanyComponent implements OnInit {
  @Input() company: CompanyResultDataResponse = {
      soldTo: '',
      postalCode: '',
      companyName: '',
      email: '',
      city: '',
      companyType: '',
      state: '',
      fax: '',
      hvacCompanyId: 0,
      country: '',
      phone: '',
      address1: '',
      address2: '',
      brandsName: '',
      parentBrandsName: '',
      warranty: '',
      shipTo: '',
      payer: '',
      authorizedBrands: []
  };

  @Output() createUserChangePage = new EventEmitter<{
      userCreateStep: string,
      company : CompanyResultDataResponse
  }>();

  public pageStep = 'step2_registeredUsersInCompany'
  public usersData: UserDetailsEntity[];
  public isLoading: boolean;
  public dataHeaders: DataHeader[] = [
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.LAST_NAME'),
          value: 'lastName',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.FIRST_NAME'),
          value: 'firstName',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.USER_ID'),
          value: 'userId',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.EMAIL'),
          value: 'email',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.ADDRESS'),
          value: 'address',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.PHONE'),
          value: 'phone',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.CITY'),
          value: 'city',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.POSTAL_CODE'),
          value: 'postalCode',
          order: null
      },
      {
          title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.LABELS.COUNTRY'),
          value: 'country',
          order: null
      }
  ];

  constructor(
      public accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
      private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
      this.accountAdminCompanyUtilsService.isuserListPageLoading$.subscribe((isLoading) => {
          this.isLoading = isLoading;
      });
      this.accountAdminCompanyUtilsService.getAccountAdminUsersByCompanyId(this.company.hvacCompanyId.toString());
      this.accountAdminCompanyUtilsService.filterListUserUnderSelectedCompany$.subscribe((data: UserDetailsEntity[]) => {
          this.usersData = data;
      });
  }

  onClickCreateUserChangePage(userCreateStep: boolean) {
      const createUserObj : SearchCompany = {
          company: this.company,
          userCreateStep: ''
      };

      if (!userCreateStep) {
          createUserObj.userCreateStep = 'step1_searchCompany';
          this.createUserChangePage.emit(createUserObj);
      }
      else if (userCreateStep) {
          createUserObj.userCreateStep = 'step3_userDetails';
          this.createUserChangePage.emit(createUserObj);
      }
  }
}
