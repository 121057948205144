<h1 class="hvac-h3">{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_INFORMATION' | translate}}</h1>
<div class="hvac-row">
    <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.COMPANY_NAME' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.name || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_CATEGORY' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.companyCategory?.name || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_TYPE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.companyType?.name || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.SOLD_TO' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.soldToCustomerId || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.SHIP_TO' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.shipToCustomerId || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PAYER' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.payerCustomerId || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'COMPANY_INFO_FORM.COMPANY_ID' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.hvacCompanyId || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.BRANDS' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{brands|| '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PRICE_LIST' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.priceListType || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PRICE_GROUP' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.priceGroup || '--'}}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>

        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.addressLine1 || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.addressLine2 || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CITY' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.city}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.STATE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.state || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ZIP_POSTAL_CODE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.postalCode || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.country|| '--'}}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>
    </div>

    <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PHONE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.phoneNumber || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.mobileNum || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.FAX' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.faxNumber || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.TOLL_FREE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.tollFreeNumber|| '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.EMAIL_ADDRESS' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.emailAddress || '--'}}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>

        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.RSM' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ rsms || '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PARTS_RSM' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ prsms || '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ACCOUNT_MANAGER' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ accountManagers || '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PARTS_ACCT_MANAGER' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ ptams || '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.TSM' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ tsms || '--' }}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PRICING_ANAL' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ pricingAnal || '--' }}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>

        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_LOCATOR' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.includeInDistributorLocator ? ('ACCOUNT_ADMIN.INCLUDE' | translate) : ('ACCOUNT_ADMIN.EXCLUDE' | translate)}}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>

        <li class="hvac-row hvac-top-xs">
            <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.STATUS' | translate}}</div>
            <div class="hvac-col-xs hvac-account-admin-detail-list-value" *ngIf="company">{{company.isActive ? ('ACCOUNT_ADMIN.ACTIVE' | translate) : ('ACCOUNT_ADMIN.INACTIVE' | translate)}}</div>
        </li>
        <li class="hvac-row hvac-top-xs">
            <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.LOCATION' | translate}}</div>
            <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{company?.legalEntity ? ('ACCOUNT_ADMIN.MAIN' | translate) : ('ACCOUNT_ADMIN.BRANCH' | translate)}}</div>
        </li>
        <li class="hvac-row hvac-top-xs">
            <div class="hvac-col-xs-6 hvac-label">{{ 'COMPANY_INFO_FORM.SERVICES' | translate}}</div>
            <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{ services || '--' }}</div>
        </li>
    </div>
</div>
