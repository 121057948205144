<ng-template [ngIf]="(this.purchaseOrderService.activePurchaseOrder$ | async)" let-activePurchaseOrder>
    <utc-loading-spinner class="hvac-po-list-loader" *ngIf="(activePurchaseOrder | json) === '{}' || isLoading"></utc-loading-spinner>
    <div *ngIf="(activePurchaseOrder | json) != '{}'">
        <div class="hvac-account-admin-heading-default hvac-account-admin-heading">
            <h2 class="hvac-trade-partner-subhead-text">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.PO_NUMBER' | translate }} {{ activePurchaseOrder.pONumber }}
            </h2>
            <p class="hvac-p-sm"><abbr title="required"
                class="hvac-program-header-required-mark">*</abbr>{{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" |
            translate }}</p>
        </div>
        <div class="hvac-row hvac-trade-parters-asn-details hvac-rhythm-6">
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-6 hvac-col-lg-4">
                <span class="hvac-h5">
                    <span>{{ 'TRADE_PARTNERS.ACKNOWLEDGE.DETAILS.PO_DATE' | translate }}</span>
                    <span class="hvac-trade-partner-def-list-value">{{activePurchaseOrder.pOCreationDate | yearMoDate }}</span>
                </span><br/>
                <span class="hvac-h5">
                    <span>{{ 'TRADE_PARTNERS.ACKNOWLEDGE.SUPPLIER_SALES_ORDER_NO' | translate }}</span>
                    <span class="hvac-trade-partner-def-list-value"> {{ activePurchaseOrder.supplierSoNumber }} </span>
                </span>
            </div>
        </div>
        <div [formGroup]="shippingForm" class="hvac-row hvac-trade-parters-asn-details hvac-rhythm-6">
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-6 hvac-col-lg-4">
                <label class="hvac-trade-partner-account-details hvac-rhythm-2">
                    <span class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.CARRIER' | translate }}<abbr title="required"
                        class="hvac-program-header-required-mark">*</abbr></span>
                    <utc-input
                        class="hvac-trade-partner-def-list-value"
                        [inputControl]="shippingForm.get('freightCarrier')"
                        type="text"
                        size="small"
                        inputId="freightCarrier"
                        [hideLabel]="true"
                        [required]="true"
                        [placeholder]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.CARRIER' | translate"
                    ></utc-input>
                </label>
                <label class="hvac-trade-partner-account-details hvac-rhythm-2">
                    <span class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.TRACKING' | translate }}<abbr title="required"
                        class="hvac-program-header-required-mark">*</abbr></span>
                    <utc-input
                        class="hvac-trade-partner-def-list-value"
                        [inputControl]="shippingForm.get('trackingNumber')"
                        type="text"
                        size="small"
                        inputId="trackingNumber"
                        [hideLabel]="true"
                        [required]="true"
                        [placeholder]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.TRACKING' | translate"
                    ></utc-input>
                </label>
                <label class="hvac-trade-partner-account-details hvac-rhythm-2">
                    <span class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.AMOUNT' | translate }}</span>
                    <utc-input
                        class="hvac-trade-partner-def-list-value"
                        [inputControl]="shippingForm.get('freightAmount')"
                        type="text"
                        size="small"
                        inputId="freightAmount"
                        [hideLabel]="true"
                        [placeholder]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.AMOUNT' | translate"
                    ></utc-input>
                </label>
            </div>
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-6 hvac-col-lg-4">
                <label class="hvac-trade-partner-account-details hvac-rhythm-2">
                    <span class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.DATE_SHIPPED' | translate }}<abbr title="required"
                        class="hvac-program-header-required-mark">*</abbr></span>
                        <hvac-date-picker
                        class="hvac-trade-partner-def-list-value"
                        [lightShadow]="true"
                        [value]="shippingForm.get('dateShipped')?.value"
                        [formControl]="$any(shippingForm).controls['dateShipped']"
                        size="small"
                        [id]="'dateShipped'"
                        [required]="true">
                        </hvac-date-picker>
                </label>
                <label class="hvac-trade-partner-account-details hvac-rhythm-2">
                    <span class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.WEIGHT' | translate }}</span>
                    <utc-input
                        class="hvac-trade-partner-def-list-value"
                        [inputControl]="shippingForm.get('freightWeight')"
                        type="text"
                        size="small"
                        inputId="freightWeight"
                        [hideLabel]="true"
                        [placeholder]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.FREIGHT.WEIGHT' | translate"
                    ></utc-input>
                </label>
            </div>
        </div>
        <div class="hvac-rhythm-6">
            <hvac-table class="hvac-trade-partners-asn-table" [ngClass]="{'hvac-rhythm-3': formHasMaxValueError}" [responsive]="true" theme="zebra">
                <thead>
                    <tr>
                        <td>
                            {{ TableColumnHeaders + 'ITEM' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'QTY' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'QTY_OPEN' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'QTY_SHIPPED' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'UNITS' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'PRODUCT_NO' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'SHIP_DATE' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'SUPPLIER_PART_NUM' | translate }}
                        </td>
                    </tr>
                </thead>
                <tbody [formGroup]="purchaseOrderItemsForm">
                    <tr *ngFor="let poOrderItem of activePurchaseOrder.lines">
                        <td [attr.data-label]="TableColumnHeaders + 'ITEM' | translate">{{ poOrderItem.pOLineNumber }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'QTY' | translate">{{ poOrderItem.pOOrderQuantity }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'QTY_OPEN' | translate">{{ poOrderItem.openQuantity }}</td>
                        <td class="hvac-trade-partners-asn-input-cell" [attr.data-label]="TableColumnHeaders + 'QTY_SHIPPED' | translate">
                            <utc-svg *ngIf="purchaseOrderItemsForm.get(poOrderItem.pOLineNumber)?.status === 'INVALID'" class="hvac-trade-partners-asn-error-icon" src="assets/icons/info.svg"></utc-svg>
                            <utc-input
                                [error]="purchaseOrderItemsForm.get(poOrderItem.pOLineNumber)?.status === 'INVALID'"
                                [inputControl]="purchaseOrderItemsForm.get(poOrderItem.pOLineNumber)"
                                type="number"
                                size="small"
                                [ariaLabel]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.TABLE_DATA.QTY_PLACEHOLDER' | translate"
                                [hideLabel]="true"
                                class="hvac-trade-partners-asn-qty-input"
                                [placeholder]="'TRADE_PARTNERS.ADVANCED_SHIPMENT.TABLE_DATA.QTY_PLACEHOLDER' | translate"
                            ></utc-input>
                        </td>
                        <td [attr.data-label]="TableColumnHeaders + 'UNITS' | translate">{{ poOrderItem.unitOfMeasure }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'PRODUCT_NO' | translate">{{ poOrderItem.sapMaterialNumber }}</td>

                        <td [attr.data-label]="TableColumnHeaders + 'SHIP_DATE' | translate">{{ poOrderItem.ackShipDate ? (poOrderItem.ackShipDate | yearMoDate) : '' }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'SUPPLIER_PART_NUM' | translate"> {{ poOrderItem.supplierPartNumber }}  </td>
                    </tr>
                </tbody>
            </hvac-table>
            <p *ngIf="formHasMaxValueError" class="hvac-trade-partners-asn-error">
                <utc-svg class="hvac-trade-partners-asn-error-icon" src="assets/icons/info.svg"></utc-svg>
                {{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.QUANTITY_ERROR' | translate }}</p>
        </div>
        <div class="hvac-trade-partners-asn-footer hvac-row hvac-rhythm-2 hvac-between-sm">
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-6 hvac-col-lg-6">
            </div>
            <div class="hvac-trade-parters-asn-submit hvac-col-xs-12 hvac-col-sm-offset-4 hvac-col-sm-8 hvac-col-md-6 hvac-col-lg-4">
                <button buttonType="button" (click)="onSubmit()" class="hvac-trade-parters-asn-submit-btn" [disabled]="!purchaseOrderItemsForm.valid || !shippingForm.valid || !itemsFormHasValue || isLoading" [hvacButton]="'primary'">{{ 'TRADE_PARTNERS.ADVANCED_SHIPMENT.SUBMIT' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>
