import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hvac-validate-soldto-popup',
    templateUrl: './validation-soldto-popup.component.html',
    styleUrls: ['./validation-soldto-popup.component.scss']
})
export class ValidationSoldtoPopupComponent {
  @Input() showModal: boolean;
  @Input() existingPrimaryDistributorDetails: string
  @Output() onCancel: EventEmitter<undefined> = new EventEmitter();
  @Output() onContinue: EventEmitter<undefined> = new EventEmitter();

  onCancelClick() {
      this.onCancel.emit();
  }

  onContinueClick() {
      this.onContinue.emit();
  }
}
