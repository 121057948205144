<div class="hvac-cp-event-log">
    <hvac-tab-bar class="hvac-alert-status-filters" variant="secondary" *ngIf="enableFilter">
        <button  (click)="handleTabClick(AlertStatusFilter.ALL)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.ALL}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.ALL' | translate }}</button>
        <button  (click)="handleTabClick(AlertStatusFilter.ACTIVE)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.ACTIVE}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.ACTIVE' | translate }}</button>
        <button  (click)="handleTabClick(AlertStatusFilter.HISTORICAL)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.HISTORICAL}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.HISTORICAL' | translate }}</button>
    </hvac-tab-bar>
    <hvac-table *ngIf="data?.length; else noData" [responsive]="isResponsive">
        <thead class="hvac-cp-event-log-table-header">
            <tr class="hvac-cp-event-log-table-header-row">
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.code">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.CODE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.dateTime">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DATE_AND_TIME' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.severity">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.SEVERITY' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.type">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.TYPE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.description">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DESCRIPTION' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.consecutiveEvents">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.CONSECUTIVE_EVENTS' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.equipmentSource">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.EQUIPMENT_SOURCE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.diagnostics">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DIAGNOSTICS' | translate}}
                </th>
            </tr>
        </thead>
        <tbody class="hvac-cp-event-log-body">
            <tr *ngFor="let event of data" class="hvac-cp-event-log-items">
                <td *ngIf="config?.code" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.CODE' | translate" class="hvac-p-sm hvac-cp-event-log-item">{{ event.code }}</td>
                <td *ngIf="config?.dateTime" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DATE_AND_TIME' | translate" class="hvac-p-sm hvac-cp-event-log-item">
                    <div *ngIf="event.dateTime && event.dateTime !== '' else noDateTime">
                        <div class="hvac-fw-sb hvac-rhythm-1">{{ event?.dateTime | dateTimeTz: 'date' }}</div>
                        <div>{{ event?.dateTime | dateTimeTz: 'time' }}</div>
                    </div>

                    <ng-template #noDateTime>{{CharSymbol.DoubleDash}}</ng-template>
                </td>

                <td *ngIf="config?.severity" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.SEVERITY' | translate" class="hvac-p-sm hvac-cp-event-log-item">
                    <hvac-alert-chip  *ngIf="event?.severity" [alertType]="event.severity | severityToAlertType">{{event.severity}}</hvac-alert-chip>
                  </td>
                <td *ngIf="config?.type" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.TYPE' | translate" class="hvac-p-sm hvac-cp-event-log-item">{{ event?.type}}</td>
                <td *ngIf="config?.description" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DESCRIPTION' | translate" class="hvac-p-sm hvac-cp-event-log-item">{{ event?.description  }}</td>
                <td *ngIf="config?.consecutiveEvents" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.CONSECUTIVE_EVENTS' | translate" class="hvac-p-sm hvac-cp-event-log-item">{{ event?.consecutiveEvents }}</td>
                <td *ngIf="config?.equipmentSource" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.EQUIPMENT_SOURCE' | translate" class="hvac-p-sm hvac-cp-event-log-item">{{ event?.equipmentSource }}</td>
                <td *ngIf="config?.diagnostics" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.DIAGNOSTICS' | translate" class="hvac-p-sm hvac-cp-event-log-item">
                    <a *ngIf="event?.diagnosticsUrl" href="{{ event?.diagnosticsUrl }}" target="_blank" class="hvac-cp-event-log-troubleshoot-button" hvacButton="default" size="small">{{ 'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.TROUBLESHOOT' | translate }}</a>
                </td>
            </tr>
        </tbody>
    </hvac-table>

    <ng-template #noData>
        <div class="hvac-cp-event-log-no-data hvac-h4">{{ 'CONNECTED_PORTAL.WALL_CONTROL.EVENT_LOG.EMPTY_MESSAGE' | translate }}</div>
    </ng-template>

    <div class="hvac-cp-list-footer" *ngIf="totalPages > 1">
        <div class="hvac-cp-list-footer-pagination">
            <hvac-pagination
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
    </div>
</div>
