<ol class="hvac-product-breadcrumbs">
    <li class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-category"
        [ngClass]="{
            'hvac-product-breadcrumbs-expanded': categoryExpanded$ | async
        }">
        <button class="hvac-product-breadcrumbs-selected"
            attr.aria-label="{{ (category$ | async)?.name }} button"
            (click)="toggleCategory()">
            <utc-svg class="hvac-product-breadcrumbs-selected-icon" [src]="(categoryIcon$ | async)!"></utc-svg>
            <span class="hvac-product-breadcrumbs-selected-label">{{ (category$ | async)?.name }}</span>
            <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
        </button>
        <ol class="hvac-product-breadcrumbs-options">
            <li class="hvac-product-breadcrumbs-option-item"
                *ngFor="let category of catalogCategories$ | async">
                <button class="hvac-product-breadcrumbs-option"
                    (click)="setCategory(category)"
                    [attr.disabled]="(category.urn === (categoryUrn$ | async)) ? 'disabled' : null"
                    [ngClass]="{ 'hvac-product-breadcrumbs-option-current': category.urn === (categoryUrn$ | async) }">
                    {{ category.name }}
                    <utc-svg class="hvac-product-breadcrumbs-option-selected-icon" src="assets/icons/check.svg"></utc-svg>
                </button>
            </li>
        </ol>
    </li>
    <li
        class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-subcategory"
        [ngClass]="{
            'hvac-product-breadcrumbs-expanded': (subcategoryExpanded$ | async),
            'hvac-subcategory-breeadcrumb-hidden': !(subcategoryEnabled$ | async)
        }"
        >
        <button [disabled]="!(subcategoryEnabled$ | async)"
            (click)="toggleSubcategory()"
            class="hvac-product-breadcrumbs-selected"  #subCategoryCrumb>
            <span class="hvac-product-breadcrumbs-selected-label">{{ (subcategory$ | async)?.name || 'PRODUCTS.BREADCRUMB_SUBCATEGORY' | translate }}</span>
            <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
        </button>
        <ol class="hvac-product-breadcrumbs-options">
            <li class="hvac-product-breadcrumbs-option-item" *ngFor="let subcategory of (category$ | async)?.children">
                <button class="hvac-product-breadcrumbs-option"
                    (click)="setSubcategory(subcategory)"
                    [attr.disabled]="(getSubcategoryName(subcategory.urn) === (subcategoryUrn$ | async)) ? 'disabled' : null"
                    [ngClass]="{ 'hvac-product-breadcrumbs-option-current': getSubcategoryName(subcategory.urn) === (subcategoryUrn$ | async) }">
                    {{ subcategory.name }}
                    <utc-svg class="hvac-product-breadcrumbs-option-selected-icon" src="assets/icons/check.svg"></utc-svg>
                </button>
            </li>
        </ol>
    </li>
    <li class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-product"
        [ngClass]="{
            'hvac-product-breadcrumbs-breadcrumb-inprogress': !(productsEnabled$ | async),
            'hvac-product-breadcrumbs-expanded': productsExpanded$ | async
        }">
        <button class="hvac-product-breadcrumbs-selected"
            [disabled]="!(productsEnabled$ | async)"
            (click)="toggleProducts()" #productCrumb >
            <span class="hvac-product-breadcrumbs-selected-label">{{ (productUrn$ | async) || 'PRODUCTS.BREADCRUMB_MODEL' | translate }}</span>
            <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
        </button>
        <ol class="hvac-product-breadcrumbs-options">
            <li class="hvac-product-breadcrumbs-option-item" *ngFor="let product of familyProducts$ | async">
                <button class="hvac-product-breadcrumbs-option"
                    [attr.disabled]="(product.urn === (productUrn$ | async)) ? 'disabled' : null"
                    [ngClass]="{ 'hvac-product-breadcrumbs-option-current': product.urn === (productUrn$ | async) }"
                    (click)="setProduct(product)">
                    <img *ngIf="product.image; else fallbackImage" class='hvac-product-breadcrumbs-option-image' [src]="product.image" onError="this.src='assets/images/no-image-icon.svg';this.className='error-image';" alt="{{ 'PRODUCTS.BREADCRUMB_ALT_ICON' | translate}}" />
                    <ng-template #fallbackImage>
                        <img class="error-image" src="assets/images/no-image-icon.svg" alt="{{ 'PRODUCTS.BREADCRUMB_ALT_FALLBACK' | translate}}">
                    </ng-template>
                    <div class='hvac-product-breadcrumbs-option-details'>
                        <span class='option-details-urn' [innerHtml]="product.urn"></span>
                        <span class='option-details-name' [innerHtml]="product.name"></span>
                    </div>
                    <utc-svg class="hvac-product-breadcrumbs-option-selected-icon" src="assets/icons/check.svg"></utc-svg>
                </button>
            </li>
        </ol>
    </li>
</ol>
