<form [formGroup]="accountInfoForm">
    <div class='hvac-create-account-information-banner-container'>
        <hvac-toast></hvac-toast>
    </div>
    <div class='hvac-create-account-information-form-container'>
        <div class='hvac-create-account-information-form-column'>
            <div class='hvac-create-account-input-row hvac-create-account-input-half'>
                <utc-input [error]="accountInfoForm.controls.firstNameControl.dirty && !accountInfoForm.controls.firstNameControl.valid" [errorMessage]="(firstNameErrorMessage$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [inputControl]="accountInfoForm.controls.firstNameControl" inputId="create-account-input-first"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.FIRST_NAME' | translate" type="text"></utc-input>
                <utc-input [error]="accountInfoForm.controls.lastNameControl.dirty && !accountInfoForm.controls.lastNameControl.valid" [errorMessage]="(lastNameErrorMessage$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [inputControl]="accountInfoForm.controls.lastNameControl" inputId="create-account-input-last"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.LAST_NAME' | translate" type="text"></utc-input>
            </div>
            <div class='hvac-create-account-input-row'>
                <utc-input [error]="accountInfoForm.controls.phoneControl.dirty && !accountInfoForm.controls.phoneControl.valid" [errorMessage]="(phoneErrorMessage$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [inputControl]="accountInfoForm.controls.phoneControl" inputId="create-account-input-phone"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.PHONE' | translate" type="tel" class='hvac-create-account-input'></utc-input>
            </div>
            <div class='hvac-create-account-input-row'>
                <utc-input [error]="accountInfoForm.controls.emailControl.dirty && !accountInfoForm.controls.emailControl.valid" [errorMessage]="(emailErrorMessage$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [inputControl]="accountInfoForm.controls.emailControl" inputId="create-account-input-email"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.EMAIL' | translate" type="email" class='hvac-create-account-input'></utc-input>
            </div>

        </div>
        <div class='hvac-create-account-information-form-column'>
            <div class='hvac-create-account-input-row'>
                <utc-input [error]="accountInfoForm.controls.userNameControl.dirty && !accountInfoForm.controls.userNameControl.valid" [errorMessage]="(userNameErrorMessage$ | async)!"
                    [disableInputIcon]="(apiRequestActive$ | async)!"
                    [disabledInput]="(apiRequestActive$ | async)!"
                    [inputControl]="accountInfoForm.controls.userNameControl" inputId="create-account-input-username"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.USER_NAME' | translate" type="text" class='hvac-create-account-input'></utc-input>
            </div>
            <div class='hvac-create-account-input-row'>
                <utc-password-input [requirements]="passwordRequirements$ | async" [error]="accountInfoForm.controls.passwordControl.dirty && !accountInfoForm.controls.passwordControl.valid" [errorMessage]="(passwordErrorMessage$ | async)!"
                    [disabled]="(apiRequestActive$ | async)!"
                    inputId="create-account-input-password" [passwordControl]="accountInfoForm.controls.passwordControl"
                    [label]="'CREATE_ACCOUNT_INFORMATION.LABEL.PASSWORD' | translate" type="password" class='hvac-create-account-input'></utc-password-input>
            </div>
        </div>
    </div>
    <div class='hvac-create-account-input-row hvac-create-account-input-checkbox'>
        <utc-checkbox
            inputId="create-account-input-terms"
            inputName="create-account-input-terms"
            [disabled]="(apiRequestActive$ | async)!"
            (change)="changeTermsControl($event)">
        </utc-checkbox>
        <p class='hvac-create-account-input-checkbox-terms-link'[innerHtml]="'CREATE_ACCOUNT_INFORMATION.TERMS_AND_CONDITIONS' | translate | safe:'html'"></p>
    </div>
</form>
