import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { AccountStatusService } from 'common/services/account-status.service';
import { ToastService } from 'common/services/toast.service';
import { AcceptCoolCalcTerms } from 'common/store/account-status/account-status.actions';

@Component({
    selector: 'hvac-cool-calc-terms-and-conditions',
    templateUrl: './cool-calc-terms-and-conditions.component.html',
    styleUrls: ['./cool-calc-terms-and-conditions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoolCalcTermsAndConditionsComponent {
    errorMessages = this.translate.instant('COOL_CALC_TERMS_OF_USE.ERROR_MESSAGE');
    bannerType = 'CoolCalcErrorBanner';
    bannerDuration = 10000;

    constructor(
        private readonly store: Store,
        private translate: TranslateService,
        private statusService: AccountStatusService,
        private toastService: ToastService
    ) {}

    closeAction() {
        this.store.dispatch(new Navigate(['/']));
    }

    declineAction() {
        this.postCoolCalcStatus(false);
        this.store.dispatch(new Navigate(['/']));
        this.toastService.add({
            content: this.errorMessages,
            theme: 'info',
            id: this.bannerType,
            closeable: true,
            autoClose: true
        });
    }

    acceptAction() {
        this.postCoolCalcStatus(true);
    }

    postCoolCalcStatus(status: boolean) {
        this.statusService.postCoolCalcTermsStatus({ accept: status }).subscribe(() => {
            if (status) {
                this.store.dispatch(new AcceptCoolCalcTerms());
                this.store.dispatch(new Navigate(['/support/pre-sale-support/cool-calc']));
            }
        });
    }
}
