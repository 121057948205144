<dl class="hvac-data-sharing-header">
    <dt class="hvac-cp-title hvac-h5">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.DATA_SHARING' | translate }}</dt>
    <div class="hvac-cp-blocks">

        <dd class="hvac-cp-block">
            <div class="hvac-cp-block-contents" [tooltip]="((dataSharing.viewStatus | dataSharingToStatus) === 'success') ? viewStatusEnabledOptions : viewStatusDisabledOptions">
                <div class="hvac-cp-block-contents-icons"><utc-svg [src]="((dataSharing.viewStatus | dataSharingToStatus) === 'success') ? 'assets/icons/circle-check.svg' : 'assets/icons/circle-cross.svg'"></utc-svg></div>
                <div class="hvac-cp-block-contents-info"> {{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.VIEW_STATUS' | translate }} </div>    
            </div>
        </dd>
            
        <dd class="hvac-cp-block" *ngIf="systemType === SystemType.INFINITY || systemType === SystemType.CARRIER_ELT">
            <div class="hvac-cp-block-contents" [tooltip]="((dataSharing.viewConfig | dataSharingToStatus) === 'success') ? viewConfigEnabledOptions : viewConfigDisabledOptions">
                <div class="hvac-cp-block-contents-icons"><utc-svg [src]="((dataSharing.viewConfig | dataSharingToStatus) === 'success') ? 'assets/icons/circle-check.svg' : 'assets/icons/circle-cross.svg'"></utc-svg></div>
                <div class="hvac-cp-block-contents-info">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.VIEW_CONFIG' | translate }}</div>    
            </div>
        </dd>

        <dd class="hvac-cp-block" *ngIf="systemType !== SystemType.ECOBEE_NI">
            <div class="hvac-cp-block-contents" [tooltip]="((dataSharing.editConfig | dataSharingToStatus) === 'success') ? editConfigEnabledOptions : editConfigDisabledOptions">
                <div class="hvac-cp-block-contents-icons"><utc-svg [src]="((dataSharing.editConfig | dataSharingToStatus) === 'success') ? 'assets/icons/circle-check.svg' : 'assets/icons/circle-cross.svg'"></utc-svg></div>
                <div class="hvac-cp-block-contents-info">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.EDIT_CONFIG' | translate }}</div>    
            </div>
        </dd>
        
    </div>
</dl>

<ng-template #viewStatusEnabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.VIEW_STATUS_ENABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
<ng-template #viewStatusDisabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.VIEW_STATUS_DISABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
<ng-template #viewConfigEnabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.VIEW_CONFIG_ENABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
<ng-template #viewConfigDisabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.VIEW_CONFIG_DISABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
<ng-template #editConfigEnabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.EDIT_CONFIG_ENABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
<ng-template #editConfigDisabled>
    <div class="tooltip-container">
        <div class="tooltip-content">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.TOOLTIP.EDIT_CONFIG_DISABLED' | translate }}</div>
        <div class="tooltip-anchor"></div>
    </div>
</ng-template>
