<section class="hvac-company-info-container">
    <button class="hvac-company-header">
        <h2> {{ title }}</h2>
    </button>
    <div class="hvac-company-form">
        <form [formGroup]="companyCreateForm">
            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS1" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.address1.dirty && !companyCreateForm.controls.address1.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS1' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.address1"
                            type="text">
                        </utc-input>
                    </div>
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS2" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.address2.dirty && !companyCreateForm.controls.address2.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS2' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.address2"
                            type="text">
                        </utc-input>
                    </div>
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COUNTY" | translate }}
                        </div>

                        <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.COUNTRY' | translate }}"
                            [error]="companyCreateForm.controls.country.dirty && !companyCreateForm.controls.country.valid"
                            [shadow]="'none'"
                            (onChange)="onCountryChange($event)"
                            [group]="companyCreateForm" control="country" [options]="(companyCreateService.countryOptionData$ | async) || []">
                        </utc-select>
                    </div>
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.STATE" | translate }}
                        </div>

                        <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                            defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.STATE' | translate }}"
                            [error]="companyCreateForm.controls.state.dirty && !companyCreateForm.controls.state.valid"
                            [shadow]="'none'"
                            (onChange)="onStateChange($event)"
                            [group]="companyCreateForm" control="state" [options]="(companyCreateService.stateOptionData$ | async) || []">
                        </utc-select>
                    </div>
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CITY" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.city.dirty && !companyCreateForm.controls.city.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CITY' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.city"
                            type="text">
                        </utc-input>
                    </div>
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ZIP/POSTAL" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                            [error]="companyCreateForm.controls.postalCode.dirty && !companyCreateForm.controls.postalCode.valid"
                            [placeholder]="(placeHolderByCountry | async)?.placeHolderZipcode?.label || 'ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE' | translate "
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.postalCode"
                            type="text">
                        </utc-input>
                    </div>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.OFFICE_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.phone.dirty && !companyCreateForm.controls.phone.valid"
                            [placeholder]="(placeHolderByCountry | async)?.placeHolderOffice || 'ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER' | translate "
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.phone"
                            type="text" [mask]=maskPhoneNumber inputId="office_number">
                        </utc-input>
                    </div>

                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.MOBILE_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.mobile.dirty && !companyCreateForm.controls.mobile.valid"
                            [placeholder]="(placeHolderByCountry | async)?.placeHolderMobile || 'ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER' | translate "
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.mobile"
                            type="text" [mask]=maskPhoneNumber inputId="mobile_number">
                        </utc-input>
                    </div>

                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.FAX_NUMBER" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.fax.dirty && !companyCreateForm.controls.fax.valid"
                            [placeholder]="(placeHolderByCountry | async)?.placeHolderFax || 'ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER' | translate "
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.fax"
                            type="text" [mask]=maskPhoneNumber inputId="fax_number">
                        </utc-input>
                    </div>

                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.TOLL_FREE" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.tollFree.dirty && !companyCreateForm.controls.tollFree.valid"
                            [placeholder]="(placeHolderByCountry | async)?.placeHolderTollFree || 'ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER' | translate "
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.tollFree"
                            type="text" [mask]=maskPhoneNumber inputId="toll_free_number">
                        </utc-input>
                    </div>

                    <div class="hvac-row">
                        <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                            {{ "ACCOUNT_ADMIN.COMPANY_CREATE.EMAIL" | translate }}
                        </div>
                        <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-company-create-field"
                            [error]="companyCreateForm.controls.email.dirty && !companyCreateForm.controls.email.valid"
                            [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.EMAIL' | translate"
                            [hideLabel]="false" [inputControl]="companyCreateForm.controls.email"
                            type="text">
                        </utc-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
