<ng-template [ngIf]="(this.purchaseOrderService.activePurchaseOrder$ | async)" let-activePurchaseOrder>
    <utc-loading-spinner class="hvac-po-list-loader" *ngIf="(activePurchaseOrder | json) === '{}'"></utc-loading-spinner>
    <div *ngIf="(activePurchaseOrder | json) != '{}'">
        <div class="hvac-trade-partner-subhead">
            <h2 class="hvac-trade-partner-subhead-text">{{ 'TRADE_PARTNERS.ACKNOWLEDGE.DETAILS.PO_NUMBER' | translate }} {{ activePurchaseOrder.pONumber }}
                <span class="hvac-h5">{{ 'TRADE_PARTNERS.ACKNOWLEDGE.DETAILS.PO_DATE' | translate }} <span class="hvac-trade-partner-def-list-value">{{activePurchaseOrder.pOCreationDate | yearMoDate }}</span></span>
            </h2>
        </div>
        <hvac-table class="hvac-trade-partners-acknowledgement-table hvac-rhythm-2" [responsive]="true" theme="zebra">
            <thead>
                <tr>
                    <td>
                        {{ TableColumnHeaders + 'ITEM' | translate }}
                    </td>
                    <td>
                        {{ TableColumnHeaders + 'QTY' | translate }}
                    </td>
                    <td>
                        {{ TableColumnHeaders + 'UNITS' | translate }}
                    </td>
                    <td>
                        {{ TableColumnHeaders + 'PRODUCT_NO' | translate }}
                    </td>
                    <td>
                        {{ TableColumnHeaders + 'PRICE' | translate }}
                    </td>
                    <td>
                        {{ TableColumnHeaders + 'SHIP_DATE' | translate }}
                    </td>
                </tr>
            </thead>
            <tbody [formGroup]="purchaseOrderItemsForm">
                <tr *ngFor="let poOrderItem of activePurchaseOrder.lines">
                    <td [attr.data-label]="TableColumnHeaders + 'ITEM' | translate">{{ poOrderItem.pOLineNumber }}</td>
                    <td [attr.data-label]="TableColumnHeaders + 'QTY' | translate">{{ poOrderItem.pOOrderQuantity }}</td>
                    <td [attr.data-label]="TableColumnHeaders + 'UNITS' | translate">{{ poOrderItem.unitOfMeasure }}</td>
                    <td [attr.data-label]="TableColumnHeaders + 'PRODUCT_NO' | translate">{{ poOrderItem.sapMaterialNumber }}</td>
                    <td [attr.data-label]="TableColumnHeaders + 'PRICE' | translate">{{ poOrderItem.unitCost }}

                    </td>
                    <td [attr.data-label]="TableColumnHeaders + 'SHIP_DATE' | translate">
                        <hvac-date-picker
                            label="label"
                            [value]="purchaseOrderItemsForm.get(poOrderItem.pOLineNumber)?.value"
                            [formControlName]="poOrderItem.pOLineNumber"
                            size="small"
                            [inputId]="poOrderItem.pOLineNumber"
                            [id]="poOrderItem.pOLineNumber"
                        ></hvac-date-picker>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
        <div class="hvac-row hvac-rhythm-4">
            <div class="hvac-col-xs-12 hvac-col-sm-5 hvac-col-md-4">
                <utc-checkbox
                    class="hvac-rhythm-2"
                    inputId="supplierId"
                    [inputControl]="showSupplierSalesNum"
                    >{{ 'TRADE_PARTNERS.ACKNOWLEDGE.SUPPLIER_SALES_CHECKBOX' | translate}}</utc-checkbox>
                <utc-input
                    *ngIf="showSupplierSalesNum.value"
                    [inputControl]="supplierSoNumber"
                    type="text"
                    size="small"
                    [placeholder]="'TRADE_PARTNERS.ACKNOWLEDGE.SUPPLIER_SALES_ORDER_NO' | translate"
                ></utc-input>
            </div>
        </div>
        <div class="hvac-row hvac-end-sm hvac-rhythm-2 hvac-purchase-order-detail-total">
            <div class="hvac-col-xs-12 hvac-col-sm-8 hvac-col-md-6 hvac-start-xs hvac-col-lg-4">
                <button [disabled]="!purchaseOrderItemsForm.valid || !acknowledgedVerified.value || isSubmitInProgress" (click)="onSubmit()" hvacButton="primary">{{ 'TRADE_PARTNERS.ACKNOWLEDGE.SUBMIT' | translate }}</button>
            </div>
        </div>
        <div class="hvac-row  hvac-end-sm hvac-rhythm-10 hvac-purchase-order-detail-total">
            <div class="hvac-col-xs-12 hvac-col-sm-8 hvac-col-md-6 hvac-start-xs hvac-col-lg-4">
                <utc-checkbox
                    inputId="supplierId"
                    [inputControl]="acknowledgedVerified"
                    >{{ 'TRADE_PARTNERS.ACKNOWLEDGE.CONFIRMATION_INSTRUCTIONS' | translate }}</utc-checkbox>
            </div>
        </div>
    </div>
</ng-template>
