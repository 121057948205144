import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { AppConstants } from 'common/app-constants';
import { ToastService } from 'common/services/toast.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { CreateUserDetails, UserAuthorizedFeatures, UserAuthorizedRoles, UserDetails, UserDetailsEntity, UserFeatures, UserSecurityRoles, UserCreateStrings } from 'private/app/models/user.model';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'hvac-user-roles-details',
    templateUrl: './hvac-user-roles-details.component.html',
    styleUrls: ['./hvac-user-roles-details.component.scss']
})
export class HvacUserRolesDetailsComponent {
    @Input() usersData?: UserDetailsEntity;
    @Input() userDetails: UserDetails;
    @Input() userRoles: UserAuthorizedRoles[];
    @Input() userFeatures: UserAuthorizedFeatures[];
    @Input() page: string;

    @Output() registrationUserChangePage = new EventEmitter<string>();
    @Output() activeScreenName = new EventEmitter<string>();
    @Output() userDetailsSubmit = new EventEmitter<CreateUserDetails>();
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;

    public isUserDetailsAvailable: boolean = true;
    public rolesTabs: string[] = [];
    public additionalRolesTab: string[] = [];
    public isuserEditLastPage: boolean = false;
    public userRegistrationPage2: boolean = true;
    public userRegistrationPage3: boolean = false;
    public userRegistrationPage4: boolean = false;

    public selectedTab: number = 0;
    public selectedTabName: string;
    public selectedAddTab: number = 0;
    public selectedAddTabName: string;
    public isTableLoading: boolean = false;
    public currentPage: number = AppConstants.currentPage;
    public userAuthorizedRoles: UserAuthorizedRoles[];
    public userAuthorizedFeatures: UserAuthorizedFeatures[];
    public selectedRoles: UserSecurityRoles[] = [];
    public selectedAdditionalRoles: UserFeatures[] = [];
    public defaultRole: UserSecurityRoles[] = [];
    public defaultAdditionalRoles: UserFeatures[] = [];
    public featuresSelectedByRole: string[] = [];
    public isUserDetailsSummeryMode: boolean = true;
    public hvacCompanyId?: string;
    public brandFamily: string | null;
    public isLoading: boolean = false;
    public userRoleDetail: boolean;
    public selctedDisplayName: string;
    public selectedDesc?: string;
    public featureDetail: boolean;


    constructor(
        public userAPIService : UserApiService,
        public userAdminService: UserAdminService,
        private toastService: ToastService,
        public translate: TranslateService
    ) { }

    get userRoleAndFeatureDetails(): boolean {
        if (this.userRoleDetail) {
            return this.userRoleDetail;
        }
        if (this.featureDetail) {
            return this.featureDetail;
        }

        return false;
    }

    ngOnInit(): void {
        this.getActiveScreenName();
        this.userAdminService.smoothScrollToTop();
        this.hvacCompanyId = this.userDetails?.companyId;
        this.userBrandFamily$.subscribe((brand) => this.brandFamily = brand);
        this.isLoading = true;
        if (this.hvacCompanyId) {
            this.userAPIService.getUserAuthorizedRoles(this.hvacCompanyId).subscribe((authorizedRoles) => {
                this.isLoading = false;
                this.userAuthorizedRoles = authorizedRoles;
                this.userAuthorizedRoles.forEach((item) => {
                    this.rolesTabs.push(item.roleCategory);
                    if (item.roleCategory === UserCreateStrings.SystemDefaults) {
                        this.selectedRoles = item.userRoles;
                        this.defaultRole = item.userRoles;
                        this.updateFeaturesSelected(item.userRoles[0]);
                        this.defaultAdditionalRoles = (item.userRoles[0].userFeatures) ? item.userRoles[0].userFeatures : this.defaultAdditionalRoles;
                    }
                    if (this.usersData && this.usersData?.userFeaturesDetails.length) {
                        item.userRoles.forEach((role) => {
                            role.isSelected = Boolean(this.checkRoleSelection(role));
                            (role.isSelected) ? this.updateFeaturesSelected(role) : '';
                        });
                    }
                });
                this.rolesTabs = this.rolesTabs.filter((item) => item !== UserCreateStrings.SystemDefaults);
                this.selectedTabName = this.rolesTabs[0];
                this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
                if (this.usersData && this.usersData?.userRolesDetails.length) {
                    const processUserRoles = (roleItem : UserSecurityRoles, selectedRoles: UserSecurityRoles[]) => {
                        this.usersData?.userRolesDetails.forEach((userRole) => {
                            if (roleItem.roleId === userRole.roleId) {
                                selectedRoles.push(roleItem);
                                roleItem.isSelected = true;
                            }
                        });
                    };

                    this.userAuthorizedRoles.forEach((item) => {
                        item.userRoles.forEach((roleItem) => {
                            processUserRoles(roleItem, this.selectedRoles);
                        });
                    });
                }
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('HvacUserRolesDetailsComponent-getUserAuthorizedRoles', error.message));

            this.userAPIService.getUserAuthorizedFeatures(this.hvacCompanyId).subscribe((authorizedFeatures) => {
                this.userAuthorizedFeatures = authorizedFeatures;
                this.userAuthorizedFeatures.forEach((item) => {
                    this.additionalRolesTab.push(item.featureCategory);
                });
                this.selectedAddTabName = this.additionalRolesTab[0];
            }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('HvacUserRolesDetailsComponent-getUserAuthorizedFeatures', error.message));
        }
    }

    public getActiveScreenName() {
        let activeScreenName = '';

        if (this.page === UserCreateStrings.CreateUser) {
            if (this.userRegistrationPage2) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_CREATE_PAGE.STEP_4');
            }
            else if (this.userRegistrationPage3) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_CREATE_PAGE.STEP_5');
            }
            else if (this.userRegistrationPage4) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_CREATE_PAGE.STEP_6');
            }
        }

        else if (this.page === UserCreateStrings.RegisterUser) {
            if (this.userRegistrationPage2) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STEP_2');
            }
            else if (this.userRegistrationPage3) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STEP_3');
            }
            else if (this.userRegistrationPage4) {
                activeScreenName = this.translate.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STEP_4');
            }
        }

        this.activeScreenName.emit(activeScreenName);
    }

    public getRolesList() {
        return this.userAuthorizedRoles?.find((item) => item.roleCategory === this.selectedTabName)?.userRoles || [];
    }

    public getAdditionalRolesList() {
        return (this.userAuthorizedFeatures?.find((item) => item.featureCategory === this.selectedAddTabName)?.userFeatures || []).filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public checkRoleSelection(role: UserSecurityRoles) {
        return this.usersData?.userRolesDetails.find((item: UserSecurityRoles) => (item.roleId === role.roleId));
    }

    public updateFeaturesSelected(role: UserSecurityRoles) {
        return role.userFeatures?.map((feature) => this.featuresSelectedByRole.push(feature.featureDisplayName));
    }

    public roleSelected(role: UserSecurityRoles) {
        role.isSelected = !role.isSelected;
        this.selectedRoles = [];
        this.featuresSelectedByRole = [];
        this.updateFeaturesSelected(this.defaultRole[0]);
        this.userAuthorizedRoles.forEach((item) => {
            item.userRoles.forEach((subItem) => {
                if (subItem.isSelected) {
                    this.selectedRoles.push(subItem);
                    this.updateFeaturesSelected(subItem);
                }
            });
        });
        this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public addtnlRoleSelected(addtnlRole: UserFeatures) {
        addtnlRole.isSelected = !addtnlRole.isSelected;
        this.selectedAdditionalRoles = [];
        this.userAuthorizedFeatures.forEach((item) => {
            item.userFeatures.forEach((subItem) => {
                (subItem.isSelected) ? this.selectedAdditionalRoles.push(subItem) : '';
            });
        });
        this.selectedAdditionalRoles = this.selectedAdditionalRoles.filter((val) => !this.featuresSelectedByRole.includes(val.featureDisplayName));
    }

    public getSystemDefaultRole() {
        return this.userAuthorizedRoles?.find((item) => item.roleCategory === UserCreateStrings.SystemDefaults)?.userRoles[0].roleDisplayName;
    }

    public handleTabClick(tabSelection: number, tab: string) {
        this.isTableLoading = true;
        this.selectedTab = tabSelection;
        this.selectedTabName = tab;
        this.currentPage = AppConstants.currentPage;
    }

    public handleAddTabClick(tabSelection: number, tab: string) {
        this.selectedAddTab = tabSelection;
        this.selectedAddTabName = tab;
        this.isTableLoading = true;
    }

    public onChangePage() {
        this.isuserEditLastPage = !this.isuserEditLastPage;
        this.userAdminService.smoothScrollToTop();
    }

    public getUserDetailsSummery() {
        this.isUserDetailsSummeryMode = !this.isUserDetailsSummeryMode;
    }

    public goToUserDetails() {
        (this.page === UserCreateStrings.CreateUser) ? this.registrationUserChangePage.emit('step3_userDetails') : this.registrationUserChangePage.emit('step1_userRegistrationForm');
    }

    public goToUserRoles() {
        this.userRegistrationPage2 = true;
        this.userRegistrationPage3 = false;
        this.userRegistrationPage4 = false;
        this.userAdminService.smoothScrollToTop();
        this.getActiveScreenName();
    }

    public goToUserFeatures() {
        this.userRegistrationPage2 = false;
        this.userRegistrationPage3 = true;
        this.userRegistrationPage4 = false;
        this.userAdminService.smoothScrollToTop();
        this.getActiveScreenName();
    }

    public goToUserSummary() {
        this.userRegistrationPage2 = false;
        this.userRegistrationPage3 = false;
        this.userRegistrationPage4 = true;
        this.userAdminService.smoothScrollToTop();
        this.getActiveScreenName();
    }

    public mapCreateUserDetails(userDetails: UserDetails) {
        if ((this.selectedAdditionalRoles.length === 0) && (this.usersData)) {
            this.selectedAdditionalRoles = this.usersData.userFeaturesDetails;
        }
        if ((this.page === UserCreateStrings.CreateUser || this.page === UserCreateStrings.RegisterUser)) {
            this.defaultAdditionalRoles.map((additionalFeature) => this.selectedAdditionalRoles.push(additionalFeature));
        }
        if ((this.page === UserCreateStrings.CreateUser || this.page === UserCreateStrings.RegisterUser) && (this.selectedRoles.length === 0)) {
            this.selectedRoles = this.defaultRole;
        }
        const createUserDetails: CreateUserDetails = {
            userID: (this.page === UserCreateStrings.CreateUser) ? UserCreateStrings.AdminCreate : userDetails.userID,
            companyId: userDetails.companyId.toString(),
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            employerName: userDetails.employerName,
            userAddress1: userDetails.userAddress1,
            userAddress2: userDetails.userAddress2,
            userCellPhone: userDetails.userCellPhone,
            userCity: userDetails.userCity,
            userCountry: userDetails.userCountry,
            userFax: userDetails.userFax,
            userPhone: userDetails.userPhone,
            userPostalCode: userDetails.userPostalCode,
            userState: userDetails.userCountry,
            userTitle: userDetails.userTitle,
            userStatus: (this.page === UserCreateStrings.CreateUser) ? UserCreateStrings.Active : null,
            brandFamily: this.brandFamily,
            contactOnly: userDetails.contactOnly,
            userRolesDetails: this.selectedRoles,
            userFeaturesDetails: this.selectedAdditionalRoles
        };

        createUserDetails.userRolesDetails.forEach((data) => {
            delete data.userFeatures;
            delete data.requiresAuthorization;
            delete data.roleDesc;
            delete data.isSelected;
        });

        createUserDetails.userFeaturesDetails.forEach((data) => {
            delete data.featureDesc;
            delete data.deleted;
        });

        return createUserDetails;
    }

    public onSubmit() {
        this.userAdminService.smoothScrollToTop();
        this.userDetailsSubmit.emit(this.mapCreateUserDetails(this.userDetails));
    }

    public onSelectRoleAndFeatureDetails(role:UserSecurityRoles | null, feature: UserFeatures | null) {
        if (role) {
            this.userRoleDetail = true;
            this.selctedDisplayName = role.roleDisplayName;
            this.selectedDesc = role.roleDesc;
        }
        if (feature) {
            this.featureDetail = true;
            this.selctedDisplayName = feature.featureDisplayName;
            this.selectedDesc = feature.featureDesc;
        }
    }

    public userRoleAndFeatureModalClose() {
        if (this.userRoleDetail) {
            this.userRoleDetail = false;
        }
        if (this.featureDetail) {
            this.featureDetail = false;
        }
    }
}
