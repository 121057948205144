<ul class="hvac-product-subnav" [class.hvac-product-subnav-sticky]="sticky$ | async">
    <ng-container *ngIf="!showPlaceholder; else placeholder">
        <li *ngFor="let section of sections$ | async" class="hvac-product-subnav-section">
            <button
                *ngIf="!showPlaceholder; else placeholder"
                class="hvac-product-subnav-section-link"
                [ngClass]="{ 'hvac-product-subnav-section-link-active': section.title === (activeSection$ | async)?.title }"
                (click)="scrollToSection(section, section.title)">
                {{ section.title }}
            </button>
        </li>
    </ng-container>
</ul>
<ng-template #placeholder>
    <li class="hvac-product-subnav-section">
        <span class="hvac-product-subnav-section-link-placeholder"><span class="hvac-placeholder-content hvac-placeholder-content-dark"></span></span>
    </li>
    <li class="hvac-product-subnav-section">
        <span class="hvac-product-subnav-section-link-placeholder"><span class="hvac-placeholder-content hvac-placeholder-content-dark"></span></span>
    </li>
    <li class="hvac-product-subnav-section">
        <span class="hvac-product-subnav-section-link-placeholder"><span class="hvac-placeholder-content hvac-placeholder-content-dark"></span></span>
    </li>
</ng-template>
