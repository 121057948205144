import { ToggleBookmarkSlide } from './../../../../../common/store/ui/ui.actions';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { PerformSearch } from 'common/store/search/search.actions';
import { MobileMenuVisible, ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { Bookmark, BookmarkPostData, FetchAddBookmark, FetchDeleteBookmark } from 'private/app/store/bookmarks.actions';
import { BookmarkState } from 'private/app/store/bookmarks.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'hvac-bookmark-list',
    templateUrl: './bookmarks-list.component.html',
    styleUrls: ['./bookmarks-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BookmarksListComponent implements OnInit {
    @Input() menuActive = false;
    @Select(BookmarkState.bookmarks) bookmarks$!: Observable<Array<Bookmark>>;
    @Select(RouterState.state) routerState$!: Observable<RouterStateSnapshot>;

    toAddSubject$: Subject<BookmarkPostData|null> = new Subject();
    toAdd$: Observable<BookmarkPostData|null> = this.toAddSubject$.pipe(
        distinctUntilChanged((l, r) => (l && l.link) === (r && r.link))
    );

    currentUrl: RouterStateSnapshot;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bookmarkList$: any = this.bookmarks$.pipe(map((bookmarkList) => bookmarkList?.map((bookmark) => {
        const bookmarkUrl = new URL(bookmark.link, window.location.origin);
        const queryParams: { [ key: string ]: string } = {};
        bookmarkUrl.searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });

        return {
            ...bookmark,
            link: decodeURIComponent(bookmarkUrl.pathname),
            queryParams: queryParams
        };
    })));

    showAddButton$ = combineLatest([this.bookmarks$, this.routerState$]).pipe(map(([bookmarks, routerState]) => {
        this.currentUrl = routerState;
        if (bookmarks.length === 0) {
            return true;
        }
        const linkList = bookmarks.map((bookmark: Bookmark) => bookmark.link);

        if (this.currentUrl) {
            return !linkList.some((link) => {
                if (this.currentUrl.url.includes('/search')) {
                    return decodeURIComponent(link) === decodeURIComponent(this.currentUrl.url);
                }
                const bookmarkLinkNoParams = link.split('?')[0];
                const currentLinkNoParams = this.currentUrl.url.split('?')[0];

                return decodeURIComponent(bookmarkLinkNoParams) === decodeURIComponent(currentLinkNoParams);
            });
        }

        return false;
    }));

    constructor(private readonly store: Store) {}

    ngOnInit() {
        this.toAdd$.subscribe((bookmark) => {
            if (bookmark) {
                this.store.dispatch(new FetchAddBookmark(bookmark));
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.routerState$.subscribe((res: any) => {
            this.currentUrl = res;
        });
    }

    toggleMenu() {
        const toggle = this.menuActive ? 'none' : 'bookmarks';

        return this.store.dispatch(new ToggleHeaderDropdown(toggle));
    }

    handleBookmarkNavigate(data: Bookmark) {
        this.toggleMenu();
        if (data.link.includes('search') && data.queryParams) {
            this.store.dispatch(new PerformSearch(data.queryParams));
        }

        this.store.dispatch(new ToggleBookmarkSlide(false));

        return this.store.dispatch(new MobileMenuVisible(false));
    }

    addBookmark() {
        const bookmark: BookmarkPostData = {
            link: this.currentUrl.url.toString(),
            caption: this.handleSearchTitle(document.title)
        };
        this.toAddSubject$.next(bookmark);
    }

    removeBookmark(bookmark: Bookmark) {
        this.store.dispatch(new FetchDeleteBookmark(bookmark));
        this.toAddSubject$.next(null);
    }

    handleSearchTitle(title: string) {
        if (!this.currentUrl.url.includes('search') || !this.currentUrl.root.queryParamMap.has('q')) {
            return title;
        }

        return `${title}: ${this.currentUrl.root.queryParamMap.get('q')}`;
    }
}
