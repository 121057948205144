<ng-template [ngIf]="isUserDetailsAvailable" [ngIfElse]="spinner">

    <div *ngIf="!isuserEditLastPage;else lastPageData" class="hvac-user-admin-container">
            <h1 class="hvac-p-sm">
                {{ 'USER_ADMIN.USER_SECURITY.ROLE_GROUPING' | translate | uppercase}}
            </h1>
        <hvac-tab-bar variant="secondary">
            <button *ngFor="let tab of rolesTabs; let i = index;" (click)="handleTabClick(i, tab)" [ngClass]="{ 'hvac-active' : selectedTab === i }">{{ tab }}</button>
        </hvac-tab-bar>

        <hvac-table class="hvac-user-admin-table-wrap-roles" [theme]="'zebra'" [responsive]="true">
            <tbody>
                <tr *ngFor="let role of getRolesList()">
                    <td class="hvac-checkbox-container">
                        <utc-checkbox [checked]="role.isSelected || false" (change)="roleSelected(role)"></utc-checkbox>
                    </td>
                    <td class="hvac-user-admin-table-row-data">{{ role.roleDisplayName }}</td>
                    <td class="hvac-user-admin-table-row-details">
                     <a class="hvac-text-link" (click)="onSelectRoleAndFeatureDetails(role , null)">{{"USER_ADMIN.USER_SECURITY.ROLE_DETAILS"| translate}}</a>                    
                    </td>
                </tr>
            </tbody>
        </hvac-table>
    </div>

    <ng-template #lastPageData>
        <div class="hvac-user-admin-container">
            <h1 class="hvac-p-sm">
                {{ 'USER_ADMIN.USER_SECURITY.ADDITIONAL_ACCESS' | translate | uppercase}}
            </h1>
        <hvac-tab-bar variant="secondary">
            <button *ngFor="let tab of additionalRolesTab; let i = index;" (click)="handleAddTabClick(i, tab)" [ngClass]="{ 'hvac-active' : selectedAddTab === i }">{{ tab }}</button>
        </hvac-tab-bar>

        <hvac-table class="hvac-user-admin-table-wrap-roles" [theme]="'zebra'" [responsive]="true">
            <tbody>
                <tr *ngFor="let addtnlRole of getAdditionalRolesList()">
                    <td>
                        <utc-checkbox [checked]="addtnlRole.isSelected || false" (change)="addtnlRoleSelected(addtnlRole)"></utc-checkbox>
                    </td>
                    <td class="hvac-user-admin-table-row-data">{{ addtnlRole.featureDisplayName }}</td>
                    <td class="hvac-user-admin-table-row-details">
                        <a class="hvac-text-link" (click)="onSelectRoleAndFeatureDetails(null,addtnlRole)">{{ 'USER_ADMIN.USER_SECURITY.FEATURE_DETAILS' | translate}}</a>
                    </td>
                </tr>   
            </tbody>
        </hvac-table>
    </div>
    </ng-template>


        <section class="hvac-user-admin-container hvac-user-info-brief-container">
            <div class="hvac-user-info-brief-header">
                <div class="hvac-user-info-header">
                    <h2>{{ "USER_ADMIN.USER_SUMMARY" | translate }}</h2>
                </div>
                <div (click)="getUserDetailsSummery()" class="hvac-user-info-down-arrow">
                    <utc-svg class="hvac-text-link" src="assets/icons/chevron-down.svg"></utc-svg>
                </div>
            </div>
            <div [ngClass]="{'hidden': isUserDetailsSummeryMode}">
                <div class="hvac-divider"></div>
                <hvac-table class="hvac-user-admin-table-wrap-user-info" [theme]="'zebra'" [responsive]="true">
                    <tbody>
                        <tr>
                            <td class="hvac-user-admin-table-row-data" >{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.USER_ID' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ userIdToDisplay }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.USER_NAME' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ getUserName() }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.COMPANY_INFORMATION' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ getCompanyInfo() }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.DEFAULT_ROLES' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ getSystemDefaultRole() }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.SELECTED_ROLES' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <ul>
                                    <li *ngFor="let item of selectedRoles" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item.roleDisplayName }}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.FEATURES_SELECTED_BY_ROLES' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <ul>
                                    <li *ngFor="let item of featuresSelectedByRole" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item }}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td class="hvac-user-admin-table-row-data">{{ 'USER_ADMIN.USER_SECURITY.USER_SUMMARY.ADDITIONAL_FEATURES_SELECTED' | translate}}</td>
                            <td class="hvac-user-admin-table-row-details">
                                <ul>
                                    <li *ngFor="let item of selectedAdditionalRoles" class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ item.featureDisplayName }}</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </hvac-table>
            </div>
        </section>

        <div class="hvac-flex-end" *ngIf="!isuserEditLastPage;else editUserInfoFooter">
                <button type="button" class="hvac-edit-button" (click)="cancelChanges()" hvacButton="default">
                    {{ 'USER_ADMIN.CANCEL' | translate}}
                </button>
                <button type="button" (click)="onChangePage()" class="hvac-edit-button" hvacButton="primary">
                    {{ 'FOOTER.NEXT' | translate}}
                </button>
        </div>
        <ng-template #editUserInfoFooter>
            <div class="hvac-flex-end">
                <button class="hvac-edit-button" type="button" [hvacButton]="'default'" (click)="onChangePage()">
                    <span>{{ "FOOTER.BACK" | translate }}</span>
                </button>
                <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="submitChanges()">
                    {{ 'FOOTER.SUBMIT' | translate }}
                    <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                </button>
            </div>
            </ng-template>
</ng-template>

<ng-template [ngIf]="userRoleAndFeatureDetails">
    <div class="x-overflow-hidden">
        <utc-modal modal [visible]="true" (closeAction)="userRoleAndFeatureModalClose()" size="dynamic-height" theme="rounded" class="hvac-modal-close" [showHeader]="false">
            <div class="hvac-edit-user-security-modal">
                <hvac-modal-header>
                    <h2>{{ selctedDisplayName }}</h2>
                </hvac-modal-header>
                <div class="modal-content">{{selectedDesc}}</div>
            </div>
            <div class="hvac-divider"></div>
            <div class="display-flex-end">
                <button (click)="userRoleAndFeatureModalClose()" class="modal-button" hvacButton="primary">{{'CLOSE' | translate}}</button>
            </div>
        </utc-modal>
    </div>
</ng-template>

<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
