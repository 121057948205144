<utc-modal modal [visible]="(isModalVisible | async) || false" (closeAction)="handleCloseModal()" size="medium" [showHeader]="false" theme="rounded">
    <div class="hvac-program-manager-modal">
        <h4 class="hvac-coolcalc-impersonation-model-title">{{
            'ACCOUNT_ADMIN.PROGRAMS.ADD_PROGRAM_MANAGER' | translate }}</h4>
        <utc-select
            [shadow]="'none'"
            [multiple]="true"
            placeholder="{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_MANAGER' | translate }}"
            defaultOption="{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_MANAGER' | translate }}"
            [group]="programManagerForm" control="managers" [options]="(nonAssignedManagers$ | async) || []"
            (onChange)="onManagerSelect($event)">
        </utc-select>
        <div class="hvac-program-manager-container" *ngIf="(programManagers$ | async)?.length">
            <hvac-chip *ngFor="let programManager of programManagers$ | async" [text]="programManager.name || ''" [chipId]="programManager.value" (onChipClose)="removeManager($event)"></hvac-chip>
        </div>
        <button (click)="addManagers()" class="hvac-program-manager-button" hvacButton="primary" [disabled]="!(programManagers$ | async)?.length">{{
            'ACCOUNT_ADMIN.PROGRAMS.ADD_CONTACTS' |
            translate }}</button>
    </div>
</utc-modal>
