import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import {
    ConnectedControlsReportResponse,
    DistributorResponse,
    DistributorStats,
    DistributorStatsResponse,
    OpportunityUsageReportResponse,
    DistributorsResponse,
    DealerLoginsReporttUsage
} from 'private/app/models/distributor.model';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

export const QUERY_DISTRIBUTORS = gql`
    query QueryDistributors($params: SearchQueryParams) {
        queryDistributors(params: $params) {
            totalCount,
            totalPages,
            data {
                id
                name
                connectedControlCount
                connectedCustomerCount
                connectedDealers
                queuedControlCount
            }
        }
    }
`;

export const QUERY_DISTRIBUTOR_BY_ID = gql`
    query QueryDistributorById($id: String!) {
        queryDistributorById(id: $id) {
            data {
                id,
                name
            }
        }
    }
`;

export const QUERY_DISTRIBUTOR_STATS = gql`
    query QueryDistributorStats($id: String!) {
        queryDistributorStats(id: $id) {
            data {
                connectedControlCount,
                queuedControlCount,
                connectedCustomerCount,
                connectedDealers
            }
        }
    }
`;

export const QUERY_CONNECTED_CONTROLS_BY_DISTRIBUTOR_ID = gql`
    query QueryConnectedControlsByDistributorId($id: String!, $year: Float!) {
        queryConnectedControlsByDistributorId(id: $id, year: $year) {
            data {
                connectedControls {
                    dataSummary {
                        dateTime
                        controlCount
                    }
                    filepath
                }

                queuedControls {
                    dataSummary {
                        dateTime
                        controlCount
                    }
                    filepath
                }
            }
        }
    }
`;

export const QUERY_DEALER_LOGINS_BY_DISTRIBUTOR_ID = gql`
    query QueryDealerLoginsByDistributorId($id: String!, $params: StartEndDateInput!) {
        queryDealerLoginsByDistributorId(id: $id, params: $params) {
            filepath
            data {
                dateTime
                dealerHVPID
                dealerName
                hvacPin
                hvacUsername
                distributorName
                distributorHVPID
                companyType
            }
        }
    }
`;

export const QUERY_DEALER_OPPORTUNITY_REPORT_USAGE_BY_DISTRIBUTOR_ID = gql`
    query QueryDealerOpportunityReportUsageByDistributorId($id: String!, $params: StartEndDateInput!) {
        queryDealerOpportunityReportUsageByDistributorId(id: $id, params: $params) {
            data {
                dataSummary {
                    totalUnregistered
                    totalDisabledDataSharing
                    totalCount
                }
                filepath
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class DistributorsService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryDistributors(queryParams?: SearchQueryParams): Observable<DistributorsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDistributors').query<{ queryDistributors: DistributorsResponse }>({
                query: QUERY_DISTRIBUTORS,
                variables: { params: queryParams },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDistributors } = value.data;

                    return queryDistributors;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDistributorById(distributorId: string): Observable<DistributorResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDistributorById').query<{ queryDistributorById: DistributorResponse }>({
                query: QUERY_DISTRIBUTOR_BY_ID,
                variables: { id: distributorId },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDistributorById } = value.data;

                    return queryDistributorById;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDistributorStats(distributorId: string): Observable<DistributorStatsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDistributorStats').query<{ queryDistributorStats: DistributorStatsResponse }>({
                query: QUERY_DISTRIBUTOR_STATS,
                variables: { id: distributorId },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryDistributorStats;

                    const orderRef: DistributorStats = {
                        queuedControlCount: 0,
                        connectedCustomerCount: 0,
                        connectedControlCount: 0,
                        connectedDealers: 0
                    };

                    const orderedData = Object.assign(orderRef, data);

                    return { data: orderedData };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryConnectedControlsByDistributorId(distributorId: string, year: number): Observable<ConnectedControlsReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryConnectedControlsByDistributorId').query<{ queryConnectedControlsByDistributorId: ConnectedControlsReportResponse }>({
                query: QUERY_CONNECTED_CONTROLS_BY_DISTRIBUTOR_ID,
                variables: {
                    id: distributorId,
                    year
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryConnectedControlsByDistributorId;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealerLoginsByDistributorId(distributorId: string, startISODate: string, endISODate: string): Observable<DealerLoginsReporttUsage> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const params = {
            startDate: startISODate,
            endDate: endISODate
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealerLoginsByDistributorId').query<{ queryDealerLoginsByDistributorId: DealerLoginsReporttUsage }>({
                query: QUERY_DEALER_LOGINS_BY_DISTRIBUTOR_ID,
                variables: {
                    id: distributorId,
                    params
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryDealerLoginsByDistributorId;
                    const filepath = value.data.queryDealerLoginsByDistributorId.filepath;

                    return {
                        data: data,
                        filepath
                    };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealerOpportunityReportUsageByDistributorId(distributorId: string, startISODate: string, endISODate: string): Observable<OpportunityUsageReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const params = {
            startDate: startISODate,
            endDate: endISODate
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealerOpportunityReportUsageByDistributorId').query<{ queryDealerOpportunityReportUsageByDistributorId: OpportunityUsageReportResponse }>({
                query: QUERY_DEALER_OPPORTUNITY_REPORT_USAGE_BY_DISTRIBUTOR_ID,
                variables: {
                    id: distributorId,
                    params
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryDealerOpportunityReportUsageByDistributorId;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
