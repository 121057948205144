<section class="hvac-content-container hvac-rhythm-10">
    <h1 class="hvac-h1 hvac-rhythm-6">{{name}}</h1>
    <div class='havc-profile-banner-container'>
        <hvac-toast name="ProfileBanner"></hvac-toast>
    </div>
    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-3">
            <hvac-section-select class="hvac-rhythm-5"
                [formGroup]="selectPage"
                [control]="'page'"
                [selectId]="'selectProfilePage'"
                [options]="options"
            ></hvac-section-select>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>
