import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { NavOption } from 'common/components/dropdown/dropdown-navigation/dropdown-navigation.component';
import { environment } from 'common/environments/environment';
import { SearchQuery } from 'common/models';
import { UserStatus } from 'common/models/account-status';
import { User } from 'common/models/auth';
import { NavigationService } from 'common/services/navigation.service';
import { PagePositionService } from 'common/services/page-position.service';
import { UrlService } from 'common/services/url.service';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AuthState } from 'common/store/auth/auth.state';
import { HeaderSearchVisible, PerformSearch } from 'common/store/search/search.actions';
import { SearchState } from 'common/store/search/search.state';
import { ClearSuggestions } from 'common/store/suggestions/suggestions.actions';
import { MobileMenuVisible, TabletMenuVisible, ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, skip, take, takeUntil } from 'rxjs/operators';

const STICKY_HEADER_CLASS = 'hvac-header-sticky';
const SHOW_HEADER_CLASS = 'hvac-header-sticky-reveal';
const HIDE_HEADER_CLASS = 'hvac-header-sticky-hide';

@Component({
    selector: 'hvac-private-header',
    templateUrl: './private-header.component.html',
    styleUrls: ['./private-header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PrivateHeaderComponent {
    @Select(AuthState.user) user$: Observable<User>;
    @Select(AuthState.loggedIn) loggedIn$: Observable<User>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Select(UiState.tabletMenuVisible) tabletMenuVisible$: Observable<boolean>;
    @Select(AuthState.loggedIn) logged$: Observable<boolean>;
    @Select(UiState.headerDropdown) headerDropdown$: Observable<string>;
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(SearchState.query) searchQuery$!: Observable<SearchQuery>;
    @Select(SearchState.headerSearchVisible) headerSearchVisible$: Observable<boolean>;

    ngOnDestroy$ = new Subject();
    userNameOption: NavOption;
    brandFilter = environment.features.brandFilter.brand || environment.features.brandFilter.content

    public bookmarksMenu$: Observable<boolean>;
    public queryControl = new UntypedFormControl('', Validators.required);
    public safeSearchVisible$: Observable<boolean>;

    backButtonCurrentMenu$ = this.navigationService.navigationSections$.pipe(map((currentNavigation) => {
        if (currentNavigation.path.length > 1) {
            return currentNavigation;
        }

        return null;
    }));

    constructor(
        private readonly store: Store,
        private readonly urlService: UrlService,
        private readonly element: ElementRef,
        private readonly pagePositionService: PagePositionService,
        private readonly renderer: Renderer2,
        private readonly viewportScroller: ViewportScroller,
        private readonly navigationService: NavigationService,
        private readonly router: Router
    ) { }

    ngOnInit() {
        this.bookmarksMenu$ = this.headerDropdown$.pipe(map((menuOpen: string) => menuOpen === 'bookmarks'));
        this.safeSearchVisible$ = this.headerSearchVisible$.pipe(
            delay(0)
        );
        combineLatest([this.pagePositionService.viewportState$, this.mobileMenuVisible$, this.tabletMenuVisible$]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([viewportState, mobileMenuVisible, tabletMenuVisible]) => viewportState.scroll.top >= this.element.nativeElement.firstElementChild.offsetHeight && !mobileMenuVisible && !tabletMenuVisible),
            distinctUntilChanged()
        ).subscribe((makeSticky) => {
            if (makeSticky) {
                this.renderer.addClass(document.documentElement, STICKY_HEADER_CLASS);
                this.renderer.addClass(document.documentElement, HIDE_HEADER_CLASS);
            }
            else {
                this.renderer.removeClass(document.documentElement, STICKY_HEADER_CLASS);
            }
        });

        combineLatest([this.pagePositionService.viewportState$, this.mobileMenuVisible$, this.tabletMenuVisible$]).pipe(
            takeUntil(this.ngOnDestroy$),
            filter(([viewportState, _mobileMenuVisible, _tabletMenuVisible]) => Boolean(viewportState.scroll.changed && viewportState.scroll.velocity.y !== 0)),
            map(([viewportState, mobileMenuVisible, tabletMenuVisible]) => viewportState.scroll.velocity.y <= 0 || mobileMenuVisible || tabletMenuVisible),
            distinctUntilChanged(),
            skip(1)
        ).subscribe((revealHeader) => {
            if (revealHeader) {
                this.renderer.addClass(document.documentElement, SHOW_HEADER_CLASS);
                this.renderer.removeClass(document.documentElement, HIDE_HEADER_CLASS);
            }
            else {
                this.renderer.addClass(document.documentElement, HIDE_HEADER_CLASS);
                this.renderer.removeClass(document.documentElement, SHOW_HEADER_CLASS);
            }
        });

        this.searchQuery$.subscribe((query) => {
            if (query.q) {
                this.queryControl.setValue(query.q, { emitEvent: false });
            }
        });

        this.router.events.pipe(
            filter((evt: Event): evt is NavigationEnd => evt instanceof NavigationEnd)
        ).subscribe((evt: RouterEvent) => {
            if (!evt.url.includes('search')) {
                this.store.dispatch(new ClearSuggestions());
            }
        });
    }

    onSubmit() {
        const query = { q: this.queryControl.value };
        this.store.dispatch(new PerformSearch(query));
        this.store.dispatch(new Navigate(['/search'], query));
    }

    displaySearch() {
        this.headerSearchVisible$.pipe(
            take(1)
        ).subscribe((searchActive) => {
            if (!searchActive) {
                this.store.dispatch(new HeaderSearchVisible(true));
            }
            else if (searchActive && this.queryControl.valid) {
                this.onSubmit();
            }
        });
    }

    hideSearch() {
        this.store.dispatch(new HeaderSearchVisible(false));
    }

    scrollToTop() {
        this.viewportScroller.scrollToPosition([0, 0]);
    }

    skipToMainContent() {
        this.urlService.skipToContent('main-content');
    }

    openMobileMenu() {
        this.store.dispatch(new MobileMenuVisible(true));
    }

    handleMobileNavigateBack() {
        this.navigationService.mobileNavigateBack();
    }

    handleCloseBookmarks() {
        this.store.dispatch(new ToggleHeaderDropdown('none'));
    }

    closeMobileMenu() {
        this.store.dispatch(new MobileMenuVisible(false));
        this.store.dispatch(new TabletMenuVisible(false));
    }

    signOut() {
        this.store.dispatch(new Navigate(['/sign-out']));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
