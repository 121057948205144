import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectedDealer } from 'private/app/models/connected-dealer.model';
import { CompanyCode } from 'private/app/models/company.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';


@Component({
    selector: 'hvac-dealer-resources',
    templateUrl: './dealer-resources.component.html',
    styleUrls: ['./dealer-resources.component.scss']
})
export class DealerResourcesComponent extends BaseComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = { };
    public isLoading = { dealerInfo: false };
    public dealerData$ = new BehaviorSubject<null | ConnectedDealer>(null);
    public companyCode$: Observable<CompanyCode>;
    readonly CompanyCode = CompanyCode;

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private dealersService: DealersService,
        private connectedPortalUserService: ConnectedPortalUserService
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.route.parent) {
            return;
        }
        this.route.parent.paramMap
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((params) => {
                const dealerId = params?.get('id') || '';
                this.breadCrumbData = this.getBreadcrumbData(dealerId);

                this.isLoading.dealerInfo = true;
                this.getDealerData(dealerId);
            });

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((code: string) => code as CompanyCode)

        );
    }

    getDealerData(dealerId: string) {
        this.dealersService.queryDealerById(dealerId)
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((res) => {
                const { data } = res;

                this.isLoading.dealerInfo = false;

                this.breadCrumbData = this.getBreadcrumbData(dealerId, data.name);
                this.dealerData$.next(data);
            });
    }

    getBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.RESOURCES') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }
}
