<div class="hvac-cp-diagnostic-equipment-container">
    <div class="hvac-cp-diagnostic-equipment">
        <div class="hvac-h5 hvac-cp-diagnostic-equipment-title">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT.HEADING' | translate}}</div>
        <div class="hvac-cp-diagnostic-equipment-list">
            <div *ngFor="let equipment of equipmentsList; trackBy:trackByEquipmentList;" class="hvac-cp-diagnostic-equipment-list-item"
            (click)="selectEquipment(equipment)" [ngClass] = "{'active': equipment.isSelected, 'disabled': isTestInProgress && !equipment.isSelected}">
                <div [ngClass]=" equipment.isSelected ? 'circle-indicator-select' : 'circle-indicator' "></div>
                <div class="equipment-name">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.EQUIPMENT.EQUIPMENTS.' + equipment.equipmentName | translate }}</div>
                <div class="alert-indicator" *ngIf="equipment.hasAlertNotification"></div>
            </div>
        </div>
    </div>
    <div class="hvac-cp-diagnostic-test-stage">
        <div class="hvac-h5  hvac-cp-diagnostic-test-stage-title">{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.STAGES.HEADING' | translate}}</div>
        <div class="hvac-cp-diagnostic-test-stage-actions">
            <button hvacButton="default"
            class="hvac-cp-diagnostic-test-stage-actions-button"
            size="small" buttonType="button"
            *ngFor="let testStage of selectedEquipment?.tests; let i = index"
            [ngClass]="{'button-active': testStage.hasStarted}"
            (click)="toggleStageTest(testStage)"
            [disabled]="isTestInProgress && !testStage.hasStarted">
                <span class="hvac-cp-diagnostic-test-stage-actions-button-text" *ngIf="selectedEquipment?.isTestable; else other_devices">
                    {{ (testStage.hasStarted ? (isCancelTestInitiated ? testStage.cancellingTestText : testStage.cancelTestText) : testStage.testText)
                    }}
                </span><div *ngIf="testStage.hasStarted" class="hvac-button-spinner"></div>
                <ng-template #other_devices>
                    <span class="hvac-cp-diagnostic-test-stage-actions-button-text">
                        {{ ('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.STAGES.noData' | translate)}}
                    </span><div *ngIf="testStage.hasStarted" class="hvac-button-spinner"></div>
                </ng-template>
            </button>
        </div>
    </div>
    <div class="hvac-cp-diagnostic-test-toast-wrapper">
        <hvac-toast [name]="toastOutlet"></hvac-toast>
    </div>
</div>
<div class="hvac-cp-diagnostic-test-stage-timer" *ngIf="timer">{{ ('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TIMER' | translate) }} : {{ formattedElapsedTime }}</div>
