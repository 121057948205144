import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from './../../../../common/models/content-heading';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountAdminCompanyCreateService } from 'private/app/services/account-admin/account-admin-company-create.service';
import { CompanyQueryService } from 'private/app/services/account-admin/company-query.service';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { ToastService } from 'common/services/toast.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Option } from '../../../../common/components/select/select.component';
import { AppConstants } from 'common/app-constants';
import { Select } from '@ngxs/store';
import { ContentState } from 'common/store/content/content.state';
import { BrandList, UserType } from 'common/models/brand.model';
import { AccountAdminDetailsEditConfiguration, AccountAdminEditFormConfig, CompanyCategoryType, CompanyType } from '../account-admin-company-details/company-query/company-query-configuration';
import { ServicesType } from './business-information/business-information.component';
import { UntypedFormGroup } from '@angular/forms';
import { AddressLookup } from 'private/app/components/address-lookup/address-lookup.component';
import { environment } from 'common/environments/environment';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { AccountStatusService } from 'common/services/account-status.service';
import { AccountStatusResponse } from 'common/models/account-status';
import { BaseComponent } from 'common/components/base/base.component';

@Component({
    selector: 'hvac-create-company',
    templateUrl: './create-company.component.html',
    styleUrls: ['./create-company.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateCompanyComponent extends BaseComponent implements OnInit {
    @Select(ContentState.contentBrandList) availableBrandsList$!: Observable<BrandList[]>;
    public onSubmit$: Subject<void> = new Subject<void>();
    public authorisedBrandList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public toastOutlet = 'createCompanyToast';
    public googleAddressValidationFeatureFlag = environment.features.googleAddressValidation;
    public companyCreateForm = this.companyCreateService.companyCreateForm;
    public breadCrumbData: ContentHeadingData;
    public userIsInternal: boolean;
    public userBrandFamily: string;
    public companyConfiguration: AccountAdminDetailsEditConfiguration;
    public apiRequestActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public addCompanyRelationship$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public relationshipFormReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showAddressVerification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public addressLookUpInput: AddressLookup;
    public modalToastStatus: boolean = false;
    public showModalFrMkngCrntCmpnyToAsPrmry: boolean = false;
    public existingPrimaryDistributorDetails: string = '';
    private isFormReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private bannerType = 'CompanyCreate';
    private idmRoles: string[];
    private countriesAllowedToAddressCorrection = ['US', 'CA'];
    private isAddressVerificationRequired: boolean = false;

    constructor(
        private companyQuery: CompanyQueryService,
        public companyCreateService: AccountAdminCompanyCreateService,
        private oktaService: OktaService,
        public translate: TranslateService,
        public adminService: AccountAdminService,
        private readonly accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        private toastService: ToastService,
        private router: Router,
        private readonly statusService: AccountStatusService
    ) {
        super();
        this.breadCrumbData = this.companyCreateService.breadCrumbData;
    }

    ngOnInit(): void {
        this.statusService.getUserStatus().subscribe((result: AccountStatusResponse) => {
            this.userIsInternal = (result.company.companyCategory.name === UserType.INTERNAL);
            this.userBrandFamily = result.brandFamily;
        });
        this.availableBrandsList$.subscribe((brandList) => {
            let availableBrandList: Option[] = [];
            brandList?.forEach((brand) => {
                brand.catalogBrands?.forEach((catalogBrand) => {
                    availableBrandList.push({
                        name: catalogBrand.name,
                        value: catalogBrand.code
                    });
                });
            });

            // remove duplicates
            availableBrandList = Array.from(availableBrandList.reduce((objA, objB) => objA.set(objB.name, objB), new Map()).values()).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            this.authorisedBrandList$.next(availableBrandList);
        });
        this.companyCreateService.resetDesignation();
        this.toastService.removeAllTimeOuts();
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.companyCreateService.idmRoles$.next(this.accountAdminCompanyUtilsService.idmUserRole(res?.claims?.idm_user_roles || []));
            this.idmRoles = res?.claims?.idm_user_roles || [];
        });
        this.companyCreateService.designation$?.subscribe((designation) => {
            this.isAddressVerificationRequired = Boolean(designation.companyCategory === CompanyCategoryType.HVAC_CUSTOMER);
            this.companyCreateService.initializeCompany();
            this.companyQuery.generateCompanyDetailsConfiguration(designation.companyCategory, designation.companyType, this.idmRoles);
            this.accountAdminCompanyUtilsService.updateComapnyCreateByForm(this.companyCreateForm, this.companyCreateService.company);

            this.accountAdminCompanyUtilsService.resetForm(this.companyCreateForm);
            if (designation.companyCategory && designation.companyType) {
                const companyCategory = this.companyCreateService.companyCategory.filter((category) => category.name === designation.companyCategory);
                const companyCategoryType = this.companyCreateService.companyCategory.filter((category) => category.name.toLowerCase() === designation.companyCategory.toLowerCase())[0]?.companyTypes?.filter((type) => type.code === designation.companyType);
                this.companyCreateService.company.companyCategory = {
                    name: companyCategory.length ? companyCategory[0].name : '',
                    code: companyCategory.length ? companyCategory[0].code : ''
                };

                this.companyCreateService.company.companyType = {
                    name: companyCategoryType?.length ? companyCategoryType[0].name : '',
                    code: companyCategoryType?.length ? `Z${companyCategoryType[0].code}` : ''
                };
                this.companyCreateService.checkValidation(this.companyCreateService.company.companyCategory.name, this.companyCreateService.company.companyType.name);
            }
        });

        this.companyQuery.companyDetailsConfiguration.subscribe((configuration) => {
            this.companyConfiguration = configuration;
            this.addCompanyRelationship$.next(
                Boolean(configuration.configType === CompanyCategoryType.HVAC_CUSTOMER
                    || configuration.configType === CompanyCategoryType.INTERNATIONAL_CUSTOMER
                    || configuration.configType === CompanyCategoryType.MARINE_CUSTOMER
                    || configuration.configType === CompanyCategoryType.NATIONAL_ACCOUNT_CUSTOMER
                    || configuration.configType === CompanyCategoryType.VENDOR)
            );
        });

        combineLatest([this.isFormReady, this.companyCreateForm.controls.country.valueChanges]).pipe(
            takeUntil(this.ngOnDestroy$)
        )
            .subscribe(([isFormReady, country]) => {
                if (this.apiRequestActive$.value) {
                    return;
                }
                if (isFormReady) {
                    if (this.countriesAllowedToAddressCorrection.includes(country[0].value) && this.isAddressVerificationRequired && this.googleAddressValidationFeatureFlag) {
                        this.addressLookUpInput = this.companyAddressToBeValidate(this.companyCreateForm);
                        this.showAddressVerification$.next(true);
                        this.isFormReady.next(false);

                        return;
                    }
                    if (this.addCompanyRelationship$.value) {
                        this.adminService.isModalVisible = true;

                        return;
                    }
                    this.processCreateCompany();
                }
            });

        combineLatest([
            this.companyCreateForm.controls.address1.valueChanges,
            this.companyCreateForm.controls.address2.valueChanges,
            this.companyCreateForm.controls.state.valueChanges,
            this.companyCreateForm.controls.country.valueChanges,
            this.companyCreateForm.controls.city.valueChanges,
            this.companyCreateForm.controls.postalCode.valueChanges
        ]).pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(() => {
            if (!this.isAddressVerificationRequired) {
                this.isAddressVerificationRequired = true;
            }
        });
    }

    companyAddressToBeValidate(companyCreateForm: UntypedFormGroup): AddressLookup {
        return this.companyCreateService.companyAddressToValidate(companyCreateForm);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateRelationships($event: any) {
        this.adminService.isModalVisible = false;
        this.accountAdminCompanyUtilsService.updateRelationshipToCompany($event, this.companyCreateService.company);
        this.processCreateCompany();
    }

    initCreateCmpnyRqstIfValidInput() {
        const isFormInvalid = [];
        Object.keys(this.companyCreateForm.controls).forEach((key) => {
            this.companyCreateForm.get(key)?.markAsDirty();
            if (Object.prototype.hasOwnProperty.call(this.companyConfiguration.formConfig, key) && this.companyConfiguration.formConfig[key as keyof AccountAdminEditFormConfig]?.view && this.companyCreateForm.get(key)?.invalid) {
                isFormInvalid.push(key);
            }
        });

        if (isFormInvalid.length > 0) {
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.VALIDATION_ERROR'),
                id: this.bannerType,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
        else {
            this.isFormReady.next(true);
            this.onSubmit$.next();
        }
    }

    isFormInvalid() {
        const isInvalid = ((this.companyConfiguration.formConfig.brands?.mandatory && this.companyCreateForm.controls.brands.invalid)
            || ((this.companyConfiguration.configType === CompanyCategoryType.DOMESTIC_DISTRIBUTOR && this.companyConfiguration.companyType === CompanyType.DOMESTIC_DISTRIBUTOR) || this.companyConfiguration.configType === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR) && (this.companyCreateForm.controls.services.invalid || (this.companyCreateForm.controls.services.value.find((service: { name: ServicesType; }) => service.name === ServicesType.WarrantyDistributor) && (this.companyCreateForm.controls.claimType.invalid || this.companyCreateForm.controls.soldTo.invalid))));

        return isInvalid;
    }

    onAddressConfirmation(address: AddressLookup | null): void {
        if (address === null) {
            this.showAddressVerification$.next(false);

            return;
        }

        this.companyCreateService.onAddressConfirmation(address);
        this.showAddressVerification$.next(false);
        this.isAddressVerificationRequired = false;
    }

    toastOpenStatus(toastOpenStatus: boolean) {
        this.modalToastStatus = toastOpenStatus;
    }

    cancelForm() {
        this.router.navigate([`/Admin/Admin-Tools/account-admin.html`]);
    }

    cnclChngngExstingPrmryDstrbtr() {
        this.showModalFrMkngCrntCmpnyToAsPrmry = false;
        this.apiRequestActive$.next(false);
    }

    issueCreateCompanyPostRequest() {
        this.showModalFrMkngCrntCmpnyToAsPrmry = false;
        this.companyQuery.createCompany(this.companyCreateService.getCompanyData()).subscribe((companyCreateResponse) => {
            this.accountAdminCompanyUtilsService.resetForm(this.companyCreateForm);
            this.companyCreateForm.clearValidators();
            this.toastService.add({
                content: this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.COMPANY_CREATE_SUCCESS', {
                    companyName: companyCreateResponse.name,
                    companyId: companyCreateResponse.hvacCompanyId
                }),
                id: this.bannerType,
                outletName: this.toastOutlet,
                theme: 'success',
                closeable: true,
                autoClose: true
            });
            setTimeout(() => {
                this.companyCreateService.resetDesignation();
                this.router.navigate([`/Admin/Admin-Tools/account-admin.html/company/${companyCreateResponse.hvacCompanyId}`], { replaceUrl: true });

                return;
            }, AppConstants.timeOutNavigation);
        },
        (err) => {
            const toastContent = () => {
                if (err.error.message) {
                    return err.error.message;
                }
                else if (err.status === 400) {
                    return this.translate.instant('ACCOUNT_ADMIN.COMPANY_CREATE.VALIDATION_ERROR');
                }

                return err.error.details;
            };
            this.apiRequestActive$.next(false);
            this.toastService.add({
                content: toastContent(),
                id: this.bannerType,
                outletName: this.toastOutlet,
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        });
    }

    private getUserCnsntFrMkngCrntSldToAsPrmry(primaryDistributorDetailsText = '') {
        const detailsText = primaryDistributorDetailsText.split('-');

        this.existingPrimaryDistributorDetails = `${detailsText[1] || ''} - ${detailsText[2] || ''}`;
        this.showModalFrMkngCrntCmpnyToAsPrmry = true;
    }

    private processCreateCompany() {
        this.apiRequestActive$.next(true);
        this.toastService.remove(this.bannerType);
        this.accountAdminCompanyUtilsService.updateComapnyCreateByForm(this.companyCreateForm, this.companyCreateService.company);

        if (
            this.companyCreateService.isCompanyCategoryDistributorType(this.companyCreateService.designation$.value.companyCategory) &&
            this.companyCreateService.company.isPrimaryAccount
        ) {
            this.companyQuery.isPrimaryDistributorExistsForCrntSoldToCmpny(this.companyCreateForm.controls.soldTo.value)
                .subscribe((response: [boolean, string]) => {
                    const [isPrimaryDistributorAlreadyPresent, primaryDistributorDetailsText] = response;

                    if (isPrimaryDistributorAlreadyPresent) {
                        this.getUserCnsntFrMkngCrntSldToAsPrmry(primaryDistributorDetailsText);
                    }
                    else {
                        this.issueCreateCompanyPostRequest();
                    }
                });
        }
        else {
            this.issueCreateCompanyPostRequest();
        }
    }
}
