import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SystemProduct } from 'common/models/system-product';
import { ConnectedProductUpdate, SystemType } from 'private/app/models/connected-product.model';
import { DataSharing, NotificationPreferences } from 'private/app/models/connected-system.model';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

export enum SystemView {
    ProductsView = 'productsView',
    NotificationPreferencesView = 'notificationPreferencesView'
}

export interface PropertySystem {
    systemId: string;
    name: string;
    hasCriticalFault?: boolean;
    dataSharing: DataSharing;
    products: SystemProduct[];
    systemType: string;
    serialNumber?: string;
}

@Component({
    selector: 'hvac-property-system-panel',
    templateUrl: './property-system-panel.component.html',
    styleUrls: ['./property-system-panel.component.scss']
})
export class PropertySystemPanelComponent implements OnChanges {
    @Input() openOnInit: boolean = false;
    @Input() dealerId: string;
    @Input() system: PropertySystem;
    @Input() isRelearnSupported: boolean = false;
    @Input() isRelearning: boolean | null = false;
    @Output() onRelearnEquipment = new EventEmitter<string>();
    @Output() onUpdateBtnClick = new EventEmitter<ConnectedProductUpdate>();

    public openState: boolean = false;
    public systemView: SystemView = SystemView.ProductsView;
    public notificationPreferences$ = new BehaviorSubject<null | NotificationPreferences>(null);
    public isLoading = { notificationPreferences: false }
    public isRelearnDisabled: boolean;
    public isDeviceStatusLoading = false;

    public readonly SystemView = SystemView;
    public readonly SystemType = SystemType;
    ngOnDestroy$ = new Subject();

    constructor(private dealerService: DealersService, private productService: ProductService) {}

    ngOnInit(): void {
        if (this.system.systemType === SystemType.ECOBEE && this.system?.serialNumber) {
            this.isDeviceStatusLoading = true;
            this.productService
                .queryWallControlStatusBySerialNo(this.system.serialNumber, this.system.systemType as SystemType, this.dealerId)
                .pipe(takeUntil(this.ngOnDestroy$),
                    catchError(() => {
                        this.isDeviceStatusLoading = false;
                        this.isRelearnDisabled = true;

                        return EMPTY;
                    })).subscribe((response) => {
                    this.isRelearnDisabled = response.data.isDisconnected;
                    this.isDeviceStatusLoading = false;
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.openOnInit?.isFirstChange()) {
            this.openState = changes.openOnInit.currentValue;
        }
    }

    public handleToggle() {
        this.openState = !this.openState;
    }

    public handleRelearnClick() {
        const { systemId } = this.system;

        this.onRelearnEquipment.emit(systemId);
    }

    public getNotificationPreferences() {
        const { systemId } = this.system;

        if (systemId && this.notificationPreferences$.getValue() === null) {
            this.isLoading.notificationPreferences = true;

            this.dealerService.queryNotificationPreferencesBySystemId(systemId, this.dealerId).subscribe((res) => {
                this.isLoading.notificationPreferences = false;

                this.notificationPreferences$.next(res.data);
            });
        }
    }

    public handleProductUpdateClick(event: ConnectedProductUpdate) {
        this.onUpdateBtnClick.emit(event);
    }
}
