<div class="hvac-connected-portal-alerts-filters">
    <label class="hvac-subhead">{{ 'CONNECTED_PORTAL.ALERTS.VIEW_BY' | translate }}</label>
    <div class="hvac-connected-portal-alerts-filters-options">
        <button class="hvac-p-sm hvac-connected-portal-alerts-filters-button"
            [ngClass]="{ 'hvac-connected-portal-alerts-filters-active': alertLevelFilterSelection === 'All' }"
            (click)="updateAlertLevelFilter('All')">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.ALL' | translate }}</button>
        <button class="hvac-p-sm hvac-connected-portal-alerts-filters-button"
            [ngClass]="{ 'hvac-connected-portal-alerts-filters-active': alertLevelFilterSelection === ConnectedDeviceAlertSeverity.CRITICAL }"
            (click)="updateAlertLevelFilter(ConnectedDeviceAlertSeverity.CRITICAL)">{{
            'CONNECTED_PORTAL.ALERTS.FILTERS.CRITICAL' | translate }}</button>
        <button class="hvac-p-sm hvac-connected-portal-alerts-filters-button"
            [ngClass]="{ 'hvac-connected-portal-alerts-filters-active': alertLevelFilterSelection === ConnectedDeviceAlertSeverity.MODERATE }"
            (click)="updateAlertLevelFilter(ConnectedDeviceAlertSeverity.MODERATE)">{{
            'CONNECTED_PORTAL.ALERTS.FILTERS.MODERATE' | translate }}</button>
        <button class="hvac-p-sm hvac-connected-portal-alerts-filters-button"
            [ngClass]="{ 'hvac-connected-portal-alerts-filters-active': alertLevelFilterSelection === ConnectedDeviceAlertSeverity.INFORMATIONAL }"
            (click)="updateAlertLevelFilter(ConnectedDeviceAlertSeverity.INFORMATIONAL)">{{
            'CONNECTED_PORTAL.ALERTS.FILTERS.INFORMATIONAL' | translate }}</button>

    </div>
</div>
