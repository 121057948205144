<div class="hvac-factory-system-details">
    <div class="hvac-content-loader" *ngIf="isLoading; else content">
        <utc-loading-spinner></utc-loading-spinner>
    </div>

    <ng-template #content>
        <div class="system-overview-title-container">
            <div class="system-overview-title">
                <h2 class="hvac-h3">{{ 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.SYSTEM_DETAILS' | translate }}</h2>
            </div>
        </div>

        <ul class="hvac-system-product-list" *ngIf="data">
            <li class="hvac-system-product-list-item" *ngFor='let product of data?.productInfo'>
                <h2 class="hvac-subhead">{{ product.type }}</h2>
                <div class="hvac-product-info-content">
                    <dl class="hvac-product-description-list">
                        <hvac-product-descriptor
                            [label]="'CONNECTED_PORTAL.PRODUCT_CARD.MODEL_NUMBER' | translate"
                            [value]="product.modelNo | textFallback"></hvac-product-descriptor>
                        <hvac-product-descriptor
                            [label]="'CONNECTED_PORTAL.PRODUCT_CARD.SERIAL_NUMBER' | translate"
                            [value]="product.serialNo | textFallback"></hvac-product-descriptor>
                        <hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.FIRMWARE' | translate"
                            [value]="product.firmware | textFallback"></hvac-product-descriptor>
                        <hvac-product-descriptor
                            *ngIf="product.type == ProductType.WallControl && isWallControlConnected"
                            [label]="'CONNECTED_PORTAL.PRODUCT_CARD.STATUS' | translate"
                            value="CONNECTED"></hvac-product-descriptor>
                        <hvac-product-descriptor
                            *ngIf="product.type == ProductType.WallControl && isWallControlConnected == false"
                            [label]="'CONNECTED_PORTAL.PRODUCT_CARD.STATUS' | translate"
                            value="DISCONNECTED"></hvac-product-descriptor>
                        <hvac-product-descriptor
                            *ngIf="product.type == ProductType.WallControl && isWallControlConnected == null"
                            [label]="'CONNECTED_PORTAL.PRODUCT_CARD.STATUS' | translate"
                            value="-"></hvac-product-descriptor>
                    </dl>
                </div>
            </li>
        </ul>
    </ng-template>
</div>
