
<section class="hvac-content-container brand-select-page">
    <h1 class="hvac-h2">{{'BRAND_SELECT_PAGE.HEADER' | translate}}</h1>

    <p *ngIf="(externalLinks$ | async)?.length; else noContent" class="hvac-rhythm-6 hvac-subhead hvac-p">{{'BRAND_SELECT_PAGE.SUBHEAD' | translate}}</p>
    <ng-template #noContent>
        <p class="hvac-rhythm-6 hvac-subhead hvac-p">{{'BRAND_SELECT_PAGE.NO_CONTENT' | translate}}</p>
    </ng-template>
    <div class="hvac-row">
        <div *ngFor="let link of externalLinks$ | async" class="hvac-rhythm-2 hvac-col-xs-12 hvac-col-lg-4">
            <a *ngIf="link.target === '_self';else externalLink" [routerLink]="link.link" [queryParams]="{Brand: link.brand}"  class="hvac-light-button-link hvac-button hvac-button-primary" rel="noopener noreferrer">
                {{ link.brand }}
            </a>
            <ng-template #externalLink>
                <a [href]="link.link" [target]="link.target" class="hvac-light-button-link hvac-button hvac-button-primary" rel="noopener noreferrer">
                    {{ link.brand }}
                </a>
            </ng-template>
        </div>
    </div>
</section>
