import { Component, Output, ViewEncapsulation, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Option } from 'common/components/select/select.component';
import { CatalogCategory, CatalogState } from 'common/store/catalog/catalog.state';
import { Select, Store } from '@ngxs/store';
import { FetchPartsCatalogCategories } from 'common/store/catalog/catalog.actions';
import { skip, takeUntil } from 'rxjs/operators';
import { DocumentService } from 'common/services/document.service';

@Component({
    selector: 'hvac-parts-selectors',
    templateUrl: './parts-selectors.component.html',
    styleUrls: ['./parts-selectors.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PartsSelectors implements OnInit, OnDestroy {
    @Output() public setCategory = new EventEmitter<string[]>()
    @Select(CatalogState.partsCatalogCategories) partsCategories$!: Observable<CatalogCategory[]>;
    public $categoryOptions = new BehaviorSubject<Option[]>([])
    public selectedSubcategory: string;
    public subcategoryOptions: Option[] | undefined = [];
    public isSubcategoryDisabled: boolean = false;
    loading: boolean;
    ngOnDestroy$ = new Subject();
    private categories: CatalogCategory[];
    private selectedPrimaryCategory: string;


    constructor(
        private store: Store,
        private documentService: DocumentService
    ) { }

    ngOnInit() {
        this.store.dispatch(new FetchPartsCatalogCategories());
        this.loading = true;
        this.partsCategories$.pipe(
            takeUntil(this.ngOnDestroy$), skip(1)
        ).subscribe((categories) => {
            this.loading = false;
            const formattedCategories = categories.map((category) => ({
                name: category.name,
                value: category.urn
            }));
            this.$categoryOptions.next(formattedCategories);
            this.categories = categories;
        });

        this.documentService.documentSubCategories$.subscribe((documentCategoryOptions) => {
            if (this.selectedPrimaryCategory && !this.selectedSubcategory) {
                if (documentCategoryOptions.length > 0) {
                    const categoryChildren = this.categories.find((category) => category.urn === this.selectedPrimaryCategory);
                    this.subcategoryOptions = categoryChildren?.children.map((category) => ({
                        name: category.name,
                        value: category.urn,
                        disabled: documentCategoryOptions.length > 0 && documentCategoryOptions.indexOf(category.name) < 0
                    }));
                    this.isSubcategoryDisabled = (this.subcategoryOptions?.filter((cat) => cat.disabled === true).length) === this.subcategoryOptions?.length;
                }
                else {
                    this.subcategoryOptions = [];
                }
            }
        });
    }

    emitSelection(categoryName: string) {
        this.setCategory.emit([categoryName]);
    }

    selectCategory(categoryId: string) {
        const categoryMatch = this.$categoryOptions.value.find((category) => category.value === categoryId) || '';
        this.selectedPrimaryCategory = categoryId;
        this.selectedSubcategory = '';
        const categoryName = categoryMatch ? categoryMatch.name : '';
        this.emitSelection(categoryName);
    }

    selectSubcategory(categoryId: string) {
        if (categoryId) {
            const categoryMatch = this.subcategoryOptions?.find((category) => category.value === categoryId) || '';
            this.selectedSubcategory = categoryId;
            const categoryName = categoryMatch ? categoryMatch.name : '';
            this.emitSelection(categoryName);
        }
        else {
            this.selectCategory(this.selectedPrimaryCategory);
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
