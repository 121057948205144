import { ValueRange } from 'common/models/value-range';
import { endOfYear, startOfYear } from 'date-fns';
import { PropertyValueRange } from 'private/app/models/connected-portal-ui-config.model';
import { ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { ECOBEE_PLC_MODELS } from 'private/app/views/connected-portal/constants';

export const stringToBoolOrNull = (value?: string): boolean | null => {
    if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    }

    return null;
};

export const stringToBool = (value?: string): boolean => {
    if (typeof value === 'string') {
        return value?.toLowerCase() === 'true';
    }

    return false;
};

export const nonEmptyStringOrNull = (value?: string): string | null => (typeof value === 'string' && value.trim() !== '' ? value : null);

export const getIsEcobeePlcModel = (modelNo: string): boolean => ECOBEE_PLC_MODELS.map((model) => model.toLowerCase()).includes(modelNo.toLowerCase());

export const isNumericString = (value: string) => typeof Number(value) === 'number' && !isNaN(Number(value));

export const stringToNumber = (value: string) => {
    if (isNumericString(value)) {
        return Number(value);
    }

    return null;
};

export const isValueOutOfRange = (value: number, range: ValueRange) => value < range.min || value > range.max;

export const formatOutOfRangeEcobeeProps = (properties: object, propertyRanges: Record<string, PropertyValueRange>, replacementString: string) => {
    const formattedEntries = Object.entries(properties).map((entry) => {
        const [key, value] = entry;
        const propRange = propertyRanges[key];

        if (propRange && isValueOutOfRange(Number(value), propRange)) {
            const nextEntry = [
                key,
                replacementString
            ] as [string, string];

            return nextEntry;
        }

        return entry;
    });

    return Object.fromEntries(formattedEntries);
};

export const isUndefined = (value?: unknown): boolean => typeof value === 'undefined';
export const isDefined = (value?: unknown): boolean => !isUndefined(value);

export const formatNumberToRange = (value: number, min: number, max: number, step: number): number => {
    let nextValue = value;

    if (Number.isInteger(step)) {
        const delta = value % step;

        if (delta !== 0) {
            nextValue = Math.floor(value) + (step - delta);
        }
    }
    else {
        const valueDecimals = value.toString().split('.')[1];
        const stepDecimals = step.toString().split('.')[1];

        // If there are more decimal places than the step allows, fix to the step decimal length
        if (valueDecimals?.length > stepDecimals?.length) {
            nextValue = Number(value.toFixed(stepDecimals.length));
        }
    }

    if (value < min) {
        nextValue = min;
    }
    else if (value > max) {
        nextValue = max;
    }

    return nextValue;
};

export const getStartDateEndDateFromYear = (year: number): { startDate: string, endDate: string} => {
    const startDate = startOfYear(new Date(year, 0)).toISOString();
    const endDate = endOfYear(new Date(year, 0)).toISOString();

    return {
        startDate,
        endDate
    };
};

export const getWarrantyLookupUrl = (dealerId: string, propertyId: string, serialNo: string | undefined, systemType: SystemType, productType: ProductType | undefined): string => {
    const baseUrl = `/connected-portal/dealers/${dealerId}/customers/${propertyId}/products`;

    switch (productType) {
        case ProductType.WallControl:
            return `${baseUrl}/wall-control/${systemType}/${serialNo}/warranty/warranty-details?productType=${productType}`;
        case ProductType.IndoorUnit:
            return `${baseUrl}/indoor-unit/${systemType}/${serialNo}/warranty/warranty-details?productType=${productType}`;
        case ProductType.OutdoorUnit:
            return `${baseUrl}/outdoor-unit/${systemType}/${serialNo}/warranty/warranty-details?productType=${productType}`;
        default:
            return '';
    }
};

export const getIsValidSerialOrModelNo = (value: string): boolean => {
    const isAllDashes = value?.split('-').length - 1 === value?.length;

    return (value?.length > 0 && !isAllDashes);
};

export const findSystemBySerialNo = (systems: ConnectedSystem[], serialNo: string) => systems.find((system) => system.products.find((product) => product.serialNo === serialNo));

export const findSystemProductBySerialNo = (systems: ConnectedSystem[], serialNo: string) => findSystemBySerialNo(systems, serialNo)?.products.find((product) => product.serialNo === serialNo);

export const findSystemProductByType = (system: ConnectedSystem, productType: ProductType) => system.products.find((product) => product.type === productType);
