
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'system-diagnostic-parameter',
    templateUrl: './system-diagnostic-parameter.component.html',
    styleUrls: ['./system-diagnostic-parameter.component.scss']
})
export class SystemDiagnosticParameterComponent implements OnInit {
    @Input() minValue?: number;
    @Input() maxValue?: number;
    @Input() leftOutOfRange?: boolean = false;
    @Input() rightOutOfRange?: boolean = false;
    baseValue: number;
    progress: number;
    _currentValue: number | undefined;
    showSlider: boolean = false;

    @Input() set currentValue(value: number | undefined) {
        this._currentValue = value;
        this.updateProgress();
    }

    get currentValue(): number | undefined {
        return this._currentValue;
    }

    ngOnInit(): void {
        this.setInitialValues();
    }

    setInitialValues() {
        /* eslint-disable no-undefined */
        if (this.maxValue !== undefined && this.minValue !== undefined) {
            this.baseValue = this.maxValue - this.minValue;
            this.updateProgress();
        }
    }

    updateProgress() {
        /* eslint-disable no-undefined */
        if (this.currentValue !== undefined && this.minValue !== undefined && this.maxValue !== undefined) {
            const progress = Math.floor(
                ((this.currentValue - this.minValue) / this.baseValue) * 100
            );

            if (this.currentValue < this.minValue || this.currentValue > this.maxValue) {
                if (this.currentValue < this.minValue && this.leftOutOfRange) {
                    this.showSlider = true;
                    this.progress = progress < 0 ? 0 : progress;
                }
                else if (this.currentValue > this.maxValue && this.rightOutOfRange) {
                    this.showSlider = true;
                    this.progress = progress > 100 ? 100 : progress;
                }
                else {
                    this.showSlider = false;
                }
            }
            else {
                this.showSlider = true;
                this.progress = progress;
            }
        }
    }
}
