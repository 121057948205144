import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertSeverity, AlertSeverityFilter } from 'private/app/models/alert.model';

@Component({
    selector: 'hvac-connected-portal-alerts-filters',
    templateUrl: './connected-portal-alerts-filters.component.html',
    styleUrls: ['./connected-portal-alerts-filters.component.scss']
})
export class ConnectedPortalAlertsFiltersComponent {
    @Input() alertLevelFilterSelection: AlertSeverityFilter;
    @Output() public onAlertsFilterChange = new EventEmitter<AlertSeverityFilter>();

    readonly ConnectedDeviceAlertSeverity = AlertSeverity;

    updateAlertLevelFilter(alertFilter: AlertSeverityFilter) {
        this.onAlertsFilterChange.emit(alertFilter);
    }
}
