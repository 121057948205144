import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccountAdminProgramEditData, DateRange } from 'src/private/app/models/account-admin-program.model';
import { AccountAdminProgramService } from 'src/private/app/services/account-admin/account-admin-program.service';
import { GMTTimeConversionUtilsService } from 'src/common/services/gmt-time-transformation.service';
import { DateUtilsService } from 'src/common/services/date-utils-service';
import { TranslateService } from '@ngx-translate/core';
import { PeriodErrorValidationResult } from 'private/app/views/program/edit-program/edit-program.component';
import { enumDatePickerField } from 'common/components/date-range-picker/date-range-picker.component';

export type DateFieldPeriodValidationResultTypes = 'ProgramDatesErrorValidationResult' | 'EnrollmentDatesErrorValidationResult' | 'LocatorDatesErrorValidationResult';

@Injectable({ providedIn: 'root' })
export class ProgramEnrollmentDateUtilsService {
    constructor(
        private gmtTimeConversionUtilsService: GMTTimeConversionUtilsService,
        private dateUtilsService: DateUtilsService,
        private translate: TranslateService
    ) {
    }

    public setProgramPeriod(
        programDates: DateRange,
        programDate$: BehaviorSubject<DateRange>,
        programAdminData: AccountAdminProgramEditData,
        enrollmentPeriodValidationResult: PeriodErrorValidationResult,
        locatorPeriodValidationResult: PeriodErrorValidationResult
    ) {
        const programStartDate = programDates.startDate;
        const programEndDate = programDates.endDate;

        programDate$.next({
            startDate: programStartDate,
            endDate: programEndDate
        });

        if (programStartDate && programEndDate) {
            const enrollmentStartDate = this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programAdminData.programDetails.enrollmentStartDate, { formatTimeAs: 'StartOfDay' });
            const enrollmentEndDate = this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programAdminData.programDetails.enrollmentEndDate, { formatTimeAs: 'EndOfDay' });
            const locatorStartDate = this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programAdminData.programDetails.locatorStartDate, { formatTimeAs: 'StartOfDay' });
            const locatorEndDate = this.gmtTimeConversionUtilsService.getGMTTimeFromDate(programAdminData.programDetails.locatorEndDate, { formatTimeAs: 'EndOfDay' });

            if (enrollmentStartDate && enrollmentEndDate) {
                if (enrollmentStartDate < programStartDate || enrollmentEndDate > programEndDate) {
                    this.setDateError(enrollmentPeriodValidationResult, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.ENROLLMENT_DATE_ERROR'));
                }
                else {
                    this.removeDateError(enrollmentPeriodValidationResult);
                }
            }

            if (locatorStartDate && locatorEndDate) {
                if (locatorStartDate < programStartDate || locatorEndDate > programEndDate) {
                    this.setDateError(locatorPeriodValidationResult, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.LOCATOR_DATE_ERROR'));
                }
                else {
                    this.removeDateError(locatorPeriodValidationResult);
                }
            }
        }
    }

    public setEnrollmentPeriod(
        enrolmmentDates: DateRange,
        programDate$: BehaviorSubject<DateRange>,
        accountAdminProgramService: AccountAdminProgramService,
        enrollmentPeriodValidationResult: PeriodErrorValidationResult
    ) {
        const enrollmentStartDate: number | undefined = enrolmmentDates.startDate;
        const programStartDate: number | undefined = programDate$.value.startDate;
        const enrollmentEndDate: number | undefined = enrolmmentDates.endDate;
        const programEndDate: number | undefined = programDate$.value.endDate;

        if ((programStartDate && programEndDate && enrollmentStartDate && enrollmentEndDate) && (enrollmentStartDate < programStartDate || enrollmentEndDate > programEndDate)) {
            this.setDateError(enrollmentPeriodValidationResult, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.ENROLLMENT_DATE_ERROR'));
        }
        else {
            this.removeDateError(enrollmentPeriodValidationResult);
        }

        accountAdminProgramService.programAdminData.programDetails.enrollmentStartDate = this.dateUtilsService.formatDate(enrollmentStartDate);
        accountAdminProgramService.programAdminData.programDetails.enrollmentEndDate = this.dateUtilsService.formatDate(enrollmentEndDate);
    }

    setLocatorPeriod(
        locatorDates: DateRange,
        programDate$: BehaviorSubject<DateRange>,
        accountAdminProgramService: AccountAdminProgramService,
        locatorPeriodValidationResult: PeriodErrorValidationResult
    ) {
        const locatorStartDate: number | undefined = locatorDates.startDate;
        const programStartDate: number | undefined = programDate$.value.startDate;
        const locatorEndDate: number | undefined = locatorDates.endDate;
        const programEndDate: number | undefined = programDate$.value.endDate;

        if ((programStartDate && programEndDate) && ((locatorStartDate && locatorStartDate < programStartDate) || (locatorEndDate && locatorEndDate > programEndDate))) {
            this.setDateError(locatorPeriodValidationResult, this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.LOCATOR_DATE_ERROR'));
        }
        else {
            this.removeDateError(locatorPeriodValidationResult);
        }
        accountAdminProgramService.programAdminData.programDetails.locatorStartDate = this.dateUtilsService.formatDate(locatorStartDate);
        accountAdminProgramService.programAdminData.programDetails.locatorEndDate = this.dateUtilsService.formatDate(locatorEndDate);
    }

    public removeDateError(
        periodValidationResult: PeriodErrorValidationResult
    ) {
        periodValidationResult.genericErrorMessage = '';
    }

    public setDateError(
        periodValidationResult: PeriodErrorValidationResult,
        errorMessage: string
    ) {
        periodValidationResult.genericErrorMessage = errorMessage;
    }

    public getEmptyPeriodErrorValidationResult(fieldName?: string): PeriodErrorValidationResult {
        return {
            fieldName,
            genericErrorMessage: '',
            startDateFieldErrorMessage: '',
            endDateFieldErrorMessage: ''
        };
    }

    public areDateFieldsInError(dateFieldsValidationResult: PeriodErrorValidationResult[]): boolean {
        return dateFieldsValidationResult.findIndex((dateFieldValidationResult: PeriodErrorValidationResult) => dateFieldValidationResult.genericErrorMessage.length > 0 ||
                dateFieldValidationResult.startDateFieldErrorMessage.length > 0 ||
                dateFieldValidationResult.endDateFieldErrorMessage.length > 0) > -1;
    }

    public isValidationResultError(validationResult: PeriodErrorValidationResult): boolean {
        return validationResult.genericErrorMessage.length > 0 ||
            validationResult.startDateFieldErrorMessage.length > 0 ||
            validationResult.endDateFieldErrorMessage.length > 0;
    }

    public setDateFieldError(periodErrorValidationResult: PeriodErrorValidationResult, errorField: enumDatePickerField, errorMessage: string) {
        if (errorField === enumDatePickerField.START_DATE_FIELD) {
            periodErrorValidationResult.startDateFieldErrorMessage = errorMessage;
        }
        else {
            periodErrorValidationResult.endDateFieldErrorMessage = errorMessage;
        }
    }
}

