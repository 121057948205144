<h1>{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DETAILS' | translate }}</h1>
<form (ngSubmit)="onSubmit(form, dealerLocatorListData)">
    <div *ngFor="let group of form?.controls; trackBy:trackByFn; let i = index" class="hvac-dealers-list-container">
        <div *ngIf="dealerLocatorListData">
            <button type="button" class="hvac-collapsible-locator-list" (click)="toggleResultRow(getValueAt(i))"
                    [ngClass]="{ 'hvac-enrollment-accordian-expanded': getValueAt(i).isCollapsed == true }">
                    <div class="hvac-company-title">
                        <label>{{ getValueAt(i).dealerBrandLocatorNm | lowercase }}</label>
                        <div class="hvac-dealer-locator-check-mark-icon">
                            <utc-svg *ngIf="getValueAt(i).isEnrolled" [ngClass]="{ 'hvac-dealer-locator-enrollment-check-on-expand': getValueAt(i).isCollapsed }" class="hvac-dealer-locator-enrollment-check" src="assets/icons/check.svg"></utc-svg>
                        </div>
                    </div>
                    <utc-svg class="havc-company-row-chevron" src="assets/icons/chevron-down.svg">
                    </utc-svg>
                </button>
                <div class="content" [ngClass]="getValueAt(i).isCollapsed == true ? 'show' : 'hide'">
                <div class="hvac-accordian-content">
                    <div class="hvac-row hvac-col-xs-12">
                        <div class="hvac-col-xs-12 hvac-account-admin-details-col">
                            <ul class="hvac-account-admin-detail-list">
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label hvac-dealer-locator-name-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_LOCATOR_LABEL' | translate: { company: getValueAt(i).dealerBrandLocatorNm | lowercase } }}</div>
                                    <div class="hvac-dealer-locator-enable-container">
                                        <utc-checkbox [inputControl]="group.get('isEnrolled')" [checked]="getValueAt(i).isEnrolled"
                                        (change)="toggleDealerLocator(getValueAt(i))"></utc-checkbox>
                                        <span class="hvac-enabled-status-text">{{ getValueAt(i).isEnrolled ? 'Enabled' : 'Disabled' }}</span>
                                    </div>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.CALL_TRACKING_NUMBER_LABEL' | translate}}</div>
                                    <utc-input [inputControl]="group.get('callTrackingNumber')" type="text" [disabled]="false" [hideLabel]="true"></utc-input>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.ADDITIONAL_CALL_TRACKING_NUMBER_LABEL' | translate}}</div>
                                    <utc-input [inputControl]="group.get('addCallTrackingNumber')" type="text" [disabled]="false" [hideLabel]="true"></utc-input>
                                </li>
                                <li *ngIf="getControlsArray(group).length" class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.PRODUCT_OFFERINGS_LABEL' | translate}}</div>
                                    <div class="hvac-form-field hvac-inline-form-field">
                                        <div *ngFor="let offering of getControlsArray(group)" class="hvac-product-offering-container">
                                            <utc-checkbox [disabled]="offering.value.productOfferingId === '2' ? false : true" [checked]="offering.value.isSelected" [inputId]="offering.value.productOfferingId" [value]="offering.value.productNm" (change)="onOfferingChange(offering.value)" [inputName]="offering.value.productNm">
                                            <span class="hvac-document-filter-name">{{ offering.value.productNm }}</span>
                                            </utc-checkbox>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="brandService.isICPBrand(getValueAt(i).dealerBrandLocatorNm)" class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_RANKING' | translate }}</div>
                                    <utc-select (change)="dropdownChange(getValueAt(i), rankingFormGroup(group))" class="hvac-dealer-ranking-dropdown" [multiple]="false" [group]="rankingFormGroup(group)" control="rankingDropdown" defaultOption="{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.SELECT_RANKING' | translate }}" [options]="rankings"></utc-select>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.LOCATOR_URL_LABEL' | translate}}</div>
                                    <utc-input [inputControl]="group.get('url')" type="text" [disabled]="false" [hideLabel]="true"></utc-input>
                                </li>
                                <li class="hvac-row hvac-top-xs">
                                    <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.FIRST_YEAR_IN_HVAC_LABEL' | translate}}</div>
                                    <utc-input [inputControl]="group.get('firstYearInHVAC')" type="text" [disabled]="false" [hideLabel]="true"></utc-input>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hvac-dealer-locator-footer-container">
        <button hvacButton="default" (click)="cancelAction()" class='hvac-manage-dealer-footer-button'>{{'MANAGE_DEALER.CANCEL' | translate }}</button>
        <button type="submit" [disabled]="!(enableSubmit$ | async)" hvacButton="primary" class='hvac-manage-dealer-footer-button'>{{'MANAGE_DEALER.SAVE' | translate }}</button>
    </div>
</form>
<utc-loading-spinner  *ngIf="loading"></utc-loading-spinner>

