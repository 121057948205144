import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { ToastService } from 'common/services/toast.service';
import { EnrollmentOption, ProgramDetails, ProgramEnrollment } from 'private/app/models/account-admin-program.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import enrollmentStatusConstants from 'private/app/views/program/enrollment-form/enrollment-forms/enrollment-status.json';
import * as XLSX from 'xlsx';

interface EnrollmentExcelExportFormat {
    'Company Name': string;
    'Company ID': string;
    City: string;
    State: string;
    'Enrollment Status': string;
    'WorkFlow Status'?: string;
    'Enrollment Level'?: string;
    'Sponsoring Distributor'?: string;
}

@Injectable({ providedIn: 'root' })
export class AccountAdminProgramEnrollmentService implements OnDestroy {
    public manageEnrollmentsForm = new UntypedFormGroup({
        dealerName: new UntypedFormControl(''),
        dealerDisplayId: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        workflowStatus: new UntypedFormControl(''),
        sponsoringDistributor: new UntypedFormControl('')
    });

    public breadCrumbData: ContentHeadingData = {
        Content: {
            title: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS'),
            pageTitle: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS')
        },
        breadCrumbs: [
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                url: '/admin'
            },
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                url: '/Admin/Program'
            },
            {
                title: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_3'),
                url: '/Admin/Program/search-program/search'
            }
        ]
    };

    public enrollmentStatusTabs: string[];
    public PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND: EnrollmentOption;
    public PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE: EnrollmentOption;
    public nonDealerConversionEnrollmentStatusTabs: string[];
    private ngOnDestroy$ = new Subject();

    constructor(
        private translateService: TranslateService,
        private languageTranslationService: LanguageTranslationService,
        private router: Router,
        private readonly toastService: ToastService
    ) {
        this.languageTranslationService.translationsLoaded().pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
            this.initDropDownOptions();
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public getEnrollmentActionOptions(enrollment: ProgramEnrollment, isEnrollmentFormEmpty: boolean, isEnrollmentWorkflowEmpty: boolean) {
        let options: EnrollmentOption[] = [];

        switch (enrollment.enrollmentStatus) {
            case enrollmentStatusConstants.enrollmentStatus.Eligible:
                return [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER
                ];
            case enrollmentStatusConstants.enrollmentStatus.Active:
                if (isEnrollmentFormEmpty && isEnrollmentWorkflowEmpty) {
                    options = [
                        this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE,
                        this.PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE
                    ];
                    options.push(this.getEnrollmentLevelOption(enrollment));

                    return options;
                }
                options = [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE
                ];

                options.push(this.getEnrollmentLevelOption(enrollment));

                return options;
            case enrollmentStatusConstants.enrollmentStatus.Inactive:
            case enrollmentStatusConstants.enrollmentStatus['Pending Initial']:
            case enrollmentStatusConstants.enrollmentStatus['Pending Final']:
            case enrollmentStatusConstants.enrollmentStatus.Returned:
                options = [
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER,
                    this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE
                ];

                options.push(this.getEnrollmentLevelOption(enrollment));

                return options;
            default: return options;
        }
    }

    public tabValue(tab: string) {
        let val: string = '';
        switch (tab) {
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE'):
                val = '0';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE'):
                val = '1';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE'):
                val = '2';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_FINAL'):
                val = '3';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_INITIAL'):
                val = '4';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.RETURNED'):
                val = '5';
                break;
            case this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ALL'):
                break;
            default:
                val = '0';
                break;
        }

        return val;
    }

    public getWorkflowStatus(status: string) {
        let workflowStatusId: string = '';
        if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.ELIGIBLE').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '0';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_APPROVED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '1';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_APPROVED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '2';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_DECLINED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '3';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_DECLINED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '4';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.PM_RETURNED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '5';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.DIST_SUBMITTED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '6';
        }
        else if (this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.WORKFLOW_STATUS.SM_RETURNED').toLowerCase().includes(status.toLowerCase())) {
            workflowStatusId = '7';
        }

        return workflowStatusId;
    }

    public openProgramEnrollmentForm(enrollment: ProgramEnrollment, programData: ProgramDetails) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ['/Admin/Program/search-program/enrollments/form'],
                {
                    queryParams: {
                        cid: enrollment.dealerDisplayId,
                        pid: programData.id,
                        brandName: programData?.brand?.name
                    }
                }
            )
        );

        const emptyTab = window.open('about:blank', '_blank');

        if (emptyTab) {
            emptyTab.location.href = url;
        }
        else {
            this.toastService.add({
                content: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ERROR.ENROLLMENT_FORM_OPEN_ERROR'),
                id: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_TOAST_MESSAGE_ID'),
                theme: 'error',
                closeable: true,
                autoClose: true
            });
        }
    }

    public exportSelectedEnrollments(programName: string, enrollmentMap: Map<number, ProgramEnrollment>, isDealerConversionProgram: boolean, isEnrollmentLevel: boolean): void {
        const title = `Enrollments_${programName}_${new Date().toDateString()}`;
        const fileName = `${title}.xlsx`;
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.transformEnrollmentToExcelExportFormat(enrollmentMap, isDealerConversionProgram, isEnrollmentLevel));
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        XLSX.writeFile(workBook, fileName);
    }

    public handleSelectAllEnrollments(event: Event, programEnrollmentData: ProgramEnrollment[], selectedEnrollments: Map<number, ProgramEnrollment>) {
        const isSelectAllChecked = (event.target as HTMLInputElement).checked;

        programEnrollmentData.forEach((dealer) => {
            dealer.isChecked = isSelectAllChecked;
            if (isSelectAllChecked) {
                selectedEnrollments.set(dealer.dealerId, dealer);
            }
            else {
                selectedEnrollments.delete(dealer.dealerId);
            }
        });
    }

    private initDropDownOptions() {
        this.enrollmentStatusTabs = [
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ALL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_INITIAL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.PENDING_FINAL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.RETURNED')
        ];
        this.nonDealerConversionEnrollmentStatusTabs = [
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ALL'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ELIGIBLE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.ACTIVE'),
            this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.INACTIVE')
        ];
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_ENROLL_DEALER = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ACTIVE'),
            value: '1',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_ACTIVE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_DEACTIVATE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.INACTIVE'),
            value: '2',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_INACTIVE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_RESET_TO_ELIGIBLE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.ELIGIBLE'),
            value: '0',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.STATUS'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.STATUS_ELIGIBLE')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.LOCATOR_SUSPEND'),
            value: '2',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.LEVEL_SUSPEND')
        };
        this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE = {
            name: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.DROPDOWN_OPTIONS.ENROLLMENT_ACTIONS.LOCATOR_ACTIVE'),
            value: '1',
            enrollmentType: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LEVEL'),
            confirmDetails: this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_ACTION_MESSAGE.LEVEL_ACTIVE')
        };
    }

    private getEnrollmentLevelOption(enrollment: ProgramEnrollment): EnrollmentOption {
        return enrollment.enrollmentLevel === 1 ? this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_SUSPEND : this.PROGRAM_ENROLLMENT_ACTION_OPTION_LOCATOR_ACTIVE;
    }

    private transformEnrollmentToExcelExportFormat(enrollmentMap: Map<number, ProgramEnrollment>, isDealerConversionProgram: boolean, isEnrollmentLevel: boolean): EnrollmentExcelExportFormat[] {
        return Array.from(enrollmentMap.values()).map((enrollment) => {
            const enrollmentStatus: keyof typeof enrollmentStatusConstants.enrollmentStatusReverseMapping =
                enrollment.enrollmentStatus.toString() as keyof typeof enrollmentStatusConstants.enrollmentStatusReverseMapping;
            const workflowStatus: keyof typeof enrollmentStatusConstants.workflowStatusReverseMapping =
                enrollment.enrollmentWorkFlowStatusId.toString() as keyof typeof enrollmentStatusConstants.workflowStatusReverseMapping;
            const transformedEnrollment: EnrollmentExcelExportFormat = {
                'Company Name': enrollment.dealerName,
                'Company ID': enrollment.dealerDisplayId.toString(),
                'City': enrollment.city,
                'State': enrollment.state,
                'Enrollment Status': enrollmentStatusConstants.enrollmentStatusReverseMapping[enrollmentStatus]
            };

            if (isDealerConversionProgram) {
                transformedEnrollment['WorkFlow Status'] = enrollmentStatusConstants.workflowStatusReverseMapping[workflowStatus];
            }

            if (isEnrollmentLevel) {
                transformedEnrollment['Enrollment Level'] = enrollment.enrollmentLevel === 1
                    ? this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.LOCATOR') :
                    this.translateService.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_STATUS.SUSPEND');
            }

            transformedEnrollment['Sponsoring Distributor'] = enrollment.sponsoringDistributor;

            return transformedEnrollment;
        });
    }
}
