<section>
    <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
</section>

<main class="hvac-content-container">
    <div class="havc-coolcalc-support-section">
        <span class="havc-coolcalc-support-label">{{ 'COOL_CALC.MODAL.EXP_ISSUE' | translate}}</span>
        <button type="button" class="hvac-user-text-button" hvacButton="text" (click)="openHelpModal()">
            {{ 'COOL_CALC.MODAL.FILL_FORM' | translate}}
        </button>
    </div>
    <div class="hvac-coolcalc-impersonation-container" *ngIf="canImpersonate">
        <h1 *ngIf="impersonatedUser.userName.length > 0">{{ 'COOL_CALC.IMPERSONATION_TITLE' | translate: { userName: impersonatedUser.userName } }}</h1>
        <button  *ngIf="!impersonatedUser.id.length" (click)="isModalVisible = true" class="hvac-coolcalc-impersonate" [hvacButton]="'primary'">
            <span>{{ 'COOL_CALC.USER_SUPPORT' | translate }}</span>
        </button>
        <button *ngIf="impersonatedUser.id.length" (click)="exitImpersonation()" class="hvac-coolcalc-impersonate" [hvacButton]="'primary'">
            <span>{{ 'COOL_CALC.EXIT' | translate }}</span>
        </button>
    </div>

    <div class="hvac-landing-page">
        <section class="hvac-landing-page-section hvac-page-section">
            <div id="coolcalc-ui" class="hvac-coolcalc-restui">
                <utc-loading-spinner></utc-loading-spinner>
            </div>
            <div id="mj8-report"></div>
            <utc-loading-spinner *ngIf="coolCalcService.isCoolCalcLoading" class="hvac-pdf-download-indicator"
                [spinnerSize]="'small'"></utc-loading-spinner>
        </section>
    </div>
    <div class="hvac-coolcalc-impersonation-model-container">
        <utc-modal modal [visible]="isModalVisible" (closeAction)="handleCloseModal()" size="medium" [showHeader]="false" theme="rounded">
            <div>
                <h4 class="hvac-coolcalc-impersonation-model-title">{{
                    'COOL_CALC.CUSTOMERS_USER_ID' | translate }}</h4>
                
                <utc-input [inputControl]="userIdControl" [errorMessage]="sessionUserErrorMessage$ | async"
                    label="{{ 'COOL_CALC.USER_ID_HINT' | translate }}"></utc-input>
                <div class="hvac-coolcalc-impersonation-model-actions">
                    <button (click)="impersonateCoolCalc()" class="hvac-coolcalc-impersonation-actions-button" hvacButton="primary" [disabled]="userIdControl.value.length === 0">{{ 'COOL_CALC.SUBMIT' |
                        translate }}</button>
                </div>
            </div>
        </utc-modal>
    </div>
    <div class="hvac-coolcalc-support-model-container">
        <utc-modal modal [visible]="isHelpModalVisible" (closeAction)="handleCloseHelpModal()" size="small"
            [showHeader]="false" theme="rounded">
            <ng-container *ngIf="loading; else content">
                <utc-loading-spinner class="hvac-coolcalc-support-model-container" spinnerSize="small"
                    *ngIf="loading"></utc-loading-spinner>
            </ng-container>
            <ng-template #content>
                <div *ngIf="apiStatus">
                    <p>{{apiStatus}}</p>
                </div>
                <div *ngIf="!apiStatus">
                    <div class="havc-coolcalc-help-modal-inner-container">
                        <h5 class="hvac-coolcalc-support-model-title">{{
                            'COOL_CALC.MODAL.REQUEST_FORM_HEADER' | translate }}</h5>
                        <hr>
                        <h5>{{'COOL_CALC.MODAL.REACH_YOU' | translate }}
                            <span class="hvac-coolcalc-required">*</span>
                        </h5>
                        <utc-input [inputControl]="requestSupportForm.controls.emailValidationControl"
                            [error]="requestSupportForm.controls.emailValidationControl.dirty && !requestSupportForm.controls.emailValidationControl.valid"
                            [label]="'COOL_CALC.MODAL.EMAIL' | translate" [type]="'email'"></utc-input>
                        <p *ngIf="requestSupportForm.controls.emailValidationControl.dirty && !requestSupportForm.controls.emailValidationControl.valid"
                            class="havc-coolcalc-form-warning">
                            <span class="exclamation-circle">
                                <i class="fas fa-exclamation"></i> </span>
                            {{'COOL_CALC.MODAL.EMAIL_FORMAT' | translate }}
                        </p><utc-input shadow="light"
                            [error]="requestSupportForm.controls.userPhoneControl.dirty && !requestSupportForm.controls.userPhoneControl.valid"
                            [label]="'COOL_CALC.MODAL.PHONE' | translate" [hideLabel]="false"
                            [inputControl]="requestSupportForm.controls.userPhoneControl" type="text" [mask]=maskPhoneNumber
                            inputId="user_number">
                        </utc-input>
                    </div>
                    <div class="havc-coolcalc-help-modal-inner-container">
                        <h5>{{'COOL_CALC.MODAL.TELL_US_MORE' | translate }}
                            <span class="hvac-coolcalc-required">*</span>
                        </h5>
                        <utc-textarea [inputControl]="requestSupportForm.controls.issueDescriptionControl"
                            [error]="requestSupportForm.controls.issueDescriptionControl.dirty && !requestSupportForm.controls.issueDescriptionControl.valid"
                            [errorMessage]="sessionUserErrorMessage$ | async" [maxLength]="maxCommentLimit"
                            label="{{ 'COOL_CALC.MODAL.ISSUE_DESCRIPTION' | translate }}"></utc-textarea>
                        <div class="hvac-coolcalc-support-model-actions">
                            <button (click)="requestSubmitClick()" class="hvac-coolcalc-support-actions-button" [disabled]="requestSupportForm.controls.emailValidationControl.value?.length === 0 || 
                            requestSupportForm.controls.emailValidationControl.value === undefined ||
                                requestSupportForm.controls.userPhoneControl.value?.length === 0 || 
                                requestSupportForm.controls.issueDescriptionControl.value?.length === 0 ||
                                !requestSupportForm.controls.emailValidationControl.valid ||
                                !requestSupportForm.controls.userPhoneControl.valid" hvacButton="primary">{{
                                'COOL_CALC.SUBMIT' |
                                translate }}</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </utc-modal>
    </div>
</main>