import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { Toast, ToastService } from 'common/services/toast.service';
import { DiagnosticEquipments, DiagnosticParameterUnit, DiagnosticsConfigurations, DiagnosticTestUnitType, EquipmentHeatType, SystemDiagnosticsExtendedParamConfig, SystemDiagnosticsParamConfig, SystemDiagnosticsParamsData } from 'private/app/models/connected-portal-system-diagnostics.model';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { SystemDiagnosticsService } from 'private/app/services/connected-portal/system-diagnostics.service';
import { ApiErrorName, ApiResponseCode, ApiResponseState, SYSTEM_DIAGNOSTICS_ODU_ADDITIONAL_PARAMS, SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest, EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

type SystemDiagnosticsParamTypeKey = keyof SystemDiagnosticsParamConfig;
type SystemDiagnosticsIDUParamTypeKey = keyof Pick<SystemDiagnosticsParamConfig, 'supplyAirTemperature' | 'returnAirTemperature'>;
type SystemDiagnosticsODUParamTypeKey = keyof Omit<SystemDiagnosticsParamConfig, 'supplyAirTemperature' | 'deltaT' | 'returnAirTemperature' | 'blowerRpm'>;

const PARAM_ABSOLUTE_MIN = -9999;
const PARAM_ABSOLUTE_MAX = 9999;

@Component({
    selector: 'wall-control-system-diagnostics',
    templateUrl: './wall-control-system-diagnostics.component.html',
    styleUrls: ['./wall-control-system-diagnostics.component.scss'],
    providers: [ToastService, SystemDiagnosticsService]
})
export class WallControlSystemDiagnosticsComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Output() onExitTest = new EventEmitter<Toast>();

    readonly DiagnosticTestUnitType = DiagnosticTestUnitType;
    readonly CharSymbol = CharSymbol;
    readonly DiagnosticParameterUnit = DiagnosticParameterUnit;
    readonly ApiResponseState = ApiResponseState;

    public activateWallControl$ = new BehaviorSubject(true);
    public testUnitType$ = new BehaviorSubject<DiagnosticTestUnitType>(DiagnosticTestUnitType.ODU_AND_IDU);
    public systemHeatType$ = new BehaviorSubject<EquipmentHeatType>(EquipmentHeatType.FURNACE);
    public equipmentList$: Observable<DiagnosticEquipments[] | null>;
    public systemDiagnosticsConfig$: Observable<SystemDiagnosticsExtendedParamConfig>;
    public systemDiagnosticsParams$: Observable<SystemDiagnosticsParamConfig>;
    public activationState: ApiResponseState = ApiResponseState.Loading;

    public systemDiagnosticsParams?: SystemDiagnosticsParamConfig;
    public equipments?: DiagnosticEquipments[];
    public selectedEquipment?: DiagnosticEquipments;
    public showAlertNotification = false;
    public showToastElement = true;
    public loadingMessages: string[] = [];
    public equipmentListToastOutlet = 'systemDiagnosticsEquipmentListToast';
    public diagnosticsToastOutlet = 'wallControlDiagnosticsToast';

    constructor(
        private cdr: ChangeDetectorRef,
        private productService: ProductService,
        private systemDiagnosticSvc: SystemDiagnosticsService,
        private toastService: ToastService,
        private readonly numberSuffixPipe: NumberSuffixPipe,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        const loadingTranslation = this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.LOADING_MESSAGES');
        this.loadingMessages = Array.isArray(loadingTranslation) ? loadingTranslation : [];

        this.systemDiagnosticSvc.getDiagnosticRealTimeData$()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((data: SystemDiagnosticsParamsData) => {
                this.updateTestData(data);
            });

        this.equipmentList$ = this.activateWallControl$.pipe(
            tap(() => {
                this.activationState = ApiResponseState.Loading;
            }),
            switchMap(() => this.productService.activateEcobeeControlBySerialNo(this.serialNo, this.dealerId).pipe(
                switchMap((activationRes) => {
                    if (activationRes?.code === ApiResponseCode.SUCCESS) {
                        return this.systemDiagnosticSvc.queryDiagnosticTestEquipmentBySerialNo(this.serialNo, this.dealerId);
                    }

                    throw new Error(activationRes?.message || 'ACTIVATION_FAILED');
                }),
                tap((testEquipmentRes) => {
                    this.systemHeatType$.next(this.getSystemHeatType(testEquipmentRes));
                    this.activationState = ApiResponseState.Success;
                }),
                catchError(() => {
                    this.activationState = ApiResponseState.Error;

                    const toastBase = 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.DEVICE_DISCONNECTED';

                    this.toastService.removeAll();
                    this.toastService.add({
                        content: this.translate.instant(`${toastBase}.TITLE`) + this.translate.instant(`${toastBase}.SUB_TEXT`),
                        theme: 'error',
                        closeable: true,
                        autoClose: false,
                        outletName: this.equipmentListToastOutlet
                    });

                    return EMPTY;
                })
            ))
        );

        this.systemDiagnosticsConfig$ = this.systemDiagnosticSvc.getSystemDiagnosticParamConfig().pipe(shareReplay());

        this.systemDiagnosticsParams$ = combineLatest([
            this.systemHeatType$,
            this.systemDiagnosticsConfig$
        ]).pipe(
            map(([systemHeatType, diagnosticsConfig]) => {
                const formattedConfig = this.formatDiagnosticConfig(systemHeatType, diagnosticsConfig);

                return this.getParamsConfigAsList(formattedConfig);
            }),
            tap((params) => {
                this.systemDiagnosticsParams = params;
            }),
            catchError((err) => {
                err.testEquipmentListingError ? this.showToast(this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.EQUIPMENT_LISTING_ERROR')) : this.showToast(err.message);

                return EMPTY;
            })
        );

        this.systemDiagnosticsParams$.subscribe();
    }

    getParamsConfigAsList(data: SystemDiagnosticsParamConfig) {
        return Object.entries(data).reduce((acc, [key, value]) => {
            const isParameterOutOfRangeChecked = Object.hasOwnProperty.call(SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS, key);
            /* eslint-disable no-undefined */
            acc[key as SystemDiagnosticsParamTypeKey] = {
                currentValue: value.type === DiagnosticTestUnitType.IDU ? undefined : value.min,
                min: value.min,
                max: value.max,
                equipmentType: value.type,
                unit: value.unit,
                displayValue: this.formatDisplayValue(value),
                ...(isParameterOutOfRangeChecked && SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS[key as SystemDiagnosticsIDUParamTypeKey]),
                ...(value.type === DiagnosticTestUnitType.ODU && SYSTEM_DIAGNOSTICS_ODU_ADDITIONAL_PARAMS[key as SystemDiagnosticsODUParamTypeKey]),
                ...((key === 'deltaT' || key === 'blowerRpm') && {
                    outOfRangeLowerMin: value.outOfRangeLowerMin,
                    outOfRangeLowerMax: value.outOfRangeLowerMax,
                    outOfRangeUpperMin: value.outOfRangeUpperMin,
                    outOfRangeUpperMax: value.outOfRangeUpperMax,
                    iduDisplayOrder: value.iduDisplayOrder
                })
            };

            return acc;
        }, {} as SystemDiagnosticsParamConfig);
    }

    updateTestData(data: SystemDiagnosticsParamsData) {
        if (this.selectedEquipment) {
            let showEquipmentAlertIndication: boolean = false;

            if (this.systemDiagnosticsParams) {
                Object.keys(this.systemDiagnosticsParams).forEach((key) => {
                    const paramName = key as SystemDiagnosticsParamTypeKey;
                    const nextValue = Math.round(Number(data[paramName]));

                    if (this.systemDiagnosticsParams && this.selectedEquipment) {
                        if (
                            (this.systemDiagnosticsParams[paramName].equipmentType === this.selectedEquipment.equipmentType ||
                        this.selectedEquipment.equipmentType === DiagnosticTestUnitType.ODU_AND_IDU)) {
                            const maxVal = Number(typeof this.systemDiagnosticsParams[paramName].max === 'number' ? this.systemDiagnosticsParams[paramName].max : PARAM_ABSOLUTE_MIN);
                            const minVal = Number(typeof this.systemDiagnosticsParams[paramName].min === 'number' ? this.systemDiagnosticsParams[paramName].min : PARAM_ABSOLUTE_MAX);

                            if ((typeof maxVal === 'number' && maxVal > PARAM_ABSOLUTE_MIN) || (typeof minVal === 'number' && minVal < PARAM_ABSOLUTE_MAX)) {
                                this.systemDiagnosticsParams[paramName].currentValue = nextValue;
                                this.systemDiagnosticsParams[paramName].displayValue = this.formatDisplayValue(this.systemDiagnosticsParams[paramName]);
                                if ((nextValue > maxVal) || (nextValue < minVal)) {
                                    this.systemDiagnosticsParams[paramName].showAlertNotification = true;
                                    showEquipmentAlertIndication = true;
                                }
                                else {
                                    this.systemDiagnosticsParams[paramName].showAlertNotification = false;
                                }
                            }
                        }
                    }
                });
            }

            if (showEquipmentAlertIndication) {
                this.selectedEquipment.hasAlertNotification = true;
                this.showAlertNotification = true;
            }
            else {
                this.selectedEquipment.hasAlertNotification = false;
                this.showAlertNotification = false;
            }
        }
    }

    resetTestData(toastMessage?: Toast | undefined) {
        this.showAlertNotification = false;
        this.cdr.detectChanges();

        if (this.systemDiagnosticsParams) {
            Object.entries(this.systemDiagnosticsParams).forEach(([key, value]) => {
                if (this.systemDiagnosticsParams) {
                    const { currentValue, ...rest } = value;
                    rest.displayValue = this.formatDisplayValue(rest);
                    rest.showAlertNotification = false;
                    this.systemDiagnosticsParams[key as SystemDiagnosticsParamTypeKey] = rest;
                }
            });

            this.cdr.detectChanges();
        }

        if (toastMessage) {
            this.exitTest(toastMessage);
        }
    }

    exitTest(toastMessage?: Toast) {
        this.onExitTest.next(toastMessage);
    }

    setSelectedEquipment(equipment: DiagnosticEquipments) {
        this.selectedEquipment = equipment;
        this.testUnitType$.next(equipment.equipmentType);
        this.cdr.detectChanges();
    }

    handleSocketDataError(errorName: ApiErrorName) {
        this.activationState = ApiResponseState.Error;
        this.testUnitType$.next(DiagnosticTestUnitType.ODU_AND_IDU);

        if (errorName === ApiErrorName.DisconnectUserEnded) {
            this.toastService.toasts$.next([]);

            this.toastService.add({
                content: this.translate.instant('CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.TOAST.TEST_CANCELED_BY_DEVICE'),
                theme: 'error',
                closeable: true,
                autoClose: false,
                outletName: this.equipmentListToastOutlet
            });
        }
    }

    sortODU(a: KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>, b: KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>) {
        return a.value.oduDisplayOrder && b.value.oduDisplayOrder ? a.value.oduDisplayOrder - b.value.oduDisplayOrder : -1;
    }

    sortIDU(a: KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>, b: KeyValue<keyof SystemDiagnosticsParamConfig, DiagnosticsConfigurations>) {
        return a.value.iduDisplayOrder && b.value.iduDisplayOrder ? a.value.iduDisplayOrder - b.value.iduDisplayOrder : -1;
    }

    private getSystemHeatType(equipments: DiagnosticEquipments[]): EquipmentHeatType {
        return equipments.some((item) => item.equipmentName === EquipmentHeatType.FURNACE)
            ? EquipmentHeatType.FURNACE
            : EquipmentHeatType.FAN_COIL;
    }

    private formatDiagnosticConfig(systemHeatType: EquipmentHeatType, config: SystemDiagnosticsExtendedParamConfig): SystemDiagnosticsParamConfig {
        const { fanCoilBlowerRpm, fanCoilDeltaT, furnaceBlowerRpm, furnaceDeltaT, ...otherParams } = config;

        const formattedDiagnosticConfig = (systemHeatType === EquipmentHeatType.FURNACE)
            ? {
                ...otherParams,
                ...{
                    blowerRpm: {
                        ...furnaceBlowerRpm,
                        ...SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS['furnaceBlowerRpm']
                    },
                    deltaT: {
                        ...furnaceDeltaT,
                        ...SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS['furnaceDeltaT']
                    }
                }
            }
            : {
                ...otherParams,
                ...{
                    blowerRpm: {
                        ...fanCoilBlowerRpm,
                        ...SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS['fanCoilBlowerRpm']
                    },
                    deltaT: {
                        ...fanCoilDeltaT,
                        ...SYSYEM_DIAGNOSTICS_OUT_OF_RANGE_PARAMS['fanCoilDeltaT']
                    }
                }
            };

        return formattedDiagnosticConfig;
    }

    private formatDisplayValue(data: DiagnosticsConfigurations) {
        let displayVal: number | string | undefined = '--';

        if (this.isNumeric(data.currentValue)) {
            switch (data.unit) {
                case DiagnosticParameterUnit.THERMAL:
                    displayVal = this.numberSuffixPipe.transform(data.currentValue, CharSymbol.Fahrenheit);
                    break;
                case DiagnosticParameterUnit.ROTATION:
                case DiagnosticParameterUnit.PRESSURE:
                    displayVal = `${data.currentValue} ${data.unit}`;
                    break;
                default:
                    displayVal = data.currentValue;
                    break;
            }
        }

        return displayVal;
    }

    private showToast(msg: string) {
        this.toastService.removeAll();
        this.toastService.add({
            bgColor: '#F8F8F8',
            content: msg,
            theme: 'error',
            closeable: true,
            autoClose: true,
            outletName: this.diagnosticsToastOutlet
        });
    }

    private isNumeric(num: number | undefined) {
        return !isNaN(num as number);
    }
}

