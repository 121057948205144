<ng-template [ngIf]="!isLoading" [ngIfElse]="spinner">
    <section class="hvac-program-admin-container">
        <div class="hvac-program-header">
            <h1 class="hvac-h3">
                {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_INFORMATION' | translate }}
            </h1>
            <p class="hvac-p-sm">
                <abbr title="required" class="hvac-program-header-required">*</abbr>
                {{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" | translate }}
            </p>
        </div>
        <div class="hvac-program-admin-form" *ngIf="accountAdminProgramService.programAdminData">
            <form [formGroup]="programCreateForm">
                <div class="hvac-row">
                    <div class="hvac-col-xs-12 hvac-col-lg-6">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_NAME" | translate }}
                            </div>
                            <utc-input  class="hvac-col-xs-12 hvac-col-lg-8"
                                [error]="!isProgramNameFieldValid && !isDuplicateProgramName"
                                [errorMessage]="programNameFieldErrorMessage"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_NAME' | translate" [hideLabel]="false"
                                shadow="none" size="small"
                                [inputControl]="programCreateForm.controls.name" type="text"
                                [max]="maxInputLimitProgramNameField">
                            </utc-input>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE" | translate }}
                            </div>
                            <utc-input class="hvac-col-xs-12 hvac-col-lg-8"
                                [error]="programCreateForm.controls.code.dirty && !programCreateForm.controls.code.valid" 
                                [errorMessage]="programCreateForm.controls.code.hasError('specialCharProhibitError') ? ('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE_SPECIAL_CHAR_PROHIBIT' | translate) : 
                                ('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE_LENGTH_ERROR' | translate)"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_CODE_XXXX' | translate"
                                shadow="none" size="small"
                                [hideLabel]="false" [inputControl]="programCreateForm.controls.code" type="text">
                            </utc-input>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.PROGRAM_PERIOD" | translate }}
                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-8">
                                <hvac-date-range-picker 
                                    [startDate]="todayDate"
                                    [endDate]="endOfYearDate"
                                    [error]="programPeriodErrorValidationResult.genericErrorMessage"
                                    (onSelection)="setProgramDate({ startDate: $event.startDate, endDate: $event.endDate })"
                                    [theme]="'retro'">
                                </hvac-date-range-picker>
                            </div>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_PERIOD" | translate }}
                            </div>
                            <hvac-date-range-picker class="hvac-col-xs-12 hvac-col-lg-8"
                                [startDate]="todayDate"
                                [endDate]="endOfYearDate"
                                (onSelection)="setEnrollmentDate({ startDate: $event.startDate, endDate: $event.endDate })"
                                [error]="enrollmentPeriodErrorValidationResult.genericErrorMessage"
                                [theme]="'retro'">
                            </hvac-date-range-picker>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.DESCRIPTION" | translate }}
                            </div>
                            <utc-textarea class="hvac-col-xs-12 hvac-col-lg-8 hvac-distributor-comments-input" shadow="none" 
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_DESCRIPTION' | translate"
                                [inputControl]="programCreateForm.controls.description"
                                [maxLength]="maxProgramDescriptionLimit"
                                ></utc-textarea>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.VERTICAL" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.SELECT_VERTICAL' | translate"
                                [error]="( verticalError$ | async ) || programCreateForm.controls.vertical.value[0]?.value?.length === 0 || programCreateForm.controls.vertical.dirty && !programCreateForm.controls.vertical.valid"
                                shadow="none" [group]="programCreateForm" control="vertical" size="small"
                                [options]="accountAdminProgramService.programVerticalOptions">
                            </utc-select>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.BRANDS" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8" shadow="none" size="small" [group]="programCreateForm"
                                [error]="( brandsError$ | async )|| programCreateForm.controls.brands.value[0]?.value?.length === 0 || programCreateForm.controls.brands.dirty && !programCreateForm.controls.brands.valid"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.SELECT_BRAND' | translate" control="brands"
                                [options]="(brandOptionData$ | async) || []">
                            </utc-select>
                        </div>
                    </div>
                    <div class="hvac-col-xs-12 hvac-col-lg-6">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.PROGRAM_MANAGER" | translate }}
                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-8">
                                <div class="hvac-program-manager-container">
                                    <hvac-chip
                                        *ngFor="let programManager of accountAdminProgramService.programAdminData?.programDetails?.programManagers" [text]="programManager.firstName + ' ' + programManager.lastName" [chipId]="programManager.userId"  (onChipClose)="accountAdminProgramService.removeManager($event)"></hvac-chip>
                                </div>
                                <button hvacButton="text" (click)="accountAdminProgramService.addManagerPopUp()"
                                    class="hvac-program-add-manager">{{
                                    'ACCOUNT_ADMIN.PROGRAMS.ADD_PROGRAM_MANAGER' | translate }}</button>
                            </div>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ELIGIBILITY_CRITERIA" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [error]="( eligibilityCriteraError$ | async ) || programCreateForm.controls.eligibilityCriteria.value[0]?.value?.length === 0 || programCreateForm.controls.eligibilityCriteria.dirty && !programCreateForm.controls.eligibilityCriteria.valid"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.SELECT_ELIGIBILTY_CRITERIA' | translate" 
                                shadow="none" size="small" [group]="programCreateForm" control="eligibilityCriteria"
                                [options]="accountAdminProgramService.programAdminData.criteria || []">
                            </utc-select>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_LEVEL" | translate }}
                            </div>
                            <utc-checkbox class="hvac-col-xs-12 hvac-col-lg-8"
                                [inputId]="'enrollmentLevel'" [value]="'enrollmentLevel'"
                                [inputControl]="programCreateForm.controls.enrollmentLevel"
                                [inputName]="'enrollmentLevel'"> {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR_SUSPEND" | translate
                                }}
                            </utc-checkbox>
                        </div>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR" | translate }}
                            </div>
                            <hvac-date-range-picker class="hvac-col-xs-12 hvac-col-lg-8"
                                [startDate]="todayDate"
                                [endDate]="endOfYearDate"
                                (onSelection)="setLocatorDate({ startDate: $event.startDate, endDate: $event.endDate })"
                                [error]="locatorPeriodErrorValidationResult.genericErrorMessage"
                                [startDateFieldError]="locatorPeriodErrorValidationResult.startDateFieldErrorMessage"
                                [endDateFieldError]="locatorPeriodErrorValidationResult.endDateFieldErrorMessage"
                                [disableLastValidDateRestore]="true"
                                [fieldNameOrId]="locatorPeriodErrorValidationResult.fieldName"
                                [theme]="'retro'">
                            </hvac-date-range-picker>
                            <p *ngIf="!isLocatorDateSet" class="hvac-locator-date-error">{{ "ACCOUNT_ADMIN.PROGRAMS.ERROR.LOCATOR_EMPTY_DATE_ERROR" | translate }}</p>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.LOCATOR_ICON" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                shadow="none" size="small" [group]="programCreateForm" control="locatorIcon"
                                [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.SELECT_ICON' | translate" 
                                [options]="accountAdminProgramService.programAdminData.locatorIcons || []">
                            </utc-select>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENTITLEMENT_WORKFLOW" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                shadow="none" size="small" [group]="programCreateForm" control="enrollmentWorkflow"
                                [options]="accountAdminProgramService.programEnrollmentWorkflowOptions">
                            </utc-select>
                        </div>
                        <div *ngIf="(accountAdminProgramService.isEnrollmentFormVisible$ | async); else massEnrollment"
                            class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label hvac-required">
                                {{ "ACCOUNT_ADMIN.PROGRAMS.ENTITLEMENT_FORM" | translate }}
                            </div>
                            <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                [error]="programCreateForm.controls.enrollmentForm.dirty && !programCreateForm.controls.enrollmentForm.valid"
                                shadow="none" size="small" [group]="programCreateForm" control="enrollmentForm"
                                [options]="accountAdminProgramService.programEnrollmentFormOptions">
                            </utc-select>
                        </div>
                        <ng-template #massEnrollment>
                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                    {{ "ACCOUNT_ADMIN.PROGRAMS.MASS_ENROLLMENT" | translate }}
                                </div>
                                <utc-checkbox class="hvac-col-xs-12 hvac-col-lg-8"
                                    [inputId]="'massEnrollFl'" [value]="'massEnrollFl'"
                                    [inputControl]="programCreateForm.controls.massEnrollFl"
                                    [inputName]="'massEnrollFl'">
                                </utc-checkbox>
                            </div>
                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                    {{ "ACCOUNT_ADMIN.PROGRAMS.FINAL_APPROVED_BY" | translate }}
                                </div>
                                <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                    shadow="none" size="small" [group]="programCreateForm" control="finalApprovedBy"
                                    [options]="accountAdminProgramService.programFinalApprover">
                                </utc-select>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </form>
        </div>
        <div class="hvac-program-edit-footer">
            <button [hvacButton]="'default'"
            (click)="onCancel()">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
            </button>
            <button [hvacButton]="'primary'" [disabled]="!programCreateForm.valid || !areAllMandatoryFieldsFilled() || isRequesting || areDateFieldsInError || !isLocatorDateSet"
                (click)="onSubmit(programCreateForm)">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.SAVE' | translate }}</span>
            </button>
        </div>
        <section class="hvac-program-manager-model-container">
            <hvac-program-manager [isModalVisible]="accountAdminProgramService.isModalVisible" (isModalVisibleChange)="accountAdminProgramService.modalVisibilityChanged($event)" (onManagerListChange)="accountAdminProgramService.onManagerListChange($event)" [programManagers]="programManagers || []" [assignedManagers]="accountAdminProgramService.programAdminData.programDetails.programManagers || []"></hvac-program-manager>
        </section>
    </section>
</ng-template>
<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>