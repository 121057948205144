<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
        <div class="hvac-cp-stats">
            <hvac-connected-portal-stats [statsData]="(stats$ | async)!"
                [isLoading]="isLoadingList.stats"></hvac-connected-portal-stats>
        </div>
    </div>
    <div bottom>
        <section class="hvac-cp-card">
            <div class="hvac-cp-search">
                <hvac-search-bar [label]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.DISTRIBUTOR_SEARCH' | translate"
                    [queryControl]="queryControl"
                    [placeholder]="'CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.SEARCH_PLACEHOLDER' | translate"
                    (onSearchSubmit)="handleSearchSubmit($event)"></hvac-search-bar>
            </div>
        </section>

        <section class="hvac-cp-card">
            <h3 class="hvac-reports-header hvac-h3">{{ 'CONNECTED_PORTAL.FACTORY_DASHBOARD.REPORTS' | translate }}</h3>
            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <hvac-factory-connected-control-report ></hvac-factory-connected-control-report>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <hvac-customer-logins-report></hvac-customer-logins-report>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6">
                    <hvac-fad-report></hvac-fad-report>
                </div>
            </div>
        </section>
    </div>
</hvac-connected-portal-layout-container>
