import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Option } from 'common/components/select/select.component';
import { OpticleanService } from 'private/app/services/opticlean/opticlean.service';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';

type USObjectType = {
    [key: string]: { name: string, value: string }[]
};

@Component({
    selector: 'hvac-opti-clean-sizing-selection-tool',
    templateUrl: './opti-clean-sizing-selection-tool.component.html',
    styleUrls: ['./opti-clean-sizing-selection-tool.component.scss']
})
export class OptiCleanSizingSelectionToolComponent implements OnInit {
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translate.instant('OPTICLEAN.OPTICLEAN') },
        breadCrumbs: [
            {
                title: this.translate.instant('OPTICLEAN.SUPPORT'),
                url: '/marketing'
            },
            {
                title: this.translate.instant('OPTICLEAN.OPTICLEAN'),
                url: '/sales-tools/opticlean-tool'
            }
        ]
    };

    USObject: USObjectType = {
        FN1AAF005000: [
            {
                name: 'Motor Tap - 1',
                value: '375'
            },
            {
                name: 'Motor Tap - 2',
                value: '425'
            },
            {
                name: 'Motor Tap - 3',
                value: '500'
            },
            {
                name: 'Motor Tap - 4',
                value: '575'
            },
            {
                name: 'Motor Tap - 5',
                value: '600'
            }
        ],

        FN1AAF006000: [
            {
                name: 'Switch Setting - Low',
                value: '200'
            },
            {
                name: 'Motor Tap - 2',
                value: '300'
            },
            {
                name: 'Switch Setting - Medium',
                value: '400'
            },
            {
                name: 'Motor Tap - 4',
                value: '500'
            },
            {
                name: 'Switch Setting - High',
                value: '600'
            }
        ],

        FN1AAF015000: [
            {
                name: 'Switch Setting - Low',
                value: '500'
            },
            {
                name: 'Motor Tap - 2',
                value: '800'
            },
            {
                name: 'Switch Setting - Medium',
                value: '1000'
            },
            {
                name: 'Motor Tap - 4',
                value: '1200'
            },
            {
                name: 'Switch Setting - High',
                value: '1500'
            }
        ]
    }

    showRoomAreaError = false;
    showRoomHeightError = false;
    showAchError = false;
    showUnitQuantityError = false;
    errorMessage = null;
    roomAreaErrorMessage = null;
    roomHeightErrorMessage = null;
    achErrorMessage = null;
    unitQuantityErrorMessage = null;

    public motorSpeedValues: Option[] = [];
    public unitQtyChecked: boolean = false;
    public unitQtyChecked$: BehaviorSubject<boolean> = new BehaviorSubject(true as boolean);
    public achChecked$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);

    public cfm: string ='';
    public achValue:string = '';
    public roomHeight:string = '';
    public roomArea:string = '';
    public qtyResult:Number = 0;
    public achResult:Number = 0;
    public qtyResultText:string = '';
    public achResultText:string = '';
    public unitQuantity: string = '';
    public unitSizeValues$: BehaviorSubject<Option[]>= new BehaviorSubject<Option[]>([]);

    constructor(public opticleanService: OpticleanService,
        private cdref: ChangeDetectorRef,
        private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.opticleanService.opticleanForm.controls.cfm.setValue('500');
        this.opticleanService.opticleanForm.controls.achValue.setValue('6');
        this.opticleanService.opticleanForm.controls.unitQuantity.setValue('1');
        this.opticleanService.opticleanForm.controls.roomHeight.setValue('10');
        this.opticleanService.opticleanForm.controls.roomArea.setValue('500');
        this.unitSizeValues$ = new BehaviorSubject<Option[]>(
            Object.keys(this.USObject).map((key) => ({
                name: key,
                value: key
            }))
        );
    }

    updateRadioValue(checkedValue: boolean) {
        this.qtyResultText = '';
        this.achResultText = '';
        this.achResult = 0;
        this.qtyResult = 0;
        if (checkedValue) {
            this.unitQtyChecked$.next(true);
            this.achChecked$.next(false);
        }
        else {
            this.unitQtyChecked$.next(false);
            this.achChecked$.next(true);
        }
    }

    ngAfterViewInit() {
        this.unitSizeValues$.subscribe((options: Option[]) => {
            this.opticleanService.opticleanForm.controls.unitSizeValue.patchValue(options[0].name);
            this.motorSpeedValueChange(options[0].name);
            this.cdref.detectChanges();
        });
    }

    unitSizeValueChange(event: Option[]) {
        if (!event[0]?.value) {
            return;
        }
        this.motorSpeedValueChange(event[0].value);
    }

    motorSpeedValueChange(selectItem:string) {
        const tempMotorSpeedArray = Object.entries(this.USObject).filter((key) => key[0].includes(selectItem));

        const restructuredArray = Object.entries(tempMotorSpeedArray[0][1]).map(([key, value]) => ({
            name: key,
            value: value
        }));
        const tempFormatArray: {name: string, value: string}[] = restructuredArray.map((item) => item.value);
        this.motorSpeedValues = [...tempFormatArray];
        this.opticleanService.opticleanForm.controls.motorSpeedValue.patchValue(this.motorSpeedValues[0].name);
        this.opticleanService.opticleanForm.controls.cfm.patchValue(this.motorSpeedValues[0].value);
    }


    motorValueChange(event: Option[]) {
        this.opticleanService.opticleanForm.controls.cfm.patchValue(event[0]?.value);
    }

    calculate() {
        this.cfm = this.opticleanService.opticleanForm.controls.cfm.value;
        this.achValue = this.opticleanService.opticleanForm.controls.achValue.value;
        this.roomHeight = this.opticleanService.opticleanForm.controls.roomHeight.value;
        this.roomArea = this.opticleanService.opticleanForm.controls.roomArea.value;
        this.unitQuantity = this.opticleanService.opticleanForm.controls.unitQuantity.value;

        this.errorMessage = this.translate.instant('OPTICLEAN.MINIMUM_VALUE_ERROR');

        if (Number(this.achValue) < 1) {
            this.showAchError = true;
        }
        else {
            this.showAchError = false;
        }
        if (Number(this.unitQuantity) < 1) {
            this.showUnitQuantityError = true;
        }
        else {
            this.showUnitQuantityError = false;
        }
        if (Number(this.roomHeight) < 1) {
            this.showRoomHeightError = true;
        }
        else {
            this.showRoomHeightError = false;
        }
        if (Number(this.roomArea) < 1) {
            this.showRoomAreaError = true;
        }
        else {
            this.showRoomAreaError = false;
        }


        if (!this.showAchError && !this.showUnitQuantityError && !this.showRoomHeightError && !this.showRoomAreaError) {
            this.qtyResult = Math.ceil((Number(this.achValue) * Number(this.roomHeight) * Number(this.roomArea)) / Number(this.cfm) / 60);
            this.qtyResultText = this.translate.instant('OPTICLEAN.QTY_RESULT_TEXT').replace('<value>', this.qtyResult);

            this.achResult = (Number(this.cfm) * 60 / (Number(this.roomHeight) * Number(this.roomArea)) * Number(this.unitQuantity));
            this.achResultText = `${this.translate.instant('OPTICLEAN.ACH_RESULT_TEXT')} ${this.achResult}`;
        }
        else {
            this.qtyResult = 0;
            this.achResult = 0;
        }
    }
}
