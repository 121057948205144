import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { Option } from 'common/components/select/select.component';
import { CountryOption, Program, ProgramSearchPayload, UpdateCriteriaData } from 'private/app/models/account-admin-program.model';
import { DynamicNestedFilterService } from 'common/services/dynamic-nested-filter.service';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { ToastService } from 'common/services/toast.service';
import { Router } from '@angular/router';
import { AppConstants } from 'common/app-constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { HvacAutoCompleteEvent } from 'common/components/hvac-autocomplete/hvac-auto-complete.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EligibilityCriteriaQueryConfig } from '../edit-eligibility-criteria/eligibilityCriteriaQueryConfig.service';
import { AccountAdminEligibilityCriteriaService } from 'private/app/services/account-admin/account-admin-eligibility-criteria.service';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { QueryBuilderConfig, RuleSet } from 'common/libs/angular2-query-builder/query-builder/query-builder.interfaces';

@Component({
    selector: 'hvac-create-eligibility-criteria',
    templateUrl: './create-eligibility-criteria.component.html',
    styleUrls: ['./create-eligibility-criteria.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateEligibilityCriteriaComponent implements OnInit, OnDestroy {
    public fieldCriteriaForm: UntypedFormGroup = new UntypedFormGroup({ criteriaName: new UntypedFormControl('', [Validators.required]) });
    public breadCrumbData: ContentHeadingData;
    public isLoading: boolean;
    public displayInputField: boolean = true;
    public customerCompanyForm: UntypedFormGroup = new UntypedFormGroup({ customerCompanyId: new UntypedFormControl() });
    public distributorCompanyForm: UntypedFormGroup = new UntypedFormGroup({ distributorCompanyId: new UntypedFormControl() });
    public queryBuilderConfigValues: QueryBuilderConfig = this.eligibilityCriteriaQueryConfigService.QueryBuilderConfigValues as QueryBuilderConfig;
    public queryDataToDisplay: RuleSet;
    public modifiedQueryDataToDisplay: RuleSet;
    public selectedCountryId: string | null = 'US';
    public optionListForState: CountryOption[] = [];
    public bannerType = 'CreateEligibilityCriteria';
    public programNameData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public programNameQuerySubject$: BehaviorSubject<string> = new BehaviorSubject('');
    public programNames: Program[] = [];
    public isLoadMoreData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public initialPage = 0;
    public autoComplete$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });
    public totalCount = 0;
    public isCustomerCompanyIDFiltered: boolean = false;
    public isDistributorCompanyIDFiltered: boolean = false;
    public currentPageAfterFilter: number = 1;
    public totalPagesAfterFilter: number = 1;
    public maxInputLimitNameCriteriaField: number = 60;
    ngOnDestroy$ = new Subject();
    public companyFileUploadCleanUpFuncRef: () => void;
    private criteriaDetails: UpdateCriteriaData = this.eligibilityCriteriaQueryConfigService.criteriaDetails;
    private distributorsFileUploadCleanUpFuncRef: () => void;
    constructor(
        public translate: TranslateService,
        public eligibilityCriteriaService: AccountAdminEligibilityCriteriaService,
        public eligibilityCriteriaQueryConfigService: EligibilityCriteriaQueryConfig,
        public accountAdminProgramService: AccountAdminProgramService,
        public queryStringService: DynamicNestedFilterService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private toastService: ToastService,
        private languageTranslationService: LanguageTranslationService,
        private router: Router,
        private commonUtils: CommonUtilsService
    ) {
        this.assignComapniesFileUploadCleanUpFuncRef = this.assignComapniesFileUploadCleanUpFuncRef.bind(this);
        this.assignDistributorsFileUploadCleanUpFuncRef = this.assignDistributorsFileUploadCleanUpFuncRef.bind(this);
    }

    ngOnInit(): void {
        // SUBSCRIPTIONS
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = {
                Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.CREATE_ELIGIBILITY_CRITERIA') },
                breadCrumbs: [
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                        url: '/admin'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                        url: '/Admin/Program'
                    }
                ]
            };
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
        });
        this.commonUtils.getCountriesEntity().subscribe((countryList) => {
            this.queryBuilderConfigValues.fields.Country.options = countryList.map((country) => ({
                name: country.name,
                value: country.countryId
            }));
            this.optionListForState = countryList.map((country): CountryOption => ({
                code: country.code,
                value: country.countryId.toString()
            }));
        });
        this.getStateList(this.selectedCountryId!);
    }

    getStateList(selectedCountry: string) {
        this.commonUtils.getStateEntityByCountry(selectedCountry).subscribe((stateList) => {
            this.queryBuilderConfigValues.fields.StateProvince.options = stateList.map((state) => ({
                name: state.name,
                value: state.stateId
            }));
        });
    }

    filterCustomerID(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input.value) {
            this.isCustomerCompanyIDFiltered = true;
            this.eligibilityCriteriaService.customerCompanySearchFilterData$.next(
                this.eligibilityCriteriaService.customerCompanyfilteredData$.value.filter((res) => res[0].trim().localeCompare(input.value) === 0)
            );
        }
        else {
            this.isCustomerCompanyIDFiltered = false;
            this.eligibilityCriteriaService.customerCompanySearchFilterData$.next(this.eligibilityCriteriaService.customerCompanyList$.value);
        }
    }

    filterDistributorID(event: Event) {
        const input = <HTMLInputElement>event.target;
        if (input.value) {
            this.isDistributorCompanyIDFiltered = true;
            this.eligibilityCriteriaService.distributorCompanySearchFilterData$.next(
                this.eligibilityCriteriaService.distributorCompanyfilteredData$.value.filter((res) => res[0].trim().localeCompare(input.value) === 0)
            );
        }
        else {
            this.isDistributorCompanyIDFiltered = false;
            this.eligibilityCriteriaService.distributorCompanySearchFilterData$.next(this.eligibilityCriteriaService.distributorCompanyList$.value);
        }
    }


    modifyQueryData($event: RuleSet) {
        this.modifiedQueryDataToDisplay = $event;
        this.selectedCountryId = JSON.stringify($event).split('"field":"Country","operator":"Equals","value":')[1]?.split('}')[0];
        this.selectedCountryId = (this.selectedCountryId) ? JSON.parse(this.selectedCountryId) : 'US';
        const selectedCountry = this.optionListForState.filter((entry) => String(entry.value) === this.selectedCountryId)[0]?.code || null;
        if (selectedCountry) {
            this.getStateList(selectedCountry);
        }
    }

    public programName($event: string) {
        this.programNameData$.subscribe((_status) => this.autoComplete$.next({
            loading: false,
            loadMore: this.isLoadMoreData$.value || false
        }));

        this.programNameQuerySubject$.next($event);
        this.programNameQuerySubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(AppConstants.defaultTypeAheadDebounceTime)
        ).subscribe((_searchTextValue) => {
            this.programNameData$.next([]);

            this.autoComplete$.next({ loading: true });
            this.getProgramListByQuery($event, this.programNameData$, false);
        });
    }

    programNameWithPagination() {
        this.autoComplete$.next({ loading: true });
        this.getProgramListByQuery(this.programNameQuerySubject$.value, this.programNameData$, true);
    }

    public getProgramListByQuery(programName: string, programNameData$: BehaviorSubject<Option[]>, isLoadMore: boolean) {
        this.initialPage = isLoadMore ? this.initialPage + 1 : this.initialPage;
        const criteriaDataForPages: ProgramSearchPayload = <ProgramSearchPayload>({
            criteria: {
                programName: programName,
                programCode: '',
                programStatus: '',
                brandName: '',
                eligibleCriteriaName: ''
            },
            pageParams: {
                page: this.initialPage,
                pageSize: 50
            },
            sortFields: [],
            sortParams: [],
            determineTotalCount: true
        });

        this.accountAdminApiService.getAccountAdminProgramsBySearch((criteriaDataForPages)).subscribe((programResults) => {
            this.totalCount = programResults?.totalCount;

            if (programNameData$.value?.length === 0) {
                this.programNames = this.eligibilityCriteriaService.mapProgramDetails(programResults.data);
                this.paginationStatus();
                programNameData$.next(this.programNames.map((program: Program) => ({
                    name: program.programName,
                    value: program.programId as unknown as string
                })));

                return;
            }

            this.programNames = [...this.programNames, ...this.eligibilityCriteriaService.mapProgramDetails(programResults.data)];
            this.paginationStatus();
            programNameData$.next(this.programNames.map((program: Program) => ({
                name: program.programName,
                value: program.programId as unknown as string
            })));
        });
    }

    public paginationStatus() {
        this.isLoadMoreData$.next(this.programNames?.length < this.totalCount);
    }


    onSubmit() {
        this.isLoading = true;
        this.criteriaDetails.programIds = [];
        this.criteriaDetails.criteriaName = this.fieldCriteriaForm.controls.criteriaName.value;
        this.criteriaDetails.selectedFields = this.queryStringService.getSelectedFields(this.modifiedQueryDataToDisplay);
        this.criteriaDetails.criteriaFilter = this.queryStringService.basicRulesetToQueryString(this.modifiedQueryDataToDisplay);
        this.criteriaDetails.dealerCompanyIds = this.eligibilityCriteriaService.customerCompanyfilteredData$.value.flat(1).map(Number);
        this.criteriaDetails.distributorCompanyIds = this.eligibilityCriteriaService.distributorCompanyfilteredData$.value.flat(1).map(Number);
        if (JSON.stringify(this.modifiedQueryDataToDisplay).includes('ProgramId')) {
            const programId = JSON.stringify(this.modifiedQueryDataToDisplay).split('"field":"ProgramId","operator":"Equals","value":')[1]?.split('}')[0];
            this.criteriaDetails.programIds.push(Number(JSON.parse(programId)));
        }
        this.accountAdminApiService.postCreateEligibilityCriteria(this.criteriaDetails).subscribe((response) => {
            this.accountAdminProgramService.smoothScrollToTop();
            if (response.id) {
                this.isLoading = false;
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_CREATE_SUCCESS', { criteriaName: response.name }),
                    theme: 'success',
                    id: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
                setTimeout(() => {
                    this.router.navigate([`/Admin/Program/search-program/edit-eligibility-criteria`], {
                        queryParams: {
                            criteriaName: response.name,
                            criteriaId: response.id
                        }
                    });

                    return;
                }, AppConstants.timeOutNavigation);
            }
        },
        (err) => {
            this.isLoading = false;
            this.accountAdminProgramService.smoothScrollToTop();
            this.toastService.add({
                content: err.error.message,
                theme: 'error',
                id: this.bannerType,
                closeable: true,
                autoClose: true
            });
        });
    }

    onCancel() {
        this.fieldCriteriaForm.controls.criteriaName.setValue('');
        this.fieldCriteriaForm.controls.criteriaName.setErrors(null);
        this.queryDataToDisplay = this.eligibilityCriteriaQueryConfigService.emptyQueryData;
        this.eligibilityCriteriaService.getCustomerIdValue([]);
        this.eligibilityCriteriaService.getDistributorIdValue([]);
        this.accountAdminProgramService.smoothScrollToTop();
        this.eligibilityCriteriaService.isCustomerTableVisible = false;
        this.eligibilityCriteriaService.isDistributorTableVisible = false;
        this.companyFileUploadCleanUpFuncRef();
        this.distributorsFileUploadCleanUpFuncRef();
    }

    assignComapniesFileUploadCleanUpFuncRef(cleanUpFunc: () => void) {
        this.companyFileUploadCleanUpFuncRef = cleanUpFunc;
    }

    assignDistributorsFileUploadCleanUpFuncRef(cleanUpFunc: () => void) {
        this.distributorsFileUploadCleanUpFuncRef = cleanUpFunc;
    }

    isRulesetObject(obj: unknown): obj is RuleSet {
        // eslint-disable-next-line no-prototype-builtins
        return Object(obj).hasOwnProperty('rules');
    }

    hasEmptyRuleset(ruleset: RuleSet): boolean {
        if (!ruleset || ruleset?.rules?.length === 0) {
            return true;
        }

        let isAnyRulesetEmpty = false;

        for (let i = 0; i < (ruleset?.rules?.length || 0); i++) {
            if (isAnyRulesetEmpty) {
                break;
            }

            const rule = ruleset?.rules[i];

            if (this.isRulesetObject(rule)) {
                isAnyRulesetEmpty = this.hasEmptyRuleset(rule);
            }
        }

        return isAnyRulesetEmpty;
    }

    ngOnDestroy(): void {
        this.eligibilityCriteriaService.ngOnDestroy();
    }
}
