import { ConnectedCustomer } from './connected-customer.model';
import { ConnectedProduct } from './connected-product.model';
import { ConnectedProperty } from './connected-property.model';
import { DefaultCollectionResponse } from './default-collection-response.model';

export enum AlertSeverity {
    INFORMATIONAL = 'Informational',
    MODERATE = 'Moderate',
    CRITICAL = 'Critical'
}
export enum AlertStatusFilter {
    ALL='All',
    ACTIVE = 'Active',
    HISTORICAL = 'Historical',
}
export type AlertSeverityFilter = AlertSeverity | 'All';

export interface AlertError {
    code:string
    description: string;
    type: string;
    severity: AlertSeverity;
    troubleshootUri: string;
    isActive:boolean
}

export interface Alert {
    id: string;
    dateTime: string;
    error: AlertError;
    property: ConnectedProperty;
    customer: ConnectedCustomer;
    product: ConnectedProduct;
    source: string;
    occurrenceCount: number;
    dealerId: string,
    wallControlSerialNo: string
}

export type ProductAlert = Pick<Alert,
'id'
| 'dateTime'
| 'product'
| 'error'
| 'source'
| 'occurrenceCount'
| 'dealerId'
| 'wallControlSerialNo'
>;

export interface ProductAlertResponse extends DefaultCollectionResponse {
    data: ProductAlert[];
}

export type DealerAlert = Pick<Alert,
'id'
| 'dateTime'
| 'error'
| 'customer'
| 'product'
| 'property'
| 'dealerId'
| 'wallControlSerialNo'
>

export interface DealerAlertResponse extends DefaultCollectionResponse {
    data: DealerAlert[];
}

export type SystemAlert = ProductAlert;

export interface SystemAlertsResponse extends DefaultCollectionResponse {
    data: SystemAlert[]
}
