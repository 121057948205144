import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'common/environments/environment';

const uri = environment.api.connectedPortal.graphql;
const mockUri = environment.mocks?.connectedPortal.graphql.url;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getApolloBaseOptions = (httpLink: HttpLink, apiUri: string): ApolloClientOptions<any> => ({
    link: httpLink.create({ uri: apiUri }),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
        watchQuery: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'no-cache' }
    }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return getApolloBaseOptions(httpLink, uri);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApolloMock(httpLink: HttpLink): Record<string, ApolloClientOptions<any>> {
    return {
        mock: {
            name: 'mock',
            ...getApolloBaseOptions(httpLink, mockUri)
        }
    };
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink]
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApolloMock,
            deps: [HttpLink]
        }
    ]
})
export class GraphQLModule {}
