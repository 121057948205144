import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentHeadingData } from './../../../../common/models/content-heading';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { UserAdminService } from 'private/app/services/account-admin/user/user-admin.service';
import { CompanyResultDataResponse } from 'private/app/models/account-admin-search-results';
import { CreateUserDetails, UserDetails } from 'private/app/models/user.model';
import { UserApiService } from 'private/app/services/account-admin/user/user-api.service';
import { ToastService, ToastTheme } from 'common/services/toast.service';
import { Router } from '@angular/router';

type SearchCompany = {
    userCreateStep: string,
    company: CompanyResultDataResponse
}
@Component({
    selector: 'hvac-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public ngOnDestroy$ = new Subject();
  public isCompanyListDataAvailable: boolean = false;
  public selectedCompanyId: string;
  public selectedCompanyName: string;
  public company: CompanyResultDataResponse;
  public createUserOnStepNumber: string;
  public activeScreenName: string;
  public userDetails: UserDetails;
  public apiRequestActive = false;
  public isLoading: boolean = false;

  public breadCrumbData: ContentHeadingData = {
      Content: { title: this.translate.instant('ACCOUNT_ADMIN.CREATE_USER') },
      breadCrumbs: [
          {
              title: this.translate.instant('ACCOUNT_ADMIN.PARENT_1'),
              url: '/admin'
          },
          {
              title: this.translate.instant('ACCOUNT_ADMIN.PARENT_2'),
              url: '/admin/admin tools'
          }
      ]
  };

  public searchCompany : {
      userCreateStep: 'step2_registeredUsersInCompany',
      company: CompanyResultDataResponse
  }

  constructor(
      public translate: TranslateService,
      public accountAdminService: AccountAdminService,
      public userAdminService: UserAdminService,
      public userApiService: UserApiService,
      private router: Router,
      private readonly toastService: ToastService
  ) { }

  ngOnInit(): void {
      this.createUserOnStepNumber = 'step1_searchCompany';
      this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
  }

  onClickSearch() {
      this.isCompanyListDataAvailable = true;
  }

  getCreateUserActiveScreenName(): string {
      if (this.createUserOnStepNumber === 'step1_searchCompany') {
          this.activeScreenName = this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.STEP_1');
      }
      else if (this.createUserOnStepNumber === 'step2_registeredUsersInCompany') {
          this.activeScreenName = this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.STEP_2');
      }
      else if (this.createUserOnStepNumber === 'step3_userDetails') {
          this.activeScreenName = this.translate.instant('ACCOUNT_ADMIN.CREATE_USER_PAGE.STEP_3');
      }

      return this.activeScreenName;
  }

  getActiveScreenName(name: string) {
      this.activeScreenName = name;
  }

  onClickCreateUserChangePage(event : SearchCompany) {
      const { company } = event;

      if (company.hvacCompanyId && company.companyName) {
          this.selectedCompanyId = company.hvacCompanyId?.toString();
          this.selectedCompanyName = company.companyName;
      }
      this.company = company;
      this.userAdminService.smoothScrollToTop();
      this.createUserOnStepNumber = event.userCreateStep;
  }

  onContinueToStep2Click(event: string) {
      this.createUserOnStepNumber = event;
  }

  CreateUserDetails(event: UserDetails) {
      this.userDetails = event;
  }

  onClickClearSearch() {
      this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
  }

  userCreateDetailsSubmit(event : CreateUserDetails) {
      this.isLoading = true;
      this.apiRequestActive = true;
      let userID: string | undefined;
      this.userApiService.createOrRegisterUser(event).subscribe((response) => {
          this.isLoading = false;
          this.apiRequestActive = false;
          this.userAdminService.smoothScrollToTop();
          this.showToastBar('success', response.details);
          this.userAdminService.userEditForm.markAsPristine();
          userID = response.details.split(' : ').pop();
          this.router.navigate([`/Admin/Admin-Tools/account-admin.html/user-details/${userID}`]);
      }, (error) => {
          this.isLoading = false;
          this.apiRequestActive = false;
          this.userAdminService.smoothScrollToTop();
          this.showToastBar('error', error.error.message);
      });
  }

  ngOnDestroy() {
      this.accountAdminService.advancedSearchForm.reset(this.accountAdminService.advancedSearchFormDefault.value);
      this.ngOnDestroy$.next();
      this.ngOnDestroy$.complete();
  }

  private showToastBar(theme: ToastTheme, response: string) {
      this.toastService.add({
          content: response,
          theme: theme,
          id: 'createUser',
          closeable: true,
          autoClose: true
      });
  }
}
