import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BaseComponent } from 'common/components/base/base.component';
import { CSVExportService } from 'common/services/csv-export.service';
import { BrandTypes, BrandingState } from 'common/store/branding/branding.state';
import { FADReport } from 'private/app/models/factory.model';
import { ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { FAD_REPORT_MAX_MONTH_SELECTION } from 'private/app/views/connected-portal/constants';
import { distinctUntilChanged, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'hvac-fad-report',
    templateUrl: './fad-report.component.html',
    styleUrls: ['./fad-report.component.scss']
})
export class FadReportComponent extends BaseComponent implements OnInit {
    public data?: FADReport;
    public isLoading = false;
    public yearSelectOptions = this.chartConfigService.getMonthYearOptions(FAD_REPORT_MAX_MONTH_SELECTION);

    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;

    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);

    logoImage$ = this.store.select(BrandingState.themeBrand).pipe(
        distinctUntilChanged(),
        map((brand) => {
            if (brand === BrandTypes.Bryant) {
                return 'assets/images/bryant-fad-logo.png';
            }

            return 'assets/images/carrier-fad-logo.jpg';
        })
    );

    constructor(
        private chartConfigService: ChartConfigService,
        private factoryService: FactoryService,
        private csvExportService: CSVExportService,
        private store: Store
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.yearSelectFormControl.value) {
            this.getFilePath(this.yearSelectFormControl.value).subscribe(
                (res) => {
                    this.data = res.data;
                }
            );
        }

        this.yearSelectFormControl.valueChanges
            .pipe(
                takeUntil(this.ngOnDestroy$),
                switchMap((monthYear: string) => this.getFilePath(monthYear))
            ).subscribe((res) => {
                this.data = res.data;
            });
    }

    getFilePath(selectedMonthYear: string) {
        const [month, year] = selectedMonthYear.split('-');

        this.isLoading = true;

        return this.factoryService
            .queryFADReport(parseInt(month, 10), parseInt(year, 10))
            .pipe(
                takeUntil(this.ngOnDestroy$),
                finalize(() => {
                    this.isLoading = false;
                })
            );
    }

    handleRunReport() {
        return this.getFilePath(this.yearSelectFormControl.value)
            .pipe(
                tap((res) => {
                    if (res?.data?.filepath) {
                        const { filepath } = res.data;

                        this.csvExportService.downloadFile(filepath);
                    }
                })
            ).subscribe();
    }
}
