<div class="hvac-row">
    <div *ngIf="(isLoading$ | async); else content" class="hvac-content-loader">
        <utc-loading-spinner></utc-loading-spinner>
    </div>

    <ng-template #content>
        <div class="hvac-row" *ngIf="(wallControlDetails$ | async) as wallControlDetails" style="position: relative">
            <div *ngIf="dataSharing.systemConditions" class="hvac-col-xs-12 hvac-col-lg-8 hvac-cp-ni-system-conditions hvac-rhythm-3">
                <div class="hvac-row">
                    <div class="hvac-col-xs-6 hvac-col-md-12">
                        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS' | translate}}</h2>

                        <ng-container *ngIf="systemType">
                            <div class="hvac-row hvac-cp-ni-system-conditions-params">
                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_TEMP' | translate"
                                        [value]="wallControlDetails.indoorTemp | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_HUMIDITY' | translate"
                                        [value]="wallControlDetails.indoorHumidity | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Percent"></hvac-stats-widget>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                    <div class="hvac-col-xs-6 hvac-col-md-12">
                        <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_SETTINGS' | translate}}</h2>

                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1 hvac-cp-ecobee-system-reading">
                                <hvac-stats-widget class="hvac-cp-cool" [label]="'CONNECTED_PORTAL.WALL_CONTROL.COOL_TO' | translate" [value]="wallControlDetails.coolTo | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                            </div>

                            <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1 hvac-cp-ecobee-system-reading">
                                <hvac-stats-widget  class="hvac-cp-heat" [label]="'CONNECTED_PORTAL.WALL_CONTROL.HEAT_TO' | translate" [value]="wallControlDetails.heatTo | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                            </div>

                            <div class="hvac-col-xs-12 hvac-col-md"></div>
                        </div>
                    </div>

                    <div class="hvac-col-lg-12 hvac-cp-system-condition-warning" *ngIf="isAnySystemConditionOutOfRange">
                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg"></utc-svg> <div class="hvac-p-sm hvac-fw-rg hvac-cp-system-condition-warning-text">{{ 'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS_MESSAGES.PARAMETER_OUT_OF_RANGE' | translate }}</div>
                    </div>

                </div>
            </div>
        </div>
    </ng-template>
</div>
