import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { ManageDealerLocatorService } from 'private/app/services/manage-dealer-locator.service';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { DealerLocatorEnrollmentList, Ratings } from 'private/app/models/manage-dealer-locator';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastService } from 'common/services/toast.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BaseResponse } from 'common/models/account-status';

export interface ThirdPartyRatingData {
    source: string;
    id: string;
    url: string;
}

export interface ThirdPartyDynamicFormData {
    data: Ratings;
    controller: UntypedFormGroup
}

@Component({
    selector: 'app-third-party-ratings',
    templateUrl: './third-party-ratings.component.html',
    styleUrls: ['./third-party-ratings.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ThirdPartyRatingsComponent {
    public dealerLocatorListData: DealerLocatorEnrollmentList = {
        dealerLocatorEnrollments: [],
        availableZipCodes: [],
        assignedZipCodes: [],
        locatorRatings: [],
        companyId: '',
        isFADEnabled: false
    }

    public dynamicForm: UntypedFormGroup;
    public dealerLocatorArray: Ratings[] = [];
    public loading = true;
    public thirdPartyDynamicFormData: ThirdPartyDynamicFormData[] = [];
    public dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.SOURCE'),
            value: 'sourceName',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.ID'),
            value: 'companyId',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.URL'),
            value: 'sourceURL',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION'),
            value: 'action',
            order: null
        }
    ];

    isRatingAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private companyId: string;
    private ngOnDestroy$ = new Subject();
    private hvacpCompanyId: string;

    constructor(
        private translate: TranslateService,
        private dealerLocatorService: ManageDealerLocatorService,
        private toastService: ToastService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.dynamicForm = this.formBuilder.group({ thirdPartyRating: new UntypedFormArray([]) });
        this.dealerLocatorService.data$.pipe(skipWhile((response) => !response.companyId), takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            this.hvacpCompanyId = data.hvacpCompanyId || '';
            this.companyId = data.companyId;
            this.loading = false;
            this.thirdPartyDynamicFormData = [];
            this.dealerLocatorListData = data;
            this.loadThirdPartyRatingForm();
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    removeLink(rating: Ratings) {
        const index: number = this.dealerLocatorListData.locatorRatings.indexOf(rating);
        if (index !== -1) {
            this.dealerLocatorListData.locatorRatings.splice(index, 1);
            this.dealerLocatorService.deleteThirdPartyRating({
                hvacCompanyID: this.hvacpCompanyId,
                sourceName: rating.sourceName
            }).subscribe((response: BaseResponse) => {
                if (response.status === 'Success') {
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.THIRD_PARTY_RATING_REMOVE_SUCCESS_MESSAGE'),
                        theme: 'success',
                        id: 'DEALER_LOCATOR_POST_SUCCESS_MESSAGE',
                        closeable: true,
                        autoClose: true
                    });
                }
            });
        }

        this.loadThirdPartyRatingForm();
    }

    public saveAction() {
        const body = this.dealerLocatorService.getDealerLocatorPostRequestBody(this.dealerLocatorArray.filter((ratings) => Boolean(ratings.sourceURL)), this.dealerLocatorListData);
        this.dealerLocatorListData.locatorRatings = this.dealerLocatorArray;
        this.loading = true;
        this.dealerLocatorService.postDealerEnrollmentData(body).pipe(takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            this.loading = false;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            if (data.status === 'Success') {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_LOCATOR_POST_SUCCESS_MESSAGE'),
                    theme: 'success',
                    id: 'DEALER_LOCATOR_POST_SUCCESS_MESSAGE',
                    closeable: true,
                    autoClose: true
                });
            }
        });
        this.loadThirdPartyRatingForm();
    }

    private loadThirdPartyRatingForm() {
        this.thirdPartyDynamicFormData = [];
        this.dealerLocatorArray = this.generateThirdPartyRecords(this.dealerLocatorListData.locatorRatings);
        this.thirdPartyDynamicFormData = this.dealerLocatorArray.map((locatorData) => ({
            data: locatorData,
            controller: this.formBuilder.group({
                id: [''],
                url: ['', { emitEvent: true }],
                source: [locatorData.sourceName]
            })
        }));

        this.thirdPartyDynamicFormData.forEach((dynamicData) => {
            this.isRatingAvailable$.next(false);
            dynamicData.controller.get('id')?.setValue(dynamicData.data.sourceId);
            dynamicData.controller.get('url')?.setValue(dynamicData.data.sourceURL, { emitEvent: true });
            dynamicData.controller.get('id')?.valueChanges.subscribe((controllerData: string) => {
                dynamicData.data.sourceURL = this.buildThirdPartyLink(dynamicData);
                dynamicData.data.sourceId = controllerData;
                dynamicData.controller.get('url')?.setValue(this.buildThirdPartyLink(dynamicData), { emitEvent: true });
                this.isRatingAvailable$.next(this.dealerLocatorArray.filter((ratings) => Boolean(ratings.sourceURL)).length > 0);
            });
        });

        // applying form sorting
        this.thirdPartyDynamicFormData = this.thirdPartyDynamicFormData.slice().sort((valueA, valueB) => valueA.data.sourceName.toLowerCase().localeCompare(valueB.data.sourceName.toLowerCase()));
    }

    private generateThirdPartyRecords(locatorRatings: Ratings[]): Ratings[] {
        const emptySource: Ratings[] = [];
        if (locatorRatings.findIndex((el: Ratings) => el.sourceName === 'Facebook') === -1) {
            emptySource.push(this.emptySource('Facebook'));
        }
        if (locatorRatings.findIndex((el: Ratings) => el.sourceName === 'Yelp') === -1) {
            emptySource.push(this.emptySource('Yelp'));
        }
        if (locatorRatings.findIndex((el: Ratings) => el.sourceName === 'Google') === -1) {
            emptySource.push(this.emptySource('Google'));
        }

        return [...locatorRatings, ...emptySource];
    }

    private emptySource(sourceName: string): Ratings {
        return {
            id: '',
            sourceName: sourceName,
            sourceId: '',
            companyId: this.companyId,
            sourceURL: '',
            status: '1'
        };
    }

    private buildThirdPartyLink(controllerData: ThirdPartyDynamicFormData): string {
        if (controllerData.data.sourceId.length === 0) {
            controllerData.data.sourceURL = '';

            return controllerData.data.sourceURL;
        }
        if (controllerData.data.sourceName === 'Yelp') {
            controllerData.data.sourceURL = `https://www.yelp.com/biz/${controllerData.data.sourceId}`;

            return controllerData.data.sourceURL;
        }
        if (controllerData.data.sourceName === 'Facebook') {
            controllerData.data.sourceURL = `https://www.facebook.com/${controllerData.data.sourceId}`;

            return controllerData.data.sourceURL;
        }
        controllerData.data.sourceURL = `https://www.google.com/maps/place/?q=place_id:${controllerData.data.sourceId}`;

        return controllerData.data.sourceURL;
    }
}
