import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface UserRegistrationsFormValue {
    firstName: string;
    lastName: string;
    userName: string;
    companyName: string;
    companyId: string;
    createdDate: string;
    userStatus: string;
}

export interface UserRegistrationsForm {
    firstName: FormControl;
    lastName: FormControl;
    userName: FormControl;
    companyName: FormControl;
    companyId: FormControl;
    createdDate: FormControl;
    userStatus: FormControl;
}

@Injectable({ providedIn: 'root' })
export class UserRegistrationsService {
  public userRegistrationsSearchForm: FormGroup<UserRegistrationsForm> = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      userName: new FormControl(''),
      companyName: new FormControl(''),
      companyId: new FormControl(''),
      createdDate: new FormControl(''),
      userStatus: new FormControl('')
  });

  public defaultRegistrationsSearchFormValue: UserRegistrationsFormValue = {
      firstName: '',
      lastName: '',
      userName: '',
      companyName: '',
      companyId: '',
      createdDate: '',
      userStatus: ''
  }
}
