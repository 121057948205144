import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, UrlTree } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { PrivateAppRouteData } from 'private/app/app-routing.module';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountAdminGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly store: Store,
        private oktaService: OktaService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { accessRoles } = route.data as PrivateAppRouteData;

        if (accessRoles?.length) {
            return this.getRoleAllowed(accessRoles);
        }

        return of(false);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
        const { accessRoles } = childRoute.data as PrivateAppRouteData;
        if (accessRoles?.length) {
            return this.getRoleAllowed(accessRoles);
        }

        return of(false);
    }

    private getRoleAllowed(accessRoles: string[]): Observable<boolean> {
        return this.oktaService.idToken$.pipe(
            skipWhile((res) => Object.keys(res).length < 1),
            map((res: IdToken) => {
                const roles = res?.claims?.idm_user_roles;
                if (roles) {
                    const containAll = roles.some((item) => ((accessRoles as string[]).indexOf(item) !== -1
                    ));

                    return containAll;
                }

                return false;
            }),
            catchError(() => {
                this.store.dispatch(new Navigate(['/']));

                return of(false);
            })
        );
    }
}
