import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { HeapService } from 'common/services/heap.service';
import { SystemAlertsResponse } from 'private/app/models/alert.model';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { ConnectedPortalSystemFault, FaultSeverity } from 'private/app/models/system-fault.model';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';
import { EMPTY } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-factory-systems-faults',
    templateUrl: './factory-systems-faults.component.html',
    styleUrls: ['./factory-systems-faults.component.scss'],
    providers: [QueryParamsService]
})
export class FactorySystemsFaultsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() systemId: string;
    @Input() systemType: string;

    public faultsListPageTotal = 0;
    public faultsCurrentPage = 1;
    public faultsListPageSize = 5;
    public faultCount: number;
    public isLoading: boolean = false;
    public systemFaults: ConnectedPortalSystemFault[] = [];
    public toastOutlet = 'systemFaultsToast';
    public readonly ConnectedSystemFaultSeverity = FaultSeverity;
    private isFaultRenderTracked: boolean = false;

    constructor(
        private queryParamsService: QueryParamsService,
        private factoryService: FactoryService,
        private heapService: HeapService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initializeFaultsQueryParams();
    }

    originalSeverityOrder = (): number => 0;

    handleFaultsListPageChange(nextPage: number) {
        this.queryParamsService.updateParams({
            page: nextPage,
            scroll: false
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['systemId'].isFirstChange()) {
            this.handleSystemIdChange();
        }
    }

    private handleSystemIdChange() {
        // when current page is 1 and systemId is changed it will not trigger query change (because url will be the same) so we need to manually trigger change
        if (this.faultsCurrentPage === 1) {
            this.queryParamsService.emitParamsChange({
                page: 1,
                scroll: false
            });
        }
        else {
            this.queryParamsService.updateParams({
                page: 1,
                scroll: false
            });
        }
    }

    /* faults listing */
    private initializeFaultsQueryParams() {
        this.queryParamsService.init({ page: this.faultsCurrentPage })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((value) => {
                this.isLoading = true;

                const { page } = value;
                const nextPage = this.getNextPage(page);

                this.getSystemFaults(this.systemId, nextPage)
                    .pipe(
                        takeUntil(this.ngOnDestroy$),
                        catchError(() => {
                            this.isLoading = false;

                            return EMPTY;
                        })
                    )
                    .subscribe((systemFaults) => {
                        this.setDataOnSuccess(systemFaults);
                    });

                this.faultsCurrentPage = nextPage;
            });
    }

    private getNextPage(currentPage: number): number {
        if (this.faultsListPageTotal) {
            return currentPage > this.faultsListPageTotal ? this.faultsListPageTotal : currentPage;
        }

        return currentPage;
    }

    private getSystemFaults(systemId: string, currentPage: number = 1, pageSize: number = this.faultsListPageSize) {
        const queryParams: DefaultQueryParams = {
            pagination: {
                pageSize: pageSize,
                currentPage: currentPage
            }
        };

        return this.factoryService
            .querySystemFaultsBySystemId(systemId, queryParams)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map((value): { totalPages: number, totalCount: number, systemFaults: ConnectedPortalSystemFault[]} => {
                    const { totalPages, totalCount } = value;

                    return {
                        totalPages,
                        totalCount,
                        systemFaults: this.formatFaults(value)
                    };
                })
            );
    }

    private formatFaults(systemFaultsData: SystemAlertsResponse): ConnectedPortalSystemFault[] {
        const systemFaults = systemFaultsData.data.map((entry): ConnectedPortalSystemFault => {
            const { id, dateTime: datetime, product, error, source } = entry;

            const errorCode = error.code;

            return {
                id,
                datetime,
                faultSeverity: error.severity,
                faultCode: errorCode,
                faultMessage: error.description,
                source: source || product.name
            };
        });

        return systemFaults.filter(Boolean);
    }

    private setDataOnSuccess(data: {totalPages: number, totalCount: number, systemFaults: ConnectedPortalSystemFault[]}) {
        const { totalPages, totalCount, systemFaults } = data;

        this.isLoading = false;
        this.faultsListPageTotal = totalPages;
        this.faultCount = totalCount;
        this.systemFaults = systemFaults;
        if (!this.isFaultRenderTracked) {
            this.heapService.track(eventNames.handleSystemsOverviewFaultsLoad);
            this.isFaultRenderTracked = true;
        }
    }
}
