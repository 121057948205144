import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from 'common/components/base/base.component';
import { ConnectedPortalUIConfig } from 'private/app/models/connected-portal-ui-config.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { WallControlConfig } from 'private/app/models/wall-control.model';
import { ConfigEditProp, ConfigEditZoneProp, ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { ApiResponseCode, CONFIGURATION_RELOAD_DELAY, MISSING_API_RESPONSE_DATA } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, scan, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../../../product-detail-context.service';

interface ConfigData {
    configProps: Record<string, ConfigEditProp>;
    zonesConfigProps: Record<string, ConfigEditZoneProp>[];
}

interface LoadingState {
    configData?: boolean;
    saveUpdate?: boolean;
}

@Component({
    selector: 'hvac-infinity-wall-control-config',
    templateUrl: './infinity-wall-control-config.component.html',
    styleUrls: ['./infinity-wall-control-config.component.scss']
})
export class InfinityWallControlConfigComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() systemType: SystemType;
    @Input() isEditEnabled?: boolean;
    @Input() isSaveEnabled?: boolean;
    @Input() isDeviceConnected: boolean;

    public configData$: Observable<ConfigData>;
    public configFormGroup: UntypedFormGroup | null = null;
    public zoneConfigFormGroup: UntypedFormGroup | null = null;
    public isLoading$: Observable<boolean>;
    public toastOutlet = 'editConfigUpdateToast';

    private successToastQueue$ = new BehaviorSubject< DefaultUpdateResponse | null>(null)
    private loadData$ = new BehaviorSubject<boolean>(true);
    private wallControlConfigData$: Observable<WallControlConfig>;
    private configFormGroupInitialValues: Record<string, unknown> | null = null;
    private zoneConfigFormGroupInitialValues: Record<string, unknown> | null = null;
    private loadingStates$ = new BehaviorSubject<LoadingState>({
        configData: false,
        saveUpdate: false
    });

    constructor(
        private contextService: ProductDetailContextService,
        private productService: ProductService,
        private configService: ProductConfigurationService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.isLoading$ = this.loadingStates$.pipe(
            scan((acc, next) => ({
                ...acc,
                ...next
            })),
            map((loadingStates) => Object.values(loadingStates).some((loadingState) => loadingState === true))
        );

        this.wallControlConfigData$ = this.loadData$.pipe(
            switchMap(() => this.productService
                .queryWallControlConfigBySerialNo(this.serialNo, this.dealerId, this.systemType)
                .pipe(
                    map((res) => res.data),
                    tap(() => {
                        // If data loading happens after an update we show a success toast based on the update response
                        if (this.successToastQueue$.value) {
                            this.contextService.showToast(false, this.successToastQueue$.value, this.toastOutlet);
                            this.successToastQueue$.next(null);
                        }
                    })
                ))
        );

        this.configData$ = this.wallControlConfigData$.pipe(
            tap(() => this.loadingStates$.next({ configData: true })),
            switchMap((data) => {
                const { zones, ...props } = data;

                if (this.isEditEnabled) {
                    return this.configService.staticConfigEditParams$
                        .pipe(
                            switchMap((uiConfig) => this.getEditableConfigPropData$(data, uiConfig)
                                .pipe(
                                    tap((editConfigProps) => {
                                        const {
                                            configDataControls,
                                            zoneConfigDataControlsSets
                                        } = editConfigProps;

                                        this.configFormGroup = new UntypedFormGroup(configDataControls);
                                        this.configFormGroupInitialValues = this.configFormGroup.value;
                                        this.zoneConfigFormGroup = new UntypedFormGroup(zoneConfigDataControlsSets);
                                        this.zoneConfigFormGroupInitialValues = this.zoneConfigFormGroup.value;
                                    }),
                                    map((editConfigProps) => {
                                        const {
                                            configData,
                                            zonesConfigData
                                        } = editConfigProps;

                                        const configProps = configData;
                                        const zonesConfigProps = zonesConfigData;

                                        return {
                                            configProps,
                                            zonesConfigProps
                                        };
                                    })
                                ))
                        );
                }

                const configProps = this.configService.getReadOnlyPropData(props);
                const zonesConfigProps = zones.map((zone, zoneIndex) => this.configService
                    .getReadOnlyZonePropData(zone, zoneIndex) || []);

                return of({
                    configProps,
                    zonesConfigProps
                });
            }),
            tap(() => this.loadingStates$.next({ configData: false })),
            finalize(() => this.loadingStates$.next({ configData: false })),
            shareReplay()
        );

        // SUBSCRIPTIONS
        this.contextService.onConfigSaveConfirmed$
            .pipe(
                switchMap(() => this.saveConfigUpdates$()),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();
    }

    public openConfirmModal() {
        this.contextService.isConfigEditConfirmModalVisible$.next(true);
    }

    public resetFormGroups() {
        this.configFormGroup?.reset(this.configFormGroupInitialValues);
        this.zoneConfigFormGroup?.reset(this.zoneConfigFormGroupInitialValues);
    }

    public sortOrder() {
        return 0;
    }

    private saveConfigUpdates$() {
        if (!(this.configFormGroup && this.zoneConfigFormGroup)) {
            throw new Error('UPDATE_FAILED: Application Error');
        }

        const baseData = this.configService.getValuesFromFormGroup(this.configFormGroup as UntypedFormGroup);
        const zoneData = this.getZoneSubmissionData(this.zoneConfigFormGroup as UntypedFormGroup);
        const submissionData = { ...baseData };

        if (zoneData.length) {
            submissionData.zones = zoneData;
        }

        this.loadingStates$.next({ saveUpdate: true });

        return this.productService
            .updateInfinityWallControlConfigBySerialNo(this.serialNo, this.dealerId, submissionData)
            .pipe(
                switchMap((res) => {
                    if (res) {
                        if (res?.code === ApiResponseCode.SUCCESS) {
                            this.successToastQueue$.next(res);

                            return of(true).pipe(
                                tap(() => {
                                    this.loadingStates$.next({ configData: true });
                                }),
                                delay(CONFIGURATION_RELOAD_DELAY),
                                tap(() => {
                                    this.loadData$.next(true);
                                })
                            );
                        }

                        this.contextService.showToast(false, res, this.toastOutlet);
                    }

                    throw new Error(MISSING_API_RESPONSE_DATA);
                }),
                catchError((err) => {
                    this.contextService.showToast(true, err, this.toastOutlet);
                    this.resetFormGroups();
                    this.loadingStates$.next({ saveUpdate: false });

                    return of(false);
                }),
                tap(() => {
                    this.loadingStates$.next({ saveUpdate: false });
                })
            );
    }

    private getEditableConfigPropData$(wallControlConfigData: WallControlConfig, uiConfig: ConnectedPortalUIConfig['controlConfigEditParams']) {
        const wallControlStaticConfigParams = uiConfig.infinity.wallControl;
        const { zones, ...configDataNoZones } = wallControlConfigData;
        const { configEditParams: dynamicConfigEditParams } = configDataNoZones;

        const mergedData = this.configService.mergeConfigData(configDataNoZones, wallControlStaticConfigParams);

        const configDataControls = Object.entries(mergedData)
            .reduce((acc, [key, value]) => {
                if (value && value.readOnly === false && value.control) {
                    acc[key] = value.control;
                }

                return acc;
            }, {} as Record<string, UntypedFormControl>);

        const mergedZonesData = zones?.map((zoneData, zoneIndex): Record<string, ConfigEditZoneProp> => this.configService
            .mergeConfigZoneData(
                zoneData,
                dynamicConfigEditParams,
                wallControlStaticConfigParams,
                zoneIndex
            )) || [];

        // Create a form group and set of controls for each zone.
        const zoneConfigDataControlsSets = [...mergedZonesData]
            .reduce((zoneAcc, curZone) => {
                // Every prop object contains the groupId, so we pull it out of the first one.
                const groupId = Object.values(curZone)[0]?.groupId;

                const zoneControls = Object.entries(curZone).filter(([_key, value]) => Boolean(value.control))
                    .reduce((zonePropAcc, [_key, value]) => {
                        if (value.control) {
                            zonePropAcc[value.id] = value.control;
                        }

                        return zonePropAcc;
                    }, {} as { [key: string]: UntypedFormControl });

                if (groupId) {
                    zoneAcc[groupId] = new UntypedFormGroup(zoneControls);
                }

                return zoneAcc;
            }, {} as { [key: string]: UntypedFormGroup });

        return of({
            configData: mergedData,
            configDataControls,
            zonesConfigData: mergedZonesData,
            zoneConfigDataControlsSets
        });
    }

    private getZoneSubmissionData(zoneConfigFormGroup: UntypedFormGroup) {
        const zoneData = Object.entries(zoneConfigFormGroup.controls).reduce((acc, [key, value]) => {
            const zoneId = key;
            const zoneProps = this.configService.getValuesFromFormGroup(value as UntypedFormGroup);

            if (Object.keys(zoneProps)?.length) {
                const data = {
                    id: zoneId,
                    ...zoneProps
                };

                acc.push(data);
            }

            return acc;
        }, [] as { [key: string]: unknown }[]);

        return zoneData;
    }
}
