<hvac-connected-portal-layout-container>
    <div class="hvac-cp-top-container" top>
        <div class="hvac-cp-breadcrumb-header">
            <hvac-content-header [data]="breadCrumbData">
                <div class="hvac-dealer-customer-nav-wrap" *ngIf="(companyCode$ | async) === CompanyCode.Distributor; else notDistributor">
                    <hvac-distributor-navigation class="home-nav"></hvac-distributor-navigation>
                    <button hvacbutton="default" (click)="openAddControlModal()" class="hvac-header-title-container-button hvac-button"
                        size="small" buttonType="button" *ngIf="showAddControlFeature">
                        <span class="hvac-header-title-container-button-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ACTIONS.ADD_CONTROL" |
                            translate }}<div class="hvac-header-title-container-button-add">+</div>
                        </span>
                    </button>
                </div>
                <ng-template #notDistributor>
                    <button hvacbutton="default" (click)="openAddControlModal()" class="hvac-header-title-container-button hvac-button"
                        size="small" buttonType="button" *ngIf="showAddControlFeature">
                        <span class="hvac-header-title-container-button-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ACTIONS.ADD_CONTROL" |
                            translate }}<div class="hvac-header-title-container-button-add">+</div>
                        </span>
                    </button>
                </ng-template>
            </hvac-content-header>
        </div>
    </div>
    <div class="hvac-cp-bottom-container" bottom>
        <div class="hvac-row hvac-cp-customers-row">
            <div class="hvac-col-xs-12 hvac-col-lg-7 hvac-cp-customers-col">
                <div class="hvac-cp-search-bar-container">
                    <hvac-search-bar [label]="'CONNECTED_PORTAL.CUSTOMERS.SEARCH.CUSTOMER_SEARCH' | translate"
                        [placeholder]="searchPlaceholder | translate"
                        [queryControl]="queryControl"
                        (onSearchSubmit)="handleSearchSubmit($event)"
                        (onSearchReset)="handleSearchReset()">
                    </hvac-search-bar>
                </div>
            </div>
            <div class="hvac-col-xs-12 hvac-col-lg-5 hvac-cp-customers-col">
                <div class="hvac-cp-list-menu-container">
                    <div class="hvac-cp-list-menu-wrapper">
                        <hvac-customers-list-menu
                            [connectedCount]="(connectedCount$ | async) || 0"
                            [queuedCount]="(queuedCount$ | async) || 0"
                            (onLinkClick)="handleListMenuLinkClick()">
                        </hvac-customers-list-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="hvac-cp-item-list">
            <router-outlet></router-outlet>
        </div>
    </div>

    <utc-modal modal
        [visible]="isAddControlModalVisible"
        (closeAction)="handleCloseModal()"
        size="small"
        theme="rounded"
        class="hvac-connected-portal-add-control-modal">
        <hvac-modal-header>
                <span class="hvac-h3">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.HEADING" | translate }}</span>
        </hvac-modal-header>
        <hvac-cp-search-control
            [dealerId]="dealerId"
            (onModalClose)="handleCloseModal()"
            (onWorkflowChange)=handleWorkflowChange($event)
            *ngIf="isAddControlModalVisible && currentWorkflowStep === AddControlWorkflow.SEARCH_CONTROL">
        </hvac-cp-search-control>
        <hvac-cp-add-control
            [dealerId]="dealerId"
            (onModalClose)="handleCloseModal($event)"
            [statusDetails]="workflowData"
            *ngIf="isAddControlModalVisible && currentWorkflowStep === AddControlWorkflow.ADD_CONTROL">
        </hvac-cp-add-control>
    </utc-modal>
</hvac-connected-portal-layout-container>

<hvac-remove-control-modal *ngIf="(customerControlContextSvc.isRemoveControlModalVisible$  | async) as isRemoveControlModalVisible"
    [isModalVisible]="isRemoveControlModalVisible"
    [controlType]="controlType"
    [heading]="'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.CONFIRM_MODAL.HEADING' | translate"
    [message]="'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.CONFIRM_MODAL.MESSAGE' | translate"
    (onCloseModal)="customerControlContextSvc.isRemoveControlModalVisible$.next(false)"
    (onConfirmButtonClick)="customerControlContextSvc.confirmRemoveControl$.next(true)"></hvac-remove-control-modal>
