import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Incentive } from './incentives-item.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IncentivesContent {
    TITLE: string;
    BODY: string;
    ICON: string;
}

@Component({
    selector: 'hvac-login-incentives',
    templateUrl: './incentives.component.html',
    styleUrls: ['./incentives.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IncentivesComponent {
    incentives$: Observable<Incentive[]> = this.translations.get('LOGIN_PAGE.INCENTIVES').pipe(
        map((content: IncentivesContent[]) => content.map((incentiveContent) => ({
            title: incentiveContent.TITLE,
            body: incentiveContent.BODY,
            icon: incentiveContent.ICON
        })))
    );

    constructor(private readonly translations: TranslateService) {}
}
