<ng-template #customerName let-item="item">
    <div class="hvac-p-sm hvac-cp-customer-name">{{item.name}}</div>
</ng-template>

<ng-template #propertyAddress let-item="item">
    <div class="hvac-p-sm">{{ item.propertyAddress }}</div>
</ng-template>

<ng-template #model let-item="item">
    <div class="hvac-p-sm hvac-cp-model-name">{{ item.modelName }}</div>
    <div class="hvac-p-sm">{{ item.model }}</div>
</ng-template>

<ng-template #email let-item="item">
    <div class="hvac-p-sm"><a  *ngIf="item.email"class="hvac-text-link" [attr.href]="'mailto:' + item.email">{{ item.email }}</a></div>
</ng-template>

<ng-template #phone let-item="item">
    <div class="hvac-p-sm"><a *ngIf="item.phone" class="hvac-text-link" [attr.href]="'tel:' + item.phone">{{ item.phone }}</a></div>
</ng-template>

<ng-template #detailsLink let-item="item">
    <a *ngIf="item.id" hvacButton="default" size="small" [routerLink]="['../', item.id]" [queryParams]="{search: searchTerm}">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.DETAILS' | translate }}</a>
</ng-template>

<div class="hvac-cp-list-container">
    <ul class="hvac-cp-list" >
        <ng-container *ngIf="items?.length === 0">
            <li class="hvac-cp-list-item hvac-cp-no-data">
                <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
            </li>
        </ng-container>

        <ng-container *ngIf="items">
            <li class="hvac-cp-list-item" *ngFor="let item of items; trackBy:trackByItem">
                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.NAME' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="customerName" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.ADDRESS' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="propertyAddress" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.MODEL' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="model" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.EMAIL' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="email" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                    <div class="hvac-label hvac-col-xs-4 hvac-cp-field-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.PHONE' | translate }}</div>
                    <div class="hvac-col-xs-8 hvac-cp-field-value">
                        <ng-container [ngTemplateOutlet]="phone" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>

                <div class="hvac-row hvac-cp-field-group">
                    <div class="hvac-col-xs-12 hvac-cp-field-value hvac-cp-details-link-container">
                        <ng-container [ngTemplateOutlet]="detailsLink" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </div>
                </div>
            </li>
        </ng-container>

            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
    </ul>

    <table class="hvac-cp-list">
        <thead>
            <tr class="hvac-cp-list-table-header">
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.NAME' | translate }}</th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.ADDRESS' | translate }}</th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.MODEL' | translate }}</th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.EMAIL' | translate }}</th>
                <th class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.HEADINGS.PHONE' | translate }}</th>
                <th class="hvac-label"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="items?.length === 0">
                <tr class="hvac-cp-list-row hvac-cp-list-item">
                    <td class="hvac-cp-field-group hvac-cp-no-data" colspan="6">
                        <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="items">
                <tr class="hvac-cp-list-row hvac-cp-list-item" *ngFor="let item of items; trackBy:trackByItem">
                    <td class="hvac-cp-field-group hvac-cp-customer-name-group">
                        <ng-container [ngTemplateOutlet]="customerName" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-property-address-group">
                        <ng-container [ngTemplateOutlet]="propertyAddress" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-model-group">
                        <ng-container [ngTemplateOutlet]="model" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-email-group">
                        <ng-container [ngTemplateOutlet]="email" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-phone-group">
                        <ng-container [ngTemplateOutlet]="phone" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                    <td class="hvac-cp-field-group hvac-cp-detail-link-group">
                        <ng-container [ngTemplateOutlet]="detailsLink" [ngTemplateOutletContext]="{item: item}"></ng-container>
                    </td>
                </tr>
            </ng-container>

            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
        </tbody>
    </table>

    <div class="hvac-cp-list-footer">
        <div class="hvac-cp-list-footer-pagination">
            <hvac-pagination
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>

        <div class="hvac-cp-list-footer-export">
            <button class="hvac-cp-action-button" hvacButton="primary" (click)="handleExportProperties()" [disabled]="items?.length === 0">
                {{ 'CONNECTED_PORTAL.CUSTOMERS.ACTIONS.EXPORT_CUSTOMER_LIST' | translate }}
            </button>
        </div>
    </div>

</div>
