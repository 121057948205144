/* eslint-disable id-length */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parseISO, sub } from 'date-fns';
import { DataEntry, Interval, SelectedProperties, SensorDataPoint, SensorDataset, TransformedRelayData } from 'private/app/models/engineering-insights-filters.model';
import { SensorDataEvent, SensorPropMinMax, SystemDataEvents, ThermostatDataEvent, ThermostatSensorDataEvent } from 'private/app/models/factory.model';
import { ENGINEERING_GRAPH_PARAMETER_PROPS, ENGINEERING_GRAPH_RELAY_PROPS } from '../../../constants';


@Injectable()
export class FactoryEngineeringInsightsService {
    constructor(private translateService: TranslateService) { }

    public mapFilterInputs(dates: { startDate: number, endDate: number }, minMaxValues: SensorPropMinMax, selectedProps: SelectedProperties, disabledRelays: string[]) {
        const translateBase = 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW';

        const relays = ENGINEERING_GRAPH_RELAY_PROPS.map((key) => {
            const isDisabled = disabledRelays.includes(key);

            return ({
                key,
                label: this.translateService.instant(`${translateBase}.RELAY_PROPS.${key}.LABEL`),
                description: this.translateService.instant(`${translateBase}.RELAY_PROPS.${key}.DESCRIPTION`),
                isDisabled,
                isSelected: selectedProps.relays.includes(key) && !isDisabled
            });
        });

        const parameters = ENGINEERING_GRAPH_PARAMETER_PROPS.map((key) => {
            const value = minMaxValues[key] || {
                min: null,
                max: null
            };
            const isDisabled = (value.min === null || value.max === null);

            return ({
                key,
                label: this.translateService.instant(`${translateBase}.PARAMETER_PROPS.${key}.LABEL`),
                isDisabled,
                isSelected: selectedProps.parameters.includes(key) && !isDisabled,
                ...value
            });
        });

        return {
            minStartDate: sub(new Date(dates.endDate), { days: 7 }).getTime(),
            startDate: dates.startDate,
            endDate: dates.endDate,
            maxEndDate: Date.now(),
            relays,
            parameters
        };
    }

    public getDisabledRelays(data: ThermostatDataEvent[]) {
        return ENGINEERING_GRAPH_RELAY_PROPS
            .map((prop) => ({
                prop,
                hasData: data
                    .map((event) => (event[prop as keyof ThermostatDataEvent]))
                    .some((value) => (value === 'Relay_on'))
            }))
            .filter((value) => (!value.hasData))
            .map((value) => value.prop);
    }

    public getRelayEventData(thermostatEvents: ThermostatDataEvent[], selectedRelays: string[]): TransformedRelayData {
        const dataset = thermostatEvents.map((event) => {
            const relayProps = Object.entries(event).reduce((acc, [key, value]) => {
                if (selectedRelays.includes(key)) {
                    acc[key] = value;
                }

                return acc;
            }, {} as { [key: string]: number | null });

            return {
                date: event.date,
                ...relayProps
            };
        });

        return Object.keys(dataset[0])
            .filter((key) => key !== 'date')
            .reduce((acc: TransformedRelayData, key: string) => {
                const intervals: Interval[] = [];
                let start: string | null = null;

                dataset.forEach((entry: DataEntry) => {
                    if (entry[key] === 'Relay_on' && start === null) {
                        start = entry.date;
                    }
                    else if (entry[key] !== 'Relay_on' && start !== null) {
                        intervals.push({
                            start,
                            end: entry.date
                        });
                        start = null;
                    }

                    // Handle the case where the last entry is "Relay_on"
                    if (entry === dataset[dataset.length - 1] && start !== null) {
                        intervals.push({
                            start,
                            end: entry.date
                        });
                    }
                });

                acc[key] = intervals;

                return acc;
            }, {});
    }

    public getCombinedDataEvents(data: SystemDataEvents, minMaxValues: { [key: string]: { min: number | null, max: number | null } }) {
        const { thermostatDataEvents, sensorDataEvents } = data;
        const thermostatSensorDataEvents = thermostatDataEvents.map((event): ThermostatSensorDataEvent => {
            const { CSP, HSP, OT, IT, OH, IH, date } = event;

            return {
                date,
                CSP,
                HSP,
                OT,
                IT,
                OH,
                IH
            };
        });

        // Create a combined structure for easy iteration
        const combinedDataSets: SensorDataset[] = [];

        // Extract and combine thermostatDataEvents properties
        Object.keys(thermostatSensorDataEvents[0]).forEach((key) => {
            if (key !== 'date') {
                const minMax = minMaxValues[key] || {
                    min: null,
                    max: null
                };

                combinedDataSets.push({
                    name: key,
                    data: thermostatSensorDataEvents.map((event): SensorDataPoint => ({
                        date: parseISO(event.date),
                        value: event[key as keyof ThermostatSensorDataEvent] as number | null
                    })),
                    ...minMax
                });
            }
        });

        // Extract and combine sensorDataEvents properties
        Object.keys(sensorDataEvents[0]).forEach((key) => {
            if (key !== 'date') {
                const minMax = minMaxValues[key] || {
                    min: null,
                    max: null
                };

                combinedDataSets.push({
                    name: key,
                    data: sensorDataEvents.map((event) => ({
                        date: parseISO(event.date),
                        value: event[key as keyof SensorDataEvent] as number | null
                    })),
                    ...minMax
                });
            }
        });

        return combinedDataSets;
    }
}
