/* tslint:disable:max-line-length */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AccountState } from 'common/store/create-account.state';
import { createAccountEmailCodeValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { AcceleratedSignUpPostBody, AccountInfoUserInfo, CreateAccountStatus, EmailVerificationStatus, ICPDealerRegistrationDetails } from 'private/app/models/accountInfo';
import { FetchCreateAccount, LockUserAccountForm, RequestEmailVerificationCode, ResetAccountUserInformation, ResetEmailVerification, ResetUniqueEmail, ResetUniqueUserName, SubmitEmailVerificationCode, UnlockUserAccountForm } from 'private/app/store/create-account.actions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { CreateAccountService } from '../../services/create-account.service';
import { ToastService } from 'common/services/toast.service';
/* tslint:enable:max-line-length */

@Component({
    selector: 'utc-create-email-validate',
    templateUrl: './createEmailvalidate.component.html',
    styleUrls: ['./createEmailvalidate.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateEmailValidateComponent implements OnInit {
    @Select(AccountState.createAccountStatus) createAccountStatus$: Observable<CreateAccountStatus>;
    @Select(AccountState.validAccountInfo) validAccountInfo$!: Observable<boolean>;
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AccountState.emailVerificationCodeRequestStatus) verificationCodeStatus$: Observable<EmailVerificationStatus>;
    @Select(AccountState.accountInfo) accountInfo$: Observable<AccountInfoUserInfo>;
    @Select(AccountState.icpDealerRegistrationDetails) icpDealerRegistrationDetails$: Observable<ICPDealerRegistrationDetails>;

    footerNumberOfSteps = 3;
    accountInfo: AccountInfoUserInfo;
    resendButtonTimer = 180000;
    cancelButtonDisabled = false;
    resetButtonDisabled = true;
    errorBanner = false;
    ngOnDestroy$ = new Subject();
    ICPCreateAccount$ = new BehaviorSubject<boolean>(false);
    emailCodeControl = new UntypedFormControl('', createAccountEmailCodeValidator());
    errorMessages$ = this.translate.get('CREATE_ACCOUNT_EMAIL.ERROR_MESSAGES');
    emailCodeErrorMessage$ = combineLatest([this.emailCodeControl.statusChanges, this.errorMessages$]).pipe(
        map(([_valid, message]) => getErrorMessage(this.emailCodeControl.errors, message))
    );

    footerActionButtonText$ = combineLatest([this.translate.get('CREATE_ACCOUNT_EMAIL.BUTTON_VALIDATE'), this.translate.get('CREATE_ACCOUNT_EMAIL.FOOTER_CONTINUE_BUTTON'), this.ICPCreateAccount$, this.translate.get('CREATE_ACCOUNT_EMAIL.BUTTON_CREATE')]).pipe(
        map(([validate, btnContinue, icpCreateAccount, btnCreate]) => {
            if (icpCreateAccount) {
                this.onEmailValidationSuccess = icpCreateAccount;

                return btnCreate;
            }

            return this.isIcpDealerRegistrationRequest ? validate : btnContinue;
        })
    );

    public isIcpDealerRegistrationRequest: boolean;

    private onEmailValidationSuccess = false;
    private hvacCompanyId: string;
    private icpFooterNumberOfSteps = 2;
    private buttonTimer: NodeJS.Timeout;

    constructor(
        private translate: TranslateService,
        private readonly store: Store,
        private location: Location,
        private createAccountService: CreateAccountService,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.icpDealerRegistrationDetails$.subscribe((icpDealerRegistrationDetails) => {
            if (icpDealerRegistrationDetails.brand.length > 0 && icpDealerRegistrationDetails.dealerCode.length > 0) {
                this.hvacCompanyId = icpDealerRegistrationDetails.dealerCode.indexOf('1') === 0 ?
                    icpDealerRegistrationDetails.dealerCode.replace('1', '3') :
                    icpDealerRegistrationDetails.dealerCode;

                this.footerNumberOfSteps = this.icpFooterNumberOfSteps;
                this.isIcpDealerRegistrationRequest = true;
            }
        });
        this.disableResendButton();
        this.accountInfo$.pipe(
            first(),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((data) => {
            this.accountInfo = data;
            if (this.accountInfo.emailAddress && this.accountInfo.username) {
                this.store.dispatch(
                    new RequestEmailVerificationCode(
                        this.accountInfo.emailAddress, this.accountInfo.username
                    )
                );
            }
        });
        this.verificationCodeStatus$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((requestStatus: EmailVerificationStatus) => {
            this.errorBanner = false;
            switch (requestStatus) {
                case EmailVerificationStatus.Success:
                    this.store.dispatch(new UnlockUserAccountForm());
                    if (this.isIcpDealerRegistrationRequest) {
                        this.ICPCreateAccount$.next(true);
                        this.toastService.add({
                            content: this.translate.instant('CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_VALIDATION_SUCCESS'),
                            theme: 'success',
                            autoClose: true,
                            id: 'EMAIL_VALIDATION_SUCCESS'
                        });

                        return;
                    }
                    this.store.dispatch(new Navigate(['/create-account-company-lookup']));
                    break;
                case EmailVerificationStatus.Requested:
                    break;
                case EmailVerificationStatus.Error:
                    this.resetButtonDisabled = false;
                    this.errorBanner = true;
                    this.store.dispatch(new UnlockUserAccountForm());
                    break;
                case EmailVerificationStatus.Forbidden:
                    this.store.dispatch(new UnlockUserAccountForm());
                    this.store.dispatch(new ResetUniqueUserName());
                    this.store.dispatch(new ResetUniqueEmail());
                    this.store.dispatch(new Navigate(['/create-account-information']));
                    break;
                default:
                    break;
            }
        });
    }

    requestEmailCode() {
        this.disableResendButton();
        this.toastService.add({
            content: `A new verification code has sent to ${this.accountInfo.emailAddress}`,
            theme: 'success',
            id: 'requestEmailCode',
            autoClose: true
        });
        this.store.dispatch(new RequestEmailVerificationCode(this.accountInfo.emailAddress, this.accountInfo.username));
    }


    backToInformationPage() {
        clearTimeout(this.buttonTimer);
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());

        return this.location.back();
    }

    // Final submit account
    submitAccountInfo() {
        if (this.onEmailValidationSuccess) {
            if (this.isIcpDealerRegistrationRequest) {
                // Accelerated account create
                const body: AcceleratedSignUpPostBody = {
                    hvacCompanyId: this.hvacCompanyId,
                    ...this.accountInfo
                };

                this.createAccountService.postAccelerateSignUp(body).subscribe(() => {
                    window.location.href = '/login';
                });
            }
            else {
                this.store.dispatch(new FetchCreateAccount());
            }

            return;
        }

        clearTimeout(this.buttonTimer);
        this.resetButtonDisabled = true;
        this.store.dispatch(new LockUserAccountForm());
        this.store.dispatch(new SubmitEmailVerificationCode(
            this.accountInfo.emailAddress, this.accountInfo.username, this.emailCodeControl.value
        ));
    }

    handleHeaderCancel() {
        clearTimeout(this.buttonTimer);
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetAccountUserInformation());
        this.store.dispatch(new ResetEmailVerification());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    handleHeaderLogo() {
        clearTimeout(this.buttonTimer);
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    disableResendButton() {
        this.resetButtonDisabled = true;
        this.buttonTimer = setTimeout(() => {
            this.resetButtonDisabled = false;
            this.errorBanner = true;
            this.toastService.add({
                content: this.translate.instant('CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_RESEND_MESSAGE'),
                theme: 'warning',
                id: 'EMAIL_RESEND_MESSAGE',
                autoClose: true
            });
        }, this.resendButtonTimer);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
