<div class='hvac-chat-bot' [ngClass]="{'chatbot-open': (visible$ | async) && !(minimized$ | async), 'chatbot-minimized': (minimized$ | async), 'mobile-menu-open': (mobileMenuVisible$ | async)}">
    <utc-tooltip *ngIf="!(visible$ | async) || (minimized$ | async)">
        <utc-tooltip-content>
                <p class='hvac-tooltip-description'>{{ hoverText$ | async  }}</p>
        </utc-tooltip-content>
        <utc-tooltip-anchor-content>
            <button class='hvac-chat-bot-trigger'(click)="openChatbot()">
                <span class="hvac-accessible-text">{{'CHATBOT.TRIGGER_ACCESSIBLE_TEXT' | translate}}</span>
                <utc-svg [src]="'assets/icons/chatbot-trigger.svg'"></utc-svg>
            </button>
        </utc-tooltip-anchor-content>
    </utc-tooltip>
    <div *ngIf="(visible$ | async)" class='hvac-chat-bot-container'>
        <div class='hvac-chat-bot-header'>
            <utc-svg class='hvac-chat-bot-corner-icon' src="assets/icons/chatbot-corner.svg"></utc-svg>
            <div class='button-container'>
                <button class="hvac-chat-bot-minimize" buttonType="button" (click)="minimizeChatbot()">
                    <span class="hvac-accessible-text">{{'CHATBOT.MINIMIZE_ACCESSIBLE_TEXT' | translate}}</span>
                    <utc-svg class="hvac-chat-bot-icon" src="assets/icons/minus.svg"></utc-svg>
                </button>
                <button class="hvac-chat-bot-close" buttonType="button" (click)="closeChatbot()">
                    <span class="hvac-accessible-text">{{'CHATBOT.CLOSE_ACCESSIBLE_TEXT' | translate}}</span>
                    <utc-svg class="hvac-chat-bot-icon" src="assets/icons/close.svg"></utc-svg>
                </button>
            </div>
        </div>
        <iframe [title]="'CHATBOT.IFRAME_TITLE' | translate" [src]="chatbotUrl | safe:'resourceUrl'"></iframe>
    </div>
</div>
