<footer class="hvac-private-footer">
    <div [ngClass]="{'public-footer-container': !isPrivate}"class="hvac-content-container">
        <div *ngIf="isPrivate; else PublicCTA" class="hvac-private-footer-content-col hvac-private-footer-col-one">
            <p class='hvac-private-footer-content-title'>{{'FOOTER.CONTACT_TITLE' | translate}}</p>
            <div class="hvac-private-footer-contact-container">
                <utc-svg class="hvac-private-footer-contact-icon" src="assets/icons/sign_up.svg"></utc-svg>
                <a target='_blank' [href]="signupLink$ | async"
                    hvacLink='alt'>{{ 'FOOTER.SIGNUP_TEXT' | translate }}</a>
            </div>
            <div class="hvac-private-footer-contact-container">
                <utc-svg class="hvac-private-footer-contact-icon" src="assets/icons/phone.svg"></utc-svg>
                <a target='_blank' [href]="phoneLink$ | async"
                    hvacLink='alt'>{{  phoneText$  | async }}</a>
            </div>
            <div class="hvac-private-footer-contact-container">
                <utc-svg class="hvac-private-footer-contact-icon" src="assets/icons/letter.svg"></utc-svg>
                <a target='_blank' [href]=" emailLink$  | async"
                    hvacLink='alt'>{{ emailText$ | async }}</a>
            </div>
        </div>
        <ng-template #PublicCTA>
            <div class='hvac-public-footer-cta-container'>
                <div class="hvac-public-footer-cta-content">
                    <div class="hvac-public-footer-cta-content-with-icon hvac-public-footer-contact-us">
                        <utc-svg class="hvac-public-footer-cta-icon" src="assets/icons/phone.svg"></utc-svg>
                        <div class="hvac-public-footer-cta-content-text-and-cta">
                            <p class="hvac-public-footer-cta-content-text">{{ 'FOOTER.CUSTOMER_SUPPORT' | translate }}</p>
                            <p class="hvac-public-footer-cta-contact-number" [innerHtml]="('FOOTER.CUSTOMER_SUPPORT_NUMBER.' + (((brand$ | async) || '') | uppercase)) | translate | safe:'html'"></p>
                            <div class="hvac-public-footer-login-message-container">
                                <p class="hvac-public-footer-login-message">
                                    <span>{{ 'FOOTER.CUSTOMER_SUPPORT_LOGIN_CTA_INTRO' | translate }} </span>
                                    <a [href]="'FOOTER.CUSTOMER_SUPPORT_LOGIN_CTA_LINK' | translate" target="_blank"
                                        class="hvac-public-footer-login-link">{{ 'FOOTER.CUSTOMER_SUPPORT_LOGIN_CTA_LINK_TEXT' | translate }}</a>
                                    <span> {{ 'FOOTER.CUSTOMER_SUPPORT_LOGIN_CTA_OUTRO' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hvac-public-footer-cta-content">
                    <div class="hvac-public-footer-cta-content-with-icon">
                        <utc-svg class="hvac-public-footer-cta-icon" src="assets/icons/truck.svg"></utc-svg>
                        <div class="hvac-public-footer-cta-content-text-and-cta">
                            <p class="hvac-public-footer-cta-content-text">
                                {{ 'FOOTER.INTERESTED_IN_BECOMING_A_DEALER' | translate }}</p>
                            <a class="hvac-public-footer-cta-content-cta" href="{{ ('FOOTER.DEALER_SURVEY.' + (((brand$ | async) || '') | uppercase)) | translate }}"
                                target="_blank">{{ 'FOOTER.YES_TELL_ME_MORE' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <div class='hvac-private-footer-links-container'>
            <div class="hvac-private-footer-content-col">
                <div class='hvac-public-footer-policy-row' *ngIf="(brand$ | async) !== 'default' && !isPrivate">
                    <a *ngFor="let link of (navigationLinks$ | async)" hvacLink='primary' [href]="link.LINK"
                        class='hvac-public-footer-policy-link hvac-public-footer-navigation-link'>{{link.TEXT}}</a>
                </div>
                <div class='hvac-private-footer-policy-row'>
                    <a target='_blank' hvacLink='primary' [href]="'FOOTER.TERMS_OF_USE_LINK' | translate"
                        class='hvac-private-footer-policy-link'>{{'FOOTER.TERMS_OF_USE' | translate}}</a>
                    <a target='_blank' hvacLink='primary' [href]="'FOOTER.PRIVACY_POLICY_LINK' | translate"
                        class='hvac-private-footer-policy-link'>{{'FOOTER.PRIVACY_POLICY' | translate}}</a>
                    <div class="ot-sdk-show-settings hvac-private-footer-cookie-link consent-button">{{'FOOTER.COOKIE_PREFERENCES' | translate}}</div>

                    </div>
                <p class='hvac-private-footer-legal-copy'> {{'FOOTER.TRADEMARK.'+templateKey+'.LINE1_PRIVATE' | translate}}
                </p>
                <p class='hvac-private-footer-legal-copy'>{{'FOOTER.TRADEMARK.'+templateKey+'.LINE2_PRIVATE' | translate: { year: copyrightYear } }}
                </p>
            </div>
            <div *ngIf="(socialLinks$ | async)" class="hvac-private-footer-content-col hvac-private-footer-content-social-col">
                <div class='hvac-private-footer-single-social-container'>
                    <a target='_blank' *ngFor="let social of (socialLinks$ | async)" href="{{social.LINK}}" class="hvac-private-footer-social-link" target="_blank">
                        <utc-svg class='hvac-private-footer-contact-icon hvac-private-footer-social-icon' src="assets/icons/social-{{social.NAME}}.svg"></utc-svg>
                        <p class="hvac-accessible-text">{{social.TEXT}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
