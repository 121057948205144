import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class UserRelationshipsEditService {
    public userRelationshipEditFormControls = new FormGroup({
        searchAvailableSoldTos: new UntypedFormControl(''),
        searchAssignedSoldTos: new UntypedFormControl('')
    });

    public sampleCompaniesList: string[] = [];

    constructor() {
        for (let i = 0; i < 24; i++) {
            this.sampleCompaniesList.push(`Company ${Math.floor(Math.random() * 100).toString()} ABC ${i + 1}`);
        }
    }
}
