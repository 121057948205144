import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ExportUserDetailsEntity } from '../../models/user.model';

@Injectable({ providedIn: 'root' })
export class AccountAdminExportService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public doExport(response: HttpResponse<any>) {
        const fileName: string | undefined = response.headers?.get('content-disposition')?.split('filename="')?.[1].split('"')?.[0];
        const binaryData = [];
        const downloadLink = document.createElement('a');

        binaryData.push(response.body);
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', fileName || 'export.xlsx');
        downloadLink.setAttribute('target', '_blank');
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    public doExportUserXSLX(User : ExportUserDetailsEntity) {
        const selectedUser = [User];
        const title = `User_Details_${new Date().toDateString()}`;
        const fileName = `${title}.xlsx`;
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedUser);
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        XLSX.writeFile(workBook, fileName);
    }
}
