<section>
    <h1 class="hvac-h2 hvac-rhythm-5">{{'COMPANY_INFO_FORM.HEADER' | translate}}</h1>

    <form [formGroup]="companyInfoForm">
        <div class="hvac-row">
            <div class="hvac-col-xs-12 hvac-col-md-6 hvac-profile-col">
                <div class="hvac-form-field">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.companyType"
                        label="{{'COMPANY_INFO_FORM.COMPANY_TYPE' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field" [hidden]="!isDealer">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.companyId"
                    label="{{'COMPANY_INFO_FORM.COMPANY_ID' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.companyName"
                    label="{{'COMPANY_INFO_FORM.NAME' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.email"
                        label="{{'GENERAL.LABELS.EMAIL' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.address1"
                        label="{{'GENERAL.LABELS.ADDRESS_1' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.address2"
                        label="{{'GENERAL.LABELS.ADDRESS_2' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.city"
                        label="{{'GENERAL.LABELS.CITY' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.state"
                        label="{{'GENERAL.LABELS.STATE' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.zip"
                        label="{{'GENERAL.LABELS.ZIP' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.country"
                        label="{{'GENERAL.LABELS.COUNTRY' | translate}}">
                    </utc-input>
                </div>
            </div>

            <div class="hvac-col-xs-12 hvac-col-md-6">
                <div class="hvac-form-field">
                    <utc-input
                        [inputControl]="companyInfoForm.controls.phone"
                        label="{{'GENERAL.LABELS.PHONE' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field" [hidden]="!isDistributor">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.payer"
                        label="{{'COMPANY_INFO_FORM.PAYER' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field" [hidden]="!isDistributor">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.soldTo"
                        label="{{'COMPANY_INFO_FORM.SELL_TO_NUMBER' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field" [hidden]="!isDistributor">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.shipToNumber"
                        label="{{'COMPANY_INFO_FORM.SHIP_TO_NUMBER' | translate}}">
                    </utc-input>
                </div>

                <div class="hvac-form-field" [hidden]="!isDealer && !isDistributor">
                    <utc-input
                    [inputControl]="companyInfoForm.controls.language"
                    label="{{'COMPANY_INFO_FORM.LANGUAGE' | translate}}">
                    </utc-input>
                </div>
                <h2>{{ 'COMPANY_INFO_FORM.AUTHORIZED_BRANDS' | translate }}</h2>
                <div class='hvac-company-brands-list-title'>
                    <ng-container *ngFor="let brand of authorisedBrands$ | async">
                        <div *ngIf="brand.toLowerCase() !== 'icl'" class="hvac-company-brand-badge hvac-company-brand-badge-primary">{{brand.toUpperCase()}}</div>
                    </ng-container>
                </div>
                <h2>{{ 'COMPANY_INFO_FORM.SERVICES' | translate }}</h2>
                <div class="hvac-company-brands-list-title">
                    <ng-container *ngFor="let service of companyServices$ | async">
                        <div class="hvac-company-brand-badge hvac-company-brand-badge-primary">{{service.toUpperCase()}}</div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</section>
