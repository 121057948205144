import { FormatphonenumberPipe } from './../pipes/formatphonenumber.pipe';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrandFilterComponent } from 'common/components/brand-filter/brand-filter.component';
import { ErrorPageComponent } from 'common/components/error-page/error-page.component';
import { FilterPipe } from 'common/pipes/filter.pipe';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { SafePipeModule } from 'safe-pipe';
import { RadioButtonComponent } from '../content/components/radio/radio-button.component';
import { RadioGroupComponent } from '../content/components/radio/radio-group.component';
import { SignInTeaserComponent } from '../content/components/sign-in-teaser/sign-in-teaser.component';
import { SignupPanelComponent } from './signup-panel/signup-panel.component';
import { DistributorLocatorComponent } from '../views/distributor-locator/distributor-locator.component';
import { HvacMapComponent } from '../views/distributor-locator/map/hvac-map.component';
import { RichTextModule } from '../pipes/rich-text/rich-text.module';
import { SearchTextFilterPipe } from 'common/pipes/search-text.pipe';
import { AlertComponent } from './alert/alert.component';
import { AutoSuggestComponent } from './auto-suggest/auto-suggest.component';
import { ContentSuggestionComponent } from './auto-suggest/components/content-suggestion.component';
import { DocumentSuggestionComponent } from './auto-suggest/components/document-suggestion.component';
import { ProductSuggestionComponent } from './auto-suggest/components/product-suggestion.component';
import { PartSuggestionComponent } from './auto-suggest/components/part-suggestion.component';
import { ToastComponent } from './toast/toast.component';
import { ButtonComponent } from './button/button.component';
import { ButtonDirective } from './button/button.directive';
import { ShareButtonComponent } from './button/share-button.component';
import { CardDirective } from './card/card.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DocumentListItemComponent } from './documents/document-list-item.component';
import { DownloadLinkComponent } from './download-link/download-link.component';
import { DealerDropdownMenuComponent } from './dropdown/dropdown-dealer-locator-menu/dropdown-dealer-locator-menu.component';
import { DropdownIconMenuComponent } from './dropdown/dropdown-icon-menu/dropdown-icon-menu.component';
import { DropdownNavigationComponent } from './dropdown/dropdown-navigation/dropdown-navigation.component';
import { OptionsMenuComponent } from './dropdown/options-menu/options-menu.component';
import { FullPageLoaderComponent } from './full-page-loader/full-page-loader.component';
import { HeroComponent } from './hero/hero.component';
import { ImageFallbackComponent } from './image-fallback/image-fallback.component';
import { InputComponent } from './input/input.component';
import { PasswordInputComponent } from './input/passwordInput.component';
import { LinkDirective } from './link-directive/link.directive';
import { LinkComponent } from './link/link.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoginPanelComponent } from './login-panel/login-panel.component';
import { NavigationContainerComponent } from './navigation-container/navigation-container.component';
import { NavigationNestedContainerComponent } from './navigation-container/navigation-nested-container/navigation-nested-container.component';
import { NavigationPanelContainerComponent } from './navigation-container/navigation-panel-container/navigation-panel-container.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PlaceholderListItemComponent } from './placeholder/placeholder-list-item.component';
import { PrimerFrameComponent } from './primer-frame/primer-frame.component';
import { ProductCardComponent } from './product/product-card.component';
import { ProductListItemComponent } from './products/product-list-item.component';
import { PartListItemComponent } from './part-list-item/part.list-item.component';
import { ResultsListComponent } from './results-list/results-list.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SegmentedButtonComponent } from './segmented-button/segmented-button';
import { SelectComponent } from './select/select.component';
import { SelectMenuComponent } from './select/select-menu/select-menu.component';
import { SiteContentListItemComponent } from './site-content/site-content-list-item.component';
import { SvgComponent } from './svg/svg.component';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { TableComponent } from './table/table.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipAnchorContentComponent } from './tooltip/tooltipAnchorContent.component';
import { TooltipContentComponent } from './tooltip/tooltipContent.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { SectionSelectComponent } from './section-select/section-select.component';
import { NotificationComponent } from './notification/notification.component';
import { ModalHeaderComponent } from './modal/modal-header/modal-header.component';
import { ModalComponent } from './modal/modal.component';
import { ScrollToTopButtonComponent } from './scroll-to-top-button/scroll-to-top-button.component';
import { JumpbarComponent } from 'common/content/components/content-header/jump-bar/jump-bar.component';
import { BaseLayoutComponent } from 'common/layouts/layout-base/layout-base.component';
import { ClickOutsideDirective } from 'common/directives/click-outside.directive';
import { HvacRadioButtonComponent } from './radio-button/hvac-radio-button.component';
import { AccordionNewComponent } from './accordion-new/accordion-new.component';
import { StatsWidgetComponent } from './stats-widget/stats-widget.component';
import { DayPickerComponent } from './day-picker/day-picker.component';
import { DataBlockComponent } from './data-block/data-block.component';
import { HvacAutoCompleteComponent } from './hvac-autocomplete/hvac-auto-complete.component';
import { AccordionBodyComponent } from './accordion-new/accordion-body/accordion-body.component';
import { AccordionHeaderComponent } from './accordion-new/accordion-header/accordion-header.component';
import { SystemProductCardComponent } from './system-product-card/system-product-card.component';
import { ProductDescriptorComponent } from './product-descriptor/product-descriptor.component';
import { SystemProductListItemComponent } from './system-product-list-item/system-product-list-item.component';
import { BoolToStatusPipe } from 'common/pipes/bool-to-status.pipe';
import { TextFallbackPipe } from 'common/pipes/text-fallback.pipe';
import { ProductPropertyListComponent } from './product-property-list/product-property-list.component';
import { ProductPropertyListItemComponent } from './product-property-list/product-property-list-item/product-property-list-item.component';
import { ProductPropertyListItemLabelComponent } from './product-property-list/product-property-list-item-label/product-property-list-item-label.component';
import { ProductPropertyListItemValueComponent } from './product-property-list/product-property-list-item-value/product-property-list-item-value.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TypeofPipe } from 'common/pipes/typeof.pipe';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { MilitaryToStandard } from 'common/pipes/military-to-standard.pipe';
import { ListComponent } from './list/list.component';
import { DegreePipe } from 'common/pipes/degree.pipe';
import { AlertChipComponent } from './alert-chip/alert-chip.component';
import { BaseComponent } from './base/base.component';
import { TextAreaComponent } from './textarea/textarea.component';
import { SelectNewComponent } from './select-new/select-new.component';
import { ChipComponent } from './chip/chip.component';
import { InputFileComponent } from './input-file/input-file.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { DynamicNestedFilterComponent } from './dynamic-nested-filter/dynamic-nested-filter.component';
import { AddressComponent } from './address/address.component';
import { DatePickerInputDatePipe } from 'common/pipes/date-picker-input-date';
import { NumberStepperComponent } from './number-stepper/number-stepper.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { EventChipComponent } from './event-chip/event-chip.component';
import { QueryBuilderModule } from 'common/libs/angular2-query-builder/angular2-query-builder.module';
import { LoadSvgPipe } from './svg/load-svg.pipe';
import { NgxPopperModule } from 'common/libs/ngx-popper/popper.module';

const commonComponents = [
    ChipComponent,
    AddressComponent,
    AlertComponent,
    AlertChipComponent,
    AccordionNewComponent,
    AccordionBodyComponent,
    AccordionHeaderComponent,
    BaseComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    ToastComponent,
    BoolToStatusPipe,
    TextFallbackPipe,
    TypeofPipe,
    BrandFilterComponent,
    ButtonComponent,
    ButtonDirective,
    CardDirective,
    CheckboxComponent,
    HvacRadioButtonComponent,
    DocumentListItemComponent,
    DownloadLinkComponent,
    OptionsMenuComponent,
    DropdownNavigationComponent,
    DropdownIconMenuComponent,
    ErrorPageComponent,
    FullPageLoaderComponent,
    ImageFallbackComponent,
    InputComponent,
    LinkDirective,
    LinkComponent,
    LoginPanelComponent,
    ModalHeaderComponent,
    ModalComponent,
    NavigationContainerComponent,
    NavigationNestedContainerComponent,
    NavigationPanelContainerComponent,
    NumberSuffixPipe,
    PasswordInputComponent,
    PlaceholderListItemComponent,
    PrimerFrameComponent,
    ProductCardComponent,
    ProductDescriptorComponent,
    ProductPropertyListComponent,
    ProductPropertyListItemComponent,
    ProductPropertyListItemLabelComponent,
    ProductPropertyListItemValueComponent,
    ProductListItemComponent,
    PartListItemComponent,
    ResultsListComponent,
    RadioButtonComponent,
    RadioGroupComponent,
    ScrollToTopButtonComponent,
    SignInTeaserComponent,
    SignupPanelComponent,
    SelectComponent,
    SelectMenuComponent,
    SelectNewComponent,
    ShareButtonComponent,
    SiteContentListItemComponent,
    SvgComponent,
    SystemProductCardComponent,
    SystemProductListItemComponent,
    TabBarComponent,
    TextAreaComponent,
    TableComponent,
    TooltipAnchorContentComponent,
    TooltipComponent,
    TooltipContentComponent,
    DocumentListItemComponent,
    SiteContentListItemComponent,
    AutoSuggestComponent,
    ContentSuggestionComponent,
    DocumentSuggestionComponent,
    LoadingSpinnerComponent,
    ProductSuggestionComponent,
    PartSuggestionComponent,
    SearchInputComponent,
    HeroComponent,
    WhatsNewComponent,
    FilterPipe,
    SegmentedButtonComponent,
    DistributorLocatorComponent,
    HvacMapComponent,
    DealerDropdownMenuComponent,
    PaginationComponent,
    SearchTextFilterPipe,
    SectionSelectComponent,
    NotificationComponent,
    JumpbarComponent,
    BaseLayoutComponent,
    ClickOutsideDirective,
    StatsWidgetComponent,
    DayPickerComponent,
    DataBlockComponent,
    HvacAutoCompleteComponent,
    MilitaryToStandard,
    ListComponent,
    DegreePipe,
    FormatphonenumberPipe,
    InputFileComponent,
    ToggleButtonComponent,
    DynamicNestedFilterComponent,
    DatePickerInputDatePipe,
    NumberStepperComponent,
    ProgressBarComponent,
    EventChipComponent,
    LoadSvgPipe
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPopperModule,
        TranslateModule,
        RichTextModule,
        PdfJsViewerModule,
        SafePipeModule,
        QueryBuilderModule
    ],
    declarations: commonComponents,
    exports: commonComponents,
    providers: [
        DegreePipe,
        NumberSuffixPipe
    ]
})
export class CommonComponentsModule { }
