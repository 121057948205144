import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { createAccountEmailInputValidator } from 'common/utils/createAccountValidators';
import { ConnectedDealer, DealerInput } from 'private/app/models/connected-dealer.model';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { catchError } from 'rxjs/operators';
import { AppConstants } from 'common/app-constants';
import { stringToBool } from 'private/app/services/connected-portal/utils';
import { ApiResponseCode } from 'private/app/views/connected-portal/constants';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'hvac-dealer-notification-settings',
    templateUrl: './dealer-notification-settings.component.html',
    styleUrls: ['./dealer-notification-settings.component.scss']
})
export class DealerNotificationSettingsComponent implements OnInit {
    @Input() dealerInfo: ConnectedDealer

    @Output() getData = new EventEmitter<string>()
    isLoading: boolean;

    public emailForm: UntypedFormGroup = new UntypedFormGroup({
        primaryEmail: new UntypedFormControl('', [createAccountEmailInputValidator(AppConstants.maxEmailLength, true, AppConstants.validEmailRegex)]),
        primaryCriticalAlertsEnabled: new UntypedFormControl(false),
        primaryMaintenanceEnabled: new UntypedFormControl(false),
        primaryControlOfflineEnabled: new UntypedFormControl(false),
        secondaryEmail: new UntypedFormControl('', [createAccountEmailInputValidator(AppConstants.maxEmailLength, false, AppConstants.validEmailRegex)]),
        secondaryCriticalAlertsEnabled: new UntypedFormControl(false),
        secondaryMaintenanceEnabled: new UntypedFormControl(false),
        secondaryControlOfflineEnabled: new UntypedFormControl(false)
    });

    constructor(
        private dealersService: DealersService,
        private toastService: ToastService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.setFormValues(this.emailForm, this.dealerInfo);
    }

    setFormValues(emailForm: UntypedFormGroup, dealerInfo: ConnectedDealer) {
        if (dealerInfo.notificationSettings?.primary?.email) {
            emailForm.controls.primaryEmail.setValue(dealerInfo.notificationSettings?.primary?.email, { emitEvent: true });
        }
        emailForm.controls.primaryCriticalAlertsEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.primary?.criticalAlertsEnabled!), { emitEvent: true });
        emailForm.controls.primaryMaintenanceEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.primary?.maintenanceEnabled!), { emitEvent: true });
        emailForm.controls.primaryControlOfflineEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.primary?.controlOfflineEnabled!), { emitEvent: true });

        if (dealerInfo.notificationSettings?.secondary?.email) {
            emailForm.controls.secondaryEmail.setValue(dealerInfo.notificationSettings?.secondary?.email, { emitEvent: true });
        }
        emailForm.controls.secondaryCriticalAlertsEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.secondary?.criticalAlertsEnabled!), { emitEvent: true });
        emailForm.controls.secondaryMaintenanceEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.secondary?.maintenanceEnabled!), { emitEvent: true });
        emailForm.controls.secondaryControlOfflineEnabled.setValue(stringToBool(dealerInfo.notificationSettings?.secondary?.controlOfflineEnabled!), { emitEvent: true });
    }

    onSubmit() {
        const formattedData: DealerInput = {
            notificationSettings: {
                primary: {
                    email: this.emailForm.value.primaryEmail,
                    criticalAlertsEnabled: this.emailForm.value.primaryCriticalAlertsEnabled,
                    controlOfflineEnabled: this.emailForm.value.primaryControlOfflineEnabled,
                    maintenanceEnabled: this.emailForm.value.primaryMaintenanceEnabled
                },
                secondary: {
                    email: this.emailForm.value.secondaryEmail,
                    criticalAlertsEnabled: this.emailForm.value.secondaryCriticalAlertsEnabled,
                    controlOfflineEnabled: this.emailForm.value.secondaryControlOfflineEnabled,
                    maintenanceEnabled: this.emailForm.value.secondaryMaintenanceEnabled
                }
            }
        };

        this.isLoading = true;
        this.dealersService.updateDealerById(this.dealerInfo.id, formattedData)
            .pipe(catchError((err: string) => {
                this.isLoading = false;
                throw err;
            })).subscribe((res) => {
                if (res?.code === ApiResponseCode.SUCCESS) {
                    this.toastService.add({
                        content: this.translate.instant('CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_UPDATED'),
                        theme: 'success',
                        closeable: true,
                        autoClose: true,
                        id: 'dealerNotificationSettingsToast',
                        bgColor: '#F8F8F8'
                    });
                }
                this.isLoading = false;
                this.getData.emit(this.dealerInfo.id);
            });
    }
}
