import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Toast } from 'common/services/toast.service';
import { SystemType } from 'private/app/models/connected-product.model';

@Component({
    selector: 'hvac-system-diagnostics-modal',
    templateUrl: './system-diagnostics-modal.component.html',
    styleUrls: ['./system-diagnostics-modal.component.scss']
})
export class SystemDiagnosticsModalComponent {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() isDiagnosticModalVisible: boolean = false;
    @Input() propertyId: string;
    @Input() systemType: SystemType;

    @Output() closeDiagnosticsTestModal = new EventEmitter<Toast>();

    public hideDiagnosticAttentionInfo = false;

    handleCloseDiagnosticsTestModal(toastMessage?: Toast | undefined) {
        this.closeDiagnosticsTestModal.emit(toastMessage);
        this.hideDiagnosticAttentionInfo = false;
    }

    handleCloseAttentionInfo() {
        this.hideDiagnosticAttentionInfo = true;
    }
}
