/* tslint:disable:max-line-length */
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Requirement } from 'common/components/input/passwordInput.component';
import { User } from 'common/models/auth';
import { ToastService } from 'common/services/toast.service';
import { AuthState } from 'common/store/auth/auth.state';
import { AccountState } from 'common/store/create-account.state';
import { createAccountPasswordValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { ResetPasswordStatus } from 'private/app/models/accountInfo';
import { LockUserAccountForm, RequestPasswordReset, ResetPasswordState, UnlockUserAccountForm } from 'private/app/store/create-account.actions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
/* tslint:enable:max-line-length */

export interface userDetails {
    User:string;
    profile:string;
    firstName:string;
    lastName:string;
}
@Component({
    selector: 'utc-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ChangePasswordComponent implements OnInit, OnDestroy {
    @Output() onValidChange = new EventEmitter<boolean>();

    @Select(AuthState.user) user$: Observable<User>;
    @Select(AccountState.validAccountInfo) validAccountInfo$: Observable<boolean>;
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AuthState.tokens) tokerns$: Observable<boolean>;
    @Select(AccountState.passwordResetStatus) passwordResetStatus$: Observable<ResetPasswordStatus>;

    ngOnDestroy$ = new Subject();
    infoValid$ = new BehaviorSubject(false);
    passwordRequirements$: Observable<Requirement[]>;
    errorMessages$ = this.translate.get('CHANGE_PASSWORD_INFORMATION.ERROR_MESSAGES');
    requirementMessages$: Observable<string> = this.translate.get('CHANGE_PASSWORD_INFORMATION.PASSWORD_REQUIREMENTS');
    passwordErrorMessage$: Observable<string>;

    defaultPasswordRequirements: { [key: string]: boolean } = {
        COMPLEXITY_REQUIREMENT: false,
        MIN_LENGTH: false,
        USERNAME_CANNOT_EQUAL_PASSWORD: false
    };

    passwordResetForm = new UntypedFormGroup({
        currentPasswordControl: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(51)]),
        newPasswordControl: new UntypedFormControl('', [createAccountPasswordValidator(), Validators.maxLength(51)])

    });

    activeUserName: string;
    showBanner: boolean;
    userName$: Observable<string>;


    constructor(
        private translate: TranslateService,
        private readonly store: Store,
        private readonly toastService: ToastService
    ) {
        this.userName$ = this.user$.pipe(map((userDetails: User) => ((userDetails && userDetails.profile) ? `${userDetails.profile.firstName} ${userDetails.profile.lastName}` : 'User Details')));
    }

    ngOnInit(): void {
        this.setForResetPassword();
        this.userName$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((userName: string) => {
            this.activeUserName = userName;
        });
        this.userName$ = this.user$.pipe(map((userDetails: User) => ((userDetails && userDetails.profile) ?
            `${userDetails.profile.firstName} ${userDetails.profile.lastName}`
            : 'User Details')));

        this.passwordRequirements$ = this.passwordResetForm.controls.newPasswordControl.valueChanges.pipe(
            withLatestFrom(this.requirementMessages$),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map(([value, message]: any) => {
                const errors: { [key: string]: boolean } = {
                    ...this.defaultPasswordRequirements,
                    ...this.passwordResetForm.controls.newPasswordControl.errors
                };
                if (this.activeUserName === value) {
                    errors.USERNAME_CANNOT_EQUAL_PASSWORD = true;
                }
                if (!value) {
                    errors.USERNAME_CANNOT_EQUAL_PASSWORD = true;
                }

                return Object.keys(errors).map((validReq) => ({
                    valid: !errors[validReq],
                    message: message[validReq]
                }));
            })
        );

        this.passwordErrorMessage$ = combineLatest([this.passwordResetForm.controls.newPasswordControl.valueChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.passwordResetForm.controls.passwordControl.errors, message))
        );

        this.passwordResetForm.statusChanges.subscribe((isValid) => {
            if (isValid === 'VALID' && this.passwordResetForm.controls.newPasswordControl.value.length > 0 && this.passwordResetForm.controls.currentPasswordControl.value.length > 0) {
                this.infoValid$.next(true);
            }
            else {
                this.infoValid$.next(false);
            }
        });

        this.passwordResetStatus$.pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((status: ResetPasswordStatus) => {
                this.store.dispatch(new UnlockUserAccountForm());
                if (status === ResetPasswordStatus.Success) {
                    this.toastService.add({
                        content: 'Your password has been updated.',
                        theme: 'success',
                        id: 'passwordResetStatus',
                        autoClose: true
                    });
                    this.store.dispatch(new Navigate(['/']));
                    this.showBanner = false;
                }
                else if (status === ResetPasswordStatus.Error) {
                    this.showBanner = true;
                }
                else {
                    this.showBanner = false;
                }
            });
    }

    setForResetPassword() {
        this.store.dispatch(new ResetPasswordState());
    }

    submitAccountInfo() {
        this.store.dispatch(new LockUserAccountForm());

        this.toastService.remove('passwordResetStatus');

        this.store.dispatch(
            new RequestPasswordReset(
                this.passwordResetForm.controls.currentPasswordControl.value, this.passwordResetForm.controls.newPasswordControl.value
            )
        );
    }

    gotoDashboard() {
        this.store.dispatch(new Navigate(['/']));
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
