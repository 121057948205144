import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { ProfileDetails, ResponseProfileUpdate, ResponseSubscription, Subscription } from 'private/app/models/user-validation';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Option } from '../../../../common/components/select/select.component';
import { environment } from 'common/environments/environment';
import { UntypedFormGroup } from '@angular/forms';

export interface Country {
    name: string;
    value: string;
}

export interface Brand {
    name: string;
    value: string;
    usageType: Array<string>;
}

@Injectable({ providedIn: 'root' })
export class UserValidationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {

    }

    setUserDetails(userInfoForm: UntypedFormGroup, profileInfo: ProfileDetails[]) {
        userInfoForm.controls.userIdControl.setValue(profileInfo[0].userID, { emitEvent: true });
        userInfoForm.controls.myHvacPinControl.setValue(profileInfo[0].hvacPin, { emitEvent: true });
        userInfoForm.controls.employeeIdControl.setValue(profileInfo[0].id, { emitEvent: true });
        userInfoForm.controls.employeeNameControl.setValue(profileInfo[0].userTitle, { emitEvent: true });
        userInfoForm.controls.usernameControl.setValue(profileInfo[0].userID, { emitEvent: true });
        userInfoForm.controls.firstNameControl.setValue(profileInfo[0].firstName, { emitEvent: true });
        userInfoForm.controls.lastNameControl.setValue(profileInfo[0].lastName, { emitEvent: true });
        userInfoForm.controls.address1Control.setValue(profileInfo[0].userAddress1, { emitEvent: true });
        userInfoForm.controls.address2Control.setValue(profileInfo[0].userAddress2, { emitEvent: true });
        userInfoForm.controls.cityControl.setValue(profileInfo[0].userCity, { emitEvent: true });
        userInfoForm.controls.zipControl.setValue(profileInfo[0].userPostalCode, { emitEvent: true });
        userInfoForm.controls.phoneControl.setValue(profileInfo[0].userPhone, { emitEvent: true });
        userInfoForm.controls.cellControl.setValue(profileInfo[0].userCellPhone, { emitEvent: true });
        userInfoForm.controls.faxControl.setValue(profileInfo[0].userFax, { emitEvent: true });
        userInfoForm.controls.emailControl.setValue(profileInfo[0].email, { emitEvent: true });
    }

    getProfileDetails(userId: string): Observable<ProfileDetails[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<ProfileDetails[]>(`${environment.api.profileDetails}?userId=${userId}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateUserProfileDetails(profile: ProfileDetails): Observable<ResponseProfileUpdate> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<ResponseProfileUpdate>(environment.api.profileDetails, profile, {
                ...options,
                responseType: 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public createSelectOptions(options: string[], nameProp: string, valueProp: string): Option[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return options.map((option: any) => ({
            name: option[nameProp],
            value: option[valueProp]
        }));
    }

    getSubscriptionStatus(): Observable<Subscription[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<Subscription[]>(environment.api.subscription, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    deleteSubscription(): Observable<ResponseSubscription> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.delete<ResponseSubscription>(`${environment.api.subscription}`,
                {
                    ...options,
                    responseType: 'json'
                })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    createSubscription(): Observable<ResponseSubscription> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<ResponseSubscription>(environment.api.subscription, {}, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
