import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'private/app/components/components.module';
import { CreateAccountLookupComponent } from './createAccountLookup.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        TranslateModule,
        SafePipeModule
    ],
    declarations: [
        CreateAccountLookupComponent
    ]
})
export class CreateAccountLookupModule { }
