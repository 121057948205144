import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PersonasService } from '../../services/personas.service';
import { map } from 'rxjs/operators';

export interface Persona {
    ILLUSTRATION: string;
    ICON: string;
    NAME: string;
    MORE_DETAILS: string;
    BENEFITS: {
        TITLE: string;
        DESCRIPTION?: string;
        LIST?: Array<string>;
        ICON: string;
    }[];
}

@Component({
    selector: 'hvac-personas',
    templateUrl: './personas.component.html',
    styleUrls: ['./personas.component.scss'],
    providers: [
        PersonasService
    ],
    encapsulation: ViewEncapsulation.None
})
export class PersonasComponent {
    personas$: Observable<Persona[]> = this.translations.get('LOGIN_PAGE.PERSONAS');
    selectedPersona$: Observable<number|null> = this.personasService.section$;
    showDetails$: Observable<boolean> = this.selectedPersona$.pipe(
        map((sectionId: number|null) => sectionId !== null)
    );

    constructor(
        private readonly translations: TranslateService,
        private readonly personasService: PersonasService
    ) {}
}
