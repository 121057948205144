import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { warrantyEntitlementData } from 'common/mock-data/warrenty-api-res';
import { WarrantyEntitlementResponse, WarrantyDetails, WarrantyData, WarrantyInfo } from 'common/models/product-warranty';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class ProductWarrantyService {
    constructor(private translate: TranslateService) { }

    getWarrantyEntitlements(serialNo: string): Observable<WarrantyInfo> {
        if (!serialNo) {
            throw new Error('getWarrantyEntitlements: no serial number provided');
        }

        return of(warrantyEntitlementData).pipe(map((data) => {
            const generalSectionData: (WarrantyDetails | undefined)[] = [
                this.formatEntitlementOverviewData(data),
                this.formatServiceContractData(data)
            ];

            return ({
                generalSection: generalSectionData.filter((genData) => genData) as WarrantyDetails[],
                coveredParts: this.formatCoveredPartsData(data)
            });
        }));
    }

    private formatEntitlementOverviewData(data: WarrantyEntitlementResponse): WarrantyDetails | undefined {
        let entitlementOverviewDetails: WarrantyDetails | undefined;
        const entitlementOverviewData: WarrantyData[] = [];
        const entitlementOverviewTitle = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.TITLE');
        const entitlementOverview = data.entitlementData.find((entData) => entData.header === entitlementOverviewTitle);
        if (entitlementOverview && entitlementOverview.detail && entitlementOverview.detail.length > 0) {
            const entitlementOverviewProps = [
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.OWNER.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.DATE_INSTALLED.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.DATE_TRANSFERED.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.POLICY_DESCRIPTION.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.MARK_AS.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.SHIPPED_DATE.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.REPLACEMENT_OF_MODEL.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.ENTITLEMENT_OVERVIEW.PROPERTIES.REPLACEMENT_OF_SERIAL.API_KEY'
            ];
            const labels = this.translate.instant(entitlementOverviewProps);
            entitlementOverviewProps.forEach((propKey) => {
                const detail = entitlementOverview.detail?.find((elm) => elm.dataKey === labels[propKey]);
                if (detail) {
                    entitlementOverviewData.push({
                        key: this.getPropertyLabel(propKey),
                        value: detail.dataDescription
                    });
                }
            });
            entitlementOverviewDetails = {
                title: entitlementOverviewTitle,
                data: entitlementOverviewData
            };
        }

        return entitlementOverviewDetails;
    }

    private formatServiceContractData(data: WarrantyEntitlementResponse): WarrantyDetails | undefined {
        let serviceContractDetails: WarrantyDetails | undefined;
        const serviceContractData: WarrantyData[] = [];
        const serviceContractTitle = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.TITLE');
        const serviceContract = data.entitlementData.find((entData) => entData.header === serviceContractTitle);
        if (serviceContract && serviceContract.detail && serviceContract.detail.length > 0) {
            const serviceContractProps = [
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.CONTRACT_NUMBER.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.PROVIDER_NAME.API_KEY'
            ];
            const serviceContractDetailExtProps = [
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.THIRD_PARTY_CONTACT_NUMBER.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.PLAN_NUMBER.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.PLAN_TYPE.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.COVERAGE_START.API_KEY',
                'CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.COVERAGE_END.API_KEY'
            ];
            const labels = this.translate.instant(serviceContractProps);
            const detailExtLabels = this.translate.instant(serviceContractDetailExtProps);
            const detailExtKey = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.SERVICE_CONTRACT.PROPERTIES.PLAN_INFO');
            const detailExtData = serviceContract.detail.find((elm) => elm.dataKey === detailExtKey);
            serviceContractProps.forEach((propKey) => {
                const detail = serviceContract.detail?.find((elm) => elm.dataKey === labels[propKey]);
                if (detail) {
                    serviceContractData.push({
                        key: this.getPropertyLabel(propKey),
                        value: detail.dataDescription
                    });
                }
            });

            if (detailExtData && detailExtData.detailExt && detailExtData.detailExt.length > 0) {
                serviceContractDetailExtProps.forEach((propKey) => {
                    const detail = detailExtData.detailExt?.find((elm) => elm.extDataKey === detailExtLabels[propKey]);
                    if (detail) {
                        serviceContractData.push({
                            key: this.getPropertyLabel(propKey),
                            value: detail.extDataDescription
                        });
                    }
                });
            }

            serviceContractDetails = {
                title: serviceContractTitle,
                data: serviceContractData
            };
        }

        return serviceContractDetails;
    }

    private getPropertyLabel(apiKey: string) {
        let propLabel = apiKey;
        if (apiKey) {
            const propLabels = apiKey.split('.');
            propLabels.pop();
            propLabels.push('LABEL');
            propLabel = propLabels.join('.');
        }

        return propLabel;
    }

    private formatCoveredPartsData(data: WarrantyEntitlementResponse): WarrantyDetails[] {
        const coveredPartsWarrantyDetails: WarrantyDetails[] = [];
        const entitlementWarrantyLookupKey = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.LOOKUP_KEY');
        const detailsDataFilterKey: string = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.FILTER_KEY');
        const entitlementWarrantyInfo = data.entitlementData.find((warrantyInfo) => warrantyInfo.header === entitlementWarrantyLookupKey);
        if (entitlementWarrantyInfo && entitlementWarrantyInfo.detail && entitlementWarrantyInfo.detail.length > 0) {
            const coveredPartsDetails = entitlementWarrantyInfo.detail?.filter((details) => Boolean(Object.getOwnPropertyDescriptor(details, detailsDataFilterKey)));
            if (coveredPartsDetails && coveredPartsDetails.length > 0) {
                const coveredPartsProps = [
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.BRAND.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.APPLICATION_TYPE.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.ORIGINAL_EQUIPMENT_OWNER.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.WARRANTY_LENGTH.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.INSTALLED_AFTER.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.WARRANTY_START.API_KEY',
                    'CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.WARRANTY_STOP.API_KEY'
                ];
                const apiKeys = this.translate.instant(coveredPartsProps);
                coveredPartsDetails.forEach((cpDetail) => {
                    const coverdPartsDisplayData: WarrantyData[] = [];
                    const coveredPartTitleKey = this.translate.instant('CONNECTED_PORTAL.PRODUCT_WARRANTY.COVERED_PARTS.PROPERTIES.COMPONENT_CODE');
                    const coveredPartTitle = cpDetail.detailExt?.find((elm) => elm.extDataKey === coveredPartTitleKey)?.extDataDescription;
                    coveredPartsProps.forEach((propKey) => {
                        const detail = cpDetail.detailExt?.find((elm) => elm.extDataKey === apiKeys[propKey]);
                        if (detail) {
                            coverdPartsDisplayData.push({
                                key: this.getPropertyLabel(propKey),
                                value: detail.extDataDescription
                            });
                        }
                    });

                    coveredPartsWarrantyDetails.push({
                        title: coveredPartTitle ? coveredPartTitle : '',
                        data: coverdPartsDisplayData
                    });
                });
            }
        }

        return coveredPartsWarrantyDetails;
    }
}
