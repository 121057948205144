import { WarrantyEntitlementResponse } from 'common/models/product-warranty';

/* eslint-disable max-lines */
export const warrantyEntitlementData: WarrantyEntitlementResponse = {
    entitlementData: [
        {
            header: 'Entitlement Overview',
            detail: [
                {
                    dataKey: 'version',
                    dataDescription: '1.0'
                },
                {
                    dataKey: 'productName',
                    dataDescription: 'FURNACE 80% 110M'
                },
                {
                    dataKey: 'modelNumber',
                    dataDescription: 'N8MSN1102122A'
                },
                {
                    dataKey: 'discreteModelNumber',
                    dataDescription: 'N8MSN1102122A3'
                },
                {
                    dataKey: 'serialNumber',
                    dataDescription: 'A152921933'
                },
                {
                    dataKey: 'owner',
                    dataDescription: 'Franklin Heating & COoling, Inc. - Harvison, BJ'
                },
                {
                    dataKey: 'equipmentInstallationAddress',
                    dataDescription: '105 Cliffe Run,Franklin,TN37067,US'
                },
                {
                    dataKey: 'dateInstalled',
                    dataDescription: '09/14/2015'
                },
                {
                    dataKey: 'warrantyPolicyCode',
                    dataDescription: 'IGF801000'
                },
                {
                    dataKey: 'warrantyPolicyDescription',
                    dataDescription: 'FOR SPECIFIC COVERAGE ON NON-REGISTERED UNITS REFER TO WARRANTY CERTIFICATE '
                },
                {
                    dataKey: 'standardLaborWarrantyExpirationDate',
                    dataDescription: ''
                },
                {
                    dataKey: 'standardLaborWarrantyExpirationDate',
                    dataDescription: ''
                },
                {
                    dataKey: 'dateTransferred',
                    dataDescription: ''
                },
                {
                    dataKey: 'registeredOnTime',
                    dataDescription: 'Yes'
                },
                {
                    dataKey: 'enhancedWarranty',
                    dataDescription: 'Yes'
                },
                {
                    dataKey: 'UTCPropertyId',
                    dataDescription: ''
                },
                {
                    dataKey: 'transferDate',
                    dataDescription: ''
                },
                {
                    dataKey: 'markedAs',
                    dataDescription: ''
                },
                {
                    dataKey: 'shippedDate',
                    dataDescription: '07/24/2015'
                },
                {
                    dataKey: 'soldToDistributorName',
                    dataDescription: 'M & A SUPPLY COMPANY INC.'
                },
                {
                    dataKey: 'soldToDistributorNumber',
                    dataDescription: '406192'
                },
                {
                    dataKey: 'soldToDistributorCity',
                    dataDescription: 'NASHVILLE'
                },
                {
                    dataKey: 'soldToDistributorState',
                    dataDescription: 'TN'
                },
                {
                    dataKey: 'replacementOfModel',
                    dataDescription: ''
                },
                {
                    dataKey: 'replacementOfSerialNumber',
                    dataDescription: ''
                },
                {
                    dataKey: 'brand',
                    dataDescription: 'ICP'
                }
            ]
        },
        {
            header: 'Entitlement Warranty Info',
            detail: [
                {
                    dataKey: 'labelProperties',
                    dataDescription: 'standardWarranty'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'Original'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Primary Heat Exch- Furnace'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '20 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2035'
                        }
                    ],
                    dataDescription: '1'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'Original'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Secondary Heat Exch-Furnace'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '20 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2035'
                        }
                    ],
                    dataDescription: '2'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'Original'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'SPP Heat Exch Aluminized'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '20 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2035'
                        }
                    ],
                    dataDescription: '3'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'Original'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Enhanced Parts Warranty'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '10 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2025'
                        }
                    ],
                    dataDescription: '4'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'ALL'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Primary Heat Exch- Furnace'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '20 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2035'
                        }
                    ],
                    dataDescription: '5'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'ALL'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Secondary Heat Exch-Furnace'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '20 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2035'
                        }
                    ],
                    dataDescription: '6'
                },
                {
                    dataKey: 'lineNo',
                    detailExt: [
                        {
                            extDataKey: 'brand',
                            extDataDescription: 'ICP'
                        },
                        {
                            extDataKey: 'applicationType',
                            extDataDescription: 'Owner Occupied Residential'
                        },
                        {
                            extDataKey: 'originalEquipmentOwner',
                            extDataDescription: 'ALL'
                        },
                        {
                            extDataKey: 'componentCode',
                            extDataDescription: 'Standard Parts Warranty'
                        },
                        {
                            extDataKey: 'warrantyLength',
                            extDataDescription: '5 years'
                        },
                        {
                            extDataKey: 'installedAfter',
                            extDataDescription: '04/01/2010'
                        },
                        {
                            extDataKey: 'warrantyStart',
                            extDataDescription: '09/14/2015'
                        },
                        {
                            extDataKey: 'warrantyStop',
                            extDataDescription: '09/14/2020'
                        }
                    ],
                    dataDescription: '7'
                }
            ]
        },
        { header: 'Entitlement Standard Warranty Info' },
        { header: 'Entitlement Extended Warranty Info' },
        {
            header: 'Service Contract Info',
            detail: [
                {
                    dataKey: 'contractNumber',
                    dataDescription: 'TN3145558215'
                },
                {
                    dataKey: 'cancelReason',
                    dataDescription: ''
                },
                {
                    dataKey: 'modelNumber',
                    dataDescription: 'N8MSN1102122A'
                },
                {
                    dataKey: 'modelName',
                    dataDescription: 'SPLITAC'
                },
                {
                    dataKey: 'serialNumber',
                    dataDescription: 'A152921933'
                },
                {
                    dataKey: 'owner',
                    dataDescription: 'Harvison, B.J.'
                },
                {
                    dataKey: 'address',
                    dataDescription: '105 Cliff Run,Franklin, TN 37067 US'
                },
                {
                    dataKey: 'phone',
                    dataDescription: '615-771-0784'
                },
                {
                    dataKey: 'serviceProviderId',
                    dataDescription: '791523016'
                },
                {
                    dataKey: 'serviceProviderName',
                    dataDescription: 'Franklin Heating & Cooling'
                },
                {
                    dataKey: 'dateOfPurchase',
                    dataDescription: ''
                },
                {
                    dataKey: 'productInstallDate',
                    dataDescription: '10/19/2015'
                },
                {
                    dataKey: 'planInfo',
                    detailExt: [
                        {
                            extDataKey: 'thirdPartyContractNumber',
                            extDataDescription: '145929758'
                        },
                        {
                            extDataKey: 'PlanNumber',
                            extDataDescription: '211178'
                        },
                        {
                            extDataKey: 'PlanType',
                            extDataDescription:
                                'Labor Only Plans- For detailed coverage information or other questions regarding this optional contract,\n please contact AIG Warranty at 1-866-538-8922, email \nhvacsupport@aig.com, or go to www.climateshieldcomplete.com'
                        },
                        {
                            extDataKey: 'PlanName',
                            extDataDescription: ''
                        },
                        {
                            extDataKey: 'CoverageStart',
                            extDataDescription: '11/19/2015'
                        },
                        {
                            extDataKey: 'CoverageEnd',
                            extDataDescription: '10/17/2020'
                        }
                    ],
                    dataDescription: 'PlanDetails'
                }
            ]
        },
        { header: 'Service Bulletins Info' },
        {
            header: 'Entitlement Policy Statement',
            detail: [
                {
                    dataKey: 'policyStatement',
                    dataDescription: ''
                }
            ]
        },
        { header: 'Preauthorization' }
    ]
};

