import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/company.model';
import { ConnectedProduct, ProductType } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { WallControlStatus } from 'private/app/models/wall-control.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, delay, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MISSING_PRODUCT_DATA, MISSING_REQUIRED_PROPERTY } from '../../constants';
import { FactorySystemDetails } from './components/factory-system-details/factory-system-details.component';
import { SystemOverviewSearchCriteria } from './components/factory-system-search/factory-system-search.component';


enum ContentView {
    SystemOverview = 'overview',
    EngineeringInsights = 'engineering'
}

@Component({
    selector: 'hvac-factory-systems-overview',
    templateUrl: './factory-systems-overview.component.html',
    styleUrls: ['./factory-systems-overview.component.scss']
})
export class FactorySystemsOverviewComponent implements OnInit {
    public readonly CompanyCode = CompanyCode;
    public readonly ContentView = ContentView;

    public companyCode$: Observable<CompanyCode>;
    public breadcrumbs: ContentHeadingData;
    public systemType$: Observable<string>;
    public contentView$ = new BehaviorSubject(ContentView.SystemOverview);
    public searchTypeOptionsFormInitialValue: Record<string, unknown> | null = null;
    public system$: Observable<ConnectedSystem>;
    public wallControl$: Observable<ConnectedProduct>;
    public wallControlStatus$: Observable<WallControlStatus>;
    public isWallControlConnected$: Observable<boolean>;
    public systemSearch$: Subject<SystemOverviewSearchCriteria> = new Subject<SystemOverviewSearchCriteria>();
    public systemDetails$: Observable<FactorySystemDetails>;
    public navButtonLabel$: Observable<string>;
    public searchError$ = new Subject<string | null>();
    public wallControlSerialNo$: Observable<string>;
    public isLoading = {
        system: false,
        wallControlStatus: false
    };

    public isLoaded = { system: false };

    constructor(
        private cpUserService: ConnectedPortalUserService,
        private translateService: TranslateService,
        private productService: ProductService
    ) { }

    ngOnInit(): void {
        this.companyCode$ = this.cpUserService.getCompanyCode().pipe(
            map((code) => code as CompanyCode)
        );

        this.navButtonLabel$ = this.contentView$.pipe(
            map((content) => (content === ContentView.SystemOverview
                ? this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.SYSTEM_INSIGHTS')
                : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.ENGINEERING_INSIGHTS')))
        );

        this.breadcrumbs = this.getBreadcrumbData();

        this.system$ = this.systemSearch$.pipe(
            tap(() => {
                this.isLoading.system = true;
            }),
            switchMap(({ serialNo, unitType }) => this.productService.querySystemBySerialNo(serialNo, unitType).pipe(
                map(({ data }) => (data)),
                tap(() => {
                    this.isLoaded.system = true;
                    this.isLoading.system = false;
                    this.searchError$.next(null);
                }),
                catchError(() => {
                    this.isLoading.system = false;
                    this.searchError$.next(this.translateService.instant('CONNECTED_PORTAL.FACTORY_SYSTEM_OVERVIEW.SEARCH_ERROR'));

                    return EMPTY;
                })
            )),
            shareReplay()
        );

        this.wallControl$ = this.system$.pipe(
            map((system) => {
                const wallControl = system?.products.find((product) => product.type === ProductType.WallControl);

                if (wallControl) {
                    return wallControl;
                }

                throw new Error(MISSING_PRODUCT_DATA);
            })
        );

        this.wallControlStatus$ = this.wallControl$.pipe(
            tap(() => {
                this.isLoading.wallControlStatus = true;
            }),
            switchMap((wallControl) => {
                const { serialNo, systemType } = wallControl;

                return this.productService
                    .queryWallControlStatusBySerialNo(serialNo, systemType)
                    .pipe(
                        delay(5000),
                        map((res) => res.data),
                        tap(() => {
                            this.isLoading.wallControlStatus = false;
                        }),
                        catchError(() => {
                            this.isLoading.wallControlStatus = false;

                            return EMPTY;
                        })
                    );
            })
        );

        this.wallControlSerialNo$ = this.wallControl$.pipe(
            map((wallControl) => {
                const serial = wallControl.serialNo || wallControl.esn;

                if (serial) {
                    return serial;
                }

                throw new Error(MISSING_REQUIRED_PROPERTY);
            })
            // startWith('415535147885')
        );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (!status.isDisconnected)));

        this.systemDetails$ = this.system$.pipe(
            map((system) => {
                const productData = system.products;

                if (productData) {
                    return { productInfo: productData };
                }

                throw new Error(MISSING_PRODUCT_DATA);
            })
        );
    }

    handleContentChange() {
        if (this.contentView$.value === ContentView.SystemOverview) {
            this.contentView$.next(ContentView.EngineeringInsights);
        }
        else {
            this.contentView$.next(ContentView.SystemOverview);
        }
    }

    handleSearchUpdate($event: SystemOverviewSearchCriteria) {
        this.systemSearch$.next($event);
    }

    handleSearchReset() {
        this.searchError$.next(null);
    }

    getBreadcrumbData(): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.SYSTEMS_OVERVIEW') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal/dashboard'
                }
            ]
        };
    }
}
