/* eslint-disable max-lines */
import { Injectable, Injector } from '@angular/core';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedFactoryStatsResponse } from 'private/app/models/connected-factory.model';
import { Observable } from 'rxjs';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { HttpErrorResponse } from '@angular/common/http';
import { gql } from 'apollo-angular';
import { ConnectedControlsReportForFactoryResponse, FactoryCustomerLoginsReportResponse, FaultsReportForFactoryResponse, FADReportResponse, SystemDataEventsResponse, SystemDataEventsMinMaxResponse } from 'private/app/models/factory.model';
import { ApiResponseCode } from 'private/app/views/connected-portal/constants';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { SystemAlertsResponse } from 'private/app/models/alert.model';

export const QUERY_FACTORY_STATS = gql`
    query QueryFactoryStats{
        queryFactoryStats{
            data {
                connectedControlCount,
                queuedControlCount,
                connectedDealers,
                connectedCustomerCount,
            }
        }
    }
`;

export const QUERY_CUSTOMER_LOGINS_FOR_FACTORY = gql`
    query QueryCustomerLoginsForFactory($year: Float!) {
        queryCustomerLoginsForFactory(year: $year){
            data {
                dataSummary {
                    dateTime
                    dealerCount
                    distributorCount
                }
                filepath
            }
        }
    }
`;

export const QUERY_CUSTOMER_LOGINS_FOR_FACTORY_FILE_ONLY = gql`
    query QueryCustomerLoginsForFactory($year: Float!) {
        queryCustomerLoginsForFactory(year: $year){
            data {
                filepath
            }
        }
    }
`;

export const QUERY_CONNECTED_CONTROLS_FOR_FACTORY = gql`
    query QueryConnectedControlsForFactory($year: Float!) {
        queryConnectedControlsForFactory(year: $year) {
            data {
                dataSummary {
                    dateTime
                    controlCount
                }
                filepath
            }
        }
    }
`;

const relayDataEvents = `
    thermostatDataEvents {
        date
        Y1
        Y2
        W1
        W2
        C
        G
        OB
        RC
    }
`;

const thermostatDataEvents = `
    thermostatDataEvents {
        date
        Y1
        Y2
        W1
        W2
        C
        G
        OB
        RC
        CSP
        HSP
        IT
        OT
        IH
        OH
    }
`;

const dataMinMax = `
    dataMinMax {
        CSP {
            min
            max
        }
        HSP {
            min
            max
        }
        IT {
            min
            max
        }
        OT {
            min
            max
        }
        IH {
            min
            max
        }
        OH {
            min
            max
        }
        RPM {
            min
            max
        }
        deltaT {
            min
            max
        }
        LLP {
            min
            max
        }
        LLT {
            min
            max
        }
        OAT {
            min
            max
        }
        RAT {
            min
            max
        }
        SC {
            min
            max
        }
        SLP {
            min
            max
        }
        SLT {
            min
            max
        }
        SH {
            min
            max
        }
        SAT {
            min
            max
        }
    }
`;

const sensorDataEvents = `
    sensorDataEvents {
        date
        RPM
        deltaT
        LLP
        LLT
        OAT
        RAT
        SC
        SLP
        SLT
        SH
        SAT
    }
`;


export const QUERY_SYSTEM_DATA_EVENTS_FOR_FACTORY = gql`
    query QuerySystemDataEventsBySerialNo($id: String!, $params: StartEndDateInputMaxItemExportFile!) {
        querySystemDataEventsBySerialNo(id: $id, params: $params) {
            code
            message
            data {
                ${thermostatDataEvents}
                ${dataMinMax}
                ${sensorDataEvents}
                filepath
            }
        }
    }
`;

export const QUERY_SYSTEM_DATA_EVENTS_MIN_MAX_FOR_FACTORY = gql`
    query QuerySystemDataEventsMinMaxBySerialNo($id: String!, $params: StartEndDateInputMaxItemExportFile!) {
        querySystemDataEventsBySerialNo(id: $id, params: $params) {
            code
            message
            data {
                ${relayDataEvents}
                ${dataMinMax}
            }
        }
    }
`;

export const QUERY_FAD_REPORT_FOR_FACTORY = gql`
    query QueryFADReport($month: Float!, $year: Float!) {
        queryFADReport(month: $month, year: $year) {
            data {
                filepath
            }
        }
    }
`;

export const QUERY_FAULT_REPORT_FOR_FACTORY = gql`
    query QueryFaultReportForFactory($year: Float!, $systemId: String!) {
        queryFaultReportForFactory(year: $year, systemId: $systemId){
            data {
                faultSource {
                    wallControl {
                        malFunctionCount,
                        notificationCount
                    }
                    indoorUnit {
                        malFunctionCount,
                        notificationCount
                    }
                    outdoorUnit {
                        malFunctionCount,
                        notificationCount
                    }
                }
                faultSeverity {
                    criticalPercent
                    moderatePercent
                    informationalPercent
                }
            }
        }
    }
`;

export const QUERY_SYSTEM_FAULTS_BY_SYSTEM_ID = gql`
    query QuerySystemFaultsBySystemId($id: String!, $params: DefaultQueryParams) {
        querySystemFaultsBySystemId(id: $id, params: $params) {
            totalCount
            totalPages
            data {
                id,
                dateTime,
                occurrenceCount,
                dealerId,
                wallControlSerialNo,
                source,
                error {
                    code,
                    description,
                    severity,
                    troubleshootUri,
                    type,
                    isActive
                },
                product {
                    name,
                    modelNo
                    serialNo
                    type
                    systemType
                }
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class FactoryService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryFactoryStats(): Observable<ConnectedFactoryStatsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryFactoryStats').query<{ queryFactoryStats: ConnectedFactoryStatsResponse }>({
                query: QUERY_FACTORY_STATS,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryFactoryStats;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryCustomerLoginsForFactory(year: number, fileOnly = false): Observable<FactoryCustomerLoginsReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryCustomerLoginsForFactory').query<{ queryCustomerLoginsForFactory: FactoryCustomerLoginsReportResponse }>({
                query: fileOnly ? QUERY_CUSTOMER_LOGINS_FOR_FACTORY_FILE_ONLY : QUERY_CUSTOMER_LOGINS_FOR_FACTORY,
                variables: { year },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryCustomerLoginsForFactory;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryConnectedControlsForFactory(year: number): Observable<ConnectedControlsReportForFactoryResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryConnectedControlsForFactory').query<{ queryConnectedControlsForFactory: ConnectedControlsReportForFactoryResponse }>({
                query: QUERY_CONNECTED_CONTROLS_FOR_FACTORY,
                variables: { year },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryConnectedControlsForFactory;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryFaultReportForFactory(year: number, systemId: string): Observable<FaultsReportForFactoryResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryFaultReportForFactory').query<{ queryFaultReportForFactory: FaultsReportForFactoryResponse }>({
                query: QUERY_FAULT_REPORT_FOR_FACTORY,
                variables: {
                    year,
                    systemId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryFaultReportForFactory;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryFADReport(month: number, year: number): Observable<FADReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryFadReportForFactory').query<{ queryFADReport: FADReportResponse }>({
                query: QUERY_FAD_REPORT_FOR_FACTORY,
                variables: {
                    month,
                    year
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data } = value.data.queryFADReport;

                    return { data };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public querySystemDataEventsMinMaxValuesBySerialNo(id: String, params: { startDate: String, endDate: String, maxItemCount: number, generateExportFile: string }): Observable<SystemDataEventsMinMaxResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('querySystemDataEventsBySerialNo').query<{ querySystemDataEventsBySerialNo: SystemDataEventsResponse }>({
                query: QUERY_SYSTEM_DATA_EVENTS_MIN_MAX_FOR_FACTORY,
                variables: {
                    id,
                    params
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data, code, message } = value.data.querySystemDataEventsBySerialNo;

                    if (code !== ApiResponseCode.SUCCESS) {
                        throw new HttpErrorResponse({
                            status: code,
                            statusText: message
                        });
                    }

                    const minMaxTransformed = Object.entries(data.dataMinMax).reduce((acc, [key, val]) => {
                        if (val === null) {
                            acc[key] = {
                                min: null,
                                max: null
                            };
                        }
                        else {
                            acc[key] = val;
                        }

                        return acc;
                    }, {} as {[key: string]: {min: number | null, max: number | null}});


                    return {
                        code,
                        message,
                        data: {
                            dataMinMax: minMaxTransformed,
                            thermostatDataEvents: data.thermostatDataEvents
                        }
                    };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                if (err.status !== ApiResponseCode.NO_CONTENT) {
                    this.showErrorToast(err);
                }

                throw err;
            })
        );
    }

    public querySystemDataEventsBySerialNo(id: String, params: { startDate: String, endDate: String, maxItemCount: number, generateExportFile: string }): Observable<SystemDataEventsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('querySystemDataEventsBySerialNo').query<{ querySystemDataEventsBySerialNo: SystemDataEventsResponse }>({
                query: QUERY_SYSTEM_DATA_EVENTS_FOR_FACTORY,
                variables: {
                    id,
                    params
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { data, code, message } = value.data.querySystemDataEventsBySerialNo;

                    if (code !== ApiResponseCode.SUCCESS) {
                        throw new HttpErrorResponse({
                            status: code,
                            statusText: message
                        });
                    }

                    const minMaxTransformed = Object.entries(data.dataMinMax).reduce((acc, [key, val]) => {
                        if (val === null) {
                            acc[key] = {
                                min: null,
                                max: null
                            };
                        }
                        else {
                            acc[key] = val;
                        }

                        return acc;
                    }, {} as { [key: string]: { min: number | null, max: number | null } });

                    const sortedThermostatDataEvents = data.thermostatDataEvents.sort((itemA, itemB) => itemA.date.localeCompare(itemB.date));
                    const sortedSensorDataEvents = data.sensorDataEvents.sort((itemA, itemB) => itemA.date.localeCompare(itemB.date));

                    const transformed = {
                        ...data,
                        dataMinMax: minMaxTransformed,
                        thermostatDataEvents: sortedThermostatDataEvents,
                        sensorDataEvents: sortedSensorDataEvents
                    };

                    return {
                        code,
                        message,
                        data: transformed
                    };
                })
            )),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((err: any) => {
                if (err?.status !== ApiResponseCode.NO_CONTENT) {
                    this.showErrorToast(err);
                }

                throw err;
            })
        );
    }

    public querySystemFaultsBySystemId(systemId: string, queryParams?: DefaultQueryParams): Observable<SystemAlertsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('querySystemFaultsBySystemId').query<{ querySystemFaultsBySystemId: SystemAlertsResponse }>({
                query: QUERY_SYSTEM_FAULTS_BY_SYSTEM_ID,
                variables: {
                    id: systemId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { querySystemFaultsBySystemId } = value.data;

                    const transformedAlerts = querySystemFaultsBySystemId.data.map((alert) => {
                        const { error } = alert;

                        return {
                            ...alert,
                            error: {
                                ...error,
                                isActive: error.isActive as unknown as string === 'true'
                            }
                        };
                    });
                    const transformedData = {
                        ...querySystemFaultsBySystemId,
                        data: transformedAlerts
                    };

                    return transformedData;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
