export interface ControlStatusAddress {
    address1: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
}

export enum SearchControlStatus {
    CONTROL_ALREADY_LINKED_TO_DEALER = 'CONTROL_ALREADY_LINKED_TO_DEALER',
    CONTROL_FOUND_READY_TO_LINK = 'CONTROL_FOUND_READY_TO_LINK',
    CONTROL_LINKED_TO_OTHER_DEALER = 'CONTROL_LINKED_TO_OTHER_DEALER',
    CONTROL_NOT_REGISTERED = 'CONTROL_NOT_REGISTERED',
    CONTROL_SERIAL_NOT_VALID = 'CONTROL_SERIAL_NOT_VALID',
    CONTROL_LINKED_TO_ECOBEE_DEALER = 'CONTROL_LINKED_TO_ECOBEE_DEALER'
}

export interface SearchControlStatusData {
    status: SearchControlStatus;
    customerAddress?: ControlStatusAddress
}

export interface SearchControlStatusResponse {
    data: SearchControlStatusData;
}

export interface WorkflowData {
    status: SearchControlStatus;
    controlNumber?: string;
    customerAddress?: ControlStatusAddress
}

export enum AddControlWorkflow {
    SEARCH_CONTROL = 'search_control',
    ADD_CONTROL = 'add_control'
}
