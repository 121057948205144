<div class='hvac-bookmarks-trigger-container' [ngClass]="{'hvac-bookmarks-expanded': menuActive}">
    <button class="hvac-bookmarks-icon-trigger" (click)="toggleMenu()">
        <utc-svg src="assets/icons/bookmark-solid.svg"></utc-svg>
        <span class="hvac-accessible-text">{{ 'BOOKMARKS.TITLE' | translate }}</span>
    </button>
</div>
<div class="hvac-bookmarks" [ngClass]="{'hvac-bookmarks-expanded': menuActive, 'can-add-page': (showAddButton$ | async)}">
    <ol class="hvac-bookmarks-options">
        <li class="hvac-bookmarks-option-item" *ngFor="let bookmark of (bookmarkList$ | async)">
            <a (click)="handleBookmarkNavigate(bookmark)" class="hvac-bookmarks-option" target="_self"
                [routerLink]="[bookmark.link]" [queryParams]="bookmark.queryParams" >
                {{ bookmark.caption }}
            </a>
            <button class='hvac-bookmarks-remove-button' (click)="removeBookmark(bookmark)">
                <utc-svg class='hvac-bookmarks-remove-icon' src="assets/icons/close.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'BOOKMARKS.DELETE' | translate:{ bookmark: bookmark.caption } }}</span>
            </button>
        </li>
    </ol>
    <button *ngIf="showAddButton$ | async" (click)="addBookmark()" hvacButton="secondary" hvacButtonIcon hvacButtonDark class='hvac-bookmarks-add-current-page-button'><utc-svg src="assets/icons/close.svg"></utc-svg> <span>{{'HEADER.BOOKMARKS.ADD_CURRENT_PAGE' | translate}}</span></button>
</div>
