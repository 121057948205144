<div class="hvac-dealer-logins-report">
    <hvac-report-chart-container
        [chartTitle]="'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.DEALER_LOGINS' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.YEAR_TO_DATE_ANALYSIS' | translate"
        [hasData]="!!(data && chartData)" [isLoading]="isLoading" yearSelectId="hvac-dealer-logins-report-year-select"
        [yearSelectFormControl]="yearSelectFormControl" [yearSelectOptions]="yearSelectOptions"
        [isReportButtonDisabled]="!data"
        (onRunReport)="handleRunReport()"
        >
        <canvas *ngIf="chartData" baseChart class="hvac-chart" [data]="chartData" [options]="chartOptions" [type]="chartType"></canvas>
    </hvac-report-chart-container>
</div>
