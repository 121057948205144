<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-connected-portal-dealer-dashboard-header">
            <hvac-content-header [data]="breadCrumbData">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>

        <div class="hvac-connected-portal-dealer-dashboard-stats">
            <hvac-connected-portal-stats [statsData]="(stats$ | async)!" [isLoading]="isLoadingList.stats"></hvac-connected-portal-stats>
        </div>
    </div>
    <div bottom>
        <div class="hvac-connected-portal-dealer-dashboard-search">
            <hvac-search-bar
                [label]="'CONNECTED_PORTAL.CUSTOMERS.SEARCH.CUSTOMER_SEARCH' | translate"
                [placeholder]="'CONNECTED_PORTAL.CUSTOMERS.SEARCH.PLACEHOLDER' | translate"
                [queryControl]="queryControl"
                (onSearchSubmit)="handleSearchSubmit($event)">
            </hvac-search-bar>
        </div>

        <div class="hvac-connected-portal-dealer-dashboard-alerts">
            <ng-container *ngIf="(alerts$ | async) as alerts">
                <hvac-connected-portal-alerts
                    [alerts]="alerts"
                    [alertLevelFilterSelection]="alertsSeverityFilter"
                    [alertsListTotalPages]="alertsListPageTotal || 0"
                    [alertsListCurrentPage]="alertsCurrentPage"
                    [alertsStartDate]="alertsStartDate"
                    [alertsEndDate]="alertsEndDate"
                    [isLoading]="isLoadingList.alerts"
                    (onAlertsFilterChange)="handleAlertsFilterChange($event)"
                    (onAlertsDateRangeChange)="handleDateRangeChange($event)"
                    (onAlertsPageChange)="handleAlertsPageChange($event)"
                    (onRemoveSelectedAlerts)="handleShowRemoveSelectedModal()"
                    (onExportAlerts)="handleExportAlerts()"
                    >
                </hvac-connected-portal-alerts>
            </ng-container>
        </div>
    </div>

    <utc-modal modal
        [visible]="isModalVisible"
        (closeAction)="handleCloseModal()"
        size="small"
        theme="rounded"
        class="hvac-connected-portal-dealer-dashboard-modal">
        <div>
            <p class="hvac-connected-portal-dealer-dashboard-modal-message">{{ 'CONNECTED_PORTAL.ALERTS.ACTIONS.REMOVE_MESSAGE' | translate }}</p>
            <div class="hvac-connected-portal-dealer-dashboard-modal-actions">
                <button hvacButton="default" (click)="handleCloseModal()">{{ 'CONNECTED_PORTAL.ALERTS.ACTIONS.CANCEL' | translate }}</button>
                <button hvacButton="primary" (click)="handleRemoveAlertsConfirmation()">{{ 'CONNECTED_PORTAL.ALERTS.ACTIONS.CONTINUE' | translate }}</button>
            </div>
        </div>
    </utc-modal>
</hvac-connected-portal-layout-container>
