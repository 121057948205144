<div class="hvac-factory-systems-overview">
    <div class="hvac-system-faults-container">
        <hvac-factory-systems-faults [systemId]="systemId" [systemType]="systemType">
        </hvac-factory-systems-faults>
    </div>

    <div class="hvac-row hvac-system-insights-fault-reports">
        <div class="hvac-col-md-12 hvac-col-lg-6 hvac-system-fault-report-container">
            <div class="hvac-system-fault-report">
                <hvac-fault-source-report [systemId]="systemId"></hvac-fault-source-report>
            </div>
        </div>
        <div class="hvac-col-md-12 hvac-col-lg-6 hvac-system-fault-report-container">
            <div class="hvac-system-fault-report">
                <hvac-fault-severity-report [systemId]="systemId"></hvac-fault-severity-report>
            </div>
        </div>
    </div>
</div>
