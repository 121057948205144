<utc-create-account-header (cancelFunction)="handleHeaderCancel()" (logoFunction)="handleHeaderLogo()"
    [cancelButtonDisabled]="(apiRequestActive$ | async)!"
    [cancelButtonText]="'CREATE_ACCOUNT_LOOKUP.HEADER_CANCEL_BUTTON' | translate"></utc-create-account-header>
<h2 class='hvac-distributor-locator-title hvac-hero-large-text'> {{'DISTRIBUTOR_LOCATOR.TITLE' | translate }}
</h2>
<div class="hvac-distributor-locator">
    <section class="hvac-distributor-locator-input-section">
        <div class='hvac-distributor-locator-input-container'>
            <h3 class="hvac-distributor-locator-label">{{'DISTRIBUTOR_LOCATOR.LABELS.ZIP_CODE' | translate}}</h3>
            <utc-input [inputControl]="distributorLocatorInput" inputId="distributor-locator-input" type="text"
                [labelIcon]="'assets/icons/search.svg'" iconLabel="{{ 'DISTRIBUTOR_LOCATOR.SEARCH' | translate }}"
                iconPosition="right" [errorMessage]="zipCodeErrorMessage$ | async"
                [disableInputIcon]="!distributorLocatorInput.valid" [hasIconFunction]="true"
                (iconClickFunction)="zipCodeSearch()" (enterKeyFunction)="zipCodeSearch()"></utc-input>
        </div>
        <div *ngIf="(selectedDistributor$ | async)?.name?.length" class='hvac-distributor-locator-selected-item'>
            <p class='hvac-create-account-lookup-info-title'>
                {{'DISTRIBUTOR_LOCATOR.SELECTED_DISTRIBUTOR' | translate}}
            </p>
            <p>{{ (selectedDistributor$ | async)?.name }} </p>
            <p>{{ (selectedDistributor$ | async)?.address }} </p>
        </div>
        <div class='hvac-distributor-locator-results-count-container'>
            <div *ngIf="associatedDistributorList$" class='hvac-distributor-locator-results-count'>
                {{( associatedDistributorList$ | async)?.length}} {{'DISTRIBUTOR_LOCATOR.RESULTS' | translate}}
            </div>
        </div>
    </section>
    <section class="hvac-distributor-locator-results">
        <div class='hvac-distributor-locator-label-row'>
            <h3>{{'DISTRIBUTOR_LOCATOR.LABELS.DISTRIBUTOR' | translate}}</h3>
            <h3>{{'DISTRIBUTOR_LOCATOR.LABELS.ADDRESS' | translate}}</h3>
        </div>
        <section *ngIf="(associatedDistributorList$ | async) && ( associatedDistributorList$ | async)?.length"
            class='hvac-distributor-locator-results-section'>
            <div class='hvac-distributor-locator-results-row'
                *ngFor="let result of ( associatedDistributorList$ | async)">
                <div class='hvac-distributor-locator-results-title'>
                    <h3>{{result.name}}</h3>
                    <div class="hvac-distributor-locator-brand-badge hvac-distributor-locator-brand-badge-{{brand.substring(0,3).toLowerCase()}}"
                        *ngFor="let brand of result.associatedBrands">{{brand.toUpperCase()}}</div>
                </div>
                <p>{{formatAddress(result.address)}}</p>
                <div class='hvac-distributor-locator-results-button' SELECT_DISTRIBUTOR
                    [ngClass]="{'hvac-distributor-selected': (selectedDistributor$ | async)?.id === result.id  }">
                    <button (click)="setCompanyId(result)">{{ ( selectedDistributor$ | async)?.id === result.id  ? ( 'DISTRIBUTOR_LOCATOR.SELECTED_DISTRIBUTOR'  | translate ) :  ( 'DISTRIBUTOR_LOCATOR.SELECT_DISTRIBUTOR'  | translate )}} </button>
                </div>
            </div>
            <button hvacButton="default" [disabled]="hasRequested" *ngIf="currentPage < totalPages" (click)="pullPrimaryDistributors()"  class='hvac-shadow-button-with-icon hvac-distributor-locator-load-more'>{{'SEARCH_PAGE.VIEW_MORE' | translate }}</button>
        </section>
        <section *ngIf="(( associatedDistributorList$ | async)?.length === 0  && !(apiRequestActive$ | async))"
            class='hvac-distributor-locator-results-section hvac-distributor-locator-no-results'>
            <p>{{'ACCOUNT_ADMIN.NO_RESULTS' | translate}}</p>
        </section>
    </section>
</div>
<utc-tab-navigation-footer [continueButtonDisabled]="(apiRequestActive$ | async) || (selectedDistributor$ | async)?.name?.length === 0"
    [backButtonDisabled]="(apiRequestActive$ | async)!" [numberOfSteps]="footerNumberOfSteps"
    [currentStep]="footerCurrentStep" [continueText]="'CREATE_ACCOUNT_EMAIL.BUTTON_CREATE' | translate"
    [backText]="'CREATE_ACCOUNT_EMAIL.FOOTER_BACK_BUTTON' | translate" (continueButtonFunction)="submitAccountInfo()"
    (backButtonFunction)="backToInformationPage()">
</utc-tab-navigation-footer>
