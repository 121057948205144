import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';

import { CommonComponentsModule } from 'common/components/common-components.module';
import { RichTextModule } from '../../pipes/rich-text/rich-text.module';
import { QuickLinksComponent } from './quick-links.component';
import { SwiperDirective } from 'common/components/swiper/swiper.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonComponentsModule,
        EllipsisModule,
        DragDropModule,
        RichTextModule,
        RouterModule,
        OverlayModule,
        TranslateModule,
        SwiperDirective,
        CommonModule
    ],
    declarations: [
        QuickLinksComponent
    ],
    exports: [
        QuickLinksComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QuickLinksModule { }
