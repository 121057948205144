<div class="hvac-cp-adv-search-fields-container">
    <div class="hvac-cp-adv-search-filters">
        <div class="hvac-row hvac-rhythm-3">
            <div class="hvac-col-xs-12">
                <hvac-accordion-new [open]="openState" (toggle)="toggleOpenSate()">
                    <hvac-accordion-header>{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.FORM_TITLE' | translate }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <div class="hvac-cp-adv-search-sub-head hvac-rhythm-2">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.CUSTOMER_DETAILS' | translate }}</div>
                        <div class="hvac-row hvac-rhythm-3">
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.firstName"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.FIRST_NAME' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.lastName"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.LAST_NAME' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.email"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.EMAIL' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.controlSerialNo"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.CONTROL_SERIAL_NO' | translate"
                                    [error]="advancedSearchForm.controls.controlSerialNo.dirty && !advancedSearchForm.controls.controlSerialNo.valid"
                                    [errorMessage]="controlSerialNoErrorMessage$ | async">
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.indoorSerialNo"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.INDOOR_SERIAL_NO' | translate"
                                    [error]="advancedSearchForm.controls.indoorSerialNo.dirty && !advancedSearchForm.controls.indoorSerialNo.valid"
                                    [errorMessage]="indoorSerialNoErrorMessage$ | async">
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.outdoorSerialNo"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.OUTDOOR_SERIAL_NO' | translate"
                                    [error]="advancedSearchForm.controls.outdoorSerialNo.dirty && !advancedSearchForm.controls.outdoorSerialNo.valid"
                                    [errorMessage]="outdoorSerialNoErrorMessage$ | async">
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <hvac-select-new
                                    [formControl]="$any(advancedSearchForm).get('controlModelNo')"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.CONTROL_MODEL_NO' | translate"
                                    [placeholder]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.SELECT_CONTROL_MODEL_NO' | translate"
                                    [placeholderDisabled]="false"
                                    [options]="controlModelNumbers || []">
                                </hvac-select-new>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.indoorModelNo"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.INDOOR_MODEL_NO' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.outdoorModelNo"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.OUTDOOR_MODEL_NO' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.dealerName"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.DEALER_NAME' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.dealerHVPID"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.DEALER_HVPID' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.esn"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.ESN' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <utc-input (keydown.enter)="searchCustomers()"
                                    [inputControl]="advancedSearchForm.controls.city"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.CITY' | translate">>
                                </utc-input>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <hvac-select-new
                                    [formControl]="$any(advancedSearchForm).get('state')"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.STATE' | translate"
                                    [placeholder]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.SELECT_STATE' | translate"
                                    [placeholderDisabled]="false"
                                    [options]="stateList || []">
                                </hvac-select-new>
                            </div>
                            <div class="hvac-cp-adv-search-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4">
                                <hvac-select-new
                                    [formControl]="$any(advancedSearchForm).get('country')"
                                    [label]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.COUNTRY' | translate"
                                    [placeholder]="'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.SELECT_COUNTRY' | translate"
                                    [placeholderDisabled]="false"
                                    [options]="(countryList$ | async) || []">
                                </hvac-select-new>
                            </div>
                        </div>

                        <div class="hvac-cp-adv-search-sub-head hvac-rhythm-2 hvacp-bold-txt">Customer Data Sharing Options</div>
                        <div class="hvac-row hvac-rhythm-2">
                            <div class="hvac-cp-data-sharing-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4 hvac-rhythm-2">
                                <span class="hvac-cp-option-name hvac-cp-option-space">{{"CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.VIEW_STATUS" | translate}}</span>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.viewStatusEnabled" class="hvac-cp-option-space">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.ENABLED' | translate }}
                                </utc-checkbox>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.viewStatusDisabled">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.DISABLED' | translate }}
                                </utc-checkbox>
                            </div>
                            <div class="hvac-cp-data-sharing-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4 hvac-rhythm-2">
                                <span class="hvac-cp-option-name hvac-cp-option-space">{{"CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.VIEW_CONFIG" | translate}}</span>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.viewConfigEnabled" class="hvac-cp-option-space">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.ENABLED' | translate }}
                                </utc-checkbox>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.viewConfigDisabled">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.DISABLED' | translate }}
                                </utc-checkbox>
                            </div>
                            <div class="hvac-cp-data-sharing-form hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-4 hvac-rhythm-2">
                                <span class="hvac-cp-option-name hvac-cp-option-space">{{"CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.EDIT_CONFIG" | translate}}</span>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.editConfigEnabled" class="hvac-cp-option-space">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.ENABLED' | translate }}
                                </utc-checkbox>
                                <utc-checkbox [inputControl]="dataSharingOptionsForm.controls.editConfigDisabled">
                                    {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LABELS.DISABLED' | translate }}
                                </utc-checkbox>
                            </div>
                        </div>
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-md-6 hvac-rhythm-2">
                                <button hvacButton="default" size="small" (click)="clearFilters()">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.ACTIONS.CLEAR_ALL' | translate }}</button>
                            </div>
                            <div class="hvac-col-xs-12 hvac-col-md-6">
                                <button hvacButton="default"
                                    size="small"
                                    (click)="searchCustomers()"
                                    class="hvac-cp-align-right"
                                    [disabled]="(advancedSearchForm.controls.controlSerialNo.dirty && !advancedSearchForm.controls.controlSerialNo.valid) ||
                                                (advancedSearchForm.controls.indoorSerialNo.dirty && !advancedSearchForm.controls.indoorSerialNo.valid) ||
                                                (advancedSearchForm.controls.outdoorSerialNo.dirty && !advancedSearchForm.controls.outdoorSerialNo.valid)"
                                >{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.ACTIONS.SEARCH' | translate }}</button>
                            </div>
                        </div>
                    </hvac-accordion-body>
                </hvac-accordion-new>
            </div>
        </div>
    </div>
</div>