<div class="hvac-personas-item " [ngClass]="{ 'hvac-personas-item-show-details': showDetails$ | async }">
    <button hvacCard class="hvac-personas-item-card" (click)="openDetails()">
        <div class="hvac-personas-item-illustration">
            <utc-svg class="hvac-personas-item-person" [src]="persona.ILLUSTRATION"></utc-svg>
            <utc-svg class="hvac-personas-item-icon" [src]="persona.ICON"></utc-svg>
        </div>
        <h3 class="hvac-personas-item-title">{{ persona.NAME }}</h3>
        <div hvacButton="secondary" class="hvac-personas-item-more-details">{{ persona.MORE_DETAILS }}</div>
    </button>
    <div hvacCard='static' class="hvac-personas-item-details">
        <button
            hvacButton="secondary"
            class="hvac-personas-item-details-close"
            (click)="closeDetails()">
            <utc-svg src="assets/icons/close.svg"></utc-svg>
            <span class="hvac-accessible-text">Close</span>
        </button>
        <div class="hvac-personas-item-details-heading">
            <utc-svg class="hvac-personas-item-details-icon" [src]="persona.ICON"></utc-svg>
            <h4 class="hvac-personas-item-details-title">{{ persona.NAME }}</h4>
        </div>
        <ul class="hvac-personas-item-details-benefits">
            <li class="hvac-personas-item-details-benefit" *ngFor="let benefit of persona.BENEFITS">
                <utc-svg class="hvac-personas-item-details-benefit-icon" [src]="benefit.ICON"></utc-svg>
                <div class="hvac-personas-item-details-benefit-description">
                    <h5 class="hvac-personas-item-details-benefit-title">{{ benefit.TITLE }}</h5>
                    <p class="hvac-personas-item-details-benefit-body" *ngIf="benefit.DESCRIPTION; else listDetails">{{ benefit.DESCRIPTION }}</p>
                    <ng-template #listDetails>
                        <ul class="hvac-personas-item-list-details">
                            <li class='hvac-personas-item-list-details-list-item' *ngFor="let detail of benefit.LIST">{{ detail }}</li>
                        </ul>
                    </ng-template>
                </div>
            </li>
        </ul>
    </div>
</div>
