import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeapService } from 'common/services/heap.service';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';

@Component({
    selector: 'hvac-opportunity-report-card',
    templateUrl: './opportunity-report-card.component.html',
    styleUrls: ['./opportunity-report-card.component.scss']
})
export class OpportunityReportCardComponent {
    @Input() id: string;
    @Input() title: string;
    @Input() description: string;
    @Input() isLoading = false;
    @Input() isDownloading = false;

    @Output() onDownloadClick = new EventEmitter<string>();

    constructor(private heapService: HeapService) {

    }

    public handleButtonClick() {
        if (this.isDownloading) {
            return;
        }

        if (this.id === 'data-sharing') {
            this.heapService.track(eventNames.handleDataSharingOpportunityReportDownloadClick);
        }
        else if (this.id === 'registration') {
            this.heapService.track(eventNames.handleRegistrationOpportunityReportDownloadClick);
        }

        this.onDownloadClick.emit(this.id);
    }
}
