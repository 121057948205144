import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { SystemType } from 'private/app/models/connected-product.model';
import { SystemMode, Zone } from 'private/app/models/wall-control.model';
import { DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, finalize, map, scan, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../customer-product-details/product-detail-context.service';
import { WallControlProgramSettings } from './components/wall-control-program-settings/wall-control-program-settings.component';
import { SystemConditionsService } from './system-conditions.service';

interface LoadingState {
    wallControlDetails?: boolean;
    zoningDetails?: boolean;
}

interface SystemConditions {
    isInfinity: boolean;
    outdoorTemp: string;
    outdoorHumidity: string;
    indoorTemp?: string;
    indoorHumidity?: string;
    relativeHumidity?: string;
}

interface SystemSettings {
    isEcobee: boolean;
    coolTo?: string;
    heatTo?: string;
    isCoolToOutOfRange: boolean;
    isHeatToOutOfRange: boolean;
    isOutOfRangeWarningVisible: boolean;
}

interface ProgramSettings extends WallControlProgramSettings {
    isProgramSettingsVisible: boolean;
}

interface PageData {
    systemConditions: SystemConditions;
    programSettings: ProgramSettings;
    systemSettings: SystemSettings;
    systemMode: SystemMode;
}

@Component({
    selector: 'hvac-system-conditions',
    templateUrl: './system-conditions.component.html',
    styleUrls: ['./system-conditions.component.scss'],
    providers: [SystemConditionsService]
})
export class SystemConditionsComponent extends BaseComponent implements OnInit {
    public readonly SystemType = SystemType;
    public readonly CharSymbol = CharSymbol;
    public isLoading$: Observable<boolean>;
    public isProgramSettingsVisible$: Observable<boolean>;
    public pageData$: Observable<PageData>;

    private isAnyEcobeeValueOutOfRange$ = new BehaviorSubject<boolean>(false);
    private loadData$ = new BehaviorSubject<true>(true);
    private loadingStates$ = new BehaviorSubject<LoadingState>({
        wallControlDetails: false,
        zoningDetails: false
    });

    constructor(
        private systemConditionsService: SystemConditionsService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private productConfigurationService: ProductConfigurationService,
        private productDetailContextService: ProductDetailContextService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.isLoading$ = this.loadingStates$.pipe(
            scan((acc, next) => ({
                ...acc,
                ...next
            })),
            map((loadingStates) => (Object.values(loadingStates).some((loadingState) => loadingState === true))),
            distinctUntilChanged(),
            tap(() => {
                this.cdr.detectChanges();
            })
        );

        const routeData$ = this.productDetailContextService.getRouteData$().pipe(
            shareReplay()
        );

        const currentZoneName$ = this.route.paramMap.pipe(
            map((paramMap) => {
                const zone = paramMap.get('zone');

                return zone ? decodeURIComponent(zone) : null;
            }),
            shareReplay()
        );

        const systemType$ = routeData$.pipe(
            map((routeData) => routeData.systemType),
            shareReplay()
        );

        const wallControlData$ = combineLatest([this.loadData$, routeData$]).pipe(
            tap(() => {
                this.loadingStates$.next({ wallControlDetails: true });
            }),
            switchMap(([, { serialNo, dealerId, systemType }]) => this.productService.queryWallControlBySerialNo(serialNo, dealerId, systemType)),
            tap(() => {
                this.loadingStates$.next({ wallControlDetails: false });
            }),
            finalize(() => {
                this.loadingStates$.next({ wallControlDetails: false });
            }),
            shareReplay()
        );

        const wallControlZoningData$ = combineLatest([this.loadData$, routeData$]).pipe(
            tap(() => {
                this.loadingStates$.next({ zoningDetails: true });
            }),
            switchMap(([, { serialNo, dealerId }]) => this.productService.queryWallControlZoningBySerialNo(serialNo, dealerId)),
            tap(() => {
                this.loadingStates$.next({ zoningDetails: false });
            }),
            finalize(() => {
                this.loadingStates$.next({ zoningDetails: false });
            }),
            shareReplay()
        );

        const dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(shareReplay());

        const wallControlDetails$ = combineLatest([
            wallControlData$,
            this.productConfigurationService.systemConditionsConfigParams$,
            currentZoneName$,
            systemType$
        ]).pipe(
            map(([wallControlDetailsRes, systemConditionsConfig, zoneName, systemType]) => {
                if (systemType === SystemType.ECOBEE) {
                    this.isAnyEcobeeValueOutOfRange$.next(false);

                    const ecobeeWallControlDetails = this.systemConditionsService.getEcobeeWallControlDetails(wallControlDetailsRes.data, systemConditionsConfig, zoneName);

                    if (ecobeeWallControlDetails.hasOutOfRangeValues) {
                        this.isAnyEcobeeValueOutOfRange$.next(true);
                    }

                    return ecobeeWallControlDetails.data;
                }

                return this.systemConditionsService.getInfinityWallControlDetails(wallControlDetailsRes.data);
            }),
            shareReplay()
        );

        const zoneDetails$ = combineLatest([wallControlDetails$, currentZoneName$]).pipe(
            map(([wallControlDetails, zoneName]) => wallControlDetails?.zones.find((zone: Zone) => zone.name === zoneName) || null),
            shareReplay()
        );

        const zoningData$ = combineLatest([wallControlZoningData$, currentZoneName$]).pipe(
            map(([wallControlZoningRes, zoneName]) => wallControlZoningRes.data.find((zone) => zone.zoneName === zoneName)),
            shareReplay()
        );

        const isHoldOn$ = zoningData$.pipe(
            map((zoningData) => zoningData?.holdSetting === 'on')
        );

        const activityTimes$ = zoneDetails$.pipe(
            map((zoneDetails) => {
                const programs = zoneDetails?.programs;

                return programs ? this.systemConditionsService.getUniqueProgramListTimes(programs) : [];
            }),
            shareReplay()
        );

        const activities$ = combineLatest([zoningData$, zoneDetails$, systemType$]).pipe(
            map(([zoningData, zoneDetails, systemType]) => {
                if (zoneDetails && systemType === SystemType.INFINITY) {
                    return this.systemConditionsService.getZoneActivities(zoneDetails, zoningData);
                }

                return null;
            }),
            shareReplay()
        );

        const activeActivityTime$ = combineLatest([activityTimes$, wallControlDetails$]).pipe(
            map(([activityTimes, wallControlDetails]): string => this.systemConditionsService.getActiveTime(activityTimes, wallControlDetails.statusLocalTime)),
            shareReplay()
        );

        const systemConditions$ = combineLatest([systemType$, zoneDetails$, wallControlDetails$]).pipe(
            map(([systemType, zoneDetails, wallControlDetails]): SystemConditions => {
                const { indoorHumidity, outdoorTemp, outdoorHumidity } = wallControlDetails;

                return {
                    isInfinity: systemType === SystemType.INFINITY,
                    indoorTemp: zoneDetails?.indoorTemp,
                    relativeHumidity: zoneDetails?.relativeHumidity,
                    indoorHumidity,
                    outdoorHumidity,
                    outdoorTemp
                };
            }),
            shareReplay()
        );

        const systemSettings$ = combineLatest([systemType$, zoneDetails$, this.isAnyEcobeeValueOutOfRange$]).pipe(
            map(([systemType, zoneDetails, isAnyEcobeeValueOutOfRange]): SystemSettings => {
                const isEcobee = systemType === SystemType.ECOBEE;

                return {
                    isEcobee,
                    coolTo: zoneDetails?.coolTo,
                    heatTo: zoneDetails?.heatTo,
                    isCoolToOutOfRange: isEcobee && zoneDetails?.coolTo === CharSymbol.DoubleDash,
                    isHeatToOutOfRange: isEcobee && zoneDetails?.heatTo === CharSymbol.DoubleDash,
                    isOutOfRangeWarningVisible: isEcobee && isAnyEcobeeValueOutOfRange
                };
            }),
            shareReplay()
        );

        const programSettings$ = combineLatest([dataSharingPermissions$, systemType$, activeActivityTime$, activities$, isHoldOn$]).pipe(
            map(([dataSharingPermissions, systemType, activeActivityTime, activities, isHoldOn]): ProgramSettings => {
                const isProgramSettingsVisible = systemType === SystemType.INFINITY && dataSharingPermissions.programSettings;

                return {
                    isProgramSettingsVisible,
                    activeActivityTime,
                    activities,
                    isHoldOn
                };
            }),
            shareReplay()
        );

        const systemMode$ = combineLatest([dataSharingPermissions$, wallControlDetails$]).pipe(
            map(([dataSharingPermissions, wallControlDetails]): SystemMode => {
                const { fan, cooling, heating, userSetting } = wallControlDetails;

                return {
                    isSystemModeEnabled: dataSharingPermissions.systemMode,
                    isEquipmentActivityEnabled: dataSharingPermissions.equipmentActivity,
                    userSetting,
                    fan,
                    cooling,
                    heating
                };
            }),
            shareReplay()
        );

        this.pageData$ = combineLatest([
            systemConditions$,
            systemSettings$,
            programSettings$,
            systemMode$
        ]).pipe(
            map(([
                systemConditions,
                systemSettings,
                programSettings,
                systemMode
            ]): PageData => ({
                systemConditions,
                systemSettings,
                programSettings,
                systemMode
            }))
        );

        // SUBSCRIPTIONS
        this.productDetailContextService.onEcobeeDataRefresh$
            .pipe(
                tap((isDataRefreshed) => {
                    if (isDataRefreshed) {
                        this.loadData$.next(true);
                    }
                }),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe();
    }
}
