<div class="hvac-account-admin-form-header {{ showRegistartionRequests ? 'show-registration-requests' : '' }} hvac-account-admin-heading hvac-account-admin-heading-default">
    <h1 class="hvac-h4">{{
        'ACCOUNT_ADMIN.ADVANCED_SEARCH_HEADING' | translate }}</h1>
        <button *ngIf="adminAccess && isCreateUserFeatureAvailable && showRegistartionRequests" type="button" class="hvac-user-edit-details-button" routerLink="/Admin/Admin-Tools/user-registrations" [hvacButton]="'default'"  size="small">
          {{ 'ACCOUNT_ADMIN.REGISTRATION_REQUEST' | translate }} <span class="hvac-notifications-count">{{ (requestCount$ | async) }}</span>
        </button>
        <button *ngIf="adminAccess && isCreateUserFeatureAvailable" type="button" class="hvac-user-edit-details-button" routerLink="/Admin/Admin-Tools/create-user" [hvacButton]="'default'"  size="small">
          {{ 'ACCOUNT_ADMIN.CREATE_USER' | translate }}
        </button>
        <button *ngIf="adminAccess" type="button" class="hvac-user-edit-details-button" routerLink="/Admin/Admin-Tools/create-company" [hvacButton]="'default'"  size="small">
            {{ 'ACCOUNT_ADMIN.CREATE_COMPANY' | translate }}
        </button>
</div>
<form [formGroup]="accountAdminService.advancedSearchForm">
    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-6 hvac-account-admin-col" *ngIf="!(isNonInternalDistributor$ | async)">
            <div class="hvac-form-field">
                <utc-select shadow="light" [group]="accountAdminService.advancedSearchForm" control="entityType" [selectId]="selectID"
                    defaultOption="{{ 'ACCOUNT_ADMIN.LEGAL_ENTITY' | translate }}" [options]="(legalEntityOptionData$ | async) || []" (onChange)="onLegalEntityChange($event)"></utc-select>
            </div>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-lg-6 hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-select shadow="light" [group]="accountAdminService.advancedSearchForm" control="distributorBranch" [selectId]="selectID"
                defaultOption="{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_BRANCH' | translate }}" [options]="( distributorBranchOptionData$ | async ) || []" [disabled]="!(isNonInternalDistributor$ | async) && !isLegealEntitySelected"></utc-select>
        </div>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row hvac-account-admin-form-inner">
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="companyType"
                    defaultOption="{{ 'ACCOUNT_ADMIN.COMPANY_TYPE' | translate }}" [options]="companyTypes || []"></utc-select>

            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.companyName"
                    label="{{ 'ACCOUNT_ADMIN.COMPANY_NAME' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light"  [inputControl]="accountAdminService.advancedSearchForm.controls.companyId"
                    label="{{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.soldTo"
                    label="{{ 'ACCOUNT_ADMIN.SOLD_TO' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.shipTo"
                    label="{{ 'ACCOUNT_ADMIN.SHIP_TO' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.payer"
                    label="{{ 'ACCOUNT_ADMIN.PAYER' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="brand"
                    defaultOption="{{ 'ACCOUNT_ADMIN.BRAND' | translate }}" [options]="formData?.brands || []"></utc-select>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.priceList"
                    label="{{ 'ACCOUNT_ADMIN.PRICE_LIST' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.priceGroup"
                    label="{{ 'ACCOUNT_ADMIN.PRICE_GROUP' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.cin"
                    label="{{ 'ACCOUNT_ADMIN.CIN' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.hvp"
                    label="{{ 'ACCOUNT_ADMIN.HVP#' | translate }}"></utc-input>
            </div>
            <div *ngIf="showRelationshipType" class="hvac-form-field">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="relationshipType"
                    defaultOption="{{ 'ACCOUNT_ADMIN.RELATIONSHIP_TYPE' | translate }}" [options]="formData?.relationshipTypes || []"></utc-select>
            </div>

            <div *ngIf="isInternalUser" class="hvac-form-field hvac-inline-form-field">
                <label class="hvac-label">{{ 'ACCOUNT_ADMIN.STATUS' | translate }}</label>
                <div>
                    <utc-checkbox [inputControl]="accountAdminService.advancedSearchForm.controls.statusActive">
                        {{ 'ACCOUNT_ADMIN.ACTIVE' | translate }}
                    </utc-checkbox>
                    <utc-checkbox [inputControl]="accountAdminService.advancedSearchForm.controls.statusInactive">
                        {{ 'ACCOUNT_ADMIN.INACTIVE' | translate }}
                    </utc-checkbox>
                </div>
            </div>
            <div class="hvac-form-field hvac-inline-form-field">
                <label class="hvac-label">{{ 'ACCOUNT_ADMIN.LOCATION' | translate }}</label>
                <div>
                    <utc-checkbox [inputControl]="accountAdminService.advancedSearchForm.controls.locationMain">
                        {{ 'ACCOUNT_ADMIN.MAIN' | translate }}
                    </utc-checkbox>
                    <utc-checkbox [inputControl]="accountAdminService.advancedSearchForm.controls.locationBranch">
                        {{ 'ACCOUNT_ADMIN.BRANCH' | translate }}
                    </utc-checkbox>
                </div>
            </div>

            <div class="hvac-form-field">
                <hvac-accordion-new theme="light" class="accordion hvac-accordion-new" [open]="isAccordionOpen" (click)="handleToggle()">
                    <hvac-accordion-header class="accordionLabel hvac-accordion-label">{{"ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA" | translate}}</hvac-accordion-header>
                </hvac-accordion-new>

                <div *ngIf="isAccordionOpen">
                    <div class="hvac-form-field">
                        <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.parentCompanyID" 
                            label="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.PARENT_COMPANY_ID' | translate}}"></utc-input>
                    </div>
                    <div class="hvac-form-field">
                        <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.parentCompanyName"
                            label="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.PARENT_COMPANY_NAME' | translate}}"></utc-input>
                    </div>
                    <div class="hvac-form-field">
                        <utc-select [group]="accountAdminService.advancedSearchForm" control="createdDate" shadow="light"
                            defaultOption="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.DATE_CRITERIA' | translate}}" [options]="accountAdminService.createdDateOptions || []"></utc-select>
                    </div>

                    <div class="hvac-row hvac-date-range-picker">
                        <div class="hvac-form-field hvac-col-xs-12 hvac-col-md-8">
                            <hvac-date-range-picker 
                            *ngIf="!isResetDateRangePicker; else clearDateRange"
                            [error]="(dateErrorMessage$ | async) || ''"
                            [startDate]="startDate"
                            [endDate]="endDate"
                            [disableLastValidDateRestore]="true"
                            [controls]="companyRelationDateControls"
                            (onSelection)="setRelationshipCriteriaDate({ startDate: $event.startDate, endDate: $event.endDate })" theme="retro"></hvac-date-range-picker>
                            <ng-template #clearDateRange>
                                <hvac-date-range-picker 
                                [error]="(dateErrorMessage$ | async) || ''"
                                [disableLastValidDateRestore]="true"
                                (onSelection)="setRelationshipCriteriaDate({ startDate: $event.startDate, endDate: $event.endDate })" theme="retro"></hvac-date-range-picker>
                            </ng-template>
                        </div>
                    </div>

                    <div class="hvac-form-field">
                        <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.distributorID"
                            label="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.DISTRIBUTOR_COMPANY_ID' | translate}}"></utc-input>
                    </div>
                    <div class="hvac-form-field">
                        <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.soldToNumber"
                            label="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.SOLD_TO' | translate}}"></utc-input>
                    </div>
                    <div class="hvac-form-field">
                        <utc-select [group]="accountAdminService.advancedSearchForm" control="status" shadow="light"
                            defaultOption="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.STATUS' | translate}}" [options]="accountAdminService.relationshipStatusOptions || []"></utc-select>
                    </div>

                    <div class="hvac-form-field">
                        <utc-select [group]="accountAdminService.advancedSearchForm" control="type" shadow="light" [multiple]="true"
                            defaultOption="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.TYPE' | translate}}" [options]="accountAdminService.relationshipTypeOptions || []"></utc-select>
                    </div>
                    <div class="hvac-form-field">
                        <utc-select [group]="accountAdminService.advancedSearchForm" control="tierLevel" shadow="light"
                            defaultOption="{{'ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.TIER_LEVEL' | translate}}" [options]="accountAdminService.tierLevelOptions || []"></utc-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.address1"
                    label="{{ 'ACCOUNT_ADMIN.ADDRESS' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.city"
                    label="{{ 'ACCOUNT_ADMIN.CITY' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.region"
                    label="{{ 'ACCOUNT_ADMIN.STATE' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.postalCode"
                    label="{{ 'ACCOUNT_ADMIN.ZIP_CODE' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-select [group]="accountAdminService.advancedSearchForm" control="country" shadow="light"
                    defaultOption="{{ 'ACCOUNT_ADMIN.COUNTRY' | translate }}" [options]="(countryList$ | async) || []" [multiple]="true">
                </utc-select>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.phone"
                    label="{{ 'ACCOUNT_ADMIN.PHONE' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.fax"
                    label="{{ 'ACCOUNT_ADMIN.FAX' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.email"
                    label="{{ 'ACCOUNT_ADMIN.EMAIL' | translate }}"></utc-input>
            </div>
        </div>
        <div class="hvac-col-custom hvac-account-admin-col">
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.firstName"
                    label="{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.lastName"
                    label="{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field" *ngIf="isUserFeatureAvailable" >
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.id"
                    label="{{ 'ACCOUNT_ADMIN.USER_ID' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field">
                <utc-input shadow="light" [inputControl]="accountAdminService.advancedSearchForm.controls.title"
                    label="{{ 'ACCOUNT_ADMIN.TITLE' | translate }}"></utc-input>
            </div>
            <div class="hvac-form-field"  *ngIf="!isDistributor">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="contactType"
                    defaultOption="{{ 'ACCOUNT_ADMIN.CONTACT_TYPE' | translate }}" [options]="formData?.contactTypes || []">
                </utc-select>
            </div>
            <div class="hvac-form-field"  *ngIf="!isDistributor">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="rsm"
                    defaultOption="{{ 'ACCOUNT_ADMIN.RSM' | translate }}" [options]="formData?.rsms || []"></utc-select>
            </div>
            <div class="hvac-form-field"  *ngIf="!isDistributor">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="tsm"
                    defaultOption="{{ 'ACCOUNT_ADMIN.TSM' | translate }}" [options]="formData?.tsms || []"></utc-select>
            </div>
            <div class="hvac-form-field"  *ngIf="!isDistributor">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="accountManager"
                    defaultOption="{{ 'ACCOUNT_ADMIN.ACCOUNT_MANAGER' | translate }}" [options]="formData?.accountManagers || []">
                </utc-select>
            </div>
            <div class="hvac-form-field"  *ngIf="!isDistributor">
                <utc-select [multiple]="true" shadow="light" [group]="accountAdminService.advancedSearchForm" control="pricingAnal"
                    defaultOption="{{ 'ACCOUNT_ADMIN.PRICING_ANAL' | translate }}" [options]="formData?.pricingAnal || []">
                </utc-select>
            </div>
        </div>
    </div>
    <div class="hvac-divider"></div>
    <div *ngIf="onAccountAdminResult" class="hvac-account-admin-btn-wrap hvac-button-fixed">
        <div class="hvac-account-admin-buttons">
            <utc-button buttonType="button" label="{{ 'ACCOUNT_ADMIN.SEARCH' | translate }}" (click)="onSubmit(accountAdminService.advancedSearchForm)"
            classNames="hvac-button-primary hvac-button-dark hvac-account-admin-btn">
            </utc-button>
        </div>
        <div class="hvac-account-admin-buttons">
            <button type="button" hvacButton="default" class="hvac-account-admin-form-clear-btn hvac-account-admin-btn" (click)="clearAll()">
                {{ 'ACCOUNT_ADMIN.CLEAR_ALL' | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="!onAccountAdminResult"  class="hvac-account-admin-footer-wrap">
        <button type="button" hvacButton="default" class="hvac-account-admin-form-clear-btn" (click)="clearAll()">
            {{ 'ACCOUNT_ADMIN.CLEAR_ALL' | translate }}
        </button>
        <div class="hvac-account-admin-btn-wrap hvac-button-fixed">
            <utc-button buttonType="button" label="{{ 'ACCOUNT_ADMIN.SEARCH' | translate }}" (click)="onSubmit(accountAdminService.advancedSearchForm)"
                classNames="hvac-button-primary hvac-button-dark hvac-account-admin-btn">
            </utc-button>
        </div>
    </div>
</form>
