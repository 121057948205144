import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { BrandService } from 'common/services/brand.service';
import { AuthState } from 'common/store/auth/auth.state';
import { BrandingState } from 'common/store/branding/branding.state';
import { ContentState } from 'common/store/content/content.state';
import { combineLatest, Observable, of } from 'rxjs';

@Component({
    selector: 'hvac-private-footer',
    templateUrl: './private-footer.component.html',
    styleUrls: ['./private-footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PrivateFooterComponent {
    @Select(BrandingState.themeBrand) brand$!: Observable<string>;
    @Select(ContentState.contentBrand) contentBrand$!: Observable<string>;
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socialLinks$: Observable<any[]>;
    phoneText$: Observable<string>;
    emailText$: Observable<string>;
    phoneLink$: Observable<string>;
    emailLink$: Observable<string>;
    signupLink$: Observable<string>;
    navigationLinks$: Observable<{
        LINK: string;
        TEXT: string;
    }[]>;

    templateKey = 'DEFAULT';
    isPrivate: boolean;
    public copyrightYear = new Date().getFullYear().toString();

    constructor(private readonly translate: TranslateService, private readonly brandService: BrandService) { }

    ngOnInit() {
        this.isPrivate = false;
        combineLatest([this.isLoggedIn$, this.brand$, this.contentBrand$]).subscribe(([isLoggedIn, inputBrand, contentBrand]) => {
            this.isPrivate = isLoggedIn;
            const brand = inputBrand.toLowerCase();
            let translateKey = 'CBP';
            // use carrier socials for all footers other than bryant and ICP for now
            this.socialLinks$ = this.translate.get(`FOOTER.SOCIALS.CARRIER.SOCIALS`);
            if (brand === 'default' && this.brandService.isICPBrand(contentBrand.toLowerCase())) {
                translateKey = 'ICP';
                this.templateKey = 'ICP';
                this.socialLinks$ = of([]);
            }
            else if (brand === 'carrier' || brand === 'bryant') {
                this.templateKey = brand.toUpperCase();
                if (brand === 'bryant') {
                    this.socialLinks$ = this.translate.get(`FOOTER.SOCIALS.${brand.toLocaleUpperCase()}.SOCIALS`);
                }
                if (!this.isPrivate) {
                    this.navigationLinks$ = this.translate.get(`FOOTER.NAVIGATION_LINKS.${brand.toLocaleUpperCase()}`);
                }
            }

            this.phoneText$ = this.translate.get(`FOOTER.${translateKey}_CONTACT.PHONE_TEXT`);
            this.emailText$ = this.translate.get(`FOOTER.${translateKey}_CONTACT.EMAIL_TEXT`);
            this.phoneLink$ = this.translate.get(`FOOTER.${translateKey}_CONTACT.PHONE_LINK`);
            this.emailLink$ = this.translate.get(`FOOTER.${translateKey}_CONTACT.EMAIL_LINK`);
            this.signupLink$ = this.translate.get(`FOOTER.${translateKey}_SIGNUP.SIGNUP_LINK`);
        });
    }
}
