<div [ngClass]="'hvac-editable-user-info'">
<div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.FIRST_NAME' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">firstName
                    </div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                    [error]="userAdminService.userEditForm.controls.firstName.dirty && !userAdminService.userEditForm.controls.firstName.valid"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FIRST_NAME' | translate"
                            [inputControl]="userAdminService.userEditForm.controls.firstName"
                            [hideLabel]="true" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.LAST_NAME' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">lastName</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                            [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.LAST_NAME' | translate"
                            [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.lastName"
                            [error]="userAdminService.userEditForm.controls.lastName.dirty && !userAdminService.userEditForm.controls.lastName.valid"
                            type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.TITLE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">userTitle</div>
                
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userTitle.dirty && !userAdminService.userEditForm.controls.userTitle.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_TITLE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userTitle" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_NAME' | translate}}
                    </div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{ user.employerName | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMPLOYER_NAME' | translate"
                        [error]="userAdminService.userEditForm.controls.employerName.dirty && !userAdminService.userEditForm.controls.employerName.valid"
                        [hideLabel]="true"
                        [disabledInput]="true"
                        [inputControl]="userAdminService.userEditForm.controls.employerName" type="text" shadow="none">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_ID' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.companyId | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.companyId.dirty && !userAdminService.userEditForm.controls.companyId.valid"
                        [disabledInput]="true" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.companyId" type="text" shadow="none">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                        'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userAddress1 | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userAddress1.dirty && !userAdminService.userEditForm.controls.userAddress1.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS1' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userAddress1" type="text" shadow="none" [max]="60">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userAddress2 | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userAddress2.dirty && !userAdminService.userEditForm.controls.userAddress2.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS2' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userAddress2" type="text" shadow="none" [max]="60">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                        'ACCOUNT_ADMIN.CITY' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userCity | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userCity.dirty && !userAdminService.userEditForm.controls.userCity.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.CITY' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userCity" type="text" shadow="none" [max]="40">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                      <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            [error]="userAdminService.userEditForm.controls.userCountry.dirty && !userAdminService.userEditForm.controls.userCountry.valid"
                            [shadow]="'none'"
                            (onChange)="onCountryChange($event)"
                            [group]="userAdminService.userEditForm" control="userCountry" [options]="(countryOptionData$ | async) || []">
                        </utc-select>
                    
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.CREATE_USER_PAGE.STATE_PROVINCE' | translate}}</div>
                        <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                            [error]="userAdminService.userEditForm.controls.userState.dirty && !userAdminService.userEditForm.controls.userState.valid"
                            [shadow]="'none'"
                            (onChange)="onStateChange($event)"
                            [group]="userAdminService.userEditForm" control="userState" [options]="(stateOptionData$ | async) || []">
                        </utc-select>
                    
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                        'ACCOUNT_ADMIN.ZIP_POSTAL_CODE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                        user.userPostalCode | textFallback }}</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userPostalCode.dirty && !userAdminService.userEditForm.controls.userPostalCode.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.ZIP_CODE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userPostalCode" type="text" shadow="none" [max]="10">
                    </utc-input>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>

    <div class="hvac-row">
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.OFFICE_PHONE' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">userPhone</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                    [error]="userAdminService.userEditForm.controls.userPhone.dirty && !userAdminService.userEditForm.controls.userPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.OFFICE_PHONE' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userPhone" 
                        type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE_NUMBER' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">userCellPhone</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userCellPhone.dirty && !userAdminService.userEditForm.controls.userCellPhone.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userCellPhone" type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FAX_NUMBER' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">userFax</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.userFax.dirty && !userAdminService.userEditForm.controls.userFax.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.userFax" type="text" shadow="none" [max]="20">
                    </utc-input>
                </li>
            </ul>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
            <ul class="hvac-account-admin-detail-list">
                <li class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                        'ACCOUNT_ADMIN.EMAIL' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">email</div>
                    <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                        [error]="userAdminService.userEditForm.controls.email.dirty && !userAdminService.userEditForm.controls.email.valid"
                        [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMAIL' | translate" [hideLabel]="true"
                        [inputControl]="userAdminService.userEditForm.controls.email" type="text" shadow="none" [max]="100">
                    </utc-input>
                </li>
                <li *ngIf="brandFamily === brandFamilyValue" class="hvac-row hvac-top-xs">
                    <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.CONTACT_ONLY' | translate}}</div>
                    <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">contactOnly</div>
                    <utc-checkbox class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode" 
                        [inputControl]="userAdminService.userEditForm.controls.contactOnly"
                        inputId="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate" 
                        inputName="'ACCOUNT_ADMIN.CONTACT_ONLY' | translate">
                    </utc-checkbox>
                </li>
            </ul>
        </div>
    </div>
    <div class="hvac-divider"></div>
    
    
        <div class="hvac-row hvac-account-admin-detail-footer hvac-flex-end">   
            <button type="button" [hvacButton]="'default'" (click)="onClickCreateUserChangePage(false)">
                <span>{{ "FOOTER.BACK_TO_PREVIOUS_STEP" | translate }}</span>
            </button>
            <button type="button" class="hvac-edit-button" hvacButton="primary" (click)="onClickCreateUserChangePage(true)" [disabled]="!userAdminService.userEditForm.valid">
                {{ "FOOTER.CONTINUE" | translate}}
            </button>
        </div>
       

    