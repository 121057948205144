import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { BaseContentEntity } from 'common/models';
import { QuickLink } from 'common/models/quick-links';
import { QuickLinksService } from 'common/services/quickLinks.service';
import { ContentState } from 'common/store/content/content.state';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
register();


function __indexOf(collection: HTMLCollection, node: HTMLElement) {
    return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
    const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();

    return y >= top && y <= bottom && x >= left && x <= right;
}


@Component({
    selector: 'hvac-quick-links',
    templateUrl: './quick-links.component.html',
    styleUrls: ['./quick-links.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class QuickLinksComponent implements OnInit, OnDestroy {
    @Select(ContentState.contentLoading) contentLoading$: Observable<boolean>;
    @Select(ContentState.contentBrand) userPrefBrand$: Observable<string>;
    @Input() inputLinks: BaseContentEntity;
    @ViewChild(CdkDropList) placeholder: CdkDropList;
    @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
    target: CdkDropList | null;
    source: CdkDropList | null;
    activeContainer: CdkDropList;
    targetIndex: number;
    sourceIndex: number;
    dragIndex: number;
    quickLinks$: Observable<QuickLink[]>;
    editableQuickLinks$: BehaviorSubject<QuickLink[]> = new BehaviorSubject([] as QuickLink[]);
    editableQuickLinkObservable$: Observable<QuickLink[]> = this.editableQuickLinks$.asObservable();
    quickLinksPerPage = 9;
    showEditView = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visibleLinks$: Observable<any[]>;
    hideEdit: boolean;
    brandSelection$ = new BehaviorSubject<string>('');

    swiperParams = {
        slidesPerView: 1,
        pagination: false,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        freeMode: {
            enabled: true,
            sticky: true
        },
        breakpointsInverse: false,
        navigation: {
            nextEl: `.hvac-quickLink-prev`,
            prevEl: `.hvac-quickLink-next`
        }
    };

    constructor(private viewportRuler: ViewportRuler, private readonly quickLinkService: QuickLinksService, private store: Store) { }

    ngOnInit() {
        this.contentLoading$.subscribe((loading: boolean) => {
            if (!loading && this.showEditView) {
                setTimeout(() => {
                    const phElement = this.placeholder.element.nativeElement;

                    phElement.style.display = 'none';
                    phElement.parentElement!.removeChild(phElement);
                }, 100);
            }
        });
        if (this.inputLinks) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.visibleLinks$ = of(this.inputLinks).pipe(map((inputQuickLinks:any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let quickLinks: any[] = [];
                if (inputQuickLinks && inputQuickLinks.Content && inputQuickLinks.Content.list) {
                    const unformattedQuickLinks = [...inputQuickLinks.Content.list.$values];

                    if (unformattedQuickLinks) {
                        const editable = JSON.parse(JSON.stringify(unformattedQuickLinks));
                        editable.sort((link1: {Position:number}, link2: {Position:number}) => link1.Position - link2.Position);
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const formatted = editable.map((link: any) => ({
                            title: link.Content.title,
                            contentId: link.Id,
                            target: link.Content.links.linkOperation.Key,
                            link: link.Content.links.linkExternal,
                            image: link.Content.primaryMedia.BinaryContent.Url || null,
                            position: link.Position,
                            status: typeof link.Status === 'undefined' ? true : link.Status,
                            tags: typeof link.Content?.tags === 'undefined' ? null : link.Content.tags
                        }));
                        const hidden = formatted.filter((link: {status:boolean}) => !link.status);
                        const visible = formatted.filter((link: {status:boolean}) => link.status);
                        const newFormatted = visible.concat(hidden);

                        quickLinks = newFormatted.map((link: string[], index: number) => ({
                            ...link,
                            position: index + 1
                        }));
                    }
                }
                const visibleLink = quickLinks.filter((link) => link.status);
                const visibleLinkOrdered = visibleLink.map((link, index) => ({
                    ...link,
                    position: index + 1
                }));

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const x = visibleLinkOrdered.map((_link: string, index: number) => (index % this.quickLinksPerPage === 0 ? quickLinks.slice(index, index + this.quickLinksPerPage) : null)).filter((res: any) => (res));

                return x;
            }));

            this.hideEdit = true;
        }
        else {
            this.quickLinks$ = this.quickLinkService.quickLinks$;
            this.visibleLinks$ = this.quickLinks$.pipe(map((quickLinks: QuickLink[]) => {
                const visibleLink = quickLinks.filter((link) => link.status);
                const visibleLinkOrdered = visibleLink.map((link, index) => ({
                    ...link,
                    position: index + 1
                }));

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return visibleLinkOrdered.map((_link: QuickLink, index: number) => (index % this.quickLinksPerPage === 0 ? quickLinks.slice(index, index + this.quickLinksPerPage) : null)).filter((res: any) => (res));
            }));

            this.quickLinks$.pipe(distinctUntilChanged()).subscribe((quickLinks: QuickLink[]) => {
                const editable = JSON.parse(JSON.stringify(quickLinks));
                this.editableQuickLinks$.next(editable);
            });
        }

        this.userPrefBrand$.subscribe((brandPref: string) => {
            this.brandSelection$.next(brandPref);
        });
    }

    ngOnDestroy() {
        this.showEditView = false;
    }

    dragMoved(evt: CdkDragMove) {
        const point = this.getPointerPositionOnPage(evt.event);

        this.listGroup._items.forEach((dropList) => {
            if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
                this.activeContainer = dropList;

                return;
            }
        });
    }

    dropListDropped() {
        if (!this.target) {
            return;
        }
        const phElement = this.placeholder.element.nativeElement;
        const parent = phElement.parentElement;

        phElement.style.display = 'none';

        parent!.removeChild(phElement);
        parent!.appendChild(phElement);
        parent!.insertBefore(this.source!.element.nativeElement, parent!.children[this.sourceIndex]);

        this.target = null;
        this.source = null;

        if (this.sourceIndex !== this.targetIndex) {
            this.editableQuickLinks$.pipe(take(1)).subscribe(((editableLinks: QuickLink[]) => {
                moveItemInArray(editableLinks, this.sourceIndex, this.targetIndex);
                const reordered = this.updateQuickLinkPositions(editableLinks);
                this.editableQuickLinks$.next(reordered);
                this.quickLinkService.updateQuickLinks(reordered);
            })).unsubscribe();
        }
    }

    dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        if (drop === this.placeholder) {
            return true;
        }

        if (drop !== this.activeContainer) {
            return false;
        }

        const phElement = this.placeholder.element.nativeElement;
        const sourceElement = drag.dropContainer.element.nativeElement;
        const dropElement = drop.element.nativeElement;

        const dragIndex = __indexOf(dropElement.parentElement!.children, (this.source ? phElement : sourceElement));
        const dropIndex = __indexOf(dropElement.parentElement!.children, dropElement);

        if (!this.source) {
            this.sourceIndex = dragIndex;
            this.source = drag.dropContainer;

            phElement.style.width = `${sourceElement.clientWidth}px`;
            phElement.style.height = `${sourceElement.clientHeight}px`;

            sourceElement.parentElement!.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = '';
        dropElement.parentElement!.insertBefore(phElement, (dropIndex > dragIndex ? dropElement.nextSibling : dropElement));

        return false;
    }

    /** Determines the point of the page that was touched by the user. */
    getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();

        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
    }

    toggleEditView() {
        const newView = !this.showEditView;
        if (newView) {
            this.showEditView = newView;

            return setTimeout(() => {
                const phElement = this.placeholder.element.nativeElement;

                phElement.style.display = 'none';
                phElement.parentElement!.removeChild(phElement);
            }, 10);
        }
        this.editableQuickLinkObservable$.pipe(take(1)).subscribe((quickLinks: QuickLink[]) => (this.quickLinkService.updateQuickLinks(quickLinks))).unsubscribe();

        this.showEditView = newView;

        return;
    }

    toggleLinkVis(link: QuickLink) {
        const changedLink = link;
        changedLink.status = !changedLink.status;
        this.editableQuickLinks$.pipe(take(1)).subscribe(((editableLinks: QuickLink[]) => {
            // find link position
            const linkIndex = editableLinks.findIndex((searchLink: QuickLink) => searchLink === link);
            // remove from list
            editableLinks.splice(linkIndex, 1);

            return changedLink.status ? this.showLink(changedLink, editableLinks) : this.hideLink(changedLink, editableLinks);
        })).unsubscribe();
    }

    hideLink(link: QuickLink, linkList: QuickLink[]) {
        linkList.push(link);
        const reordered = this.updateQuickLinkPositions(linkList);
        this.editableQuickLinks$.next(reordered);

        return this.quickLinkService.updateQuickLinks(reordered);
    }

    showLink(link: QuickLink, linkList: QuickLink[]) {
        const reversed = linkList;
        reversed.reverse();
        // find last visible item
        const firstVisible = reversed.findIndex((revLink) => revLink.status);
        firstVisible === -1 ? reversed.push(link) : reversed.splice(firstVisible, 0, link);
        reversed.reverse();
        const linksToUpdate = reversed;
        const reordered = this.updateQuickLinkPositions(linksToUpdate);
        this.editableQuickLinks$.next(reordered);

        return this.quickLinkService.updateQuickLinks(reordered);
    }

    updateQuickLinkPositions(linksToOrder: QuickLink[]): QuickLink[] {
        linksToOrder.forEach((link, index) => {
            link.position = index + 1;
        });

        return linksToOrder.sort((position1, position2) => position1.position - position2.position);
    }

    position(_index: number, item: QuickLink) {
        // or item.id
        return item.position;
    }

    checkSiteMap(quickLink: QuickLink) {
        if (quickLink.tags) {
            if (this.brandSelection$.value === 'default') {
                const query = { q: quickLink.link.replace('?Brand=', '') };
                this.store.dispatch(new Navigate(['/brand-select'], query));

                return;
            }
            const queryBrand = this.formatBrandQueryParam(quickLink.link.replace('?Brand=', ''), this.brandSelection$.value);
            const linkUrl = quickLink.link.concat(`${queryBrand}`);
            window.open(`${linkUrl}`, `${quickLink.target}`);

            return;
        }

        window.open(`${quickLink.link}`, `${quickLink.target}`);
    }

    formatBrandQueryParam(externalLink: string, brand: string) {
        let formattedBrandName = '';
        if (externalLink.includes('opcost')) {
            formattedBrandName = brand === 'Day & Night' ? 'DayAndNight' : brand;
        }
        else {
            formattedBrandName = brand === 'Day & Night' ? 'DayandNight' : brand;
        }

        return formattedBrandName;
    }
}
