<div class="hvac-product-details-alerts">
    <hvac-tab-bar class="hvac-alert-status-filters" variant="secondary" *ngIf="enableFilter">
        <button  (click)="handleTabClick(AlertStatusFilter.ALL)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.ALL}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.ALL' | translate }}</button>
        <button  (click)="handleTabClick(AlertStatusFilter.ACTIVE)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.ACTIVE}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.ACTIVE' | translate }}</button>
        <button  (click)="handleTabClick(AlertStatusFilter.HISTORICAL)" [ngClass]="{ 'hvac-active' : selectedTab === AlertStatusFilter.HISTORICAL}">{{ 'CONNECTED_PORTAL.ALERTS.FILTERS.HISTORICAL' | translate }}</button>
    </hvac-tab-bar>
    <hvac-table [responsive]="true">
        <thead class="hvac-product-details-alerts-headers">
            <tr>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.CODE' | translate }}</th>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.SEVERITY' | translate }}</th>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.TYPE' | translate }}</th>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.DESCRIPTION' | translate }}</th>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.SERVER_TIMESTAMP' | translate }}</th>
                <th>{{ 'CONNECTED_PORTAL.PRODUCT_ALERTS.OCCURRENCES' | translate }}</th>
            </tr>
        </thead>

        <tbody class="hvac-product-details-alerts-body">
            <tr *ngFor="let alert of data">
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.CODE' | translate" class="hvac-h4">{{ alert.error.code }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.SEVERITY' | translate">
                <hvac-alert-chip *ngIf="alert.error.severity" [alertType]="alert.error.severity | severityToAlertType">{{alert.error.severity}}</hvac-alert-chip></td>
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.TYPE' | translate" class="hvac-fw-md">{{ alert.error.type }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.DESCRIPTION' | translate">{{ alert.error.description }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.SERVER_TIMESTAMP' | translate">
                    <div>
                        <div class="hvac-fw-sb hvac-rhythm-1">{{ alert.dateTime | dateTimeTz: 'date' }}</div>
                        <div>{{ alert.dateTime | dateTimeTz: 'time' }}</div>
                    </div>
                </td>
                <td [attr.data-label]="'CONNECTED_PORTAL.PRODUCT_ALERTS.OCCURRENCES' | translate" class="hvac-fw-sb">{{ alert.occurrenceCount }}</td>
            </tr>

            <ng-container *ngIf="data?.length === 0">
                <tr>
                    <td colspan="6" style="text-align: center;">
                        <div class="hvac-product-details-alerts-empty hvac-fw-bd">{{ 'CONNECTED_PORTAL.ALERTS.LIST.EMPTY_MESSAGE' | translate }}</div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </hvac-table>

    <hvac-pagination
        *ngIf="totalPages > 1"
        [totalPages]="totalPages"
        [currentPage]="currentPage"
        (pageChange)="handlePageChange($event)">
    </hvac-pagination>
</div>
