import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AuthState, LoggedIn } from 'common/store/auth/auth.state';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Login implements CanActivate {
    @Select(AuthState.loggedIn) loggedIn$: Observable<LoggedIn>;

    constructor(private readonly store: Store) { }

    canActivate(route: ActivatedRouteSnapshot) {
        return route.queryParams.skipAuth
            ? true
            : this.loggedIn$.pipe(skipWhile((loggedIn) => loggedIn === LoggedIn.Unknown), map((loggedIn) => {
                if (loggedIn === LoggedIn.LoggedIn) {
                    this.store.dispatch(new Navigate(['/']));

                    return false;
                }

                return true;
            }));
    }
}
