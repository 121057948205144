<ng-template #customerInfo let-alert="alert">
    <div class="hvac-p-sm hvac-connected-portal-alerts-list-customer-name">
        <a
            class="hvac-text-link"
            [routerLink]="'../customers/' + alert.propertyId"
            >{{ alert.customerName }}</a
        >
    </div>
    <div
        class="
            hvac-p-sm
            hvac-fw-rg
            hvac-connected-portal-alerts-list-customer-address
        "
    >
        {{ alert.propertyAddress }}
    </div>
    <div class="hvac-p-sm hvac-connected-portal-alerts-list-customer-telephone">
        <a
            *ngIf="alert.customerPhone"
            class="hvac-text-link"
            [attr.href]="'tel:' + alert.customerPhone"
            >{{ alert.customerPhone }}</a
        >
    </div>
</ng-template>

<ng-template #alertChip let-alert="alert">
    <hvac-alert-chip
        *ngIf="alert?.alertLevel"
        [alertType]="alert.alertLevel | severityToAlertType"
        >{{ alert.alertLevel }}</hvac-alert-chip
    >
</ng-template>

<ng-template #datetime let-alert="alert">
    <div class="hvac-p-sm hvac-connected-portal-alerts-list-date">
        {{ alert.datetime | dateTimeTz: "date" }}
    </div>
    <div class="hvac-p-sm hvac-fw-rg hvac-connected-portal-alerts-list-time">
        {{ alert.datetime | dateTimeTz: "time" }}
    </div>
</ng-template>

<ng-template #product let-alert="alert">
    <div
        class="hvac-p-sm hvac-connected-portal-alerts-list-product-model-number"
    >
        {{ alert.modelNumber }}
    </div>
    <div
        class="
            hvac-p-sm hvac-connected-portal-alerts-list-product-serial-number
        "
    >
        <a
            *ngIf="alert.type === ProductType.IndoorUnit"
            class="hvac-text-link"
            routerLink="../customers/{{
                alert.propertyId
            }}/products/indoor-unit/{{ alert.systemType }}/{{
                alert.wallControlSerialNo
            }}"
            >{{ alert.serialNumber }}</a
        >
        <a
            *ngIf="alert.type === ProductType.OutdoorUnit"
            class="hvac-text-link"
            routerLink="../customers/{{
                alert.propertyId
            }}/products/outdoor-unit/{{ alert.systemType }}/{{
                alert.wallControlSerialNo
            }}"
            >{{ alert.serialNumber }}</a
        >
        <a
            *ngIf="alert.type === ProductType.WallControl"
            class="hvac-text-link"
            routerLink="../customers/{{
                alert.propertyId
            }}/products/wall-control/{{ alert.systemType }}/{{
                alert.wallControlSerialNo
            }}"
            >{{ alert.serialNumber }}</a
        >
        <a
            *ngIf="alert.type === ComputedAlertType"
            class="hvac-text-link"
            routerLink="../customers/{{
                alert.propertyId
            }}/products/wall-control/{{ alert.systemType }}/{{
                alert.wallControlSerialNo
            }}"
            >{{ alert.serialNumber }}</a
        >
    </div>
</ng-template>

<ng-template #errorCode let-alert="alert">
    <div class="hvac-p-sm hvac-connected-portal-alerts-list-error-code">
        {{ alert.errorCode }}
    </div>
    <div
        class="
            hvac-p-sm hvac-fw-rg hvac-connected-portal-alerts-list-error-message
        "
    >
        {{ alert.errorMessage }}
    </div>
    <div class="hvac-p-sm hvac-connected-portal-alerts-list-error-url">
        <a
            *ngIf="alert.troubleshootUri"
            href="{{ alert.troubleshootUri }}"
            target="_blank"
            hvacButton="default"
            size="small"
            >{{ "CONNECTED_PORTAL.ALERTS.LIST.TROUBLESHOOT" | translate }}</a
        >
    </div>
</ng-template>

<ng-template #removeSelect let-alert="alert">
    <utc-checkbox
        (change)="handleAlertSelectChange($event)"
        [checked]="alert.selectedForRemoval"
        [value]="alert.id"
    ></utc-checkbox>
</ng-template>

<div class="hvac-connected-portal-alerts-list-container">
    <ul class="hvac-connected-portal-alerts-list">
        <ng-container *ngIf="alerts">
            <li
                class="hvac-connected-portal-alerts-list-item"
                *ngFor="let alert of alerts; trackBy: trackByAlert"
            >
                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-customer
                    "
                >
                    <div class="hvac-subhead hvac-col-xs-4">
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.CUSTOMER_INFO"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="customerInfo"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>
                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-severity
                    "
                >
                    <div class="hvac-subhead hvac-col-xs-4">
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.SEVERITY"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="alertChip"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>
                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-datetime
                    "
                >
                    <div class="hvac-subhead hvac-col-xs-4">
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_DATE"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="datetime"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>
                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-error
                    "
                >
                    <div class="hvac-subhead hvac-col-xs-4">
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_CODE"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="errorCode"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>

                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-product
                    "
                >
                    <div
                        class="
                            hvac-subhead
                            hvac-col-xs-4
                            hvac-connected-portal-alerts-list-field-label
                        "
                    >
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.MODEL_SERIAL"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="product"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>

                <div
                    class="
                        hvac-row
                        hvac-top-xs
                        hvac-connected-portal-alerts-list-field-group
                        hvac-connected-portal-alerts-list-remove
                    "
                >
                    <div
                        class="
                            hvac-subhead
                            hvac-col-xs-4
                            hvac-connected-portal-alerts-list-field-label
                        "
                    >
                        {{
                            "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.SELECT"
                                | translate
                        }}
                    </div>
                    <div
                        class="
                            hvac-col-xs-8
                            hvac-connected-portal-alerts-list-field-value
                        "
                    >
                        <ng-container
                            [ngTemplateOutlet]="removeSelect"
                            [ngTemplateOutletContext]="{ alert: alert }"
                        ></ng-container>
                    </div>
                </div>
            </li>
        </ng-container>
        <ng-container *ngIf="!isLoading && alerts?.length === 0">
            <div class="hvac-connected-portal-alerts-list-empty hvac-fw-bd">
                {{ "CONNECTED_PORTAL.ALERTS.LIST.EMPTY_MESSAGE" | translate }}
            </div>
        </ng-container>

        <utc-loading-spinner
            variant="overlay"
            *ngIf="isLoading"
        ></utc-loading-spinner>
    </ul>

    <hvac-table [responsive]="true" theme="zebra">
        <thead>
            <tr class="hvac-connected-portal-alerts-list-table-header">
                <th class="hvac-subhead">
                    <utc-checkbox class="select-all-checkbox" [checked]="allSelected" (change)="toggleSelectAll()">
                        <span class="alert-selected-count" *ngIf="selectedCount">({{selectedCount}})</span>
                    </utc-checkbox>
                </th>
                <th class="hvac-subhead">
                    {{
                        "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.SEVERITY"
                            | translate
                    }}
                </th>
                <th class="hvac-subhead">
                    {{
                        "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.CUSTOMER_INFO"
                            | translate
                    }}
                </th>
                <th class="hvac-subhead">
                    {{
                        "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_DATE"
                            | translate
                    }}
                </th>
                <th class="hvac-subhead">
                    {{
                        "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.MODEL_SERIAL"
                            | translate
                    }}
                </th>
                <th class="hvac-subhead">
                    {{
                        "CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_CODE"
                            | translate
                    }}
                </th>
            </tr>
        </thead>

        <tbody [ngClass]="{ 'hvac-cp-empty-list': alerts?.length === 0 }">
            <tr *ngFor="let alert of alerts; trackBy: trackByAlert">
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.SELECT'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="removeSelect"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.SEVERITY'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="alertChip"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.CUSTOMER_INFO'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="customerInfo"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_DATE'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="datetime"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.MODEL_SERIAL'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="product"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
                <td
                    [attr.data-label]="
                        'CONNECTED_PORTAL.ALERTS.ALERT_HEADINGS.ALERT_DATE'
                            | translate
                    "
                >
                    <ng-container
                        [ngTemplateOutlet]="errorCode"
                        [ngTemplateOutletContext]="{ alert: alert }"
                    ></ng-container>
                </td>
            </tr>
            <ng-container *ngIf="!isLoading && alerts?.length === 0">
                <tr>
                    <td colspan="6" [align]="'center'">
                        <div
                            class="
                                hvac-connected-portal-alerts-list-empty
                                hvac-fw-bd
                            "
                        >
                            {{
                                "CONNECTED_PORTAL.ALERTS.LIST.EMPTY_MESSAGE"
                                    | translate
                            }}
                        </div>
                    </td>
                </tr>
            </ng-container>

            <utc-loading-spinner
                variant="overlay"
                *ngIf="isLoading"
            ></utc-loading-spinner>
        </tbody>
    </hvac-table>

    <div class="hvac-connected-portal-alerts-list-footer">
        <div class="hvac-connected-portal-alerts-list-footer-pagination">
            <hvac-pagination
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)"
            >
            </hvac-pagination>
        </div>
        <div
            class="hvac-connected-portal-alerts-list-footer-actions"
            *ngIf="alerts"
        >
            <button
                class="hvac-cp-action-button"
                hvacButton="default"
                [size]="isDesktopBp ? 'default' : 'small'"
                [disabled]="!hasAlertsSelected"
                (click)="handleRemoveSelected()"
            >
                {{
                    "CONNECTED_PORTAL.ALERTS.ACTIONS.REMOVE_SELECTED"
                        | translate
                }}
            </button>

            <button
                class="hvac-cp-action-button"
                hvacButton="primary"
                [size]="isDesktopBp ? 'default' : 'small'"
                [disabled]="alerts.length === 0"
                (click)="handleExportAlerts()"
            >
                <ng-container *ngIf="hasAlertsSelected; else exportAll">{{
                    "CONNECTED_PORTAL.ALERTS.ACTIONS.EXPORT_SELECTED"
                        | translate
                }}</ng-container>
                <ng-template #exportAll>{{
                    "CONNECTED_PORTAL.ALERTS.ACTIONS.EXPORT_ALL" | translate
                }}</ng-template>
            </button>
        </div>
    </div>
</div>
