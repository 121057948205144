import { ChangeDetectorRef, Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'common/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
import { ErrorService } from 'common/content/services/error.service';

@Component({
    selector: 'hvac-distributor',
    templateUrl: './distributor.component.html',
    styleUrls: ['./distributor.component.scss']
})
export class DistributorComponent implements OnInit, AfterViewInit {
    public showToast = false;

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (!environment.features.connectedPortal.distributors) {
            this.errorService.errorStatus$.next(404);
            this.cdr.detectChanges();
            this.showToast = true;
        }
    }

    ngAfterViewInit(): void {
        if (this.showToast) {
            this.toastService.add({
                bgColor: '#F8F8F8',
                content: this.translate.instant('CONNECTED_PORTAL.BETA_DISTRIBUTOR_INTERNAL_TOAST'),
                id: 'cp-beta-message',
                closeable: true
            });
        }
    }
}

