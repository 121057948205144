<section class="hvac-document-results-content hvac-content-container">
    <div class="hvac-document-results-controls">
        <div class="hvac-document-results-filter-control">
            <button class="hvac-text-button" type="button" (click)="toggleFilterMenu()"><span
                    class="hvac-document-results-show-text">{{ 'PRODUCTS_LITERATURE.SHOW' | translate }} </span>{{
                'PRODUCTS_LITERATURE.FILTERS' | translate }}</button>
        </div>
        <div class="hvac-document-results-displayed-text-container">
            <p class="hvac-document-results-displayed-text" *ngIf="allDocuments.length > 0">
                {{
                'PRODUCTS_LITERATURE.RESULT_COUNT_MESSAGE' | translate: { begin: displayCount , total:
                allDocuments.length
                } }}
            </p>
        </div>
    </div>
    <div class="hvac-document-results-main-content">
        <div class="hvac-document-results-filter-and-list">
            <div class="hvac-document-results-filter" [ngClass]="{ 'hvac-filters-toggled': (filterView$ | async) }" >
                <hvac-document-filter-list [filterData]="filterModel$ | async"
                    (filterQuery)="filterQuery(allDocuments,$event)" (closeFilter)="toggleFilterMenu()">
                </hvac-document-filter-list>
            </div>

            <div class="hvac-document-results-list-container" id="document-results">
                <div *ngIf="(displayedDocuments$ | async)?.length; else noResults">
                    <div *ngFor="let data of displayedDocuments$ | async">
                        <div class="collapsible" (click)="toggleResultRow(data)"
                            [ngClass]="{ 'expanded': expandedRow === data.title  }">
                            <div class="hvac-document-title">
                                <label>{{ data.title }}</label>
                                <div *ngIf="data.count > 0" class="hvac-document-count">
                                    {{ data.count }}</div>
                            </div>
                            <utc-svg class="havc-document-row-chevron" src="assets/icons/chevron-down.svg">
                            </utc-svg>
                        </div>
                        <div class="content" [ngClass]="expandedRow === data.title ? 'show' : 'hide' ">
                            <hvac-data-table [data]="data.documents" [dataHeaders]="columnHeaders"  [dataSort]="dataSort"
                                translationPrefix="PRODUCTS_LITERATURE.COLUMN_HEADER." (onClick)="onClick($event)"
                                [search]="false" [pageSize]="data.documents.length">
                            </hvac-data-table>
                        </div>
                    </div>
                    <div *ngIf="configBrand === 'private'" class="havc-product-literature-export-container">
                        <utc-button buttonType="button" classNames="hvac-export-button"
                            title="{{'PRODUCTS_LITERATURE.EXPORT.ENTIRE_LIST' | translate}}"
                            label="{{'PRODUCTS_LITERATURE.EXPORT.ENTIRE_LIST' | translate}}" (click)="exportFullList()">
                            {{'PRODUCTS_LITERATURE.EXPORT.ENTIRE_LIST' | translate}}</utc-button>
                        <utc-button buttonType="button" classNames="hvac-export-button"
                            label="{{'PRODUCTS_LITERATURE.EXPORT.FILTER' | translate}}"
                            title="{{'PRODUCTS_LITERATURE.EXPORT.FILTER' | translate}}" (click)="exportFilteredList()"
                            *ngIf="shouldDisplayExportFilterButton$ | async">
                        </utc-button>
                    </div>
                    <div *ngIf= "hasMore" class="hvac-document-results-load-more-container">
                        <utc-button buttonType="button" classNames="hvac-shadow-button-with-icon hvac-shadow-button-text-centered hvac-view-more-button" label="{{ 'PRODUCTS_LITERATURE.VIEW_MORE' | translate }}" [title]="'PRODUCTS_LITERATURE.VIEW_MORE' | translate" (click)= "loadMoreDocuments()"></utc-button>
                    </div>
                </div>
                <ng-template #noResults>
                    <div class="no-records-found">
                        <div class="hvac-product-spinner" *ngIf="documentRequesting$ | async">
                            <utc-loading-spinner [spinnerSize]="'small'"></utc-loading-spinner>
                        </div>
                        <span *ngIf="!(documentRequesting$ | async)">{{'PRODUCTS_LITERATURE.NO_DOCUMENT_FOUND' |
                            translate}}</span>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</section>
