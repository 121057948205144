import { Component, Input } from '@angular/core';
import { NotificationPreferences } from 'private/app/models/connected-system.model';

@Component({
    selector: 'hvac-system-notification-preferences',
    templateUrl: './system-notification-preferences.component.html',
    styleUrls: ['./system-notification-preferences.component.scss']
})
export class SystemNotificationPreferencesComponent {
    @Input() data: NotificationPreferences;
}
