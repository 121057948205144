<main class="hvac-main-content-container">
    <div class='hvac-reset-password-information'>
        <h2 class='hvac-reset-password-information-title hvac-hero-large-text'> {{'CHANGE_PASSWORD_INFORMATION.TITLE' |
            translate }}</h2>
        <div class="hvac-password-banner">
            <hvac-toast ></hvac-toast>
        </div>

        <form [formGroup]="passwordResetForm">
            <div class='hvac-reset-password-container'>
                <div class="hvac-reset-password-container-column">

                    <utc-password-input [disabled]="(apiRequestActive$ | async)!" inputId="reset-password-old"
                        [error]="passwordResetForm.controls.currentPasswordControl.dirty && !passwordResetForm.controls.currentPasswordControl.valid"
                        [passwordControl]="passwordResetForm.controls.currentPasswordControl"
                        [label]="'CHANGE_PASSWORD_INFORMATION.LABEL.CURRENT_PASSWORD' | translate" type="password"
                        class='hvac-create-account-input'></utc-password-input>
                </div>

                <div class="hvac-reset-password-container-column">

                    <utc-password-input [requirements]="(passwordRequirements$ | async)!"
                        [error]="passwordResetForm.controls.newPasswordControl.dirty && !passwordResetForm.controls.newPasswordControl.valid"
                        [errorMessage]="(passwordErrorMessage$ | async)!" [disabled]="(apiRequestActive$ | async)!"
                        inputId="reset-password-new" [passwordControl]="passwordResetForm.controls.newPasswordControl"
                        [label]="'CHANGE_PASSWORD_INFORMATION.LABEL.NEW_PASSWORD' | translate" type="password"
                        class='hvac-create-account-input'></utc-password-input>
                </div>

            </div>
            <div class="hvac-password-button-submit"><button hvacButton="primary"
                    [disabled]="!(infoValid$ | async) || (apiRequestActive$ | async)" (click)="submitAccountInfo()">
                    <span>{{ 'CHANGE_PASSWORD_INFORMATION.LABEL.SUBMIT' |translate}}</span>
                    <utc-svg [src]="'assets/icons/arrow.svg'"></utc-svg>
                </button>
            </div>
        </form>
    </div>
</main>
