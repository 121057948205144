<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
        <div class="hvac-cp-stats" *ngIf="isDistributorStatsFeatureEnabled">
            <hvac-connected-portal-stats [statsData]="(stats$ | async)!"
                [isLoading]="isLoadingList.stats"></hvac-connected-portal-stats>
        </div>
    </div>

    <div bottom>
        <section class="hvac-cp-card">
            <div class="hvac-cp-search">
                <hvac-search-bar
                    [label]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.DEALER_SEARCH' | translate"
                    [queryControl]="queryControl"
                    [placeholder]="'CONNECTED_PORTAL.DISTRIBUTOR_DEALERS.SEARCH_PLACEHOLDER' | translate"
                    (onSearchSubmit)="handleSearchSubmit($event)"
                    ></hvac-search-bar>
            </div>
        </section>

        <section class="hvac-cp-card">
            <h3 class="hvac-reports-header hvac-h3">{{ 'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.REPORTS' | translate }}</h3>
            <ng-container *ngIf="distributorId">
                <div class="hvac-rhythm-3">
                    <hvac-connected-control-report [distributorId]="distributorId"></hvac-connected-control-report>
                </div>
            </ng-container>

            <ng-container *ngIf="distributorId">
                <div class="hvac-row">
                    <div class="hvac-col-xs-12" [ngClass]="{ 'hvac-col-lg-6': isOpportunityReportUsageGraphEnabled}">
                        <hvac-dealer-logins-report [distributorId]="distributorId"></hvac-dealer-logins-report>
                    </div>

                    <div *ngIf="isOpportunityReportUsageGraphEnabled" class="hvac-col-xs-12 hvac-col-lg-6">
                        <hvac-opportunity-usage-report [distributorId]="distributorId"></hvac-opportunity-usage-report>
                    </div>
                </div>
            </ng-container>
        </section>
    </div>
</hvac-connected-portal-layout-container>
