
<div class="hvac-association-title-section hvac-contacts-title-section" #contactContainer>
    <h1 class="havc-association-header-title">{{ 'ACCOUNT_ADMIN.ASSOCIATED_USERS' | translate }}</h1>
    <div class="hvac-association-add-button-subtitle-container">
    <div *ngIf="isSameFamily && addContact && company.isActive" class="hvac-add-contact-wrap hvac-add-contact-container">
        <button class="hvac-text-link" (click)="showAddContactModal = true"> {{ 'ACCOUNT_ADMIN.ADD_CONTACT_WITH_PLUS' | translate}}</button>
    </div>
    <div class="hvac-association-subtitle-conatiner" *ngIf="!loading && totalCount">
        <h2 *ngIf="pagination === 1; else nonZeroPage" class="havc-association-sub-header-title hvac-association-sub-title">{{ 'ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE_FIRST_PAGE' | translate: { begin: displayCountStart , total:
            totalCount
            } }}</h2>
            <ng-template #nonZeroPage>
                <h2 class="havc-association-sub-header-title">{{ 'ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE' | translate: { begin: displayCountStart, end: displayCountEnd, total:
                    totalCount
                    } }}</h2>
            </ng-template>
    </div>
    </div>
</div>
<div class="hvac-account-admin-table-wrap hvac-rhythm-3">
    <hvac-table>
        <thead>
            <tr>
                <td *ngFor="let item of dataHeaders">
                    <button class="hvac-table-sort-btn">
                        <span class="hvac-association-detail-column-header">{{item.title}}</span>
                        <span class="hvac-table-list-sort" [ngClass]="{
                            'hvac-table-list-sort-show': item.order,
                            'column-ascending': item.order === 'asc'
                        }">
                            <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                        </span>
                    </button>
                </td>
            </tr>
        </thead>
        <tbody>
            <a [routerLink]="['../../contact/', contact.user.id]" queryParamsHandling="merge" class="hvac-account-admin-table-link hvac-association-details-table-link" *ngFor="let contact of contactDetails$ | async">
                <tr>
                    <td>
                        <div class="hvac-association-name-column">{{contact.user.firstName}} {{contact.user.lastName}}</div>
                        <div class="hvac-p-xs hvac-association-detail-fields">{{createDetails(contact.user)}}
                            <br />
                            {{'ACCOUNT_ADMIN.USER_ID' | translate }}: {{contact.user.id}}
                        </div>
                    </td>
                    <td>
                        {{contact.user.id}}
                    </td>
                    <td>
                        {{contact.user.status | titlecase}}
                    </td>
                    <td>
                        {{contact?.user?.contactOnly ? ('ACCOUNT_ADMIN.CONTACT' | translate) : ('ACCOUNT_ADMIN.USER' | translate)}}
                    </td>
                </tr>
            </a>
        </tbody>
    </hvac-table>
    <p class="hvac-account-admin-no-results" *ngIf="!(contactDetails$ | async)?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
    <div class="hvac-association-detail-loader"><utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small"></utc-loading-spinner>
    </div>
</div>
<div class="hvac-associations-pagination-control">
    <hvac-pagination *ngIf="totalPages > 1"
        [totalPages]="totalPages"
        [currentPage]="pagination"
        (pageChange)="handlePageChange($event)">
    </hvac-pagination>
</div>
<div *ngIf="totalPages > 1 || !(contactDetails$ | async)?.length" class="hvac-divider"></div>

<utc-modal
    class="hvac-account-admin-add-contact-modal"
    [visible]="showAddContactModal"
    (closeAction)="showAddContactModal = false">

    <hvac-modal-header>{{ 'ACCOUNT_ADMIN.ADD_CONTACT' | translate}}</hvac-modal-header>

    <div class="hvac-account-admin-add-contact-modal-body">
        <div>
            <hvac-toast class="hvac-rhythm-5" [name]="toastOutlet"></hvac-toast>

            <div class="hvac-rhythm-5">
                <utc-select
                    [group]="addContactForm"
                    control="contactType"
                    [defaultOption]="'ACCOUNT_ADMIN.SELECT_CONTACT_TYPE' | translate"
                    [options]="contactTypeOptions">
                </utc-select>
            </div>

            <div class="hvac-form-field">
                <utc-select
                    *ngIf="addContactForm.controls.contactType.value[0]?.value === 'AM'"
                    [group]="addContactForm"
                    control="accountManager"
                    [defaultOption]="'ACCOUNT_ADMIN.SELECT_ACCOUNT_MANAGER' | translate"
                    [options]="formData.accountManagers || []">
                </utc-select>

                <utc-select
                    *ngIf="addContactForm.controls.contactType.value[0]?.value === 'RSM'"
                    [group]="addContactForm"
                    control="rsm"
                    [defaultOption]="'ACCOUNT_ADMIN.SELECT_RSM' | translate"
                    [options]="formData.rsms || []">
                </utc-select>

                <utc-select
                    *ngIf="addContactForm.controls.contactType.value[0]?.value === 'TSM'"
                    [group]="addContactForm"
                    control="tsm"
                    [defaultOption]="'ACCOUNT_ADMIN.SELECT_TSM' | translate"
                    [options]="formData.tsms || []">
                </utc-select>
            </div>
        </div>

        <button class="hvac-button-primary hvac-account-admin-add-contact-button hvac-primary-button"
            [disabled]="!addContactForm.controls.accountManager.value[0]?.value &&
            !addContactForm.controls.rsm.value[0]?.value &&
            !addContactForm.controls.tsm.value[0]?.value"
            (click)="postContact()">
            {{ 'ACCOUNT_ADMIN.ADD_CONTACT' | translate}}
        </button>
    </div>
</utc-modal>
