<div class="hvac-content-container hvac-opticlean-outer">
    <p class="hvac-opticlean-description">
        {{"OPTICLEAN.PAGE_DESCRIPTION" | translate }}
    </p>
    <div class="hvac-opticlean-calculate-form-header">
        <h1>{{'OPTICLEAN.CALCULATE' | translate }}</h1>

        <div class="hvac-opticlean-calculate-form-radio">
            <hvac-radio-button [inputName]="'location'" value="'OPTICLEAN.UNIT_QTY'" (change)="updateRadioValue(true)"
            [checked]="unitQtyChecked || true" >
                {{ 'OPTICLEAN.UNIT_QTY' | translate }}
            </hvac-radio-button>
            <hvac-radio-button [inputName]="'location'" value="'OPTICLEAN.ACH_FOR_SPACE'" (change)="updateRadioValue(false)"
            [checked]="unitQtyChecked || false" >
                {{ 'OPTICLEAN.ACH_FOR_SPACE' | translate }}
            </hvac-radio-button>
        </div>

    </div>
    <div class="hvac-opticlean-form">
        <div class="hvac-opticlean-form-row-items">
            <div class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.UNIT_SIZE' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-select shadow="light" [group]="opticleanService.opticleanForm"
                        control="unitSizeValue"
                        [options]="(unitSizeValues$ | async)|| []" (onChange)="unitSizeValueChange($event)"></utc-select>

                </div>
            </div>
            <div class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.MOTOR_SPEED' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-select shadow="light" [group]="opticleanService.opticleanForm"
                        control="motorSpeedValue"
                        [options]="motorSpeedValues" (onChange)="motorValueChange($event)"></utc-select>

                </div>
            </div>
            <div class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.CFM' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-input shadow="light" [disabledInput]="true"
                        [inputControl]="opticleanService.opticleanForm.controls.cfm"></utc-input>
                </div>
            </div>
            <div *ngIf="unitQtyChecked$ | async" class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.ACH' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-input inputId="opticlean-ach-input" shadow="light" type="number"
                    [errorMessage]="errorMessage" [error]="showAchError"
                        [inputControl]="opticleanService.opticleanForm.controls.achValue"></utc-input>
                </div>
            </div>
            <div *ngIf="achChecked$ | async" class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.UNIT_QUANTITY' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-input inputId="opticlean-unitQty-input" shadow="light" type="number"
                    [errorMessage]="errorMessage" [error]="showUnitQuantityError"
                        [inputControl]="opticleanService.opticleanForm.controls.unitQuantity"></utc-input>
                </div>
            </div>
            <div class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.ROOM_HEIGHT' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-input inputId="opticlean-roomheight-input" shadow="light" [value]="'10'" type="number"
                    [errorMessage]="errorMessage" [error]="showRoomHeightError"
                        [inputControl]="opticleanService.opticleanForm.controls.roomHeight"></utc-input>
                </div>
            </div>
            <div class="hvac-opticlean-calculate-form-item">
                <h2>{{'OPTICLEAN.ROOM_AREA' | translate }}</h2>
                <div class="hvac-opticlean-calculate-form-values">
                    <utc-input inputId="opticlean-roomarea-input" shadow="light" [value]="'500'" type="number"
                    [errorMessage]="errorMessage" [error]="showRoomAreaError"
                        [inputControl]="opticleanService.opticleanForm.controls.roomArea"></utc-input>
                </div>
            </div>
            <div class="hvac-opticlean-calculate-form-item">
                <utc-button label="{{ 'OPTICLEAN.CALCULATE' | translate }}"
                    (click)="calculate()"
                    classNames="hvac-button-primary hvac-button-dark">
                </utc-button>
            </div>

            <p *ngIf="(unitQtyChecked$ | async) && qtyResult!=0" class="hvac-opticlean-result">{{'OPTICLEAN.QTY_RESULT_TEXT_ONE' | translate }} <strong>{{qtyResult}}</strong> {{'OPTICLEAN.QTY_RESULT_TEXT_TWO' | translate }}</p>
            <p *ngIf="(achChecked$ | async) && achResult!=0" class="hvac-opticlean-result"> {{'OPTICLEAN.ACH_RESULT_TEXT' | translate }}</p>
            <p *ngIf="(achChecked$ | async) && achResult!=0" class="hvac-opticlean-result hvac-opticlean-bold">{{achResult}}</p>

        </div>
        <div class="hvac-opticlean-calculate-form-image">
            <img class="hvac-opticlean-form-image" alt="{{ 'OPTICLEAN.OPTICLEAN' | translate }}" [src]="'assets/images/opticlean_tool.png'" />
        </div>
    </div>
</div>