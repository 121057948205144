/* eslint-disable max-lines */
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectedPortalAlert } from './components/connected-portal-alerts/connected-portal-alerts.component';
import { ConnectedPortalStat } from '../../components/connected-portal-stats/connected-portal-stats.component';
import { EMPTY, Observable, Subject } from 'rxjs';
import { AlertSeverity, AlertSeverityFilter, DealerAlertResponse } from 'private/app/models/alert.model';
import { DateRangeQueryParams } from 'private/app/models/default-query-params';
import { ContentHeadingData } from 'common/models/content-heading';
import { ConnectedPortalDealerDashboardQueryService } from './services/connected-portal-dealer-dashboard-query.service';
import { ConnectedPortalDealerDashboardAlertsService } from './services/connected-portal-dealer-dashboard-alerts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AppConstants } from 'common/app-constants';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { ConnectedDealerStats } from 'private/app/models/connected-dealer.model';
import { CharSymbol } from 'common/enums/char-symbol';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { sub } from 'date-fns';
import { DecimalPipe } from '@angular/common';
import { AddToast, ToastService } from 'common/services/toast.service';
import { ApiResponseCode, MISSING_ROUTE_PARAMS_ERROR, ToastOutlet } from '../../constants';
import { CompanyCode } from 'private/app/models/company.model';
import { ErrorService } from 'common/content/services/error.service';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DateRange } from 'private/app/models/account-admin-program.model';
import { HeapService } from 'common/services/heap.service';
import { eventNames } from 'private/app/views/connected-portal/heap/constants';

const EXPORT_ALL_FILENAME_PREFIX = 'alerts-export-all';
const EXPORT_SELECTED_FILENAME_PREFIX = 'alerts-export-selected';
const INITIAL_PAGE = 1;

export interface Stats {
    data?: ConnectedDealerStats;
    error?: boolean
}

export interface Alerts {
    data?: ConnectedPortalAlert[];
    error?: boolean
}

@Component({
    selector: 'hvac-dealer-dashboard',
    templateUrl: './dealer-dashboard.component.html',
    styleUrls: ['./dealer-dashboard.component.scss'],
    providers: [ConnectedPortalDealerDashboardQueryService, ConnectedPortalDealerDashboardAlertsService]
})
export class DealerDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    readonly AppConstants = AppConstants;
    ngOnDestroy$ = new Subject();
    alertCount: number;
    alertsCurrentPage = INITIAL_PAGE;
    alertsListPageSize = 5;
    alertsListPageTotal: number | null = null;
    alertsSeverityFilter: AlertSeverityFilter = AlertSeverity.CRITICAL;
    alertsStartDate = sub(new Date(), { days: AppConstants.twoWeeksRange }).getTime();
    alertsEndDate = Date.now();
    stats$: Observable<ConnectedPortalStat[]>;
    isModalVisible = false;
    queryControl = new UntypedFormControl('', Validators.required);

    isLoadingList = {
        stats: false,
        alerts: false
    };

    public companyCode$: Observable<CompanyCode>;
    public alerts$?: Observable<ConnectedPortalAlert[]>;
    public breadCrumbData: ContentHeadingData = { };
    readonly CompanyCode = CompanyCode;

    private dealerId?: string;

    constructor(
        private dealersService: DealersService,
        private alertsService: AlertsService,
        private translateService: TranslateService,
        private queryParamsService: ConnectedPortalDealerDashboardQueryService,
        private dealerDashboardAlertsService: ConnectedPortalDealerDashboardAlertsService,
        private route: ActivatedRoute,
        private router: Router,
        private numberSuffixPipe: NumberSuffixPipe,
        private decimalPipe: DecimalPipe,
        private toastService: ToastService,
        private errorService: ErrorService,
        private userService: ConnectedPortalUserService,
        private heapService: HeapService
    ) { }

    ngOnInit(): void {
        this.alerts$ = this.dealerDashboardAlertsService.init().pipe(map(((alerts) => alerts as ConnectedPortalAlert[])));

        if (!this.route.parent) {
            return;
        }

        const dealerIdParam$ = this.route.parent.paramMap.pipe(map((params) => {
            const dealerId = params?.get('id');

            if (dealerId === null) {
                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }

            return dealerId;
        }, catchError(() => {
            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

            return EMPTY;
        })), takeUntil(this.ngOnDestroy$));

        dealerIdParam$.subscribe((dealerId) => {
            if (!dealerId) {
                return;
            }

            this.dealerId = dealerId;
            this.breadCrumbData = this.getBreadcrumbData(this.dealerId);
            this.dealersService.queryDealerById(this.dealerId)
                .pipe(takeUntil(this.ngOnDestroy$)).subscribe((res) => {
                    const { name: dealerName } = res.data;
                    if (this.dealerId) {
                        this.breadCrumbData = this.getBreadcrumbData(this.dealerId, dealerName);
                    }
                });
            this.stats$ = this.getStats(this.dealerId);

            const { page, alertsSeverity } = this.queryParamsService.init({
                page: this.alertsCurrentPage,
                alertsSeverity: this.alertsSeverityFilter
            });

            this.alertsCurrentPage = page;
            this.alertsSeverityFilter = alertsSeverity;
            this.fetchData();
        });


        this.companyCode$ = this.userService.getCompanyCode() as Observable<CompanyCode>;
    }

    fetchData() {
        if (this.dealerId) {
            this.getUIAlerts(this.dealerId, this.alertsSeverityFilter, this.getNextPage(this.alertsCurrentPage)).subscribe((alerts) => {
                this.dealerDashboardAlertsService.updateAlerts(alerts);
            });
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    handleCloseModal() {
        this.isModalVisible = false;
    }

    handleShowRemoveSelectedModal() {
        this.isModalVisible = true;
    }

    handleAlertsFilterChange(alertsSeverity: AlertSeverityFilter) {
        this.dealerDashboardAlertsService.unselectAll();
        this.alertsSeverityFilter = alertsSeverity;
        this.alertsCurrentPage = INITIAL_PAGE;
        this.queryParamsService.updateParams({
            alertsSeverity,
            page: INITIAL_PAGE
        });
        this.fetchData();
    }

    handleDateRangeChange($event: DateRange) {
        if (!($event.startDate && $event.endDate)) {
            return;
        }

        if (this.dealerId) {
            this.alertsStartDate = $event.startDate;
            this.alertsEndDate = $event.endDate;
            this.alertsCurrentPage = INITIAL_PAGE;
            this.queryParamsService.updateParams({ page: INITIAL_PAGE });
            this.fetchData();
        }

        this.dealerDashboardAlertsService.unselectAll();
    }

    handleAlertsPageChange(nextPage: number) {
        this.dealerDashboardAlertsService.unselectAll();
        this.alertsCurrentPage = nextPage;
        this.queryParamsService.updateParams({
            page: nextPage,
            scroll: false
        });
        this.fetchData();
    }

    handleRemoveAlertsConfirmation() {
        if (this.dealerId) {
            this.heapService.track(eventNames.handleDashboardAlertDeleteClick);
            const alertIds = this.dealerDashboardAlertsService.getSelectedAlertIds();
            this.removeAlerts(alertIds, this.dealerId);
            this.isModalVisible = false;
        }
    }

    handleExportAlerts() {
        if (this.dealerId) {
            // We get all alerts, in case the user wants to export all or export selected alerts from pages no longer in memory.
            this.getExportAlerts(this.dealerId, this.alertsSeverityFilter).subscribe((allAlerts) => {
                const selectedAlertIds = this.dealerDashboardAlertsService.getSelectedAlertIds();

                if (selectedAlertIds.length) {
                    const selectedAlerts = allAlerts.filter((alert) => selectedAlertIds.includes(alert.id));

                    this.dealerDashboardAlertsService.exportAlerts(selectedAlerts, EXPORT_SELECTED_FILENAME_PREFIX);
                }
                else {
                    this.dealerDashboardAlertsService.exportAlerts(allAlerts, EXPORT_ALL_FILENAME_PREFIX);
                }
            });
        }
    }

    handleSearchSubmit(query: string | null) {
        const params = { search: query };

        this.router.navigate(['../customers'], {
            relativeTo: this.route,
            queryParams: params
        });
    }

    getBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }

    getStats(dealerId: string) {
        this.isLoadingList.stats = true;

        return this.dealersService
            .queryDealerStats(dealerId)
            .pipe(
                map((res) => {
                    const { data } = res;
                    this.isLoadingList.stats = false;

                    return Object.entries(data).map(([key, value]) => ({
                        label: `CONNECTED_PORTAL.STATS.PROPS.${key}`,
                        value: this.formatStatValues(key, value)
                    }), []);
                })
            );
    }

    formatStatValues(key: string, value: string | number) {
        switch (key) {
            case 'connectedControlPercent':
                return this.numberSuffixPipe.transform(value, CharSymbol.Percent);
            default:
                return this.decimalPipe.transform(value, '1.0-0') || '';
        }
    }

    getUIAlerts(dealerId: string, alertLevel: AlertSeverityFilter, currentPage: number, pageSize: number = this.alertsListPageSize) {
        this.isLoadingList.alerts = true;

        return this.getAlerts(dealerId, alertLevel, currentPage, pageSize)
            .pipe(
                map((value) => {
                    const { totalPages, totalCount, alerts } = value;

                    this.alertCount = totalCount;
                    this.alertsListPageTotal = totalPages;

                    return alerts;
                }),
                catchError((err: Error) => {
                    this.dealerDashboardAlertsService.updateAlerts([]);
                    this.isLoadingList.alerts = false;

                    throw err;
                })
            );
    }

    getExportAlerts(dealerId: string, alertLevel: AlertSeverityFilter) {
        this.isLoadingList.alerts = true;

        return this.getAlerts(dealerId, alertLevel, 1, this.alertCount).pipe(
            map((value) => {
                this.isLoadingList.alerts = false;

                return value.alerts;
            }),
            catchError((err: Error) => {
                this.isLoadingList.alerts = false;

                throw err;
            })
        );
    }

    getAlerts(dealerId: string, alertLevel: AlertSeverityFilter, currentPage: number = 1, pageSize: number = this.alertsListPageSize) {
        const queryParams: DateRangeQueryParams = {
            pagination: {
                pageSize: pageSize,
                currentPage: currentPage
            },
            sort: {
                field: 'dateTime',
                order: 'desc'
            },
            dateRange: this.getAlertsDateRange()
        };

        if (alertLevel !== 'All') {
            queryParams.filter = [
                {
                    field: 'error.severity',
                    value: alertLevel
                }
            ];
        }

        return this.alertsService
            .queryAlertsByDealerId(dealerId, queryParams)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map((value): { totalPages: number, totalCount: number, alerts: ConnectedPortalAlert[]} => {
                    this.isLoadingList.alerts = false;
                    const { totalPages, totalCount } = value;

                    return {
                        totalPages,
                        totalCount,
                        alerts: this.formatAlerts(value)
                    };
                })
            );
    }

    removeAlerts(alertIds: string[], dealerId: string) {
        this.alertsService
            .deleteAlertsByIds(alertIds)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                tap(() => {
                    const toast: AddToast = {
                        outletName: ToastOutlet.DealerAlertsToast,
                        content: this.translateService.instant('CONNECTED_PORTAL.ALERTS.TOASTS.DELETE_ALERTS_SUCCESS'),
                        autoClose: true,
                        theme: 'success'
                    };

                    this.toastService.add(toast);
                }),
                switchMap(() => this.getUIAlerts(
                    dealerId,
                    this.alertsSeverityFilter,
                    this.getNextPage(this.alertsCurrentPage)
                ))
            ).subscribe((value) => {
                this.dealerDashboardAlertsService.updateAlerts(value);
                this.dealerDashboardAlertsService.resetIdList();
            });
    }

    getNextPage(currentPage: number): number {
        if (this.alertsListPageTotal) {
            return currentPage > this.alertsListPageTotal ? this.alertsListPageTotal : currentPage;
        }

        return currentPage;
    }

    ngAfterViewInit(): void {
        this.userService.getHVPID$()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((primaryDealerId) => {
                if (primaryDealerId === this.dealerId) {
                    this.toastService.add({
                        bgColor: '#F8F8F8',
                        content: this.translateService.instant('CONNECTED_PORTAL.BETA_DEALER_TOAST'),
                        id: 'cp-beta-message',
                        closeable: true
                    });
                }
            });
    }

    private getAlertsDateRange() {
        return {
            start: new Date(this.alertsStartDate).toISOString(),
            end: new Date(this.alertsEndDate).toISOString()
        };
    }

    private formatAlerts(alertsData: DealerAlertResponse): ConnectedPortalAlert[] {
        const alerts = alertsData.data.map((entry): ConnectedPortalAlert => {
            const {
                id, dateTime: datetime, property, product, customer, error, wallControlSerialNo
                , dealerId
            } = entry;
            if (!property) {
                return null as unknown as ConnectedPortalAlert;
            }

            const { address } = property;
            const addressStreet = address.address2 ? `${address.address1}, ${address.address2}` : address.address1;

            const errorCode = error.code;

            return {
                id,
                datetime,
                customerName: `${customer.firstName} ${customer.lastName}`,
                propertyAddress: `${addressStreet}, ${address.city}, ${address.state} ${address.zipCode}`,
                alertLevel: error.severity,
                errorCode: errorCode,
                errorMessage: error.description,
                customerPhone: customer.phone1,
                troubleshootUri: error.troubleshootUri,
                modelNumber: product.modelNo,
                serialNumber: product.serialNo,
                systemType: product.systemType,
                selectedForRemoval: false,
                propertyId: property.id,
                type: product.type,
                dealerId: dealerId,
                wallControlSerialNo: wallControlSerialNo
            };
        });

        return alerts.filter(Boolean);
    }
}
