<section class="hvac-product-details-content-section" *ngIf="showProductData$ | async">
    <div>
        <div class="hvac-product-content-section-overview">
            <div class="hvac-product-content-section-overview-description">
                <h2 class="hvac-section-heading" [attr.id]="'PRODUCTS.PRODUCT_DATA' | translate" tabindex="'-1'">{{ 'PRODUCTS.PRODUCT_DATA' | translate }}</h2>
                <div class="hvac-product-data-container">
                    <div class="hvac-product-family-container">
                        <form class='hvac-product-family-search-form'>
                            <input
                                class="hvac-product-family-search-input"
                                id="hvac-product-family-search"
                                name="hvac-product-family-search"
                                type="text"
                                [placeholder]="'PRODUCTS.QUICK_SEARCH' | translate"
                                [attr.aria-label]="'PRODUCTS.QUICK_SEARCH' | translate"
                                [title]="'PRODUCTS.QUICK_SEARCH' | translate"
                                #productSearchInput
                                (keyup)="inputKeyup($event)"
                                autocomplete="off" />
                            <div class="hvac-input-action-wrap">
                                <button hvacButton="icon"
                                    [title]="'PRODUCTS.QUICK_SEARCH' | translate"
                                    (click)="onOverviewSearch()">
                                    <utc-svg src="assets/icons/search.svg"></utc-svg>
                                </button>
                            </div>
                        </form>
                        <div class="hvac-mobile-family-select-container">
                            <form [formGroup]="chooseFamilyProductForm">
                                <utc-select
                                    variant="primary"
                                    [group]="chooseFamilyProductForm"
                                    [control]="'selectFamilyProductControl'"
                                    [selectId]="'selectFamilyProduct'"
                                    [options]="productOptions$ | async">
                                </utc-select>

                                <button type="submit" class="hvac-invisible-submit" disabled>
                                    <span class="hvac-accessible-text">{{'SUBMIT' | translate }}</span>
                                </button>
                            </form>
                        </div>
                        <ul class="hvac-product-family-list">
                            <li *ngFor="let product of attributes$ | async | searchTextFilter: searchKey" class="hvac-product-family-list-item">
                                <utc-button
                                    buttonType="button"
                                    classNames="hvac-product-variant-button"
                                    label="{{ product.urn }}"
                                    [title]="product.urn"
                                    (click)="updateActiveProduct(product)"
                                    [ngClass]="{ 'hvac-button-active': activeSisterProduct ? activeSisterProduct[0].urn === product.urn : false}"></utc-button>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="activeSisterProduct" class="hvac-product-data-content">
                        <div *ngIf="activeSisterProduct.length === 1">
                            <div class="hvac-primary-data-group">
                                <div *ngFor="let attributeGroup of activeSisterProduct | slice: 0:1" class="hvac-product-data-attribute-group">
                                    <utc-product-attribute-group [attributeGroupTitle]="attributeGroup.attribute" [attributes]="attributeGroup.values"></utc-product-attribute-group>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="activeSisterProduct.length > 1">
                            <div class="hvac-multiple-data-group-container">
                                <div class="hvac-primary-data-group">
                                    <div *ngFor="let attributeGroup of activeSisterProduct | slice: 0:1" class="hvac-product-data-attribute-group">
                                        <utc-product-attribute-group [attributeGroupTitle]="attributeGroup.attribute" [attributes]="attributeGroup.values"></utc-product-attribute-group>
                                    </div>
                                </div>
                                <div class="hvac-secondary-data-group">
                                    <div *ngFor="let attributeGroup of activeSisterProduct | slice: 1" class="hvac-product-data-attribute-group">
                                        <utc-product-attribute-group [attributeGroupTitle]="attributeGroup.attribute" [attributes]="attributeGroup.values"></utc-product-attribute-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="hvac-section-rule">
</section>
