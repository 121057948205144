import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { GraphQLErrors } from '@apollo/client/errors';
import { Apollo } from 'apollo-angular';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { ToastService } from 'common/services/toast.service';
import { Observable, of } from 'rxjs';
import { environment } from 'common/environments/environment';
import { Mocks, ProductAttributes } from 'common/models';
import { ConnectedProduct, ProductType, SystemType } from 'private/app/models/connected-product.model';
import { nonEmptyStringOrNull } from './utils';
import { ECOBEE_PRODUCT_IMAGE_MAP } from 'private/app/views/connected-portal/constants';
import { TranslateService } from '@ngx-translate/core';
import { ConnectedPortalGraphQLError } from 'private/app/models/connected-portal-error.model';

type MockQueries = keyof Mocks['connectedPortal']['graphql']['queries'];

export enum MediaImageSize {
    Small = '300x300',
    Medium = '500x500'
}

export abstract class ConnectedPortalBaseService {
    protected apiOptions: ApiOptionsService;
    protected apollo: Apollo;
    protected toastService: ToastService;
    protected translateService: TranslateService;

    constructor(
        public injector: Injector
    ) {
        this.apiOptions = injector.get(ApiOptionsService);
        this.apollo = injector.get(Apollo);
        this.toastService = injector.get(ToastService);
        this.translateService = injector.get(TranslateService);
    }

    isNotFoundError(errors: GraphQLErrors) {
        return errors.filter((error) => error.message === 'No Data found').length > 0;
    }

    getEmptyCollectionResponse() {
        return { data: [] };
    }

    getEmptyCollectionResponsePaginated() {
        return {
            totalCount: 0,
            totalPages: 0,
            data: []
        };
    }

    public showErrorToast(err: HttpErrorResponse | ConnectedPortalGraphQLError) {
        this.toastService.add({
            bgColor: '#F8F8F8',
            content: err.message,
            theme: 'error',
            autoClose: true,
            id: 'cp-api-error'
        });
    }

    public getApolloServiceByQueryId(queryId: MockQueries) {
        const useMock = Boolean(environment.mocks?.connectedPortal.graphql.queries[queryId] || environment.mocks?.connectedPortal.graphql.queries['MOCK_ALL']);

        return this.apollo.use(useMock ? 'mock' : '') || this.apollo;
    }

    public removeEmptyProps(object: unknown) {
        // eslint-disable-next-line no-undefined
        const replacer = (_key: string, value: unknown) => (value === '' || value === null ? undefined : value);
        const jsonString = JSON.stringify(object, replacer);

        return JSON.parse(jsonString);
    }

    public getProductCatalogData(productData: ConnectedProduct, productAttributes: ProductAttributes | null, mediaImageSize: MediaImageSize, brand?: string): Observable<ConnectedProduct> {
        const systemType = productData.systemType;

        let name = nonEmptyStringOrNull(productAttributes?.name) || productData.name;

        let image = productAttributes?.media?.[mediaImageSize]?.[0]?.url;

        if (systemType === SystemType.ECOBEE) {
            image = ECOBEE_PRODUCT_IMAGE_MAP[productData.modelNo] || image;

            if (productData.type === ProductType.WallControl) {
                name = nonEmptyStringOrNull(productAttributes?.name) || this.ecobeeBackUpProductName(productData.modelNo, productData.name);
            }
        }
        if (productData.modelNo === 'SYST0101CW') {
            image = `${environment.siteUrls.default}/assets/images/SYST0101CW_1_500x500.jpg`;
        }

        if (systemType === SystemType.CARRIER_ELT && productData.type !== ProductType.WallControl) {
            if (!nonEmptyStringOrNull(productData.modelNo)) {
                switch (brand?.toLowerCase()) {
                    case 'carrier':
                        image = productData.type === ProductType.IndoorUnit ?
                            `${environment.siteUrls.default}/assets/images/carrier_idu_placeholder.png` :
                            `${environment.siteUrls.default}/assets/images/carrier_odu_placeholder.png`;
                        break;
                    case 'bryant':
                        image = productData.type === ProductType.IndoorUnit ?
                            `${environment.siteUrls.default}/assets/images/bryant_idu_placeholder.png` :
                            `${environment.siteUrls.default}/assets/images/bryant_odu_placeholder.png`;
                        break;
                    case 'default':
                        image = productData.type === ProductType.IndoorUnit ?
                            `${environment.siteUrls.default}/assets/images/agnostisc_idu_placeholder.png` :
                            `${environment.siteUrls.default}/assets/images/agnostic_odu_placeholder.png`;
                        break;
                    default:
                        break;
                }
            }
        }

        return of(
            {
                ...productData,
                image,
                name
            }
        );
    }

    /* formatting is added to manage version issue of backend gql */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    public formatApolloError(error: any): ConnectedPortalGraphQLError {
        if (error.extensions) {
            return error;
        }

        /* add more properties, if missing from old gql version */
        const extensionInfo = {
            ...(error.errorType) && { errorType: error.errorType },
            ...(error.code) && { code: error.code }
        };

        return ({
            ...error,
            extensions: extensionInfo
        });
    }

    private ecobeeBackUpProductName(modelNumber: string, productName: string) {
        const translationKey = `CONNECTED_PORTAL.ECOBEE_DEFAULT_PRODUCT_NAME.${modelNumber}`;
        const backUpName = this.translateService.instant(translationKey);

        return backUpName === translationKey ? productName : backUpName;
    }
}
