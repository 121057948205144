import { RelationshipTypesUpdated } from './../../../models/distributor-relationships.model';
import { Component, ElementRef, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IdmService } from 'private/app/services/idm.service';
import { Company, Contact, User } from 'private/app/models/account-admin-search';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompanyAssociations, CompanyAssociationsResult } from 'private/app/models/company.model';
import { JumpBarLink, JumpbarService } from 'common/content/services/jump-bar.service';
import { takeUntil } from 'rxjs/operators';
import { DistributorService } from 'private/app/services/distributor-relationships.service';
import { AutoCompleteTypes, FilteredBrands } from 'private/app/models/distributor-relationships.model';
import { ToastService } from 'common/services/toast.service';
import { CompanyQueryService } from 'private/app/services/account-admin/company-query.service';
import { Brand } from 'private/app/services/user-validation/user-validation.service';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { PricingBrandWarrantyRelationship, RelationshipTabOption } from 'private/app/models/account-admin-company.model';
import { CompanyRelationshipService } from 'private/app/services/account-admin/company-relationship.service';
type InActiveActiveTabs = 'active' | 'inactive';

@Component({
    selector: 'app-association-base',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class AssociationBaseComponent implements OnDestroy {
    @Input() company: Company;
    @Input() onCompanyChange = new BehaviorSubject<Company>({} as Company);
    @Input() addContact: boolean;
    @Input() adminAccess: boolean;
    @Input() contactDetails$ = new BehaviorSubject<Contact[]>([] as Contact[]);
    @Input() isSameFamily: Boolean;

    loading:boolean;
    pagination: number = 1;
    totalCount: number;
    totalPages: number;
    disabled : boolean = false;
    displayCountStart: number;
    displayCountEnd: number;
    singlePageCount: number;
    pageSize = 5;
    public brandAddress?: string;
    public companyName?: string;
    public brandName?: string;
    public companyId?: string | number;
    public addressLine1: string;
    public city?: string;
    public postalCode?: string;
    public regionCode?: string;
    public urlChanged = new Subject();
    public selectedTab: InActiveActiveTabs = 'active';
    public currentDate: string;
    public show: typeof AutoCompleteTypes = AutoCompleteTypes;
    public brandFormData: FilteredBrands | null = null;
    public distributorListResults: PricingBrandWarrantyRelationship[];
    public isNotSuccessfullySubmitted: boolean = false;
    public isRelationshipExists: boolean = false;
    public tableData$ = new BehaviorSubject<PricingBrandWarrantyRelationship[]>([]);
    public selectedRelationshipTabOptions: typeof RelationshipTabOption = RelationshipTabOption
    public relationshipId?: number;
    public companyRelationShipId?: number = 0;
    public brandId?: string | number;
    public allBrands: Brand[];
    public relationshipTypes$ = new BehaviorSubject<RelationshipTypesUpdated[]>([] as RelationshipTypesUpdated[]);
    public tierLevelCode?: number;
    public isSameHierarchy?: string = '';
    public createdDate?: string;
    public validToDate?: string;
    public status?: number;
    public hvp?: string = '';
    public relationshipType: string;
    public isSuccessfullySubmitted: boolean;
    public tierLevelName?: string;


    ngOnDestroy$ = new Subject();
    public dealerDetailsResult$ = new BehaviorSubject<CompanyAssociationsResult>({
        data: [],
        totalCount: 0
    });

    public distributerDetailsResult$ = new BehaviorSubject<CompanyAssociationsResult>({
        data: [],
        totalCount: 0
    });

    public selectedRelationShipTab: RelationshipTabOption = RelationshipTabOption.BRAND;

    jumpBarData: JumpBarLink;
    public deactivateRelationshipModal: boolean = false;
    public deactivateLastRelationshipModal: boolean = false;

    constructor(
        protected toastService: ToastService,
        protected translate: TranslateService,
        protected idmService: IdmService,
        protected adminService: AccountAdminService,
        protected adminApiService: AccountAdminApiService,
        protected location: Location,
        protected route: ActivatedRoute,
        protected router: Router,
        protected jumpBarService: JumpbarService,
        protected distributorService: DistributorService,
        protected readonly element: ElementRef,
        public companyQuery: CompanyQueryService,
        public companyRelationshipService: CompanyRelationshipService
    ) {
        this.location.onUrlChange((url, state) => {
            this.urlChanged.next({
                url,
                state
            });
        });
    }

    public createDetails(company: CompanyAssociations | User): string {
        return [
            company.address.addressLine1,
            company.address.city,
            company.address.regionCode
        ].filter(Boolean).join(', ');
    }

    public navigateToPage(pageNo: string | null, companyType: string) {
        if (pageNo) {
            this.pagination = parseInt(pageNo, 10);
        }
        else {
            this.pagination = 1;
        }
        this.getAssociationsResults(companyType, this.pagination);
    }

    public onPageChange(event: number, queryParamsData: Params) {
        const url = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: queryParamsData,
            queryParamsHandling: 'merge'
        }).toString();
        this.location.go(url);
        this.pagination = event;
    }

    public getCurrentDateAndTime() {
        this.currentDate = new Date().toISOString();
    }

    handleCloseModal() {
        this.adminService.isModalVisible = false;
        this.distributorService.showManageRelationshipModal = false;
        this.isNotSuccessfullySubmitted = false;
        this.isRelationshipExists = false;
        this.deactivateRelationshipModal = false;
        this.companyRelationShipId = 0;
        this.clearBrandedTabs();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public initializePagination() {
        this.pagination = 1;
        this.totalCount = this.distributorListResults.length;
        this.totalPages = Math.ceil(this.distributorListResults.length / this.pageSize);
        this.handlePageChange(this.pagination);
    }

    public handlePageChange(page: number) {
        this.pagination = page;
        const startingIndex = (this.pagination - 1) * this.pageSize;
        this.tableData$.next(this.distributorListResults.slice(startingIndex, this.pagination * this.pageSize));
        this.displayCountEnd = this.pagination === 1 ? this.distributorListResults.slice(startingIndex, 5 * this.pagination).length : ((this.pageSize * this.pagination) - (this.pageSize - this.distributorListResults.slice(startingIndex, 5 * this.pagination).length));
        this.singlePageCount = this.pagination === 1 && this.totalCount > 1 ? this.totalCount : this.pagination;
        this.displayCountStart = this.pagination === 1 ? this.pagination : (this.pagination * this.pageSize) - 4;
    }

    public clearBrandedTabs() {
        this.brandAddress = '';
        this.companyName = '';
        this.companyName = '';
        this.companyId = '';
        this.city = '';
        this.regionCode = '';
        this.postalCode = '';
    }

    private getAssociationsResults(type: string, pagination: number = 1) {
        this.loading = true;
        this.idmService.getAssociations(this.adminService.createAssociatedDistributerSearchBody(this.company, pagination, null, type)).pipe(takeUntil(this.ngOnDestroy$)).subscribe((response: CompanyAssociationsResult) => {
            this.loading = false;
            if (type === 'DCR') {
                this.dealerDetailsResult$.next(response);
            }
            else {
                this.distributerDetailsResult$.next(response);
            }
        });
    }
}

