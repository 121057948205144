import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { WallControlEnergyEventResponse } from 'private/app/models/energy-events.model';

export const QUERY_EVENTS_BY_SERIAL_NUMBER = gql`
    query queryScheduledVppEventsBySerialNo($serialNumber: String!, $dealerId: String!, $params: DefaultQueryParams) {
      queryScheduledVppEventsBySerialNo(serialNumber: $serialNumber, dealerId: $dealerId, params: $params) {
        data {
            startTime,
            endTime,
            description 
        }
        totalCount,
        totalPages
      }
    }
`;

@Injectable({ providedIn: 'root' })

export class EnergyEventsService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryScheduledVppEventsBySerialNo(serialNumber: string, dealerId: string, queryParams?: DefaultQueryParams): Observable<WallControlEnergyEventResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryScheduledVppEventsBySerialNo').query<{ queryScheduledVppEventsBySerialNo: WallControlEnergyEventResponse }>({
                query: QUERY_EVENTS_BY_SERIAL_NUMBER,
                variables: {
                    serialNumber,
                    dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryScheduledVppEventsBySerialNo } = value.data;

                    return queryScheduledVppEventsBySerialNo;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
