<div class="hvac-video-widget">
    <ul class="hvac-video-list">
        <li *ngFor="let video of videos">
            <a [href]="video.Content.links.linkExternal" rel="noopener noreferrer" target="_blank" class="hvac-video-link">
                <img [src]="video.Content.primaryMedia.BinaryContent.Url" [alt]="video.Content.primaryMedia.ExternalContent.Metadata.Alt" class="hvac-video-thumbnail">
                <div class="hvac-video-details">
                    <span class="hvac-video-play-symbol">
                        <utc-svg src="assets/icons/play.svg" class="hvac-play-icon"></utc-svg>
                    </span>
                    <p class="hvac-video-title" [innerHTML]="video.Content.title | richText"></p>
                </div>
            </a>
        </li>
    </ul>
</div>
