<div class="hvac-cp-generic-event-logs-container">
    <div class="hvac-cp-generic-event-header">
        <div class="hvac-cp-generic-event-header-title hvac-h5">
            {{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ALERTS.TITLE' | translate }}
        </div>
        <div class="hvac-cp-generic-event-header-action">
            <button
                hvacButton="primary"
                (click)="wallControlEventLogCurrentPage$.next(1)"
                size="small">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ALERTS.ACTIONS.REFRESH' | translate }}
            </button>
        </div>
    </div>
    <hvac-wall-control-event-log
        *ngIf="(wallControlEventLogData$ | async) as eventLogData"
        [config]="eventLogData.config"
        [data]="eventLogData.items"
        [totalPages]="eventLogData.totalPages"
        [currentPage]="eventLogData.currentPage"
        [enableFilter]="false"
        (onPageChange)="wallControlEventLogCurrentPage$.next($event)"
        [isResponsive]="true"
        >
    </hvac-wall-control-event-log>
    <div class="hvac-cp-diag-event-loader" *ngIf="isLoadingAlerts$ | async">
        <utc-loading-spinner variant="overlay">
            <ul class="hvac-loading-message-container">
                <li class="hvac-loading-message hvac-label-sm">{{ 'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.ALERTS.LOADING_TEXT' | translate}}</li>
            </ul>
        </utc-loading-spinner>
    </div>
</div>