import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppConstants } from 'common/app-constants';
import { PoToastTypes, PurchaseOrderItem, TABLE_COLUMN_HEADERS } from 'private/app/models/purchase-order-details.model';
import { filter, takeUntil } from 'rxjs/operators';
import { PurchaseOrderService } from 'private/app/services/purchase-orders.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'common/services/toast.service';
import { Subject } from 'rxjs';
import { YearMoDatePipe } from 'private/app/pipes/year-mo-date.pipe';

@Component({
    selector: 'hvac-acknowledgement',
    templateUrl: './acknowledgement.component.html',
    styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit, OnDestroy {
    readonly AppConstants = AppConstants
    readonly TableColumnHeaders = TABLE_COLUMN_HEADERS;
    public supplierSoNumber: UntypedFormControl = new FormControl('')
    public purchaseOrderItemsForm: FormGroup<{
        [key: string]: FormControl<string | null>
    }> = new FormGroup({})

    public todaysDate: string = new Date().toISOString().substring(0, 10);
    public isSubmitInProgress: boolean;
    public acknowledgedVerified: FormControl= new FormControl(false)
    public showSupplierSalesNum: FormControl= new FormControl(false)
    private purchaseOrderNumber?: string;
    private purchaseOrderLineItems: PurchaseOrderItem[] = [];
    private ngOnDestroy$ = new Subject();

    constructor(
        private datePipe: DatePipe,
        public purchaseOrderService: PurchaseOrderService,
        private readonly router: Router,
        private translate: TranslateService,
        private toastService: ToastService,
        private yearMoDatePipe: YearMoDatePipe
    ) {}

    ngOnInit() {
        this.purchaseOrderService.activePurchaseOrder$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((poItem) => Boolean(poItem) && Object.keys(poItem).length > 0)
        ).subscribe((poItem) => {
            this.purchaseOrderLineItems = poItem.lines;
            poItem.lines.forEach((poLineItem) => {
                this.purchaseOrderNumber = poItem.pONumber;
                const validDateFormat = poLineItem.requestedShipDate.trim() ? this.yearMoDatePipe.transform(poLineItem.requestedShipDate) : this.todaysDate;
                const formattedDate = this.datePipe.transform(validDateFormat, AppConstants.dateFormatMed);
                this.purchaseOrderItemsForm.addControl(poLineItem.pOLineNumber, new FormControl<string | null>(formattedDate, Validators.required));
            });
        });
    }

    public onSubmit() {
        this.toastService.removeAll();
        this.isSubmitInProgress = true;
        const data = this.purchaseOrderLineItems.map((lineItem: PurchaseOrderItem) => {
            const formattedDate = this.purchaseOrderItemsForm.get(lineItem.pOLineNumber)?.value.replace(/-/g, '');
            const controlValue = {
                pOLineNumber: lineItem.pOLineNumber,
                ackDate: formattedDate || ''
            };

            return controlValue;
        });
        this.purchaseOrderService.createAcknowledgement(this.supplierSoNumber.value, data).pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(
            (success) => {
                if (success) {
                    this.isSubmitInProgress = false;
                    this.router.navigate(['./trade-partners'], { queryParams: { [PoToastTypes.ACKN]: this.purchaseOrderNumber } });
                }
            }, () => {
                this.isSubmitInProgress = false;
                this.toastService.add({
                    bgColor: '#F8F8F8',
                    closeable: true,
                    content: this.translate.instant('TRADE_PARTNERS.API_ERROR'),
                    id: 'trade-partners-api-error',
                    theme: 'error'
                });
            }
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
