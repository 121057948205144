/* eslint-disable camelcase */
export interface ApiLink {
    href: string;
    method?: string;
}

export interface IdentityProvider {
    type: string;
    id: string;
}

export interface UserProfile {
    firstName: string;
    lastName: string;
    mobilePhone: string|null;
    secondEmail: string|null;
    login: string;
    email: string;
}

export interface User {
    id: string;
    status: string;
    created: string;
    activated: null;
    statusChanged: string;
    lastLogin: string;
    lastUpdated: string;
    passwordChanged: string;
    profile: UserProfile;
    credentials: {
        password: object;
        emails: {
            value: string;
            status: string;
            type: string;
        }[];
        recovery_question: {
            question: string;
        };
        provider: IdentityProvider;
    };
    _links: {
        suspend: ApiLink;
        resetPassword: ApiLink;
        forgotPassword: ApiLink;
        expirePassword: ApiLink;
        changeRecoveryQuestion: ApiLink;
        self: ApiLink;
        changePassword: ApiLink;
        deactivate: ApiLink;
    };
}

export interface TokenMeta {
    authorizeUrl: string;
    claims: {
        groups: string[],
        idm_user_roles?: string[],
        idm_user_companies: {
            id: string;
            companyCategory: {
                code: string;
                name: string;
            }
        }[],
        preferred_username?: string;
        name?: string;
    };
    clientId: string;
    expiresAt: number;
    issuer: string;
    scopes: string[];
    status: string;
}

export interface AccessToken extends TokenMeta {
    accessToken: string;
}

export interface IdToken extends TokenMeta {
    idToken: string;
}

export interface Tokens {
    tokens: {
        idToken?: IdToken;
        accessToken?: AccessToken;
    };
}

export interface Session {
    createdAt: string;
    expiresAt: string;
    id: string;
    idp: IdentityProvider;
    lastFactorVerification: null|string;
    lastPasswordVerification: null|string;
    login: string;
    mfaActive: boolean;
    refresh: () => Promise<Session>;
    status: string;
    user: () => Promise<User>;
    userId: string;
}

export interface Redirect {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any|null;
}
