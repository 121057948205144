<section class="hvac-highlights-section" [ngClass]="{ 'hvac-highlights-section-full-width': fullWidth }">
    <div class="hvac-highlights-heading">
        <utc-svg class="hvac-highlights-heading-icon" [src]="highlightIcon"></utc-svg>
        <h3 class="hvac-highlights-heading-text">
            <span class="hvac-highlights-heading-title" [innerHTML]="data?.Content?.title | richText"></span>
            <span class="hvac-highlights-heading-subtitle">
                <utc-svg class="hvac-highlights-heading-sparkle" src="assets/icons/sparkle.svg"></utc-svg>
                <span class="hvac-highlights-heading-subtitle-text" [innerHTML]="data?.Content?.subtitle | richText"></span>
            </span>
        </h3>
    </div>
    <div class="hvac-highlights-content">
        <div class="hvac-highlights-highlight-lists">
            <ul class="hvac-highlights-list hvac-highlights-list-media">
                <li class="hvac-highlights-list-item" *ngFor="let item of mediaHighlights">
                    <hvac-media-highlight [data]="item"></hvac-media-highlight>
                </li>
            </ul>

            <ul class="hvac-highlights-list hvac-highlights-list-buttons">
                <li class="hvac-highlights-list-item" *ngFor="let item of linkOnlyHighlights">
                    <a *ngIf="item?.Content?.links.linkOperation.Key === '_blank'; else internalButtonLink"
                        [hvacButton]="'primary'"
                        class="hvac-highlights-list-item-button-link"
                        [href]="item?.Content?.links.linkExternal | decodeUriComponent"
                        rel="noopener noreferrer"
                        target="_blank"
                        [innerHTML]="item?.Content?.title | richText">
                    </a>

                    <ng-template #internalButtonLink>
                        <a class="hvac-highlights-list-item-button-link"
                            [hvacButton]="'primary'"
                            [routerLink]="[item?.Content?.links.linkExternal | decodeUriComponent]"
                            [innerHTML]="item?.Content?.title | richText">
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="serviceBulletins" class="hvac-highlights-service-bulletins">
        <hvac-bulletins [bulletins]="serviceBulletins"></hvac-bulletins>
    </div>
</section>
