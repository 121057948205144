import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { SEARCH_RESULTS_PAGE_SIZE } from '../../constants';
import { BasicCSV, CSVExportService } from 'common/services/csv-export.service';
import { DistributorsService } from 'private/app/services/connected-portal/distributors.service';
import { Distributor } from 'private/app/models/distributor.model';

const EXPORT_FILENAME_PREFIX = 'connected-distributors';
export interface ConnectedPortalDistributor {
    id: string;
    name: string;
    dealerCount: string;
    connectedControlCount: string;
    customerCount: string;
}

@Component({
    selector: 'hvac-factory-distributors',
    templateUrl: './factory-distributors.component.html',
    styleUrls: ['./factory-distributors.component.scss'],
    providers: [QueryParamsService]
})
export class FactoryDistributorsComponent extends BaseComponent implements OnInit {
    distributors: Distributor[] = [];
    totalPages = 0;
    currentPage = 1;
    pageSize = SEARCH_RESULTS_PAGE_SIZE;
    isLoading: boolean;
    queryControl = new UntypedFormControl('', Validators.required);
    searchTerm?: string | null;
    itemCount: number = 0;

    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DISTRIBUTORS') },
        breadCrumbs: [
            {
                title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                url: '/connected-portal/dashboard'
            }
        ]
    };

    constructor(
        private queryParamsService: QueryParamsService,
        private distributorsService: DistributorsService,
        private translateService: TranslateService,
        private csvExportService: CSVExportService
    ) {
        super();
    }

    ngOnInit(): void {
        this.queryParamsService.init({ page: this.currentPage })
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((value) => {
                this.isLoading = true;

                const { page, search } = value;
                const nextPage = this.getNextPage(page);

                const queryParams: SearchQueryParams = {
                    pagination: {
                        pageSize: this.pageSize,
                        currentPage: nextPage
                    }
                };

                if (search) {
                    queryParams.searchTerm = search;
                    this.queryControl.setValue(search);
                    this.searchTerm = search;
                }

                this.distributorsService.queryDistributors(queryParams)
                    .pipe(
                        takeUntil(this.ngOnDestroy$),
                        catchError(() => {
                            this.isLoading = false;

                            return this.distributors = [];
                        })
                    ).subscribe((res) => {
                        this.isLoading = false;
                        this.totalPages = res.totalPages;
                        this.itemCount = res.totalCount;
                        this.distributors = res.data;
                    });

                this.currentPage = nextPage;
            });
    }

    handleSearchSubmit(query: string | null) {
        const params = {
            page: 1,
            search: query
        };

        this.queryParamsService.updateParams(params);
    }

    handleSearchReset() {
        const params = {
            page: 1,
            search: null
        };

        this.queryParamsService.updateParams(params);
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    handleExportList() {
        this.isLoading = true;
        const queryParams: SearchQueryParams = {
            pagination: {
                currentPage: 1,
                pageSize: this.itemCount
            }
        };

        if (this.searchTerm) {
            queryParams.searchTerm = this.searchTerm;
        }

        this.distributorsService.queryDistributors(queryParams)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                catchError(() => {
                    this.isLoading = false;

                    return this.distributors = [];
                })
            ).subscribe((res) => {
                const { data } = res;
                const formattedData = data.reduce((acc, curItem, index) => {
                    const { id, name, connectedDealers, connectedControlCount, connectedCustomerCount } = curItem;

                    const rowData = {
                        id,
                        name,
                        connectedDealers,
                        connectedControlCount,
                        connectedCustomerCount
                    };

                    if (index === 0) {
                        acc.headings = Object.keys(rowData)
                            .map((key) => this.translateService.instant(`CONNECTED_PORTAL.FACTORY_DISTRIBUTORS.EXPORT_HEADINGS.${key}`));
                    }

                    acc.body.push(Object.values(rowData));

                    return acc;
                }, {
                    headings: [],
                    body: []
                } as BasicCSV);

                const csvData = this.csvExportService.toCSV(formattedData);
                this.csvExportService.saveAs(csvData, EXPORT_FILENAME_PREFIX);
                this.isLoading = false;
            });
    }
}
