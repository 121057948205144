import { Injectable, Injector } from '@angular/core';
import { ConnectedPortalBaseService } from './connected-portal-base.service';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DisabledDataSharingReportResponse, UnregisteredControlsReportResponse, DefaultDealershipActivityResponse, VppEligibilityReportResponse } from 'private/app/models/opportunity-report.model';
import { Option } from 'common/components/select-new/select-new.component';

export const QUERY_DISABLED_DATA_SHARING_BY_DEALER_ID_META = gql`
    query QueryDisabledDataSharingByDealerId($id: String!) {
        queryDisabledDataSharingByDealerId(id: $id) {
            totalCount
        }
    }
`;

export const QUERY_DISABLED_DATA_SHARING_BY_DEALER_ID = gql`
    query QueryDisabledDataSharingByDealerId($id: String!) {
        queryDisabledDataSharingByDealerId(id: $id) {
            totalCount
            data {
                filepath
            }
        }
    }
`;

export const QUERY_UNREGISTERED_CONTROLS_BY_DEALER_ID_META = gql`
    query QueryUnregisteredControlsByDealerId($id: String!) {
        queryUnregisteredControlsByDealerId(id: $id) {
            totalCount
        }
    }
`;

export const QUERY_UNREGISTERED_CONTROLS_BY_DEALER_ID = gql`
    query QueryUnregisteredControlsByDealerId($id: String!) {
        queryUnregisteredControlsByDealerId(id: $id) {
            totalCount
            data {
                filepath
            }
        }
    }
`;

export const QUERY_DEALERSHIP_ACTIVITY_REPORT_BY_DEALERID = gql`
    query QueryDealershipActivityReportByDealerId($id: String!, $year: Float!) {
        queryDealershipActivityReportByDealerId(id: $id, year: $year) {
            data {
                filepath
            }
        }
    }
`;

export const QUERY_VPP_ELIGIBILITY_REPORT_BY_DEALERID = gql`
    query QueryVppEligibilityByDealerID($id: String!) {
        queryVppEligibilityByDealerID(id: $id) {
            totalCount
            data {
                filepath
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class OpportunityReportsService extends ConnectedPortalBaseService {
    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    public queryDisabledDataSharingByDealerId(id: string, metaOnly: boolean = false): Observable<DisabledDataSharingReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const query = metaOnly ? QUERY_DISABLED_DATA_SHARING_BY_DEALER_ID_META : QUERY_DISABLED_DATA_SHARING_BY_DEALER_ID;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDisabledDataSharingByDealerId').query<{ queryDisabledDataSharingByDealerId: DisabledDataSharingReportResponse }>({
                query,
                variables: { id },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDisabledDataSharingByDealerId } = value.data;

                    return queryDisabledDataSharingByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryUnregisteredControlsByDealerId(id: string, metaOnly: boolean = false): Observable<UnregisteredControlsReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const query = metaOnly ? QUERY_UNREGISTERED_CONTROLS_BY_DEALER_ID_META : QUERY_UNREGISTERED_CONTROLS_BY_DEALER_ID;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryUnregisteredControlsByDealerId').query<{ queryUnregisteredControlsByDealerId: UnregisteredControlsReportResponse }>({
                query,
                variables: { id },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryUnregisteredControlsByDealerId } = value.data;

                    return queryUnregisteredControlsByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryDealershipActivityReportByDealerId(id: string, year: number): Observable<DefaultDealershipActivityResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryDealershipActivityReportByDealerId').query<{ queryDealershipActivityReportByDealerId: DefaultDealershipActivityResponse }>({
                query: QUERY_DEALERSHIP_ACTIVITY_REPORT_BY_DEALERID,
                variables: {
                    id,
                    year
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryDealershipActivityReportByDealerId } = value.data;

                    return queryDealershipActivityReportByDealerId;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);
                throw err;
            })
        );
    }

    public queryVppEligibilityByDealerID(dealerId: string): Observable<VppEligibilityReportResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryVppEligibilityByDealerID').query<{ queryVppEligibilityByDealerID: VppEligibilityReportResponse }>({
                query: QUERY_VPP_ELIGIBILITY_REPORT_BY_DEALERID,
                variables: { id: dealerId },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryVppEligibilityByDealerID } = value.data;

                    return queryVppEligibilityByDealerID;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);
                throw err;
            })
        );
    }

    public loginOpportunityYearOptions(): Option[] {
        const currentYear = new Date().getFullYear();
        const startYear = 2023;

        return this.getYearRange(startYear, currentYear).map((year) => ({
            name: year.toString(),
            value: year.toString()
        }));
    }

    public getYearRange(minYear: number, maxYear: number): number[] {
        return [...Array(maxYear - (minYear - 1)).keys()].map((index) => minYear + index);
    }
}
