<div class="hvac-recent-activity-list">
    <div class="hvac-recent-activity-section" *ngFor="let item of results">
        <div class="hvac-recent-activity-group">{{item.timeStamp || '' | DateStatus: today}}</div>
        <ul class="hvac-recent-activity-item" *ngFor="let value of item.values">
            <li class="hvac-recent-activity-title">
                <a
                    [routerLink]="value.routerLink?.url"
                    [queryParams]="value.routerLink?.params"
                    class="hvac-recent-activity-title-text"
                    hvacLink="primary"
                    (click)="closeSlide()">
                    <span>
                        {{ value.pageTitle }}
                    </span>
                </a>
                <div class='hvac-content-breadcrumb-container'>
                    <span class='hvac-content-crumb-container' *ngFor="let crumb of value.breadcrumb"> <a
                        [routerLink]="[crumb.url]" class='hvac-subtext'>{{crumb.title}}</a><span
                        class='hvac-breadcrumb-divider'>/</span></span>
                </div>
            </li>
        </ul>
    </div>
    <div class="hvac-activity-no-data" *ngIf="!results.length">
        You have no recent history
    </div>
</div>
