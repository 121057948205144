<div class="hvac-dealer-info">
    <h2 class="hvac-cp-section-header">
        {{ dealerInfo.name }}
    </h2>

    <div class="hvac-row">
        <div class="hvac-info-group hvac-col-xs-12 hvac-col-md-4 hvac-col-lg-3">
            <h4 class="hvac-info-title">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.COMPANY_ADDRESS' | translate }}</h4>
            <address class="hvac-info-value hvac-info-address"> {{ dealerInfo.address?.street }}<br /> {{
                dealerInfo.address?.city }}, {{ dealerInfo.address?.state }} {{ dealerInfo.address?.zipCode }}<br /> {{
                dealerInfo.address?.country }} </address>
        </div>
        <div class="hvac-info-group hvac-col-xs-12 hvac-col-md-4 hvac-col-lg-3">
            <h4 class="hvac-info-title">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.COMPANY_ID' | translate }}</h4>
            <div class="hvac-info-value">{{ dealerInfo.id }}</div>
        </div>
        <div class="hvac-info-group hvac-col-xs-12 hvac-col-md-4 hvac-col-lg-3">
            <h4 class="hvac-info-title">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.ECOBEE_PIN' | translate }}</h4>
            <div class="hvac-info-value">
                <span *ngIf="ecobeePin; else requestEcobeePin">{{ ecobeePin }}</span>
                <ng-template #requestEcobeePin>
                    <button
                        hvacButton="primary"
                        class="hvac-request-ecobee-pin-button"
                        size="small"
                        [disabled]="isEcobeePinLoading || isEcobeePinRequestDisabled"
                        (click)="handleRequestEcobeePinClick()">
                            <span *ngIf="!isEcobeePinLoading; else generatingEcobeePin">{{'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.REQUEST_ECOBEE_PIN' | translate}}</span>
                            <ng-template #generatingEcobeePin>
                                <span>{{'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.GENERATING_ECOBEE_PIN' | translate}}</span>
                                <div class="hvac-button-spinner"></div>
                            </ng-template>
                        </button>
                </ng-template>
            </div>
        </div>
        <div class="hvac-info-group hvac-col-xs-12 hvac-col-lg-3">
            <h4 class="hvac-info-title">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.CONTACT_INFO' | translate }}</h4>
            <dl class="hvac-info-list">
                <div class="hvac-info-list-item">
                    <dt class="hvac-info-label">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.EMAIL' | translate }}:</dt> <dd class="hvac-info-value"><a class="hvac-text-link" [attr.href]="'mailto:' + dealerInfo.email">{{ dealerInfo.email }}</a></dd>
                </div>

                <div class="hvac-info-list-item">
                    <dt class="hvac-info-label">{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.INFO.PHONE' | translate }}:</dt> <dd class="hvac-info-value"><a class="hvac-text-link" [attr.href]="'tel:' + dealerInfo.phone">{{ dealerInfo.phone }}</a></dd>
                </div>
            </dl>
        </div>
    </div>
</div>
