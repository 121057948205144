import { Pipe, PipeTransform } from '@angular/core';
import { Status } from 'common/components/data-block/data-block.component';
import { DataSharingValue } from 'private/app/models/connected-system.model';

// Convert a DataSharing value to a Data Block Status value

@Pipe({ name: 'dataSharingToStatus' })
export class DataSharingToStatusPipe implements PipeTransform {
    transform(value: DataSharingValue): Status {
        return value === 'true' ? 'success' : 'inactive';
    }
}
