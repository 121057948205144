<div class="hvac-account-admin-heading hvac-account-admin-heading-default">
    <h1 class="hvac-h4">{{ 'ACCOUNT_ADMIN.CONTACT_INFORMATION' | translate}}</h1>
    <a hvacButton="default" size="small" class="hvac-back-button" [routerLink]="['/Admin/Admin-Tools/account-admin.html/results/contacts', {
        queryParams: this.accountAdminService.lastQueryParams.value
        }]" queryParamsHandling="merge" class="hvac-text-link hvac-user-details-back-to-result">
        <utc-svg class='hvac-back-arrow' src="assets/icons/arrow.svg"></utc-svg>
        <div class="hvac-back-text">{{ 'ACCOUNT_ADMIN.BACK_TO_SEARCH_RESULTS' | translate}}</div>
    </a>
</div>

<div class="hvac-row">
    <div class="hvac-col-xs-12 hvac-col-lg-5 hvac-account-admin-details-col">
        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.NAME' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.firstName}} {{contact?.lastName}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.TITLE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.title || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.STATUS' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value" *ngIf="contact">{{contact.status === 'ACTIVE' ? ('ACCOUNT_ADMIN.ACTIVE' | translate) : ('ACCOUNT_ADMIN.INACTIVE' | translate)}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.addressLine1 || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.addressLine2 || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CITY' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.city}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.STATE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.regionCode || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ZIP_CODE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.postalCode || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.COUNTRY' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.address?.countryCode|| '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.PHONE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.phoneNumber || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.FAX' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.faxNumber || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.CELL' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.mobilePhoneNumber || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.EMAIL' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.emailAddress || '--'}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.ACCOUNT_TYPE' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{contact?.contactOnly ? ('ACCOUNT_ADMIN.CONTACT' | translate) : ('ACCOUNT_ADMIN.USER' | translate)}}</div>
            </li>
            <li class="hvac-row hvac-top-xs">
                <div class="hvac-col-xs-6 hvac-label">{{ 'ACCOUNT_ADMIN.LAST_LOGIN' | translate}}</div>
                <div class="hvac-col-xs hvac-account-admin-detail-list-value">{{lastLoginDate || '--'}}</div>
            </li>
        </ul>

        <div class="hvac-divider"></div>
    </div>

    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-account-admin-details-col">
        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row">
                <div class="hvac-col-xs-12 hvac-label">{{ 'ACCOUNT_ADMIN.CONTACT_TYPES' | translate}}</div>
                <ul class="hvac-col-xs-12 hvac-account-admin-detail-link-list" *ngIf="contactTypes?.length">
                    <li *ngFor="let company of contactTypes">
                        {{company.name}}:
                        <ul class="account-admin-nested-list">
                            <li *ngFor="let type of company.types">{{type}}</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <div class="hvac-divider"></div>
    </div>
    <div class="hvac-col-xs-12 hvac-col-lg-3 hvac-account-admin-details-col">
        <ul class="hvac-account-admin-detail-list">
            <li class="hvac-row">
                <div class="hvac-col-xs-12 hvac-label">{{ 'ACCOUNT_ADMIN.ASSOCIATED_COMPANIES' | translate}}</div>
                <ul class="hvac-col-xs-12 hvac-account-admin-detail-link-list" *ngIf="contact?.companies?.length">
                    <li *ngFor="let company of contact?.companies">
                        <a routerLink="../../company/{{company.id}}" class="hvac-text-link">{{company.name}}</a>
                    </li>
                </ul>

                <p class="hvac-col-xs-12 hvac-p-xs" *ngIf="!contact?.companies?.length">({{ 'ACCOUNT_ADMIN.NO_ASSOCIATED_COMPANIES' | translate}})</p>
            </li>
        </ul>
    </div>
</div>

<div class="hvac-divider"></div>

<div class="hvac-row hvac-account-admin-detail-footer">
    <div class="hvac-col-xs-12 hvac-col-md-6">
        <p class="hvac-account-admin-changes">
            <span class="hvac-account-admin-changes-date" *ngIf="contact?.lastModifiedDate">{{ 'ACCOUNT_ADMIN.DATE' | translate}}: {{lastModifiedDate || '--'}}</span>
            <span>{{ 'ACCOUNT_ADMIN.WHO' | translate}}: {{contact?.lastModifiedBy || '--'}}</span>
        </p>
    </div>
    <div class="hvac-col-xs-12 hvac-col-md-6 hvac-end-xs">
        <a *ngIf="canEdit" routerLink="/Pages/admin/AdminEditUser.aspx" [queryParams]="{
            requestId: contact?.id,
            adminsearch: true
        }" hvacButton="primary">
            <span>{{ 'ACCOUNT_ADMIN.EDIT' | translate}}</span>
            <utc-svg src="assets/icons/arrow.svg"></utc-svg>
        </a>
    </div>
</div>


