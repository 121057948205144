<div class="engineering-overview-title-container">
    <div class="engineering-overview-title">
        <h3 class="hvac-h3">{{
            'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.INSIGHTS' | translate
            }}</h3>
    </div>
    <div>
        <button class="hvac-collapse-button" [ngClass]="{'hvac-collapse-button-collapsed': !(isFiltersOpen$ | async)}" hvacButton="text" size="small" (click)="handleCollapseFilters()">
            <span class="hvac-collapse-button-text">{{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.COLLAPSE_FILTER' |
                translate}}</span>

            <div class="hvac-collapse-icon">
                <utc-svg *ngIf="!(isFiltersOpen$ | async)" src="assets/icons/expand-plus.svg"></utc-svg>
                <utc-svg *ngIf="(isFiltersOpen$ | async)" src="assets/icons/collapse-minus.svg"></utc-svg>
            </div>
        </button>
    </div>
</div>
<div class="hvac-chart-filters-container">
    <hvac-factory-engineering-insights-filters [isFilterOpen]="isFiltersOpen$ | async" *ngIf="filterInputs$ | async as filterInputs"
        [filterInputs]="filterInputs"
        (onApplyFilters)="handleFilterUpdate($event)"
        (onFilterReset)="handleFilterReset()"></hvac-factory-engineering-insights-filters>

    <utc-loading-spinner class="hvac-filters-loader" *ngIf="isLoadingFilters$ | async" variant="overlay"></utc-loading-spinner>
</div>

<div class="hvac-chart-container" *ngIf="curDataView$ | async as curDataView">
    <ng-container *ngIf="(chartData$ | async) as chartData">
        <hvac-factory-engineering-insights-chart *ngIf="chartData && curDataView === DataView.Graph"
            [minMaxData]="(minMaxValues$ | async) || {}" [chartData]="chartData"></hvac-factory-engineering-insights-chart>
            <div class="download-chart-details"  *ngIf="!isLoading">
                <button hvacButton="default" class="download-image-btn" size="small" (click)="handleDownloadImage()">{{
                'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.DOWNLOAD_IMAGE' | translate
                }}</button>
                <button hvacButton="primary" size="small" (click)="handleRuntimeReport()">{{
                    'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.EXPORT_RUNTIME_REPORT' | translate
                    }}</button>

            </div>
            <utc-loading-spinner class="hvac-filters-loader" *ngIf="isLoading" variant="overlay"></utc-loading-spinner>
    </ng-container>

    <div class="hvac-chart-content-message" *ngIf="curDataView === DataView.NoData">
        <div class="hvac-label">{{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.NO_DATA' |
            translate}}</div>
    </div>

    <div class="hvac-chart-content-message" *ngIf="curDataView === DataView.LoadData">
        <div class="hvac-label">{{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.LOAD_DATA' |
            translate}}</div>
    </div>

    <div class="hvac-chart-content-message" *ngIf="curDataView === DataView.TimeoutError">
        <div class="hvac-label">
            {{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.SERVER_TIMEOUT' | translate}}</div>
    </div>

    <div class="hvac-chart-loader-container" *ngIf="curDataView === DataView.Loading">
        <utc-loading-spinner class="hvac-chart-loader" variant="overlay"></utc-loading-spinner>
    </div>
</div>

