export interface HeaderDetails {
    fileId:number;
    headerId:number;
    error: string;
    fileName: string;
    status: string;
    fileType:string;
    documentLink:string;
    name:string;
    type: string;
    requested: string;
    completed: string;
}

export class Inboxmessage {
    headerId: number;
    name: string;
    type: string;
    availableFormats: string;
    inboxViewer: boolean;
    requested: string;
    completed: string;
    status: string;
    error: string;
    documentLink: string;
    details: HeaderDetails[];
    fileName: string;
    fileId: number;
    fileType: string;
    isExpandable: boolean;
}

export interface InboxStateModel {
    unreadCount: number;
    inboxMessages: Inboxmessage[];
}

export interface InboxAPIResponseModel {
    GetInboxUnreadCountResult: number;
}

export class InboxAPIResponse {
    inboxDetails: Inboxmessage[];
}

export class GetInboxCount {
    static readonly type = '[Inbox] GetInboxCount';
}

export class ReceiveInboxCount {
    static readonly type = '[Inbox] ReceiveInboxCount';
    constructor(public readonly unreadCountResponse: InboxAPIResponseModel) { }
}

export class GetInboxDetails {
    static readonly type = '[Inbox] GetInboxDetails';
}

