<div class="hvac-wall-control-config-details">
    <hvac-toast [name]="toastOutlet"></hvac-toast>

    <div class="hvac-config-loading-spinner" *ngIf="(isLoading$ | async) else content">
        <utc-loading-spinner variant="overlay"></utc-loading-spinner>
    </div>
    <ng-template #content>
        <ng-container *ngIf="(configData$ | async) as configData">
            <hvac-product-property-list>
                <hvac-product-property-list-item *ngFor="let prop of configData.configProps| keyvalue: sortOrder">
                    <hvac-product-property-list-item-label> {{ 'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.PROPS.' +
                        prop.value.id | translate}} </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>
                        <ng-container *ngIf="prop.value.readOnly || isEditEnabled === false; else editProp"> {{
                            prop.value.value }} </ng-container>
                        <ng-template #editProp>
                            <hvac-config-edit-prop *ngIf="prop.value.control" [editProp]="prop.value"
                                [propFormControl]="prop.value.control"
                                [groupId]="prop.value.groupId"></hvac-config-edit-prop>
                        </ng-template>
                    </hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>

            <hvac-product-property-list *ngFor="let zoneData of configData.zonesConfigProps; index as zoneIndex">
                <hvac-product-property-list-item *ngFor="let prop of zoneData | keyvalue: sortOrder">
                    <hvac-product-property-list-item-label> {{ prop.value.zoneName }}: {{
                        'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.PROPS.' + prop.value.id | translate}}
                    </hvac-product-property-list-item-label>
                    <hvac-product-property-list-item-value>
                        <ng-container *ngIf="prop.value.readOnly || isEditEnabled === false; else editProp"> {{
                            prop.value.value }} </ng-container>
                        <ng-template #editProp>
                            <hvac-config-edit-prop *ngIf="prop.value.groupId && prop.value.control"
                                [editProp]="prop.value" [propFormControl]="prop.value.control"
                                [groupId]="prop.value.groupId"></hvac-config-edit-prop>
                        </ng-template>
                    </hvac-product-property-list-item-value>
                </hvac-product-property-list-item>
            </hvac-product-property-list>
        </ng-container>

        <div *ngIf="isEditEnabled && configFormGroup" class="hvac-form-actions hvac-save-user-info">
            <div class="hvac-save-user-info-message" *ngIf="isDeviceConnected; else deviceDisconnectInfoMsg">
                <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                <span class="hvac-p-sm hvac-fw-rg">{{ isSaveEnabled ? ('CONNECTED_PORTAL.CONFIG_SAVE_ENABLED' |
                    translate) : ('CONNECTED_PORTAL.CONFIG_SAVE_DISABLED' | translate)}}</span>
            </div>
            <ng-template #deviceDisconnectInfoMsg>
                <div class="hvac-save-user-info-message">
                    <utc-svg class="hvac-save-user-info-icon" src="assets/icons/info.svg"></utc-svg>
                    <span class="hvac-p-sm hvac-fw-rg">{{ 'CONNECTED_PORTAL.CONFIG_SAVE_DEVICE_DISCONNECTED' |
                        translate }}</span>
                </div>
            </ng-template>
            <div class="hvac-form-actions-group">
                <button class="hvac-form-actions-button"
                    [disabled]="!isSaveEnabled || (configFormGroup.pristine && zoneConfigFormGroup?.pristine) || !isDeviceConnected"
                    hvacButton="default" size="small" (click)="resetFormGroups()">{{
                    'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.CANCEL' | translate }}</button>
                <button class="hvac-form-actions-button"
                    [disabled]="!isSaveEnabled || (configFormGroup.pristine && zoneConfigFormGroup?.pristine) || !isDeviceConnected"
                    hvacButton="primary" size="small" (click)="openConfirmModal()">{{
                    'CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ACTIONS.SAVE' | translate }}</button>
            </div>
        </div>
    </ng-template>
</div>
