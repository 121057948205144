import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { format } from 'date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { FactoryCustomerLoginsDataSummaryEntry, FactoryCustomerLoginsReport } from 'private/app/models/factory.model';
import { BarChartColors, ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'hvac-customer-logins-report',
    templateUrl: './customer-logins-report.component.html',
    styleUrls: ['./customer-logins-report.component.scss']
})
export class CustomerLoginsReportComponent extends BaseComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public data?: FactoryCustomerLoginsReport;
    public isLoading = false;
    public chartOptions: ChartConfiguration['options'];
    public chartType: ChartType = 'bar';
    public chartData: null | ChartData<'bar'> = null;
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);
    public legendData: { label: string, color: string }[] = [];

    private maxTickCount = {
        mobile: 4,
        tablet: 12
    }

    constructor(
        private factoryService: FactoryService,
        private chartConfigService: ChartConfigService,
        private cdr: ChangeDetectorRef,
        private breakpointService: BreakPointService,
        private csvExportService: CSVExportService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.yearSelectFormControl.value) {
            const selectedYear = parseInt(this.yearSelectFormControl.value, 10);
            this.loadChartData(selectedYear).subscribe((res) => {
                this.isLoading = false;
                this.data = res.data;
                this.configureChart(this.data?.dataSummary || []);
            });
        }

        this.yearSelectFormControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((year) => {
                const parsedYear = parseInt(year, 10);

                return this.loadChartData(parsedYear);
            })
        ).subscribe((res) => {
            this.isLoading = false;
            this.data = res.data;
            this.configureChart(this.data?.dataSummary || []);
        });

        this.breakpointService.breakpoint$.pipe(
            filter(() => Boolean(this.data)),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((_value) => {
            if (this.data?.dataSummary) {
                this.configureChart(this.data.dataSummary);
                this.cdr.detectChanges();
            }
        });
    }

    public handleRunReport() {
        return this.loadChartData(this.yearSelectFormControl.value, true)
            .pipe(
                tap((res) => {
                    if (res?.data?.filepath) {
                        const { filepath } = res.data;

                        this.csvExportService.downloadFile(filepath);
                    }
                })
            ).subscribe();
    }

    private loadChartData(year: number, fileOnly = false) {
        this.isLoading = true;
        const params = year;

        return this.factoryService
            .queryCustomerLoginsForFactory(params, fileOnly)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            );
    }

    private getChartLabelsAndDataset(dataSummary: FactoryCustomerLoginsDataSummaryEntry[]) {
        const labels = dataSummary.map((entry) => format(new Date(entry.dateTime), 'MMM').toUpperCase());
        const dealerCounts = dataSummary.map((entry) => entry.dealerCount);
        const distributorCounts = dataSummary.map((entry) => entry.distributorCount);

        return {
            labels,
            datasets: [
                {
                    label: 'Dealers',
                    data: dealerCounts
                },
                {
                    label: 'Distributors',
                    data: distributorCounts
                }
            ]
        };
    }

    private configureChart(data: FactoryCustomerLoginsReport['dataSummary']) {
        if (data === null) {
            return;
        }

        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
        const { labels, datasets } = this.getChartLabelsAndDataset(data);

        this.chartOptions = this.chartConfigService.getGroupedBarChartConfig({ xScaleMaxTicks });
        this.chartData = this.chartConfigService.getGroupedBarChartData(labels, datasets, { datasetConfig: { categoryPercentage: 0.6 } });
        this.legendData = datasets.map((datum, index) => ({
            label: datum.label,
            color: BarChartColors[index].gradientTop
        }));
    }
}
