import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ProductDetails } from 'common/models';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { ProductsState } from 'common/store/products/products.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';

@Component({
    selector: 'utc-product-content-section-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OptionsComponent implements OnInit, OnDestroy {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(ContentState.contentBrand) brand$!: Observable<string>;
    @Select(CatalogState.currentBrand) currentBrand$!: Observable<string>;

    ngOnDestroy$ = new Subject();
    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    showOptions$ = this.attributes$.pipe(
        map((attributes) => Boolean(attributes && attributes.optionsTexts))
    );

    optionsTexts$ = combineLatest([this.attributes$, this.brand$, this.currentBrand$]).pipe(
        map(([attributes, inputBrand, currentBrand]) => {
            const brand = (inputBrand === 'default') ? currentBrand : inputBrand;
            const optionsTexts = attributes && attributes.optionsTexts ? attributes.optionsTexts : {};
            const selectedText = optionsTexts[brand] ? optionsTexts[brand] : optionsTexts[Object.keys(optionsTexts)[0]];

            return this.sanitizer.bypassSecurityTrustHtml(selectedText);
        })
    );

    constructor(
        private readonly translate: TranslateService,
        private readonly sanitizer: DomSanitizer,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef
    ) {}

    ngOnInit() {
        this.showOptions$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.OPTIONS'),
            order: 2,
            container: this.element.nativeElement
        };
    }
}
