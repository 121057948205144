import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { buildHttpParams } from 'common/api/utils';
import { environment } from 'common/environments/environment';
import { BaseResponse } from 'common/models/account-status';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { ToastService } from 'common/services/toast.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BrandedProductOffering, DealerLocatorEnrollmentList, DealerLocatorEnrollments, ProductOfferings, Ratings, ThirdPartyRatingParam, ZipCodes } from '../models/manage-dealer-locator';

@Injectable({ providedIn: 'root' })
export class ManageDealerLocatorService {
    public data$ = new BehaviorSubject<DealerLocatorEnrollmentList>({} as DealerLocatorEnrollmentList);
    public backAction = new BehaviorSubject(false);
    public refreshEnrollmentData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly httpClient: HttpClient,
        private apiOptions: ApiOptionsService,
        private currentRoute: Router,
        private toastService: ToastService,
        private translate: TranslateService
    ) { }

    public getBrandedProductOfferings(): Observable<BrandedProductOffering[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<BrandedProductOffering[]>(`${environment.api.dealerProductOfferings}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public postDealerEnrollmentData(enrollmentData: DealerLocatorEnrollmentList) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.post<BaseResponse>(`${environment.api.dealerLocatorEnrollmentsPost}`, enrollmentData, { ...options })),
            catchError((err: HttpErrorResponse) => {
                if (this.currentRoute.url === '/Admin/Admin-Tools/manage-dealer-locator/details' || this.currentRoute.url === '/Admin/Admin-Tools/manage-dealer-locator/zipcode' || this.currentRoute.url === '/Admin/Admin-Tools/manage-dealer-locator/ratings') {
                    this.toastService.add({
                        content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_LOCATOR_POST_FAILURE_MESSAGE'),
                        theme: 'error',
                        closeable: true,
                        autoClose: true,
                        id: 'DEALER_LOCATOR_POST_FAILURE_MESSAGE'
                    });

                    return of(err);
                }
                throw err;
            })
        );
    }

    public getAssignedZipCodePostBody(zipCode: ZipCodes[], dealerLocatorDetails: DealerLocatorEnrollmentList) {
        const body: DealerLocatorEnrollmentList = {
            dealerLocatorEnrollments: dealerLocatorDetails.dealerLocatorEnrollments,
            assignedZipCodes: zipCode,
            locatorRatings: dealerLocatorDetails.locatorRatings,
            companyId: dealerLocatorDetails.companyId,
            isFADEnabled: dealerLocatorDetails.isFADEnabled
        };

        return body;
    }

    public getDealerLocatorPostRequestBody(dealerLocatorRatingsArray: Ratings[], dealerLocatorDetails: DealerLocatorEnrollmentList) {
        const body: DealerLocatorEnrollmentList = {
            dealerLocatorEnrollments: dealerLocatorDetails.dealerLocatorEnrollments,
            assignedZipCodes: dealerLocatorDetails.assignedZipCodes,
            locatorRatings: dealerLocatorRatingsArray,
            companyId: dealerLocatorDetails.companyId,
            isFADEnabled: dealerLocatorDetails.isFADEnabled
        };

        return body;
    }

    public getEnrollmentsPostRequestBody(form: UntypedFormArray, dealerLocatorDetails: DealerLocatorEnrollmentList) {
        const dealerLocatorEnrollmentsArray: DealerLocatorEnrollments[] = [];
        dealerLocatorDetails.dealerLocatorEnrollments.forEach((enrollments, index) => {
            const enrollmentsBody:DealerLocatorEnrollments = this.createDealerLocatorPostBody(form.value[index], enrollments, dealerLocatorDetails);
            dealerLocatorEnrollmentsArray.push(enrollmentsBody);
        });
        const body: DealerLocatorEnrollmentList = {
            dealerLocatorEnrollments: dealerLocatorEnrollmentsArray,
            assignedZipCodes: dealerLocatorDetails.assignedZipCodes,
            locatorRatings: dealerLocatorDetails.locatorRatings,
            companyId: dealerLocatorDetails.companyId,
            isFADEnabled: dealerLocatorDetails.isFADEnabled
        };

        return body;
    }

    public getEnrollments(id: string): Observable<DealerLocatorEnrollmentList> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<DealerLocatorEnrollmentList>(`${environment.api.dealerLocatorEnrollments}${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public deleteThirdPartyRating(rating: ThirdPartyRatingParam): Observable<BaseResponse> {
        const params = buildHttpParams({
            hvacCompanyID: rating.hvacCompanyID,
            sourceName: rating.sourceName
        });
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.delete<BaseResponse>(`${environment.api.dealerLocatorThirdPartyRating}`,
                {
                    params,
                    ...options,
                    responseType: 'json'
                })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    private createDealerLocatorPostBody(dataObj: DealerLocatorEnrollments, enrollmentsList: DealerLocatorEnrollments, locatorDetailsList: DealerLocatorEnrollmentList) {
        const body: DealerLocatorEnrollments = {
            dealerBrandLocatorId: enrollmentsList.dealerBrandLocatorId,
            dealerBrandLocatorNm: enrollmentsList.dealerBrandLocatorNm,
            firstYearInHVAC: dataObj.firstYearInHVAC,
            callTrackingNumber: dataObj.callTrackingNumber,
            addCallTrackingNumber: dataObj.addCallTrackingNumber,
            marketingMessageId: enrollmentsList.marketingMessageId,
            isEnrolled: dataObj.isEnrolled,
            url: dataObj.url,
            Id: enrollmentsList.Id,
            ranking: enrollmentsList.ranking,
            productOfferings: this.getProductOfferingArray(dataObj, enrollmentsList, locatorDetailsList)
        };

        return body;
    }

    private getProductOfferingArray(dataObj: DealerLocatorEnrollments, enrollments: DealerLocatorEnrollments, locatorDetailsList: DealerLocatorEnrollmentList): ProductOfferings[] {
        const pdtOfferArray: ProductOfferings[] = [];
        dataObj.productOfferings.map((value: ProductOfferings) => {
            if (value.isSelected) {
                const obj = {
                    productOfferingId: value.productOfferingId,
                    companyId: value.companyId ? value.companyId : locatorDetailsList.companyId,
                    dealerLocatorEnrollmentId: value.dealerLocatorEnrollmentId ? value.dealerLocatorEnrollmentId : enrollments.Id
                };
                pdtOfferArray.push(obj);
            }

            return value;
        });

        return pdtOfferArray;
    }
}
