import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { NavMenu } from 'common/services/navigation-content.service';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { FrameLoaded } from 'common/store/ui/ui.actions';
import { createTitle } from 'common/utils/title-util';
import { isIE11 } from 'common/utils/user-agent';
import { WINDOW } from 'common/window.provider';
import { Observable } from 'rxjs';

const MAX_LOAD_TIME = environment.frameContent && environment.frameContent.maxLoaderTime || 6000;

@Component({
    selector: 'frame-content',
    templateUrl: './frame-content.component.html',
    styleUrls: ['./frame-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FrameContentComponent implements OnDestroy {
    @Select(NavigationState.navigation) navigation$: Observable<NavMenu>;

    @ViewChild('contentFrame') contentFrame: ElementRef;

    baseUrl = environment.frameContent && environment.frameContent.baseUrl;
    legacyInboxUrl = environment.frameContent && environment.frameContent.inboxUrl;
    isLoading = true;
    contentSource: string;
    contentFrameTitle: string;
    maxLoadTimer: NodeJS.Timeout;
    frameResetTimer: NodeJS.Timeout;
    navigation: NavMenu;
    frameReset = true;

    constructor(
        private readonly location: Location,
        private readonly store: Store,
        router: Router,
        private readonly title: Title,
        @Inject(WINDOW) private readonly window: Window
    ) {
        const titleToUpdate = createTitle(window.location.pathname, this.navigation);
        this.contentSource = 'about:blank';
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.showLoader();
                this.contentSource = decodeURIComponent(this.baseUrl + event.url.toString());
                this.store.dispatch(new FrameLoaded());
                this.contentFrameTitle = titleToUpdate;
                this.resetFrame();
            }
        });
        this.navigation$.subscribe((nav) => {
            this.navigation = nav;
        });
        this.title.setTitle(titleToUpdate);
        this.contentFrameTitle = titleToUpdate;
    }

    @HostListener('window:message', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    receiveMessage(event: any) {
        if (Object.prototype.hasOwnProperty.call((event.data), 'frameHeight')) {
            this.handleFrameNav(event.data);
            if (!isIE11(this.window.navigator)) {
                this.contentFrame.nativeElement.style.height = `${event.data.frameHeight}px`;
            }
            if (Object.prototype.hasOwnProperty.call(event.data, 'frameHref')) {
                this.location.replaceState(event.data.frameHref);
            }
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(_event: Event) {
        const tempSrc = decodeURIComponent(this.baseUrl + window.location.pathname.toString());
        this.contentSource = tempSrc;
        this.resetFrame();
        this.title.setTitle(createTitle(window.location.pathname, this.navigation));
    }

    ngOnDestroy() {
        this.clearMaxLoadTimer();
    }

    showLoader() {
        this.clearMaxLoadTimer();
        this.isLoading = true;
        this.maxLoadTimer = setTimeout(() => this.frameLoaded(), MAX_LOAD_TIME);
    }

    frameLoaded() {
        this.clearMaxLoadTimer();
        this.isLoading = false;
    }

    clearMaxLoadTimer(): void {
        if (this.maxLoadTimer) {
            clearTimeout(this.maxLoadTimer);
        }
        if (this.frameResetTimer) {
            clearTimeout(this.frameResetTimer);
        }

        return;
    }

    handleFrameNav(frameData: {frameHref: string, frameTitle:string}): void {
        const href = frameData.frameHref ? frameData.frameHref : window.location.pathname;

        if (Object.prototype.hasOwnProperty.call(frameData, 'frameTitle')) {
            this.title.setTitle(frameData.frameTitle);
            this.contentFrameTitle = frameData.frameTitle;
        }
        else {
            this.title.setTitle(createTitle(href, this.navigation));
        }
    }

    resetFrame() {
        this.frameReset = false;
        this.frameResetTimer = setTimeout(() => {
            this.frameReset = true;
        }, 100);
    }
}
