<div class="hvac-cp-adv-search-result-container">
    <div *ngIf="(customerResult$ | async)" class="hvac-cp-card">
        <ng-template #customerName let-item="item">
            <div class="hvac-p-sm hvac-cp-customer-name">{{item.name}}</div>
        </ng-template>

        <ng-template #propertyAddress let-item="item">
            <div class="hvac-p-sm">{{ item.propertyAddress }}</div>
        </ng-template>

        <ng-template #model let-item="item">
            <div class="hvac-p-sm hvac-cp-model-name">{{ item.modelName }}</div>
            <div class="hvac-p-sm">{{ item.model }}</div>
        </ng-template>

        <ng-template #contact let-item="item">
            <div class="hvac-p-sm"><a class="hvac-text-link" [attr.href]="'mailto:' + item.email">{{ item.email }}</a></div>
            <div class="hvac-p-sm">{{ item.phone }}</div>
        </ng-template>

        <ng-template #userName let-item="item">
            <div class="hvac-p-sm">{{item.userName}}</div>
        </ng-template>

        <ng-template #dealer let-item="item">
            <div class="hvac-p-sm">{{ item.dealerName }}</div>
            <div class="hvac-p-sm">{{ 'HVPID: ' + item.dealerHVPID }}</div>
        </ng-template>

        <ng-template #noDealer let-item="item">
            <div class="hvac-p-sm">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.NO_DEALER' | translate }}</div>
        </ng-template>

        <ng-template #detailsLink let-item="item">
            <a *ngIf="item.id" hvacButton="default" size="small" [routerLink]="['/connected-portal/dealers', item.dealerHVPID,'customers', item.id]" [queryParams]="{search: controlSerialNo}">{{ 'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.DETAILS' | translate }}</a>
        </ng-template>

        <div class="hvac-cp-list-container">
            <ul class="hvac-cp-list" >
                <ng-container *ngIf="customers?.length === 0">
                    <li class="hvac-cp-list-item hvac-cp-no-data">
                        <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
                    </li>
                </ng-container>

                <ng-container *ngIf="customers">
                    <li class="hvac-cp-list-item" *ngFor="let item of customers; trackBy:trackByItem">
                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.NAME' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="customerName" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.ADDRESS' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="propertyAddress" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.MODEL' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="model" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.CONTACT' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="contact" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.USERNAME' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="contact" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-top-xs hvac-cp-field-group">
                            <div class="hvac-label hvac-col-xs-4 hvac-cp-field-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.DEALER' | translate }}</div>
                            <div class="hvac-col-xs-8 hvac-cp-field-value">
                                <ng-container [ngTemplateOutlet]="dealer" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>

                        <div class="hvac-row hvac-cp-field-group">
                            <div class="hvac-col-xs-12 hvac-cp-field-value hvac-cp-details-link-container">
                                <ng-container [ngTemplateOutlet]="detailsLink" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </div>
                        </div>
                    </li>
                </ng-container>

                    <utc-loading-spinner variant="overlay" *ngIf="(isLoading$ | async)"></utc-loading-spinner>
            </ul>
            <h4 class="hvac-h4" *ngIf="itemCount && itemCount > 0">{{itemCount}} {{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.RESULTS' | translate }}</h4>

            <table class="hvac-cp-list">
                <thead>
                    <tr class="hvac-cp-list-table-header">
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.NAME' | translate }}</th>
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.ADDRESS' | translate }}</th>
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.MODEL' | translate }}</th>
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.CONTACT' | translate }}</th>
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.USERNAME' | translate }}</th>
                        <th class="hvac-label">{{ 'CONNECTED_PORTAL.ADVANCED_SEARCH.LIST_HEADING.DEALER' | translate }}</th>
                        <th class="hvac-label"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="customers?.length === 0">
                        <tr class="hvac-cp-list-row hvac-cp-list-item">
                            <td class="hvac-cp-field-group hvac-cp-no-data" colspan="6">
                                <span class="hvac-p-sm">{{ 'TABLE.NO_RECORDS_FOUND' | translate }}</span>
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container *ngIf="customers">
                        <tr class="hvac-cp-list-row hvac-cp-list-item" *ngFor="let item of customers; trackBy:trackByItem">
                            <td class="hvac-cp-field-group hvac-cp-customer-name-group">
                                <ng-container [ngTemplateOutlet]="customerName" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-property-address-group">
                                <ng-container [ngTemplateOutlet]="propertyAddress" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-model-group">
                                <ng-container [ngTemplateOutlet]="model" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-email-group">
                                <ng-container [ngTemplateOutlet]="contact" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-username-group">
                                <ng-container [ngTemplateOutlet]="userName" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-dealer-group">
                                <ng-container [ngTemplateOutlet]="item.dealerHVPID ? dealer: noDealer" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                            <td class="hvac-cp-field-group hvac-cp-detail-link-group" *ngIf="item.dealerHVPID">
                                <ng-container [ngTemplateOutlet]="detailsLink" [ngTemplateOutletContext]="{item: item}"></ng-container>
                            </td>
                        </tr>
                    </ng-container>

                    <utc-loading-spinner variant="overlay" *ngIf="(isLoading$ | async)"></utc-loading-spinner>
                </tbody>
            </table>
            <hvac-toast [name]="'exportErrorToastOutlet'"></hvac-toast>
            <div class="hvac-cp-list-footer">
                <div class="hvac-cp-list-footer-pagination">
                    <hvac-pagination *ngIf="currentPage && totalPages && totalPages > 1"
                        [totalPages]="totalPages"
                        [currentPage]="currentPage"
                        (pageChange)="handlePageChange($event)">
                    </hvac-pagination>
                </div>

                <div class="hvac-cp-list-footer-export">
                    <button class="hvac-cp-action-button" hvacButton="primary"  (click)="handleExportProperties()" [disabled]="customers?.length === 0">
                        {{ 'CONNECTED_PORTAL.CUSTOMERS.ACTIONS.EXPORT_CUSTOMER_LIST' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
