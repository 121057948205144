import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguageTranslationService {
    constructor(private translateService: TranslateService) {
    }

    translationsLoaded(): Observable<boolean> {
        if (this.translateService.store.translations[this.translateService.currentLang]) {
            return of(true);
        }

        return this.translateService.onLangChange
            .pipe(
                map((res) => Boolean(res))
            );
    }
}
