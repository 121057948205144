import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DocumentService } from 'common/services/document.service';
import { ContentComponent } from 'common/content/services/content-component.service';
import { DataTableFilterOption, DataTableLayout, DataTableSort } from 'common/models/data-table';
import { DocumentField, DocumentQuery } from 'common/models/document';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QueryFactory } from './query-factory/query-factory';

@Component({
    selector: 'hvac-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentListComponent implements OnDestroy, ContentComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() data: any;
    @Input() showSubTitle: boolean;

    documentListLoading = true;
    documentListError = false;
    documents: string[] = [];
    filterOptions: DataTableFilterOption[] = [];
    public isHome = false;
    layout: DataTableLayout;
    columnHeaders: string[];
    dataSort: DataTableSort;
    isSearchable = false;
    pageSize = 10;
    titleIcon: string;
    ngOnDestroy$ = new Subject();
    enableLanguageFilter: boolean;
    enableStatusFilter: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;

    constructor(
        private readonly documentService: DocumentService,
        private readonly queryFactory: QueryFactory
    ) {
        this.isHome = window.location.pathname === '/';
    }

    ngOnInit() {
        this.queryFactory.createQuery(this.data).then((query: DocumentQuery) => {
            this.query = query;
            this.fetchDocuments();
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onRowClick(event: any) {
        window.open(this.buildDocumentPath(event.value), '_blank');
    }

    buildDocumentPath(document: {DocumentBaseURL: string, DocumentPath: string}) {
        // remove trailing slash from base URL if exists
        return document.DocumentBaseURL.replace(/\/$/, '') + document.DocumentPath;
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    fetchDocuments() {
        this.documentService.getDocuments(this.query).pipe(takeUntil(this.ngOnDestroy$)).subscribe((results) => {
            if (results.Documents) {
                results.Documents.forEach((element: {fileType:string, FileType: string, DocumentMIMEType: string}) => {
                    element.fileType = element.FileType || element.DocumentMIMEType;
                });
                this.documents = this.queryFactory.filter(this.data, results.Documents);
                this.filterOptions = this.queryFactory.fetchFilterOptions(this.data, this.documents);
                this.layout = this.queryFactory.layout(this.data);
                this.isSearchable = this.queryFactory.isSearchable(this.data);
                this.enableLanguageFilter = this.queryFactory.enableLanguageFilter(this.data) || false;
                this.enableStatusFilter = this.queryFactory.enableStatusFilter(this.data) || false;
                this.columnHeaders = this.queryFactory.fields(this.data).map((item: DocumentField) => item.fieldName);
                this.dataSort = {
                    sortFieldName: this.queryFactory.sort(this.data).sortFieldName,
                    sortDirection: this.queryFactory.sort(this.data).sortDirection
                } as DataTableSort;
                this.pageSize = this.queryFactory.pageSize(this.data);
                this.titleIcon = this.data.Content.primaryMedia ? this.data.Content.primaryMedia.BinaryContent.Url : null;
                this.documentListLoading = false;
            }
        },
        (_error) => {
            this.documentListError = true;
            this.documentListLoading = false;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    documentQuery(query: any) {
        const selectionData = Object.keys(query).filter((validReq) => query[validReq]).map((validReq) => ({
            title: validReq,
            status: query[validReq]
        }));
        if (selectionData.length === 0) {
            this.query.status = [''];
            this.fetchDocuments();

            return;
        }
        if (selectionData.length === 2) {
            this.query.status = [''];
        }
        else if (selectionData[0].title === 'Current') {
            this.query.status = ['Current'];
        }
        else {
            this.query.status = ['Obsolete'];
        }

        this.fetchDocuments();
    }
}
