import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { SwiperContainer } from 'swiper/element/bundle';

@Directive({
    selector: '[hvacSwiper]',
    standalone: true
})
export class SwiperDirective implements OnChanges, OnDestroy {
    @Input() config?: Record<string, unknown>;

    constructor(private readonly el: ElementRef<SwiperContainer>) { }

    ngAfterViewInit(): void {
        Object.assign(this.el.nativeElement, this.config);
        this.el.nativeElement.initialize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['config'].isFirstChange()) {
            this.updateChangedParams(changes['config'].currentValue);

            this.el.nativeElement?.swiper?.update();
        }
    }

    ngOnDestroy() {
        this.el.nativeElement.swiper.destroy();
    }

    private updateChangedParams(config: Record<string, unknown>) {
        for (const key in this.el.nativeElement.swiper.params) {
            if (config[key] && this.el.nativeElement.swiper.params[key] !== config[key]) {
                (this.el.nativeElement as never)[key] = config[key] as never;
            }
        }
    }
}
