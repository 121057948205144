import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AssociationsParamsObject, CompanyAssociations } from 'private/app/models/company.model';
import { Company, DataHeader } from 'private/app/models/account-admin-search';
import { takeUntil } from 'rxjs/operators';
import { AssociationBaseComponent } from '../association-base/association-base.component';
import { BehaviorSubject } from 'rxjs';
import { Params } from '@angular/router';

@Component({
    selector: 'app-associated-dealers',
    templateUrl: './associated-dealers.component.html',
    styleUrls: ['./associated-dealers.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssociatedDealersComponent extends AssociationBaseComponent {
    @ViewChild('dealerContainer', { static: true }) dealerContainer: ElementRef;

    public dealerDetails$ = new BehaviorSubject<CompanyAssociations[]>([] as CompanyAssociations[]);

    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ACCOUNT_ADMIN.DEALER_NAME'),
            value: 'name',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.ID'),
            value: 'id',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.STATUS'),
            value: 'active',
            order: null
        }
    ];

    ngOnInit() {
        // SUBSCRIPTIONS
        this.onCompanyChange.pipe(takeUntil(this.ngOnDestroy$)).subscribe((comp: Company) => {
            if (comp?.id && this.company.id !== comp.id) {
                this.company = comp;
                this.navigateToPage(null, 'DCR');
            }
        });

        this.route.queryParamMap.pipe(takeUntil(this.ngOnDestroy$)).subscribe((params) => {
            const pageNo = params.get('dealerPagination') as string;
            this.navigateToPage(pageNo, 'DCR');
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.urlChanged.pipe(takeUntil(this.ngOnDestroy$)).subscribe((value: any) => {
            let pair = null;
            const data: AssociationsParamsObject[] = [];
            if (value.url.includes('dealerPagination') && value.url.includes('dealerTable')) {
                const keyval = value.url.split('?')[1].split('&');
                keyval.forEach((element: string) => {
                    pair = element.split('=');
                    data.push({
                        key: pair[0],
                        value: pair[1]
                    });
                });
                const filteredArray:AssociationsParamsObject[] = data.filter((obj: AssociationsParamsObject) => obj.key === 'dealerPagination');
                if (filteredArray && filteredArray.length > 0) {
                    this.navigateToPage(filteredArray[0].value, 'DCR');
                }
            }
        });

        this.dealerDetailsResult$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((dealerResults) => {
            this.dealerDetails$.next(dealerResults.data);
            this.totalCount = dealerResults?.totalCount;
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            this.displayCountStart = this.pagination === 1 ? dealerResults?.data?.length : this.pageSize * (this.pagination - 1) + 1;
            this.displayCountEnd = this.totalPages === this.pagination ? this.totalCount : dealerResults.data.length * this.pagination;
        });
    }

    getNativeElementRef() {
        return this.dealerContainer;
    }


    public handlePageChange(event: number) {
        const queryParams: Params = {
            dealerPagination: event,
            activeTable: 'dealerTable'
        };
        this.onPageChange(event, queryParams);
    }

    public getAssociatedBrands(brands: string[]) {
        return brands.join(', ');
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
