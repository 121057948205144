<div class="hvac-program-search-container">
    <div class="hvac-program-header">
        <h1 class="hvac-h3">
            {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_BY' | translate }}
        </h1>
        <a *ngIf="programAdminFullAccess" [hvacButton]="'default'" routerLink="/Admin/Program/search-program/create" size="small">
            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CREATE_PROGRAM' | translate }}</span>
        </a>
    </div>

    <form [formGroup]="programSearchForm">
        <div class="hvac-row hvac-program-filter-container">
            <div class="hvac-col-xs-12 hvac-col-md-12 hvac-col-lg hvac-program-input">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.STATUS' | translate }}
                </h2>
                <utc-select [options]="accountAdminProgramService.statusOptions" [group]="programSearchForm" control="status"
                    defaultOption="{{ 'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.STATUS' | translate }}">
                </utc-select>
            </div>
            <div class="hvac-col-xs-12 hvac-col-md-12 hvac-col-lg hvac-program-input">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.BRANDS' | translate }}
                </h2>
                <utc-select [options]="(brandsList$ | async)!" [group]="programSearchForm" control="brand"
                    defaultOption="{{ 'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.BRANDS' | translate }}">
                </utc-select>
            </div>
            <div class="hvac-col-xs-12 hvac-col-md-12 hvac-col-lg hvac-program-input">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_NAME' | translate }}
                </h2>
                <utc-input [inputControl]="programSearchForm.controls.programName"
                    [label]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.ENTER_PROGRAM' | translate">
                </utc-input>
            </div>
            <div class="hvac-col-xs-12 hvac-col-md-12 hvac-col-lg hvac-program-input">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.PROGRAM_CODE' | translate }}
                </h2>
                <utc-input [inputControl]="programSearchForm.controls.programCode"
                    [label]="'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.PROGRAM_CODE' | translate"
                    [error]="programSearchForm.controls.programCode.dirty && !programSearchForm.controls.programCode.valid">
                </utc-input>
            </div>
            <div class="hvac-col-xs-12 hvac-col-md-12 hvac-col-lg hvac-program-input">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.CRITERIA_NAME' | translate }}
                </h2>
                <utc-select [options]="(criteriaNamesList$ | async)!" [group]="programSearchForm" control="criteriaName"
                defaultOption="{{ 'ACCOUNT_ADMIN.PROGRAMS.PLACEHOLDER_TEXT.CRITERIA_NAME' | translate }}">
                </utc-select>
            </div>
        </div>
        <div class="program-search-btn">
            <button hvacbutton="primary" buttonType="submit" class="hvac-button-primary"
                [disabled]="!programSearchForm.valid"
                (click)="searchPrograms()">
                <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS' | translate }}</span>
            </button>
        </div>
    </form>
</div>
<div class="hvac-program-table-container">
    <hvac-table class="hvac-program-table-wrap" [theme]="'zebra'">
        <thead>
            <tr>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.PROGRAM_DETAILS' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.PROGRAM_CODE' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.BRAND' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.STATUS' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.CRITERIA' | translate }}
                </td>
                <td>
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.HEADING' | translate }}
                </td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let program of (programResults$ | async)">
                <td class="hvac-program-name">
                    {{ program.programName }}<br>
                    <span class="program_manager">{{ program.programManagerEmailsCount }}</span>
                </td>
                <td>
                    {{ program.code }}
                </td>
                <td>
                    {{ program.brandName }}
                </td>
                <td>
                    {{ program.status }}
                </td>
                <td>
                    {{ program.criteriaName }}
                </td>
                <td class="hvac-program-actions-width">
                    <a [routerLink]="['/Admin/Program/search-program/edit', program.programId]" queryParamsHandling="merge"  class="hvac-program-action">{{ programAdminFullAccess ? ('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.EDIT_PROGRAM' | translate) : ('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.VIEW_PROGRAM' | translate) }}</a> | <a [routerLink]="['/Admin/Program/search-program/manage-enrollments', program.programId]" class="hvac-program-action">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.MANAGE_ENROLLMENTS' | translate }}</a><br>
                    <a *ngIf="programAdminFullAccess" [routerLink]="['/Admin/Program/search-program/clone', program.programId]"  class="hvac-program-action">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.CLONE_PROGRAM' | translate }}</a> <span *ngIf="programAdminFullAccess">|</span>
                    <button *ngIf="programAdminFullAccess" (click)="modalVisible(program.programId)" class="hvac-program-action" hvacButton="text">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.REMOVE_PROGRAM' | translate }}</button>
                </td>
            </tr>
        </tbody>
    </hvac-table>
    <p class="hvac-admin-programs-no-results" *ngIf="!(programResults$ | async)?.length && !loading">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
    <utc-loading-spinner spinnerSize="small" *ngIf="loading"></utc-loading-spinner>
<div class="hvac-pagination">
        <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="currentPage"
            (pageChange)="handlePageChange($event)">
        </hvac-pagination>
    </div>
</div>
<utc-modal class='hvac-delete-enrollments-modal' [visible]="isModalVisible" (closeAction)="handleCloseModal()" theme='rounded' size='medium' [showHeader]=false>
<div class="hvac-modal-enrollments-delete">
    <p class="hvac-h3 hvac-warning-text">{{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.WARNING' | translate }}</p>
    <p class="hvac-label hvac-warning">{{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.REMOVE_PROGRAM_WARNING' | translate }}</p>
    <p class="hvac-h4 hvac-continue">{{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.REMOVE_PROGRAM_WARNING_CONFIRMATION' | translate }}</p>
    <div class="hvac-divider hvac-button-divider"></div>
    <div class="hvac-modal-button-container">
        <div class="hvac-remove-program-button-container">
            <button hvacButton="default" (click)="handleCloseModal()">
                {{'ACCOUNT_ADMIN.CANCEL' | translate }}
            </button>
            <button hvacButton="primary" (click)="deleteEnrolledProgram()">
                {{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.DELETE' | translate }}
            </button>
        </div>
    </div>
</div>
</utc-modal>
