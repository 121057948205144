import { DistributorService } from './../../../services/distributor-relationships.service';
import { Component, ElementRef, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JumpbarService } from 'common/content/services/jump-bar.service';
import { IdmService } from 'private/app/services/idm.service';
import { AccountAdminFormResultMap, Company, ContactAssociationsResult, ContactsPost, ContactTypesMap, DataHeader } from 'private/app/models/account-admin-search';
import { AccountAdminService } from 'private/app/services/account-admin/account-admin.service';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { BehaviorSubject } from 'rxjs';
import { AssociationBaseComponent } from '../association-base/association-base.component';
import { Location } from '@angular/common';
import { Option } from 'common/components/select/select.component';
import { OktaService } from 'common/services/okta/okta.service';
import { IdToken } from 'common/models';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { takeUntil } from 'rxjs/operators';
import { AssociationsParamsObject } from 'private/app/models/company.model';
import { ToastService } from 'common/services/toast.service';
import { CompanyQueryService } from 'private/app/services/account-admin/company-query.service';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { CompanyRelationshipService } from 'private/app/services/account-admin/company-relationship.service';

@Component({
    selector: 'app-associated-contacts',
    templateUrl: './associated-contacts.component.html',
    styleUrls: ['./associated-contacts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AssociatedContactsComponent extends AssociationBaseComponent {
    @ViewChild('contactContainer', { static: true }) contactContainer: ElementRef;

    @Output() onContactFormSubmit = new EventEmitter();

    public toastOutlet = 'postContactsError';
    public companyTypeCode$ = new BehaviorSubject<string>('');
    contactTypes:ContactTypesMap[]
    public formData: AccountAdminFormResultMap;
    public showAddContactModal: boolean;
    public canEdit: boolean = false;
    public addContactForm = new UntypedFormGroup({
        contactType: new UntypedFormControl(''),
        rsm: new UntypedFormControl(''),
        accountManager: new UntypedFormControl(''),
        tsm: new UntypedFormControl('')
    });

    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('COMPANY_INFO_FORM.CONTACT_NAME'),
            value: '',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.ID'),
            value: 'id',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.STATUS'),
            value: 'status',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.ACCOUNT_TYPE'),
            value: '',
            order: null
        }
    ];

    contactTypeOptions: Option[] = [
        {
            name: this.translate.instant('ACCOUNT_ADMIN.SELECT_CONTACT_TYPE'),
            value: ''
        },
        {
            name: this.translate.instant('ACCOUNT_ADMIN.ACCOUNT_MANAGER'),
            value: 'AM'
        },
        {
            name: this.translate.instant('ACCOUNT_ADMIN.RSM'),
            value: 'RSM'
        },
        {
            name: this.translate.instant('ACCOUNT_ADMIN.TSM'),
            value: 'TSM'
        }
    ];

    constructor(
        private oktaService: OktaService,
        toastService: ToastService,
        translate: TranslateService,
        idmService: IdmService,
        adminService: AccountAdminService,
        adminApiService: AccountAdminApiService,
        location: Location,
        route: ActivatedRoute,
        router: Router,
        jumpBarService: JumpbarService,
        distributorService: DistributorService,
        element: ElementRef,
        companyQuery: CompanyQueryService,
        public companyRelationshipService: CompanyRelationshipService,
        public postAdminService: AccountPostAdminService
    ) {
        super(toastService, translate, idmService, adminService, adminApiService, location, route, router, jumpBarService, distributorService, element, companyQuery, companyRelationshipService);
    }

    ngOnInit() {
        // SUBSCRIPTIONS
        this.oktaService.idToken$.subscribe((res: IdToken) => {
            this.canEdit = Boolean(res
                ?.claims
                ?.idm_user_roles
                ?.includes(IdmUserRoles.ACCOUNTADMIN));
        });
        this.route.queryParamMap.pipe(takeUntil(this.ngOnDestroy$)).subscribe((params) => {
            const pageNo = params.get('contactsPagination') as string;
            this.navigateToPage(pageNo);
        });

        this.onCompanyChange.pipe(takeUntil(this.ngOnDestroy$)).subscribe((comp: Company) => {
            this.companyTypeCode$.next(comp?.companyType?.code || '');
            if (comp?.id && this.company.id !== comp.id) {
                this.company = comp;
                this.navigateToPage(null);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.urlChanged.pipe(takeUntil(this.ngOnDestroy$)).subscribe((value: any) => {
            let pair = null;
            const data: AssociationsParamsObject[] = [];
            if (value.url.includes('contactsPagination') && value.url.includes('contactsTable')) {
                const keyval = value.url.split('?')[1].split('&');
                keyval.forEach((element: string) => {
                    pair = element.split('=');
                    data.push({
                        key: pair[0],
                        value: pair[1]
                    });
                });
                const filteredArray:AssociationsParamsObject[] = data.filter((obj: AssociationsParamsObject) => obj.key === 'contactsPagination');
                if (filteredArray && filteredArray.length > 0) {
                    this.navigateToPage(filteredArray[0].value);
                }
            }
        });

        this.addContactForm.controls.contactType.valueChanges.subscribe(() => {
            this.addContactForm.controls.rsm.setValue('');
            this.addContactForm.controls.accountManager.setValue('');
            this.addContactForm.controls.tsm.setValue('');
        });

        this.adminService.getAccountAdminSearchForm({ excludeParts: true }).subscribe((data: AccountAdminFormResultMap) => {
            this.formData = data;
        });
    }

    getNativeElementRef() {
        return this.contactContainer;
    }

    public handlePageChange(event: number) {
        const queryParams: Params = {
            contactsPagination: event,
            activeTable: 'contactsTable'
        };
        this.onPageChange(event, queryParams);
    }

    public navigateToPage(pageNo: string | null) {
        if (pageNo) {
            this.pagination = parseInt(pageNo, 10);
        }
        else {
            this.pagination = 1;
        }
        this.getAccountAdminContactsById(this.pagination);
    }

    public postContact() {
        const body: ContactsPost = {
            companyId: this.route.snapshot.params.id,
            contactTypeId: this.addContactForm.controls.contactType.value[0]?.value,
            userId: this.addContactForm.controls.accountManager.value[0]?.value ||
                this.addContactForm.controls.rsm.value[0]?.value ||
                this.addContactForm.controls.tsm.value[0]?.value
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.postAdminService.postContacts(body).subscribe((res: any) => {
            if (res.error?.error) {
                this.toastService.add({
                    content: res.error.error,
                    id: 'postContactsError',
                    theme: 'error',
                    closeable: true,
                    autoClose: true,
                    outletName: this.toastOutlet
                });
            }

            if (!res.error) {
                this.getAccountAdminContactsById();

                this.showAddContactModal = false;
                this.resetForm();
                this.onContactFormSubmit.emit();
            }
        });
    }


    private getAccountAdminContactsById(pagination: number = 1) {
        this.loading = true;
        this.idmService.postAccountAdminContactSearchByCompany(this.idmService.createAssociatedContactsSearchBody(this.company, pagination)).subscribe((res: ContactAssociationsResult) => {
            this.loading = false;
            this.contactDetails$.next(res.data[0].contacts);
            this.totalCount = res?.totalCount;
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
            this.displayCountStart = this.pagination === 1 ? res?.data[0]?.contacts?.length : this.pageSize * (this.pagination - 1) + 1;
            this.displayCountEnd = this.totalPages === this.pagination ? this.totalCount : res?.data[0]?.contacts?.length * this.pagination;
        });
    }

    private resetForm() {
        this.addContactForm.controls.contactType.setValue('');
        this.addContactForm.controls.rsm.setValue('');
        this.addContactForm.controls.accountManager.setValue('');
        this.addContactForm.controls.tsm.setValue('');
    }
}
