import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { AddToast, ToastService } from 'common/services/toast.service';
import { WallControlCommand } from 'private/app/models/connected-portal-system-diagnostics.model';
import { ConnectedPortalUIConfig } from 'private/app/models/connected-portal-ui-config.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { WallControlConfig } from 'private/app/models/wall-control.model';
import { ConfigEditProp, ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { stringToNumber } from 'private/app/services/connected-portal/utils';
import { ApiResponseCode, COMPRESSOR_OUTDOOR_MIN_TEMP_AUX_OUTDOOR_MAX_TEMP_OFFSET, COMPRESSOR_OUTDOOR_MIN_TEMP_DISABLED_VALUE, COMPRESSOR_OUTDOOR_MIN_TEMP_MIN_VALUE, ECOBEE_CONFIGURATION_RELOAD_DELAY, INPUT_AUTO_UPDATE_DELAY } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, scan, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EcobeeEditConfigState, ProductDetailContextService } from '../../../../product-detail-context.service';

interface ConfigData {
    configProps: Record<string, ConfigEditProp>;
}

interface LoadingState {
    configData?: boolean;
    saveUpdate?: boolean;
}

@Component({
    selector: 'hvac-ecobee-wall-control-config',
    templateUrl: './ecobee-wall-control-config.component.html',
    styleUrls: ['./ecobee-wall-control-config.component.scss']
})
export class EcobeeWallControlConfigComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() systemType: SystemType;
    @Input() isEditEnabled?: boolean;
    @Input() isSaveEnabled?: boolean;
    @Input() isDeviceConnected: boolean;

    public readonly CharSymbol = CharSymbol;
    public readonly EditConfigState = EcobeeEditConfigState;

    public editConfigUpdateToastOutlet = 'editConfigUpdateToast';
    public editConfigConnectionToastOutlet = 'editConfigConnectionToast';
    public configData$: Observable<ConfigData>;
    public configFormGroup: UntypedFormGroup | null = null;
    public zoneConfigFormGroup: UntypedFormGroup | null = null;
    public isCompressorOutdoorMinTempWarningShown$ = new BehaviorSubject(false);
    public isEcobeeConfigOutOfRange$: Observable<boolean>;
    public editConfigState$ = this.contextService.ecobeeEditConfigState$;
    public isLoading$: Observable<{ loading: boolean } & LoadingState>;

    private loadData$ = new BehaviorSubject<boolean>(true);
    private acOvercoolMaxValue: string | null;
    private wallControlConfigData$: Observable<WallControlConfig>;
    private configFormGroupInitialValues: Record<string, unknown> | null = null;
    private zoneConfigFormGroupInitialValues: Record<string, unknown> | null = null;
    private loadingStates$ = new BehaviorSubject<LoadingState>({
        configData: false,
        saveUpdate: false
    });

    constructor(
        private configService: ProductConfigurationService,
        private contextService: ProductDetailContextService,
        private productService: ProductService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.isLoading$ = this.loadingStates$.pipe(
            scan((acc, next) => ({
                ...acc,
                ...next
            })),
            map((loadingStates) => ({
                ...loadingStates,
                loading: Object.values(loadingStates).some((loadingState) => loadingState === true)
            }))
        );

        this.isEcobeeConfigOutOfRange$ = this.configService.ecobeeConfigOutOfRange$;

        this.wallControlConfigData$ = this.loadData$.pipe(
            switchMap(() => this.productService
                .queryWallControlConfigBySerialNo(this.serialNo, this.dealerId, this.systemType)
                .pipe(map((res) => res.data))),
            shareReplay()
        );

        this.configData$ = this.wallControlConfigData$.pipe(
            tap(() => this.loadingStates$.next({ configData: true })),
            switchMap((data) => {
                const { zones, ...props } = data;

                if (this.isEditEnabled) {
                    // Store this value so we can send it in the submission later.
                    this.acOvercoolMaxValue = data.acOvercoolMax;

                    return this.configService.staticConfigEditParams$
                        .pipe(
                            switchMap((uiConfig) => this.getEditableConfigPropData$(data, uiConfig)
                                .pipe(
                                    tap((editConfigProps) => {
                                        const { configDataControls } = editConfigProps;

                                        this.configFormGroup = new UntypedFormGroup(configDataControls);
                                        this.configFormGroupInitialValues = this.configFormGroup.value;

                                        this.initCompressorMinAuxMaxControlSyncing(this.configFormGroup);
                                    }),
                                    map((editConfigProps) => {
                                        const { configData } = editConfigProps;

                                        const configProps = configData;

                                        return { configProps };
                                    })
                                ))
                        );
                }

                const configProps = this.configService.getEcobeeReadOnlyPropData(props);

                return of({ configProps });
            }),
            tap(() => this.loadingStates$.next({ configData: false })),
            finalize(() => this.loadingStates$.next({ configData: false })),
            shareReplay()
        );

        // SUBSCRIPTIONS
        this.contextService.onConfigSaveConfirmed$
            .pipe(
                switchMap(() => this.saveConfigUpdates$()),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        const editConfigConnectionToast: AddToast = {
            outletName: this.editConfigConnectionToastOutlet,
            closeable: true,
            autoClose: true
        };

        this.contextService.enableEcobeeEditConfig$
            .pipe(
                tap(() => {
                    this.editConfigState$.next(EcobeeEditConfigState.EstablishingConnection);
                }),
                switchMap((isEnabled) => (isEnabled
                    ? this.productService.activateEcobeeControlBySerialNo(this.serialNo, this.dealerId)
                        .pipe(
                            tap(() => this.loadData$.next(true)),
                            catchError(() => {
                                this.toastService.add({
                                    ...editConfigConnectionToast,
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.CONNECTION_FAILURE'),
                                    theme: 'error'
                                });

                                this.editConfigState$.next(EcobeeEditConfigState.Unconnected);

                                return EMPTY;
                            })
                        )
                    : of(isEnabled))),
                tap((success) => {
                    if (success) {
                        this.toastService.add({
                            ...editConfigConnectionToast,
                            content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.ECOBEE.CONFIG_CONNECTION.CONNECTION_SUCCESS'),
                            theme: 'success'
                        });

                        this.editConfigState$.next(EcobeeEditConfigState.Connected);
                    }
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.contextService.onEcobeeDataRefresh$
            .pipe(
                tap(() => this.loadData$.next(true))
            ).subscribe();
    }

    public openConfirmModal() {
        this.contextService.isConfigEditConfirmModalVisible$.next(true);
    }

    public resetFormGroups() {
        this.configFormGroup?.reset(this.configFormGroupInitialValues);
        this.zoneConfigFormGroup?.reset(this.zoneConfigFormGroupInitialValues);
    }

    public sortOrder() {
        return 0;
    }

    public filterByConfigType(filterParam: string, item: Record<string, ConfigEditProp>) {
        return item.value.settingsType === filterParam;
    }

    public ecobeeSortOrder(a: KeyValue<string, ConfigEditProp>, b: KeyValue<string, ConfigEditProp>) {
        return a.value.displayOrder && b.value.displayOrder ? a.value.displayOrder - b.value.displayOrder : 0;
    }

    private saveConfigUpdates$() {
        if (!(this.configFormGroup)) {
            throw new Error('UPDATE_FAILED: Application Error');
        }

        // acOvercoolMax must always be sent as part of an ecobee update even when not edited.
        const submissionData = {
            acOvercoolMax: this.acOvercoolMaxValue,
            ...this.getOnlyUpdatedFormValues(this.configFormGroup as UntypedFormGroup)
        };

        this.loadingStates$.next({ saveUpdate: true });

        return this.productService.activateEcobeeControlBySerialNo(this.serialNo, this.dealerId)
            .pipe(
                switchMap(() => this.productService
                    .updateEcobeeWallControlConfigBySerialNo(this.serialNo, this.dealerId, submissionData)
                    .pipe(
                        switchMap((res) => {
                            if (res?.code === ApiResponseCode.SUCCESS) {
                                this.contextService.showToast(false, res, this.editConfigUpdateToastOutlet);

                                return of(true).pipe(
                                    delay(ECOBEE_CONFIGURATION_RELOAD_DELAY),
                                    tap(() => this.loadData$.next(true))
                                );
                            }

                            throw new Error(`UPDATE_FAILED: ${res?.code}`);
                        })
                    )),
                switchMap(() => this.productService.publishCommand(this.serialNo, this.dealerId, { command: WallControlCommand.DisconnectCommand })),
                catchError((err) => {
                    this.contextService.showToast(true, err, this.editConfigUpdateToastOutlet);
                    this.resetFormGroups();

                    return EMPTY;
                }),
                finalize(() => {
                    this.loadingStates$.next({ saveUpdate: false });
                    this.editConfigState$.next(EcobeeEditConfigState.Unconnected);
                })
            );
    }

    private getEditableConfigPropData$(wallControlConfigData: WallControlConfig, uiConfig: ConnectedPortalUIConfig['controlConfigEditParams']) {
        const wallControlStaticConfigParams = uiConfig.ecobee.wallControl;

        const mergedData = this.configService.mergeEcobeeConfigData(wallControlConfigData, wallControlStaticConfigParams);

        const configDataControls = Object.entries(mergedData)
            .reduce((acc, [key, value]) => {
                if (value && value.readOnly === false && value.control) {
                    acc[key] = value.control;
                }

                return acc;
            }, {} as Record<string, UntypedFormControl>);

        return of({
            configData: mergedData,
            configDataControls
        });
    }

    private initCompressorMinAuxMaxControlSyncing(configFormGroup: UntypedFormGroup) {
        // Setup synchronization between compressorOutdoorMinTemp and auxOutdoorMaxTemp controls.
        // compressorOutdoorMinTemp must maintain 5 degrees separation from auxOutdoorMaxTemp when both are editable.
        const compressorOutdoorMinCtrl = configFormGroup.get('compressorOutdoorMinTemp');
        const auxOutdoorMaxCtrl = configFormGroup.get('auxOutdoorMaxTemp');

        if (compressorOutdoorMinCtrl && auxOutdoorMaxCtrl) {
            const updateCompressorOutdoorMinCtrlValue = (useTimeout = false) => {
                const compressorOutdoorMinNum = stringToNumber(compressorOutdoorMinCtrl.value);
                const auxOutdoorMaxNum = stringToNumber(auxOutdoorMaxCtrl.value);

                if (auxOutdoorMaxNum === null || compressorOutdoorMinNum === null) {
                    return;
                }

                if (compressorOutdoorMinNum > (auxOutdoorMaxNum - COMPRESSOR_OUTDOOR_MIN_TEMP_AUX_OUTDOOR_MAX_TEMP_OFFSET)) {
                    const offsetValue = auxOutdoorMaxNum - COMPRESSOR_OUTDOOR_MIN_TEMP_AUX_OUTDOOR_MAX_TEMP_OFFSET;
                    const clampedValue = offsetValue - Math.abs(offsetValue % 5);
                    const nextValue = clampedValue < COMPRESSOR_OUTDOOR_MIN_TEMP_MIN_VALUE ? COMPRESSOR_OUTDOOR_MIN_TEMP_DISABLED_VALUE : clampedValue;

                    if (useTimeout) {
                        setTimeout(() => {
                            compressorOutdoorMinCtrl.setValue(String(nextValue));
                            compressorOutdoorMinCtrl.markAsDirty();
                        }, INPUT_AUTO_UPDATE_DELAY);
                    }
                    else {
                        compressorOutdoorMinCtrl.setValue(String(nextValue));
                        compressorOutdoorMinCtrl.markAsDirty();
                    }
                }
            };

            compressorOutdoorMinCtrl?.valueChanges.subscribe(() => {
                updateCompressorOutdoorMinCtrlValue(true);
            });

            auxOutdoorMaxCtrl.valueChanges.subscribe(() => {
                updateCompressorOutdoorMinCtrlValue(false);
            });
        }
    }

    private getOnlyUpdatedFormValues(formGroup: UntypedFormGroup) {
        const dirtyValues: Record<string, string | object> = {};

        Object.keys(formGroup.controls).forEach((controlKey) => {
            const currentControl = formGroup.get(controlKey);

            if ((currentControl as UntypedFormGroup).controls) {
                dirtyValues[controlKey] = this.getOnlyUpdatedFormValues(currentControl as UntypedFormGroup);
            }
            else if (currentControl && currentControl.dirty) {
                dirtyValues[controlKey] = currentControl.value;
            }
        });

        return dirtyValues;
    }
}
