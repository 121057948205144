import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { BaseContentEntity } from 'common/models';
import { UserStatus } from 'common/models/account-status';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { DashboardContentService } from 'private/app/views/dashboard/services/dashboard-content.service';
import { FetchApprovalRequests } from 'private/app/store/approval-requests.actions';
import { ApprovalRequestsState } from 'private/app/store/approval-requests.state';
import { RemoveNotifications } from 'private/app/store/notification.actions';
import { Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'hvac-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotificationsComponent implements OnInit {
    @Select(AccountStatusState.isActiveUser) isActiveUser$: Observable<UserStatus>;
    @Select(ApprovalRequestsState.requestCount) requestCount$: Observable<number>;

    approvalRequestsNotification$: Observable<string>;
    notifications$ = this.dashboardContent.notifications$;
    loading$: Subject<boolean> = new Subject();

    constructor(
        private readonly store: Store,
        private readonly translate: TranslateService,
        private readonly dashboardContent: DashboardContentService
    ) {}

    ngOnInit() {
        this.store.dispatch(new FetchApprovalRequests());
        this.approvalRequestsNotification$ = this.requestCount$.pipe(
            mergeMap((count) => this.translate.get('NOTIFICATIONS.USER_REGISTRATION_NOTIFICATION', { count }))
        );
    }

    hasLink(notification: BaseContentEntity): boolean {
        const { links } = notification?.Content || {};
        const link = links.$values ? links.$values[0] : links;

        return Boolean(link.linkExternal || link.linkInternalParameter || link.linkInternal);
    }

    removeNotification(notification : BaseContentEntity) {
        this.store.dispatch(new RemoveNotifications([{
            contentId: notification?.Id || '',
            status: false
        }]));
    }
}
