import { Component, Input } from '@angular/core';

@Component({
    selector: 'hvac-product-details-panel',
    templateUrl: './product-details-panel.component.html',
    styleUrls: ['./product-details-panel.component.scss']
})
export class ProductDetailsPanelComponent {
    @Input() headerText: string;
    @Input() divider: boolean = true;

    public openState = false;

    handleToggle() {
        this.openState = !this.openState;
    }
}
