import { Component, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { CloseChatbot, MinimizeChatbot, OpenChatBot, UnMinimizeChatbot } from 'common/store/chatbot/chatbot.actions';
import { ChatbotState } from 'common/store/chatbot/chatbot.state';
import { UiState } from 'common/store/ui/ui.state';
import { Observable } from 'rxjs';
@Component({
    selector: 'hvac-chat-bot',
    templateUrl: './chatBot.component.html',
    styleUrls: ['./chatBot.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatBotComponent {
    @Select(ChatbotState.hoverText) hoverText$: Observable<String>;
    @Select(ChatbotState.chatBotVisible) visible$: Observable<Boolean>;
    @Select(ChatbotState.chatBotMinimized) minimized$: Observable<Boolean>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<Boolean>;

    chatbotUrl = environment.chatbotUrl || '';

    isVisible: Boolean;
    isMinimized: Boolean;

    constructor(private readonly store: Store) { }

    ngOnInit() {
        this.minimized$.subscribe((minimized) => {
            this.isMinimized = minimized;

            return;
        });
        this.visible$.subscribe((visible) => {
            this.isVisible = visible;

            return;
        });
    }

    openChatbot() {
        if (!this.isVisible) {
            this.store.dispatch(new OpenChatBot());
        }
        else if (this.isMinimized) {
            this.store.dispatch(new UnMinimizeChatbot());
        }
    }

    minimizeChatbot() {
        this.store.dispatch(new MinimizeChatbot());
    }

    closeChatbot() {
        this.store.dispatch(new CloseChatbot());
    }
}
