/* eslint-disable max-lines */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';
import { CountryOption, EligibilityCriteria, EligibleCompanies, Program, ProgramSearchPayload, UpdateCriteriaData } from 'private/app/models/account-admin-program.model';
import { Option } from 'common/components/select/select.component';
import { EligibilityCriteriaQueryConfig } from './eligibilityCriteriaQueryConfig.service';
import { DynamicNestedFilterService } from 'src/common/services/dynamic-nested-filter.service';
import { AccountAdminEligibilityCriteriaService } from 'private/app/services/account-admin/account-admin-eligibility-criteria.service';
import { ToastService } from 'common/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HvacAutoCompleteEvent } from 'common/components/hvac-autocomplete/hvac-auto-complete.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppConstants } from 'common/app-constants';
import { CommonUtilsService } from 'private/app/services/common-utils.service';
import { QueryBuilderConfig, RuleSet } from 'common/libs/angular2-query-builder/query-builder/query-builder.interfaces';

@Component({
    selector: 'hvac-edit-eligibility-criteria',
    templateUrl: './edit-eligibility-criteria.component.html',
    styleUrls: ['./edit-eligibility-criteria.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [AccountAdminEligibilityCriteriaService]
})
export class EditEligibilityCriteriaComponent implements OnInit, OnDestroy {
    ngOnDestroy$ = new Subject();
    public fieldCriteriaForm: UntypedFormGroup = new UntypedFormGroup({ criteriaName: new UntypedFormControl('') });
    public totalPages: number;
    public pageSize: number = 10;
    public currentPage: number = 1;
    public customerCompanyForm: UntypedFormGroup = new UntypedFormGroup({ customerCompanyId: new UntypedFormControl() });
    public distributorCompanyForm: UntypedFormGroup = new UntypedFormGroup({ distributorCompanyId: new UntypedFormControl() });
    public displayInputField: boolean;
    public criteriaNamesList$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public criteriaData: EligibilityCriteria;
    public eligibleCompanyData$: BehaviorSubject<EligibleCompanies[]> = new BehaviorSubject<EligibleCompanies[]>([]);
    public eligibleCompanyFilteredData$: BehaviorSubject<EligibleCompanies[]> = new BehaviorSubject<EligibleCompanies[]>([]);
    public isLoading: boolean;
    public queryBuilderConfigValues: QueryBuilderConfig = this.eligibilityCriteriaQueryConfigService.QueryBuilderConfigValues as QueryBuilderConfig;
    public breadCrumbData: ContentHeadingData = this.eligibilityCriteriaQueryConfigService.breadCrumbData;
    public queryDataToDisplay: RuleSet;
    public initialQuery: RuleSet = { ...this.eligibilityCriteriaQueryConfigService.emptyQueryData };
    public modifiedQueryDataToDisplay: RuleSet;
    public countryOptionData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public selectedCountryId: string | null = 'US';
    public optionListForState: CountryOption[] = [];
    public programId: string;
    public bannerType = 'EditEligibiltyCriteria';
    public criteriaNameFromCreate: string;
    public criteriaIdFromCreate: string;
    public programNameData$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    public programNameQuerySubject$: BehaviorSubject<string> = new BehaviorSubject('');
    public programNames: Program[] = [];
    public isLoadMoreData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public initialPage = 0;
    public autoComplete$: BehaviorSubject<HvacAutoCompleteEvent> = new BehaviorSubject<HvacAutoCompleteEvent>({ loading: false });
    public totalCount = 0;
    public currentPageAfterFilter: number = 1;
    public totalPagesAfterFilter: number = 1;
    public criteriaDetails: UpdateCriteriaData = this.eligibilityCriteriaQueryConfigService.criteriaDetails;
    public disableFields: boolean = true;
    public isQueryUpdated = false;
    public modifyQueryFlag: number = 0;

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        public queryStringService: DynamicNestedFilterService,
        public eligibilityCriteriaService: AccountAdminEligibilityCriteriaService,
        public eligibilityCriteriaQueryConfigService: EligibilityCriteriaQueryConfig,
        private toastService: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private languageTranslationService: LanguageTranslationService,
        private readonly accountAdminApiService: AccountAdminApiService,
        private commonUtils: CommonUtilsService
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((params) => {
                this.criteriaNameFromCreate = (params.criteriaName) ? params.criteriaName : '';
                this.criteriaIdFromCreate = (params.criteriaId) ? params.criteriaId : '';
                this.router.navigate([`/Admin/Program/search-program/edit-eligibility-criteria`], { replaceUrl: true });
            });
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
        });
        this.displayInputField = false;
        this.getAccountAdminProgramEligibilityCriteria();
        this.commonUtils.getCountriesEntity().subscribe((countryList) => {
            this.queryBuilderConfigValues.fields.Country.options = countryList.map((country) => ({
                name: country.name,
                value: country.countryId.toString()
            }));
            this.optionListForState = countryList.map((country): CountryOption => ({
                code: country.name,
                value: country.countryId.toString()
            }));
        });
        this.getStateList(this.selectedCountryId!).subscribe(
            (stateList) => {
                this.queryBuilderConfigValues.fields.StateProvince.options = stateList;
            }
        );
        this.fieldCriteriaForm.controls.criteriaName.setValue(this.criteriaNameFromCreate);
        this.getAccountAdminProgramCriteria();
    }

    ngOnDestroy() {
        this.eligibilityCriteriaService.ngOnDestroy();
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    getStateList(selectedCountry: string) {
        return this.commonUtils.getStateByCountry(selectedCountry);
    }

    areQueriesEqual(query1: RuleSet, query2: RuleSet): boolean {
        if (query1.condition !== query2.condition) {
            return false;
        }

        if (query1.rules.length !== query2.rules.length) {
            return false;
        }

        return JSON.stringify(query1.rules) === JSON.stringify(query2.rules);
    }

    modifyQueryData($event: RuleSet) {
        this.modifiedQueryDataToDisplay = $event;
        this.isQueryUpdated = (this.modifyQueryFlag >= 2);
        this.selectedCountryId = JSON.stringify($event).split('"field":"Country","operator":"Equals","value":')[1]?.split('}')[0];
        this.selectedCountryId = (this.selectedCountryId) ? JSON.parse(this.selectedCountryId) : 'US';
        const selectedCountry = this.optionListForState.filter((entry) => String(entry.value) === this.selectedCountryId)[0]?.code || null;
        if (selectedCountry) {
            this.getStateList(selectedCountry).subscribe(
                (stateList) => {
                    this.queryBuilderConfigValues.fields.StateProvince.options = stateList;
                }
            );
        }
        this.modifyQueryFlag += 1;
    }

    public programName($event: string) {
        this.programNameData$.subscribe((_status) => this.autoComplete$.next({
            loading: false,
            loadMore: this.isLoadMoreData$.value || false
        }));

        this.programNameQuerySubject$.next($event);
        this.programNameQuerySubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(AppConstants.defaultTypeAheadDebounceTime)
        ).subscribe((_searchTextValue) => {
            this.programNameData$.next([]);

            this.autoComplete$.next({ loading: true });
            this.getProgramListByQuery($event, this.programNameData$, false);
        });
    }

    programNameWithPagination() {
        this.autoComplete$.next({ loading: true });
        this.getProgramListByQuery(this.programNameQuerySubject$.value, this.programNameData$, true);
    }

    public getProgramListByQuery(programName: string, programNameData$: BehaviorSubject<Option[]>, isLoadMore: boolean) {
        this.initialPage = isLoadMore ? this.initialPage + 1 : this.initialPage;
        const criteriaDataForPages: ProgramSearchPayload = <ProgramSearchPayload>({
            criteria: {
                programName: programName,
                programCode: '',
                programStatus: '',
                brandName: '',
                eligibleCriteriaName: ''
            },
            pageParams: {
                page: this.initialPage,
                pageSize: 50
            },
            sortFields: [],
            sortParams: [],
            determineTotalCount: true
        });

        this.accountAdminApiService.getAccountAdminProgramsBySearch((criteriaDataForPages)).subscribe((programResults) => {
            this.totalCount = programResults?.totalCount;

            if (programNameData$.value?.length === 0) {
                this.programNames = this.eligibilityCriteriaService.mapProgramDetails(programResults.data);
                this.paginationStatus();
                programNameData$.next(this.programNames.map((program: Program) => ({
                    name: program.programName,
                    value: program.programId as unknown as string
                })));

                return;
            }

            this.programNames = [...this.programNames, ...this.eligibilityCriteriaService.mapProgramDetails(programResults.data)];
            this.paginationStatus();
            programNameData$.next(this.programNames.map((program: Program) => ({
                name: program.programName,
                value: program.programId as unknown as string
            })));
        });
    }

    public paginationStatus() {
        this.isLoadMoreData$.next(this.programNames?.length < this.totalCount);
    }

    getAccountAdminProgramCriteria() {
        this.modifyQueryFlag = 0;
        this.displayInputField = true;
        this.currentPage = 1;
        this.queryDataToDisplay = { ...this.eligibilityCriteriaQueryConfigService.emptyQueryData };
        const criteriaId = (this.criteriaIdFromCreate) ? this.criteriaIdFromCreate : this.fieldCriteriaForm.controls.criteriaName.value[0]?.value;
        this.criteriaDetails.criteriaId = criteriaId;
        this.criteriaDetails.criteriaName = (this.criteriaNameFromCreate) ? this.criteriaNameFromCreate : this.fieldCriteriaForm.controls.criteriaName.value[0]?.name;
        if (criteriaId) {
            this.disableFields = false;
            this.isLoading = true;
            this.accountAdminApiService.getAccountAdminProgramCriteria(criteriaId).subscribe((criteria: EligibilityCriteria) => {
                this.isLoading = false;
                this.criteriaData = criteria;
                if (criteria.filterStmt) {
                    this.queryDataToDisplay = this.queryStringService.parseExpression(criteria.filterStmt);
                    this.initialQuery = { ...this.queryDataToDisplay };
                    if (criteria.filterStmt.includes('ProgramId')) {
                        const programId = criteria.filterStmt.split('ProgramId = \'')[1]?.split('\' ')[0];
                        this.accountAdminApiService.getAccountAdminProgramsById(programId).subscribe((response) => {
                            const programName: Option[] = [];
                            programName.push({
                                name: `${response.name} (${response.status})`,
                                value: response.id as unknown as string
                            });
                            this.programNameData$.next(programName);
                        });
                    }
                }
                else {
                    this.queryDataToDisplay = { ...this.eligibilityCriteriaQueryConfigService.emptyQueryData };
                }

                (criteria.dealerIds) ? this.eligibilityCriteriaService.getCustomerIdValue(criteria.dealerIds.split(',').map((line: string) => line.split(','))) : this.eligibilityCriteriaService.getCustomerIdValue([]);
                (criteria.distributorIds) ? this.eligibilityCriteriaService.getDistributorIdValue(criteria.distributorIds.split(',').map((line: string) => line.split(','))) : this.eligibilityCriteriaService.getDistributorIdValue([]);

                if (criteria.eligibleCompanies) {
                    this.totalPages = Math.ceil(criteria.eligibleCompanies.length / this.pageSize);
                    this.eligibleCompanyFilteredData$.next(criteria.eligibleCompanies);
                    this.eligibleCompanyData$.next(criteria.eligibleCompanies.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
                }
            },
            (err) => {
                this.isLoading = false;
                this.eligibilityCriteriaQueryConfigService.showToastBar(err);
            });
        }
    }

    onSubmit() {
        this.isLoading = true;
        this.criteriaDetails.programIds = [];
        this.criteriaDetails.selectedFields = this.queryStringService.getSelectedFields(this.modifiedQueryDataToDisplay);
        this.criteriaDetails.criteriaFilter = this.queryStringService.basicRulesetToQueryString(this.modifiedQueryDataToDisplay);
        this.criteriaDetails.dealerCompanyIds = this.eligibilityCriteriaService.customerCompanyfilteredData$.value.flat(1).map(Number);
        this.criteriaDetails.distributorCompanyIds = this.eligibilityCriteriaService.distributorCompanyfilteredData$.value.flat(1).map(Number);
        if (JSON.stringify(this.modifiedQueryDataToDisplay).includes('ProgramId')) {
            const programId = JSON.stringify(this.modifiedQueryDataToDisplay).split('"field":"ProgramId","operator":"Equals","value":')[1]?.split('}')[0];
            this.criteriaDetails.programIds.push(Number(JSON.parse(programId)));
        }
        this.accountAdminApiService.postUpdateEligibilityCriteria(this.criteriaDetails).subscribe((response) => {
            this.accountAdminProgramService.smoothScrollToTop();
            if (response.id) {
                this.isLoading = false;
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_UPDATE_SUCCESS', { criteriaName: response.name }),
                    theme: 'success',
                    id: this.bannerType,
                    closeable: true,
                    autoClose: true
                });
                this.getAccountAdminProgramCriteria();
            }
        },
        (err) => {
            this.isLoading = false;
            this.eligibilityCriteriaQueryConfigService.showToastBar(err);
        });
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), this.pageSize);
        this.eligibleCompanyData$.next(
            this.eligibleCompanyFilteredData$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    onCancel() {
        this.fieldCriteriaForm.controls.criteriaName.setValue('');
        this.disableFields = true;
        this.criteriaData.eligibleCompanies = [];
        this.queryDataToDisplay = { ...this.eligibilityCriteriaQueryConfigService.emptyQueryData };
        this.eligibilityCriteriaService.getCustomerIdValue([]);
        this.eligibilityCriteriaService.getDistributorIdValue([]);
        this.eligibleCompanyData$.next([]);
        this.totalPages = 0;
        this.accountAdminProgramService.smoothScrollToTop();
        this.eligibilityCriteriaService.isCustomerTableVisible = false;
        this.eligibilityCriteriaService.isDistributorTableVisible = false;
    }

    private getAccountAdminProgramEligibilityCriteria() {
        this.accountAdminApiService.getAccountAdminProgramEligibilityCriteria().subscribe((criteriaNames: EligibilityCriteria[]) => {
            const optionList: Option[] = [];
            criteriaNames.map((criteria: EligibilityCriteria) => optionList.push({
                name: criteria.name,
                value: criteria.id
            }));
            const sortedOptions = optionList?.slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase()));
            sortedOptions.unshift({
                name: this.translate.instant('ACCOUNT_ADMIN.SELECT_CRITERIA'),
                value: ''
            });
            this.criteriaNamesList$.next(sortedOptions);
        });
    }
}
