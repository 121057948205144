import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ErrorService, SiteMap } from 'common/content/services/error.service';
import { environment } from 'common/environments/environment';
import { SearchQuery } from 'common/models';
import { User } from 'common/models/auth';
import { BreakPointService } from 'common/services/breakpoint.service';
import { ContentService } from 'common/services/content.service';
import { NavMenu } from 'common/services/navigation-content.service';
import { HVAC_SESSION_EVENT, SessionEvent } from 'common/services/okta/okta-base.service';
import { SessionStorageService } from 'common/services/sessionStorage/sessionStorage.service';
import { ToastService } from 'common/services/toast.service';
import { GetUserStatus } from 'common/store/account-status/account-status.actions';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { LogOut } from 'common/store/auth/auth.actions';
import { AuthState } from 'common/store/auth/auth.state';
import { SetBrand, SetBrandRedirect } from 'common/store/branding/branding.actions';
import { BrandingState } from 'common/store/branding/branding.state';
import { ChatbotState } from 'common/store/chatbot/chatbot.state';
import { ContentState } from 'common/store/content/content.state';
import { LoginPanelState } from 'common/store/login-panel/login-panel.state';
import { NavigationState } from 'common/store/navigation/navigation.state';
import { PerformSearch } from 'common/store/search/search.actions';
import { ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { createTitle } from 'common/utils/title-util';
import { isIE11 } from 'common/utils/user-agent';
import { WINDOW } from 'common/window.provider';
import { FetchBookmarks } from 'private/app/store/bookmarks.actions';
import { GetInboxCount, GetInboxDetails } from 'private/app/store/inbox.actions';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DataResolverService } from '../../common/content/services/data-resolver.service';

const NO_SCROLL_CLASS = 'hvac-state-fixed';
const PANEL_VISIBLE_CLASS = 'hvac-slide-out-visible';
const APPZI_SCRIPT_ID = 'appzi-script';
// brands that have color themes on the site
const THEMED_BRANDS = ['carrier', 'bryant'];
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class AppComponent implements OnInit, OnDestroy {
    @Select(AuthState.isLoggedIn) isLoggedIn$: Observable<boolean>;
    @Select(AuthState.user) user$: Observable<User | null>;
    @Select(BrandingState.themeBrand) brand$!: Observable<string>;
    @Select(ChatbotState.canShowChatbot) canShowChatBot$: Observable<Boolean>;
    @Select(ChatbotState.chatBotVisible) chatBotVisible$: Observable<Boolean>;
    @Select(ContentState.contentLoading) contentLoading$: Observable<boolean>;
    @Select(LoginPanelState.loginPanelVisible) loginPanelVisible$: Observable<boolean>;
    @Select(NavigationState.currentPage) currentPage$: Observable<NavMenu>;
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Select(UiState.tabletMenuVisible) tabletMenuVisible$: Observable<boolean>;
    @Select(UiState.bookmarkSlide) bookmarkSlide$: Observable<boolean>;
    @Select(AccountStatusState.getProfileUpdateRequestStatus) profileUpdateRequestStatus$: Observable<boolean>;
    @Select(ContentState.dealerAccessCheckLoading) dealerAccessCheckLoading$: Observable<boolean>;

    ngOnDestroy$ = new Subject();
    isUserValidation$ = new BehaviorSubject<boolean>(false)
    allowChatbot = environment.features.chatbot;
    showFooter = true;
    showPageLoader = false;

    constructor(
        public errorService: ErrorService,
        public toastService: ToastService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private dataResolverService: DataResolverService,
        private contentService: ContentService,
        private readonly element: ElementRef,
        private readonly renderer: Renderer2,
        private readonly sessionStorage: SessionStorageService,
        private readonly title: Title,
        private readonly breakPoint: BreakPointService,
        private readonly translate: TranslateService,
        @Inject(WINDOW) private readonly window: Window
    ) {
    }

    @HostListener('window:storage', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStorageEvent(event: any) {
        if (event.key !== HVAC_SESSION_EVENT) {
            return;
        }
        if (event.newValue === SessionEvent.LOGOUT) {
            localStorage.removeItem(HVAC_SESSION_EVENT);
            sessionStorage.clear();
            this.store.dispatch(new LogOut());
        }
    }

    ngOnInit() {
        this.renderer.addClass(this.element.nativeElement, environment.envName);

        window.onmessage = (event) => {
            if (event.data && event.data.id === 'popupPosition') {
                window.scrollTo(0, 0);
            }
        };

        this.contentService.getSiteMap().subscribe((res: SiteMap[]) => {
            const urls = res.concat([
                { Url: '/en/us/' },
                { Url: '/en/us/home' }
            ]);

            this.contentService.siteMap$.next(urls);
        });

        combineLatest([this.router.events, this.currentPage$]).subscribe(([_routerEvent, navUrl]) => {
            const baseUrl = this.router.url.match(/^\/?([^?/]*)/);
            if (baseUrl && typeof baseUrl[1] !== 'undefined' && baseUrl[1] !== null) {
                if (baseUrl[1].toLowerCase() === 'connected-portal') {
                    return;
                }
            }
            this.title.setTitle(createTitle(this.router.url, navUrl));
        });

        this.restoreBranding();

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this.toastService.removeAll();
            }

            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            this.showFooter = this.canShowFooter(this.router.url);
            this.store.dispatch(new ToggleHeaderDropdown('none'));

            // scroll to top of page every new navigation
            // unless we explicitly prevent it.
            if (!evt.url.includes('scroll=false')) {
                window.scrollTo(0, 0);
            }

            if (isIE11(this.window.navigator)) {
                this.toastService.add({
                    content: this.translate.instant('IE_BANNER_CONTENT'),
                    theme: 'warning',
                    id: 'ie-banner',
                    closeable: true
                });
            }
        });

        this.isLoggedIn$.pipe(
            takeUntil(this.ngOnDestroy$),
            first((loggedIn) => loggedIn)
        ).subscribe(() => {
            this.store.dispatch(new FetchBookmarks());

            this.dataResolverService.resolveData();

            if (environment.appziSrc && !window.document.getElementById(APPZI_SCRIPT_ID)) {
                const appziScript = this.window.document.createElement('script');
                appziScript.async = true;
                appziScript.id = APPZI_SCRIPT_ID;
                appziScript.src = environment.appziSrc;
                this.window.document.head.appendChild(appziScript);
            }
        });

        this.route.queryParams.subscribe((params) => {
            if (params.brandref) {
                this.store.dispatch(new SetBrand(params.brandref));
                this.store.dispatch(new SetBrandRedirect(params.brandref));
            }
        });

        this.brand$.subscribe((brand) => {
            this.renderer.removeClass(this.element.nativeElement, 'carrier');
            this.renderer.removeClass(this.element.nativeElement, 'bryant');
            if (THEMED_BRANDS.includes(brand.toLowerCase())) {
                this.renderer.addClass(this.element.nativeElement, brand.toLowerCase());
            }
            this.updateFavicon(brand);
        });

        this.routerState$.pipe(
            takeUntil(this.ngOnDestroy$),
            first((routerState) => Boolean(routerState && !routerState.url.startsWith('/authenticating') && !routerState.url.startsWith('/callback')))
        ).subscribe((routerState) => {
            if (!routerState) {
                return;
            }
            const isSearch = routerState.url.startsWith('/search');
            const hasParams = Object.keys(routerState.root.queryParams).length;

            if (isSearch && hasParams) {
                this.store.dispatch(new PerformSearch(routerState.root.queryParams as SearchQuery));
            }
        });

        this.mobileMenuVisible$.subscribe((mobileMenuVisible: boolean) => {
            this.toggleNoScroll(mobileMenuVisible);
        });

        this.tabletMenuVisible$.subscribe((tabletMenuVisible: boolean) => {
            this.toggleNoScroll(tabletMenuVisible);
        });

        this.loginPanelVisible$.subscribe((loginPanelVisible: boolean) => {
            this.toggleNoScroll(loginPanelVisible);
        });

        this.bookmarkSlide$.subscribe((bookmarkSlideVisible: boolean) => {
            this.toggleNoScroll(bookmarkSlideVisible);
        });

        this.profileUpdateRequestStatus$?.subscribe((profileVerificationRequest: boolean) => {
            if (environment.features.userValidation) {
                this.isUserValidation$.next(profileVerificationRequest);
            }
        });

        this.chatBotVisible$.subscribe((chatBotVisible) => {
            if (chatBotVisible && this.breakPoint.isMobile()) {
                this.renderer.addClass(document.documentElement, NO_SCROLL_CLASS);
            }
            else {
                this.renderer.removeClass(document.documentElement, NO_SCROLL_CLASS);
            }
        });

        this.user$.subscribe((user) => {
            if (!user) {
                return;
            }
            this.store.dispatch(new GetUserStatus());
            this.store.dispatch(new GetInboxCount());
            this.store.dispatch(new GetInboxDetails());
        });
    }

    canShowFooter(url: string) {
        return !url.includes('create-account-information') && !url.includes('create-account-company-lookup') && !url.includes('create-account-email-validate');
    }

    toggleNoScroll(property: boolean) {
        if (property) {
            this.renderer.addClass(document.documentElement, NO_SCROLL_CLASS);
            this.renderer.addClass(document.documentElement, PANEL_VISIBLE_CLASS);
        }
        else {
            this.renderer.removeClass(document.documentElement, NO_SCROLL_CLASS);
            this.renderer.removeClass(document.documentElement, PANEL_VISIBLE_CLASS);
        }
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private restoreBranding() {
        const brand = this.sessionStorage.retrieveBrand();
        const brandRedirect = this.sessionStorage.retrieveBrandref();
        if (brand) {
            this.store.dispatch(new SetBrand(brand));
        }
        if (brandRedirect) {
            this.store.dispatch(new SetBrandRedirect(brandRedirect));
        }
    }

    private updateFavicon(brand: string) {
        const oldLink = document.getElementById('private-favicon');
        if (oldLink) {
            const link = document.createElement('link');
            link.id = 'private-favicon';
            link.rel = 'icon';
            link.type = 'image/png';
            if (THEMED_BRANDS.includes(brand.toLowerCase())) {
                link.href = `assets/images/${brand.toLowerCase()}-favicon-32x32.png`;
            }
            else {
                link.href = 'assets/images/default-favicon-32x32.png';
            }
            document.head.removeChild(oldLink);
            document.head.appendChild(link);
        }
    }
}
