<div class="hvac-brand-container">
    <div class="hvac-brand-button" *ngFor='let brand of authorizedBrands; trackBy: trackByCode'>
        <span>{{ brand.name }}</span>
        <button class="hvac-button" *ngIf="editCompanyInformation && brand.canEdit" hvacButton="default"
            (click)="removeBrand(brand)">
            <utc-svg class="hvac-brand-close-button" src="assets/icons/close.svg"></utc-svg>
        </button>
    </div>
</div>

<button class="hvac-add-brand-button" *ngIf="editCompanyInformation" (click)="showBrandlist()" hvacButton="default">
    <span>{{
        'ACCOUNT_ADMIN.ADD_BRANDS' | translate}}</span>
    <utc-svg [ngClass]="{ 'hvac-add-chevron-up': this.addBrands }" class="hvac-add-brand-icon"
        src="assets/icons/chevron-down.svg"></utc-svg>
</button>

<div *ngIf="this.addBrands && editCompanyInformation" class="hvac-user-brand-container">
    <div class="hvac-add-button-container">
        <utc-checkbox class="hvac-brand-checkbox" *ngFor='let brand of userBrandList; trackBy: trackBybrandCode' [checked]="brand.selected || false"
            [inputId]="brand.brandName" [value]="brand.brandName" (change)="addBrand($event,brand)"
            [inputName]="brand.brandName" [disabled]="(brand.selected && associatedBrandsCheck(brand.brandName))">
            <span>{{ brand.brandName }}</span>
        </utc-checkbox>
    </div>
</div>
