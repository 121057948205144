import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from 'common/components/base/base.component';
import { Option } from 'common/components/select-new/select-new.component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-report-chart-container',
    templateUrl: './report-chart-container.component.html',
    styleUrls: ['./report-chart-container.component.scss']
})
export class ReportChartContainerComponent extends BaseComponent implements OnInit {
    @Input() chartTitle: string;
    @Input() chartSubtitle: string;
    @Input() hasData: boolean;
    @Input() isLoading: boolean;
    @Input() yearSelectId: string;
    @Input() yearSelectOptions: Option[];
    @Input() yearSelectFormControl: UntypedFormControl;
    @Input() hasReportButton = true;
    @Input() isReportButtonDisabled: boolean;
    @Input() noDataMessage?: string;

    @Output() onYearSelectionChange = new EventEmitter<number>();
    @Output() onRunReport = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.yearSelectFormControl.valueChanges
            .pipe(
                takeUntil(this.ngOnDestroy$)
            ).subscribe((selectionValue) => {
                this.onYearSelectionChange.emit(selectionValue);
            });
    }

    handleRunReport() {
        if (this.yearSelectFormControl.value !== null) {
            this.onRunReport.emit();
        }
    }
}
