import { Injectable } from '@angular/core';
import { WallControlCommand } from 'private/app/models/connected-portal-system-diagnostics.model';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProductService } from './product.service';


@Injectable({ providedIn: 'root' })
export class ConnectedPortalContextService {
    public disconnectWallControlById$ = new Subject<{serialNo: string, dealerId: string}>();

    constructor(
        private productService: ProductService
    ) {
        this.disconnectWallControlById$.pipe(
            switchMap(({ serialNo, dealerId }) => this.productService.publishCommand(serialNo, dealerId, { command: WallControlCommand.DisconnectCommand }))
        ).subscribe();
    }
}
