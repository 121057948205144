import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from './company-query-configuration';

export const AccountAdminHvacCustomerConfig = {
    configType: CompanyCategoryType.HVAC_CUSTOMER,
    association: {
        associatedDistributor: true,
        associatedContact: true,
        associatedDealer: false
    },
    elightFeatures: true,
    distributorLocator: true,
    formConfig: {
        companyName: { edit: true },
        contactLic: {
            view: true,
            edit: false
        },
        language: {
            view: true,
            edit: true
        },
        hoursOfOperation: {
            view: true,
            edit: true
        },
        address1: {
            view: true,
            edit: true
        },
        address2: {
            view: true,
            edit: true
        },
        country: {
            view: true,
            edit: true
        },
        state: {
            view: true,
            edit: true
        },
        city: {
            view: true,
            edit: true
        },
        postalCode: {
            view: true,
            edit: true
        },
        phone: {
            view: true,
            edit: true
        },
        services: {
            view: false,
            edit: false
        },
        mobile: {
            view: true,
            edit: true
        },
        tollFree: {
            view: true,
            edit: true
        },
        fax: {
            view: true,
            edit: true
        },
        email: {
            view: true,
            edit: true
        },
        notes: {
            view: true,
            edit: true
        }

    },
    services: {}
} as AccountAdminDetailsEditConfiguration;
