import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AccountStatusService } from 'common/services/account-status.service';
import { BrandService } from 'common/services/brand.service';
import { AccountState } from 'common/store/create-account.state';
import { AccountInfoUserInfo, EmailVerificationStatus, UniqueAccountInfoStatus } from 'private/app/models/accountInfo';
import {
    ResetAccountUserInformation,
    ResetCompanyCodeInfo, ResetCompanyCodeQuery, ResetEmailVerification, ResetUniqueEmail, ResetUniqueUserName
} from 'private/app/store/create-account.actions';
import { CreateAccountInfoFormComponent } from 'private/app/components/create-account-info-form/createAccountInfoForm.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICPDealerRegistrationCode } from '../../store/create-account.actions';

@Component({
    selector: 'utc-create-account-information',
    templateUrl: './createAccountInformation.component.html',
    styleUrls: ['./createAccountInformation.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateAccountInformationComponent implements OnInit {
    @ViewChild(CreateAccountInfoFormComponent) accountInfoForm: CreateAccountInfoFormComponent;

    @Select(AccountState.accountInfo) accountInfo$: Observable<AccountInfoUserInfo>;
    @Select(AccountState.uniqueUserNameStatus) uniqueUserNameStatus: Observable<UniqueAccountInfoStatus>;
    @Select(AccountState.validAccountInfo) validAccountInfo$: Observable<boolean>;
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AccountState.emailVerificationCodeRequestStatus) verificationCodeStatus$: Observable<EmailVerificationStatus>;
    @Select(RouterState.state) routerState$: Observable<RouterStateSnapshot>;

    footerNumberOfSteps = 3;
    footerCurrentStep = 1;
    infoValid$ = new BehaviorSubject(false);
    isIcpDealerRegistrationRequest: boolean;

    private icpFooterNumberOfSteps = 2;

    constructor(
        private readonly store: Store,
        private route: ActivatedRoute,
        private readonly brandService: BrandService,
        private readonly statusService: AccountStatusService
    ) { }

    ngOnInit() {
        this.isIcpDealerRegistrationRequest = this.brandService.isICPBrand((this.route.snapshot.queryParamMap.get('brandref') === 'dayandnight' ? 'DAY & NIGHT' : this.route.snapshot.queryParamMap.get('brandref')) || '');
        if (this.isIcpDealerRegistrationRequest) {
            const code = this.route.snapshot.queryParamMap.get('icpDealerCode');

            if (code) {
                this.statusService.postCompanyId({
                    payload: code,
                    decrypt: true
                }).subscribe((res: { details: string}) => {
                    this.store.dispatch(new ICPDealerRegistrationCode({
                        brand: (this.route.snapshot.queryParamMap.get('brandref') === 'dayandnight' ? 'DAY & NIGHT' : this.route.snapshot.queryParamMap.get('brandref')) || '',
                        dealerCode: res.details || ''
                    }));

                    this.footerNumberOfSteps = this.icpFooterNumberOfSteps;
                });
            }
            else {
                this.store.dispatch(new ICPDealerRegistrationCode({
                    brand: (this.route.snapshot.queryParamMap.get('brandref') === 'dayandnight' ? 'DAY & NIGHT' : this.route.snapshot.queryParamMap.get('brandref')) || '',
                    dealerCode: ''
                }));
            }
        }
    }

    updateInfoValid(isValid: boolean) {
        this.infoValid$.next(isValid);
    }

    submitAccountInfo() {
        this.accountInfoForm.submitAccountInfo();
    }

    handleHeaderCancel() {
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetAccountUserInformation());
        this.store.dispatch(new ResetEmailVerification());
        this.store.dispatch(new ResetCompanyCodeQuery());
        this.store.dispatch(new ResetCompanyCodeInfo());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }

    handleHeaderLogo() {
        this.store.dispatch(new ResetUniqueUserName());
        this.store.dispatch(new ResetUniqueEmail());
        this.store.dispatch(new ResetEmailVerification());

        return this.store.dispatch(new Navigate(['login'], { skipAuth: true }));
    }
}
