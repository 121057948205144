<utc-modal [visible]="showModal" [theme]="'rounded'" [showHeader]="true" [size]="'medium'">
    <div>
        <h3 class="modal-header">{{"ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.MODAL_HEADER_FOR_PRIMARY_SOLD_TO_VALIDATION" | translate}}</h3>
        <div>{{ "ACCOUNT_ADMIN.CREATE_COMPANY_PAGE.MODAL_CONTENT_FOR_PRIMARY_SOLD_TO_VALIDATION" | translate: { comapny_id_nd_name: existingPrimaryDistributorDetails } }}</div>
        <hr>
        <div class="modal-controls">
            <button class="modal-control" (click)="onCancelClick()" [hvacButton]="'default'">
                <span>{{ "ACCOUNT_ADMIN.CANCEL" | translate }}</span>
            </button>
            <button [hvacButton]="'primary'" (click)="onContinueClick()">
                <span>{{ "ACCOUNT_ADMIN.CONTINUE" | translate }}</span>
            </button>
        </div>
    </div>
</utc-modal>
