import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/distributor-relationships.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { catchError, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { MISSING_ROUTE_PARAMS_ERROR, SEARCH_RESULTS_PAGE_SIZE } from '../../constants';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { ConnectedDealer } from 'private/app/models/connected-dealer.model';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { ErrorService } from 'common/content/services/error.service';

@Component({
    selector: 'hvac-dealer-linked-dealers',
    templateUrl: './dealer-linked-dealers.component.html',
    styleUrls: ['./dealer-linked-dealers.component.scss'],
    providers: [QueryParamsService]
})
export class DealerLinkedDealersComponent implements OnInit {
    public readonly CompanyCode = CompanyCode;
    public isLoading: boolean;
    public totalPages = 0;
    public currentPage = 1;
    public pageSize = SEARCH_RESULTS_PAGE_SIZE;
    public itemCount: number = 0;

    public isLoading$: Observable<boolean>;
    public breadcrumbs$: Observable<ContentHeadingData>;
    public companyCode$: Observable<CompanyCode>;
    public dealers$: Observable<ConnectedDealer[]>

    constructor(
        private queryParamsService: QueryParamsService,
        private route: ActivatedRoute,
        private connectedPortalUserService: ConnectedPortalUserService,
        private dealersService: DealersService,
        private translateService: TranslateService,
        private errorService: ErrorService
    ) {}

    ngOnInit(): void {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(404);

            return;
        }

        const routeData$ = this.route.parent.paramMap.pipe(
            map((paramMap) => {
                const dealerId = paramMap.get('id');

                if (dealerId) {
                    return { dealerId };
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            shareReplay(),
            catchError(() => {
                this.errorService.errorStatus$.next(404);

                return EMPTY;
            })
        );

        this.breadcrumbs$ = routeData$.pipe(
            switchMap(({ dealerId }) => this.dealersService.queryDealerById(dealerId).pipe(
                map(({ data }) => this.getDealerBreadcrumbData(dealerId, data.name)),
                startWith(this.getDealerBreadcrumbData(dealerId))
            ))
        );

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((companyCode) => companyCode as CompanyCode)
        );

        const primaryDealerId$ = this.connectedPortalUserService.getHVPID$();

        this.dealers$ = combineLatest([primaryDealerId$, this.queryParamsService.init({ page: this.currentPage })])
            .pipe(
                tap(() => {
                    this.isLoading = true;
                }),
                switchMap(([primaryDealerId, { page }]) => {
                    const nextPage = this.getNextPage(page);

                    const queryParams: SearchQueryParams = {
                        pagination: {
                            pageSize: this.pageSize,
                            currentPage: nextPage
                        }
                    };

                    return this.dealersService.queryLinkedDealersByDealerId(primaryDealerId, queryParams).pipe(
                        tap(({ totalCount, totalPages }) => {
                            this.totalPages = totalPages;
                            this.itemCount = totalCount;
                            this.currentPage = nextPage;

                            if (this.currentPage > this.totalPages) {
                                this.handlePageChange(1);
                            }
                        }),
                        map((res) => res.data)
                    );
                }),
                tap(() => {
                    this.isLoading = false;
                }),
                catchError(() => {
                    this.isLoading = false;

                    return of([]);
                })
            );
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    private getDealerBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.LINKED_DEALERS') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }
}
