<div class="hvac-zipcode-assignment-container">
    <div class="hvac-manage-dealer-zipcode-search-container">
        <p class="hvac-content-header-sub-title">
            <span>{{ ( showAssignedZipCode ? 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ASSIGNED_ZIP_CODES' : 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.AVAILABLE_ZIP_CODES' ) | translate  }}</span>
        </p>
        <div class="hvac-row">
            <button class="hvac-zipcode-button" [ngClass]="{ 'active': !showAssignedZipCode}" hvacButton="default" (click)="toggleTable('availableZipcodeTable')">
                <span>{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.AVAILABLE_ZIP_CODES' | translate }}</span>
                <span *ngIf="availableZipCodeArray.length" class="hvac-button-badge">{{ availableZipCodeArray.length }}</span>
            </button>
            <button class="hvac-zipcode-button" [ngClass]="{ 'active': showAssignedZipCode}" hvacButton="default" (click)="toggleTable('assignedZipcodeTable')">
                <span>{{ 'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ASSIGNED_ZIP_CODES' | translate }}</span>
                <span *ngIf="assignedZipCodeArray.length" class="hvac-button-badge">{{ assignedZipCodeArray.length }}</span>
            </button>
        </div>
    </div>
    <div class="hvac-manage-dealer-zipcode-table-container">
        <div class="hvac-manage-dealer-zipcode-table">
            <app-zipcode-table [tableTypeIdentifier]="showAssignedZipCode? assignedZipcodeTable : availableZipcodeTable"
                (onSelectAllClick)="onSelectAllCheckboxSelection($event)" (selectedZipCodeArray)="onSelectedZipCodes($event)"
                (onSave)="saveAction()" [tableType]="'MANAGE_DEALER.ZIPCODE_ASSIGNMENT.AVAILABLE_ZIP_CODES' | translate"
                [zipCodeArray]="(showAssignedZipCode ? assignedZipCodeArray : availableZipCodeArray )|| []"></app-zipcode-table>
        </div>
    </div>
</div>
<utc-loading-spinner  *ngIf="loading"></utc-loading-spinner>

