<div class="hvac-bulletins" *ngIf="parsedBulletins.length > 0">
    <div class="hvac-bulletins-title-container">
        <div class='hvac-bulletins-title-icon' ><utc-svg [src]="'assets/icons/wrench.svg'"></utc-svg></div>
        <h3 class="hvac-bulletins-title" [innerHTML]="title | richText"></h3>
    </div>
    <ul class="hvac-bulletins-list">
        <li class='hvac-bulletins-list-item' *ngFor='let bulletin of parsedBulletins'>
            <ng-container *ngIf="bulletin.link && bulletin.title">
                <a *ngIf="bulletin.target === '_self'; else externalLink" [routerLink]="[bulletin.link]" class="hvac-bulletins-link" [target]="bulletin.target">
                    <div class="hvac-bulletins-details">
                        <p class="hvac-bulletins-link-title" [innerHTML]="bulletin.title | richText"></p>
                    </div>
                </a>
                <ng-template #externalLink>
                    <a [href]="bulletin.link" class="hvac-bulletins-link" [target]="bulletin.target">
                        <div class="hvac-bulletins-details">
                            <p class="hvac-bulletins-link-title" [innerHTML]="bulletin.title | richText"></p>
                        </div>
                    </a>
                </ng-template>
            </ng-container>
        </li>
    </ul>
</div>

