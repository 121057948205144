<div class="hvac-cp-system-product-card">
    <div class="hvac-cp-system-product-image-container">
        <utc-image-fallback
            [suppliedImageSrc]="image"
            fallbackImageSrc="assets/images/no-image-icon.svg"></utc-image-fallback>
    </div>

    <div *ngIf="noDataMessage; else displayProductData" class="hvac-cp-system-product-no-data-block">
        <h4 class="hvac-cp-system-product-name hvac-h4" [innerHtml]="name"></h4>
        <ng-container *ngIf="showDescriptor">
            <dl class="hvac-system-product-descriptors">
                <ng-container  *ngFor="let descriptor of descriptors">
                    <hvac-product-descriptor *ngIf="descriptor.value" [label]="descriptor.label" [value]="descriptor.value" [infoLink]="descriptor.infoLink"></hvac-product-descriptor>
                </ng-container>
            </dl>
        </ng-container>
        <hvac-data-block class="hvac-cp-system-product-no-data-block-content" status="inactive">{{ noDataMessage }}</hvac-data-block>
    </div>

    <ng-template #displayProductData>
        <h4 class="hvac-cp-system-product-name hvac-h4" [ngClass]="{ 'hvac-cp-fault-indicator': showFaultIndicator }" [innerHtml]="name"></h4>
        <dl class="hvac-cp-system-product-descriptors">
            <ng-container  *ngFor="let descriptor of descriptors">
                <hvac-product-descriptor *ngIf="descriptor.value" [label]="descriptor.label" [value]="descriptor.value" [infoLink]="descriptor.infoLink"></hvac-product-descriptor>
            </ng-container>
        </dl>

        <a *ngIf="productLink && !isUpdateEnabled" class="hvac-cp-system-product-link" hvacButton="primary" size="small" [routerLink]="productLink.url" [queryParams]="productLink.queryParams">{{ productLink.title }}</a>
    </ng-template>

    <button *ngIf="isUpdateEnabled" class="hvac-cp-system-product-link" hvacButton="primary" size="small" (click)="updateData()">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.PRODUCT.UPDATE' | translate }}</button>
</div>