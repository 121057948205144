import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseContentEntity, ContentModelContent } from 'common/models';

const COMMERCIAL_ICON = 'commercial-buildings.svg';
const RESIDENTIAL_ICON = 'residential-building.svg';


@Component({
    selector: 'hvac-highlight-section',
    templateUrl: './highlight-section.component.html',
    styleUrls: ['./highlight-section.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HighlightSectionComponent implements OnInit {
    @Input() data: BaseContentEntity;
    @Input() fullWidth: boolean;
    @Input() serviceBulletins: BaseContentEntity;

    highlightIcon: string;
    mediaHighlights: ContentModelContent[];
    linkOnlyHighlights: BaseContentEntity[];

    ngOnInit() {
        const icon = this.data?.Content.title.toLowerCase().includes('residential') ? RESIDENTIAL_ICON : COMMERCIAL_ICON;
        this.highlightIcon = `assets/icons/${icon}`;

        this.mediaHighlights = this.data?.Content.list.$values.filter((item: {Content:{primaryMedia:string}}) => Boolean(item.Content.primaryMedia));

        this.linkOnlyHighlights = this.data?.Content.list.$values.filter((item: {Content:{primaryMedia:string}}) => !item.Content.primaryMedia);
    }
}
