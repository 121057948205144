import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ProductDetails, TrainingCourse } from 'common/models';
import { AuthState } from 'common/store/auth/auth.state';
import { ContentState } from 'common/store/content/content.state';
import { ProductsState } from 'common/store/products/products.state';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';

@Component({
    selector: 'utc-product-content-section-training',
    templateUrl: './training-courses.component.html',
    styleUrls: ['./training-courses.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TrainingCoursesComponent implements OnInit, OnDestroy {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(ContentState.contentBrand) brand$!: Observable<string>;
    @Select(AuthState.isLoggedIn) loggedIn$: Observable<boolean>;

    ngOnDestroy$ = new Subject();
    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    courses$: Observable<TrainingCourse[]> = this.attributes$.pipe(
        withLatestFrom(this.brand$),
        map(([attributes, siteBrand]) => {
            if (!attributes || !attributes.trainingCourses || !attributes.trainingCourses.length) {
                return [];
            }

            const siteBrandArray: string[] = (siteBrand === 'default') ? attributes.brandNames : [siteBrand];

            return attributes.trainingCourses.filter((course) => course.brands.some((brand) => siteBrandArray.includes(brand)));
        })
    );

    showTraining$ = this.courses$.pipe(
        map((courses) => Boolean(courses.length))
    );


    constructor(
        private readonly translate: TranslateService,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef
    ) { }

    ngOnInit() {
        this.showTraining$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.TRAINING'),
            order: 5,
            container: this.element.nativeElement
        };
    }
}
