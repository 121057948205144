import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { IdToken } from 'common/models';
import { OktaService } from 'common/services/okta/okta.service';
import { Observable, of } from 'rxjs';
import { skipWhile, map, catchError } from 'rxjs/operators';
import { PrivateAppRouteData } from '../app-routing.module';

@Injectable({ providedIn: 'root' })
export class IDMUserRolesResolver implements Resolve<string[]> {
    constructor(private oktaService: OktaService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<string[]> {
        const { accessRoles } = route.data as PrivateAppRouteData;

        return this.oktaService.idToken$.pipe(
            skipWhile((res) => Object.keys(res).length < 1),
            map((res: IdToken) => {
                const roles = res?.claims?.idm_user_roles;
                if (roles) {
                    const containAll = roles.filter((item) => ((accessRoles as string[]).indexOf(item) !== -1
                    ));

                    return containAll;
                }


                return roles || [];
            }),
            catchError(() => of([]))
        );
    }
}
