<div class="hvac-wall-control-zoning-details">
    <ng-container *ngIf="data.length; else noZones">
        <hvac-tab-bar variant="secondary">
            <button *ngFor="let tab of tabs; let i = index;" (click)="handleTabClick(i)" [ngClass]="{ 'hvac-active' : selectedTab === i }">{{ tab }}</button>
        </hvac-tab-bar>

        <ul class="hvac-cp-list" *ngIf="formattedData">
            <li *ngFor="let zone of formattedData; let i = index;" class="hvac-cp-list-item" [ngClass]="{ 'hvac-cp-list-item-visible': selectedTab === i }">
                <hvac-product-property-list>
                    <hvac-product-property-list-item *ngFor="let property of zone">
                        <hvac-product-property-list-item-label>{{ property.label }}</hvac-product-property-list-item-label>
                        <hvac-product-property-list-item-value>{{ property.value }}</hvac-product-property-list-item-value>
                    </hvac-product-property-list-item>
                </hvac-product-property-list>
            </li>
        </ul>
    </ng-container>

    <ng-template #noZones>
        <div class="hvac-wall-control-zoning-no-zones-message hvac-col-xs-12">
            <span class="hvac-h5">{{'CONNECTED_PORTAL.WALL_CONTROL.ZONING.NO_ZONES_DETECTED' | translate}}</span>
        </div>
    </ng-template>
</div>
