import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { enumKeyFromValue } from 'common/utils/data-transforms.util';
import { AlertSeverityFilter, AlertSeverity } from 'private/app/models/alert.model';
import { ReplaySubject, Subject } from 'rxjs';

export interface DealerDashboardQueryParams {
    page: number;
    filters?: Record<string, string>;
    parseParams?: Function;
    alertsSeverity: AlertSeverityFilter;
    scroll?: boolean;
}

@Injectable()
export class ConnectedPortalDealerDashboardQueryService implements OnDestroy {
    ngOnDestroy$ = new Subject();
    onParamsChange$ = new ReplaySubject<DealerDashboardQueryParams>();

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {}

    init(defaultParams: DealerDashboardQueryParams) {
        const queryParams = this.route.snapshot.queryParamMap;

        const { page, alertsSeverity } = this.parseParams(queryParams);

        if (this.isDefined(page) && this.isAlertSeverityFilter(alertsSeverity)) {
            return {
                page: page as number,
                alertsSeverity: alertsSeverity as AlertSeverityFilter
            };
        }

        const params = {
            page: (this.isDefined(page) ? page : defaultParams.page) as number,
            alertsSeverity: (this.isAlertSeverityFilter(alertsSeverity) ? alertsSeverity : defaultParams.alertsSeverity) as AlertSeverityFilter
        };

        this.updateParams(params);


        return params;
    }

    updateParams(params: Partial<DealerDashboardQueryParams>) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge'
        });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    private parseParams(params: ParamMap): Partial<DealerDashboardQueryParams> {
        const page = this.paramStringToInt(
            params.get('page')
        );

        const alertsSeverity = this.paramStringToAlertSeverity(
            params.get('alertsSeverity')
        );

        return {
            page,
            alertsSeverity
        };
    }

    private paramStringToInt(param: string | null): number | undefined {
        if (param) {
            const parsedInt = parseInt(param, 10);

            if (!isNaN(parsedInt)) {
                return parsedInt;
            }
        }

        return;
    }

    private paramStringToAlertSeverity(param: string | null): AlertSeverityFilter | undefined {
        if (param) {
            if (this.isAlertSeverityFilter(param)) {
                return param as AlertSeverityFilter;
            }
        }

        return;
    }

    private isAlertSeverityFilter(alertLevelFilter?: string): boolean {
        if (alertLevelFilter) {
            const isAlertLevel = enumKeyFromValue(AlertSeverity, alertLevelFilter);

            return Boolean(isAlertLevel || alertLevelFilter === 'All');
        }

        return false;
    }

    private isDefined(prop: unknown) {
        return typeof prop !== 'undefined';
    }
}
