import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppConstants } from 'common/app-constants';

@Injectable({ providedIn: 'root' })
export class DateUtilsService {
    constructor(
        public datePipe: DatePipe
    ) {
    }

    public formatDate(date: number | undefined) {
        return this.datePipe.transform(date, AppConstants.dateFormatMed) || '';
    }
}
