<div class="engineering-insights-filters-container">
    <div class="hvac-label hvac-subhead">
        {{ "CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.DATE_RANGE" | translate }}
    </div>
    <div>
        <hvac-date-range-picker
            dateType="datetime"
            [startDate]="filterInputs.startDate"
            [endDate]="filterInputs.endDate"
            [maxEndDate]="filterInputs.maxEndDate"
            (onSelection)="setDates({ startDate: $event.startDate, endDate: $event.endDate })">
        </hvac-date-range-picker>

        <div class="hvac-invalid-date-range" *ngIf="hasInvalidDates$ | async">
            <p class="hvac-input-error">{{ 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.DATE_SELECTION_EXCEEDED' | translate }}</p>
        </div>
    </div>

    <div class="hvac-row hvac-filter-container" *ngIf="isFilterOpen">
        <div class="hvac-col-xs-12 hvac-col-lg-6">
            <h4 class="hvac-h4">{{ 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.RELAY STATES' | translate }}</h4>

            <div class="hvac-row hvac-label hvac-subhead">
                <div class="hvac-col-xs-6">
                    {{ "CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.RELAY" | translate }}
                </div>
                <div class="hvac-col-xs-6">
                    {{ "CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.DESCRIPTION" | translate }}
                </div>
            </div>

            <div class="hvac-filter-list hvac-relay-list">
                <div class="hvac-row hvac-relay-item" *ngFor="let item of filterInputs?.relays; let i = index">
                    <div class="hvac-col-xs-6">
                        <utc-checkbox [checked]="item.isSelected" [disabled]="item.isDisabled" (change)="setRelayValue($event, i)">
                            <span>{{ item.label }}</span>
                        </utc-checkbox>
                    </div>
                    <div class="hvac-col-xs-6 hvac-relay-description">
                        {{ item.description }}
                    </div>
                </div>
            </div>
        </div>

        <div class="hvac-col-xs-12 hvac-col-lg-6">
            <div class="reset-params">
                <h4 class="hvac-h4">{{
                    'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.PARAMETERS' | translate }}
                </h4>
            </div>
            <div class="hvac-row hvac-label hvac-subhead">
                <div class="hvac-col-xs-6">
                    {{ "CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.PARAMETER" | translate
                    }}
                </div>
                <div class="hvac-col-xs-6">
                    {{ "CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.RANGE_FILTER" |
                    translate }}
                </div>
            </div>

            <div class="hvac-filter-list">
                <div class="hvac-row" *ngFor="let item of filterInputs?.parameters; let i = index">
                    <div class="hvac-col-xs-6 hvac-param-selection">
                        <utc-checkbox [checked]="item.isSelected"
                            [disabled]="item.isDisabled" (change)="setParamValue($event, i)">
                            <span>{{ item.label }}</span>
                        </utc-checkbox>
                    </div>
                    <div class="hvac-col-xs-6 hvac-param-min-max">
                        <hvac-number-stepper [minValue]="item.min" [maxValue]="item.max" [disabled]="item.isDisabled"
                            (minValueChange)="getUpdatedMinValue($event, i)"
                            (maxValueChange)="getUpdatedMaxValue($event, i)"></hvac-number-stepper>
                    </div>
                </div>
            </div>

            <div class="filter-button">
                <div>
                    <button hvacButton="text" size="small" (click)="resetFilters()">{{
                        'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.RESET' | translate }}</button>
                </div>

                <button hvacButton="default" size="small" (click)="applyFilters()" [disabled]="isApplyFiltersDisabled$ | async">{{
                    'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.FILTERS' | translate
                    }}</button>
            </div>
        </div>
    </div>
</div>
