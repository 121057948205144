import { Component, Input } from '@angular/core';

@Component({
    selector: 'hvac-factory-system-insights',
    templateUrl: './factory-system-insights.component.html',
    styleUrls: ['./factory-system-insights.component.scss']
})
export class FactorySystemInsightsComponent {
    @Input() systemType: string;
    @Input() systemId: string;
}
