<ng-template [ngIf]="(this.purchaseOrderService.activePurchaseOrder$ | async)" let-activePurchaseOrder>
    <utc-loading-spinner class="hvac-po-list-loader" *ngIf="(activePurchaseOrder | json) === '{}'"></utc-loading-spinner>
    <div *ngIf="(activePurchaseOrder | json) != '{}'">
        <div class="hvac-trade-partner-subhead">
            <h2 class="hvac-trade-partner-subhead-text">{{ 'TRADE_PARTNERS.ACKNOWLEDGE.DETAILS.PO_NUMBER' | translate }} {{ activePurchaseOrder.pONumber }}</h2>
        </div>
        <div class="hvac-trade-partner-order-detail">
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-4  hvac-col-lg-4">
                <dl class="hvac-trade-partner-account-details">
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.DETAILS.PO_DATE' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">{{ activePurchaseOrder.pOCreationDate | yearMoDate }}</dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.DETAILS.PAYMENT_TERMS' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">{{ activePurchaseOrder.paymentTerms }}</dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.DETAILS.PRB_FRT' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">{{ activePurchaseOrder.pONumber }}</dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.DETAILS.MARK_FOR_NOTES' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">{{ activePurchaseOrder.notes }}</dd>
                </dl>
            </div>
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-4 hvac-col-lg-4">
                <dl class="hvac-trade-partner-account-details">
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.SUPPLIER_INFO.NUMBER' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">{{ activePurchaseOrder.supplier }} </dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.SUPPLIER_INFO.ADDRESS' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">
                        <hvac-address
                            [name]="activePurchaseOrder.vendorAddressDetails[0].line1"
                            [line1]="activePurchaseOrder.vendorAddressDetails[0].line2"
                            [city]="activePurchaseOrder.vendorAddressDetails[0].city"
                            [state]="activePurchaseOrder.vendorAddressDetails[0].state"
                            [zipCode]="activePurchaseOrder.vendorAddressDetails[0].zipCode"
                        ></hvac-address>
                    </dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.SUPPLIER_INFO.SHIP_TO_NO' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value"> {{ activePurchaseOrder.shipToAddressDetails[0].shipToAddressNumber }} </dd>
                    <dt class="hvac-trade-partner-def-list-label">{{ 'TRADE_PARTNERS.PO_DETAIL.SUPPLIER_INFO.SHIP_TO' | translate }}</dt>
                    <dd class="hvac-trade-partner-def-list-value">
                        <hvac-address
                            [name]="activePurchaseOrder.shipToAddressDetails[0].line1"
                            [line1]="activePurchaseOrder.shipToAddressDetails[0].line2"
                            [line2]="activePurchaseOrder.shipToAddressDetails[0].line3"
                            [line3]="activePurchaseOrder.shipToAddressDetails[0].line4"
                            [city]="activePurchaseOrder.shipToAddressDetails[0].city"
                            [state]="activePurchaseOrder.shipToAddressDetails[0].state"
                        ></hvac-address>
                    </dd>
                </dl>
            </div>
            <div class="hvac-col-xs-12 hvac-col-sm-12 hvac-col-md-4 hvac-col-lg-4 hvac-trade-partners-detail-logo">
                <hvac-address
                    [name]="'TRADE_PARTNERS.PO_DETAIL.TOTALINE_CONTACT.NAME' | translate"
                    [line1]="'TRADE_PARTNERS.PO_DETAIL.TOTALINE_CONTACT.LINE1' | translate"
                    [city]="'TRADE_PARTNERS.PO_DETAIL.TOTALINE_CONTACT.CITY' | translate"
                    [state]="'TRADE_PARTNERS.PO_DETAIL.TOTALINE_CONTACT.STATE' | translate"
                    [zipCode]="'TRADE_PARTNERS.PO_DETAIL.TOTALINE_CONTACT.ZIP' | translate"
                ></hvac-address>
                <a href="http://www.carrier.com" class="hvac-h6 hvac-text-link" alt="">www.carrier.com</a>
            </div>
        </div>
        <div class="hvac-rhythm-10">
            <hvac-table [responsive]="true" theme="zebra" [ngClass]="{'hvac-rhythm-3': activePurchaseOrder.pOstatus === PoStatus.CHANGED}">
                <thead>
                    <tr>
                        <td>
                            {{ TableColumnHeaders + 'ITEM' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'QTY' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'UNITS' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'PRODUCT_NO' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'SUPPLIER_PART_NUM' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'PRODUCT_DESCRIPTION' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'HARM_CODE' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'PRICE' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'REQ_DATE' | translate }}
                        </td>
                        <td>
                            {{ TableColumnHeaders + 'AMOUNT' | translate }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let poOrderItem of activePurchaseOrder.lines">
                        <td [attr.data-label]="TableColumnHeaders + 'ITEM' | translate">{{ poOrderItem.pOLineNumber }}</td>
                        <td [ngClass]="{'hvac-po-error-field': activePurchaseOrder.pOstatus === PoStatus.CHANGED}" [attr.data-label]="TableColumnHeaders + 'QTY' | translate">{{ poOrderItem.pOOrderQuantity }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'UNITS' | translate">{{ poOrderItem.unitOfMeasure }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'PRODUCT_NO' | translate">{{ poOrderItem.sapMaterialNumber }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'SUPPLIER_PART_NUM' | translate">{{ poOrderItem.supplierPartNumber }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'PRODUCT_DESCRIPTION' | translate">{{ poOrderItem.itemDescription }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'HARM_CODE' | translate">{{ poOrderItem.harmonizedCode }}</td>
                        <td [ngClass]="{'hvac-po-error-field': activePurchaseOrder.pOstatus === PoStatus.CHANGED}" [attr.data-label]="TableColumnHeaders + 'PRICE' | translate">{{ poOrderItem.unitCost }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'REQ_DATE' | translate">{{ poOrderItem.requestedShipDate | yearMoDate }}</td>
                        <td [attr.data-label]="TableColumnHeaders + 'AMOUNT' | translate">{{ poOrderItem.extendedCost }}</td>
                    </tr>

                </tbody>
            </hvac-table>
            <p *ngIf="activePurchaseOrder.pOstatus === PoStatus.CHANGED" class="hvac-trade-partners-asn-error">
                <utc-svg class="hvac-trade-partners-asn-error-icon" src="assets/icons/info.svg"></utc-svg>
                {{ 'TRADE_PARTNERS.PO_DETAIL.CHANGED_NOTIFICATION' | translate }}</p>
        </div>
        <div class="hvac-row hvac-end-xs hvac-rhythm-2 hvac-purchase-order-detail-total">
            <div class="hvac-col-xs">
                <h2 class="hvac-h2">{{ 'TRADE_PARTNERS.PO_DETAIL.TOTAL' | translate }}</h2>
                <h3 class="hvac-h3">{{ activePurchaseOrder.totalAmount}}</h3>
            </div>
        </div>
        <div class="hvac-row hvac-center-xs hvac-end-md hvac-rhythm-2">
            <div class="hvac-col-xs-12 hvac-col-md-6">
                <a routerLink="confirm" class="hvac-purchase-order-detail-submit" hvacButton="primary">{{ 'TRADE_PARTNERS.PO_DETAIL.CREATE' | translate }}</a>
            </div>
        </div>
        <div class="hvac-row hvac-center-xs hvac-end-md  hvac-rhythm-5">
            <div class="hvac-col-xs-12 hvac-col-md-6">
                <a target='_blank' class="hvac-text-link hvac-purchase-order-detail-terms" href="https://files.hvacpartners.com/docs/1006/ValCust/08/TradepartnersTC1.pdf">{{ 'TRADE_PARTNERS.PO_DETAIL.TERMS_AND_CONDITIONS' | translate }}</a>
            </div>
        </div>
    </div>
</ng-template>
