import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'common/app-constants';
import { Option } from 'common/components/select/select.component';
import { BrandFamily } from 'common/models/brand.model';
import { ToastService } from 'common/services/toast.service';
import { BrandOverwriteType } from 'common/services/user.service';
import { EmailInputErrorObject } from 'common/utils/createAccountValidators';
import { AsyncErrorObject, DealerOwner, DistributorOwner, EnrollmentForm, EnrollmentFormData, EnrollmentFormDetails, EnrollmentFormField, EnrollmentFormFieldRules } from 'private/app/models/account-admin-enrollment.model';
import { DealerExpectedYear, YearEstimatedPurchases } from 'private/app/views/program/enrollment-form/enrollment-form.component';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { InputErrorObject, inputValidator } from '../account-admin-edit-form.service';
import { EnrollmentApiService } from './enrollment-api.service';

export enum EnrollmentFOrmControls {
    BRAND_SOLD_PERCENTAGE = 'BrandSoldPercentage'
}
@Injectable({ providedIn: 'root' })
export class EnrollmentFormService {
    public totalSplitOfBusiness$ = new BehaviorSubject<boolean>(true);
    public percentOfPriorYearSales$ = new BehaviorSubject<boolean>(true);
    public newLabel : string = '';

    brandFamily: BrandOverwriteType;
    enrollmentFormDetails: EnrollmentFormDetails;

    public distributors$ = new BehaviorSubject<DistributorOwner[]>([]);
    constructor(private formBuilder: UntypedFormBuilder,
        public translate: TranslateService,
        private readonly toastService: ToastService,
        private readonly enrollmentApiService: EnrollmentApiService) {
    }

    getDistributorData(): Observable<DistributorOwner[]> {
        return this.distributors$;
    }

    removeYearSale(enrollmentFormGroup: UntypedFormGroup, salesInfoCommon: EnrollmentFormField[], name: string) {
        if (salesInfoCommon.some((el) => el.name === name)) {
            const index = salesInfoCommon.findIndex((saleInfo) => saleInfo.name === name);
            salesInfoCommon.splice(index, 1);
            (enrollmentFormGroup.get('salesInfoCommon') as UntypedFormGroup).removeControl(name);
        }
    }

    addYearSale(value: string | undefined, enrollmentFormGroup: UntypedFormGroup, salesInfoCommon: EnrollmentFormField[], name: string) {
        if (!salesInfoCommon.some((el) => el.name === name)) {
            if (name === DealerExpectedYear.YEAR_TWO) {
                this.newLabel = DealerExpectedYear.YEAR_TWO_PERCENTAGE;
            }
            else if (name === DealerExpectedYear.YEAR_THREE) {
                this.newLabel = DealerExpectedYear.YEAR_THREE_PERCENTAGE;
            }
            else {
                this.newLabel = name;
            }
            (enrollmentFormGroup.get('salesInfoCommon') as UntypedFormGroup).addControl(name, this.formBuilder.control(value || '', Validators.required));
            salesInfoCommon.push({
                type: 'select',
                name: name,
                label: `${this.newLabel} %`,
                value: value || '1',
                rules: { required: true },
                options: this.buildOptionsArray(name)

            });
        }
    }

    addYearPurchase(enrollmentFormGroup: UntypedFormGroup, salesInformation: EnrollmentFormField[], name: string) {
        if (!salesInformation.some((el) => el.name === name)) {
            if (name === YearEstimatedPurchases.SECOND_YEAR_ESTIMATED_PURCHASES) {
                this.newLabel = YearEstimatedPurchases.ESTIMATED_YEAR_2_PURCHASES;
            }
            else if (name === YearEstimatedPurchases.THIRD_YEAR_ESTIMATED_PURCHASES) {
                this.newLabel = YearEstimatedPurchases.ESTIMATED_YEAR_3_PURCHASES;
            }
            else {
                this.newLabel = name;
            }
            salesInformation.push({
                type: 'text',
                name: name,
                label: `${this.newLabel}`,
                value: '1',
                rules: {
                    required: true,
                    number: true,
                    maxLimit: 11
                }
            });
            (enrollmentFormGroup.get('salesInformation') as UntypedFormGroup).addControl(name, this.formBuilder.control('', this.addValidator({
                required: true,
                number: true,
                maxLimit: 11
            })));
        }
    }

    removeYearPurchase(enrollmentFormGroup: UntypedFormGroup, salesInformation: EnrollmentFormField[], name: string) {
        if (salesInformation.some((el) => el.name === name)) {
            const index = salesInformation.findIndex((saleInfo) => saleInfo.name === name);
            salesInformation.splice(index, 1);
            (enrollmentFormGroup.get('salesInformation') as UntypedFormGroup).removeControl(name);
        }
    }

    addValidator(rules: EnrollmentFormFieldRules) {
        if (!rules) {
            return [];
        }

        const validators = Object.keys(rules).map((rule) => {
            switch (rule) {
                case 'required':
                    return rules.required ? Validators.required : Validators.nullValidator;
                case 'email':
                    return this.emailInputValidator(100, false, AppConstants.validEmailRegex);
                case 'number':
                    return Validators.pattern(AppConstants.validNumberRegex);
                case 'maxLimit':
                    return inputValidator(rules.maxLimit || 0, false);
                case 'maxValue':
                    return this.inputValidatorWithMaxLimit(rules.maxValue || null, rules.required || false);
                default:
                    return Validators.nullValidator;
            }
        });

        return validators;
    }

    addAsyncValidator(rules: EnrollmentFormFieldRules, controlName: string): AsyncValidatorFn | AsyncValidatorFn[] | null | undefined {
        if (!rules) {
            return [];
        }

        return this.checkIfSumOfHundred(rules, controlName);
    }

    getEnrollmentFormDetails(companyId: string, programId: string, brandFamily: BrandOverwriteType) {
        this.brandFamily = brandFamily;

        return this.enrollmentApiService.getEnrollmentFormInfo(companyId, programId).pipe(
            map((res) => {
                this.enrollmentFormDetails = res;

                return this.enrollmentApiService.mapEnrollmentResponse(res);
            })
        );
    }


    getEnrollmentFormManagers(companyId: string, programId: string, enrollmentForm: EnrollmentForm, data: EnrollmentFormData) {
        return forkJoin([
            this.enrollmentApiService.getDealerOwners(companyId),
            this.enrollmentApiService.getDistributorOwners({
                companyId: companyId,
                programId: programId,
                brandFamily: this.getBrandFamily()
            })
        ]).pipe(
            map((res: [DealerOwner, DistributorOwner[]]) => {
                this.distributors$.next(res[1]);

                return this.enrollmentApiService.prepareEnrollmentFormManagers(res, enrollmentForm, data);
            })
        );
    }

    toastMessage(content: string, theme: 'success' | 'error') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.toastService.add({
            content: content,
            id: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_TOAST_MESSAGE_ID'),
            theme: theme,
            closeable: true,
            autoClose: true
        });
    }

    private getBrandFamily() {
        switch (this.brandFamily as BrandOverwriteType) {
            case 'car,bry,pay':
                return BrandFamily.CBP;
            case 'icp':
                return BrandFamily.ICP;

            default:
                return BrandFamily.GENERIC;
        }
    }


    private inputValidatorWithMaxLimit(maxLimit: number | null, isRequired: boolean): ValidatorFn {
        return (control: AbstractControl): InputErrorObject | null => {
            const errorObject: InputErrorObject = {};
            const controlValue = control.value;
            if (typeof controlValue !== 'undefined' && controlValue !== null) {
                if (isRequired && controlValue.trim() === '') {
                    errorObject.REQUIRED = true;
                }
                if (maxLimit && controlValue > maxLimit) {
                    errorObject.LENGTH = true;
                }
                if (Object.keys(errorObject).length !== 0) {
                    return errorObject;
                }
            }

            return null;
        };
    }

    private emailInputValidator(maxLength: number, isRequired: boolean, patternRegex: RegExp): ValidatorFn {
        return (control: AbstractControl): EmailInputErrorObject | null => {
            const errorObject: EmailInputErrorObject = {};
            const controlValue = control.value;
            if (typeof controlValue !== 'undefined' && controlValue !== null) {
                if (isRequired) {
                    errorObject.REQUIRED = true;
                }
                if (maxLength && controlValue.length >= maxLength) {
                    errorObject.LENGTH = true;
                }
                if (controlValue.length > 0 && patternRegex && !patternRegex.test(controlValue)) {
                    errorObject.EMAIL_FORMAT = true;
                }
                if (Object.keys(errorObject).length !== 0) {
                    return errorObject;
                }
            }

            return null;
        };
    }

    private buildOptionsArray(parentDropdownSelection: string): Option[] {
        return new Array(parentDropdownSelection === DealerExpectedYear.YEAR_TWO ? 7 : 5).fill(0)
            .map((_num, index) => {
                index + 1;

                return {
                    value: `${index}`,
                    name: `${index}`
                };
            });
    }

    private checkIfSumOfHundred(rules: EnrollmentFormFieldRules, controlName: string): AsyncValidatorFn {
        return (control: AbstractControl): Observable<AsyncErrorObject | null> => of(control.value).pipe(
            delay(AppConstants.debounceTime200),
            map(() => {
                const errorObject: AsyncErrorObject = {};
                const controlValue = control.value;
                if (typeof controlValue !== 'undefined') {
                    if (controlValue.trim() === '') {
                        errorObject.REQUIRED = rules.required;
                    }
                    if (controlValue.length > Number(rules.maxLimit)) {
                        errorObject.LENGTH = true;
                    }
                    if (controlName.includes(EnrollmentFOrmControls.BRAND_SOLD_PERCENTAGE) && !this.percentOfPriorYearSales$.value) {
                        errorObject.SUM_OF_HUNDRED = true;
                    }
                    if (controlName.length === 0 && !this.totalSplitOfBusiness$.value) {
                        errorObject.SUM_OF_HUNDRED = true;
                    }
                    if (Object.keys(errorObject).length !== 0) {
                        return errorObject;
                    }
                }

                return null;
            })
        );
    }
}
