import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Company, ContactsResponse, DataHeader, User } from '../../../models/account-admin-search';
import { AccountAdminExportService } from '../../../services/account-admin/account-admin-export.service';
import { AccountAdminService } from '../../../services/account-admin/account-admin.service';
import { AccountPostAdminService } from 'private/app/services/account-admin/account-post-admin.service';
import { ToastService } from 'common/services/toast.service';
@Component({
    selector: 'hvac-users-table',
    templateUrl: './users-table.component.html',
    styleUrls: ['./users-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersTableComponent implements OnInit {
    contacts?: ContactsResponse[];
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('ACCOUNT_ADMIN.CONTACT'),
            value: 'firstName',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.COMPANY'),
            value: 'companyId',
            order: null
        },
        {
            title: this.translate.instant('ACCOUNT_ADMIN.ACCOUNT'),
            value: 'soldToCustomerId',
            order: null
        }
    ];

    loading: boolean;
    pagination: number;
    totalCount: number;
    totalPages: number;

    private pageSize = 10;

    constructor(
        private accountAdminService: AccountAdminService,
        public accountPostAdminService: AccountPostAdminService,
        public accountAdminExportService: AccountAdminExportService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        @Inject(DOCUMENT) private doc: Document,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.accountAdminService.currentTab = 'contacts';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.route.queryParamMap.subscribe((value: any) => {
            if (value.params.contactsPagination) {
                this.pagination = parseInt(value.params.contactsPagination, 10);
            }
            else {
                this.pagination = 1;
            }

            this.dataHeaders = this.accountAdminService.updateDataHeaders(value.params.contactsSortFields, this.dataHeaders);

            const sortParams = value.params.contactsSortFields || [];

            this.postContactsSearch(sortParams, value.params.contactsPagination);
        }, (error: HttpErrorResponse) => this.toastService.raiseDefaultErrorToast('UsersTableComponent-ngOnInit', error.message));
    }

    public createDetails(contact: ContactsResponse): string {
        return [
            contact.userPhone ? `${this.translate.instant('ACCOUNT_ADMIN.PHONE')}: ${contact.userPhone}` : null,
            contact.userFax ? `${this.translate.instant('ACCOUNT_ADMIN.FAX')}: ${contact.userFax}` : null,
            contact.email ? `${this.translate.instant('ACCOUNT_ADMIN.EMAIL')}: ${contact.email}` : null
        ].filter(Boolean).join(', ');
    }

    public findDefaultCompany(contact: User): Company | undefined {
        return contact.companies?.find((company: Company) => company.id === contact.defaultCompanyId);
    }

    public handlePageChange(event: number) {
        const queryParams: Params = { contactsPagination: event };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );

        this.pagination = event;
    }

    public sort(item: DataHeader) {
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort) {
            return;
        }

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }

        const queryParams: Params = { contactsSortFields: currentSort.order ? `${currentSort.value}-${currentSort.order}` : null };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge'
            }
        );
    }

    public postCompanySearchExport(results: number) {
        if (results >= 1) {
            this.loading = true;

            this.accountPostAdminService.postCompanySearchExport(
                this.accountAdminService.createSearchBody(
                    this.accountAdminService.advancedSearchForm.value,
                    null,
                    null,
                    'userCriteria'
                )
            ).subscribe((response: HttpResponse<string>) => {
                this.loading = false;

                this.accountAdminExportService.doExport(response);
            });
        }
    }

    public onRowClick(contactId: string) {
        if (!this.doc.getSelection()?.toString()) {
            this.router.navigate(['../../contact/', contactId], { relativeTo: this.route });
        }
    }

    private postContactsSearch(sortParams: string, pagination: number = 1) {
        this.loading = true;

        this.accountPostAdminService.postContactsSearch(
            this.accountAdminService.createSearchBody(
                this.accountAdminService.advancedSearchForm.value,
                pagination,
                sortParams,
                'userCriteria'
            )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).subscribe((res: any) => {
            this.loading = false;

            this.contacts = res?.data;
            this.totalCount = (res?.totalCount === 0) ? this.totalCount : res?.totalCount;
            this.totalPages = Math.ceil(this.totalCount / this.pageSize);
        });
    }
}
