/* eslint-disable no-use-before-define */
export interface AcceleratedSignUpPostBody {
    hvacCompanyId: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
}

export interface AccountStateModel {
    accountInfo: AccountInfoUserInfo;
    validAccountInfo: boolean;
    createAccountStatus: CreateAccountStatus;
    companyCode: {
        companyCodeQuery: string,
        companyCodeInfo: CompanyDetailInfo,
        companyCodeErrors: InvalidCompanyDetailInfo
    };
    businessLocator: {
        zipCodeQuery: string;
        zipCodeSearchStatusPending: boolean
        results: Array<CompanyDetailInfo> | null;
    };
    userName: {
        uniqueUserNameStatus: UniqueAccountInfoStatus;
        userNameQuery: string;
    };
    email: {
        uniqueEmailStatus: UniqueAccountInfoStatus;
        emailQuery: string;
    };
    emailVerification: {
        emailVerificationStatus: EmailVerificationStatus
    };
    apiRequestActive: boolean;

    passwordResetStatus: ResetPasswordStatus;
    icpDealerRegistration: ICPDealerRegistrationDetails;
    primaryDistributorId?: string;
}

export interface AccountInfoUserInfo {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    username: string;
    password: string;
}

export interface AccountInfoPostData {
    hvacCompanyId: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    primaryDistributorId?: string;
}
export interface EmailVerificationCodePostData {
    userId: string;
    email: string;
}

export interface SubmitVerificationCodePostData {
    userId: string;
    email: string;
    code: string;
}

export interface AssociatedDistributorPostData {
    criteria: {
        companyId: string;
        companyTypeCode: string;
        getAssociatedBrands: boolean;
        postalCode: string;
    };
    determineTotalCount: boolean;
    pageParams: {
        page: number;
        pageSize: number
    };
}

export interface RequestPasswordResetPutData {
    oldPassword: string;
    newPassword: string;
}

export interface ResponsePasswordReset {
    message: string;
    status: string;
    details: string;
    errorCodes: string;
}
export interface ResponseManageSubscription {
    details: string;
    message: string;
    status: string;
}

export interface BrandObject {
    brandName: string;
    description?: string;
    parentBrand?: string;
    catalogValue?: string;
    brandFamily?: string;
}
export interface InvalidCompanyDetailInfo {
    isInvalid: boolean;
    serverDown: boolean;
}

export interface CompanyDetailInfo {
    companyId: string;
    companyName: string;
    address1: string;
    city: string;
    state: string;
    country: string;
    companyTypeCode: string;
    companyTypeName: string;
    postalCode: string;
    isActive: number;
    brandfamily: string;
    brands: Array<BrandObject>;
    address2?: string;
    phone?: string;
    email?: string;
    leagacyId?: number;
    legacyCompanyTypeCode?: string;
    legacyCompanyTypeName?: string;
    categoryCode?: string;
    displayName?: string;
    categoryName?: string;
    isSoldTo?: string;
    services?: Array<{
        serviceType: string;
        description: string;
    }>;
    primaryDistributorId?: string;
}

export interface ZipCodeResult {
    country: string;
    brands: Array<BrandObject>;
    city: string;
    HVACCompanyID: number;
    companyName: string;
    postalCode: string;
    isActive: boolean;
    companyCategoryName: string;
    companyId: number;
    companyAddress: string;
    companyCategoryCode: string;
    state: string;
    companyTypeCode: string;
    brandfamily: string;
    companyTypeName: string;
}

export interface AssociatedDistributorResult {
    data: AssociatedDistributor[];
    totalCount: number;
}

export interface AssociatedDistributor {
    id: string;
    companyType: {
        code: string;
        name: string;
    }
    legalEntity: boolean;
    active: boolean;
    name: string,
    emailAddress: string;
    address: Address,
    includeInDistributorLocator: boolean;
    companyCategory: {
        code: string;
        name: string;
    }
    zipCode: string;
    associatedBrands: string[]
}

export interface Address {
    addressLine1: string;
    city: string;
    regionCode: string;
    postalCode: string;
    countryCode: string;
}


export interface ZipCodeSearchResponse {
    CompanyDetails: Array<ZipCodeResult>;
}

export interface ICPDealerRegistrationDetails {
    brand: string;
    dealerCode: string
}

export enum UniqueAccountInfoStatus {
    Unique = 'Unique',
    NotUnique = 'Not Unique',
    Pending = 'Pending',
    NotChecked = 'Not Checked',
}

export enum CreateAccountStatus {
    Success = 'Success',
    Error = 'Error',
    Pending = 'Pending',
    NotCreated = 'Not Created',
}

export enum EmailVerificationStatus {
    Success = 'Success',
    Error = 'Error',
    Requested = 'Requested',
    Pending = 'Pending',
    NotVerified = 'NotVerified',
    Forbidden = 'Forbidden',
}

export enum ResetPasswordStatus {
    Success = 'Success',
    Error = 'Error',
    Requested = 'Requested',
    Pending = 'Pending',
    Forbidden = 'Forbidden',
}

export enum ManageSubscriptionStatus {
    Success = 'Success',
    Error = 'Error',
}
