import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hvac-distributor-navigation',
    templateUrl: './distributor-navigation.component.html',
    styleUrls: ['./distributor-navigation.component.scss']
})
export class DistributorNavigationComponent {
    constructor(private router: Router) { }

    goToDistributorDashboard() {
        this.router.navigate(['/connected-portal']);
    }
}
