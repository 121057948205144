<div class="hvac-connected-system-faults">
    <h2 class="hvac-h3 hvac-connected-system-faults-title">{{ 'CONNECTED_PORTAL.SYSTEM_FAULTS.TITLE' | translate}}
    </h2>
    <hvac-toast [name]="toastOutlet"></hvac-toast>

    <div class="hvac-connected-system-faults-wrapper">
        <div class="hvac-faults-legends-container">
            <div class="hvac-faults-legend" *ngFor="let faultSeverity of ConnectedSystemFaultSeverity | keyvalue : originalSeverityOrder">
                <span class="hvac-faults-legend-icon"
                    [ngClass]="{'hvac-faults-critical': faultSeverity.value === ConnectedSystemFaultSeverity.CRITICAL,
                                'hvac-faults-moderate': faultSeverity.value === ConnectedSystemFaultSeverity.MODERATE,
                                'hvac-faults-info': faultSeverity.value === ConnectedSystemFaultSeverity.INFORMATIONAL}"></span>
                <span class="hvac-faults-legend-name hvac-p-sm">{{faultSeverity.key}}</span>
            </div>
        </div>

        <hvac-factory-systems-faults-list
            [faults]="systemFaults"
            [isLoading]="isLoading"
            [totalPages]="faultsListPageTotal"
            [currentPage]="faultsCurrentPage"
            (onPageChange)="handleFaultsListPageChange($event)">
        </hvac-factory-systems-faults-list>
    </div>
</div>
