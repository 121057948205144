<div class="hvac-connected-portal-alerts">
    <h2 class="hvac-h3 hvac-connected-portal-alerts-title">{{ 'CONNECTED_PORTAL.ALERTS.CONTROL_ALERTS' | translate}}
    </h2>
    <hvac-toast [name]="toastOutlet"></hvac-toast>
    <div class="hvac-connected-portal-alerts-controls">
        <hvac-connected-portal-alerts-options [alertsStartDate]="alertsStartDate" [alertsEndDate]="alertsEndDate"
            (onAlertsDateRangeChange)="handleDateRangeChange($event)"></hvac-connected-portal-alerts-options>
        <hvac-connected-portal-alerts-filters [alertLevelFilterSelection]="alertLevelFilterSelection"
            (onAlertsFilterChange)="updateAlertLevelFilter($event)"></hvac-connected-portal-alerts-filters>
    </div>

    <hvac-connected-portal-alerts-list
        [alerts]="alerts"
        [isLoading]="isLoading"
        [totalPages]="alertsListTotalPages"
        [currentPage]="alertsListCurrentPage"
        (onPageChange)="handleAlertsListPageChange($event)"
        (onRemoveSelected)="handleAlertsListRemoveSelected()"
        (onExportAlerts)="handleExportAlerts()"
    ></hvac-connected-portal-alerts-list>
</div>
