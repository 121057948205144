import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountState } from '../../../common/store/create-account.state';
import { ResetAccountUserInformation } from '../store/create-account.actions';

@Injectable({ providedIn: 'root' })
export class CreateAccount implements CanActivate {
    @Select(AccountState.validAccountInfo) validAccountInfo$: Observable<boolean>;

    constructor(private readonly store: Store) { }

    canActivate() {
        return this.validAccountInfo$.pipe(map((isValid: boolean) => {
            if (!isValid) {
                this.store.dispatch(new ResetAccountUserInformation());
                this.store.dispatch(new Navigate(['/create-account-information']));

                return false;
            }

            return true;
        }),
        catchError(() => {
            this.store.dispatch(new Navigate(['/create-account-information']));

            return of(false);
        }));
    }
}
