import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { buildHttpParams } from 'common/api/utils';
import { SiteMap } from 'common/content/services/error.service';
import { environment } from 'common/environments/environment';
import { UIContentResponse } from 'common/models';
import { ClearUIPageContent } from 'common/store/content/content.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BaseContentEntity, ContentRegion, UIContentDataRegions } from '../models/content';
import { ApiOptionsService } from './api-options/api-options.service';
import { BrandService } from './brand.service';

@Injectable({ providedIn: 'root' })
export class ContentService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentResponse$: BehaviorSubject<any> = new BehaviorSubject({});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public siteMap$: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private readonly store: Store,
        private readonly brandService: BrandService
    ) {
    }

    public getSiteMap(): Observable<SiteMap[]> {
        return this.httpClient.get<SiteMap[]>(environment.api.siteMap);
    }

    fetchContentPage<T>(page: string): Observable<T> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = this.buildParams();

        if (page === '/parts-literature' || page === '/parts-media' || page === '/parts-video') {
            return options$.pipe(
                switchMap(
                    (options) => this.httpClient.get<T>(
                        `${environment.api.totalineContent}/en/us${page}`, {
                            params,
                            ...options
                        }
                    )
                ),
                catchError((err: HttpErrorResponse) => {
                    this.store.dispatch(new ClearUIPageContent());
                    throw err;
                })
            );
        }

        if (environment.brand === 'private' && page && this.isPageAvailableInSitemap(page.split('?')[0])) {
            return options$.pipe(
                switchMap(
                    (options) => this.httpClient.get<T>(
                        `${environment.api.publicContent}/en/us${page}`, {
                            params,
                            ...options
                        }
                    )
                ),
                catchError((err: HttpErrorResponse) => {
                    this.store.dispatch(new ClearUIPageContent());
                    throw err;
                })
            );
        }

        return options$.pipe(
            switchMap(
                (options) => this.httpClient.get<T>(
                    // `${environment.api.content}/en/us${page}`, {
                    `http://10.181.24.76:8080/hvacSSOTest/pages/682/en/us/ui.html`, {
                        params,
                        ...options
                    }
                )
            ),
            catchError((err: HttpErrorResponse) => {
                this.store.dispatch(new ClearUIPageContent());
                throw err;
            })
        );
    }

    fetchUiContent(): Observable<UIContentResponse> {
        return this.fetchContentPage<UIContentResponse>('/ui.html');
    }

    buildParams() {
        if (this.brandService.isICPBrand(environment.brand)) {
            return buildHttpParams({ brand: `${environment.brand}` });
        }

        return;
    }

    // Maps API reponse
    // Can/Should be expanded to add more information as needed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public mapContentApiResponse(data: UIContentResponse): any {
        return {
            regions: data?.Regions?.map((region: ContentRegion) => ({
                name: region.Name,
                entities: region?.Entities?.map((entity: BaseContentEntity) => ({
                    content: {
                        title: entity?.Content?.title || null,
                        list: this.mapLists(entity?.Content?.list)
                    }
                })) || null
            })).filter(Boolean)
        };
    }

    public featuresList(data: UIContentResponse) {
        const { regions } = this.mapContentApiResponse(data);
        const main = regions?.find((region: UIContentDataRegions) => region.name === 'Main');
        const featureList = main?.entities?.find((entity: {content: {title: string}}) => entity.content.title === 'Features List');

        return featureList?.content?.list;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private mapLists(list: any): any[] | null {
        if (!list) {
            return null;
        }

        if (!list.$values) {
            return [list.Content.title];
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return list.$values.map((item: any) => item.Content.title);
    }

    private isPageAvailableInSitemap(pageURL: string) {
        if (pageURL === '/') {
            return false;
        }

        const lastPath = pageURL.split('/').pop()?.split('?')[0];
        const found = this.siteMap$.value.find((option: SiteMap) => option.Url.split('/').pop() === lastPath);

        if (found?.brands?.find((brand: string) => brand === 'private')) {
            return false;
        }

        return found;
    }
}
