import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DocumentService } from '../../../../services/document.service';
import { DocumentQuery } from 'common/models/document';
import { QueryFactory } from '../../../../content/components/document-list/query-factory/query-factory';
import { DocumentField } from '../../../../models/document';
import { ProductLiteratureService } from '../../service/product-literature.service';
import { DataTableSort, DataTableSortDirection } from 'common/models/data-table';
import { environment } from 'common/environments/environment';
import { ProductLiteratureDocument } from 'common/models';

export type FilterMapOptions = 'DMSType' | 'Status' | 'Brands' | 'Language';
@Component({
    selector: 'hvac-document-result',
    templateUrl: './document-result.component.html',
    styleUrls: ['./document-result.component.scss'],
    providers: [
        ProductLiteratureService
    ],
    encapsulation: ViewEncapsulation.None
})
export class DocumentResultComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() urnData: Observable<any[]>;
    @Input() categoryData: Observable<string[]>

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allDocuments: any[] = [];
    allResults: string[] = [];

    displayCount = 0;
    columnHeaders: string[] = ['Document Title', 'Catelog Number', 'Release Date', 'Print Date'];
    dataSort = {
        sortFieldName: 'PrintDate',
        sortDirection: DataTableSortDirection.DESCENDING
    } as DataTableSort;

    configBrand = environment.brand;
    expandedRow: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    literatureQueryData: any;
    searchKeyWord: string;
    filterQueryData: string[];
    filterDataCopy: string[];
    filteredList: string[];
    paginationItemCount = 100;


    ngOnDestroy$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterModel$ = new BehaviorSubject<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayedDocuments$ = new BehaviorSubject<any[]>([]);
    exportFilteredList$ = new BehaviorSubject<Boolean>(false);
    exportFullList$ = new BehaviorSubject<Boolean>(false);
    documentRequesting$ = new BehaviorSubject<Boolean>(false);
    filterView$ = new BehaviorSubject<Boolean>(false);
    queryProductURN: string;
    searchAndFilterData: string;
    shouldDisplayExportFilterButton$ = new BehaviorSubject<boolean>(false);

    hasFilters$ = this.filterModel$.pipe(
        map((resultModel: string[]) => Boolean(resultModel && resultModel.length))
    );

    hasMore: boolean;

    constructor(private readonly documentService: DocumentService, private readonly productLiteratureService: ProductLiteratureService,
        private readonly queryFactory: QueryFactory) {

    }

    ngOnInit() {
        this.urnData.pipe(
            takeUntil(this.ngOnDestroy$),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filter((productDetails: any) => Boolean(productDetails.length)),
            tap((productDetails) => {
                this.displayCount = 0;
                this.allResults = [];
                if (productDetails.length === 0) {
                    this.resetDocumentResults();

                    return;
                }
                this.queryProductURN = productDetails.map((product: {urn:string}) => [product.urn]);
                this.literatureQueryData = {
                    queryType: 'productliterature',
                    skip: 0,
                    maxItems: this.paginationItemCount,
                    modelNumbers: this.queryProductURN
                };
                this.fetchDocumentByProductURN(this.literatureQueryData);
            })
        ).subscribe();

        this.categoryData.pipe(
            takeUntil(this.ngOnDestroy$),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filter((categoryId: any) => Boolean(categoryId.length)),
            tap((categoryId) => {
                this.displayCount = 0;
                this.allResults = [];
                if (categoryId.length === 0) {
                    this.resetDocumentResults();

                    return;
                }
                this.literatureQueryData = {
                    queryType: 'productliterature',
                    skip: 0,
                    maxItems: this.paginationItemCount,
                    category: categoryId,
                    isParts: true
                };

                this.fetchDocumentByProductURN(this.literatureQueryData);
            })
        ).subscribe();

        combineLatest([
            this.displayedDocuments$,
            this.exportFilteredList$,
            this.exportFullList$
        ]).pipe(
            map(([documentArray, exportFilteredList, exportFullList]) => {
                if (exportFilteredList) {
                    this.exportFilteredList$.next(false);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const mergedArray: any[] = [];
                    documentArray.forEach((type) => {
                        type.documents.forEach((document: string) => {
                            mergedArray.push(document);
                        });
                    });
                    this.productLiteratureService.exportToExcel(mergedArray);
                }
                if (exportFullList) {
                    this.exportFullList$.next(false);
                    this.productLiteratureService.exportToExcel(this.allDocuments);
                }
            })
        ).subscribe();
    }

    resetDocumentResults() {
        this.displayedDocuments$.next([]);
        this.filterModel$.next([]);
        this.allDocuments = [];
        this.filterDataCopy = [];
        this.expandedRow = '';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchDocumentByProductURN(queryData: any) {
        this.queryFactory.createQuery(queryData).then((query: DocumentQuery) => {
            this.documentRequesting$.next(true);
            this.documentService.getDocuments(query).pipe(takeUntil(this.ngOnDestroy$)).subscribe((results) => {
                if (results.ResultCount <= 0) {
                    this.documentRequesting$.next(false);
                    this.hasMore = false;
                    this.allDocuments = [];
                    this.displayedDocuments$.next([]);
                    this.filterModel$.next([]);

                    return;
                }
                this.hasMore = !(results.ResultCount < this.paginationItemCount);

                if (results.Documents) {
                    this.allResults = this.allResults.concat(results.Documents);
                    this.allDocuments = this.productLiteratureService.parseDocumentsByBrands(this.queryFactory.filter(queryData, this.allResults));
                    this.columnHeaders = this.queryFactory.fields(queryData).map((item: DocumentField) => item.fieldName);
                    this.displayCount = this.allDocuments.length;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const documentArray: any = this.queryFactory.filterByType(queryData, this.allDocuments);
                    this.displayedDocuments$.next([...new Set(documentArray.data)]);
                    this.filterModel$.next(this.queryFactory.fetchFilterOptions(queryData, this.allDocuments));
                    this.documentRequesting$.next(false);
                    this.filterQuery(this.allDocuments, this.searchAndFilterData);
                }
            },
            (error) => {
                this.documentRequesting$.next(false);
                throw error;
            });
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleResultRow(event: any) {
        this.expandedRow === event.title ? this.expandedRow = '' : this.expandedRow = event.title;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick(event: any) {
        const docPath = event.value.DocumentPath;
        const filePath = event.value.DocumentBaseURL + docPath.substring(1, docPath.length);
        window.open(filePath, '_blank');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterQuery(documents: Partial<ProductLiteratureDocument>[], searchAndFilterData: any) {
        this.searchAndFilterData = searchAndFilterData || null;
        if (this.searchAndFilterData === null) {
            return;
        }
        const { filterInput, searchInput } = searchAndFilterData;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let filteredResults: any[] = documents;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let filteredResultsbyType: any = {};
        if (searchInput.length > 1) {
            filteredResults = filteredResults.filter((result) => result['DocumentTitle'].toLowerCase().includes(searchInput) || result['CatalogueNumber'].includes(searchInput));
        }
        filterInput.forEach((filterGroup: string[]) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const groupFilteredData = filteredResults.filter((result: string) => filterGroup.some((singleFilter: any) => result[singleFilter.category] === singleFilter.title));
            filteredResults = [...groupFilteredData];
        });


        this.displayCount = filteredResults.length;
        filteredResultsbyType = this.queryFactory.filterByType(this.literatureQueryData, filteredResults);
        this.displayedDocuments$.next([...new Set(filteredResultsbyType.data)]);

        if ((searchAndFilterData.filterInput.length > 0 || searchAndFilterData.searchInput !== '') && filteredResultsbyType.data.length > 0) {
            this.shouldDisplayExportFilterButton$.next(true);
        }
        else {
            this.shouldDisplayExportFilterButton$.next(false);
        }

        return this.displayedDocuments$.value;
    }

    loadMoreDocuments() {
        this.literatureQueryData = {
            queryType: 'productliterature',
            skip: this.allDocuments.length,
            maxItems: this.paginationItemCount,
            modelNumbers: this.queryProductURN
        };
        this.fetchDocumentByProductURN(this.literatureQueryData);
    }


    toggleFilterMenu() {
        this.filterView$.next(!this.filterView$.value);
    }

    exportFilteredList() {
        this.exportFilteredList$.next(true);
    }

    exportFullList() {
        this.exportFullList$.next(true);
    }
}
