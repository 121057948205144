<div class="hvac-cp-energy-events">
    <hvac-table *ngIf="data?.length; else noData" [responsive]="isResponsive">
        <thead class="hvac-cp-energy-events-table-header">
            <tr class="hvac-cp-energy-events-table-header-row">
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.date">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.DATE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.startTime">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.START_TIME' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.endTime">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.END_TIME' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb" *ngIf="config?.description">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.DESCRIPTION' | translate}}
                </th>
            </tr>
        </thead>
        <tbody class="hvac-cp-energy-events-body">
            <tr *ngFor="let event of data" class="hvac-cp-energy-events-items">
                <td *ngIf="config?.date" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.DATE' | translate" class="hvac-p-sm hvac-cp-energy-events-item">
                    <div *ngIf="event.startTime && event.startTime !== '' else noDateTime">
                        <div class="hvac-fw-sb hvac-rhythm-1">{{ event?.startTime | dateTimeTz: 'date' }}</div>
                    </div>

                    <ng-template #noDateTime>{{CharSymbol.DoubleDash}}</ng-template>
                </td>

                <td *ngIf="config?.startTime" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.START_TIME' | translate" class="hvac-p-sm hvac-cp-energy-events-item">
                    <div *ngIf="event.startTime && event.startTime !== '' else noDateTime">
                        <div>{{ event?.startTime | dateTimeTz: 'time' }}</div>
                    </div>

                    <ng-template #noDateTime>{{CharSymbol.DoubleDash}}</ng-template>
                </td>

                <td *ngIf="config?.endTime" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.END_TIME' | translate" class="hvac-p-sm hvac-cp-energy-events-item">
                    <div *ngIf="event.endTime && event.endTime !== '' else noDateTime">
                        <div>{{ event?.endTime | dateTimeTz: 'time' }}</div>
                    </div>

                    <ng-template #noDateTime>{{CharSymbol.DoubleDash}}</ng-template>
                </td>


                <td *ngIf="config?.description" [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.DESCRIPTION' | translate" class="hvac-p-sm hvac-cp-energy-events-item">{{ event?.description  }}</td>
            </tr>
        </tbody>
    </hvac-table>

    <ng-template #noData>
        <div class="hvac-cp-energy-events-no-data hvac-h4">{{ 'CONNECTED_PORTAL.WALL_CONTROL.ENERGY_EVENTS.EMPTY_MESSAGE' | translate }}</div>
    </ng-template>

    <div class="hvac-cp-list-footer" *ngIf="totalPages > 1">
        <div class="hvac-cp-list-footer-pagination">
            <hvac-pagination
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
    </div>
</div>
