<ul class="hvac-private-header-icon-menu">
    <!-- <hvac-bookmark-menu [menuActive]="(headerDropdown$ | async) === 'bookmarks'">
    </hvac-bookmark-menu> -->
    <button class="hvac-private-header-icon-link bookmarks" (click)="openBookMarkWindow()">
        <span class="hvac-accessible-text">{{ 'HEADER.ICON_LINKS.FILE_FOLDER' | translate }}</span>
        <utc-svg src="assets/icons/bookmark-solid.svg"></utc-svg>
    </button>
    <button class="hvac-private-header-icon-link inbox" (click)="handleInboxButton()">
        <span class="hvac-accessible-text">{{ 'HEADER.ICON_LINKS.FILE_FOLDER' | translate }}</span>
        <utc-svg src="assets/icons/file-folder.svg"></utc-svg>
        <span class="hvac-inbox-count-badge" *ngIf="((unreadCount$ | async) || 0) > 0">{{ unreadCount$ | async}}</span>
    </button>
    <hvac-dropdown-icon-menu [menuActive]="(headerDropdown$ | async) === 'help'" (openMenuEmitter)="openHelpDropdown($event)" [selectedOption]="helpSelectedOption"
        [menuOptions]="(helpNavigationOptions$ | async)!" iconSrc="assets/icons/question-mark.svg">
    </hvac-dropdown-icon-menu>
</ul>
