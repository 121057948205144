import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BaseChartDirective } from 'ng2-charts';
import { FaultsReportForFactoryResponse } from 'private/app/models/factory.model';
import { ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';

const CHART_COLORS = {
    critical: '#FF5433',
    moderate: '#FFAA00',
    informational: '#1891F6'
};
const translationBase = 'CONNECTED_PORTAL.FACTORY_SYSTEM_INSIGHTS.FAULT_SEVERITY';

@Component({
    selector: 'hvac-fault-severity-report',
    templateUrl: './fault-severity-report.component.html',
    styleUrls: ['./fault-severity-report.component.scss']
})
export class FaultSeverityReportComponent extends BaseComponent implements OnInit, OnChanges {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    @Input() systemId: string;

    public hasData$: Observable<boolean>;
    public data$: Observable<FaultsReportForFactoryResponse>;
    public chartData$: Observable<null | ChartData<'doughnut'>>;
    public isLoading = true;
    public chartOptions$: Observable<ChartConfiguration['options']>;
    public chartType: ChartType = 'doughnut';
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);
    public hasFaults$: Observable<boolean>;

    constructor(
        private chartConfigService: ChartConfigService,
        private factoryService: FactoryService,
        private translationService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.data$ = this.yearSelectFormControl.valueChanges.pipe(
            startWith(this.startYear),
            tap(() => {
                this.isLoading = true;
            }),
            switchMap((year) => this.factoryService.queryFaultReportForFactory(parseInt(year, 10), this.systemId).pipe(
                catchError(() => {
                    this.isLoading = false;

                    return EMPTY;
                })
            ))
        );

        this.chartOptions$ = of(this.chartConfigService.getDoughnutChartConfig({
            radius: '95%',
            cutout: '87%'
        }));

        this.chartData$ = this.data$.pipe(
            map(({ data }) => this.formatChartData(data)),
            tap(() => {
                this.isLoading = false;
            })
        );

        this.hasFaults$ = this.data$.pipe(map(({ data }) => Boolean(data.faultSeverity) && !Object.values(data.faultSeverity)?.every((value) => value === 0)));

        this.hasData$ = combineLatest([this.hasFaults$, this.chartData$]).pipe(
            map(([hasFaults, chartData]) => Boolean(hasFaults && chartData)),
            startWith(false)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['systemId'].isFirstChange()) {
            this.yearSelectFormControl.setValue(this.startYear);
        }
    }

    private formatChartData(data: FaultsReportForFactoryResponse['data']) {
        const translationPath = `${translationBase}.DATA_LABELS`;
        const labels = data.faultSeverity && Object.keys(data.faultSeverity).map((key) => this.translationService.instant(`${translationPath}.${key}`));
        const dataset = data.faultSeverity && Object.values(data.faultSeverity);


        return this.chartConfigService.getDonutChartData(labels, dataset, { chartColors: Object.values(CHART_COLORS) });
    }
}
