import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { environment } from 'common/environments/environment';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { DealerAlertResponse, ProductAlertResponse, SystemAlertsResponse } from 'private/app/models/alert.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { DateRangeQueryParams, DefaultQueryParams } from 'private/app/models/default-query-params';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

const { ecobeeSystemsFeature } = environment.features.connectedPortal;

const getParamsQueryAlertsBySerialNo = (hasSystemId: boolean) => `
        query QueryAlertsBySerialNo($id: String!, $type: String!${hasSystemId ? ', $systemType: String' : ''}, $params: DefaultQueryParams) {
            queryAlertsBySerialNo(id: $id, type: $type${hasSystemId ? ', systemType: $systemType' : ''}, params: $params) {
    `;

export const QUERY_ALERTS_BY_SERIAL_NO = gql`
        ${getParamsQueryAlertsBySerialNo(ecobeeSystemsFeature)}
            totalCount,
            totalPages
            data {
                id
                dateTime
                source
                product {
                    modelNo
                    serialNo
                    systemType
                }
                error {
                    code,
                    description
                    severity
                    type
                    troubleshootUri,
                    isActive
                },
                occurrenceCount,
                dealerId,
                wallControlSerialNo
            }
        }
    }
`;

export const QUERY_ALERTS_BY_SYSTEM_ID = gql`
    query QueryAlertsBySystemId($id: String!, $systemType: String, $dealerId: String, $params: DefaultQueryParams) {
        queryAlertsBySystemId(id: $id, systemType: $systemType, dealerId: $dealerId, params: $params) {
            totalCount
            totalPages
            data {
                id,
                dateTime,
                occurrenceCount,
                dealerId,
                wallControlSerialNo,
                source,
                error {
                    code,
                    description,
                    severity,
                    troubleshootUri,
                    type,
                    isActive
                },
                product {
                    name,
                    modelNo
                    serialNo
                    type
                    systemType
                }
            }
        }
    }
`;

export const QUERY_ALERTS_BY_DEALER_ID = gql`
    query QueryAlertsByDealerId($id: String!, $params: DateRangeQueryParams) {
        queryAlertsByDealerId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id,
                dateTime,
                error {
                    code,
                    description,
                    severity,
                    troubleshootUri,
                    isActive
                    
                },
                product {
                    name,
                    modelNo
                    serialNo
                    type
                    systemType
                },
                property {
                    id
                    address {
                        address1,
                        address2,
                        city,
                        state,
                        zipCode
                    },
                }
                customer {
                    firstName,
                    lastName,
                    phone1
                },
                dealerId,
                wallControlSerialNo
            }
        }
    }
`;

export const DELETE_ALERTS_BY_IDS = gql`
    mutation DeleteAlertsByIds($ids: [String!]!) {
        deleteAlertsByIds(ids: $ids) {
            ids
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class AlertsService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryAlertsBySerialNo(id: string, type: 'wall control' | 'indoor unit' | 'outdoor unit', systemType: SystemType, queryParams?: DefaultQueryParams): Observable<ProductAlertResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const variables: any = {
            id,
            type,
            params: queryParams
        };

        if (ecobeeSystemsFeature) {
            variables.systemType = systemType;
        }

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryAlertsBySerialNo').query<{ queryAlertsBySerialNo: ProductAlertResponse }>({
                query: QUERY_ALERTS_BY_SERIAL_NO,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryAlertsBySerialNo } = value.data;
                    const transformedAlerts = queryAlertsBySerialNo.data.map((alert) => {
                        const { error } = alert;

                        return {
                            ...alert,
                            error: {
                                ...error,
                                isActive: error.isActive as unknown as string === 'true'
                            }
                        };
                    });
                    const transformedData = {
                        ...queryAlertsBySerialNo,
                        data: transformedAlerts
                    };

                    return transformedData;
                })
            )),
            catchError(() => of(this.getEmptyCollectionResponsePaginated()))
        );
    }

    public queryAlertsBySystemId(systemId: string, systemType: string, dealerId: string | undefined, queryParams?: DefaultQueryParams): Observable<SystemAlertsResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryAlertsBySystemId').query<{ queryAlertsBySystemId: SystemAlertsResponse }>({
                query: QUERY_ALERTS_BY_SYSTEM_ID,
                variables: {
                    id: systemId,
                    systemType,
                    dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryAlertsBySystemId } = value.data;

                    const transformedAlerts = queryAlertsBySystemId.data.map((alert) => {
                        const { error } = alert;

                        return {
                            ...alert,
                            error: {
                                ...error,
                                isActive: error.isActive as unknown as string === 'true'
                            }
                        };
                    });
                    const transformedData = {
                        ...queryAlertsBySystemId,
                        data: transformedAlerts
                    };

                    return transformedData;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryAlertsByDealerId(dealerId: string, queryParams?: DateRangeQueryParams): Observable<DealerAlertResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryAlertsByDealerId').query<{ queryAlertsByDealerId: DealerAlertResponse }>({
                query: QUERY_ALERTS_BY_DEALER_ID,
                variables: {
                    id: dealerId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryAlertsByDealerId } = value.data;
                    const transformedAlerts = queryAlertsByDealerId.data.map((alert) => {
                        const { error } = alert;

                        return {
                            ...alert,
                            error: {
                                ...error,
                                isActive: error.isActive as unknown as string === 'true'
                            }
                        };
                    });
                    const transformedData = {
                        ...queryAlertsByDealerId,
                        data: transformedAlerts
                    };

                    return transformedData;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public deleteAlertsByIds(alertIds: string[]): Observable<string[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('deleteAlertsByIds').mutate<{ deleteAlertsByIds: { data: string[] } }>({
                mutation: DELETE_ALERTS_BY_IDS,
                variables: { ids: alertIds },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const deleteAlertsByIds = value.data?.deleteAlertsByIds.data || [];

                    return deleteAlertsByIds;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
