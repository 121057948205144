<utc-modal modal [visible]="isDiagnosticModalVisible" (closeAction)="handleCloseDiagnosticsTestModal()"
    size="dynamic-height" theme="rounded" class="hvac-connected-portal-system-diagnostics-modal">
    <hvac-modal-header>
        <span class="hvac-h3">{{"CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.HEADING" | translate }}</span>
    </hvac-modal-header>
    <div class="hvac-cp-sub-title">
        <div class="hvac-h4 ">Test Equipment</div>
        <div class="hvac-cp-diagnostic-info" [ngClass]="{'hide': hideDiagnosticAttentionInfo}">
            <b>{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.DISCLAIMER.HEADING' | translate}}: </b>
            {{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.DISCLAIMER.MAIN_TEXT' | translate}}
            <b>{{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.DISCLAIMER.NOTE' | translate}}:</b>
            {{'CONNECTED_PORTAL.SYSTEM_DIAGNOSTICS.DISCLAIMER.ADDITIONAL_TEXT' | translate}} <div class="close-icon"
                (click)="handleCloseAttentionInfo()">x</div>
        </div>
    </div>
    <wall-control-system-diagnostics *ngIf="isDiagnosticModalVisible" (onExitTest)="handleCloseDiagnosticsTestModal($event)"
        [dealerId]="dealerId"
        [serialNo]="serialNo"></wall-control-system-diagnostics>
    
    <hvac-generic-event-logs *ngIf="isDiagnosticModalVisible"
        [dealerId]="dealerId"
        [serialNo]="serialNo"
        [propertyId]="propertyId"
        [systemType]="systemType">
    </hvac-generic-event-logs>
</utc-modal>