import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { sub } from 'date-fns';
import { DateRange } from 'private/app/models/account-admin-program.model';

@Component({
    selector: 'hvac-connected-portal-alerts-options',
    templateUrl: './connected-portal-alerts-options.component.html',
    styleUrls: ['./connected-portal-alerts-options.component.scss']
})
export class ConnectedPortalAlertsOptionsComponent {
    @Input() alertsStartDate: number;
    @Input() alertsEndDate: number;
    @Output() alertsStartDateChange = new EventEmitter<number>();
    @Output() alertsEndDateChange = new EventEmitter<number>();
    @Output() public onAlertsPageChange = new EventEmitter<number>();
    @Output() public onAlertsDateRangeChange = new EventEmitter();

    readonly AppConstants = AppConstants;
    public minStartDate: number = sub(new Date(), { months: AppConstants.twoMonthsRange }).getTime();
    public maxEndDate: number = Date.now();

    handleDateRangeChange($event: DateRange) {
        this.onAlertsDateRangeChange.emit($event);
    }
}
