import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { TABLE_COLUMN_HEADERS, PO_STATUS } from 'private/app/models/purchase-order-details.model';
import { PurchaseOrderService } from 'private/app/services/purchase-orders.service';
import { of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-purchase-order-detail',
    templateUrl: './purchase-order-detail.component.html',
    styleUrls: ['./purchase-order-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderDetailComponent implements AfterViewInit, OnDestroy {
    ngOnDestroy$ = new Subject();
    readonly AppConstants = AppConstants
    readonly TableColumnHeaders = TABLE_COLUMN_HEADERS;
    readonly PoStatus = PO_STATUS;
    constructor(public purchaseOrderService: PurchaseOrderService) {}

    ngAfterViewInit() {
        this.purchaseOrderService.activePurchaseOrder$.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((activepo) => {
                if (activepo.pOstatus === PO_STATUS.CHANGED) {
                    return this.purchaseOrderService.createAcknowledgement().pipe(
                        takeUntil(this.ngOnDestroy$),
                        catchError((err: HttpErrorResponse) => {
                            throw err;
                        })
                    );
                }

                return of({});
            }),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
