import { ApprovalRequestsResponse } from 'common/models/approval-requests';

export class FetchApprovalRequests {
    static readonly type = '[ApprovalRequests] FetchApprovalRequests';
}

export class ReceiveApprovalRequests {
    static readonly type = '[ApprovalRequests] ReceiveApprovalRequests';
    constructor(public readonly approvalRequests: ApprovalRequestsResponse) {}
}
