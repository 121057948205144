import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Option } from 'common/components/dropdown/options-menu/options-menu.component';
import { ProductDetails } from 'common/models';
import { ProductAttributes, ProductDocument } from 'common/models/product';
import { ProductsState } from 'common/store/products/products.state';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';
@Component({
    selector: 'utc-product-content-section-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentsComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @ViewChild('documentSearchInput') documentSearchInput: ElementRef;

    public documentForm: UntypedFormGroup;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentCategoriesFiltered$: Observable<any[]>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public categorySubTypes$: Observable<any[]>;
    public languageSelection$: BehaviorSubject<string[]> = new BehaviorSubject(['all']);
    public languageOptions$: Observable<Option[] | null>;
    public documents$: Observable<ProductDocument[] | undefined>;
    public documentsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
    public documentSearchText$: BehaviorSubject<string> = new BehaviorSubject('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public filteredDocuments$: Observable<any[]>;

    private currentCategory$: BehaviorSubject<string> = new BehaviorSubject('');
    private subcategory$: BehaviorSubject<string> = new BehaviorSubject('');
    private showArchived$: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
    private languageSelectionArray: string[];
    private localeOptions: Option[] | null;
    private hasActiveDocs$: Observable<boolean>;
    private attributes$: Observable<ProductAttributes | undefined>;
    private searchKeyUpSubject$: BehaviorSubject<string> = new BehaviorSubject('');
    private ngOnDestroy$ = new Subject();

    constructor(
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef,
        private readonly translate: TranslateService,
        private readonly changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.documentForm = new UntypedFormGroup({
            categorySelector: new UntypedFormControl(''),
            subCategorySelector: new UntypedFormControl(''),
            showArchivedDocs: new UntypedFormControl(false)
        });

        this.documents$ = this.activeProduct$.pipe(
            map((productDetails: ProductDetails) => {
                this.documentSearchText$.next('');

                return productDetails && productDetails.documents;
            })
        );

        this.languageOptions$ = combineLatest([this.documents$, this.translate.get('PRODUCTS.LANGUAGE_TITLES')])
            .pipe(map(([documentList, languageTitles]) => {
                if (!documentList) {
                    return null;
                }
                const localeList: string[] = [];
                documentList.forEach((document) => {
                    localeList.push(...document.locales);
                });

                return [...new Set(localeList)].map((locale) => ({
                    title: languageTitles[locale],
                    value: locale
                }));
            }));

        this.attributes$ = this.activeProduct$.pipe(
            map((productDetails: ProductDetails) => productDetails && productDetails.attributes)
        );

        this.hasActiveDocs$ = combineLatest([this.attributes$, this.documents$]).pipe(
            map(([attributes, documents]) => {
                const productIsActive = Boolean(attributes && attributes.active);
                const hasCurrentDoc = Boolean(documents && documents.some(({ current }) => current));

                return productIsActive && hasCurrentDoc;
            })
        );

        this.documentForm.controls['categorySelector'].valueChanges.subscribe((selectedCategory) => {
            this.currentCategory$.next((Array.isArray(selectedCategory) ? selectedCategory[0] : selectedCategory));
        });

        this.documentForm.controls['subCategorySelector'].valueChanges.subscribe((selectedSubcategory) => {
            this.subcategory$.next(selectedSubcategory);
        });

        this.documentForm.controls['showArchivedDocs'].valueChanges.pipe(
            tap(() => {
                this.documentsLoading$.next(true);
            }),
            delay(800)
        ).subscribe((showArchived) => {
            this.showArchived$.next(showArchived);
        });

        this.documentCategoriesFiltered$ = combineLatest([
            this.showArchived$,
            this.documents$,
            this.languageSelection$,
            this.documentSearchText$
        ]).pipe(
            map(([showArchived, documents, language, searchText]) => {
                if (!documents) {
                    return [];
                }
                let filteredDocTypes = documents;
                if (!showArchived) {
                    filteredDocTypes = filteredDocTypes.filter((doc) => doc.current);
                }

                if (language[0] !== 'all') {
                    filteredDocTypes = filteredDocTypes.filter((doc) => doc.locales.some((locale) => language.indexOf(locale) !== -1));
                }

                if (searchText && searchText !== '') {
                    filteredDocTypes = filteredDocTypes.filter((doc) => doc.title.toLowerCase().includes(searchText));
                }

                return [...new Set(filteredDocTypes.map(({ type }) => type))];
            })
        );

        this.categorySubTypes$ = combineLatest([
            this.currentCategory$,
            this.showArchived$,
            this.documents$,
            this.languageSelection$,
            this.documentSearchText$
        ]).pipe(
            map(([categoryName, showArchived, documents, language, searchText]) => {
                if (!documents) {
                    return [];
                }

                let categoryDocs = documents.filter((docs) => docs.type === (Array.isArray(categoryName) ? categoryName[0] : categoryName) && (showArchived || docs.current));


                if (language[0] !== 'all') {
                    categoryDocs = categoryDocs.filter((doc) => doc.locales.some((locale) => language.indexOf(locale) !== -1));
                }

                if (searchText && searchText !== '') {
                    categoryDocs = categoryDocs.filter((doc) => doc.title.toLowerCase().includes(searchText));
                }

                return [...new Set(categoryDocs.map(({ subtype }) => subtype))];
            })
        );

        combineLatest([this.categorySubTypes$, this.subcategory$.pipe(startWith(null))]).pipe(
            filter(([categorySubTypes, _subcategory]) => Boolean(categorySubTypes && categorySubTypes.length)),
            distinctUntilChanged((l, r) => (l && l[0]) === (r && r[0]))
        ).subscribe(([categorySubTypes, subcategory]) => {
            if ((subcategory && categorySubTypes.includes(subcategory))) {
                this.documentForm.controls['subCategorySelector'].setValue((Array.isArray(subcategory) ? subcategory[0] : subcategory));
            }
            else {
                this.documentForm.controls['subCategorySelector'].setValue(categorySubTypes[0]);
            }
        });

        this.filteredDocuments$ = combineLatest([
            this.currentCategory$,
            this.subcategory$,
            this.documents$,
            this.showArchived$,
            this.languageSelection$,
            this.documentSearchText$
        ]).pipe(
            map(([category, subcat, documents, showArchived, language, searchText]) => {
                if (!documents) {
                    return [];
                }

                const matchesType = (doc: ProductDocument): boolean => doc.type === category && doc.subtype === (Array.isArray(subcat) ? subcat[0] : subcat);
                const matchesStatus = (doc: ProductDocument): boolean => showArchived || doc.current;
                const matchesLang = (doc: ProductDocument): boolean => language[0] === 'all' || doc.locales.some((locale) => language.indexOf(locale) !== -1);
                const matchesSearchText = (doc: ProductDocument): boolean => searchText === '' || doc.title.toLowerCase().includes(searchText);

                const allDocs = documents.filter((doc) => matchesType(doc) && matchesStatus(doc) && matchesLang(doc) && matchesSearchText(doc));
                this.documentsLoading$.next(false);

                return allDocs;
            })
        );

        this.documents$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((documents) => Boolean(documents && documents.length > 0)),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();

        this.documentCategoriesFiltered$.pipe(
            takeUntil(this.ngOnDestroy$),
            delay(0),
            withLatestFrom(this.currentCategory$.pipe(startWith(null)))
        ).subscribe(([documentCategories, currentCategory]) => {
            if (currentCategory && documentCategories.indexOf(currentCategory) > -1) {
                return;
            }
            const activeTab = documentCategories.indexOf('Technical Literature') > -1 ? 'Technical Literature' : documentCategories[0];
            this.documentForm.controls['categorySelector'].setValue(activeTab);
        });

        this.hasActiveDocs$.pipe(
            takeUntil(this.ngOnDestroy$),
            delay(0),
            tap((hasActiveDocs) => {
                this.documentForm.controls.showArchivedDocs.setValue(!hasActiveDocs);
            })
        ).subscribe();

        this.languageOptions$.subscribe((languageOptions) => {
            this.localeOptions = languageOptions;
            this.getUserLocale();
        });

        this.languageSelection$.subscribe((languageArray) => {
            this.languageSelectionArray = languageArray;
        });

        this.searchKeyUpSubject$.pipe(
            debounceTime(500)
        ).subscribe((searchTextValue) => {
            this.documentSearchText$.next(searchTextValue.toLowerCase());
        });
    }

    ngAfterViewChecked() {
        // prevent ExpressionChangedAfterItHasBeenCheckedError on binding of documentCategoriesFiltered$
        this.changeDetector.detectChanges();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.DOCUMENTS'),
            order: 4,
            container: this.element.nativeElement
        };
    }

    toggleArchivedDocs(value: Event) {
        const input = <HTMLInputElement>value.target;
        this.documentForm.controls['showArchivedDocs'].setValue(input.checked);
    }

    toggleLanguage(event: Event) {
        if ((event.target as HTMLInputElement).checked) {
            if (this.languageSelectionArray[0] === 'all') {
                this.languageSelection$.next([(event.target as HTMLInputElement).value]);
            }
            else {
                this.languageSelection$.next([...this.languageSelectionArray, (event.target as HTMLInputElement).value]);
            }
        }
        else if (this.languageSelectionArray.length === 1) {
            this.languageSelection$.next(['all']);
        }
        else {
            this.languageSelectionArray.splice(this.languageSelectionArray.indexOf((event.target as HTMLInputElement).value), 1);
            this.languageSelection$.next(this.languageSelectionArray);
        }
    }

    getUserLocale() {
        if (!this.localeOptions || this.localeOptions.length <= 1) {
            return this.languageSelection$.next(['all']);
        }
        let userLocale = typeof navigator.languages === 'undefined' ? navigator.language : navigator.languages[0];
        userLocale = userLocale.substr(0, 2);

        return this.localeOptions.some((locale) => (locale.value === userLocale)) ? this.languageSelection$.next([userLocale]) : this.languageSelection$.next(['all']);
    }

    onDocumentSearch() {
        this.documentSearchText$.next(this.documentSearchInput.nativeElement.value.toLowerCase());
    }

    clearDocumentSearch() {
        this.documentSearchInput.nativeElement.value = '';
        this.documentSearchText$.next('');
    }

    inputKeyup(event: Event) {
        this.searchKeyUpSubject$.next((event.target as HTMLInputElement).value);
    }
}
