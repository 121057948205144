<div class="hvac-main-content-container">
    <div class="hvac-trade-partners-header">
        <hvac-content-header [rightSideContent]="true" *ngIf="hasHeaderData" [data]="data">
            <a [routerLink]="'/trade-partners'" *ngIf="showBackButton" class="hvac-trade-partners-back-button" size="small" hvacButton="secondary">
                {{ 'TRADE_PARTNERS.BACK_BUTTON' | translate }}
                <utc-svg src="assets/icons/arrow.svg"></utc-svg>
            </a>
        </hvac-content-header>
    </div>
    <div class="hvac-landing-page">
        <div class="hvac-trade-partner-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
