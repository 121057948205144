import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { SystemType } from 'private/app/models/connected-product.model';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { IduConfigurationResponse, IduHistoricalDataResponse, IduStatusResponse } from 'private/app/models/idu.model';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

interface IduConfigInputProps {
    gTerminalAvailable?: string;
    dehumidifyDrainTime?: string;
    gTerminalInput?: string;
    gTerminalFanSpeed?: string;
    auxiliaryHeatLockoutTemp?: string;
    reheatEnabled?: string;
}

export const QUERY_IDU_CONFIGURATION_BY_SERIAL_NO = gql`
    query QueryIduConfigurationBySerialNo($id: String!, $dealerId: String) {
        queryIduConfigurationBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                configEditParams {
                    gTerminalInput {
                        readOnly
                    }
                    gTerminalFanSpeed {
                        readOnly
                    }
                    auxiliaryHeatLockoutTemp {
                        options
                        readOnly
                    }
                    reheatEnabled {
                        readOnly
                    }
                }
                dateTime
                airflow
                dehumidifyDrainTime
                gTerminalAvailable
                gTerminalFanSpeed
                gTerminalInput
                gTerminalInputType
                heatOffDelay
                type
                altitude
                altitudeSelection
                auxiliaryHeatLockoutTemp
                furnaceAllowedStages
                furnaceMaxAllowedLimitCFM
                furnaceMaxCFMEnable
                furnaceMaxLimitCFM
                furnaceMinAllowedLimitCFM
                furnaceMinCFMEnable
                furnaceMinLimitCFM
                hydronicAirflow
                hydronicOffDelay
                hydronicOnDelay
                lowHeatRise
            }
        }
    }
`;

export const QUERY_IDU_STATUS_BY_SERIAL_NO = gql`
        query QueryIduStatusBySerialNo($id: String!, $dealerId: String, $systemType: String) {
            queryIduStatusBySerialNo(id: $id, dealerId: $dealerId, systemType: $systemType) {
            data {
                dateTime
                type
                stage
                returnAirTemp
                supplyAirTemp
                deltaT
                blowerRPM
                furnaceLockoutActive
                furnaceLockoutTimer
                indoorAirflow
                inducerRPM
                leavingAirTemp
                operationStatus
                staticPressure
                dissipationStatus
                latestAirflowVerification{
                    staticPressure
                    calcMinCFM
                    blowerSpeedRPM
                    systemCFM
                }
            }
        }
    }
`;

export const QUERY_IDU_HISTORICAL_DATA_BY_SERIAL_NO = gql`
    query QueryIduHistoricalDataBySerialNo($id: String!, $dealerId: String) {
        queryIduHistoricalDataBySerialNo(id: $id, dealerId: $dealerId) {
            data {
                dateTime
                blowerCycles
                blowerHours
                highHeatCycles
                highHeatHours
                lowHeatCycles
                lowHeatHours
                mediumHeatCycles
                mediumHeatHours
                powerCycles
                totalOnHours
            }
        }
    }
`;

export const UPDATE_IDU_CONFIG_BY_SERIAL_NO = gql`
    mutation UpdateIduConfigBySerialNo($id: String!, $dealerId: String, $data: IduConfigInputProps! ) {
        updateIduConfigBySerialNo(id: $id, dealerId: $dealerId, data: $data) {
            message,
            code,
            data {
                id
                name
                value
                description
            }
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class IduService extends ConnectedPortalBaseService {
    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public queryIduConfigurationBySerialNo(id: string, dealerId: string): Observable<IduConfigurationResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryIduConfiguration').query<{ queryIduConfigurationBySerialNo: IduConfigurationResponse }>({
                query: QUERY_IDU_CONFIGURATION_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryIduConfigurationBySerialNo } = value.data;

                    return this.removeEmptyProps(queryIduConfigurationBySerialNo) as IduConfigurationResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public updateIduConfigBySerialNo(id: string, dealerId: string, data: IduConfigInputProps) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('updateIduConfigBySerialNo').mutate<{ updateIduConfigBySerialNo: DefaultUpdateResponse }>({
                mutation: UPDATE_IDU_CONFIG_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId,
                    data
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => value.data?.updateIduConfigBySerialNo)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryIduStatus(id: string, dealerId: string, systemType: SystemType): Observable<IduStatusResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const variables: any = {
            id,
            dealerId,
            systemType
        };

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryIduStatusBySerialNo').query<{ queryIduStatusBySerialNo: IduStatusResponse }>({
                query: QUERY_IDU_STATUS_BY_SERIAL_NO,
                variables,
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryIduStatusBySerialNo } = value.data;

                    return this.removeEmptyProps(queryIduStatusBySerialNo) as IduStatusResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public getIduHistoricalData(id: string, dealerId: string): Observable<IduHistoricalDataResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryIduHistoricalDataBySerialNo').query<{ queryIduHistoricalDataBySerialNo: IduHistoricalDataResponse }>({
                query: QUERY_IDU_HISTORICAL_DATA_BY_SERIAL_NO,
                variables: {
                    id,
                    dealerId
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    const { queryIduHistoricalDataBySerialNo } = value.data;

                    return this.removeEmptyProps(queryIduHistoricalDataBySerialNo) as IduHistoricalDataResponse;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
