import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { AddToast, ToastService } from 'common/services/toast.service';
import { SystemType } from 'private/app/models/connected-product.model';
import { DefaultUpdateDatum, DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { IduConfigurationResponse } from 'private/app/models/idu.model';
import { IduService } from 'private/app/services/connected-portal/idu.service';
import { ConfigEditProp, ProductConfigurationService } from 'private/app/services/connected-portal/product-configuration.service';
import { ApiErrorName, ApiResponseCode, CONFIGURATION_RELOAD_DELAY, MISSING_API_RESPONSE_DATA } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../../../product-detail-context.service';

@Component({
    selector: 'hvac-infinity-idu-configuration',
    templateUrl: './infinity-idu-configuration.component.html',
    styleUrls: ['./infinity-idu-configuration.component.scss']
})
export class InfinityIduConfigurationComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() serialNo: string;
    @Input() systemType: SystemType;
    @Input() editEnabled?: boolean;
    @Input() saveEnabled?: boolean;
    @Input() isDeviceConnected: boolean;
    @Output() onSaveButtonClick = new EventEmitter();

    public isLoading: boolean = false;
    public configFormGroup: UntypedFormGroup | null = null;
    public formattedPropData: Record<string, ConfigEditProp> | null = null;
    public initialValues: Record<string, unknown> | null = null;
    public iduConfigData$ = new BehaviorSubject<IduConfigurationResponse | null>(null);
    public toastOutlet = 'iduConfigEditToast';

    private successToastQueue$ = new BehaviorSubject<DefaultUpdateResponse | null>(null);

    constructor(
        private iduService: IduService,
        private configService: ProductConfigurationService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private contextService: ProductDetailContextService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadData();

        if (this.editEnabled) {
            this.getEditableConfigPropData()
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe((value) => {
                    if (value) {
                        const { data, controls } = value;
                        this.configFormGroup = new UntypedFormGroup(controls);
                        this.initialValues = this.configFormGroup.value;
                        this.formattedPropData = data;
                    }
                });

            this.contextService.onConfigSaveConfirmed$
                .pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
                    this.handleSubmit();
                });
        }
        else {
            this.iduConfigData$
                .pipe(takeUntil(this.ngOnDestroy$))
                .subscribe((configData) => {
                    if (configData) {
                        this.formattedPropData = this.configService.getReadOnlyPropData(configData.data);
                    }
                });
        }
    }

    handleSubmit() {
        if (this.configFormGroup) {
            const cleanedData = this.configService.getValuesFromFormGroup(this.configFormGroup);

            if (cleanedData) {
                this.isLoading = true;
                this.iduService.updateIduConfigBySerialNo(this.serialNo, this.dealerId, cleanedData)
                    .pipe(
                        takeUntil(this.ngOnDestroy$),
                        tap((res) => {
                            if (res) {
                                if (res.code === ApiResponseCode.SUCCESS) {
                                    this.successToastQueue$.next(res);

                                    setTimeout(() => {
                                        this.loadData();
                                    }, CONFIGURATION_RELOAD_DELAY);
                                }
                                else {
                                    this.showToast(false, res);
                                    this.isLoading = false;
                                }
                            }
                            else {
                                throw Error(MISSING_API_RESPONSE_DATA);
                            }
                        }),
                        catchError((err) => {
                            this.isLoading = false;
                            this.showToast(true, err);

                            throw err;
                        })
                    )
                    .subscribe();
            }
        }
    }

    handleCancel() {
        this.configFormGroup?.reset(this.initialValues);
    }

    sortOrder() {
        return 0;
    }

    openConfirmModal() {
        this.onSaveButtonClick?.emit();
    }

    private loadData() {
        this.iduService.queryIduConfigurationBySerialNo(this.serialNo, this.dealerId)
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((res) => {
                this.isLoading = false;
                this.iduConfigData$.next(res);

                // If data loading happens after an update we show a success toast based on the update response
                if (this.successToastQueue$.value) {
                    this.showToast(false, this.successToastQueue$.value);
                    this.successToastQueue$.next(null);
                }
            });
    }

    private getEditableConfigPropData() {
        return combineLatest([
            this.iduConfigData$,
            this.configService.staticConfigEditParams$
        ])
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map(([iduConfigRes, uiConfig]) => {
                    if (iduConfigRes === null || uiConfig === null) {
                        return null;
                    }

                    const { data: iduConfigData } = iduConfigRes;
                    const iduStaticConfigParams = uiConfig.infinity.indoorUnit;
                    const mergedData = this.configService.mergeConfigData(iduConfigData, iduStaticConfigParams);
                    const controls = Object.entries(mergedData)
                        .reduce((acc, [key, value]) => {
                            if (value.control) {
                                acc[key] = value.control;
                            }

                            return acc;
                        }, {} as Record<string, UntypedFormControl>);

                    return {
                        data: mergedData,
                        controls
                    };
                })
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private showToast(isError: boolean, data: any) {
        const configEditToast: AddToast = {
            outletName: this.toastOutlet,
            closeable: true,
            autoClose: true
        };

        this.toastService.removeAll();

        if (isError === false) {
            if (data?.code === ApiResponseCode.SUCCESS) {
                const configEditErrorToast: AddToast = {
                    ...configEditToast,
                    ...{
                        content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.SUCCESS.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.SUCCESS.SUB_TEXT'),
                        theme: 'success'
                    }
                };

                this.toastService.add(configEditErrorToast);
            }
            else if (data?.code === ApiResponseCode.SERVICE_UNAVAILABLE) {
                data.data?.every((datum: DefaultUpdateDatum) => {
                    switch (datum.name) {
                        case ApiErrorName.DeviceDisconnectedError: {
                            const deviceDisconnectedErrorToast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.DEVICE_DISCONNECTED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.DEVICE_DISCONNECTED.SUB_TEXT'),
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(deviceDisconnectedErrorToast);

                            return false;
                        }
                        case ApiErrorName.DeviceCommandUnacknowledgedError: {
                            const unAcknowledgedErrorToast: AddToast = {
                                ...configEditToast,
                                ...{
                                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.SUB_TEXT'),
                                    theme: 'error'
                                }
                            };

                            this.toastService.add(unAcknowledgedErrorToast);

                            return false;
                        }
                        default:
                            break;
                    }

                    return true;
                });
            }
        }
        else {
            const configEditErrorToast: AddToast = {
                ...configEditToast,
                ...{
                    content: this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.TITLE') + this.translateService.instant('CONNECTED_PORTAL.WALL_CONTROL.CONFIG.MESSAGES.UNACKNOWLEDGED.SUB_TEXT'),
                    theme: 'error'
                }
            };
            this.toastService.add(configEditErrorToast);
        }
    }
}
