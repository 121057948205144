import { Pipe, PipeTransform } from '@angular/core';
import { AlertSeverity } from 'private/app/models/alert.model';

@Pipe({ name: 'severityToAlertType' })
export class SeverityToAlertTypePipe implements PipeTransform {
    transform(alertLevel: AlertSeverity): 'critical' | 'moderate' | 'informational' {
        switch (alertLevel) {
            case AlertSeverity.CRITICAL:
                return 'critical';
            case AlertSeverity.MODERATE:
                return 'moderate';
            case AlertSeverity.INFORMATIONAL:
                return 'informational';
            // no default
        }
    }
}
