<div [ngSwitch]="field.type" [formGroup]="form">
     <label *ngSwitchCase="'label'" style="white-space: pre-wrap">{{ (field.name === 'additionalMarketFound' || field.name === 'splitOfBusinessTitle') ? '' : (form.get(group)).controls[field.name]?.value || '--' }}</label>
    <utc-input *ngSwitchCase="'text'" type="text" shadow="none" size="small" [hideLabel]="false" [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_ENTER_VALUE' | translate"
        [error]="$any(form.get(group)).controls[field.name]?.dirty && !$any(form.get(group)).controls[field.name]?.valid"
        [inputControl]="$any(form.get(group)).controls[field.name]" [disabledInput]="disable">
    </utc-input>
    <utc-select *ngSwitchCase="'select'" [group]="$any(form.get(group))" [control]="field.name" shadow="none" 
        [defaultOption]="'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE' | translate"
        [disabled]="disable || (disableRSM && field.name === 'DistributorSalesManagerId')" 
        [placeholder]="'ACCOUNT_ADMIN.PROGRAMS.ENROLLMENT_FORMS.PLEASE_CHOOSE' | translate"
        [selectId]="field.selectedId" [error]="($any(form.get(group)).controls[field.name]?.dirty && $any(form.get(group)).controls[field.name]?.value === 'Please Choose') || $any(form.get(group)).controls[field.name]?.dirty && !$any(form.get(group)).controls[field.name]?.valid"
        size="small" [options]="field.options" [errorMessage]="'ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAM.MANDATORY_FIELD' | translate">
    </utc-select>
    <utc-textarea *ngSwitchCase="'textarea'" type="textarea" [placeholder]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMMENTS_PLACEHOLDER' | translate" [disabledInput]="disable" shadow="none" [inputControl]="$any(form.get(group)).controls[field.name]">
    </utc-textarea>
</div>
