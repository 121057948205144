
interface VendorAddress {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    city: string;
    state: string;
}

export enum PoToastTypes {
    ERROR = 'error',
    ACKN = 'acknowledged',
    ASN = 'shipped'
}

interface VendorAddressDetails extends VendorAddress {
    zipCode: string
}

interface VendorShipAddress extends VendorAddress {
    shipToAddressNumber: string
}

export const PO_VIEWED_MARK = 'X';

export interface PurchaseOrderItem {
    supplier: string,
    pONumber: string,
    pODocumentType: string,
    supplierPartNumber: string,
    pOLineNumber: string,
    pOOrderQuantity: string,
    unitOfMeasure: string,
    sapMaterialNumber: string,
    itemDescription: string,
    harmonizedCode: string,
    requestedShipDate: string,
    ackShipDate: string,
    unitCost: string,
    extendedCost: string,
    openQuantity: string
}

export enum PO_STATUS {
    CHANGED = 'Change',
    NEW = 'New',
    WAITING = 'Waiting for Order Acknowledge',
    ACKNOWLEDGED = 'Acknowledged',
    PARTIAL_SHIP = 'Acknowledged and Partial Shipped',
    CANCELLED = 'Cancelled (Item Deleted)',
    FULLY_SHIPPED = 'Fully Shipped',
}

export interface PurchaseOrderDetail {
    supplier: string,
    pONumber: string,
    pOOrderType: 'OD' | ' ' | 'OW',
    pOCreationDate: string,
    supplierPartNumber: string,
    customerPONumber: string,
    customerMaterialNumber: string,
    pOstatus: PO_STATUS,
    pOVendorNumber: string,
    vendorAddressDetails: VendorAddressDetails[],
    shipToAddressDetails: VendorShipAddress[],
    carrierShipMethod: string;
    paymentTerms: string,
    freightHandlingCode: string,
    lines: PurchaseOrderItem[],
    totalAmount: string,
    supplierSoNumber: string,
    sort?: number,
    waitingAcknowledgement: string,
    notes: string
}

export interface PoLineItem {
    poLineItemNumber: number,
    poQuantity: number,
    unitOfMeasure: string,
    sapMaterialNumber: string,
    ackDate: string
}

export interface AcknowledgementPost {
    purchaseOrderRequest: {
        poNumber: string,
        supplierSoNumber: string,
        poLines: PoLineItem[],
        waitingAcknowledgement: string
    }
}

export interface AdvancedShipNotification {
    advanceShipNoticeRequest: {
        poNumber: string
        poDate: string
        supplierSoNumber: string,
        freightWeight: string,
        freightCarrier: string,
        freightAmount: string,
        dateShipped: string,
        trackingNumber: string,
        asnLines: [
            {
                poLineItemNumber: string,
                sapMaterialNumber: string,
                supplierPartNumber: number,
                unitsShipped: string,
                unitOfMeasure: 'EA',
                asnDate: string,
                originalQty: string,
                qtyOpen: string
            }
        ]
    }
}

export const TABLE_COLUMN_HEADERS = 'TRADE_PARTNERS.PO_DETAIL.TABLE_DATA.';
export const ASN_TABLE_COLUMN_HEADERS = 'TRADE_PARTNERS.ADVANCED_SHIPMENT.TABLE_DATA.';

