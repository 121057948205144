<div class='hvac-tab-navigation-footer'>
    <div class='hvac-tab-navigation-footer-container' [ngClass]="{'no-back-button': !backText}">
        <div *ngIf="backText" class='hvac-tab-navigation-footer-back-container'>
            <button  [disabled]="backButtonDisabled" (click)="backButtonClick($event)" class='hvac-tab-navigation-footer-back-button'>
                {{ backText }}
            </button>
        </div>
        <div class='hvac-tab-navigation-footer-step-container'>
            <div *ngFor="let step of stepCounter(numberOfSteps); index as index;" class='hvac-tab-navigation-footer-step'
                [ngClass]="{'hvac-tab-navigation-footer-step-current': (index+1) === currentStep}"></div>
        </div>
        <div class='hvac-tab-navigation-footer-continue-button-container'>
            <button *ngIf="skipText" [disabled]="skipButtonDisabled" (click)="skipButtonClick($event)" class='hvac-tab-navigation-footer-skip-button'>
                {{ skipText }}
            </button>
            <button hvacButton="primary" *ngIf="continueText" [disabled]="continueButtonDisabled" (click)="continueButtonClick($event)" class='hvac-tab-navigation-footer-continue-button'>
                <span>{{ continueText }}</span>
                <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
            </button>
        </div>
    </div>
</div>
