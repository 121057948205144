/* eslint-disable max-lines */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { AppConstants } from 'common/app-constants';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { ToastService } from 'common/services/toast.service';
import { differenceInMinutes, format, sub } from 'date-fns';
import { DateRange } from 'private/app/models/account-admin-program.model';
import { SystemType } from 'private/app/models/connected-product.model';
import { WallControlConfig, WallControlStatus } from 'private/app/models/wall-control.model';
import { ActivityCode, ActivityTrackerService } from 'private/app/services/connected-portal/activity-tracker.service';
import { CarrierEltReportService, EltDataSummary } from 'private/app/services/connected-portal/carrier-elt-report.service';
import { ChartConfigService, MultiLineDataSet, MultiLineChartColors, RuntimeChartType } from 'private/app/services/connected-portal/chart-config.service';
import { DataSummary, RunTimeReportData, RunTimeReportResponse } from 'private/app/services/connected-portal/ecobee-report.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { ECOBEE_MAX_RUNTIME_REPORT_DAY_RANGE, RUNTIME_REPORT_END_DATE_DIFF_MINUTE_MAX, TempUnitFormat } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

type EnabledDataSets = { heatSetPointEnabled: boolean, coolSetPointEnabled: boolean, indoorTemperatureEnabled: boolean, outdoorTemperatureEnabled: boolean, indoorHumidityEnabled:boolean}

@Component({
    selector: 'hvac-carrier-elt-run-time-report',
    templateUrl: './carrier-elt-run-time-report.component.html',
    styleUrls: ['./carrier-elt-run-time-report.component.scss']
})
export class CarrierEltRunTimeReportComponent extends BaseComponent implements OnInit {
  @Input() dealerId: string;
  @Input() systemType: SystemType;
  @Input() serialNo: string;
  @Input() modelName: string;
  @Input() propertyId: string;
  @Output() onReportDownload = new EventEmitter();

  public readonly AppConstants = AppConstants;
  public toastOutlet = 'runtimeReportToast';
  public tempUnit:string|null|undefined;
  public isLoading = false;
  public chartOptions: ChartConfiguration['options'];
  public ecobeeChartOptions: ChartConfiguration['options'];
  public chartType: ChartType = 'line';
  public chartData: null | ChartData<'line'> = null;
  public ecobeeChartData: null | ChartData<'line'> = null;
  public data?: RunTimeReportData;
  public equipmentActivity: { label: string, value: string }[] = [];
  public startDate: number;
  public endDate: number = new Date().getTime();
  public swimlaneChartDataSummary$ = new BehaviorSubject<DataSummary[]>([]);

    public chartLegendFormControlGroup = new UntypedFormGroup(
        {
            heatSetPointEnabled: new UntypedFormControl(true),
            coolSetPointEnabled: new UntypedFormControl(true),
            indoorTemperatureEnabled: new UntypedFormControl(true),
            outdoorTemperatureEnabled: new UntypedFormControl(true)
        }
    )

    public humidChartLegendFormControlGroup = new UntypedFormGroup(
        { indoorHumidityEnabled: new UntypedFormControl(true) }
    )

  public endDateMaxDiffExceededMessage$ = new BehaviorSubject<string | null>(null);
  public wallControlStatus$: Observable<WallControlStatus>;
  public wallControlConfig$: Observable<WallControlConfig>;

  public tempUnitFormat$: Observable<TempUnitFormat>;
  public stageStatus$: Observable<string>;
  public readonly SystemType = SystemType;

  private selectedStartDate: string;
  private selectedEndDate: string;
  private loadChartData$ = new Subject<{startDate: string, endDate: string}>();
  private chartSerialOrEsn: Observable<string>;

  private maxTickCount = {
      mobile: 3,
      tablet: 6
  }

  private pointDivisor = {
      mobile: 3,
      tablet: 1
  }

  constructor(
      private eltReportSvc: CarrierEltReportService,
      private csvExportService: CSVExportService,
      private breakpointService: BreakPointService,
      private chartConfigService: ChartConfigService,
      private productService: ProductService,
      private toastService: ToastService,
      private translateService: TranslateService,
      private activityTrackerService: ActivityTrackerService
  ) {
      super();
  }

  ngOnInit():void {
      this.startDate = this.getStartDate(ECOBEE_MAX_RUNTIME_REPORT_DAY_RANGE);

      this.wallControlConfig$ = this.productService.queryWallControlConfigBySerialNo(this.serialNo, this.dealerId, this.systemType).pipe(
          map((data) => data.data)
      );

      // OBSERVABLES
      this.chartSerialOrEsn = of('').pipe(
          switchMap(() => of(this.serialNo)),
          shareReplay()
      );

      // SUBSCRIPTIONS
      this.wallControlStatus$ = this.productService
          .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
          .pipe(
              map((data) => data.data),
              shareReplay()
          );
      this.stageStatus$ = this.wallControlStatus$.pipe(

          map((data) => data.stageStatus as string)
      );
      this.tempUnitFormat$ = this.wallControlStatus$.pipe(

          map((data) => data.tempUnitFormat as TempUnitFormat)
      );
      combineLatest([this.wallControlConfig$, this.stageStatus$]).pipe(map(([wallConfig, stageStatus]) => {
          this.equipmentActivity.push({
              label: this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_SYSTEM_TYPE.${wallConfig.systemType}`),
              value: this.translateService.instant(`CONNECTED_PORTAL.WALL_CONTROL.CONFIG.CARRIER_ELT.EQUIPMENT_STAGE_STATUS.${stageStatus}`)
          });
      })).subscribe();
      this.chartLegendFormControlGroup
          .valueChanges
          .pipe(
              withLatestFrom(this.tempUnitFormat$),
              takeUntil(this.ngOnDestroy$)
          )
          .subscribe(([enabledDataSets, tempUnitFormat]) => {
              this.configureChart(this.data?.dataSummary || [], enabledDataSets as EnabledDataSets, tempUnitFormat as TempUnitFormat);
          });
      this.humidChartLegendFormControlGroup
          .valueChanges
          .pipe(takeUntil(this.ngOnDestroy$))
          .subscribe((enabledDataSets) => {
              this.configureHumidityChart(this.data?.dataSummary || [], enabledDataSets as EnabledDataSets);
          });

      this.getDataLoader$().pipe(
          takeUntil(this.ngOnDestroy$)
      ).subscribe();
  }

  handleDateRangeChange($event: DateRange) {
      if (!($event.startDate && $event.endDate)) {
          return;
      }

      this.selectedStartDate = new Date($event.startDate).toISOString();
      this.selectedEndDate = new Date($event.endDate).toISOString();

      this.loadChartData$.next({
          startDate: this.selectedStartDate,
          endDate: this.selectedEndDate
      });
  }


  onExportClick() {
      if (this.serialNo) {
          const params = {
              startDate: this.selectedStartDate ? this.selectedStartDate : new Date(this.startDate).toISOString(),
              endDate: this.selectedEndDate ? this.selectedEndDate : new Date(this.endDate).toISOString()
          };

          this.chartSerialOrEsn.pipe(
              tap(() => this.isLoading = true),
              switchMap((serialOrEsn) => this.eltReportSvc.queryELTRuntimeReportBySerialNo(serialOrEsn, params, this.systemType)),
              take(1),
              catchError(() => {
                  this.addErrorToast();
                  this.isLoading = false;

                  return EMPTY;
              })
          )
              .subscribe((res: RunTimeReportResponse) => {
                  this.isLoading = false;
                  const { filepath } = res.data;
                  this.trackActivity();
                  if (filepath) {
                      this.csvExportService.downloadFile(filepath);
                  }
              });
      }
  }

  private trackActivity() {
      this.activityTrackerService.trackActivity({ activityCode: ActivityCode.ExportRuntimeReport })
          .pipe(takeUntil(this.ngOnDestroy$))
          .subscribe();
  }

  private getDataLoader$() {
      return this.loadChartData$.pipe(
          startWith({
              startDate: new Date(this.startDate).toISOString(),
              endDate: new Date(this.endDate).toISOString()
          }),
          tap(() => this.isLoading = true),
          switchMap((params) => this.chartSerialOrEsn.pipe(
              map((serialOrEsn) => ({
                  params,
                  serialOrEsn
              }))
          )),
          switchMap(({ serialOrEsn, params }) => this.eltReportSvc.queryELTRuntimeReportBySerialNo(serialOrEsn, params, this.systemType).pipe(
              withLatestFrom(this.tempUnitFormat$),
              map((res) => (
                  {
                      startEndDate: params,
                      resData: res[0],
                      tempUnit: res[1]
                  }))
          )),
          tap(({ startEndDate, resData, tempUnit }) => {
              this.data = resData.data;
              this.configureChart(this.data?.dataSummary || [], this.chartLegendFormControlGroup.value as EnabledDataSets, tempUnit);
              this.configureHumidityChart(this.data?.dataSummary || [], this.humidChartLegendFormControlGroup.value as EnabledDataSets);
              this.swimlaneChartDataSummary$.next(this.data?.dataSummary || []);
              const { endDate } = startEndDate;
              const endDateMaxDiffExceededMessage = this.data.dataSummary
                  ? this.getEndDateMaxDiffExceededMessage(endDate, this.data.dataSummary)
                  : null;

              this.endDateMaxDiffExceededMessage$.next(endDateMaxDiffExceededMessage);
          }),
          map(({ resData }) => resData.data),
          tap(() => this.isLoading = false),
          catchError(() => {
              this.addErrorToast();
              this.isLoading = false;

              return EMPTY;
          })
      );
  }

  private addErrorToast() {
      this.toastService.removeAll();
      this.toastService.add({
          outletName: this.toastOutlet,
          content: this.translateService.instant('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.TOAST.DATA_ERROR'),
          autoClose: true,
          theme: 'error'
      });
  }

  private getStartDate(daysFromNow: number) {
      return sub(new Date(), { days: daysFromNow }).getTime();
  }

  private getChartLabelsAndDataset(data: DataSummary[]) {
      return [...data]
          .sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime))
          .reduce((acc, curItem) => {
              const label = curItem.dateTime;
              const heatToSetPointValue = curItem.heatToSetPoint;
              const coolToSetPointValue = curItem.coolToSetPoint;
              const indoorTempValue = curItem.indoorTemp;
              const outdoorTempValue = curItem.outdoorTemp;


              return {
                  labels: [...acc.labels, label],
                  heatDataset: [...acc.heatDataset, heatToSetPointValue],
                  coolDataset: [...acc.coolDataset, coolToSetPointValue],
                  indoorTempDataset: [...acc.indoorTempDataset, indoorTempValue],
                  outdoorTempDataset: [...acc.outdoorTempDataset, outdoorTempValue]

              };
          }, {
              labels: [],
              heatDataset: [],
              coolDataset: [],
              indoorTempDataset: [],
              outdoorTempDataset: []

          } as { labels: string[], heatDataset: number[], coolDataset: number[], indoorTempDataset: number[], outdoorTempDataset: number[]});
  }

  private configureChart(data: RunTimeReportData['dataSummary'], enabledDataSets: EnabledDataSets, tempUnit:TempUnitFormat) {
      if (data === null) {
          return;
      }

      const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
      const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
      const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
      const { labels, heatDataset, coolDataset, indoorTempDataset, outdoorTempDataset } = this.getChartLabelsAndDataset(data);


      const dataSets: MultiLineDataSet[] = [{
          id: 'heatSetPointEnabled',
          configOptions: {
              pointBackgroundColor: MultiLineChartColors.White,
              pointBorderColor: MultiLineChartColors.Red,
              borderColor: MultiLineChartColors.Red
          },
          dataSet: heatDataset
      },
      {
          id: 'coolSetPointEnabled',
          configOptions: {
              pointBackgroundColor: MultiLineChartColors.White,
              pointBorderColor: MultiLineChartColors.Blue,
              borderColor: MultiLineChartColors.Blue
          },
          dataSet: coolDataset
      },
      {
          id: 'indoorTemperatureEnabled',
          configOptions: {
              pointBackgroundColor: MultiLineChartColors.White,
              pointBorderColor: MultiLineChartColors.DarkBlue,
              borderColor: MultiLineChartColors.DarkBlue
          },
          dataSet: indoorTempDataset
      },
      {
          id: 'outdoorTemperatureEnabled',
          configOptions: {
              pointBackgroundColor: MultiLineChartColors.White,
              pointBorderColor: MultiLineChartColors.Turquoise,
              borderColor: MultiLineChartColors.Turquoise
          },
          dataSet: outdoorTempDataset

      }].filter((dataSet) => (enabledDataSets)[dataSet.id as keyof typeof enabledDataSets]);

      const yAxis = [...heatDataset, ...coolDataset, ...indoorTempDataset, ...outdoorTempDataset]
          .flat()
          .sort((valueA, valueB) => valueB - valueA);
      const yPadding = 5;

      let tempMax = yPadding;
      let tempMin = -yPadding;

      if (yAxis.length > 0) {
          tempMax = Math.floor(yAxis.shift() as number);
          tempMin = Math.floor(yAxis.pop() as number);
      }

      const yMax = this.getNextMultipleOfTen(tempMax);
      const yMin = this.getLeastMultipleOfTen(tempMin);

      const additionalParams = {
          xScaleMaxTicks,
          chartType: RuntimeChartType.TEMPERATURE,
          tempUnitFormat: tempUnit
      };

      this.chartOptions = this.chartConfigService.getMultiLineChartConfig(yMax, yMin, additionalParams, data.length);
      this.chartData = this.chartConfigService.getMultiLineChartData(labels, dataSets, pointDivisor);
  }

  private getLeastMultipleOfTen(num:number) {
      return Math.floor(num / 10) * 10;
  }

  private getNextMultipleOfTen(num:number) {
      return Math.ceil(num / 10) * 10;
  }

  private getHumidityChartLabelsAndDataset(data: EltDataSummary[]) {
      return [...data]
          .sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime))
          .reduce((acc, curItem) => {
              const label = curItem.dateTime;

              const indoorHumidityValue = curItem.indoorHumidity;

              return {
                  labels: [...acc.labels, label],

                  indoorHumidityDataset: [...acc.indoorHumidityDataset, indoorHumidityValue]
              };
          }, {
              labels: [],

              indoorHumidityDataset: []
          } as { labels: string[], indoorHumidityDataset: number[]});
  }

  private configureHumidityChart(data: RunTimeReportData['dataSummary'], enabledDataSets: EnabledDataSets) {
      if (data === null) {
          return;
      }

      const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
      const xScaleMaxTicks = isLargeScreen ? this.maxTickCount.tablet : this.maxTickCount.mobile;
      const pointDivisor = isLargeScreen ? this.pointDivisor.tablet : this.pointDivisor.mobile;
      const { labels, indoorHumidityDataset } = this.getHumidityChartLabelsAndDataset(data);

      const dataSets: MultiLineDataSet[] = [

          {
              id: 'indoorHumidityEnabled',
              configOptions: {
                  pointBackgroundColor: MultiLineChartColors.White,
                  pointBorderColor: MultiLineChartColors.Orange,
                  borderColor: MultiLineChartColors.Orange
              },
              dataSet: indoorHumidityDataset
          }
      ].filter((dataSet) => (enabledDataSets)[dataSet.id as keyof typeof enabledDataSets]);

      const yAxis = [...indoorHumidityDataset]
          .flat()
          .sort((valueA, valueB) => valueB - valueA);


      const additionalParams = {
          xScaleMaxTicks,
          chartType: RuntimeChartType.HUMIDITY
      };
      const yPadding = 5;

      let tempMax = yPadding;
      let tempMin = -yPadding;

      if (yAxis.length > 0) {
          tempMax = Math.floor(yAxis.shift() as number);
          tempMin = Math.floor(yAxis.pop() as number);
      }

      const yMax = this.getNextMultipleOfTen(tempMax);
      const yMin = this.getLeastMultipleOfTen(tempMin);

      this.ecobeeChartOptions = this.chartConfigService.getMultiLineChartConfig(yMax, yMin, additionalParams);
      this.ecobeeChartData = this.chartConfigService.getMultiLineChartData(labels, dataSets, pointDivisor);
  }

  private getEndDateMaxDiffExceededMessage(endDate: string, reportData: DataSummary[]): string | null {
      const sortedData = [...reportData].sort((itemA, itemB) => itemA.dateTime.localeCompare(itemB.dateTime));
      const reportLastEntryTime = sortedData.pop()?.dateTime;

      const isExceeded = reportLastEntryTime ? differenceInMinutes(new Date(endDate), new Date(reportLastEntryTime).setSeconds(0)) > RUNTIME_REPORT_END_DATE_DIFF_MINUTE_MAX : false;

      if (isExceeded && reportLastEntryTime) {
          const formattedDate = format(new Date(reportLastEntryTime), `${AppConstants.dateTimeFormatShort2}`);

          return this.translateService.instant('CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.MISSING_RUNTIME_INFORMATION', { reportLastEntryTime: formattedDate });
      }

      return null;
  }
}
