<div class="hvac-cp-add-control-container" *ngIf="statusDetails?.status">
    <div [ngSwitch]="statusDetails.status">
        <div *ngSwitchCase="searchControlStatus.CONTROL_FOUND_READY_TO_LINK" class="hvac-cp-add-control">
            <div class="hvac-cp-add-control-info-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.READY_TO_LINK.INFO_TXT" | translate}}</div>
            <div class="hvac-cp-add-control-number">{{statusDetails.controlNumber}}</div>
            <div class="hvac-cp-add-control-info-container">
                <div *ngIf="displayControlFoundReadyToLinkAdditionalInfo" class="hvac-cp-add-control-additional-info">
                    <utc-svg src="assets/icons/info.svg"></utc-svg>
                    <span [innerHTML]="'CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.READY_TO_LINK.ADDITIONAL_INFO' | translate"></span>
                </div>
                <div class="hvac-cp-add-action-section" [ngClass]="{'hvac-cp-add-action-section-right-align': !isDisableNotificationEnabled}">
                    <div *ngIf="isDisableNotificationEnabled" class="hvac-cp-add-action-section-checkbox-container">
                        <utc-checkbox [inputControl]="disableNotificationControl"></utc-checkbox>
                        <span class="selection-text">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.READY_TO_LINK.USER_ACK_MSG" | translate}}</span>
                    </div>
                    <button hvacbutton="primary"
                        class="hvac-button hvac-button-primary hvac-button-small"
                        (click)="addDealerControl()"
                        buttonType="button"
                    >
                        <span>{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.ACTIONS.ADD_CONTROL" | translate}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div *ngSwitchCase="searchControlStatus.CONTROL_LINKED_TO_OTHER_DEALER" class="hvac-cp-add-control">
            <div class="hvac-cp-add-control-info-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.LINKED_TO_OTHER_DEALER.INFO_TXT" | translate}}</div>
            <div class="hvac-cp-add-control-info-txt cp-txt-gutter">{{statusDetails.controlNumber}}</div>
            <div class="hvac-cp-add-control-info-txt-bold">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.LINKED_TO_OTHER_DEALER.CONFIRM_TXT" | translate}}</div>
            <div class="hvac-cp-add-action-section linked-to-other">
                <div class="hvac-cp-add-action-section-checkbox-container">
                    <utc-checkbox [inputControl]="linkToOtherDealerControl"></utc-checkbox>
                    <span class="selection-text">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.LINKED_TO_OTHER_DEALER.USER_ACK_MSG" | translate}}</span>
                </div>

                <button hvacbutton="primary"
                    class="hvac-button hvac-button-primary hvac-button-small"
                    (click)="addDealerControl()"
                    [disabled]="!userAcknowledgement.onControlLinkedToOtherDealer"
                    buttonType="button"
                >
                    <span>{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.ACTIONS.ADD_CONTROL" | translate}}</span>
                </button>
            </div>
        </div>

        <div *ngSwitchCase="searchControlStatus.CONTROL_NOT_REGISTERED" class="hvac-cp-add-control">
            <div class="hvac-cp-add-control-info-txt">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.CONTROL_NOT_REGISTERED.INFO_TXT" | translate}}</div>
            <div class="hvac-cp-add-control-info-txt-bold cp-txt-gutter">{{ "CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.CONTROL_NOT_REGISTERED.ADDITIONAL_INFO" | translate }}</div>
            <div class="hvac-cp-add-control-info-txt cp-txt-gutter">
                <span class="hvac-cp-add-control-info-txt-bold">{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.CONTROL_NOT_REGISTERED.NOTE_TITLE" | translate}}</span>
                {{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.CONTROL_NOT_REGISTERED.NOTE_TXT" | translate}}
            </div>
            <div class="hvac-cp-add-action-section">
                <button hvacbutton="primary"
                    class="hvac-button hvac-button-primary hvac-button-small"
                    (click)="addDealerControl()"
                    buttonType="button"
                >
                    <span>{{"CONNECTED_PORTAL.ADD_NEW_CONTROL.ADD_CONTROL.ACTIONS.ADD_CONTROL" | translate}}</span>
                </button>
            </div>
        </div>
    </div>

    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
</div>
