<ng-template [ngIf]="isUserDetailsAvailable" [ngIfElse]="spinner">
    <div class="hvac-content-container">
        <div [ngClass]="'hvac-editable-user-info'">
            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.USERNAME' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">
                                {{user.userID | textFallback}}
                            </div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userID.dirty && !userAdminService.userEditForm.controls.userID.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FIRST_NAME' | translate" [max]="40"
                                [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.userID"
                                type="text" shadow="none" [disabledInput]="true">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.FIRST_NAME' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">
                                {{user.firstName | textFallback}}
                            </div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.firstName.dirty && !userAdminService.userEditForm.controls.firstName.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FIRST_NAME' | translate" [max]="40"
                                [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.firstName"
                                type="text" shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.LAST_NAME' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.lastName | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.lastName.dirty && !userAdminService.userEditForm.controls.lastName.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.LAST_NAME' | translate" [max]="40"
                                [hideLabel]="true" [inputControl]="userAdminService.userEditForm.controls.lastName"
                                type="text" shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.TITLE' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userTitle | textFallback }}</div>

                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userTitle.dirty && !userAdminService.userEditForm.controls.userTitle.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_TITLE' | translate" [hideLabel]="true"
                                [max]="40" [inputControl]="userAdminService.userEditForm.controls.userTitle" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_NAME' | translate}}
                                <button type="button" class="hvac-user-text-button" hvacButton="text"
                                    (click)="onUserCompanyChangeClick()">
                                    {{ 'USER_ADMIN.CHANGE_COMPANY' | translate}}
                                </button>
                            </div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.employerName | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.employerName.dirty && !userAdminService.userEditForm.controls.employerName.valid"
                                [hideLabel]="true" [disabledInput]="true"
                                [inputControl]="userAdminService.userEditForm.controls.employerName" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.EMPLOYER_ID' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.companyId | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.companyId.dirty && !userAdminService.userEditForm.controls.companyId.valid"
                                [disabledInput]="true" [hideLabel]="true"
                                [inputControl]="userAdminService.userEditForm.controls.companyId" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hvac-divider"></div>

            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.ADDRESS1' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userAddress1 | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userAddress1.dirty && !userAdminService.userEditForm.controls.userAddress1.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS1' | translate"
                                [hideLabel]="true" [max]="60"
                                [inputControl]="userAdminService.userEditForm.controls.userAddress1" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.ADDRESS2' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userAddress2 | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userAddress2.dirty && !userAdminService.userEditForm.controls.userAddress2.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.USER_ADDRESS2' | translate"
                                [hideLabel]="true" [max]="60"
                                [inputControl]="userAdminService.userEditForm.controls.userAddress2" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.CITY' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userCity | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userCity.dirty && !userAdminService.userEditForm.controls.userCity.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.CITY' | translate" [hideLabel]="true"
                                [max]="40" [inputControl]="userAdminService.userEditForm.controls.userCity" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.COUNTRY'
                                | translate}}</div>
                            <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                [error]="userAdminService.userEditForm.controls.userCountry.dirty && !userAdminService.userEditForm.controls.userCountry.valid"
                                [shadow]="'none'" (onChange)="onCountryChange($event)"
                                [group]="userAdminService.userEditForm" control="userCountry"
                                [options]="(countryOptionData$ | async) || []">
                            </utc-select>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.STATE_PROVINCE' |
                                translate}}</div>
                            <utc-select class="hvac-col-xs hvac-account-admin-edit-value"
                                [error]="userAdminService.userEditForm.controls.userState.dirty && !userAdminService.userEditForm.controls.userState.valid"
                                [shadow]="'none'" (onChange)="onStateChange($event)"
                                [group]="userAdminService.userEditForm" control="userState"
                                [options]="(stateOptionData$ | async) || []">
                            </utc-select>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.ZIP_POSTAL_CODE' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userPostalCode | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userPostalCode.dirty && !userAdminService.userEditForm.controls.userPostalCode.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.ZIP_CODE' | translate" [hideLabel]="true"
                                [max]="10" [inputControl]="userAdminService.userEditForm.controls.userPostalCode"
                                type="text" shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hvac-divider"></div>

            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.OFFICE_PHONE' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userPhone | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userPhone.dirty && !userAdminService.userEditForm.controls.userPhone.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.OFFICE_PHONE' | translate"
                                [hideLabel]="true" [max]="20"
                                [inputControl]="userAdminService.userEditForm.controls.userPhone" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.MOBILE_NUMBER' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userCellPhone | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userCellPhone.dirty && !userAdminService.userEditForm.controls.userCellPhone.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER' | translate"
                                [hideLabel]="true" [max]="20"
                                [inputControl]="userAdminService.userEditForm.controls.userCellPhone" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.FAX_NUMBER' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.userFax | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.userFax.dirty && !userAdminService.userEditForm.controls.userFax.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER' | translate" [hideLabel]="true"
                                [max]="20" [inputControl]="userAdminService.userEditForm.controls.userFax" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label" [ngClass]="'hvac-required'">{{
                                'ACCOUNT_ADMIN.EMAIL_ADDRESS' | translate}}</div>
                            <div class="hvac-col-xs hvac-account-admin-detail-list-value hvac-non-edit-mode">{{
                                user.email | textFallback }}</div>
                            <utc-input class="hvac-col-xs hvac-account-admin-edit-value hvac-edit-mode"
                                [error]="userAdminService.userEditForm.controls.email.dirty && !userAdminService.userEditForm.controls.email.valid"
                                [placeholder]="'USER_ADMIN.PLACEHOLDER_TEXT.EMAIL' | translate" [hideLabel]="true"
                                [max]="100" [inputControl]="userAdminService.userEditForm.controls.email" type="text"
                                shadow="none">
                            </utc-input>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hvac-divider"></div>

            <div class="hvac-account-admin-form-header hvac-account-admin-heading hvac-account-admin-heading-default">
                <h1 class="hvac-h4">
                    {{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.REGISTRATION_ACTIONS' | translate }}
                </h1>
            </div>

            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.ACTION' |
                                translate}}</div>
                            <utc-select class="hvac-col-xs hvac-account-admin-edit-value" [shadow]="'none'"
                                [error]="userAdminService.userEditForm.controls.registrationAction.dirty && !userAdminService.userEditForm.controls.registrationAction.valid"
                                [group]="userAdminService.userEditForm" control="registrationAction"
                                (onChange)="onActionChange($event)" [options]="(actionOptionData$ | async) || []">
                            </utc-select>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="hvac-row">
                <div class="hvac-col-xs-12 hvac-col-lg-14 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-2 hvac-label">{{ 'ACCOUNT_ADMIN.COMMENTS' |
                                translate}}</div>
                            <utc-textarea class="hvac-col-xs hvac-account-admin-edit-value hvac-comment-box" shadow="none"
                                [placeholder]="'ACCOUNT_ADMIN.COMMENTS' | translate"
                                [maxLength]="maxCommentLimit"
                                [inputControl]="userAdminService.userEditForm.controls.comments"></utc-textarea>
                        </li>
                    </ul>
                </div>
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-account-admin-details-col">
                    <ul class="hvac-account-admin-detail-list">
                        <li class="hvac-row hvac-top-xs">
                            <div class="hvac-col-xs-5 hvac-label">
                                <button type="button" class="hvac-user-text-button" hvacButton="text" 
                                (click)="onViewRegHistoryClick(viewRegHistoryClick)">
                                    <ng-container *ngIf="viewRegHistoryClick; else hideLabel">{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.HIDE_REG_HISTORY' | translate}}</ng-container>
                                    <ng-template #hideLabel>{{ 'ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.VIEW_REG_HISTORY' | translate}}</ng-template>
                                </button>
                            </div>
                    </ul>
                </div>
            </div>

            <div class="hvac-row" *ngIf="viewRegHistoryClick">
                <div class="hvac-col-xs-12 hvac-col-lg-14 hvac-account-admin-details-col">
                    <hvac-table class="havc-user-registration-details hvac-program-table-wrap" [theme]="'zebra'" [responsive]="false">
                        <thead>
                            <tr>
                                <td>{{registrationHistoryDataHeaders[0].title}}</td>
                                <td>{{registrationHistoryDataHeaders[1].title}}</td>
                                <td>{{registrationHistoryDataHeaders[2].title}}</td>
                                <td>{{registrationHistoryDataHeaders[3].title}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let registrationHistory of registrationHistories.crntPgUIData; let row=even;">
                                <td [attr.data-label]="registrationHistoryDataHeaders[0].title">
                                    {{registrationHistory.data.date | date:'MM/dd/yyyy h:mm:ss a'}}
                                </td>
                                <td [attr.data-label]="registrationHistoryDataHeaders[1].title">
                                    {{registrationHistory.data.submittedBy}}
                                </td>
                                <td [attr.data-label]="registrationHistoryDataHeaders[2].title">
                                    {{registrationHistory.data.status}}
                                </td>
                                <td [attr.data-label]="registrationHistoryDataHeaders[3].title">
                                    {{registrationHistory.data.comment}}
                                </td>
                            </tr>
                        </tbody>
                    </hvac-table>
                </div>
            </div>
            <div class="hvac-row" *ngIf="viewRegHistoryClick">
                <hvac-pagination
                    [useUIData]="true"
                    [UIData]="registrationHistories.dataFrPgntn"
                    [globalDataHolder]="registrationHistories.dataFrPgntn"
                    [currentPage]="registrationHistories.crntPgNum"
                    [totalPages]="registrationHistories.pgsCount"
                    (pageChange)="registrationHistories.onPageChange($event)"
                ></hvac-pagination>
            </div>

            <div class="hvac-row hvac-account-admin-detail-footer hvac-flex-end">
                <button class="hvac-edit-button" type="button" [hvacButton]="'default'" (click)="onCancelClick()">
                    <span>{{ "ACCOUNT_ADMIN.CANCEL" | translate }}</span>
                </button>
                <button *ngIf="actionValue === 'Approve'" type="button" class="hvac-edit-button" hvacButton="primary" [disabled]="!userAdminService.userEditForm.valid" (click)="onContinueClick()">
                        {{ "FOOTER.CONTINUE" | translate}}
                </button>
                <button *ngIf="actionValue !== 'Approve'" type="button" class="hvac-edit-button" hvacButton="primary" [disabled]="!userAdminService.userEditForm.valid|| actionValue === undefined || actionValue === 'Select'" (click)="onSaveClick()">
                    {{ "FOOTER.SAVE" | translate}}
                </button>
            </div>
        </div>

    </div>
</ng-template>
<ng-template [ngIf]="userCompanyEditMode">
    <div class="x-overflow-hidden">
        <utc-modal [theme]="'rounded'" [visible]="true" [size]="'dynamic-height'"
            (closeAction)="searchCompanyModalClose()">
            <hvac-modal-header>
                <h1>{{ "USER_ADMIN.USER_DETAILS.SEARCH_USER_IN_COMPANY" | translate }}</h1>
            </hvac-modal-header>
            <hvac-create-user-company-search-form [selectedCompanyTypeName]="userCompanyTypeName"
                [selectedCompanyTypeCode]="userCompanyTypeCode" [isCompanyTypeReadOnlyField]="true"
                [clickClearForm]="onClickClearSearch" (onClickSearch)="onCompanySearchClick()">
            </hvac-create-user-company-search-form>
            <hvac-create-user-company-table *ngIf="isCompanyListDataAvailable"
                [onCompanySelect]="onCompanySelect"></hvac-create-user-company-table>
        </utc-modal>
    </div>
</ng-template>

<ng-template #spinner>
    <utc-loading-spinner></utc-loading-spinner>
</ng-template>
