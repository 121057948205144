import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/company.model';
import { ProductType, SystemType } from 'private/app/models/connected-product.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { combineLatest, concat, Observable, of } from 'rxjs';
import { catchError, concatAll, map, shareReplay, switchMap } from 'rxjs/operators';
import { MISSING_ROUTE_PARAMS_ERROR } from '../../../constants';
import { ErrorService } from 'common/content/services/error.service';
import { findSystemBySerialNo, findSystemProductByType } from 'private/app/services/connected-portal/utils';

export interface PageRouteData {
    dealerId: string;
    propertyId: string;
    systemType: SystemType;
    serialNo: string;
    productType: ProductType
}

@Component({
    selector: 'hvac-connected-portal-warranty-info',
    templateUrl: './connected-portal-warranty-info.component.html',
    styleUrls: ['./connected-portal-warranty-info.component.scss']
})
export class ConnectedPortalWarrantyInfoComponent implements OnInit {
    public breadcrumbs$: Observable<ContentHeadingData>;
    public detailUrl$: Observable<string>;
    public companyCode$: Observable<CompanyCode>;
    public serialNo$: Observable<string>;

    public readonly CompanyCode = CompanyCode;

    constructor(
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private dealersService: DealersService,
        private connectedPortalUserService: ConnectedPortalUserService,
        private errorService: ErrorService
    ) {}

    ngOnInit(): void {
        if (!this.route.parent) {
            this.errorService.errorStatus$.next(404);

            return;
        }

        const routeData$ = combineLatest([this.route.parent.paramMap, this.route.paramMap, this.route.queryParams])
            .pipe(
                map(([parentParams, params, queryParams]) => {
                    const dealerId = parentParams.get('id');
                    const propertyId = params.get('id');
                    const serialNo = params.get('serialNo');
                    const systemType = params.get('systemType') as SystemType | null;
                    const productType = queryParams['productType'] as ProductType | null;

                    if (dealerId && propertyId && serialNo && systemType && productType) {
                        return {
                            dealerId,
                            propertyId,
                            serialNo,
                            systemType,
                            productType
                        };
                    }

                    throw new Error(MISSING_ROUTE_PARAMS_ERROR);
                }),
                catchError((err) => {
                    if (err === MISSING_ROUTE_PARAMS_ERROR) {
                        this.errorService.errorStatus$.next(404);
                    }

                    throw err;
                }),
                shareReplay()
            );

        const system$ = routeData$.pipe(
            switchMap(({ propertyId, serialNo, dealerId }) => this.dealersService.querySystemsByPropertyId(propertyId, dealerId).pipe(
                map((systemsRes) => findSystemBySerialNo(systemsRes.data, serialNo))
            )),
            shareReplay()
        );

        this.serialNo$ = routeData$.pipe(map(({ serialNo }) => serialNo));

        this.detailUrl$ = combineLatest([routeData$, system$]).pipe(
            map(([routeData, system]) => {
                const { dealerId, propertyId, systemType, productType } = routeData;

                if (system) {
                    const wallControlSerial = findSystemProductByType(system, ProductType.WallControl)?.serialNo;

                    if (wallControlSerial) {
                        return this.getControlDetailUrl(productType, dealerId, propertyId, systemType, wallControlSerial);
                    }
                }

                return '';
            })
        );

        this.breadcrumbs$ = combineLatest([routeData$, this.detailUrl$]).pipe(
            switchMap(([routeData, detailUrl]) => {
                const { dealerId, propertyId } = routeData;

                const basic = of(this.getBreadcrumbData(detailUrl, dealerId, propertyId));
                const withDealerName = this.dealersService.queryDealerById(dealerId).pipe(
                    map((res) => {
                        const { name: dealerName } = res.data;

                        return this.getBreadcrumbData(detailUrl, dealerId, propertyId, dealerName);
                    })
                );

                return concat([basic, withDealerName]);
            }),
            concatAll()
        );

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((companyCode) => companyCode as CompanyCode)
        );
    }

    public getControlDetailUrl(productType: ProductType, dealerId: string, propertyId: string, systemType: string, serialNo: string): string {
        switch (productType) {
            case ProductType.WallControl:
                return `/connected-portal/dealers/${dealerId}/customers/${propertyId}/products/wall-control/${systemType}/${serialNo}`;
            case ProductType.IndoorUnit:
                return `/connected-portal/dealers/${dealerId}/customers/${propertyId}/products/indoor-unit/${systemType}/${serialNo}`;
            case ProductType.OutdoorUnit:
                return `/connected-portal/dealers/${dealerId}/customers/${propertyId}/products/outdoor-unit/${systemType}/${serialNo}`;
            default:
                return '';
        }
    }

    private getBreadcrumbData(
        detailUrl: string,
        dealerId: string,
        propertyId: string,
        dealerName?: string
    ) {
        const data = {
            Content: {
                title: this.translateService.instant(
                    'CONNECTED_PORTAL.PAGE_HEADINGS.WARRANTY_LOOKUP'
                )
            },
            breadCrumbs: [
                {
                    title: this.translateService.instant(
                        'CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'
                    ),
                    url: `/connected-portal`
                },
                {
                    title: dealerName
                        ? dealerName
                        : this.translateService.instant(
                            'CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'
                        ),
                    url: `/connected-portal/dealers/${dealerId}`
                },
                {
                    title: this.translateService.instant(
                        'CONNECTED_PORTAL.PAGE_HEADINGS.CUSTOMERS'
                    ),
                    url: `/connected-portal/dealers/${dealerId}/customers`
                },
                {
                    title: this.translateService.instant(
                        'CONNECTED_PORTAL.PAGE_HEADINGS.PROPERTY_DETAILS'
                    ),
                    url: `/connected-portal/dealers/${dealerId}/customers/${propertyId}`
                },
                {
                    title: this.translateService.instant(
                        'CONNECTED_PORTAL.PAGE_HEADINGS.CONTROL_DETAILS'
                    ),
                    url: detailUrl
                }
            ]
        };

        return data;
    }
}
