<div class="hvac-connected-control-report">
    <hvac-report-chart-container
        [chartTitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.CONNECTED_CONTROLS' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.MONTH_MONTH_ANALYSIS' | translate"
        [hasData]="!!(chartData && data?.dataSummary)" [isLoading]="isLoading" yearSelectId="hvac-connected-control-report-year-select"
        [yearSelectFormControl]="yearSelectFormControl" [yearSelectOptions]="yearSelectOptions"
        [isReportButtonDisabled]="!data?.filepath"
        (onRunReport)="handleRunReport()"
        >
        <canvas *ngIf="chartData" baseChart class="hvac-chart" [data]="chartData" [options]="chartOptions" [type]="chartType"></canvas>
    </hvac-report-chart-container>
</div>
