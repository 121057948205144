import { SearchQuery } from 'common/models';

export interface Bookmark {
    id: string;
    caption: string;
    link: string;
    queryParams?: SearchQuery;
}

export interface BookmarkPostData {
    caption: string;
    link: string;
}
export interface BookmarkStateModel {
    bookmarks: Array<Bookmark> | [];
}
export class FetchBookmarks {
    static readonly type = '[Bookmarks] FetchBookmarks';
}

export class RecieveBookmarks {
    static readonly type = '[Bookmarks] RecieveBookmarks';
    constructor(public readonly bookmarks: Array<Bookmark>) {}
}

export class FetchAddBookmark {
    static readonly type = '[Bookmarks] FetchAddBookmark';
    constructor(public readonly bookmarkData: BookmarkPostData) {}
}

export class RecieveAddBookmark {
    static readonly type = '[Bookmarks] RecieveAddBookmark';
    constructor(public readonly bookmark: Bookmark) {}
}

export class FetchDeleteBookmark {
    static readonly type = '[Bookmarks] FetchDeleteBookmark';
    constructor(public readonly bookmark: Bookmark) {}
}

export class RecieveDeleteBookmark {
    static readonly type = '[Bookmarks] RecieveDeleteBookmarks';
    constructor(public readonly bookmark: Bookmark) {}
}
