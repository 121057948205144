import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'utc-product-attribute-group',
    templateUrl: './product-attribute-group.component.html',
    styleUrls: ['./product-attribute-group.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductAttributeGroupComponent {
    @Input() attributeGroupTitle = '';
    @Input() attributes:{
        type: {
            name: string;
            unitOfMeasure: string;
        },
        value: string;
    }[] = [];
}
