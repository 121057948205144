<div>
    <section class="hvac-section-container hvac-dealer-resources">
        <div class="hvac-cp-dealer-resources">
            <h2 class="hvac-h3 hvac-cp-dealer-resources-title">
                {{'CONNECTED_PORTAL.RESOURCES.HELPFUL_RESOURCES' | translate}}
            </h2>
            <div class="dealer-resources-links">
                <a target='_blank' class="hvac-text-link" href="https://files.hvacpartners.com/docs/1010/ValCust/02/01-TSTAT-079-01.pdf">{{'CONNECTED_PORTAL.RESOURCES.CARRIER_RESOURCES' | translate}}</a>
            </div>
            <div class="dealer-resources-links">
                <a target='_blank' class="hvac-text-link" href="https://files.hvacpartners.com/docs/1010/ValCust/08/01-8110-1500-01.pdf">{{'CONNECTED_PORTAL.RESOURCES.BRYANT_RESOURCES' | translate}}</a>
            </div>
        </div>
    </section>
</div>
