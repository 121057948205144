import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrameContentComponent } from './frame-content.component';
import { Auth } from 'private/app/guards/auth.guard';

const routes: Routes = [
    {
        path: '**',
        component: FrameContentComponent,
        canActivate: [Auth]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FrameContentRoutingModule {
}
