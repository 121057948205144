<div class="hvac-cp-notification">
    <hvac-table [responsive]="true">
        <thead class="hvac-cp-notification-table-header">
            <tr class="hvac-cp-notification-table-header-row">
                <th class="hvac-p-sm hvac-fw-sb">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.TYPE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.CODE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.MESSAGE' | translate}}
                </th>
                <th class="hvac-p-sm hvac-fw-sb">
                    {{'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.SERVER_TIME_STAMP' | translate}}
                </th>
            </tr>
        </thead>
        <tbody class="hvac-cp-notification-body">
            <tr *ngFor="let notification of data" class="hvac-cp-notification-items">
                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.TYPE' | translate" class="hvac-p-sm hvac-cp-notification-item">{{ notification?.type }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.CODE' | translate" class="hvac-p-sm hvac-cp-notification-item">{{ notification?.code }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.MESSAGE' | translate" class="hvac-p-sm hvac-cp-notification-item">{{ notification?.message }}</td>
                <td [attr.data-label]="'CONNECTED_PORTAL.WALL_CONTROL.NOTIFICATION.SERVER_TIME_STAMP' | translate" class="hvac-p-sm hvac-cp-notification-item">
                    <div>
                        <div class="hvac-fw-sb hvac-rhythm-1">{{ notification?.dateTime | dateTimeTz: 'date' }}</div>
                        <div>{{ notification?.dateTime | dateTimeTz: 'time' }}</div>
                    </div>
                </td>
            </tr>
        </tbody>
    </hvac-table>
    <div class="hvac-cp-list-footer">
        <div class="hvac-cp-list-footer-pagination">
            <hvac-pagination
                *ngIf="totalPages > 1"
                [totalPages]="totalPages"
                [currentPage]="currentPage"
                (pageChange)="handlePageChange($event)">
            </hvac-pagination>
        </div>
    </div>
</div>
