<div class="hvac-wall-control-system-info">
    <hvac-product-property-list>
        <hvac-product-property-list-item *ngFor="let prop of systemData">
            <hvac-product-property-list-item-label>
                {{ prop.label }}
            </hvac-product-property-list-item-label>
            <hvac-product-property-list-item-value>{{ prop.value }}</hvac-product-property-list-item-value>
        </hvac-product-property-list-item>
    </hvac-product-property-list>
</div>

