<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
    </div>

    <div bottom>
        <hvac-resources-page></hvac-resources-page>
    </div>
</hvac-connected-portal-layout-container>
