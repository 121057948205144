<div class='hvac-login-hero'>
    <img *ngIf="(brandLogo$ | async)" class="hvac-login-hero-logo" [src]="(brandLogo$ | async)" alt="{{ 'BRAND' | translate}}" />
    <div class="hvac-login-hero-intro">
        <h1 class="hvac-login-hero-title">{{ 'LOGIN_PAGE.INTRO_TITLE' | translate }}</h1>
        <p class="hvac-login-hero-description">{{ 'LOGIN_PAGE.INTRO_PARAGRAPH' | translate }}</p>
    </div>
    <div class="hvac-login-hero-support">
        <ng-container *ngIf="!(brandLogo$ | async)">
            <p class="hvac-login-hero-brand-support">{{ 'LOGIN_PAGE.BRANDS_SUPPORTED' | translate }}</p>
            <ul class="hvac-login-hero-brand-list">
                <li class="hvac-login-hero-brand"><a href="https://carrier.hvacpartners.com/" routerLinkActive="active" target="_blank" rel="{{ 'BRANDS.CARRIER' | translate }}"><img alt="{{ 'BRANDS.CARRIER' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/carrier-login-logo-sm.png'"/></a></li>
                <li class="hvac-login-hero-brand"><a href="http://bryant.hvacpartners.com/" routerLinkActive="active" target="_blank" rel="{{ 'BRANDS.BRYANT' | translate }}"><img alt="{{ 'BRANDS.BRYANT' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/bryant-login-logo-sm.png'"/></a></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.AIRQUEST' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/airquest-logo.svg'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.ARCOAIRE' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/arcoaire-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.COMFORTMAKER' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/comfortmaker-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.DAY_AND_NIGHT' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/day-and-night-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.HEIL' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/heil-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.KEEPRITE' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/keeprite-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.MIDEA' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/midea-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.PAYNE' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/payne-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.SMARTCOMFORT' | translate }}" class="hvac-login-hero-brand-logo"
                        [src]="'assets/images/smart-comfort-logo.png'" /></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.TEMPSTAR' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/tempstar-logo.png'"/></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.FACTORY_AUTHRORIZED_PARTS' | translate }}"
                        class="hvac-login-hero-brand-logo" [src]="'assets/images/factory-authorized-parts-logo.png'" /></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.TOTALINE' | translate }}" class="hvac-login-hero-brand-logo"
                        [src]="'assets/images/totaline-logo.png'" /></li>
                <li class="hvac-login-hero-brand"><img alt="{{ 'BRANDS.FAST' | translate }}" class="hvac-login-hero-brand-logo" [src]="'assets/images/fast-logo-oem.png'"/></li>
                <li class="hvac-login-hero-brand hide"><img alt="{{ 'BRANDS.FAST' | translate }}" class="hvac-login-hero-brand-logo"
                        [src]="'assets/images/fast-logo.png'" /></li>
            </ul>
        </ng-container>
        <div class="hvac-login-hero-button-row">
            <button hvacButton="primary" (click)="onSignUpClick()">{{ 'LOGIN_PAGE.SIGN_UP' | translate }}</button>
        </div>
    </div>
</div>
