import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CharSymbol } from 'common/enums/char-symbol';
import { ValueRange } from 'common/models/value-range';
import { ActivityMap, Period, Program, SystemConditionsParamsConfig, WallControlDetails, WallControlZoning, Zone } from 'private/app/models/wall-control.model';
import { isValueOutOfRange } from 'private/app/services/connected-portal/utils';
import { ECOBEE_TRANSLATION_VALUE_KEYS } from '../../../constants';

@Injectable()
export class SystemConditionsService {
    constructor(
        private translateService: TranslateService
    ) {}

    public getInfinityWallControlDetails(wallControlDetails: WallControlDetails) {
        return {
            ...wallControlDetails,
            userSetting: this.formatDisplayLabel(wallControlDetails.userSetting)
        };
    }

    public getEcobeeWallControlDetails(wallControlDetails: WallControlDetails, systemConditionsConfig: SystemConditionsParamsConfig, zoneName: string | null) {
        const { indoorHumidity, outdoorHumidity, outdoorTemp, zones, ...otherDetails } = wallControlDetails;

        const isOutOfRangeRef = { value: false };

        const outOfRangeFormattedData: WallControlDetails = {
            indoorHumidity: this.checkOutOfRangeAndFormat(indoorHumidity, CharSymbol.DoubleDash, systemConditionsConfig.indoorHumidity, isOutOfRangeRef),
            outdoorHumidity: this.checkOutOfRangeAndFormat(outdoorHumidity, CharSymbol.DoubleDash, systemConditionsConfig.outdoorHumidity, isOutOfRangeRef),
            outdoorTemp: this.checkOutOfRangeAndFormat(outdoorTemp, CharSymbol.DoubleDash, systemConditionsConfig.outdoorTemperature, isOutOfRangeRef),
            zones: zones.map((zone) => {
                if (zone.name === zoneName) {
                    const { indoorTemp, coolTo, heatTo, ...otherVal } = zone;

                    return {
                        indoorTemp: this.checkOutOfRangeAndFormat(indoorTemp, CharSymbol.DoubleDash, systemConditionsConfig.indoorTemperature, isOutOfRangeRef),
                        coolTo: this.checkOutOfRangeAndFormat(coolTo, CharSymbol.DoubleDash, systemConditionsConfig.coolToSetPoint, isOutOfRangeRef),
                        heatTo: this.checkOutOfRangeAndFormat(heatTo, CharSymbol.DoubleDash, systemConditionsConfig.heatToSetPoint, isOutOfRangeRef),
                        ...otherVal
                    };
                }

                return zone;
            }),
            ...otherDetails
        };

        return {
            data: {
                ...outOfRangeFormattedData,
                userSetting: this.formatDisplayLabel(wallControlDetails.userSetting)
            },
            hasOutOfRangeValues: isOutOfRangeRef.value
        };
    }

    public getCarrierEltWallControlDetails(wallControlDetails: WallControlDetails) {
        return {
            ...wallControlDetails,
            userSetting: this.formatDisplayLabel(wallControlDetails.userSetting)
        };
    }

    public getUniqueProgramListTimes(programs: Program[]): string[] {
        const times = programs.map((day) => day.periods.map((period) => period.time)).flat();

        return Array.from(new Set(times));
    }

    public getActiveTime(allTimes: string[], statusLocalTime: string): string {
        const date = new Date(statusLocalTime);

        const localStatusTimeString = parseInt(date.toLocaleTimeString('en-US', { hour12: false })
            .split(':').slice(0, -1).join(''), 10);

        const timesAsNumbers = allTimes.map((timeString) => parseInt(timeString.split(':').join(''), 10)).sort((a, b) => a - b);

        const active = timesAsNumbers.find((time, idx) => {
            const time1 = time;
            const time2 = timesAsNumbers[idx + 1];

            return localStatusTimeString >= time1 &&
                localStatusTimeString <= time2;
        }) || '';

        return active.toString();
    }

    public getZoneActivities(zone: Zone, wallControlZoning?: WallControlZoning): (ActivityMap | null)[] | null {
        const activities = this.getActivityMap(zone);
        const manualActivityData = zone.activities?.find((activity) => activity?.name === 'manual');

        if (manualActivityData) {
            activities?.push({
                ...manualActivityData,
                time: wallControlZoning?.overrideUntilTime ? String(wallControlZoning?.overrideUntilTime) : ''
            });
        }

        return activities;
    }

    private getActivityMap(zoneDetails: Zone): (ActivityMap | null)[] | null {
        const { programs, activities } = zoneDetails;
        const uniqueTimes: string[] = [];

        const mappedData = programs?.map((day) => day.periods.map((period) => {
            if (!uniqueTimes.includes(period.time)) {
                uniqueTimes.push(period.time);

                const activity = activities?.find((activity2) => activity2.name === period.activity);

                return {
                    name: period.activity,
                    time: period.time,
                    cool: activity?.cool || '0',
                    heat: activity?.heat || '0',
                    fan: activity?.fan || '',
                    days: this.mapDays(programs, period)
                };
            }

            return null;
        }).filter(Boolean).flat())
            .filter(Boolean)
            .flat()
            .sort((activityA, activityB) => parseInt(activityA?.time || '', 10) - parseInt(activityB?.time || '', 10));

        return mappedData?.filter((mappedActivity) => mappedActivity?.days?.find((day) => day.enabled === 'on')) || null;
    }

    private formatDisplayLabel(value: string) {
        if (typeof value === 'string' && ECOBEE_TRANSLATION_VALUE_KEYS.includes(String(value))) {
            const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_PROP_VALUES.${value}`;

            return this.translateService.instant(translationKey);
        }

        return value;
    }

    private mapDays(zonePrograms: Zone['programs'], period: Period) {
        return zonePrograms?.map((program) => ({
            dayOfWeek: program.dayOfWeek,
            enabled: program.periods.find((period2) => period2.activity === period.activity && period2.time === period.time)?.enabled || 'off'
        }));
    }

    private checkOutOfRangeAndFormat(value: null | undefined | string, char: string, configRange: ValueRange, isOutOfRangeRef: { value: boolean }): string {
        const isValid = this.isValidValue(value, configRange);

        if (!isValid) {
            isOutOfRangeRef.value = true;
        }

        return isValid ? value as string : char;
    }

    private isValidValue(value: null | undefined | string, configRange: ValueRange) {
        if (typeof value === 'undefined'
            || value === null
            || String(value).trim() === ''
            || isValueOutOfRange(Number(value), configRange)
        ) {
            return false;
        }

        return true;
    }
}
