import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { PrivateAppRouteData } from 'private/app/app-routing.module';

@Injectable({ providedIn: 'root' })
export class EnvironmentFeaturesGuard implements CanActivate {
    constructor(
        private readonly store: Store
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean {
        const { envFeature } = route.data as PrivateAppRouteData;
        if (envFeature) {
            return true;
        }

        this.store.dispatch(new Navigate(['/']));

        return false;
    }
}
