import { Injectable } from '@angular/core';
import { AccountStatusService } from 'common/services/account-status.service';
import { ProgramDetails } from '../models/account-admin-program.model';
import { AccountStatusResponse } from 'common/models/account-status';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ManageEnrollmentsService {
    public showDropdowns$ = new BehaviorSubject<Boolean>(false);
    public userCompanyType: string;
    public finalApprovedByType: string | null;
    public enrollmentWorkflow: string | null;
    constructor(
        public translate: TranslateService,
        private readonly statusService: AccountStatusService
    ) { }

    checkDropdownVisibility(data : ProgramDetails) {
        this.statusService.getUserStatus().subscribe((result: AccountStatusResponse) => {
            this.userCompanyType = (result.company.companyType.name);
            this.finalApprovedByType = data.finalApprovedBy?.desc ? data.finalApprovedBy.desc : null;
            this.enrollmentWorkflow = data.enrollmentWorkflow?.name ? data.enrollmentWorkflow.name : null;
            const INTERNAL = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.INTERNAL');
            const DISTRIBUTOR = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.DISTRIBUTOR');
            const DOMESTIC_DISTRIBUTOR = this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.MANAGE_ENROLLMENTS_PLACEHOLDER_TEXT.DOMESTIC_DISTRIBUTOR');

            if (this.enrollmentWorkflow === null) {
                if (
                    (this.finalApprovedByType === INTERNAL && this.userCompanyType === INTERNAL) ||
                    (this.finalApprovedByType === DISTRIBUTOR && this.userCompanyType === DOMESTIC_DISTRIBUTOR)
                ) {
                    this.showDropdowns$.next(true);
                }
                else {
                    this.showDropdowns$.next(false);
                }
            }
            else if (this.finalApprovedByType === null) {
                this.showDropdowns$.next(true);
            }
            else {
                this.showDropdowns$.next(false);
            }
        });

        return this.showDropdowns$;
    }
}
