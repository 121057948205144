import { Injectable } from '@angular/core';
import { QuickLinksPersonalizationError } from 'common/models/create-account-errors';
import { QuickLinksApiService } from 'common/services/quick-links-api.service';
import { QuickLink } from 'common/models/quick-links';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })

export class QuickLinksService {
    quickLinks = new BehaviorSubject<QuickLink[]>([] as QuickLink[]);
    quickLinks$ = this.quickLinks.asObservable();

    constructor(
        private readonly personalizationService: QuickLinksApiService,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    buildQuickLinks(inputQuickLinks: any) {
        if (inputQuickLinks && inputQuickLinks.Content && inputQuickLinks.Content.list) {
            const unformattedQuickLinks = inputQuickLinks.Content.list.$values;
            if (unformattedQuickLinks) {
                const editable = JSON.parse(JSON.stringify(unformattedQuickLinks));
                editable.sort((link1: {Position: number}, link2: {Position: number}) => link1.Position - link2.Position);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const formatted = editable.map((link: any) => ({
                    title: link.Content.title,
                    contentId: link.Id,
                    target: link.Content.links.linkOperation.Key,
                    link: link.Content.links.linkExternal,
                    image: link.Content.primaryMedia.BinaryContent.Url || null,
                    position: link.Position,
                    status: typeof link.Status === 'undefined' ? true : link.Status,
                    tags: link.Content.tags ? link.Content.tags : null
                }));
                const hidden = formatted.filter((link: {status: boolean}) => !link.status);
                const visible = formatted.filter((link: {status:boolean}) => link.status);
                const newFormatted = visible.concat(hidden);

                return this.quickLinks.next(newFormatted.map((link: object, index: number) => ({
                    ...link,
                    position: index + 1
                })));
            }

            return this.quickLinks.next([]);
        }

        return this.quickLinks.next([]);
    }

    updateQuickLinks(quickLinks: QuickLink[]) {
        this.personalizationService.updateQuickLinks(quickLinks).subscribe(
            (_result: string) => this.quickLinks.next(quickLinks),
            (err: string) => {
                this.toastService.add({
                    content: this.translate.instant(new QuickLinksPersonalizationError().content),
                    id: new QuickLinksPersonalizationError().name,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
                throw err;
            }
        );
    }
}
