<div class="hvac-cp-config-edit-modal-container">
    <utc-modal modal
        [visible]="isModalVisible"
        (closeAction)="handleCloseModal()"
        size="small"
        theme="rounded"
        class="hvac-cp-config-edit-modal">
        <hvac-modal-header>
                <span class="hvac-h3">{{"CONNECTED_PORTAL.ODU.CONFIGURATION.CONFIRM_MODAL.HEADING" | translate }}</span>
        </hvac-modal-header>
        
        <div class="hvac-cp-config-edit-modal-message">
            {{"CONNECTED_PORTAL.ODU.CONFIGURATION.CONFIRM_MODAL.MESSAGE" | translate}}
        </div>
        <div class="hvac-cp-config-edit-modal-actions">
            <button hvacButton="default" [disabled]="false" (click)="handleCloseModal()" size="small">{{ 'CONNECTED_PORTAL.ODU.CONFIGURATION.CONFIRM_MODAL.ACTIONS.CANCEL' | translate }}</button>
            <button hvacButton="primary" [disabled]="false" (click)="handleConfirmAction()" size="small">{{ 'CONNECTED_PORTAL.ODU.CONFIGURATION.CONFIRM_MODAL.ACTIONS.OK' | translate }}</button>
        </div>
    </utc-modal>
</div>
