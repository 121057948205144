import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { DefaultUpdateResponse } from 'private/app/models/default-update-response.model';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';

export enum ActivityCode {
    DataSharingDisabledReportDownload = 'DATA_SHARING_DISABLED_REPORT_DOWNLOAD',
    UnregisteredControlsReportDownload = 'UNREGISTERED_CONTROLS_REPORT_DOWNLOAD',
    ConnectedPortalLogin = 'CONNECTED_PORTAL_LOGIN',
    ExportRuntimeReport = 'EXPORT_RUNTIME_REPORT',
    VPPEligibilityReportDownload = 'VPP_ELIGIBILITY_REPORT_DOWNLOAD'
}

export interface ActivityTrackerInput {
    dealerId?: string;
    activityCode: ActivityCode
}

const TRACK_ACTIVITY = gql`
    mutation TrackActivity($params: ActivityTrackerInput!) {
        trackActivity(params: $params) {
            message
            code
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class ActivityTrackerService extends ConnectedPortalBaseService {
    private dealerId: string | null = null;

    constructor(
        public injector: Injector
    ) {
        super(injector);
    }

    public trackDealerVisit(dealerId: string): Observable<DefaultUpdateResponse | undefined | boolean> {
        if (dealerId === this.dealerId) {
            return of(false);
        }

        const params: ActivityTrackerInput = {
            dealerId,
            activityCode: ActivityCode.ConnectedPortalLogin
        };

        return this.trackActivity(params).pipe(
            map((res) => {
                if (res?.code === 200) {
                    this.dealerId = dealerId;

                    return res;
                }

                return res;
            }),
            catchError((err: HttpErrorResponse) => {
                this.dealerId = null;

                throw err;
            })
        );
    }

    public trackActivity(params: ActivityTrackerInput) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('trackActivity').mutate<{ trackActivity: DefaultUpdateResponse }>({
                mutation: TRACK_ACTIVITY,
                variables: { params },
                context: { headers: options.headers }
            }).pipe(
                map((res) => res.data?.trackActivity)
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }
}
