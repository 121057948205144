<section class="hvac-product-details-content-section hvac-product-details-content-section-documents" *ngIf="(documents$ | async)?.length">
    <ng-container *ngIf="documentCategoriesFiltered$ | async as categories">
        <div class='hvac-documents-heading' [attr.id]="'PRODUCTS.DOCUMENTS' | translate" tabindex="-1">
            <h2 class="hvac-section-heading" >{{ 'PRODUCTS.DOCUMENTS' | translate }}</h2>
        </div>
        <div class='hvac-documents-controls'>
            <form class='hvac-document-search-form'>
                <input
                    class="hvac-document-search-input"
                    id="hvac-document-search"
                    name="hvac-document-search"
                    type="text"
                    [placeholder]="'SEARCH' | translate"
                    [attr.aria-label]="'SEARCH' | translate"
                    [title]="'SEARCH' | translate"
                    #documentSearchInput
                    (keyup)="inputKeyup($event)"
                    autocomplete="off" />
                    <div class="hvac-input-action-wrap">
                        <button *ngIf="(documentSearchText$ | async)?.length" hvacButton="default" class="hvac-clear-button"
                            (click)="clearDocumentSearch()">
                            {{ 'CLEAR' | translate }}
                        </button>
                        <button hvacButton="icon" [title]="'SEARCH' | translate" (click)="onDocumentSearch()">
                            <utc-svg src="assets/icons/search.svg"></utc-svg>
                        </button>
                    </div>
            </form>
            <div *ngIf="((languageOptions$ | async)?.length || 0) > 1" class="hvac-language-selection-group">
                <utc-checkbox
                *ngFor="let language of (languageOptions$ | async)"
                class="hvac-language-selection-button"
                [class.checked]="(languageSelection$ | async)?.indexOf(language.value) !== -1"
                [checked]="(languageSelection$ | async)?.indexOf(language.value) !== -1"
                [value]="language.value"
                (change)="toggleLanguage($event)">{{language.title}}</utc-checkbox>
            </div>
            <utc-checkbox
                class="hvac-product-docs-toggle"
                (change)="toggleArchivedDocs($event)"
                [checked]="documentForm.controls['showArchivedDocs'].value"
            >
                {{ 'PRODUCTS.SHOW_PREVIOUS_DOCS' | translate }}
            </utc-checkbox>
        </div>

        <form [formGroup]="documentForm" novalidate>
            <utc-select
                variant="primary"
                class="hvac-product-docs-mobile-selector"
                [group]="documentForm"
                [options]="categories"
                control="categorySelector"
                [selectId]="'categorySelector'"></utc-select>
            <utc-select
                variant="primary"
                [ngClass]="{'hvac-hide-category-subtypes': !(categorySubTypes$ | async)?.length}"
                class="hvac-product-docs-mobile-selector"
                [group]="documentForm"
                [options]="(categorySubTypes$ | async)!"
                control="subCategorySelector"
                [selectId]="'subCategorySelector'"></utc-select>
            <ul class="hvac-product-documents-categories">
                <li class="hvac-product-documents-category" *ngFor="let docType of categories">
                    <input
                        formControlName="categorySelector"
                        type="radio"
                        name="categorySelector"
                        [attr.id]="docType"
                        [value]="docType"
                        class="hvac-product-documents-radio">
                    <label class="hvac-shadow-button" for="{{docType}}">
                        {{docType}}
                        <utc-svg class="hvac-product-documents-selected-icon" src="assets/icons/arrow-button.svg"></utc-svg>
                    </label>
                </li>
            </ul>
            <div class="hvac-product-documents-doc-area" [ngClass]="{'hvac-product-docs-loading' : !!(documentsLoading$ | async)}">
                <ul class="hvac-product-documents-subcategory" [ngClass]="{'hvac-hide-category-subtypes': !(categorySubTypes$ | async)?.length}">
                    <li
                        class="hvac-product-subcat-selector"
                        *ngFor="let subcategory of (categorySubTypes$ | async)"
                    >
                        <input
                            class="hvac-product-subcat-input"
                            formControlName="subCategorySelector"
                            name="subCategorySelector"
                            [attr.id]="'sub_' + subcategory"
                            [value]="subcategory"
                            type="radio">
                        <label class="hvac-product-subcat-label" for="{{'sub_' + subcategory}}">{{subcategory}}</label>
                    </li>
                </ul>
                <div class="hvac-product-content-documents">
                    <div *ngIf="!(filteredDocuments$ | async)?.length" class="hvac-product-content-empty">
                        <p class="hvac-product-content-empty-message">
                            {{ 'PRODUCTS.NO_RESULTS_MESSAGE' | translate }}
                            <span *ngIf="!documentForm.controls['showArchivedDocs'].value">
                                <br /><br />
                                {{ 'PRODUCTS.ENABLE_PRIOR_VERSIONS_MESSAGE' | translate }}
                            </span>
                        </p>
                    </div>
                    <ul class="hvac-product-content-doc-list">
                        <ng-container *ngFor="let document of (filteredDocuments$ | async)">
                            <utc-document-list-item [resource]="document" mode="list"></utc-document-list-item>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </form>
    </ng-container>
    <hr class="hvac-section-rule">
</section>
