export const DEACTIVATED_STAT = 2;
export const ACTIVATED_STAT = 1;
export type InActiveActiveTabs = 'active' | 'inactive';

export interface FilteredOption {
    name: string;
    value: string;
    usageType: string[];
}

export interface FilteredBrands {
    brands: FilteredOption[];
}

export interface CompanySearchFields {
    criteria: {
        companyCriteria: {
            companyTypeCodes?: string[];
            brandCodes?: string[];
            name?: string;
            hvacCompanyIds?: number[];
            brandFamily?: string;
            pricingRelationship?: boolean;
            warrantyRelationship?: boolean;
            companyServices?: string[];
        },
        contactTypeIds?: string[];
        includeContactsInResponse?: boolean;
    },
    pageParams?: {
        page?: number;
        pageSize: number;
    },
    determineTotalCount?: boolean;
}

export enum AutoCompleteTypes {
    name = 'name',
    value = 'value',
    nameValue = 'nameValue'
}

export interface RelationshipTypesUpdated {
    brand?: {
        code: string;
        id: string;
        name: string;
    }
    code: string;
    description: string;
    id: string;
}

export interface AutoCompleteAddresses {
    address: {
        addressLine1: string;
    }
    name: string;
    id: string;
}

export interface WarrantyInformation {
    name: string;
    id: string;
    soldToCustomerId: string;
    companyServices: string[];
}

export interface SoldToInformation {
    name: string;
    id: string;
    soldTo: string;
}


export interface CompanyModalInformation {
    name: string;
    id: string;
    address: {
        addressLine1: string;
        city: string;
        postalCode: string;
        regionCode: string;
    }
    soldToCustomerId: string;
    companyServices: string[];
    warranty: string;
}

export interface CompanyInformation {
    name: string;
    id: string;
    address: {
        addressLine1: string;
        city: string;
        postalCode: string;
        regionCode: string;
    }
    soldToCustomerId?: string;
}

export interface CompanyDetails {
    name: string;
    id: string;
    address: string;
    warranty?: string;
    city?: string;
    postalCode?: string;
    regionCode?: string;
    soldToCustomerId?: string;
    companyServices?: string[];
}

export interface CompanyDetailsResponse {
    companyName: string;
    hvacCompanyId: string;
    address1: string;
    address2?: string;
    warranty?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    soldTo?: string;
    companyServices?: string[];
}

export enum TierLevel {
    Leadership = 1,
    Advertising = 2,
    ResidentialContractor = 3,
    Other = 4,
    CommercialContractor = 5
}

export enum CompanyCode {
    Distributor = 'DDT',
    Internal = 'INT',
    Dealer = 'DCR',
    International = 'DID',
    IndependentRep = 'ISR',
    MarineDistributor = 'MAC',
    DirectSales = 'DSO',
    Customer = 'CUS',
    NationalAccount = 'NAT'
}


