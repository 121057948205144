import { CommonComponentsModule } from 'common/components/common-components.module';
import { CommonModule } from '@angular/common';
import { ConnectedPortalAlertsComponent } from './dealer/dealer-dashboard/components/connected-portal-alerts/connected-portal-alerts.component';
import { ConnectedPortalAlertsFiltersComponent } from './dealer/dealer-dashboard/components/connected-portal-alerts/connected-portal-alerts-filters/connected-portal-alerts-filters.component';
import { ConnectedPortalAlertsListComponent } from './dealer/dealer-dashboard/components/connected-portal-alerts/connected-portal-alerts-list/connected-portal-alerts-list.component';
import { ConnectedPortalComponent } from './connected-portal.component';
import { DealerDashboardComponent } from './dealer/dealer-dashboard/dealer-dashboard.component';
import { ConnectedPortalNavigationComponent } from './components/connected-portal-navigation/connected-portal-navigation.component';
import { ConnectedPortalStatsComponent } from './components/connected-portal-stats/connected-portal-stats.component';
import { ContentModule } from 'common/content/content.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectedPortalLayoutContainerComponent } from './components/connected-portal-layout-container/connected-portal-layout-container.component';
import { ConnectedPortalCustomersPropertyComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/connected-portal-customers-property.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ConnectedPortalConnectCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-connect-customers/connected-portal-connect-customers.component';
import { CustomersListMenuComponent } from './dealer/connected-portal-dealer-customers/components/customers-list-menu/customers-list-menu.component';
import { TextOverflowModule } from 'common/pipes/text-overflow/text-overflow.module';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { SystemConditionsComponent } from './dealer/components/system-conditions/system-conditions.component';
import { ProductDetailsCardComponent } from './dealer/components/product-details-card/product-details-card.component';
import { SystemDataSharingHeaderComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/system-data-sharing-header/system-data-sharing-header.component';
import { PropertySystemPanelComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/property-system-panel/property-system-panel.component';
import { ProductDetailsPanelComponent } from './dealer/connected-portal-dealer-customers/components/product-details-panel/product-details-panel.component';
import { ConnectedPortalAlertsOptionsComponent } from './dealer/dealer-dashboard/components/connected-portal-alerts/connected-portal-alerts-options/connected-portal-alerts-options.component';
import { ProductDetailAlertsComponent } from './dealer/connected-portal-dealer-customers/components/product-detail-alerts/product-detail-alerts.component';
import { SeverityToAlertTypePipe } from 'private/app/pipes/severity-to-alert-type.pipe';
import { DataSharingToStatusPipe } from 'private/app/pipes/data-sharing-to-status.pipe';
import { ConnectedPortalDealerCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-dealer-customers.component';
import { ConnectedPortalQueuedCustomersComponent } from './dealer/connected-portal-dealer-customers/connected-portal-queued-customers/connected-portal-queued-customers.component';
import { RecentActivityBannerComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/recent-activity-banner/recent-activity-banner.component';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { DistributorDashboardComponent } from './distributor/distributor-dashboard/distributor-dashboard.component';
import { DealerComponent } from './dealer/dealer.component';
import { DistributorComponent } from './distributor/distributor.component';
import { FactoryComponent } from './factory/factory.component';
import { DistributorDealersComponent } from './distributor/distributor-dealers/distributor-dealers.component';
import { MilitaryToStandard } from 'common/pipes/military-to-standard.pipe';
import { SystemDiagnosticsParamUnitTypePipe } from 'private/app/pipes/system-diagnostics-param-unit-type.pipe';
import { ConnectedPortalWarrantyInfoComponent } from './dealer/connected-portal-dealer-customers/connected-portal-warranty-info/connected-portal-warranty-info.component';
import { WarrantyDetailsComponent } from './dealer/connected-portal-dealer-customers/components/warranty-details/warranty-details.component';
import { OrderByPipe } from 'private/app/pipes/order-by.pipe';
import { SystemNotificationPreferencesComponent } from './dealer/connected-portal-dealer-customers/connected-portal-customers-property/components/system-notification-preferences/system-notification-preferences.component';
import { DealerSettingsComponent } from './dealer/dealer-settings/dealer-settings.component';
import { DealerInfoComponent } from './dealer/dealer-settings/components/dealer-info/dealer-info.component';
import { DealerNotificationSettingsComponent } from './dealer/dealer-settings/components/dealer-notification-settings/dealer-notification-settings.component';
import { FilterObjectArrayPipe } from 'private/app/pipes/filter-object-array.pipe';
import { EmptyToCharPipe } from 'common/pipes/empty-to-char.pipe';
import { NgChartsModule } from 'ng2-charts';
import { ConnectedControlReportComponent } from './distributor/distributor-dashboard/components/connected-control-report/connected-control-report.component';
import { SearchControlComponent } from './dealer/connected-portal-dealer-customers/components/search-control/search-control.component';
import { AddControlComponent } from './dealer/connected-portal-dealer-customers/components/add-control/add-control.component';
import { DealerLoginsReportComponent } from './distributor/distributor-dashboard/components/dealer-logins-report/dealer-logins-report.component';
import { OpportunityUsageReportComponent } from './distributor/distributor-dashboard/components/opportunity-usage-report/opportunity-usage-report.component';
import { ReportChartContainerComponent } from './components/report-chart-container/report-chart-container.component';
import { SystemDiagnosticsModalComponent } from './dealer/connected-portal-dealer-customers/components/system-diagnostics-modal/system-diagnostics-modal.component';
import { FactoryDashboardComponent } from './factory/factory-dashboard/factory-dashboard.component';
import { FactoryDistributorsComponent } from './factory/factory-distributors/factory-distributors.component';
import { DealerResourcesComponent } from './dealer/dealer-resources/dealer-resources.component';
import { ResourcesPageComponent } from './components/resources-page/resources-page.component';
import { DistributorResourcesComponent } from './distributor/distributor-resources/distributor-resources.component';
import { DistributorNavigationComponent } from './dealer/components/distributor-navigation/distributor-navigation.component';
import { OutOfRangeToCharPipe } from 'common/pipes/out-of-range-to-char';
import { DealerOpportunitiesComponent } from './dealer/dealer-opportunities/dealer-opportunities.component';
import { OpportunityReportCardComponent } from './dealer/dealer-opportunities/components/opportunity-report-card/opportunity-report-card.component';
import { OpportunityReportCardsComponent } from './dealer/dealer-opportunities/components/opportunity-report-cards/opportunity-report-cards.component';
import { ConnectedPortalFaqComponent } from './components/connected-portal-faq/connected-portal-faq.component';
import { SafePipeModule } from 'safe-pipe';
import { CustomerLoginsReportComponent } from './factory/factory-dashboard/components/customer-logins-report/customer-logins-report.component';
import { FactoryResourcesComponent } from './factory/factory-resources/factory-resources.component';
import { FactoryConnectedControlReportComponent } from './factory/factory-dashboard/components/factory-connected-control-report/factory-connected-control-report.component';
import { EcobeeWallControlDetailComponent } from './dealer/customer-product-details/wall-control/ecobee-wall-control-detail/ecobee-wall-control-detail.component';
import { InfinityWallControlDetailComponent } from './dealer/customer-product-details/wall-control/infinity-wall-control-detail/infinity-wall-control-detail.component';
import { WallControlDetailContainerComponent } from './dealer/customer-product-details/wall-control/wall-control-detail-container/wall-control-detail-container.component';
import { IduDetailContainerComponent } from './dealer/customer-product-details/idu/idu-detail-container/idu-detail-container.component';
import { EcobeeIduDetailComponent } from './dealer/customer-product-details/idu/ecobee-idu-detail/ecobee-idu-detail.component';
import { InfinityIduDetailComponent } from './dealer/customer-product-details/idu/infinity-idu-detail/infinity-idu-detail.component';
import { OduDetailContainerComponent } from './dealer/customer-product-details/odu/odu-detail-container/odu-detail-container.component';
import { InfinityOduDetailComponent } from './dealer/customer-product-details/odu/infinity-odu-detail/infinity-odu-detail.component';
import { EcobeeOduDetailComponent } from './dealer/customer-product-details/odu/ecobee-odu-detail/ecobee-odu-detail.component';
import { ErrorRedirectComponent } from './components/error-redirect/error-redirect.component';
import { EcobeeNIWallControlDetailComponent } from './dealer/customer-product-details/wall-control/ecobee-ni-wall-control-detail/ecobee-ni-wall-control-detail.component';
import { EcobeeNiSystemConditionsComponent } from './dealer/customer-product-details/wall-control/ecobee-ni-wall-control-detail/ecobee-ni-system-conditions/ecobee-ni-system-conditions.component';
import { WallControlHumidityAndVentilationDetailsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-humidity-and-ventilation-details/wall-control-humidity-and-ventilation-details.component';
import { WallControlStatusDetailsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-status-details/wall-control-status-details.component';
import { WallControlNotificationsComponent } from './dealer/customer-product-details/wall-control/components/hvac-wall-control-notifications/hvac-wall-control-notifications.component';
import { WallControlEventLogComponent } from './dealer/customer-product-details/wall-control/components/wall-control-event-log/wall-control-event-log.component';
import { WallControlZoningDetailsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-zoning-details/wall-control-zoning-details.component';
import { WallControlSystemInfoComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-info/wall-control-system-info.component';
import { ConfigEditPropComponent } from './dealer/customer-product-details/components/config-edit-prop/config-edit-prop.component';
import { InfinityIduConfigurationComponent } from './dealer/customer-product-details/idu/infinity-idu-detail/components/infinity-idu-configuration/infinity-idu-configuration.component';
import { InfinityOduConfigurationComponent } from './dealer/customer-product-details/odu/infinity-odu-detail/components/infinity-odu-configuration/infinity-odu-configuration.component';
import { WallControlSystemDiagnosticsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/wall-control-system-diagnostics.component';
import { SystemDiagnosticParameterComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/system-diagnostic-parameter/system-diagnostic-parameter.component';
import { SystemDiagnosticEquipmentComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/system-diagnostic-equipment/system-diagnostic-equipment.component';
import { EcobeeRunTimeReportComponent } from './dealer/customer-product-details/wall-control/components/ecobee-run-time-report/ecobee-run-time-report.component';
import { ConfirmConfigEditModalComponent } from './dealer/customer-product-details/components/confirm-config-edit-modal/confirm-config-edit-modal.component';
import { EcobeeWallControlConfigComponent } from './dealer/customer-product-details/wall-control/ecobee-wall-control-detail/components/ecobee-wall-control-config/ecobee-wall-control-config.component';
import { InfinityWallControlConfigComponent } from './dealer/customer-product-details/wall-control/infinity-wall-control-detail/components/infinity-wall-control-config/infinity-wall-control-config.component';
import { DealerLinkedDealersComponent } from './dealer/dealer-linked-dealers/dealer-linked-dealers.component';
import { RemoveControlModalComponent } from './dealer/connected-portal-dealer-customers/components/remove-control-modal/remove-control-modal.component';
import { WallControlProgramSettingsComponent } from './dealer/components/system-conditions/components/wall-control-program-settings/wall-control-program-settings.component';
import { ConnectedPortalAdvancedSearchComponent } from './components/connected-portal-advanced-search/connected-portal-advanced-search.component';
import { ConnectedPortalAdvancedSearchFieldsComponent } from './components/connected-portal-advanced-search/connected-portal-advanced-search-fields/connected-portal-advanced-search-fields.component';
import { ConnectedPortalAdvancedSearchResultComponent } from './components/connected-portal-advanced-search/connected-portal-advanced-search-result/connected-portal-advanced-search-result.component';
import { DistributorAdvancedSearchComponent } from './distributor/distributor-advanced-search/distributor-advanced-search.component';
import { FactoryAdvancedSearchComponent } from './factory/factory-advanced-search/factory-advanced-search.component';
import { FactorySystemsOverviewComponent } from './factory/factory-systems-overview/factory-systems-overview.component';
import { FactoryEngineeringInsightsComponent } from './factory/factory-systems-overview/factory-engineering-insights/factory-engineering-insights.component';
import { FactorySystemInsightsComponent } from './factory/factory-systems-overview/factory-system-insights/factory-system-insights.component';
import { FadReportComponent } from './factory/factory-dashboard/components/fad-report/fad-report.component';
import { FaultSourceReportComponent } from './factory/factory-systems-overview/factory-system-insights/components/fault-source-report/fault-source-report.component';
import { FactorySystemsFaultsComponent } from './factory/factory-systems-overview/components/factory-systems-faults/factory-systems-faults.component';
import { FactorySystemsFaultsListComponent } from './factory/factory-systems-overview/components/factory-systems-faults-list/factory-systems-faults-list.component';
import { FactorySystemDetailsComponent } from './factory/factory-systems-overview/components/factory-system-details/factory-system-details.component';
import { FactorySystemSearchComponent } from './factory/factory-systems-overview/components/factory-system-search/factory-system-search.component';
import { FaultSeverityReportComponent } from './factory/factory-systems-overview/factory-system-insights/components/fault-severity-report/fault-severity-report.component';
import { FactoryEngineeringInsightsChartComponent } from './factory/factory-systems-overview/factory-engineering-insights/components/factory-engineering-insights-chart/factory-engineering-insights-chart.component';
import { EncodeURIComponentPipe } from 'common/pipes/encode-uri-component.pipe';
import { EcobeeNiRunTimeReportComponent } from './dealer/customer-product-details/wall-control/components/ecobee-ni-run-time-report/ecobee-ni-run-time-report.component';
import { FactoryEngineeringInsightsFiltersComponent } from './factory/factory-systems-overview/factory-engineering-insights/components/factory-engineering-insights-filters/factory-engineering-insights-filters.component';
import { CarrierEltWallControlDetailComponent } from './dealer/customer-product-details/wall-control/carrier-elt-wall-control-detail/carrier-elt-wall-control-detail.component';
import { CarrierEltSystemConditionsComponent } from './dealer/customer-product-details/wall-control/carrier-elt-wall-control-detail/carrier-elt-system-conditions/carrier-elt-system-conditions.component';
import { ConnectedPortalResultsListComponent } from './components/connected-portal-results-list/connected-portal-results-list.component';
import { ConnectedPortalSystemProductCardComponent } from './components/connected-portal-system-product-card/connected-portal-system-product-card.component';
import { UpdateProductDetailsModalComponent } from './dealer/customer-product-details/components/update-product-details-modal/update-product-details-modal.component';
import { CarrierEltWallControlConfigComponent } from './dealer/customer-product-details/wall-control/carrier-elt-wall-control-detail/components/carrier-elt-wall-control-config/carrier-elt-wall-control-config.component';
import { WallControlEltProgramSettingsComponent } from './dealer/components/system-conditions/components/wall-control-elt-program-settings/wall-control-elt-program-settings.component';
import { CarrierEltRunTimeReportComponent } from './dealer/customer-product-details/wall-control/components/carrier-elt-run-time-report/carrier-elt-run-time-report.component';
import { DealerPendingActivationComponent } from './dealer/dealer-pending-activation/dealer-pending-activation.component';
import { ConnectedPortalSwimlaneChartComponent } from './dealer/customer-product-details/wall-control/components/connected-portal-swimlane-chart/connected-portal-swimlane-chart.component';
import { GenericEventLogsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-system-diagnostics/components/generic-event-logs/generic-event-logs.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { WallControlEnergyEventsComponent } from './dealer/customer-product-details/wall-control/components/wall-control-energy-events/wall-control-energy-events.component';
import { DealerFadComponent } from './dealer/dealer-fad/dealer-fad.component';
import { EventTypePipe } from 'private/app/pipes/event-type.pipe';

@NgModule({
    declarations: [
        ConnectedPortalAlertsComponent,
        ConnectedPortalAlertsFiltersComponent,
        ConnectedPortalAlertsListComponent,
        ConnectedPortalAlertsOptionsComponent,
        ConnectedPortalComponent,
        ConnectedPortalConnectCustomersComponent,
        ConnectedPortalCustomersPropertyComponent,
        ConnectedPortalDealerCustomersComponent,
        ConnectedPortalQueuedCustomersComponent,
        ConnectedPortalNavigationComponent,
        ConnectedPortalLayoutContainerComponent,
        ConnectedPortalNavigationComponent,
        ConnectedPortalStatsComponent,
        CustomersListMenuComponent,
        DataSharingToStatusPipe,
        DateTimeTzPipe,
        DealerComponent,
        DealerDashboardComponent,
        DistributorComponent,
        DistributorDashboardComponent,
        EmptyToCharPipe,
        EncodeURIComponentPipe,
        FactoryComponent,
        InfoCardComponent,
        ProductDetailAlertsComponent,
        ProductDetailsCardComponent,
        ProductDetailsPanelComponent,
        PropertySystemPanelComponent,
        RecentActivityBannerComponent,
        SearchBarComponent,
        SeverityToAlertTypePipe,
        SystemConditionsComponent,
        SystemDataSharingHeaderComponent,
        WallControlHumidityAndVentilationDetailsComponent,
        WallControlStatusDetailsComponent,
        WallControlNotificationsComponent,
        WallControlEventLogComponent,
        WallControlZoningDetailsComponent,
        WallControlSystemInfoComponent,
        DistributorDashboardComponent,
        DistributorDealersComponent,
        ConfigEditPropComponent,
        InfinityIduConfigurationComponent,
        InfinityOduConfigurationComponent,
        WallControlSystemDiagnosticsComponent,
        SystemDiagnosticParameterComponent,
        SystemDiagnosticsParamUnitTypePipe,
        SystemDiagnosticEquipmentComponent,
        ConnectedPortalWarrantyInfoComponent,
        WarrantyDetailsComponent,
        OrderByPipe,
        SystemNotificationPreferencesComponent,
        DealerSettingsComponent,
        DealerInfoComponent,
        DealerNotificationSettingsComponent,
        FilterObjectArrayPipe,
        ConnectedControlReportComponent,
        SearchControlComponent,
        AddControlComponent,
        EcobeeRunTimeReportComponent,
        DealerLoginsReportComponent,
        OpportunityUsageReportComponent,
        ReportChartContainerComponent,
        SystemDiagnosticsModalComponent,
        FactoryDashboardComponent,
        FactoryDistributorsComponent,
        DealerResourcesComponent,
        ResourcesPageComponent,
        DistributorResourcesComponent,
        ConfirmConfigEditModalComponent,
        DistributorNavigationComponent,
        OutOfRangeToCharPipe,
        DealerOpportunitiesComponent,
        OpportunityReportCardComponent,
        OpportunityReportCardsComponent,
        ConnectedPortalFaqComponent,
        CustomerLoginsReportComponent,
        FactoryResourcesComponent,
        FactoryConnectedControlReportComponent,
        EcobeeWallControlDetailComponent,
        InfinityWallControlDetailComponent,
        WallControlDetailContainerComponent,
        IduDetailContainerComponent,
        EcobeeIduDetailComponent,
        InfinityIduDetailComponent,
        OduDetailContainerComponent,
        InfinityOduDetailComponent,
        EcobeeOduDetailComponent,
        ErrorRedirectComponent,
        EcobeeNIWallControlDetailComponent,
        EcobeeNiSystemConditionsComponent,
        EcobeeWallControlConfigComponent,
        InfinityWallControlConfigComponent,
        DealerLinkedDealersComponent,
        RemoveControlModalComponent,
        WallControlProgramSettingsComponent,
        ConnectedPortalAdvancedSearchComponent,
        ConnectedPortalAdvancedSearchFieldsComponent,
        ConnectedPortalAdvancedSearchResultComponent,
        DistributorAdvancedSearchComponent,
        FactoryAdvancedSearchComponent,
        FactorySystemsOverviewComponent,
        FactorySystemInsightsComponent,
        FactoryEngineeringInsightsComponent,
        FactorySystemsFaultsComponent,
        FactorySystemsFaultsListComponent,
        FadReportComponent,
        FaultSourceReportComponent,
        FactorySystemSearchComponent,
        FaultSeverityReportComponent,
        FactoryEngineeringInsightsChartComponent,
        FactorySystemDetailsComponent,
        EcobeeNiRunTimeReportComponent,
        FactoryEngineeringInsightsFiltersComponent,
        CarrierEltWallControlDetailComponent,
        CarrierEltSystemConditionsComponent,
        ConnectedPortalResultsListComponent,
        ConnectedPortalSystemProductCardComponent,
        UpdateProductDetailsModalComponent,
        CarrierEltWallControlConfigComponent,
        WallControlEltProgramSettingsComponent,
        CarrierEltRunTimeReportComponent,
        DealerPendingActivationComponent,
        ConnectedPortalSwimlaneChartComponent,
        GenericEventLogsComponent,
        TooltipDirective,
        WallControlEnergyEventsComponent,
        DealerFadComponent,
        EventTypePipe
    ],
    providers: [
        EncodeURIComponentPipe,
        EmptyToCharPipe,
        DateTimeTzPipe,
        MilitaryToStandard,
        OutOfRangeToCharPipe
    ],
    exports: [
        ConnectedPortalLayoutContainerComponent,
        ConnectedPortalFaqComponent,
        DistributorNavigationComponent
    ],
    imports: [
        CommonComponentsModule,
        CommonModule,
        ContentModule,
        FormsModule,
        NgChartsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        TextOverflowModule,
        SafePipeModule
    ]
})
export class ConnectedPortalModule {}
