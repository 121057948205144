<div class="hvac-association-title-section hvac-distributor-title-section" #distributorContainer>
    <div class="hvac-distributor-header-container">
        <div class="hvac-header-and-add-relationships">
            <h1 class="havc-association-header-title hvac-add-distributor-title">
                {{ "ACCOUNT_ADMIN.DISTRIBUTOR_RELATIONSHIPS" | translate }}
            </h1>
            <div class="hvac-active-inactive-container hvac-active-inactive-desktop"
                *ngIf="!loading && (tableData$ | async)?.length">
                <div class="hvac-view-by-container">
                    <p class="hvac-subhead hvac-view-by-text">
                        {{ "ACCOUNT_ADMIN.VIEW_BY" | translate }}
                    </p>
                </div>
                <hvac-tab-bar variant="secondary">
                    <button buttonType="button" class="hvac-active-distributors" (click)="handleTabClickTabs('active')" [ngClass]="{
                            'hvac-active': selectedTab === 'active'
                        }">
                        {{ "ACCOUNT_ADMIN.ACTIVE_RELATIONSHIPS" | translate }}
                    </button>
                    <button buttonType="button" class="hvac-inactive-distributors" (click)="handleTabClickTabs('inactive')" [ngClass]="{
                            'hvac-active': selectedTab === 'inactive'
                        }" [disabled]="(deactivatedRelationships$ | async)?.length === 0">
                        {{ "ACCOUNT_ADMIN.INACTIVE_RELATIONSHIPS" | translate }}
                    </button>
                </hvac-tab-bar>
            </div>
        </div>
    </div>
    <div class="hvac-row hvac-total-and-add-distributors" *ngIf="!loading && (tableData$ | async)?.length">
        <div class="hvac-col-sm-8 hvac-total-and-add">
            <h2 *ngIf="totalPages === 1; else nonZeroPage" class="hvac-association-sub-header-title">
                {{
                "ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE_FIRST_PAGE"
                | translate
                : {begin: singlePageCount, total: (tableData$ | async)?.length } }}
            </h2>
            <ng-template #nonZeroPage>
                <h2 class="hvac-association-sub-header-title">
                    {{"ACCOUNT_ADMIN.RESULT_COUNT_MESSAGE" | translate
                    : { begin: displayCountStart, end: displayCountEnd, total: this.distributorListResults.length } }}
                </h2>
            </ng-template>
            <button buttonType="button" hvacButton="text" class="hvac-relationships-link" (click)="adminService.isModalVisible = true"
                *ngIf="!(isRelationshipButtonVisible$ | async) && isSameFamily && adminAccess && company.isActive">
                {{ "ACCOUNT_ADMIN.ADD_RELATIONSHIPS_WITH_PLUS" | translate }}
            </button>
        </div>
        <div class="hvac-active-inactive-container  hvac-active-inactive-mobile"
            *ngIf="!loading && (tableData$ | async)?.length">
            <div class="hvac-view-by-container">
                <p class="hvac-subhead hvac-view-by-text">
                    {{ "ACCOUNT_ADMIN.VIEW_BY" | translate }}
                </p>
            </div>
            <hvac-tab-bar variant="secondary">
                <button buttonType="button" class="hvac-active-distributors" (click)="handleTabClickTabs('active')" [ngClass]="{
                            'hvac-active': selectedTab === 'active'
                        }">
                    {{ "ACCOUNT_ADMIN.ACTIVE_RELATIONSHIPS" | translate }}
                </button>
                <button buttonType="button" class="hvac-inactive-distributors" (click)="handleTabClickTabs('inactive')" [ngClass]="{
                            'hvac-active': selectedTab === 'inactive'
                        }" [disabled]="(deactivatedRelationships$ | async)?.length === 0">
                    {{ "ACCOUNT_ADMIN.INACTIVE_RELATIONSHIPS" | translate }}
                </button>
            </hvac-tab-bar>
        </div>
    </div>
    <div class="relationship-banner">
        <hvac-toast [name]="toastOutlet"></hvac-toast>
    </div>
    <div class="hvac-distributor-table-container">
        <hvac-table [responsive]="true">
            <thead>
                <tr>
                    <td class="hvac-distributor-title-info">
                        {{ "ACCOUNT_ADMIN.COMPANY_INFO" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.TYPE" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.BRANDS" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.TIER_LEVEL" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.CLAIM_TYPE" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.CIN_NO" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.HVP_NO" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.SOLD_TO" | translate }}
                    </td>
                    <td class="hvac-distributor-table-info">
                        {{ "ACCOUNT_ADMIN.SHIP_TO" | translate }}
                    </td>
                </tr>
            </thead>
            <tbody *ngFor="let distributor of tableData$ | async">
                <tr>
                    <td [attr.data-label]="
                            'ACCOUNT_ADMIN.COMPANY_INFO' | translate
                        " class="hvac-company-distributor-information hvac-company-distributor-company-address">
                        <div>
                            <div class="hvac-company-distributor-row">
                                <div>{{ distributor.dealerDistributor.distributorCompanyName }}</div>
                                <utc-tooltip  *ngIf="distributor.sapMessage">
                                    <utc-tooltip-content>
                                        <p class='hvac-tooltip-description'
                                            [innerHTML]="distributor.sapMessage">
                                        </p>
                                    </utc-tooltip-content>
                                    <utc-tooltip-anchor-content>
                                        <img [src]="'assets/icons/diagnostic-notification.svg'" />
                                    </utc-tooltip-anchor-content>
                                </utc-tooltip>  
                            </div>
                            <br />
                            <div class="hvac-p-xs hvac-address-id-comments-information">
                                {{ distributor.dealerDistributor.distributorAddress }}
                                <br />
                                {{ "ACCOUNT_ADMIN.COMPANY_ID" | translate }}:
                                {{ distributor.dealerDistributor.distributorCompanyId }}
                                <br />
                                {{ "ACCOUNT_ADMIN.COMMENTS" | translate }}:
                                {{ distributor.dealerDistributor.comment }}
                            </div>
                        </div>
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.TYPE' | translate"
                        class="hvac-company-distributor-information">
                        {{ distributor.relationshipType }}
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.BRANDS' | translate"
                        class="hvac-company-distributor-information">
                        {{ distributor.brandName }}
                    </td>
                    <td [attr.data-label]="
                            'ACCOUNT_ADMIN.TIER_LEVEL' | translate
                        " class="hvac-company-distributor-information">
                        {{ distributor.tierLevel }}
                    </td>
                    <td [attr.data-label]="
                            'ACCOUNT_ADMIN.CLAIM_TYPE' | translate
                        " class="hvac-company-distributor-information">
                        {{ companyRelationshipService.getClaimTypeName(distributor.claims) }}
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.CIN_NO' | translate"
                        class="hvac-company-distributor-information">
                        {{ distributor.cin }}
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.HVP_NO' | translate"
                        class="hvac-company-distributor-information">
                        {{ distributor.hvp || ""}}
                    </td>
                    <td [attr.data-label]="'ACCOUNT_ADMIN.SOLD_TO' | translate"
                        class="hvac-company-distributor-information">
                        {{ distributor.soldTo || "" }}
                    </td>
                </tr>
                <tr class="hvac-manage-delete-links">
                    <td colspan="9">
                        <div class="hvac-distributor-links-container hvac-row">
                            <div class="hvac-col-xs hvac-end-lg hvac-distributor-management-links"
                                *ngIf="selectedTab === 'active' && isSameFamily && adminAccess">
                                <button buttonType="button" class="hvac-text-link hvac-delete-link" (click)="deactivateRelationship(distributor)">
                                    {{ "ACCOUNT_ADMIN.DEACTIVATE" | translate }}
                                </button>
                                <button buttonType="button" class="hvac-manage-link" hvacButton="default" size="small" (click)="showManageModal(distributor)">
                                    {{ "ACCOUNT_ADMIN.MANAGE" | translate }}
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </hvac-table>
        <p class="hvac-account-admin-no-results hvac-distributor-relationships-no-results"
            *ngIf="(tableData$ | async)?.length === 0 && !loading">
            {{ "ACCOUNT_ADMIN.NO_RESULTS" | translate }}
        </p>
        <div class="hvac-association-detail-loader">
            <utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small">
            </utc-loading-spinner>
        </div>
    </div>
    <div class="hvac-associations-pagination-control">
        <hvac-pagination *ngIf="totalPages > 1" [totalPages]="totalPages" [currentPage]="pagination"
            (pageChange)="handlePageChange($event)">
        </hvac-pagination>
    </div>
    <utc-modal class='hvac-deactivate-relationship-modal' (closeAction)="handleCloseModal()" [visible]="deactivateRelationshipModal" theme='rounded' size='medium' [showHeader]="false">
        <div class="hvac-deactivate-relationship-wrapper">
            <h3 class="hvac-h3 hvac-warning-text">{{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.WARNING' | translate }}</h3>
            <p class="hvac-h4">{{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.WARNING_INFO' | translate }}</p>
            <p>{{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.WARNING_INFO_CONTINUED' | translate }}</p>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-deactivate-relationship-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="handleCloseModal()">
                    {{'ACCOUNT_ADMIN.CANCEL' | translate }}
                </button>
                <button buttonType="button" hvacButton="primary" (click)="deactivateCheck()">
                    {{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.CONTINUE' | translate }}
                </button>
            </div>
        </div>
    </utc-modal>

    <utc-modal class='hvac-deactivate-relationship-modal' (closeAction)="handleLastCloseModal()" [visible]="deactivateLastRelationshipModal" theme='rounded' size='medium' [showHeader]="false">
        <div class="hvac-deactivate-relationship-wrapper">
            <h3 class="hvac-h3 hvac-warning-text">{{'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_TABLE.ACTIONS.WARNING' | translate }}</h3>
            <p class="hvac-h4">{{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_WARNING.WARNING_INFO' | translate }}</p>
            <p>{{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.WARNING_INFO_CONTINUED' | translate }}</p>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-deactivate-relationship-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="handleLastCloseModal()">
                    {{'ACCOUNT_ADMIN.CANCEL' | translate }}
                </button>
                <button buttonType="button" hvacButton="primary" (click)="deactivate()">
                    {{'ACCOUNT_ADMIN.DEACTIVATE_RELATIONSHIP_MODAL.CONTINUE' | translate }}
                </button>
            </div>
        </div>
    </utc-modal>
</div>

<hvac-manage-relationship [brandList]="(authorisedBrandList$ | async)!" [brandFamily]="company?.brandFamily!" [selectedRelationship]="eventsSubject.asObservable()" [relationshipList]="distributorListResults" (submitChanges)="postRelationships($event)"></hvac-manage-relationship>


