<div class='hvac-email-validation-popup-container'>
    <button hvacButton="default" (click)="cancelEmailValidation()"  class='hvac-create-account-header-cancel-link'>{{'USER_VALIDATION.HEADER_CANCEL_BUTTON' | translate }}</button>
    <div class='hvac-email-validation-heading'>
        <h2 class='hvac-email-validation-title hvac-hero-large-text'> {{'USER_VALIDATION.TITLE' | translate }}
        </h2>
        <p class='hvac-email-validation-body-copy hvac-hero-normal-text'>{{'USER_VALIDATION.EMAIL_VALIDATION_DESCRIPTION'
            | translate
            }}</p>
        <hr class="line-break"/>

        <div class='hvac-email-validation-banner-container'>
            <hvac-toast [name]="toastOutlet"></hvac-toast>
        </div>

        <utc-input inputId="hvac-email-validation-input"
            type="text" [inputControl]="emailCodeControl"
            [label]="'CREATE_ACCOUNT_EMAIL.LABEL.EMAIL_CODE' | translate"
            [error]="emailCodeControl.dirty && !emailCodeControl.valid"
            [disabledInput]="(apiRequestActive$ | async)!"
            [disableInputIcon]="(apiRequestActive$ | async)!">
        </utc-input>
        <button [disabled]="resetButtonDisabled" hvacButton="default" (click)="requestVerificationCode()"
            class='hvac-resend-email-code'
            [innerHTML]="(resetButtonDisabled ? 'USER_VALIDATION.EMAIL_CODE_SEND' : 'USER_VALIDATION.EMAIL_CODE_RESEND') | translate"></button>

            <button hvacButton="primary"  [disabled]="!( emailCodeControl.valid ) || (apiRequestActive$ | async)" (click)= "submitEmailVerification()"  class='hvac-email-validation-submit-button'>
                <span>{{ 'USER_VALIDATION.SUBMIT'
                    | translate }}</span>
                <utc-svg [src]="'assets/icons/arrow-white.svg'"></utc-svg>
            </button>
    </div>
</div>
