<hvac-connected-portal-layout-container>
    <div class="hvac-cp-top-container" top>
        <div class="hvac-cp-breadcrumb-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation
                    *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <section class="hvac-cp-bottom-container" bottom>
        <ng-container *ngIf="(routeData$ | async) as routeData">
            <ng-container [ngSwitch]="routeData.systemType">

                <hvac-infinity-wall-control-detail
                    *ngSwitchCase="SystemType.INFINITY"
                    [dealerId]="routeData.dealerId"
                    [propertyId]="routeData.propertyId"
                    [systemType]="routeData.systemType"
                    [serialNo]="routeData.serialNo">
                </hvac-infinity-wall-control-detail>

                <hvac-ecobee-wall-control-detail
                    *ngSwitchCase="SystemType.ECOBEE"
                    [dealerId]="routeData.dealerId"
                    [propertyId]="routeData.propertyId"
                    [systemType]="routeData.systemType"
                    [serialNo]="routeData.serialNo">
                </hvac-ecobee-wall-control-detail>

                <hvac-ecobee-ni-wall-control-detail
                    *ngSwitchCase="SystemType.ECOBEE_NI"
                    [propertyId]="routeData.propertyId"
                    [systemType]="routeData.systemType"
                    [serialNo]="routeData.serialNo"
                    [dealerId]="routeData.dealerId">
                </hvac-ecobee-ni-wall-control-detail>

                <hvac-carrier-elt-wall-control-detail
                    *ngSwitchCase="SystemType.CARRIER_ELT"
                    [systemType]="routeData.systemType"
                    [propertyId]="routeData.propertyId"
                    [serialNo]="routeData.serialNo"
                    [dealerId]="routeData.dealerId">
                </hvac-carrier-elt-wall-control-detail>

                <div *ngSwitchDefault>
                    <hvac-error-redirect></hvac-error-redirect>
                </div>
            </ng-container>
        </ng-container>
    </section>
</hvac-connected-portal-layout-container>

<utc-modal *ngIf="(routeData$ | async) as routeData" modal class="hvac-cp-runtime-report-modal" [visible]="(contextService.isReportModalVisible$ | async) || false"
    (closeAction)="contextService.isReportModalVisible$.next(false)" size="dynamic-height" theme="rounded">
    <hvac-modal-header>
        <span class="hvac-h3">{{'CONNECTED_PORTAL.ECOBEE_RUN_TIME_REPORT.HEADING' | translate}}</span>
    </hvac-modal-header>
    <ng-container [ngSwitch]="routeData.systemType" *ngIf="contextService.isReportModalVisible$ | async">
        <hvac-carrier-elt-run-time-report
        *ngSwitchCase="SystemType.CARRIER_ELT"
        [dealerId]="routeData.dealerId"
        [systemType]="routeData.systemType"
        [serialNo]="routeData.serialNo"
        [propertyId]="routeData.propertyId"
        [modelName]="(contextService.productData$ | async)?.modelName || ''">
    </hvac-carrier-elt-run-time-report>
        <hvac-ecobee-run-time-report
            *ngSwitchCase="SystemType.ECOBEE"
            [dealerId]="routeData.dealerId"
            [systemType]="routeData.systemType"
            [serialNo]="routeData.serialNo"
            [propertyId]="routeData.propertyId"
            [modelName]="(contextService.productData$ | async)?.modelName || ''">
        </hvac-ecobee-run-time-report>
        <hvac-ecobee-ni-run-time-report
            *ngSwitchCase="SystemType.ECOBEE_NI"
            [dealerId]="routeData.dealerId"
            [systemType]="routeData.systemType"
            [serialNo]="routeData.serialNo"
            [propertyId]="routeData.propertyId"
            [modelName]="(contextService.productData$ | async)?.modelName || ''">
        </hvac-ecobee-ni-run-time-report>
    </ng-container>

</utc-modal>

<hvac-system-diagnostics-modal *ngIf="(routeData$ | async) as routeData"
    [dealerId]="routeData.dealerId"
    [serialNo]="routeData.serialNo"
    [propertyId]="routeData.propertyId"
    [systemType]="routeData.systemType"
    [isDiagnosticModalVisible]="(contextService.isDiagnosticsModalVisible$ | async) || false"
    (closeDiagnosticsTestModal)="contextService.closeDiagnosticsTest$.next($event)"></hvac-system-diagnostics-modal>

<hvac-confirm-config-edit-modal *ngIf="(contextService.isConfigEditConfirmModalVisible$ | async) as isConfigEditConfirmModalVisible"
    [isModalVisible]="isConfigEditConfirmModalVisible"
    [productType]="ProductType.WallControl"
    (onCloseModal)="contextService.isConfigEditConfirmModalVisible$.next(false)"
    (onConfirmButtonClick)="contextService.confirmConfigEditSave$.next(true)"></hvac-confirm-config-edit-modal>

<hvac-remove-control-modal *ngIf="(customerControlContextSvc.isRemoveConnectedControlModalVisible$  | async) as isRemoveControlModalVisible"
    [isModalVisible]="isRemoveControlModalVisible"
    [controlType]="controlType"
    [message]="'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.CONFIRM_MODAL.MESSAGE' | translate"
    [heading]="'CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.CONFIRM_MODAL.HEADING' | translate"
    (onCloseModal)="customerControlContextSvc.isRemoveConnectedControlModalVisible$.next(false)"
    (onConfirmButtonClick)="customerControlContextSvc.confirmRemoveConnectedControl$.next(true)"></hvac-remove-control-modal>

