import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { BasicCSV, CSVExportService } from 'common/services/csv-export.service';
import { SearchQueryParams } from 'private/app/models/default-query-params';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { SearchService } from 'private/app/services/connected-portal/search.service';
import { Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { QueryParamsService } from 'private/app/services/connected-portal/query-params.service';
import { QUERY_PARAM_DEBOUNCE_INTERVAL, SEARCH_RESULTS_PAGE_SIZE } from '../../../constants';
import { CustomerControlContextService } from 'private/app/services/connected-portal/customer-control-context.service';
import { ToastService } from 'common/services/toast.service';

const EXPORT_FILENAME_PREFIX = 'connected-customers';

export interface ConnectedPortalCustomerProperty {
    id: string;
    name: string;
    propertyAddress: string;
    model: string;
    modelName: string;
    email: string;
    phone: string;
}

@Component({
    selector: 'hvac-connected-portal-connect-customers',
    templateUrl: './connected-portal-connect-customers.component.html',
    styleUrls: ['./connected-portal-connect-customers.component.scss']
})
export class ConnectedPortalConnectCustomersComponent extends BaseComponent implements OnInit {
    ngOnDestroy$ = new Subject();
    currentPage = 1;
    pageSize = SEARCH_RESULTS_PAGE_SIZE;
    itemCount = 0;
    totalPages: number = 1;
    items?: ConnectedPortalCustomerProperty[];
    isLoading: boolean;
    dealerId?: string;
    searchTerm?: string | null;

    constructor(
        private queryParamsService: QueryParamsService,
        private dealersService: DealersService,
        private searchService: SearchService,
        private router: Router,
        private csvExportService: CSVExportService,
        private translateService: TranslateService,
        private customerControlContextSvc: CustomerControlContextService,
        private toastService: ToastService
    ) {
        super();
    }

    ngOnInit(): void {
        this.searchService.searchPlaceholder.next('CONNECTED_PORTAL.CUSTOMERS.SEARCH.PLACEHOLDER');

        this.isLoading = true;

        const urlPageNumber = this.router.url?.split('page=')[1]?.split('&')[0] ? Number(this.router.url?.split('page=')[1]?.split('&')[0]) : this.currentPage;

        if (urlPageNumber !== this.currentPage) {
            this.handlePageChange(1);
        }

        this.queryParamsService.init({ page: this.currentPage })
            .pipe(
                debounceTime(QUERY_PARAM_DEBOUNCE_INTERVAL),
                switchMap((value) => {
                    const { page, search } = value;
                    const nextPage = this.getNextPage(page);

                    this.isLoading = true;
                    this.dealerId = this.router.url?.split('dealers/')[1]?.split('/')[0];
                    this.searchTerm = search;
                    this.currentPage = nextPage;

                    return this.getProperties(this.dealerId, nextPage, search)
                        .pipe(
                            takeUntil(this.ngOnDestroy$),
                            catchError(() => {
                                this.isLoading = false;

                                return this.items = [];
                            })
                        );
                }),
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((res) => {
                const { totalCount, totalPages, data } = res;

                this.totalPages = totalPages;
                this.itemCount = totalCount;
                this.isLoading = false;

                this.items = data;
            });

        if (this.customerControlContextSvc.isControlAdded) {
            if (this.customerControlContextSvc.toastContent) {
                this.toastService.add(this.customerControlContextSvc.toastContent);
            }

            this.customerControlContextSvc.isControlAdded = false;
            this.customerControlContextSvc.toastContent = null;
        }
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    handlePageChange(page: number) {
        this.queryParamsService.updateParams({
            page,
            scroll: false
        });
    }

    handleExportProperties() {
        if (this.dealerId) {
            this.isLoading = true;

            this.getProperties(this.dealerId, 1, this.searchTerm, this.itemCount).pipe(
                takeUntil(this.ngOnDestroy$),
                catchError(() => {
                    this.isLoading = false;

                    return [];
                })
            )
                .subscribe((res) => {
                    const { data } = res;

                    const formattedData = data.reduce((acc, curItem, index) => {
                        const { name, propertyAddress, modelName, model, email, phone } = curItem;
                        const rowData = {
                            name,
                            propertyAddress,
                            modelName,
                            model,
                            email,
                            phone
                        };

                        if (index === 0) {
                            acc.headings = Object.keys(rowData)
                                .map((key) => this.translateService.instant(`CONNECTED_PORTAL.CUSTOMERS.CONNECTED_LIST.EXPORT_HEADINGS.${key}`));
                        }

                        acc.body.push(Object.values(rowData));

                        return acc;
                    }, {
                        headings: [],
                        body: []
                    } as BasicCSV);

                    const csvData = this.csvExportService.toCSV(formattedData);
                    this.csvExportService.saveAs(csvData, EXPORT_FILENAME_PREFIX);
                    this.isLoading = false;
                });
        }
    }

    trackByItem(_index: number, item: ConnectedPortalCustomerProperty) {
        return item.id;
    }

    private getNextPage(currentPage: number): number {
        if (this.totalPages) {
            return currentPage > this.totalPages ? this.totalPages : currentPage;
        }

        return currentPage;
    }

    private getProperties(dealerId: string, page: number, searchTerm?: string | null, pageSize = this.pageSize) {
        const queryParams: SearchQueryParams = {
            pagination: {
                pageSize,
                currentPage: page
            }
        };

        if (searchTerm) {
            queryParams.searchTerm = searchTerm;
        }

        return this.dealersService
            .queryPropertiesByDealerId(dealerId, queryParams)
            .pipe(
                map((value): { totalPages: number, totalCount: number, data: ConnectedPortalCustomerProperty[]} => {
                    const { totalPages, totalCount } = value;

                    const items = value.data.map((entry): ConnectedPortalCustomerProperty => {
                        const { id, customer, address } = entry;
                        const { firstName, lastName, email, phone1 } = customer;

                        const name = `${firstName} ${lastName}`;

                        const addressStreet = address.address2 ? `${address.address1}, ${address.address2}` : address.address1;
                        const propertyAddress = `${addressStreet}, ${address.city}, ${address.state} ${address.zipCode}`;

                        return {
                            id,
                            name,
                            propertyAddress,
                            email,
                            phone: phone1,
                            model: entry.systems[0].products[0].modelNo,
                            modelName: entry.systems[0].products[0].name
                        };
                    });

                    return {
                        totalPages,
                        totalCount,
                        data: items
                    };
                })
            );
    }
}
