import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { BaseResponse } from 'common/models/account-status';
import { ApiOptionsService, ApiOptions } from 'common/services/api-options/api-options.service';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoolCalcService {
    public isCoolCalcLoading: boolean = false;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    public validateSessionUser(id: string): Observable<BaseResponse> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<BaseResponse>(`${environment.api.validateSessionUser}?userId=${id}`, { ...options })),
            catchError((err: HttpErrorResponse) => of(err.error))
        );
    }
}
