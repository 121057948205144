<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-breadcrumb-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>

        <div *ngIf="(recentActivityAlert$ | async) as recentActivityAlert" class="hvac-row hvac-center-xs hvac-rhythm-4 hvac-cp-activity-banner">
            <div class="hvac-col-xs-11">
                <hvac-recent-activity-banner [alert]="recentActivityAlert" (close)="closeRecentActivity()"></hvac-recent-activity-banner>
            </div>
        </div>
    </div>
    <div bottom>
        <div class="hvac-cp-card">
            <ng-container *ngIf="(formattedPropertyDetails$ | async) as propertyDetails; else loadingInfo">
                <h2 class="hvac-cp-customer-name">{{ propertyDetails.customerInfo.name }}</h2>
                <ul class="hvac-cp-info-list">
                    <li class="hvac-cp-info-item">
                        <hvac-info-card [headerText]="'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.PROPERTY' | translate">
                            <address>
                                {{ propertyDetails.propertyAddress.address1 }}<br/>
                                <ng-container *ngIf="propertyDetails.propertyAddress?.address2">
                                    {{ propertyDetails.propertyAddress.address2 }}<br/>
                                </ng-container>
                                {{ propertyDetails.propertyAddress.region }}
                            </address>
                        </hvac-info-card>
                    </li>
                    <li class="hvac-cp-info-item">
                        <hvac-info-card [headerText]="'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.CUSTOMER_INFORMATION' | translate">
                            <div>
                                <span class="hvac-cp-label">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.PRIMARY_PHONE' | translate }}:</span>
                                <a *ngIf="propertyDetails.customerInfo.phone" class="hvac-text-link" [attr.href]="'tel:' + propertyDetails.customerInfo.phone">{{ propertyDetails.customerInfo.phone }}</a>
                            </div>
                            <div>
                                <span class="hvac-cp-label">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.EMAIL_ADDRESS' | translate }}:</span> <a class="hvac-text-link" [attr.href]="'mailto:' + propertyDetails.customerInfo.email">{{ propertyDetails.customerInfo.email }}</a>
                            </div>
                            <div>
                                <span class="hvac-cp-label">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.USERNAME' | translate }}:</span> {{ propertyDetails.customerInfo.username }}
                            </div>
                        </hvac-info-card>
                    </li>
                    <li class="hvac-cp-info-item">
                        <hvac-info-card [headerText]="'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.DEALER_INFORMATION' | translate">
                            <div>
                                <span class="hvac-cp-label">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.NAME' | translate }}:</span> {{ propertyDetails.dealerInfo.name }}
                            </div>
                            <div>
                                <span class="hvac-cp-label">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.INFO.HVP_ID' | translate }}:</span> {{ propertyDetails.dealerInfo.hvpId }}
                            </div>
                        </hvac-info-card>
                    </li>
                </ul>
            </ng-container>

            <ng-template #loadingInfo>
                <div class="hvac-cp-loader">
                    <utc-loading-spinner></utc-loading-spinner>
                </div>
            </ng-template>
        </div>

        <ul class="hvac-cp-property-system-list" *ngIf="(dealerId$ | async) as dealerId">
            <li class="hvac-cp-property-system-item" *ngFor="let system of (formattedSystems$ | async); let i = index">
                <hvac-property-system-panel *ngIf="dealerId"
                    [dealerId]="dealerId"
                    [system]="system"
                    [openOnInit]="connectedProductData ? system.systemId === connectedProductData.systemId : i === 0"
                    [isRelearnSupported]="system.systemType === SystemType.ECOBEE && !isRelearning"
                    [isRelearning]="isRelearning && (relearnSystemId$ | async) === system.systemId"
                    (onRelearnEquipment)="openRelearnModal($event)"
                    (onUpdateBtnClick)="openUpdateModal($event)"
                    ></hvac-property-system-panel>
            </li>
        </ul>

    </div>

    <utc-modal modal class="hvac-relearn-modal"
        [visible]="isRelearnModalVisible"
        (closeAction)="closeRelearnModal()"
        size="small"
        theme="rounded">

        <hvac-modal-header>
            <span class="hvac-h3">{{'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.MODAL.HEADING' | translate}}</span>
        </hvac-modal-header>

        <div class="hvac-relearn-modal-body">
            <div class="hvac-relearn-modal-copy hvac-p-sm" [innerHTML]="'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.MODAL.MESSAGE' | translate"></div>

            <div class="hvac-relearn-modal-buttons">
                <button hvacButton="default" size="small" (click)="closeRelearnModal()">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.MODAL.CANCEL' | translate }}</button>
                <button hvacButton="primary" size="small" (click)="confirmRelearn()">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.RELEARN.MODAL.RELEARN' | translate }}</button>
            </div>
        </div>
    </utc-modal>

    <utc-modal modal class="hvac-update-product-details-modal" *ngIf="isUpdateModalVisible"
        [visible]="isUpdateModalVisible"
        (closeAction)="closeUpdateModal($event)"
        size="small"
        theme="rounded">

        <hvac-modal-header>
            <span class="hvac-h3">{{(connectedProductData.productType === ProductType.IndoorUnit ? 'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.HEADING.IDU_HEADING' :  'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.HEADING.ODU_HEADING')| translate}}</span>
        </hvac-modal-header>

        <div class="hvac-relearn-modal-body">
            <hvac-update-product-details-modal *ngIf="isUpdateModalVisible"
                [currentValues]="connectedProductData"
                (closeAction)="closeUpdateModal($event)"
                (showLoader)="showProductUpdateLoader()">
            </hvac-update-product-details-modal>

            <div class="hvac-cp-loader" *ngIf="isProductUpdateLoaderVisible">
                <utc-loading-spinner>{{'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.LOADER_INFO' | translate}}</utc-loading-spinner>
            </div>
        </div>
    </utc-modal>
</hvac-connected-portal-layout-container>
