<nav class="hvac-cp-customers-list-menu">
    <div class="hvac-label">{{ 'CONNECTED_PORTAL.CUSTOMERS.MENU.VIEW_BY' | translate }}</div>
    <ul class="hvac-cp-list">
        <li class="hvac-cp-menu-item">
            <a class="hvac-cp-menu-link" [routerLink]="[ 'connected' ]" routerLinkActive="hvac-cp-menu-link-active" (click)="handleLinkClick('connected')">
                <div class="hvac-cp-link-count hvac-h2">{{ connectedCount | number: '1.0-0'}}</div>
                <span class="hvac-cp-link-text hvac-p-sm">{{ 'CONNECTED_PORTAL.CUSTOMERS.MENU.CONNECTED_CUSTOMERS' | translate }}</span>
            </a>
        </li>
        <li class="hvac-cp-menu-item">
            <a class="hvac-cp-menu-link" [routerLink]="[ 'queued' ]" routerLinkActive="hvac-cp-menu-link-active" (click)="handleLinkClick('queued')">
                <div class="hvac-cp-link-count hvac-h2">{{ queuedCount | number: '1.0-0' }}</div>
                <span class="hvac-cp-link-text hvac-p-sm">{{ 'CONNECTED_PORTAL.CUSTOMERS.MENU.CUSTOMER_QUEUE' | translate }}</span>
            </a>
        </li>
    </ul>
</nav>
