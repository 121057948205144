export enum EventType {
    ELIGIBLE = 'eligible',
    NOT_ELIGIBLE = 'not eligible',
    ENROLLED = 'enrolled'
}

export enum Activity {
    SCHEDULED= 'scheduled',
    RESPONDING = 'responding',
    INACTIVE = 'inactive'
}

export type EnergyEventsType = 'eligible' | 'enrolled' | 'notEligible' | 'scheduled' | 'responding' | 'inactive';
