import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ConnectedPortalSystemFault, FaultSeverity } from 'private/app/models/system-fault.model';

@Component({
    selector: 'hvac-factory-systems-faults-list',
    templateUrl: './factory-systems-faults-list.component.html',
    styleUrls: ['./factory-systems-faults-list.component.scss']
})
export class FactorySystemsFaultsListComponent extends BaseComponent {
    @Input() faults: ConnectedPortalSystemFault[];
    @Input() totalPages: number;
    @Input() currentPage: number;
    @Input() isLoading: boolean;
    @Output() public onPageChange: EventEmitter<number> = new EventEmitter();

    public readonly ConnectedSystemFaultSeverity = FaultSeverity;

    constructor() {
        super();
    }

    trackByAlert(_index: number, alert: ConnectedPortalSystemFault) {
        return alert.id;
    }

    handlePageChange(nextPage: number) {
        this.onPageChange.emit(nextPage);
    }
}
