import { Component, ViewChild, ElementRef, Inject, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CreateAccountInfoFormComponent } from 'private/app/components/create-account-info-form/createAccountInfoForm.component';
import { WINDOW } from 'common/window.provider';
import { PagePositionService } from 'common/services/page-position.service';

@Component({
    selector: 'hvac-login-create-account',
    templateUrl: './login-create-account.component.html',
    styleUrls: ['./login-create-account.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginCreateAccountComponent {
    @ViewChild(CreateAccountInfoFormComponent) createAccountInfoForm: CreateAccountInfoFormComponent;

    infoValid$ = new BehaviorSubject(false);

    constructor(
        private readonly el: ElementRef,
        private readonly pagePositionService: PagePositionService,
        @Inject(WINDOW) private readonly window: Window
    ) {}

    submitAccountInfoForm() {
        this.createAccountInfoForm.submitAccountInfo();
    }

    updateInfoValid(isValid: boolean) {
        this.infoValid$.next(isValid);
    }

    focusForm() {
        const elPosition = this.pagePositionService.getAbsoluteElementPosition(this.el.nativeElement) || 0;
        this.window.scrollTo({
            left: 0,
            top: elPosition.top,
            behavior: 'smooth'
        });
        this.window.setTimeout(() => this.createAccountInfoForm.focusForm(), 500);
    }
}
