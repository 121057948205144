import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RemoveNotificationError } from 'common/models/create-account-errors';
import { NotificationService } from 'common/services/notification.service';
import { HvacNotification, NotificationStateModel } from 'common/models/Notifications';
import { RemoveNotifications } from './notification.actions';
import { DataResolverService } from '../../../common/content/services/data-resolver.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'common/services/toast.service';

@State<NotificationStateModel>({
    name: 'notifications',
    defaults: { notifications: [] }
})
@Injectable({ providedIn: 'root' })
export class NotificationsState {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private readonly notificationService: NotificationService,
        private dataResolverService: DataResolverService,
        private toastService: ToastService
    ) { }

    @Selector()
    static notifications({ notifications }: NotificationStateModel): HvacNotification[] {
        return notifications;
    }

    @Action(RemoveNotifications)
    removeNotifications(_ctx: StateContext<NotificationStateModel>, action: RemoveNotifications) {
        this.notificationService.removeNotifications(action.notifications).subscribe(
            (_result: string) => {
                this.dataResolverService.resolve(this.route.snapshot, this.router.routerState.snapshot).subscribe();
            },
            (_err) => {
                this.toastService.add({
                    content: new RemoveNotificationError().content,
                    id: new RemoveNotificationError().name,
                    theme: 'error',
                    closeable: true,
                    autoClose: true
                });
            }
        );
    }
}
