<div class="hvac-eligibility-criteria-container">

    <form [formGroup]="fieldCriteriaForm" class="hvac-rhythm-3">
        <div class="hvac-row hvac-criteria-name-container">
            <div class="hvac-row hvac-criteria-name-text">
                <h2 class="hvac-header">
                    {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CRITERIA_NAME' | translate }}
                    <abbr title="required" class="hvac-program-criteria-header-required">*</abbr>
                </h2>
                <p class="hvac-p-sm">
                    <abbr title="required" class="hvac-program-header-required">*</abbr>
                    {{"ACCOUNT_ADMIN.PROGRAMS.REQUIRED_INFORMATION" | translate }}
                </p>
            </div>
            <utc-input class="hvac-criteria-name-input hvac-col-xs-12 hvac-col-md-5 hvac-col-4"
                [inputControl]="fieldCriteriaForm.controls.criteriaName" shadow="none"
                [label]="'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.CRITERIA_NAME_TEXT' | translate"
                [error]="fieldCriteriaForm.controls.criteriaName.touched && !fieldCriteriaForm.controls.criteriaName.valid"
                [max]="maxInputLimitNameCriteriaField">
            </utc-input>
        </div>
    </form>
    <br>

    <h2 class="hvac-header">
        {{ 'ACCOUNT_ADMIN.PROGRAMS.FIELD_CRITERIA' | translate }}
    </h2>
    <hvac-dynamic-nested-filter [QueryBuilderConfigData]="queryBuilderConfigValues" [QueryData]="queryDataToDisplay" [ProgramNameData$]="programNameData$" [autoComplete$]="autoComplete$" (updatedQueryData)="modifyQueryData($event)" (programNameString)="programName($event)" (programNameWithPagination)="programNameWithPagination()"> </hvac-dynamic-nested-filter>

    <div class="hvac-row hvac-eligibility-criteria-field-container">

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-6">
            <form [formGroup]="customerCompanyForm" class="hvac-rhythm-4">
                <div class=" hvac-company-id hvac-criteria-input">
                    <h2 class="hvac-header">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.CUSTOMER_COMPANYID' | translate }}
                    </h2>
                    <hvac-input-file acceptableFileType="csv" [displayInputField]=displayInputField fileUploadControl="customer" [getCleanUpActionRef]="assignComapniesFileUploadCleanUpFuncRef"(onUploadFile)="eligibilityCriteriaService.uploadedFile($event)" (onImportFile)="eligibilityCriteriaService.importedFile($event)"></hvac-input-file>
                    <div class="hvac-upload-file-table" *ngIf="eligibilityCriteriaService.isCustomerTableVisible">
                        <div class="hvac-upload-file-table-header">
                            <p class="hvac-p-sm">
                                {{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}
                            </p>
                            <p class="hvac-p-sm">
                                {{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION' | translate }}
                            </p>
                        </div>
                        <div class="hvac-upload-file-table-body">
                            <form [formGroup]="customerCompanyForm">
                                <utc-input shadow="none" class="hvac-col-lg-12  hvac-col-md-12 hvac-col-xs-12" type="text" [inputControl]="customerCompanyForm.controls.customerCompanyId"
                                    label="Filter"  (keyup)="filterCustomerID($event)">
                                </utc-input>
                            </form>
                            <hvac-table class="hvac-criteria-table-wrap" theme="zebra">
                                <tbody>
                                    <ng-template ngFor let-company [ngForOf]="(eligibilityCriteriaService.customerCompanySearchFilterData$ | async)">
                                        <tr>
                                            <td>
                                                {{company}}
                                            </td>
                                            <td class="hvac-upload-file-actions-width">
                                                <button type="button" (click)="eligibilityCriteriaService.removeCompanyId(company)" hvacButton="text">{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.REMOVE' | translate }}</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </hvac-table>
                            <p class="hvac-upload-file-no-results" *ngIf="!(eligibilityCriteriaService.customerCompanyList$ | async)?.length">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
                        </div>
                        <div class="hvac-upload-fle-table-footer">
                            <hvac-pagination *ngIf="!isCustomerCompanyIDFiltered; else removeCustomerCompanyIDPagination" [totalPages]="eligibilityCriteriaService.customerTotalPages" [currentPage]="eligibilityCriteriaService.customerCurrentPage" (pageChange)="eligibilityCriteriaService.handleCustomerPageChange($event)">
                            </hvac-pagination>
                            <ng-template #removeCustomerCompanyIDPagination>
                                <hvac-pagination [totalPages]="totalPagesAfterFilter" [currentPage]="currentPageAfterFilter">
                                </hvac-pagination>
                            </ng-template>

                            <div class="hvac-upload-file-table-count">
                                <p>{{eligibilityCriteriaService.customerTotalCompany}} Results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="hvac-col-xs-12 hvac-col-md-6 hvac-col-6">
            <form [formGroup]="distributorCompanyForm" class="hvac-rhythm-4">
                <div class=" hvac-company-id hvac-criteria-input">
                    <h2 class="hvac-header">
                        {{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.PLACEHOLDER_TEXT.DISTRIBUTOR_COMPANYID' | translate }}
                    </h2>
                    <hvac-input-file acceptableFileType="csv" [displayInputField]=displayInputField control="distributor"[getCleanUpActionRef]="assignDistributorsFileUploadCleanUpFuncRef"(onUploadFile)="eligibilityCriteriaService.uploadedFile($event)" (onImportFile)="eligibilityCriteriaService.importedFile($event)"></hvac-input-file>
                    <div class="hvac-upload-file-table" *ngIf="eligibilityCriteriaService.isDistributorTableVisible">
                        <div class="hvac-upload-file-table-header">
                            <p class="hvac-p-sm">
                                {{ 'ACCOUNT_ADMIN.COMPANY_ID' | translate }}
                            </p>
                            <p class="hvac-p-sm">
                                {{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION' | translate }}
                            </p>
                        </div>
                        <div class="hvac-upload-file-table-body">
                            <form [formGroup]="distributorCompanyForm">
                                <utc-input shadow="none" class="hvac-col-lg-12  hvac-col-md-12 hvac-col-xs-12" type="text" [inputControl]="distributorCompanyForm.controls.distributorCompanyId"
                                    label="Filter" (keyup)="filterDistributorID($event)">
                                </utc-input>
                            </form>
                            <hvac-table class="hvac-criteria-table-wrap" [theme]="'zebra'">
                                <tbody>
                                    <ng-template ngFor let-company [ngForOf]="(eligibilityCriteriaService.distributorCompanySearchFilterData$ | async)">
                                        <tr>
                                            <td>
                                                {{company}}
                                            </td>
                                            <td class="hvac-upload-file-actions-width">
                                                <button type="button" (click)="eligibilityCriteriaService.removeDistributorId(company)" hvacButton="text">{{ 'MANAGE_DEALER.THIRD_PARTY_RATINGS.REMOVE' | translate }}</button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </hvac-table>
                            <p class="hvac-upload-file-no-results" *ngIf="!(eligibilityCriteriaService.distributorCompanyList$ | async)?.length">{{ 'ACCOUNT_ADMIN.NO_RESULTS' | translate }}</p>
                        </div>
                        <div class="hvac-upload-fle-table-footer">
                            <hvac-pagination *ngIf="!isDistributorCompanyIDFiltered; else removeDistributorCompanyIDPagination" [totalPages]="eligibilityCriteriaService.distributorTotalPages" [currentPage]="eligibilityCriteriaService.distributorCurrentPage" (pageChange)="eligibilityCriteriaService.handleDistributorPageChange($event)">
                            </hvac-pagination>
                            <ng-template #removeDistributorCompanyIDPagination>
                                <hvac-pagination [totalPages]="totalPagesAfterFilter" [currentPage]="currentPageAfterFilter">
                                </hvac-pagination>
                            </ng-template>
                            <div class="hvac-upload-file-table-count">
                                <p>{{eligibilityCriteriaService.distributorTotalCompany}} Results</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="hvac-csv-template-note-container hvac-col-xs-12 hvac-col-md-12">
            <utc-svg class="hvac-note-info-icon" src="assets/icons/info.svg"></utc-svg>
            <p class="hvac-h6 notification-settings-note">{{ 'ACCOUNT_ADMIN.PROGRAMS.PROGRAM_ELIGIBILITY_CRITERIA.CSV_TEMPLATE_TEXT' | translate }}</p>
        </div>
    </div>
    <utc-loading-spinner spinnerSize="small" *ngIf="isLoading"></utc-loading-spinner>

    <div class="hvac-row hvac-eligibility-criteria-action-buttons">
        <button type="button" hvacButton="default" (click)="onCancel()">
            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CANCEL' | translate }}</span>
        </button>
        <button type="button" hvacButton="primary" (click)="onSubmit()" [disabled]="isLoading || fieldCriteriaForm.controls.criteriaName.invalid || hasEmptyRuleset(modifiedQueryDataToDisplay)">
            <span>{{ 'ACCOUNT_ADMIN.PROGRAMS.CREATE' | translate }}</span>
        </button>
    </div>
</div>
