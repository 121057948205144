<div class="hvac-cp-dealer-notification-settings">
    <h2 class="hvac-h3 hvac-cp-dealer-notification-settings-title">{{
        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.DEALER_EQUIPMENT_NOTIFICATION_SETTINGS' | translate}}
    </h2>
    <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div class="email-form-section hvac-row">
            <div class="primary-email-notification-form hvac-col-xs-12 hvac-col-md-6">
                <div class="hvac-form-field">
                    <utc-input inputId="primaryEmail" [error]="emailForm.controls.primaryEmail.dirty && !emailForm.controls.primaryEmail.valid"
                        label="{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ADDRESS_1' | translate }}"
                        [hideLabel]="false" [inputControl]="emailForm.controls.primaryEmail" type="text">
                    </utc-input>
                </div>
                <utc-checkbox class="hvac-form-checkbox"
                    [inputControl]="emailForm.controls.primaryCriticalAlertsEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_CRITICAL_ALERTS' | translate
                        }}</span>
                </utc-checkbox>
                <utc-checkbox class="hvac-form-checkbox" [inputControl]="emailForm.controls.primaryMaintenanceEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_MAINTENANCE_ALERTS' | translate
                        }}</span>
                </utc-checkbox>
                <utc-checkbox class="hvac-form-checkbox"
                    [inputControl]="emailForm.controls.primaryControlOfflineEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_CONTROL_OFFLINE_ALERTS' |
                        translate }}</span>
                </utc-checkbox>
            </div>
            <div class="secondary-email-notification-form hvac-col-xs-12 hvac-col-md-6">
                <div class="hvac-form-field">
                    <utc-input inputId="secondaryEmail"
                        [error]="emailForm.controls.secondaryEmail.dirty && !emailForm.controls.secondaryEmail.valid"
                        label="{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ADDRESS_2' | translate }}"
                        [hideLabel]="false" [inputControl]="emailForm.controls.secondaryEmail" type="text">
                    </utc-input>
                </div>
                <utc-checkbox class="hvac-form-checkbox"
                    [inputControl]="emailForm.controls.secondaryCriticalAlertsEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_CRITICAL_ALERTS' | translate
                        }}</span>
                </utc-checkbox>
                <utc-checkbox class="hvac-form-checkbox"
                    [inputControl]="emailForm.controls.secondaryMaintenanceEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_MAINTENANCE_ALERTS' | translate
                        }}</span>
                </utc-checkbox>
                <utc-checkbox class="hvac-form-checkbox"
                    [inputControl]="emailForm.controls.secondaryControlOfflineEnabled">
                    <span class="hvac-checkbox-label">{{
                        'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.EMAIL_ME_CONTROL_OFFLINE_ALERTS' |
                        translate }}</span>
                </utc-checkbox>
            </div>
        </div>
        <div class="bottom-content hvac-row">
            <div class="hvac-notification-settings-note-container hvac-col-xs-12 hvac-col-md-8">
                <utc-svg class="hvac-note-info-icon" src="assets/icons/info.svg"></utc-svg>
                <p class="hvac-h6 notification-settings-note">{{
                    'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.DEFAULT_NOTE' | translate }}</p>
            </div>
            <div class="hvac-submit-button-container hvac-col-xs-12 hvac-col-md-4">
                <button class="notification-save-button" [hvacButton]="'primary'"
                    [disabled]="!emailForm.controls.primaryEmail.valid || (emailForm.controls.secondaryEmail.value.length > 0 && !emailForm.controls.secondaryEmail.valid)"
                    type="submit">
                    <span>{{ 'CONNECTED_PORTAL.DEALER_SETTINGS.NOTIFICATION_SETTINGS.SAVE_CHANGES' | translate }}</span>
                </button>
            </div>
        </div>
    </form>
    <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>
</div>
