<div class="hvac-connected-portal-alerts-options">
    <div class="hvac-connected-portal-alerts-options-date">
        <label class="hvac-subhead">{{ 'CONNECTED_PORTAL.ALERTS.DATE_RANGE' | translate }}</label>
        <hvac-date-range-picker
            [startDate]="alertsStartDate"
            [endDate]="alertsEndDate"
            [minStartDate]="minStartDate"
            [maxEndDate]="maxEndDate"
            [submitText]="('CONNECTED_PORTAL.ALERTS.ACTIONS.APPLY' | translate)"
            (onSelection)="handleDateRangeChange($event)"></hvac-date-range-picker>
    </div>
</div>
