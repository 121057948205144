
<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-faq-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>
        <div class="hvac-cp-card">
            <hvac-table *ngIf="(dealers$ | async) as dealers" [responsive]="true" theme="zebra">
                <thead>
                    <tr>
                        <th>{{ 'CONNECTED_PORTAL.LINKED_DEALERS.HVPID' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.LINKED_DEALERS.NAME' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.LINKED_DEALERS.CUSTOMERS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.LINKED_DEALERS.CONNECTED_CONTROLS' | translate }}</th>
                        <th>{{ 'CONNECTED_PORTAL.LINKED_DEALERS.QUEUED_CONTROLS' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody [ngClass]="{ 'hvac-cp-empty-list' : dealers.length === 0 }">
                    <tr *ngFor="let dealer of dealers">
                        <td [attr.data-label]="'CONNECTED_PORTAL.LINKED_DEALERS.HVPID' | translate">{{ dealer.id }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.LINKED_DEALERS.NAME' | translate">{{ dealer.name }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.LINKED_DEALERS.CUSTOMERS' | translate">{{ dealer.customerCount }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.LINKED_DEALERS.CONNECTED_CONTROLS' | translate">{{ dealer.connectedControlCount }}</td>
                        <td [attr.data-label]="'CONNECTED_PORTAL.LINKED_DEALERS.QUEUED_CONTROLS' | translate">{{ dealer.queuedControlCount }}</td>
                        <td>
                            <div class="hvac-cp-details-link-wrapper" [ngClass]="{'hvac-cp-details-link-hidden' : dealer.customerCount === 0 && dealer.connectedControlCount === 0 && dealer.queuedControlCount === 0 }" >
                                <a *ngIf="dealer.id"
                                    class="hvac-cp-details-link"
                                    hvacButton="default"
                                    size="small"
                                    [routerLink]="['../../../dealers', dealer.id]">{{ 'CONNECTED_PORTAL.LINKED_DEALERS.VIEW_DASHBOARD' | translate }}</a>
                            </div>
                        </td>
                    </tr>

                    <ng-container *ngIf="!isLoading && dealers?.length === 0">
                        <tr>
                            <td colspan="6" [align]="'center'">
                                <div class="hvac-fw-bd">{{ 'CONNECTED_PORTAL.LINKED_DEALERS.EMPTY_MESSAGE' | translate }}</div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </hvac-table>

            <utc-loading-spinner variant="overlay" *ngIf="isLoading"></utc-loading-spinner>

            <div class="hvac-cp-list-footer">
                <div *ngIf="totalPages > 1" class="hvac-cp-list-footer-pagination">
                    <hvac-pagination
                        [totalPages]="totalPages"
                        [currentPage]="currentPage"
                        (pageChange)="handlePageChange($event)">
                    </hvac-pagination>
                </div>
            </div>
        </div>
    </div>
</hvac-connected-portal-layout-container>



