import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { QuickLink } from 'common/models/quick-links';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class QuickLinksApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) { }

    updateQuickLinks(quickLinks: QuickLink[]) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => {
                if (environment.api.accountQuicklinksPersonalizationApi) {
                    return this.httpClient.put<string>(environment.api.accountQuicklinksPersonalizationApi, { quickLinks: quickLinks }, { ...options });
                }

                return of('');
            }),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }
}
