<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-cp-header">
            <hvac-content-header [data]="breadCrumbData"></hvac-content-header>
        </div>
    </div>
    <div bottom>
        <hvac-connected-portal-advanced-search [parentComponentName]="componentName" ></hvac-connected-portal-advanced-search>
    </div>
</hvac-connected-portal-layout-container>
