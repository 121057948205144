import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InputTheme } from 'common/components/search-input/search-input.component';
import { PurchaseOrderDetail, PO_STATUS } from 'private/app/models/purchase-order-details.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Select } from '@ngxs/store';
import { AppConstants } from 'common/app-constants';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AccountStatusModel } from 'common/models/account-status';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrderService } from 'private/app/services/purchase-orders.service';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

const LIST_LENGTH = 20;

@Component({
    selector: 'hvac-purchase-order-list',
    templateUrl: './purchase-order-list.component.html',
    styleUrls: ['./purchase-order-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderListComponent implements OnInit, OnDestroy {
    @Select(AccountStatusState.getProfileInfo) user$: Observable<AccountStatusModel | null>;
    poStatusOptions = PO_STATUS;
    ngOnDestroy$ = new Subject();
    purchaseOrderSearch = new UntypedFormControl('', Validators.required);
    searchTheme: InputTheme = 'light';
    public isLoading: Boolean;
    public readonly AppConstants = AppConstants
    public purchaseOrders$ = new BehaviorSubject<PurchaseOrderDetail[]>([]);
    public hasError: Boolean;
    public listLength: number = LIST_LENGTH;
    public start = 0;
    public end = 8;
    public pagination = {
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        start: 0,
        end: this.listLength
    }

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private purchaseOrderService: PurchaseOrderService,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}


    ngOnInit() {
        this.purchaseOrderService.isLoading.next(true);
        this.purchaseOrderService.fetchPurchaseOrders().pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(
            (results) => {
                this.purchaseOrderService.purchaseOrderList$.next(results);
                this.pagination.totalItems = results?.length;
                this.pagination.totalPages = Math.ceil(results?.length / this.listLength);
                this.purchaseOrders$.next(results);
                this.purchaseOrderService.isLoading.next(false);
            },
            () => {
                this.purchaseOrderService.isLoading.next(false);
                this.toastService.add({
                    bgColor: '#F8F8F8',
                    closeable: true,
                    content: this.translate.instant('TRADE_PARTNERS.API_ERROR_403'),
                    id: 'trade-partners-api-error',
                    theme: 'error'
                });
            }
        );

        this.purchaseOrderService.isLoadingObs.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((isLoading) => {
            this.isLoading = isLoading;
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    public onSubmit() {
        this.hasError = false;
        this.purchaseOrderService.getActivePo(this.purchaseOrderSearch.value).pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(
            (activepo) => {
                const { pONumber, pOstatus } = activepo;
                if (pOstatus === PO_STATUS.ACKNOWLEDGED || pOstatus === PO_STATUS.PARTIAL_SHIP) {
                    this.router.navigate([`./${pONumber}/advanced-shipment`], { relativeTo: this.route });
                }
                else {
                    this.router.navigate(['./', pONumber], { relativeTo: this.route });
                }
            },
            () => {
                this.hasError = true;
            }
        );
    }

    public onPagination(pageAdvanced: number) {
        this.goToPage(pageAdvanced);
    }

    private goToPage(pageAdvanced: number) {
        this.pagination.currentPage = pageAdvanced;
        this.pagination.start = this.listLength * (pageAdvanced - 1);
        this.pagination.end = this.pagination.start + this.listLength;
    }
}
