import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from 'common/app-constants';
import { CharSymbol } from 'common/enums/char-symbol';
import { AlertSeverity, AlertStatusFilter } from 'private/app/models/alert.model';


export interface WallControlEventLogEvent {
    code:string;
    dateTime: string;
    severity: AlertSeverity;
    type?: string | null;
    description?: string | null;
    consecutiveEvents?: number | null;
    equipmentSource?: string | null;
    diagnosticsUrl?: string | null;
}

export class WallControlEventLogConfig {
    code: boolean = true;
    dateTime: boolean = true;
    severity: boolean = true;
    type: boolean = true;
    description: boolean = true;
    consecutiveEvents: boolean = true;
    equipmentSource: boolean = true;
    diagnostics: boolean = true;
}

@Component({
    selector: 'hvac-wall-control-event-log',
    templateUrl: './wall-control-event-log.component.html',
    styleUrls: ['./wall-control-event-log.component.scss']
})
export class WallControlEventLogComponent implements OnInit {
    @Input() data: WallControlEventLogEvent[];
    @Input() totalPages: number;
    @Input() currentPage: number;
    @Input() enableFilter: boolean =false;
    @Input() config?: WallControlEventLogConfig = new WallControlEventLogConfig();
    @Input() isResponsive: boolean =true;
    @Output() onPageChange = new EventEmitter<number>();
    @Output() selectedFilter = new EventEmitter<AlertStatusFilter>();
    public readonly AppConstants = AppConstants;
    public readonly CharSymbol= CharSymbol;
    public selectedTab: string = AlertStatusFilter.ACTIVE;
    readonly AlertStatusFilter = AlertStatusFilter;
    handlePageChange(page: number) {
        this.onPageChange.emit(page);
    }

    handleTabClick(tabSelection: AlertStatusFilter) {
        this.selectedTab = tabSelection;
        this.selectedFilter.emit(tabSelection);
    }

    ngOnInit(): void {
        if (this.data?.length === 0 && this.selectedTab === AlertStatusFilter.ACTIVE) {
            this.selectedTab = AlertStatusFilter.ALL;
            this.selectedFilter.emit(AlertStatusFilter.ALL);
        }
    }
}
