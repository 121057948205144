import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterObjectArray' })
export class FilterObjectArrayPipe implements PipeTransform {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    transform(value?: any[] | undefined | null, filterFunction?: any, filterParam?: string): any[] {
        if (value && Array.isArray(value)) {
            return [...value].filter(filterFunction.bind(null, filterParam));
        }

        return [];
    }
}

