<div>
    <div *ngIf ="usersData.size > 0" class="results-counts-info"> 
        {{ "ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.RESULT_COUNT_MESSAGE" | translate: 
            { 
                begin: (usersData.crntPgNum - 1) * pageSize + 1,
                end: usersData.crntPgNum === usersData.pgsCount ? usersData.size : (usersData.crntPgNum) * pageSize, 
                total: usersData.size
            }
        }}
    </div>
    <div class="hvac-table-wrap" >
        <hvac-table [responsive]="true" [theme]="'zebra'">
            <thead>
                <tr>
                    <td *ngFor="let item of dataHeaders">
                        <button class="hvac-table-sort-btn" (click)="sort(item)">
                            {{item.title}}
                            <span class="hvac-table-list-sort" [ngClass]="{
                                'hvac-table-list-sort-show': item.order,
                                'column-ascending': item.order === 'asc'
                            }">
                                <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                            </span>
                        </button>
                    </td>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let userData of usersData.crntPgSlctblUIData; let row=even;">
                        <td class="center-align" [attr.data-label]="dataHeaders[0].title">
                            <utc-checkbox [checked]="userData.isSelected" (click)="selectUserRegistration($event, userData.getId())"></utc-checkbox>
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[1].title">
                            {{userData.data.firstName}}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[2].title">
                            {{userData.data.lastName}}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[3].title">
                            {{ userData.data.userName ? userData.data.userName : "--"}}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[4].title">
                            {{userData.data.companyName}}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[5].title">
                            {{userData.data.companyId}}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[6].title">
                            {{userData.data.createdDate | date: 'MM/dd/yyyy' }}
                        </td>
                        <td class="center-align" [attr.data-label]="dataHeaders[7].title">
                            {{userData.data.userStatus}}
                        </td>
                        <td (click)="onClickReview(userData.data)" class="center-align" [attr.data-label]="dataHeaders[8].title">
                            <button [hvacButton]="row ? 'default' : 'secondary'" type="button">
                                {{ "ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.USER_RESULT_REVIEW" | translate}}
                            </button>
                        </td>
                    </tr>
            </tbody>
        </hvac-table>   
        <utc-loading-spinner *ngIf="loading" class="hvac-account-admin-loader" spinnerSize="small"></utc-loading-spinner>
        <div class="footer-controls">
            <div><hvac-pagination
                [useUIData]="true"
                [UIData]="usersData.dataFrPgntn"
                [globalDataHolder]="usersData.dataFrPgntn"
                [currentPage]="usersData.crntPgNum"
                [totalPages]="usersData.pgsCount"
                (pageChange)="usersData.onPageChange($event)"
            >
            </hvac-pagination></div>
            <div class="export-controls">
                <button class="control" (click)="onSelectAllCurrentPageClick()" [hvacButton]="'secondary'" type="button">{{ "ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SELECT_ALL_BUTTON_TEXT" | translate}}</button>
                <button class="control" (click)="onSelectAllFromAllPagesClick()" [hvacButton]="'secondary'" type="button">{{ "ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SELECT_ALL_PAGES_BUTTON_TEXT" | translate}}</button>
                <button class="control" (click)="onExportSelectedClick()" [hvacButton]="'primary'" type="button">{{ "ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.EXPORT_BUTTON_TEXT" | translate}}</button>
            </div>
        </div>
    </div>
</div>