import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { Company, CompanyContact } from 'private/app/models/account-admin-search';
import { CompanyRelationshipsResult, PlaceHolderText } from 'private/app/models/account-admin-company.model';
import { BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Option } from '../../../../common/components/select/select.component';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { ApiOptions } from '../../../../common/services/api-options/api-options.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'common/environments/environment';
import { UserDetailsEntity } from 'private/app/models/user.model';

export const RESIDENTIAL = 'RESR';

@Injectable({ providedIn: 'root' })
export class AccountAdminCompanyUtilsService {
    public userListCurrentPage: number = 1;
    public isuserListPageLoading$ = new BehaviorSubject<boolean>(true);
    public userPageSize: number = 10;
    public totalUsers: number;
    public radix: number = 10;
    public totalPages: number;
    public menuActive: boolean = false;
    public userUnderSelectedCompanyList$ = new BehaviorSubject<UserDetailsEntity[]>([]);
    public filterListUserUnderSelectedCompany$ = new BehaviorSubject<UserDetailsEntity[]>([]);
    public placeHolderData$: BehaviorSubject<PlaceHolderText> = new BehaviorSubject<PlaceHolderText>({
        placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_NUMBER'),
        placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER'),
        placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER'),
        placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER'),
        placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER'),
        placeHolderZipcode: { label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE') }
    });

    constructor(
        private translatePipe: TranslateService,
        private readonly apiOptions: ApiOptionsService,
        private readonly httpClient: HttpClient
    ) {
    }

    updateComapnyCreateByForm(form: UntypedFormGroup, company: Company) {
        company.name = form.controls.companyName.value;
        company.url = form.controls.companyUrl.value;
        company.soldToCustomerId = form.controls.soldTo.value;
        company.shipToCustomerId = form.controls.shipTo.value;
        company.payerCustomerId = form.controls.payer.value;
        company.priceListType = form.controls.priceList.value;
        company.priceGroup = form.controls.priceGroup.value;
        company.contractorLicense = form.controls.contLic.value;

        company.addressLine1 = form.controls.address1.value;
        company.addressLine2 = form.controls.address2.value;
        company.city = form.controls.city.value;
        company.postalCode = form.controls.postalCode.value;
        company.phoneNumber = form.controls.phone.value;
        company.mobileNum = form.controls.mobile.value;
        company.tollFreeNumber = form.controls.tollFree.value;
        company.faxNumber = form.controls.fax.value;
        company.emailAddress = form.controls.email.value;

        company.c10Parent = form.controls.c10Parent.value;
        company.c10Child = form.controls.c10Child.value;
        company.storeNumber = form.controls.storeNumber.value;
        company.searchRadius = form.controls.searchRadius.value;
        company.divRegNum = form.controls.divRegNumber.value;
        company.ownershipType = form.controls.ownership.value;
        company.latitude = form.controls.latitude.value;
        company.longitude = form.controls.longitude.value;
        company.locatorName = form.controls.locatorName.value;
        company.salesOfficeNumber = form.controls.salesOfficeNumber.value;
        company.notes = form.controls.notes.value;

        // IT Super Admin
        company.inventoryAPI = form.controls.inventoryAPI.value;
        company.regionCode = form.controls.regionCode.value;
        company.customerUserName = form.controls.customerUserName.value;
        company.customerGUID = form.controls.customerGUID.value;
    }

    updateRelationshipToCompany(relationshipForm: CompanyRelationshipsResult, company: Company) {
        company.brandedRelationships = relationshipForm.brandedRelationships;
        company.pricingWarrantyRelationships = relationshipForm.pricingWarrantyRelationships;
    }

    idmUserRole(idmUserRoles: string[]): IdmUserRoles {
        if (idmUserRoles.includes(IdmUserRoles.INTERNALCBP)) {
            return IdmUserRoles.INTERNALCBP;
        }
        if (idmUserRoles.includes(IdmUserRoles.INTERNALICP)) {
            return IdmUserRoles.INTERNALICP;
        }

        return IdmUserRoles.ACCOUNTADMIN;
    }

    formInputPlaceHolder(countryCode: string) {
        if (countryCode === 'US') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_US'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_US.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_US.INFO')
                }
            });

            return;
        }
        if (countryCode === 'CA') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_CA'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_CA.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_CA.INFO')
                }
            });

            return;
        }

        if (countryCode === 'MX') {
            this.placeHolderData$.next({
                placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_NUMBER'),
                placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER'),
                placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER'),
                placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER'),
                placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER'),
                placeHolderZipcode: {
                    label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_MX.LABEL'),
                    info: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE_MX.INFO')
                }
            });

            return;
        }

        this.placeHolderData$.next({
            placeHolderPhone: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.PHONE_NUMBER'),
            placeHolderOffice: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.OFFICE_NUMBER'),
            placeHolderMobile: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.MOBILE_NUMBER'),
            placeHolderFax: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.FAX_NUMBER'),
            placeHolderTollFree: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.TOLL_FREE_NUMBER'),
            placeHolderZipcode: { label: this.translatePipe.instant('ACCOUNT_ADMIN.PLACEHOLDER_TEXT.ZIPCODE') }
        });
    }

    public resetForm(form: UntypedFormGroup) {
        form.reset({
            services: '',
            brands: '',
            rsm: '',
            partRSM: '',
            accountManagers: '',
            partAM: '',
            tsm: '',
            pricingAnalyst: '',
            country: '',
            state: '',
            language: ''
        });
    }

    public updateCompanyContact(option: Option[], companyType: string, companyCreateCopy: Company) {
        // Pricing Analyst - Multiselect dropdown
        if (option?.length) {
            option.filter((contactInfo) => companyCreateCopy.companyContacts.push({
                contactType: companyType,
                userName: contactInfo.name,
                userId: contactInfo.value
            }));

            return;
        }
        const contact: CompanyContact = {
            contactType: companyType,
            userName: option?.map((value: Option) => value.name)[0],
            userId: option?.map((value: Option) => value.value)[0]
        };
        if (companyCreateCopy) {
            if (companyCreateCopy.companyContacts.findIndex((elem) => elem.contactType === contact.contactType) === -1 || contact.contactType === '') {
                companyCreateCopy.companyContacts.push(contact);

                return;
            }

            companyCreateCopy.companyContacts.map((companyContact) => {
                if (companyContact.contactType === contact.contactType) {
                    companyContact.userName = contact.userName;
                    companyContact.userId = contact.userId;
                }

                return companyContact;
            });
        }
    }

    public getRegionCodeByState(region: string, stateOptionData: Option[]): string {
        const stateSelection = stateOptionData.find((state) => state.name === region);
        if (stateSelection) {
            return stateSelection.value;
        }

        return '';
    }

    public getCountryCodeByCountry(countryName: string, countryOptionData: Option[]): string {
        const countrySelection = countryOptionData.find((country) => country.name === countryName);
        if (countrySelection) {
            return countrySelection.value;
        }

        return '';
    }

    public filterCountryArraywithMostUsed(countries: Option[] | undefined): Option[] {
        const mostUsedCountry = countries?.filter((country) => country.value === 'US' || country.value === 'CA' || country.value === 'MX') || [];
        mostUsedCountry.sort((optionA: Option, _optionB: Option) => {
            if (optionA.value === 'US') {
                return -1;
            }
            else if (optionA.value === 'CA') {
                return 1;
            }

            return 0;
        });
        const popDefaultItem = countries?.find((country) => country.value === '');
        if (popDefaultItem) {
            mostUsedCountry.unshift(popDefaultItem);
        }

        return [...mostUsedCountry, ...countries || []].filter((value, idx, array) => array.findIndex((value2: Option) => (value2.value === value.value)) === idx);
    }

    public isNumber(value: string | number): boolean {
        return ((value !== null) &&
               (value !== '') &&
               !isNaN(Number(value.toString())));
    }

    public getAccountAdminUsersByCompanyId(id: string) {
        this.isuserListPageLoading$.next(true);
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `hvacCompanyId=${id}` });

        const observable$ = options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<UserDetailsEntity[]>(`${environment.api.accountAdminUsersByCompany}`, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );

        observable$.subscribe(
            (userList: UserDetailsEntity[]) => {
                if (userList) {
                    this.totalUsers = userList.length;
                    this.userUnderSelectedCompanyList$.next(userList);
                    this.totalPages = Math.ceil(this.totalUsers / this.userPageSize);
                    this.handleCreateUserListPageChange(this.userListCurrentPage);
                    this.isuserListPageLoading$.next(false);
                }
                else {
                    this.totalUsers = 0;
                    this.userUnderSelectedCompanyList$.next([]);
                    this.totalPages = 0;
                    this.handleCreateUserListPageChange(this.userListCurrentPage);
                    this.isuserListPageLoading$.next(false);
                }
            }
        );
    }

    public toggleMenu() {
        this.menuActive = !this.menuActive;
    }

    public handleCreateUserListPageChange(page:number) {
        this.userListCurrentPage = parseInt(page.toString(), this.radix);
        this.filterListUserUnderSelectedCompany$.next(
            this.userUnderSelectedCompanyList$.value.slice((this.userListCurrentPage - 1) * this.userPageSize, this.userListCurrentPage * this.userPageSize)
        );
    }
}
