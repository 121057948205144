import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { NavOption } from 'common/components/dropdown/dropdown-navigation/dropdown-navigation.component';
import { UserStatus } from 'common/models/account-status';
import { User } from 'common/models/auth';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AuthState } from 'common/store/auth/auth.state';
import { ToggleHeaderDropdown } from 'common/store/ui/ui.actions';
import { UiState } from 'common/store/ui/ui.state';
import { environment } from 'common/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dropdownLinks: { [key: string]: any } = {
    PROFILE: {
        value: environment.features.profile ? '/profile' : '/Pages/partners/MyAccount.aspx',
        action: 'link'
    },
    CHANGE_PASSWORD: {
        value: '/change-password',
        action: 'link'
    },
    CHANGE_ACCOUNT: {
        value: '/Pages/devpages/Change-Account.aspx',
        action: 'link'
    }
};

@Component({
    selector: 'hvac-user-detail-dropdown',
    templateUrl: './user-detail-dropdown.component.html',
    styleUrls: ['./user-detail-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserDetailDropdown implements OnInit {
    @Select(UiState.headerDropdown) headerDropdown$: Observable<string>;
    @Select(AuthState.user) user$: Observable<User>;
    @Select(AccountStatusState.isPublicPlusUser) isPublicPlusUser$: Observable<UserStatus>;
    @Select(UiState.mobileMenuVisible) mobileMenuVisible$: Observable<boolean>;
    @Input() icon: string = 'assets/icons/chevron-down.svg';

    detailOptions$: Observable<NavOption[]>

    defaultOptions$ = of([
        'PROFILE',
        'CHANGE_PASSWORD',
        'CHANGE_ACCOUNT'
    ]);

    publicPlusOptions$ = of([
        'CHANGE_PASSWORD'
    ]);

    userName$: Observable<string>;

    constructor(
        private readonly translate: TranslateService,
        private readonly store: Store
    ) {
    }

    openUserDetailDropdown(isOpen: boolean) {
        const dropdown = isOpen ? 'userDetails' : 'none';

        return this.store.dispatch(new ToggleHeaderDropdown(dropdown));
    }

    ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.userName$ = this.user$.pipe(map((userDetails: any) => ((userDetails && userDetails.profile) ? `${userDetails.profile.firstName} ${userDetails.profile.lastName}` : 'User Details')));

        this.detailOptions$ = combineLatest([
            this.defaultOptions$,
            this.translate.get('HEADER.ACCOUNT_DETAILS'),
            this.isPublicPlusUser$,
            this.publicPlusOptions$
        ]).pipe(map(([defaultOptions, optionTranslations, isPublicPlus, publicPlusOptions]) => {
            const optionList: NavOption[] = [];
            const optionsToUse = isPublicPlus ? publicPlusOptions : defaultOptions;

            optionsToUse.forEach((option) => {
                optionList.push({
                    title: optionTranslations[option],
                    value: dropdownLinks[option].value,
                    action: dropdownLinks[option].action
                });
            });

            return optionList;
        }));
    }
}
