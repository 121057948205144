/* eslint-disable max-lines */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { gql } from 'apollo-angular';
import { Option } from 'common/components/select-new/select-new.component';
import { environment } from 'common/environments/environment';
import { ApiOptions } from 'common/services/api-options/api-options.service';
import { InputErrorObject } from 'common/utils/createAccountValidators';
import { AdvancedSearchCustomerPropertiesResponse, AdvancedSearchPropertyDetailsResponse, ConnectedPortalAdvancedSearchCustomerProperties } from 'private/app/models/connected-portal-advanced-search.model';
import { Filter, SearchQueryParams } from 'private/app/models/default-query-params';
import { UserValidationCountriesEntity, UserValidationStatesEntity } from 'private/app/models/user-validation';
import { AdvancedSearchDataSharingOptions, CONNECTED_PORTAL_ADV_SEARCH_SUPPORTED_COUNTRIES, ESN_MODEL_NUMBERS, MISSING_API_RESPONSE_DATA } from 'private/app/views/connected-portal/constants';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConnectedPortalBaseService } from './connected-portal-base.service';


export const QUERY_CUSTOMERS_BY_DISTRIBUTOR_ID = gql`
    query QueryCustomerByDistributorId($id: String!, $params: SearchQueryParams) {
        queryCustomerByDistributorId(id: $id, params: $params) {
            totalCount,
            totalPages,
            data {
                id
                address {
                    address1
                    address2
                    city
                    state
                    country
                    zipCode
                }
                dealerHVPID
                dealerName
                id
                propertyName
                customer {
                    email
                    firstName
                    lastName
                    phone1
                    phone2
                    username
                }
                product {
                    name
                    modelNo
                    serialNo
                    firmware
                    systemType
                    isPlcEquipped
                    type
                    esn
                }
            }
        }
    }
`;

export const QUERY_CUSTOMERS_BY_DISTRIBUTOR_ID_FILE_EXPORT = gql`
    query QueryCustomerByDistributorId($id: String!, $params: SearchQueryParams) {
        queryCustomerByDistributorId(id: $id, params: $params) {
            filepath
        }
    }
`;

export const QUERY_CUSTOMERS_BY_FACTORY = gql`
    query QueryCustomerByFactory($params: SearchQueryParams) {
        queryCustomerByFactory(params: $params) {
            totalCount,
            totalPages,
            data {
                id
                address {
                    address1
                    address2
                    city
                    state
                    country
                    zipCode
                }
                dealerHVPID
                dealerName
                id
                propertyName
                customer {
                    email
                    firstName
                    lastName
                    phone1
                    phone2
                    username
                }
                product {
                    name
                    modelNo
                    serialNo
                    firmware
                    systemType
                    isPlcEquipped
                    type
                    esn
                }
            }
        }
    }
`;

export const QUERY_CUSTOMERS_BY_FACTORY_FILE_EXPORT = gql`
    query QueryCustomerByFactory($params: SearchQueryParams) {
        queryCustomerByFactory(params: $params) {
            filepath
        }
    }
`;

@Injectable({ providedIn: 'root' })
export class AdvancedSearchService extends ConnectedPortalBaseService {
    private controlModelNumbers: Option[];

    constructor(
        private readonly httpClient: HttpClient,
        private translate: TranslateService,
        injector: Injector
    ) {
        super(injector);
        this.setControlModelNumbers();
    }

    public getControlModelNumbers(): Option[] {
        return this.controlModelNumbers;
    }

    public createAdvancedSearchInputValidator(minLength: number, isRequired: boolean): ValidatorFn {
        return (control: AbstractControl): InputErrorObject | null => {
            const errorObject: InputErrorObject = {};
            const controlValue = control.value;
            if (typeof controlValue !== 'undefined') {
                if (isRequired && controlValue.trim() === '') {
                    errorObject.REQUIRED = true;
                }
                if (minLength && (controlValue.length < minLength && controlValue.length > 0)) {
                    errorObject.LENGTH = true;
                }
                if (Object.keys(errorObject).length !== 0) {
                    return errorObject;
                }
            }

            return null;
        };
    }

    public getCountries(): Observable<Option[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<UserValidationCountriesEntity[]>(environment.api.getCountries, { ...options }).pipe(
                map((countryList) => {
                    const optionList: Option[] = [];
                    const supportedCountries = CONNECTED_PORTAL_ADV_SEARCH_SUPPORTED_COUNTRIES;
                    countryList.forEach((country: UserValidationCountriesEntity) => {
                        if (supportedCountries.includes(country.code)) {
                            optionList.push({
                                name: country.name,
                                value: country.code
                            });
                        }
                    });

                    return optionList;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getStateByCountry(selectedCountry: string): Observable<Option[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const url = environment.api.getStateListByCountry.split('{{state}}').join(selectedCountry);

        return options$.pipe(
            switchMap((options) => this.httpClient.get<UserValidationStatesEntity[]>(url, { ...options }).pipe(
                map((stateList) => {
                    const optionList: Option[] = [];
                    stateList.forEach((state: UserValidationStatesEntity) => {
                        optionList.push({
                            name: state.name,
                            value: state.code
                        });
                    });

                    return optionList;
                })
            )),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public queryCustomerByDistributorId(distributorId: string, queryParams?: SearchQueryParams, isFileExport = false): Observable<AdvancedSearchCustomerPropertiesResponse | { filepath: string }> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const query = isFileExport
            ? QUERY_CUSTOMERS_BY_DISTRIBUTOR_ID_FILE_EXPORT
            : QUERY_CUSTOMERS_BY_DISTRIBUTOR_ID;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryCustomerByDistributorId').query<{ queryCustomerByDistributorId: AdvancedSearchPropertyDetailsResponse }>({
                query,
                variables: {
                    id: distributorId,
                    params: queryParams
                },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    if (isFileExport) {
                        const filepath = value.data.queryCustomerByDistributorId.filepath;
                        if (filepath) {
                            return { filepath };
                        }
                    }

                    const { queryCustomerByDistributorId } = value.data;
                    const { totalPages, totalCount, data } = queryCustomerByDistributorId;

                    const items = data.map((property): ConnectedPortalAdvancedSearchCustomerProperties => {
                        const { id, customer, address, product, dealerHVPID, dealerName } = property;
                        const name = `${customer.firstName} ${customer.lastName}`;
                        const userName = customer.username;
                        const addressStreet = address.address2 ? `${address.address1}, ${address.address2}` : address.address1;
                        const propertyAddress = `${addressStreet}, ${address.city}, ${address.state} ${address.zipCode}`;

                        return {
                            id,
                            name,
                            userName,
                            propertyAddress,
                            email: customer.email,
                            phone: customer.phone1,
                            model: product.modelNo,
                            modelName: product.name,
                            dealerHVPID,
                            dealerName
                        };
                    });

                    return {
                        totalPages,
                        totalCount,
                        data: items
                    };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public queryCustomerByFactory(queryParams?: SearchQueryParams, isFileExport = false): Observable<AdvancedSearchCustomerPropertiesResponse | { filepath: string }> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        const query = isFileExport
            ? QUERY_CUSTOMERS_BY_FACTORY_FILE_EXPORT
            : QUERY_CUSTOMERS_BY_FACTORY;

        return options$.pipe(
            switchMap((options: ApiOptions) => this.getApolloServiceByQueryId('queryCustomerByFactory').query<{ queryCustomerByFactory: AdvancedSearchPropertyDetailsResponse }>({
                query,
                variables: { params: queryParams },
                context: { headers: options.headers }
            }).pipe(
                map((value) => {
                    if (isFileExport) {
                        const filepath = value.data.queryCustomerByFactory.filepath;

                        if (filepath) {
                            return { filepath };
                        }

                        throw new Error(MISSING_API_RESPONSE_DATA);
                    }

                    const { queryCustomerByFactory } = value.data;
                    const { totalPages, totalCount, data } = queryCustomerByFactory;

                    const items = data.map((property): ConnectedPortalAdvancedSearchCustomerProperties => {
                        const { id, customer, address, product, dealerHVPID, dealerName } = property;
                        const name = `${customer.firstName} ${customer.lastName}`;
                        const userName = customer.username;
                        const addressStreet = address.address2 ? `${address.address1}, ${address.address2}` : address.address1;
                        const propertyAddress = `${addressStreet}, ${address.city}, ${address.state} ${address.zipCode}`;

                        return {
                            id,
                            name,
                            propertyAddress,
                            email: customer.email,
                            phone: customer.phone1,
                            model: product.modelNo,
                            modelName: product.name,
                            dealerHVPID,
                            dealerName,
                            userName
                        };
                    });

                    return {
                        totalPages,
                        totalCount,
                        data: items
                    };
                })
            )),
            catchError((err: HttpErrorResponse) => {
                this.showErrorToast(err);

                throw err;
            })
        );
    }

    public getFilterParameters(advancedSearchForm: UntypedFormGroup, dataSharingOptionsForm: UntypedFormGroup) {
        const filters: Filter[] = [];
        const controls = Object.keys(advancedSearchForm.controls);
        const dataSharingOptions: string[] = Object.values(AdvancedSearchDataSharingOptions);

        controls.forEach((key) => {
            const mappingFieldName = this.translate.instant(`CONNECTED_PORTAL.ADVANCED_SEARCH.FILTER_FIELDS.${key}`);
            const value = advancedSearchForm.controls[key].value;

            if (value && value.length > 0) {
                const selectedValue = advancedSearchForm.controls[key].value;
                const filterValue = Array.isArray(selectedValue) ? selectedValue[0].value : selectedValue;

                filters.push({
                    field: mappingFieldName,
                    value: filterValue
                });
            }
            else if (dataSharingOptions.includes(key)) {
                const dataSharingStatusObj = this.getDataSharingFilterValue(key, dataSharingOptionsForm);

                if (dataSharingStatusObj.hasFilterValue) {
                    filters.push({
                        field: mappingFieldName,
                        value: dataSharingStatusObj.value
                    });
                }
            }
        });

        return filters;
    }

    private setControlModelNumbers() {
        const esnModelNumbers = ESN_MODEL_NUMBERS;

        this.controlModelNumbers = esnModelNumbers.map((modelNumber) => ({
            name: modelNumber,
            value: modelNumber
        }));
    }

    private getDataSharingFilterValue(filedName: string, dataSharingOptionsForm: UntypedFormGroup) {
        let userSelection = false;
        const dataSharingFilter = {
            hasFilterValue: false,
            value: ''
        };

        switch (filedName) {
            case AdvancedSearchDataSharingOptions.VIEW_STATUS:
                userSelection = dataSharingOptionsForm.controls.viewStatusEnabled.value || dataSharingOptionsForm.controls.viewStatusDisabled.value;

                if (userSelection) {
                    dataSharingFilter.hasFilterValue = true;
                    dataSharingFilter.value = dataSharingOptionsForm.controls.viewStatusEnabled.value ? 'true' : 'false';
                }
                break;
            case AdvancedSearchDataSharingOptions.VIEW_CONFIG:
                userSelection = dataSharingOptionsForm.controls.viewConfigEnabled.value || dataSharingOptionsForm.controls.viewConfigDisabled.value;

                if (userSelection) {
                    dataSharingFilter.hasFilterValue = true;
                    dataSharingFilter.value = dataSharingOptionsForm.controls.viewConfigEnabled.value ? 'true' : 'false';
                }
                break;
            case AdvancedSearchDataSharingOptions.EDIT_CONFIG:
                userSelection = dataSharingOptionsForm.controls.editConfigEnabled.value || dataSharingOptionsForm.controls.editConfigDisabled.value;

                if (userSelection) {
                    dataSharingFilter.hasFilterValue = true;
                    dataSharingFilter.value = dataSharingOptionsForm.controls.editConfigEnabled.value ? 'true' : 'false';
                }
                break;
            default:
                break;
        }

        return dataSharingFilter;
    }
}

