<utc-modal class='hvac-address-lookup-modal' size="dynamic-height" (closeAction)="onCancel()"
    [visible]="visible" theme='rounded' [showHeader]="false">
    <utc-loading-spinner *ngIf="(spinnerStatus$ | async)" [spinnerSize]="'small'"></utc-loading-spinner>
    <ng-container *ngIf="address$ | async as addressSet">
        <div class="hvac-address-lookup-wrapper">
            <h3 class="hvac-h3">{{
                'ADDRESS_LOOKUP.ADDRESS_SUGGESTION' | translate }}</h3>
            <div class="hvac-divider hvac-button-divider"></div>
            <div class="hvac-address-lookup-input" *ngIf="addressLookup">
                <p class="hvac-label hvac-address-lookup-title">
                    {{ 'ADDRESS_LOOKUP.SEARCH_FOR' | translate }}</p>
                <p class="hvac-info-value hvac-company-address-information hvac-label">
                    <hvac-address [name]="addressLookup.address1" [line2]="addressLookup.address2"
                    [city]="addressLookup.city" [state]="addressLookup.state.code"
                    [country]="addressLookup.country.code" [zipCode]="addressLookup.postalCode"></hvac-address>
                </p>
            </div>
            <div class="hvac-address-lookup-export-warning" *ngIf="!(processStatus$ | async)">
                <hvac-toast [name]="toastOutlet"></hvac-toast>
            </div>

            <div *ngIf="!(toastStatus$ | async)$">
                <div class="hvac-company-form">
                    <form [formGroup]="companyCreateForm">
                        <div class="hvac-row">
                            <div class="hvac-col-xs-12 hvac-col-lg-6">
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS1" | translate }}
                                    </div>
                                    <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS1' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.address1"
                                        size="small"
                                        type="text">
                                    </utc-input>
                                </div>
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ADDRESS2" | translate }}
                                    </div>
                                    <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.ADDRESS2' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.address2"
                                        size="small"
                                        type="text">
                                    </utc-input>
                                </div>

                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.CITY" | translate }}
                                    </div>
                                    <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        [placeholder]="'ACCOUNT_ADMIN.COMPANY_CREATE.PLACEHOLDER_TEXT.CITY' | translate"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.city"
                                        size="small"
                                        type="text">
                                    </utc-input>
                                </div>

                            </div>
                            <div class="hvac-col-xs-12 hvac-col-lg-6">
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.STATE" | translate }}
                                    </div>

                                    <utc-select class="hvac-col-xs-12 hvac-col-lg-8 hvac-address-correction-input"
                                        shadow="none"
                                        size="small"
                                        (onChange)="onStateChange($event)"
                                        [group]="companyCreateForm" control="state" [options]="(companyCreateService.stateOptionData$ | async) || []">>
                                    </utc-select>
                                </div>
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.ZIP/POSTAL" | translate }}
                                    </div>
                                    <utc-input shadow="light" class="hvac-col-xs-12 hvac-col-lg-8"
                                        [error]="companyCreateForm.controls.postalCode.dirty && !companyCreateForm.controls.postalCode.valid"
                                        [hideLabel]="false" [inputControl]="companyCreateForm.controls.postalCode"
                                        size="small"
                                        type="text">
                                    </utc-input>
                                </div>
                                <div class="hvac-row hvac-address-lookup-row-padding">
                                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-label">
                                        {{ "ACCOUNT_ADMIN.COMPANY_CREATE.COUNTY" | translate }}
                                    </div>

                                    <utc-select class="hvac-col-xs-12 hvac-col-lg-8"
                                        [error]="companyCreateForm.controls.country.dirty && !companyCreateForm.controls.country.valid"
                                        shadow="none" size="small"
                                        (onChange)="onCountryChange($event)"
                                        [group]="companyCreateForm" control="country" [options]="(companyCreateService.countryOptionData$ | async) || []">
                                    </utc-select>
                                </div>
                            </div>
                        </div>
                </form>
                <div class="hvac-address-lookup-form-action">
                    <button buttonType="button" hvacButton="primary" (click)="checkAddress()">
                        {{ 'ADDRESS_LOOKUP.CHECK_ADDRESS'| translate }}
                    </button>
                </div>
            </div>
        </div>
            <div class="hvac-address-lookup-table">
                <hvac-table>
                    <thead>
                        <tr>
                            <td *ngFor="let item of dataHeaders">
                                <button class="hvac-table-sort-btn">
                                    <span class="hvac-address-lookup-column-header">{{item.title}}</span>
                                    <span class="hvac-table-list-sort" [ngClass]="{
                                        'hvac-table-list-sort-show': item.order,
                                        'column-ascending': item.order === 'asc'
                                    }">
                                        <utc-svg src="assets/icons/chevron-down.svg"></utc-svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let address of addressSet; let indexOfElement = index;" [ngClass]="{'hvac-address-lookup-form-blue-background' : indexOfElement !== addressSet.length - 1}">
                            <td>
                                {{address.address1}}
                            </td>
                            <td>
                                {{address.address2}}
                            </td>
                            <td>
                                {{address.city}}
                            </td>
                            <td>
                                {{address.state.label}}
                            </td>
                            <td>
                                {{address.country.label}}
                            </td>
                            <td>
                                {{address.postalCode}}
                            </td>
                            <td>
                                <button type="button" (click)="userAddress(address)" hvacButton="text">{{
                                    (indexOfElement === addressSet.length - 1 ? 'ADDRESS_LOOKUP.KEEP_EXISTING' :
                                    'ADDRESS_LOOKUP.USE_ADDRESS') | translate }}</button>
                            </td>

                        </tr>
                    </tbody>
                </hvac-table>
            </div>
            <div class="hvac-address-lookup-modal-actions">
                <button buttonType="button" hvacButton="default" (click)="onCancel()">
                    {{'ADDRESS_LOOKUP.CANCEL' | translate }}
                </button>
            </div>

        </div>
    </ng-container>
</utc-modal>
