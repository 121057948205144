import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { AuthState, LoggedIn } from 'common/store/auth/auth.state';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';
import { OktaService } from 'common/services/okta/okta.service';
import { IdmUserRoles } from '../enums/idm-user-roles';

@Injectable({ providedIn: 'root' })
export class Auth implements CanActivate {
    @Select(AuthState.loggedIn) loggedIn$: Observable<LoggedIn>;

    constructor(
        private readonly store: Store,
        private readonly oktaService: OktaService
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([this.oktaService.idToken$, this.loggedIn$]).pipe(
            skipWhile(([_user, loggedIn]) => loggedIn === LoggedIn.Unknown),
            map(([user, loggedIn]) => {
                const isTradePartnerUser = user.claims.idm_user_roles?.includes(IdmUserRoles.TRADE_PARTNERS);
                if (isTradePartnerUser && !state.url.includes('trade-partners')) {
                    this.store.dispatch(new Navigate(['/trade-partners']));
                }

                if (loggedIn === LoggedIn.LoggedIn) {
                    return true;
                }

                this.store.dispatch(new Navigate(['/login']));

                return false;
            }),
            catchError(() => {
                this.store.dispatch(new Navigate(['/login']));

                return of(false);
            })
        );
    }
}
