
<div class="hvac-cp-card hvac-cp-faq-container">
    <div class="hvac-h3 hvac-rhythm-3">{{'CONNECTED_PORTAL.FAQ.HEADING' | translate}}</div>

    <div class="hvac-row hvac-rhythm-3 hvac-cp-faq-topic" *ngFor="let questionGroup of faqData">
        <div class="hvac-col-xs-12">
            <hvac-accordion-new [open]="questionGroup.isOpen" (toggle)="toggleAccordion(questionGroup)">
                <hvac-accordion-header>{{ questionGroup.TITLE }}</hvac-accordion-header>
                    <hvac-accordion-body>
                        <div class="hvac-cp-group-border"></div>
                        <div class="hvac-row hvac-cp-faq-question" *ngFor="let question of questionGroup.QUESTIONS">
                            <div class="hvac-col-xs-12">
                                <hvac-accordion-new [open]="question.isOpen" (toggle)="toggleAccordion(question)">
                                    <hvac-accordion-header>{{ question.QUESTION }}</hvac-accordion-header>
                                        <hvac-accordion-body>
                                            <div [innerHTML]="question.ANSWER | safe: 'html'" class="hvac-cp-faq-answer"></div>
                                        </hvac-accordion-body>
                                </hvac-accordion-new>
                            </div>
                        </div>
                    </hvac-accordion-body>
            </hvac-accordion-new>
        </div>
    </div>
</div>
