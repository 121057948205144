<div class="hvac-cp-search">
    <h2 class="hvac-h3 hvac-cp-search-bar-title">
        {{ 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.SEARCH' | translate }}
        <div class="hvac-cp-factory-search-disclaimer">{{ 'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.DISCLAIMER' | translate }}</div>
    </h2>
    <div class="radio-button-container">
        <hvac-radio-button class="child-radio-button" [checked]="selectedFilterOptions == ProductType.WallControl"
            [inputName]="'wallControl'" (change)="filterOption(ProductType.WallControl)"> {{
            'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.WALL_CONTROL' | translate }} </hvac-radio-button>
        <hvac-radio-button class="child-radio-button" [checked]="selectedFilterOptions == ProductType.IndoorUnit"
            [inputName]="'indoorUnit'" (change)="filterOption(ProductType.IndoorUnit)"> {{
            'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.INDOOR_UNIT' | translate }} </hvac-radio-button>
        <hvac-radio-button class="child-radio-button" [checked]="selectedFilterOptions == ProductType.OutdoorUnit"
            [inputName]="'outdoorUnit'" (change)="filterOption(ProductType.OutdoorUnit)"> {{
            'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.OUTDOOR_UNIT' | translate }} </hvac-radio-button>
    </div>
    <hvac-search-bar [queryControl]="queryControl"
        [placeholder]="'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_OVERVIEW.SEARCH_PLACEHOLDER' | translate"
        (onSearchReset)="searchReset.emit(true)"
        (onSearchSubmit)="handleSearchSubmit()">
    </hvac-search-bar>

    <div *ngIf="errorMsg" class='hvac-search-input-error hvac-input-show-message'>
        <span class='hvac-input-message' [innerHTML]="errorMsg | safe:'html'"></span>
    </div>
</div>
