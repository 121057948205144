import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ContentHeadingData } from 'common/models/content-heading';
import { Observable } from 'rxjs';
import { ConnectedPortalStat } from '../../components/connected-portal-stats/connected-portal-stats.component';
import { map, takeUntil } from 'rxjs/operators';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'hvac-factory-dashboard',
    templateUrl: './factory-dashboard.component.html',
    styleUrls: ['./factory-dashboard.component.scss']
})
export class FactoryDashboardComponent extends BaseComponent implements OnInit {
    public isLoadingList = { stats: false };
    public queryControl = new UntypedFormControl('', Validators.required);
    public stats$?: Observable<ConnectedPortalStat[]>;
    public breadCrumbData: ContentHeadingData = {
        Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DASHBOARD') },
        breadCrumbs: [
            {
                title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                url: '/connected-portal/dashboard'
            }
        ]
    };

    constructor(
        private factoryService: FactoryService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private decimalPipe: DecimalPipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.isLoadingList.stats = true;
        this.stats$ = this.factoryService
            .queryFactoryStats()
            .pipe(
                takeUntil(this.ngOnDestroy$),
                map((res) => {
                    const { data } = res;

                    this.isLoadingList.stats = false;

                    return Object.entries(data).map(([key, value]) => ({
                        label: `CONNECTED_PORTAL.STATS.PROPS.${key}`,
                        value: this.decimalPipe.transform(value, '1.0-0') || ''
                    }), []);
                })
            );
    }

    handleSearchSubmit(query: string | null) {
        const params = { search: query };

        this.router.navigate(['../distributors'], {
            relativeTo: this.route,
            queryParams: params
        });
    }
}
