import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartOptions } from 'chart.js';
import { BaseComponent } from 'common/components/base/base.component';
import { BreakPointService } from 'common/services/breakpoint.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { BaseChartDirective } from 'ng2-charts';
import { OpportunityReportUsage, OpportunityReportUsageDataSummary } from 'private/app/models/distributor.model';
import { ChartConfigService } from 'private/app/services/connected-portal/chart-config.service';
import { DistributorsService } from 'private/app/services/connected-portal/distributors.service';
import { REPORT_MAX_YEAR_SELECTION } from 'private/app/views/connected-portal/constants';
import { filter, finalize, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-opportunity-usage-report',
    templateUrl: './opportunity-usage-report.component.html',
    styleUrls: ['./opportunity-usage-report.component.scss']
})
export class OpportunityUsageReportComponent extends BaseComponent implements OnInit {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
    @Input() distributorId: string;

    public data: OpportunityReportUsage | null = null;
    public isLoading = false;
    public chartOptions: ChartOptions<'doughnut'>;
    public chartData: null | ChartData<'doughnut'> = null;
    public yearSelectOptions = this.chartConfigService.getYearOptions(REPORT_MAX_YEAR_SELECTION);
    public startYear = this.yearSelectOptions[this.yearSelectOptions.length - 1].value;
    public yearSelectFormControl = new UntypedFormControl(this.startYear, Validators.required);

    constructor(
        private distributorsService: DistributorsService,
        private chartConfigService: ChartConfigService,
        private csvExportService: CSVExportService,
        private cdr: ChangeDetectorRef,
        private breakpointService: BreakPointService,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        const selectedYear = parseInt(this.yearSelectFormControl.value, 10);

        this.loadChartData(selectedYear).subscribe((res) => {
            this.isLoading = false;
            this.data = res.data;
            this.configureChart(this.data.dataSummary);
        });

        this.yearSelectFormControl.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((year) => {
                const parsedYear = parseInt(year, 10);

                return this.loadChartData(parsedYear);
            })
        )
            .subscribe((res) => {
                this.isLoading = false;
                this.data = res.data;
                this.configureChart(this.data.dataSummary);
            });

        this.breakpointService.breakpoint$.pipe(
            filter(() => Boolean(this.data)),
            takeUntil(this.ngOnDestroy$)
        ).subscribe((_value) => {
            if (this.data) {
                this.configureChart(this.data.dataSummary);
                this.cdr.detectChanges();
            }
        });
    }

    public handleRunReport() {
        this.loadChartData(this.yearSelectFormControl.value).subscribe((res) => {
            if (res.data.filepath) {
                const { filepath } = res.data;
                this.csvExportService.downloadFile(filepath);
            }
        });
    }

    private loadChartData(year: number) {
        this.isLoading = true;
        const { startDate, endDate } = this.chartConfigService.getStartEndDateFromYear(year);

        return this.distributorsService
            .queryDealerOpportunityReportUsageByDistributorId(this.distributorId, startDate, endDate)
            .pipe(
                takeUntil(this.ngOnDestroy$),
                finalize(() => {
                    this.isLoading = false;
                })
            );
    }

    private getChartLabelsAndDataset(data: OpportunityReportUsageDataSummary) {
        const base = 'CONNECTED_PORTAL.DISTRIBUTOR_DASHBOARD.OPPORTUNITY_REPORT';
        const dataSharingLabel = this.translateService.instant(`${base}.DATA_SHARING`);
        const registrationLabel = this.translateService.instant(`${base}.REGISTRATION_OPPORTUNITIES`);

        const labels = [dataSharingLabel, registrationLabel];
        const dataset = [data.totalDisabledDataSharing, data.totalUnregistered];

        return {
            labels,
            dataset
        };
    }

    private configureChart(data: OpportunityReportUsageDataSummary) {
        if (data === null) {
            return;
        }

        const { labels, dataset } = this.getChartLabelsAndDataset(data);
        const isLargeScreen = this.breakpointService.isDesktop() || this.breakpointService.isTablet();
        const legendPosition = isLargeScreen ? 'right' : 'bottom';
        const isEmptyDataSet = data.totalCount === 0;

        this.chartOptions = this.chartConfigService.getDoughnutChartConfig({ legendPosition });
        this.chartData = isEmptyDataSet ? null : this.chartConfigService.getDonutChartData(labels, dataset);
    }
}
