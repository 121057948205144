import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ProductDetails } from 'common/models';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { ProductsState } from 'common/store/products/products.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';

@Component({
    selector: 'utc-product-content-section-options-data',
    templateUrl: './options-data.component.html',
    styleUrls: ['./options-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OptionsDataComponent implements OnInit, OnDestroy {
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(ContentState.contentBrand) brand$!: Observable<string>;
    @Select(CatalogState.currentBrand) currentBrand$!: Observable<string>;

    ngOnDestroy$ = new Subject();
    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    showOptionsData$ = this.attributes$.pipe(
        map((attributes) => Boolean(attributes && attributes.optionsTexts))
    );

    OptionsDataTexts$ = combineLatest([this.attributes$, this.brand$, this.currentBrand$]).pipe(
        map(([attributes, inputBrand, currentBrand]) => {
            const brand = (inputBrand === 'default') ? currentBrand : inputBrand;
            const optionsDataTexts = attributes && attributes.optionsTexts ? attributes.optionsTexts : {};
            const selectedText = optionsDataTexts[brand] ? optionsDataTexts[brand] : optionsDataTexts[Object.keys(optionsDataTexts)[0]];

            let res:string = selectedText;
            const firstTHRegex = RegExp(/<th rowspan="2">(\w)* Unit Size<\/th>/);
            const firstTHExec = firstTHRegex.exec(res);
            let firstTHData = firstTHExec && firstTHExec.length ? firstTHExec[0] : null;
            if (firstTHData) {
                res = res.replace('Unit Size', '');
                res = res.replace('rowspan="2"', '');
                firstTHData = firstTHData.replace('<th rowspan="2">', '');
                firstTHData = firstTHData.replace(' Unit Size</th>', '');
                const model = `<th>Unit Size</th>`;
                res = res.replace('</tr><tr><th', `</tr><tr>${model}<th`);
            }

            return this.sanitizer.bypassSecurityTrustHtml(res);
        })
    );

    constructor(
        private readonly translate: TranslateService,
        private readonly sanitizer: DomSanitizer,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef
    ) {}

    ngOnInit() {
        this.showOptionsData$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.OPTIONS_DATA'),
            order: 2,
            container: this.element.nativeElement
        };
    }
}
