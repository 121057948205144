/* eslint-disable */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'common/environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

let scriptElm = document.createElement('script');

scriptElm.type = 'text/javascript';
var code = `
            if (window.heap == null) {
                (window.heap = window.heap || []),
                    (heap.load = function (e, t) {
                        (window.heap.appid = e), (window.heap.config = t = t || {});
                        var r = t.forceSSL || 'https:' === document.location.protocol,
                            a = document.createElement('script');
                        (a.type = 'text/javascript'),
                            (a.async = !0),
                            (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js');
                        var n = document.getElementsByTagName('script')[0];
                        n.parentNode.insertBefore(a, n);
                        for (
                            var o = function (e) {
                                    return function () {
                                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                                    };
                                },
                                p = [
                                    'addEventProperties',
                                    'addUserProperties',
                                    'clearEventProperties',
                                    'identify',
                                    'resetIdentity',
                                    'removeEventProperty',
                                    'setEventProperties',
                                    'track',
                                    'unsetEventProperty'
                                ],
                                c = 0;
                            c < p.length;
                            c++
                        )
                            heap[p[c]] = o(p[c]);
                    });

                window.heap.load('${environment.trackingId}');

            }`;
try {
    scriptElm.appendChild(document.createTextNode(code));
    document.head.appendChild(scriptElm);
} catch (e) {
    scriptElm.text = code;
    document.head.appendChild(scriptElm);
}


platformBrowserDynamic().bootstrapModule(AppModule, [])
    .catch ((err) => console.error(err));
