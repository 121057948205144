import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'common/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ClearCatalogCategories } from 'common/store/catalog/catalog.actions';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AppConstants } from 'common/app-constants';

@Component({
    selector: 'hvac-product-literature',
    templateUrl: './product-literature.component.html',
    styleUrls: ['./product-literature.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductLiteratureComponent implements OnInit {
    public showToggle: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    productURNData: BehaviorSubject<any> = new BehaviorSubject([]);
    productURNData$ = this.productURNData.asObservable();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    category: BehaviorSubject<any> = new BehaviorSubject([]);
    category$ = this.category.asObservable();
    public searchView = new FormControl('products')

    parts = this.translate.instant('PRODUCTS_LITERATURE.VIEW_OPTIONS.PARTS');
    products = this.translate.instant('PRODUCTS_LITERATURE.VIEW_OPTIONS.PRODUCTS');

    public viewOptions = [this.parts, this.products]

    constructor(
        private translate: TranslateService,
        private readonly store: Store,
        private readonly router: Router
    ) {}

    ngOnInit() {
        const isTotaline = environment.brand === 'Totaline';
        const isPrivatePartsSearch = this.router.url.includes(AppConstants.PrivatePartsLiteratureSearchURL);

        if (isTotaline) {
            this.searchView.setValue('parts');
        }
        else if (environment.brand === 'private' && isPrivatePartsSearch) {
            this.searchView.setValue('parts');
        }
        else {
            this.searchView.setValue('products');
        }
        this.showToggle = !isTotaline && Boolean(environment.features.partsCatalog) && environment.brand !== 'private';
        this.searchView.valueChanges.subscribe(() => {
            this.store.dispatch(new ClearCatalogCategories());
            this.category.next([]);
            this.productURNData.next([]);
        });
    }

    setProductURNData(data: object) {
        this.productURNData.next(data);
    }

    setCategory(category: string[]) {
        this.category.next(category);
    }

    toggleSearchType(event: string) {
        this.searchView.setValue(event);
    }
}
