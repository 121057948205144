<div class="hvac-product-details-card">
    <div class="hvac-card-header">
        <h2 class="hvac-product-name" [innerHtml]="product.name"></h2>
        <div class="hvac-back-button-container">
            <a hvacButton="default" width="full" size="small" hvacButtonIcon class="hvac-button hvac-button-small hvac-back-button" routerLink="/connected-portal/dealers/{{dealerId}}/customers/{{propertyId}}">
                <utc-svg class="hvac-back-arrow" src="assets/icons/arrow.svg"></utc-svg>
                <span>{{ 'CONNECTED_PORTAL.PRODUCT_CARD.PROPERTY_DETAILS_TEXT' | translate }}</span>
            </a>
        </div>
    </div>
    <div class="hvac-row hvac-card-body">
        <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-2 hvac-product-image">
            <utc-image-fallback *ngIf="product" class="hvac-product-placeholder-image"
            [suppliedImageSrc]="product.image || ''"
            fallbackImageSrc="assets/images/no-image-icon.svg"></utc-image-fallback>
            <div class="hvac-row hvac-start-md hvac-rhythm-4">
                <div class="hvac-zones-header hvac-col-xs-12">
                    <div>
                        <div
                            *ngIf="product.dateTime"
                            class="hvac-zone-status-date hvac-p-sm">
                            {{"CONNECTED_PORTAL.WALL_CONTROL.STATUS_AS_OF"| translate}}
                            {{ product.dateTime | dateTimeTz }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-rhythm-2 hvac-product-info">
            <h2 class="hvac-subhead hvac-product-info-heading">{{ 'CONNECTED_PORTAL.PRODUCT_CARD.PRODUCT_INFORMATION' | translate }}</h2>
            <div class="hvac-product-info-content">
                <div class="hvac-product-info-content-left">
                    <dl class="hvac-product-description-list">
                        <hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.MODEL_NUMBER' | translate"
                            [value]="product.modelNo | textFallback"></hvac-product-descriptor>

                        <hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.SERIAL_NUMBER' | translate"
                            [value]="product.serialNo | textFallback"></hvac-product-descriptor>

                        <hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.FIRMWARE' | translate"
                            [value]="product.firmware | textFallback"></hvac-product-descriptor>
                        <hvac-product-descriptor *ngIf="(product.type === ProductType.IndoorUnit || product.type === ProductType.OutdoorUnit) && product.refrigerantType" [label]="'CONNECTED_PORTAL.PRODUCT_CARD.REFRIGERANT_TYPE' | translate"
                            [value]="product.refrigerantType | textFallback"></hvac-product-descriptor>
                    </dl>
                    <div class="hvac-product-status">
                        <div *ngIf="isConnectionStatusVisible" class="hvac-p-sm hvac-fw-sb">{{ 'CONNECTED_PORTAL.PRODUCT_CARD.SERVER_CONNECTION' | translate}}</div>
                        <div class="hvac-product-status-indicator">
                            <hvac-data-block *ngIf="isConnectionStatusVisible; else operationStatus"
                                [status]="isDeviceConnected ? (isDeviceConnected | boolToStatus) : 'inactive'"> {{ isDeviceConnected ?
                                ('CONNECTED_PORTAL.PRODUCT_CARD.DEVICE_STATUS_CONNECTED' | translate) :
                                ('CONNECTED_PORTAL.PRODUCT_CARD.DEVICE_STATUS_DISCONNECTED' | translate) }} </hvac-data-block>
                            <ng-template #operationStatus>
                                <div *ngIf="isOperationStatusVisible(product.stage)">
                                    <hvac-data-block *ngIf="!isOnStage(product.stage)" status="inactive">{{
                                        ('CONNECTED_PORTAL.PRODUCT_CARD.OPERATION_STATUS_OFF' | translate | uppercase) }}</hvac-data-block>
                                    <div *ngIf="isOnStage(product.stage)">
                                        <hvac-data-block status="success">{{ ('CONNECTED_PORTAL.PRODUCT_CARD.OPERATION_STATUS_ON' | translate |
                                            uppercase) }}</hvac-data-block>
                                        <hvac-data-block status="inactive">{{ ('CONNECTED_PORTAL.PRODUCT_CARD.STAGE' | translate | uppercase) +
                                            " " + product.stage }}</hvac-data-block>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="hvac-product-info-content-right">
                    <ul class="hvac-product-link-list">

                        <li *ngIf="isRuntimeReportEnabled"
                            class="hvac-product-link-item"><button hvacButton="text" class="hvac-product-link hvac-text-link-sm"
                                (click)="handleRunReportClick()">{{ 'CONNECTED_PORTAL.PRODUCT_CARD.RUNTIME_REPORT' | translate }}</button></li>
                        <li class="hvac-product-link-item" *ngIf="documentationLink"><a class="hvac-product-link hvac-text-link-sm"
                                [attr.href]="documentationLink" target="_blank">{{ 'CONNECTED_PORTAL.PRODUCT_CARD.DOCUMENTATION' |
                                translate }}</a></li>
                        <li class="hvac-product-link-item" *ngIf="showWarrantyLookup && warrantyLookup"><a
                                class="hvac-product-link hvac-text-link-sm" [attr.href]="warrantyLookup">{{
                                'CONNECTED_PORTAL.PRODUCT_CARD.WARRANTY_LOOKUP' | translate }}</a></li>
                        <li class="hvac-product-link-item" *ngIf="isRemoveControlEnabled" (click)="customerControlContextSvc.isRemoveConnectedControlModalVisible$.next(true)"><a
                                class="hvac-product-link hvac-text-link-sm">{{
                                'CONNECTED_PORTAL.PRODUCT_CARD.REMOVE_CONTROL_FROM_PORTAL' | translate }}</a></li>
                    </ul>

                    <div class="hvac-product-actions">
                        <button *ngIf="isTestEquipmentEnabled"
                            class="hvac-product-test-equipment-button" hvacButton="primary" size="small"
                            (click)="handleTestEquipmentClick()">{{ 'CONNECTED_PORTAL.PRODUCT_CARD.TEST_EQUIPMENT' | translate}}</button>
                    </div>
                </div>
            </div>
            <div *ngIf="product.systemType === SystemType.CARRIER_ELT && systemInfo?.vppEligibility && systemInfo?.vppEligibility !== EventType.NOT_ELIGIBLE">
                <h2 class="hvac-subhead hvac-demand-response-heading">{{ 'CONNECTED_PORTAL.PROPERTY_DETAIL.SYSTEMS.DEMAND_RESPONSE' | translate }}</h2>

                    <div class="hvac-demand-response-param-info">
                        <dl class="hvac-product-description-list">
                            <div class="event-param hvac-row">
                                <div class="hvac-col-xs-3"><hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.DEMAND_RESPONSE.ENROLLMENT' | translate"></hvac-product-descriptor></div>
                                <div class="hvac-col-xs-9 hvac-end-xs hvac-start-lg" *ngIf="(systemInfo.vppEligibility | eventType)"><hvac-event-chip [eventType]="systemInfo.vppEligibility | eventType">{{systemInfo.vppEligibility}}</hvac-event-chip></div>
                            </div>
                            <div class="event-param hvac-row" *ngIf="dataSharingPermissions?.energyEvents">
                                <div class="hvac-col-xs-3"><hvac-product-descriptor [label]="'CONNECTED_PORTAL.PRODUCT_CARD.DEMAND_RESPONSE.ACTIVITY' | translate"></hvac-product-descriptor></div>
                                <div class="hvac-col-xs-9 hvac-end-xs hvac-start-lg" *ngIf="(systemInfo.vppActivity | eventType)"><hvac-event-chip [eventType]="systemInfo.vppActivity | eventType">{{'CONNECTED_PORTAL.PRODUCT_CARD.DEMAND_RESPONSE.SUB_ACTIVITY.' + systemInfo?.vppActivity?.toUpperCase() | translate}}</hvac-event-chip></div>
                            </div>
                        </dl>
                        </div>
                </div>

        </div>

        <div class="hvac-toast-container hvac-col-xs-12">
            <hvac-toast [name]="toastOutlet"></hvac-toast>
        </div>
        <utc-loading-spinner *ngIf="showSpinner"></utc-loading-spinner>
    </div>
</div>
