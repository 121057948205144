
<div class="hvac-breadcrumb-container hvac-content-container">
    <div class="hvac-breadcrumb-quick-search">
        <span class="hvac-product-label-search">{{ 'PRODUCTS_LITERATURE.SEARCH_BY_MODEL_NUMBER' | translate }}</span>
        <utc-input type="text"
            [inputControl]="quickSearchModelNumberControl"
            [labelIcon]="'assets/icons/search.svg'"
            iconPosition="right"
            [hasIconFunction]="true"
            (iconClickFunction)="quickSearch()"
            (enterKeyFunction)="quickSearch()"
            label="{{ 'PRODUCTS_LITERATURE.ENTER_MODEL' | translate }}"></utc-input>
    </div>
    <span class="hvac-product-label-or">{{ 'OR' | translate }}</span>
    <div class="hvac-breadcrumb-category">
        <span class="hvac-product-label-search">{{ 'PRODUCTS_LITERATURE.SEARCH_BY_CATEGORY' | translate }}</span>
        <ol (hvacClickOutside)="handleClickOutside()" class="hvac-product-breadcrumbs">
            <li  class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-category"
                [ngClass]="{
                    'hvac-product-breadcrumbs-expanded': categoryExpanded$ | async
                }">
                <button class="hvac-product-breadcrumbs-selected"
                    (click)="toggleCategory()">
                    <div class="hvac-product-breadcrumbs-selected-icon">
                        <utc-svg *ngIf="(categoryIcon$ | async)?.length" [src]="(categoryIcon$ | async)!"></utc-svg>
                    </div>
                    <span class="hvac-product-breadcrumbs-selected-label">{{ (filterCategoriesLabel$ | async) || 'PRODUCTS_LITERATURE.SELECT_A_PRODUCT_CATEGORY' | translate}}</span>
                    <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
                </button>
                <ol class="hvac-product-breadcrumbs-options">
                    <li class="hvac-product-breadcrumbs-option-item"
                        *ngFor="let filter of filterCategories$ | async">
                        <utc-checkbox
                            class="hvac-product-breadcrumbs-option"
                            [checked]="filter.selected"
                            [inputId]="filter.catalog.name"
                            [value]="filter.catalog.name"
                            (change)="setCategory($event,filter)"
                            [inputName]="filter.catalog.name">
                            <span class="hvac-search-filter-name">{{ filter.catalog.name }}</span>
                        </utc-checkbox>
                    </li>
                </ol>
            </li>
            <li
                class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-subcategory"
                [ngClass]="{
                    'hvac-product-breadcrumbs-expanded': (subcategoryExpanded$ | async)
                }"
                >
                <button [disabled]="!(subcategoryEnabled$ | async)"
                    (click)="toggleSubcategory()"
                    class="hvac-product-breadcrumbs-selected"  #subCategoryCrumb>
                    <span class="hvac-product-breadcrumbs-selected-label">{{ (filterSubCategoriesLabel$ | async) || 'PRODUCTS_LITERATURE.SELECT_A_SUB_CATEGORY' | translate }}</span>
                    <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
                </button>
                <ol class="hvac-product-breadcrumbs-options">
                    <li class="hvac-product-breadcrumbs-option-item" *ngFor="let subcategory of filterSubCategories$ | async">
                    <utc-checkbox
                            class="hvac-product-breadcrumbs-option"
                            [checked]="subcategory.selected"
                            [inputId]="subcategory.urn"
                            [value]="subcategory.subCategory"
                            (change)="setSubcategory(subcategory)"
                            [inputName]="subcategory.subCategory">
                            <span class="hvac-search-filter-name">{{ subcategory.subCategory }}</span>
                        </utc-checkbox>
                    </li>
                </ol>
            </li>
            <li  class="hvac-product-breadcrumbs-breadcrumb hvac-product-breadcrumbs-product"
                [ngClass]="{
                    'hvac-product-breadcrumbs-breadcrumb-inprogress': !(productsEnabled$ | async),
                    'hvac-product-breadcrumbs-expanded': productsExpanded$ | async
                }">
                <button class="hvac-product-breadcrumbs-selected"
                    [disabled]="!(productsEnabled$ | async)"
                    (click)="toggleProducts()" #productCrumb >
                    <span class="hvac-product-breadcrumbs-selected-label">{{ (filterproductLabel$ | async) || 'PRODUCTS_LITERATURE.SELECT_A_MODEL_NUMBER' | translate }}</span>
                    <utc-svg class="hvac-product-breadcrumbs-selected-chevron" src="assets/icons/chevron-down.svg"></utc-svg>
                    <div class="hvac-product-spinner" *ngIf="productListLoading$ | async">
                        <utc-loading-spinner [spinnerSize]="'small'"></utc-loading-spinner>
                    </div>
                </button>
                <ol class="hvac-product-breadcrumbs-options">
                    <li class="hvac-product-breadcrumbs-option-item" *ngFor="let product of filterProducts$ | async">
                        <button class="hvac-product-breadcrumbs-option"
                            (click)="setProduct(product)"
                        >
                            <utc-checkbox
                                [inputId]="product.urn"
                                [checked]="product.selected"
                                [value]="product.urn"
                                [inputName]="product.urn">
                            </utc-checkbox>
                            <img *ngIf="product.image; else fallbackImage" class='hvac-product-breadcrumbs-option-image' [src]="product.image" onError="this.src='assets/images/no-image-icon.svg';this.className='error-image';" alt="{{ 'PRODUCTS_LITERATURE.BREADCRUMB_ICON_ALT' | translate }}" />
                            <ng-template #fallbackImage>
                                <img class="error-image" src="assets/images/no-image-icon.svg" alt="{{ 'PRODUCTTS_LITERATURE.NO_BREADCRUMB_ICON_ALT' | translate }}">
                            </ng-template>
                            <div class='hvac-product-breadcrumbs-option-details'>
                                <span class='option-details-urn' [innerHtml]="product.urn"></span>
                                <span class='option-details-name' [innerHtml]="product.name"></span>
                            </div>
                            <utc-svg class="hvac-product-breadcrumbs-option-selected-icon" src="assets/icons/check.svg"></utc-svg>
                        </button>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
</div>

