import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleServiceService } from 'private/app/services/connected-portal/page-title-service.service';

@Component({
    selector: 'hvac-connected-portal',
    templateUrl: './connected-portal.component.html',
    styleUrls: ['./connected-portal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConnectedPortalComponent implements OnDestroy {
    ngOnDestroy$ = new Subject();
    titleTextArray: string[];
    customersPathKey: string;
    connectedPortalBasePath: string;
    prevUrl: string = '';
    isTrackerActivityCalled: boolean = false;

    constructor(
        private router: Router,
        private readonly title: Title,
        private translate: TranslateService,
        private pageTitleServiceService: PageTitleServiceService
    ) {
        this.connectedPortalBasePath = this.translate.instant(
            'CONNECTED_PORTAL.TITLE.BASE_PATH'
        );

        this.router.events
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((evt) => {
                if (
                    evt instanceof NavigationEnd &&
                    this.prevUrl !== this.router.url
                ) {
                    this.prevUrl = evt.url;
                    const titleTextArray =
                        this.pageTitleServiceService.getTitleTextArray(
                            this.router.url
                        );
                    if (
                        titleTextArray &&
                        titleTextArray.length > 0 &&
                        titleTextArray[1].toLowerCase() ===
                            this.connectedPortalBasePath
                    ) {
                        this.title.setTitle(
                            this.pageTitleServiceService.getTitleText(
                                titleTextArray
                            )
                        );
                    }
                }
            });

        this.pageTitleServiceService.customerName$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((customerName) => {
                this.title.setTitle(customerName as string);
            });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
