import { Component, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { ProductDetails } from 'common/models';
import { ProductsState } from 'common/store/products/products.state';
import { Observable } from 'rxjs';
import { DetailSectionService } from '../../services/detail-section.service';


@Component({
    selector: 'utc-product-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss'],
    providers: [
        DetailSectionService
    ],
    encapsulation: ViewEncapsulation.None
})
export class DetailsComponent {
    @Select(ProductsState.revealProductDetails) revealDetails$!: Observable<boolean>;
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    showOptions = environment.features.product.options;
    showWorksWellWith = environment.features.product.worksWellWith;
}


