import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { formatFrameTitle, stringToPascalCase } from 'common/utils/title-util';

@Injectable({ providedIn: 'root' })
export class PageTitleServiceService {
    public customerName$ = new BehaviorSubject<String>('');
    private customersPathKey: string;

    constructor(private translate: TranslateService) {
        this.customersPathKey = this.translate.instant(
            'CONNECTED_PORTAL.TITLE.CUSTOMERS_PATH_KEY'
        );
    }

    public setCustomerName(name: String) {
        this.customerName$.next(name);
    }

    public getTitleTextArray(url: String) {
        const urlTextArray = url.split('/').map((txt) => {
            let urlTxt = txt.replace(/\?\w+=.*/, '');
            if (urlTxt && urlTxt.includes('#')) {
                urlTxt = urlTxt.split('#')[0];
            }
            urlTxt = urlTxt.toLowerCase();

            return urlTxt;
        });

        return urlTextArray;
    }

    public getTitleText(titleTextArray: String[]) {
        let titleText = titleTextArray[titleTextArray.length - 1].startsWith('zone') ? titleTextArray[titleTextArray.length - 2] as string : titleTextArray.pop() as string;
        const titleKey = `CONNECTED_PORTAL.TITLE.PAGE_TITLES.${titleText.toUpperCase()}`;
        const translatedTitle = this.translate.instant(titleKey);
        if (titleKey !== translatedTitle) {
            titleText = translatedTitle;
        }
        else if (
            titleTextArray.indexOf(this.customersPathKey) ===
            titleTextArray.length - 1
        ) {
            titleText = this.translate.instant(
                'CONNECTED_PORTAL.TITLE.DEFAULT_CUSTOMER'
            );
        }
        else {
            titleText = formatFrameTitle(titleText);
        }
        const faq = this.translate.instant('CONNECTED_PORTAL.PAGE_HEADINGS.FAQ');
        if (titleText === faq.toLowerCase()) {
            return titleText = faq;
        }

        if (titleText === this.translate.instant('CONNECTED_PORTAL.PAGE_HEADINGS.FAD_TITLE')) {
            return this.translate.instant('CONNECTED_PORTAL.TITLE.PAGE_TITLES.FAD_TRACKING');
        }

        return stringToPascalCase(titleText);
    }
}
