import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountAdminCompanyCreateService } from 'private/app/services/account-admin/account-admin-company-create.service';
import { CompanyQueryService } from 'private/app/services/account-admin/company-query.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountAdminDetailsEditConfiguration, CompanyCategoryType } from '../../account-admin-company-details/company-query/company-query-configuration';
import { Option } from '../../../../../common/components/select/select.component';
import { AccountAdminFormResultMap } from 'private/app/models/account-admin-search';
import { AccountAdminCompanyUtilsService } from 'private/app/services/account-admin/account-admin-company-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { Validators } from '@angular/forms';
import { inputValidator } from 'private/app/services/account-admin/account-admin-edit-form.service';
import { BrandFamily } from 'common/models/brand.model';
import { AccountAdminApiService } from 'private/app/services/account-admin/account-admin-api.service';

export enum ServicesType {
    WarrantyDistributor = 'Warranty Distributor'
}

@Component({
    selector: 'hvac-business-information',
    templateUrl: './business-information.component.html',
    styleUrls: ['./business-information.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BusinessInformationComponent implements OnInit {
    @Select(AccountStatusState.getUserBrandFamily) userBrandFamily$: Observable<string | null>;
    @Input() brandList: Option[];

    public companyCreateForm = this.companyCreateService.companyCreateForm;
    public companyConfiguration: AccountAdminDetailsEditConfiguration;
    public claimOptions: Option[] = [];
    public companyCategoryType = CompanyCategoryType;
    public servicesType = ServicesType;
    public isCBP: boolean = false;
    public isSoldToRequired$ = new BehaviorSubject<boolean>(false);
    public isServicesInvalid: boolean = false;
    public isServicesDisabled: boolean = false;
    formData: AccountAdminFormResultMap;

    constructor(
        public translateService: TranslateService,
        public companyQuery: CompanyQueryService,
        public companyCreateService: AccountAdminCompanyCreateService,
        private readonly accountAdminCompanyUtilsService: AccountAdminCompanyUtilsService,
        private readonly accountAdminApiService: AccountAdminApiService
    ) { }

    ngOnInit(): void {
        this.companyQuery.companyDetailsConfiguration.subscribe((configuration) => {
            this.companyConfiguration = configuration;
        });

        this.companyCreateService.formData.subscribe((formData) => {
            this.formData = formData;
        });

        this.companyCreateService.servicesOptionData.subscribe((data) => {
            this.isServicesDisabled = Boolean(data.length);
        });
        this.claimOptions = [...this.companyQuery.claimTypeOptions];
        this.userBrandFamily$.subscribe((res) => {
            this.isCBP = res?.toLowerCase() === BrandFamily.CBP;

            if (this.isCBP) {
                this.claimOptions.pop();
            }
            else {
                this.claimOptions.unshift({
                    name: this.translateService.instant('ACCOUNT_ADMIN.SELECT_CLAIM_TYPE'),
                    value: ''
                });
            }
        });
    }

    public isSoldToRequired(): boolean {
        let servicesValue;
        if (this.companyCreateForm.controls.services.value !== '') {
            servicesValue = (this.companyCreateForm.controls.services.value.find((service: { name: ServicesType; }) => service.name === ServicesType.WarrantyDistributor));
        }
        const soldToRequired = ((this.companyConfiguration.configType === CompanyCategoryType.DOMESTIC_DISTRIBUTOR || this.companyConfiguration.configType === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR) && servicesValue);

        soldToRequired ? this.companyCreateForm.controls.soldTo.addValidators(Validators.required) : this.companyCreateForm.controls.soldTo.setValidators([inputValidator(10, false)]);
        this.companyCreateForm.controls.soldTo.updateValueAndValidity();

        return soldToRequired;
    }

    setManager(conatctType: string, event: Option[]) {
        if (!event) {
            return;
        }
        this.accountAdminCompanyUtilsService.updateCompanyContact(event, conatctType, this.companyCreateService.company);
    }

    onLanguageChange(lan: Option[]) {
        if (Array.isArray(lan)) {
            this.companyCreateService.company.language = lan?.map((language: Option) => language.name);
        }
    }

    onClaimTypeChange(claimType: Option[]) {
        if (Array.isArray(claimType)) {
            this.companyCreateService.company.warrantyType = claimType[0].value ? claimType[0].name : '';
        }
    }

    onServicesChange(services: Option[]) {
        this.isServicesInvalid = false;
        this.isSoldToRequired$.next(this.isSoldToRequired());
        if (services.length === 1 && services[0].name === ServicesType.WarrantyDistributor) {
            this.isServicesInvalid = true;
        }
        if (Array.isArray(services)) {
            this.companyCreateForm.controls.claimType.setValue('');
            this.companyCreateService.company.warrantyType = '';
            this.companyCreateService.company.companyServices = services.map((option) => ({
                id: option.value,
                code: this.accountAdminApiService.companyServices.filter((service) => service.id === option.value)[0].code,
                name: option.name
            })) || [];
            const selectedOptionArray = this.companyQuery.mapCompanyServices(services).map((service) => service.name);
            this.companyQuery.isClaimTypeVisible(selectedOptionArray);
            if (this.companyQuery.configuration.formConfig.claimType?.view) {
                this.companyCreateForm.controls.claimType.setValidators([Validators.required]);
                this.companyCreateForm.controls.claimType.updateValueAndValidity();
                if (this.isCBP) {
                    this.companyCreateForm.controls.claimType.setValue(this.claimOptions[0].name);
                    this.companyCreateService.company.warrantyType = this.claimOptions[0].value ? this.claimOptions[0].name : '';
                }
            }
        }
    }

    onBrandsChange(brands: Option[]) {
        if (Array.isArray(brands)) {
            this.companyCreateService.company.brands = brands.map((option) => ({
                code: option.value,
                name: option.name
            }));
        }
    }

    onParentCompanyChange(parentCompany: Option) {
        if (parentCompany) {
            this.companyCreateService.company.parentCompany = {
                name: parentCompany.name,
                id: parentCompany.value
            };
        }
    }
}
