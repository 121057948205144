import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ViewMode } from 'common/models';
import { SystemProduct } from 'common/models/system-product';
import { ConnectedProductUpdate } from 'private/app/models/connected-product.model';

type ResultsListTheme = 'default' | 'borderless';

@Component({
    selector: 'hvac-connected-portal-results-list',
    templateUrl: './connected-portal-results-list.component.html',
    styleUrls: ['./connected-portal-results-list.component.scss']
})
export class ConnectedPortalResultsListComponent {
    @Input() results: SystemProduct[] = [];
    @Input() theme: ResultsListTheme = 'default';
    @Input() mode: ViewMode;
    @Input() systemId: string;
    @Output() onUpdateBtnClick = new EventEmitter<ConnectedProductUpdate>();

    @HostBinding('class.hvac-results-list-borderless') get isBorderlessTheme() {
        return this.theme === 'borderless';
    }

    handleUpdateClick(event: ConnectedProductUpdate) {
        this.onUpdateBtnClick.emit(event);
    }
}
