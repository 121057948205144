import { Injectable } from '@angular/core';
import { Company, CompanyServices, ParentCompany } from 'private/app/models/account-admin-search';
import { AccountAdminDetailsEditConfiguration, AccountAdminEditFormConfig, CompanyCategoryType, CompanyType } from '../../views/account-admin-company-details/company-query/company-query-configuration';
import { DomesticDistributorConfig } from '../../views/account-admin-company-details/company-query/domestic-distributor-configuration';
import { AccountAdminHvacCustomerConfig } from '../../views/account-admin-company-details/company-query/hvac-customer-configuration';
import { InternationalCustomerConfig } from '../../views/account-admin-company-details/company-query/international-customer-configuration';
import { InternationalDistributorConfig } from '../../views/account-admin-company-details/company-query/international-distributor-configuration';
import { MarineDistributorConfig } from '../../views/account-admin-company-details/company-query/marine-distributor-configuration';
import { MarineCustomerConfig } from '../../views/account-admin-company-details/company-query/marine-customer-configuration';
import { NationalAccountCustomerConfig } from '../../views/account-admin-company-details/company-query/national-account-customer-configuration';
import { VendorConfig } from '../../views/account-admin-company-details/company-query/vendor-configuration';
import { DefaultConfig } from '../../views/account-admin-company-details/company-query/default-configuration';
import { InternalConfig } from '../../views/account-admin-company-details/company-query/internal-configuration';
import { Option } from '../../../../common/components/select/select.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IdmUserRoles } from 'private/app/enums/idm-user-roles';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams, HttpErrorResponse, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'common/environments/environment';
import { ApiOptions, ApiOptionsService } from 'common/services/api-options/api-options.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { CSRValidatePostParam } from 'private/app/models/account-admin-company.model';
import { ClaimOption } from './company-relationship.service';

const WARRANTY_DISTRIBUTOR = 'Warranty Distributor';

export enum CategoryCompanyType {
    DOMESTIC_DISTRIBUTOR = 'DDT',
    MARINE_DISTRIBUTOR = 'MAC',
    CUSTOMER = 'CUS',
    VENDOR = 'VEN',
    INTERNATIONAL_DISTRIBUTOR = 'DID',
    DEFAULT = 'DEF',
    INDEPENDENT_SALES_REP = 'REP',
    DEALER_CONTRACTOR = 'DCR'
}

enum enumValidateSoldToResponse {
    SUCCESS = 'Success',
    FAILURE = 'Failure'
}

export interface ValidateSoldToResponse {
    message: string;
    status: enumValidateSoldToResponse;
    details: string;
}

@Injectable({ providedIn: 'root' })
export class CompanyQueryService {
    public companyDetailsConfiguration = new BehaviorSubject<AccountAdminDetailsEditConfiguration>(DefaultConfig);
    public updateCompanyDetails = new BehaviorSubject<Company>({} as Company);
    public hoursOfOperation: string[] = [
        this.translatePipe.instant('ACCOUNT_ADMIN.OPERATION_HOURS.AVAILABLE_24_7_FOR_ALL_CALLS'),
        this.translatePipe.instant('ACCOUNT_ADMIN.OPERATION_HOURS.NORMAL_BUSINESS_HOURS'),
        this.translatePipe.instant('ACCOUNT_ADMIN.OPERATION_HOURS.NORMAL_HOURS_WITH_AFTER_HOURS_ANSWERING_SERVICE'),
        this.translatePipe.instant('ACCOUNT_ADMIN.OPERATION_HOURS.NORMAL_HOURS_WITH_EXTENDED_SERIVCE_HOURS')
    ];

    public claimTypeOptions: Option[] = [
        {
            name: this.translatePipe.instant('ACCOUNT_ADMIN.CLAIM_TYPES.ENTITLEMENT_CLAIMS'),
            value: ClaimOption.ENTITLEMENT_AND_CLAIMS
        },
        {
            name: this.translatePipe.instant('ACCOUNT_ADMIN.CLAIM_TYPES.ENTITLEMENT'),
            value: ClaimOption.ENTITLEMENT
        }
    ]

    public configuration: AccountAdminDetailsEditConfiguration = DefaultConfig;
    public nonInternalUser: boolean;

    constructor(
        private translatePipe: TranslateService,
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) {
    }

    isPrimaryDistributorExistsForCrntSoldToCmpny(enteredSoldToNo: string): Observable<[boolean, string]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `soldTo=${enteredSoldToNo}` });

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<ValidateSoldToResponse>(`${environment.api.validateSoldTo}`, {
                params,
                ...options
            })),
            map((response: ValidateSoldToResponse): [boolean, string] => [false, response.details]),
            catchError((errorResponse: HttpErrorResponse): Observable<[boolean, string]> => {
                const error: ValidateSoldToResponse = errorResponse.error as ValidateSoldToResponse;

                return of([true, error.details]);
            })
        );
    }

    generateCompanyDetailsConfiguration(category: string, type: string, idmUserRoles?: string[]) {
        if (this.isNonInternalUser(idmUserRoles) && (category === CompanyCategoryType.DOMESTIC_DISTRIBUTOR || category === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR || category === CompanyCategoryType.MARINE_DISTRIBUTOR)) {
            this.configuration = DomesticDistributorConfig;
            this.configuration.configType = category;
            Object.keys(this.configuration.formConfig).forEach((key) => {
                this.configuration.formConfig[key as keyof AccountAdminEditFormConfig] = {
                    view: true,
                    edit: false
                };
            });
            this.configuration.formConfig.searchRadius = {
                view: true,
                edit: true
            };

            this.configuration.formConfig.services!.view = true;
            this.configuration.formConfig.services!.edit = true;
            this.configuration.addBrands = false;
        }
        else {
            if (category === CompanyCategoryType.INTERNAL) {
                this.configuration = InternalConfig;
            }
            if (category === CompanyCategoryType.DOMESTIC_DISTRIBUTOR) {
                this.configuration = DomesticDistributorConfig;
                this.configuration.companyType = type;
                this.configuration.formConfig.claimType.view = false;
                this.configuration.formConfig.services!.view = true;
                this.configuration.formConfig.services!.edit = true;
                if (type.includes(CategoryCompanyType.DOMESTIC_DISTRIBUTOR)) {
                    this.configuration.formConfig.c10Child.edit = true;
                    this.configuration.formConfig.c10Parent.edit = true;
                    this.configuration.formConfig.storeNumber.edit = true;
                }
                if (this.configuration.formConfig.salesOfficeNumber) {
                    this.configuration.formConfig.salesOfficeNumber.view = (type === CategoryCompanyType.INDEPENDENT_SALES_REP);
                }

                if (this.isInternalCBP(idmUserRoles)) {
                    this.configuration.formConfig.ownership.view = true;
                    this.configuration.formConfig.ownership.edit = true;
                }
                if (this.isInternalICP(idmUserRoles)) {
                    this.configuration.formConfig.searchRadius.edit = false;
                }
            }
            if (category === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR) {
                this.configuration = InternationalDistributorConfig;
                this.configuration.companyType = type;
                if (this.isInternalCBP(idmUserRoles)) {
                    this.configuration.formConfig.searchRadius.view = true;
                    this.configuration.formConfig.legalEntity.view = true;
                    this.configuration.formConfig.divRegNumber.view = true;
                }
            }
            if (category === CompanyCategoryType.MARINE_DISTRIBUTOR) {
                this.configuration = MarineDistributorConfig;
                this.configuration.companyType = type;
                if (this.isInternalICP(idmUserRoles)) {
                    this.configuration.formConfig.searchRadius.edit = false;
                }
            }
            if (category === CompanyCategoryType.HVAC_CUSTOMER || category === CompanyCategoryType.CUSTOMER) {
                this.configuration = AccountAdminHvacCustomerConfig;
                this.configuration.companyType = type;
                this.configuration.formConfig.services!.view = false;
                if (type.includes(CategoryCompanyType.DEALER_CONTRACTOR)) {
                    this.configuration.formConfig.services!.view = true;
                    this.configuration.formConfig.services!.edit = true;
                }
                if (this.isInternalCBP(idmUserRoles)) {
                    this.configuration.formConfig.language.view = true;
                }
            }
            if (category === CompanyCategoryType.INTERNATIONAL_CUSTOMER) {
                this.configuration = InternationalCustomerConfig;
                this.configuration.companyType = type;
            }
            if (category === CompanyCategoryType.MARINE_CUSTOMER) {
                this.configuration = MarineCustomerConfig;
                this.configuration.companyType = type;
            }
            if (category === CompanyCategoryType.NATIONAL_ACCOUNT_CUSTOMER || category === CompanyCategoryType.NATIONAL_ACCOUNT) {
                this.configuration = NationalAccountCustomerConfig;
                this.configuration.companyType = type;
            }
            if (category === CompanyCategoryType.VENDOR) {
                this.configuration = VendorConfig;
                this.configuration.companyType = type;
            }

            if (this.isITSuperAdmin(idmUserRoles)) {
                this.updateITSuperAdminFeatures(this.configuration, idmUserRoles);
            }
        }

        if ((category === CompanyCategoryType.INTERNAL || category === CompanyCategoryType.DOMESTIC_DISTRIBUTOR || category === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR || category === CompanyCategoryType.MARINE_DISTRIBUTOR)) {
            if (this.configuration.formConfig.brands?.view) {
                this.configuration.formConfig.brands.mandatory = true;
            }
        }

        if (this.isNonInternalUser(idmUserRoles)) {
            this.configuration.formConfig.notes = {
                view: false,
                edit: false
            };
        }

        this.companyDetailsConfiguration.next(this.configuration);
    }

    isNonInternalUser(idmUserRoles: string[] | undefined) {
        this.nonInternalUser = !idmUserRoles?.includes(IdmUserRoles.DEFAULT_INTERNAL);

        return this.nonInternalUser;
    }

    updateITSuperAdminFeatures(configuration: AccountAdminDetailsEditConfiguration, idmUserRoles: string[] | undefined) {
        const isSuperAdmin = Boolean(this.isITSuperAdmin(idmUserRoles) && this.isDistributorType(configuration.companyType));

        configuration.formConfig.inventoryAPI = {
            view: isSuperAdmin,
            edit: isSuperAdmin
        };
        configuration.formConfig.regionCode = {
            view: isSuperAdmin,
            edit: isSuperAdmin
        };
        configuration.formConfig.customerUserName = {
            view: isSuperAdmin,
            edit: isSuperAdmin
        };
        configuration.formConfig.customerGUID = {
            view: isSuperAdmin,
            edit: isSuperAdmin
        };
    }

    public isClaimTypeVisible(value: string[]): boolean {
        if (this.nonInternalUser) {
            return false;
        }
        if ((this.configuration.configType === CompanyCategoryType.DOMESTIC_DISTRIBUTOR || this.configuration.configType === CompanyCategoryType.INTERNATIONAL_DISTRIBUTOR)
            && (value?.includes(WARRANTY_DISTRIBUTOR))) {
            this.setClaimTypeVisibity(true);

            return true;
        }

        this.setClaimTypeVisibity(false);

        return false;
    }

    public mapCompanyServices(value: Option[]): CompanyServices[] {
        return value?.map((option: Option) => ({
            id: option.value,
            name: option.name,
            code: option.value
        })) || [];
    }

    public getParentCompanyByCategoryType(categoryType: string): Observable<ParentCompany[]> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `categoryName=${categoryType}` });

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.get<ParentCompany[]>(`${environment.api.accountAdminParentCompanyById}`, {
                params,
                ...options
            })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public createCompany(company: Company): Observable<Company> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<Company>(`${environment.api.accountAdminCompany}`, company, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    public validateCIN(param: CSRValidatePostParam): Observable<HttpResponse<string>> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options: ApiOptions) => this.httpClient.post<HttpResponse<string>>(`${environment.api.accountAdminRelationships}/csr/validate?relationshipTypeId=${param.relationshipTypeId}&distributorCompanyId=${param.distributorCompanyId}&CIN=${param.CIN}`, {}, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }


    public isCompanyCategoryCustomerVendor(companyCode: CompanyCategoryType | string): Boolean {
        return Boolean(companyCode === CompanyCategoryType.HVAC_CUSTOMER
            || companyCode === CompanyCategoryType.INTERNATIONAL_CUSTOMER
            || companyCode === CompanyCategoryType.MARINE_CUSTOMER
            || companyCode === CompanyCategoryType.NATIONAL_ACCOUNT_CUSTOMER
            || companyCode === CompanyCategoryType.VENDOR);
    }

    generateServiceSelectiondata(services: CompanyServices[]): Option[] {
        return services?.map((service) => ({
            value: service.code,
            name: service.name
        })).slice().sort((valueA, valueB) => valueA.name.toLowerCase().localeCompare(valueB.name.toLowerCase())) || [];
    }

    private isDistributorType(companyType: string | undefined) {
        return Object.values(CompanyType).includes(companyType as CompanyType) || Object.values(CompanyType).includes((companyType?.replace('Z', '')) as CompanyType);
    }

    private setClaimTypeVisibity(visibility: boolean) {
        if (this.configuration && this.configuration.formConfig.claimType) {
            this.configuration.formConfig.claimType.edit = visibility;
            this.configuration.formConfig.claimType.view = visibility;
        }
    }

    private isInternalCBP(idmUserRoles: string[] | undefined) {
        return idmUserRoles?.includes(IdmUserRoles.INTERNALCBP);
    }

    private isInternalICP(idmUserRoles: string[] | undefined) {
        return idmUserRoles?.includes(IdmUserRoles.INTERNALICP);
    }

    private isITSuperAdmin(idmUserRoles: string[] | undefined) {
        return idmUserRoles?.includes(IdmUserRoles.IT_SUPER_ADMIN);
    }
}
