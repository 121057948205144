import { Pipe, PipeTransform } from '@angular/core';
import { ValueRange } from '../models/value-range';

@Pipe({ name: 'outOfRangeToChar' })
export class OutOfRangeToCharPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, char: string, configRange: ValueRange, checkForOutOfRange: Function): any {
        if (typeof value === 'undefined' ||
                value === null ||
                String(value).trim() === '' ||
                !(Number(value) >= configRange?.min && Number(value) <= configRange?.max)) {
            checkForOutOfRange(true);

            return (char + char);
        }
        checkForOutOfRange(false);

        return value;
    }
}
