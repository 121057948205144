import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Option } from 'common/components/select/select.component';
import { ProgramManager } from 'private/app/models/account-admin-program.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'hvac-program-manager',
    templateUrl: './program-manager.component.html',
    styleUrls: ['./program-manager.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgramManagerComponent implements OnInit {
    @Input() isModalVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    @Input() programManagers: Option[];
    @Input() assignedManagers: ProgramManager[];
    @Output() onManagerListChange: EventEmitter<Option[]> = new EventEmitter();
    @Output() isModalVisibleChange: EventEmitter<boolean> = new EventEmitter();

    programManagerForm = new UntypedFormGroup({ managers: new FormControl('') });
    programManagers$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);
    nonAssignedManagers$: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>([]);

    ngOnInit(): void {
        this.isModalVisible?.subscribe((visibility) => {
            if (visibility) {
                this.nonAssignedManagers$.next(this.programManagers.filter((managers) => !this.assignedManagers.find((assignedManager) => managers.value === assignedManager.userId)) || this.programManagers);
                this.programManagerForm.controls.managers.setValue('');
            }
        });
    }

    handleCloseModal() {
        this.isModalVisible?.next(false);
        this.isModalVisibleChange.next(false);
        this.programManagers$.next([]);
    }

    addManagers() {
        this.onManagerListChange.next(this.programManagers$.value);
        this.handleCloseModal();
    }

    onManagerSelect(managers: Option[]) {
        if (managers) {
            this.programManagers$.next(managers?.filter((manager) => manager?.value));
        }
    }

    removeManager(managerId: string) {
        const programManager = this.programManagers$.value.filter((manager) => manager.value !== managerId);
        this.programManagers$.next(programManager);
        this.programManagerForm.controls.managers.setValue(this.programManagers$.value?.map((manager) => ({
            value: manager.value,
            name: manager.name
        })));
    }
}
