import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UserValidationService } from 'private/app/services/user-validation/user-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { ManageSubscriptionStatus } from 'private/app/models/accountInfo';
import { Select } from '@ngxs/store';
import { AuthState } from 'common/store/auth/auth.state';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { User } from 'common/models';
import { DateService } from 'common/services/date/date.service';
import { ResponseSubscription } from 'private/app/models/user-validation';
import { ToastService } from 'common/services/toast.service';

@Component({
    selector: 'app-manage-subscriptions',
    templateUrl: './manage-subscriptions.component.html',
    styleUrls: ['./manage-subscriptions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ManageSubscriptionsComponent implements OnInit, OnDestroy {
    @Select(AuthState.user) user$: Observable<User>;

    ngOnDestroy$ = new Subject();

    public formValid$ = new BehaviorSubject(false);
    isSubscribed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    eventsSubject: Subject<boolean> = new Subject<boolean>();
    errorMessages = this.translate.instant('ACCOUNT_ADMIN.MANAGE_SUBSCRIPTIONS.ERROR');
    successMessage = this.translate.instant('ACCOUNT_ADMIN.MANAGE_SUBSCRIPTIONS.SUCCESS');
    public isLoading = true;
    public apiRequestActive: boolean;
    bannerType = 'ProfileBanner';

    public manageSubscriptionsForm: UntypedFormGroup = new UntypedFormGroup({ subscribeToBrandedNews: new UntypedFormControl('') });

    private subscriptionValid$ = new BehaviorSubject(false);


    constructor(
        public dateService: DateService,
        private readonly userValidationService: UserValidationService,
        private translate: TranslateService,
        private readonly toastService: ToastService
    ) { }

    ngOnInit(): void {
        this.apiRequestActive = true;
        this.userValidationService.getSubscriptionStatus().subscribe((subscriptionData) => {
            this.isSubscribed$.next(subscriptionData[0].isSubscribed);
            this.setFormValues(subscriptionData[0].isSubscribed);
            this.isLoading = false;
            this.apiRequestActive = false;
        });
    }

    changeSubscriptionControl(value: Event) {
        const input = <HTMLInputElement>value.target;
        this.subscriptionValid$.next(input.checked);
        this.isSubscribed$.next(input.checked);
    }

    updateInfoValid(isValid: boolean) {
        this.formValid$.next(isValid);
    }

    updateUserProfileDetails() {
        this.toastService.remove(this.bannerType);

        if (this.isSubscribed$.value) {
            this.userValidationService.createSubscription()
                .subscribe((status: ResponseSubscription) => {
                    this.getStatusMessage(status);
                    this.apiRequestActive = false;
                });
        }
        else {
            this.userValidationService.deleteSubscription()
                .subscribe((status: ResponseSubscription) => {
                    this.getStatusMessage(status);
                    this.apiRequestActive = false;
                });
        }

        this.apiRequestActive = true;
        this.eventsSubject.next(true);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getStatusMessage(status: any) {
        if (status.status === ManageSubscriptionStatus.Success) {
            this.toastService.add({
                content: this.successMessage,
                theme: 'success',
                id: this.bannerType,
                outletName: this.bannerType,
                closeable: true,
                autoClose: true
            });
        }
        else if (status === ManageSubscriptionStatus.Error) {
            this.toastService.add(({
                content: this.errorMessages,
                theme: 'error',
                id: this.bannerType,
                outletName: this.bannerType,
                closeable: true
            }));
        }
    }

    private setFormValues(userStatus: boolean) {
        if (userStatus) {
            this.manageSubscriptionsForm.controls.subscribeToBrandedNews.setValue(userStatus);
        }
    }
}
