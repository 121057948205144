import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { ApprovalRequestsResponse } from 'common/models/approval-requests';
import { Observable } from 'rxjs';
import { CacheNetworkObservableResponse } from '../models/Cache-Observable-Response';

@Injectable({ providedIn: 'root' })
export class ApprovalRequestsService {
    private cacheApprovalRequestsObservable: CacheNetworkObservableResponse<ApprovalRequestsResponse>
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService
    ) { }

    getApprovalRequestsCount(): Observable<ApprovalRequestsResponse> {
        if (!this.cacheApprovalRequestsObservable) {
            this.cacheApprovalRequestsObservable = CacheNetworkObservableResponse.createCachedClass<ApprovalRequestsResponse>(
                this.cacheApprovalRequestsObservable,
                this.httpClient,
                this.apiOptions,
                this.getApprovalRequestsCount.name
            );
        }

        return this.cacheApprovalRequestsObservable.value(environment.api.accountApprovalRequestsApi);
    }
}
