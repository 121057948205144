/* tslint:disable:max-line-length */
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { InputComponent } from 'common/components/input/input.component';
import { AccountState } from 'common/store/create-account.state';
import { createAccountEmailInputValidator, createAccountInputValidator, createAccountPasswordValidator, createAccountPhoneInputValidator, createAccountUserNameValidator, getErrorMessage } from 'common/utils/createAccountValidators';
import { AccountInfoUserInfo, EmailVerificationStatus, ICPDealerRegistrationDetails, UniqueAccountInfoStatus } from 'private/app/models/accountInfo';
import { FetchUniqueEmail, FetchUniqueUserName, LockUserAccountForm, ResetUniqueEmail, ResetUniqueUserName, SetAccountUserInformation, UnlockUserAccountForm } from 'private/app/store/create-account.actions';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const validPhoneRegex = /^[1]?[+]?[ ]?[(]?[0-9]{3}[)]?[ ]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
const validUserNameRegex = /^[a-zA-Z0-9.-]*$/;
/* tslint:enable:max-line-length */

@Component({
    selector: 'utc-create-account-info-form',
    templateUrl: './createAccountInfoForm.component.html',
    styleUrls: ['./createAccountInfoForm.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CreateAccountInfoFormComponent implements OnInit, OnDestroy {
    @ViewChild(InputComponent) firstInput: InputComponent;

    @Output() onValidChange = new EventEmitter<boolean>();

    @Select(AccountState.accountInfo) accountInfo$: Observable<AccountInfoUserInfo>;
    @Select(AccountState.uniqueUserNameStatus) uniqueUserNameStatus$: Observable<UniqueAccountInfoStatus>;
    @Select(AccountState.uniqueEmailStatus) uniqueEmailStatus$: Observable<UniqueAccountInfoStatus>;
    @Select(AccountState.validAccountInfo) validAccountInfo$: Observable<boolean>;
    @Select(AccountState.apiRequestActive) apiRequestActive$: Observable<boolean>;
    @Select(AccountState.emailVerificationCodeRequestStatus) verificationCodeStatus$: Observable<EmailVerificationStatus>;
    @Select(AccountState.icpDealerRegistrationDetails) icpDealerRegistrationDetails$: Observable<ICPDealerRegistrationDetails>;

    ngOnDestroy$ = new Subject();

    accountInfoForm = new UntypedFormGroup({
        firstNameControl: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        lastNameControl: new UntypedFormControl('', [createAccountInputValidator(101, true)]),
        phoneControl: new UntypedFormControl('',
            [createAccountPhoneInputValidator(101, true, validPhoneRegex)]),
        emailControl: new UntypedFormControl('',
            [createAccountEmailInputValidator(101, true, validEmailRegex)]),
        termsControl: new UntypedFormControl(false, Validators.requiredTrue),
        userNameControl: new UntypedFormControl('', [createAccountUserNameValidator(13, true, validUserNameRegex)]),
        passwordControl: new UntypedFormControl('', [createAccountPasswordValidator(), Validators.maxLength(51)])
    });

    defaultPasswordRequirements: { [key: string]: boolean } = {
        COMPLEXITY_REQUIREMENT: false,
        MIN_LENGTH: false,
        USERNAME_CANNOT_EQUAL_PASSWORD: false
    };

    errorMessages$ = this.translate.get('CREATE_ACCOUNT_INFORMATION.ERROR_MESSAGES');
    requirementMessages$: Observable<string> = this.translate.get('CREATE_ACCOUNT_INFORMATION.PASSWORD_REQUIREMENTS');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    passwordRequirements$: Observable<any>;
    firstNameErrorMessage$: Observable<string>;
    lastNameErrorMessage$: Observable<string>;
    phoneErrorMessage$: Observable<string>;
    emailErrorMessage$: Observable<string>;
    userNameErrorMessage$: Observable<string>;
    passwordErrorMessage$: Observable<string>;

    constructor(
        private translate: TranslateService,
        private readonly store: Store
    ) {
        this.accountInfo$.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((info: AccountInfoUserInfo) => {
            if (info.firstName) {
                this.accountInfoForm.controls.firstNameControl.setValue(info.firstName, { emitEvent: true });
            }
            if (info.lastName) {
                this.accountInfoForm.controls.lastNameControl.setValue(info.lastName, { emitEvent: true });
            }
            if (info.phoneNumber) {
                this.accountInfoForm.controls.phoneControl.setValue(info.phoneNumber, { emitEvent: true });
            }
            if (info.emailAddress) {
                this.accountInfoForm.controls.emailControl.setValue(info.emailAddress, { emitEvent: true });
            }
            if (info.username) {
                this.accountInfoForm.controls.userNameControl.setValue(info.username, { emitEvent: true });
            }
            if (info.password) {
                this.accountInfoForm.controls.passwordControl.setValue(info.password, { emitEvent: true });
            }
        });

        this.validAccountInfo$.subscribe((res: boolean) => {
            if (res) {
                this.store.dispatch(new Navigate(['/create-account-email-validate'], {}, { queryParamsHandling: 'preserve' }));
            }
        });
    }

    ngOnInit() {
        combineLatest([this.uniqueEmailStatus$, this.uniqueUserNameStatus$]).pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe(([emailStatus, usernameStatus]) => {
            if (emailStatus !== UniqueAccountInfoStatus.Pending && usernameStatus !== UniqueAccountInfoStatus.Pending) {
                this.store.dispatch(new UnlockUserAccountForm());
            }

            const { controls } = this.accountInfoForm;
            if (emailStatus === UniqueAccountInfoStatus.Unique && usernameStatus === UniqueAccountInfoStatus.Unique) {
                return this.store.dispatch(new SetAccountUserInformation({
                    firstName: controls['firstNameControl'].value,
                    lastName: controls['lastNameControl'].value,
                    phoneNumber: controls['phoneControl'].value,
                    emailAddress: controls['emailControl'].value,
                    username: controls['userNameControl'].value,
                    password: controls['passwordControl'].value
                }));
            }

            if (usernameStatus === UniqueAccountInfoStatus.NotUnique) {
                controls.userNameControl.setErrors({ USERNAME_TAKEN: true });

                return this.store.dispatch(new ResetUniqueUserName());
            }

            if (emailStatus === UniqueAccountInfoStatus.NotUnique) {
                controls.emailControl.setErrors({ EMAIL_TAKEN: true });

                return this.store.dispatch(new ResetUniqueEmail());
            }

            return null;
        });

        this.passwordRequirements$ = this.accountInfoForm.controls.passwordControl.valueChanges.pipe(
            withLatestFrom(this.requirementMessages$),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map(([value, message]: any) => {
                const errors: { [key: string]: boolean } = {
                    ...this.defaultPasswordRequirements,
                    ...this.accountInfoForm.controls.passwordControl.errors
                };
                if (this.accountInfoForm.controls.userNameControl.value === value) {
                    errors.USERNAME_CANNOT_EQUAL_PASSWORD = true;
                }

                return Object.keys(errors).map((validReq) => ({
                    valid: !errors[validReq],
                    message: message[validReq]
                }));
            })
        );

        this.firstNameErrorMessage$ = combineLatest([
            this.accountInfoForm.controls.firstNameControl.statusChanges, this.errorMessages$
        ]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.firstNameControl.errors, message))
        );
        this.lastNameErrorMessage$ = combineLatest([this.accountInfoForm.controls.lastNameControl.statusChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.lastNameControl.errors, message))
        );
        this.phoneErrorMessage$ = combineLatest([this.accountInfoForm.controls.phoneControl.statusChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.phoneControl.errors, message))
        );
        this.emailErrorMessage$ = combineLatest([this.accountInfoForm.controls.emailControl.statusChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.emailControl.errors, message))
        );
        this.userNameErrorMessage$ = combineLatest([this.accountInfoForm.controls.userNameControl.statusChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.userNameControl.errors, message))
        );
        this.passwordErrorMessage$ = combineLatest([this.accountInfoForm.controls.passwordControl.valueChanges, this.errorMessages$]).pipe(
            map(([_valid, message]) => getErrorMessage(this.accountInfoForm.controls.passwordControl.errors, message))
        );

        this.accountInfoForm.statusChanges.subscribe((status) => {
            this.onValidChange.emit(status === 'VALID');
        });
    }

    submitAccountInfo() {
        this.store.dispatch(new LockUserAccountForm());
        this.store.dispatch(new FetchUniqueUserName(this.accountInfoForm.controls.userNameControl.value));
        this.store.dispatch(new FetchUniqueEmail(this.accountInfoForm.controls.emailControl.value));
    }


    changeTermsControl($event: Event) {
        return this.accountInfoForm.controls.termsControl.setValue(($event.target as HTMLInputElement).checked);
    }

    public focusForm() {
        this.firstInput.input.nativeElement.focus();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
