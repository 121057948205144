import { Component, Input } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ConnectedDealer } from 'private/app/models/connected-dealer.model';
import { CompanyCode } from 'private/app/models/distributor-relationships.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hvac-dealer-info',
    templateUrl: './dealer-info.component.html',
    styleUrls: ['./dealer-info.component.scss']
})
export class DealerInfoComponent extends BaseComponent {
    @Input() dealerInfo: ConnectedDealer

    public ecobeePin: string | null = null;
    public isEcobeePinLoading = false;
    public isEcobeePinRequestDisabled = true;

    constructor(
        private dealerService: DealersService,
        private connectedPortalUserService: ConnectedPortalUserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.ecobeePin = this.dealerInfo.ecobeePin || null;

        this.connectedPortalUserService.getCompanyCode()
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((companyCode) => {
                this.isEcobeePinRequestDisabled = companyCode !== CompanyCode.Dealer;
            });
    }

    handleRequestEcobeePinClick() {
        this.requestEcobeePin();
    }

    private requestEcobeePin() {
        const { id } = this.dealerInfo;

        if (id) {
            this.isEcobeePinLoading = true;
            this.dealerService.requestEcobeePinByDealerId(id)
                .pipe(
                    catchError((err) => {
                        this.isEcobeePinLoading = false;

                        throw err;
                    })
                )
                .subscribe((res) => {
                    if (res) {
                        const { ecobeePin } = res.data;

                        this.ecobeePin = ecobeePin;
                        this.isEcobeePinLoading = true;
                    }
                });
        }
    }
}
