<div class="hvac-bookmark-list">
        <button hvacButton="secondary" class=' hvac-bookmarks-add-current-page-button' (click)="addBookmark()" >
            <utc-svg [src]="'assets/icons/close.svg'"></utc-svg>
            <span>{{'HEADER.BOOKMARKS.ADD_CURRENT_PAGE' | translate}}</span>
        </button>
        <ol class="hvac-bookmarks-options">
            <li class="hvac-bookmarks-item-list" *ngFor="let bookmark of (bookmarkList$ | async )">
                <div class="hvac-bookmarks-list-item">
                <a (click)="handleBookmarkNavigate(bookmark)" class="hvac-bookmarks-list" target="_self"
                    [routerLink]="[bookmark.link]" [queryParams]="bookmark.queryParams">
                    {{ bookmark.caption }}
                </a>
                <button class='hvac-bookmarks-remove-button' (click)="removeBookmark(bookmark)">
                    <utc-svg class='hvac-bookmarks-remove-icon' src="assets/icons/close.svg"></utc-svg>
                    <span class="hvac-accessible-text">{{ 'BOOKMARKS.DELETE' | translate:{ bookmark: bookmark.caption }
                        }}</span>
                </button>
            </div>
            </li>
        </ol>
</div>
