import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BookmarkService } from '../services/bookmark.service';
import { Bookmark, BookmarkPostData, BookmarkStateModel, FetchAddBookmark, FetchBookmarks, FetchDeleteBookmark, RecieveAddBookmark, RecieveBookmarks, RecieveDeleteBookmark } from './bookmarks.actions';


@State<BookmarkStateModel>({
    name: 'bookmarks',
    defaults: { bookmarks: [] }
})

@Injectable({ providedIn: 'root' })
export class BookmarkState {
    constructor(private readonly bookmarkService: BookmarkService) { }

    @Selector()
    static bookmarks({ bookmarks }: BookmarkStateModel): Array<Bookmark> {
        return bookmarks;
    }

    @Action(FetchBookmarks)
    fetchBookmarks(ctx: StateContext<BookmarkStateModel>) {
        return this.getBookmarks(ctx);
    }

    @Action(RecieveBookmarks)
    recieveBookmarks(ctx: StateContext<BookmarkStateModel>, action: RecieveBookmarks) {
        const decodedBookmarks = action.bookmarks?.map((bookmark) => ({
            ...bookmark,
            link: decodeURIComponent(bookmark.link)
        }));
        ctx.patchState({ bookmarks: decodedBookmarks });
    }

    @Action(FetchAddBookmark)
    fetchAddBookmark(ctx: StateContext<BookmarkStateModel>, action: FetchAddBookmark) {
        return this.addBookmark(ctx, action.bookmarkData);
    }

    @Action(RecieveAddBookmark)
    recieveAddBookmark(ctx: StateContext<BookmarkStateModel>, action: RecieveAddBookmark) {
        const newBookmarks: Array<Bookmark> = ctx.getState().bookmarks;

        return ctx.patchState({ bookmarks: newBookmarks.concat(action.bookmark) });
    }

    @Action(FetchDeleteBookmark)
    fetchDeleteBookmark(ctx: StateContext<BookmarkStateModel>, action: FetchDeleteBookmark) {
        return this.deleteBookmark(ctx, action.bookmark);
    }

    @Action(RecieveDeleteBookmark)
    recieveDeleteBookmark(ctx: StateContext<BookmarkStateModel>, action: RecieveDeleteBookmark) {
        const removedBookmarks: Array<Bookmark> = ctx.getState().bookmarks;

        return ctx.patchState({ bookmarks: removedBookmarks.filter((bookmark) => bookmark.id !== action.bookmark.id) });
    }

    private getBookmarks(ctx: StateContext<BookmarkStateModel>) {
        this.bookmarkService.getBookmarks().subscribe((res) => {
            ctx.dispatch(new RecieveBookmarks(res));
        });
    }

    private addBookmark(ctx: StateContext<BookmarkStateModel>, bookmark: BookmarkPostData) {
        this.bookmarkService.addBookmarks(bookmark).subscribe((res) => {
            ctx.dispatch(new RecieveAddBookmark(res));
        });
    }

    private deleteBookmark(ctx: StateContext<BookmarkStateModel>, deletedBookmark: Bookmark) {
        this.bookmarkService.deleteBookmarks(deletedBookmark.id).subscribe((_res) => ctx.dispatch(new RecieveDeleteBookmark(deletedBookmark)));
    }
}
