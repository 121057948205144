import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { Selectable, SelectablePaginationUtils } from '../../../../../common/utils/PaginationUtils';
import * as XLSX from 'xlsx';
import { ToastService } from 'common/services/toast.service';
import { UserRegistrationRequest } from 'private/app/models/user.model';

interface ApprovalRequestUserExportFormat {
    'First Name': string;
    'Last Name': string;
    'User ID': string;
    'Company Name': string;
    'Company ID': string;
    'Created Date': string;
    Status: string;
}

@Component({
    selector: 'hvac-user-registrations-results',
    templateUrl: './user-registrations-results.component.html',
    styleUrls: ['./user-registrations-results.component.scss']
})
export class UserRegistrationsResultsComponent implements OnInit, OnChanges {
    @Input() users: UserRegistrationRequest[] = [];
    @Input() loading: boolean;
    @Output() registrationUserChangePage = new EventEmitter<{
        registrationUserStep: string,
        usersData : UserRegistrationRequest
    }>();

    public dataHeaders: DataHeader[] = [
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.SELECT_TEXT'),
            value: '',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.FIRST_NAME'),
            value: 'FirstName',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.LAST_NAME'),
            value: 'LastName',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.USER_ID'),
            value: 'UserId',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_NAME'),
            value: 'CompanyName',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.COMPANY_ID'),
            value: 'CompanyId',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.CREATED_DATE'),
            value: 'CreatedDate',
            order: null
        },
        {
            title: this.translateService.instant('ACCOUNT_ADMIN.STATUS'),
            value: 'UserStatus',
            order: null
        },
        {
            title: '',
            value: '',
            order: null
        }
    ];

    public readonly pageSize = 10;
    public usersData: SelectablePaginationUtils<UserRegistrationRequest>;

    constructor(
        private readonly translateService: TranslateService,
        private readonly toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        this.usersData = new SelectablePaginationUtils(this.users.map(this.transformUserDataToSelectableData), this.pageSize, this.dataHeaders);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.users) {
            const users: UserRegistrationRequest[] = changes.users.currentValue;

            this.usersData?.initData(users.map(this.transformUserDataToSelectableData));
        }
    }

    sort(item: DataHeader): void {
        this.usersData.sort(item);
    }

    selectUserRegistration(event: MouseEvent, selectedId: string) {
        this.usersData.selectItem(event, selectedId);
    }

    onSelectAllCurrentPageClick() {
        this.usersData.onSelectAllCurrentPageClick();
    }

    onSelectAllFromAllPagesClick() {
        this.usersData.onSelectAllFromAllPagesClick();
    }

    onExportSelectedClick() {
        const selectedUsers = this.usersData.getAllPagesData.filter((data) => data.isSelected).map((filteredData) => filteredData.data);

        if (selectedUsers && selectedUsers.length === 0) {
            this.toastService.add({
                content: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.ERRORS.NO_RESULTS_SELECTED_TO_EXPORT'),
                autoClose: true,
                closeable: true,
                theme: 'error',
                id: this.translateService.instant('ACCOUNT_ADMIN.USER_REGISTRATIONS_PAGE.TOAST_ID')
            });

            return;
        }

        const title = `User_Registrations_${new Date().toDateString()}`;
        const fileName = `${title}.xlsx`;
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.transformToExportUserFormart(selectedUsers));
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        XLSX.writeFile(workBook, fileName);
    }

    public onClickReview(usersData: UserRegistrationRequest) {
        this.registrationUserChangePage.emit({
            registrationUserStep: 'step1_userRegistrationForm',
            usersData: usersData
        });
    }

    private transformToExportUserFormart(users: UserRegistrationRequest[]): ApprovalRequestUserExportFormat[] {
        return users.map((user): ApprovalRequestUserExportFormat => ({
            'First Name': user.firstName,
            'Last Name': user.lastName,
            'User ID': user.userName || '',
            'Company Name': user.companyName,
            'Company ID': user.companyId,
            'Created Date': new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).format(new Date(user.createdDate)),
            'Status': user.userStatus || ''
        }));
    }

    private transformUserDataToSelectableData = (userData: UserRegistrationRequest): Selectable<UserRegistrationRequest> => ({
        data: userData,
        isSelected: false,
        getId: () => userData.firstName + userData.lastName + userData.email + userData.companyId
    })
}
