import { ClaimOption } from './company-relationship.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Option } from '../../../../common/components/select/select.component';
import { AccountAdminFormFieldService } from './account-admin-form-field.service';

@Injectable({ providedIn: 'root' })
export class AccountAdminCommonComponentService {
    public formFields = this.formFieldService.accountAdminParentFormFields;

    public claimTypeOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.CLAIM_TYPES.ENTITLEMENT_CLAIMS'),
            value: ClaimOption.ENTITLEMENT_AND_CLAIMS
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.CLAIM_TYPES.ENTITLEMENT'),
            value: ClaimOption.ENTITLEMENT
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.CLAIM_TYPES.INACTIVE'),
            value: ClaimOption.INACTIVE
        }
    ]

    public createdDateOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.CREATED_DATE_PLACEHOLDER'),
            value: ''
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.RELATIONSHIP_CREATED_DATE'),
            value: '1'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.RELATIONSHIP_MODIFIED_DATE'),
            value: '2'
        }
    ]

    public relationshipStatusOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.STATUS_PLACEHOLDER'),
            value: ''
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.ACTIVE'),
            value: '1'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.INACTIVE'),
            value: '2'
        }
    ]

    public relationshipTypeOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.TYPE_PLACEHOLDER'),
            value: ''
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.BRANDED'),
            value: '1'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.PRICING'),
            value: '2'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.WARRANTY'),
            value: '3'
        }
    ]

    public tierLevelOptions: Option[] = [
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.TIER_LEVEL_PLACEHOLDER'),
            value: ''
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.LEADERSHIP'),
            value: '1'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.ADVERTISING'),
            value: '2'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.RESIDENTIAL_CONTRACTOR'),
            value: '3'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.OTHER'),
            value: '4'
        },
        {
            name: this.translateService.instant('ACCOUNT_ADMIN.RELATIONSHIP_CRITERIA_SECTION.COMMERCIAL_CONTRACTOR'),
            value: '5'
        }
    ]

    constructor(private translateService: TranslateService, private formFieldService: AccountAdminFormFieldService) { }
}
