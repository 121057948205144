import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { ToastService, Toast } from 'common/services/toast.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { PoToastTypes } from 'private/app/models/purchase-order-details.model';
import { AccountStatusState } from 'common/store/account-status/account-status.state';
import { AccountStatusModel } from 'common/models/account-status';
import { PurchaseOrderService } from 'private/app/services/purchase-orders.service';

@Component({
    selector: 'hvac-trade-partners',
    templateUrl: './trade-partners.component.html',
    styleUrls: ['./trade-partners.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TradepartnersComponent {
    @Select(AccountStatusState.getProfileInfo) user$: Observable<AccountStatusModel | null>;
    public id: string;
    public data: ContentHeadingData;
    public hasHeaderData: boolean;
    public showBackButton: boolean;

    constructor(
        private route: ActivatedRoute,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router,
        private purchaseOrderService: PurchaseOrderService
    ) {
        this.route.queryParams.subscribe((params) => {
            if (Object.keys(params).length > 0) {
                const paramName: PoToastTypes = Object.keys(params)[0] as PoToastTypes;
                let toast: Toast;
                const poNumber = params[PoToastTypes.ACKN] || params[PoToastTypes.ASN];

                switch (paramName) {
                    case ('error'):
                        toast = {
                            bgColor: '#F8F8F8',
                            autoClose: false,
                            closeable: true,
                            content: `There was no purchase order found with that number`,
                            id: 'tp-po-error',
                            theme: 'error'
                        };
                        break;
                    default:
                        toast = {
                            bgColor: '#F8F8F8',
                            autoClose: false,
                            closeable: true,
                            content: this.translateService.instant(`TRADE_PARTNERS.CONFIRMATION_TOAST.${paramName}`, { number: poNumber }),
                            id: 'tp-po',
                            theme: 'success'
                        };
                }
                this.toastService.add(toast);
            }
        });

        const routeData$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route.snapshot),
            map((innerRoute) => {
                while (innerRoute.firstChild) {
                    // eslint-disable-next-line no-param-reassign
                    innerRoute = innerRoute.firstChild;
                }

                return innerRoute;
            }),
            tap((innerRoute) => {
                if (innerRoute.params.id) {
                    this.purchaseOrderService.getActivePo(innerRoute.params.id).subscribe((item) => {
                        this.purchaseOrderService.activePurchaseOrder$.next(item);
                    }, () => {
                        this.router.navigate(['/trade-partners'], { queryParams: { [PoToastTypes.ERROR]: true } });
                    });
                }
            }),
            filter((innerRoute) => innerRoute.data?.headerData),
            map((innerRoute) => innerRoute)
        );


        routeData$.pipe(
            switchMap((activeRoute) => {
                const routeId = activeRoute.params.id;
                this.showBackButton = Boolean(routeId);

                return this.translateService.get(`${activeRoute.data.headerData}.title`, { number: routeId }).pipe(
                    tap((data) => {
                        this.hasHeaderData = true;
                        this.data = { Content: { title: data } };
                    })
                );
            })
        ).subscribe();
    }
}
