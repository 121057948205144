<hvac-connected-portal-layout-container>
    <div class="hvac-cp-top-container" top>
        <div class="hvac-cp-breadcrumb-header">
            <hvac-content-header *ngIf="(breadcrumbs$ | async) as breadcrumbs" [data]="breadcrumbs">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <section class="hvac-cp-bottom-container" bottom>
        <div class="hvac-row">
            <div class="hvac-col-xs-12">
                <div class="hvac-cp-card ">
                    <div class="hvac-cp-warranty-title">
                        <h2 class="hvac-cp-warranty-title-name">{{'CONNECTED_PORTAL.PRODUCT_WARRANTY.HEADING' | translate}}</h2>
                        <div class="hvac-cp-warranty-title-back-button-container">
                            <a hvacButton="default" width="full" size="small" hvacButtonIcon class="hvac-button hvac-button-small" [routerLink]="detailUrl$ | async">
                                <utc-svg class="hvac-cp-warranty-title-back-button-container-back-arrow" src="assets/icons/arrow.svg"></utc-svg>
                                <span>{{ 'CONNECTED_PORTAL.PAGE_HEADINGS.CONTROL_DETAILS' | translate }}</span>
                            </a>
                        </div>
                    </div>
                    <div>
                        <hvac-warranty-details *ngIf="(serialNo$ | async) as serialNo" [serialNumber]="serialNo"></hvac-warranty-details>
                    </div>
                </div>
            </div>
        </div>
    </section>
</hvac-connected-portal-layout-container>
