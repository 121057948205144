import { Component, Input, OnInit } from '@angular/core';
import { Pagination, SystemType } from 'private/app/models/connected-product.model';
import { BehaviorSubject, EMPTY, Observable, combineLatest } from 'rxjs';
import { WallControlEventLogConfig, WallControlEventLogEvent } from '../../../wall-control-event-log/wall-control-event-log.component';
import { catchError, debounceTime, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DefaultQueryParams } from 'private/app/models/default-query-params';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { BaseComponent } from 'common/components/base/base.component';
import { ProductAlert } from 'private/app/models/alert.model';

const PAGINATION_PAGE_SIZE = 5;

interface EventLogData extends Pagination {
    config: WallControlEventLogConfig;
    items: WallControlEventLogEvent[];
}

@Component({
    selector: 'hvac-generic-event-logs',
    templateUrl: './generic-event-logs.component.html',
    styleUrls: ['./generic-event-logs.component.scss']
})
export class GenericEventLogsComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;

    public wallControlEventLogData$: Observable<EventLogData>;
    public system$: Observable<ConnectedSystem | null>;
    public wallControlEventLogCurrentPage$ = new BehaviorSubject<number>(1);
    public isLoadingAlerts$ = new BehaviorSubject(false);
    private loadData$ = new BehaviorSubject<boolean>(true);

    constructor(
        private dealerService: DealersService,
        private alertService: AlertsService,
        private dataSharingService: DataSharingService
    ) {
        super();
    }

    ngOnInit(): void {
        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlEventLogData$ = this.loadData$.pipe(
            switchMap(() => combineLatest([
                this.wallControlEventLogCurrentPage$,
                this.system$.pipe(map((system) => system?.systemId))
            ]).pipe(
                // Avoids making multiple api calls when props change close together
                debounceTime(100),
                tap(() => {
                    this.isLoadingAlerts$.next(true);
                }),
                filter(([, systemId]) => Boolean(systemId)),
                switchMap(
                    ([currentPage, systemId]) => {
                        const queryParams: DefaultQueryParams = {
                            pagination: {
                                currentPage,
                                pageSize: PAGINATION_PAGE_SIZE
                            },
                            sort: {
                                field: 'dateTime',
                                order: 'desc'
                            }
                        };

                        return this.alertService
                            .queryAlertsBySystemId(
                                systemId as string,
                                this.systemType,
                                this.dealerId,
                                queryParams
                            ).pipe(
                                map(({ data, totalPages }) => {
                                    const items = this.formatProductAlertsToEventLogItems(data);
                                    const config = new WallControlEventLogConfig();
                                    config.diagnostics = false;

                                    return {
                                        config,
                                        items,
                                        totalPages,
                                        currentPage
                                    };
                                }),
                                catchError(() => {
                                    this.isLoadingAlerts$.next(false);

                                    return EMPTY;
                                })
                            );
                    }
                ),
                tap(() => {
                    this.isLoadingAlerts$.next(false);
                }),
                takeUntil(this.ngOnDestroy$)
            ))
        );
    }

    private formatProductAlertsToEventLogItems(productAlerts: ProductAlert[]): EventLogData['items'] {
        return [...productAlerts]
            .map((alert): WallControlEventLogEvent => ({
                code: alert.error.code,
                dateTime: alert.dateTime,
                severity: alert.error.severity,
                type: alert.error.type,
                description: alert.error.description,
                consecutiveEvents: alert.occurrenceCount,
                equipmentSource: alert.source,
                diagnosticsUrl: alert.error.troubleshootUri
            }))
            .sort((itemA, itemB) => (itemA && itemB ? itemB.dateTime.localeCompare(itemA.dateTime) : 0));
    }
}
