import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from 'common/content/services/error.service';
import { ContentHeadingData } from 'common/models/content-heading';
import { CompanyCode } from 'private/app/models/distributor-relationships.model';
import { ConnectedPortalUserService } from 'private/app/services/connected-portal/connected-portal-user.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap, startWith } from 'rxjs/operators';
import { ApiResponseCode, MISSING_ROUTE_PARAMS_ERROR } from '../../constants';
import { FADData } from 'private/app/models/fad-model';

@Component({
    selector: 'hvac-dealer-fad',
    templateUrl: './dealer-fad.component.html',
    styleUrls: ['./dealer-fad.component.scss']
})
export class DealerFadComponent implements OnInit {
    public year: number;
    public readonly CompanyCode = CompanyCode;
    public dealerId$: Observable<string>;
    public companyCode$: Observable<CompanyCode>;
    public breadcrumbs$: Observable<ContentHeadingData>;
    public fadData$: Observable<FADData>;

    constructor(
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private connectedPortalUserService: ConnectedPortalUserService,
        private dealersService: DealersService,
        private errorService: ErrorService
    ) { }

    ngOnInit(): void {
        const today = new Date();
        this.year = today.getFullYear();

        if (!this.route.parent) {
            this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

            return;
        }

        const routeData$ = this.route.parent.paramMap.pipe(
            map((params) => {
                const dealerId = params.get('id');

                if (dealerId) {
                    return { dealerId };
                }

                throw new Error(MISSING_ROUTE_PARAMS_ERROR);
            }),
            catchError(() => {
                this.errorService.errorStatus$.next(ApiResponseCode.NOT_FOUND);

                return EMPTY;
            })
        );

        this.dealerId$ = routeData$.pipe(
            map(({ dealerId }) => dealerId),
            shareReplay()
        );

        this.companyCode$ = this.connectedPortalUserService.getCompanyCode().pipe(
            map((code) => code as CompanyCode),
            shareReplay()
        );

        this.breadcrumbs$ = this.dealerId$.pipe(
            switchMap((dealerId) => this.dealersService.queryDealerById(dealerId).pipe(
                map(({ data }) => this.getBreadcrumbData(dealerId, data.name)),
                startWith(this.getBreadcrumbData(dealerId))
            ))
        );
        this.fadData$ = this.dealerId$.pipe(
            switchMap((dealerID) => this.dealersService.queryFadProgressionByDealerID(dealerID, this.year).pipe(
                map((result) => result as FADData)
            ))
        );
    }

    private getBreadcrumbData(dealerId: string, dealerName?: string): ContentHeadingData {
        return {
            Content: { title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.FAD') },
            breadCrumbs: [
                {
                    title: this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.CONNECTED_PORTAL'),
                    url: '/connected-portal'
                },
                {
                    title: dealerName ? dealerName : this.translateService.instant('CONNECTED_PORTAL.PAGE_HEADINGS.DEALER'),
                    url: `/connected-portal/dealers/${dealerId}`
                }
            ]
        };
    }
}
