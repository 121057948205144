/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'common/components/select-new/select-new.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { NumberSuffixPipe } from 'common/pipes/number-suffix.pipe';
import { ControlConfigPropReadOnly, ControlConfigPropReadOnlyAndOptions } from 'private/app/models/connected-portal-control-config-prop.model';
import { SystemDiagnosticsExtendedParamConfig } from 'private/app/models/connected-portal-system-diagnostics.model';
import { ConnectedPortalUIConfig, ConnectedPortalUIConfigProp, UIConfigOptionNumConfig } from 'private/app/models/connected-portal-ui-config.model';
import { IduConfiguration } from 'private/app/models/idu.model';
import { OduConfiguration } from 'private/app/models/odu.model';
import { SystemConditionsParamsConfig, WallControlConfig, WallControlConfigEditParams, WallControlConfigZone } from 'private/app/models/wall-control.model';
import { DateTimeTzPipe } from 'private/app/pipes/date-time-tz.pipe';
import { ECOBEE_CONFIG_RANGE_PROPERTIES, ECOBEE_CONFIG_READ_ONLY_PROPERTY_RANGES, ECOBEE_TRANSLATION_VALUE_KEYS, ECOBEE_UNKNOWN_VALUES, DEVICE_TEMPERATURE_PROPERTIES, TempUnitFormat, DEVICE_PERCENT_PROPERTIES } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIConfigApiService } from './ui-config-api.service';
import { isValueOutOfRange } from './utils';
import { Unit } from 'private/app/views/connected-portal/dealer/customer-product-details/wall-control/carrier-elt-wall-control-detail/components/carrier-elt-wall-control-config/carrier-elt-wall-control-config.service';

export type OduConfigStaticEditParams = ConnectedPortalUIConfig['controlConfigEditParams']['infinity']['outdoorUnit'];
export type IduConfigStaticEditParams = ConnectedPortalUIConfig['controlConfigEditParams']['infinity']['indoorUnit'];
export type WallControlStaticEditParams = ConnectedPortalUIConfig['controlConfigEditParams']['infinity']['wallControl'] | ConnectedPortalUIConfig['controlConfigEditParams']['ecobee']['wallControl'] | ConnectedPortalUIConfig['controlConfigEditParams']['carrierELT']['wallControl'];
export type StaticConfigEditParams = OduConfigStaticEditParams | IduConfigStaticEditParams | WallControlStaticEditParams;
export type ConfigData = Omit<WallControlConfig, 'zones'> | IduConfiguration | OduConfiguration;

export interface ConfigProp {
    readOnly: boolean;
    id: string;
    value: string | Date | unknown;
    type?: ConnectedPortalUIConfigProp['type'];
    control?: UntypedFormControl
    groupId?: string;
    options?: string[];
    unit?: string;
    min?: number;
    max?: number;
    step?: number;
    settingsType?: ConnectedPortalUIConfigProp['settingsType'],
    displayOrder?: number;
    displayValue?:string
}

export interface ConfigEditProp extends Omit<ConfigProp, 'options'> {
    options?: Option[]
}

export interface ConfigEditZoneProp extends ConfigEditProp {
    zoneName: string;
}

export type ControlConfigEditParams = ConnectedPortalUIConfig['controlConfigEditParams'];

export enum EcobeeWallControlConfigParamMapping {
    DEAD_BAND = 'deadband',
    OUTDOOR_TEMP_LOCK = 'compressorOutdoorMinTemp',
    AUX_OUTDOOR_MAX_TEMP = 'auxOutdoorMaxTemp',
    OVER_COOL_TO_DEHUMIDIFY = 'acOvercoolMax',
    FAN_RUNTIME_MIN = 'fanRuntimeMin',
    MODE = 'userModeSelection',
    PROGRAM_MODE = 'programMode',
    HEAT_TO_SET_POINT = 'heatToSetPoint',
    COOL_TO_SET_POINT = 'coolToSetPoint'
}

export enum EcobeeWallControlConfigSettingsType {
    DEAD_BAND = 'advanced',
    OUTDOOR_TEMP_LOCK = 'advanced',
    OVER_COOL_TO_DEHUMIDIFY = 'advanced',
    AUX_OUTDOOR_MAX_TEMP = 'advanced',
    FAN_RUNTIME_MIN = 'basic',
    MODE = 'basic',
    PROGRAM_MODE = 'basic',
    HEAT_TO_SET_POINT = 'basic',
    COOL_TO_SET_POINT = 'basic'
}

export enum EcobeeWallControlConfigDisplayOrder {
    DEAD_BAND = 6,
    OUTDOOR_TEMP_LOCK = 7,
    OVER_COOL_TO_DEHUMIDIFY = 9,
    AUX_OUTDOOR_MAX_TEMP = 8,
    FAN_RUNTIME_MIN = 5,
    MODE = 1,
    PROGRAM_MODE = 4,
    HEAT_TO_SET_POINT = 3,
    COOL_TO_SET_POINT = 2
}

@Injectable()
export class ProductConfigurationService {
    public connectedPortalUIConfigStaticData?: ConnectedPortalUIConfig;
    public ecobeeConfigOutOfRange$ = new BehaviorSubject<boolean>(false);

    public uiConfigData$: Observable<ConnectedPortalUIConfig>;
    public staticConfigEditParams$: Observable<ControlConfigEditParams>;
    public systemDiagnosticConfigParams$: Observable<SystemDiagnosticsExtendedParamConfig>;
    public systemConditionsConfigParams$: Observable<SystemConditionsParamsConfig>;

    constructor(
        private uiConfigApiService: UIConfigApiService,
        private translate: TranslateService,
        private dateTimeTzPipe: DateTimeTzPipe,
        private numberSuffixPipe: NumberSuffixPipe
    ) {
        this.uiConfigData$ = this.uiConfigApiService.getConnectedPortalUIConfig();
        this.staticConfigEditParams$ = this.uiConfigData$.pipe(map((uiStaticData) => uiStaticData.controlConfigEditParams));
        this.systemDiagnosticConfigParams$ = this.uiConfigData$.pipe(map((uiStaticData) => uiStaticData.systemDiagnosticsParams));
        this.systemConditionsConfigParams$ = this.uiConfigData$.pipe(map((uiStaticData) => uiStaticData.systemConditionsParams));
    }

    getReadOnlyPropData(configData: ConfigData, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        return Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                const propData: Omit<ConfigProp, 'options'> = {
                    id: key,
                    readOnly: true,
                    value: this.formatValue(key, value, tempUnitFormat)
                };

                acc[key] = propData;

                return acc;
            }, {} as Record<string, ConfigEditProp>);
    }

    getEcobeeReadOnlyPropData(configData: ConfigData, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        return Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                const propData: Omit<ConfigProp, 'options'> = {
                    id: this.getEcobeeMappingKey(key as EcobeeWallControlConfigParamMapping),
                    readOnly: true,
                    value: this.formatValue(key, value, tempUnitFormat),
                    ...({
                        settingsType: this.getEcobeeSettingsType(key as EcobeeWallControlConfigParamMapping),
                        displayOrder: this.getEcobeeDisplayOrder(key as EcobeeWallControlConfigParamMapping)
                    })
                };

                acc[key] = propData;

                return acc;
            }, {} as Record<string, ConfigEditProp>);
    }

    getEltReadOnlyPropData(configData: ConfigData, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        return Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                const propData: Omit<ConfigProp, 'options'> = {
                    id: key,
                    readOnly: true,
                    value: this.formatEltPropValue(key, value, tempUnitFormat)
                };

                acc[key] = propData;

                return acc;
            }, {} as Record<string, ConfigEditProp>);
    }

    getReadOnlyZonePropData(zoneConfigData: WallControlConfigZone, zoneIndex: number, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        const { id: zoneId, zoneName, ...displayedZoneProps } = zoneConfigData;

        return Object.entries(displayedZoneProps)
            .reduce((acc, [key, value]) => {
                const propData: Omit<ConfigProp, 'options'> = {
                    id: key,
                    readOnly: true,
                    value: this.formatValue(key, value, tempUnitFormat)
                };

                const zoneNameFallback = this.getZoneNameFallback(zoneIndex);

                acc[key] = {
                    ...propData,
                    zoneName: zoneName || zoneNameFallback
                };

                return acc;
            }, {} as Record<string, ConfigEditZoneProp>);
    }

    mergeConfigData(configData: ConfigData, staticConfigEditParams: StaticConfigEditParams, tempUnitFormat = TempUnitFormat.Fahrenheit): Record<string, ConfigEditProp> {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        const mergedData = Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                let propData: ConfigProp = {
                    id: key,
                    readOnly: true,
                    value
                };

                // Check to see if this property has an entry in the editable list
                const staticConfigPropParams = staticConfigEditParams[key];

                if (staticConfigPropParams) {
                    propData = this.getEditParamPropData(key, value, propData, staticConfigPropParams, dynamicConfigEditParams, tempUnitFormat);
                }
                else {
                    // If the value is not editable we format it for display
                    propData.value = this.formatValue(key, value, tempUnitFormat);
                }

                const { options, ...rest } = propData;
                const out: ConfigEditProp = rest;

                if (options) {
                    let unitSuffix = '';

                    if (DEVICE_TEMPERATURE_PROPERTIES.includes(propData.id)) {
                        unitSuffix = tempUnitFormat === TempUnitFormat.Fahrenheit
                            ? CharSymbol.Fahrenheit
                            : CharSymbol.Celsius;
                    }
                    if (DEVICE_PERCENT_PROPERTIES.includes(propData.id)) {
                        unitSuffix = Unit.Percent;
                    }

                    out.options = this.formatSelectOptions(options, unitSuffix);
                }

                acc[key] = out;

                return acc;
            }, {} as Record<string, ConfigEditProp>);


        return mergedData;
    }

    mergeEltConfigData(configData: ConfigData, staticConfigEditParams: StaticConfigEditParams, tempUnitFormat = TempUnitFormat.Fahrenheit): Record<string, ConfigEditProp> {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        const mergedData = Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                let propData: ConfigProp = {
                    id: key,
                    readOnly: true,
                    value
                };

                // Check to see if this property has an entry in the editable list
                const staticConfigPropParams = staticConfigEditParams[key];

                if (staticConfigPropParams) {
                    propData = this.getEditParamPropData(key, value, propData, staticConfigPropParams, dynamicConfigEditParams, tempUnitFormat);
                }
                else {
                    // If the value is not editable we format it for display
                    propData.value = this.formatEltPropValue(key, value, tempUnitFormat);
                }

                const { options, ...rest } = propData;
                const out: ConfigEditProp = rest;

                if (options) {
                    let unitSuffix = '';

                    if (DEVICE_TEMPERATURE_PROPERTIES.includes(propData.id)) {
                        unitSuffix = tempUnitFormat === TempUnitFormat.Fahrenheit
                            ? CharSymbol.Fahrenheit
                            : CharSymbol.Celsius;
                    }
                    if (DEVICE_PERCENT_PROPERTIES.includes(propData.id)) {
                        unitSuffix = Unit.Percent;
                    }

                    out.options = this.formatSelectOptions(options, unitSuffix);
                }

                acc[key] = out;

                return acc;
            }, {} as Record<string, ConfigEditProp>);


        return mergedData;
    }

    mergeEcobeeConfigData(configData: ConfigData, staticConfigEditParams: StaticConfigEditParams, tempUnitFormat = TempUnitFormat.Fahrenheit): Record<string, ConfigEditProp> {
        const { configEditParams: dynamicConfigEditParams, ...configDataProps } = configData;

        const mergedData = Object.entries(configDataProps)
            .reduce((acc, [key, value]) => {
                let propData: ConfigProp = {
                    id: this.getEcobeeMappingKey(key as EcobeeWallControlConfigParamMapping),
                    readOnly: true,
                    value,
                    ...({
                        settingsType: this.getEcobeeSettingsType(key as EcobeeWallControlConfigParamMapping),
                        displayOrder: this.getEcobeeDisplayOrder(key as EcobeeWallControlConfigParamMapping)
                    })
                };

                // Check to see if this property has an entry in the editable list
                const staticConfigPropParams = staticConfigEditParams[key];

                if (staticConfigPropParams) {
                    const paramData = this.getEditParamPropData(key, value, propData, staticConfigPropParams, dynamicConfigEditParams);
                    const isValid = this.getIsValidEcobeeConfigValue(key, value, paramData);

                    if (isValid) {
                        propData = paramData;
                    }
                    else {
                        propData.value = CharSymbol.DoubleDash;
                        this.ecobeeConfigOutOfRange$.next(true);
                    }
                }
                else {
                    // If the value is not editable we format it for display
                    propData.value = this.formatValue(key, value, tempUnitFormat);
                }

                const { options, ...rest } = propData;
                const out: ConfigEditProp = rest;

                if (options) {
                    let unitSuffix = '';

                    if (DEVICE_TEMPERATURE_PROPERTIES.includes(key)) {
                        unitSuffix = tempUnitFormat === TempUnitFormat.Fahrenheit
                            ? CharSymbol.Fahrenheit
                            : CharSymbol.Celsius;
                    }

                    out.options = this.formatSelectOptions(options, unitSuffix);
                }

                acc[key] = out;

                return acc;
            }, {} as Record<string, ConfigEditProp>);

        return mergedData;
    }

    mergeConfigZoneData(
        configZoneDataProps: WallControlConfigZone,
        dynamicConfigEditParams: WallControlConfigEditParams,
        staticConfigEditParams: WallControlStaticEditParams,
        zoneIndex: number
    ): Record<string, ConfigEditZoneProp> {
        const { id: zoneId, zoneName, ...displayedZoneProps } = configZoneDataProps;

        const mergedData = Object.entries(displayedZoneProps)
            .reduce((acc, [key, value]) => {
                let propData: ConfigProp = {
                    id: key,
                    groupId: zoneId,
                    readOnly: true,
                    value
                };

                const staticZoneConfig = staticConfigEditParams['zones'] as unknown as WallControlStaticEditParams;

                if (staticZoneConfig) {
                    const staticConfigPropParams = staticZoneConfig[key];

                    if (staticConfigPropParams) {
                        propData = this.getEditParamPropData(key, value, propData, staticConfigPropParams, dynamicConfigEditParams);
                    }
                }

                const zoneNameFallback = this.getZoneNameFallback(zoneIndex);
                const { options, ...rest } = propData;
                const out: ConfigEditZoneProp = {
                    ...rest,
                    zoneName: zoneName || zoneNameFallback
                };

                if (options) {
                    out.options = this.formatSelectOptions(options);
                }

                acc[key] = out;

                return acc;
            }, {} as Record<string, ConfigEditZoneProp>);

        return mergedData;
    }

    getEditParamPropData(
        propName: string,
        propValue: string | null,
        propData: ConfigProp,
        staticConfigPropParams: ConnectedPortalUIConfigProp,
        dynamicConfigEditParams: ConfigData['configEditParams'],
        tempUnitFormat = TempUnitFormat.Fahrenheit
    ): ConfigProp {
        const { useApiEdit, useApiOptions, options: staticOptions, unitOptions: staticUnitOptions, ...restParams } = staticConfigPropParams;
        const control = this.getControl(propValue);

        let tempPropData = { ...propData };

        // Default to readOnly false or all props in the static config,
        // and override with apiPropParams if a, readOnly value is provided.
        tempPropData.readOnly = false;

        if (staticUnitOptions) {
            tempPropData.options = this.getOptionValues(staticUnitOptions[tempUnitFormat]);
        }
        else if (staticOptions) {
            tempPropData.options = this.getOptionValues(staticOptions);
        }
        else if (isFinite(Number(restParams.min)) && restParams.max && restParams.resolution) {
            tempPropData.options = this.getOptionValues({
                max: restParams.max,
                min: restParams.min!,
                resolution: restParams.resolution
            });
        }

        if (useApiOptions || useApiEdit) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const apiPropParams = (dynamicConfigEditParams as any)[propName] as ControlConfigPropReadOnly | ControlConfigPropReadOnlyAndOptions;

            const { readOnly } = apiPropParams;

            // Options and UnitOptions values may be an array of string values
            // or a set of min, max and resolution values that need to be expanded into a list of string values
            if ('unitOptions' in apiPropParams && apiPropParams.unitOptions) {
                const options = apiPropParams.unitOptions[tempUnitFormat];

                tempPropData.options = Array.isArray(options)
                    ? options
                    : this.getValuesFromNumConfig(options.min, options.max, options.resolution);
            }
            else if ('options' in apiPropParams && apiPropParams.options) {
                const { options } = apiPropParams;

                tempPropData.options = Array.isArray(options)
                    ? options
                    : this.getValuesFromNumConfig(options.min, options.max, options.resolution);
            }

            const data = {
                ...tempPropData,
                ...restParams,
                readOnly
            };

            if (data.readOnly === false) {
                data.control = control;
            }
            else {
                data.value = this.formatValue(propName, propValue, tempUnitFormat);
            }

            tempPropData = data;
        }

        const data = {
            ...restParams,
            ...tempPropData,
            control
        };

        return data;
    }

    getValuesFromFormGroup(formGroup: UntypedFormGroup) {
        return Object.entries(formGroup.value)
            .reduce((acc, [key, formValue]) => {
                // Select components return an array of values
                if (Array.isArray(formValue)) {
                    acc[key] = formValue[0].value;
                }
                else {
                    acc[key] = formValue;
                }

                return acc;
            }, {} as {[key: string]: unknown});
    }

    private getOptionValues(configOptions: string[] | UIConfigOptionNumConfig) {
        return Array.isArray(configOptions)
            ? configOptions
            : this.getValuesFromNumConfig(configOptions.min, configOptions.max, configOptions.resolution);
    }

    private getValuesFromNumConfig(min: number, max: number, resolution: number): string[] {
        const stepCount = (max - min) / resolution + 1;
        const numOptions = [...new Array(stepCount).keys()].map(
            (_key, index) => (index * resolution + min).toString()
        );

        return numOptions;
    }

    private getIsValidEcobeeConfigValue = (propName: string, value: string | null, paramData: ConfigProp): boolean => {
        const isValueCheckRequired = ECOBEE_CONFIG_RANGE_PROPERTIES.includes(propName);

        if (isValueCheckRequired) {
            const tempData = paramData;
            const { options } = tempData;

            if (options && value) {
                const isValidValue = options.includes(value);

                if (isValidValue) {
                    return true;
                }

                return false;
            }
        }

        return true;
    };

    private getControl(value: unknown) {
        return new UntypedFormControl(value, {
            nonNullable: true,
            validators: Validators.required
        });
    }

    private formatSelectOptions(options: string[], unitSuffix?: string): Option[] {
        return options.map((option) => {
            let optionLabel = option;

            // Special handling for a set of Ecobee specific property values
            if (ECOBEE_TRANSLATION_VALUE_KEYS.includes(option)) {
                const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_PROP_VALUES.${option}`;

                optionLabel = this.translate.instant(translationKey);
            }

            // Disable the unknown values from Ecobee options
            const isDisabled = (ECOBEE_UNKNOWN_VALUES.includes(option));

            if (unitSuffix && isFinite(Number(option))) {
                optionLabel += unitSuffix;
            }

            return {
                name: optionLabel,
                value: option,
                disabled: isDisabled
            };
        });
    }

    private formatValue(key: string, value: unknown, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        // Special handling for a set of Ecobee specific property values
        if (ECOBEE_TRANSLATION_VALUE_KEYS.includes(String(value))) {
            const translationKey = `CONNECTED_PORTAL.WALL_CONTROL.ECOBEE_PROP_VALUES.${value}`;

            return this.translate.instant(translationKey);
        }

        const ecobeePropertyRange = ECOBEE_CONFIG_READ_ONLY_PROPERTY_RANGES[key as keyof typeof ECOBEE_CONFIG_READ_ONLY_PROPERTY_RANGES];

        if (ecobeePropertyRange) {
            if (isValueOutOfRange(Number(value), ecobeePropertyRange)) {
                return CharSymbol.DoubleDash;
            }
        }

        if (DEVICE_TEMPERATURE_PROPERTIES.includes(key)) {
            const unit = tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit;

            return this.numberSuffixPipe.transform(String(value), unit);
        }

        switch (key) {
            case 'dateTime':
                return typeof value === 'string' && this.dateTimeTzPipe.transform(value);
            case 'fanRuntimeMin':
                return this.numberSuffixPipe.transform(String(value), ' min/hr');
            default:
                return value;
        }
    }

    private formatEltPropValue(key: string, value: unknown, tempUnitFormat = TempUnitFormat.Fahrenheit) {
        if (DEVICE_TEMPERATURE_PROPERTIES.includes(key)) {
            const unit = tempUnitFormat === TempUnitFormat.Celsius ? CharSymbol.Celsius : CharSymbol.Fahrenheit;

            return this.numberSuffixPipe.transform(String(value), unit);
        }

        switch (key) {
            case 'dateTime':
                return typeof value === 'string' && this.dateTimeTzPipe.transform(value);
            case 'fanRuntimeMin':
                return this.numberSuffixPipe.transform(String(value), ' min/hr');
            default:
                return value;
        }
    }

    private getZoneNameFallback(zoneIndex: number) {
        return `${this.translate.instant('CONNECTED_PORTAL.WALL_CONTROL.ZONE_NAME_DEFAULT')}: ${zoneIndex + 1}`;
    }

    private getEcobeeMappingKey(setKey: string): string {
        const mappingKey = Object.entries(EcobeeWallControlConfigParamMapping).find(([key, val]) => key && val === setKey)?.[0];

        return mappingKey ? mappingKey : setKey;
    }

    private getEcobeeSettingsType(setKey: string) {
        const mappingKey = Object.entries(EcobeeWallControlConfigParamMapping).find(([key, val]) => key && val === setKey)?.[0];

        return mappingKey ? EcobeeWallControlConfigSettingsType[mappingKey as keyof typeof EcobeeWallControlConfigSettingsType] : 'basic';
    }

    private getEcobeeDisplayOrder(setKey: string) {
        const mappingKey = Object.entries(EcobeeWallControlConfigParamMapping).find(([key, val]) => key && val === setKey)?.[0];

        return mappingKey ? EcobeeWallControlConfigDisplayOrder[mappingKey as keyof typeof EcobeeWallControlConfigDisplayOrder] : -1;
    }
}
