import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { API_RESPONSE } from 'common/app-constants';
import { BaseComponent } from 'common/components/base/base.component';
import { ConnectedProductUpdate, ConnectedProductUpdateResponse } from 'private/app/models/connected-product.model';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { delay, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'hvac-update-product-details-modal',
    templateUrl: './update-product-details-modal.component.html',
    styleUrls: ['./update-product-details-modal.component.scss']
})
export class UpdateProductDetailsModalComponent extends BaseComponent implements OnInit {
    @Input() currentValues: ConnectedProductUpdate;
    @Output() closeAction = new EventEmitter<ConnectedProductUpdateResponse>()
    @Output() showLoader = new EventEmitter();
    serialNumberErrMsg$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    modelNumberErrMsg$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public productDetailsUpdateForm: UntypedFormGroup;

    constructor(private productService: ProductService, private translate: TranslateService) {
        super();
    }

    ngOnInit(): void {
        const currSerialNumber = this.currentValues?.data.serialNo || '';
        const currModelNumber = this.currentValues?.data.modelNo || '';

        this.productDetailsUpdateForm = new UntypedFormGroup({
            serialNumber: new UntypedFormControl(currSerialNumber, [Validators.maxLength(25), Validators.pattern('[a-zA-Z0-9-]+')]),
            modelNumber: new UntypedFormControl(currModelNumber, [Validators.maxLength(25), Validators.pattern('[a-zA-Z0-9-]+')])
        }, this.oneFieldRequiredValidator);

        this.productDetailsUpdateForm.valueChanges.subscribe(() => {
            const serialNumber = this.productDetailsUpdateForm.get('serialNumber');
            if (serialNumber) {
                const serialNumberErros = this.getTranslatedErrors(serialNumber);
                this.serialNumberErrMsg$.next(serialNumberErros);
            }

            const modelNumber = this.productDetailsUpdateForm.get('modelNumber');
            if (modelNumber) {
                const modelNumberErros = this.getTranslatedErrors(modelNumber);
                this.modelNumberErrMsg$.next(modelNumberErros);
            }
        });
    }

    public updateProductDetails() {
        const serialNo = this.productDetailsUpdateForm.controls.serialNumber.value;
        const modelNo = this.productDetailsUpdateForm.controls.modelNumber.value;

        const dataToUpdate = {
            ...this.currentValues,
            ...{
                data: {
                    serialNo,
                    modelNo
                }
            }
        };

        this.showLoader.emit();
        this.productService.updateProductInfoBySystemId(dataToUpdate)
            .pipe(takeUntil(this.ngOnDestroy$))
            .pipe(delay(5000))
            .subscribe((responsedata) => {
                if (responsedata) {
                    const { code } = responsedata;

                    if (code === API_RESPONSE.SUCCESS) {
                        this.closeAction.emit({
                            data: dataToUpdate,
                            status: 'success'
                        });
                    }
                    else {
                        this.closeAction.emit({ status: 'error' });
                    }
                }
            }, this.handleApiError.bind(this));
    }

    public cancelUpdate() {
        this.closeAction.emit();
    }

    private handleApiError() {
        this.closeAction.emit({ status: 'error' });
    }

    private getTranslatedErrors(ctrl: AbstractControl): string {
        const translations = {
            required: this.translate.instant('GENERAL.ERROR_MESSAGES.REQUIRED') as string,
            pattern: this.translate.instant('GENERAL.ERROR_MESSAGES.ALPHANUMERIC_WITH_DASH') as string,
            maxlength: this.translate.instant('GENERAL.ERROR_MESSAGES.MAXLENGTH_25') as string
        };
        const errorList = Object.keys(ctrl.errors || {})
            .map((item: string) => (<Record<string, string>>translations)[item])
            .join('<br>');

        return errorList;
    }

    private oneFieldRequiredValidator(control: AbstractControl): {[key: string]: boolean} | null {
        if (control?.value?.serialNumber === '' && control?.value?.modelNumber === '') {
            return { required: true };
        }

        return null;
    }
}
