<div class="hvac-cp-update-product-details-container">
    <div class="hvac-cp-product-details-section">
        <div class="hvac-cp-product-details">
            <div class="hvac-cp-product-details-label">{{'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.MODEL_NUMBER' | translate}}
            </div>
            <utc-input
                [inputControl]="productDetailsUpdateForm.controls.modelNumber"
                inputId="update-modelNumber-input"
                [error]="productDetailsUpdateForm.controls.modelNumber.dirty && !productDetailsUpdateForm.controls.modelNumber.valid"
                [errorMessage]="modelNumberErrMsg$ | async"
            >
            </utc-input>
            <div class="hvac-cp-product-details-help-txt">{{'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.MODEL_NUMBER_EXAMPLE' | translate}}</div>
        </div>
        <div class="hvac-cp-product-details">
            <div class="hvac-cp-product-details-label">{{'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.SERIAL_NUMBER' | translate}}
            </div>
            <utc-input
                [inputControl]="productDetailsUpdateForm.controls.serialNumber"
                inputId="update-serialNumber-input"
                [error]="productDetailsUpdateForm.controls.serialNumber.dirty && !productDetailsUpdateForm.controls.serialNumber.valid"
                [errorMessage]="serialNumberErrMsg$ | async"
            >
            </utc-input>
            <div class="hvac-cp-product-details-help-txt">{{'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.SERIAL_NUMBER_EXAMPLE' | translate}}</div>
        </div>

    </div>
    <div class="hvac-cp-product-details-action-section">
        <button class="hvac-button hvac-button-small" (click)="cancelUpdate()"
                    hvacButton="default">{{
                    'CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.ACTIONS.CANCEL' | translate }}</button>
        <button (click)="updateProductDetails()"
            hvacbutton="primary"
            class="hvac-button hvac-button-primary hvac-button-small"
            buttonType="button"
            [disabled] = "productDetailsUpdateForm.invalid"
        >
            <span>{{"CONNECTED_PORTAL.UPDATE_PRODUCT_DETAILS.ACTIONS.UPDATE" | translate}}</span>
        </button>
    </div>
</div>