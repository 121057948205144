import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { ErrorService } from 'common/content/services/error.service';
import { CharSymbol } from 'common/enums/char-symbol';
import { AlertsData, InfinityProductDetailsData, ProductType, StatusDataProps, SystemType } from 'private/app/models/connected-product.model';
import { ApiProtocol, ConnectedSystem } from 'private/app/models/connected-system.model';
import { IduStatus } from 'private/app/models/idu.model';
import { WallControlStatus } from 'private/app/models/wall-control.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { IduService } from 'private/app/services/connected-portal/idu.service';
import { MqttActivationManagerService } from 'private/app/services/connected-portal/mqtt-activation-manager.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { MISSING_PRODUCT_DATA, PRODUCT_ALERTS_PAGE_SIZE } from 'private/app/views/connected-portal/constants';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../product-detail-context.service';

@Component({
    selector: 'hvac-infinity-idu-detail',
    templateUrl: './infinity-idu-detail.component.html',
    styleUrls: ['./infinity-idu-detail.component.scss']
})
export class InfinityIduDetailComponent extends BaseComponent implements OnInit {
    @Input() dealerId: string;
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;

    public readonly CharSymbol = CharSymbol;
    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public productData$: Observable<InfinityProductDetailsData>;
    public iduStatusData$: Observable<IduStatus>;
    public iduStatusProps$: Observable<StatusDataProps>;
    public historicalData$: Observable<[string, string][]>;
    public wallControlStatus$: Observable<WallControlStatus>;
    public alertsData$: Observable<AlertsData>;
    public alertsCurrentPage$ = new BehaviorSubject<number>(1);
    public toggleAccordion$ = new Subject<keyof typeof this.accordions$.value>();
    public accordions$ = new BehaviorSubject({
        alerts: true,
        configuration: false,
        historicalData: false,
        status: false
    });

    private apiProtocol?: ApiProtocol;

    constructor(
        public contextService: ProductDetailContextService,
        private iduService: IduService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private alertService: AlertsService,
        private mqttActivationManager: MqttActivationManagerService,
        private errorService: ErrorService
    ) {
        super();
    }

    ngOnInit() {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.wallControlStatus$ = this.productService
            .queryWallControlStatusBySerialNo(this.serialNo, this.systemType, this.dealerId)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false));

        this.iduStatusData$ = this.iduService.queryIduStatus(this.serialNo, this.dealerId, this.systemType)
            .pipe(
                map((res) => res.data),
                shareReplay()
            );

        this.iduStatusProps$ = this.iduStatusData$.pipe(
            map((data): StatusDataProps => {
                const { latestAirflowVerification, ...otherStatusProps } = data;

                const statusProps = this.productService.formatData(Object.entries(otherStatusProps));
                const airflowVerificationProps = latestAirflowVerification
                    ? this.productService.formatData(Object.entries(latestAirflowVerification))
                    : null;

                return {
                    statusProps,
                    airflowVerificationProps
                };
            })
        );

        this.productData$ = combineLatest([
            this.iduStatusData$,
            this.isWallControlConnected$,
            this.system$
        ]).pipe(
            map(([iduStatusData, isWallControlConnected, system]) => {
                const productData = system?.products.find((product) => product.type === ProductType.IndoorUnit);

                if (productData) {
                    return {
                        isWallControlConnected,
                        productInfo: {
                            ...productData,
                            stage: iduStatusData.stage,
                            dateTime: iduStatusData.dateTime
                        }
                    };
                }

                throw new Error(MISSING_PRODUCT_DATA);
            }),
            catchError((err) => {
                this.errorService.errorStatus$.next(404);

                throw err;
            })
        );

        this.alertsData$ = this.alertsCurrentPage$
            .pipe(
                switchMap((currentPage) => this.alertService.queryAlertsBySerialNo(this.serialNo, 'indoor unit', this.systemType, {
                    pagination: {
                        currentPage,
                        pageSize: PRODUCT_ALERTS_PAGE_SIZE
                    },
                    sort: {
                        field: 'dateTime',
                        order: 'desc'
                    }
                })
                    .pipe(map(({ data, totalPages }): AlertsData => ({
                        items: data,
                        totalPages,
                        currentPage
                    }))))
            );

        this.historicalData$ = this.iduService.getIduHistoricalData(this.serialNo, this.dealerId)
            .pipe(map((res) => this.productService.formatData(Object.entries(res.data))));

        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);

                    if (system.apiProtocol === ApiProtocol.MQTT) {
                        this.mqttActivationManager.addControl(this.serialNo, this.dealerId);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();

        if (this.apiProtocol === ApiProtocol.MQTT && this.serialNo) {
            this.mqttActivationManager.removeControl(this.serialNo);
        }

        super.ngOnDestroy();
    }
}
