import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { SectionSelectOption } from 'common/components/section-select/section-select.component';
import { Option } from 'common/components/select/select.component';
import { User } from 'common/models';
// import { UserEntity } from 'private/app/models/user.model';
import { AuthState } from 'common/store/auth/auth.state';
import { Observable } from 'rxjs';
// import { IdmService } from 'private/app/services/idm.service';

@Component({
    selector: 'app-profile-landing',
    templateUrl: './profile-landing.component.html',
    styleUrls: ['./profile-landing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileLandingComponent implements OnInit {
    @Select(AuthState.user) user$: Observable<User>;

    public name: string;

    public selectPage: UntypedFormGroup = new UntypedFormGroup({ page: new UntypedFormControl(null) });

    public options: Option[] = [
        {
            name: this.translateService.instant('PROFILE.PROFILE'),
            value: 'profile'
        },
        {
            name: this.translateService.instant('PROFILE.SUBSCRIPTIONS'),
            value: 'manage-subscriptions'
        },
        {
            name: this.translateService.instant('PROFILE.ROLES'),
            value: '/Pages/MyRoles.aspx'
        },
        // Should only show if user is a dealer: See: #1008
        {
            name: this.translateService.instant('PROFILE.COMPANY_INFO'),
            value: 'company-info'
        }
    ];

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translateService: TranslateService
        // private readonly idmService: IdmService

    ) {
        this.selectPage.controls.page.setValue([this.options.find((option: Option) => this.route.snapshot.firstChild?.routeConfig?.path === option.value)]);
    }

    ngOnInit(): void {
        this.user$.subscribe((res: User) => {
            if (res) {
                this.name = `${res.profile.firstName} ${res.profile.lastName}`;
            }
        });

        // this.user$.subscribe((user: User) => {
        //     if (user) {
        //         this.idmService.getUser(user.profile.login).subscribe((res: UserEntity) => {
        //             this.idmService.userInfo$.next(res);
        //         });
        //     }
        // });

        this.selectPage.controls.page.valueChanges.subscribe((page: SectionSelectOption[]) => {
            this.router.navigate([page[0].value], { relativeTo: this.route });
        });
    }
}
