<section class="hvac-highlights">
    <div *ngFor="let highlight of highlights; first as isFirst; last as isLast" class="hvac-highlights-highlight"
        [ngClass]="{ 'hvac-highlights-highlight-wide': (isLast && isFirst),
        'hide': !highlight?.Content?.list.$values.length  }">
        <ng-container *ngIf="isFirst && isLast; else multipleHighlights">
            <hvac-highlight-section [data]="highlight" [fullWidth]="isLast && isFirst"
                [serviceBulletins]="serviceBulletins"></hvac-highlight-section>
        </ng-container>

        <ng-template #multipleHighlights>
            <hvac-highlight-section [data]="highlight"></hvac-highlight-section>
        </ng-template>
    </div>
    <div class="hvac-highlights-bulletins" *ngIf="highlights.length !== 1 && serviceBulletins">
        <hvac-bulletins [bulletins]="serviceBulletins"></hvac-bulletins>
    </div>
</section>
