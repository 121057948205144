import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProductType } from 'private/app/models/connected-product.model';
export interface SystemOverviewSearchCriteria {
    unitType: string;
    serialNo: string;
}

@Component({
    selector: 'hvac-factory-system-search',
    templateUrl: './factory-system-search.component.html',
    styleUrls: ['./factory-system-search.component.scss']
})
export class FactorySystemSearchComponent implements OnInit {
  @Input() errorMsg?: string | null;
  @Output() searchResultEvent = new EventEmitter<SystemOverviewSearchCriteria>();
  @Output() searchReset = new EventEmitter<true>();

  public queryControl = new UntypedFormControl('', Validators.required);
  public selectedFilterOptions: string;
  public readonly ProductType = ProductType;

  ngOnInit(): void {
      this.selectedFilterOptions = ProductType.WallControl;
  }

  filterOption(type:string) {
      this.selectedFilterOptions = type;
  }

  handleSearchSubmit() {
      this.searchResultEvent.emit({
          serialNo: this.queryControl.value.trim(),
          unitType: this.selectedFilterOptions
      });
  }
}
