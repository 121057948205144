<hvac-private-header *ngIf="isLoggedIn$ | async"></hvac-private-header>

<navigation-container *ngIf="isLoggedIn$ | async"></navigation-container>

<hvac-bookmarks-slide></hvac-bookmarks-slide>

<div class="hvac-app-full-page-loader-wrap" *ngIf="(contentLoading$ | async) || (dealerAccessCheckLoading$ | async)">
    <utc-full-page-loader></utc-full-page-loader>
</div>
<div class="hvac-app-content-wrap">
    <hvac-toast></hvac-toast>

    <div *ngIf="!(errorService.errorStatus$ | async)">
        <router-outlet></router-outlet>
    </div>

    <error-page *ngIf="!!(errorService.errorStatus$ | async)" [status]="errorService.errorStatus$.value">
    </error-page>

</div>

<app-user-validation *ngIf="(isUserValidation$ | async) && (isLoggedIn$ | async)"></app-user-validation>

<hvac-private-footer [ngClass]="{'hvac-hide-footer': !showFooter}"></hvac-private-footer>

<hvac-chat-bot *ngIf="(isLoggedIn$ | async) && (canShowChatBot$ | async) && allowChatbot"></hvac-chat-bot>
