import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WarrantyInfo } from '../../../../../../../../common/models/product-warranty';
import { ProductWarrantyService } from '../../../../../../../../common/services/product-warranty.service';

@Component({
    selector: 'hvac-warranty-details',
    templateUrl: './warranty-details.component.html',
    styleUrls: ['./warranty-details.component.scss']
})
export class WarrantyDetailsComponent implements OnInit {
    @Input() serialNumber: string;
    warrantyInfo: WarrantyInfo;
    isLoading = false;
    ngOnDestroy$ = new Subject();
    constructor(private productWarrantyService: ProductWarrantyService) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.productWarrantyService
            .getWarrantyEntitlements(this.serialNumber)
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((data: WarrantyInfo) => {
                this.warrantyInfo = data;
                this.isLoading = false;
            });
    }
}
