import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { ManageDealerLocatorService } from 'private/app/services/manage-dealer-locator.service';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DealerLocatorEnrollmentList, Pagination, ZipCodes } from 'private/app/models/manage-dealer-locator';
import { AssignedZipCodes, ChildTable } from './zipcode-table/zipcode-table.component';
import { ToastService } from 'common/services/toast.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-zipcode-assignment',
    templateUrl: './zipcode-assignment.component.html',
    styleUrls: ['./zipcode-assignment.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ZipcodeAssignmentComponent {
    public dealerLocatorListData: DealerLocatorEnrollmentList = {
        dealerLocatorEnrollments: [],
        availableZipCodes: [],
        assignedZipCodes: [],
        locatorRatings: [],
        companyId: '',
        isFADEnabled: false
    };

    public availableZipCodeArray: ZipCodes[] = [];
    public assignedZipCodeArray: ZipCodes[] = [];
    public availableZipcodeTable = 'availableZipcodeTable';
    public assignedZipcodeTable = 'assignedZipcodeTable';
    public loading: boolean;
    public showAssignedZipCode = false;

    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.SOURCE'),
            value: 'source',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.ID'),
            value: 'id',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.URL'),
            value: 'url',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.THIRD_PARTY_RATINGS.ACTION'),
            value: 'action',
            order: null
        }
    ];

    currentPage: number = 1;
    pageSize: number = 10;
    public paginationObj: Pagination = {
        assignedZipcode: {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            totalPages: 0
        },
        availableZipcode: {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            totalPages: 0
        }
    };

    private ngOnDestroy$ = new Subject();
    private hvacpCompanyId: string;
    private selectedZipCodes: ZipCodes[];
    constructor(
        private translate: TranslateService,
        private dealerLocatorService: ManageDealerLocatorService,
        private router: Router,
        private toastService: ToastService
    ) {
        this.router.events.pipe(takeUntil(this.ngOnDestroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const urlSplit = event.urlAfterRedirects.split('/') || [];
                this.hvacpCompanyId = urlSplit.length ? urlSplit[urlSplit.length - 2] : '';
            }
        });
    }

    ngOnInit() {
        this.dealerLocatorService.data$.pipe(skipWhile((response) => !response.companyId), takeUntil(this.ngOnDestroy$)).subscribe((data) => {
            this.dealerLocatorListData = data;
            this.availableZipCodeArray = this.dealerLocatorListData.availableZipCodes || [];
            this.assignedZipCodeArray = this.dealerLocatorListData.assignedZipCodes || [];
            this.updateTotalPage();
        });
    }

    updateTotalPage() {
        if (this.assignedZipCodeArray) {
            this.paginationObj.assignedZipcode.totalPages = Math.ceil(this.assignedZipCodeArray.length / this.pageSize);
        }
        if (this.availableZipCodeArray) {
            this.paginationObj.availableZipcode.totalPages = Math.ceil(this.availableZipCodeArray.length / this.pageSize);
        }
    }

    toggleMenu(value: string) {
        if (value === 'assign') {
            const selectedZipCodes = this.availableZipCodeArray?.filter((zipCode) => (zipCode.isSelected));
            const filteredArray = this.availableZipCodeArray?.filter((zipCode) => (!zipCode.isSelected));
            this.availableZipCodeArray = filteredArray;
            const filteredDataArray = selectedZipCodes?.map((zipCode: ZipCodes) => {
                zipCode.isSelected = !zipCode.isSelected;
                zipCode.isAssigned = true;

                return zipCode;
            });
            this.assignedZipCodeArray = this.assignedZipCodeArray.concat(filteredDataArray!);
        }
        else {
            const selectedZipCodes = this.assignedZipCodeArray?.filter((zipCode) => (zipCode.isSelected));
            const filteredArray = this.assignedZipCodeArray?.filter((zipCode) => (!zipCode.isSelected));
            this.assignedZipCodeArray = filteredArray.length ? filteredArray : [];
            const filteredDataArray = selectedZipCodes?.map((zipCode: ZipCodes) => {
                zipCode.isSelected = !zipCode.isSelected;
                zipCode.isAssigned = false;

                return zipCode;
            });
            this.availableZipCodeArray = this.availableZipCodeArray?.concat(filteredDataArray!);
        }
        this.updateTotalPage();
    }

    onSelectAllCheckboxSelection(value: ChildTable) {
        if (value.tableType === 'availableZipcodeTable') {
            this.availableZipCodeArray?.map((zipCode: ZipCodes) => {
                zipCode.isSelected = value.isSelected;

                return zipCode;
            });
        }
        else {
            this.assignedZipCodeArray?.map((zipCode: ZipCodes) => {
                zipCode.isSelected = value.isSelected;

                return zipCode;
            });
        }
    }

    toggleTable(tableType: string) {
        this.showAssignedZipCode = (tableType === this.assignedZipcodeTable);
    }

    onSelectedZipCodes(assignedZipCode: AssignedZipCodes) {
        this.selectedZipCodes = assignedZipCode.zipCodes;
    }

    saveAction() {
        this.assignedZipCodeArray = Array.from(this.assignedZipCodeArray.concat(this.selectedZipCodes).reduce((objA, objB) => objA.set(objB.zipCodeId, objB), new Map()).values());
        const body = this.dealerLocatorService.getAssignedZipCodePostBody(this.assignedZipCodeArray, this.dealerLocatorListData);
        this.loading = true;
        this.dealerLocatorService.postDealerEnrollmentData(body).subscribe((data) => {
            this.refreshDealerData();
            this.loading = false;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            if (data.status === 'Success') {
                this.toastService.add({
                    content: this.translate.instant('ACCOUNT_ADMIN.MANAGE_DEALER_LOCATOR.DEALER_LOCATOR_POST_SUCCESS_MESSAGE'),
                    theme: 'success',
                    id: 'DEALER_LOCATOR_POST_SUCCESS_MESSAGE',
                    closeable: true,
                    autoClose: true
                });
            }
        });
    }

    refreshDealerData() {
        this.dealerLocatorService.getEnrollments(this.hvacpCompanyId).subscribe((data) => this.dealerLocatorService.data$.next(data));
    }

    public cancelAction() {
        this.dealerLocatorService.backAction.next(true);
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
