<div class="hvac-cp-queue-control-modal-container">
    <utc-modal modal
        [visible]="isModalVisible"
        (closeAction)="handleCloseModal()"
        size="small"
        theme="rounded"
        class="hvac-cp-remove-queue-control-modal">
        
        <hvac-modal-header>
                <span class="hvac-h3">{{ heading }}</span>
        </hvac-modal-header>
        
        <div class="hvac-cp-remove-queue-control-modal-message">
            {{ message }}
        </div>
        <div class="hvac-cp-remove-queue-control-modal-actions">
            <button hvacButton="default" [disabled]="false" (click)="handleCloseModal()" size="small">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.CONFIRM_MODAL.ACTIONS.CANCEL' | translate }}</button>
            <button hvacButton="primary" [disabled]="false" (click)="handleConfirmAction()" size="small">{{ 'CONNECTED_PORTAL.CUSTOMERS.QUEUED_LIST.CONFIRM_MODAL.ACTIONS.REMOVE' | translate }}</button>
        </div>
    </utc-modal>
</div>

