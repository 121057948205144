import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectedPortalUIConfig } from 'private/app/models/connected-portal-ui-config.model';
import { environment } from 'common/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { Mocks } from 'common/models';
import { Observable, of } from 'rxjs';

type MockApis = keyof Mocks['connectedPortal']['rest']['apis'];

@Injectable({ providedIn: 'root' })
export class UIConfigApiService {
    private configData$: Observable<ConnectedPortalUIConfig> | null = null;

    constructor(
        private readonly httpClient: HttpClient
    ) { }

    public getConnectedPortalUIConfig(): Observable<ConnectedPortalUIConfig> {
        if (this.configData$) {
            return this.configData$;
        }

        return this.httpClient.get<ConnectedPortalUIConfig>(this.getEndpoint('ui/config')).pipe(
            tap((res) => {
                this.configData$ = of(res);
            }),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    getEndpoint(apiId: MockApis) {
        const url = environment.api.connectedPortal[apiId] as string;
        const portalMocks = environment.mocks.connectedPortal;

        if (portalMocks?.rest?.apis[apiId]) {
            const mockBase = portalMocks.rest.url;
            const mockUrl = `${mockBase}/${apiId}`;

            return mockUrl;
        }

        return url;
    }
}
