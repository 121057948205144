import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataHeader } from 'private/app/models/account-admin-search';
import { ZipCodes } from 'private/app/models/manage-dealer-locator';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

export interface ZipCodeData {
    brand: string;
    county: string;
    city: string;
    state: string;
    zipCode: string;
}

export interface ChildTable {
    isSelected: boolean;
    tableType: string;
}

export interface FilterQuery {
    value: string;
    query: string
}

export interface AssignedZipCodes {
    type: string;
    zipCodes: ZipCodes[]
}

@Component({
    selector: 'app-zipcode-table',
    templateUrl: './zipcode-table.component.html',
    styleUrls: ['./zipcode-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ZipcodeTableComponent implements OnInit, OnDestroy {
    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
    @Input() tableType: string;
    @Input() tableTypeIdentifier: string;
    @Input() zipCodeArray: ZipCodes[] = [];
    @Output() public onSelectAllClick = new EventEmitter();
    @Output() public onSave = new EventEmitter();
    @Output() public selectedZipCodeArray = new EventEmitter<AssignedZipCodes>()
    public zipCodeDisplayArray$ = new BehaviorSubject<ZipCodes[]>([]);
    public isAllSelected: boolean;
    public searchControl: UntypedFormGroup;
    public filterControlGroup: UntypedFormGroup;
    public currentPage: number = 1;
    public pageSize: number = 10;
    public totalPages: number;
    dataHeaders: DataHeader[] = [
        {
            title: this.translate.instant('MANAGE_DEALER.ZIPCODE_ASSIGNMENT.BRAND'),
            value: 'parentBrandName',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.ZIPCODE_ASSIGNMENT.COUNTY'),
            value: 'countyName',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.ZIPCODE_ASSIGNMENT.CITY'),
            value: 'cityName',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.ZIPCODE_ASSIGNMENT.STATE'),
            value: 'stateName',
            order: null
        },
        {
            title: this.translate.instant('MANAGE_DEALER.ZIPCODE_ASSIGNMENT.ZIP'),
            value: 'zipCodeName',
            order: null
        }
    ];

    private zipCodeArray$ = new BehaviorSubject<ZipCodes[]>([]);
    private filteredData$ = new BehaviorSubject<ZipCodes[]>([]);
    private searchKeyUpSubject$ = new BehaviorSubject<{event: string, key: string}>({
        event: '',
        key: ''
    });

    private filterKeyValues = this.dataHeaders.map(({ value }) => ({
        value,
        query: ''
    }));

    private filterSubject$ = new BehaviorSubject(this.filterKeyValues)
    private filter$ = this.filterSubject$.asObservable()
    private dataSource$ = combineLatest([this.filter$, this.zipCodeArray$]).pipe(
        map(([filter, dataSource]) => dataSource.filter((item) => filter.every((value) => new RegExp(String(value.query).toLowerCase()).test(
            String(item[value.value]).toLowerCase()
        ))))
    );

    private ngOnDestroy$ = new Subject();
    constructor(
        private translate: TranslateService
    ) {}

    public sort(item: DataHeader) {
        let sortedArr: ZipCodes[] = [];
        const currentSort: Partial<DataHeader> = this.dataHeaders.find((header: DataHeader) => header.value === item.value) || {};

        if (!currentSort.order) {
            currentSort.order = 'asc';
        }
        else if (currentSort.order === 'asc') {
            currentSort.order = 'desc';
        }
        else {
            currentSort.order = null;
        }
        if (!currentSort.order) {
            this.zipCodeDisplayArray$.next(
                this.zipCodeArray.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
            );

            return;
        }
        if (item.value === 'zipCodeName') {
            sortedArr = this.zipCodeArray.slice().sort((a, b) => {
                const valueA = a[item.value] || '';
                const valueB = b[item.value] || '';

                return Number(valueA) - Number(valueB);
            });
        }
        else {
            sortedArr = this.zipCodeArray.slice().sort((a, b) => {
                const valueA = a[item.value] || '';
                const valueB = b[item.value] || '';

                return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
            });
        }
        if (currentSort.order === 'desc') {
            sortedArr.reverse();
        }
        this.zipCodeDisplayArray$.next(
            sortedArr.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    ngOnInit() {
        this.searchControl = new UntypedFormGroup({ search: new UntypedFormControl('', null) });
        this.filterControlGroup = new UntypedFormGroup({
            brandControl: new UntypedFormControl(''),
            countyControl: new UntypedFormControl(''),
            cityControl: new UntypedFormControl(''),
            stateControl: new UntypedFormControl(''),
            zipCodeControl: new UntypedFormControl('')
        });

        this.searchKeyUpSubject$.pipe(
            takeUntil(this.ngOnDestroy$),
            debounceTime(100)
        ).subscribe((searchQuery) => {
            this.filterKeyValues.forEach((filterKeyValue) => {
                if (filterKeyValue.value === searchQuery.event) {
                    filterKeyValue.query = searchQuery.key;
                }
            });
            this.filterSubject$.next(this.filterKeyValues);
        });

        this.filterControlGroup.controls.brandControl.valueChanges.subscribe((value: string) => {
            this.inputChange('parentBrandName', value);
        });
        this.filterControlGroup.controls.countyControl.valueChanges.subscribe((value: string) => {
            this.inputChange('countyName', value);
        });
        this.filterControlGroup.controls.cityControl.valueChanges.subscribe((value: string) => {
            this.inputChange('cityName', value);
        });
        this.filterControlGroup.controls.stateControl.valueChanges.subscribe((value: string) => {
            this.inputChange('stateName', value);
        });
        this.filterControlGroup.controls.zipCodeControl.valueChanges.subscribe((value: string) => {
            this.inputChange('zipCodeName', value);
        });

        this.dataSource$.subscribe((data) => {
            this.totalPages = Math.ceil(data.length / this.pageSize);
            this.filteredData$.next(data);
            this.zipCodeDisplayArray$.next(data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize));
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.zipCodeArray['currentValue']) {
            this.resetForm();
            this.zipCodeArray = changes.zipCodeArray['currentValue'];
            this.totalPages = Math.ceil(this.zipCodeArray.length / this.pageSize);
            this.handlePageChange(1);
            this.zipCodeArray$.next(this.zipCodeArray);
            this.zipCodeDisplayArray$.next(
                this.zipCodeArray$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
            );
        }
    }

    resetForm() {
        this.filterControlGroup?.controls.brandControl.setValue('');
        this.filterControlGroup?.controls.countyControl.setValue('');
        this.filterControlGroup?.controls.cityControl.setValue('');
        this.filterControlGroup?.controls.stateControl.setValue('');
        this.filterControlGroup?.controls.zipCodeControl.setValue('');
        this.filterKeyValues = this.dataHeaders.map(({ value }) => ({
            value,
            query: ''
        }));
    }

    handlePageChange(page: number) {
        this.currentPage = parseInt(page.toString(), 10);
        this.zipCodeDisplayArray$.next(
            this.filteredData$.value.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        );
    }

    selectAllZipCodes() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.filteredData$.value.map((data) => {
                data.isAssigned = true;

                return data;
            });

            this.selectedZipCodeArray.next(
                {
                    type: this.tableType,
                    zipCodes: this.zipCodeArray$.value.filter((zipcode) => zipcode.isAssigned)
                }
            );

            return;
        }

        this.filteredData$.value.map((data) => {
            data.isAssigned = false;

            return data;
        });

        this.selectedZipCodeArray.next(
            {
                type: this.tableType,
                zipCodes: this.zipCodeArray$.value.filter((zipcode) => zipcode.isAssigned)
            }
        );
    }

    selectZipCode(zipCodeObj: ZipCodes, event: Event) {
        this.zipCodeArray$.value.map((data) => {
            if (data.zipCodeId === zipCodeObj.zipCodeId) {
                data.isAssigned = this.tableTypeIdentifier === 'availableZipcodeTable' ? (event.target as HTMLInputElement).checked : !(event.target as HTMLInputElement).checked;
            }

            return data;
        });

        this.selectedZipCodeArray.next(
            {
                type: this.tableType,
                zipCodes: this.zipCodeArray$.value.filter((zipcode) => zipcode.isAssigned)
            }
        );
    }

    assignSelectedCodes() {
        this.onSave.next();
    }

    private inputChange(event: string, key: string) {
        this.searchKeyUpSubject$.next({
            event,
            key
        });
    }
}
