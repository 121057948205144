import { Pipe, PipeTransform } from '@angular/core';
import { Activity, EnergyEventsType, EventType } from '../models/event.model';

@Pipe({ name: 'eventType' })
export class EventTypePipe implements PipeTransform {
    transform(eventType: EventType | Activity): EnergyEventsType | '' {
        switch (eventType) {
            case EventType.ELIGIBLE:
                return 'eligible';
            case EventType.ENROLLED:
                return 'enrolled';
            case EventType.NOT_ELIGIBLE:
                return 'notEligible';
            case Activity.SCHEDULED:
                return 'scheduled';
            case Activity.RESPONDING:
                return 'responding';
            case Activity.INACTIVE:
                return 'inactive';
            default:
                return '';
        }
    }
}
