<div class="hvac-login">
    <div *ngIf='isLogoutRedirect' class='hvac-logout-frame'>
        <iframe [src]="logoutFrameUrl | safe:'resourceUrl'" (load)='logoutFrameLoaded()'></iframe>
    </div>
    <div class='hvac-login-header-container'>
        <div class="hvac-login-header-brand">
            <a [routerLink]="['/']" class="hvac-header-logo-link">
                <utc-svg class="hvac-header-logo" src="assets/images/hvac-partners.svg"></utc-svg>
                <span class="hvac-accessible-text">{{ 'LOGIN_PAGE.LOGO_TITLE' | translate }}</span>
            </a>
        </div>
        <section class="hvac-login-header-show-login">
            <button class="hvac-login-header-show-login-button" hvacButton="primary" (click)="showLoginPanel()">{{
                'LOGIN' | translate }}</button>
        </section>
    </div>
    <utc-login-panel class="hvac-login-page-login-panel" [content]="(loginPanelConent$ | async)!"
        [ngClass]="isLogoutLoading ? 'hvac-login-hidden' : 'hvac-login-visible'"></utc-login-panel>
    <div class="hvac-login-page">
        <hvac-toast [name]="toastOutlet"></hvac-toast>

        <div class="hvac-login-body">
            <hvac-login-hero (onSignUp)="scrollToCreateAccountForm()"></hvac-login-hero>
            <hvac-login-incentives></hvac-login-incentives>
            <hvac-personas></hvac-personas>
            <hvac-login-create-account></hvac-login-create-account>
        </div>
    </div>

    <div class="hvac-homepage-hero-pattern-elements">
        <utc-svg class="hvac-homepage-hero-pattern rellax" data-rellax-speed="-4" src="assets/images/home-pattern.svg">
        </utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-1 rellax" data-rellax-speed="-4"
            data-rellax-percentage="0.5" src="assets/images/home-illustration-1.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-2 rellax" data-rellax-speed="-3"
            src="assets/images/home-illustration-2.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-3 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-3.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-4 rellax" data-rellax-speed="-3"
            src="assets/images/home-illustration-4.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-5 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-5.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-6 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-6.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-7 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-7.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-8 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-8.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-9 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-9.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-10 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-10.svg"></utc-svg>
        <utc-svg class="hvac-hvac-product-illustration hvac-hvac-product-11 rellax" data-rellax-speed="-4"
            src="assets/images/home-illustration-11.svg"></utc-svg>
    </div>
</div>
