import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { BrowseCategoriesIconsService } from 'common/services/browse-categories-icons.service';
import { Product, ProductAttributes, ProductDetails } from 'common/models';
import { FetchCatalogCategories, FetchCatalogCategoryProducts } from 'common/store/catalog/catalog.actions';
import { CatalogCategory, CatalogState, CategoryProducts } from 'common/store/catalog/catalog.state';
import { ConcealProductDetails, RevealProductDetails } from 'common/store/products/products.actions';
import { ProductsState } from 'common/store/products/products.state';
import { ResourceRepositoryModel } from 'common/store/repository/repository.model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, first, map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'utc-product-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    @ViewChild('productCrumb') productCrumb: ElementRef;
    @ViewChild('subCategoryCrumb') subCategoryCrumb: ElementRef;

    @Select(CatalogState.catalogCategories) catalogCategories$!: Observable<CatalogCategory[]>;
    @Select(CatalogState.categoryProducts) catalogProducts$!: Observable<CategoryProducts>;
    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(ProductsState.repository) productRepository$!: Observable<ResourceRepositoryModel<Product>>;

    ngOnDestroy$ = new Subject();
    isfromDirectUrlRoute = true;

    categoryUrn$: BehaviorSubject<string> = new BehaviorSubject('');
    category$: Observable<CatalogCategory|undefined> = combineLatest([this.catalogCategories$, this.categoryUrn$]).pipe(
        map(([catalog, category]) => catalog.find(({ urn }) => urn === category))
    );

    categoryIcon$: Observable<string> = this.category$.pipe(
        map((category) => {
            if (category) {
                return this.iconService.getCategoryIcon(category.urn);
            }

            return '';
        })
    );

    subcategoryUrn$: BehaviorSubject<string> = new BehaviorSubject('');
    subcategory$: Observable<CatalogCategory|undefined> = combineLatest([this.category$, this.subcategoryUrn$]).pipe(
        map(([category, subcategoryUrn]) => category && category.children.find(({ urn }) => urn === `${category.urn}/${subcategoryUrn}`))
    );

    subcategoryEnabled$: Observable<boolean> = this.category$.pipe(
        map((category) => Boolean(category && category.children.length))
    );

    currentCategoryUrn$ = combineLatest([this.category$, this.subcategory$]).pipe(
        map(([category, subcategory]) => {
            if (!category || (category.children.length && !subcategory)) {
                return '';
            }

            return subcategory ? subcategory.urn : category.urn;
        })
    );

    attributes$: Observable<ProductAttributes> = this.activeProduct$.pipe(
        filter((product) => Boolean(product && product.attributes)),
        map(({ attributes }) => attributes as ProductAttributes)
    );

    productUrn$: BehaviorSubject<string> = new BehaviorSubject('');
    productsEnabled$: Observable<Boolean> = combineLatest([this.category$, this.subcategory$]).pipe(
        map(([category, subcategory]) => {
            if (subcategory) {
                return true;
            }

            return Boolean(category && !category.children.length);
        })
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    familyProducts$: Observable<any[]> = combineLatest([
        this.currentCategoryUrn$,
        this.catalogProducts$,
        this.productRepository$
    ]).pipe(
        filter(([categoryUrn]) => Boolean(categoryUrn)),
        map(([categoryUrn, categoryProducts, productRepository]) => {
            const products = categoryProducts[categoryUrn] || [];

            const filteredProducts = products.map((urn) => productRepository[urn]).filter(Boolean);

            return filteredProducts.map(((product) => ({
                urn: product.urn,
                name: product.name,
                image: product.media ? this.findImageSrc(product.media) : null
            })));
        })
    );

    fetchProducts$ = combineLatest([this.currentCategoryUrn$, this.catalogProducts$]).pipe(
        filter(([categoryUrn]) => Boolean(categoryUrn)),
        distinctUntilChanged(([categoryUrnA], [categoryUrnB]) => categoryUrnA === categoryUrnB),
        tap(([categoryUrn, catalogProducts]) => {
            const categoryProducts = catalogProducts[categoryUrn] || [];
            if (categoryUrn && !categoryProducts.length) {
                this.store.dispatch(new FetchCatalogCategoryProducts(categoryUrn));
            }
        })
    );

    categoryExpanded$ = new BehaviorSubject<Boolean>(false);
    subcategoryExpanded$ = new BehaviorSubject<Boolean>(false);
    productsExpanded$ = new BehaviorSubject<Boolean>(false);

    dispatchInTransition$ = combineLatest([this.currentCategoryUrn$, this.productUrn$]).pipe(
        map(([categoryUrn, productUrn]) => Boolean(categoryUrn && productUrn)),
        distinctUntilChanged(),
        tap((reveal) => {
            const action = reveal ? new RevealProductDetails() : new ConcealProductDetails();

            return this.store.dispatch(action);
        })
    );

    constructor(
        private readonly store: Store,
        private readonly iconService: BrowseCategoriesIconsService
    ) {}

    ngOnInit() {
        this.fetchProducts$.pipe(takeUntil(this.ngOnDestroy$)).subscribe();

        this.catalogCategories$.pipe(
            takeUntil(this.ngOnDestroy$),
            first(),
            tap((catalogCategories) => (catalogCategories.length ? null : this.store.dispatch(new FetchCatalogCategories())))
        ).subscribe();

        this.attributes$.pipe(
            takeUntil(this.ngOnDestroy$),
            filter((attributes) => Boolean(attributes && attributes.categories)),
            map((attributes) => (attributes.categories[0]
                .split('/')
                .concat('')
                .slice(0, 2)
                .concat(attributes.urn) as [string, string, string])),
            tap(([primaryCategory, subcategory, product]) => {
                this.categoryUrn$.next(primaryCategory);
                if (this.isfromDirectUrlRoute) {
                    this.subcategoryUrn$.next(subcategory);
                }
                this.productUrn$.next(product);
            })
        ).subscribe();

        combineLatest([this.category$, this.productsEnabled$, this.productUrn$]).subscribe(
            ([category, productsEnabled, product]) => {
                const categorySelected = category && category.name;

                if (categorySelected && !productsEnabled) {
                    // Open the Subcategory Menu
                    return this.expandSubcategory();
                }
                else if (categorySelected && productsEnabled && !product) {
                    // Open the Products Menu
                    return this.expandProducts();
                }

                // Close both Subcategory and Products Menus
                this.collapseSubcategory();
                this.collapseProducts();

                return;
            }
        );

        this.subcategoryExpanded$.pipe(
            debounceTime(100),
            delay(100)
        ).subscribe((enabled) => {
            if (enabled) {
                this.focusSubcategory();
            }
        });

        this.productsExpanded$.pipe(
            debounceTime(100),
            delay(100)
        ).subscribe((enabled) => {
            if (enabled) {
                this.focusProduct();
            }
        });

        this.dispatchInTransition$.pipe(takeUntil(this.ngOnDestroy$)).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    toggleCategory() {
        if (this.categoryExpanded$.value) {
            return this.collapseCategory();
        }

        return this.expandCategory();
    }

    expandCategory() {
        this.categoryExpanded$.next(true);
        this.subcategoryExpanded$.next(false);
        this.productsExpanded$.next(false);
    }

    collapseCategory() {
        this.categoryExpanded$.next(false);
    }

    toggleSubcategory() {
        if (this.subcategoryExpanded$.value) {
            return this.collapseSubcategory();
        }

        return this.expandSubcategory();
    }

    expandSubcategory() {
        this.subcategoryExpanded$.next(true);
        this.categoryExpanded$.next(false);
        this.productsExpanded$.next(false);
    }

    collapseSubcategory() {
        this.subcategoryExpanded$.next(false);
    }

    toggleProducts() {
        if (this.productsExpanded$.value) {
            return this.collapseProducts();
        }

        return this.expandProducts();
    }

    expandProducts() {
        this.productsExpanded$.next(true);
        this.categoryExpanded$.next(false);
        this.subcategoryExpanded$.next(false);
    }

    collapseProducts() {
        this.productsExpanded$.next(false);
    }

    setCategory(category: CatalogCategory) {
        this.categoryUrn$.next(category.urn);
        this.subcategoryUrn$.next('');
        this.productUrn$.next('');
        this.collapseCategory();
    }

    setSubcategory(category: CatalogCategory) {
        this.subcategoryUrn$.next(category.urn.split('/')[1]);
        this.productUrn$.next('');
        this.collapseSubcategory();
    }

    setProduct(product: Product) {
        this.isfromDirectUrlRoute = false;
        this.store.dispatch(new Navigate(['/products', 'detail', product.urn]));
        this.collapseProducts();
    }

    getSubcategoryName(urnPath: string) {
        return urnPath.split('/').pop();
    }

    focusSubcategory() {
        this.subCategoryCrumb.nativeElement.focus();
    }

    focusProduct() {
        this.productCrumb.nativeElement.focus();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    findImageSrc(media: any) {
        let src = null;
        if (media['100x100'] && media['100x100'][0].url) {
            src = media['100x100'][0].url;
        }
        else if (media['150x150'] && media['150x150'][0].url) {
            src = media['150x150'][0].url;
        }
        else if (media['200x200'] && media['200x200'][0].url) {
            src = media['200x200'][0].url;
        }
        else if (media['300x300'] && media['300x300'][0].url) {
            src = media['300x300'][0].url;
        }
        else if (media['400x400'] && media['400x400'][0].url) {
            src = media['400x400'][0].url;
        }
        else if (media['500x500'] && media['500x500'][0].url) {
            src = media['500x500'][0].url;
        }
        else if (media['highest-res'] && media['highest-res'][0].url) {
            src = media['highest-res'][0].url;
        }

        return src;
    }
}
