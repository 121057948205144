import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Select } from '@ngxs/store';
import { environment } from 'common/environments/environment';
import { OktaService } from 'common/services/okta/okta.service';
import { BrandingState } from 'common/store/branding/branding.state';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'hvac-sign-out',
    templateUrl: './signOut.component.html',
    styleUrls: []
})
export class SignOutComponent implements OnInit {
    @Select(BrandingState.brandRedirect) redirectBrand$: Observable<string>;
    constructor(
        private readonly okta: OktaService,
        private readonly route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(
            withLatestFrom(this.redirectBrand$),
            map(([params, brandState]: [Params, string]): string => {
                // special handling needed
                const brand = brandState.toLowerCase().replace(' & ', 'and');
                if (params['redirect']) {
                    return params['redirect'];
                }
                const siteUrls: {[index: string]:string} = environment.siteUrls;

                return Object.prototype.hasOwnProperty.call(siteUrls, brand) ? `${siteUrls[brand]}/logout` : environment.auth.postLogoutRedirectUri;
            })
        ).subscribe((redirectUri: string) => {
            this.okta.logout(redirectUri);
        });
    }
}
