import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { environment } from 'common/environments/environment';
import { ProductAlert } from 'private/app/models/alert.model';
import { Pagination, ProductType, SystemType, EcobeeNIProductDetailsData } from 'private/app/models/connected-product.model';
import { ConnectedSystem } from 'private/app/models/connected-system.model';
import { WallControlStatus } from 'private/app/models/wall-control.model';
import { AlertsService } from 'private/app/services/connected-portal/alerts.service';
import { DataSharingOptions, DataSharingService } from 'private/app/services/connected-portal/data-sharing.service';
import { DealersService } from 'private/app/services/connected-portal/dealers.service';
import { ProductService } from 'private/app/services/connected-portal/product.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailContextService } from '../../product-detail-context.service';
import { WallControlEventLogConfig, WallControlEventLogEvent } from '../components/wall-control-event-log/wall-control-event-log.component';

const PAGINATION_PAGE_SIZE = 10;
const isRuntimeLinkFeatureEnabled = environment.features.connectedPortal.ecobeeNiRuntimeLink;

interface EventLogData extends Pagination {
    config: WallControlEventLogConfig;
    items: WallControlEventLogEvent[];
}

@Component({
    selector: 'hvac-ecobee-ni-wall-control-detail',
    templateUrl: './ecobee-ni-wall-control-detail.component.html',
    styleUrls: ['./ecobee-ni-wall-control-detail.component.scss']
})
export class EcobeeNIWallControlDetailComponent extends BaseComponent implements OnInit {
    @Input() propertyId: string;
    @Input() systemType: SystemType;
    @Input() serialNo: string;
    @Input() dealerId: string;

    public system$: Observable<ConnectedSystem | null>;
    public dataSharingPermissions$: Observable<DataSharingOptions>;
    public isWallControlConnected$: Observable<boolean>;
    public productData$: Observable<EcobeeNIProductDetailsData>;
    public wallControlStatus$: Observable<WallControlStatus>
    public wallControlEventLogData$: Observable<EventLogData>
    public wallControlEventLogCurrentPage$ = new BehaviorSubject<number>(1);
    public toggleAccordion$: Subject<keyof typeof this.accordions$.value> = new Subject();
    public accordions$ = new BehaviorSubject({
        eventLog: false,
        config: false
    });

    public esn$: Observable<string>;

    constructor(
        public contextService: ProductDetailContextService,
        private productService: ProductService,
        private dataSharingService: DataSharingService,
        private dealerService: DealersService,
        private alertService: AlertsService
    ) {
        super();
    }

    ngOnInit(): void {
        // OBSERVABLES
        this.dataSharingPermissions$ = this.dataSharingService.dataPoints$
            .pipe(takeUntil(this.ngOnDestroy$));

        this.system$ = this.dealerService.querySystemsByPropertyId(this.propertyId, this.dealerId)
            .pipe(
                map((res) => this.dataSharingService.findCurrentSystem(res.data, this.serialNo) || null),
                shareReplay()
            );

        this.esn$ = this.system$
            .pipe(
                map((data) => data?.products.find((product) => product.type === ProductType.WallControl)?.esn as string),
                shareReplay()
            );

        this.wallControlStatus$ = this.esn$
            .pipe(
                switchMap((esn) => this.productService
                    .queryWallControlStatusBySerialNo(esn, this.systemType, this.dealerId)
                    .pipe(
                        map((data) => data.data),
                        shareReplay()
                    ))
            );

        this.isWallControlConnected$ = this.wallControlStatus$
            .pipe(map((status) => (typeof status?.isDisconnected !== 'undefined') && status?.isDisconnected === false));

        this.productData$ = combineLatest([
            this.wallControlStatus$,
            this.isWallControlConnected$,
            this.productService.queryProductBySerialNo(this.serialNo, this.dealerId)
        ]).pipe(
            map(([wallControlStatus, isWallControlConnected, productRes]) => ({
                isWallControlConnected,
                isRuntimeReportEnabled: wallControlStatus.isRuntimeReportEnabled && isRuntimeLinkFeatureEnabled,
                productInfo: productRes.data,
                modelName: productRes.data.name
            })),
            shareReplay()
        );

        this.wallControlEventLogData$ = combineLatest([
            this.wallControlEventLogCurrentPage$,
            this.system$.pipe(map((system) => system?.systemId))
        ]).pipe(
            filter(([, systemId]) => Boolean(systemId)),
            switchMap(([currentPage, systemId]) => this.alertService
                .queryAlertsBySystemId(
                    systemId as string,
                    this.systemType,
                    this.dealerId,
                    {
                        pagination: {
                            currentPage,
                            pageSize: PAGINATION_PAGE_SIZE
                        },
                        sort: {
                            field: 'dateTime',
                            order: 'desc'
                        }
                    }
                ).pipe(
                    map(({ data, totalPages }) => {
                        const items = this.formatProductAlertsToEventLogItems(data);
                        const config = new WallControlEventLogConfig();
                        config.diagnostics = false;

                        return {
                            config,
                            items,
                            totalPages,
                            currentPage
                        };
                    })
                ))
        );

        // SUBSCRIPTIONS
        this.toggleAccordion$
            .pipe(
                tap((accordionId) => {
                    const nextValue = !this.accordions$.value[accordionId];

                    this.accordions$.next({
                        ...this.accordions$.value,
                        [accordionId]: nextValue
                    });
                }),
                takeUntil(this.ngOnDestroy$)
            ).subscribe();

        this.system$
            .pipe(takeUntil(this.ngOnDestroy$))
            .subscribe((system) => {
                if (system) {
                    this.dataSharingService.setPermissions(system.dataSharing, system.systemType);
                }
            });
    }

    ngOnDestroy() {
        this.dataSharingService.resetPermissions();

        super.ngOnDestroy();
    }

    private formatProductAlertsToEventLogItems(productAlerts: ProductAlert[]): EventLogData['items'] {
        return [...productAlerts]
            .map((alert): WallControlEventLogEvent => ({
                code: alert.error.code,
                dateTime: alert.dateTime,
                severity: alert.error.severity,
                type: alert.error.type,
                description: alert.error.description,
                consecutiveEvents: alert.occurrenceCount,
                equipmentSource: alert.product.type,
                diagnosticsUrl: alert.error.troubleshootUri
            }))
            .sort((itemA, itemB) => (itemA && itemB ? itemB.dateTime.localeCompare(itemA.dateTime) : 0));
    }
}
