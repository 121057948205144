import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { AuthState } from 'common/store/auth/auth.state';
import { CompanyCode } from 'private/app/models/company.model';
import { IdmService } from 'private/app/services/idm.service';
import { Injectable } from '@angular/core';
import { map, skipWhile, switchMap } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { Observable, of } from 'rxjs';
import { PrivateAppRouteData } from 'private/app/app-routing.module';
import { Select, Store } from '@ngxs/store';
import { User } from 'common/models';
import { UserEntity } from 'private/app/models/user.model';
import { ErrorService } from 'common/content/services/error.service';
import { HeapService } from 'common/services/heap.service';
import { CookieService } from 'common/services/cookie/cookie.service';
import { AppConstants } from 'common/app-constants';

@Injectable({ providedIn: 'root' })
export class ConnectedPortalGuard implements CanActivateChild {
    @Select(AuthState.user) user$: Observable<User>;

    constructor(
        private readonly store: Store,
        private readonly idmService: IdmService,
        private readonly errorService: ErrorService,
        private heapService: HeapService,
        private cookieService: CookieService
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
        const { allowedCompanyCodes } = childRoute.data as PrivateAppRouteData;
        if (allowedCompanyCodes) {
            return this.getIsAllowed(allowedCompanyCodes);
        }

        return of(false);
    }

    getUserData() {
        return this.user$.pipe(
            skipWhile((value) => !value),
            switchMap((value) => this.idmService.getUser(value.profile.login))
        );
    }

    getIsAllowed(allowedCompanyCodes: string[]): Observable<boolean> {
        return this.getUserData().pipe(
            map((user: UserEntity) => {
                const consentCookie = this.cookieService.getCookie(AppConstants.CONSENT_COOKIE) ?? '';
                if (decodeURIComponent(consentCookie).includes(AppConstants.CONSENT_COOKIE_VALUE)) {
                    this.heapService.addUserProperties({
                        companyID: user.defaultCompanyId,
                        companyName: user.defaultCompanyName
                    });
                }

                if (user?.companies?.length) {
                    const [companyData] = user.companies;

                    if (companyData) {
                        const { code } = companyData.companyType;
                        const { name } = companyData.companyType;

                        if (decodeURIComponent(consentCookie).includes(AppConstants.CONSENT_COOKIE_VALUE)) {
                            this.heapService.addUserProperties({ companyType: name });
                        }

                        // If the user has the allowed company code we let them
                        // view the connected portal route
                        if (allowedCompanyCodes.includes(code)) {
                            return true;
                        }

                        //  If the user does NOT have the allowed company code we
                        //  redirect them to the appropriate route
                        this.redirectByCompanyCode(code, companyData.id);

                        return false;
                    }
                }

                this.errorService.errorStatus$.next(404);

                return false;
            })
        );
    }

    redirectByCompanyCode(companyCode: CompanyCode | string, id: string): void {
        switch (companyCode) {
            case CompanyCode.Internal:
                this.store.dispatch(new Navigate([`connected-portal`]));
                break;
            case CompanyCode.Distributor:
                this.store.dispatch(new Navigate([`connected-portal/distributors/${id}`]));
                break;
            case CompanyCode.Dealer:
                this.store.dispatch(new Navigate([`connected-portal/dealers/${id}`]));
                break;
            default:
                this.store.dispatch(new Navigate(['/']));
                break;
        }
    }
}
