import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { BrandService } from 'common/services/brand.service';
import { ProductDetails, ProductMedia } from 'common/models';
import { CatalogState } from 'common/store/catalog/catalog.state';
import { ContentState } from 'common/store/content/content.state';
import { ProductsState } from 'common/store/products/products.state';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';
import { Brand } from 'common/models/brand.model';

@Component({
    selector: 'utc-product-content-section-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./content-section.component.scss', './overview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit, OnDestroy {
    @Input() showPlaceholder: boolean;

    @Select(ProductsState.activeProduct) activeProduct$!: Observable<ProductDetails>;
    @Select(ContentState.contentBrand) brand$!: Observable<string>;
    @Select(CatalogState.currentBrand) currentBrand$!: Observable<string>;


    ngOnDestroy$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickStream$ = new BehaviorSubject<any>(null);

    attributes$ = this.activeProduct$.pipe(
        filter((product) => Boolean(product)),
        map(({ attributes }) => attributes)
    );

    showOverview$ = this.attributes$.pipe(
        map((attributes) => Boolean(attributes && attributes.overviewTexts))
    );

    overviewText$ = combineLatest([this.attributes$, this.brand$, this.currentBrand$, this.clickStream$]).pipe(
        map(([attributes, inputBrand, currentBrand, clickStream]) => {
            if (clickStream !== null) {
                const overviewTexts = attributes && attributes.overviewTexts ? attributes.overviewTexts : {};
                const selectedText = overviewTexts[clickStream] ? overviewTexts[clickStream] : overviewTexts[Object.keys(overviewTexts)[0]];
                if (this.imageSrc$.value.brand !== clickStream) {
                    const selImage = attributes && attributes.media && attributes.media['500x500'] ? attributes.media['500x500'].find((x:{brand:string, }) => x.brand === clickStream) : {};
                    if (selImage) {
                        this.imageSrc$.next(selImage);
                    }
                }

                return this.sanitizer.bypassSecurityTrustHtml(selectedText);
            }
            const brand = (inputBrand === 'default') ? currentBrand : inputBrand;
            const overviewTexts = attributes && attributes.overviewTexts ? attributes.overviewTexts : {};
            const selectedText = overviewTexts[brand] ? overviewTexts[brand] : overviewTexts[Object.keys(overviewTexts)[0]];

            return this.sanitizer.bypassSecurityTrustHtml(selectedText);
        })
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    imageSrc$ = new BehaviorSubject<any>(null);
    moreImages$ = this.attributes$.pipe(
        filter((attributes) => Boolean(attributes)),
        map((attributes) => {
            if (attributes && attributes.media && attributes.media['500x500'] && attributes.media['500x500'].length > 1) {
                return attributes.media['500x500'];
            }

            return [];
        })
    );

    brandList$ = this.attributes$.pipe(
        withLatestFrom(this.brandService.getBrandMap()),
        map(([attributes, allBrands]) => {
            const brandNames = attributes && attributes.brandNames || [];

            return allBrands.filter((item) => brandNames.includes(item.brandName)) || [];
        })
    );


    constructor(
        private readonly translate: TranslateService,
        private readonly sanitizer: DomSanitizer,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef,
        private readonly brandService: BrandService
    ) { }

    ngOnInit() {
        this.showOverview$.pipe(
            takeUntil(this.ngOnDestroy$),
            distinctUntilChanged(),
            tap((show) => {
                if (show) {
                    this.detailSectionService.registerSection(this.sectionConfig());
                }
                else {
                    this.detailSectionService.deregisterSection(this.sectionConfig());
                }
            })
        ).subscribe();

        this.activeProduct$.pipe(
            takeUntil(this.ngOnDestroy$),
            map((product) => product && product.attributes && product.attributes.media && product.attributes.media['500x500'][0]),
            tap((image) => {
                this.imageSrc$.next(image);
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
        this.detailSectionService.deregisterSection(this.sectionConfig());
    }

    sectionConfig(): DetailSection {
        return {
            title: this.translate.instant('PRODUCTS.OVERVIEW'),
            order: 0,
            container: this.element.nativeElement
        };
    }

    changeImage(image: ProductMedia) {
        this.imageSrc$.next(image);
        this.clickStream$.next(image.brand);
    }

    brandClicked(brandObj: Brand) {
        this.clickStream$.next(brandObj.brandName);
    }
}
