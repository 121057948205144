import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentHeadingData } from 'common/models/content-heading';
import { AccountAdminProgramService } from 'private/app/services/account-admin/account-admin-program.service';
import { LanguageTranslationService } from 'common/services/language-translation.service';

@Component({
    selector: 'hvac-program-admin',
    templateUrl: './program-admin.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ProgramAdminComponent {
    public breadCrumbData: ContentHeadingData;

    constructor(
        public translate: TranslateService,
        public accountAdminProgramService: AccountAdminProgramService,
        private languageTranslationService: LanguageTranslationService
    ) {
        this.languageTranslationService.translationsLoaded().subscribe(() => {
            this.breadCrumbData = {
                Content: { title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.SEARCH_PROGRAMS') },
                breadCrumbs: [
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_1'),
                        url: '/admin'
                    },
                    {
                        title: this.translate.instant('ACCOUNT_ADMIN.PROGRAMS.PARENT_2'),
                        url: '/Admin/Program'
                    }
                ]
            };
            this.accountAdminProgramService.setProgramAdminBreadCrumData(this.breadCrumbData);
        });
    }
}
