import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { CharSymbol } from 'common/enums/char-symbol';
import { parseISO, sub } from 'date-fns';
import { ApplyFiltersOutput, ChartData, EngineeringInsightsFiltersInput, SelectedProperties } from 'private/app/models/engineering-insights-filters.model';
import { SensorPropMinMax, SystemDataEventsResponse } from 'private/app/models/factory.model';
import { FactoryService } from 'private/app/services/connected-portal/factory.service';
import { BehaviorSubject, combineLatest, EMPTY, NEVER, Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, shareReplay, skip, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApiResponseCode, ENGINEERING_GRAPH_DEFAULT_DAY_RANGE, ENGINEERING_GRAPH_SELECTIONS } from '../../../constants';
import { FactoryEngineeringInsightsService } from './factory-engineering-insights.service';
import { CSVExportService } from 'common/services/csv-export.service';
import { FactoryEngineeringInsightsChartComponent } from './components/factory-engineering-insights-chart/factory-engineering-insights-chart.component';

const SYSTEM_EVENT_DATA_SCALE = 3;
const EVENT_DEBOUNCE_TIME = 200;

enum DataView {
    Graph = 'graph',
    Idle = 'idle',
    LoadData = 'loadData',
    Loading = 'loading',
    NoData = 'noData',
    TimeoutError = 'loadingTimeout'
}

@Component({
    selector: 'hvac-factory-engineering-insights',
    templateUrl: './factory-engineering-insights.component.html',
    styleUrls: ['./factory-engineering-insights.component.scss'],
    providers: [FactoryEngineeringInsightsService]
})
export class FactoryEngineeringInsightsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() serialNo: string;
    @ViewChild(FactoryEngineeringInsightsChartComponent, { static: false }) childComponent: FactoryEngineeringInsightsChartComponent;

    public readonly DataView = DataView;
    public readonly ApiResponseCode = ApiResponseCode;
    public readonly CharSymbols = CharSymbol;
    public filterInputs$: Observable<EngineeringInsightsFiltersInput>;
    public chartFilters$: Observable<ApplyFiltersOutput>;
    public chartData$: Observable<ChartData | null>;
    public isLoadingFilters$ = new BehaviorSubject(false);
    public isLoadingChart$ = new BehaviorSubject(false);
    public minMaxValues$ = new BehaviorSubject<SensorPropMinMax>({});
    public minMaxDefaultValues$ = new BehaviorSubject<SensorPropMinMax>({});
    public disabledRelays$ = new BehaviorSubject<string[]>([]);
    public isFiltersOpen$ = new BehaviorSubject(true);
    public curDataView$ = new BehaviorSubject<DataView>(DataView.Idle);
    public isLoading = false;

    private systemEventsData$: Observable<SystemDataEventsResponse>;
    private selectedProperties$ = new BehaviorSubject<SelectedProperties>(ENGINEERING_GRAPH_SELECTIONS);

    private readonly INITIAL_CHART_DATES = {
        startDate: sub(new Date(), { days: ENGINEERING_GRAPH_DEFAULT_DAY_RANGE }).getTime(),
        endDate: Date.now()
    }

    private chartDates$ = new BehaviorSubject(this.INITIAL_CHART_DATES);

    constructor(
        private factoryService: FactoryService,
        private insightsService: FactoryEngineeringInsightsService,
        private csvExportService: CSVExportService
    ) {
        super();
    }

    ngOnInit(): void {
        this.chartDates$.pipe(
            distinctUntilChanged(),
            // Unsubscribe after loading the initial filter data.
            take(1),
            tap(() => {
                this.isLoadingFilters$.next(true);
            }),
            switchMap(({ startDate, endDate }) => this.factoryService.querySystemDataEventsMinMaxValuesBySerialNo(this.serialNo, {
                startDate: new Date(startDate).toISOString(),
                endDate: new Date(endDate).toISOString(),
                maxItemCount: SYSTEM_EVENT_DATA_SCALE,
                generateExportFile: 'false'
            }).pipe(
                catchError((err: HttpErrorResponse) => {
                    this.isLoadingFilters$.next(false);

                    if (err?.status === ApiResponseCode.NO_CONTENT) {
                        this.curDataView$.next(DataView.NoData);
                    }
                    else {
                        this.curDataView$.next(DataView.TimeoutError);
                    }

                    return EMPTY;
                })
            )),
            tap(({ data }) => {
                this.minMaxValues$.next(data.dataMinMax);
                this.minMaxDefaultValues$.next(data.dataMinMax);
                this.disabledRelays$.next(this.insightsService.getDisabledRelays(data.thermostatDataEvents));
                this.isLoadingFilters$.next(false);
                this.curDataView$.next(DataView.LoadData);
            })
        ).subscribe();

        this.filterInputs$ = combineLatest([this.chartDates$, this.minMaxValues$, this.selectedProperties$, this.disabledRelays$])
            .pipe(
                debounceTime(EVENT_DEBOUNCE_TIME),
                map(([dates, minMaxValues, selectedProps, disabledRelays]) => this.insightsService.mapFilterInputs(dates, minMaxValues, selectedProps, disabledRelays)),
                shareReplay()
            );

        this.systemEventsData$ = this.chartDates$.pipe(
            // Don't load chart data for the first set of dates, which will instead only load the filter data.
            skip(1),
            distinctUntilChanged((prevDates, curDates) => {
                const isSameDates = Boolean(prevDates.startDate === curDates.startDate && prevDates.endDate === curDates.endDate);

                return isSameDates;
            }),
            tap(() => {
                this.isLoadingChart$.next(true);
                this.curDataView$.next(DataView.Loading);
            }),
            switchMap(({ startDate, endDate }) => this.factoryService.querySystemDataEventsBySerialNo(this.serialNo, {
                startDate: new Date(startDate).toISOString(),
                endDate: new Date(endDate).toISOString(),
                maxItemCount: SYSTEM_EVENT_DATA_SCALE,
                generateExportFile: 'false'
            }).pipe(
                tap(({ data }) => {
                    this.minMaxValues$.next(data.dataMinMax);
                    this.minMaxDefaultValues$.next(data.dataMinMax);
                    this.disabledRelays$.next(this.insightsService.getDisabledRelays(data.thermostatDataEvents));
                }),
                catchError((err: HttpErrorResponse) => {
                    this.isLoadingChart$.next(false);

                    if (err?.status === ApiResponseCode.NO_CONTENT) {
                        this.curDataView$.next(DataView.NoData);
                    }
                    else {
                        this.curDataView$.next(DataView.TimeoutError);
                    }

                    return NEVER;
                })
            )),
            tap(() => {
                this.curDataView$.next(DataView.Graph);
                this.isLoadingChart$.next(false);
            })
        );

        this.chartData$ = combineLatest([this.systemEventsData$, this.minMaxValues$, this.selectedProperties$]).pipe(
            debounceTime(EVENT_DEBOUNCE_TIME),
            map(([{ data }, minMaxValues, selectedProps]): ChartData => {
                const { thermostatDataEvents, sensorDataEvents } = data;

                const allDates = Array.from(new Set([
                    ...thermostatDataEvents.map((event) => event.date),
                    ...sensorDataEvents.map((event) => event.date)
                ]))
                    .map((date) => parseISO(date));

                const combinedDataEvents = this.insightsService.getCombinedDataEvents(data, minMaxValues)
                    .filter((event) => ['date', ...selectedProps.parameters].includes(event.name))
                    .filter((event) => !event.data.every((datum) => datum.value === null));

                const relayEvents = this.insightsService.getRelayEventData(thermostatDataEvents, selectedProps.relays);

                const transformedData = {
                    dates: Array.from(allDates),
                    sensorData: combinedDataEvents,
                    relayData: relayEvents
                };

                return transformedData;
            }),
            shareReplay(),
            catchError(() => NEVER),
            startWith(null)
        );

        this.chartData$.pipe(takeUntil(this.ngOnDestroy$)).subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['serialNo'].isFirstChange()) {
            this.resetFilters();
        }
    }

    handleFilterUpdate(filterData: ApplyFiltersOutput) {
        const selectedRelays = filterData.relays.filter((item) => item.isSelected).map((item) => item.key);
        const selectedParams = filterData.parameters.filter((item) => item.isSelected).map((item) => item.key);

        const minMaxValues = filterData.parameters.map((param) => ({
            key: param.key,
            min: param.min,
            max: param.max
        })).reduce((acc, cur) => {
            acc[cur.key] = {
                min: cur.min,
                max: cur.max
            };

            return acc;
        }, {} as SensorPropMinMax);

        const { startDate, endDate } = filterData;

        this.chartDates$.next({
            startDate,
            endDate
        });

        this.minMaxValues$.next(minMaxValues);

        this.selectedProperties$.next({
            relays: selectedRelays,
            parameters: selectedParams
        });
    }

    handleFilterReset() {
        this.minMaxValues$.next(this.minMaxDefaultValues$.value);
    }

    handleCollapseFilters() {
        this.isFiltersOpen$.next(!this.isFiltersOpen$.value);
    }

    getFilePath() {
        this.isLoading = true;

        return this.chartDates$.pipe(
            take(1),
            switchMap(({ startDate, endDate }) => this.factoryService
                .querySystemDataEventsBySerialNo(this.serialNo, {
                    startDate: new Date(startDate).toISOString(),
                    endDate: new Date(endDate).toISOString(),

                    maxItemCount: SYSTEM_EVENT_DATA_SCALE,
                    generateExportFile: 'true'
                })
                .pipe(
                    takeUntil(this.ngOnDestroy$),
                    finalize(() => {
                        this.isLoading = false;
                    })
                ))
        );
    }

    handleRuntimeReport() {
        return this.getFilePath()
            .pipe(
                tap((res) => {
                    if (res?.data?.filepath) {
                        const { filepath } = res.data;

                        this.csvExportService.downloadFile(filepath);
                    }
                })
            ).subscribe();
    }

    handleDownloadImage() {
        this.childComponent.downloadChart();
    }

    private resetFilters() {
        this.minMaxValues$.next({});
        this.chartDates$.next(this.INITIAL_CHART_DATES);
        this.selectedProperties$.next(ENGINEERING_GRAPH_SELECTIONS);
        this.disabledRelays$.next([]);
    }
}
