import { Injectable } from '@angular/core';
import { environment } from 'common/environments/environment';
import { AddressLookup } from 'private/app/components/address-lookup/address-lookup.component';
import { AddressLookupResult } from 'private/app/models/address-lookup.model';
import { BehaviorSubject } from 'rxjs';

export enum ProcessStatusIncorrect { 'I1', 'I2', 'I3', 'I4' }

export enum ProcessStatusCorrect { 'C1', 'C2', 'C3', 'C4' }

export enum ProcessStatusVerified { 'V1', 'V2', 'V3', 'V4' }
@Injectable({ providedIn: 'root' })
export class AddressLookupService {
    public incorrectProcessStatus: string[] = ['I1', 'I2'];
    addressLookUpResult$: BehaviorSubject<AddressLookupResult> = new BehaviorSubject<AddressLookupResult>({});
    addressDoctorLookUp(address: AddressLookup) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4) {
                if (xmlHttp.status === 200) {
                    const oParser = new DOMParser();
                    const oDOM = oParser.parseFromString(xmlHttp.response, 'application/xml');
                    this.addressLookUpResult$.next(this.xmlToJson(oDOM as unknown as Element));
                }
            }
        };
        xmlHttp.open('POST', environment.addressDoctor.baseUrl + environment.addressDoctor.api, true);
        xmlHttp.setRequestHeader('Content-Type', 'text/xml');
        xmlHttp.send(this.addressLookupPostBody(address));
    }


    private addressLookupPostBody(address: AddressLookup) {
        return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://validator5.AddressDoctor.com/Webservice5/v2">
        <soapenv:Header/>
        <soapenv:Body>
          <Process xmlns="http://validator5.AddressDoctor.com/Webservice5/v2">
          <login>${environment.addressDoctor.cred.login}</login>
          <password>${environment.addressDoctor.cred.password}</password>
            <parameters>
            <ProcessMode>${environment.addressDoctor.ProcessMode}</ProcessMode>
              <MaximumNumberOfResults>${environment.addressDoctor.MaxResultCount}</MaximumNumberOfResults>  </parameters>
            <addresses>
              <Address>
              <FormattedAddress>
              <string>${address.address1}</string>
              <string>${address.address1}</string>
              <string>${address.city}</string>
              <string>${address.state.code}</string>
              <string>${address.country.code}</string>
              <string>${address.postalCode}</string>
          </FormattedAddress>
              </Address>
            </addresses>
          </Process>
        </soapenv:Body>
      </soapenv:Envelope>
      `;
    }

    private xmlToJson(xml : Element) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let obj: any = {};

        if (xml.nodeType === 1) {
            if (xml.attributes.length > 0) {
                obj['@attributes'] = {};
                for (let node = 0; node < xml.attributes.length; node++) {
                    const attribute = xml.attributes.item(node);
                    if (attribute) {
                        obj['@attributes'][attribute.nodeName] = attribute?.nodeValue;
                    }
                }
            }
        }
        else if (xml.nodeType === 3) {
            obj = xml.nodeValue;
        }

        if (xml.hasChildNodes()) {
            for (let i = 0; i < xml.childNodes.length; i++) {
                const item = xml.childNodes.item(i);
                const nodeName = item.nodeName.replace('soap:', '').replace('#', '');
                if (typeof (obj[nodeName]) === 'undefined') {
                    obj[nodeName] = this.xmlToJson(item as Element);
                }
                else {
                    if (typeof (obj[nodeName].push) === 'undefined') {
                        const old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item as Element));
                }
            }
        }

        return obj;
    }
}
