import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiOptionsService } from 'common/services/api-options/api-options.service';
import { environment } from 'common/environments/environment';
import { InboxAPIResponse, Inboxmessage } from 'private/app/store/inbox.actions';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ToastService } from 'common/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class InboxService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly apiOptions: ApiOptionsService,
        private currentRoute: Router,
        private toastService: ToastService,
        private translate: TranslateService
    ) { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getInboxCount(): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<string>(`${environment.api.inbox}/count`, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getInboxDetails(): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.get<InboxAPIResponse>(environment.api.inboxDetails, { ...options })),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 404 || err.status === 403) {
                    return of({});
                }
                throw err;
            })
        );
    }

    parseInboxData(result: Inboxmessage[]): Inboxmessage[] {
        result.forEach((data) => {
            data.documentLink = data.type.split('#;')[2];
            data.type = data.type.split('#;')[0] === '' ? data.type.split('#;')[1] : data.type.split('#;')[0];
            data.fileType = (data.availableFormats === 'csv') ? 'csv' : data.availableFormats;
            data.inboxViewer = Boolean(data.availableFormats.includes('csv'));
            data.status = data.details && data.details[0] && data.details[0].status ? data.details[0].status : '';
            data.fileName = data.details && data.details[0] && data.details[0].fileName ? data.details[0].fileName : '';
            const nameArray = data.name.split('#;');
            data.name = nameArray[0] === data.name ? data.name : data.name.split('#;')[0];
            if (data.details && data.details[0] && data.details[0].fileId) {
                data.fileId = data.details[0].fileId;
            }
            if (data.details.length === 0) {
                data.status = 'In Progress';
                data.completed = data.requested;
                data.fileType = '';
            }
            if (data.details.length > 1) {
                data.isExpandable = true;
                data.details.forEach((element, i) => {
                    element.headerId = data.headerId;
                    element.documentLink = data.documentLink;
                    element.name = data.name;
                    element.type = data.type;
                    element.requested = data.requested;
                    element.completed = data.completed;
                    if (data.availableFormats === '' || !data.availableFormats) {
                        element.fileType = '';
                    }
                    else {
                        const temp = data.availableFormats.split(',').reverse();
                        if (temp) {
                            const tempIndex = temp[i];
                            if (tempIndex) {
                                const trimmed = tempIndex.trim();
                                if (trimmed) {
                                    element.fileType = trimmed;
                                }
                            }
                            else {
                                element.fileType = '';
                            }
                        }
                        else {
                            element.fileType = '';
                        }
                    }
                });
            }
        });

        return result;
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    downloadReport(downloadQuery: string): Observable<any> {
        const options$ = this.apiOptions.getAuthedHttpOptions();
        const params = new HttpParams({ fromString: `filename=${downloadQuery}` });

        return options$.pipe(
            switchMap((options) => this.httpClient.get<Blob>(environment.api.inboxDownload, {
                params,
                ...options,
                responseType: 'blob' as 'json'
            })),
            catchError((err: HttpErrorResponse) => {
                if (this.currentRoute.url === '/inbox') {
                    this.toastService.add({
                        content: this.translate.instant('INBOX.INVALID_DOWNLOAD'),
                        theme: 'error',
                        closeable: true,
                        autoClose: true,
                        id: 'INBOX.INVALID_DOWNLOAD'
                    });
                }
                if (err.status === 404 || err.status === 403) {
                    return of({});
                }
                throw err;
            })
        );
    }

    deleteReport(id: string) {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.delete<string>(`${environment.api.inbox}/${id}`,
                {
                    ...options,
                    responseType: 'json'
                })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    updateInboxStatus(id: string): Observable<string> {
        const options$ = this.apiOptions.getAuthedHttpOptions();

        return options$.pipe(
            switchMap((options) => this.httpClient.put<string>(`${environment.api.inbox}/${id}`, {}, { ...options })),
            catchError((err: HttpErrorResponse) => {
                throw err;
            })
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileError(event: any) {
        const err = this.getErrorMessage(event.value);
        this.toastService.add({
            content: err,
            id: 'fileError',
            theme: 'error',
            closeable: true,
            autoClose: true
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getErrorMessage(value: any): string {
        if (value.details && value.details instanceof Array) {
            return value.details[0].error;
        }

        return value.error;
    }
}
