import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'private/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { CreateEmailValidateComponent } from './createEmailvalidate.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        TranslateModule,
        SafePipeModule
    ],
    declarations: [
        CreateEmailValidateComponent
    ]
})
export class CreateEmailValidateModule { }
