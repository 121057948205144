import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ConnectedPortalAlert } from '../components/connected-portal-alerts/connected-portal-alerts.component';
import { BasicCSV, CSVExportService } from 'common/services/csv-export.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConnectedPortalDealerDashboardAlertsService implements OnDestroy {
    ngOnDestroy$ = new Subject();
    alerts$ = new BehaviorSubject<ConnectedPortalAlert[]>([]);
    selectedAlertIds$ = new BehaviorSubject<string[]>([]);

    selectedCount$ = this.selectedAlertIds$.pipe(map((alerts) => alerts.length));

    allSelected$ = combineLatest([this.selectedAlertIds$, this.alerts$]).pipe(map(([selectedAlerts, alerts]) => {
        if (!selectedAlerts.length || !alerts.length) {
            return false;
        }

        const allIsSelected = alerts.map((alert) => alert.id).every((alertId) => selectedAlerts.includes(alertId));

        return allIsSelected;
    }))

    constructor(
        private csvExportService: CSVExportService,
        private translateService: TranslateService
    ) {}

    init() {
        return combineLatest([this.alerts$, this.selectedAlertIds$]).pipe(
            map(([alerts, ids]) => alerts.map((alert) => {
                if (ids.includes(alert.id)) {
                    return {
                        ...alert,
                        selectedForRemoval: true
                    };
                }

                return alert;
            })),
            takeUntil(this.ngOnDestroy$)
        );
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    selectAll() {
        const alerts = this.alerts$.value;
        const ids = alerts.map((alert) => alert.id);

        this.addAlertIds(ids);
    }

    unselectAll() {
        this.selectedAlertIds$.next([]);
    }

    updateAlerts(alerts: ConnectedPortalAlert[]) {
        this.alerts$.next(alerts);
    }


    addAlertIds(alertIds: string[]) {
        const nextIds = this.getUniqueIds(
            this.selectedAlertIds$
                .getValue()
                .concat(alertIds)
        );

        this.selectedAlertIds$.next(nextIds);
    }

    removeAlertIds(alertIds: string[]) {
        const nextIds = this.getUniqueIds(
            this.selectedAlertIds$
                .getValue()
                .filter((id) => !alertIds.includes(id))
        );

        this.selectedAlertIds$.next(nextIds);
    }

    exportAlerts(alerts: ConnectedPortalAlert[], documentTitle: string = 'alerts-export') {
        const alertsCsvData = alerts.reduce((acc, curAlert, index) => {
            const { datetime, errorCode, errorMessage, customerPhone, customerName, alertLevel, modelNumber, serialNumber } = curAlert;

            const row = {
                datetime,
                alertLevel,
                modelNumber,
                serialNumber,
                errorCode,
                errorMessage,
                customerName,
                customerPhone
            };

            if (index === 0) {
                acc.headings = Object.keys(row).map((key) => this.translateService.instant(`CONNECTED_PORTAL.ALERTS.EXPORT_HEADINGS.${key}`));
            }

            acc.body.push(Object.values(row));

            return { ...acc };
        }, {
            headings: [],
            body: []
        } as BasicCSV);

        const csvData = this.csvExportService.toCSV(alertsCsvData);
        this.csvExportService.saveAs(csvData, documentTitle);
    }

    resetIdList() {
        this.selectedAlertIds$.next([]);
    }

    getSelectedAlertIds() {
        return this.selectedAlertIds$.getValue();
    }

    private getUniqueIds(ids: string[]) {
        return Array.from(new Set(ids));
    }
}
