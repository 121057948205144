<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-settings-header">
            <hvac-content-header [data]="breadCrumbData">
                <hvac-distributor-navigation *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>
        <hvac-resources-page></hvac-resources-page>
    </div>
</hvac-connected-portal-layout-container>
