/* tslint:disable:max-line-length */
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { PagePositionService, ViewportState } from 'common/services/page-position.service';
import { UrlService } from 'common/services/url.service';
import { WINDOW } from 'common/window.provider';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DetailSection, DetailSectionService } from '../../services/detail-section.service';
/* tslint:enable:max-line-length */

interface ScrollToLocation {
    offset: number;
    id: string;
}

@Component({
    selector: 'utc-product-subnav',
    templateUrl: './subnav.component.html',
    styleUrls: ['./subnav.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubnavComponent implements OnInit, OnDestroy {
    @Input() showPlaceholder: boolean;

    ngOnDestroy$ = new Subject();
    parent!: HTMLElement;
    sections$: Observable<DetailSection[]> = this.detailSectionService.sections$.pipe(
        takeUntil(this.ngOnDestroy$)
    );

    headerHeight = 64;
    subnavHeight = 0;
    defaultSubnavHeight = 62;
    activeHighlightCushion = 10;
    setFocusBuffer = 10;
    scrollTo$: Subject<ScrollToLocation|null> = new Subject();

    activeSection$: Observable<DetailSection|undefined> = combineLatest([
        this.sections$,
        this.pagePositionService.viewportState$
    ]).pipe(
        takeUntil(this.ngOnDestroy$),
        filter(([sections]) => Boolean(sections.length)),
        map(([sections, viewportState]) => {
            const scrollTop = viewportState.scroll.top;
            const activeSection = sections.find((section) => {
                const topOffset = this.subnavHeight + this.activeHighlightCushion;
                const sectionTop = this.pagePositionService.getAbsoluteElementPosition(section.container).top - topOffset;
                const sectionBottom = sectionTop + section.container.offsetHeight;

                return scrollTop >= sectionTop && scrollTop < sectionBottom;
            });

            return activeSection;
        }),
        tap(() => this.cdr.detectChanges())
    );

    sticky$ = this.pagePositionService.viewportState$.pipe(
        takeUntil(this.ngOnDestroy$),
        map((viewportState: ViewportState) => {
            const cutoff = this.parent && this.pagePositionService.getAbsoluteElementPosition(this.parent).top || Infinity;

            return viewportState.scroll.top >= cutoff;
        }),
        distinctUntilChanged(),
        tap(() => this.cdr.detectChanges())
    );

    constructor(
        private readonly pagePositionService: PagePositionService,
        private readonly detailSectionService: DetailSectionService,
        private readonly element: ElementRef,
        private readonly cdr: ChangeDetectorRef,
        private readonly urlService: UrlService,
        @Inject(WINDOW) private readonly window: Window
    ) {}

    ngOnInit() {
        this.parent = this.element.nativeElement.offsetParent;
        this.subnavHeight = this.element.nativeElement.firstElementChild.offsetHeight ?
            this.element.nativeElement.firstElementChild.offsetHeight :
            this.defaultSubnavHeight;

        this.scrollTo$.pipe(
            takeUntil(this.ngOnDestroy$),
            switchMap((scrollTo) => this.pagePositionService.viewportState$.pipe(
                map((pagePosition) => ({
                    scrollTo,
                    pagePosition
                }))
            )),
            filter(({ scrollTo }) => Boolean(scrollTo)),
            map(({ scrollTo, pagePosition }) => ({
                scrollTo,
                pagePosition
            } as { scrollTo: ScrollToLocation, pagePosition: ViewportState })),
            filter(({ scrollTo, pagePosition }) => {
                const { scroll } = pagePosition;
                const { offset } = scrollTo;
                const under = offset - this.setFocusBuffer;
                const over = offset + this.setFocusBuffer;
                const scrolledNear = scroll.top >= under && scroll.top <= over;

                return scrolledNear || !scroll.changed;
            }),
            distinctUntilChanged((a, b) => a.scrollTo.id === b.scrollTo.id)
        ).subscribe(({ scrollTo }) => {
            this.scrollTo$.next(null);
            if (scrollTo) {
                this.urlService.skipToContent(scrollTo.id);
            }
        });
    }

    ngOnDestroy() {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }

    scrollToSection(section: DetailSection, id: string) {
        const containerTop = this.pagePositionService.getAbsoluteElementPosition(section.container).top;
        const currentTop = window.pageYOffset || document.documentElement.scrollTop;
        const topOffset = containerTop - this.subnavHeight - (currentTop > containerTop ? this.headerHeight : 0);
        this.window.scrollTo({
            left: 0,
            top: topOffset,
            behavior: 'smooth'
        });
        this.scrollTo$.next({
            offset: topOffset,
            id
        });
    }
}
