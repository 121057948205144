<div class="hvac-inbox-holder hvac-content-container">
    <utc-loading-spinner class="hvac-inbox-holder" *ngIf="!(inboxInfo$ | async)" [spinnerSize]="'small'">
    </utc-loading-spinner>
    <div *ngIf="inboxInfo$ | async">
        <div class='hvac-content-breadcrumb-container'>
            <span class='hvac-content-crumb-container'> <a [routerLink]="['/']" class='hvac-subtext'>My
                    Dashboard</a><span class='hvac-breadcrumb-divider'>/</span></span>
        </div>
        <h2 class="hvac-inbox-title">{{ 'INBOX.TITLE' | translate }}</h2>
        <div class="havc-inbox-header">
            <div class="title">{{ 'INBOX.HEADING.REPORTING' | translate }}</div>
            <div class="sub-line">{{ 'INBOX.HEADING.REPORTING_SUB' | translate }}</div>
        </div>
        <div #bannerContainer class="hvac-inbox-banner">
            <hvac-toast [name]="toastOutlet"></hvac-toast>
        </div>
        <div>
            <button [disabled]="disableRefresh" hvacButton="primary" size="small" (click)="refreshInboxInfo()">{{'INBOX.REFRESH' | translate}} <utc-svg src="assets/icons/refresh.svg"></utc-svg></button>
        </div>
        <hvac-data-table
            [data]="(inboxInfo$ | async)!"
            [dataHeaders]="columnHeaders"
            (onClick)="onClick($event)"
            (handleExpandedRowEvent)="readMultiInboxReport($event)"
            [contextMenu]="contextMenuData"
            [dataSort]="dataSort"
            headerNote="{{ 'INBOX.INFO_NOTE' | translate  }}">
        </hvac-data-table>
    </div>
</div>
