<div class="hvac-fad-report">
    <hvac-report-chart-container [chartTitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.FAD_REPORT' | translate"
        [chartSubtitle]="'CONNECTED_PORTAL.FACTORY_DASHBOARD.YEAR_TO_DATE_ANALYSIS' | translate"
        [hasData]="!!(data?.filepath)" [isLoading]="isLoading"
        yearSelectId="hvac-connected-control-report-year-select" [yearSelectFormControl]="yearSelectFormControl"
        [yearSelectOptions]="yearSelectOptions" [isReportButtonDisabled]="!data?.filepath"
        (onRunReport)="handleRunReport()">
            <div class="hvac-fad-report-logo-container">
                <img class="hvac-fad-report-logo" [src]="logoImage$ | async"/>
            </div>
    </hvac-report-chart-container>
</div>
