<div class="hvac-row">
    <div *ngIf="(isLoading$ | async); else content" class="hvac-content-loader">
        <utc-loading-spinner></utc-loading-spinner>
    </div>

    <ng-template #content>
        <ng-container *ngIf="(pageData$ | async) as data">
            <div class="hvac-row" style="position: relative">
                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-cp-system-conditions hvac-rhythm-3">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-6 hvac-col-md-12 hvac-cp-system-conditions-wrap">
                            <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS' | translate}}</h2>

                            <div *ngIf="data.systemConditions.isInfinity; else ecobeeSystemConditions" class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_TEMP' | translate"
                                        [value]="data.systemConditions.indoorTemp | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_HUMIDITY' | translate"
                                        [value]="data.systemConditions.relativeHumidity | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Percent"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3">
                                    <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.OUTDOOR_TEMP' | translate"
                                        [value]="data.systemConditions.outdoorTemp | emptyToChar: CharSymbol.DoubleDash | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                </div>
                            </div>

                            <ng-template #ecobeeSystemConditions>
                                <div class="hvac-row">
                                    <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemConditions.indoorTemp === CharSymbol.DoubleDash"></utc-svg>
                                        <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_TEMP' | translate"
                                            [value]="data.systemConditions.indoorTemp | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                    </div>

                                    <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemConditions.indoorHumidity === CharSymbol.DoubleDash"></utc-svg>
                                        <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.INDOOR_HUMIDITY' | translate"
                                            [value]="data.systemConditions.indoorHumidity | numberSuffix: CharSymbol.Percent"></hvac-stats-widget>
                                    </div>

                                    <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemConditions.outdoorTemp === CharSymbol.DoubleDash"></utc-svg>
                                        <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.OUTDOOR_TEMP' | translate"
                                            [value]="data.systemConditions.outdoorTemp | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                    </div>

                                    <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-3 hvac-cp-ecobee-system-reading">
                                        <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemConditions.outdoorHumidity === CharSymbol.DoubleDash"></utc-svg>
                                        <hvac-stats-widget [label]="'CONNECTED_PORTAL.WALL_CONTROL.OUTDOOR_HUMIDITY' | translate"
                                            [value]="data.systemConditions.outdoorHumidity | numberSuffix: CharSymbol.Percent"></hvac-stats-widget>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="hvac-col-xs-6 hvac-col-md-12">
                            <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_SETTINGS' | translate}}</h2>

                            <div class="hvac-row">
                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1" [ngClass]="{'hvac-cp-ecobee-system-reading': data.systemSettings.isEcobee}">
                                    <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemSettings.isCoolToOutOfRange"></utc-svg>
                                    <hvac-stats-widget class="hvac-cp-cool" [label]="'CONNECTED_PORTAL.WALL_CONTROL.COOL_TO' | translate" [value]="data.systemSettings.coolTo | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md hvac-rhythm-1" [ngClass]="{'hvac-cp-ecobee-system-reading': data.systemSettings.isEcobee}">
                                    <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg" *ngIf="data.systemSettings.isHeatToOutOfRange"></utc-svg>
                                    <hvac-stats-widget  class="hvac-cp-heat" [label]="'CONNECTED_PORTAL.WALL_CONTROL.HEAT_TO' | translate" [value]="data.systemSettings.heatTo | numberSuffix: CharSymbol.Fahrenheit"></hvac-stats-widget>
                                </div>

                                <div class="hvac-col-xs-12 hvac-col-md"></div>
                            </div>
                        </div>

                        <div class="hvac-col-lg-12 hvac-cp-system-condition-warning" *ngIf="data.systemSettings.isOutOfRangeWarningVisible">
                            <utc-svg class="hvac-cp-system-condition-warning-icon" src="assets/icons/info.svg"></utc-svg> <div class="hvac-p-sm hvac-fw-rg hvac-cp-system-condition-warning-text">{{ 'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_CONDITIONS_MESSAGES.PARAMETER_OUT_OF_RANGE' | translate }}</div>
                        </div>

                    </div>
                </div>

                <div class="hvac-col-xs-12 hvac-col-lg-6 hvac-cp-system-mode hvac-rhythm-3">
                    <div class="hvac-row">
                        <div class="hvac-col-xs-12">
                            <h2 class="hvac-h4 hvac-rhythm-3">{{'CONNECTED_PORTAL.WALL_CONTROL.SYSTEM_MODE' | translate}}</h2>

                            <div class="hvac-row">
                                <div class="hvac-col-xs-12">
                                    <div class="hvac-row">
                                        <div class="hvac-col-xs-12 hvac-col-lg-4">
                                            <div *ngIf="data.systemMode.isSystemModeEnabled" class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-3">
                                                <div class="hvac-col-xs hvac-col-lg-12 hvac-rhythm-2">
                                                    <div class="hvac-subhead hvac-cp-heading">{{'CONNECTED_PORTAL.WALL_CONTROL.USER_SETTINGS' | translate}}</div>
                                                </div>

                                                <div class="hvac-col-xs hvac-col-lg-12 hvac-start-lg">
                                                    <hvac-data-block [status]="data.systemMode.userSetting === 'auto' ? 'success' : 'inactive'">
                                                        {{ data.systemMode.userSetting }}
                                                    </hvac-data-block>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="data.systemMode.isEquipmentActivityEnabled" class="hvac-col-xs-12 hvac-col-lg-8">
                                            <div class="hvac-row hvac-rhythm-2">
                                                <div class="hvac-col-xs">
                                                    <div class="hvac-subhead hvac-cp-heading">{{'CONNECTED_PORTAL.WALL_CONTROL.EQUIPMENT_ACTIVITY' | translate}}</div>
                                                </div>
                                            </div>

                                            <div class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-1" *ngIf="data.systemMode.fan">
                                                <div class="hvac-col-xs">
                                                    <div class="hvac-subhead">{{ data.systemMode.fan.label }}</div>
                                                </div>

                                                <div class="hvac-col-xs">
                                                    <hvac-data-block style="text-align: center;" [status]="data.systemMode.fan.value === 'ON' ? 'success' : 'inactive'" width="30px">
                                                        {{ data.systemMode.fan.value }}
                                                    </hvac-data-block>
                                                </div>
                                            </div>

                                            <ng-container *ngIf="data.systemMode.heating">
                                                <div class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-1" *ngFor="let mode of data.systemMode.heating">
                                                    <div class="hvac-col-xs">
                                                        <div class="hvac-subhead">{{mode.label.toUpperCase() | translate}}</div>
                                                    </div>

                                                    <div class="hvac-col-xs">
                                                        <hvac-data-block style="text-align: center;" [status]="mode.value.toLowerCase() === 'off' ? 'inactive' : 'success'"  width="30px">
                                                            {{mode.value}}
                                                        </hvac-data-block>
                                                    </div>
                                                </div>
                                            </ng-container>


                                            <div class="hvac-row hvac-between-xs hvac-middle-xs hvac-rhythm-1" *ngIf="data.systemMode.cooling">
                                                <div class="hvac-col-xs">
                                                    <div class="hvac-subhead">{{data.systemMode.cooling.label.toUpperCase()}}</div>
                                                </div>

                                                <div class="hvac-col-xs">
                                                    <hvac-data-block style="text-align: center;" [status]="data.systemMode.cooling.value.toLowerCase() === 'off' ? 'inactive' : 'success'"  width="30px">
                                                        {{data.systemMode.cooling.value}}
                                                    </hvac-data-block>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="hvac-col-xs-12">
                    <hvac-wall-control-program-settings
                        *ngIf="data.programSettings.isProgramSettingsVisible"
                        [data]="data.programSettings">
                    </hvac-wall-control-program-settings>
                </div>
            </div>
        </ng-container>
    </ng-template>
</div>
