import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BaseComponent } from 'common/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Option } from 'common/components/select-new/select-new.component';
import { InputErrorObject, getErrorMessage } from 'common/utils/createAccountValidators';
import { takeUntil, map, tap } from 'rxjs/operators';

export interface CountryAndStates extends Option {
    states?: Option[];
}

@Component({
    selector: 'hvac-connected-portal-advanced-search-fields',
    templateUrl: './connected-portal-advanced-search-fields.component.html',
    styleUrls: ['./connected-portal-advanced-search-fields.component.scss']
})
export class ConnectedPortalAdvancedSearchFieldsComponent extends BaseComponent implements OnInit {
    @Input() countryList$: Observable<CountryAndStates[]>;
    @Input() countriesAndStates$: Observable<CountryAndStates[]>;
    @Input() controlModelNumbers: Option[];
    @Output() searchEvent = new EventEmitter();
    public openState: boolean = true;
    public advancedSearchForm: UntypedFormGroup;
    public dataSharingOptionsForm: UntypedFormGroup;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    public controlSerialNoErrorMessage$: Observable<any>;
    public indoorSerialNoErrorMessage$: Observable<any>;
    public outdoorSerialNoErrorMessage$: Observable<any>;
    public stateList: Option[];
    private errorMessages$ = this.translate.get('CONNECTED_PORTAL.ADVANCED_SEARCH.VALIDATION.ERROR_MESSAGES');
    private advancedSearchFormInitialValue: Record<string, unknown> | null = null;
    private dataSharingOptionsFormInitialValue: Record<string, unknown> | null = null;
    private countryAndStateList: CountryAndStates[];

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.initializeCountryAndStateData();
        this.initializeForms();
        this.setValueChangeListeners();
    }

    toggleOpenSate() {
        this.openState = !this.openState;
    }

    searchCustomers() {
        this.searchEvent.emit({
            advancedSearchForm: this.advancedSearchForm,
            dataSharingOptionsForm: this.dataSharingOptionsForm
        });
    }

    clearFilters() {
        this.advancedSearchForm.reset(this.advancedSearchFormInitialValue);
        this.dataSharingOptionsForm.reset(this.dataSharingOptionsFormInitialValue);
    }

    private initializeCountryAndStateData() {
        this.countryList$ = this.countriesAndStates$?.pipe(
            map((countries) => [...countries].reverse()),
            tap((data) => {
                this.countryAndStateList = data;
                this.getStateListByCountry('');
            }),
            map((countries) => countries.map((country) => {
                const data = { ...country };
                delete data?.states;

                return data;
            }))
        );
    }

    private getStateListByCountry(code: string) {
        this.stateList = <Option[]> this.countryAndStateList
            ?.filter((item) => (code ? code === item.value : true))
            .map((item) => item?.states)
            .flat()
            .sort((itemA, itemB) => (itemA as Option).name.localeCompare((itemB as Option).name));
    }

    private initializeForms() {
        this.advancedSearchForm = new UntypedFormGroup({
            firstName: new UntypedFormControl(''),
            lastName: new UntypedFormControl(''),
            email: new UntypedFormControl(''),
            controlSerialNo: new UntypedFormControl('', [this.createAdvancedSearchInputValidator(4, false)]),
            indoorSerialNo: new UntypedFormControl('', [this.createAdvancedSearchInputValidator(4, false)]),
            outdoorSerialNo: new UntypedFormControl('', [this.createAdvancedSearchInputValidator(4, false)]),
            controlModelNo: new UntypedFormControl(''),
            indoorModelNo: new UntypedFormControl(''),
            outdoorModelNo: new UntypedFormControl(''),
            dealerName: new UntypedFormControl(''),
            dealerHVPID: new UntypedFormControl(''),
            esn: new UntypedFormControl(''),
            city: new UntypedFormControl(''),
            state: new UntypedFormControl(''),
            country: new UntypedFormControl(''),
            viewStatus: new UntypedFormControl(''),
            viewConfig: new UntypedFormControl(''),
            editConfig: new UntypedFormControl('')
        });

        this.dataSharingOptionsForm = new UntypedFormGroup({
            viewStatusEnabled: new UntypedFormControl(''),
            viewStatusDisabled: new UntypedFormControl(''),
            viewConfigEnabled: new UntypedFormControl(''),
            viewConfigDisabled: new UntypedFormControl(''),
            editConfigEnabled: new UntypedFormControl(''),
            editConfigDisabled: new UntypedFormControl('')
        });

        this.advancedSearchFormInitialValue = this.advancedSearchForm.value;
        this.dataSharingOptionsFormInitialValue = this.dataSharingOptionsForm.value;
    }

    private createAdvancedSearchInputValidator(minLength: number, isRequired: boolean): ValidatorFn {
        return (control: AbstractControl): InputErrorObject | null => {
            const errorObject: InputErrorObject = {};
            const controlValue = control.value;
            if (typeof controlValue !== 'undefined') {
                if (isRequired && controlValue.trim() === '') {
                    errorObject.REQUIRED = true;
                }
                if (minLength && (controlValue.length < minLength && controlValue.length > 0)) {
                    errorObject.LENGTH = true;
                }
                if (Object.keys(errorObject).length !== 0) {
                    return errorObject;
                }
            }

            return null;
        };
    }

    private setValueChangeListeners() {
        this.advancedSearchForm.controls.country.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((selectedCountry) => {
            this.advancedSearchForm.controls.state.setValue('');
            this.getStateListByCountry(selectedCountry);
        });


        this.dataSharingOptionsForm.controls.viewStatusEnabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isViewStatusDisabled = this.dataSharingOptionsForm.controls.viewStatusDisabled.value;

            if (val && isViewStatusDisabled) {
                this.dataSharingOptionsForm.controls.viewStatusDisabled.setValue(false);
            }
        });

        this.dataSharingOptionsForm.controls.viewStatusDisabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isViewStatusEnabled = this.dataSharingOptionsForm.controls.viewStatusEnabled.value;

            if (val && isViewStatusEnabled) {
                this.dataSharingOptionsForm.controls.viewStatusEnabled.setValue(false);
            }
        });

        this.dataSharingOptionsForm.controls.viewConfigEnabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isviewConfigDisabled = this.dataSharingOptionsForm.controls.viewConfigDisabled.value;

            if (val && isviewConfigDisabled) {
                this.dataSharingOptionsForm.controls.viewConfigDisabled.setValue(false);
            }
        });

        this.dataSharingOptionsForm.controls.viewConfigDisabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isviewConfigEnabled = this.dataSharingOptionsForm.controls.viewConfigEnabled.value;

            if (val && isviewConfigEnabled) {
                this.dataSharingOptionsForm.controls.viewConfigEnabled.setValue(false);
            }
        });

        this.dataSharingOptionsForm.controls.editConfigEnabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isEditConfigDisabled = this.dataSharingOptionsForm.controls.editConfigDisabled.value;

            if (val && isEditConfigDisabled) {
                this.dataSharingOptionsForm.controls.editConfigDisabled.setValue(false);
            }
        });

        this.dataSharingOptionsForm.controls.editConfigDisabled.valueChanges.pipe(
            takeUntil(this.ngOnDestroy$)
        ).subscribe((val: boolean) => {
            const isEditConfigEnabled = this.dataSharingOptionsForm.controls.editConfigEnabled.value;

            if (val && isEditConfigEnabled) {
                this.dataSharingOptionsForm.controls.editConfigEnabled.setValue(false);
            }
        });

        this.controlSerialNoErrorMessage$ = combineLatest([
            this.advancedSearchForm.controls.controlSerialNo.statusChanges, this.errorMessages$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([, message]) => getErrorMessage(this.advancedSearchForm.controls.controlSerialNo.errors, message))
        );

        this.indoorSerialNoErrorMessage$ = combineLatest([
            this.advancedSearchForm.controls.indoorSerialNo.statusChanges, this.errorMessages$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([, message]) => getErrorMessage(this.advancedSearchForm.controls.indoorSerialNo.errors, message))
        );

        this.outdoorSerialNoErrorMessage$ = combineLatest([
            this.advancedSearchForm.controls.outdoorSerialNo.statusChanges, this.errorMessages$
        ]).pipe(
            takeUntil(this.ngOnDestroy$),
            map(([, message]) => getErrorMessage(this.advancedSearchForm.controls.outdoorSerialNo.errors, message))
        );
    }
}
