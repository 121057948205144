import { AfterContentChecked, Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { SystemType } from 'private/app/models/connected-product.model';
import { DataSharing } from 'private/app/models/connected-system.model';
import { Tooltip, Placement } from 'private/app/views/connected-portal/directives/tooltip.directive';

@Component({
    selector: 'hvac-system-data-sharing-header',
    templateUrl: './system-data-sharing-header.component.html',
    styleUrls: ['./system-data-sharing-header.component.scss']
})
export class SystemDataSharingHeaderComponent implements AfterContentChecked {
    @Input() dataSharing: DataSharing
    @Input() systemType: string
    @ViewChild('viewStatusEnabled', { static: false }) viewStatusEnabled :TemplateRef<ElementRef>;
    @ViewChild('viewStatusDisabled', { static: false }) viewStatusDisabled :TemplateRef<ElementRef>;
    @ViewChild('viewConfigEnabled', { static: false }) viewConfigEnabled :TemplateRef<ElementRef>;
    @ViewChild('viewConfigDisabled', { static: false }) viewConfigDisabled :TemplateRef<ElementRef>;
    @ViewChild('editConfigEnabled', { static: false }) editConfigEnabled :TemplateRef<ElementRef>;
    @ViewChild('editConfigDisabled', { static: false }) editConfigDisabled :TemplateRef<ElementRef>;
    viewStatusEnabledOptions: Tooltip;
    viewStatusDisabledOptions: Tooltip;
    viewConfigEnabledOptions: Tooltip;
    viewConfigDisabledOptions: Tooltip;
    editConfigEnabledOptions: Tooltip;
    editConfigDisabledOptions: Tooltip;
    readonly SystemType = SystemType;

    ngAfterContentChecked(): void {
        this.setTooltipOptions();
    }

    setTooltipOptions() {
        const defaultParams = {
            placement: Placement.TOP,
            distance: 5,
            width: 300,
            height: 50
        };

        this.viewStatusEnabledOptions = {
            template: this.viewStatusEnabled,
            ...defaultParams
        };
        this.viewStatusDisabledOptions = {
            template: this.viewStatusDisabled,
            ...defaultParams
        };
        this.viewConfigEnabledOptions = {
            template: this.viewConfigEnabled,
            ...defaultParams
        };
        this.viewConfigDisabledOptions = {
            template: this.viewConfigDisabled,
            ...defaultParams
        };
        this.editConfigEnabledOptions = {
            template: this.editConfigEnabled,
            ...defaultParams
        };
        this.editConfigDisabledOptions = {
            template: this.editConfigDisabled,
            ...defaultParams
        };
    }
}
