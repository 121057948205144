<utc-modal class="hvac-relationship-modal" [visible]="adminService.isModalVisible"
(closeAction)="handleCloseModal()" [displayWarning]="true">
    <hvac-modal-header>
        <div class="hvac-relationship-modal-header">
            <h1>
                {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.MANAGE_RELATIONSHIPS" | translate }}
            </h1>
            <button buttonType="button" class="hvac-relationship-button" hvacButton="default" size="small" *ngIf="!isEditModal" [disabled]="tableData.length === 0"
            (click)="showRelationshipList = true">
            {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.NEW_RELATIONSHIP" | translate }}
                <span class="">{{tableData.length}}</span>
            </button>
        </div>
    </hvac-modal-header>
    <div class="hvac-relationship-modal-tabs" *ngIf="!showRelationshipList">
        <hvac-tab-bar variant="secondary">
            <button buttonType="button" *ngIf="(companyConfiguration.companyType !== 'NBR' && companyConfiguration.companyType !== 'ZNBR')" (click)="handleTabClick('branded')"
                [ngClass]="{ 'hvac-active' : selectedTab === 'branded', 'hvac-hide-tab': isEditModal && selectedTab !== 'branded'}">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.BRANDED"
                | translate }}
                <span class="hvac-relationship-modal-tabs-desktop">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP" |
                    translate }}</span>
            </button>
            <button buttonType="button" *ngIf="companyConfiguration.companyType !== 'VEN' && companyConfiguration.companyType !== 'ZVEN'" (click)="handleTabClick('pricing')"
                [ngClass]="{ 'hvac-active' : selectedTab === 'pricing', 'hvac-hide-tab': isEditModal && selectedTab !== 'pricing'}">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.PRICING"
                | translate }}
                <span class="hvac-relationship-modal-tabs-desktop">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP" |
                    translate }}</span>
            </button>
            <button buttonType="button" *ngIf="companyConfiguration.companyType !== 'VEN' && companyConfiguration.companyType !== 'ZVEN'" (click)="handleTabClick('warranty')"
                [ngClass]="{ 'hvac-active' : selectedTab === 'warranty', 'hvac-hide-tab': isEditModal && selectedTab !== 'warranty'}">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.WARRANTY"
                | translate }}
                <span class="hvac-relationship-modal-tabs-desktop">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP" |
                    translate }}</span>
            </button>
        </hvac-tab-bar>
        <p class="hvac-required-text"><abbr title="required"
            class="hvac-relationship-required-field">*</abbr>{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.REQUIRED_FIELD"
        |
        translate }}</p>
    </div>
    <div class="hvac-relationship-modal-body">
        <div class="hvac-relationship-modal-form-container" *ngIf="!showRelationshipList">
            <form [formGroup]="relationshipForm">
                <div class="hvac-row hvac-relationship-containers">
                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6" *ngIf="(formConfig$ | async)?.brand">
                        <label class="hvac-label hvac-required">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.BRANDS" | translate
                            }}</label>
                        <utc-select class="hvac-col-xs hvac-relationship-select" [group]="relationshipForm" control='brand'
                            [options]="brandList" shadow="light" size="default" [disabled]="isEditModal"
                            [error]="(brandError$ | async) || relationshipForm.controls.brand.dirty && !relationshipForm.controls.brand.valid"
                            defaultOption="{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SELECT_BRANDS' | translate }}"
                            [errorMessage]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.BRANDS_REQUIRED' | translate">
                        </utc-select>
                    </div>
                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6">
                        <label class="hvac-label hvac-required">{{ "ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_NAME_OR_HVAC_ID" |
                            translate }}</label>
                        <hvac-autocomplete [inputControl]='$any(relationshipForm.controls.distributorCompanyName)'
                            [data]="( companyLookUpData$ | async ) || []" class="hvac-col-xs"
                            (onSelect)="onCompanyLookUp($event)"
                            (onLoadMore)="companyLookupWithPagination()" [disabledInput]="isEditModal"
                            [error]=" (companyDetailsError$ | async) || relationshipForm.controls.distributorCompanyName.dirty && !relationshipForm.controls.distributorCompanyName.valid"
                            [placeholder]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_NAME_OR_ID_PLACEHOLDER' | translate"
                            [errorMessage]="isRelationshipExists ? ('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.RELATIONSHIP_EXISTS' | translate): ('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.NAME_REQUIRED' | translate)"
                            [event]="(autoComplete$ | async) || {loading: false}">
                        </hvac-autocomplete>
                    </div>

                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6" *ngIf="(formConfig$ | async)?.cinNumber">
                        <label class="hvac-label hvac-required">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CIN_NUMBER" | translate }}
                        </label>
                        <utc-input class="hvac-col-xs" [inputControl]='relationshipForm.controls.cinNumber' type="text" [disabled]="isEditModal"
                            [placeholder]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CIN_NUMBER_PLACEHOLDER' | translate" [disabledInput]="isEditModal"
                            [error]="(invalidCIN$ | async ) || relationshipForm.controls.cinNumber.dirty && !relationshipForm.controls.cinNumber.valid"
                            [errorMessage]=" (invalidCIN$ | async ) ? ('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CIN_INVALID' | translate) : (CINLengthExceeded$ | async) ? ('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CIN_LENGTH' | translate) : ('ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CIN_REQUIRED' | translate)">
                        </utc-input>
                    </div>

                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6" *ngIf="(formConfig$ | async)?.tierLevel">
                        <label class="hvac-label">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.TIER_LEVEL" | translate }}
                        </label>
                        <utc-select class="hvac-col-xs hvac-relationship-select" [group]="relationshipForm" control="tierLevel"
                            [options]="companyRelationshipService.tierLevelOptions || []" shadow="light" size="default"
                            defaultOption="{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.TIER_LEVEL_PLACEHOLDER' | translate }}">
                        </utc-select>
                    </div>

                    <div class="hvac-col-xs-12 hvac-col-lg-3 hvac-col-md-6" *ngIf="(formConfig$ | async)?.claimType">
                        <label class="hvac-label hvac-required">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CLAIMS" | translate }}</label>
                        <utc-select class="hvac-col-xs hvac-relationship-select" [group]="relationshipForm" control="claims"
                            shadow="light" size="default" [disabled]="isEditModal"
                            defaultOption="{{ 'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.WARRANTY_CLAIM_DEFAULT'| translate }}"
                            [error]="relationshipForm.controls.claims.invalid && (relationshipForm.controls.claims.dirty || relationshipForm.controls.claims.touched)"
                            [options]="( companyRelationshipService.claimTypeOptions$ | async) || []"></utc-select>
                    </div>
                </div>
                <div class="hvac-row hvac-relationship-containers" *ngIf="companyDetails$ | async as companyDetails">
                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6">
                        <p class="hvac-label hvac-company-info-title">
                            {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_DETAILS" | translate }}</p>
                        <p class="hvac-info-value hvac-company-address-information hvac-label">
                            {{ (companyDetails$ | async)?.companyName }}<br />
                            {{ (companyDetails$ | async)?.address1 }}<br />
                            {{ (companyDetails$ | async)?.city }} {{ (companyDetails$ | async)?.state }} {{
                            (companyDetails$ | async)?.postalCode }}<br />
                        </p>
                    </div>

                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6">
                        <p class="hvac-label hvac-company-info-title">
                            {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMPANY_ID" | translate }}</p>
                        <p class="hvac-info-value hvac-company-address-information hvac-label">
                            {{ (companyDetails$ | async)?.hvacCompanyId }}
                        </p>
                    </div>
                    <div class="hvac-col-xs-12 hvac-col-lg-4 hvac-col-md-6">
                        <p class="hvac-label hvac-company-info-title">
                            {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SOLD_TO" | translate }}</p>
                        <p class="hvac-info-value hvac-company-address-information hvac-label">
                            {{ (companyDetails$ | async)?.soldTo }}
                        </p>
                    </div>
                </div>
                <div class="hvac-company-relationship-comment">
                    <label class="hvac-label">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMMENTS" | translate }}</label>
                    <br />
                    <utc-textarea class="hvac-company-relationship-input" shadow="light"
                        [placeholder]="'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.COMMENTS_PLACEHOLDER' | translate"
                        [inputControl]="relationshipForm.controls.comments"></utc-textarea>
                </div>
            </form>
        </div>

        <div class="hvac-relationship-modal-table-container" *ngIf="showRelationshipList">
            <hvac-table [responsive]="true">
                <thead>
                    <tr>
                        <td class="hvac-distributor-title-info">
                            {{ "ACCOUNT_ADMIN.COMPANY_INFO" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.TYPE" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.BRANDS" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.TIER_LEVEL" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.CLAIM_TYPE" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.CIN_NO" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.SOLD_TO" | translate }}
                        </td>
                        <td class="hvac-distributor-table-info">
                            {{ "ACCOUNT_ADMIN.SHIP_TO" | translate }}
                        </td>
                    </tr>
                </thead>
                <tbody *ngFor="let distributor of tableData">
                    <tr>
                        <td [attr.data-label]="
                                'ACCOUNT_ADMIN.COMPANY_INFO' | translate
                            " class="hvac-company-distributor-information hvac-company-distributor-company-address">
                            <div>
                                {{ distributor.dealerDistributor.distributorCompanyName }}
                                <br />
                                <div class="hvac-p-xs hvac-address-id-comments-information">
                                    {{ distributor.dealerDistributor.distributorAddress }}
                                    <br />
                                    {{ "ACCOUNT_ADMIN.COMPANY_ID" | translate }}:
                                    {{ distributor.dealerDistributor.distributorCompanyId }}
                                    <br />
                                    {{ "ACCOUNT_ADMIN.COMMENTS" | translate }}:
                                    {{ distributor.dealerDistributor.comment }}
                                </div>
                            </div>
                        </td>
                        <td [attr.data-label]="'ACCOUNT_ADMIN.TYPE' | translate"
                            class="hvac-company-distributor-information">
                            {{ distributor.relationshipType }}
                        </td>
                        <td [attr.data-label]="'ACCOUNT_ADMIN.BRANDS' | translate"
                            class="hvac-company-distributor-information">
                            {{ distributor.brandName }}
                        </td>
                        <td [attr.data-label]="
                                'ACCOUNT_ADMIN.TIER_LEVEL' | translate
                            " class="hvac-company-distributor-information">
                            {{ distributor.tierLevel }}
                        </td>
                        <td [attr.data-label]="
                                'ACCOUNT_ADMIN.CLAIM_TYPE' | translate
                            " class="hvac-company-distributor-information">
                            {{ companyRelationshipService.getClaimTypeName(distributor.claims) }}
                        </td>
                        <td [attr.data-label]="'ACCOUNT_ADMIN.CIN_NO' | translate"
                            class="hvac-company-distributor-information">
                            {{ distributor.cin }}
                        </td>
                        <td [attr.data-label]="'ACCOUNT_ADMIN.SOLD_TO' | translate"
                            class="hvac-company-distributor-information">
                            {{ distributor.soldTo || "" }}
                        </td>
                    </tr>
                    <tr class="hvac-relationship-modal-table-container-options">
                        <td colspan="9">
                            <div class="hvac-col-xs hvac-end-lg hvac-distributor-management-links">
                                <button buttonType="button" hvacButton="default" size="small" (click)="removeRelationship(distributor)">
                                    {{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.REMOVE" | translate}}
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </hvac-table>
        </div>
        <div class="hvac-relationship-modal-actions">
            <div class="hvac-relationship-modal-actions-container" [class.relationship-list]="showRelationshipList">
                <div class="hvac-relationship-modal-footer-link" *ngIf="showRelationshipList">
                    <a hvacButton="default" size="small"  class="hvac-text-link" (click)="showRelationshipList = false;">
                        <utc-svg class="hvac-back-arrow" src="assets/icons/arrow.svg"></utc-svg>
                        <div>{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.BACK_TO_ADDING_RELATIONSHIPS" | translate}}</div>
                    </a>
                </div>
                <div class="hvac-relationship-modal-footer-buttons">
                    <button buttonType="button" hvacButton="default" (click)="handleCloseModal()"
                    class="hvac-save-post-exit-button">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CANCEL" |
                    translate }}</button>
                    <button buttonType="button" hvacButton="primary" (click)="addRelationships()" *ngIf="showRelationshipList"
                        class="hvac-save-post-exit-button">{{isCreate ? ("ACCOUNT_ADMIN.CREATE_COMPANY" | translate) : ("ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SUBMIT" | translate)}} </button>
                    <button buttonType="button" hvacButton="primary" (click)="updateRelationship()" *ngIf="isEditModal"
                    class="hvac-save-post-exit-button">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.SAVE_CHANGES" | translate}}</button>

                    <button buttonType="button" hvacButton="primary" *ngIf="!showRelationshipList && !isEditModal" (click)="saveChanges()" [disabled]="!isRelationshipExists"
                        class="hvac-save-post-exit-button">{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.ADD_RELATIONSHIP" | translate
                    }}</button>
                </div>
            </div>
            <div class="hvac-relationship-modal-footer-link" *ngIf="!showRelationshipList && tableData.length > 0">
                <a hvacButton="default" size="small" (click)="showRelationshipList = true;" class="hvac-text-link">
                    <div>{{"ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.CONTINUE_NEXT_STEP" | translate}}</div>
                    <utc-svg src="assets/icons/arrow.svg"></utc-svg>
                </a>
            </div>
        </div>
    </div>
</utc-modal>

<utc-modal class='hvac-unsaved-changes-warning-modal' (closeAction)="unsavedChangesModalClose()" [visible]="unsavedChangesModal" theme='rounded' size='dynamic-height' [showHeader]="false">
    <div class="hvac-unsaved-changes-warning-wrapper">
        <h3 class="hvac-h3 hvac-warning-text">{{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.WARNING_TEXT' | translate }}</h3>
        <p class="hvac-h4">{{warningMessage}}</p>
        <p>{{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.WARNING_INFO_CONTINUED' | translate }}</p>
        <div class="hvac-divider hvac-button-divider"></div>
        <div class="hvac-unsaved-changes-warning-modal-actions">
            <button buttonType="button" hvacButton="default" (click)="unsavedChangesModalClose()">
                {{'ACCOUNT_ADMIN.CANCEL' | translate }}
            </button>
            <button buttonType="button" hvacButton="primary" (click)="removeUnsavedChanges()">
                {{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.CONTINUE' | translate }}
            </button>
        </div>
    </div>
</utc-modal>

<utc-modal class='hvac-unsaved-changes-warning-modal' (closeAction)="unsavedRelationshipsModalClose()" [visible]="unsavedRelationshipsModal" theme='rounded' size='dynamic-height' [showHeader]="false">
    <div class="hvac-unsaved-changes-warning-wrapper">
        <h3 class="hvac-h3 hvac-warning-text">{{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.WARNING_TEXT' | translate }}</h3>
        <p class="hvac-h4">{{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_RELATIONSHIPS_WARNING_MODAL.WARNING_INFO' | translate }}</p>
        <p>{{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_RELATIONSHIPS_WARNING_MODAL.WARNING_INFO_CONTINUED' | translate }}</p>
        <div class="hvac-divider hvac-button-divider"></div>
        <div class="hvac-unsaved-changes-warning-modal-actions">
            <button buttonType="button" hvacButton="default" (click)="removeUnsavedRelationships()">
                {{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_CHANGES_WARNING_MODAL.CONTINUE' | translate }}
            </button>
            <button buttonType="button" hvacButton="primary" (click)="unsavedRelationshipsModalClose()">
                {{'ACCOUNT_ADMIN.DISTRIBUTOR_MODAL.UNSAVED_RELATIONSHIPS_WARNING_MODAL.RETURN_TO_ADDING_RELATIONSHIP' | translate }}
            </button>
        </div>
    </div>
</utc-modal>
