<hvac-connected-portal-layout-container>
    <div top>
        <div class="hvac-dealer-settings-header">
            <hvac-content-header [data]="breadcrumbs">
                <hvac-distributor-navigation
                    *ngIf="(companyCode$ | async) === CompanyCode.Distributor"></hvac-distributor-navigation>
            </hvac-content-header>
        </div>
    </div>
    <div bottom>
        <div class="hvac-cp-card">
            <hvac-factory-system-search [errorMsg]="searchError$ | async"
                (searchResultEvent)="handleSearchUpdate($event)"
                (searchReset)="handleSearchReset()"></hvac-factory-system-search>

            <utc-loading-spinner *ngIf="isLoaded.system && isLoading.system" class="hvac-search-loader" backgroundMode="transparent"></utc-loading-spinner>

        </div>
        <div class="hvac-content-loader hvac-cp-card" *ngIf="isLoading.system && !isLoaded.system; else content">
            <utc-loading-spinner></utc-loading-spinner>
        </div>

        <ng-template #content>
            <div class="hvac-cp-card" *ngIf="(system$ | async)">
                <hvac-factory-system-details [isLoading]="isLoading.system && !isLoaded.system" [isWallControlConnected]="isWallControlConnected$ | async" [data]="(systemDetails$ | async)"
                    (onNavButtonClick)="handleContentChange()"></hvac-factory-system-details>
            </div>

            <ng-container *ngIf="(system$ | async) as system">
                <div class="hvac-cp-card">
                    <div class="hvac-content-tabs">
                        <hvac-tab-bar>
                            <button (click)="handleContentChange()" [ngClass]="{ 'hvac-active' : (contentView$ | async) === ContentView.SystemOverview}">{{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.FAULTS' | translate}}</button>
                            <button (click)="handleContentChange()" [ngClass]="{ 'hvac-active' : (contentView$ | async) === ContentView.EngineeringInsights}">{{'CONNECTED_PORTAL.FACTORY_SYSTEM.SYSTEM_ENGINEERING.ENGINEERING_OVERVIEW.INSIGHTS' | translate}}</button>
                        </hvac-tab-bar>
                    </div>

                    <ng-container *ngIf="(contentView$ | async) === ContentView.SystemOverview; else engineering">
                       <hvac-factory-system-insights [systemId]="system.systemId" [systemType]="system.systemType"></hvac-factory-system-insights>
                    </ng-container>

                    <ng-template #engineering>
                        <hvac-factory-engineering-insights *ngIf="(wallControlSerialNo$ | async) as serialNo"
                            [serialNo]="serialNo"></hvac-factory-engineering-insights>
                    </ng-template>
                </div>
            </ng-container>
        </ng-template>
    </div>
</hvac-connected-portal-layout-container>
