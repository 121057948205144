import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'common/components/base/base.component';

interface FAQCommon {
    isOpen: boolean;
    isQuestionGroup: boolean;
}

interface FAQquestion extends FAQCommon {
    QUESTION: string;
    ANSWER: string;
}

interface FAQquestionGroup extends FAQCommon{
    TITLE: string;
    QUESTIONS: FAQquestion[];
}

@Component({
    selector: 'hvac-connected-portal-faq',
    templateUrl: './connected-portal-faq.component.html',
    styleUrls: ['./connected-portal-faq.component.scss']
})
export class ConnectedPortalFaqComponent extends BaseComponent implements OnInit {
    public faqData: FAQquestionGroup[] = [];

    constructor(
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit(): void {
        this.faqData = this.getFaqData();
    }

    toggleAccordion(accordianObj: FAQquestionGroup | FAQquestion) {
        accordianObj.isOpen = !accordianObj.isOpen;

        if (accordianObj.isQuestionGroup) {
            this.closeChildAccordians(accordianObj as FAQquestionGroup);
        }
    }

    private getFaqData() {
        const data: FAQquestionGroup[] = this.translateService.instant('CONNECTED_PORTAL.FAQ.QUESTION_GROUPS');

        if (Array.isArray(data)) {
            const faqData = data.map((group: FAQquestionGroup) => {
                group.isOpen = false;
                group.isQuestionGroup = true;
                group.QUESTIONS.forEach((question: FAQquestion) => {
                    question.isOpen = false;
                    question.isQuestionGroup = false;
                });

                return group;
            });

            return faqData;
        }

        return [];
    }

    private closeChildAccordians(qnGroup: FAQquestionGroup) {
        qnGroup.QUESTIONS.forEach((question: FAQquestion) => {
            question.isOpen = false;
        });
    }
}
